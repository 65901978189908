import React, { CSSProperties, useEffect, useRef, useState } from "react";

const defaultStyle: CSSProperties = {
  display: "block",
  overflow: "hidden",
  resize: "none",
  width: "100%",
};

interface props {
  textCallback: (value: string) => void;
  style?: CSSProperties;
  properties: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
  defaultValue?: string;
}

const AutoHeightTextarea = ({ style = defaultStyle, textCallback, properties, defaultValue }: props) => {

  const messageRefElement = document.createElement('textarea');
  const textareaRef = useRef(messageRefElement);

  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [currentValue]);

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <textarea
      {...properties}
      ref={textareaRef}
      style={style}
      value={currentValue}
      onChange={e => {
        setCurrentValue(e.target.value);
        textCallback(e.target.value);
      }}
    />
  );
};

export default AutoHeightTextarea;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { IFormAction } from '../../workflow/interface/form-action';
import { IFormRole } from '../interface/form-role';
import { IFormStatus, IFormStatusWithAction } from '../../workflow/interface/form-status';
import { icons } from '../../../../../../../common/icons';
import { IConstraint, IStatusConstraint } from '../interface/constraint';
import { v4 as uuid } from 'uuid';

interface IRoleConstraint {
  actions: IFormAction[];
  role: IFormRole;
  status: IFormStatus;
  setRoleAction(status: IFormStatusWithAction, role: IFormRole): void;
  constraintList: IConstraint[];
}

const RoleConstraint: React.FC<IRoleConstraint> = (props) => {
  let accessLevelIcon = icons.blue.world;
  const statusConstraint = props.role.constraintsPerStatus ? props.role.constraintsPerStatus.filter((e: IStatusConstraint) => e.statusId === props.status.id) : [];
  let fieldCompareConstraint: IConstraint[] = [];
  if (statusConstraint.length > 0) {
    fieldCompareConstraint = statusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'field-compare');
    if (statusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'creator').length > 0) {
      accessLevelIcon = icons.blue.lock;
    }
    if (statusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'owner').length > 0) {
      accessLevelIcon = icons.blue.interact;
    }
  }

  return <>
    <Image className={'access-constraint'} src={accessLevelIcon} />
    {
      fieldCompareConstraint.map((e: IConstraint) => {
        let bgColor = '';
        const constraintTemplate = props.constraintList.filter((f: IConstraint) => e.style.constraintId === f.style.constraintId);
        if (constraintTemplate.length > 0) {
          bgColor = constraintTemplate[0].style.constraintColor;
        }
        return <span key={uuid()} style={bgColor !== '' ? { backgroundColor: bgColor } : {}}></span>
      })
    }
  </>
}

export default RoleConstraint;
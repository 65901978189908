import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '../..';
import { ICommentDeltaFields } from '../../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IFieldsLayout } from '../../../../../../module/business-process/module/form/interface/form';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils, ManageAdminHistory } from '../../../../manage-history';
import DeltaFields from '../../../fields';
import { FieldConfigChange } from '../../../utils/field-config-change';

interface IFieldLayoutDelta {
  delta: ICommentDeltaFields[];
  text: string;
  showUpdateTextOnly: boolean;
}

export class BPFormBuilderDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.FormBuilder;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;
    let showUpdateTextOnly = false;

    const { targetDelta, targetInstance } = { ...historyLogs.context };

    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    if (targetDelta) {
      const deltaKeys = Object.keys(targetDelta);
      if (deltaKeys.indexOf('fieldsLayout') > -1) {
        const { newDelta, oldDelta } = this.setFieldsLayout(targetDelta['fieldsLayout']);
        if (Object.keys(newDelta).length > 0) {
          const fieldsLayout = this.formatFieldsLayout(newDelta, oldDelta);
          deltaText = `${commentSender} ${fieldsLayout.text}`;
          deltaArray = fieldsLayout.delta;
          showUpdateTextOnly = fieldsLayout.showUpdateTextOnly;
        }
      } else {
        const fieldsDelta = new DeltaFields();
        deltaArray = fieldsDelta.formatContext(targetDelta || {}, targetInstance, utils);
        deltaText = `${commentSender} has updated Field: (label: ${targetInstance.label}, type: ${targetInstance.type})`;
      }
    }

    return { delta: deltaArray, text: deltaText, showUpdateTextOnly };
  }

  setFieldsLayout = (delta: Delta) => {

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key.indexOf('_') > -1) {
        const newKey = key.split('_')[1];
        if (delta[key] instanceof Array) {
          oldDelta[newKey] = delta[key].filter((e: any) => e instanceof Object);
        }
      } else {
        newDelta[key] = delta[key]
      }
    });
    return { newDelta, oldDelta };
  }

  formatFieldsLayout = (delta: Delta, targetInstance: any) => {
    const newDeltaKeys = Object.keys(delta);
    let deltaText = '';
    let showUpdateTextOnly = false;
    let deltaArray = [] as ICommentDeltaFields[];
    if (newDeltaKeys.length > 0) {
      newDeltaKeys.forEach(key => {
        if ((delta[key] && Object.keys(delta[key][0]).length > 0 && targetInstance[key]) instanceof Array) {
          const fieldsLayout = this.fieldsLayout(delta[key][0], targetInstance[key][0]);
          deltaText = `has updated Section (name: ${fieldsLayout.updatedSection}, type: ${fieldsLayout.updatedSectionType})`;

          const newDeltaKeys = Object.keys(fieldsLayout.newDelta).filter(key => {
            if (key === 'body' || key === 'header') {
              return false;
            }
            return true;
          });

          let newDelta = {} as Delta;
          let oldDelta = {} as Delta;

          if (Object.keys(fieldsLayout.newDelta).length === 1) {
            if (Object.keys(fieldsLayout.newDelta).toString() === 'header') {
              deltaText = `has updated header fields inside Section (name: ${fieldsLayout.updatedSection}, type: ${fieldsLayout.updatedSectionType})`;
              showUpdateTextOnly = true;
            }
            if (Object.keys(fieldsLayout.newDelta).toString() === 'body') {
              deltaText = `has updated fields inside Section (name: ${fieldsLayout.updatedSection}, type: ${fieldsLayout.updatedSectionType})`;
              showUpdateTextOnly = true;
            }
          } else {
            if (Object.keys(fieldsLayout.newDelta).indexOf('header') > -1 &&
              Object.keys(fieldsLayout.newDelta).indexOf('body') > -1) {
              deltaText = `has updated header and/or fields and other config of Section (name: ${fieldsLayout.updatedSection}, type: ${fieldsLayout.updatedSectionType})`;
            }
          }

          newDeltaKeys.forEach(key => {
            newDelta[key] = fieldsLayout.newDelta[key];
            oldDelta[key] = fieldsLayout.oldDelta[key];
          });

          if (Object.keys(newDelta).length > 0) {
            deltaArray = FieldConfigChange.format(newDelta, oldDelta, oldDelta);
            showUpdateTextOnly = false;
          }
        } else {
          if (!targetInstance[key]) {
            const newDelta = delta[key] as IFieldsLayout[];
            if (newDelta[0] && newDelta[0].style === 'grouped') {
              deltaText = `has added new Section (name: ${newDelta[0].title || 'Untitled'})`;
            } else {
              deltaText = `has updated fields inside Section (name: Untitled, type: Plain)`;
            }
            showUpdateTextOnly = true;
          }
        }
      });
    } else {
      if (Object.keys(targetInstance).length > 0) {
        const instanceKeys = Object.keys(targetInstance);
        instanceKeys.forEach(key => {
          deltaText = `has deleted Section (name: ${targetInstance[key][0].title || 'Untitled'})`;
          showUpdateTextOnly = true;
        });
      }
    }

    return { delta: deltaArray, text: deltaText, showUpdateTextOnly };
  }

  fieldsLayout = (delta: any, instance: any) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      const oldData = instance[key];
      if (typeof delta[key] === 'object') {
        if (JSON.stringify(delta[key]) !== JSON.stringify(oldData)) {
          newDelta[key] = [delta[key]];
          if (typeof oldData === 'object') {
            if (Array.isArray(oldData)) {
              if (oldData.length !== delta[key].length) {
                newDelta[key] = [[...oldData], [...delta[key]]];
              }
            } else {
              newDelta[key] = [{ ...oldData }, { ...delta[key] }];
            }
          }
          oldDelta[key] = oldData || '';
        }
      } else {
        if (delta[key] !== oldData) {
          newDelta[key] = !!oldData ? [oldData, delta[key]] : [delta[key]];
          oldDelta[key] = oldData || '';
          if (key === 'collapsable') {
            oldDelta[key] = oldData || false;
          }
        }
      }
    });

    return {
      oldDelta,
      newDelta,
      updatedSection: instance.title || 'Untitled',
      updatedSectionType: ManageAdminHistory.uppercase(instance.style)
    };
  }
}

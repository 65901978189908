import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_BUSINESS_PROCESS_OPTION, CREATE_APP, GET_APP_LIST, GET_APP_DETAILS, DELETE_APP_CONFIRM, UPDATE_APP } from './constant';
import { setStatusCodeAction } from '../../../error/action';
import {
  getBusinessProcessOptionErrorAction,
  getBusinessProcessOptionReturnAction,
  createAppErrorAction,
  createAppReturnAction,
  getAppListReturnAction,
  getAppListErrorAction,
  getAppDetailsErrorAction,
  getAppDetailsReturnAction,
  confirmDeleteAppReturnAction,
  confirmDeleteAppErrorAction,
  updateAppReturnAction,
  updateAppErrorAction
} from './action';
import { getRequest, postRequest, deleteRequest, putRequest, IAxiosResponse } from '../../../../common/api-request';
import { IBusinessProcessOptionInput } from './interface/input/business-process-option';
import { IForm } from '../business-process/module/form/interface/form';
import { IAppCreateInput } from './interface/input/app-create';
import { IAppListInput } from './interface/input/app-list';
import { IAppGetInput } from './interface/input/app-details';
import { IAppConfirmDeleteInput } from './interface/input/app-confirm-delete';
import { IAppUpdateInput } from './interface/input/app-update';

export function* getBusinessProcessOption(args: IBusinessProcessOptionInput) {
  try {
    const company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms?limit=0`, {});
    const optionList = req.data && req.data.data.map((details: IForm, key: number) => {
      return {
        key: key,
        text: details.name,
        value: details.id
      }
    })
    yield put(getBusinessProcessOptionReturnAction({ data: optionList }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getBusinessProcessOptionErrorAction());
  }
}

export function* createApp(args: IAppCreateInput) {
  try {
    let company = args.company;
    let data = args.data;
    let req: IAxiosResponse = yield call(postRequest, `${company}/applications`, data);
    let formReq: IAxiosResponse = yield call(getRequest, `${company}/applications/${req.data.data.id}?load=forms`, {});
    yield put(createAppReturnAction({ data: { ...formReq.data.data } }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(createAppErrorAction({ message: JSON.stringify(error.response.data.message) }));
  }
}

export function* getAppList(args: IAppListInput) {
  try {
    let company = args.company;
    let name = args.name ? `&name=${args.name}` : '';
    let req: IAxiosResponse = yield call(getRequest, `${company}/applications?load=forms&limit=20${name}`, {});
    yield put(getAppListReturnAction({ data: { ...req.data.data } }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getAppListErrorAction({ message: JSON.stringify(error.response.data.message) }));
  }
}

export function* getAppDetails(args: IAppGetInput) {
  try {
    let company = args.company;
    let appId = args.appId;
    let req: IAxiosResponse = yield call(getRequest, `${company}/applications/${appId}?load=forms`, {});
    yield put(getAppDetailsReturnAction({ data: { ...req.data.data } }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getAppDetailsErrorAction({ message: JSON.stringify(error.response.data.message) }));
  }
}

export function* deleteApp(args: IAppConfirmDeleteInput) {
  try {
    let company = args.company;
    let appId = args.appId;
    yield call(deleteRequest, `${company}/applications/${appId}`, {});
    yield put(confirmDeleteAppReturnAction({ appId: appId }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(confirmDeleteAppErrorAction({ message: JSON.stringify(error.response.data.message) }));
  }
}

export function* updateApp(args: IAppUpdateInput) {
  try {
    let company = args.company;
    let appId = args.appId;
    let data = args.data;
    yield call(putRequest, `${company}/applications/${appId}`, data);
    let req: IAxiosResponse = yield call(getRequest, `${company}/applications/${appId}?load=forms`, {});
    yield put(updateAppReturnAction({ data: { ...req.data.data } }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(updateAppErrorAction({ message: JSON.stringify(error.response.data.message) }));
  }
}

export function* appsSagas() {
  yield takeLatest(GET_BUSINESS_PROCESS_OPTION, getBusinessProcessOption);
  yield takeLatest(CREATE_APP, createApp);
  yield takeLatest(GET_APP_LIST, getAppList);
  yield takeLatest(GET_APP_DETAILS, getAppDetails);
  yield takeLatest(DELETE_APP_CONFIRM, deleteApp);
  yield takeLatest(UPDATE_APP, updateApp);
}

// All sagas to be loaded
export default appsSagas;
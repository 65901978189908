import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { IFieldConfig } from '../../../../../../../../../users/interface/field';

interface IProps {
  formFieldList: ICustomFieldDetail[];
  formId: string;
  referenceFieldList: ICustomFieldDetail[];
  selectedField: string;
}

export const useManageIssueFieldsConfig = (props: IProps) => {
  const { formFieldList, formId, referenceFieldList } = { ...props };
  const defaultEnumReference = { id: '', name: '' };

  const [enumBpReference, setEnumBpReference] = useState(defaultEnumReference);
  const [fieldData, setFieldData] = useState<any>(null);
  const [configData, setConfigData] = useState<any>(null);
  const [selectedFieldConfig, setSelectedFieldConfig] = useState<any>(null);

  const setFieldDataToState = (field: ICustomFieldDetail, bpFormId: string) => {
    if (field.type === 'autopopulated' && field.config) {
      field = {
        ...field,
        type: field.config.labelType
      }
    }
    setSelectedFieldConfig(field);
    let tempField = { ...field };
    let tempConfig: any = { ...tempField.config };
    const config = { ...tempConfig, dataType: 'document' } as IFieldConfig;
    setConfigData(config);
    delete tempField.config;
    setFieldData(tempField);
    setEnumBpReference({
      id: bpFormId,
      name: ''
    });
  }

  const manageAutopopulateConfig = (field: ICustomFieldDetail) => {
    const config = field.config as any;
    const sourceField = formFieldList.find((e: ICustomFieldDetail) => e.id === config.sourceField);
    const sourceForm = sourceField && sourceField.config && sourceField.config.data.formId;
    if (field.config && field.config.labelFormat) {
      const labelId = field.config.labelFormat.replace(/fields./g, '').replace(/{/g, '').replace(/}/g, '');
      if (referenceFieldList.length > 0) {
        const labelConfig = referenceFieldList.find(e => e.id === labelId);
        if (labelConfig) {
          field = labelConfig;
        }
      }
    }
    setFieldDataToState(field, sourceForm);
  }

  const manageFieldConfiguration = (fieldId: string) => {
    if (!fieldId) {
      setFieldData(null);
      setConfigData(null);
      setEnumBpReference(defaultEnumReference);
    }

    let field = formFieldList.find((e: ICustomFieldDetail) => e.id === fieldId);
    if (field) {
      if (field && field.configName === IConfigName.Autopopulated) {
        manageAutopopulateConfig(field);
      } else {
        setFieldDataToState(field, formId);
      }
    }
  }

  useEffect(() => {
    if (props.selectedField) {
      manageFieldConfiguration(props.selectedField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedField]);

  return {
    ...props, enumBpReference, setEnumBpReference, fieldData, setFieldData,
    configData, setConfigData, selectedFieldConfig, setSelectedFieldConfig
  }
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Route } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { useSetFourthLevelRoute } from '../../../../../../hooks/use-set-fourth-level-route';
import PDFTemplateContainer from './list';

interface IMatch {
  path: string;
  params: {
    thirdLevelRoute: string;
  }
}

interface PDFTemplateProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class PDFTemplate {

  name = 'pdf-template';

  render: React.FC<PDFTemplateProps> = (props) => {
    const match: IMatch = useRouteMatch();
    const dispatch = useDispatch();

    useSetFourthLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: match.params.thirdLevelRoute,
      fourthLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route path={`${match.path}`}>
          <PDFTemplateContainer hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </>
    );
  }
}

export default PDFTemplate;

import { useEffect } from 'react';
import { IFieldAccessType } from '../../../../../../../component/admin-module/module/users/interface/field';
import { ArrayFieldProperties } from '../../../../service/array-field-properties.service';
import { TimeProps } from '../../../../service/type/time';

interface InitialLoad {
  isAddRecord: boolean;
  accessType: IFieldAccessType;
  setDefaultValue(): void;
  validateInput(): void;
  isMultiline?: boolean;
  setMultilineCount?(value: number): void;
  elementProperties?: ArrayFieldProperties<TimeProps>;
}

export const useInitialLoad = (props: InitialLoad) => {
  const { setDefaultValue, validateInput, isAddRecord, accessType } = props;

  useEffect(() => {
    if (props.setMultilineCount && props.elementProperties) {
      const len = props.elementProperties.current.length;
      props.setMultilineCount(len === 0 ? 1 : len);
    }
    if (isAddRecord) {
      setDefaultValue();;
    }
    if (accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
import React, { useState, useRef } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { IFormAction, IFormActionConfig } from '../interface/form-action';
import { actionAssignmentType } from '../constant';
import AssignmentType from './assignment-type';
import { icons } from '../../../../../../../common/icons';
import { IFormStatus } from '../interface/form-status';
import { useSetActionToStateAndRef } from './hooks/use-set-action-to-state-and-ref';


interface IActionModal {
  selectedAction: IFormAction;
  deleteAction(actionId: string, actionName: string): void;
  updateActionList(forUpdateFormAction: IFormAction): void;
  formStatusList: IFormStatus[];
  formAction: IFormAction;
  editAction(formAction: IFormAction): void;
}

const ActionModal: React.FC<IActionModal> = (props) => {
  const actionNameRef = useRef(document.createElement('input'));

  const {
    assignmentType,
    setAssignmentType,
    selectedActionId,
    returnAction,
    setReturnAction
  } = useSetActionToStateAndRef({
    actionNameRef,
    selectedAction: props.selectedAction
  });

  const initialError = { name: false };
  const [error, setError] = useState(initialError);

  const updateActionList = (outsideClicked: boolean) => {
    let actionObject = { ...props.selectedAction };
    let newErrorState = { ...error };

    if (actionObject.new && !actionNameRef.current.value && outsideClicked) {
      return props.deleteAction(props.selectedAction.id, props.selectedAction.name);
    }

    if (!actionNameRef.current.value) {
      newErrorState.name = true;
      setError(newErrorState);
      return;
    }

    actionObject.name = actionNameRef.current.value.toUpperCase();
    const splitAssignmentType = assignmentType.split('-');
    if (!actionObject.config) {
      actionObject.config = {} as IFormActionConfig;
    }
    actionObject.config.assignmentType = splitAssignmentType[1];
    actionObject.config.direction = splitAssignmentType[0];
    actionObject.config.return = returnAction;
    props.updateActionList(actionObject);
    newErrorState.name = false;
    setError(newErrorState);
  }

  const actionTargetStatus = props.formStatusList.find((status: IFormStatus) => status.id === props.selectedAction.config?.targetStatusId) as IFormStatus;
  const actionStatus = props.formStatusList.find((status: IFormStatus) => status.id === props.selectedAction.statusId) as IFormStatus;
  const arrAssignmentType = assignmentType.split('-');

  return <>
    <div className={`${selectedActionId ? 'hidden-container' : ''}`} onClick={() => { updateActionList(true) }}></div>
    <div id='action-modal' className={`action-modal-container ${selectedActionId ? '' : 'hidden'}`}>
      <input
        placeholder='Type Action name...'
        onKeyDown={(event) => {
          if (event.key === 'Enter') { updateActionList(false) }
        }}
        type='text'
        className={`action-modal-title ${error.name ? 'error' : ''}`}
        ref={actionNameRef} />
      <div className={`action-modal-icon ${(actionTargetStatus.final || actionStatus.final) ? ' action-modal-revert' : ' action-modal-grid'}`}>
        {
          actionAssignmentType
            .filter((type: string) => {
              return ((actionTargetStatus.final || actionStatus.final) && type !== 'forward-individual' && type !== 'backward-individual') || (!actionTargetStatus.final && !actionStatus.final);
            })
            .map((type: string) => {
              return <AssignmentType
                formAction={props.formAction}
                editAction={props.editAction}
                key={`asignment-type-${type}`}
                selectedAssignmentType={assignmentType}
                assignmentType={type}
                setAssignmentType={setAssignmentType}
                isEndStatus={actionTargetStatus.final || actionStatus.final}
                screen='action-modal'
              />
            })
        }
      </div>
      {arrAssignmentType[0] === 'backward' &&
        <div className='checkbox-container'>
          <div onClick={() => setReturnAction(!returnAction)} className="ui checkbox">
            <input type="checkbox"
              className="hidden"
              onChange={() => { }}
              checked={returnAction}
            />
            <label>Return</label>
          </div>
        </div>
      }
      <div className='flex-container'>
        <Image className={`action-modal-btn cancel`} onClick={() => props.deleteAction(props.selectedAction.id, props.selectedAction.name)} src={icons.other.circleCancel} />
        <Image className={`action-modal-btn ok`} onClick={() => updateActionList(false)} src={icons.other.circleOk} />
      </div>
    </div>
  </>
}

export default ActionModal;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Dimmer, Loader, Portal, Tab } from 'semantic-ui-react-bpm';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';
import changeRoute from '../../../../../../../../../common/utils/change-menu';
import { IRootState } from '../../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import ButtonContainer from '../../../../../button-container';
import { saveDisplayDataAction, saveDisplayDataErrorAction, deleteDisplayDataAction, publishDisplayDataAction } from '../actions';
import { useGetDisplayData } from '../hooks/use-get-display-data';
import { useSetDisplayDataState } from '../hooks/use-set-display-data-state';
import { IDisplayData } from '../interface/display-data';
import DisplayDataModal from '../modal';
import CreatedDisplayData from './created';
import ActionMessage from '../../../../../../../../../common/general/action-message';
import { displayDataActionMessage } from '../constant';
import AppliedDisplayData from './applied';
import { IDisplayDataConfigAssignments } from '../interface/display-data-assignments';

interface IDisplayDataConfig {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const DisplayDataConfig: React.FC<IDisplayDataConfig> = (props) => {
  const dispatch = useDispatch();
  const match: { params: { formId: string } } = useRouteMatch();
  const history = useHistory();

  const { company } = useSelector((state: IRootState) => state.auth);
  const {
    displayData,
    displayDataConfigKeypath,
    fieldList,
    listLoading,
    displayDataAssignments,
    displayDataComponents,
    saveType
  } = useSelector((state: IRootState) => state.configurationDisplayData);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const { statusList } = useSelector((state: IRootState) => state.slas);

  const [activeMenu, setActiveMenu] = useState<'Applied Labels' | 'Created Labels'>('Created Labels');
  const [selectedData, setSelectedData] = useState<IDisplayData | undefined>();
  const [displayDataList, setDisplayDataList] = useState<IDisplayData[]>([]);
  const [displayDataToDelete, setDisplayDataToDelete] = useState<IDisplayData>({} as IDisplayData);
  const [displayDataApplied, setDisplayDataApplied] = useState<IDisplayDataConfigAssignments[]>([]);

  const setAppliedDataValidation = (data: IDisplayDataConfigAssignments, index: number) => {
    let message = ``;
    const tempData = [...displayDataApplied];
    const dataExists = tempData.filter(e => !e.deleted).filter((e, idx) => {
      if (idx !== index && data.component && data.statusId) {
        let componentSelected = (e.component === data.component);
        let statusSelected = (e.statusId === data.statusId);
        let configSelected = e.configId === data.configId;
        if (e.statusId === 'all' && e.component === 'all' && !configSelected) {
          configSelected = true;
        }
        if (componentSelected && statusSelected && !configSelected) {
          configSelected = true;
        }
        return componentSelected && statusSelected && configSelected;
      }
      return false;
    });
    if (dataExists.length > 0) {
      const allStatusesAndComponents = data.component === 'all' && data.statusId === 'all'
        && tempData.some(e => e.component === 'all' && e.statusId === 'all');
      if (allStatusesAndComponents) {
        return `Only one label config for all component of all statuses is allowed.`;
      }
      return `Only one label config for a component with same status is allowed.`;
    }
    return message;
  }

  const triggerErrorMessage = (message: string) => {
    dispatch(saveDisplayDataErrorAction(message))
  }

  const openDisplayDataForm = (data: IDisplayData, actionType: 'edit' | 'create') => {
    const tempData = [...displayDataList];
    if (data.new && actionType === 'create') {
      tempData.push(data);
      setDisplayDataList(tempData);
    }
    setSelectedData(data);
  }

  const fieldValueEqualPrev = (prev: IDisplayData, current: IDisplayData) => {
    const isHeaderEq = JSON.stringify(prev.header) === JSON.stringify(current.header);
    const isContentsEq = JSON.stringify(prev.content) === JSON.stringify(current.content);
    const isNameEq = prev.name === current.name;
    return isHeaderEq && isContentsEq && isNameEq;
  }

  const saveFieldConfig = (value: IDisplayData) => {
    let tempData = [...displayDataList];
    tempData = tempData.map((data: IDisplayData) => {
      let tempData = { ...data };
      if (tempData.id === value.id) {
        tempData = value;
        tempData = {
          ...value,
          edited: !data.new ? fieldValueEqualPrev(tempData, data) : false
        }
      }
      return tempData;
    });
    setDisplayDataList(tempData);
    setSelectedData(undefined);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteSelectedDisplayData = (isNew?: boolean) => {
    if (displayDataToDelete && !isNew) {
      if (!displayDataToDelete.new) {
        deleteDisplayData(displayDataToDelete);
      } else {
        let tempData = [...displayDataList];
        tempData = tempData.map((labels: IDisplayData) => {
          const tempLabel = { ...labels };
          if (tempLabel.id === displayDataToDelete.id) {
            tempLabel.deleted = true;
          }
          return tempLabel;
        });
        setDisplayDataList(tempData);
      }
    }
    if (selectedData && isNew) {
      let tempData = [...displayDataList];
      let index = tempData.indexOf(selectedData);
      tempData.splice(index, 1);
      setDisplayDataList(tempData);
    }
    setDisplayDataToDelete({} as IDisplayData);
    setSelectedData(undefined);
  }

  const closeSettings = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const saveDisplayData = (saveType: string) => {
    dispatch(saveDisplayDataAction({
      company: company,
      formId: match.params.formId,
      saveType,
      displayDataList,
      activeMenu,
      displayDataAssignments: displayDataApplied,
    }));
    props.hasPageChangeRef.current.hasChange = false;
  }

  const addNewAppliedDisplayData = (data: IDisplayDataConfigAssignments) => {
    const tempData = [...displayDataApplied];
    tempData.push(data);
    setDisplayDataApplied(tempData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const editAppliedDisplayData = (data: IDisplayDataConfigAssignments, index: number) => {
    const errorMessage = setAppliedDataValidation(data, index);
    if (errorMessage) {
      triggerErrorMessage(errorMessage);
      return;
    }
    const tempData = [...displayDataApplied];
    tempData[index] = data;
    setDisplayDataApplied(tempData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteAppliedDisplayData = (data: IDisplayDataConfigAssignments) => {
    if (!data.new) {
      deleteDisplayData(data);
    } else {
      let tempData = [...displayDataApplied];
      const index = tempData.indexOf(data);
      tempData = tempData.map((data, idx) => {
        const tempLabel = { ...data };
        if (idx === index) {
          tempLabel.deleted = true;
        }
        return tempLabel;
      });
      setDisplayDataApplied(tempData);
    }
  }

  const deleteDisplayData = (data: any) => {
    dispatch(deleteDisplayDataAction({
      company: company,
      formId: match.params.formId,
      activeMenu,
      dataToDelete: data
    }));
  }

  const panes = [
    {
      menuItem: 'Created Labels',
      render: () => <Tab.Pane attached={false}>
        <CreatedDisplayData
          openDisplayDataForm={openDisplayDataForm}
          displayDataList={displayDataList}
          displayDataConfigKeypath={displayDataConfigKeypath}
          setDisplayDataToDelete={setDisplayDataToDelete}
          triggerErrorMessage={triggerErrorMessage}
          displayDataApplied={displayDataApplied}
        />
      </Tab.Pane>
    },
    {
      menuItem: 'Applied Labels',
      render: () => <Tab.Pane attached={false}>
        <AppliedDisplayData
          displayDataComponents={displayDataComponents}
          displayDataApplied={displayDataApplied}
          addNewAppliedDisplayData={addNewAppliedDisplayData}
          statusList={statusList}
          displayDataList={displayDataList}
          editAppliedDisplayData={editAppliedDisplayData}
          formId={match.params.formId}
          deleteAppliedDisplayData={deleteAppliedDisplayData}
          displayDataConfigKeypath={displayDataConfigKeypath}
        />
      </Tab.Pane>
    },
  ]

  useGetDisplayData({
    company: company,
    dispatch: dispatch,
    formId: match.params.formId
  });

  useSetDisplayDataState({
    displayData,
    setDisplayDataList,
    displayDataAssignments,
    setDisplayDataApplied,
    displayDataList
  });

  useEffect(() => {
    if (saveType === 'publish') {
      dispatch(publishDisplayDataAction({ company: company, formId: match.params.formId, }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveType]);

  return <div id='display-data-module-container' style={{ position: 'relative' }} className='display-data-module'>
    {
      (listLoading) &&
      <Dimmer active inverted>
        <Loader active content='Loading' />
      </Dimmer>
    }
    <ActionMessage messages={displayDataActionMessage} />
    <div className='display-data-wrapper'>
      <div className='display-data-tab-container'>
        <Tab menu={{ secondary: true }} panes={panes} onTabChange={(e: any) => setActiveMenu(e.target.text)} />
      </div>
      <ButtonContainer
        cancel={closeSettings}
        save={() => saveDisplayData('draft')}
        publish={() => saveDisplayData('publish')}
      />
    </div>
    <Portal open={selectedData !== undefined}>
      <DisplayDataModal
        isHiddenContainer={selectedData === undefined}
        setSelectedData={setSelectedData}
        selectedData={selectedData ? selectedData : {} as IDisplayData}
        fieldList={fieldList}
        displayDataConfigKeypaths={displayDataConfigKeypath}
        saveFieldConfig={saveFieldConfig}
        deleteSelectedDisplayData={deleteSelectedDisplayData}
      />
    </Portal>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      cancelButtonName={'Cancel'}
      confirmButtonColor={'red'}
      cancelButtonColor={'green'}
      close={() => setDisplayDataToDelete({} as IDisplayData)}
      open={displayDataToDelete !== undefined && displayDataToDelete.id !== undefined}
      confirm={() => deleteSelectedDisplayData()}
      cancel={() => setDisplayDataToDelete({} as IDisplayData)}
      headerText="Confirmation"
      detailText={`${displayDataToDelete.name} will be deleted. Do you want to proceed?`}
      loading={false}
      type='warning'
    />
  </div>
}

export default DisplayDataConfig;
import { IDocumentsGetInput } from './interface/input/get-documents';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getDocumentsReturnAction, getDocumentsErrorAction } from './action';
import { IAxiosResponse, postRequest } from '../../../../common/api-request';
import { setStatusCodeAction } from '../../../error/action';
import { GET_DOCUMENTS } from './constant';

export function* getDocuments(args: IDocumentsGetInput) {
  try {
    let company = args.company;
    let filterData = args.filterData;
    let loadFields = '';
    if (args.loadFields) {
      loadFields = `&load=fields`
    }
    const load = `load=owner&load=form&load=creator&load=updatedBy&load=statusName&load=dynamicAction&load=slaStatus&load=lastStatusChange&load=accessType&load=ownerType${loadFields}`;
    const filter: IAxiosResponse = yield call(postRequest, `${company}/documents/tasks?${load}&limit=0&case=${args.case}`, filterData);

    yield put(getDocumentsReturnAction({ documentsList: filter.data.data }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getDocumentsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* calendarSagas() {
  yield takeLatest(GET_DOCUMENTS, getDocuments);
}

// All sagas to be loaded
export default calendarSagas;
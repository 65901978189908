import {
  GET_FORM_FIELDS,
  GET_FORM_FIELDS_RETURN,
  GET_FORM_FIELDS_ERROR,
  PUBLISH_DUPLICATE_CHECKER,
  PUBLISH_DUPLICATE_CHECKER_ERROR,
  PUBLISH_DUPLICATE_CHECKER_RETURN,
  SAVE_DUPLICATE_CHECKER,
  SAVE_DUPLICATE_CHECKER_RETURN,
  SAVE_DUPLICATE_CHECKER_ERROR,
  GET_DUPLICATE_CHECKER,
  GET_DUPLICATE_CHECKER_ERROR,
  GET_DUPLICATE_CHECKER_RETURN
} from './constant'
import { IFormFieldsDetailInput } from './interface/input/form-fields'
import { IFormFieldsResponse } from './interface/response/form-fields'
import { IDuplicateCheckerDetailSaveInput, IDuplicateCheckerPublishDetailInput } from './interface/input/duplicate-checker-save'
import { IDuplicateCheckerSaveResponse } from './interface/response/duplicate-checker-save'
import { IDuplicateCheckerGetDetailInput } from './interface/input/duplicate-checker-get'
import { IDuplicateCheckerResponse } from './interface/response/duplicate-checker-get'

export const getFormFieldsAction = (payload: IFormFieldsDetailInput) => {
  return { type: GET_FORM_FIELDS, ...payload }
}

export const getFormFieldsReturnAction = (payload: IFormFieldsResponse) => {
  return { type: GET_FORM_FIELDS_RETURN, ...payload }
}

export const getFormFieldsErrorAction = (message: string) => {
  return { type: GET_FORM_FIELDS_ERROR, message }
}

export const getDuplicateCheckerAction = (payload: IDuplicateCheckerGetDetailInput) => {
  return { type: GET_DUPLICATE_CHECKER, ...payload }
}

export const getDuplicateCheckerReturnAction = (payload: IDuplicateCheckerResponse) => {
  return { type: GET_DUPLICATE_CHECKER_RETURN, ...payload }
}

export const getDuplicateCheckerErrorAction = (message: string) => {
  return { type: GET_DUPLICATE_CHECKER_ERROR, message }
}

export const saveDuplicateCheckerAction = (payload: IDuplicateCheckerDetailSaveInput) => {
  return { type: SAVE_DUPLICATE_CHECKER, ...payload }
}

export const saveDuplicateCheckerReturnAction = (payload: IDuplicateCheckerSaveResponse) => {
  return { type: SAVE_DUPLICATE_CHECKER_RETURN, ...payload }
}

export const saveDuplicateCheckerErrorAction = (message: string) => {
  return { type: SAVE_DUPLICATE_CHECKER_ERROR, message }
}

export const publishDuplicateCheckerAction = (payload: IDuplicateCheckerPublishDetailInput) => {
  return { type: PUBLISH_DUPLICATE_CHECKER, ...payload }
}

export const publishDuplicateCheckerReturnAction = () => {
  return { type: PUBLISH_DUPLICATE_CHECKER_RETURN }
}

export const publishDuplicateCheckerErrorAction = (message: string) => {
  return { type: PUBLISH_DUPLICATE_CHECKER_ERROR, message }
}
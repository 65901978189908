import { CSSProperties } from 'react';
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { icons } from '../../../../common/icons';
import { getFilters } from '../../../main/partials/header/hexToCssFilter';
import { CLEAR_DOCUMENT_ACTION_MESSAGE } from '../../constant';
import { CLEAR_FORM_DOCUMENTS_FIELD_ERROR } from '../report/constant';

export const GET_FORM = 'app/document/form/GET_FORM';
export const GET_FORM_RETURN = 'app/document/form/GET_FORM_RETURN';
export const GET_FORM_FIELD = 'app/document/form/GET_FORM_FIELD';
export const GET_FORM_FIELD_RETURN = 'app/document/form/GET_FORM_FIELD_RETURN';
export const GET_DOCUMENT_ACTION = 'app/document/form/GET_DOCUMENT_ACTION';
export const GET_DOCUMENT_ACTION_RETURN = 'app/document/form/GET_DOCUMENT_ACTION_RETURN';
export const SAVE_DOCUMENT = 'app/document/form/SAVE_DOCUMENT';
export const SAVE_DOCUMENT_RETURN = 'app/document/form/SAVE_DOCUMENT_RETURN';
export const SAVE_DOCUMENT_ERROR = 'app/document/form/SAVE_DOCUMENT_ERROR';
export const CLEAR_MESSAGES = 'app/document/form/CLEAR_MESSAGES';
export const CLEAR_SAVE_DOCUMENT_SUCCESS = 'app/document/form/CLEAR_SAVE_DOCUMENT_SUCCESS';
export const CLEAR_SAVE_DOCUMENT_ERROR = 'app/document/form/CLEAR_SAVE_DOCUMENT_ERROR';
export const TRIGGER_FIELD_TO_UPDATE = 'app/document/form/TRIGGER_FIELD_TO_UPDATE';
export const TRIGGER_FIELD_TO_UPDATE_RETURN = 'app/document/form/TRIGGER_FIELD_TO_UPDATE_RETURN';
export const TRIGGER_FIELD_TO_UPDATE_SHORTCUT = 'app/document/form/TRIGGER_FIELD_TO_UPDATE_SHORTCUT';
export const TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN = 'app/document/form/TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN';
export const GET_COMMENTS = 'app/document/form/GET_COMMENTS';
export const GET_COMMENT_SENDER = 'app/document/form/GET_COMMENT_SENDER';
export const GET_COMMENT_SENDER_RETURN = 'app/document/form/GET_COMMENT_SENDER_RETURN';
export const GET_COMMENTS_RETURN = 'app/document/form/GET_COMMENTS_RETURN';
export const SAVE_COMMENT = 'app/document/form/SAVE_COMMENT';
export const SAVE_COMMENT_RETURN = 'app/document/form/SAVE_COMMENT_RETURN';
export const UPDATE_COMMENT_RETURN = 'app/document/form/UPDATE_COMMENT_RETURN';
export const SAVE_COMMENT_RETURN_ERROR = 'app/document/form/SAVE_COMMENT_RETURN_ERROR';
export const DELETE_COMMENT = 'app/document/form/DELETE_COMMENT';
export const DELETE_COMMENT_RETURN = 'app/document/form/DELETE_COMMENT_RETURN';
export const RESET_DOCUMENT_FORM = 'app/document/form/RESET_DOCUMENT_FORM';
export const RESET_DOCUMENT_FORM_SHORTCUT = 'app/document/form/RESET_DOCUMENT_FORM_SHORTCUT';
export const GET_DOCUMENTS_DUPLICATE = 'app/document/form/GET_DOCUMENTS_DUPLICATE';
export const GET_DOCUMENTS_DUPLICATE_RETURN = 'app/document/form/GET_DOCUMENTS_DUPLICATE_RETURN';
export const GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN = 'app/document/form/GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN';
export const GET_DOCUMENTS_FOLLOWERS = 'app/document/form/GET_DOCUMENTS_FOLLOWERS';
export const GET_DOCUMENTS_FOLLOWERS_RETURN = 'app/document/form/GET_DOCUMENTS_FOLLOWERS_RETURN';
export const FOLLOW_DOCUMENT = 'app/document/form/FOLLOW_DOCUMENT';
export const UNFOLLOW_DOCUMENT = 'app/document/form/UNFOLLOW_DOCUMENT';
export const UNFOLLOW_DOCUMENT_RETURN = 'app/document/form/UNFOLLOW_DOCUMENT_RETURN';
export const GET_DOCUMENTS_ACTION_CONFIG = 'app/document/form/GET_DOCUMENTS_ACTION_CONFIG';
export const GET_DOCUMENTS_ACTION_CONFIG_RETURN = 'app/document/form/GET_DOCUMENTS_ACTION_CONFIG_RETURN;'
export const GET_INDIVIDUAL_USERS_LIST = 'app/document/form/GET_INDIVIDUAL_USERS_LIST';
export const GET_INDIVIDUAL_USERS_LIST_RETURN = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_RETURN';
export const GET_INDIVIDUAL_USERS_LIST_ERROR = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_ERROR';
export const GET_INDIVIDUAL_USERS_LIST_RESET = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_RESET';
export const SAVING_WITH_NO_CHANGES = 'app/document/form/SAVING_WITH_NO_CHANGES';
export const RESET_SAVING_WITH_NO_CHANGES_MESSAGE = 'app/document/form/RESET_SAVING_WITH_NO_CHANGES_MESSAGE';
export const RESET_CURRENT_ACTION_TYPE = 'app/document/form/RESET_CURRENT_ACTION_TYPE';
export const DELETE_DOCUMENT = 'app/document/form/DELETE_DOCUMENT';
export const DELETE_DOCUMENT_RETURN = 'app/document/form/DELETE_DOCUMENT_RETURN';
export const DELETE_DOCUMENT_ERROR = 'app/document/form/DELETE_DOCUMENT_ERROR';
export const RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE = 'app/document/form/RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE';
export const RESET_DELETE_DOCUMENT_ERROR_MESSAGE = 'app/document/form/RESET_DELETE_DOCUMENT_ERROR_MESSAGE';
export const DELETE_COMMENT_ATTACHMENT = 'app/document/form/DELETE_COMMENT_ATTACHMENT';
export const KICK_CURRENT_USER = 'app/document/form/KICK_CURRENT_USER';
export const GET_RELATED_FORMS = 'app/document/form/GET_RELATED_FORMS';
export const GET_RELATED_FORMS_RETURN = 'app/document/form/GET_RELATED_FORMS_RETURN';
export const GET_RELATED_DOCUMENTS = 'app/document/form/GET_RELATED_DOCUMENTS';
export const GET_RELATED_DOCUMENTS_RETURN = 'app/document/form/GET_RELATED_DOCUMENTS_RETURN';
export const GET_RELATED_DATATABLE = 'app/document/form/GET_RELATED_DATATABLE';
export const GET_RELATED_DATATABLE_RETURN = 'app/document/form/GET_RELATED_DATATABLE_RETURN';
export const RESET_RELATED_FORMS = 'app/document/form/RESET_RELATED_FORMS';
export const GET_FORM_FIELD_SHORTCUT = 'app/document/form/GET_FORM_FIELD_SHORTCUT';
export const GET_FORM_FIELD_RETURN_SHORTCUT = 'app/document/form/GET_FORM_FIELD_RETURN_SHORTCUT';
export const GET_FORM_SHORTCUT = 'app/document/form/GET_FORM_SHORTCUT';
export const GET_FORM_SHORTCUT_RETURN = 'app/document/form/GET_FORM_SHORTCUT_RETURN';
export const GET_DOCUMENT_ACTION_SHORTCUT = 'app/document/form/GET_DOCUMENT_ACTION_SHORTCUT';
export const GET_DOCUMENT_ACTION_SHORTCUT_RETURN = 'app/document/form/GET_DOCUMENT_ACTION_SHORTCUT_RETURN';
export const GET_INDIVIDUAL_USERS_LIST_SHORTCUT = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_SHORTCUT';
export const GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN';
export const GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR';
export const GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET = 'app/document/form/GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET';
export const SAVE_DOCUMENT_SHORTCUT = 'app/document/form/SAVE_DOCUMENT_SHORTCUT';
export const SAVE_DOCUMENT_SHORTCUT_RETURN = 'app/document/form/SAVE_DOCUMENT_SHORTCUT_RETURN';
export const SAVE_DOCUMENT_SHORTCUT_ERROR = 'app/document/form/SAVE_DOCUMENT_SHORTCUT_ERROR';
export const CLEAR_SAVE_DOCUMENT_SUCCESS_SHORTCUT = 'app/document/form/CLEAR_SAVE_DOCUMENT_SUCCESS_SHORTCUT';
export const CLEAR_SAVE_DOCUMENT_ERROR_SHORTCUT = 'app/document/form/CLEAR_SAVE_DOCUMENT_ERROR_SHORTCUT';
export const RESET_REFERENCE_FORM_FIELD = 'app/document/form/RESET_REFERENCE_FORM_FIELD';
export const RESET_REFERENCE_FORM_FIELD_RETURN = 'app/document/form/RESET_REFERENCE_FORM_FIELD_RETURN';
export const UPDATE_COMMENT_UNREAD_COUNT = 'app/document/form/UPDATE_COMMENT_UNREAD_COUNT';
export const MARK_COMMENT = 'app/document/form/MARK_COMMENT';
export const MARK_COMMENT_RETURN = 'app/document/form/MARK_COMMENT_RETURN';
export const GET_AUTOMATION_CONFIG = 'app/document/form/GET_AUTOMATION_CONFIG';
export const GET_AUTOMATION_CONFIG_RETURN = 'app/document/form/GET_AUTOMATION_CONFIG_RETURN';
export const GET_AUTOMATION_CONFIG_SHORTCUT = 'app/document/form/GET_AUTOMATION_CONFIG_SHORTCUT';
export const GET_AUTOMATION_CONFIG_SHORTCUT_RETURN = 'app/document/form/GET_AUTOMATION_CONFIG_SHORTCUT_RETURN';
export const UPDATE_DOCUMENT_ACTION = 'app/document/form/UPDATE_DOCUMENT_ACTION';
export const UPDATE_DOCUMENT_ACTION_SHORTCUT = 'app/document/form/UPDATE_DOCUMENT_ACTION_SHORTCUT';
export const RESET_FORM_FIELDS_AUTOPOPULATE = 'app/document/form/RESET_FORM_FIELDS_AUTOPOPULATE';
export const REFRESH_FORM = 'app/document/form/REFRESH_FORM';
export const REFRESH_FORM_SHORTCUT = 'app/document/form/REFRESH_FORM_SHORTCUT';
export const CLEAR_COMMENT_LIST = 'app/document/CLEAR_COMMENT_LIST';
export const GET_FORM_ERROR = 'app/document/form/GET_FORM_ERROR';

export const documentFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_DOCUMENT_SUCCESS_SHORTCUT,
    type: 'success',
    reducerContainer: 'documentForm',
    reducerItem: 'saveDocumentShortcutMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_DOCUMENT_ERROR,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'saveDocumentErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: RESET_SAVING_WITH_NO_CHANGES_MESSAGE,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'savingWihoutChangesMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: RESET_DELETE_DOCUMENT_ERROR_MESSAGE,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'deleteDocumentErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_DOCUMENT_ACTION_MESSAGE,
    type: 'success',
    reducerContainer: 'endUser',
    reducerItem: 'documentActionMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_FORM_DOCUMENTS_FIELD_ERROR,
    type: 'negative',
    reducerContainer: 'reportReducer',
    reducerItem: 'formDocumentsFieldError',
    hasCloseIcon: true
  },
]

export const documentFormShortcutActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_DOCUMENT_ERROR_SHORTCUT,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'saveDocumentShortcutErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: RESET_SAVING_WITH_NO_CHANGES_MESSAGE,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'savingWihoutChangesMessage',
    hasCloseIcon: true
  },
]

export const supportedFileFormats: { [file: string]: string } = {
  'formats': 'jpg|gif|png|jpeg|pdf|txt|doc|docx|xls|xlsx|ppt|pptx|'
}

export const fileOfficeBlackIcons: { [type: string]: { icon: NodeRequire, iconStyle: CSSProperties } } = {
  'pptBlack': {
    icon: icons.other.pptBlack,
    iconStyle: {
      color: '#dd5b26',
      width: 30,
      height: 25
    }
  },
  'pptxBlack': {
    icon: icons.other.pptBlack,
    iconStyle: {
      color: '#dd5b26',
      width: 30,
      height: 25
    }
  },
  'docBlack': {
    icon: icons.other.wordBlack,
    iconStyle: {
      color: '#2b5493',
      width: 30,
      height: 25
    }
  },
  'docxBlack': {
    icon: icons.other.wordBlack,
    iconStyle: {
      color: '#2b5493',
      width: 30,
      height: 25
    }
  },
  'xlsBlack': {
    icon: icons.other.excelBlack,
    iconStyle: {
      color: '#307044',
      width: 30,
      height: 25
    }
  },
  'xlsxBlack': {
    icon: icons.other.excelBlack,
    iconStyle: {
      color: '#307044',
      width: 30,
      height: 25
    }
  },
}

export const fileIcons: { [type: string]: { icon: NodeRequire, iconStyle: CSSProperties } } = {
  'pdf': {
    icon: icons.other.pdf,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'txt': {
    icon: icons.other.txt,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'text': {
    icon: icons.other.txt,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'jpg': {
    icon: icons.other.jpg,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'jpeg': {
    icon: icons.other.jpg,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'tif': {
    icon: icons.other.tiff,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'tiff': {
    icon: icons.other.tiff,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'png': {
    icon: icons.other.png,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'gif': {
    icon: icons.other.gif,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  },
  'doc': {
    icon: icons.other.word,
    iconStyle: {
      color: '#2b5493'
    }
  },
  'docx': {
    icon: icons.other.word,
    iconStyle: {
      color: '#2b5493'
    }
  },
  'ppt': {
    icon: icons.other.ppt,
    iconStyle: {
      color: '#dd5b26'
    }
  },
  'pptx': {
    icon: icons.other.ppt,
    iconStyle: {
      color: '#dd5b26'
    }
  },
  'xls': {
    icon: icons.other.excel,
    iconStyle: {
      color: '#307044'
    }
  },
  'xlsx': {
    icon: icons.other.excel,
    iconStyle: {
      color: '#307044'
    }
  },
  'unknown': {
    icon: icons.other.unknown,
    iconStyle: {
      filter: getFilters('#737373').filter,
      color: '#2e302f'
    }
  }
}

export const commentFilterTypeList = [
  {
    id: 'user',
    iconDefault: icons.other.comments,
    iconSelected: icons.white.chatBubble
  },
  {
    id: 'system',
    iconDefault: icons.other.bot,
    iconSelected: icons.white.bot
  },
  {
    id: 'attachments',
    iconDefault: icons.other.attachment,
    iconSelected: icons.white.clip
  }
];
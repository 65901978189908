import { useEffect, useState } from 'react';
import { SanitizeSlas, prepareDeadlineField } from '../../../../../../../../../common/utils/sanitize-slas';
import { IDaysCheckbox } from '../interface/days-checkbox';
import { ISlasAndStatuses, ISlaStatusesTaskAssigned } from '../interface/slaAndStatuses';
import { IProcessLaunch, IStatusTaskList, ITaskAssigned, IWorkingHours } from '../interface/status-task-list';

interface IProps {
  slaStatusList: ISlasAndStatuses;
  daysOfWeekList: IDaysCheckbox[];
  formId: string;
}

export const useSetSLAStatusListState = (props: IProps) => {
  const { slaStatusList, daysOfWeekList, formId } = { ...props };

  const [statusTaskList, setStatusTaskList] = useState({} as IStatusTaskList);
  const [taskAssigned, setTaskAssigned] = useState([] as ITaskAssigned[]);
  const [processLaunch, setProcessLaunch] = useState({} as IProcessLaunch);
  const [selectedDays, setSelectedDays] = useState([] as IDaysCheckbox[]);
  const [workingHours, setWorkingHours] = useState({} as IWorkingHours);
  const [is24Hour, setIs24Hour] = useState(true);
  const [isAllDaysChecked, setIsAllDaysChecked] = useState(false);

  useEffect(() => {
    const statusTaskListData = { ...statusTaskList } as IStatusTaskList;
    let daysList = [...selectedDays] as IDaysCheckbox[];
    let allEquals = daysList.every(days => days.isChecked === true);
    statusTaskListData.taskAssigned = taskAssigned;
    statusTaskListData.processLaunch = processLaunch;
    statusTaskListData.workingHours = workingHours;
    statusTaskListData.daysToCount = selectedDays;
    setStatusTaskList(statusTaskListData);
    setIsAllDaysChecked(allEquals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskAssigned, processLaunch, workingHours, selectedDays])

  useEffect(() => {
    let is24HourChecked = false;
    let isTaskAssignedConfigured = false;
    let isProcessLaunchConfigured = false;
    let statusTaskListData = {} as IStatusTaskList;
    let taskAssignedData = [] as ITaskAssigned[];
    let daysToCountData = [] as IDaysCheckbox[];
    let workingHoursData = {} as IWorkingHours;
    let processLaunchData = {} as IProcessLaunch;
    let daysOfWeek = Object.assign([], daysOfWeekList);

    if (slaStatusList && Object.keys(slaStatusList).length > 0) {
      const { interval, warningInterval, deadline } = { ...slaStatusList.processLaunch };
      let unit = interval ? interval.unit : '';
      if (!interval && warningInterval && warningInterval.unit) {
        unit = warningInterval.unit;
      }
      processLaunchData = {
        count: interval ? interval.value : 0,
        unit: unit,
        warningInterval: warningInterval ? warningInterval.value : 0,
        timeTrigger: !!deadline ? 'deadline' : 'task-assigned',
        timeTriggerField: !!deadline ? prepareDeadlineField(deadline, formId) : undefined
      } as IProcessLaunch

      if (slaStatusList.processLaunch) {
        isProcessLaunchConfigured = true;
      }

      let daysOfWeekNewList = SanitizeSlas.incomingWorkTimeInterval(slaStatusList.workTimeIntervals, daysOfWeek);
      if (daysOfWeekNewList) {
        daysToCountData = daysOfWeekNewList.workTimeInterval;
        workingHoursData = daysOfWeekNewList.workingHours;
        is24HourChecked = daysOfWeekNewList.is24HourChecked;
      }

      slaStatusList.taskAssigned.forEach((status: ISlaStatusesTaskAssigned) => {
        let unit = status.interval ? status.interval.unit : '';
        if (!status.interval && status.warningInterval && status.warningInterval.unit) {
          unit = status.warningInterval.unit;
        }
        taskAssignedData.push({
          statusId: status.statusId,
          statusName: status.name,
          initial: status.initial,
          final: status.final,
          count: status.interval ? status.interval.value : 0,
          unit: unit,
          warningInterval: status.warningInterval ? status.warningInterval.value : 0,
          timeTrigger: !!status.deadline ? 'deadline' : 'task-assigned',
          timeTriggerField: !!status.deadline ? prepareDeadlineField(status.deadline, formId) : undefined
        });
      });

      const indexInterval = slaStatusList.taskAssigned.findIndex((status: ISlaStatusesTaskAssigned) => status.interval || status.deadline);
      if (indexInterval !== -1) {
        isTaskAssignedConfigured = true;
      }

      statusTaskListData = {
        isProcessLaunch: isProcessLaunchConfigured,
        isTaskAssigned: isTaskAssignedConfigured,
        taskAssigned: taskAssignedData,
        processLaunch: processLaunchData,
        daysToCount: daysToCountData,
        workingHours: workingHoursData,
        includeDraft: slaStatusList.includeDraft ? true : false
      }

      setStatusTaskList(statusTaskListData);
      setTaskAssigned(taskAssignedData);
      setProcessLaunch(processLaunchData);
      setSelectedDays(daysToCountData);
      setWorkingHours(workingHoursData);
      setIs24Hour(is24HourChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slaStatusList]);

  return {
    ...props, statusTaskList, setStatusTaskList,
    taskAssigned, setTaskAssigned, processLaunch, setProcessLaunch,
    selectedDays, setSelectedDays, workingHours, setWorkingHours,
    is24Hour, setIs24Hour, isAllDaysChecked, setIsAllDaysChecked
  }
}
import { IFormPermissionSection, ISection, IStatusAction, ITablePermission } from '../../component/admin-module/module/business-process/module/permission/interface/section'
import {
  IStatusFieldPermission,
  IPermissionFieldFilterConfig,
  IPermissionFieldCondition,
  AccessLevel,
  IFormFieldPermission,
  IConsolidateTableConfig
} from '../../component/admin-module/module/business-process/module/permission/interface/field-permission';
import {
  IBusinessRules,
  IRulesFieldCondition,
  IRulesFields,
  IRulesPermission,
  IRulesCondition,
  IRules,
  IRulesSectionFields,
  IRulesSection,
  IRulesAction
} from '../../component/admin-module/module/business-process/module/permission/interface/rules';
import {
  IAccessLevelCondition,
  IAccessLevelConfig,
  IApiPermission,
  IApiPermissionObject,
  IApiSection
} from '../../component/admin-module/module/business-process/module/permission/interface/api-permission';
import { ICustomFieldDetail } from '../custom-field-config/interface/custom-field-detail';
import { ActionPermission } from '../../component/document-module/module/form/automation/components/action/set-action-permission/set-action-permission.action.config';

export class SanitizeFormPermission {
  static structureIncoming = (businessRules: IBusinessRules[]): IApiPermissionObject[] => {
    if (businessRules && businessRules.length > 0) {
      return businessRules.map(rules => {
        let section = [] as IApiSection[];
        let actions = [] as IApiPermission[];

        if (rules.fields && rules.fields.sections) {
          section = rules.fields.sections.map(sections => {
            const fields = sections.fields && sections.fields.map(fields => {

              let data = {} as IApiPermission;
              if (fields.rules) {
                data = {
                  id: fields.id,
                  accessLevel: SanitizeFormPermission.structureRulesToAccessLevel(fields.rules)
                }
              }
              if (fields.columns || fields.consolidateTable) {
                const columns = fields.columns ? fields.columns.map(col => {
                  let dataCol = {} as IApiPermission;
                  if (col.rules) {
                    dataCol = {
                      id: col.id,
                      accessLevel: SanitizeFormPermission.structureRulesToAccessLevel(col.rules)
                    }
                  }
                  return dataCol;
                }) : [];
                if (fields.consolidateTable) {
                  fields.consolidateTable = SanitizeFormPermission.structureConsolidateTable(fields.consolidateTable, 'incoming');
                }
                data = {
                  id: fields.id,
                  accessLevel: 'editable',
                  columnPermissions: columns && columns.length > 0 ? columns : undefined,
                  consolidateTable: fields.consolidateTable ? fields.consolidateTable : undefined
                }
              }
              return data;
            });
            return {
              id: sections.id,
              fields,
              allFieldsAccessLevel: sections.rules ?
                SanitizeFormPermission.structureRulesToAccessLevel(sections.rules)
                : undefined
            } as IApiSection
          });
        }

        if (rules.actions) {
          actions = rules.actions.map(action => {
            let data = {} as IApiPermission;
            if (action.rules) {
              data = {
                id: action.id,
                accessLevel: SanitizeFormPermission.structureRulesToAccessLevel(action.rules, 'actions')
              }
            }
            return data;
          });
        }
        return {
          statusId: rules.statusId,
          referToStatusId: rules.fields && rules.fields.referToStatus ? rules.fields.referToStatus : undefined,
          sections: section,
          actions: actions
        } as IApiPermissionObject
      });
    }
    return []
  }

  static structureOutgoing = (permissionList: IFormPermissionSection[]): IBusinessRules[] => {
    return permissionList.map((status: IFormPermissionSection) => {
      let fieldsRule: IRulesFields = {};

      const sections: IRulesSection[] = status.sections.map((section: ISection) => {
        let sectionRules = {} as IRules;
        if (section.accessLevelPerStatus && section.accessLevelPerStatus !== undefined) {
          sectionRules = SanitizeFormPermission.structureAcessLevelToRules(section.accessLevelPerStatus);
        }
        return {
          id: section.id,
          rules: Object.keys(sectionRules).length > 0 ? sectionRules : undefined,
          fields: Object.keys(sectionRules).length > 0 ? [] : SanitizeFormPermission.structureFieldsPermissionToRules(
            section.permissions,
            section.fields,
            status.table
          )
        }
      });
      fieldsRule = {
        referToStatus: status.referToStatusId || undefined,
        sections: sections
      }
      return {
        statusId: status.statusId,
        fields: fieldsRule,
        actions: SanitizeFormPermission.structureActionToRules(status.actions)
      }
    });
  }

  static structureFieldsPermissionToRules = (permissions: IStatusFieldPermission[],
    fieldList: ICustomFieldDetail[], tablePermission?: ITablePermission[]): IRulesSectionFields[] => {
    return permissions.map((permission: IStatusFieldPermission) => {
      const fieldData = fieldList.find(e => e.id === permission.fieldId);
      const config = permission.config as IPermissionFieldFilterConfig;
      const accessLevel = permission.accessLevel as string;

      let consolidateTable = undefined as IConsolidateTableConfig | undefined
      let columnFields: IRulesSectionFields[] | null = null;
      let fieldRules = {} as IRules;

      if (config && Object.keys(config).length > 0 && typeof config !== 'undefined') {
        fieldRules = SanitizeFormPermission.structureAcessLevelToRules(config);
      } else {
        fieldRules = SanitizeFormPermission.structureAcessLevelToRules(accessLevel);
      }

      if (fieldData && fieldData.type === 'table' && tablePermission) {
        const tableData = tablePermission.find(e => e.id === fieldData.id);
        if (tableData) {
          const noChange = tableData.permissions.every(field => !field.config && field.accessLevel === AccessLevel.Editable);
          if (!noChange && !tableData.accessLevelPerTable) {
            columnFields = SanitizeFormPermission.structureFieldsPermissionToRules(tableData.permissions, tableData.fields);
          }
          if (tableData.consolidateTable) {
            consolidateTable = SanitizeFormPermission.structureConsolidateTable(tableData.consolidateTable, 'outgoing');
          }
        }
      }

      return {
        id: permission.fieldId,
        rules: fieldRules,
        columns: columnFields && columnFields.length > 0 ? columnFields : undefined,
        consolidateTable: consolidateTable ? consolidateTable : undefined
      } as IRulesSectionFields
    });
  }

  static structureConditionToRules = (config: IPermissionFieldCondition[]): IRulesFieldCondition[] => {
    return config.map(condition => {
      let value = condition.compareValue;
      let keyPath = condition.fieldId;
      if (condition.compareType === 'field' && condition.compareField.fieldId) {
        value = condition.compareField.fieldId;
        if (!condition.compareField.fieldId.startsWith('fields.')) {
          value = `fields.${condition.compareField.fieldId}`;
        }
      }
      if (keyPath && !keyPath.startsWith('fields.')) {
        keyPath = `fields.${keyPath}`;
      }
      return {
        keyPath: keyPath,
        operator: condition.operator,
        valueType: condition.compareType,
        value: value
      } as IRulesFieldCondition;
    });
  }

  static structureAcessLevelToRules = (accessLevel: string | IPermissionFieldFilterConfig, type?: string): IRules => {
    let onTrueConfig = {} as IRulesPermission;
    let onFalseConfig = {} as IRulesPermission;
    let whenConfig = {} as IRulesCondition;
    let disabledMessage: string | undefined = undefined;

    if (typeof accessLevel === 'object') {
      const { condition, conditionRule, joinCondition } = { ...accessLevel };
      const defaultPermission = type === 'actions' ? ActionPermission.Enabled : AccessLevel.Editable;
      whenConfig = {
        joint: joinCondition,
        conditions: SanitizeFormPermission.structureConditionToRules(condition)
      };
      onTrueConfig = {
        ...conditionRule.onTrue,
        setPermission: conditionRule.onTrue.setPermission || defaultPermission
      };
      onFalseConfig = {
        ...conditionRule.onFalse,
        setPermission: conditionRule.onFalse.setPermission || defaultPermission
      };
      disabledMessage = conditionRule.disabledMessage;
    } else {
      if (type === 'actions') {
        const actionAccessLevel = accessLevel ? accessLevel : ActionPermission.Enabled;
        if (actionAccessLevel !== ActionPermission.Enabled) {
          onTrueConfig = { setPermission: actionAccessLevel };
        }
      } else {
        const fieldAccessLevel = accessLevel ? accessLevel : AccessLevel.Editable;
        onTrueConfig = { setPermission: fieldAccessLevel };
      }
    }

    return {
      onTrue: Object.keys(onTrueConfig).length > 0 ? onTrueConfig : undefined,
      when: Object.keys(whenConfig).length > 0 ? whenConfig : undefined,
      onFalse: Object.keys(onFalseConfig).length > 0 ? onFalseConfig : undefined,
      disabledMessage,
    }
  }

  static structureRulesToCondition = (when: IRulesCondition): IAccessLevelConfig => {
    let accessLevelConfig = {} as IAccessLevelConfig;
    if (when.joint) {
      accessLevelConfig.joint = when.joint;
    }
    accessLevelConfig.conditions = when.conditions.map(condition => {
      if (condition.field) condition.keyPath = condition.field;
      if (condition.keyPath && condition.keyPath.startsWith('fields.')) {
        condition.keyPath = condition.keyPath.split('fields.')[1];
      }
      if (condition.value && typeof condition.value === 'string' && condition.value.startsWith('fields.')) {
        condition.value = condition.value.split('fields.')[1];
      }
      return condition as IAccessLevelCondition;
    });
    return accessLevelConfig;
  }

  static structureRulesToAccessLevel = (rules: IRules, type?: string) => {
    let accessLevel: string | IAccessLevelConfig = type === 'actions' ? ActionPermission.Enabled : AccessLevel.Editable;
    const { when, onFalse, onTrue, disabledMessage } = { ...rules };
    if (when && onFalse && onTrue) {
      accessLevel = {
        ...SanitizeFormPermission.structureRulesToCondition(when),
        conditionRule: {
          disabledMessage,
          onFalse: SanitizeFormPermission.structureRulesPermission(onFalse),
          onTrue: SanitizeFormPermission.structureRulesPermission(onTrue),
        }
      }
    } else {
      if (onTrue) {
        accessLevel = onTrue.setPermission;
      }
    }
    return accessLevel;
  }

  static structureActionToRules = (actionsList: IStatusAction[]): IRulesAction[] => {
    return actionsList.map(actions => {
      let fieldRules = {} as IRules;
      const { accessLevel, config } = { ...actions.permissions };
      if (config && Object.keys(config).length > 0 && typeof config !== 'undefined') {
        fieldRules = SanitizeFormPermission.structureAcessLevelToRules(config, 'actions');
      } else {
        fieldRules = SanitizeFormPermission.structureAcessLevelToRules(accessLevel as string, 'actions');
      }
      return {
        id: actions.id,
        rules: fieldRules
      } as IRulesAction;
    })
  }

  static structureFormFieldPermission = (businessRules: IBusinessRules[]) => {
    const permissionList = SanitizeFormPermission.structureIncoming(businessRules);
    const formFieldPermission = [] as IFormFieldPermission[];
    permissionList && permissionList.forEach(permission => {
      const permissions = [] as IStatusFieldPermission[];
      permission.sections && permission.sections.forEach(section => {
        if (section.fields && section.fields.length) {
          section.fields.forEach(field => {
            let columnPermissions = [] as IStatusFieldPermission[];
            if (field.columnPermissions) {
              columnPermissions = field.columnPermissions.map(column => {
                return {
                  accessLevel: column.accessLevel,
                  fieldId: column.accessLevel
                } as any
              })
            }
            permissions.push({
              fieldId: field.id,
              accessLevel: field.accessLevel,
              columnPermissions: columnPermissions,
            });
          });
        }
      })
      formFieldPermission.push({
        permissions: permissions,
        statusId: permission.statusId
      })
    })
    return formFieldPermission;
  }

  static structureConsolidateTable = (consolidateTable: IConsolidateTableConfig, type: 'incoming' | 'outgoing') => {
    let referenceKeyPath = consolidateTable.referenceKeyPath;
    if (type === 'outgoing') {
      if (!referenceKeyPath.startsWith('fields.')) {
        referenceKeyPath = `fields.${referenceKeyPath}`;
      }
    } else {
      if (referenceKeyPath.startsWith('fields.')) {
        referenceKeyPath = referenceKeyPath.replace(/fields./g, '');
      }
    }
    consolidateTable.referenceKeyPath = referenceKeyPath;
    return consolidateTable;
  }

  static structureRulesPermission = (rules: IRulesPermission) => {
    if (rules.setValue) {
      const { compose } = rules.setValue;
      if (compose && compose.hasOwnProperty('fieldIds')) {
        delete compose.fieldIds;
      }
    }
    return rules;
  }
}
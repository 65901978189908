import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
// import EndUserHeader from './document-header';
import AdminHeader from './admin-header';
import { IRootState } from '../../../../reducers';
import AllowedUserListActionControl from '../../../../common/utils/user-list-action-control';
import { Route, useLocation } from 'react-router-dom';
// import BPMManagerHeader from './bpm-manager-header';
import { IHasPageChangeRef } from '../../interface/has-page-change';
import PortalHeader from './portal-header';
import { UserProfileStaticKey } from '../../../auth/interface/user-profile';

interface IheaderComponentProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const HeaderComponent: React.FC<IheaderComponentProps> = (props) => {
  let location = useLocation();
  const userLoggedIn = useSelector((state: IRootState) => state.auth.userLoggedIn);

  let validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);

  return (
    <Fragment>
      {!validateUserProfile.hasOnlyProfile(UserProfileStaticKey.EndUser) &&
        <>
          {location.pathname.startsWith('/attachment') === false &&
            <>
              <Route exact path='/'>
                <AdminHeader hasPageChangeRef={props.hasPageChangeRef} />
              </Route>
              <Route path='/admin'>
                <AdminHeader hasPageChangeRef={props.hasPageChangeRef} />
              </Route>
              {/* <Route path='/bpm-manager'>
                <BPMManagerHeader />
              </Route> */}
              <Route path='/portal'>
                <PortalHeader hasPageChangeRef={props.hasPageChangeRef} />
              </Route>
              {/* <Route path="/settings">
                <EndUserHeader hasPageChangeRef={props.hasPageChangeRef} />
              </Route> */}
            </>
          }
        </>
      }
    </Fragment >
  );
}

export default HeaderComponent;

import React from 'react';
import { FieldElement } from '../component/field-element';
import moment from 'moment';
import { IFieldData } from './interface/field-data';

interface IDateField {
  data: IFieldData;
  detail: FieldElement;
}

const DateCustomField: React.FC<IDateField> = (props) => {
  const date = props.data.fields ? props.data.fields[props.detail.id] : '';
  let formattedDate: string = '';

  if (date) {
    formattedDate = moment(props.data.fields ? props.data.fields[props.detail.id] : '', 'YYYY-MM-DD').format('LL')
  }

  if (date && typeof date === 'object') {
    return date.length > 0 && date.map((dates: string, index: number) => {
      return (<div key={`column-${props.detail.id}-${props.data.id}-${index}`}>
        <div>
          {dates !== null ?
            <React.Fragment>
              {moment(dates, 'YYYY-MM-DD').format('LL')}
              {(date.length > 1 && index < date.length - 1) ? ',' : ''}
            </React.Fragment>
            : ''
          }
        </div>
      </div>)
    })
  }

  return (<div>
    <div key={`column-${props.detail.id}-${props.data.id}`}>
      <span id={`value-${props.detail.id}-${props.data.id}`}>{formattedDate || ''}</span>
    </div>
  </div>)
}

export default DateCustomField;
import { useEffect, useState } from 'react';
import { IAutomationChildComponent, IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
  selectedChildComponent?: IAutomationChildComponent | undefined;
}

export const useSetIfElseBlockCondition = (props: IProps) => {
  const { selectedComponent, selectedChildComponent } = { ...props };

  const [conditionList, setConditionList] = useState<IAutomationComponent[]>([]);

  useEffect(() => {
    if (selectedComponent && !selectedChildComponent) {
      const currentConditionList = selectedComponent.config.conditionList || [];
      setConditionList(currentConditionList);
    }
    if (selectedComponent && selectedChildComponent) {
      const component = selectedChildComponent.component;
      const rowIndex = selectedChildComponent.rowIndex;
      const elseRowIndex = selectedChildComponent.elseRowIndex;

      if (!component && !rowIndex && elseRowIndex !== undefined) {
        const selectedElse = selectedComponent.config.elseConditionList[elseRowIndex];
        if (selectedElse.conditionList && selectedElse.conditionList.length > 0) {
          const currentConditionList = selectedElse.conditionList || [];
          setConditionList(currentConditionList);
        } else {
          const currentConditionList = selectedElse.conditionList || [];
          setConditionList(currentConditionList);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent, selectedChildComponent])

  return { ...props, conditionList, setConditionList }
}
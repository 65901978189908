import { useEffect } from 'react';
import { getStatusListAction } from '../action';
import { getFormFieldListAction } from '../../document-list/action';
import { getBusinessProcessListAction } from '../../../../form/action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetSLAData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (formId && company) {
      dispatch(getStatusListAction({ companyId: company, formId: formId }));
      dispatch(getBusinessProcessListAction({ company: company }));
      dispatch(getFormFieldListAction({ company: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, company]);
}
import { IFormBuilderSection } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';

class SortSectionField {

  name = 'SortFieldSection';

  updateSection = (sectionList: IFormBuilderSection[], field: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    let newSectionList = [...sectionList];
    return newSectionList.map((section: IFormBuilderSection) => {
      if (section.sectionId === sectionUpdateConfig.sectionId
        && sectionUpdateConfig.dragItemRowIndex !== undefined
        && sectionUpdateConfig.dragItemRow !== undefined
        && sectionUpdateConfig.dropAreaRowIndex !== undefined
      ) {
        section.fields.splice(sectionUpdateConfig.dragItemRowIndex, 1);
        section.fields.splice(sectionUpdateConfig.dropAreaRowIndex, 0, sectionUpdateConfig.dragItemRow);
      }
      return section;
    });
  }
}

export default SortSectionField;
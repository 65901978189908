import React, { useEffect, useState } from 'react';
import { TConfigType, TRulesPermission } from '..';
import { IFieldPermissionSettings } from '../../..';
import { IRulesPermission } from '../../../../interface/rules';

interface IClearConfigProps extends IFieldPermissionSettings {
  boolSelected: boolean;
  rules: IRulesPermission;
  updateConfig(configType: TConfigType, configOption: TRulesPermission, value: any): void;
}
class ClearConfig {

  name = 'shouldClear'

  render: React.FC<IClearConfigProps> = (props) => {

    const [shouldClear, setShouldClear] = useState(false);

    const toggleClearSwitch = () => {
      setShouldClear(!shouldClear);
      props.updateConfig(props.boolSelected ? 'onTrue' : 'onFalse', 'shouldClear', !shouldClear);
    }

    useEffect(() => {
      if (props.rules) {
        setShouldClear(props.rules.shouldClear || false);
      }
    }, [props.rules])

    return <div className="ui fitted toggle checkbox clear-switch" onClick={toggleClearSwitch}>
      <input className="hidden" onChange={() => { }} type="checkbox"
        checked={shouldClear} value="" />
      <label>Clear</label>
    </div>
  }
}

export default ClearConfig;
import React, { useState, useEffect, CSSProperties } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { IField, IFieldAccessType, IInputConfig } from '../../../../../../component/admin-module/module/users/interface/field';
import { addThousandSeparator } from '../../../../../utils/add-thousand-separator';
import { setFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { FieldProperties } from '../../../service/field-properties.service';
import { NumberProps } from '../../../service/type/number';
import { validateInput } from './utils/validate-input';
import { useInitialLoad } from './hook/use-initial-load';
import { renderIcon } from './utils/render-icon';
import { renderError } from './utils/render-error';
import { IConfigName } from '../../../../type/interface/field-type-object';

const NumberType: React.FC<AcceptedProps> = (props) => {
  const uniqId = uuid();
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher] = useState('');
  const [validateTriggered, setValidateTriggered] = useState(false)

  const elementProperties = new FieldProperties(name, props.forwardedRef, new NumberProps());

  const setProperty = () => {
    elementProperties.setCurrent({ ...elementProperties.current, value: addThousandSeparator(elementProperties.current.value, props.inputConfig as IInputConfig) });
  }

  const triggerAutomationService = (value: any) => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value)
      }
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (props.tableColumnRowIndex !== undefined && props.tableColumnDisplayed !== undefined) {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed
        });
      }
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = typeof props.defaultValue !== 'undefined' && props.defaultValue !== null ? JSON.stringify(props.defaultValue) : '';
    if (defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = elementProperties.isSet() && defaultValue;
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValueString });
    }
  }

  const fieldStyle = (): CSSProperties => {
    let style = {} as CSSProperties;
    if (props.hasInlineStyle) {
      const headerElement = document.getElementById(`header-${name}`);
      if (headerElement) {
        let { width } = headerElement.getBoundingClientRect();
        width = width < 72 ? 72 : width;
        style = { maxWidth: `${width}px` }
      } else {
        style = { maxWidth: `${72}px` }
      }
    }
    return style;
  }

  const onBlur = (event: any) => {
    const id = props.tableColumnRowIndex ? `${uniqId}-number-${name}-${props.tableColumnRowIndex}` : `${uniqId}-number-${name}`;
    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    elementProperties.setCurrent({ ...elementProperties.current, value: event.target.value })

    validateInput({
      setRefresher: setRefresher,
      name: name,
      validationSchemaProps: {
        ...props.validationSchema,
        isRequired: props.accessType === IFieldAccessType.Required || false,
      },
      multiline: false,
      forwardedRef: props.forwardedRef,
      inputConfig: props.inputConfig,
      setValidateTriggered: setValidateTriggered,
      setProperty: setProperty,
      labelProps: props.label,
      requiredProps: props.accessType === IFieldAccessType.Required || false,
      triggerFilter: props.triggerFilter,
      triggerFieldReload: props.triggerFieldReload,
      reloadOnChange: props.reloadOnChange,
      accessType: props.accessType,
      valueFieldClass: elementProperties,
      tableId: props.tableId
    })

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    triggerAutomationService(event.target.value);
  }

  const setRef = (curRef: any) => {
    if (curRef) {
      const id = props.tableColumnRowIndex ? `${uniqId}-number-${name}-${props.tableColumnRowIndex}` : `${uniqId}-number-${name}`;
      if (props.isFocus && refresher === '') {
        curRef.focus();
      }
      if (focusedElement && focusedElement === id) {
        focusSelectedElement(focusedElement);
      }
      curRef.fieldType = 'number';
      if (elementProperties.isSet()) {
        setProperty();
        curRef.value = elementProperties.current.value;
        curRef.error = elementProperties.getError();
      }
      elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'number' })
      return curRef;
    }
  }

  const onClick = () => {
    const id = props.tableColumnRowIndex ? `${uniqId}-number-${name}-${props.tableColumnRowIndex}` : `${uniqId}-number-${name}`;
    focusInput(id);
  }

  const onChange = (event: any) => {
    elementProperties.setCurrent({ ...elementProperties.current, value: event.target.value })
    if (props.triggerFilter) {
      props.triggerFilter('')
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.handleOnKeyPress) props.handleOnKeyPress(e);
  }

  const onFocus = () => {
    let value = elementProperties.current.value;
    if (typeof value === 'string') {
      value = value.replace(/,/g, '')
    }
    elementProperties.setCurrent({ ...elementProperties.current, value: value })
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement) {
      validateInput({
        setRefresher: setRefresher,
        name: name,
        validationSchemaProps: props.validationSchema,
        multiline: false,
        forwardedRef: props.forwardedRef,
        inputConfig: props.inputConfig,
        setValidateTriggered: setValidateTriggered,
        setProperty: setProperty,
        labelProps: props.label,
        requiredProps: props.accessType === IFieldAccessType.Required || false,
        triggerFilter: props.triggerFilter,
        accessType: props.accessType,
        valueFieldClass: elementProperties,
        tableId: props.tableId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateTriggered,
    props.defaultValueElement,
    props.label,
    props.validationSchema,
    props.forwardedRef,
    name,
    props.inputConfig,
    props.required
  ])

  useInitialLoad({
    isAddRecord: props.isAddRecord,
    setDefaultValue,
    setRefresher,
    name,
    validationSchema: props.validationSchema,
    multiline: false,
    forwardedRef: props.forwardedRef,
    inputConfig: props.inputConfig,
    setProperty: setProperty,
    label: props.label,
    accessType: props.accessType,
    elementProperties
  })

  useEffect(() => {
    if (props.isClearValue && elementProperties.current) {
      elementProperties.setCurrent({ ...elementProperties.current, value: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  useEffect(() => {
    const configValue = props.inputConfig.config.value;
    if (props.configName === IConfigName.FX) {
      triggerAutomationService(configValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configName, props.inputConfig.config.value]);

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span id={refresher} className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>
            {props.label}
          </label>
          {
            (props.hint && !props.tableId) &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div
          onClick={() => {
            const id = props.tableColumnRowIndex
              ? `${uniqId}-number-${name}-${props.tableColumnRowIndex}`
              : `${uniqId}-number-${name}`;
            focusInput(id);
          }}
          style={fieldStyle()}
          className={`field-input ui input single ${elementStatus({
            forwardedRef: props.forwardedRef,
            name: name,
            multiline: props.multiline || false,
            required: props.accessType === IFieldAccessType.Required,
            fieldValueObject: elementProperties
          })}`}>
          <input type="text"
            id={`${uniqId}-number-${name}${props.tableColumnRowIndex ? `-${props.tableColumnRowIndex}` : ''}`}
            className={props.hasIconInside ? 'with-icon' : ''}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            name={name}
            disabled={props.accessType === IFieldAccessType.Readonly}
            ref={setRef}
            style={{ textAlign: props.inputConfig.config.alignment || 'left' }}
            onClick={onClick}
            placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
          />
          {renderIcon(props.hasIconInside || false, props.inputConfig.config.percent ? true : false)}
        </div>
        {renderError({ elementProperties, showErrorMessage: props.showErrorMessage })}
      </>
    }
  </>
}

export default React.memo(NumberType);

import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFieldConfig } from '../../../users/interface/field';

interface IProps {
  field: ICustomFieldDetail;
}

export const useDataTableFieldConfigData = (props: IProps) => {
  const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
  const [configData, setConfigData] = useState<IFieldConfig>({} as IFieldConfig);

  useEffect(() => {
    let field = { ...props.field };
    const config = { ...field.config } as IFieldConfig;
    setConfigData(config);
    delete field.config;
    setFieldData(field);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.field)]);

  return { fieldData, setFieldData, configData, setConfigData, ...props };
}
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Signature,
  required: false,
  type: 'signature',
};

const value = {
  title: 'Signature',
  icon: {
    black: require('../../icons/black/signature.png'),
    white: require('../../icons/white/signature.png'),
    blue: require('../../icons/blue/signature.png'),
  },
  style: {
    width: '20px',
    height: '20px',
  },
  defaultData,
  defaultConfig: {},
};

export default value;

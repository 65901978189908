import React, { useState, useEffect } from 'react';
import TaskManager from './task-manager';
import TaskAssigned from './list/task-assigned';
import { daysOfWeek, slasActionMessage } from './constant';
import { IDaysCheckbox } from './interface/days-checkbox';
import ProcessLaunch from './list/process-launch';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../../../../../../reducers';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { saveSlasAction, publishSlasAction, setSlasErrorValidationMessage } from './action';
import { IStatusTaskList, ITaskAssigned, IProcessLaunch, IWorkingHours, ITimeTriggerField } from './interface/status-task-list';
import ButtonContainer from '../../../../button-container';
import moment from 'moment';
import { Dimmer, Loader, Table } from 'semantic-ui-react-bpm';
import ActionMessage from '../../../../../../../../common/general/action-message';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { IFormAction } from '../../../workflow/interface/form-action';
import { IReference } from '../bp-relationship/interface/form';
import Axios from 'axios';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { useGetSLAData } from './hooks/use-get-sla-data';
import { useSetSLAStatusListState } from './hooks/use-set-sla-status-list-state';

interface ISLAsContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const SLAsContainer: React.FC<ISLAsContainerProps> = (props) => {
  let daysOfWeekData = Object.assign([], daysOfWeek);
  const match: { params: { formId: string } } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const { company } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const {
    slaStatusList,
    saveRoleType,
    saveSlasLoading,
    publishSlasLoading,
    statusListLoading,
    statusList,
    referenceList
  } = useSelector((state: IRootState) => state.slas);
  const { formList } = useSelector((state: IRootState) => state.businessProcess);
  const { fieldList } = useSelector((state: IRootState) => state.documentList);

  const [daysOfWeekList, setDaysOfWeekList] = useState([] as IDaysCheckbox[]);
  const [referencedBPFields, setReferencedBPFields] = useState([] as ICustomFieldDetail[]);
  const {
    statusTaskList,
    setStatusTaskList,
    taskAssigned,
    setTaskAssigned,
    processLaunch,
    setProcessLaunch,
    selectedDays,
    setSelectedDays,
    workingHours,
    setWorkingHours,
    is24Hour,
    setIs24Hour,
    isAllDaysChecked,
    setIsAllDaysChecked
  } = useSetSLAStatusListState({
    daysOfWeekList,
    slaStatusList,
    formId: match.params.formId
  });

  const initialTimeTrigger: ITimeTriggerField = {
    triggerType: 'value',
    fieldConfig: {
      fieldId: '',
      fromBP: ''
    },
    valueConfig: {
      fieldType: 'date',
      value: ''
    }
  }

  const getFields = async (reference: IReference) => {
    if (company && reference) {
      try {
        let request: any = { data: { data: [] } };
        if (reference.dataType === 'database') {
          request = await Axios.get(`${company}/databases/${reference.id}/fields`, {});
        }
        if (reference.dataType === 'document') {
          request = await Axios.get(`${company}/forms/${reference.id}/fields?draft=true`, {});
        }
        if (request.data.data && request.data.data.length > 0) {
          request.data.data = request.data.data.filter((e: ICustomFieldDetail) => e.type === 'date');
        }
        return request.data.data;
      } catch (_e) {
        return _e;
      }
    }
  }

  const getReferencedBpFields = () => {
    const bpReference = referenceList.filter(ref => ref.dataType === 'document');
    Promise
      .all(bpReference.map(reference => getFields(reference)))
      .then((response: ICustomFieldDetail[][]) => {
        if (response.length > 0) {
          const flatResponse = response.reduce((flatten, array) => [...flatten, ...array])
          setReferencedBPFields(flatResponse);
        }
      });
  }

  const cancelSlasConfiguration = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const updateSelectedSLAs = (type: string, value: boolean) => {
    const statusTaskListData = { ...statusTaskList } as IStatusTaskList;
    if (type === 'task') {
      statusTaskListData.isTaskAssigned = !value;
    } else if (type === 'process') {
      statusTaskListData.isProcessLaunch = !value;
    }
    setStatusTaskList(statusTaskListData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const updateTaskAssigned = (taskAssignedList: ITaskAssigned[], statusId: string, value: any, type: string) => {
    taskAssignedList.forEach((taskAssigned: ITaskAssigned) => {
      if (taskAssigned.statusId === statusId) {
        if (type === 'count') {
          taskAssigned.count = value
        }
        if (type === 'unit') {
          taskAssigned.unit = value
        }
        if (type === 'excelValue') {
          taskAssigned.warningInterval = value;
        }
        if (type === 'timeTrigger') {
          taskAssigned.timeTrigger = value;
          taskAssigned.timeTriggerField = value === 'deadline' ? { ...initialTimeTrigger } : undefined;
        }
        if (type === 'timeTriggerField') {
          taskAssigned.timeTriggerField = value;
        }
      }
    });
    return taskAssignedList;
  }

  const onIncludeDraftChange = (value: boolean) => {
    let statusTaskListData = { ...statusTaskList } as IStatusTaskList;
    statusTaskListData.includeDraft = value;
    setStatusTaskList(statusTaskListData);
    if (!value) {
      let taskAssignedList = [...taskAssigned] as ITaskAssigned[];
      taskAssignedList = taskAssignedList.map(taskAssigned => {
        if (taskAssigned.initial) {
          taskAssigned.count = 0;
          taskAssigned.unit = '';
          taskAssigned.warningInterval = 0;
          taskAssigned.timeTrigger = '';
          taskAssigned.timeTriggerField = undefined;
        }
        return taskAssigned;
      });
      setTaskAssigned(taskAssignedList);
    }
    props.hasPageChangeRef.current.hasChange = true;
  }

  const onWorkingTimeChange = (time: any, name: string) => {
    let value = moment(time).format("HH:mm:ssZ");
    let workingHoursData = { ...workingHours } as IWorkingHours;
    if (name === 'startTime') {
      workingHoursData.startTime = value;
    }
    if (name === 'endTime') {
      workingHoursData.endTime = value;
    }
    setWorkingHours(workingHoursData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const onDaysChange = (event: any) => {
    let daysList: any = [...selectedDays] as IDaysCheckbox[];
    if (event.target.value) {
      let data = {};
      let index = daysList.findIndex((days: IDaysCheckbox) => days.value === event.target.value)
      daysList.forEach((days: IDaysCheckbox) => {
        if (days.value === event.target.value) {
          data = {
            id: days.id,
            value: days.value,
            label: days.label,
            isChecked: event.target.checked
          }
        }
      });
      daysList[index] = data;
    }
    setSelectedDays(daysList)
    setIsAllDaysChecked(false)
    props.hasPageChangeRef.current.hasChange = true;
  };

  const onAllDayChange = (event: any) => {
    let newData: any = [] as IDaysCheckbox[];
    let daysList = [...selectedDays] as IDaysCheckbox[];
    daysList.forEach((days: IDaysCheckbox) => {
      newData.push({
        id: days.id,
        value: days.value,
        label: days.label,
        isChecked: event.target.checked
      })
    });
    setSelectedDays(newData);
    setIsAllDaysChecked(!isAllDaysChecked)
    props.hasPageChangeRef.current.hasChange = true;
  };

  const onCountChange = (action: string, type: string, statusId: string, value: number, isExcelValue: boolean) => {
    if (action === 'increment') {
      value = value + 1;
    } else if (action === 'decrement') {
      value = value - 1;
    }
    if (statusId && type === 'task') {
      const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
      const taskList = updateTaskAssigned(taskAssignedList, statusId, value, isExcelValue ? 'excelValue' : 'count');
      setTaskAssigned(taskList);
      props.hasPageChangeRef.current.hasChange = true;

    }
    if (type === 'process') {
      const processLaunchData = { ...processLaunch } as IProcessLaunch;
      if (isExcelValue) {
        processLaunchData.warningInterval = value;
      } else {
        processLaunchData.count = value;
      }
      setProcessLaunch(processLaunchData)
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const onCountInputChange = (event: any, statusId: string, type: string, isExcelValue: boolean) => {
    let regExp = new RegExp(/^\d+(?:\.\d{1,2})?$/)
    let value = event.target.value;

    if (value === '' || regExp.test(value)) {
      if (type === 'process') {
        const processLaunchData = { ...processLaunch } as IProcessLaunch;
        if (isExcelValue) {
          processLaunchData.warningInterval = parseFloat(value);
        } else {
          processLaunchData.count = parseFloat(value);
        }
        setProcessLaunch(processLaunchData);
        props.hasPageChangeRef.current.hasChange = true;
      }
      if (statusId && type === 'task') {
        const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
        const taskList = updateTaskAssigned(taskAssignedList, statusId, parseFloat(value), isExcelValue ? 'excelValue' : 'count');
        setTaskAssigned(taskList);
        props.hasPageChangeRef.current.hasChange = true;
      }
    }
  }

  const onTimeUnitChange = (target: any, statusId: string, type: string) => {
    if (type === 'process') {
      const processLaunchData = { ...processLaunch } as IProcessLaunch;
      processLaunchData.unit = target.value;
      setProcessLaunch(processLaunchData);
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (statusId && type === 'task') {
      const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
      const taskList = updateTaskAssigned(taskAssignedList, statusId, target.value, 'unit');
      setTaskAssigned(taskList);
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const onTimeTriggerChange = (value: string, statusId: string, type: string) => {
    if (type === 'process') {
      const processLaunchData = { ...processLaunch } as IProcessLaunch;
      processLaunchData.timeTrigger = value;
      processLaunchData.timeTriggerField = value === 'deadline' ? { ...initialTimeTrigger } : undefined;
      setProcessLaunch(processLaunchData);
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (statusId && type === 'task') {
      const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
      const taskList = updateTaskAssigned(taskAssignedList, statusId, value, 'timeTrigger');
      setTaskAssigned(taskList);
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const onTimeTriggerFieldChange = (value: ITimeTriggerField, statusId: string, type: string) => {
    if (type === 'process') {
      const processLaunchData = { ...processLaunch } as IProcessLaunch;
      processLaunchData.timeTriggerField = value;
      setProcessLaunch(processLaunchData);
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (statusId && type === 'task') {
      const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
      const taskList = updateTaskAssigned(taskAssignedList, statusId, value, 'timeTriggerField');
      setTaskAssigned(taskList);
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const isWorkingHoursValid = () => {
    let workingHoursData = { ...workingHours } as IWorkingHours;
    const startTime = moment(workingHoursData.startTime, "HH:mm a");
    const endTime = moment(workingHoursData.endTime, "HH:mm a");
    const comparison = endTime.diff(startTime, 'hours');
    if (comparison < 0) {
      dispatch(setSlasErrorValidationMessage('Working hour endTime must be after working hour startTime'))
    }
    return comparison > 0 ? true : false;
  }

  const isValid = () => {
    const statusTaskListData = { ...statusTaskList } as IStatusTaskList;
    const processLaunchData = { ...processLaunch } as IProcessLaunch;
    const taskAssignedList = [...taskAssigned] as ITaskAssigned[];
    let activeTasksList = taskAssignedList.filter(e => e.final === false);
    if (!statusTaskListData.includeDraft) {
      activeTasksList = activeTasksList.filter(e => e.initial === false);
    }

    let totalTaskAssignedCount = 0;
    let taskAssignedDaysToHours = 0;
    let taskAssignedHours = 0;
    let totalWarningInterval = 0;
    let warningIntervalHours = 0;
    let warningIntervalDaysToHours = 0;
    let isValid = true;
    let totalProcessLaunchCount = 0;

    if (statusTaskListData.isTaskAssigned) {
      for (const taskAssigned of activeTasksList) {
        if (taskAssigned.timeTrigger === 'deadline' && taskAssigned.timeTriggerField) {
          if (!taskAssigned.unit) {
            dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: Units cannot be empty`));
            return isValid = false;
          }
          if (taskAssigned.timeTriggerField.triggerType === 'field') {
            if (taskAssigned.timeTriggerField.fieldConfig) {
              const reference = taskAssigned.timeTriggerField.fieldConfig.reference;
              if (reference && Object.keys(reference).length > 0) {
                const fieldId = reference.keyPath;
                if (!fieldId) {
                  dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: SLA Deadline Field cannot be empty`));
                  return isValid = false;
                }
              } else {
                const fieldId = taskAssigned.timeTriggerField.fieldConfig.fieldId;
                if (!fieldId) {
                  dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: SLA Deadline Field cannot be empty`));
                  return isValid = false;
                }
              }
            }
          } else {
            const value = (taskAssigned.timeTriggerField && taskAssigned.timeTriggerField.valueConfig && taskAssigned.timeTriggerField.valueConfig.value) || '';
            if (!value) {
              dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: SLA Deadline Value cannot be empty`));
              return isValid = false;
            }
          }
        }
        if (taskAssigned.timeTrigger !== 'deadline' && !taskAssigned.timeTriggerField) {
          if (taskAssigned.warningInterval > taskAssigned.count) {
            dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: Excel value cannot be greater than SLA`));
            return isValid = false;
          }
          if (taskAssigned.count === 0 && (taskAssigned.unit || taskAssigned.warningInterval)) {
            dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: SLA must be configured`));
            return isValid = false;
          }
          if ((taskAssigned.count || taskAssigned.warningInterval) && !taskAssigned.unit) {
            isValid = false;
            if (!isValid) {
              dispatch(setSlasErrorValidationMessage(`${taskAssigned.statusName.toUpperCase()}: Units must be configured`))
            }
          }
          if (taskAssigned.unit === 'd') {
            taskAssignedDaysToHours += taskAssigned.count * 24;
            if (taskAssigned.warningInterval) {
              warningIntervalDaysToHours += taskAssigned.warningInterval * 24;
            }
          } else {
            taskAssignedHours += taskAssigned.count;
            if (taskAssigned.warningInterval) {
              warningIntervalHours += taskAssigned.warningInterval;
            }
          }
        }
      }
      totalTaskAssignedCount = taskAssignedDaysToHours + taskAssignedHours;
      totalWarningInterval = warningIntervalHours + warningIntervalDaysToHours;

      let emptyCountValue = activeTasksList.filter(task => task.timeTrigger !== 'deadline' && !task.timeTriggerField)
        .some((task: ITaskAssigned) => task.count === 0 && task.unit !== '');
      let emptyUnitValue = activeTasksList.some((task: ITaskAssigned) => task.count > 0 && task.unit === '');
      let allHasDeadlineConf = activeTasksList.some((task: ITaskAssigned) => task.timeTrigger === 'deadline' && !!task.timeTriggerField);

      if (!allHasDeadlineConf) {
        if (totalWarningInterval > totalTaskAssignedCount) {
          dispatch(setSlasErrorValidationMessage("Excel value cannot be greater than SLA"))
        }
        if (emptyUnitValue) {
          dispatch(setSlasErrorValidationMessage("Statuses Units must be configured"))
        }
        if (emptyCountValue) {
          dispatch(setSlasErrorValidationMessage("Statuses SLA must be configured"))
        }
        if (totalTaskAssignedCount === 0) {
          dispatch(setSlasErrorValidationMessage("At least one status must be configured"))
        }
        isValid = (emptyUnitValue || emptyCountValue || totalTaskAssignedCount === 0 || totalWarningInterval > totalTaskAssignedCount)
          ? false : true;
      }
    }

    if (statusTaskListData.isProcessLaunch) {
      if (processLaunchData.unit === 'd') {
        totalProcessLaunchCount += processLaunchData.count * 24;
      } else {
        totalProcessLaunchCount += processLaunchData.count
      }

      if (!processLaunchData.timeTriggerField) {
        if (processLaunchData.warningInterval > processLaunchData.count) {
          isValid = false;
          if (!isValid) {
            dispatch(setSlasErrorValidationMessage("Excel value cannot be greater than SLA"))
          }
        }
        if (processLaunchData.count === 0) {
          isValid = false;
          if (!isValid) {
            dispatch(setSlasErrorValidationMessage("Process Launch SLA must be configured"))
          }
        }
      } else {
        if (processLaunchData.timeTriggerField.triggerType === 'field') {
          if (processLaunchData.timeTriggerField.fieldConfig) {
            const reference = processLaunchData.timeTriggerField.fieldConfig.reference;
            if (reference && Object.keys(reference).length > 0) {
              const fieldId = reference.keyPath;
              if (!fieldId) {
                dispatch(setSlasErrorValidationMessage(`Process Launch SLA Deadline Field cannot be empty`));
                return isValid = false;
              }
            } else {
              const fieldId = processLaunchData.timeTriggerField.fieldConfig.fieldId;
              if (!fieldId) {
                dispatch(setSlasErrorValidationMessage(`Process Launch SLA Deadline Field cannot be empty`));
                return isValid = false;
              }
            }
          }
        } else {
          const value = (processLaunchData.timeTriggerField && processLaunchData.timeTriggerField.valueConfig && processLaunchData.timeTriggerField.valueConfig.value) || '';
          if (!value) {
            dispatch(setSlasErrorValidationMessage(`Process Launch SLA Deadline Value cannot be empty`));
            return isValid = false;
          }
        }
      }
      if (!processLaunchData.unit) {
        isValid = false;
        if (!isValid) {
          dispatch(setSlasErrorValidationMessage("Process Launch Units must be configured"))
        }
      }
    }

    if (statusTaskListData.isProcessLaunch && statusTaskListData.isTaskAssigned) {
      if (totalTaskAssignedCount > totalProcessLaunchCount) {
        isValid = false;
        dispatch(setSlasErrorValidationMessage("Sum of “Task Assigned SLAs” cannot be greater than “Process Launch SLA”"))
      }
    }
    return isValid;
  }

  const saveSLAsConfiguration = (saveType: string) => {
    const statusTaskListData: any = { ...statusTaskList } as IStatusTaskList;

    if (is24Hour) {
      delete statusTaskListData.workingHours;
    } else {
      if (!isWorkingHoursValid()) {
        return
      }
    }
    if (isValid()) {
      dispatch(saveSlasAction({
        companyId: company,
        formId: match.params.formId,
        saveType: saveType,
        taskStatusList: statusTaskListData
      }));
      props.hasPageChangeRef.current.hasChange = false;
    }
  }

  useGetSLAData({
    dispatch,
    company,
    formId: match.params.formId
  });

  useEffect(() => {
    setDaysOfWeekList(daysOfWeekData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveRoleType === 'publish') {
      dispatch(publishSlasAction({ company: company, formId: match.params.formId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRoleType]);

  useEffect(() => {
    if (referenceList.length > 0) {
      getReferencedBpFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceList])

  return <>
    {
      (saveSlasLoading || publishSlasLoading || statusListLoading) &&
      <Dimmer active inverted>
        <Loader active content='Loading' />
      </Dimmer>
    }
    <ActionMessage messages={slasActionMessage} />
    <div className='slas-main-container'>
      <TaskManager
        selectedDays={selectedDays}
        onAllDayChange={onAllDayChange}
        onDaysChange={onDaysChange}
        isAllDaysChecked={isAllDaysChecked}
        is24Hour={is24Hour}
        setIs24Hour={setIs24Hour}
        workingHours={workingHours}
        isTaskAssigned={statusTaskList.isTaskAssigned}
        isProcessLaunch={statusTaskList.isProcessLaunch}
        updateSelectedSLAs={updateSelectedSLAs}
        onWorkingTimeChange={onWorkingTimeChange}
        onIncludeDraftChange={onIncludeDraftChange}
        includeDraft={statusTaskList.includeDraft}
      />
      <div className='slas-container'>
        <div className='slas-list-container'>
          <table className='sla-table-list'>
            {(statusTaskList.isTaskAssigned || statusTaskList.isProcessLaunch) &&
              <Table.Header >
                <tr>
                  {statusTaskList.isTaskAssigned &&
                    <th className='header-label' colSpan={4}>Task Assigned</th>
                  }
                </tr>
                <tr>
                  {statusTaskList.isTaskAssigned ?
                    <th className='sla-header'>Status</th>
                    :
                    <th className='sla-header'></th>
                  }
                  <th className='sla-header'>Time Trigger</th>
                  <th className='sla-header green'>Excel</th>
                  <th className='sla-header amber'>SLA</th>
                  <th className='sla-header'>Units</th>
                </tr>
              </Table.Header>
            }
            <tbody>
              {statusTaskList.isTaskAssigned &&
                <>
                  {taskAssigned.map((taskAssigned: ITaskAssigned, index: number) => {
                    let bgColor = 'bg-blue';
                    const statusData = statusList.find(e => e.id === taskAssigned.statusId);
                    if (statusData) {
                      if (statusData.final) {
                        bgColor = 'bg-red';

                        const hasAction = statusData.actions.filter((action: IFormAction) => action.statusId === statusData.id && action.deleted !== true && action.type === 'dynamic');
                        if (hasAction.length > 0) {
                          bgColor = 'bg-orange';
                        }
                      }
                      if (statusData.initial) {
                        bgColor = 'bg-gray'
                      }
                    }

                    return <tr key={`task-row-${index}-${taskAssigned.statusId}`}>
                      <TaskAssigned
                        onCountChange={onCountChange}
                        onCountInputChange={onCountInputChange}
                        onTimeUnitChange={onTimeUnitChange}
                        taskAssigned={taskAssigned}
                        show={statusTaskList.isTaskAssigned}
                        includeDraft={statusTaskList.includeDraft}
                        buttonColor={bgColor}
                        onTimeTriggerChange={onTimeTriggerChange}
                        onTimeTriggerFieldChange={onTimeTriggerFieldChange}
                        referenceList={referenceList}
                        currentFormId={match.params.formId}
                        fieldList={fieldList}
                        formList={formList}
                        referencedBPFields={referencedBPFields}
                      />
                    </tr>
                  })
                  }
                </>
              }
              {statusTaskList.isProcessLaunch &&
                <ProcessLaunch
                  processLaunch={processLaunch}
                  onCountInputChange={onCountInputChange}
                  onCountChange={onCountChange}
                  onTimeUnitChange={onTimeUnitChange}
                  onTimeTriggerChange={onTimeTriggerChange}
                  onTimeTriggerFieldChange={onTimeTriggerFieldChange}
                  referenceList={referenceList}
                  currentFormId={match.params.formId}
                  fieldList={fieldList}
                  formList={formList}
                  referencedBPFields={referencedBPFields}
                />
              }
            </tbody>
          </table>
        </div>
        <ButtonContainer
          save={() => saveSLAsConfiguration('draft')}
          publish={() => saveSLAsConfiguration('publish')}
          cancel={cancelSlasConfiguration}
        />
      </div>
    </div>
  </>
}

export default SLAsContainer;
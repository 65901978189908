import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Route } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { useSetFourthLevelRoute } from '../../../../../../hooks/use-set-fourth-level-route';
import LabelsContainer from './labels';

interface IMatch {
  path: string;
  params: {
    thirdLevelRoute: string;
  }
}

interface ILabelsProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Labels {

  name = 'labels';

  render: React.FC<ILabelsProps> = (props) => {
    const match: IMatch = useRouteMatch();
    const dispatch = useDispatch();

    useSetFourthLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: match.params.thirdLevelRoute,
      fourthLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route path={`${match.path}`} exact>
          <LabelsContainer hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </>
    );
  }
}

export default Labels;

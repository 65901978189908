import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';

class DeleteField {

  name = 'DeleteField';

  updateSection = (sectionList: IFormBuilderSection[], forDeleteField: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    let newSectionList = [...sectionList];
    return newSectionList.map((section: IFormBuilderSection) => {
      if (section.sectionId === sectionUpdateConfig.sectionId) {
        section.fields = section.fields.map((fieldList: TFormBuilderSectionRow) => {
          return fieldList.map((field: ICustomFieldDetail) => {
            if (forDeleteField.id === field.id) {
              field.deleted = true;
            }
            return field;
          });
        })
      }
      return section;
    });
  }
}

export default DeleteField;
import axios, { AxiosResponse } from 'axios';
import ConfigService from '../config';
import { RealtimeService } from '../realtime/realtime.service';
axios.defaults.baseURL = ConfigService.loadConfig().general.apiBaseUrl;
axios.defaults.headers.common['client-tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

export interface IAxiosResponse {
  config?: any;
  data: {
    data: any;
    pagination?: any;
    success?: any;
    message?: string;
  }
}

export const getRequest = async (url: string, options: any): Promise<AxiosResponse> => {
  let data = await axios.get(url, options);
  return data;
};

export const deleteRequest = async (url: string, options: any): Promise<AxiosResponse> => {
  let data = await axios.delete(url, options);
  return data;
};

export const putRequest = async (url: string, options: any): Promise<AxiosResponse> => {
  let data = await axios.put(url, options);
  return data;
};

export const postRequest = async (url: string, options: any): Promise<AxiosResponse> => {
  let data = await axios.post(url, options);
  return data;
};

export const setToken = (token: string) => {
  RealtimeService.shared.setToken(token);
  axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
};

export const clearLocalStorage = () => {
  if (localStorage instanceof Object) {
    Object.keys(localStorage).forEach(keys => {
      if (keys !== 'rememberMe' && keys !== 'storedUsername') {
        localStorage.removeItem(keys);
      }
    });
  }
}
import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { resetMessageResponseAction } from '../../action';

interface IProps {
  dispatch: any;
  editCustomField?: boolean;
}

export const useSetCustomFieldOpen = (props: IProps) => {
  const { dispatch, editCustomField } = { ...props };

  const [currentFieldData, setCurrentFormData] = useState({} as ICustomFieldDetail)
  const [isCustomFieldFormOpen, setIsCustomFieldOpen] = useState(false);
  const [currentFieldConfig, setCurrentFormConfig] = useState({})

  useEffect(() => {
    if (editCustomField) {
      dispatch(resetMessageResponseAction({}));
      setIsCustomFieldOpen(true)
      setCurrentFormConfig({})
      setCurrentFormData({} as ICustomFieldDetail)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCustomField]);

  return {
    currentFieldData,
    setCurrentFormData,
    isCustomFieldFormOpen,
    setIsCustomFieldOpen,
    currentFieldConfig,
    setCurrentFormConfig,
    ...props
  }
}
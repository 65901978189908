import { useEffect, useState } from 'react';
import { IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
}

export const useSetInitialSlaUpdateStateValue = (props: IProps) => {
  const [selectedSLAStatus, setSelectedSLAStatus] = useState('');
  const [taskAssigned, setTaskAssigned] = useState<string[]>([]);
  const [processLaunch, setProcessLaunch] = useState(false);

  useEffect(() => {
    if (props.selectedComponent) {
      const eventType = props.selectedComponent.config.eventType || '';
      const processLaunch = props.selectedComponent.config.forProcessLaunch || false;
      const taskAssigned = props.selectedComponent.config.forStatus || [];
      setProcessLaunch(props.selectedComponent?.config?.forProcessLaunch ? true : false)
      setSelectedSLAStatus(eventType)
      setProcessLaunch(processLaunch)
      setTaskAssigned(taskAssigned)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    selectedSLAStatus,
    setSelectedSLAStatus,
    taskAssigned,
    setTaskAssigned,
    processLaunch,
    setProcessLaunch
  };
}
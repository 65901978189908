import { ICustomFieldDetail } from '../../custom-field-config/interface/custom-field-detail';
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Table,
  required: false,
  type: 'table',
};

interface IDefaultConfigObject {
  columns: ICustomFieldDetail[];
  minRows: number | null;
  maxRows: number | null;
  tableType: string;
}

const defaultConfig: IDefaultConfigObject = {
  columns: [],
  minRows: null,
  maxRows: null,
  tableType: 'vertical',
};

const value = {
  title: 'Table',
  icon: {
    black: require('../../icons/black/smart-grid-black.png'),
    white: require('../../icons/white/smart-grid-white.png'),
    blue: require('../../icons/blue/smart-grid-blue.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
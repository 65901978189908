import React from 'react';
import { useLocation, useRouteMatch, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IHasPageChangeRef } from '../../interface/has-page-change';
import { IRootState } from '../../../../reducers';
import { Container, Icon, Image, Menu } from 'semantic-ui-react-bpm';
import UserAvatar from './user-avatar';
import { AUTH_LOGOUT } from '../../../auth/constant';
import { menuList } from '../../constant';
import AllowedUserListActionControl from '../../../../common/utils/user-list-action-control';
import changeRoute from '../../../../common/utils/change-menu';
import { UserProfileStaticKey } from '../../../auth/interface/user-profile';

interface IPortalHeaderProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PortalHeader: React.FC<IPortalHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match: { path: string } = useRouteMatch();
  const { main } = useSelector((state: IRootState) => state);
  const { userCompanyDetails, userLoggedIn } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange, currentRoute } = useSelector((state: IRootState) => state.main);

  let validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);

  const popupTriggerComponent = userLoggedIn.user && userLoggedIn.user.avatar ?
    <Image style={{ cursor: 'pointer' }} src={userLoggedIn.user.avatar} avatar /> :
    <Icon style={{ cursor: 'pointer' }} name='user circle' size='big' color='orange' />;

  const handleLogout = () => {
    localStorage.removeItem('selectedAttachment');
    dispatch({ type: AUTH_LOGOUT, payload: {} });
  }

  return (
    <Container fluid>
      <Menu
        fixed='top'
        borderless
        className="main-menu"
      >
        <Menu.Menu position='left' className="menu-item-left portal-menu">
          <Menu.Item>
            <Image className="menu-logo" size='mini' src={require('../../mobbiz.png')} />
          </Menu.Item>
          <Menu.Item >
            {location.pathname === '' ? '' :
              <Link className={`menu-route-label`} to={`/${main.mainRoute}`}>
                <span className={`label`}>{`${main.mainRoute.toUpperCase()}`}</span>
              </Link>
            }
            {main.subRoute ?
              <Link className={`menu-route-label ${main.subRoute === currentRoute ? 'current-route' : ''}`} to={`/${main.mainRoute}/${main.subRoute}`}>
                {` > `}
                <span className={`label ${main.subRoute === currentRoute ? 'current-route' : ''}`}>{main.subRoute.toUpperCase().replace(/-/g, ' ')}</span>
              </Link>
              : ''
            }
            {main.thirdLevelRoute ?
              <Link className={`menu-route-label ${main.thirdLevelRoute === currentRoute ? 'current-route' : ''}`} to={`/${main.mainRoute}/${main.subRoute}/${main.thirdLevelRoute}`}>
                {` > `}
                <span className={`label ${main.thirdLevelRoute === currentRoute ? 'current-route' : ''}`}>{main.thirdLevelRoute.toUpperCase().replace(/-/g, ' ')}</span>
              </Link>
              : ''
            }
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position='right' className="menu-item-right">
          {menuList.map(({ id, name, route_label }, key) => {
            if (!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.EndUser) && id === 'documents') {
              return null;
            }
            if (!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.Admin) && id === 'admin') {
              return null;
            }
            if (name === 'BPM MANAGER' &&
              (!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.BPMManager)
                || (userCompanyDetails && userCompanyDetails.seq !== 1))) {
              return null;
            }
            if (id === 'portal' && match.path === '/portal') {
              return null;
            }
            return (
              <React.Fragment key={key} >
                <span style={{ cursor: 'pointer' }} className={`item ${route_label === main.mainRoute ? 'active disabled' : ''} ${id}`}
                  onClick={() => changeRoute(`/${id}`, hasPageChange || props.hasPageChangeRef.current.hasChange, { history, dispatch })}>
                  {name}
                </span>
              </React.Fragment>
            )
          })
          }
          <UserAvatar handleLogout={handleLogout}
            popupTriggerComponent={popupTriggerComponent}
          />
        </Menu.Menu>
      </Menu>
    </Container>
  )
}

export default PortalHeader;
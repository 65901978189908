import React from 'react';
import TableHeaderDetail from './table-header-detail';
import { IFormStatusWithAction } from '../../workflow/interface/form-status';
import { accessLevelIcon } from '../constant';
import { IConstraint } from '../interface/constraint';
import ToolTip from '../../../../../../../common/general/tooltip';

interface ITableHeaderProps {
  statusList: IFormStatusWithAction[];
  openCustomConstraintModal(formId: string): void;
  formId: string;
  constraintList: IConstraint[];
}

const tableHeader: React.FC<ITableHeaderProps> = (props) => {
  return (
    <tr>
      <th className='bg-dark-gray'>
        <div className='header-container'>
          <div className='header-label'>
            <ToolTip position='bottom center'
              popupMessage={'Constraint'}
              popupTriggerComponent={
                <i id={`role-constraint-${props.formId}`} className={`contraint-button icon cog ${props.constraintList.length > 0 ? 'active' : ''}`} onClick={() => props.openCustomConstraintModal(props.formId)}></i>
              }
            />
            <span>Roles</span>
          </div>
        </div>
      </th>
      {
        props.statusList.map((status: IFormStatusWithAction) => {
          let iconStyle = {};
          let bgColor = 'bg-blue';
          if (status.final && !status.finalPositive) {
            bgColor = 'bg-red';
          }
          if (status.finalPositive && status.final) {
            bgColor = 'bg-green';
          }
          if (status.accessLevel === 'owner') {
            iconStyle = {
              padding: '4px 5px',
            };
          }

          return <TableHeaderDetail
            key={`table-header-detail-role-action-${status.id}`}
            buttonColor={bgColor}
            labelText={status.name.toUpperCase()}
            isShown={true}
            icon={accessLevelIcon[status.accessLevel] || accessLevelIcon['owner']}
            style={{
              width: '25px',
              border: '1px solid white',
              padding: '4px',
              borderRadius: '5px',
              float: 'right',
              marginRight: '10px',
              height: '26px',
              ...iconStyle
            }}
            status={status}
          />
        })
      }
      <th></th>
    </tr>
  )
}

export default tableHeader;
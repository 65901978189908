import React, { useEffect, useState } from 'react';
import { Table, Dimmer, Loader, Image, Portal } from 'semantic-ui-react-bpm';
import TableHeader from './table-header';
import TableList from './table-list';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveFormStatusAction } from '../action';
import { IRootState } from '../../../../../../../reducers';
import { IFormStatus } from '../interface/form-status';
import { v4 as uuid } from 'uuid';
import StatusModal from './status-modal';
import ActionModal from './action-modal';
import { IFormAction } from '../interface/form-action';
import { ConfirmationMessage } from '../../../../../../../common/general/confirmation-modal';
import ButtonContainer from '../../../button-container';
import ActionMessage from '../../../../../../../common/general/action-message';
import { workflowActionMessage } from '../constant';
import changeRoute from '../../../../../../../common/utils/change-menu';
import { useRect } from '../../../../../../../common/utils/useRect';
import { icons } from '../../../../../../../common/icons';
import ToolTip from '../../../../../../../common/general/tooltip';
import { IFormRole } from '../../roles/interface/form-role';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';
import { useGetRoleListData } from './hooks/use-get-role-list-data';
import { useSetModalPosition } from './hooks/use-set-modal-position';
import { useSetPublishErrorMessage } from './hooks/use-set-publish-error-message';

const tableContainerRef = React.createRef<HTMLDivElement>();

interface IStatusActionTable {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const StatusActionTable: React.FC<IStatusActionTable> = (props) => {
  const match: { params: { formId: string } } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [statusList, setStatusList] = useState([] as IFormStatus[]);
  const [actionList, setActionList] = useState([] as IFormAction[]);
  const [selectedStatus, setSelectedStatus] = useState({} as IFormStatus);
  const [selectedAction, setSelectedAction] = useState({} as IFormAction);
  const [selectedActionForDeletion, setSelectedActionForDeletion] = useState({ id: '', name: '' });
  const [selectedStatusForDeletion, setSelectedStatusForDeletion] = useState({ id: '', name: '' });
  const [selectedStatusIsInUseByDocument, setSelectedStatusIsInUseByDocument] = useState({ id: '', name: '' });
  const tableContainerRect = useRect(tableContainerRef);
  const {
    company
  } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const {
    formStatusList,
    formActionList,
    formStatusListLoading,
    formActionListLoading,
    formStatusSaveLoading,
    saveWorkflowType
  } = useSelector((state: IRootState) => state.workflow);
  const { roleList } = useSelector((state: IRootState) => state.role);
  const setStatusModal = () => {
    addNewStatus();
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: tableContainerRef.current.getBoundingClientRect().width
      });
    }
  }

  const addNewStatus = () => {
    let newStatusList = [...statusList];
    const newStatus = {
      id: uuid(),
      name: '',
      initial: false,
      final: false,
      accessLevel: 'owner',
      new: true
    } as IFormStatus;
    newStatusList.push(newStatus);
    setStatusList(newStatusList);
    setSelectedStatus(newStatus as IFormStatus);
    setStatusModalPosition(newStatus.id, true)
    props.hasPageChangeRef.current.hasChange = true;
  }

  const setStatusModalPosition = (formId: string, newStatus: boolean) => {
    if (!newStatus) {
      setTimeout(() => {
        const element: HTMLElement | null = document.getElementById(`table-header-${formId}`);
        const element2: HTMLElement | null = document.getElementById('status-modal');
        const dottedElement = element?.getBoundingClientRect();

        if (element2) {
          document.body.appendChild(element2);
          element2.style.left = `calc(${dottedElement?.left}px + 25px)`;
          element2.style.top = `calc(${dottedElement?.top}px + ${window.scrollY}px - 10px)`;
          element2.style.position = `absolute`;
        }
      }, 0)
    }
  }

  const setActionModalPosition = (formId: string) => {
    setTimeout(() => {
      const element: HTMLElement | null = document.getElementById(`table-detail-${formId}`);
      const element2: HTMLElement | null = document.getElementById('action-modal');
      const dottedElement = element?.getBoundingClientRect();
      if (element2) {
        document.body.appendChild(element2);
        element2.style.left = `calc(${dottedElement?.left}px + 20px)`;
        element2.style.top = `calc(${dottedElement?.top}px + ${window.scrollY}px - 10px)`;
        element2.style.position = `absolute`;
      }
    }, 0)
  }

  const editStatus = (formStatus: IFormStatus) => {
    setSelectedStatus(formStatus);
    setStatusModalPosition(formStatus.id, false);
  }

  const deleteStatus = (detedStatusId: string) => {
    let newStatusList = [...statusList];
    newStatusList = newStatusList.map((e: IFormStatus) => {
      if (e.id === detedStatusId) {
        e.deleted = true;
      }
      return e;
    })
    setStatusList(newStatusList);
    setSelectedStatus({} as IFormStatus);
    actionList.forEach((action: IFormAction) => {
      if (action.statusId === detedStatusId || (action.config && action.config.targetStatusId === detedStatusId)) {
        deleteAction(action.id)
      }
    })
  }

  const updateStatus = (status: IFormStatus) => {
    let newStatusList = [...statusList];
    newStatusList = newStatusList.map((e: IFormStatus) => {
      if (e.id === status.id) {
        e = status;
      }
      return e;
    })
    setStatusList(newStatusList);
    setSelectedStatus({} as IFormStatus);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const sortStatus = (drag: { type: string, dragIndex: number, dragItem: IFormStatus }, dropAreaIndex: number) => {
    let newStatusList = [...statusList];
    newStatusList.splice(drag.dragIndex, 1);
    newStatusList.splice(dropAreaIndex, 0, drag.dragItem);
    setStatusList(newStatusList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const isStatusNew = (statusId: string) => {
    let isNew = false;
    statusList.forEach((status: IFormStatus) => {
      if (status.id === statusId && status.new) {
        isNew = true;
      }
    });
    return isNew;
  }

  const openDeleteConfirmationStatus = (statusId: string, statusName: string) => {
    const isStatusInUse = statusList.find((status: IFormStatus) => status.id === statusId && status.inUse)
    if (isStatusInUse) {
      setSelectedStatusIsInUseByDocument({ id: statusId, name: statusName });
      return;
    }


    if (isStatusNew(statusId)) {
      deleteStatus(statusId);
    } else {
      setSelectedStatusForDeletion({ id: statusId, name: statusName });
    }
  }

  const closeDeleteStatus = () => {
    setSelectedStatusForDeletion({ id: '', name: '' });
  }

  const closeSelectedStatusIsInUseByDocument = () => {
    setSelectedStatusIsInUseByDocument({ id: '', name: '' })
  }

  const confirmDeleteStatus = () => {
    deleteStatus(selectedStatusForDeletion.id);
    closeDeleteStatus();
    props.hasPageChangeRef.current.hasChange = true;
  }

  const isActionAlreadyUsedInRole = (actionId: string): boolean => {
    let isUsed = false;

    roleList.forEach((role: IFormRole) => {
      role.actions.forEach((action: IFormAction) => {
        if (action.id === actionId) {
          isUsed = true;
        }
      })
    })

    return isUsed;
  }

  const createNewAction = (originStatusId: string, targetStatusId: string, originStatusIndex: number, targetStatusIndex: number) => {
    let updateActionList = [...actionList];
    const newAction = {
      id: uuid(),
      statusId: originStatusId,
      name: '',
      type: 'dynamic',
      config: {
        targetStatusId,
        assignmentType: `individual`,
        direction: `${originStatusIndex >= targetStatusIndex ? 'backward' : 'forward'}`,
        return: false
      },
      new: true
    };
    updateActionList.push(newAction)
    setActionList(updateActionList)
    setSelectedAction(newAction);
    setActionModalPosition(newAction.id)
    props.hasPageChangeRef.current.hasChange = true;
  }

  const updateActionList = (forUpdateFormAction: IFormAction) => {
    let updateActionList = [...actionList];
    updateActionList = updateActionList.map((formAction: IFormAction) => {
      if (formAction.id === forUpdateFormAction.id) {
        return forUpdateFormAction;
      }
      return formAction;
    })
    setActionList(updateActionList)
    setSelectedAction({} as IFormAction);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const editAction = (formAction: IFormAction) => {
    setSelectedAction(formAction);
    setActionModalPosition(formAction.id)
  }

  const closeDeleteAction = () => {
    setSelectedActionForDeletion({ id: '', name: '' })
  }

  const confirmDeleteAction = () => {
    deleteAction(selectedActionForDeletion.id);
    closeDeleteAction();
    props.hasPageChangeRef.current.hasChange = true;
  }

  const openDeleteConfirmationAction = (actionId: string, actionName: string) => {
    if (isActionAlreadyUsedInRole(actionId)) {
      setSelectedActionForDeletion({ id: actionId, name: actionName })
    } else {
      deleteAction(actionId);
    }
  }

  const deleteAction = (actionId: string) => {
    let updateActionList = [...actionList];
    updateActionList = updateActionList.map((formAction: IFormAction) => {
      if (formAction.id === actionId) {
        formAction.deleted = true;
      }
      return formAction;
    })
    setActionList(updateActionList)
    setSelectedAction({} as IFormAction);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const saveFormStatus = (saveType: string) => {
    dispatch(saveFormStatusAction({
      companyId: company,
      formId: match.params.formId,
      actionList: actionList,
      statusList: statusList,
      saveWorkflowType: saveType ? saveType : ''
    }))
    props.hasPageChangeRef.current.hasChange = false;
  }

  const cancelWorkFlow = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history });
  }

  useSetModalPosition({
    selectedStatus,
    tableContainerRect
  });

  useGetRoleListData({
    company,
    dispatch,
    formId: match.params.formId
  });

  useSetPublishErrorMessage({
    formId: match.params.formId,
    company,
    actionList,
    dispatch,
    saveWorkflowType,
    statusList
  });

  useEffect(() => {
    setStatusList(formStatusList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatusList])

  useEffect(() => {
    setActionList(formActionList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formActionList]);

  return (
    <>
      <ToolTip
        popupMessage='Close'
        popupTriggerComponent={
          <Image id='business-process-close-icon' src={icons.blue.closeCircle} onClick={() => {
            changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
          }} />
        }
        position='right center'
      />
      <div style={{ marginTop: '15px', marginLeft: '5px', marginRight: '25px' }}>
        <ActionMessage messages={workflowActionMessage} />
      </div>
      <div className='workflow-main-container'>
        {
          ((formStatusListLoading && formActionListLoading) || formStatusSaveLoading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <div ref={tableContainerRef} className='status-action-table-container'>
          <table id='status-action-table' className='status-action-table'>
            <Table.Header >
              <TableHeader
                formStatusList={statusList}
                editStatus={editStatus}
                actionList={actionList}
                sortStatus={sortStatus}
                addNewStatus={setStatusModal}
              />
            </Table.Header>
            <TableList
              formStatusList={statusList}
              createNewAction={createNewAction}
              actionList={actionList}
              editAction={editAction}
            />
          </table>
        </div>
        <ButtonContainer
          cancel={cancelWorkFlow}
          save={() => saveFormStatus('draft')}
          publish={() => saveFormStatus('publish')}
        />
        <Portal open={selectedStatus && !!selectedStatus.id} >
          <StatusModal
            selectedStatus={selectedStatus}
            deleteStatus={openDeleteConfirmationStatus}
            updateStatus={updateStatus}
          />
        </Portal>
        <Portal open={selectedAction && !!selectedAction.id} >
          <ActionModal
            formAction={actionList[0]}
            editAction={editAction}
            selectedAction={selectedAction}
            deleteAction={openDeleteConfirmationAction}
            updateActionList={updateActionList}
            formStatusList={statusList}
          />
        </Portal>
        <ConfirmationMessage
          clearErrorMessage={() => { }}
          errorMessage={''}
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeDeleteAction}
          open={selectedActionForDeletion.id !== ''}
          confirm={confirmDeleteAction}
          cancel={closeDeleteAction}
          headerText="Confirmation"
          detailText={`The Dynamic Action '${selectedActionForDeletion.name || ''}' will be deleted. Do you still want to proceed?`}
          loading={false}
          type='warning'
        />
        <ConfirmationMessage
          clearErrorMessage={() => { }}
          errorMessage={''}
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeDeleteStatus}
          open={selectedStatusForDeletion.id !== ''}
          confirm={confirmDeleteStatus}
          cancel={closeDeleteStatus}
          headerText="Confirmation"
          detailText={`The status '${selectedStatusForDeletion.name || ''}' and related dynamic actions will be deleted. Do you still want to proceed?`}
          loading={false}
          type='warning'
        />
        <ConfirmationMessage
          clearErrorMessage={() => { }}
          errorMessage={''}
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeSelectedStatusIsInUseByDocument}
          open={selectedStatusIsInUseByDocument.id !== ''}
          confirm={() => { }}
          cancel={closeSelectedStatusIsInUseByDocument}
          headerText="Confirmation"
          detailText={`You cannot delete '${selectedStatusIsInUseByDocument.name || ''}' because there are documents that currently belong to this status.`}
          loading={false}
          type='error'
        />
      </div>
    </>
  )
}

export default StatusActionTable;
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { icons } from '../../../../common/icons';

export const GET_BP_SLA = 'app/documentModule/calendar/GET_BP_SLA';
export const GET_BP_SLA_RETURN = 'app/documentModule/calendar/GET_BP_SLA_RETURN';
export const GET_BP_SLA_ERROR = 'app/documentModule/calendar/GET_BP_SLA_ERROR';
export const GET_DOCUMENTS = 'app/documentModule/calendar/GET_DOCUMENTS';
export const GET_DOCUMENTS_RETURN = 'app/documentModule/calendar/GET_DOCUMENTS_RETURN';
export const GET_DOCUMENTS_ERROR = 'app/documentModule/calendar/GET_DOCUMENTS_ERROR';
export const CLEAR_DOCUMENTS_ERROR = 'app/documentModule/calendar/CLEAR_DOCUMENTS_ERROR';

export const calendarOwnershipFilter = [
  {
    id: 'owned',
    label: 'Owned',
    iconDefault: icons.black.user,
    iconSelected: icons.orange.user,
    style: {
      width: '11px',
      height: '16px',
      marginRight: '14px'
    }
  },
  {
    id: 'co-owned',
    label: 'Co-Owned',
    iconDefault: icons.black.users,
    iconSelected: icons.orange.users,
    style: {
      width: '16px',
      height: '16px',
      marginRight: '14px'
    }
  },
  {
    id: 'follower',
    label: 'Followed',
    iconDefault: icons.black.star,
    iconSelected: icons.orange.star,
    style: {
      width: '16px',
      height: '16px',
      marginRight: '7px'
    }
  },
  {
    id: 'all',
    label: 'ALL',
    colorDefault: '#000',
    colorSelected: '#DDA749',
  }
]

export const calendarActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_DOCUMENTS_ERROR,
    type: 'negative',
    reducerItem: 'documentsListError',
    reducerContainer: 'calendarReducer',
    hasCloseIcon: true
  }
]
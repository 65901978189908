import {
  GET_ADMIN_HISTORY_LOGS,
  GET_ADMIN_HISTORY_LOGS_ERROR,
  GET_ADMIN_HISTORY_LOGS_RETURN,
  FILTER_ADMIN_HISTORY,
  FILTER_ADMIN_HISTORY_ERROR,
  FILTER_ADMIN_HISTORY_RETURN
} from './constant';
import { IHistoryGetDetailInput } from './interface/input/history-get';
import { IHistoryFilterResponse } from './interface/response/history-filter';
import { IHistoryGetResponse } from './interface/response/history.get';

export const getAdminHistoryLogsAction = (payload: IHistoryGetDetailInput) => {
  return { type: GET_ADMIN_HISTORY_LOGS, ...payload };
}

export const getAdminHistoryLogsReturnAction = (payload: IHistoryGetResponse) => {
  return { type: GET_ADMIN_HISTORY_LOGS_RETURN, ...payload };
}

export const getAdminHistoryLogsErrorAction = (message: string) => {
  return { type: GET_ADMIN_HISTORY_LOGS_ERROR, message };
}

export const filterAdminHistoryAction = (payload: IHistoryGetDetailInput) => {
  return { type: FILTER_ADMIN_HISTORY, ...payload };
}

export const filterAdminHistoryReturnAction = (payload: IHistoryFilterResponse) => {
  return { type: FILTER_ADMIN_HISTORY_RETURN, ...payload };
}

export const filterAdminHistoryErrorAction = (message: string) => {
  return { type: FILTER_ADMIN_HISTORY_ERROR, message };
}
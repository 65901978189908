import React from 'react';
import { Image, Icon } from 'semantic-ui-react-bpm';
import { IFormAction } from '../../workflow/interface/form-action';
import { IFormRole } from '../interface/form-role';
import { IFormStatus, IFormStatusWithAction } from '../../workflow/interface/form-status';
import ToolTip from '../../../../../../../common/general/tooltip';
import RoleConstraint from './role-constraint';
import { IConstraint } from '../interface/constraint';

interface IRoleActions {
  actions: IFormAction[];
  role: IFormRole;
  status: IFormStatus;
  setRoleAction(status: IFormStatusWithAction, role: IFormRole): void;
  constraintList: IConstraint[];
}

const RoleActions: React.FC<IRoleActions> = (props) => {
  let eyeIcon = require('../../../../../../../common/icons/blue/eye.png');
  let editIcon = require('../../../../../../../common/icons/blue/icon-edit.png');
  let assignIcon = require('../../../../../../../common/icons/blue/open.png');
  let uploadIcon = require('../../../../../../../common/icons/blue/bulk-upload.png');

  let hasView = false;
  let hasSave = false;
  let hasAssign = false;
  let hasUpload = false;
  let selectedActionsList = [] as IFormAction[];

  if (props.role) {
    const actions = props.role.actions.filter(e => props.status && e.statusId === props.status.id);
    const actionTypes = actions.map(e => e.type);

    hasSave = actionTypes.includes('save') || actionTypes.includes('dynamic');
    hasView = !hasSave ? actionTypes.includes('view') : false;
    hasUpload = actionTypes.includes('upload');

    selectedActionsList = actions.filter(e => e.type === 'dynamic');

    const showSave = props.status && props.status.initial === true;
    if (showSave) {
      const saveAction = actions.filter((e: IFormAction) => e.type === 'save').map(e => {
        return { ...e, name: 'SAVE' }
      });
      selectedActionsList = selectedActionsList.concat(saveAction);
    }
  }

  return <div id='action-main-container' >
    <div id='action-image-group'>
      {hasView && !hasSave &&
        <Image style={{
          float: 'left',
          width: '19px',
          height: '14px',
          margin: '10px 5px'
        }} src={eyeIcon}></Image>
      }
      {hasSave &&
        <Image style={{
          float: 'left',
          width: '19px',
          height: '18px',
          margin: '8px 5px'
        }} src={editIcon}></Image>
      }
      {hasAssign &&
        <Image style={{
          float: 'left',
          width: '19px',
          height: '18px',
          margin: '8px 5px'
        }} src={assignIcon}></Image>
      }
      {hasUpload &&
        <Image style={{
          float: 'left',
          width: '19px',
          height: '18px',
          margin: '8px 5px'
        }} src={uploadIcon}></Image>
      }
      <ToolTip position='left center'
        popupMessage='Role Rights...'
        popupTriggerComponent={
          <Icon link name='ellipsis vertical' id={`table-role-detail-${props.role.id}-${props.status.id}`} onClick={() => props.setRoleAction(props.status as IFormStatusWithAction, props.role)} />
        }
      />
      <RoleConstraint
        constraintList={props.constraintList}
        actions={props.actions}
        setRoleAction={props.setRoleAction}
        status={props.status}
        role={props.role}
      />
    </div>
    <div id='action-button-group'>
      {
        selectedActionsList.map((action: IFormAction) => {
          return <div
            key={`selected-action-${action.id}`}
            className={`action-item ${action.type} ${action.config && action.config.direction === 'backward' ? 'backward' : 'forward'}`}>{action.name}
          </div>
        })
      }
    </div>
  </div>
}

export default RoleActions;
import { FieldValidator } from './field-validator';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
import { IConfigName } from '../../type/interface/field-type-object';
export class StringValidator extends FieldValidator {

  name = 'string';

  validate(field: FieldElement, value: string, actionType?: string) {
    const { multiline, multilineIndex } = { ...field } as any;
    if (((value === '' || value === 'undefined' || value === 'Invalid date')
      && (field.accessType !== 'required' || ((field.accessType === IFieldAccessType.Required
        || (field.validationSchema.minLength && field.validationSchema.minLength > 0))
        && actionType === 'save'))) || field.accessType === IFieldAccessType.Readonly
      || field.accessType === IFieldAccessType.Hidden
      || (field.configName === IConfigName.Autopopulated && field.accessType === IFieldAccessType.ReadWrite)) {
      return [];
    }
    // add min length if required
    field.validationSchema.minLength = field.validationSchema.minLength || 1;

    const ajv = new Ajv();
    let schema = { ...field.validationSchema };
    if (multiline && multilineIndex !== null) {
      schema = { ...field.validationSchema as any }[multilineIndex];
    }

    const validationSchema = {
      properties: {
        [field.id]: { ...schema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: multiline && field.inputConfig.config.format !== 'phone' ? [value] : value })
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}


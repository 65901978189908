import React from 'react';
import Field from './field';
import { systemFieldList, fieldIconBaseOnConfigName } from '../constant';
import { v4 as uuid } from 'uuid';
import { IColumnView, IDragColumnView } from '../interface/custom-view';
import { useDrop } from 'react-dnd';
import { IForm, IFieldsLayout, IFieldLayoutObject } from '../../../../form/interface/form';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';

interface IFieldContainerProps {
  customViewList: IColumnView[];
  removeSelectedColumnView(dragItem: { data: IColumnView }): void;
  formDetail: IForm;
  fieldList: ICustomFieldDetail[];
  searchText: string;
  fieldTypeFilter: string;
  fieldSectionFilter: string;
}

const FieldContainer: React.FC<IFieldContainerProps> = (props) => {

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'column-field',
    drop: (selectedItem: IDragColumnView) => {
      if (selectedItem.data.new === false) {
        props.removeSelectedColumnView(selectedItem);
      }
    }
  })

  const isShowSystemField = (field: IColumnView) => {
    const hasSelected = props.customViewList.filter((selectedField: IColumnView) => selectedField.fieldName === field.fieldName);
    if (props.fieldSectionFilter) {
      return false;
    }
    if (hasSelected.length > 0) {
      return false;
    }
    if (field.label.toUpperCase().indexOf(props.searchText.toUpperCase()) === -1) {
      return false;
    }
    if (props.fieldTypeFilter !== '' && props.fieldTypeFilter !== 'Date' && (field.fieldName === 'createdAt' || field.fieldName === 'updatedAt')) {
      return false;
    }
    if (props.fieldTypeFilter !== 'Text String'
      && props.fieldTypeFilter !== ''
      && (field.fieldName === 'statusId'
        || field.fieldName === 'creator'
        || field.fieldName === 'updatedBy'
        || field.fieldName === 'owner'
      )) {
      return false;
    }
    return true;
  }

  return (<>
    <div ref={dropRef} className='field-container'>
      {
        systemFieldList.filter(e => e.fieldName !== 'owner').map((field: IColumnView) => {
          if (!isShowSystemField(field)) {
            return undefined;
          }
          return <Field key={`field-container-system-field-key-${uuid()}`} field={field} />;
        })
      }
      <br />
      {
        props.formDetail.fieldsLayout &&
        props.formDetail.fieldsLayout.map((layout: IFieldsLayout) => {
          const notFulfillSelectedSection = props.fieldSectionFilter !== '' && props.fieldSectionFilter !== layout.id;
          if (notFulfillSelectedSection) {
            return undefined;
          }

          return layout.body.map((layoutObject: IFieldLayoutObject) => {
            return layoutObject.fields.map((field: string) => {
              const hasSelected = props.customViewList.filter((selectedField: IColumnView) => selectedField.fieldName === field);
              if (hasSelected.length > 0) {
                return undefined;
              }
              const fieldDetail = props.fieldList.filter((customField: ICustomFieldDetail) => customField.id === field);
              const fieldDetailObject = fieldDetail[0];
              if (fieldDetail.length > 0) {
                if ((props.fieldTypeFilter !== '' && fieldDetailObject.configName !== props.fieldTypeFilter) ||
                  fieldDetailObject.configName === IConfigName.Table) {
                  return undefined;
                }
                const icon = fieldIconBaseOnConfigName[fieldDetailObject.configName || IConfigName.TextString]
                  ? fieldIconBaseOnConfigName[fieldDetailObject.configName || IConfigName.TextString].icon
                  : null
                const iconStyle = fieldIconBaseOnConfigName[fieldDetailObject.configName || IConfigName.TextString]
                  ? fieldIconBaseOnConfigName[fieldDetailObject.configName || IConfigName.TextString].iconStyle
                  : null
                const fieldData = {
                  type: 'field',
                  label: fieldDetailObject.label,
                  fieldName: fieldDetailObject.id,
                  icon: icon,
                  iconStyle: iconStyle,
                  color: '',
                  new: true,
                } as IColumnView;
                if (fieldData.label.toUpperCase().indexOf(props.searchText.toUpperCase()) === -1 || fieldDetailObject.configName === IConfigName.Seq) {
                  return undefined;
                }
                return <Field key={`field-container-system-field-key-${uuid()}`} field={fieldData} />
              }
              return undefined;
            })
          })
        })
      }
    </div>
  </>
  );
}

export default FieldContainer;
import React from 'react';
import { useSelector } from 'react-redux';
import ToolTip from '../../../../../../../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { IRootState } from '../../../../../../../../../../../reducers';
import { IFormStatusWithAction } from '../../../../../../workflow/interface/form-status';
import { actionIconStyle } from '../../../constant';
import { IRender } from './component-registry';


class AutomationOutlineAction {

  name = 'action';

  render: React.FC<IRender> = (props) => {
    const title = props.component.config && props.component.config.action && props.component.config.action.title;
    const name = props.component.config && props.component.config.action && props.component.config.action.name;
    const iconName = props.component.config && props.component.config.action && props.component.config.action.iconName;
    const statusId = (props.component.config && props.component.config.statusId) || [];

    const iconHeight = actionIconStyle[iconName || 'default'].height;
    const iconWidth = actionIconStyle[iconName || 'default'].width;

    const {
      formStatusList
    } = useSelector((state: IRootState) => state.workflow);

    const getStatusLabel = (status: string): string => {
      const statuslabel = formStatusList
        .find((e: IFormStatusWithAction) => e.id === status)
      return statuslabel ? statuslabel?.name : '';
    }

    const renderStatusUpdate = () => {
      const currentStatus = getStatusLabel(statusId);
      return <div className='description-container'>
        {
          currentStatus
            ? <>
              <div className={`title`}>Destination Status</div>
              <div className={`content`}>{`${currentStatus}`}</div>
            </>
            : <></>
        }
      </div>
    }

    return (
      <li className={`outline-widget`} onClick={() => props.setSelectedComponent(props.component)}>
        <div className='has-border'>
          <SVGWrapper width={iconWidth} height={iconHeight} iconName={iconName || 'newAction'} color={`#3A85A8`} />
        </div>
        <div className={`content`}>
          <ToolTip position='right center'
            popupMessage='Delete'
            popupTriggerComponent={<i className="trash alternate outline icon" onClick={(e) => {
              props.deleteCreatedComponent('action', props.currentIndex)
              e.stopPropagation();
            }}></i>} />
          <span className={`title`}>{title ? `Action: ${title}` : `New Action`}</span>
          {title
            ? <>
              {name === 'update-status' && renderStatusUpdate()}
            </>
            : <span className={`description`}>{`Select an action to perform`}</span>
          }
        </div>
      </li>
    );
  }
}

export default AutomationOutlineAction;
import React, { useRef, useState } from 'react';
import { Loader } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../../../../../common/api-request/upload';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import { IComment } from '../../interface/comment';
import { ISenderInfo } from '../../interface/sender-info';
import ChatContent from '.';
import CommentEditPopup from '../popups/comment-edit';
import ChatFilter from '../filter';
import { useMountWhenChatboxOpen } from './hooks/use-mount-when-chatbox-open';
import InputBox from '../input';

interface IChatAreaProps {
  closeChatbox(): void;
  open: boolean;
  selectedFilter: string[];
  filterComment(value: any): void;
  commentList: IComment[];
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  userList: ISenderInfo[];
  selectedUser: string[];
  selectUser(value: string): void;
  openUpload(value: boolean): void;
  clearFilter(): void;
  userColor: { [id: string]: string };
  uploadedFileList: UploadedFile[];
  removeUploadedFile(file: UploadedFile): void;
  removeFileAfterSave(): void;
  documentId: string;
  company: string;
  markCommentAsUnread(comment: IComment): void;
  dismissUnreadNavigator(): void;
  lastReadMessage: IComment;
  commentsUnread: IComment[];
  module: string;
  hideInput?: boolean;
  hideFilterType?: boolean;
  disableScrollToBottom?: boolean;
  sort: boolean;
}

const ChatArea: React.FC<IChatAreaProps> = (props) => {
  let containerRef = useRef(document.createElement('div'));

  const { width, height } = useDeviceDetect();
  const [selectedId, setSelectedId] = useState('');

  const [mount, setMount] = useState(false);

  const selectedComment = props.commentList.filter(e => e.id === selectedId)[0];
  const chatAreaStyle = width <= 480 ? { height: `calc(${height - 59}px)` } : {};

  useMountWhenChatboxOpen({
    open: props.open,
    setMount
  });

  return (
    <div className={`chat-area ${props.open ? 'open' : 'close'}`} style={chatAreaStyle}>
      <ChatFilter
        userColor={props.userColor}
        clearFilter={props.clearFilter}
        selectUser={props.selectUser}
        selectedUser={props.selectedUser}
        userList={props.userList}
        filterComment={props.filterComment}
        selectedFilter={props.selectedFilter}
        closeChatbox={props.closeChatbox}
        hideFilterType={props.hideFilterType}
      />
      {mount ?
        <>
          <ChatContent
            documentId={props.documentId}
            removeFileAfterSave={props.removeFileAfterSave}
            removeUploadedFile={props.removeUploadedFile}
            uploadedFileList={props.uploadedFileList}
            userColor={props.userColor}
            saveComment={props.saveComment}
            commentList={props.commentList}
            company={props.company}
            markCommentAsUnread={props.markCommentAsUnread}
            lastReadMessage={props.lastReadMessage}
            containerRef={containerRef}
            commentsUnread={props.commentsUnread}
            dismissUnreadNavigator={props.dismissUnreadNavigator}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            module={props.module}
            sort={props.sort}
          />
          {!props.hideInput &&
            <InputBox
              documentId={props.documentId}
              removeFileAfterSave={props.removeFileAfterSave}
              removeUploadedFile={props.removeUploadedFile}
              uploadedFileList={props.uploadedFileList}
              saveComment={props.saveComment}
              openUpload={props.openUpload}
              mobile={width <= 480}
              actionType='create'
              module={props.module}
            />
          }
          {(selectedId && width <= 480) &&
            <CommentEditPopup
              open={!!selectedId}
              documentId={props.documentId}
              removeFileAfterSave={props.removeFileAfterSave}
              removeUploadedFile={props.removeUploadedFile}
              uploadedFileList={props.uploadedFileList}
              saveComment={props.saveComment}
              message={selectedComment ? selectedComment.text : ''}
              setSelectedId={setSelectedId}
              commentId={selectedId}
              mobile={width <= 480}
              actionType='edit'
              openUpload={props.openUpload}
              module={props.module}
            />}
        </>
        :
        <div className='report-table-list-loading'>
          <Loader active content='' />
        </div>
      }
    </div >
  );
};

export default ChatArea;

import { IUserSettingsUpdateResponse } from './interface/response/user-settings.update';
import { IUserSettingsResponse } from './interface/response/user-settings';
import { IUserSettingsDetailInput } from './interface/input/user-settings';
import { IUserSettingsUpdateDetailedInput } from './interface/input/user-settings-update';
import { GET_USER_SETTINGS, GET_USER_SETTINGS_RETURN, GET_USER_SETTINGS_ERROR, UPDATE_USER_SETTINGS, UPDATE_USER_SETTINGS_RETURN, UPDATE_USER_SETTINGS_ERROR } from './constant';

export const getUserSettingsAction = (payload: IUserSettingsDetailInput) => {
  return { type: GET_USER_SETTINGS, ...payload }
}

export const getUserSettingsReturnAction = (payload: IUserSettingsResponse) => {
  return { type: GET_USER_SETTINGS_RETURN, ...payload }
}

export const getUserSettingsErrorAction = (message: string) => {
  return { type: GET_USER_SETTINGS_ERROR, message }
}

export const updateUserSettingsAction = (payload: IUserSettingsUpdateDetailedInput) => {
  return { type: UPDATE_USER_SETTINGS, ...payload }
}

export const updateUserSettingsReturnAction = (payload: IUserSettingsUpdateResponse) => {
  return { type: UPDATE_USER_SETTINGS_RETURN, ...payload }
}

export const updateUserSettingsErrorAction = (message: string) => {
  return { type: UPDATE_USER_SETTINGS_ERROR, message }
}

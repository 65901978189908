import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { deleteRequest, getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../../../common/api-request';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { SanitizeAutomation } from '../../../../../../../../common/utils/sanitize-automation';
import { SanitizeField } from '../../../../../../../../common/utils/sanitize-field';
import { SanitizeSlas } from '../../../../../../../../common/utils/sanitize-slas';
import { IFormGetInput } from '../../../../../../../document-module/module/form/interface/input/form-get';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { IField } from '../../../../../users/interface/field';
import { ISlaStatusesInput } from '../../../configuration/module/slas/interface/input/slaStatuses';
import { IForm } from '../../../form/interface/form';
import {
  createAutomationErrorAction,
  createAutomationReturnAction,
  deleteAutomationReturnAction,
  getAutomationContentReturnAction,
  getAutomationlistReturnAction,
  getDatatableInputFieldReturnAction,
  getFormFieldReturnAction,
  getFormStatusReturnAction,
  getInputFieldReturnAction,
  getPublishFormReturnAction,
  getReferenceListReturnAction,
  getSelectedBPFormFieldReturnAction,
  getSlaStatusReturnAction,
  publishAutomationErrorAction,
  publishAutomationReturnAction,
  updateAutomationErrorAction,
  updateAutomationReturnAction,
  getAutomationInputFieldReturnAction,
  getAutomationPlaceholdersReturnAction,
  getPortalsReturnAction
} from './action';
import {
  CREATE_AUTOMATION,
  DELETE_AUTOMATION,
  GET_AUTOMATION_CONTENT,
  GET_AUTOMATION_LIST,
  GET_BP_SELECTED_FORM_FIELD,
  GET_DATATABLE_INPUT_FIELD,
  GET_FORM_FIELD, GET_FORM_STATUS,
  GET_INPUT_FIELD, GET_PUBLISH_FORM,
  GET_REFERENCES,
  GET_SLA_STATUS,
  PUBLISH_AUTOMATION,
  UPDATE_AUTOMATION,
  GET_AUTOMATION_FIELD_INPUT,
  GET_AUTOMATION_PLACEHOLDERS,
  GET_PORTALS
} from './constant';
import { IAutomationCreateInput } from './interface/input/automation-create';
import { IAutomationDeleteInput } from './interface/input/automation-delete';
import { IAutomationGetContentInput } from './interface/input/automation-get-content';
import { IAutomationListGetInput } from './interface/input/automation-list-get';
import { IAutomationPublishInput } from './interface/input/automation-publish';
import { IAutomationUpdateInput } from './interface/input/automation-update';
import { IFieldInputGetInput } from './interface/input/field-input-get';
import { IFormFieldGetInput } from './interface/input/form-field-get';
import { IFormtatusGetInput } from './interface/input/form-status-get';
import { IReferenceGetInput } from './interface/input/reference-get';

export function* createAutomation(args: IAutomationCreateInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const data = SanitizeAutomation.structureOutgoing(args.automation)
    const automation: IAxiosResponse = yield call(postRequest, `${company}/forms/${formId}/automations`, data);
    yield put(createAutomationReturnAction({ automation: SanitizeAutomation.structureIncoming(automation.data.data) }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(createAutomationErrorAction(JSON.stringify(error.response.data.message)))
  }
}

export function* getAutomationList(args: IAutomationListGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const automation: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/automations?draft=true`, {});
    yield put(getAutomationlistReturnAction({ automationList: SanitizeAutomation.structureIncomingMultiple(automation.data.data) }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getAutomationContent(args: IAutomationGetContentInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const automationId = args.automationId;
    const automation: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/automations/${automationId}?draft=true`, {});
    yield put(getAutomationContentReturnAction({ automation: SanitizeAutomation.structureIncoming(automation.data.data) }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* updateAutomation(args: IAutomationUpdateInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const data = SanitizeAutomation.structureOutgoing(args.automation)
    const automation: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}/automations/${args.automation.id}`, data);
    yield put(updateAutomationReturnAction({ automation: SanitizeAutomation.structureIncoming(automation.data.data) }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(updateAutomationErrorAction(JSON.stringify(error.response.data.message)))
  }
}

export function* deleteAutomation(args: IAutomationDeleteInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const automationId = args.automationId;
    yield call(deleteRequest, `${company}/forms/${formId}/automations/${automationId}`, {});
    yield put(deleteAutomationReturnAction({ automationId: args.automationId }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormField(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const fieldData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormFieldReturnAction({
      formFieldList: SanitizeField.structureIncomingField(fieldData.data.data) as ICustomFieldDetail[],
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getPublishedForm(args: IFormGetInput) {
  try {
    const company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms?draft=false&limit=0`, {});
    yield put(getPublishFormReturnAction({
      publishedFormList: req.data.data as IForm[],
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormStatusList(args: IFormtatusGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const draft = args.draft ? true : false;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=${draft}&load=actions`, {});
    yield put(getFormStatusReturnAction({
      formStatusList: req.data.data,
      draft: draft,
      formId
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getBPSelectedFormField(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const fieldData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=false`, {});
    yield put(getSelectedBPFormFieldReturnAction({
      formFieldList: SanitizeField.structureIncomingField(fieldData.data.data) as ICustomFieldDetail[],
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getInputField(args: IFieldInputGetInput) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    // const statusId = args.statusId
    const fieldResponse: IAxiosResponse = yield call(postRequest, `${company}/forms/${formId}/inputFields`, {});
    yield put(getInputFieldReturnAction({
      inputField: fieldResponse.data.data.filter((field: IField) => field.configName !== 'GAP'),
      inputFieldId: formId,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getDatabtableInputField(args: IFieldInputGetInput) {
  try {
    const company = args.companyId;
    const datatableId = args.formId;
    const fieldResponse: IAxiosResponse = yield call(postRequest, `${company}/databases/${datatableId}/records/fields`, {});
    yield put(getDatatableInputFieldReturnAction({
      inputField: fieldResponse.data.data,
      inputFieldId: datatableId,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormReference(args: IReferenceGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const ref: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/references?draft=true`, {});
    yield put(getReferenceListReturnAction({
      referenceList: ref.data.data,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* publishAutomation(args: IAutomationPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishAutomationReturnAction())
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(publishAutomationErrorAction(JSON.stringify(error.response.data.message)))
  }
}

export function* getStatusList(args: ISlaStatusesInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/slaAndStatuses?draft=true`, {});

    const sla: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/sla?draft=true`, {});
    yield put(getSlaStatusReturnAction({
      slaStatusList: SanitizeSlas.sanitizeIncomingSla(req.data.data, sla.data.data),
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getAutomationFieldInput(args: IFormFieldGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/automationFieldInputs`, {});
    yield put(getAutomationInputFieldReturnAction({ automationFieldInput: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getAutomationPlaceholders(args: IFormFieldGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/placeholders`, {});
    yield put(getAutomationPlaceholdersReturnAction({ automationPlaceholders: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getPortals(args: { type: string; companyId: string; }) {
  try {
    const companyId = args.companyId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/portal/search`, {});
    yield put(getPortalsReturnAction({ portals: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* automationSagas() {
  yield takeLatest(GET_AUTOMATION_LIST, getAutomationList);
  yield takeLatest(CREATE_AUTOMATION, createAutomation);
  yield takeLatest(UPDATE_AUTOMATION, updateAutomation);
  yield takeLatest(DELETE_AUTOMATION, deleteAutomation);
  yield takeLatest(GET_AUTOMATION_CONTENT, getAutomationContent);
  yield takeLatest(GET_FORM_FIELD, getFormField);
  yield takeLatest(GET_PUBLISH_FORM, getPublishedForm);
  yield takeEvery(GET_FORM_STATUS, getFormStatusList);
  yield takeLatest(GET_BP_SELECTED_FORM_FIELD, getBPSelectedFormField);
  yield takeEvery(GET_INPUT_FIELD, getInputField);
  yield takeLatest(GET_REFERENCES, getFormReference);
  yield takeEvery(GET_DATATABLE_INPUT_FIELD, getDatabtableInputField);
  yield takeEvery(PUBLISH_AUTOMATION, publishAutomation);
  yield takeEvery(GET_SLA_STATUS, getStatusList);
  yield takeEvery(GET_AUTOMATION_FIELD_INPUT, getAutomationFieldInput);
  yield takeEvery(GET_AUTOMATION_PLACEHOLDERS, getAutomationPlaceholders);
  yield takeEvery(GET_PORTALS, getPortals);
}

// All sagas to be loaded
export default automationSagas;

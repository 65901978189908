import { GET_DATATABLE_LIST, GET_DATATABLE_LIST_RETURN } from './constant'
import { IDatatableListGetDetailInput } from './interface/input/datatable-list-get'
import { IDatatableListGetReponse } from './interface/response/datatable-list-get'

export const getDatatablesListAction = (payload: IDatatableListGetDetailInput) => {
  return {
    type: GET_DATATABLE_LIST,
    ...payload
  }
}

export const getDatatablesListReturnAction = (payload: IDatatableListGetReponse) => {
  return {
    type: GET_DATATABLE_LIST_RETURN,
    ...payload
  }
}




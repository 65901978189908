import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse } from '../../api-request';
import { setStatusCodeAction } from '../../../component/error/action';
import { IReferenceFieldGetInput } from './interface/input/reference-field-get';
import { GET_REFERENCED_FIELD_LIST, GET_SELECTED_BP_STATUS_LIST, GET_FROM_REF_FIELD_LIST } from './constant';
import { getReferencedFieldLstReturnAction, getSelectedBpStatusListReturnAction, getFromRefFieldListReturnAction } from './action';
import { ISelectedBpStatusGetInput } from './interface/input/selected-bp-status-get';

export function* getReferencedFieldList(args: IReferenceFieldGetInput) {
  try {
    const companyId = args.company;
    let req: IAxiosResponse = { data: { data: [] } }
    let sourceId = '';
    if (args.dataType === 'database') {
      const databaseId = args.databaseId;
      sourceId = args.databaseId;
      if (databaseId) req = yield call(getRequest, `${companyId}/databases/${databaseId}/fields`, {});
    }
    if (args.dataType === 'document') {
      const formId = args.formId;
      sourceId = formId;
      if (formId) req = yield call(getRequest, `${companyId}/forms/${formId}/fields?draft=false`, {});
    }

    yield put(getReferencedFieldLstReturnAction({
      referenceFieldList: req.data.data,
      sourceId: sourceId,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getSelectedBpStatusList(args: ISelectedBpStatusGetInput) {
  try {
    const companyId = args.company;
    let req: IAxiosResponse = { data: { data: [] } }
    if (args.dataType === 'document') {
      const formId = args.formId;
      if (formId) req = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=false`, {});
    }
    yield put(getSelectedBpStatusListReturnAction({
      selectedBpStatusList: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getFromRefFieldList(args: IReferenceFieldGetInput) {
  try {
    const companyId = args.company;
    let req: IAxiosResponse = { data: { data: [] } };
    let sourceId = '';
    if (args.dataType === 'database') {
      const databaseId = args.databaseId;
      sourceId = databaseId;
      if (databaseId) req = yield call(getRequest, `${companyId}/databases/${databaseId}/fields`, {});
    }
    if (args.dataType === 'document') {
      const formId = args.formId;
      sourceId = formId;
      if (formId) req = yield call(getRequest, `${companyId}/forms/${formId}/fields?draft=false`, {});
    }
    if (args.dataType === 'user') {
      sourceId = 'user';
      req = yield call(getRequest, `${companyId}/userFields`, {});
    }
    yield put(getFromRefFieldListReturnAction({
      fromRefFieldList: req.data.data,
      sourceId: sourceId
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* permissionSagas() {
  yield takeLatest(GET_REFERENCED_FIELD_LIST, getReferencedFieldList);
  yield takeLatest(GET_SELECTED_BP_STATUS_LIST, getSelectedBpStatusList);
  yield takeEvery(GET_FROM_REF_FIELD_LIST, getFromRefFieldList);
}

// All sagas to be loaded
export default permissionSagas;
import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import SignatureCanvas from 'react-signature-canvas'
import { Button, Image } from 'semantic-ui-react-bpm';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../reducers';
import { uploadFile, UploadedFile, UploadedFileIsValid } from '../../../../../api-request/upload';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { FileProps } from '../../../service/type/file';
import { TValidationType } from '../../../service/field-properties.handler';

const SignatureType: React.FC<AcceptedProps> = (props) => {
  const name = props.getFieldId();
  const [value, setValue] = useState(null as UploadedFile | null);
  const [errorUpload, setErrorUpload] = useState('');

  const [refresher, setRefresher] = useState(1);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const canvasRef = useRef<any>(null);
  const elementProperties = new FieldProperties(name, props.forwardedRef, new FileProps())

  const { company } = useSelector((state: IRootState) => state.auth);

  const validateIfRequired = (value: UploadedFile | null) => {
    elementProperties.validate({ ...props }, TValidationType.onBlur);
  }

  const buildSignImage = (): File => {
    const mimeType = 'image/png';
    const signatureDataString = canvasRef.current.getTrimmedCanvas().toDataURL(mimeType);
    let byteString;
    if (signatureDataString.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(signatureDataString.split(',')[1]);
    } else {
      byteString = unescape(signatureDataString.split(',')[1]);
    }

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let realName = `${props.label.replace(/[^a-zA-Z0-9]/g, '')}.png`;
    const blob = new Blob([ia], { type: mimeType });

    return new File([blob], realName, { type: mimeType });
  }

  const saveImage = async () => {

    const signFile = buildSignImage();

    try {
      const uploadedFile = await uploadFile({ companyId: company, file: signFile });
      if (props.getFileUploaded) {
        props.getFileUploaded(uploadedFile);
        return;
      }
      setValue(uploadedFile);
      setErrorUpload('');
      elementProperties.setCurrent({ value: uploadedFile })
      manageAutomationCall(uploadedFile);
    } catch (e) {
      console.error(`Unable to upload signature: `, e);
      removeUploadFile();
      setErrorUpload(e);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }


    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

  }

  const removeUploadFile = () => {
    setValue(null);
    elementProperties.setCurrent({ value: null });
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    manageAutomationCall(null);
  }

  const manageAutomationCall = (value: any) => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value)
      }
    }
  }

  useEffect(() => {

    const currentFileString = elementProperties.current.value;

    if (!currentFileString) {
      return;
    }

    const currentFile = currentFileString;
    if (UploadedFileIsValid(currentFile)) {
      setValue(currentFile);
      console.log(`Set current value: `, JSON.stringify(currentFile));
    } else {
      console.log(`Current file is invalid, skipping: ${currentFile}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateIfRequired(value);
    }
    // eslint-disable-next-line
  }, [value])

  const partValue = (value: UploadedFile, readonly: boolean) => {
    return <div className="signature-image">
      <Image src={value.url} alt={value.name} style={styles.signImage}></Image>
      {!readonly && <Button onClick={() => removeUploadFile()}>Clear</Button>}
    </div>
  }

  const partNoValue = (readonly: boolean) => {
    if (readonly) {
      return <span> - </span>
    }
    return <div className='field-input' ref={(curRef) => {
      if (curRef) {
        setCanvasWidth(curRef.offsetWidth)
      }
    }}>
      <SignatureCanvas
        canvasProps={{ width: canvasWidth, height: 100, className: 'sigCanvas', id: name }}
        ref={(curRef) => {
          if (curRef) {
            if (!canvasRef.current) {
              setRefresher(refresher + 1);
            }
            curRef.getCanvas().style.width = '100%';
            curRef.getCanvas().style.border = '1px solid black';
            curRef.getCanvas().style.backgroundSize = `100% 100px`;
            return canvasRef.current = curRef;
          }
        }}
      />
      <div className='flex-wrapper sign-action'>
        <Button onClick={() => saveImage()}>Save</Button>
        <Button onClick={() => canvasRef.current.clear()} >Clear</Button>
      </div>
      {elementProperties.hasError() && props.showErrorMessage &&
        elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
        <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }} />
      }
    </div>
  }

  function isReadonly(): boolean {
    return props.accessType === IFieldAccessType.Readonly;
  }

  return <>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>

        {value ? partValue(value, isReadonly()) : partNoValue(isReadonly())}
        {errorUpload !== '' &&
          <span>{errorUpload}</span>
        }
      </>
    }
  </>
}

const styles: { [key: string]: CSSProperties } = {
  signImage: {
    width: '100%',
    objectFit: 'none',
    height: '100px',
    objectPosition: '0 0',
  }
}

export default SignatureType;
import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationGetInput } from './interface/input/application-get';
import { getApplicationListReturnAction } from './action';
import { setStatusCodeAction } from '../../error/action';
import { getRequest, IAxiosResponse } from '../../../common/api-request';
import { GET_APPLICATION_LIST } from './constant';

export function* getApplicationList(args: IApplicationGetInput) {
  try {
    const company = args.company;
    const formList: IAxiosResponse = yield call(getRequest, `${company}/documents/forms?case=list&offset=${0}&limit=${0}`, {});
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/formsAndApps`, {});
    yield put(getApplicationListReturnAction({ applicationList: req.data.data.apps, individualBp: req.data.data.individualForms, formList: formList.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* Saga() {
  yield takeLatest(GET_APPLICATION_LIST, getApplicationList);
}

// All sagas to be loaded
export default Saga;
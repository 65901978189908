import {
  GET_BUSINESS_PROCESS_LIST,
  CREATE_BUSINESS_PROCESS,
  GET_BUSINESS_PROCESS,
  UPDATE_BUSINESS_PROCESS,
  DELETE_BUSINESS_PROCESS_CONFIRM,
  UPLOAD_LOGO,
  GET_FORM_LOGO,
  GET_BACKUP_REFS,
  DEACTIVATE_BUSINESS_PROCESS
} from './constant';
import { IFormGetListInput, IFormDataInput } from './interface/input/form-get';
import { getRequest, postRequest, putRequest, deleteRequest, IAxiosResponse } from '../../../../../../common/api-request';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setStatusCodeAction } from '../../../../../error/action';
import {
  getBusinessProcessListReturnAction,
  getBusinessProcessListErrorAction,
  createBusinessProcessReturnAction,
  createBusinessProcessErrorAction,
  getBusinessProcessErrorAction,
  getBusinessProcessReturnAction,
  updateBusinessProcessReturnAction,
  updateBusinessProcessErrorAction,
  confirmDeleteBusinessProcessReturnAction,
  confirmDeleteBusinessProcessErrorAction,
  uploadLogoReturnAction,
  uploadLogoErrorAction,
  getFormLogoReturnAction,
  getFormLogoErrorAction,
  getBackupRefsReturnAction,
  getBackupRefsErrorAction,
  deactivateBusinessProcessReturnAction
} from './action';
import { IForm } from './interface/form';
import { IFormCreateInput } from './interface/input/form-create';
import { IFormUpdateInput, IFormDeactivateInput } from './interface/input/form-update';
import { IFormConfirmDeleteInput } from './interface/input/form-confirm-delete';
import { ILogoUploadInput } from './interface/input/logo-upload';
import { IFormLogoGetInput } from './interface/input/form-logo-get';
import { IBackupRefsGetInput } from './interface/input/backup-refs';

export function* getBusinessProcessList(args: IFormGetListInput) {
  try {
    const company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms?limit=0&draft=true`, {});
    yield put(getBusinessProcessListReturnAction({ data: req.data.data as IForm[] }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getBusinessProcessListErrorAction());
  }
}

export function* getBusinessProcessData(args: IFormDataInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}?draft=true`, {});
    yield put(getBusinessProcessReturnAction({ data: req.data.data as IForm }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getBusinessProcessErrorAction());
  }
}

export function* createBusinessProcess(args: IFormCreateInput) {
  try {
    const company = args.company;
    const data = args.data;
    const req: IAxiosResponse = yield call(postRequest, `${company}/forms`, data);
    const form = req.data.data as IForm;
    yield put(createBusinessProcessReturnAction({ data: form }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(createBusinessProcessErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* updateBusinessProcess(args: IFormUpdateInput) {
  try {
    const company = args.company;
    const data = args.data;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}`, data);
    yield put(updateBusinessProcessReturnAction({ data: req.data.data as IForm }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(updateBusinessProcessErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* deleteBusinessProcess(args: IFormConfirmDeleteInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(deleteRequest, `${company}/forms/${formId}`, {});
    yield put(confirmDeleteBusinessProcessReturnAction({ formId: formId }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(confirmDeleteBusinessProcessErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* uploadLogo(args: ILogoUploadInput) {
  try {
    const req: IAxiosResponse = yield call(postRequest, `/uploads`, args.data);
    yield put(uploadLogoReturnAction({ file: req.data.data }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(uploadLogoErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getFormLogo(args: IFormLogoGetInput) {
  try {
    const fileId = args.fileId;
    const req: IAxiosResponse = yield call(getRequest, `/uploads/${fileId}`, { responseType: 'blob' });
    yield put(getFormLogoReturnAction({ file: URL.createObjectURL(req.data as any) }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getFormLogoErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getBackupRefs(args: IBackupRefsGetInput) {
  try {
    if (args.query) {
      const req: IAxiosResponse = yield call(getRequest, `${args.company}/backup/refs?${args.query}`, {});
      yield put(getBackupRefsReturnAction({ data: req.data.data }));
    } else {
      yield put(getBackupRefsReturnAction({ data: [] }));
    }
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getBackupRefsErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* deactivateBusinessProcess(args: IFormDeactivateInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(putRequest, `${company}/forms/${formId}/enabled`, { enabled: args.enabled });
    yield put(deactivateBusinessProcessReturnAction({
      deactivateSuccess: true,
      enabled: args.enabled,
      formUpdated: args.formId
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(deactivateBusinessProcessReturnAction({
      deactivateSuccess: false,
      enabled: args.enabled,
      formUpdated: args.formId
    }));
  }
}

export function* businessProcessSagas() {
  yield takeLatest(GET_BUSINESS_PROCESS_LIST, getBusinessProcessList);
  yield takeLatest(GET_BUSINESS_PROCESS, getBusinessProcessData);
  yield takeLatest(CREATE_BUSINESS_PROCESS, createBusinessProcess);
  yield takeLatest(UPDATE_BUSINESS_PROCESS, updateBusinessProcess);
  yield takeLatest(DELETE_BUSINESS_PROCESS_CONFIRM, deleteBusinessProcess);
  yield takeLatest(UPLOAD_LOGO, uploadLogo);
  yield takeLatest(GET_FORM_LOGO, getFormLogo);
  yield takeLatest(GET_BACKUP_REFS, getBackupRefs);
  yield takeLatest(DEACTIVATE_BUSINESS_PROCESS, deactivateBusinessProcess);
}

// All sagas to be loaded
export default businessProcessSagas;

export const GET_ADMIN_HISTORY_LOGS = 'app/admin-module/GET_ADMIN_HISTORY_LOGS';
export const GET_ADMIN_HISTORY_LOGS_RETURN = 'app/admin-module/GET_ADMIN_HISTORY_LOGS_RETURN';
export const GET_ADMIN_HISTORY_LOGS_ERROR = 'app/admin-module/GET_ADMIN_HISTORY_LOGS_ERROR';
export const FILTER_ADMIN_HISTORY = 'app/admin-module/FILTER_ADMIN_HISTORY';
export const FILTER_ADMIN_HISTORY_RETURN = 'app/admin-module/FILTER_ADMIN_HISTORY_RETURN';
export const FILTER_ADMIN_HISTORY_ERROR = 'app/admin-module/FILTER_ADMIN_HISTORY_ERROR';

export const keysOfEnumType = [
  'items',
  'forms',
  'groups',
  'users',
  'Users/Groups',
  'actions',
  'uniqueRecords'
]
import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '../..';
import { ICommentDeltaFields } from '../../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils } from '../../../../manage-history';
import { FieldConfigChange } from '../../../utils/field-config-change';

export class BPDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.BusinessProcess;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;
    let showUpdateTextOnly = false;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (targetDelta) {
      const keysToDisplay = ['abbr', 'name', 'description', 'icon'];
      const deltaKeys = Object.keys(targetDelta).filter(e => keysToDisplay.includes(e));

      deltaKeys.forEach(key => {
        if (key === 'icon') {
          deltaText = `${commentSender} has changed icon for BP (name: ${targetInstance.name})`;
          showUpdateTextOnly = true;
        } else {
          newDelta[key] = targetDelta[key];
          oldDelta[key] = targetInstance[key];
          deltaText = `${commentSender} has updated BP (name: ${targetInstance.name})`;
        }
      });
    }


    if (Object.keys(newDelta).length > 0) {
      deltaArray = FieldConfigChange.format(newDelta, oldDelta, oldDelta);
    }

    return { delta: deltaArray, text: deltaText, showUpdateTextOnly };
  }
}

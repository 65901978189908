import { IReferencedFieldCondition, IDataFitersConfig } from '../interface/custom-field-detail'
import { v4 as uuid } from 'uuid';

interface IPrepareFilterConfig {
  condition: IDataFitersConfig;
  formDetailId: string;
  referenceId: string;
}

const splitKeypathValue = (keyPath: string) => {
  if (keyPath && keyPath.indexOf('fields.') > -1) {
    return keyPath.split('.')[1];
  }
  return keyPath;
}

const isObjectAndHasPropertyOf = (data: any, propertyToCheck: string) => {
  return typeof data === 'object' && (Object.keys(data).indexOf(propertyToCheck)) > -1;
}

export const prepareFilterConfig = (data: IPrepareFilterConfig): IReferencedFieldCondition => {
  const { condition, referenceId, formDetailId } = { ...data };

  const isCompareFieldRef = isObjectAndHasPropertyOf(condition.value, 'referencedKeyPath');
  const isFieldValue = isObjectAndHasPropertyOf(condition.value, 'keyPath');
  const isFieldKeypath = isObjectAndHasPropertyOf(condition.value, 'fieldId');

  let fromBp = formDetailId || '';
  let compareFieldFromBp = referenceId;
  let compareFieldId = splitKeypathValue(condition.keyPath);
  let compareValue = '';
  let compareType = 'field';
  let fieldId = isObjectAndHasPropertyOf(condition.value, 'fieldId') ? condition.value.fieldId : '';
  let compareFieldRef = '';
  let tableId = '';

  if (isCompareFieldRef) {

    if (condition.value.keyPath.split('.').length > 2) {
      fieldId = splitKeypathValue(condition.keyPath);
      if (isFieldValue && condition.value.keyPath.split('.').length === 3) {
        // eslint-disable-next-line
        const [label, mainId, subId] = condition.value.keyPath.split('.');
        if (mainId && subId) {
          tableId = mainId;
          compareFieldId = subId;
        }
      }
    } else {
      fieldId = splitKeypathValue(condition.keyPath);
      compareFieldId = splitKeypathValue(condition.value.keyPath);
    }

    compareType = 'field';
    compareFieldRef = splitKeypathValue(condition.value.referencedKeyPath);

    if (condition.style.dataSource === 'reference') {
      fromBp = referenceId;
      compareFieldFromBp = formDetailId || '';
    }

  } else {

    if (condition.style.dataSource === 'reference') {
      fromBp = referenceId;
      compareFieldFromBp = formDetailId || '';
      fieldId = splitKeypathValue(condition.keyPath);
      compareType = 'value';
      compareValue = condition.value;

      if (isFieldKeypath) {
        compareFieldId = condition.value.fieldId;
        compareValue = '';
        compareType = 'field';
      } else if (isFieldValue) {
        compareFieldId = splitKeypathValue(condition.value.keyPath);
        compareType = 'field';
        compareValue = '';
      }

    } else {
      fieldId = isFieldValue ? splitKeypathValue(condition.value.keyPath) : '';
      compareValue = isFieldValue ? condition.value : '';
      compareType = isFieldValue ? 'field' : 'value';
      compareFieldId = splitKeypathValue(condition.keyPath);
      if (isFieldValue && condition.value.keyPath.split('.').length === 3) {
        // eslint-disable-next-line
        const [label, mainId, subId] = condition.value.keyPath.split('.');
        if (mainId && subId) {
          tableId = mainId;
          fieldId = subId;
        }
      }
    }
  }

  return {
    tableId: tableId,
    fromBp: fromBp,
    fieldId: fieldId,
    compareField: {
      fromBp: compareFieldFromBp,
      fieldId: compareFieldId,
      fieldRef: compareFieldRef
    },
    compareType: compareType,
    compareValue: compareValue,
    operator: condition.operator,
    id: uuid()
  }
}
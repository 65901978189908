import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { IRender } from '.';
import DropdownPopperOption from '../../../general/dropdown-popper-option';

class BoolFieldValue {

  name = 'bool';

  Render: React.FC<IRender> = (props) => {
    const [refresher, setRefresher] = useState(0);

    const onChange = (value: string) => {
      if (props.forwardedRef.current[props.valueId] &&
        typeof props.forwardedRef.current[props.valueId] === 'object'
        && props.forwardedRef.current[props.valueId].hasOwnProperty('value')) {
        props.forwardedRef.current[props.valueId].value = value;
      } else {
        props.forwardedRef.current[props.valueId] = value;
      }
      setRefresher(refresher + 1)

      if (props.throwValueOutside) {
        props.throwValueOutside();
      }
    }

    const getValue = () => {
      let value = '';
      if (props.forwardedRef.current[props.valueId] &&
        typeof props.forwardedRef.current[props.valueId] === 'object'
        && props.forwardedRef.current[props.valueId].hasOwnProperty('value')) {
        value = props.forwardedRef.current[props.valueId].value;
      } else {
        value = props.forwardedRef.current[props.valueId];
      }
      return value;
    }

    return (
      <div>
        <Dropdown
          popperContainer={DropdownPopperOption}
          fluid
          selection
          clearable
          selectOnBlur={false}
          value={getValue()}
          options={[
            { key: 0, value: 'true', text: 'True' },
            { key: 1, value: 'false', text: 'False' },
          ]}
          onChange={(event, target) => { onChange(target.value as string) }}
        />
      </div>
    );
  }
}

export default BoolFieldValue;

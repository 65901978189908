import { useEffect, useState } from 'react';
import { IFormRelated, IFormRelatedField } from '../../interface/bp-relationship-field';

interface IProps {
  selectedId: string;
  dataSelected: any[];
  configuration: string;
  refName: React.MutableRefObject<HTMLInputElement>
}

export const useSetRelationConfigModalState = (props: IProps) => {
  const { dataSelected, selectedId, configuration, refName } = { ...props };

  const [headerField, setHeaderField] = useState<IFormRelatedField[]>([]);
  const [contentField, setContentField] = useState<IFormRelatedField[]>([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedData, setSelectedData] = useState<IFormRelated>({} as IFormRelated);

  useEffect(() => {
    if (selectedId) {
      const tempSelected = dataSelected.filter((relation: any) => relation.id === selectedId);
      if (tempSelected.length > 0) {
        setHeaderField(tempSelected[0].headerField);
        setContentField(tempSelected[0].fields);
        setSelectedData(tempSelected[0]);
        if (configuration === 'Label') {
          refName.current.value = tempSelected[0].name;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, selectedData]);

  return {
    ...props,
    headerField,
    setHeaderField,
    contentField,
    setContentField,
    selectedField,
    setSelectedField,
    selectedData,
    setSelectedData
  };
}
import React, { Fragment, useEffect, useState } from 'react';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { FieldProperties } from '../../../service/field-properties.service';
import { StringProps } from '../../../service/type/string';
import { elementStatus } from '../../../utils/element-status';
import { AcceptedProps } from '../../interface/accepted-props';
import 'react-phone-input-2/lib/style.css'
import { getExampleNumber, CountryCode } from 'libphonenumber-js';
import {
  setFocusedElement,
  getFocusedElement,
  focusSelectedElement,
  removeFocusedElement,
  setTableFocusedElement
} from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { TValidationType } from '../../../service/field-properties.handler';

const examples = require('libphonenumber-js/examples.mobile.json');

const PhoneType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();

  const [maxLength, setMaxLength] = useState<number>(0);
  const [refresher, setRefresher] = useState(0);
  const [validateTriggered, setValidateTriggered] = useState(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState('');

  const elementProperties = new FieldProperties(name, props.forwardedRef, new StringProps());
  const elementId = props.tableColumnRowIndex ? `phone-${name}-${props.tableColumnRowIndex}` : `phone-${name}`;

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'string' })
  }

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : maxLength ? maxLength : 0,
      maxLength: maxLength,
    };
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(refresher + 1);
    setValidateTriggered(true)
    if (props.triggerFilter) props.triggerFilter('');

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (props.tableColumnRowIndex !== undefined) {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ value: defaultValueString });
      setRefresher(refresher + 1);
    }
  }

  const handleOnChange = (value: string) => {
    const countryCode = props.inputConfig.config.phoneCountryCode ?? '';
    const exampleNumber = getExampleNumber(countryCode as CountryCode, examples);
    if (exampleNumber) {
      const number = exampleNumber.nationalNumber.toString();
      setMaxLength(number.length);
    }
    if (value !== '') {
      elementProperties.setCurrent({ value: value });
    } else {
      elementProperties.setCurrent({ value: '' });
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    setRefresher(refresher + 1);
  }

  const handleOnClick = () => {
    if (typeof props.tableColumnRowIndex === 'number') {
      focusInput(elementId);
    } else {
      focusInput(elementId);
    }
  }

  const removePlusInValue = (value: string) => {
    return value.substr(1, value.length);
  }

  const handleOnBlur = (event: any) => {
    validateInput();
    if (focusedElement && focusedElement === elementId) {
      removeFocusedElement();
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, removePlusInValue(event.target.value))
      }
    }
  }

  const setPhoneMaxLength = () => {
    const phoneCountryCode = props.inputConfig.config.phoneCountryCode ?? '';
    const exampleNumber = getExampleNumber(phoneCountryCode as CountryCode, examples);
    if (exampleNumber) {
      const number = exampleNumber.nationalNumber.toString();
      setMaxLength(number.length);
    }
  }

  const getCountryCode = () => {
    const phoneCountryCode = props.inputConfig.config.phoneCountryCode ?? '';
    const exampleNumber = getExampleNumber(phoneCountryCode as CountryCode, examples);
    if (exampleNumber && exampleNumber.countryCallingCode) {
      return `+${exampleNumber.countryCallingCode}`;
    }
    return '';
  }

  const isDisabled = () => {
    return typeof props.inputConfig.config.phoneCountryCode == 'undefined' || props.inputConfig.config.phoneCountryCode === null || props.accessType === IFieldAccessType.Readonly;
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(refresher + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.isAddRecord
  ])

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    if (focusedElement && focusedElement === elementId) {
      focusSelectedElement(focusedElement);
    }

    setPhoneCountryCode(getCountryCode() ?? '');
    setPhoneMaxLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div className={`ui labeled icon input single ${props.configName} ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: props.multiline || false,
          required: props.accessType === IFieldAccessType.Required,
          fieldValueObject: elementProperties
        })}`}>
          <div className="ui labeled input">
            <div className="ui label label">{phoneCountryCode}</div>
            <input type="text"
              id={`${elementId}`}
              className={'phone-input-field'}
              onBlur={handleOnBlur}
              onChange={(e) => handleOnChange(e.target.value)}
              name={name}
              disabled={isDisabled()}
              ref={(curRef: any) => {
                if (curRef) {
                  if (props.isFocus) {
                    curRef.focus();
                  }
                  if (focusedElement && focusedElement === elementId) {
                    focusSelectedElement(focusedElement);
                  }
                  const elementValue = props.inputConfig.config.phoneCountryCode ? elementProperties.current.value : ''
                  elementProperties.setCurrent({ value: elementValue, fieldType: 'string' });
                  curRef.value = elementValue;
                  return curRef;
                }
              }}
              maxLength={maxLength}
              style={{ textAlign: props.inputConfig.config.alignment || 'left' }}
              onClick={handleOnClick}
              placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
            />
          </div>
        </div>
        {elementProperties.hasError() && props.showErrorMessage &&
          elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
          <Fragment>
            <small style={{ display: 'flex' }} />
            <small className='field error'
              dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
            />
          </Fragment>
        }
      </>
    }
  </>
}

export default React.memo(PhoneType, (prevProps: AcceptedProps, nextProps: AcceptedProps) => {
  return prevProps.forwardedRef.current[prevProps.id].value === nextProps.forwardedRef.current[nextProps.id].value;
});
import React from 'react';
import { IFormStatus } from '../interface/form-status';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';
import ToolTip from '../../../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';

interface ITableHeaderDetail {
  buttonColor: string;
  isShownHamburger?: boolean;
  iconColor?: string;
  labelColor: string;
  formStatus: IFormStatus;
  editStatus(formStatus: IFormStatus): void;
  formStatusIndex: number;
  sortStatus(drag: { type: string, dragIndex: number, dragItem: IFormStatus }, dropAreaIndex: number): void;
}

const TableHeaderDetail: React.FC<ITableHeaderDetail> = (props) => {

  // eslint-disable-next-line
  const [dropProps, dropRef] = useDrop({
    accept: 'formStatus',
    drop: item => {
      props.sortStatus(item as any, props.formStatusIndex)
    }
  })

  // eslint-disable-next-line
  const [draggedObject, dragRef] = useDrag({
    item: {
      type: 'formStatus',
      dragIndex: props.formStatusIndex,
      dragItem: props.formStatus
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })
  return <th style={{ overflowWrap: 'break-word' }} ref={props.isShownHamburger ? dragRef : undefined} className={props.buttonColor}>
    <div style={{ whiteSpace: 'normal', display: 'flex', alignItems: 'center' }} ref={props.isShownHamburger ? dropRef : undefined} className="ui label">
      {/* <Image src={icons.white.dragIcon} className={`drag-icon ${props.isShownHamburger ? '' : 'hidden'}`} /> */}
      <SVGWrapper iconName='flag' width={20} height={20} color={props.iconColor} />
      <label className={`${props.labelColor} header-text`} onClick={() => props.editStatus(props.formStatus)} >{props.formStatus.name.toUpperCase()}</label>
      <ToolTip position='bottom center'
        popupMessage='Configure'
        popupTriggerComponent={
          <i aria-hidden="true" style={{ cursor: 'pointer' }} id={`table-header-${props.formStatus.id}`} className={`ellipsis vertical icon ${props.iconColor}`} onClick={() => props.editStatus(props.formStatus)} />
        }
      />
    </div>
  </th>
}

export default TableHeaderDetail;
import React from 'react';
import { AcceptedProps } from './interface/accepted-props';
import DateTimeType from './type/date-time/datetime';
import DateTimeArrayType from './type/date-time/datetime-array';

const DateObject: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <DateTimeArrayType {...props} />;
  } else {
    return <DateTimeType {...props} />
  }
}

export default DateObject;
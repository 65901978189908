import { useEffect, useState } from 'react';
import { getRequest } from '../../../../../../../../../../../common/api-request';
import { IAutomationPublishStatusList } from '../../../reducer';

interface IProps {
  publishedFormStatusList: IAutomationPublishStatusList;
  company: string;
}

export const useGetFormStatusOption = (props: IProps) => {
  const [detailFormStatusList, setDetailFormStatusList] = useState<IAutomationPublishStatusList>({});

  const getFormStatus = async (formId: string) => {
    try {
      const response = await getRequest(`${props.company}/forms/${formId}/statuses?draft=false&load=actions`, {})
      const tempInputFieldList = { ...detailFormStatusList };
      tempInputFieldList[formId] = response.data.data;
      setDetailFormStatusList(tempInputFieldList);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setDetailFormStatusList(props.publishedFormStatusList);
  }, [props.publishedFormStatusList])

  return { getFormStatus, detailFormStatusList };
}
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormBuilderSection } from '../../interface/section';
import { formBuilderFieldType } from '../../../../../../../../common/field/type';
import ReferencedField from '../../../../../../../../common/field/type/reference-form';
import AutopopulatedField from '../../../../../../../../common/field/type/autopopulated';
import { IConfigName, IFieldTypeObj } from '../../../../../../../../common/field/type/interface/field-type-object';
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd';
import { icons } from '../../../../../../../../common/icons';
import ToolTip from '../../../../../../../../common/general/tooltip';
import { DragFieldObject } from '../../section-field-manager/interface/section-update-config';

interface IFieldProps {
  field: ICustomFieldDetail;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  section: IFormBuilderSection
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  showUnlink: boolean;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  rowIndex: number;
  currentFieldIndex: number;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const Field: React.FC<IFieldProps> = (props) => {
  // eslint-disable-next-line
  const [draggedObject, dragRef] = useDrag({
    item: {
      type: 'moveField',
      field: props.field,
      fieldRowIndex: props.rowIndex,
      fieldIndex: props.currentFieldIndex,
      fieldSectionId: props.section.sectionId
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  // eslint-disable-next-line
  const [dropProps, dropRef] = useDrop({
    accept: 'moveField',
    drop: (item: DragFieldObject) => {
      props.moveField(item, props.section.sectionId || '', props.rowIndex, props.currentFieldIndex)
    }
  })

  let isConstraint = false;
  if (props.field.flags && props.field.flags.indexOf('constraint') > -1) {
    isConstraint = true;
    props.field.configName = IConfigName.Referenced;
  }

  let isUsedInAutomation = false;
  if (props.field.flags && props.field.flags.indexOf('automation') > -1) {
    isUsedInAutomation = true;
  }

  let formFieldObj = formBuilderFieldType.filter((field: IFieldTypeObj) => field.defaultData.configName === props.field.configName);
  if (props.field.configName === IConfigName.Referenced) {
    formFieldObj = [ReferencedField];
  }

  if (props.field.type === 'autopopulated') {
    formFieldObj = [AutopopulatedField];
  }

  let icon = icons.blue.font;
  if (formFieldObj && formFieldObj.length > 0) {
    icon = formFieldObj[0].icon.blue;
  }

  const viewIcon = () => {
    let view = <Image src={icon} className='field-icon' />;
    if (props.field.config && props.field.config.data && props.field.config.data.formId) {
      let formId = props.field.config.data.formId;
      if (props.bpImage && props.bpImage[formId] && props.bpImage[formId].seq) {
        view = <div className={`sequence-icon`}>{props.bpImage[formId].seq}</div>
      }
      if (props.bpImage && props.bpImage[formId] && props.bpImage[formId].src) {
        view = <Image src={props.bpImage[formId].src} />;
      }
    }

    return view;
  }

  const notEditable = props.field.type === 'spacer' ? true : false;

  return <div ref={dropRef}>
    <div ref={dragRef}>
      <div
        ref={(ref: any) => {
          if (ref && ref.offsetHeight > 40) {
            ref.className = ref.className + ' multi'
          }
        }}
        className={`field ${isConstraint || isUsedInAutomation ? 'constraint' : ''}`} >
        {viewIcon()}
        {notEditable
          ? <span>{props.field.label ? props.field.label : 'Untitled'}</span>
          : <span className={`field-label ${isConstraint || isUsedInAutomation ? 'constraint' : ''}`} onClick={() => {
            props.editFieldConfig(props.field, props.section.sectionId || '');
          }}>{props.field.label ? props.field.label : 'Untitled'}</span>
        }
        <div className='field-action'>
          {!isConstraint && !isUsedInAutomation &&
            <ToolTip position='left center'
              popupMessage='Delete'
              popupTriggerComponent={
                <Image src={icons.blue.delete}
                  style={{
                    width: '12px',
                    cursor: 'pointer',
                    marginTop: '2px',
                    display: 'inline-block',
                    float: 'right'
                  }} className='trash icon alternate outline'
                  onClick={() => { props.openDeleteConfirmationModal(props.field, props.section.sectionId || '') }}
                />
              }
            />
          }
          {props.showUnlink &&
            <Image
              title='Unlink'
              style={{
                float: 'right',
                width: '16px',
                marginTop: '2px',
                cursor: 'pointer'
              }} src={icons.blue.unlink}
              onClick={() => {
                if (props.section.style !== 'plain') {
                  props.unlinkField(props.section.sectionId || '', props.field, props.rowIndex, props.currentFieldIndex);
                } else {
                  props.unlinkFieldToPlainSection(props.section.sectionId || '', props.field, props.rowIndex, props.currentFieldIndex);
                }
              }}
            />
          }
          {props.field.confidential && <i className="lock icon" style={{ color: '#bdbdbd' }} />}
        </div>
      </div>
    </div>
  </div>
}

export default Field;

interface IUtils {
  actionType: "create" | "edit";
  hasUploadedFile: boolean;
  lineCount: number;
  contentHeight: number;
  mobile: boolean;
}

export const defineEditorHeight = ({
  actionType,
  hasUploadedFile,
  lineCount,
  contentHeight,
  mobile
}: IUtils) => {
  let maxLineCount = hasUploadedFile ? 2 : 4;
  let maxContentHeight = hasUploadedFile ? 57 : 60;
  let contentAreaHeight = hasUploadedFile ? 130 : 85;
  let inputHeight = 170;
  const contentAreaContainer = document.getElementById('chat-content-area');
  const editorContainer = document.getElementById('chat-editor');
  const chatContainer = document.getElementById('chat-content');

  if (mobile) {
    maxLineCount = hasUploadedFile ? 2 : 1;
    maxContentHeight = hasUploadedFile ? 57 : 38;
    inputHeight = hasUploadedFile ? 155 : 100;
    if (actionType === 'edit') {
      inputHeight = 200;
      maxLineCount = hasUploadedFile ? 4 : 7;
      contentAreaHeight = hasUploadedFile ? 130 : 100;
      maxContentHeight = hasUploadedFile ? 57 : 85;
    }
  }

  if (contentAreaContainer && editorContainer && chatContainer) {
    if (contentHeight > maxContentHeight && lineCount > maxLineCount) {
      contentAreaContainer.style.setProperty('height', `${contentHeight + contentAreaHeight}px`, 'important');
    } else {
      contentAreaContainer.style.setProperty('height', `${inputHeight}px`, 'important');
    }
  }
}
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Image } from 'semantic-ui-react-bpm';
import { dataTableFieldType } from '../../../../../common/field/type';
import referencedField from '../../../../../common/field/type/reference-form';
import { IDatatableRecord } from '../../../../../common/custom-field-config/interface/datatable-record';
import { useRouteMatch } from 'react-router-dom';
import { ICustomFieldDetail, IConfig } from '../../../../../common/custom-field-config/interface/custom-field-detail';
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd';
import { icons } from '../../../../../common/icons';
import ToolTip from '../../../../../common/general/tooltip';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';

interface IColumnFieldRefs {
  customFieldObject: ICustomFieldDetail
  columnId: number;
  handleDelete(fieldId: string, rowId: string): void;
  keys: number;
  forwardedRef: any;
  datatable: IDatatableRecord[];
  forUpdateDataTableFields: ICustomFieldDetail[];
  openCustomField(field: ICustomFieldDetail): void;
  hasDatatableRecords: boolean;
  changeCustomFieldListSequence(draggedItem: { data: ICustomFieldDetail }, droppedItemId: string): void;
  setFieldWithCurrentRefs(): void;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchParams;
}

interface IRouteMatchParams {
  dataTableId: string;
}

interface DragItem {
  index: number
  id: string
  type: string
}

const DataTableRowAttribute: React.FC<IColumnFieldRefs> = (props) => {
  const rowRef = useRef<HTMLTableRowElement>(null)
  let match: IRouteMatch = useRouteMatch();
  const [refresher, setRefresher] = useState(0)
  const [defaultColumnHead, setDefaultColumnHead] = useState('');
  const [isDefaultSelectedType, setIsDefaultSelectedType] = useState(false);

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'row',
    drop: selectedItem => {
      props.changeCustomFieldListSequence(selectedItem as any, props.customFieldObject.id || '');
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'row', data: props.customFieldObject },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  dragRef(dropRef(rowRef))

  const optionsAttribute = [{ key: 1, text: 'Attribute', value: 'Attribute' }];
  const optionsRelDataType = [
    { key: 1, text: 'Datatable', value: 'database' },
    { key: 2, text: 'Document', value: 'document' },
    { key: 3, text: 'Users Table', value: 'user' },
    { key: 4, text: 'Business Processes', value: 'form' },

  ];

  if (props.datatable && props.datatable.length > 0) {
    optionsAttribute.push({ key: 2, text: 'Relation', value: 'Relation' })
  }

  const editFieldConfiguration = (fieldData: ICustomFieldDetail) => {
    const forUpdateFieldData = { ...fieldData };
    const label = props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading;
    const dataType = props.forwardedRef.current['row' + props.customFieldObject.id].relationType;
    const fieldType = props.forwardedRef.current['row' + props.customFieldObject.id].fieldType;
    const configName = props.forwardedRef.current['row' + props.customFieldObject.id].configName;
    const config = props.forwardedRef.current['row' + props.customFieldObject.id].config as IConfig;
    forUpdateFieldData.label = label;
    forUpdateFieldData.type = fieldType;
    forUpdateFieldData.configName = configName;
    if (!forUpdateFieldData.config) {
      forUpdateFieldData.config = {} as IConfig;
    }
    forUpdateFieldData.config = config || {};
    if (forUpdateFieldData.type === 'enum') {
      forUpdateFieldData.config.dataType = dataType;
    }
    props.openCustomField(forUpdateFieldData);
  }

  const handleFieldTypeChange = (value: IConfigName) => {
    props.forwardedRef.current['row' + props.customFieldObject.id].configName = value;
    let dataTypeSelectedConfig: any = {};
    if (value === IConfigName.Referenced) {
      dataTypeSelectedConfig = { ...referencedField };
    } else {
      dataTypeSelectedConfig = value ? dataTableFieldType.filter(field => {
        return field.defaultData && field.defaultData.configName === value
      })[0] : { defaultConfig: {}, defaultData: {} };
    }

    if (value === IConfigName.Dropdown) {
      props.forwardedRef.current['row' + props.customFieldObject.id].relationType = 'static';
    }

    props.forwardedRef.current['row' + props.customFieldObject.id].config = dataTypeSelectedConfig.defaultConfig as IConfig;
    props.forwardedRef.current['row' + props.customFieldObject.id].fieldType = dataTypeSelectedConfig.defaultData.type;
    setRefresher(refresher + 1);
    props.hasPageChangeRef.current.hasChange = true;
    props.setFieldWithCurrentRefs()
  }

  const handleRelationTypeChange = (value: 'document' | 'database' | 'user') => {
    if (value === 'user') {
      const config = props.forwardedRef.current['row' + props.customFieldObject.id].config;
      props.forwardedRef.current['row' + props.customFieldObject.id].config = { ...config }
    }
    props.forwardedRef.current['row' + props.customFieldObject.id].relationType = value;
    props.setFieldWithCurrentRefs();
  }

  useEffect(() => {
    if (match.params.dataTableId !== 'add-data-table') {
      let configName = props.customFieldObject.configName;
      let heading = props.customFieldObject.label;
      let fieldType = props.customFieldObject.type;
      let fieldId = props.customFieldObject.id
      let config = props.customFieldObject.config;
      setDefaultColumnHead(heading ? heading : '')
      props.forwardedRef.current['row' + props.customFieldObject.id].id = fieldId;
      props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading = heading ? heading : '';
      props.forwardedRef.current['row' + props.customFieldObject.id].fieldType = fieldType ? fieldType : '';
      props.forwardedRef.current['row' + props.customFieldObject.id].config = config;

      if (configName && configName === IConfigName.Referenced) {
        props.forwardedRef.current['row' + props.customFieldObject.id].dataType = 'Relation';
        if (config && config.dataType) {
          props.forwardedRef.current['row' + props.customFieldObject.id].relationType = config.dataType;
        }
        setIsDefaultSelectedType(true);
      } else {
        props.forwardedRef.current['row' + props.customFieldObject.id].dataType = 'Attribute';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.dataTableId]);

  useEffect(() => {
    setDefaultColumnHead(props.customFieldObject.label || '');
    props.forwardedRef.current['row' + props.customFieldObject.id].pk = false;
    props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading = props.customFieldObject.label || '';
    props.forwardedRef.current['row' + props.customFieldObject.id].config = props.customFieldObject.config;
    let dataType = 'Attribute';
    if (props.customFieldObject.configName === IConfigName.Referenced) {
      dataType = 'Relation';
    }
    props.forwardedRef.current['row' + props.customFieldObject.id].dataType = dataType;
    props.forwardedRef.current['row' + props.customFieldObject.id].fieldType = props.customFieldObject.type;
    props.forwardedRef.current['row' + props.customFieldObject.id].configName = props.customFieldObject.configName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customFieldObject]);

  const fieldTypeValue = props.forwardedRef.current['row' + props.customFieldObject.id] ? props.forwardedRef.current['row' + props.customFieldObject.id].configName : undefined;
  const dataTypeValue = props.forwardedRef.current['row' + props.customFieldObject.id] ? props.forwardedRef.current['row' + props.customFieldObject.id].dataType : undefined;
  const relationTypeValue = props.forwardedRef.current['row' + props.customFieldObject.id] ? props.forwardedRef.current['row' + props.customFieldObject.id].relationType : undefined;

  let dataTypeError = false;
  let configNameError = false;
  let columnHeadingError = false;
  if (props.forwardedRef.current['row' + props.customFieldObject.id]
    && props.forwardedRef.current['row' + props.customFieldObject.id].error
  ) {
    columnHeadingError = props.forwardedRef.current['row' + props.customFieldObject.id].error.columnHeading
    dataTypeError = props.forwardedRef.current['row' + props.customFieldObject.id].error.dataType
    configNameError = props.forwardedRef.current['row' + props.customFieldObject.id].error.configName
  }

  return (
    <tr hidden={
      props.forwardedRef.current['row' + props.customFieldObject.id]
        && props.forwardedRef.current['row' + props.customFieldObject.id].deleted
        ? true : false
    }
      style={{
        opacity: draggedObject.opacity,
      }}
      ref={rowRef}
    >
      <td hidden>
        <input type="hidden" name={`row ${props.customFieldObject.id}`} ref={(curRef) => {
          props.forwardedRef.current['row' + props.customFieldObject.id] = curRef
        }} />
        <input type="number" readOnly
          defaultValue={
            props.forwardedRef.current['row' + props.customFieldObject.id]
              && props.forwardedRef.current['row' + props.customFieldObject.id].id ?
              props.forwardedRef.current['row' + props.customFieldObject.id].id : props.customFieldObject.id
          }
        />
      </td>
      <td style={{ padding: '0px 14px', width: '45px' }} className="td-primary td-checkbox">
        <img style={{
          float: 'left',
          width: '13px',
          cursor: 'grab',
          marginLeft: '3px'
        }} src={icons.blue.dragIcon} className='ui image' alt='' />
      </td>
      <td className="td-primary">
        <input
          type="text"
          className={`column-heading ${columnHeadingError ? 'error' : ''}`}
          placeholder='Type Column Heading'
          value={defaultColumnHead}
          onChange={(e) => {
            props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading = e.target.value;
            props.setFieldWithCurrentRefs();
          }}
          ref={(curRef) => {
            if (curRef) {
              curRef.value = props.forwardedRef.current['row' + props.customFieldObject.id] &&
                props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading ?
                props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading : '';
              return props.forwardedRef.current['row' + props.customFieldObject.id].columnHeading = curRef.value
            }
          }}
        />
      </td>
      <td className="td-primary">
        <div id='data-type-dropdown' className="field">
          <Dropdown
            popperContainer={DropdownPopperOption}
            className={`${dataTypeError ? 'error' : ''}`}
            clearable
            placeholder='Select Data Type'
            options={optionsAttribute}
            selection
            value={dataTypeValue}
            selectOnBlur={false}
            disabled={props.hasDatatableRecords && !props.customFieldObject.new ? true : false}
            onChange={(e: any, { value }: any) => {
              setRefresher(refresher + 1);
              props.forwardedRef.current['row' + props.customFieldObject.id].dataType = value;
              props.forwardedRef.current['row' + props.customFieldObject.id].fieldType = '';
              props.forwardedRef.current['row' + props.customFieldObject.id].configName = '';
              if (value === 'Relation') {
                handleFieldTypeChange(IConfigName.Referenced)
                setIsDefaultSelectedType(true);
              } else {
                setIsDefaultSelectedType(false);
              }
              props.setFieldWithCurrentRefs();
            }}
          />
        </div>
      </td>
      <td className="td-primary">
        <div id='field-type-dropdown' className="field">
          {props.forwardedRef.current['row' + props.customFieldObject.id] && props.forwardedRef.current['row' + props.customFieldObject.id].dataType === 'Relation'
            ?
            <Dropdown
              popperContainer={DropdownPopperOption}
              className={`${configNameError ? 'error' : ''}`}
              clearable
              selection
              selectOnBlur={false}
              options={optionsRelDataType}
              value={relationTypeValue}
              onChange={(e: any, { value }: any) => handleRelationTypeChange(value)}
            />
            : <Dropdown
              popperContainer={DropdownPopperOption}
              className={`${configNameError ? 'error' : ''}`}
              clearable
              selection
              selectOnBlur={false}
              options={dataTableFieldType.map((fieldType, index) => {
                return {
                  key: index,
                  text: fieldType.title,
                  value: fieldType.defaultData.configName,
                  image: { src: fieldType.icon.black, style: fieldType.fieldTypeStyle },
                  style: fieldType.defaultData.configName === IConfigName.Seq ? { display: 'none' } : {}
                }
              })}
              disabled={isDefaultSelectedType || (props.hasDatatableRecords && !props.customFieldObject.new) ? true : false}
              value={fieldTypeValue}
              onChange={(e: any, { value }: any) => handleFieldTypeChange(value)}
            />
          }

        </div>
      </td>
      <td className='td-icon-action'>
        {fieldTypeValue &&
          <ToolTip position='left center'
            popupMessage={'Field Settings'}
            popupTriggerComponent={
              <Image src={icons.blue.cog}
                style={{
                  width: '22px',
                  cursor: 'pointer',
                  margin: '0px 5px',
                  display: 'inline-block'
                }} className='action-icon' onClick={() => editFieldConfiguration(props.customFieldObject)} name='cog' size='large' />
            }
          />
        }
        <ToolTip position='left center'
          popupMessage={'Delete Field'}
          popupTriggerComponent={
            <Image src={icons.blue.delete}
              style={{
                width: '19px',
                cursor: 'pointer',
                margin: '0px 5px',
                display: 'inline-block'
              }} onClick={() => { props.handleDelete(props.customFieldObject.id || '', props.customFieldObject.id || '') }} className='action-icon' name='trash alternate outline' size='large' />
          }
        />
      </td>
    </tr>
  )
}

export default React.memo(DataTableRowAttribute)
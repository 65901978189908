/*
 *
 * test reducer
 *
 */

// import { fromJS } from 'immutable'
import {
  RESET_PASSWORD,
  RESET_PASSWORD_RETURN,
  RESET_PASSWORD_RETURN_ERROR
} from './constant';

import messages from './message';

export interface IResetPassword {
  token: string;
  resetPasswordError: boolean;
  resetPasswordLoading: boolean;
  resetPasswordStatus: string;
  resetPasswordMessage: string;
}

const initialState = {
  token: '',
  resetPasswordError: false,
  resetPasswordLoading: false,
  resetPasswordStatus: '',
  resetPasswordMessage: '',
};

const authReducer = (state = initialState, action: any) => {

  let newState = state;

  switch (action.type) {

    case RESET_PASSWORD:
      newState = { ...state, ...action.payload, resetPasswordLoading: true };
      return newState;

    case RESET_PASSWORD_RETURN:
      newState = { ...state }
      newState.resetPasswordError = false;
      newState.resetPasswordLoading = false;
      newState.resetPasswordStatus = action.payload.status;
      newState.resetPasswordMessage = messages.resetPasswordSuccess;
      return newState;

    case RESET_PASSWORD_RETURN_ERROR:
      newState = { ...state }
      newState.resetPasswordError = true;
      newState.resetPasswordLoading = false;
      newState.resetPasswordStatus = action.payload.status;
      newState.resetPasswordMessage = messages.resetPasswordError;
      return newState;

    default:
      return state;
  }
}

export default authReducer

import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useSetFourthLevelRoute } from '../../../../../../hooks/use-set-fourth-level-route';
import SqsPlugin from './sqs-plugin';

interface IMatch {
  path: string;
  params: {
    thirdLevelRoute: string;
  }
}

class SQSPlugin {

  name = 'sqs-plugin';

  render: React.FC = () => {
    const match: IMatch = useRouteMatch();
    const dispatch = useDispatch();

    useSetFourthLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: match.params.thirdLevelRoute,
      fourthLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route path={`${match.path}`} exact>
          <SqsPlugin></SqsPlugin>
        </Route>
      </>
    );
  }
}

export default SQSPlugin;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, postRequest, deleteRequest, putRequest, IAxiosResponse } from '../../../../common/api-request';
import {
  getGroupListReturnAction,
  getGroupListErrorAction,
  createGroupReturnAction,
  createGroupErrorAction,
  getUserOptionListErrorAction,
  getUserOptionListReturnAction,
  confirmDeleteGroupReturnAction,
  confirmDeleteGroupErrorAction,
  getGroupDetailReturnAction,
  getGroupDetailErrorAction,
  updateGroupReturnAction,
  updateGroupErrorAction,
  filterGroupsReturnAction,
  filterGroupsErrorAction
} from './action';
import { setStatusCodeAction } from '../../../error/action';
import { GET_GROUP_LIST, CREATE_GROUP, GET_USER_OPTION_LIST, DELETE_GROUP_CONFIRM, GET_GROUP_DETAIL, UPDATE_GROUP, FILTER_GROUPS } from './constant';
import { IGroupListInput, IFilterGroupsInput } from './interface/input/group-list';
import { IGroupCreateInput } from './interface/input/group-create';
import { IUserOptionListInput } from './interface/input/user-option-list';
import { getFieldId } from '../../../../common/utils/get-field';
import { IGroupConfirmDeleteInput } from './interface/input/group-delete-confirm';
import { IGroupInput } from './interface/input/group-details';
import { IGroupUpdateInput } from './interface/input/group-update';
import { getUserFilterOptions } from './filter';

export function* getGroupList(args: IGroupListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/groups?load=users&load=groups&offset=0&limit=0`, {});
    getUserFilterOptions(req.data.data);
    yield put(getGroupListReturnAction({
      data: req.data.data,
      userFilterOption: getUserFilterOptions(req.data.data)
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getGroupListErrorAction(e))
  }
}

export function* getGroupDetails(args: IGroupInput) {
  try {
    let company = args.company;
    let groupId = args.groupId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/groups/${groupId}?load=users&load=groups`, {});
    yield put(getGroupDetailReturnAction({ data: { ...req.data.data } }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getGroupDetailErrorAction(e))
  }
}

export function* createGroup(args: IGroupCreateInput) {
  try {
    let company = args.company;
    let data = args.data;
    const req: IAxiosResponse = yield call(postRequest, `${company}/groups?load=users&load=groups`, data);
    yield put(createGroupReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e)
    yield put(createGroupErrorAction({ message: JSON.stringify(e.response.data.message || e) }))
    // do nothing for now
  }
}

export function* getUserOptionList(args: IUserOptionListInput) {
  try {
    let company = args.company;
    if (company) {
      const globalSearch = args.globalSearch ? `&globalSearch=${args.globalSearch}` : '';
      const req: IAxiosResponse = yield call(getRequest, `${company}/users?company=${company}&active=true&limit=0&load=profiles${globalSearch}`, {});

      let field = args.userFields || [];

      if (args.userFields === undefined) {
        const response: IAxiosResponse = yield call(getRequest, `${company}/users/fields?company=${company}`, {});
        field = response.data.data;
      }

      const optionList = req.data && req.data.data
        .filter((user: any) => {
          if (args.selected) {
            return args.selected.indexOf(user.id) === -1;
          }
          return true;
        })
        .map((user: any, key: any) => {
          const userFirstName = user.fields[getFieldId(field, 'First Name')] || '';
          const userLastName = user.fields[getFieldId(field, 'Last Name')] || '';
          return {
            key: key,
            text: `${userFirstName} ${userLastName}`,
            value: user.id,
            type: 'user',
            active: user.active
          }
        });
      yield put(getUserOptionListReturnAction({ data: optionList, userFields: field }));
    }
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getUserOptionListErrorAction({ message: JSON.stringify(e.response.data.message || e) }));
  }
}

export function* deleteGroup(args: IGroupConfirmDeleteInput) {
  try {
    let company = args.company;
    let groupId = args.groupId;
    yield call(deleteRequest, `${company}/groups/${groupId}`, {});
    yield put(confirmDeleteGroupReturnAction({ groupId: groupId }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e)
    yield put(confirmDeleteGroupErrorAction({ message: JSON.stringify(e.response.data.message || e) }))
    // do nothing for now
  }
}

export function* updateGroup(args: IGroupUpdateInput) {
  try {
    let company = args.company;
    let groupId = args.groupId;
    let data = args.data;
    let req: IAxiosResponse = yield call(putRequest, `${company}/groups/${groupId}?load=users&load=groups`, data);
    yield put(updateGroupReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e)
    yield put(updateGroupErrorAction({ message: JSON.stringify(e.response.data.message || e) }))
    // do nothing for now
  }
}

export function* filterGroups(args: IFilterGroupsInput) {
  try {
    let company = args.company;
    let filterData = args.filterData;
    const req: IAxiosResponse = yield call(postRequest, `${company}/groups/search?load=users&load=groups&offset=0&limit=0`, filterData);
    yield put(filterGroupsReturnAction({ data: { ...req.data.data } }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(filterGroupsErrorAction(e))
  }
}

export function* groupSagas() {
  yield takeLatest(GET_GROUP_LIST, getGroupList);
  yield takeLatest(CREATE_GROUP, createGroup);
  yield takeLatest(GET_USER_OPTION_LIST, getUserOptionList);
  yield takeLatest(DELETE_GROUP_CONFIRM, deleteGroup);
  yield takeLatest(GET_GROUP_DETAIL, getGroupDetails);
  yield takeLatest(UPDATE_GROUP, updateGroup);
  yield takeLatest(FILTER_GROUPS, filterGroups)
}

// All sagas to be loaded
export default groupSagas;
import {
  IFormBuilderDetailInput
} from './interface/input/form-builder';
import {
  GET_FORM_BUILDER_DETAIL,
  GET_FORM_BUILDER_DETAIL_RETURN,
  GET_FORM_BUILDER_DETAIL_ERROR,
  SAVE_FORM_BUILDER_CONFIGURATION,
  SAVE_FORM_BUILDER_CONFIGURATION_RETURN,
  SAVE_FORM_BUILDER_CONFIGURATION_ERROR,
  DELETE_FORM_BUILDER_FIELD,
  PUBLISH_FORM_BUILDER_CONFIGURATION,
  PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR,
  PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN,
  GET_PUBLISHED_FORM_LIST,
  GET_PUBLISHED_FORM_LIST_RETURN,
  SET_SAVE_FORM_BUILDER_MESSAGE
} from './constant';
import { IFormBuilderReturnResponse, IFormBuilderSaveConfigurationReturnResponse } from './interface/response/form-builder';
import { IFormBuilderDetailSaveInput, IFormBuilderDeleteFieldDetailInput, IFormBuilderPublishDetailInput } from './interface/input/form-builder-save';
import { IPublishedFormDetailInput } from './interface/input/published-form-get';
import { IPublishedFormResponse } from './interface/response/published-form-response';

export const getFormBuilderDetailAction = (payload: IFormBuilderDetailInput) => {
  return { type: GET_FORM_BUILDER_DETAIL, ...payload }
}

export const getFormBuilderDetailReturnAction = (payload: IFormBuilderReturnResponse) => {
  return { type: GET_FORM_BUILDER_DETAIL_RETURN, ...payload }
}

export const getFormBuilderDetailErrorAction = (message: string) => {
  return { type: GET_FORM_BUILDER_DETAIL_ERROR, formBuilderDetailErrorMessage: message }
}

export const saveFormBuilderConfigurationAction = (payload: IFormBuilderDetailSaveInput) => {
  return { type: SAVE_FORM_BUILDER_CONFIGURATION, ...payload }
}

export const saveFormBuilderConfigurationReturnAction = (payload: IFormBuilderSaveConfigurationReturnResponse) => {
  return { type: SAVE_FORM_BUILDER_CONFIGURATION_RETURN, ...payload }
}

export const saveFormBuilderConfigurationErrorAction = (message: string) => {
  return { type: SAVE_FORM_BUILDER_CONFIGURATION_ERROR, saveFormBuilderErrorMessage: message }
}

export const deleteFormBuilderFieldAction = (payload: IFormBuilderDeleteFieldDetailInput) => {
  return { type: DELETE_FORM_BUILDER_FIELD, ...payload }
}

export const publishFormBuilderConfigurationAction = (payload: IFormBuilderPublishDetailInput) => {
  return { type: PUBLISH_FORM_BUILDER_CONFIGURATION, ...payload }
}

export const publishFormBuilderConfigurationReturnAction = () => {
  return { type: PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN }
}

export const publishFormBuilderConfigurationErrorAction = (message: string) => {
  return { type: PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR, saveFormBuilderErrorMessage: message }
}

export const getPublishedFormAction = (payload: IPublishedFormDetailInput) => {
  return { type: GET_PUBLISHED_FORM_LIST, ...payload }
}

export const getPublishedFormReturnAction = (payload: IPublishedFormResponse) => {
  return { type: GET_PUBLISHED_FORM_LIST_RETURN, ...payload }
}

export const setSaveFormBuilderMessageAction = (message: string) => {
  return { type: SET_SAVE_FORM_BUILDER_MESSAGE, saveFormBuilderMessage: message }
}
import { IFormFieldsDetailInput } from '../duplicate-checker/interface/input/form-fields'
import {
  SAVE_FORM_LABELS_ERROR,
  GET_FORM_LABELS,
  GET_FORM_LABELS_RETURN,
  GET_FORM_LABELS_ERROR,
  SET_SAVE_TYPE,
  PUBLISH_FORM_LABELS,
  PUBLISH_FORM_LABELS_ERROR,
  PUBLISH_FORM_LABELS_RETURN
} from './constant'
import { IFormLabelsGetResponse } from './interface/response/labels-get'

export const saveFormLabelsErrorAction = (fieldError: string) => {
  return {
    type: SAVE_FORM_LABELS_ERROR,
    fieldError
  }
}

export const getFormLabelsAction = (payload: IFormFieldsDetailInput) => {
  return {
    type: GET_FORM_LABELS,
    ...payload
  }
}

export const getFormLabelsReturnAction = (payload: IFormLabelsGetResponse) => {
  return {
    type: GET_FORM_LABELS_RETURN,
    ...payload
  }
}

export const getFormLabelsErrorAction = (fieldError: string) => {
  return {
    type: GET_FORM_LABELS_ERROR,
    fieldError
  }
}

export const triggerSaveTypeAction = (formLabelSaveType: string) => {
  return {
    type: SET_SAVE_TYPE,
    formLabelSaveType
  }
}

export const publishFormLabelAction = (payload: { company: string; formId: string }) => {
  return {
    type: PUBLISH_FORM_LABELS,
    ...payload
  }
}

export const publishFormLabelReturnAction = () => {
  return {
    type: PUBLISH_FORM_LABELS_RETURN
  }
}

export const publishFormLabelErrorAction = (fieldError: string) => {
  return {
    type: PUBLISH_FORM_LABELS_ERROR,
    fieldError
  }
}
import { GET_FORM_FIELD_LIST, GET_FORM_FIELD_LIST_RETURN } from '../constant';
import { IGetFormFieldDetailInput } from '../interface/input/form-field-get';
import { IGetFormFieldResponse } from '../interface/response/form-field-get';

export const getFormFieldAction = (payload: IGetFormFieldDetailInput) => {
  return { type: GET_FORM_FIELD_LIST, ...payload };
}

export const getFormFieldReturnAction = (payload: IGetFormFieldResponse) => {
  return { type: GET_FORM_FIELD_LIST_RETURN, ...payload };
}
import {
  GET_FORM_STATUS_LIST,
  GET_FORM_STATUS_LIST_RETURN,
  GET_FORM_STATUS_LIST_ERROR,
  SAVE_SLAS,
  SAVE_SLAS_RETURN,
  SAVE_SLAS_ERROR,
  PUBLISH_SLAS,
  PUBLISH_SLAS_RETURN,
  PUBLISH_SLAS_ERROR,
  CLEAR_SAVE_SLAS_ERROR,
  CLEAR_SAVE_SLAS_SUCCESS,
  SET_SLAS_ERROR_MESSAGE,
  CLEAR_SLAS_VALIDATION_ERROR
} from './constant';
import { message } from './message';
import { ISlasAndStatuses } from './interface/slaAndStatuses';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import { IReference } from '../bp-relationship/interface/form';

export interface ISlasReducer {
  slaStatusList: ISlasAndStatuses;
  statusListLoading: boolean;
  statusListErrorMessage: string;
  saveSlasMessage: string;
  saveSlasLoading: boolean;
  saveSlasErrorMessage: string;
  saveRoleType: string;
  publishSlasLoading: boolean;
  slasValidationErrorMessage: string;
  statusList: IFormStatusWithAction[];
  referenceList: IReference[];
}

const initialState: ISlasReducer = {
  slaStatusList: {} as ISlasAndStatuses,
  statusListLoading: false,
  statusListErrorMessage: '',
  saveSlasErrorMessage: '',
  saveSlasLoading: false,
  saveSlasMessage: '',
  saveRoleType: '',
  publishSlasLoading: false,
  slasValidationErrorMessage: '',
  statusList: [],
  referenceList: []
}

const slasReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_STATUS_LIST:
      newState.statusListLoading = true;
      newState.saveSlasMessage = '';
      newState.saveSlasErrorMessage = '';
      return newState;

    case GET_FORM_STATUS_LIST_RETURN:
      newState.statusListLoading = false;
      newState.slaStatusList = action.slaStatusList;
      newState.statusList = action.statusList;
      newState.referenceList = action.referenceList;
      return newState;

    case GET_FORM_STATUS_LIST_ERROR:
      newState.statusListLoading = false;
      newState.statusListErrorMessage = action.message;
      return newState;

    case SAVE_SLAS:
      newState.saveRoleType = '';
      newState.saveSlasErrorMessage = '';
      newState.saveSlasLoading = true;
      newState.saveSlasMessage = '';
      return newState;

    case SAVE_SLAS_RETURN:
      if (action.saveRoleType === 'draft') {
        newState.saveSlasMessage = message.saveSlasSuccess;
        newState.slaStatusList = action.slasStatusList;
        newState.saveSlasErrorMessage = '';
        newState.saveSlasLoading = false;
      } else {
        newState.saveSlasLoading = true;
      }
      newState.saveRoleType = action.saveRoleType;
      return newState;

    case SAVE_SLAS_ERROR:
      newState.saveSlasLoading = false;
      newState.saveSlasErrorMessage = action.formSaveErrorMessage;
      newState.saveSlasMessage = '';
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_SLAS:
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_SLAS_RETURN:
      newState.saveSlasLoading = false;
      newState.saveSlasMessage = message.publishSlasSuccess;
      return newState;

    case PUBLISH_SLAS_ERROR:
      newState.publishSlasLoading = false;
      newState.saveSlasErrorMessage = action.formSaveErrorMessage;
      return newState;

    case SET_SLAS_ERROR_MESSAGE:
      newState.slasValidationErrorMessage = action.validationMessage;
      return newState;

    case CLEAR_SAVE_SLAS_SUCCESS:
      newState.saveSlasMessage = '';
      return newState;

    case CLEAR_SAVE_SLAS_ERROR:
      newState.saveSlasErrorMessage = '';
      return newState;

    case CLEAR_SLAS_VALIDATION_ERROR:
      newState.slasValidationErrorMessage = '';
      return newState;
    default:
      return state;
  }
}

export default slasReducer;
import React from 'react';
import { IAutomationComponent } from '../../interface/component-list';

interface IAutomationOutlineAddComponent {
  setSelectedComponent(component?: IAutomationComponent): void;
}

const AutomationOutlineAddComponent: React.FC<IAutomationOutlineAddComponent> = (props) => {
  return (
    <li className={`add-component`} onClick={() => props.setSelectedComponent(undefined)}>
      <div>
        <i className="plus circle icon"></i>
      </div>
      <div className={`content`}>
        <span className={`title`}> Add Component </span>
      </div>
    </li>
  );
}

export default AutomationOutlineAddComponent;
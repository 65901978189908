import { IField } from '../../../../../../../component/admin-module/module/users/interface/field';
import { FieldConstruct } from '../../../../../field-construct';
import { AcceptedProps } from '../../../interface/accepted-props';

export const triggerOnChange = (props: AcceptedProps): void => {
  if (props.triggerFilter) {
    props.triggerFilter('');
  }
  if (props.hasPageChangeRef) {
    props.hasPageChangeRef.current.hasChange = true;
  }
  if (props.throwValueOutside) {
    props.throwValueOutside();
  }
  if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  if (props.automationService && props.fieldCollection) {
    const field = props.fieldCollection.find((e: IField) => e.id === props.id);
    if (field) {
      const value = FieldConstruct.getFieldDataFromRefs([field], props.forwardedRef);
      props.automationService.didUpdateFieldValue(field, value[props.id] ? value[props.id] : undefined);
    }
  }
}
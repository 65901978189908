import React from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../general/dropdown-popper-option';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import { IOption } from '../../../interface/option';
import { IRootState } from '../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { systemField } from '../constant';
import { IFilterConfigDetail } from '../config';
import { getFromRefFieldListAction } from '../action';

interface IIDFieldCompare extends IFilterConfigDetail {
  setCompareTypeFieldOption(): IOption[];
  handleSourceFieldLabel(id: string): void;
  refFieldSourceFormDetails: {
    id: string;
    name: string;
  } | null;
}

const IDFieldCompare: React.FC<IIDFieldCompare> = (props) => {
  const dispatch = useDispatch();

  const { formBuilderField } = useSelector((state: IRootState) => state.formBuilder);
  const { referenceFieldList } = useSelector((state: IRootState) => state.filterConfiguration);
  const { refSourceFieldList } = useSelector((state: IRootState) => state.filterConfiguration);

  const getField = (fieldId: string) => {
    const tables = formBuilderField.filter(e => e.type === 'table').map(e => e.config?.columns);
    const tableFields = tables.length ? (tables.reduce((flatten, array) => [...flatten || [], ...array || []]) || []) : [];
    const fieldList = [
      ...props.defaultField,
      ...referenceFieldList,
      ...formBuilderField,
      ...tableFields,
    ];
    const field = fieldList.find(e => e.id === fieldId);
    const { config } = { ...field };

    return config;
  }

  const setCompareFieldRefOption = (): IOption[] => {
    const config = getField(props.condition.compareField.fieldId);

    let ctr = 0;
    const options: IOption[] = [];
    let sourceFields: ICustomFieldDetail[] = [];

    if (props.configData.dataType === 'user') {
      sourceFields = getSytemFieldOptions();
    }

    if (config && config.data) {
      let sourceId = 'user';
      if (config.data.databaseId) {
        sourceId = config.data.databaseId;
      }
      if (config.data.formId) {
        sourceId = config.data.formId;
      }
      sourceFields = refSourceFieldList[sourceId] || [];
    }

    sourceFields.forEach((field, idx, array) => {
      if (field.configName !== IConfigName.Seq && field.configName !== IConfigName.GAP) {
        const refNames = [IConfigName.Referenced, IConfigName.Autopopulated];
        const stringConfigData = props.configData && props.configData.data ? JSON.stringify(props.configData.data) : '';
        const stringFieldData = field.config && field.config.data ? JSON.stringify(field.config.data) : '';
        let sameSource = stringConfigData === stringFieldData;

        const sourceField = field.config && field.config?.sourceField;
        if (sourceField) {
          const sourceRef = array.find(e => e.id === sourceField);
          if (sourceRef && sourceRef.config && sourceRef.config.data) {
            const stringFieldData = sourceRef.config && sourceRef.config.data ? JSON.stringify(sourceRef.config.data) : '';
            sameSource = stringConfigData === stringFieldData;
          }
        }
        if (sameSource || (field.configName && !refNames.includes(field.configName))) {
          options.push({
            key: ctr++,
            text: field.label || '',
            value: field.id || '',
          });
        }

      }
    })

    return options;
  }

  const getCompareFieldRefLabelFields = (fieldId: string) => {
    const config = getField(fieldId);

    if (config && config.data) {
      let sourceId = 'user';
      if (config.data.databaseId) {
        sourceId = config.data.databaseId;
      }
      if (config.data.formId) {
        sourceId = config.data.formId;
      }
      const noSourceFields = (!refSourceFieldList[sourceId]);
      if (noSourceFields) {
        dispatch(getFromRefFieldListAction({
          company: config.data.companyId,
          dataType: config.dataType,
          databaseId: config.data.databaseId,
          formId: config.data.formId,
        }));
      }
    }

  }

  const getCompareFieldRefValue = (): string => {
    return (props.configData.dataType === 'user' && props.condition.compareField.fieldRef === '')
      ? props.condition.compareField.fieldId || ''
      : props.condition.compareField.fieldRef || ''
  }


  const getSytemFieldOptions = (): ICustomFieldDetail[] => {
    const userListSystemFieldOptions: String[] = ['owner', 'creator', 'updatedBy'];
    return systemField.filter((field: ICustomFieldDetail) => userListSystemFieldOptions.indexOf(field.id || '') > -1);
  }

  return <>
    <div className='field-container selection-field-source'>
      <div className='label source-field-label'>
        <label className={`label ${!props.condition.tableId ? 'selected' : ''}`}
          onClick={() => {
            props.handleConfig('tableId', '', props.rowIndex)
            props.handleCompareField('fieldId', '', props.rowIndex)
          }}>
          Field
        </label>
        {props.tableId &&
          <label className={`label ${props.condition.tableId ? 'selected' : ''}`}
            onClick={() => {
              props.handleConfig('tableId', props.tableId!, props.rowIndex)
              props.handleCompareField('fieldId', '', props.rowIndex)
            }}>
            Table
          </label>
        }
        <label className='label selected'>:&nbsp;
          {props.condition.tableId && props.tableDetails ?
            ` ${props.tableDetails.label || 'Untitled Source'}`
            : ` ${props.currentBp.name || props.referencedBPDetail.name || 'Untitled Source'}`}
        </label>
      </div>
      <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        selection
        clearable
        search
        selectOnBlur={false}
        value={props.condition.compareField.fieldId}
        options={props.condition.tableId ? props.tableFieldOptions : props.setCompareTypeFieldOption()}
        onChange={(event, target) => {
          props.handleCompareField('fieldId', target.value as string, props.rowIndex);
          getCompareFieldRefLabelFields(target.value as string);
        }}
        placeholder='Select field'
      />
    </div>
    <div className='field-container selection-operator'>
      <label className='label selection-operator-label'>&nbsp;</label>
      <Dropdown
        fluid
        selection
        clearable={false}
        placeholder='Select operator'
        selectOnBlur={false}
        value={'any-in'}
        options={props.getOperatorOption(props.condition)}
      />
    </div>
    <div className='field-container selection-field-value'>
      <div className='label selection-field-value-label'>
        <label className={`label selected`}>
          Field {`${props.refFieldSourceFormDetails ? ` : ${(props.refFieldSourceFormDetails.name || 'Untitled Source')}` : ''}`}
        </label>
      </div>
      <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        selection
        clearable
        search
        selectOnBlur={false}
        value={getCompareFieldRefValue()}
        options={setCompareFieldRefOption()}
        onChange={(event, target) => props.handleCompareField('fieldRef', target.value as string, props.rowIndex)}
        placeholder='Select field'
      />
    </div>

  </>
}

export default IDFieldCompare;
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Autopopulated,
  required: false,
  type: 'autopopulated',
};

interface IDefaultConfigObject {
  sourceField: string;
  labelFormat: string;
  separator: string;
  autoPopulateOnce: boolean;
  editable: boolean;
}
const defaultConfig: IDefaultConfigObject = {
  sourceField: '',
  labelFormat: '',
  separator: ' ',
  autoPopulateOnce: false,
  editable: false
};

const value = {
  title: 'Autopopulated',
  icon: {
    black: require('../../icons/black/autopopulated.png'),
    white: require('../../icons/white/autopopulated.png'),
    blue: require('../../icons/blue/autopopulated.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';

export const GET_DATA_TABLE_LIST = 'app/dataTable/GET_DATA_TABLE_LIST';
export const GET_DATA_TABLE_LIST_RETURN = 'app/dataTable/GET_DATA_TABLE_LIST_RETURN';
export const GET_DATA_TABLE_LIST_ERROR = 'app/dataTable/GET_DATA_TABLE_LIST_ERROR';
export const CREATE_DATA_TABLE = 'app/dataTable/CREATE_DATA_TABLE';
export const CREATE_DATA_TABLE_RETURN = 'app/dataTable/CREATE_DATA_TABLE_RETURN';
export const CREATE_DATA_TABLE_ERROR = 'app/dataTable/CREATE_DATA_TABLE_ERROR';
export const RESET_DATA_TABLE_MESSAGE = 'app/dataTable/RESET_DATA_TABLE_MESSAGE';
export const DELETE_DATATABLE = 'app/dataTable/DELETE_DATATABLE';
export const DELETE_DATATABLE_CONFIRM = 'app/dataTable/DELETE_DATATABLE_CONFIRM';
export const DELETE_DATATABLE_CONFIRM_RETURN = 'app/dataTable/DELETE_DATATABLE_CONFIRM_RETURN';
export const DELETE_DATATABLE_CONFIRM_ERROR = 'app/dataTable/DELETE_DATATABLE_CONFIRM_ERROR';
export const DELETE_DATATABLE_CANCEL = 'app/dataTable/DELETE_DATATABLE_CANCEL';
export const CLEAR_DELETE_DATATABLE_MESSAGE = 'app/dataTable/CLEAR_DELETE_DATATABLE_MESSAGE';
export const GET_DATA_TABLE_FIELDS = 'app/dataTable/GET_DATA_TABLE_FIELDS';
export const GET_DATA_TABLE_FIELDS_RETURN = 'app/dataTable/GET_DATA_TABLE_FIELDS_RETURN';
export const GET_DATA_TABLE_FIELDS_ERROR = 'app/dataTable/GET_DATA_TABLE_FIELDS_ERROR';
export const GET_DATA_TABLE_DETAILS = 'app/dataTable/GET_DATA_TABLE_DETAILS';
export const GET_DATA_TABLE_DETAILS_RETURN = 'app/dataTable/GET_DATA_TABLE_DETAILS_RETURN';
export const GET_DATA_TABLE_DETAILS_ERROR = 'app/dataTable/GET_DATA_TABLE_DETAILS_ERROR';
export const CREATE_DATA_TABLE_RECORDS = 'app/dataTable/CREATE_DATA_TABLE_RECORDS';
export const CREATE_DATA_TABLE_RECORDS_RETURN = 'app/dataTable/CREATE_DATA_TABLE_RECORDS_RETURN';
export const CREATE_DATA_TABLE_RECORDS_ERROR = 'app/dataTable/CREATE_DATA_TABLE_RECORDS_ERROR';
export const GET_DATA_TABLE_RECORDS = 'app/dataTable/GET_DATA_TABLE_RECORDS';
export const GET_DATA_TABLE_RECORDS_RETURN = 'app/dataTable/GET_DATA_TABLE_RECORDS_RETURN';
export const GET_DATA_TABLE_RECORDS_ERROR = 'app/dataTable/GET_DATA_TABLE_RECORDS_ERROR';
export const UPDATE_DATA_TABLE = 'app/dataTable/UPDATE_DATA_TABLE';
export const UPDATE_DATA_TABLE_RETURN = 'app/dataTable/UPDATE_DATA_TABLE_RETURN';
export const UPDATE_DATA_TABLE_ERROR = 'app/dataTable/UPDATE_DATA_TABLE_ERROR';
export const SET_RECORD_EDITABLE = 'app/dataTable/SET_RECORD_EDITABLE';
export const RESET_RECORD_EDITABLE = 'app/dataTable/RESET_RECORD_EDITABLE';
export const SET_RECORD_EDITABLE_RETURN = 'app/dataTable/SET_RECORD_EDITABLE_RETURN';
export const DELETE_DATA_TABLE_FIELDS = 'app/dataTable/DELETE_DATA_TABLE_FIELDS';
export const DELETE_DATA_TABLE_FIELDS_RETURN = 'app/dataTable/DELETE_DATA_TABLE_FIELDS_RETURN';
export const DELETE_DATA_TABLE_FIELDS_ERROR = 'app/dataTable/DELETE_DATA_TABLE_FIELDS_ERROR';
export const UPDATE_DATA_TABLE_RECORD = 'app/dataTable/UPDATE_DATA_TABLE_RECORD';
export const UPDATE_DATA_TABLE_RECORD_RETURN = 'app/dataTable/UPDATE_DATA_TABLE_RECORD_RETURN';
export const UPDATE_DATA_TABLE_RECORD_ERROR = 'app/dataTable/UPDATE_DATA_TABLE_RECORD_ERROR';
export const DELETE_DATATABLE_RECORD = 'app/dataTable/DELETE_DATATABLE_RECORD';
export const DELETE_DATATABLE_RECORD_CONFIRM = 'app/dataTable/DELETE_DATATABLE_RECORD_CONFIRM';
export const DELETE_DATATABLE_RECORD_CONFIRM_RETURN = 'app/dataTable/DELETE_DATATABLE_RECORD_CONFIRM_RETURN';
export const DELETE_DATATABLE_RECORD_CONFIRM_ERROR = 'app/dataTable/DELETE_DATATABLE_RECORD_CONFIRM_ERROR';
export const DELETE_DATATABLE_RECORD_CANCEL = 'app/dataTable/DELETE_DATATABLE_RECORD_CANCEL';
export const CLEAR_DELETE_DATATABLE_RECORD_MESSAGE = 'app/dataTable/CLEAR_DELETE_DATATABLE_RECORD_MESSAGE';
export const GET_DATATABLE_DETAIL_PERMISSION_OPTION = 'app/dataTable/GET_DATATABLE_DETAIL_PERMISSION_OPTION';
export const GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN = 'app/dataTable/GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN';
export const CHECK_DATATABLE_RECORD = 'app/dataTable/CHECK_DATATABLE_RECORD';
export const CHECK_DATATABLE_RECORD_RETURN = 'app/dataTable/CHECK_DATATABLE_RECORD_RETURN';
export const CLEAR_CREATE_DATA_TABLE_SUCCESS = 'app/dataTable/CLEAR_CREATE_DATA_TABLE_SUCCESS';
export const CLEAR_UPDATE_DATA_TABLE_SUCCESS = 'app/dataTable/CLEAR_UPDATE_DATA_TABLE_SUCCESS';
export const CLEAR_DELETE_DATA_TABLE_SUCCESS = 'app/dataTable/CLEAR_DELETE_DATA_TABLE_SUCCESS';
export const CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS = 'app/dataTable/CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS';
export const CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS = 'app/dataTable/CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS';
export const CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS = 'app/dataTable/CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS';
export const CLEAR_CREATE_DATA_TABLE_ERROR = 'app/dataTable/CLEAR_CREATE_DATA_TABLE_ERROR';
export const CLEAR_UPDATE_DATA_TABLE_ERROR = 'app/dataTable/CLEAR_UPDATE_DATA_TABLE_ERROR';
export const CLEAR_DELETE_DATA_TABLE_ERROR = 'app/dataTable/CLEAR_DELETE_DATA_TABLE_ERROR';
export const CLEAR_CREATE_DATA_TABLE_RECORD_ERROR = 'app/dataTable/CLEAR_CREATE_DATA_TABLE_RECORD_ERROR';
export const CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR = 'app/dataTable/CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR';
export const CLEAR_DELETE_DATA_TABLE_RECORD_ERROR = 'app/dataTable/CLEAR_DELETE_DATA_TABLE_RECORD_ERROR';
export const TRIGGER_FIELD_TO_UPDATE = 'app/dataTable/TRIGGER_FIELD_TO_UPDATE';
export const TRIGGER_FIELD_TO_UPDATE_RETURN = 'app/dataTable/TRIGGER_FIELD_TO_UPDATE_RETURN';
export const FILTER_DATATABLE_RECORD = 'app/dataTable/FILTER_DATATABLE_RECORD';
export const FILTER_DATATABLE_RECORD_RETURN = 'app/dataTable/FILTER_DATATABLE_RECORD_RETURN';
export const GET_FILTER_FIELD_DATATABLE_RECORD = 'app/dataTable/GET_FILTER_FIELD_DATATABLE_RECORD';
export const GET_FILTER_FIELD_DATATABLE_RECORD_RETURN = 'app/dataTable/GET_FILTER_FIELD_DATATABLE_RECORD_RETURN';
export const SORT_DATATABLE_RECORD_FIELD_LIST = 'app/dataTable/SORT_DATATABLE_RECORD_FIELD_LIST';
export const BATCH_UPDATE_DATATABLE_RECORD = 'app/dataTable/BATCH_UPDATE_DATATABLE_RECORD';
export const BATCH_UPDATE_DATATABLE_RECORD_RETURN = 'app/dataTable/BATCH_UPDATE_DATATABLE_RECORD_RETURN';
export const BATCH_UPDATE_DATATABLE_RECORD_ERROR = 'app/dataTable/BATCH_UPDATE_DATATABLE_RECORD_ERROR';
export const RESET_DATATABLE_FIELDS = 'app/dataTable/RESET_DATATABLE_FIELDS';
export const BATCH_UPLOAD_DATATABLE_RECORD_RETURN = 'app/dataTable/BATCH_UPLOAD_DATATABLE_RECORD_RETURN';
export const SET_ENUM_ITEMS_FROM_RECORDS = 'app/dataTable/SET_ENUM_ITEMS_FROM_RECORDS';
export const RESET_DATATABLE_BATCH_CHANGE = 'app/dataTable/RESET_DATATABLE_BATCH_CHANGE';
export const REFRESH_DATATABLE_RECORDS = 'app/dataTable/REFRESH_DATATABLE_RECORDS';

export const GET_RECORDS_GROUPED_CONFIG = 'app/dataTable/GET_RECORDS_GROUPED_CONFIG';
export const GET_RECORDS_GROUPED_CONFIG_RETURN = 'app/dataTable/GET_RECORDS_GROUPED_CONFIG_RETURN';
export const SET_RECORDS_GROUPED_CONFIG = 'app/dataTable/SET_RECORDS_GROUPED_CONFIG';
export const DELETE_RECORDS_GROUPED_CONFIG = 'app/dataTable/DELETE_RECORDS_GROUPED_CONFIG';
export const SET_RECORDS_GROUPED_CONFIG_RETURN = 'app/dataTable/SET_RECORDS_GROUPED_CONFIG_RETURN';
export const GET_RECORDS_GROUPED_KEYPATHS = 'app/dataTable/GET_RECORDS_GROUPED_KEYPATHS';
export const GET_RECORDS_GROUPED_KEYPATHS_RETURN = 'app/dataTable/GET_RECORDS_GROUPED_KEYPATHS_RETURN';

export const defaultDatabasePermisionsOption = [
  { key: 0, text: 'All users', value: 'everyone' },
]

export const dataTableListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_DATA_TABLE_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_DATA_TABLE_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_DELETE_DATA_TABLE_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'confirmDeletionErrorMessage',
    hasCloseIcon: true,
  }
]

export const dataTableFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_DATA_TABLE_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableErrorMessage',
    hasCloseIcon: true,
  }
]

export const dataTableRecordListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableRecordMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableRecordMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_CREATE_DATA_TABLE_RECORD_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableRecordErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableRecordErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_DATA_TABLE_RECORD_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'confirmDeletionErrorMessage',
    hasCloseIcon: true,
  }
]

export const dataTableLimit = 20;
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IFormPermissionSection } from '../../interface/section';

interface IStatusList {
  status: IFormPermissionSection;
  handleSelectedStatus(formStatusPermission: IFormPermissionSection, statusIndex: number): void;
  selectedStatus: IFormPermissionSection;
  statusIdex: number;
}

const StatusList: React.FC<IStatusList> = (props) => {
  return <div className='status-list-container'>
    <Button onClick={() => { props.handleSelectedStatus(props.status, props.statusIdex) }}
      className={`btn-status-list ${props.selectedStatus.statusId === props.status.statusId ? 'active-status' : ''}`}>
      {props.status.statusName.toUpperCase()}
    </Button>
  </div>
}

export default StatusList;
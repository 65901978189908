import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
export const SET_USER_SETTINGS = 'app/documentModule/setting/SET_USER_SETTINGS'
export const GET_USER_SETTINGS = 'app/documentModule/setting/GET_USER_SETTINGS';
export const GET_USER_SETTINGS_RETURN = 'app/documentModule/setting/GET_USER_SETTINGS_RETURN';
export const GET_USER_SETTINGS_ERROR = 'app/documentModule/setting/GET_USET_SETTINGS_ERROR';
export const UPDATE_USER_SETTINGS = 'app/documentModule/setting/UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_RETURN = 'app/documentModule/setting/UPDATE_USER_SETTINGS_RETURN';
export const UPDATE_USER_SETTINGS_ERROR = 'app/documentModule/setting/UPDATE_USER_SETTINGS_ERROR';
export const CLEAR_UPDATE_USER_SETTINGS_SUCCESS = 'app/documentModule/setting/CLEAR_UPDATE_USER_SETTINGS_SUCCESS';

export const emailNotificationOptions = [
  { id: 1, text: 'Send me email notifications', value: true },
  { id: 0, text: 'Dont Send me email notifications', value: false }
]

export const userSettingsActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_USER_SETTINGS_SUCCESS,
    type: 'success',
    reducerContainer: 'userSettings',
    reducerItem: 'updateUserSettingsMessage',
    hasCloseIcon: true,
  }
]
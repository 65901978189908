import React from 'react'
import { icons } from '../../../../../../icons'
import { Image } from 'semantic-ui-react-bpm';

export const hasIconInside = (type: 'single' | 'multi-line', hasIconInside?: boolean) => {
  return (<>
    {hasIconInside &&
      <div className={`flex-container with-icon ${type} clock-icon`}>
        <Image width='15px' src={icons.black.clock} />
      </div>
    }
  </>)
}
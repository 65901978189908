import { useEffect } from 'react';
import {
  getFormStatusListAction,
  getUserListAction,
  getGroupListAction,
  getUserFieldListAction,
  getRoleListAction,
  getUserCustomFieldAction,
  getConstraintTemplateAction,
  getFormFieldAction
} from '../../action';

interface IProps {
  dispatch: any;
  companyId: string;
  formId: string
}

export const useGetRoleTableData = (props: IProps): void => {
  const { dispatch, companyId, formId } = { ...props };

  useEffect(() => {
    if (companyId && formId) {
      dispatch(getFormStatusListAction({ companyId: companyId, formId: formId }));
      dispatch(getRoleListAction({ companyId: companyId, formId: formId }));
      dispatch(getUserListAction({ company: companyId }));
      dispatch(getGroupListAction({ company: companyId }));
      dispatch(getUserFieldListAction({ company: companyId }));
      dispatch(getUserCustomFieldAction({ company: companyId }));
      dispatch(getConstraintTemplateAction({ company: companyId, formId: formId }));
      dispatch(getFormFieldAction({ company: companyId, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, formId])
}
type TAllowedUserType = { [value: string]: string[] }

const allowedUserTypeSelection: TAllowedUserType = {
  'BPM Manager': ['BPM Manager', 'Admin', 'BA', 'End User'],
  'Admin': ['BPM Manager', 'Admin', 'Users Manager', 'BA'],
  'Users Manager': ['End User'],
  'BA': [],
  'End User': [],
}

interface IProfile {
  id: string;
  name: string;
}

const getAllowedUserType = (profile: IProfile[]) => {
  let allowedProfile: string[] = [];
  profile && profile.length > 0 && profile.forEach(e => {
    let newSetAllowedProfile = allowedUserTypeSelection[e.name] || [];
    allowedProfile = [...allowedProfile, ...newSetAllowedProfile];
  });

  return allowedProfile;
}

export default getAllowedUserType;
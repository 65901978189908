import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IDropdownOption } from '../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import DropdownComponent from '../../../../../../../../../../../../common/general/dropdown';
import { IRootState } from '../../../../../../../../../../../../reducers';
import { triggerFieldUpdateOperator } from '../../../../constant';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { useSetInitialFieldUpdateStateValue } from '../hooks/use-set-initial-field-update-state-values';
import { ITriggerTypeRegistryRender } from './registry';

const errorIndicator = {
  selectedField: false,
}
class TriggerFieldUpdate {

  name = 'field-update';

  render: React.FC<ITriggerTypeRegistryRender> = (props) => {
    const {
      formFieldList
    } = useSelector((state: IRootState) => state.automation)
    const [error, setError] = useState(errorIndicator);
    const {
      fieldSelected,
      setFieldSelected,
      selectedForAction,
      setSelectedForAction
    } = useSetInitialFieldUpdateStateValue({
      selectedComponent: props.selectedComponent
    });

    const getFromFieldOption = (): IDropdownOption[] => {
      return formFieldList
        .filter((field: ICustomFieldDetail) => field.configName !== 'GAP')
        .map((field: ICustomFieldDetail) => {
          return {
            key: uuid(),
            value: field.id || '',
            text: field.label || ''
          }
        })
    }

    const validateInput = (value?: string[]) => {
      const tempError = { ...error };
      if (!value) {
        tempError.selectedField = false;
        if (fieldSelected.length === 0) {
          tempError.selectedField = true;
        }
      } else if (value.length === 0) {
        tempError.selectedField = true;
      }
      setError(tempError)
    }

    const updateAutomation = (field: string, value: any) => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config[field] = value;
        props.updateCurrentAutomation(tempComponent, true);
        props.hasComponentChange.current = false;
      }
    }

    const manageSelectedField = (value: string[]) => {
      if (props.hasComponentChange.current === false) props.parentRefresher();
      setFieldSelected(value);
      updateAutomation('sourceFields', value);
    }

    const manageForAction = (value: string[]) => {
      if (props.hasComponentChange.current === false) props.parentRefresher();
      setSelectedForAction(value);
      updateAutomation('forAction', value)
    }

    return (
      <>
        <div className={`automation-form-detail-trigger`}>
          <AutomationDetailHeader
            title={`Field Value Change`}
            description={`This rule will trigger when the value of the fields selected below changes`}
            iconHeight={16}
            iconWidth={16}
            iconName='iconEdit'
          />
          <div className={`automation-field-container`}>
            <div className={`automation-field`}>
              <label>Field to monitor for changes <span className={`required`}>*</span></label>
              <DropdownComponent
                fluid
                selection
                placeholder='Search/ Select Operations'
                clearable
                search
                multiple
                onBlur={() => {
                  validateInput()
                }}
                selectOnBlur={false}
                value={fieldSelected}
                options={getFromFieldOption()}
                onChange={(event, target: any) => {
                  manageSelectedField(target.value);
                }}
              />
              <span className='extra-detail'>You can also match names using regular expressions</span>
            </div>
            <div className={`automation-field`}>
              <label>For</label>
              <DropdownComponent
                fluid
                selection
                placeholder='Search/ Select Operations'
                clearable
                search
                multiple
                selectOnBlur={false}
                value={selectedForAction}
                options={triggerFieldUpdateOperator}
                onChange={(event, target: any) => {
                  manageForAction(target.value)
                }}
              />
              <span className='extra-detail'>Optionally select which operations this trigger will execute for. Leave blank for all operations.</span>
            </div>
          </div>
        </div>
        {/* {props.hasComponentChange.current === true &&
          <DetailActionButton  saveAutomation={() => saveClick()} />
        } */}
      </>
    );
  }
}

export default TriggerFieldUpdate;
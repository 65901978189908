import { IsIn, IsNotEmpty, IsUUID } from 'class-validator';

export enum ActionPermission {
  Hidden = 'hidden',
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export class SetActionPermissionActionConfig {

  @IsUUID()
  @IsNotEmpty()
  actionId!: string;

  @IsIn(Object.values(ActionPermission))
  permission!: ActionPermission;
}
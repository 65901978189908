export interface IFieldTypeObj {
  id?: string;
  title: string;
  value?: string;
  icon: IIconObj;
  style?: any;
  fieldTypeStyle?: any;
  defaultConfig: any;
  defaultData: any;
}

export interface IIconObj {
  blue?: any;
  black?: any;
  white?: any;
}

export enum IConfigName {
  Autopopulated = 'Autopopulated',
  Checklist = 'Checklist',
  Timestamp = 'Timestamp',
  Date = 'Date',
  Dropdown = 'Dropdown',
  Email = 'Email',
  FileUpload = 'File Upload',
  FX = 'FX',
  GAP = 'GAP',
  Geolocation = 'Geolocation',
  Number = 'Number',
  Money = 'Money',
  MultipleSelect = 'Multiple Select',
  Referenced = 'Referenced',
  Seq = 'Seq',
  Signature = 'Signature',
  Switch = 'Switch',
  Table = 'Table',
  TextStringMultiline = 'Text String Multiline',
  TextString = 'Text String',
  Time = 'Time',
  Title = 'Title',
  URL = 'URL',
  None = '',
  Range = 'Range',
  Phone = 'Phone'
}

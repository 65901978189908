import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '..';
import { IOptionValue } from '../../../../../../../common/custom-field-config/interface/custom-field-detail-config';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../document-module/module/form/interface/sender-info';
import { keysOfEnumType } from '../../../../constant';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../interface/history';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

export class GroupsDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.Groups;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (targetDelta) {
      const deltaKeys = Object.keys(targetDelta).filter(e => e !== 'updatedAt');

      deltaKeys.forEach(key => {
        if (keysOfEnumType.includes(key)) {
          const deltaInstance = this.groupsUserGroupsInstance(targetInstance);
          const delta = this.groupsUserGroupsDelta(targetDelta[key], deltaInstance);
          newDelta['Users/Groups'] = delta;
          oldDelta['Users/Groups'] = deltaInstance;
        } else {
          newDelta[key] = targetDelta[key];
          oldDelta[key] = targetInstance[key];
        }
      });

      deltaText = `${commentSender} has updated Groups (name: ${targetInstance.name})`;
    }

    if (Object.keys(newDelta).length > 0) {
      deltaArray = FieldConfigChange.format(newDelta, oldDelta, oldDelta);
    }

    return { delta: deltaArray, text: deltaText };
  }

  groupsUserGroupsDelta = (delta: Delta, deltaInstance: any) => {
    let newDelta = {} as Delta;
    let ctr = deltaInstance && deltaInstance instanceof Array ? deltaInstance.length : 0;

    Object.keys(delta).forEach(key => {
      if (delta[key] && Array.isArray(delta[key])) {
        let newKey = key;
        if (key.indexOf('_') > -1) {
          const index = deltaInstance.findIndex((e: any) =>
            delta[key].filter((d: any) => typeof d === 'object' && d.id === e.id).length > 0);
          newKey = `_${index}`;
        } else {
          if (deltaInstance && deltaInstance instanceof Array) {
            ctr++;
            newKey = `${ctr}`;
          }
        }
        newDelta[newKey] = delta[key].map((e: any) => {
          if (e && typeof e === 'object') {
            return {
              id: e.id,
              label: e.name || `${e.firstName || ''} ${e.lastName || ''}`,
              value: e.id
            }
          }
          return e;
        })
      } else {
        newDelta[key] = delta[key];
      }
    });

    return newDelta;
  }

  groupsUserGroupsInstance = (targetInstance: any) => {
    let items = [] as IOptionValue[];
    keysOfEnumType.forEach(key => {
      if (targetInstance[key] && targetInstance[key] instanceof Array
        && targetInstance[key].length > 0) {
        const mapItems = targetInstance[key].map((e: any) => {
          return {
            id: e.id,
            label: e.name || `${e.firstName || ''} ${e.lastName || ''}`,
            value: e.id
          }
        });
        items = mapItems.concat(items)
      }
    });
    return items && items.length > 0 ? items : undefined;
  }
}
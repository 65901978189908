import React from 'react';
import { AcceptedProps } from './interface/accepted-props';
import DateArrayType from './type/date/date-array';
import DateType from './type/date/date';

const DateObject: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <DateArrayType {...props} />;
  } else {
    return <DateType {...props} />
  }
}

export default DateObject;
import moment from 'moment';
import { IDatePropertiesMinMax } from '../../../../../custom-field-config/custom-field-type/date-config';

interface IUtils {
  config: IDatePropertiesMinMax;
  dynamicRef: React.MutableRefObject<any>;
  dateFormat: string;
  fromRange?: boolean;
  type?: string;
  noWeekends?: boolean;
}

export class ComputeDate {
  static compute = ({ config, dynamicRef, dateFormat, fromRange, noWeekends }: IUtils): undefined | string => {
    if (!config) {
      return undefined;
    }
    let resDate = undefined;
    switch (config.type) {
      case 'field':
        // no scenario for this at the moment
        break;
      case 'basicExpression':
        if (config.value.left.hasOwnProperty('fieldId')) {
          resDate = ComputeDate.baseOnField({ config, dynamicRef, dateFormat, fromRange, noWeekends });
        } else {
          resDate = ComputeDate.baseOnbasicExpression(config, noWeekends);
        }
    }

    return resDate ? ComputeDate.toISOString(resDate) : undefined;
  }

  static baseOnField = ({ config, dynamicRef, dateFormat, fromRange, noWeekends }: IUtils): undefined | string => {
    try {
      const value = { ...config.value };
      const left = value.left as { fieldId: string, keyPath: string };
      const right = value.right;

      let rangeValue = null;

      if (fromRange) {
        let fieldId = left.fieldId;
        if (fieldId.indexOf('From.') > -1) {
          fieldId = fieldId.split('From.')[1];
          rangeValue = dynamicRef.current[fieldId]['From'].current[left.fieldId].value;
        }
        if (fieldId.indexOf('To.') > -1) {
          fieldId = fieldId.split('To.')[1];
          rangeValue = dynamicRef.current[fieldId]['To'].current[left.fieldId].value;
        }
      }

      if (right.amount === undefined || !left?.fieldId || (left?.fieldId && !fromRange && !dynamicRef.current[left.fieldId].value)
        || (fromRange && !rangeValue)) {
        return undefined;
      }

      let date = new Date(fromRange ? rangeValue : dynamicRef.current[left.fieldId].value);

      if (value.operator === 'add') {
        const weekdays = ComputeDate.businessAdd(moment(date), right.amount, ComputeDate.manageUnit(right.unit)).format(dateFormat);
        const allDays = moment(date).add(right.amount, ComputeDate.manageUnit(right.unit)).format(dateFormat);
        return noWeekends ? weekdays : allDays;
      } else {
        const allDays = moment(date).subtract(right.amount, ComputeDate.manageUnit(right.unit)).format(dateFormat);
        const weekdays = ComputeDate.businessSubtract(moment(date), right.amount, ComputeDate.manageUnit(right.unit)).format(dateFormat);
        return noWeekends ? weekdays : allDays;
      }
    } catch (e) {
      return undefined;
    }
  }

  static baseOnbasicExpression = (config: IDatePropertiesMinMax, noWeekends?: boolean): undefined | string => {
    try {
      const value = { ...config.value };
      const left = value.left;
      const right = value.right;
      if (right.amount === undefined) {
        return undefined;
      }
      if (left === 'today') {
        const date = new Date();
        if (value.operator === 'add') {
          const weekdays = ComputeDate.businessAdd(moment(date), right.amount, ComputeDate.manageUnit(right.unit)).format('MM/DD/YYYY');
          const allDays = moment(date).add(right.amount, ComputeDate.manageUnit(right.unit)).format('MM/DD/YYYY');
          return noWeekends ? weekdays : allDays;
        } else {
          const allDays = moment(date).subtract(right.amount, ComputeDate.manageUnit(right.unit)).format('MM/DD/YYYY');
          const weekdays = ComputeDate.businessSubtract(moment(date), right.amount, ComputeDate.manageUnit(right.unit)).format('MM/DD/YYYY');
          return noWeekends ? weekdays : allDays;
        }
      }
    } catch (e) {
      return undefined;
    }

    return undefined;
  }

  static manageUnit = (value: string): any => {
    let unit = 'y';
    switch (value) {
      case 'years':
        unit = 'y';
        break;
      case 'month':
        unit = 'm';
        break;
      case 'weeks':
        unit = 'w';
        break;
      case 'days':
        unit = 'd';
        break;

    }
    return unit;
  }

  static businessAdd = (originalDate: moment.Moment, days: number, unit?: moment.unitOfTime.DurationConstructor) => {
    var i = 0;
    while (i < days) {
      originalDate.add(1, unit);
      if (originalDate.day() > 0 && originalDate.day() < 6) {
        i++;
      }
    }
    return originalDate;
  };

  static businessSubtract = (originalDate: moment.Moment, days: number, unit?: moment.unitOfTime.DurationConstructor) => {
    var i = 0;
    while (i < days) {
      originalDate.subtract(1, unit);
      if (originalDate.day() > 0 && originalDate.day() < 6) {
        i++;
      }
    }
    return originalDate;
  };

  static toISOString = (resDate: string) => {
    return (new Date(resDate)).toISOString();
  }

  static getWeekends = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const now = start.clone(), dates = [];
    while (now.isSameOrBefore(end)) {
      if (now.day() === 0 || now.day() === 6) {
        dates.push(now.toDate());
      }
      now.add(1, 'days');
    }
    return dates;
  }
}
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Popup } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IRootState } from '../../../../../../../../reducers';
import { IFormLabels } from '../labels/interface/label';
import { getBPRFormFieldsAction } from './action';
import { IFormRelated } from './interface/bp-relationship-field';
import { ISelectedRelationField } from './interface/selected-relation';

const actionStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '15px auto',
  gridColumnGap: '10px',
  alignItems: 'center'
}

const contentStyle: CSSProperties = {
  padding: '7px 15px',
  fontSize: '13px'
}

interface ISelectedRelationProps {
  relation?: IFormRelated;
  label?: IFormLabels;
  editRelationConfig?(id: string): void;
  openLabelForm?(data: IFormLabels, actionType: 'edit' | 'create'): void;
  hasCollapseIcon: boolean;
  configuration: 'Referenced Field' | 'Label';
  sanitizingRelatedForm?: boolean;
  deleteConfig?(selected: IFormLabels): void;
}

const SelectedRelation: React.FC<ISelectedRelationProps> = (props) => {
  const { label, relation, configuration, sanitizingRelatedForm } = { ...props };
  const [collapse, setCollapse] = useState(false);
  const dispatch = useDispatch();
  const { company } = useSelector((state: IRootState) => state.auth);

  useEffect(() => {
    // TO BE REFACTOR
    if (configuration === 'Referenced Field') {
      if ((!relation?.showRelatedForm) && !sanitizingRelatedForm) {
        dispatch(getBPRFormFieldsAction({
          company: company,
          formId: relation?.relationId || label?.formId,
          sanitize: true,
          relatedForm: relation
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relation, configuration, sanitizingRelatedForm])

  const id = relation?.id || label?.id;
  const title = relation?.title || label?.name;
  const relationHeaderFields = relation ? relation.headerField : undefined;
  const labelHeaderFields = label ? label.headerField : undefined;
  const relationFields = relation ? relation.fields : undefined;
  const labelFields = label ? label.fields : undefined;
  const fields = relationFields || labelFields;
  const headerField = relationHeaderFields || labelHeaderFields;

  return (<>
    <div
      key={`selected-relation-${uuid()}`}
      onClick={() => { if (props.hasCollapseIcon && props.editRelationConfig && id) props.editRelationConfig(id) }}
      className={`bp-selected`}>
      <div className={`bp-selected-header ${collapse ? 'collapse' : ''}`}>
        <span>{title}</span>
        {!props.hasCollapseIcon &&
          <Popup
            size='mini'
            basic
            on='click'
            position='bottom right'
            id='dtr-bulk-actions-popup'
            trigger={<i aria-hidden="true" id={`selected-relation-icon-${id}`}
              className={`ellipsis vertical icon`}
            />}
          >
            <Container className='bulk-actions-popup-container' style={{ minWidth: 'unset', width: '100px' }}>
              <div onClick={() => props.openLabelForm && props.label && props.openLabelForm(props.label, 'edit')} style={contentStyle} className='bulk-actions-content'>
                <div style={actionStyle}>
                  <span>Edit</span>
                </div>
              </div>
              <div className='bulk-actions-separator' />
              <div onClick={() => props.deleteConfig && props.label && props.deleteConfig(props.label)} style={{ ...contentStyle }} className='bulk-actions-content'>
                <div style={actionStyle}>
                  <span>Delete</span>
                </div>
              </div>
            </Container>
          </Popup>
        }
        {props.hasCollapseIcon &&
          <>
            {
              collapse
                ? <i id={`selected-relation-${id}`} className="chevron down icon" onClick={(e) => {
                  e.stopPropagation();
                  setCollapse(false)
                }}></i>
                : <i id={`selected-relation-${id}`} className="chevron up icon" onClick={(e) => {
                  e.stopPropagation();
                  setCollapse(true)
                }}></i>
            }
          </>
        }
      </div>
      {(headerField || fields) &&
        <div className={`bp-selected-content ${collapse ? 'collapse' : ''}`}>
          {headerField && headerField.length > 0 && headerField[0] &&
            <label className='header-field'>
              {headerField[0] && headerField[0].name ? `${headerField[0].name} :` : 'Not Indicated'}
              <span className={headerField[0].type} />
            </label>
          }
          {
            fields && fields.map((field: ISelectedRelationField, i: number) => {
              return <label key={i}>
                {field && field.name ? `${field.name} :` : 'Not Indicated'}
                <span className={field.type} />
              </label>;
            })
          }
        </div>
      }
    </div>
  </>);
}

export default React.memo(SelectedRelation);

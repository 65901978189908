import { v4 as uuid } from 'uuid';
import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';
import { ActionPermission } from '../../../../../document-module/module/form/automation/components/action/set-action-permission/set-action-permission.action.config';

export const GET_FORM_STATUSES = 'app/admin-module/business-process/permission/GET_FORM_STATUSES';
export const GET_FORM_STATUSES_RETURN = 'app/admin-module/business-process/permission/GET_FORM_STATUSES_RETURN';
export const GET_FORM_STATUSES_ERROR = 'app/admin-module/business-process/permission/GET_FORM_STATUSES_ERROR';
export const GET_FORM_STATUS = 'app/admin-module/business-process/permission/GET_FORM_STATUS';
export const GET_FORM_STATUS_RETURN = 'app/admin-module/business-process/permission/GET_FORM_STATUS_RETURN';
export const GET_FORM_STATUS_ERROR = 'app/admin-module/business-process/permission/GET_FORM_STATUS_ERROR';
export const GET_FORM_DETAIL = 'app/admin-module/business-process/permission/GET_FORM_DETAIL';
export const GET_FORM_DETAIL_RETURN = 'app/admin-module/business-process/permission/GET_FORM_DETAIL_RETURN';
export const GET_FORM_DETAIL_ERROR = 'app/admin-module/business-process/permission/GET_FORM_DETAIL_ERROR';
export const GET_STATUS_FIELD_PERMISSIONS = 'app/admin-module/business-process/permission/GET_STATUS_FIELD_PERMISSIONS';
export const GET_STATUS_FIELD_PERMISSIONS_RETURN = 'app/admin-module/business-process/permission/GET_STATUS_FIELD_PERMISSIONS_RETURN';
export const GET_STATUS_FIELD_PERMISSIONS_ERROR = 'app/admin-module/business-process/permission/GET_STATUS_FIELD_PERMISSIONS_ERROR';
export const SAVE_PERMISSION = 'app/admin-module/business-process/permission/SAVE_PERMISSION';
export const SAVE_PERMISSION_RETURN = 'app/admin-module/business-process/permission/SAVE_PERMISSION_RETURN';
export const SAVE_PERMISSION_ERROR = 'app/admin-module/business-process/permission/SAVE_PERMISSION_ERROR';
export const PUBLISH_PERMISSION = 'app/admin-module/business-process/permission/PUBLISH_PERMISSION';
export const PUBLISH_PERMISSION_RETURN = 'app/admin-module/business-process/permission/PUBLISH_PERMISSION_RETURN';
export const PUBLISH_PERMISSION_ERROR = 'app/admin-module/business-process/permission/PUBLISH_PERMISSION_ERROR';
export const GET_PERMISSION_LIST = 'app/admin-module/business-process/permission/GET_PERMISSION_LIST';
export const GET_PERMISSION_LIST_RETURN = 'app/admin-module/business-process/permission/GET_PERMISSION_LIST_RETURN';
export const GET_PERMISSION_LIST_ERROR = 'app/admin-module/business-process/permission/GET_PERMISSION_LIST_ERROR';
export const CLEAR_SAVE_PERMISSION_SUCCESS = 'app/admin-module/business-process/permission/CLEAR_SAVE_PERMISSION_SUCCESS';
export const CLEAR_SAVE_PERMISSION_ERROR = 'app/admin-module/business-process/permission/CLEAR_SAVE_PERMISSION_ERROR';

export const accessLevelToValue: { [accessLevel: string]: number } = {
  'hidden': 1,
  'readonly': 2,
  'editable': 3,
  'required': 4
}

export const valueToAccessLevel: { [accessLevel: string]: string } = {
  '1': 'hidden',
  '2': 'readonly',
  '3': 'editable',
  '4': 'required'
}

export const permissionActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_PERMISSION_SUCCESS,
    type: 'success',
    reducerContainer: 'permission',
    reducerItem: 'formSaveMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_PERMISSION_ERROR,
    type: 'negative',
    reducerContainer: 'permission',
    reducerItem: 'formSaveErrorMessage',
    hasCloseIcon: true
  }
]

export const actionPermissionList = [
  {
    id: uuid(),
    label: 'hidden',
    value: ActionPermission.Hidden
  },
  {
    id: uuid(),
    label: 'clickable',
    value: ActionPermission.Enabled
  },
  {
    id: uuid(),
    label: 'non-clickable',
    value: ActionPermission.Disabled
  },
]
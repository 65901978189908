import { ICustomFieldDetail } from '../custom-field-config/interface/custom-field-detail';
import { IField } from '../../component/admin-module/module/users/interface/field';
import { v4 as uuid } from 'uuid';

export const sortDataTableField = (sortDataTableFieldList: IField[], labelList: string[]): IField[] => {
  sortDataTableFieldList.sort(
    (config1: IField, config2: IField) => {
      const index1 = labelList.indexOf(config1.label || '');
      const index2 = labelList.indexOf(config2.label || '');
      return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
    });
  return sortDataTableFieldList
}

export const sortDataTableFieldMultiLabel = (sortDataTableFieldList: ICustomFieldDetail[], fieldLabel: string[]): ICustomFieldDetail[] => {
  const arrayToSortList = sortDataTableFieldList ? [...sortDataTableFieldList] : [];
  const filterLabelList = arrayToSortList.filter(label =>
    fieldLabel.indexOf(label.label || uuid()) === -1
  ).map(label => {
    return label.label
  });
  const labelList = [fieldLabel, ...filterLabelList];
  arrayToSortList.sort(
    (config1: ICustomFieldDetail, config2: ICustomFieldDetail) => {
      const index1 = labelList.indexOf(config1.label || '');
      const index2 = labelList.indexOf(config2.label || '');
      return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
    });
  return arrayToSortList
}

export const sortDataTableCustomField = (sortDataTableFieldList: ICustomFieldDetail[], fieldLabel: string): ICustomFieldDetail[] => {
  const arrayToSortList = [...sortDataTableFieldList];
  const filterLabelList = arrayToSortList.filter(label =>
    label.label !== fieldLabel
  ).map(label => {
    return label.label
  });
  const labelList = [fieldLabel, ...filterLabelList];
  sortDataTableFieldList.sort(
    (config1: ICustomFieldDetail, config2: ICustomFieldDetail) => {
      const index1 = labelList.indexOf(config1.label || '');
      const index2 = labelList.indexOf(config2.label || '');
      return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
    });
  return sortDataTableFieldList
}
import { IFormStatus, IFormStatusWithAction } from '../../component/admin-module/module/business-process/module/workflow/interface/form-status';
import { IFormAction } from '../../component/admin-module/module/business-process/module/workflow/interface/form-action';
import { v4 as uuid } from 'uuid';

export class SanitizeWorkflow {

  static outgoingFormStatusOnly = (statusList: IFormStatus[]): IFormStatus[] => {
    const statusListWithoutDeleted = statusList.filter((status: IFormStatus) => status.deleted !== true);
    const updatedStatusList = statusListWithoutDeleted.map((status: IFormStatus) => {
      const statusWithAction: IFormStatus = {
        id: status.id,
        name: status.name,
        initial: status.initial,
        final: status.final,
        finalPositive: status.finalPositive,
        accessLevel: status.accessLevel
      }
      return statusWithAction;
    })
    return updatedStatusList;
  }

  static outgoingFormStatus = (statusList: IFormStatus[], actionList: IFormAction[]): IFormStatusWithAction[] => {
    const statusListWithoutDeleted = statusList.filter((status: IFormStatus) => status.deleted !== true);
    const statusListWithAction = statusListWithoutDeleted.map((status: IFormStatus) => {
      let statusActionList = actionList.filter((action: IFormAction) => action.statusId === status.id && action.deleted !== true)

      const hasSave = statusActionList.filter((e: IFormAction) => e.type === 'save');
      const hasView = statusActionList.filter((e: IFormAction) => e.type === 'view');
      const hasAssign = statusActionList.filter((e: IFormAction) => e.type === 'assign');
      const hasAttach = statusActionList.filter((e: IFormAction) => e.type === 'attach');

      if (hasSave.length === 0) {
        statusActionList = statusActionList.concat([{
          id: uuid(),
          statusId: status.id,
          name: 'Save',
          type: 'save'
        }]);
      }
      if (hasView.length === 0) {
        statusActionList = statusActionList.concat([{
          id: uuid(),
          statusId: status.id,
          name: 'View',
          type: 'view'
        }]);
      }
      if (hasAssign.length === 0) {
        statusActionList = statusActionList.concat([{
          id: uuid(),
          statusId: status.id,
          name: 'Assign',
          type: 'assign'
        }]);
      }
      if (hasAttach.length === 0) {
        statusActionList = statusActionList.concat([{
          id: uuid(),
          statusId: status.id,
          name: 'Upload',
          type: 'upload'
        }]);
      }

      const statusWithAction: IFormStatusWithAction = {
        id: status.id,
        name: status.name,
        initial: status.initial,
        final: status.final,
        finalPositive: status.finalPositive === undefined ? undefined : status.finalPositive,
        actions: statusActionList,
        accessLevel: status.accessLevel
      }

      return statusWithAction;
    })
    return statusListWithAction;
  }

  static incomingFormStatus = (statusList: IFormStatusWithAction[]): { formStatusList: IFormStatus[], formActionList: IFormAction[] } => {
    let formActionList = [] as IFormAction[];
    const formStatusList = statusList.map((status: IFormStatusWithAction) => {
      if (status.actions && status.actions.length > 0) {
        formActionList = formActionList.concat(status.actions)
      }
      return {
        id: status.id,
        name: status.name,
        initial: status.initial,
        final: status.final,
        finalPositive: status.finalPositive,
        accessLevel: status.accessLevel
      } as IFormStatus
    })

    return { formStatusList: formStatusList, formActionList: formActionList }
  }
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react-bpm';
import { ConfirmationMessage } from '../../../../../../../../common/general/confirmation-modal';
import SVGWrapper from '../../../../../../../../common/icons/svg/svg-wrapper';
import { IOption } from '../../../../../../../../common/interface/option';
import { getFieldId } from '../../../../../../../../common/utils/get-field';
import { IRootState } from '../../../../../../../../reducers';
import { componentOption } from './constant';
import { useSetlabelFieldsCreator } from './hooks/use-set-label-fields-creator';
import { IAppliedLabels, IFormLabels } from './interface/label';

interface ILabelFields {
  statusOptions: IOption[];
  labelOptions: IOption[];
  labelData: IFormLabels[];
  appliedLabel: IAppliedLabels;
  editAppliedLabel(value: any): void;
  setShowAddButton(value: boolean): void;
  deleteSelectedAppliedLabel(id: string): void;
}

type TAppliedLabels = 'component' | 'status' | 'label' | 'enabled';

const LabelFieldList: React.FC<ILabelFields> = (props) => {
  const { userLoggedIn: { user }, userField } = useSelector((state: IRootState) => state.auth);
  const { company } = useSelector((state: IRootState) => state.auth);

  const [appliedLabel, setAppliedLabel] = useState<IAppliedLabels>({} as IAppliedLabels);
  const [appliedLabelToDelete, setAppliedLabelToDelete] = useState<IAppliedLabels>({} as IAppliedLabels);
  const { creatorDetails } = useSetlabelFieldsCreator({ appliedLabel, company, user });

  const getFieldValue = (fieldLabel: string) => {
    let fieldValue = '';
    if (creatorDetails && creatorDetails.fields) {
      fieldValue = creatorDetails.fields[getFieldId(userField, fieldLabel)];
    }
    return fieldValue;
  }

  const labelContents = () => {
    const { label } = { ...appliedLabel };

    return <div className='contents-data'>
      {label && label.headerField && label.headerField[0] &&
        <p className='header-field'>
          {label.headerField[0] && label.headerField[0].name ? `${label.headerField[0].name} :` : 'Not Indicated'}
          <span className={label.headerField[0].type} />
        </p>
      }
      {label && label.fields && label.fields.map((field, index) => {
        return <p className='description-field' key={`${field.id}-${index}`}>
          {field && field.name ? `${field.name} :` : 'Not Indicated'}
          <span className={field.type} />
        </p>
      })}
    </div>
  }

  const setAppliedList = (value: any, type: TAppliedLabels) => {
    let tempAppliedLabel = { ...appliedLabel };
    if (!tempAppliedLabel.new) {
      tempAppliedLabel.edited = true;
    }
    if (type === 'label') {
      const selectedLabel = props.labelData.filter(e => e.id === value);
      if (selectedLabel.length > 0) {
        tempAppliedLabel['label'] = selectedLabel[0];
      }
    } else {
      const statusSelected = [...props.appliedLabel.status];
      if (type === 'status') {
        if (value.length > 0) {
          if (value.includes('all')) {
            tempAppliedLabel[type] = ['all'] as any;
          } else {
            if (!statusSelected.includes('all')) {
              tempAppliedLabel[type] = value;
            }
          }
        } else {
          tempAppliedLabel[type] = value;
        }
      } else {
        tempAppliedLabel[type] = value;
      }
    }
    props.editAppliedLabel(tempAppliedLabel);
    props.setShowAddButton(true);
  }

  const confirmDeleteAppliedLabel = () => {
    props.deleteSelectedAppliedLabel(appliedLabelToDelete.id);
    setAppliedLabelToDelete({} as IAppliedLabels);
    props.setShowAddButton(true);
  }

  useEffect(() => {
    setAppliedLabel(props.appliedLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appliedLabel]);

  return <>
    <td className='data-field component'>
      <div className={`six wide field`}>
        <Dropdown
          className='component-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Component'
          selection
          value={appliedLabel.component}
          options={componentOption}
          multiple
          onChange={(e, target: DropdownProps) => setAppliedList(target.value, 'component')}
        />
      </div>
    </td>
    <td className='data-field status'>
      <div className={`six wide field`}>
        <Dropdown
          className='status-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Status'
          selection
          multiple
          value={appliedLabel.status}
          options={props.statusOptions}
          onChange={(e, target: DropdownProps) => setAppliedList(target.value, 'status')}
        />
      </div>
    </td>
    <td className='data-field label-d'>
      <div className={`six wide field`}>
        <Dropdown
          className='label-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Label Name'
          selection
          value={appliedLabel && appliedLabel.label && appliedLabel.label.id}
          options={props.labelOptions}
          onChange={(e, target: DropdownProps) => setAppliedList(target.value, 'label')}
        />
      </div>
    </td>
    <td className='contents'>
      {labelContents()}
    </td>
    <td>
      {getFieldValue('First Name') + ' ' + getFieldValue('Last Name')}
    </td>
    <td>
      <div onClick={() => setAppliedList(!appliedLabel.enabled, 'enabled')}
        className='ui fitted toggle checkbox checked table-checkbox-toggle'>
        <input
          checked={appliedLabel.enabled ?? false}
          style={{ cursor: 'pointer' }}
          type="checkbox"
          onChange={() => { }}
        />
        <label></label>
      </div>
    </td>
    <td>
      <Button onClick={() => setAppliedLabelToDelete(appliedLabel)} className='search-button delete'>
        <SVGWrapper iconName='trash' height='17' color='#0088a9' />
      </Button>
    </td>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      cancelButtonName={'Cancel'}
      confirmButtonColor={'red'}
      cancelButtonColor={'green'}
      close={() => { }}
      open={appliedLabelToDelete && appliedLabelToDelete.id !== undefined}
      confirm={() => confirmDeleteAppliedLabel()}
      cancel={() => setAppliedLabelToDelete({} as IAppliedLabels)}
      headerText="Confirmation"
      detailText={`This Applied Label will be deleted. Do you want to proceed?`}
      loading={false}
      type='warning'
    />
  </>
}

export default LabelFieldList;
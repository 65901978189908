import * as jsondiffpatch from 'jsondiffpatch';
import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '../..';
import { ICommentDeltaFields } from '../../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IAction } from '../../../../../../../document-module/module/report/interface/form-document';
import { IConstraint } from '../../../../../../module/business-process/module/roles/interface/constraint';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils } from '../../../../manage-history';
import { FieldConfigChange } from '../../../utils/field-config-change';

export class BPRoleDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.Roles;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    if (targetDelta) {
      deltaArray = deltaArray.concat(this.setDelta(targetDelta, targetInstance, utils));
      deltaText = `${commentSender} has updated Role (name: ${targetInstance.name})`;
    }
    return { delta: deltaArray, text: deltaText };
  }

  setDelta = (delta: Delta, oldValue: any, utils: IAdminHistoryUtils) => {
    let deltaArray = [] as ICommentDeltaFields[];

    Object.keys(delta).forEach(key => {
      if (key === 'actions') {
        deltaArray = deltaArray.concat(this.setDeltaActions(delta[key], oldValue[key], utils));
      }
      if (key === 'constraintsPerStatus') {
        deltaArray = deltaArray.concat(this.setConstraintDelta(delta[key], utils));
      }
    });

    return deltaArray;
  }

  setDeltaActions = (delta: Delta, oldValue: any[], utils: IAdminHistoryUtils) => {
    const statusList = utils.statusList || [];

    let actionDeltaArray = [] as ICommentDeltaFields[];

    const actionObject = this.groupActionByKey(delta, 'statusId');
    const oldActionList = this.groupActionItemsByStatus(oldValue, 'statusId');

    Object.keys(actionObject).forEach(key => {
      const statusDetails = statusList.find(e => e.id === key);
      const delta = this.deltaActionByType(actionObject[key] || [], oldActionList[key] || [], utils);
      actionDeltaArray.push({
        id: key,
        label: `Status: ${statusDetails && statusDetails.name.toUpperCase()}`,
        type: 'tableUpdate',
        diff: delta,
        oldValue: null
      });
    });

    return actionDeltaArray;
  }

  groupActionByKey = (delta: Delta, actionKey: any) => {
    const actionObject = {} as Delta;
    Object.keys(delta).forEach(key => {
      if (Array.isArray(delta[key])) {
        delta[key].filter((e: IAction | number) => typeof e === 'object')
          .forEach((action: IAction) => {
            const newKey = { ...action as any }[actionKey];
            actionObject[newKey] = {
              ...actionObject[newKey],
              [key]: delta[key],
              '_t': 'a'
            }
          });
      }
    });
    return actionObject;
  }

  deltaActionByType = (actionObject: Delta, oldValue: any[], utils: IAdminHistoryUtils) => {
    const oldDynamicActions = oldValue.filter(e => e.type === 'dynamic').map((e: IAction) => this.setActionItems(e));
    const oldStaticActions = oldValue.filter(e => e.type !== 'dynamic').map((e: IAction) => this.setActionItems(e));

    const dynamicActionDelta = {} as Delta;
    const staticActionDelta = {} as Delta;

    let oldDynamicActionsLength = oldDynamicActions.length - 1;
    let oldStaticActionsLength = oldStaticActions.length - 1;

    let deltaArray = [] as ICommentDeltaFields[];

    Object.keys(actionObject).forEach(key => {
      if (actionObject[key] && Array.isArray(actionObject[key])) {
        if (actionObject[key][0].type === 'dynamic') {
          let newKey = key;
          const index = oldDynamicActions.findIndex((e: any) =>
            actionObject[key].filter((d: any) => typeof d === 'object' && d.id === e.id).length > 0);
          if (key.indexOf('_') > -1 && index > -1) {
            newKey = `_${index}`;
          } else {
            oldDynamicActionsLength++;
            newKey = `${oldDynamicActionsLength}`;
          }
          dynamicActionDelta[newKey] = actionObject[key].map((e: any) => {
            if (e && typeof e === 'object') {
              return this.setActionItems(e);
            }
            return e;
          });
          dynamicActionDelta['_t'] = 'a';
        } else {
          let newKey = key;
          const index = oldStaticActions.findIndex((e: any) =>
            actionObject[key].filter((d: any) => typeof d === 'object' && d.id === e.id).length > 0);
          if (key.indexOf('_') > -1 && index > -1) {
            newKey = `_${index}`;
          } else {
            oldStaticActionsLength++;
            newKey = `${oldStaticActionsLength}`;
          }
          staticActionDelta[newKey] = actionObject[key].map((e: any) => {
            if (e && typeof e === 'object') {
              return this.setActionItems(e);
            }
            return e;
          });
          staticActionDelta['_t'] = 'a';
        }
      }
    });
    if (Object.keys(dynamicActionDelta).length > 0) {
      const enumValue = [[...oldDynamicActions], jsondiffpatch.patch([...oldDynamicActions], dynamicActionDelta)];
      const dynamicActionDeltaArr = FieldConfigChange.format({ actions: enumValue }, { actions: oldDynamicActions }, {})
      deltaArray = deltaArray.concat(dynamicActionDeltaArr);
    }
    if (Object.keys(staticActionDelta).length > 0) {
      const enumValue = [[...oldStaticActions], jsondiffpatch.patch([...oldStaticActions], staticActionDelta)];
      const staticActionDeltaArr = FieldConfigChange.format({ actions: enumValue }, { actions: oldStaticActions }, {});
      const tempAction = staticActionDeltaArr.map(action => {
        return {
          ...action,
          id: 'user-rights',
          label: 'User Rights'
        }
      });
      deltaArray = deltaArray.concat(tempAction);
    }

    return deltaArray;
  }

  setActionItems(action: IAction) {
    return {
      id: action.id,
      label: action.name ? action.name.toUpperCase() : action.type.toUpperCase()
    }
  }

  groupActionItemsByStatus = (items: any[], key: keyof IAction) => items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  setConstraintDelta = (delta: Delta, utils: IAdminHistoryUtils) => {
    const constraintObject = this.groupActionByKey(delta, 'statusId');
    const statusList = utils.statusList || [];

    let constraintDeltaArray = [] as ICommentDeltaFields[];

    Object.keys(constraintObject).forEach(key => {
      const statusDetails = statusList.find(e => e.id === key);
      constraintDeltaArray.push({
        id: key,
        label: `Status: ${statusDetails && statusDetails.name.toUpperCase()}`,
        type: 'tableUpdate',
        diff: this.constraintDeltaDetails(constraintObject[key]),
        oldValue: null
      });
    });

    return constraintDeltaArray;
  }

  constraintDeltaDetails = (delta: Delta) => {

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key.indexOf('_') > -1) {
        if (delta[key] && Array.isArray(delta[key])) {
          oldDelta['constraints'] = this.constraintItems(delta[key][0].constraints);
        }
      } else {
        newDelta['constraints'] = this.constraintItems(delta[key][0].constraints);
      }
    });

    const oldValue = Object.keys(oldDelta).length ? oldDelta : { 'constraints': [''] };
    const deltArray = FieldConfigChange.format(newDelta, oldValue, {});
    const parsedDelta = deltArray.map(delta => {
      const diff = delta.diff.filter((e: string | null) => typeof e === 'string')
      return {
        ...delta,
        diff: [delta.oldValue, diff],
      }
    });

    return parsedDelta;
  }

  constraintItems = (constraint: IConstraint[]) => {
    const isNull = constraint.every(e => !e);
    if (isNull) {
      return [""];
    }
    return constraint.map(data => {
      if (!data) {
        return null;
      }
      if (data.style) {
        data.type = data.style.constraintName;
      }
      return data.type;
    })
  }

}

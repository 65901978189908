import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import FileViewer from './file-viewer';

const FileViewerComponent: React.FC<any> = () => {
  const match: { path: string } = useRouteMatch();

  return <>
    <Route exact path={`${match.path}`}>
      <FileViewer />
    </Route>
  </>
}

export default FileViewerComponent;
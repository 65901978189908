import React, { useState } from 'react';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react-bpm';
import { IDisplayComponent, IDisplayDataConfigAssignments } from '../../interface/display-data-assignments';
import { IOption } from '../../../../../../../../../../common/interface/option';
import SVGWrapper from '../../../../../../../../../../common/icons/svg/svg-wrapper';
import { ConfirmationMessage } from '../../../../../../../../../../common/general/confirmation-modal';
import { renderFields, renderMetaFields } from './render-fields';
import { IDisplayData, IDisplayDataConfigKeypaths } from '../../interface/display-data';

interface IAppliedDisplayData {
  displayDataApplied: IDisplayDataConfigAssignments;
  displayDataComponents: IDisplayComponent[];
  statusOptions: IOption[];
  displayDataOptions: IOption[];
  componentOptions: IOption[];
  editAppliedDisplayData(value: IDisplayDataConfigAssignments, index: number): void;
  index: number;
  setShowAddButton: (value: boolean) => void
  deleteAppliedDisplayData(value: IDisplayDataConfigAssignments): void;
  displayDataConfigKeypath: IDisplayDataConfigKeypaths[];
  displayDataList: IDisplayData[];
}

const AppliedDisplayDataList: React.FC<IAppliedDisplayData> = (props) => {

  const [displayDataAppliedToDelete, setDisplayDataAppliedToDelete] = useState<IDisplayDataConfigAssignments>();

  const editAppliedDisplayData = (value: any, name: keyof IDisplayDataConfigAssignments) => {
    const tempApplied: any = { ...props.displayDataApplied };
    tempApplied[name] = value;
    if (name === 'configId') {
      const config = props.displayDataList.find(e => e.id === value);
      if (config) {
        tempApplied['content'] = config;
      } else {
        tempApplied['content'] = null;
      }
    }
    props.editAppliedDisplayData(tempApplied, props.index);
    props.setShowAddButton(true);
  }


  const confirmDeleteAppliedLabel = () => {
    if (displayDataAppliedToDelete) {
      props.deleteAppliedDisplayData(displayDataAppliedToDelete);
    }
    setDisplayDataAppliedToDelete(undefined);
    props.setShowAddButton(true);
  }

  return <>
    <td className='data-field component'>
      <div className={`six wide field`}>
        <Dropdown
          className='component-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Component'
          selection
          value={props.displayDataApplied.component}
          options={props.componentOptions}
          onChange={(e, target: DropdownProps) => editAppliedDisplayData(target.value, 'component')}
        />
      </div>
    </td>
    <td className='data-field status'>
      <div className={`six wide field`}>
        <Dropdown
          className='status-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Status'
          selection
          value={props.displayDataApplied.statusId}
          options={props.statusOptions}
          onChange={(e, target: DropdownProps) => editAppliedDisplayData(target.value, 'statusId')}
        />
      </div>
    </td>
    <td className='data-field label-d'>
      <div className={`six wide field`}>
        <Dropdown
          className='label-dropdown'
          search
          clearable
          selectOnBlur={false}
          placeholder='Select Label Name'
          selection
          value={props.displayDataApplied.configId}
          options={props.displayDataOptions}
          onChange={(e, target: DropdownProps) => editAppliedDisplayData(target.value, 'configId')}
        />
      </div>
    </td>
    <td>
      {props.displayDataApplied.content &&
        <div className='display-data-content'>
          <div className='header-container'>
            {props.displayDataApplied.content.header.map((header, idx) => {
              return <label className='header-field' key={`header-field-${idx}`}>
                {renderFields(header, props.displayDataConfigKeypath)}
              </label>
            })}
          </div>
          <div className='body-container'>
            {props.displayDataApplied.content.content.map((body, idx) => {
              return <label className='body-field' key={`body-field-${idx}`}>
                {renderFields(body, props.displayDataConfigKeypath)}
              </label>
            })}
          </div>
          <div className={`meta-container ${!props.displayDataApplied.content.meta ? 'hidden' : ''}`}>
            {props.displayDataApplied.content.meta &&
              <label className='meta-field' >
                {renderMetaFields(props.displayDataApplied.content.meta, props.displayDataConfigKeypath)}
              </label>
            }
          </div>
        </div>
      }
    </td>
    <td>
      <Button onClick={() => setDisplayDataAppliedToDelete(props.displayDataApplied)} className='search-button delete'>
        <SVGWrapper iconName='trash' height='17' color='#0088a9' />
      </Button>
    </td>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      cancelButtonName={'Cancel'}
      confirmButtonColor={'red'}
      cancelButtonColor={'green'}
      close={() => setDisplayDataAppliedToDelete(undefined)}
      open={displayDataAppliedToDelete !== undefined}
      confirm={() => confirmDeleteAppliedLabel()}
      cancel={() => setDisplayDataAppliedToDelete(undefined)}
      headerText="Confirmation"
      detailText={`This Applied Label will be deleted. Do you want to proceed?`}
      loading={false}
      type='warning'
    />
  </>
}

export default AppliedDisplayDataList;
import { PropType } from '../field-properties.handler';

interface IBooleanProps {
  value: boolean;
  fieldType?: 'bool';
}
export class BooleanProps extends PropType<IBooleanProps, IBooleanProps> {

  processValueFromRef(props: IBooleanProps): IBooleanProps {
    props.value = props.value || false;
    return props;
  }

  processValueToRef(newProps: IBooleanProps): IBooleanProps {
    newProps.value = newProps.value || false;
    return newProps;
  }
}
class EnumOperatorOption {
  name = 'enum';

  option = [
    { key: 0, value: 'is-empty', text: 'Is empty' },
    { key: 1, value: 'is-not-empty', text: 'Is not empty' },
    { key: 2, value: 'equal', text: 'Equal' },
    { key: 3, value: 'not-equal', text: 'Not Equal' },
    { key: 4, value: 'includes', text: 'Includes' },
    { key: 5, value: 'excludes', text: 'Excludes' },
    { key: 6, value: 'any-in', text: 'Any In' },
    { key: 7, value: 'contains', text: 'Contains' },
    { key: 8, value: 'does-not-contain', text: 'Does Not Contain' },
    { key: 9, value: 'starts-with', text: 'Starts With' },
    { key: 10, value: 'ends-with', text: 'Ends With' },
  ];
}

export default EnumOperatorOption;

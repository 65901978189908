import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFieldConfig } from '../../../../users/interface/field';

interface IProps {
  field: ICustomFieldDetail;
}

export const useSetCustomFieldData = (props: IProps) => {
  const { field } = { ...props };

  const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
  const [configData, setConfigData] = useState<IFieldConfig>({} as IFieldConfig);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    let field = { ...props.field };
    const config = { ...field.config } as IFieldConfig;
    setConfigData(config);
    delete field.config;
    setFieldData(field);
    if (field.flags) {
      const isUsedInAutomation = field.flags.indexOf('automation') > -1;
      const isConstraint = field.flags.indexOf('constraint') > -1;
      setIsViewOnly(isConstraint || isUsedInAutomation);
    } else {
      setIsViewOnly(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(field)]);

  return {
    fieldData, setFieldData, configData, setConfigData,
    isViewOnly, setIsViewOnly, ...props
  };
}
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { IFieldPermissionSettings } from '../..';
import { OperatorOption } from '../../../../../../../../../common/custom-field-config/filter-configuration/operator-option';
import { IConfig, ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';
import { IOption } from '../../../../../../../../../common/interface/option';
import { allowedFieldCompare, regularFieldOperator } from '../../../../../../../../../common/utils/field-operator';
import { IRootState } from '../../../../../../../../../reducers';
import { IField } from '../../../../../../users/interface/field';
import { IPermissionFieldCondition, IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import ConditionFieldFilter from '../utils/condition-field-filter';
import { StructureSettingsCondition } from '../utils/structure-settings-condition';
import { hideValueAndFieldOption } from '../../../../../../../../../common/utils/field-operator';

interface IConditionConfigProps extends IFieldPermissionSettings {
  permissionConfigRef: React.MutableRefObject<IPermissionFieldFilterConfig | undefined>;
}

const ConditionConfig: React.FC<IConditionConfigProps> = (props) => {

  const initialConfig: IPermissionFieldCondition = {
    id: '',
    fieldId: '',
    fromBp: '',
    operator: '',
    compareType: 'value',
    compareValue: '',
    compareField: {
      fieldId: ''
    }
  };

  const { company } = useSelector((state: IRootState) => state.auth);

  const defaultvalueRef = useRef<{ [id: string]: { value: string } }>({});

  const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
  const [configData, setConfigData] = useState<IConfig>({} as IConfig);
  const [refresher, setRefresher] = useState(0);

  const conditions = props.permissionConfigRef.current && props.permissionConfigRef.current.condition ?
    props.permissionConfigRef.current.condition : [];

  const getReferenceBpDetail = (): { id: string, name: string } => {
    if (Object.keys(props.currentBP).length > 0) {
      return { id: props.currentBP.id || '', name: props.currentBP.name };
    }
    return {} as { id: string, name: string };
  }

  const getSelectedField = (condition: IPermissionFieldCondition): ICustomFieldDetail => {
    if (condition.fieldId && condition.fieldId !== '') {
      let fieldConfig: ICustomFieldDetail = Object.assign({});;
      if (props.currentBP.id === condition.fromBp) {
        const fieldList = Object.assign([], [...props.fieldList]);
        const [mainId, subId] = condition.fieldId.split('.');
        const config: any = fieldList.find((field: ICustomFieldDetail) => field.id === mainId);
        if (config && typeof config === 'object') {
          fieldConfig = { ...config };
        }
        if (props.isTablePermission && props.selectedTableSection && props.selectedTableSection.fields.length > 0 && props.selectedTableSection.id === mainId) {
          const column = props.selectedFieldSection.fields.find((field: ICustomFieldDetail) => field.id === subId)
          if (column && typeof column === 'object') {
            fieldConfig = { ...column };
          }
        }
      }
      if (fieldConfig && fieldConfig.type && fieldConfig.config) {
        if (fieldConfig.type === 'enum' && fieldConfig.config) {
          fieldConfig.config = {
            ...fieldConfig.config,
            dataType: fieldConfig.config.dataType
          };
        }
        if (fieldConfig.type === 'autopopulated' && fieldConfig.config) {
          fieldConfig = {
            ...fieldConfig,
            type: fieldConfig.config.labelType
          }
        }
        return fieldConfig
      }
    }
    return {} as ICustomFieldDetail
  }

  const handleCompareField = (name: 'fieldId', value: string, index: number) => {
    const tempCondition = [...conditions];
    tempCondition[index].compareField[name] = value;
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.condition = tempCondition;
    }
    setRefresher(refresher + 1);
  }

  const handleConfig = (name: 'fieldId' | 'operator' | 'compareValue', value: string, index: number) => {
    const tempCondition = [...conditions];
    tempCondition[index][name] = value;
    if (name === 'compareValue') {
      if (defaultvalueRef.current[tempCondition[index].id]) {
        let fieldList = [...props.fieldList]
        const selectedFieldCompare = fieldList.filter(field => field.id === tempCondition[index].fieldId);
        const compareValue = setCompareValueBasedOnField(selectedFieldCompare[0], value);
        tempCondition[index].compareValue = compareValue;
      }
    }
    if (name === 'fieldId') {
      tempCondition[index].operator = '';
      defaultvalueRef.current[tempCondition[index].id] = { value: '' };
    }
    if (name === 'operator') {
      const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(value);
      const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(value);
      if (hideValueOption && hideFieldOption) {
        tempCondition[index].compareType = 'value';
      }
    }
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.condition = tempCondition;
    }
    setRefresher(refresher + 1);
  }

  const getCurrentBpField = (condition: IPermissionFieldCondition): IOption[] => {
    let options: IOption[] = [];
    props.fieldList.forEach((field: ICustomFieldDetail | IField) => {
      if (field.configName !== 'Seq' && field.id !== props.fieldData.id && field.configName !== 'Table') {
        if (condition && condition.fieldId) {
          const selectedField = getSelectedField(condition);
          if (selectedField && selectedField.configName && field.configName && selectedField.id !== field.id) {
            if (selectedField.configName === IConfigName.Referenced) {
              const { config }: any = { ...field as any };
              const { data } = { ...selectedField.config };
              if (data && Object.keys(data).length && config && Object.keys(config).length
                && JSON.stringify(data) === JSON.stringify(config.data)) {
                options.push({
                  key: uuid(),
                  text: field.label || '',
                  value: field.id || '',
                });
              }
            } else {
              const allowedField = allowedFieldCompare[selectedField.configName];
              const allowedFields = allowedField ? allowedField.split('|') : [];
              if (allowedFields && allowedFields.length > 0) {
                if (allowedFields.indexOf(field.configName) > -1) {
                  options.push({
                    key: uuid(),
                    text: field.label || '',
                    value: field.id || '',
                  });
                }
              } else {
                options.push({
                  key: uuid(),
                  text: field.label || '',
                  value: field.id || '',
                })
              }
            }
          }
        } else {
          options.push({
            key: uuid(),
            text: field.label || '',
            value: field.id || '',
          })
        }
      }
      if (props.selectedTableSection && props.selectedTableSection.id === field.id) {
        props.selectedTableSection.fields.forEach(e => {
          if (condition && condition.fieldId) {
            const [, subId] = condition.fieldId.split('.');
            if (subId !== e.id && e.id !== props.fieldData.id) {
              const selectedField = getSelectedField(condition);
              if (selectedField && selectedField.configName && e.configName && e.id !== selectedField.id) {
                if (selectedField.configName === IConfigName.Referenced) {
                  const { config } = { ...e };
                  const { data } = { ...selectedField.config };
                  if (data && Object.keys(data).length && config && Object.keys(config).length
                    && JSON.stringify(data) === JSON.stringify(config.data)) {
                    options.push({
                      key: uuid(),
                      value: `${field.id}.${e.id}`,
                      text: `[${field.label}] ${e.label}`
                    });
                  }
                } else {
                  const allowedField = allowedFieldCompare[selectedField.configName];
                  const allowedFields = allowedField ? allowedField.split('|') : [];
                  if (allowedFields && allowedFields.length > 0) {
                    if (allowedFields.indexOf(e.configName) > -1) {
                      options.push({
                        key: uuid(),
                        value: `${field.id}.${e.id}`,
                        text: `[${field.label}] ${e.label}`
                      });
                    }
                  } else {
                    options.push({
                      key: uuid(),
                      value: `${field.id}.${e.id}`,
                      text: `[${field.label}] ${e.label}`
                    });
                  }
                }

              }
            }
          } else {
            const exists = options.find(o => o.value === e.id);
            if (!exists && e.id !== props.fieldData.id) {
              options.push({
                key: uuid(),
                value: `${field.id}.${e.id}`,
                text: `[${field.label}] ${e.label}`
              });
            }
          }
        })
      }
    });
    return options;
  }

  const filterOperatorOption = (option: IOption[], valuesToShow: string[]) => {
    let newOption = [] as IOption[];
    let ctr = 0;
    valuesToShow.forEach((values: string) => {
      option.forEach((option: IOption) => {
        if (option.value === values) {
          const exists = newOption.find(e => e.value === option.value);
          if (!exists) {
            newOption.push({
              key: ctr++,
              text: option.text,
              value: option.value
            })
          }
        }
      })
    })
    return newOption;
  }

  const getOperatorOptionToShow = (fieldType: string, condition: IPermissionFieldCondition) => {
    let operatorOption = OperatorOption.getOption(fieldType);
    let newOption = [] as IOption[];
    if (!regularFieldOperator[fieldType]) {
      return [];
    }
    newOption = filterOperatorOption(operatorOption, regularFieldOperator[fieldType].split('|'))
    return newOption;
  }

  const getOperatorOption = (condition: IPermissionFieldCondition): IOption[] => {
    if (condition.fieldId && condition.fieldId !== '') {
      const [mainId, subId] = condition.fieldId.split('.');
      let fieldList = props.fieldList;
      let fieldConfig: ICustomFieldDetail[] = [];
      fieldConfig = fieldList.filter((field: ICustomFieldDetail) => field.id === mainId);
      if (props.isTablePermission && props.selectedFieldSection && props.selectedFieldSection.fields &&
        props.selectedFieldSection.fields.length > 0 && props.selectedFieldSection.id === mainId) {
        fieldConfig = props.selectedFieldSection.fields.filter((field: ICustomFieldDetail) => field.id === subId);
      }
      if (fieldConfig.length > 0) {
        if (fieldConfig[0].multiline || (fieldConfig[0].type === 'enum' && fieldConfig[0].config && fieldConfig[0].config.multiselect)) {
          return getOperatorOptionToShow('multiple-enum', condition)
        }
        if (fieldConfig[0].type === 'autopopulated') {
          return getOperatorOptionToShow(fieldConfig[0].config?.labelType || '', condition)
        }
        return getOperatorOptionToShow(fieldConfig[0].type || '', condition)
      }
    }
    return []
  }

  const addNewCondition = () => {
    const newCondition = [...conditions];
    let newObject = { ...initialConfig, fromBp: props.currentBP.id || '', id: uuid() };
    newCondition.push(newObject);
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.condition = newCondition;
    }
    setRefresher(refresher + 1);
  }

  const removeCondition = (conditionIndex: number) => {
    const tempCondition = [...conditions];
    tempCondition.splice(conditionIndex, 1);
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.condition = tempCondition;
    }
    setRefresher(refresher + 1);
  }

  const setCompareValueBasedOnField = (field: ICustomFieldDetail, value: any) => {
    if (field && field.multiline) {
      value = new Array(value);
    }
    if (field && field.type === 'enum') {
      if (field && field.config && field.config.multiselect
        && value && typeof value === 'string') {
        value = JSON.parse(value);
      }
    }
    return value;
  }

  useEffect(() => {
    let field = { ...props.fieldData };
    let config = {
      ...field.config,
      dataType: field.config && field.config.dataType === 'user' ? 'user' : 'document'
    } as IConfig;
    delete field.config;
    delete field.id;
    const conditionConfig = new StructureSettingsCondition();
    const tempCondition = conditionConfig.setConditionConfig({
      fieldData: props.fieldData,
      isSectionPermission: props.isSectionPermission,
      isTablePermission: props.isTablePermission,
      selectedFieldSection: props.selectedFieldSection,
      selectedStatus: props.selectedStatus,
      selectedTableSection: props.selectedTableSection,
      currentBP: props.currentBP,
      fieldList: props.fieldList,
      selectedStatusAction: props.selectedStatusAction,
      defaultvalueRef
    });
    setConfigData(config);
    setFieldData(field);
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.condition = tempCondition;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedStatus, props.fieldData, props.currentBP, props.open,
  props.selectedFieldSection, props.fieldList, props.selectedStatusAction]);

  return <>
    {conditions.map((condition: IPermissionFieldCondition, index: number) => {
      return <ConditionFieldFilter
        key={condition.id}
        getCurrentBpField={getCurrentBpField}
        handleConfig={handleConfig}
        getOperatorOption={getOperatorOption}
        condition={condition}
        rowIndex={index}
        company={company}
        getSelectedField={getSelectedField}
        handleCompareField={handleCompareField}
        fieldData={fieldData}
        getReferenceBpDetail={getReferenceBpDetail}
        defaultvalueRef={defaultvalueRef}
        configData={configData}
        removeCondition={removeCondition}
        isTablePermission={props.isTablePermission}
        parentFormFieldId={props.selectedFieldSection.id}
        currentFieldList={props.fieldList}
      />
    })
    }
    <div className='add-new-condition'>
      <i className={`plus circular icon new-condition`} onClick={() => addNewCondition()} />
    </div>
  </>
}

export default ConditionConfig;
import { useEffect } from 'react';
import { IPdfTemplateConfig } from '../../interface/pdf';

interface IProps {
  pdfTemplateConfigList: IPdfTemplateConfig[]
  pdfTemplateId: string;
  templateRef: React.MutableRefObject<HTMLTextAreaElement>;
  nameRef: React.MutableRefObject<HTMLInputElement>;
  history: any;
  match: any;
}

export const useSetPDFTemplateFormStateAndRefs = (props: IProps) => {
  const { pdfTemplateConfigList, pdfTemplateId, history, match, nameRef, templateRef } = { ...props };

  const cancelClick = () => {
    history.push(`/admin/business-process/configuration/${match.params.formId}/pdf-template`)
  }

  useEffect(() => {
    if (pdfTemplateId !== 'new-template') {
      const pdfTemplate = pdfTemplateConfigList.find((e: IPdfTemplateConfig) => e.id === pdfTemplateId);
      if (pdfTemplate) {
        nameRef.current.value = pdfTemplate.name;
        templateRef.current.value = pdfTemplate.template;
      } else {
        cancelClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfTemplateId, pdfTemplateConfigList]);

  return { ...props, cancelClick };
}
interface IUtils {
  setIsScrollable: (value: React.SetStateAction<boolean>) => void;
  setContainer: (value: any) => void;
  scrollContainerRef: React.MutableRefObject<any>;
  setScrollLeft?: (value: React.SetStateAction<number>) => void;
  duration?: number;
}

export class ScrollableListAction {
  static setScrollListRef = (element: any | null, utils: IUtils) => {
    if (!element) return;
    const { clientWidth, scrollWidth } = element;
    utils.setIsScrollable(scrollWidth > clientWidth);
    utils.setContainer(element);
    return utils.scrollContainerRef.current = element;
  }
  static easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
  static scrollToList = (change: number, utils: IUtils) => {
    const duration = utils.duration ? utils.duration : 300;
    let start = utils.scrollContainerRef.current.scrollLeft,
      currentTime = 0,
      increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const scrollLeft = ScrollableListAction.easeInOutQuad(currentTime, start, change, duration);
      utils.scrollContainerRef.current.scrollLeft = scrollLeft;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
        if (utils.setScrollLeft) {
          utils.setScrollLeft(scrollLeft);
        }
      }
    }

    animateScroll();
  }
}
import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, Button, Form } from 'semantic-ui-react-bpm';
import { DragObjectDisplayData, IDisplayData, IDisplayDataConfigKeypaths, IDisplayDataFields, IDisplayDataConfig } from '../interface/display-data';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import LabelFields from './fields';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';
import { IOption } from '../../../../../../../../../common/interface/option';
import {
  fieldTitleOption,
  metadataTitleOption,
  metaBPIcon,
  defaultThumbnail,
  errorIndicator,
  defaultSelectedKeypaths,
  defaultMetadata
} from '../constant';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';

interface IDisplayDataModalProps {
  isHiddenContainer: boolean;
  setSelectedData(value: IDisplayData | undefined): void;
  selectedData: IDisplayData;
  displayDataConfigKeypaths: IDisplayDataConfigKeypaths[];
  fieldList: ICustomFieldDetail[];
  saveFieldConfig(value: IDisplayData): void;
  deleteSelectedDisplayData: (isNew?: boolean | undefined) => void;
}

const defaultMetadataList: IDisplayDataConfig[] = [...defaultMetadata];

const DisplayDataModal: React.FC<IDisplayDataModalProps> = (props) => {
  const refName = useRef(document.createElement('input'));

  const [error, setError] = useState(errorIndicator);
  const [selectedKeypaths, setSelectedKeypaths] = useState(defaultSelectedKeypaths);
  const [headerField, setHeaderField] = useState<IDisplayDataFields[]>([]);
  const [contentField, setContentField] = useState<IDisplayDataFields[]>([]);
  const [thumbnail, setThumbnail] = useState<IDisplayDataConfig>();
  const [BPIcon, setBPIcon] = useState<IDisplayDataConfig>();
  const [metadata, setMetadata] = useState<IDisplayDataConfig[]>(defaultMetadataList);

  const getDeletedFieldIds = () => {
    return props.displayDataConfigKeypaths.filter(config => {
      if (config.keyPath.indexOf('fields.') > -1) {
        let keyPath = config.keyPath;
        keyPath = keyPath.split('fields.')[1];
        const exists = props.fieldList.find(e => e.id === keyPath);
        return exists === undefined;
      }
      return false;
    }).map(e => e.keyPath);
  }

  const setFieldSelectionOptions = (fields: IDisplayDataConfigKeypaths[], metadata: IDisplayDataConfigKeypaths[], fileType?: boolean) => {
    const options: IOption[] = [];
    const deletedKeypaths = getDeletedFieldIds();
    if (fields.length) {
      options.push({ ...fieldTitleOption, text: fileType ? 'BP File Fields' : fieldTitleOption.text });
      fields.forEach((e, i) => {
        options.push({ key: e.keyPath, text: e.name, value: e.keyPath, disabled: deletedKeypaths.includes(e.keyPath) })
      });
    }
    if (metadata.length) {
      options.push(metadataTitleOption);
      metadata.forEach((e, i) => {
        options.push({ key: e.keyPath, text: e.name, value: e.keyPath })
      });
    }
    return options;
  }

  const getOptions = (fileType?: boolean, metaOnly?: boolean) => {
    const configKeyPaths = props.displayDataConfigKeypaths.filter(config => {
      const selected: string[] = [].concat.apply([], [
        ...contentField.map(e => e.config) as any,
        ...headerField.map(e => e.config) as any
      ]);
      return selected.indexOf(config.keyPath) === -1;
    }).filter(config => {
      let keyPath = config.keyPath;
      if (keyPath.indexOf('fields.') > -1) keyPath = keyPath.split('fields.')[1];
      const keyPathField = props.fieldList.find(e => e.id === keyPath);
      if (fileType) {
        return keyPathField && keyPathField.configName === IConfigName.FileUpload
      }
      if (metaOnly) {
        return config.keyPath.indexOf('fields.') === -1
      }
      return true;
    });
    return setFieldSelectionOptions(
      configKeyPaths.filter(e => e.keyPath.indexOf('fields.') > -1),
      configKeyPaths.filter(e => e.keyPath.indexOf('fields.') === -1),
      fileType
    );
  }

  const updateSelectedKeypaths = (value: string[], name: string) => {
    const tempSelectedKeypaths = { ...selectedKeypaths };
    tempSelectedKeypaths[name] = value;
    setSelectedKeypaths(tempSelectedKeypaths);
  }

  const dropField = (dragItem: DragObjectDisplayData, type: 'header' | 'content') => {
    if (type === 'header') {
      const tempContentField = [...contentField];
      const tempHeaderField = [...headerField];
      if (tempHeaderField.length < 2) {
        tempContentField.splice(dragItem.index, 1);
        tempHeaderField.push(dragItem.data);
        setContentField(tempContentField);
        setHeaderField(tempHeaderField);
      }
    } else {
      const tempContentField = [...contentField];
      const tempHeaderField = [...headerField];
      if (tempContentField.length < 4) {
        tempContentField.push(dragItem.data);
        tempHeaderField.splice(dragItem.index, 1);
        setContentField(tempContentField);
        setHeaderField(tempHeaderField);
      }
    }
  }

  const sortField = (draggedItem: DragObjectDisplayData, dropIndex: number, type: 'header' | 'content') => {
    if (type === 'content') {
      const tempContentField = [...contentField];
      const dragIndex = tempContentField.indexOf(draggedItem.data);
      const element = tempContentField[dragIndex];
      tempContentField.splice(dragIndex, 1);
      tempContentField.splice(dropIndex, 0, element);
      setContentField(tempContentField);
    } else {
      const tempHeaderField = [...headerField];
      const dragIndex = tempHeaderField.indexOf(draggedItem.data);
      const element = tempHeaderField[dragIndex];
      tempHeaderField.splice(dragIndex, 1);
      tempHeaderField.splice(dropIndex, 0, element);
      setHeaderField(tempHeaderField);
    }
  }

  const removeField = (index: number, fieldType: string) => {
    if (fieldType === 'header') {
      const tempContentField = [...headerField];
      tempContentField.splice(index, 1);
      setHeaderField(tempContentField);
    } else {
      const tempContentField = [...contentField];
      tempContentField.splice(index, 1);
      setContentField(tempContentField);
    }
  }

  const updateField = (index: number, data: IDisplayDataFields, fieldType: 'header' | 'content' | 'meta') => {
    if (fieldType === 'header') {
      let tempContentField = [...headerField];
      tempContentField.splice(index, 1, data);
      setHeaderField(tempContentField);
    } else {
      let tempContentField = [...contentField];
      tempContentField.splice(index, 1, data);
      setContentField(tempContentField);
    }
  }

  const addField = (value: string[], type: 'header' | 'content') => {
    if (type === 'content') {
      const tempContentField = [...contentField];
      tempContentField.push({ config: value, showLabel: false });
      setContentField(tempContentField);
      updateSelectedKeypaths([], 'content');
    } else {
      const tempHeaderField = [...headerField];
      tempHeaderField.push({ config: value, showLabel: false });
      setHeaderField(tempHeaderField);
      updateSelectedKeypaths([], 'header');
    }
  }

  const setThumbnailField = (value: any, name: keyof IDisplayDataConfig) => {
    const tempThumbnail: any = { ...thumbnail };
    tempThumbnail[name] = value;
    setThumbnail(tempThumbnail);
  }

  const updateMetaData = (value: any, name: 'showLabel' | 'keyPath', index: number) => {
    const tempMeta: any = [...metadata];
    tempMeta[index][name] = value;
    setMetadata(tempMeta);
  }

  const deleteMetaData = (index: number) => {
    const tempMeta: any = [...metadata];
    tempMeta.splice(index, 1);
    tempMeta.splice(index - 1, 1);
    setMetadata(tempMeta);
  }

  const addMetadataRow = () => {
    const tempMeta = [...metadata];
    tempMeta.push({
      slot: `meta_${tempMeta.length + 1}`,
      keyPath: '',
      showLabel: false
    });
    tempMeta.push({
      slot: `meta_${tempMeta.length + 1}`,
      keyPath: '',
      showLabel: false
    });
    setMetadata(tempMeta);
  }

  const isValid = () => {
    let currentError: any = { ...error };
    let hasHeaderSelected = headerField.length > 0 && headerField.map(e => e.config)[0].every(v => v);

    currentError.name = (!refName.current.value) ? true : false;
    currentError.header = !hasHeaderSelected;

    setError(currentError);
    return Object.keys(currentError).every(key => currentError[key] === false);
  }

  const metaFields = () => {
    let tempMeta = [...metadata];
    let allFilled = tempMeta.every(e => e.keyPath !== '');
    if (!allFilled) {
      tempMeta = tempMeta.filter(e => e.keyPath !== '');
    }
    return tempMeta;
  }

  const reset = () => {
    refName.current.value = '';
    setHeaderField([]);
    setContentField([]);
    setThumbnail(undefined);
    setBPIcon(undefined);
  }

  const saveFieldConfig = () => {
    if (!isValid()) {
      return;
    }
    const dataToSubmit: IDisplayData = {
      ...props.selectedData,
      content: contentField,
      header: headerField,
      meta: metaFields(),
      bpIcon: BPIcon,
      thumbnail: thumbnail,
      name: refName.current.value
    };
    props.saveFieldConfig(dataToSubmit);
    reset();
  }

  const parseMeta = (meta: IDisplayDataConfig[]) => {
    let tempMeta = [...meta];
    const isOddCount = meta.length % 2 !== 0;
    if (isOddCount) {
      tempMeta.push({
        slot: `meta_${tempMeta.length + 1}`,
        keyPath: '',
        showLabel: false
      });
    }
    return tempMeta;
  }

  const cancelFieldConfig = () => {
    if (props.selectedData.new) {
      props.deleteSelectedDisplayData(true);
    }
    props.setSelectedData(undefined)
  }

  useEffect(() => {
    if (props.selectedData) {
      setHeaderField(props.selectedData.header);
      setContentField(props.selectedData.content);
      setBPIcon(props.selectedData.bpIcon);
      setThumbnail(props.selectedData.thumbnail);
      setMetadata(props.selectedData.meta ? parseMeta(props.selectedData.meta) : defaultMetadataList);
      refName.current.value = props.selectedData.name;
    }
  }, [props.selectedData])

  return <>
    <div hidden={props.isHiddenContainer} id='relation-config-modal-hidden-container' className='hidden-container' onClick={() => props.setSelectedData(undefined)} />
    <div id='file-upload-modal-dimmer' className="ui page modals dimmer transition visible active">
      <div id={`label-config-modal`} hidden={props.isHiddenContainer} className='ui modal transition visible active'>
        <div className='bp-selected-header'> Label Setup</div>
        <div className='content'>
          <Form size='large' id='group-form' >
            <Form.Group widths='equal'>
              <div className={`required six wide field`}>
                <label>Name</label>
                <div className={`ui input ${error.name ? 'error' : ''}`}>
                  <input ref={refName} type="text" />
                </div>
              </div>
            </Form.Group>
          </Form>
          <div className='header-container '>
            <div className='header-label'>
              <label className='required'>Header</label>
              <label className={`label ${headerField.length === 0 ? 'hidden' : ''}`}>Label</label>
            </div>
            <div className='header-fields-container'>
              {headerField.length > 0 &&
                <div className={`header-field`}>
                  {headerField.map((field: IDisplayDataFields, index: number) => {
                    return <LabelFields
                      key={`label-fields-header-${index}`}
                      field={field}
                      index={index}
                      fieldType='header'
                      dropField={dropField}
                      sortField={sortField}
                      removeField={removeField}
                      updateField={updateField}
                      displayDataConfigKeypaths={props.displayDataConfigKeypaths}
                    />
                  })}
                </div>
              }
              {headerField.length < 2 &&
                <div className={`field-draft ${headerField.length > 0 ? 'selected' : ''}`}>
                  <Dropdown
                    fluid
                    selection
                    placeholder='Select Fields'
                    clearable
                    search
                    multiple
                    options={getOptions()}
                    selectOnBlur={false}
                    value={selectedKeypaths['header']}
                    className={error.header ? 'error' : ''}
                    onChange={(event, target: any) => updateSelectedKeypaths(target.value, 'header')}
                  />
                  <Button className={`blue add ${selectedKeypaths['header'].length === 0 ? 'disabled' : ''}`}
                    icon='plus' onClick={() => addField(selectedKeypaths['header'], 'header')} />
                </div>
              }
            </div>
            <small className={headerField.length > 0 ? 'selected' : ''}>(Maximum: 2 Rows)</small>
          </div>
          <div className={`selected-field-container`}>
            <div className='header-label'>
              <label>Description</label>
              <label className={`label ${contentField.length === 0 ? 'hidden' : ''}`}>Label</label>
            </div>
            <div className='content-fields-container'>
              {contentField.length > 0 &&
                <div className={`selected-field`}>
                  {contentField.map((field: IDisplayDataFields, index: number) => {
                    return <LabelFields
                      key={`label-fields-content-${index}`}
                      field={field}
                      index={index}
                      fieldType='content'
                      dropField={dropField}
                      sortField={sortField}
                      removeField={removeField}
                      updateField={updateField}
                      displayDataConfigKeypaths={props.displayDataConfigKeypaths}
                    />
                  })}
                </div>
              }
              {contentField.length < 4 &&
                <div className={`field-draft ${contentField.length > 0 ? 'selected' : ''}`}>
                  <Dropdown
                    fluid
                    selection
                    placeholder='Select Fields'
                    clearable
                    search
                    multiple
                    options={getOptions()}
                    selectOnBlur={false}
                    value={selectedKeypaths['content']}
                    onChange={(event, target: any) => updateSelectedKeypaths(target.value, 'content')}
                  />
                  <Button className={`blue add ${selectedKeypaths['content'].length === 0 ? 'disabled' : ''}`}
                    icon='plus' onClick={() => addField(selectedKeypaths['content'], 'content')} />
                </div>
              }
            </div>
            <small className={contentField.length > 0 ? 'selected' : ''}>(Maximum: 4 Rows)</small>
          </div>
          <div className={`metadata-container`}>
            <div className='header-label'>
              <label>Metadata</label>
            </div>
            <div className='metadata'>
              <div className='field-container'>
                {metadata.map((config: IDisplayDataConfig, idx) => {
                  const odd = idx % 2 !== 0;
                  const configKeypath = props.displayDataConfigKeypaths.find(e => e.keyPath === config.keyPath);
                  return <div key={`meta-config-${idx}`} className={`metadata-fields ${odd ? 'grid' : ''}`}>
                    <div className='selection'>
                      <Dropdown
                        fluid
                        selection
                        placeholder='Select'
                        clearable
                        search
                        title={configKeypath ? configKeypath.name : undefined}
                        options={getOptions(false, true)}
                        className={error.meta ? 'error' : ''}
                        selectOnBlur={false}
                        value={config.keyPath}
                        onChange={(event, target: any) => updateMetaData(target.value, 'keyPath', idx)}
                      />
                      <div className='field-label eye' onClick={() => updateMetaData(!config.showLabel, 'showLabel', idx)}>
                        {!config.showLabel &&
                          <svg className='slash' width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="14.3536" y1="0.353553" x2="0.353553" y2="14.3536" stroke="#8A9296" />
                          </svg>
                        }
                        <SVGWrapper color='#8A9296' iconName='eye' width='14' height='14' />
                      </div>
                    </div>
                    {odd && <div className='field-label del' onClick={() => deleteMetaData(idx)}>
                      <SVGWrapper iconName='trash' height='15' color='#8A9296' />
                    </div>
                    }
                  </div>
                })}
              </div>
              <div className='btn-add' onClick={addMetadataRow}>
                Add rows
              </div>
            </div>
          </div>
          <div>
            <div className='toggle-checkbox-container'>
              <div className='toggle-checkbox'>
                <div onClick={() => {
                  if (!thumbnail) {
                    setThumbnail(defaultThumbnail)
                  } else {
                    setThumbnail(undefined);
                  }
                }} className='ui fitted toggle checkbox checked'>
                  <input
                    checked={thumbnail !== undefined}
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    onChange={() => { }}
                  />
                  <label></label>
                </div>
                <span className='label'>Thumbnail</span>
              </div>
              <div className='toggle-checkbox'>
                <div onClick={() => {
                  if (!BPIcon) {
                    setBPIcon(metaBPIcon);
                  } else {
                    setBPIcon(undefined);
                  }
                }} className='ui fitted toggle checkbox checked'>
                  <input
                    checked={BPIcon !== undefined}
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    onChange={() => { }}
                  />
                  <label></label>
                </div>
                <span className='label'>Show BP Icon?</span>
              </div>
            </div>
            {thumbnail !== undefined &&
              <div className='field-selection'>
                <Dropdown
                  fluid
                  selection
                  placeholder='Select File Fields'
                  clearable
                  search
                  options={getOptions(true)}
                  value={thumbnail.keyPath}
                  selectOnBlur={false}
                  onChange={(event, target) => setThumbnailField(target.value, 'keyPath')}
                />
              </div>
            }
          </div>
        </div>
        <div className='action'>
          <Button className='btn-success' onClick={saveFieldConfig}>Save</Button>
          <Button className='btn-default' onClick={cancelFieldConfig}>Cancel</Button>
        </div>
      </div>
    </div>
  </>
}

export default DisplayDataModal;
import React, { useState, Suspense, lazy, useRef, createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react-bpm';
import {
  deleteUserAction,
  resetMessageResponseAction,
  filterUsersAction,
  resetUserFieldAction,
} from './action';
import { UserFilter } from './filter';
import { UserTableList } from './user-table';
import { ConfirmationMessage } from '../../../../common/general/confirmation-modal';
import { message } from './message';
import FieldType from './field-type';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { IRootState } from '../../../../reducers';
import { IUser } from './interface/user';
import { formatFilterValues } from '../../../../common/utils/format-filter-values';
import ActionMessage from '../../../../common/general/action-message';
import { userListActionMessage, userListActionMessageAddNew, UPLOAD_USERLIST_SUCCESS_MESSAGE, UPLOAD_USERLIST_ERROR_MESSAGE, userListRecordLimit } from './constant';
import FileUploadModal from '../../../../common/general/file-upload-modal';
import AllowedUserListActionControl from '../../../../common/utils/user-list-action-control';
import PaginationComponent from '../../../../common/general/pagination';
import getAllowedUserType from '../../../../common/utils/get-allowed-user-type';
import useDeviceDetect from '../../../../common/general/device-detect';
import { useRect } from '../../../../common/utils/useRect';
import { useGetUserData } from './hooks/use-get-user-data';
import { useRemoveCustomPopup } from './hooks/use-remove-custom-popup';
import { useSetUserTableHeight } from './hooks/use-set-user-table-height';
import { IConfigName } from '../../../../common/field/type/interface/field-type-object';

const UserFormModal = lazy(() => import('./user-form'));

export interface IFilterData {
  filters: any;
  globalSearch: any;
}

interface IUserComponentProps {
  companyId: string;
  rootLink: string;
  allowedAccessAuditLogs?: boolean;
}

const filterRef = createRef<HTMLDivElement>();

const UserComponent: React.FC<IUserComponentProps> = (props) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  let filterTimeout: any = 0;
  const companyId = props.companyId;

  const user = useSelector((state: IRootState) => state.user);
  const { userLoggedIn } = useSelector((state: IRootState) => state.auth);
  const { isOpenAdminChatbox } = useSelector((state: IRootState) => state.main);
  const { width } = useDeviceDetect();
  const filterRect = useRect(filterRef);

  const searchableDynamicFields = user.userFieldListDynamic.filter(field => field.searchable === true && field.configName === IConfigName.Dropdown);
  const userLoggedInProfiles = userLoggedIn.profiles ? userLoggedIn.profiles : {};
  const currentUserProfile = userLoggedInProfiles[companyId] ? userLoggedInProfiles[companyId] : [];
  const validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);
  const allowedUserType = getAllowedUserType(currentUserProfile);
  let userTypeList = user.userProfileList.map((e: any, i: any) => { return { key: i, text: e.name, value: e.id } });
  const userTypeOption = userTypeList.filter((e: any) => allowedUserType.indexOf(e.text) > -1);

  const [currentOffset, setCurrentOffset] = useState(0);
  const [userList, setUserList] = useState([] as IUser[]);
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [userIdForDeletion, setUserIdForDeletion] = useState('');
  const [isHiddenContainer, setIsHiddenContainer] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);
  const [filterData, setFilterData] = useState({} as IFilterData);
  const [userNameForDeletion, setUserNameForDeletion] = useState('');
  const [isOpenFileUploadModal, setIsOpenFileUploadModal] = useState(false);
  const [isOpenUpdateCustomFieldModal, setIsOpenUpdateCustomFieldModal] = useState(false);

  const dynamicRefs: any = useRef({});

  const filterGridColumns = () => {
    let gridColumns = 7;
    if (searchableDynamicFields) {
      let screenWidth: number[] = [770, 895, 1060, 1200, 1360, 1500, 1680];
      if (searchableDynamicFields.length <= 3) {
        gridColumns = 2 + searchableDynamicFields.length;
      }
      for (let i = gridColumns; i > -1; i--) {
        if (width <= screenWidth[i]) {
          gridColumns--;
        }
      }
    }
    return gridColumns;
  }

  const changePage = (limit: number, offset: number) => {
    setCurrentOffset(offset);
    dispatch(filterUsersAction({ company: companyId, offset: offset, filterData: filterData.filters, search: filterData.globalSearch }));
  }

  const openDeleteModal = (id: string, userName: string) => {
    setIsOpenDeleteModal(true);
    setUserIdForDeletion(id);
    setUserNameForDeletion(userName);
  }

  const openCustomFieldModal = (id: string, userName: string) => {
    const element: HTMLElement | null = document.getElementById('button-action-plus');
    const element2: HTMLElement | null = document.getElementById('custom-popup-container');
    const plusIconElement = element?.getBoundingClientRect();
    if (element2) {
      document.body.appendChild(element2);
      element2.style.left = `calc(${plusIconElement?.left}px - 210px)`;
      element2.style.top = `calc(${plusIconElement?.top}px - 20px + ${window.scrollY}px)`;
      element2.style.position = `absolute`;
    }

    setIsOpenCustomModal(true);
    setIsHiddenContainer(false);
  }

  const openUpdateCustomFieldModal = (field: string) => {
    dispatch(resetUserFieldAction())
    history.push(`${props.rootLink}/fields/${field}`);
  }

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  }

  const closeCustomModal = () => {
    setIsOpenCustomModal(false);
    setIsHiddenContainer(true);
    setIsOpenUpdateCustomFieldModal(false);
  }

  const openUpdateUserFormModal = (id: string) => {
    history.push(`${props.rootLink}/${id}`)
    dispatch(resetMessageResponseAction({}));
  }

  const confirmDelete = () => {
    dispatch(deleteUserAction({ id: userIdForDeletion, company: companyId }));
  }

  const filterUsers = () => {
    if (filterTimeout) clearTimeout(filterTimeout);
    let filterData = formatFilterValues(dynamicRefs, 'User');
    setShowClearFilter(true);
    setFilterData(filterData)
    setCurrentOffset(0);
    filterTimeout = setTimeout(() => {
      dispatch(filterUsersAction({ company: companyId, filterData: { filters: filterData.filters }, search: filterData.globalSearch }));
    }, 800);
  }

  const clearFilters = () => {
    Object.keys(dynamicRefs.current).forEach((object, keys) => {
      if (dynamicRefs.current[object]) {
        dynamicRefs.current[object].value = '';
      }
    });
    setShowClearFilter(false);
    setFilterData({} as IFilterData);
    dispatch(filterUsersAction({ company: companyId }));
  }

  useGetUserData({ companyId, dispatch });
  useSetUserTableHeight({ height: filterRect.height });
  useRemoveCustomPopup();

  useEffect(() => {
    setUserList(user.userList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userList]);

  useEffect(() => {
    if (user.deleteUserMessage === message.deleteUserSuccess) {
      setIsOpenDeleteModal(false)
    }
  }, [user.deleteUserMessage]);

  return (<>
    {(user.userFilterLoading || user.userListLoading) &&
      <Dimmer active inverted >
        <Loader active content='Loading' />
      </Dimmer >
    }
    <ActionMessage messages={userListActionMessage} />
    {!user.createAndAddNew &&
      <ActionMessage messages={userListActionMessageAddNew} />
    }
    <Segment className='borderless user-list-container'>
      <UserFilter.Render
        filterUsers={filterUsers}
        clearFilters={clearFilters}
        showClearFilter={showClearFilter}
        dynamicRefs={dynamicRefs}
        searchableDynamicFields={searchableDynamicFields}
        rootLink={props.rootLink}
        gridColumns={filterGridColumns()}
        filterRef={filterRef}
        allowedAccessAuditLogs={props.allowedAccessAuditLogs ? props.allowedAccessAuditLogs : false}
        isOpenChatbox={isOpenAdminChatbox}
        fields={[...user.userFieldListStatic, ...user.userFieldListDynamic]}
        profileFilter={user.filterFields}
      />
      <UserTableList.Render
        staticField={user.userFieldListStatic}
        dynamicField={user.userFieldListDynamic}
        user={userList}
        openDeleteuserConfirmation={openDeleteModal}
        openCustomField={openCustomFieldModal}
        openUpdateUserFormModal={openUpdateUserFormModal}
        openUpdateCustomFieldModal={openUpdateCustomFieldModal}
        companyId={companyId}
        filterData={filterData}
        setIsOpenFileUploadModal={setIsOpenFileUploadModal}
        hasFileUpload={validateUserProfile.allowedUserCreate(companyId)}
        allowedCreateCustomField={validateUserProfile.allowedCreateUserField(companyId)}
        userTypeOption={userTypeOption}
      />
      <PaginationComponent
        totalCount={user.userRecordTotalCount}
        currentLimit={userListRecordLimit}
        currentOffset={currentOffset}
        changePage={changePage}
      />
      <Suspense fallback={<></>}>
        <Route exact path={`${match.url}/:userId`}>
          <UserFormModal
            openDeleteModal={openDeleteModal}
            companyId={companyId}
            rootLink={props.rootLink}
            userFieldListStatic={user.userFieldListStatic}
            userFieldListDynamic={user.userFieldListDynamic}
            currentUserId={userLoggedIn.user.id || ''}
            currentUserProfile={currentUserProfile}
          />
        </Route>
        <ConfirmationMessage
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeDeleteModal}
          open={isOpenDeleteModal}
          confirm={confirmDelete}
          cancel={closeDeleteModal}
          headerText="Confirmation"
          detailText={`The user '${userNameForDeletion}' will be deleted. Do you still want to proceed?`}
          type='warning'
        />
        <div className='user-custom-field'>
          <div hidden={isHiddenContainer} className='hidden-container' onClick={closeCustomModal} />
          <FieldType
            open={isOpenCustomModal}
            close={closeCustomModal}
            editCustomField={isOpenUpdateCustomFieldModal}
            openCustomFieldModal={openCustomFieldModal}
            companyId={companyId}
          />
        </div>
        <FileUploadModal
          cancel={() => setIsOpenFileUploadModal(false)}
          close={() => setIsOpenFileUploadModal(false)}
          open={isOpenFileUploadModal}
          template={{
            templateName: `User Upload`,
            templateURL: `/${companyId}/users/batch?import=xlsx&getTemplate=true`
          }}
          onUpload={(success: boolean, response: any) => {
            if (success) {
              dispatch(filterUsersAction({ company: companyId }));
              dispatch({ type: UPLOAD_USERLIST_SUCCESS_MESSAGE })
            } else {
              dispatch({ type: UPLOAD_USERLIST_ERROR_MESSAGE, message: JSON.stringify(response) })
            }
          }}
          urlGet={`${companyId}/users?limit=${userListRecordLimit}&offset=${0}`}
        />
      </Suspense>
    </Segment>
  </>)
}

export default UserComponent;
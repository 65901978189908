import React from 'react';
import TableListDetail from './table-list-detail';
import AddRole from './add-role';
import { IFormStatus, IFormStatusWithAction } from '../../workflow/interface/form-status';
import { IFormRole } from '../interface/form-role';
import { IOption } from '../../../../../../../common/interface/option';
import { IConstraint } from '../interface/constraint';
import { Group } from '../../../../groups/interface/group';

interface ITableList {
  statusList: IFormStatus[];
  currentRoleList: IFormRole[];
  optionList: IOption[];
  addRoleList(value: any): void;
  setRoleAction(status: IFormStatusWithAction, role: IFormRole): void;
  deleteRole(forDeleteRole: IFormRole): void;
  constraintList: IConstraint[];
  groupList: Group[];
}

const tableList: React.FC<ITableList> = (props) => {
  return <>
    <tbody>
      <TableListDetail
        statusList={props.statusList}
        currentRoleList={props.currentRoleList}
        setRoleAction={props.setRoleAction}
        deleteRole={props.deleteRole}
        constraintList={props.constraintList}
        groupList={props.groupList}
      />
      <AddRole
        statusList={props.statusList}
        optionList={props.optionList}
        addRoleList={props.addRoleList}
      />
    </tbody>
  </>
}

export default tableList;
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import NoIdRedirect from '../../no-id-redirect';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { useSetThirdLevelRoute } from '../../../../hooks/use-set-third-level-route';
import AutomationRedirect from './automation-redirect';
import AutomationRoute from './automation-route';
import ToolTip from '../../../../../../common/general/tooltip';
import changeRoute from '../../../../../../common/utils/change-menu';
import { icons } from '../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';

interface IAutomationProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Automation {

  name = 'automation';

  render: React.FC<IAutomationProps> = (props) => {

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <ToolTip
          popupMessage='Close'
          popupTriggerComponent={
            <Image id='business-process-close-icon' src={icons.blue.closeCircle} onClick={() => {
              changeRoute('/admin/business-process/form', props.hasPageChangeRef.current.hasChange, { dispatch, history })
            }} />
          }
          position='right center'
        />
        <Route exact path={`${match.path}`}>
          <NoIdRedirect />
        </Route>
        <AutomationRoute hasPageChangeRef={props.hasPageChangeRef} />
        <Route path={`${match.path}/:formId`} exact>
          <AutomationRedirect />
        </Route>
      </>
    );
  }
}

export default Automation;
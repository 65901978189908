
import React, { useEffect, useState } from 'react';
import { IField } from '../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../field/component/field-element';

interface IDefaultValue {
  fieldData: any;
  configData: any;
  inputObject: any;
  defaultvalueRef: any;
  maxLengthRef?: React.MutableRefObject<HTMLInputElement>;
  minLengthRef?: React.MutableRefObject<HTMLInputElement>;
  commaSeparated?: any;
  decimalRef?: React.MutableRefObject<HTMLInputElement>;
  optionValue?: any;
  isMultiselect?: any;
  refreshValue: number;
  showDefaultValue: boolean;
}

const DefaultValue: React.FC<IDefaultValue> = (props) => {

  const [refresher, setRefresher] = useState(0);

  let validationSchema: any = props.fieldData ? { type: props.fieldData.type || 'string' } : { type: 'string' };
  if (props.fieldData && props.fieldData.type === 'string') {
    validationSchema.minLength = props.minLengthRef && props.minLengthRef.current ? parseFloat(props.minLengthRef.current.value || '0') : 0;
    if (props.maxLengthRef && props.maxLengthRef.current && props.maxLengthRef.current.value) {
      validationSchema.maxLength = parseFloat(props.maxLengthRef.current.value || '0');
    }
    if (props.configData && props.configData.format && props.configData.format !== 'none' && props.configData.format !== 'phone') {
      validationSchema.format = props.configData.format;
    }
    if (props.configData.format === 'regexp' || props.configData.format === 'allowedChars') {
      validationSchema.format = undefined;
      validationSchema.pattern = props.configData.pattern;
      if (props.configData.format === 'allowedChars') {
        const uppercase = props.configData.allowedChars.indexOf('uppercase') > -1 ? "A-Z" : '';
        const lowercase = props.configData.allowedChars.indexOf('lowercase') > -1 ? "a-z" : '';
        const whitespace = props.configData.allowedChars.indexOf('whitespace') > -1 ? "\\s" : '';
        const digits = props.configData.allowedChars.indexOf('digits') > -1 ? "0-9" : '';
        const special = props.configData.allowedChars.indexOf('special') > -1 ? "~`!@#$%^&*()-_+={}[]|\\/:;\"'<>,.?" : '';
        const custom = props.configData.allowedChars.indexOf('custom') > -1 ? props.configData.customAllowedChars : '';
        validationSchema.pattern = `^[${uppercase}${lowercase}${whitespace}${digits}${special}${custom}]*$`
      }
    }
  }
  if (props.fieldData && props.fieldData.type === 'number') {
    if (props.minLengthRef && props.minLengthRef.current && props.minLengthRef.current.value) {
      validationSchema.minimum = props.minLengthRef.current ? parseFloat(props.minLengthRef.current.value || '0') : 0;
    }
    if (props.maxLengthRef && props.maxLengthRef.current && props.maxLengthRef.current.value) {
      validationSchema.maximum = parseFloat(props.maxLengthRef.current.value || '0');
    }
  }
  if (props.fieldData && props.fieldData.type === 'geolocation') {
    validationSchema = {
      anyOf: [
        {
          properties: { lon: { type: "number", minimum: -180, maximum: 180 }, lat: { type: "number", minimum: -90, maximum: 90 } },
          required: ["lon", "lat"],
          type: "object"
        },
        { type: "null" }
      ]
    }
  }

  let tempField: IField = {
    id: 'defaultField',
    inputConfig: {
      type: props.fieldData.type,
      config: props.inputObject(),
    },
    label: 'Default Value',
    validationSchema
  };

  const field = FieldElement.getDataElement(tempField);
  const Element = field.getFieldElement();

  useEffect(() => {
    setRefresher(refresher + 1)
    // eslint-disable-next-line
  }, [props.refreshValue]);

  return <div id='custom-default-value'
    key={`element-default-value-1`} className={`six wide field ${props.showDefaultValue ? '' : 'hidden'}`}>
    <Element {...field}
      showErrorMessage
      forwardedRef={props.defaultvalueRef}
      defaultValueElement={true}
    />
  </div>;
}

export default DefaultValue;
/*
 *
 * test reducer
 *
 */

// import { fromJS } from 'immutable'
import {
  AUTH_LOGIN,
  AUTH_LOGIN_RETURN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
  AUTH_LOGOUT_RETURN,
  AUTH_LOGOUT_MESSAGE_RETURN,
  SCREEN_INACTIVITY_MODAL,
  FORGOT_PASSWORD_MODAL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_RETURN,
  FORGOT_PASSWORD_RETURN_ERROR,
  AUTH_LOGOUT_MESSAGE_RESET,
  UPDATE_USER_DETAIL
} from './constant';

import {
  CHECK_AUTH_TOKEN_RETURN,
  CHECK_AUTH_TOKEN
} from './token-checker/constant';
import messages from './message';
import { AuthorisedUser } from './authorize-user';
import { plainToClass } from 'class-transformer';
import { ICompany } from '../bpm-manager-module/module/companies/interface/response/company';
import { IField } from '../admin-module/module/users/interface/field';

export interface IAuthReducer {
  token: string;
  company: string;
  loading: boolean;
  loginStatus: string;
  logoutStatus: string;
  initializeApp: boolean;
  loginError: string;
  forgotPasswordModal: boolean;
  forgotPasswordLoading: boolean;
  forgotPasswordStatus: string;
  forgotPasswordMessage: string;
  forgotPasswordError: boolean;
  forgotPasswordFormStatus: string;
  automaticLogoutModal: boolean;
  userLoggedIn: AuthorisedUser,
  logoutMessageModal: boolean;
  userCompanyDetails: ICompany;
  userField: IField[];
}

const initialState: IAuthReducer = {
  token: '',
  company: '',
  loading: false,
  loginStatus: '',
  logoutStatus: '',
  initializeApp: false,
  loginError: '',
  forgotPasswordModal: false,
  forgotPasswordLoading: false,
  forgotPasswordStatus: '',
  forgotPasswordMessage: '',
  forgotPasswordError: false,
  forgotPasswordFormStatus: messages.forgotPasswordForm,
  automaticLogoutModal: false,
  userLoggedIn: plainToClass(AuthorisedUser, {}),
  logoutMessageModal: false,
  userCompanyDetails: {} as ICompany,
  userField: [] as IField[],
};

const authReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case AUTH_LOGIN:
      newState.loading = true;
      newState.loginStatus = '';
      newState.loginError = '';
      return newState;

    case AUTH_LOGIN_RETURN:
      newState.token = action.token;
      newState.loginStatus = action.status;
      newState.loading = false;
      return newState;

    case AUTH_LOGIN_ERROR:
      newState.loginError = action.error;
      newState.loading = false;
      return newState;

    case CHECK_AUTH_TOKEN:
      newState.initializeApp = true;
      return newState;

    case CHECK_AUTH_TOKEN_RETURN:
      newState.token = action.token;
      newState.userLoggedIn = plainToClass(AuthorisedUser, action.userDetail);
      newState.company = action.userDetail.belongCompanyId;
      newState.initializeApp = false;
      newState.userCompanyDetails = action.userCompany;
      newState.userField = action.userField;
      return newState;

    case AUTH_LOGOUT:
      newState.logoutStatus = messages.logout;
      return newState;

    case AUTH_LOGOUT_RETURN:
      newState.logoutStatus = messages.logoutSuccess;
      newState.token = '';
      newState.logoutMessageModal = action.logoutMessageModal || false
      newState.userLoggedIn = plainToClass(AuthorisedUser, {});
      return newState;

    case AUTH_LOGOUT_MESSAGE_RETURN:
      newState.logoutStatus = messages.logoutSuccess;
      newState.token = '';
      newState.logoutMessageModal = action.logoutMessageModal || false
      return newState;

    case AUTH_LOGOUT_MESSAGE_RESET:
      newState.logoutMessageModal = false
      return newState;

    case FORGOT_PASSWORD:
      newState.forgotPasswordLoading = action.forgotPasswordLoading
      return newState;

    case FORGOT_PASSWORD_MODAL:
      newState.forgotPasswordModal = action.forgotPasswordModal || false;
      newState.forgotPasswordError = action.forgotPasswordError || false;
      newState.forgotPasswordStatus = action.forgotPasswordStatus || '';
      newState.forgotPasswordMessage = action.forgotPasswordMessage || '';
      return newState;

    case FORGOT_PASSWORD_RETURN:
      newState.forgotPasswordLoading = false;
      newState.forgotPasswordError = false;
      newState.forgotPasswordStatus = action.status;
      newState.forgotPasswordMessage = messages.forgotPasswordSuccess;
      newState.forgotPasswordFormStatus = messages.forgotPasswordFormSuccess;
      return newState;

    case FORGOT_PASSWORD_RETURN_ERROR:
      newState.forgotPasswordLoading = false;
      newState.forgotPasswordError = true;
      newState.forgotPasswordStatus = action.status;
      newState.forgotPasswordMessage = messages.forgotPasswordError;
      newState.forgotPasswordFormStatus = messages.forgotPasswordFormError;
      return newState;

    case SCREEN_INACTIVITY_MODAL:
      newState.automaticLogoutModal = action.automaticLogoutModal;
      return newState;


    case UPDATE_USER_DETAIL:
      console.log('action.userDetail: ', action.userDetail);
      newState.userLoggedIn = plainToClass(AuthorisedUser, action.userDetail);
      return newState;

    default:
      return state;
  }
};

export default authReducer;

import React from 'react';
import { ISubMenuList } from '../../../../admin-module/module/business-process/module/configuration/sub-menu/interface/sub-menu-list';
import { ISubMenuListProp } from '../../../../admin-module/module/business-process/module/configuration/sub-menu/interface/sub-menu-list-props';
import { FormView } from './buttons/form-view';
import { ListView } from './buttons/list-view';
import { Permissions } from './buttons/permission';
import { StakeholdersView } from './buttons/stakeholders-view';
import { PortalBPSubMenuList } from './list';

const SubMenuButtonArrayList = [
  new StakeholdersView(),
  new FormView(),
  new Permissions(),
  new ListView()
]

export class PortalBpSubMenuButton {
  registry: { [name: string]: ISubMenuList } = {};

  constructor(types?: ISubMenuList[]) {

    if (!types) {
      types = SubMenuButtonArrayList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getButton: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const { Render } = this.registry[props.type] ? this.registry[props.type] : new PortalBPSubMenuList();
    return <Render {...props} ></Render>;
  }

  static Render: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const headerButton = new PortalBpSubMenuButton();
    const Render = headerButton.getButton;
    return <Render {...props} />
  }

}
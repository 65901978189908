import { useEffect } from 'react';
import message from '../../../../message';
import { clearBusinessProcessMessageAction } from '../../action';

interface IProps {
  dispatch: any;
  history: any;
  selectedBusinessProcessId: string;
  createFormSuccessMessage: string;
  updateFormSuccessMessage: string;
  formId: string;
}

export const useClearAndRedirectFormToList = (props: IProps): void => {
  const { createFormSuccessMessage, updateFormSuccessMessage, history, selectedBusinessProcessId, dispatch, formId } = { ...props };
  useEffect(() => {
    if (createFormSuccessMessage === message.createFormSuccess && selectedBusinessProcessId) {
      dispatch(clearBusinessProcessMessageAction('createFormSuccessMessage'))
      history.push(`/admin/business-process/form-builder/${selectedBusinessProcessId}`)
    }

    if (updateFormSuccessMessage === message.updateFormSuccess && selectedBusinessProcessId && formId !== 'add') {
      history.push(`/admin/business-process/form`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFormSuccessMessage, selectedBusinessProcessId]);
}
import React from 'react';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject } from '../../../../../../common/custom-field-config/interface/custom-field-detail';

import CustomFieldForm from '../../../../../../common/custom-field-config';
import { IDataTable } from '../../../data-tables/reducer';
import { IForm } from '../form/interface/form';
import { useSetCustomFieldData } from './hooks/use-set-custom-field-data';

interface IDatatableCustomField {
  isCustomFieldFormOpen: boolean;
  closeCustomField(): void;
  field: ICustomFieldDetail;
  updateDetailList(data: ICustomFieldDetail, fieldId: string): void;
  publishedForm: IForm[];
  datatable: IDataTable[];
  formDetail: IForm | IDataTable;
  forceNoMultiline?: boolean;
  currentFieldList?: ICustomFieldDetail[];
  companyId: string;
  tableId?: string;
  tableFieldList?: ICustomFieldDetail[];
  userFieldList?: ICustomFieldDetail[];
}

const FormBuilderCustomField: React.FC<IDatatableCustomField> = (props) => {

  const {
    fieldData,
    configData,
    isViewOnly
  } = useSetCustomFieldData({ field: props.field });

  const onSubmit = (dataObject: IOnCustomFieldSubmitObject, fieldId: string) => {
    props.updateDetailList(dataObject.data, fieldId);
  }

  return <>
    <CustomFieldForm
      open={props.isCustomFieldFormOpen}
      close={props.closeCustomField}
      defaultConfig={configData}
      defaultData={fieldData}
      openDeleteModal={() => { }}
      loading={false}
      submit={onSubmit}
      cancel={props.closeCustomField}
      fieldId={fieldData.id || ''}
      formType='FormBuilder'
      publishedForm={props.publishedForm}
      datatable={props.datatable}
      formDetail={props.formDetail}
      forceNoMultiline={props.forceNoMultiline}
      currentFieldList={props.currentFieldList}
      companyId={props.companyId}
      tableId={props.tableId}
      isViewOnly={isViewOnly}
      tableFieldList={props.tableFieldList}
      userFieldList={props.userFieldList}
    />
  </>
}

export default FormBuilderCustomField;
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';

interface BackupResponse {
  data: any;
  status: number;
}

export async function backupMake(company: string, query: string): Promise<BackupResponse> {
  try {
    const config: AxiosRequestConfig = {
      headers: { Accept: "application/json" },
      responseType: 'arraybuffer'
    }
    const url = `${company}/backup/make?${query}`;
    const response: AxiosResponse = await Axios.post(url, {}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function backupRestore(company: string, query: string, file: any): Promise<BackupResponse> {
  try {
    const config: AxiosRequestConfig = {
      headers: { Accept: "application/json", 'Content-Type': 'application/octet-stream' },
    }
    const url = `${company}/backup/restore?${query}`;
    const response = await Axios.post(url, file, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    console.log("error", error.response);
    throw new Error(error.response.data.message);
  }
}

export async function backupDownload(data: Blob) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `backup_${moment().format('MM-DD-YYYY-hmmss')}.zip`);
  document.body.appendChild(link);
  link.click();
}


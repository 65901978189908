export enum ThemeColorType {
  color = 'color',
  text = 'text',
  file = 'file',
}

export interface IThemeColorStateData {
  id: string;
  color?: string;
  parent?: string;
  text?: string;
  type?: ThemeColorType;
};

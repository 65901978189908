// import { fromJS } from 'immutable'

import { 
  SET_AUTHENTICATION_CREDENTIAL_ERROR,
  CLEAR_AUTHENTICATION_CREDENTIAL_ERROR
 } from './constant';

export interface IAuthSettingsReducer {
  credentialError: string;
  isCredentialError: boolean;
}

const initialState: IAuthSettingsReducer = {
  credentialError: '',
  isCredentialError: false
}

const authSettingsReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch(action.type) {
    case SET_AUTHENTICATION_CREDENTIAL_ERROR:
      newState.credentialError = action.message;
      newState.isCredentialError = action.isCredentialError;
      return newState;

    case CLEAR_AUTHENTICATION_CREDENTIAL_ERROR:
      newState.credentialError = '';
      return newState;

    default:
      return state;
  }
}

export default authSettingsReducer;
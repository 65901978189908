import Sample from './component/sample/saga';
import Auth from './component/auth/saga';
import ResetPassword from './component/auth/reset-password/saga'
import User from './component/admin-module/module/users/saga'
import CustomField from './common/custom-field-config/saga'
import DataTable from './component/admin-module/module/data-tables/saga';
import BusinessProcess from './component/admin-module/module/business-process/module/form/saga';
import FormBuilder from './component/admin-module/module/business-process/module/form-builder/saga';
import Workflow from './component/admin-module/module/business-process/module/workflow/saga';
import Roles from './component/admin-module/module/business-process/module/roles/saga';
import Groups from './component/admin-module/module/groups/saga';
import Apps from './component/admin-module/module/apps/saga';
import Permission from './component/admin-module/module/business-process/module/permission/saga';
import CustomFilter from './component/admin-module/module/business-process/module/configuration/module/custom-filter/saga';
import Slas from './component/admin-module/module/business-process/module/configuration/module/slas/saga';
import APIs from './component/admin-module/module/business-process/module/configuration/module/apis/saga';
import DocumentList from './component/admin-module/module/business-process/module/configuration/module/document-list/saga';
import EndUser from './component/document-module/saga';
import DocumentForm from './component/document-module/module/form/saga';
import DuplicateChecker from './component/admin-module/module/business-process/module/configuration/module/duplicate-checker/saga';
import Report from './component/document-module/module/report/saga';
import LateralMenu from './component/document-module/side-menu/saga';
import Companies from './component/bpm-manager-module/module/companies/saga';
import FilterConfiguration from './common/custom-field-config/filter-configuration/saga';
import Workspace from './component/document-module/module/workspace/saga';
import EnduserDatatable from './component/document-module/module/datatable/saga';
import Calendar from './component/document-module/module/calendar/saga';
import UserAccount from './component/document-module/module/user-account/saga';
import TasksSagas from './component/document-module/module/task/saga';
import { fork, all } from 'redux-saga/effects';
import notificationSagas from './component/document-module/module/notification/saga';
import draftSagas from './component/document-module/module/draft/saga';
import BusinessProcessRelationship from './component/admin-module/module/business-process/module/configuration/module/bp-relationship/saga';
import LanguageRegion from './component/admin-module/module/settings/module/language-and-region/saga';
import Labels from './component/admin-module/module/business-process/module/configuration/module/labels/saga';
import TaskView from './component/admin-module/module/business-process/module/configuration/module/task-view/saga';
import PDFTemplate from './component/admin-module/module/business-process/module/configuration/module/pdf-template/saga';
import automationSagas from './component/admin-module/module/business-process/module/automation/module/automation-rules/saga';
import automationLogsSagas from './component/admin-module/module/business-process/module/automation/module/automation-logs/saga';
import AdminHistoryLogs from './component/admin-module/history/saga';
import PortalListView from './component/portal-module/module/business-process/module/list-view/saga';
import PortalFormView from './component/portal-module/module/business-process/module/form-view/saga';
import CompanySettingsSagas from './component/admin-module/module/settings/saga';
import UserSettingsSagas from './component/document-module/module/setting/saga';
import DisplayData from './component/admin-module/module/business-process/module/configuration/module/display-data/saga';

export default function* rootSaga() {
  yield all([
    fork(Sample),
    fork(Auth),
    fork(ResetPassword),
    fork(User),
    fork(CustomField),
    fork(DataTable),
    fork(BusinessProcess),
    fork(FormBuilder),
    fork(Workflow),
    fork(Groups),
    fork(Apps),
    fork(Roles),
    fork(Permission),
    fork(CustomFilter),
    fork(Slas),
    fork(DocumentList),
    fork(EndUser),
    fork(DocumentForm),
    fork(DuplicateChecker),
    fork(Report),
    fork(LateralMenu),
    fork(Companies),
    fork(FilterConfiguration),
    fork(Workspace),
    fork(EnduserDatatable),
    fork(Calendar),
    fork(UserAccount),
    fork(TasksSagas),
    fork(APIs),
    fork(notificationSagas),
    fork(automationSagas),
    fork(draftSagas),
    fork(BusinessProcessRelationship),
    fork(LanguageRegion),
    fork(Labels),
    fork(TaskView),
    fork(PDFTemplate),
    fork(automationLogsSagas),
    fork(AdminHistoryLogs),
    fork(PortalListView),
    fork(PortalFormView),
    fork(CompanySettingsSagas),
    fork(UserSettingsSagas),
    fork(DisplayData)
  ]);
}
import prettyBytes from 'pretty-bytes';
import React, { useEffect } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { icons } from '../icons';
import SVGwrapper from '../icons/svg/svg-wrapper';

interface Props {
  setFiles: any;
  files: any;
  accept?: string;
  multiple?: boolean;
}

const DropzoneComponent: React.FC<Props> = (props) => {
  const { acceptedFiles, getInputProps } = useDropzone();

  const iconForFile = (file: any) => {
    if (file.type.startsWith('image')) {
      return URL.createObjectURL(file);
    } else {
      return icons.black.paperclip;
    }
  }

  const removeUploadFile = (file: any) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    props.setFiles({});
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      props.setFiles(acceptedFiles[0]);
    }
    // eslint-disable-next-line
  }, [acceptedFiles[0]])

  return <>
    <div className="dropzone">
      <div className="content">
        <Dropzone
          multiple={props.multiple}
          accept={props.accept}
          noClick
          onDrop={acceptedFiles => {
            if (acceptedFiles.length > 0) {
              props.setFiles(acceptedFiles[0])
            }
          }}
        >
          {({ getRootProps }) => (
            <section className='dropzone-main-container'>
              <div className='dropzone-container' {...getRootProps()}>
                {Object.keys(props.files).length > 0 ?
                  <span className='file-selected'>
                    <img className='file-icon' src={iconForFile(props.files)} alt="" />
                    <label className='file-name'>{props.files.name}</label>
                    <p className='file-size'> ({prettyBytes(props.files.size)})</p>
                    <i className={`icon close`} onClick={() => { removeUploadFile(props.files) }} />
                  </span>
                  :
                  <div className='file-icon-upload'> <SVGwrapper iconName='uploadCloud' width='75' height='75' color='#000' /> </div>
                }
                <span className='file-upload-label'>Drag and drop your files anywhere or</span>
                <input id='file-upload' {...getInputProps()} accept={props.accept} />
                <label htmlFor='file-upload'>
                  <div className='dropzone-file-upload-btn'>Upload a file</div>
                </label>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  </>
}

export default DropzoneComponent;
import { call, put, takeLatest } from 'redux-saga/effects';
import { postRequest } from '../../../../common/api-request';
import { setStatusCodeAction } from '../../../error/action';
import { IFormDocument } from '../report/interface/form-document';
import { getDraftListReturnAction } from './action';
import { draftListLimit, GET_DRAFT_LIST } from './constant';
import { IDraftGetInput } from './interface/input/draft-get';

export function* getDraftlist(args: IDraftGetInput) {
  try {
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }
    let sort = '';
    if (args.sort) {
      sort = `&sort=${args.sort}`
    }
    let load = '&load=form'

    let company = args.company;
    let filterData = args.filterData;
    const filter: { data: { data: IFormDocument[], pagination: { total: number } } } = yield call(postRequest, `${company}/documents/search?true${load}${sort}&limit=${draftListLimit}${offset}`, filterData);
    yield put(getDraftListReturnAction({ draftList: filter.data.data, draftRecordTotalCount: filter.data.pagination.total }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* draftSagas() {
  yield takeLatest(GET_DRAFT_LIST, getDraftlist);

}

// All sagas to be loaded
export default draftSagas;
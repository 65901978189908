import { IConfigName } from './interface/field-type-object';

interface IItemObj {
  id: string;
  value: string;
  label?: string;
}

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Dropdown,
  required: false,
  type: 'enum',
};

interface IDefaultConfigObject {
  dataType: string;
  data: IItemObj[],
  multiselect: boolean;
  displayType: string;
}

const defaultConfig: IDefaultConfigObject = {
  data: [],
  multiselect: false,
  displayType: 'dropdown',
  dataType: 'static'
};

const value = {
  title: 'Dropdown',
  value: 'enum',
  icon: {
    black: require('../../icons/black/drop-down-list.png'),
    white: require('../../icons/white/drop-down-list.png'),
    blue: require('../../icons/blue/drop-down-list.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  fieldTypeStyle: {
    width: '23px',
    height: '23px',
    right: '11px',
    top: '5px',
  },
  defaultData,
  defaultConfig
}

export default value;
import { DeltaModuleType } from '../..';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IDataTable } from '../../../../../../module/data-tables/reducer';
import { AdminHistoryCategory, AdminHistoryType, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils } from '../../../../manage-history';

export class DatatablePermissionsDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.DataTablePermissions;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let dataTableList = this.deletedDTInstance(utils) as IDataTable[];

    if (utils.dataTableList) {
      dataTableList = dataTableList.concat(utils.dataTableList);
    }

    return {
      delta: [],
      text: this.formatText(historyLogs, dataTableList, historyLogs.type)
    };
  }

  lowercase = (text: string) => {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
  }

  deletedDTInstance(utils: IAdminHistoryUtils) {
    return utils.history && utils.history
      .filter(e => e.type === AdminHistoryType.Delete
        && e.category === AdminHistoryCategory.DataTable)
      .map(e => e.context.targetInstance)
  }

  formatText(historyLogs: IAdminHistoryLogs, dataTableList: IDataTable[], type: AdminHistoryType) {
    const { targetInstance } = { ...historyLogs.context };

    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');
    const belongDataTable = dataTableList.find(e => e.id === targetInstance.clientDbId);
    let text = historyLogs.text;

    if (belongDataTable) {
      text = `${commentSender} ${this.lowercase(historyLogs.text)} under DataTable: (name: ${belongDataTable.name})`;
    }

    return text;
  }

}
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'semantic-ui-react-bpm';
import { currentRouteAction, setSubRouteAction } from '../../../main/action';
import PortalUsers from './users';

class Users {

  name = 'users';

  render: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        setSubRouteAction({
          mainRoute: 'PORTAL',
          subRoute: this.name
        })
      );
      dispatch(currentRouteAction(this.name));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Container className='portal-module-container' fluid>
        <PortalUsers />
      </Container>
    )
  }

}

export default Users;
import React from 'react';
import { ConfirmationMessage } from './confirmation-modal';

interface IAlertModal {
  type?: 'warning' | 'error' | 'info'
  close(): void;
  message: string;
  open: boolean;
  confirm?(): void;
  color?: "grey" | "red" | "orange" | "yellow" | "olive" | "green" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "black" | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube";
}

export const AlertModal: React.FC<IAlertModal> = (props) => {
  return <ConfirmationMessage
    headerText=''
    cancelButtonColor={'grey'}
    close={() => props.close()}
    cancelButtonName={'Close'}
    open={props.open}
    confirm={() => { props.confirm && props.confirm() }}
    cancel={() => props.close()}
    detailText={props.message}
    type={props.type}
  />
}
import { SUB_ROUTE } from './constant';

export interface IConfigurationReducer {
  mainRoute: string;
  subRoute: string;
}

const initialState = {
  mainRoute: '',
  subRoute: '',
};

const configurationReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case SUB_ROUTE:
      newState.mainRoute = action.mainRoute;
      newState.subRoute = action.subRoute;
      return newState;

    default:
      return state;
  }
}

export default configurationReducer
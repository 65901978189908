import React, { useState } from 'react';
import Field from './field';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import FieldSelection from './field-selection';
import { v4 as uuid } from 'uuid';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { Icon, Popup } from 'semantic-ui-react-bpm';

interface IColumnFieldProps {
  selectedField: (ICustomFieldDetail | null)[];
  tableType: 'horizontal' | 'vertical';
  addNewField(): void;
  selectField(configName: IConfigName, index: number): void;
  deleteField(index: number, seq: number | string): void;
  changeFieldSequence(field: ICustomFieldDetail | null, dragIndex: number, dropIndex: number): void;
  editTableColumnField(field: ICustomFieldDetail, index: number): void;
  publishForm: IForm[];
  datatable: IDataTable[];
  selectReferenceField(data: string, index: number): void;
  hasSubtotal: boolean;
  updateColumnFieldaggregate(key: string, fieldid: string, value: any): void;
  setpreFillColumnFields(value: ICustomFieldDetail[]): void;
  preFillColumnFields: ICustomFieldDetail[];
  hasDefaultValue: boolean;
  uniqueColumnIds: string[];
  setUniqueColumnIds(value: string[]): void;
  error: { columns: boolean; errorIndexes?: number[] }
  setError: (value: { columns: boolean; errorIndexes?: number[] }) => void
  formDetail: IForm | IDataTable;
  hasColStyle: boolean;
}

const ColumnField: React.FC<IColumnFieldProps> = (props) => {
  const hasReferenceField = props.selectedField.filter(e => e && e.configName === IConfigName.Referenced).length > 0;

  const [uniquePopup, setUniquePopup] = useState(false);

  const triggerUniqueColumnIds = (colId: string) => {
    let tempColumnIds = [...props.uniqueColumnIds];
    if (tempColumnIds.includes(colId)) {
      tempColumnIds = tempColumnIds.filter(e => e !== colId);
    } else {
      tempColumnIds.push(colId);
    }
    props.setUniqueColumnIds(tempColumnIds);
  }

  const resetError = () => {
    const error = { ...props.error };
    error.columns = false;
    props.setError(error);
  }

  return <div className={`table-column-field`}>
    <div className={`table-type`}>
      <span className={`title ${props.error.columns ? 'error' : ''}`}>Columns</span>
      <div className='table-unique-columns'>
        <span>Unique Columns</span>
        <Popup
          on='click'
          id='enum-config-popup'
          basic
          position='bottom right'
          open={uniquePopup}
          onClose={() => setUniquePopup(false)}
          trigger={
            <Icon name={uniquePopup ? 'chevron up' : 'chevron down'} onClick={() => setUniquePopup(!uniquePopup)} />
          }>
          {props.selectedField.map((field: ICustomFieldDetail | null, index: number) => {
            if (!field) return null;

            const isUnique = props.uniqueColumnIds.includes(field.id as string);

            return <div key={`unique-${field.id}`} className='enum-config-item'>
              <span className='title'>{field.label}</span>
              <div className="ui fitted checkbox" onClick={() => triggerUniqueColumnIds(field.id as string)}>
                <input className="hidden" type="checkbox" checked={isUnique} onChange={() => { }} />
                <label></label>
              </div>
            </div>
          })}
        </Popup>
      </div>
    </div>
    {
      props.selectedField.map((field: ICustomFieldDetail | null, index: number) => {
        const isError = props.error.errorIndexes !== undefined && props.error.errorIndexes.includes(index);
        return field
          ? <Field
            key={uuid()}
            field={field}
            fieldIndex={index}
            deleteField={props.deleteField}
            changeFieldSequence={props.changeFieldSequence}
            editTableColumnField={props.editTableColumnField}
            publishForm={props.publishForm}
            datatable={props.datatable}
            selectReferenceField={props.selectReferenceField}
            hasSubtotal={props.hasSubtotal}
            updateColumnFieldaggregate={props.updateColumnFieldaggregate}
            hasReferenceField={hasReferenceField}
            setpreFillColumnFields={props.setpreFillColumnFields}
            preFillColumnFields={props.preFillColumnFields}
            hasDefaultValue={props.hasDefaultValue}
            hasColStyle={props.hasColStyle}
          />
          : <FieldSelection
            key={uuid()}
            fieldIndex={index}
            selectField={props.selectField}
            deleteField={props.deleteField}
            changeFieldSequence={props.changeFieldSequence}
            publishForm={props.publishForm}
            datatable={props.datatable}
            selectReferenceField={props.selectReferenceField}
            hasReferenceField={hasReferenceField}
            hasDefaultValue={props.hasDefaultValue}
            isError={isError}
            formDetail={props.formDetail}
          />;
      })
    }
    <div className={`table-add-field ${props.hasDefaultValue && hasReferenceField ? 'with-checkbox' : ''}`}>
      <i className={`plus circular icon`} onClick={() => {
        resetError();
        props.addNewField();
      }} style={{ backgroundColor: '#3C85A8', color: '#ffffff' }} />
    </div>
  </div>
}

export default ColumnField;
import { ArrayFieldProperties } from '../service/array-field-properties.service';
import { FieldProperties } from '../service/field-properties.service';

interface IElementStatusProps {
  name: string;
  multiline: boolean;
  forwardedRef: any;
  required?: boolean;
  fieldValueObject: FieldProperties<any> | ArrayFieldProperties<any>;
}

export const elementStatus = (args: IElementStatusProps, count?: number) => {
  const { multiline, required } = { ...args }
  let hasValues = false;
  let hasError = false;

  if (multiline && count !== undefined) {
    hasValues = args.fieldValueObject.getCurrent(count) ? true : false;
    hasError = args.fieldValueObject.hasError(count);
  } else {
    hasValues = args.fieldValueObject.current ? true : false;
    hasError = args.fieldValueObject.hasError();
  }

  if (required && !hasValues) {
    hasError = true;
  }

  return hasValues && !hasError ? 'valid' : hasError ? 'error' : '';
}


import { IValidationError } from '../../validation/type/validation-type';
import { FieldElement } from '../field-element';
import { GetTypeInComponent, TValidationType, PropType } from './field-properties.handler';

export class FieldProperties<Type extends PropType> {

  constructor(
    private fieldId: string,
    private forwardRef: any,
    public valueType: Type,
  ) { }

  get current(): GetTypeInComponent<Type> {
    this.setInitialDataIfNotExist();
    const fieldValue = this.forwardRef.current[this.fieldId];
    if (this.valueType.processValueFromRef) {
      return this.valueType.processValueFromRef(fieldValue);
    } else {
      return fieldValue;
    }
  }

  getCurrent(): GetTypeInComponent<Type> {
    return this.current;
  }

  setCurrent(value: GetTypeInComponent<Type>) {
    const currentValue = { ...this.current, ...value };
    let valueToSet = currentValue;
    if (this.valueType.processValueToRef) {
      valueToSet = this.valueType.processValueToRef(currentValue);
    }
    this.setInitialDataIfNotExist();
    this.forwardRef.current[this.fieldId] = valueToSet;
  }

  validate(field: FieldElement, validationType: TValidationType) {
    if (this.valueType.processValidation) {
      const value = this.getCurrent();
      this.forwardRef.current[this.fieldId].error = this.valueType.processValidation(value, field, validationType);
    }
  }

  hasValue(): boolean {
    const value: any = this.current.value;
    return value !== null
      && value !== undefined
      && value !== ''
      && !(value instanceof Array && value.length === 0)
      && !(typeof value === 'object' && (value?.lat === 0 && value?.lon === 0));
  }

  hasError(): boolean {
    this.setInitialDataIfNotExist();
    return this.forwardRef.current[this.fieldId].error && this.forwardRef.current[this.fieldId].error.length > 0;
  }

  getError(): IValidationError[] {
    this.setInitialDataIfNotExist();
    return this.forwardRef.current[this.fieldId].error || [];
  }

  setError(value: any[]) {
    this.setInitialDataIfNotExist();
    this.forwardRef.current[this.fieldId].error = value;
  }

  isSet(): boolean {
    return this.forwardRef.current[this.fieldId] ? true : false
  }

  private setInitialDataIfNotExist(): void {
    if (!this.forwardRef.current[this.fieldId]) {
      this.forwardRef.current[this.fieldId] = { value: null, error: [] };
    }
  }
}
import { IAutomationPlaceholder } from '../../../../../../interface/automation';

interface IUtils {
  automationPlaceholders: IAutomationPlaceholder[];
  text: string;
}

export class ParseEmailBody {

  static extractPlaceholderFromHTML = (text: string, regExp: RegExp): string[] => {
    const data: string[] = [];
    const match = text.matchAll(regExp);
    for (const m of Array.from(match)) {
      data.push(m[1].replace('&gt;', '>'));
    }
    return data;
  }

  static replacePlaceholderText = (text: string, substitutions: Record<string, string>, regExp: RegExp) => {
    return text.replace(regExp, (substring: string, match: string) => {
      if (match in substitutions) {
        return substitutions[match].toString()
      } else {
        return substring;
      }
    })
  }

  static fromHTMLString = (utils: IUtils) => {
    const regExp = /\{\{([^{}]+)\}\}/g; // (/\[(.*?)\]/g); regex for brackets
    const placeholders = ParseEmailBody.extractPlaceholderFromHTML(utils.text, regExp);
    if (placeholders.length === 0) return utils.text;
    const allowedPlaceholders = utils.automationPlaceholders;
    const placeholdersMapping = allowedPlaceholders.reduce((data: any, p) => {
      data[p.uiPlaceholder] = p.placeholder
      return data
    }, {});
    const placeholdersValues = new Set(Object.values(placeholdersMapping))
    const substitutions = placeholders.reduce((data: any, p) => {
      if (placeholdersValues.has(p)) {
        data[p] = `{{${p}}}`
      } else if (!(p in placeholdersMapping)) {
        data[p] = p
      } else {
        data[p] = `{{${placeholdersMapping[p]}}}`
      }
      return data
    }, {});
    return ParseEmailBody.replacePlaceholderText(utils.text, substitutions, regExp);
  }

  static toHTMLString = (utils: IUtils) => {
    const regExp = /\{\{([^{}]+)\}\}/g;
    const placeholders = ParseEmailBody.extractPlaceholderFromHTML(utils.text, regExp);
    if (placeholders.length === 0) return utils.text;
    const allowedPlaceholders = utils.automationPlaceholders;
    const placeholdersMapping = allowedPlaceholders.reduce((data: any, p) => {
      data[p.placeholder] = p.uiPlaceholder
      return data
    }, {});
    const placeholdersValues = new Set(Object.values(placeholdersMapping));
    const substitutions = placeholders.reduce((data: any, p) => {
      if (placeholdersValues.has(p)) {
        data[p] = `{{${p}}}`
      } else if (!(p in placeholdersMapping)) {
        data[p] = p
      } else {
        data[p] = `{{${placeholdersMapping[p]}}}`
      }
      return data
    }, {});
    return ParseEmailBody.replacePlaceholderText(utils.text, substitutions, regExp);
  }
}
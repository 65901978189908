export const message = {
  userListError: 'Error Retriving user list',
  userFieldListError: 'Error Retriving user field list',
  deleteUserSuccess: 'User successfully deleted',
  createUserSuccess: 'User successfully created',
  updateUserSuccess: 'User successfully updated',
  updateUserStatusSuccess: 'Status successfully updated',
  createUserFieldSuccess: 'User field successfully added',
  updateUserFieldSuccess: 'User field successfully updated',
  deleteUserFieldSuccess: 'User field successfully deleted',
  resetUserPassSuccess: 'Password successfully changed',
  uploadUserListSuccess: 'Uploading is successful',
  uploadUserListError: 'Uploading failed',
}

export const uploadProfileErrorMessage: {[type: string]: string}  = {
  'End User': 'Only Users Manager can upload End Users.',
  'Admin': 'Only Admin can upload Admin.',
  'BPM Manager': 'Only Admin can upload BPM Manager.',
  'BA': 'Only Admin can upload BA.',
  'Users Manager': 'Only Admin can upload Users Manager.',
}
import React, { useState } from 'react';
import { Icon, DropdownProps, Dropdown } from 'semantic-ui-react-bpm';
import { IFormStatus } from '../../workflow/interface/form-status';
import { IOption } from '../../../../../../../common/interface/option';
import ToolTip from '../../../../../../../common/general/tooltip';
import DropdownPopperOption from '../../../../../../../common/general/dropdown-popper-option';

interface IAddRoleProps {
  statusList: IFormStatus[];
  optionList: IOption[];
  addRoleList(value: any): void;
}

const AddRole: React.FC<IAddRoleProps> = (props) => {
  const [isOpenAddRole, setIsOpenAddRole] = useState(false);

  const addRole = (value: any) => {
    props.addRoleList(value);
    setIsOpenAddRole(false);
  }
  return <>
    {isOpenAddRole &&
      <tr>
        <td>
          <div className={`six wide field`}>
            <Dropdown
              popperContainer={DropdownPopperOption}
              search
              clearable
              selectOnBlur={false}
              onChange={(e, target: DropdownProps) => addRole(target.value)}
              placeholder='Select User/Group'
              options={props.optionList}
              selection />
          </div>
        </td>
        {
          props.statusList.map((status: IFormStatus) => {
            return <td key={`no-detail-td-${status.id}`}></td>;
          })
        }
      </tr>

    }
    {!isOpenAddRole &&
      <tr>
        <td>
          <ToolTip position='bottom center'
            popupMessage='Add'
            popupTriggerComponent={
              <Icon circular name='plus' onClick={() => setIsOpenAddRole(true)}></Icon>
            }
          />
        </td>
      </tr>
    }
  </>
}

export default AddRole;
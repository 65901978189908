import React from 'react';
import StatusSectionContainer from './container';
import { IFormPermissionSection, ISection, ITablePermission } from '../../interface/section';
import { IPermissionFieldFilterConfig } from '../../interface/field-permission';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IStatusSectionFields {
  showStatusOption: boolean;
  selectedStatus: IFormPermissionSection;
  formPermissionSection: IFormPermissionSection[];
  selectedStatusIndex: number;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  handleSectionPermissionChange(forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any): void;
  handleReferToStatus(forUpdateSection: IFormPermissionSection, statusId: string): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  openPermissionFilterConfig: boolean;
  openSectionPermissionSettings(section: ISection): void;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const StatusSectionFields: React.FC<IStatusSectionFields> = (props) => {
  return (
    <div className='status-field-container'>
      <StatusSectionContainer
        showStatusOption={props.showStatusOption}
        selectedStatus={props.selectedStatus}
        handlePermissionChange={props.handlePermissionChange}
        handleSectionPermissionChange={props.handleSectionPermissionChange}
        formPermissionSection={props.formPermissionSection}
        selectedStatusIndex={props.selectedStatusIndex}
        handleReferToStatus={props.handleReferToStatus}
        openFieldPermissionSettings={props.openFieldPermissionSettings}
        openPermissionFilterConfig={props.openPermissionFilterConfig}
        openSectionPermissionSettings={props.openSectionPermissionSettings}
        wrapperRef={props.wrapperRef}
      />
    </div>
  )
}

export default StatusSectionFields;
import React from 'react';

interface IPortalUserStatus {
  userStatus: boolean;
}

const PortalUserStatus: React.FC<IPortalUserStatus> = (props) => {
  return (
    <div className="ui fitted toggle checkbox checked checkbox-toggle">
      <input
        type="checkbox" onChange={() => { }}
        checked={props.userStatus} />
      <label></label>
    </div>
  );
}

export default PortalUserStatus;
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getStatusListReturnAction,
  getStatusListErrorAction,
  saveSlasReturnAction,
  saveSlasErrorAction,
  publishSlasReturnAction,
  publishSlasErrorAction
} from './action';
import { getRequest, putRequest, postRequest, IAxiosResponse } from '../../../../../../../../common/api-request';
import { setStatusCodeAction } from '../../../../../../../error/action';
import {
  GET_FORM_STATUS_LIST,
  SAVE_SLAS,
  PUBLISH_SLAS
} from './constant';
import { ISlaSaveInput, ISlaPublishInput } from './interface/input/slas-save';
import { SanitizeSlas } from '../../../../../../../../common/utils/sanitize-slas';
import { ISlaStatusesInput } from './interface/input/slaStatuses';

export function* getStatusList(args: ISlaStatusesInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/slaAndStatuses?draft=true`, {});
    const sla: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/sla?draft=true`, {});
    const statuses: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true&load=actions`, {});
    const reference: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/references?draft=true`, {});

    yield put(getStatusListReturnAction({
      slaStatusList: SanitizeSlas.sanitizeIncomingSla(req.data.data, sla.data.data),
      statusList: statuses.data.data,
      referenceList: reference.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getStatusListErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* saveSlas(args: ISlaSaveInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    yield call(putRequest, `${companyId}/forms/${formId}/sla`, SanitizeSlas.outgoingSlas(args.taskStatusList, formId));
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/slaAndStatuses?draft=true`, {});
    const sla: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/sla?draft=true`, {});

    yield put(saveSlasReturnAction({
      slasStatusList: SanitizeSlas.sanitizeIncomingSla(req.data.data, sla.data.data),
      saveRoleType: args.saveType
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e));
    yield put(saveSlasErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* publishSlas(args: ISlaPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishSlasReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishSlasErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* slasSagas() {
  yield takeLatest(GET_FORM_STATUS_LIST, getStatusList);
  yield takeLatest(PUBLISH_SLAS, publishSlas);
  yield takeLatest(SAVE_SLAS, saveSlas);
}

// All sagas to be loaded
export default slasSagas;
import React, { useState } from 'react';
import { IFormula } from '../../interface/formula';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';

interface ISelectedFormula {
  formula: IFormula;
  index: number;
  updateFormulaInput(currentValue: string, currentIndex: number): void;
  displayFieldList: ICustomFieldDetail[];
}

const SelectedFormula: React.FC<ISelectedFormula> = (props) => {
  const [formulaValue, setFormulaValue] = useState(props.formula.value);

  const field = props.formula.type === 'field' ? props.displayFieldList.find(e => e.id === props.formula.value) : null;

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'formula', data: props.index },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
        cursor: 'grab'
      }
    },
  });

  return <div ref={dragRef} style={draggedObject} className={`${props.formula.type} ${props.formula.agg ? 'aggregate' : ''}`}>
    {props.formula.type === 'value' ?
      <input type='number' value={formulaValue}
        onChange={(e) => { setFormulaValue(e.target.value) }}
        onBlur={(e) => {
          props.updateFormulaInput(e.target.value, props.index)
        }}
      />
      : <span>{field ? field.label : props.formula.label}</span>
    }
  </div>
}

export default SelectedFormula;
import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react-bpm';
import { IFieldTypeObj } from '../../../../../common/field/type/interface/field-type-object';

interface IFieldType {
  fieldType: IFieldTypeObj[];
  openCustomFieldForm(field: any): void;
  open: boolean;
}

const FieldType: React.FC<IFieldType> = (props) => {

  return <>
    <Segment.Group id='custom-popup-container' className={`${props.open ? '' : 'hidden'} custom-field`} >
      <Segment className='borderless sticky'>
        <Header as='h4' >Create Custom Field</Header>
      </Segment>
      {
        props.fieldType.map(field => {
          return <div key={`field-type-${field.title}`} className='field-type'
            onClick={() => props.openCustomFieldForm(field)}>
            <div className='field-type-image'>
              <Image src={field.icon.blue} style={field.style} />
            </div>
            <div className='field-type-title'>
              <span>{field.title}</span>
            </div>
          </div>
        })
      }
    </Segment.Group>
  </>
}

export default FieldType;
import React from 'react';

interface IMessage {
  error?: boolean;
  open: boolean;
  close(): void;
  message: string;
  noCloseIcon?: boolean;
}

const Message: React.FC<IMessage> = (props) => {

  return <>
    <div
      className={`ui ${props.error ? 'negative' : 'success'} message ${props.open ? '' : 'transition hidden'} message-light`}
    >
      {!props.noCloseIcon &&
        <i className="close icon" onClick={() => props.close()}></i>
      }
      < div className="header" >{props.message}</div>
    </div>
  </>
};

export default React.memo(Message);
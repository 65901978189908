import { IConfigName } from './interface/field-type-object';

interface ISeqFieldConfigObject {
  companyId?: string;
  seqId?: string;
}

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Number,
  required: false,
  type: 'number',
};

interface IDefaultConfigObject {
  alignment?: string;
  thousandSeparator?: boolean;
  decimals?: number;
  max?: number;
  min?: number;
  seq?: ISeqFieldConfigObject;
}
const defaultConfig: IDefaultConfigObject = {
  alignment: 'right',
  thousandSeparator: false,
};

const value = {
  title: 'Number',
  value: 'number',
  icon: {
    black: require('../../icons/black/123-black.png'),
    white: require('../../icons/white/123-white.png'),
    blue: require('../../icons/blue/123-blue.png'),
  },
  style: {
    height: '13px',
    top: '4px',
    left: '1px'
  },
  fieldTypeStyle: {
    height: '14px',
    top: '11px',
    width: '25px',
    right: '25px'
  },
  defaultData,
  defaultConfig
}

export default value;
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IFieldPermissionSettings } from '../..';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';
import { IPermissionFieldCondition, IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import { IRulesPermission } from '../../../interface/rules';
import ConditionConfig from '../default/condition';
import ConditionConnector, { TConditionConnectorType } from '../utils/condition-connector';
import { StructureSettingsConfig } from '../utils/structure-settings-config';
import ActionAutomationConfig from './config';
import { hideValueAndFieldOption } from '../../../../../../../../../common/utils/field-operator';
import { useDispatch } from 'react-redux';
import { saveFormPermissionErrorAction } from '../../../action';

export type TConfigOption = 'permission' | 'value' | 'clear';
export type TConfigType = 'onTrue' | 'onFalse';
export type TRulesPermission = keyof IRulesPermission;

class ActionConfiguration {

  name = 'actions'

  render: React.FC<any> = (props: IFieldPermissionSettings) => {
    const dispatch = useDispatch();

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [refresher, setRefresher] = useState(0);

    const actionLabel = props.selectedStatusAction.name || 'Untitled Action';
    const permissionConfigRef = useRef<IPermissionFieldFilterConfig | undefined>(undefined);

    const removeConfig = () => {
      props.updatePermissionConfig(undefined);
      props.cancel();
    }

    const showDeleteBtn = (): boolean => {
      let show = false;
      if (props.selectedStatusAction && props.selectedStatusAction.permissions) {
        show = !!props.selectedStatusAction.permissions.config;
      }
      return show;
    }

    const cancelConfig = () => {
      props.cancel();
    }

    const isConfigValid = () => {
      const data: any = { ...permissionConfigRef.current };
      if (data) {
        const error: boolean[] = data.condition && data.condition.map((condition: IPermissionFieldCondition) => {
          const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(condition.operator);
          const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(condition.operator);
          let hasError = false;
          if (!(hideValueOption && hideFieldOption)) {
            if (condition.compareType === 'value') {
              hasError = !condition.compareValue;
            } else {
              hasError = !condition.compareField.fieldId;
            }
          }
          return !condition.fieldId || !condition.operator || hasError;
        });
        return error.every(e => !e);
      }
      return true;
    }

    const saveConfig = () => {
      if (!isConfigValid()) {
        dispatch(saveFormPermissionErrorAction('Configuration is not complete. Field, Operator, and Compare Field/Value cannot be empty.'));
        return;
      }
      const data = { ...permissionConfigRef.current };
      props.updatePermissionConfig(data);
      props.cancel();
    }

    const updateJoinCondition = (value: TConditionConnectorType) => {
      if (permissionConfigRef.current) {
        permissionConfigRef.current.joinCondition = value;
      }
      setRefresher(refresher + 1);
    }

    useEffect(() => {
      const config = new StructureSettingsConfig();
      const { join, falseConfig, trueConfig, disabledMessage } = config.setActionConfig(
        props.selectedStatus,
        props.selectedStatusAction
      );
      permissionConfigRef.current = {
        joinCondition: join,
        condition: [],
        conditionRule: {
          onFalse: falseConfig,
          onTrue: trueConfig,
          disabledMessage
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedStatus, props.open, props.selectedStatusAction]);

    const joinCondition: TConditionConnectorType = permissionConfigRef.current ? permissionConfigRef.current.joinCondition : 'and';

    return <>
      <div className={`frontend-automation-container`}>
        <label className='field-label'>{actionLabel.toUpperCase()}</label>
        <div className='permission-settings-main'>
          <ConditionConnector
            type={joinCondition}
            setConditionConnector={updateJoinCondition}
          />
          <div className='permission-settings-container'>
            <div className='permission-settings'>
              <ConditionConfig {...props}
                permissionConfigRef={permissionConfigRef}
              />
              <ActionAutomationConfig
                {...props}
                permissionConfigRef={permissionConfigRef}
              />
            </div>
          </div>
          <div className="button-container">
            <Button className='btn-success' onClick={saveConfig}>Save</Button>
            {showDeleteBtn() &&
              <Button color='red' className='btn-action' onClick={() => setShowDeleteConfirm(true)}>Delete</Button>
            }
            <Button className='btn-default' onClick={cancelConfig}>Cancel</Button>
          </div>
        </div>
      </div>
      <ConfirmationMessage
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => setShowDeleteConfirm(false)}
        open={showDeleteConfirm}
        confirm={removeConfig}
        cancel={() => setShowDeleteConfirm(false)}
        headerText="Confirmation"
        detailText={`Dynamic permission for this action will be deleted . Do you still want to proceed?`}
        type='warning'
      />
    </>
  }

}

export default ActionConfiguration;
import React from 'react';
import { v4 as uuid } from 'uuid';
import { IComment } from '../../../../interface/comment';
import CommentWidgetAvatar from '../../utils/avatar';
import CommentWidgetBody from '../../utils/body';
import CommentWidgetSenderInfo from '../../utils/sender-info';
import ChatAttachmentMessageWidget from './widget';

interface IChatAttachmentMessage {
  comment: IComment;
  lastReadMessage: IComment;
  errorImage: string[];
  userColor: { [id: string]: string };
  addToErrorImage(imageId: string): void;
  deleteCommentAttachment(attachmentId: string, commentId: string): void;
}

const ChatAttachmentMessage: React.FC<IChatAttachmentMessage> = ({
  comment,
  lastReadMessage,
  errorImage,
  userColor,
  addToErrorImage,
  deleteCommentAttachment
}) => {

  return <div id={comment.id} key={uuid()} className={`chat-message-widget`}>
    {lastReadMessage && lastReadMessage.id === comment.id &&
      <span className='chat-unread-mark'>New</span>
    }
    <CommentWidgetAvatar
      addToErrorImage={addToErrorImage}
      comment={comment}
      errorImage={errorImage}
      userColor={userColor}
    />
    <div className={`message-content-container`}>
      <CommentWidgetSenderInfo comment={comment} />
      <div className={`message-content`}>
        <CommentWidgetBody body={comment} attachment={true} />
        <ChatAttachmentMessageWidget
          attachments={comment.attachments}
          comment={comment}
          deleteCommentAttachment={deleteCommentAttachment}
        />
      </div>
    </div>
  </div>
}

export default ChatAttachmentMessage
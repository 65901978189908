import React, { useState, useEffect } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../common/field/type/interface/field-type-object';
import { SanitizeField } from '../../../../../../../../common/utils/sanitize-field';
import { tableMobileViewList } from '../../constant';
import { IFormBuilderSection } from '../../interface/section';
import { ITableMobileViewContainer } from '../../interface/table-view';
import { ISectionUpdateConfig } from '../../section-field-manager/interface/section-update-config';
import { SanitizeSectionList } from '../../utils/sanitize-section-list';
import MobileSection from './section';
import ButtonContainer from '../../../../button-container';
import { useDispatch } from 'react-redux';
import { saveFormBuilderConfigurationErrorAction } from '../../action';

interface IMobileSectionContainer {
  currentFieldList: ICustomFieldDetail[];
  updateDetailList(
    data: ICustomFieldDetail,
    fieldId: string,
    manualUpdateConfig?: ISectionUpdateConfig
  ): void;
  sectionList: IFormBuilderSection[];
  setUpdateConfig(value: ISectionUpdateConfig): void;
  tableWidgetConfig: { [id: string]: ITableWidgetConfig };
  setTableWidgetConfig(value: { [id: string]: ITableWidgetConfig }): void;
  saveFormBuilder(saveType?: string): void;
  cancelFormBuilder(): void;
}

export interface ITableWidgetConfig {
  tableMobileView: ITableMobileViewContainer;
  extraFields: string[];
  enabled?: boolean;
  fieldName?: string;
}

const MobileSectionContainer: React.FC<IMobileSectionContainer> = (props) => {
  const dispatch = useDispatch();
  const defaultConf: ITableWidgetConfig = {
    tableMobileView: tableMobileViewList,
    extraFields: [''],
    enabled: false,
    fieldName: '',
  };

  const [currentTableFields, setCurrentTableFields] = useState<
    ICustomFieldDetail[]
  >([]);
  const [collapse, setCollapse] = useState<{ [fieldId: string]: boolean }>({});

  const updateConfig = (fieldId: string, value: ITableWidgetConfig) => {
    const tempTableMobileView = { ...props.tableWidgetConfig };
    const oldValue = tempTableMobileView[fieldId];
    tempTableMobileView[fieldId] = {
      ...value,
      fieldName: oldValue.fieldName,
    };
    props.setTableWidgetConfig(tempTableMobileView);
  };

  const isTableMobileViewEmpty = (
    tableMobileView: ITableMobileViewContainer
  ) => {
    const noValue = (v: string | undefined) => {
      return v === '' || v === undefined || !v;
    }
    const keys = Object.keys(tableMobileView);
    const empty = keys.map(key => {
      let empty = false;
      const { upperField } = tableMobileView[key];
      if (noValue(upperField)) {
        empty = true;
      } else {
        empty = false;
      }
      return empty;
    }).every(e => e === true);
    return empty;
  };

  const resetConfig = (field: ICustomFieldDetail) => {
    const fieldId = field.id as string;
    const tempCollapse = { ...collapse };
    tempCollapse[fieldId] = true;
    setCollapse(tempCollapse);
    props.updateDetailList({ ...field, mobileConfig: null }, fieldId, {
      updateType: 'UpdateField',
      sectionId: field.sectionId || '',
    });
  };

  const triggerCollapse = (value: boolean, fieldId: string) => {
    const tempCollapse = { ...collapse };
    tempCollapse[fieldId] = value;
    setCollapse(tempCollapse);
  };

  const saveMobileConfig = (saveType?: string) => {
    if (isValidMobileConfig()) {
      props.saveFormBuilder(saveType);
    }
  };

  const isValidMobileConfig = () => {
    let valid = true;

    const errorField: string[] = [];
    const tempTableMobileView = { ...props.tableWidgetConfig };
    const keys = Object.keys(tempTableMobileView);

    for (const key of keys) {
      const { fieldName, tableMobileView, enabled } = {
        ...tempTableMobileView[key],
      };

      const isTableViewEmpty = isTableMobileViewEmpty(tableMobileView);

      if (isTableViewEmpty && enabled) {
        errorField.push(fieldName || '');
        break;
      }
    }

    if (errorField.length > 0) {
      dispatch(
        saveFormBuilderConfigurationErrorAction(
          `You must configure at least 1 field for BIG slot component for field/s: ${errorField
            .join(',')
            .toString()}`
        )
      );
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    const tempTableWidgetConfig: { [id: string]: ITableWidgetConfig } = {};
    const tempCollapse: { [id: string]: boolean } = { ...collapse };

    const fieldList = SanitizeSectionList.getFieldListFromSections(
      props.sectionList,
      true
    );
    const tableFields = fieldList.filter(
      (e) => e.configName === IConfigName.Table
    );

    tableFields.forEach((fields) => {
      let widgetConfig: ITableWidgetConfig = {
        ...defaultConf,
        fieldName: fields.label,
      };
      if (fields.mobileConfig) {
        const config = SanitizeField.structureMobileConfigToWidgetConfig(
          fields.mobileConfig
        );
        widgetConfig = {
          ...config,
          fieldName: fields.label,
          extraFields: !config.extraFields.length
            ? defaultConf.extraFields
            : config.extraFields,
        };
      }
      tempTableWidgetConfig[fields.id || ''] = widgetConfig;
      if (!Object.keys(tempCollapse).includes(fields.id as string)) {
        tempCollapse[fields.id as string] = true;
      }
    });

    setCollapse(tempCollapse);
    setCurrentTableFields(tableFields);
    props.setTableWidgetConfig(tempTableWidgetConfig);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sectionList]);

  if (!currentTableFields.length) {
    return null;
  }

  return (
    <div className="form-builder-mobile-container">
      <div className="form-builder-mobile">
        {currentTableFields.map((field) => {
          const config: any = { ...field.config };
          return (
            <MobileSection
              key={field.id}
              field={field}
              columns={config.columns || []}
              updateDetailList={props.updateDetailList}
              setUpdateConfig={props.setUpdateConfig}
              tableWidgetConfig={props.tableWidgetConfig}
              updateConfig={updateConfig}
              resetConfig={resetConfig}
              collapse={collapse[field.id || '']}
              triggerCollapse={triggerCollapse}
            />
          );
        })}
      </div>
      <ButtonContainer
        save={() => saveMobileConfig('draft')}
        publish={() => saveMobileConfig('publish')}
        cancel={props.cancelFormBuilder}
      />
    </div>

  );
};

export default MobileSectionContainer;

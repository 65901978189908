import React from 'react';
import DuplicateChecker from './duplicate-checker';
import SLAs from './slas';
import APIs from './apis';
import DocumentsList from './document-list';
import CustomFilter from './custom-filter';
import BpRelationship from './bp-relationship';
import SQSPlugin from './sqs-plugin';
import Labels from './labels';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';
import TaskView from './task-view';
import PDFTemplate from './pdf-template';
import GanttChart from './gantt-chart';
import DisplayData from './display-data';

const ModuleList = [
  new DuplicateChecker(),
  new SLAs(),
  new DocumentsList(),
  new CustomFilter(),
  new BpRelationship(),
  new SQSPlugin(),
  new APIs(),
  new Labels(),
  new DisplayData(),
  new TaskView(),
  new PDFTemplate(),
  new GanttChart()
]

interface IRender {
  type: string;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

interface IConfigurationModuleRegistry {
  name: string;
  render: React.FC<any>
}

export class ConfigurationModuleRegistry {

  registry: { [name: string]: IConfigurationModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const adminModule = new ConfigurationModuleRegistry();
    const Render = adminModule.getModule(props.type);
    return <Render {...props} />
  }

}
import React from 'react';
import { accessLevelToValue, valueToAccessLevel } from '../../../../constant';
import { ISection, ITablePermission } from '../../../../interface/section';

interface ISectionSlider {
  handleSectionPermissionChange(forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any): void;
  section: ISection;
}

const SectionSlider: React.FC<ISectionSlider> = (props) => {
  const accessLevel = props.section.accessLevelPerStatus as string;
  const value = accessLevelToValue[accessLevel];
  return <div id="slider-config-container">
    <input type="range" step='1' min="1" max="4" value={value || 3}
      onChange={(e) => {
        props.handleSectionPermissionChange(props.section, valueToAccessLevel[e.target.value], valueToAccessLevel[e.target.value]);
      }}
    />
    <ul>
      <li>Hidden</li>
      <li>Read-only</li>
      <li>Editable</li>
      <li>Required</li>
    </ul>
  </div>
}

export default SectionSlider;

import React from 'react';
import { Button } from 'semantic-ui-react-bpm';

interface IButtonProps {
  cancel(): void;
  save?(): void;
  publish(): void;
}

const ButtonContainer: React.FC<IButtonProps> = (props) => {
  return <div className="button-container">
    <Button className='btn-success' onClick={() => props.publish()}  >Publish</Button>
    {props.save &&
      <Button className='ui primary' onClick={() => props.save ? props.save() : {}}  >Save</Button>
    }
    <Button className='btn-default' onClick={() => props.cancel()} >Cancel</Button>
  </div>
}

export default ButtonContainer;

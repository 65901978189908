import { call, put, takeLatest } from 'redux-saga/effects';
import {
  automationLogsLimit,
  GET_AUTOMATION_LOGS_LIST,
} from './constant';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { getAutomationLogsListActionError, getAutomationLogsListReturnAction } from './action';
import { getRequest, IAxiosResponse } from '../../../../../../../../common/api-request';
import { IAutomationLogsInput } from './interface/input/automation-logs-get';

export function* getAutomationLogsList(args: IAutomationLogsInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/automationLogs?offset=${args.offset}&limit=${automationLogsLimit}`, {});
    yield put(getAutomationLogsListReturnAction({
      automationLogsList: req.data.data,
      automationLogsTotalCount: req.data.pagination.total
    }));
  } catch (e) {
    console.error(e)
    yield put(setStatusCodeAction(e));
    yield put(getAutomationLogsListActionError());
  }
}

export function* automationLogsSaga() {
  yield takeLatest(GET_AUTOMATION_LOGS_LIST, getAutomationLogsList);
}

export default automationLogsSaga;

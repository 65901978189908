import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { icons } from '../../../../../../../../common/icons';
import { IRootState } from '../../../../../../../../reducers';
import { IComment, ICommentAttachment } from '../../../../interface/comment';
import { ISenderDetail } from '../../../../interface/sender-info';
import { useSetParsedAttachments } from './hooks/use-set-parsed-attachments';
import { renderAttachmentDeleted } from './utils/render-attachment-deleted';
import { renderAttachmentNoPreview } from './utils/render-attachment-no-preview';
import { renderAttachmentWithPreview } from './utils/render-attachment-with-preview';

interface IChatAttachment {
  attachments: ICommentAttachment[];
  comment: IComment;
  deleteCommentAttachment(attachmentId: string, commentId: string): void;
}

const ChatAttachmentMessageWidget: React.FC<IChatAttachment> = ({ attachments, deleteCommentAttachment, comment }) => {
  const { user } = useSelector((state: IRootState) => state.auth.userLoggedIn);

  const [previewAttachment, setPreviewAttachment] = useState(true);
  const [parsedAttachments, setParsedAttachments] = useState([] as ICommentAttachment[]);

  const { sender } = { ...comment };
  const senderUserInfo = { ...(sender && sender.data) } as ISenderDetail;

  const dropdownIcon = previewAttachment ? 'triangle down' : 'triangle right';

  useSetParsedAttachments({
    attachments,
    setParsedAttachments
  });

  return (
    <div className={`attachment-container ${previewAttachment ? 'withPreview' : ''} 
       ${parsedAttachments.length > 0 && parsedAttachments[0].type === 'other' ? 'withoutPreview' : ''} `}>
      <div onClick={() => setPreviewAttachment(!previewAttachment)} className="attachment">
        <span className={'message-attach-filename'}>
          <span style={{ margin: '0px 2px' }}>
            {parsedAttachments.length}
          </span>{' '}
          {parsedAttachments.length > 1 ? 'files' : 'file'}
        </span>
        <Icon className="icon-expand" name={dropdownIcon} />
      </div>
      <div className={`${parsedAttachments.length > 1 ? 'multiple' : ''}`}>
        {parsedAttachments.length > 0 && previewAttachment && parsedAttachments.map((attachment: ICommentAttachment) => {
          return (
            <div key={`${uuid()}-${attachment.id}`} className={`attachment-preview`}>
              {!attachment.deleted ?
                <div className={`attachment-main ${attachment.type}`}>
                  {senderUserInfo && senderUserInfo.id === user.id &&
                    <Image id={attachment.id}
                      title={`delete ${attachment.name}`}
                      className={`attachment-remove`}
                      src={icons.other.remove}
                      onClick={() => deleteCommentAttachment(attachment.id, comment.id)}
                    />
                  }
                  <AttachmentWidget attachment={attachment} />
                </div>
                :
                <div className={`attachment-main deleted`}>
                  {renderAttachmentDeleted()}
                </div>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AttachmentWidget: React.FC<{ attachment: ICommentAttachment }> = ({ attachment }) => {

  const [isValidImage, setIsValidImage] = useState(false);

  useEffect(() => {
    let image = document.createElement('img');
    image.src = attachment.url;
    image.onload = () => setIsValidImage(true);
    image.onerror = () => () => setIsValidImage(false);
  }, [attachment]);

  if (isValidImage) {
    return renderAttachmentWithPreview(attachment);
  }

  if (!isValidImage) {
    return renderAttachmentNoPreview(attachment);
  }

  return null;
}

export default ChatAttachmentMessageWidget;
import { ITasksListInput, ITaskBulkActionInput, IIndividualUserListInput } from './interface/input/tasks-list';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest } from '../../../../common/api-request';
import { getTasksListReturnAction, getTasksListErrorAction, triggerBulkActionsErrorAction, triggerBulkActionsReturnAction, getTaskActionUserListReturnAction, getTaskActionUserListErrorAction, getBpFieldPermissionErrorAction, getBpFieldPermissionReturnAction, saveTaskDataErrorAction, getTaskCustomLayoutErrorAction, getTaskCustomLayoutReturnAction } from './action';
import { setStatusCodeAction } from '../../../error/action';
import { GET_TASKS_LIST, taskRecordLimit, TRIGGER_BULK_ACTION, UNDO_TASK_ACTION, GET_ACTION_USER_LIST, GET_BP_FIELD_PERMISSIONS, SAVE_TASK_DATA, GET_TASK_CUSTOM_LAYOUT, systemFieldIds } from './constant';
import { IBPFieldPermissionInput } from './interface/input/bp-field-permission';
import { ITaskSaveDataInput } from './interface/input/task-save';
import { ITaskCustomLayoutInput } from './interface/input/task-custom-layout';
import { ITaskResponse } from './interface/response/tasks-list';
import { SanitizeFormPermission } from '../../../../common/utils/sanitize-form-permission';

export function* getTasksList(args: ITasksListInput) {
  try {
    let company = args.company;
    let filterData = args.filterData;
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }
    let loadTaskLayout = args.includeLayoutParam ? '&load=tasksLayout' : '';
    const load = `load=owner&load=form&load=history&load=creator&load=updatedBy&load=statusName&load=dynamicAction&load=slaStatus&load=ownerType&load=accessType&load=fields${loadTaskLayout}&component=tasks`;
    const allTasks: ITaskResponse = yield call(postRequest, `${company}/documents/tasks?${load}&case=${args.case}&limit=${taskRecordLimit}${offset}&sort=updatedAt|desc`, filterData);

    yield put(getTasksListReturnAction({
      activeTasksList: allTasks.data.data,
      taskRecordTotalCount: allTasks.data.pagination.total,
      taskAggregates: allTasks.data.aggregates ? allTasks.data.aggregates : []
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(getTasksListErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* triggerTaskBulkActions(args: ITaskBulkActionInput) {
  try {
    const company = args.company;
    const actionId = args.action.id;
    const formId = args.formId;
    const documentQuery = args.documentId;
    const filterData = args.filterData;
    const data = args.data;

    let tasksUpdated: IAxiosResponse = { data: { data: [] } };
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }

    if (args.batch) {
      tasksUpdated = yield call(postRequest, `${company}/documents/actions/batch?formId=${formId}${documentQuery}&actionId=${actionId}`, data);
    } else {
      tasksUpdated = yield call(postRequest, `${company}/documents/actions?formId=${formId}${documentQuery}&actionId=${actionId}`, data);
    }
    let loadTaskLayout = args.includeLayoutParam ? '&load=tasksLayout' : '';
    const load = `load=owner&load=form&load=history&load=creator&load=updatedBy&load=statusName&load=dynamicAction&load=slaStatus&load=ownerType&load=accessType&load=fields${loadTaskLayout}`;
    const allTasks: ITaskResponse = yield call(postRequest, `${company}/documents/tasks?${load}&case=${args.case}&limit=${taskRecordLimit}${offset}&sort=updatedAt|desc`, filterData);

    if (tasksUpdated.data.success) {
      yield put(triggerBulkActionsReturnAction({
        activeTasksList: allTasks.data.data,
        taskRecordTotalCount: allTasks.data.pagination.total,
        taskAggregates: allTasks.data.aggregates ? allTasks.data.aggregates : []
      }));
    }

  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(error.response);
    yield put(triggerBulkActionsErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* undoTasks(args: ITaskBulkActionInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    let documentQuery = args.documentId;
    let actionId = args.action.id;
    let filterData = args.filterData;
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }
    let loadTaskLayout = args.includeLayoutParam ? '&load=tasksLayout' : '';
    const load = `load=owner&load=form&load=history&load=creator&load=updatedBy&load=statusName&load=dynamicAction&load=slaStatus&load=ownerType&load=accessType&load=fields${loadTaskLayout}`;
    let tasksUpdated: IAxiosResponse = yield call(postRequest, `${company}/documents/actions/${actionId}/undo?formId=${formId}${documentQuery}`, {});
    const allTasks: ITaskResponse = yield call(postRequest, `${company}/documents/tasks?${load}&case=${args.case}&limit=${taskRecordLimit}${offset}&sort=updatedAt|desc`, filterData);

    if (tasksUpdated.data.success) {
      yield put(triggerBulkActionsReturnAction({
        activeTasksList: allTasks.data.data,
        taskRecordTotalCount: allTasks.data.pagination.total,
        taskAggregates: allTasks.data.aggregates ? allTasks.data.aggregates : []
      }));
    }
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(triggerBulkActionsErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getTaskActionUserList(args: IIndividualUserListInput) {
  try {
    let company = args.companyId;
    let actionId = args.actionId;
    let formId = args.formId;
    const req: IAxiosResponse = yield call(postRequest, `${company}/documents/actions/${actionId}/inputFields?formId=${formId}`, {});

    yield put(getTaskActionUserListReturnAction({ individualUserList: req.data.data }))
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(getTaskActionUserListErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getBpFieldPermission(args: IBPFieldPermissionInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    const bizRules: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/businessRules?draft=false`, {});
    const ids = systemFieldIds.map(e => { return `&ids=${e}` }).join('').toString();
    let filterFields: IAxiosResponse = yield call(postRequest, `${company}/documents/filterFields?true${ids}`, {
      fields: { form: [`${formId}`] }
    });
    yield put(getBpFieldPermissionReturnAction({
      bpFieldPermission: SanitizeFormPermission.structureFormFieldPermission(bizRules.data.data),
      defaultFilterFields: filterFields.data.data
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(getBpFieldPermissionErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* saveTaskData(args: ITaskSaveDataInput) {
  try {
    let company = args.company;
    let documentId = args.documentId;
    let actionId = args.actionId;
    let formId = args.formId;
    const documentQuery = `&documentId=${documentId}`;
    yield call(postRequest, `${company}/documents/actions?formId=${formId}&actionId=${actionId}${documentQuery}`, args.data);
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(saveTaskDataErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getTaskCustomLayout(args: ITaskCustomLayoutInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    let req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/tasksLayout?draft=false`, {});
    let data = req.data.data.statusLayouts ? req.data.data.statusLayouts : [];
    yield put(getTaskCustomLayoutReturnAction({ taskLayout: data }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    console.log(e);
    yield put(getTaskCustomLayoutErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* tasksSagas() {
  yield takeLatest(GET_TASKS_LIST, getTasksList);
  yield takeLatest(GET_ACTION_USER_LIST, getTaskActionUserList);
  yield takeLatest(TRIGGER_BULK_ACTION, triggerTaskBulkActions);
  yield takeLatest(UNDO_TASK_ACTION, undoTasks);
  yield takeLatest(GET_BP_FIELD_PERMISSIONS, getBpFieldPermission);
  yield takeLatest(SAVE_TASK_DATA, saveTaskData);
  yield takeLatest(GET_TASK_CUSTOM_LAYOUT, getTaskCustomLayout);
}

// All sagas to be loaded
export default tasksSagas;
import React from 'react';
import { v4 as uuid } from 'uuid';
import { newComponentIcon } from '../../constant';
import { IOptionWidget } from '../../interface/option-widget';
import AutomationDetailOptionWidget from './widget/option-widget';

interface IAddComponentProps {
  addNewComponent(value: IOptionWidget): void;
}

const AddComponent: React.FC<IAddComponentProps> = (props) => {

  return (
    <div className='add-component'>
      <div className='add-component-header'>
        <span className='title'>Add Component</span>
        <span className='description'>Components can either restrict execution by testing a condition, perform an action.</span>
      </div>
      <div className='option-widget-selection'>
        {
          newComponentIcon.map((e: IOptionWidget) => {
            return <AutomationDetailOptionWidget
              key={uuid()}
              {...e}
              setSelected={() => props.addNewComponent(e)}
              selected={false}
            />
          })
        }
      </div>
    </div>
  );
}

export default AddComponent;
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { IField, IInputConfig } from '../../../admin-module/module/users/interface/field';
import StringType from '../../../../common/field/type/date';
import { IConfigName } from '../../../../common/field/type/interface/field-type-object';
import { IOption } from '../../../../common/interface/option';

export const GET_DOCUMENT_FORM_FIELDS = 'app/documentModule/report/GET_DOCUMENT_FORM_FIELDS';
export const GET_DOCUMENT_FORM_FIELDS_RETURN = 'app/documentModule/report/GET_DOCUMENT_FORM_FIELDS_RETURN';
export const GET_DOCUMENT_FORM_FIELDS_ERROR = 'app/documentModule/report/GET_DOCUMENT_FORM_FIELDS_ERROR';
export const RESET_FORM_DOCUMENT_LIST = 'app/documentModule/report/RESET_FORM_DOCUMENT_LIST';
export const FILTER_DOCUMENTS = 'app/documentModule/report/FILTER_DOCUMENTS';
export const FILTER_DOCUMENTS_RETURN = 'app/documentModule/report/FILTER_DOCUMENTS_RETURN';
export const FILTER_DOCUMENTS_ERROR = 'app/documentModule/report/FILTER_DOCUMENTS_ERROR';
export const GET_FORM_CUSTOM_FILTER = 'app/documentModule/report/GET_FORM_CUSTOM_FILTER';
export const GET_FORM_CUSTOM_FILTER_RETURN = 'app/documentModule/report/GET_FORM_CUSTOM_FILTER_RETURN';
export const GET_FORM_CUSTOM_FILTER_ERROR = 'app/documentModule/report/GET_FORM_CUSTOM_FILTER_ERROR';
export const CLEAR_FORM_DOCUMENTS_FIELD_ERROR = 'app/documentModule/report/CLEAR_FORM_DOCUMENTS_FIELD_ERROR';
export const CLEAR_FILTER_DOCUMENTS_ERROR = 'app/documentModule/report/CLEAR_FILTER_DOCUMENTS_ERROR';
export const REFRESH_DOCUMENTS_TABLE = 'app/documentModule/report/REFRESH_DOCUMENTS_TABLE';
export const SET_DOCUMENT_FILTER = 'app/document/report/SET_DOCUMENT_FILTER';
export const CHECK_CURRENT_USER_EDITING = 'app/document/report/CHECK_CURRENT_USER_EDITING';
export const SET_CURRENT_USER_EDITING = 'app/document/report/SET_CURRENT_USER_EDITING';
export const CANCEL_CURRENT_USER_EDITING = 'app/document/report/CANCEL_CURRENT_USER_EDITING';
export const GET_STATUS_FILTER_FIELD = 'app/document/report/GET_STATUS_FILTER_FIELD';
export const GET_STATUS_FILTER_FIELD_RETURN = 'app/document/report/GET_STATUS_FILTER_FIELD_RETURN';

export const systemFilterFields: IField[] = [
  { id: 'createdAt', configName: IConfigName.Date, label: 'Doc. Creation Date', inputConfig: { type: 'date' } as IInputConfig, validationSchema: { type: 'string', format: 'date' } },
  { id: 'updatedAt', configName: IConfigName.Date, label: 'Last Update Date', inputConfig: { type: 'date' } as IInputConfig, validationSchema: { type: 'string', format: 'date' } },
  { id: 'creator', configName: IConfigName.TextString, label: 'Doc. Creator', inputConfig: { type: 'string', config: { ...StringType.defaultConfig } } as IInputConfig },
  { id: 'updatedBy', configName: IConfigName.TextString, label: 'Last Updated By', inputConfig: { type: 'string', config: { ...StringType.defaultConfig } } as IInputConfig },
  { id: 'owner', configName: IConfigName.TextString, label: 'Owner', inputConfig: { type: 'string', config: { ...StringType.defaultConfig } } as IInputConfig },
]

export const reportsActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_FORM_DOCUMENTS_FIELD_ERROR,
    type: 'negative',
    reducerContainer: 'reportReducer',
    reducerItem: 'formDocumentsFieldError',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_FILTER_DOCUMENTS_ERROR,
    type: 'negative',
    reducerContainer: 'reportReducer',
    reducerItem: 'filterDocumentsError',
    hasCloseIcon: true
  }
]

export const reportListRecordLimit = 50;

export const reportsStandardColumn = [
  {
    id: 'formName',
    name: 'BusinessProcess'
  },
  {
    id: 'urn',
    name: 'URN #'
  },
  {
    id: 'description',
    name: 'Description'
  }
];

export const SLAOptions = [
  {
    key: 1,
    text: 'Excel',
    value: `ok`
  },
  {
    key: 2,
    text: 'SLA',
    value: `warn`
  },
  {
    key: 3,
    text: 'Late',
    value: `late`
  },
  {
    key: 4,
    text: 'None SLA',
    value: `off`
  }
] as IOption[]
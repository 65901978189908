import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../document-module/module/form/interface/sender-info';
import { IForm } from '../../../../../module/business-process/module/form/interface/form';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../interface/history';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

export class AppsDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.Apps;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (targetDelta) {
      const deltaKeys = Object.keys(targetDelta).filter(e => e !== 'updatedAt');

      deltaKeys.forEach(key => {
        if (key === 'forms') {
          newDelta[key] = this.setNewAppFormDelta(targetDelta[key])
          oldDelta[key] = this.setOldAppFormDelta(targetInstance[key]);
        } else {
          newDelta[key] = targetDelta[key]
          oldDelta[key] = targetInstance[key]
        }
      });

      deltaText = `${commentSender} has updated App (name: ${targetInstance.name})`
    }

    if (Object.keys(newDelta).length > 0) {
      deltaArray = FieldConfigChange.format(newDelta, oldDelta, oldDelta);
    }

    return { delta: deltaArray, text: deltaText };
  }

  setOldAppFormDelta(form: IForm[]) {
    return form.map(e => {
      return {
        id: e.id,
        label: e.name,
        value: e.id
      }
    })
  }

  setNewAppFormDelta(delta: Delta) {
    let newDelta = {} as Delta;
    Object.keys(delta).forEach(key => {
      if (delta[key] && Array.isArray(delta[key])) {
        newDelta[key] = delta[key].map((e: any) => {
          if (e && typeof e === 'object') {
            return {
              id: e.id,
              label: e.name,
              value: e.id
            }
          }
          return e;
        })
      } else {
        newDelta[key] = delta[key];
      }
    });
    return newDelta;
  }
}
import React, { useState } from 'react';
import { Dropdown, Image } from 'semantic-ui-react-bpm';
import { IConfigName, IFieldTypeObj } from '../../../field/type/interface/field-type-object';
import { tableFieldType } from '../../../field/type';
import { icons } from '../../../icons';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import { generateSourceOption } from '../../utils/generate-source-option';

interface IFieldSelectionProps {
  selectField(configName: IConfigName, index: number): void;
  fieldIndex: number;
  deleteField(index: number, seq?: number | string): void;
  changeFieldSequence(field: ICustomFieldDetail | null, dragIndex: number, dropIndex: number): void;
  publishForm: IForm[];
  datatable: IDataTable[];
  selectReferenceField(data: string, index: number): void;
  hasReferenceField: boolean;
  hasDefaultValue: boolean;
  isError: boolean;
  formDetail: IForm | IDataTable;
}

const FieldSelection: React.FC<IFieldSelectionProps> = (props) => {
  const [type, setType] = useState('');
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'tableSelectedField',
    drop: selectedItem => {
      const data: any = selectedItem;
      props.changeFieldSequence(data.field, data.index, props.fieldIndex)
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'tableSelectedField', field: null, index: props.fieldIndex },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  const refOption = generateSourceOption({
    datatable: props.datatable || [],
    publishedForm: props.publishForm || [],
    formDetail: props.formDetail,
    formType: 'EmbeddedTable',
    includeNameOnValue: true,
  });

  return <div ref={dropRef} style={{ margin: '7px 0' }}>
    <div ref={dragRef} style={{ ...draggedObject, display: 'flex', alignItems: 'center' }}
      className={`field-selection ${props.hasDefaultValue && props.hasReferenceField ? 'with-checkbox' : ''} ${props.isError ? 'error' : ''}`}>
      <div className='field-type-drag-icon'>
        <Image src={icons.blue.dragIcon} />
      </div>
      <Dropdown
        popperContainer={DropdownPopperOption}
        className='attribute-selection'
        fluid
        selection
        clearable
        value={type}
        selectOnBlur={false}
        placeholder={'Choose Field or Reference'}
        options={[{ key: 0, text: 'Regular Field', value: 'field' }, { key: 1, text: 'Referenced Field', value: 'referenced' }]}
        onChange={(event, target) => { setType(target.value as string) }}
      />
      {type === 'field' &&
        <Dropdown
          fluid
          search
          selection
          clearable
          value={''}
          selectOnBlur={false}
          placeholder={'Choose field type'}
          className='column-field-selection'
          options={tableFieldType.map((field: IFieldTypeObj, index: number) => {
            return { key: index + 1, text: field.title, value: field.defaultData.configName }
          })}
          onChange={(event, target) => { props.selectField(target.value as IConfigName, props.fieldIndex) }}
        />
      }
      {type === 'referenced' &&
        <Dropdown
          fluid
          search
          selection
          clearable
          value={''}
          selectOnBlur={false}
          placeholder={'Choose Source Record'}
          className='column-field-selection'
          options={refOption}
          onChange={(event, target) => { props.selectReferenceField(target.value as string, props.fieldIndex) }}
        />
      }
      {type !== 'field' && type !== 'referenced' && <div style={{ marginRight: '10px' }}></div>}
      <Image src={icons.blue.delete} onClick={() => props.deleteField(props.fieldIndex)} />
    </div>
  </div>
}

export default FieldSelection;
import {
  GET_FORM_STATUS_LIST,
  GET_FORM_STATUS_LIST_RETURN,
  GET_USER_LIST,
  GET_USER_LIST_RETURN,
  GET_GROUP_LIST,
  GET_GROUP_LIST_RETURN,
  GET_USER_FIELD_LIST,
  GET_USER_FIELD_LIST_RETURN,
  SAVE_ROLE,
  SAVE_ROLE_RETURN,
  SAVE_ROLE_ERROR,
  PUBLISH_ROLE,
  PUBLISH_ROLE_RETURN,
  PUBLISH_ROLE_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_RETURN,
  GET_ROLE_LIST_ERROR,
  DELETE_ROLE,
  GET_USER_CUSTOM_FIELD,
  GET_USER_CUSTOM_FIELD_RETURN,
  GET_CONSTRAINT_TEMPLATE,
  GET_CONSTRAINT_TEMPLATE_RETURN,
  GET_FORM_FIELD,
  GET_FORM_FIELD_RETURN
} from './constant'
import { IFormStatusListDetailInput } from '../workflow/interface/input/form-status-list'
import { IFormStatusListResponse } from './interface/response/form-status-list';
import { IUserListDetailInput } from '../../../users/interface/input/user-list';
import { IGroupListDetailInput } from '../../../groups/interface/input/group-list';
import { IFormUserListResponse } from './interface/response/form-user-list';
import { IFormGroupListResponse } from './interface/response/form-group-list';
import { UserFieldListDetailInput } from '../../../users/interface/input/user-field-list';
import { IFormUserFieldListResponse } from './interface/response/form-user-field-list';
import {
  IFormRolePublishDetailInput,
  IFormRoleSaveDetailInput,
} from './interface/input/form-role-save';
import { IFormRoleSaveResponse } from './interface/response/form-role-save';
import { IFormRoleGetDetailInput } from './interface/input/form-role-get';
import { IFormRoleGetResponse } from './interface/response/form-role-get';
import { IFormRoleDeleteDetailInput } from './interface/input/form-role-delete';
import { IUserCustomFieldGetDetailInput } from './interface/input/user-custom-field-get';
import { IUserCustomFieldResponse } from './interface/response/user-custom-field-get';
import {
  IConstraintTemplateGetDetailInput,
} from './interface/input/constraint-template';
import { IConstraintTemplateGetResponse } from './interface/response/constraint-template';
import { IFormFieldGetDetailInput } from './interface/input/form-field-get';
import { IFormFieldResponse } from './interface/response/form-field-get';

export const getFormStatusListAction = (payload: IFormStatusListDetailInput) => {
  return { type: GET_FORM_STATUS_LIST, ...payload };
}

export const getFormStatusListReturnAction = (payload: IFormStatusListResponse) => {
  return { type: GET_FORM_STATUS_LIST_RETURN, ...payload };
}

export const getUserListAction = (payload: IUserListDetailInput) => {
  return { type: GET_USER_LIST, ...payload };
}

export const getUserListReturnAction = (payload: IFormUserListResponse) => {
  return { type: GET_USER_LIST_RETURN, ...payload };
}

export const getGroupListAction = (payload: IGroupListDetailInput) => {
  return { type: GET_GROUP_LIST, ...payload };
}

export const getGroupListReturnAction = (payload: IFormGroupListResponse) => {
  return { type: GET_GROUP_LIST_RETURN, ...payload };
}

export const getUserFieldListAction = (payload: UserFieldListDetailInput) => {
  return { type: GET_USER_FIELD_LIST, ...payload };
}

export const getUserFieldListReturnAction = (payload: IFormUserFieldListResponse) => {
  return { type: GET_USER_FIELD_LIST_RETURN, ...payload };
}

export const saveFormRoleAction = (payload: IFormRoleSaveDetailInput) => {
  return { type: SAVE_ROLE, ...payload };
}

export const saveFormRoleReturnAction = (payload: IFormRoleSaveResponse) => {
  return { type: SAVE_ROLE_RETURN, ...payload };
}

export const saveFormRoleErrorAction = (message: string) => {
  return { type: SAVE_ROLE_ERROR, formSaveErrorMessage: message };
}

export const publishRoleAction = (payload: IFormRolePublishDetailInput) => {
  return { type: PUBLISH_ROLE, ...payload };
}

export const publishRoleReturnAction = () => {
  return { type: PUBLISH_ROLE_RETURN };
}

export const publishRoleErrorAction = (message: string) => {
  return { type: PUBLISH_ROLE_ERROR, formSaveErrorMessage: message };
}

export const getRoleListAction = (payload: IFormRoleGetDetailInput) => {
  return { type: GET_ROLE_LIST, ...payload };
}

export const getRoleListReturnAction = (payload: IFormRoleGetResponse) => {
  return { type: GET_ROLE_LIST_RETURN, ...payload };
}

export const getRoleListErrorAction = (message: string) => {
  return { type: GET_ROLE_LIST_ERROR, getRoleListErrorMessage: message };
}

export const deleteRoleAction = (payload: IFormRoleDeleteDetailInput) => {
  return { type: DELETE_ROLE, ...payload };
}

export const getUserCustomFieldAction = (payload: IUserCustomFieldGetDetailInput) => {
  return { type: GET_USER_CUSTOM_FIELD, ...payload };
}

export const getUserCustomFieldReturnAction = (payload: IUserCustomFieldResponse) => {
  return { type: GET_USER_CUSTOM_FIELD_RETURN, ...payload };
}

export const getConstraintTemplateAction = (payload: IConstraintTemplateGetDetailInput) => {
  return { type: GET_CONSTRAINT_TEMPLATE, ...payload }
}

export const getConstraintTemplateReturnAction = (payload: IConstraintTemplateGetResponse) => {
  return { type: GET_CONSTRAINT_TEMPLATE_RETURN, ...payload }
}

export const getFormFieldAction = (payload: IFormFieldGetDetailInput) => {
  return { type: GET_FORM_FIELD, ...payload }
}

export const getFormFieldReturnAction = (payload: IFormFieldResponse) => {
  return { type: GET_FORM_FIELD_RETURN, ...payload }
}


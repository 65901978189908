import { IApp } from '../../admin-module/module/apps/reducer';
import { GET_APPLICATION_LIST, GET_APPLICATION_LIST_RETURN, GET_FORM_FIELD_RETURN } from './constant';
import { IForm } from '../../admin-module/module/business-process/module/form/interface/form';

export interface ILateralMenuReducer {
  type?: string;
  applicationList: IApp[]
  appListLoading: boolean;
  individualBp: IForm[];
  formList: IForm[];
  seqFieldIds: { [formId: string]: string };
}

const initialState: ILateralMenuReducer = {
  applicationList: [],
  appListLoading: false,
  individualBp: [],
  formList: [],
  seqFieldIds: {},
}

const LateralMenuReducer = (state = initialState, action: ILateralMenuReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_APPLICATION_LIST:
      newState.appListLoading = true;
      return newState;

    case GET_APPLICATION_LIST_RETURN:
      newState.appListLoading = false;
      newState.applicationList = action.applicationList;
      newState.individualBp = action.individualBp;
      newState.formList = action.formList;
      return newState;

    case GET_FORM_FIELD_RETURN:
      newState.seqFieldIds = action.seqFieldIds;
      return newState;

    default:
      return newState;
  }
}

export default LateralMenuReducer;
import { IUserManagerDetailsInput } from './interface/input/user-manager';
import {
  GET_USER_MANAGER_DETAILS,
  GET_USER_MANAGER_DETAILS_RETURN,
  GET_USER_MANAGER_DETAILS_ERROR,
  UPDATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT_ERROR,
  UPDATE_USER_ACCOUNT_RETURN
} from './constant';
import { IUserManagerResponse } from './interface/response/user-manager';
import { IUserAccountUpdateDetailInput } from './interface/input/update-user-account';

export const getUserManagerDetailsAction = (payload: IUserManagerDetailsInput) => {
  return { type: GET_USER_MANAGER_DETAILS, ...payload }
}

export const getUserManagerDetailsReturnAction = (payload: IUserManagerResponse) => {
  return { type: GET_USER_MANAGER_DETAILS_RETURN, ...payload }
}

export const getUserManagerDetailsErrorAction = (message: string) => {
  return { type: GET_USER_MANAGER_DETAILS_ERROR, message }
}

export const updateUserAccountAction = (payload: IUserAccountUpdateDetailInput) => {
  return { type: UPDATE_USER_ACCOUNT, ...payload }
}

export const updateUserAccountReturnAction = () => {
  return { type: UPDATE_USER_ACCOUNT_RETURN }
}

export const updateUserAccountErrorAction = (message: string) => {
  return { type: UPDATE_USER_ACCOUNT_ERROR, message }
}
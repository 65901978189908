import { IFieldAccessType, IOptionValue } from '../../../../../component/admin-module/module/users/interface/field';
import ValidateField from '../../../validation';
import { IValidationError } from '../../../validation/type/validation-type';
import { FieldElement } from '../../field-element';
import { TValidationType, PropType } from '../field-properties.handler';

interface IEnumProps {
  value: string;
  error?: IValidationError[];
  filteredOption?: IOptionValue[];
  optionsFilterSearch?: string;
  selectedOptionFilter?: IOptionValue[];
  fieldType?: 'enum';
}

export class EnumProps extends PropType<IEnumProps, IEnumProps> {

  processValueFromRef(props: IEnumProps): IEnumProps {
    props.value = props.value || '';
    return props;
  }

  processValueToRef(newProps: IEnumProps): IEnumProps {
    newProps.value = newProps.value || '';
    return newProps;
  }

  processValidation(props: IEnumProps, field: FieldElement, validationType: TValidationType): IValidationError[] {
    const value = props.value || '';
    const validateField = new ValidateField();
    const validate = validateField.singleField(field, value || '', validationType);
    return field.accessType === IFieldAccessType.Required || !!value ? validate : [];
  }
}
import React from 'react';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';
import FieldWrapper from './field';

interface IFieldContainer {

}

const FieldContainer: React.FC<IFieldContainer> = (props) => {
  return <div className='field-container'>
    <FieldWrapper />
    <div className='settings'>
      <SVGWrapper iconName='cogIcon' color='#61a7b3' width='22' height='22' />
    </div>
  </div>
}

export default FieldContainer;
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { defaultMetadata } from '../constant';
import { IDisplayData, IDisplayDataConfigKeypaths } from '../interface/display-data';
import { IDisplayDataConfigAssignments } from '../interface/display-data-assignments';
import CreatedDisplayDataList from './list/created';

interface ICreatedDisplayData {
  openDisplayDataForm(data: IDisplayData, actionType: 'edit' | 'create'): void;
  displayDataList: IDisplayData[]
  displayDataConfigKeypath: IDisplayDataConfigKeypaths[];
  setDisplayDataToDelete(data: IDisplayData): void;
  triggerErrorMessage: (message: string) => void;
  displayDataApplied: IDisplayDataConfigAssignments[];
}

const CreatedDisplayData: React.FC<ICreatedDisplayData> = (props) => {

  const deleteConfig = (selected: IDisplayData) => {
    const isConfigSelected = props.displayDataApplied.find(e => e.configId === selected.id && !e.deleted);
    if (isConfigSelected) {
      props.triggerErrorMessage(`${selected.name} is currently applied to an end user screen. Please delete ${selected.name} from Applied Labels first.`);
    } else {
      props.setDisplayDataToDelete(selected);
    }
  }

  const addNewLabel = () => {
    const initialLabel: IDisplayData = {
      id: uuid(),
      name: '',
      header: [],
      content: [],
      meta: defaultMetadata,
      new: true,
      deleted: false
    }
    props.openDisplayDataForm(initialLabel, 'create');
  }

  return (<>
    <div className='display-data-container'>
      <div className='search-container'>
        <div className={`search-filter`}>
        </div>
        <Button id='add-new-label' className='add-button' onClick={addNewLabel}>Add Label</Button>
      </div>
      <div className='display-data-list-main'>
        <div className='display-data-list-container'>
          {props.displayDataList.map(displayData => {
            if (displayData.deleted || (!(displayData.name && displayData.header.length && displayData.content.length) && displayData.new)) {
              return null;
            }
            return <CreatedDisplayDataList
              key={displayData.id}
              displayData={displayData}
              displayDataConfigKeypath={props.displayDataConfigKeypath}
              openDisplayDataForm={props.openDisplayDataForm}
              deleteConfig={deleteConfig}
            />
          })}
        </div>
      </div>
    </div>
  </>
  );
}

export default CreatedDisplayData;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../../../../../common/api-request/upload';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import { icons } from '../../../../../../common/icons';
import InputBox from '../input';

interface ICommentEditPopup {
  open: boolean;
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  openUpload?(value: boolean): void;
  removeUploadedFile(file: UploadedFile): void;
  commentId?: string;
  message?: string;
  setSelectedId?(value: string): void;
  uploadedFileList: UploadedFile[];
  removeFileAfterSave(): void;
  documentId: string;
  mobile: boolean;
  actionType: 'create' | 'edit';
  module: string;
}

const CommentEditPopup: React.FC<ICommentEditPopup> = (props) => {
  const { width } = useDeviceDetect();

  const closePopup = () => {
    if (props.setSelectedId) {
      props.setSelectedId('');
    }
  }

  return <>
    {props.open &&
      <div id='chat-file-upload-modal-dimmer' className="ui page modals dimmer transition visible active">
        <div id="comment-edit-modal" className={`ui modal transition visible active`}>
          <div className="borderless header">
            <span className="header-label">Edit Comment</span>
            <Image onClick={closePopup} className='close' src={icons.black.closePlain} />
          </div>
          <div className="actions borderless">
            <InputBox
              documentId={props.documentId}
              removeFileAfterSave={props.removeFileAfterSave}
              removeUploadedFile={props.removeUploadedFile}
              uploadedFileList={props.uploadedFileList}
              saveComment={props.saveComment}
              message={props.message}
              setSelectedId={props.setSelectedId}
              commentId={props.commentId}
              mobile={width <= 480}
              actionType='edit'
              openUpload={props.openUpload}
              module={props.module}
            />
          </div>
        </div>
      </div>
    }
  </>
}

export default CommentEditPopup;
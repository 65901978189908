import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { ICommentDeltaFields } from '../../../../../../interface/comment';
import { setEditedFieldRef } from '../../utils/system-delta-fields';
import { renderFilePreview } from './render-file-preview';

export const renderAttachmentChanges = (delta: ICommentDeltaFields, attachment: any, newDeltaFields: ICommentDeltaFields[]) => {
  return <Fragment key={`${delta.id}`}>
    <div ref={setEditedFieldRef} className='edited-field-container'>
      <div className={`edited-field-info ${newDeltaFields.length === 1 ? 'hidden' : ''}`}>
        <span className='field-name'>{delta.label}</span>
      </div>
      <div key={uuid()}
        className={`edited-field-preview ${newDeltaFields.length === 1 ? 'single' : ''}`}>
        {renderFilePreview(attachment)}
      </div>
    </div>
  </Fragment>
}
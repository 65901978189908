import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import PopperContainer from '../../../../../general/popper-component';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { TimeProps } from '../../../service/type/time';
import { TValidationType } from '../../../service/field-properties.handler';
import { useInitialLoad } from './hook/use-initial-load';
import { triggerOnChange } from './utils/trigger-on-change';
import { onCalendarClose } from './utils/on-calendar-close';
import { renderError } from './utils/render-error';
import { hasIconInside } from './utils/has-icon';
import { removePopperPosition } from '../date-time/utils/remove-popper-position';
import { returnPopperPosition } from '../date-time/utils/return-popper-position';

const TimeType: React.FC<AcceptedProps> = (props) => {

  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher]: any = useState(1);

  let calendarRef = useRef<any>();

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const elementId = props.tableColumnRowIndex ?
    `time-picker-${name}-${props.tableColumnRowIndex}`
    : `time-picker-${name}`

  const elementProperties = new FieldProperties(name, props.forwardedRef, new TimeProps())

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur)
    setRefresher(uuid());
  }

  const handleTime = (time: any) => {
    elementProperties.setCurrent({ value: time ? moment(time).utc().format('HH:mm:ss[Z]') : '' });
    triggerOnChange(props);
  }

  const getValue = () => {
    let dateToday = moment().format("YYYY-MM-DD");
    const data = elementProperties.getCurrent().value || null;
    return data ? new Date(dateToday + ' ' + data) : null;
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (props.tableColumnRowIndex !== undefined && props.tableColumnDisplayed !== undefined) {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed
        });
      }
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValueString });
    }
  }

  const setRef = (curRef: any, id: string) => {
    if (curRef && focusedElement && focusedElement === id) {
      focusSelectedElement(focusedElement);
    }
    calendarRef.current = curRef;
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(elementId);
    }
  }

  useInitialLoad({ setDefaultValue, validateInput, accessType: props.accessType, isAddRecord: props.isAddRecord || false })

  useEffect(() => {
    if (props.isClearValue && elementProperties.isSet()) {
      elementProperties.setCurrent({ ...elementProperties.current, value: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div style={{ flexDirection: 'column' }} className='flex-wrapper'>
          <DatePicker
            id={elementId}
            popperContainer={PopperContainer}
            onChange={(time) => handleTime(time)}
            selected={getValue()}
            onCalendarClose={
              () => onCalendarClose({
                id: elementId,
                focusedElement,
                removeFocusedElement,
                validateInput,
                setRefresher,
                refresher,
                onTypeTimeout,
                reloadOnChange: props.reloadOnChange,
                triggerFieldReload: props.triggerFieldReload
              })
            }
            autoFocus={props.isFocus}
            showTimeSelect
            showTimeSelectOnly
            dateFormat='HH:mm:ss'
            readOnly={props.accessType === IFieldAccessType.Readonly}
            className={`field-input single ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            })}`}
            placeholderText={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
            onInputClick={() => focusInput(elementId)}
            onFocus={() => returnPopperPosition(elementId)}
            ref={(curRef: any) => setRef(curRef, elementId)}
            onKeyDown={handleKeydown}
            popperClassName={elementId}
          />
          {hasIconInside('single', props.hasIconInside)}
          {renderError({
            elementProperties,
            showErrorMessage: props.showErrorMessage
          })}
        </div>
      </>
    }
  </>
}

export default TimeType;
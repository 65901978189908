import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { ICommentDeltaFields } from '../../../../../interface/comment';
import { isFileArrayType, setEditedFieldRef, setSytemMessagesDeltaFields } from '../utils/system-delta-fields';
import { renderAttachmentChanges } from './utils/render-attachment-changes';
import { renderFieldValue } from './utils/render-field-value';
import { renderRangeChanges } from './utils/render-range-changes';
import { renderSourceChanges } from './utils/render-source-changes';
import RenderTableChanges from './utils/render-table-changes';

interface ISytemMessagesDeltaFieldChanges {
  newDeltaFields: ICommentDeltaFields[],
  isCollapse: boolean;
  isTableFieldChanges?: boolean;
}


const SytemMessagesDeltaFieldChanges: React.FC<ISytemMessagesDeltaFieldChanges> = ({ newDeltaFields, isCollapse, isTableFieldChanges }) => {

  const objectDeltaFieldChanges = (value: any, type: 'before' | 'after') => {
    let valueEl = <>&nbsp;{value}&nbsp;</>;

    if (typeof value === 'object' && !Array.isArray(value)) {
      valueEl = <>{Object.keys(value).map(keys => {
        return <p key={keys}>
          {keys} : {value[keys]}
        </p>
      })}</>;
    }

    if (typeof value === 'object' && Array.isArray(value)) {
      valueEl = <>{value.map((value, idx) => {
        return <p key={`${value}-${idx}`}>
          {value}
        </p>
      })}</>;
    }

    return <div className={`preview ${type}`}>{valueEl}</div>
  }

  return <div className={`field-preview ${isCollapse ? 'hidden' : ''} ${isTableFieldChanges ? 'table' : ''}`}>
    {newDeltaFields.map((delta: ICommentDeltaFields) => {
      const fieldValues: any[] = setSytemMessagesDeltaFields(delta);

      let valueBefore = 'No value';
      let valueAfter = 'No value';


      if (delta.type === 'tableUpdate') {
        return <RenderTableChanges
          key={delta.id}
          delta={delta}
          newDeltaFields={newDeltaFields}
        />
      }

      if (delta.type === 'link') {
        return renderSourceChanges(delta, fieldValues, newDeltaFields);
      }

      if (delta.type === 'file' || isFileArrayType({ delta, fieldValues })) {
        return renderAttachmentChanges(delta, fieldValues, newDeltaFields);
      }

      if (delta.type === 'range' || delta.type === 'geolocation') {
        return renderRangeChanges(fieldValues, delta, newDeltaFields);
      }

      if (fieldValues && fieldValues.length > 0 && typeof fieldValues !== 'undefined') {
        if (fieldValues.length === 1) {
          valueAfter = renderFieldValue(fieldValues[0], delta);
        } else {
          if (fieldValues[0] && typeof fieldValues[0] !== 'undefined') {
            valueBefore = renderFieldValue(fieldValues[0], delta);
          }
          if (fieldValues[1] && typeof fieldValues[1] !== 'undefined') {
            valueAfter = renderFieldValue(fieldValues[1], delta);
          }
        }
      }

      if (delta.textOnly) {
        return <Fragment key={`${uuid()}`}>
          <div className='edited-field-container'>
            <div className={`edited-field-info `}>
              <span className={`field-name label`}>{delta.label}</span>
            </div>
            <div className={`edited-field-preview text-only`}>
              <div className='preview before'>{delta.text}</div>
            </div>
          </div>
        </Fragment>
      }


      return <Fragment key={`${uuid()}`}>
        <div ref={setEditedFieldRef} className='edited-field-container'>
          {delta.fieldLabel &&
            <div className={`edited-field-info `}>
              <span className={`field-name label`}>Field: {delta.fieldLabel}</span>
            </div>
          }
          <div className={`edited-field-info ${newDeltaFields.length === 1 && !delta.alwaysShowLabel ? 'hidden' : ''}`}>
            <span className={`field-name`}>{delta.label}</span>
          </div>
          <div className={`edited-field-preview ${newDeltaFields.length === 1 ? 'single' : ''}`}>
            {objectDeltaFieldChanges(valueBefore, 'before')}
            <div className='arrow-right'>&#8594;</div>
            {objectDeltaFieldChanges(valueAfter, 'before')}
          </div>
        </div>
      </Fragment>
    })}
  </div>
}

export default SytemMessagesDeltaFieldChanges;

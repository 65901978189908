import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';
import { AutomationModuleRegistry } from './module-registry';

interface IMatch {
  path: string;
  url: string;
  params: {
    submodule: string;
    thirdLevelRoute?: string;
    fourthLevelRoute?: string;
    formId: string;
  }
}

interface IAutomationModuleProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const AutomationModule: React.FC<IAutomationModuleProps> = (props) => {
  const match: IMatch = useRouteMatch();
  return (
    <Route path={`${match.path}`}>
      <AutomationModuleRegistry.Render
        type={match.params.fourthLevelRoute || ''}
        hasPageChangeRef={props.hasPageChangeRef}
      />
    </Route>
  );
}

export default AutomationModule;
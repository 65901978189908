import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import NoIdRedirect from '../../no-id-redirect';
import FormBuilderContainer from './form-builder';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { useSetThirdLevelRoute } from '../../../../hooks/use-set-third-level-route';

interface IFormBuilderProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class FormBuilder {

  name = 'form-builder';

  render: React.FC<IFormBuilderProps> = (props) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route exact path={`${match.path}`}>
          <NoIdRedirect />
        </Route>
        <Route path={`${match.path}/:formId`}>
          <FormBuilderContainer hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </>
    );
  }
}

export default FormBuilder;

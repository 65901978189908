import React from 'react';
import ListFilterConfig from './config/filter-config';
import ListHeaderConfig from './config/header-config';
interface IListMainView {

}

const ListMainView: React.FC<IListMainView> = (props) => {
  return <div className='list-main-view'>
    <ListFilterConfig />
    <ListHeaderConfig />
  </div>
}

export default ListMainView;
import React from 'react';
import { v4 as uuid } from 'uuid';
import { accessLevelToValue, valueToAccessLevel } from '../../../../constant';
import { IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../../interface/field-permission';
import { ISection, ITablePermission } from '../../../../interface/section';

interface IFieldSlider {
  permission: IStatusFieldPermission;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  section: ISection | ITablePermission;
  sectionType: 'section' | 'table';
}

const FieldSlider: React.FC<IFieldSlider> = (props) => {
  const accessLevel = props.permission.accessLevel as string;
  const value = accessLevelToValue[accessLevel || 'editable'] || 3;

  return <div key={`${props.permission.fieldId}-${uuid()}}`} id="slider-config-container">
    <input type="range" step='1' min="1" max="4" defaultValue={value}
      onChange={(e) => {
        props.handlePermissionChange(props.section, props.permission.fieldId, valueToAccessLevel[e.target.value], undefined, props.sectionType)
      }}
    />
    <ul>
      <li>Hidden</li>
      <li>Read-only</li>
      <li>Editable</li>
      <li>Required</li>
    </ul>
  </div>
}

export default FieldSlider;

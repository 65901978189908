import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest } from '../../../../../../../../common/api-request';
import { systemField } from '../../../../../../../../common/custom-field-config/filter-configuration/constant';
import { SanitizeLabels } from '../../../../../../../../common/utils/sanitize-labels';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { IFormFieldsInput } from '../duplicate-checker/interface/input/form-fields';
import {
  getFormLabelsReturnAction,
  getFormLabelsErrorAction,
  publishFormLabelReturnAction,
  publishFormLabelErrorAction,
} from './actions';
import {
  GET_FORM_LABELS,
  PUBLISH_FORM_LABELS
} from './constant';

export function* getFormLabels(args: IFormFieldsInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/labels?draft=true`, {});
    const appliedLabels: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/appliedLabels?draft=true`, {});
    const fields: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/fields?draft=true`, {});
    const status: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true`, {});

    yield put(getFormLabelsReturnAction({
      formLabels: SanitizeLabels.structureIncoming(req.data.data, fields.data.data.concat(systemField), formId),
      statusList: status.data.data,
      fieldList: fields.data.data,
      formAppliedLabels: SanitizeLabels.structureIncomingAppliedLabels(appliedLabels.data.data, fields.data.data.concat(systemField), formId)
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getFormLabelsErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* publishFormLabels(args: { company: string; formId: string, type: string }) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishFormLabelReturnAction());
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e));
    yield put(publishFormLabelErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* labelsSagas() {
  yield takeLatest(GET_FORM_LABELS, getFormLabels);
  yield takeLatest(PUBLISH_FORM_LABELS, publishFormLabels);
}

// All sagas to be loaded
export default labelsSagas;
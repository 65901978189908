import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dimmer, Grid, Header, Loader, Portal, Segment } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import changeRoute from '../../../../../../common/utils/change-menu';
import { useRect } from '../../../../../../common/utils/useRect';
import { IRootState } from '../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { getUserFieldListAction } from '../../../users/action';
import ButtonContainer from '../../button-container';
import {
  getFormDetailAction,
  getFormStatusesAction,
  getPermissionListAction,
  publishPermissionAction,
  saveFormPermissionAction
} from './action';
import ActionContainer from './actions';
import { IConsolidateTableConfig, IPermissionFieldFilterConfig, IStatusFieldPermission } from './interface/field-permission';
import { IFormPermissionSection, ISection, ITablePermission, IStatusAction } from './interface/section';
import FieldPermissionSettings from './settings';
import StatusContainer from './status/container';
import StatusSectionFields from './status/section-fields';
import PermissionTab from './tab';
import { StructurePermissionList } from './utils/structure-permission-list';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({} as { width: number, height: number });
  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const wrapperRef = React.createRef<HTMLDivElement>();

interface IPermissionContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PermissionContainer: React.FC<IPermissionContainerProps> = (props) => {
  const history = useHistory();

  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
  const [formStatusSectionList, setFormStatusSectionList] = useState([] as IFormPermissionSection[]);
  const [selectedStatus, setSelectedStatus] = useState({} as IFormPermissionSection);
  const [selectedField, setSelectedField] = useState({} as ICustomFieldDetail);
  const [selectedFieldSection, setSelectedFieldSection] = useState({} as ISection);
  const [filterConfig, setFilterConfig] = useState({} as IPermissionFieldFilterConfig);
  const [openPermissionFilterConfig, setOpenPermissionFilterConfig] = useState(false);
  const [popupPermissionConfig, setPopupPermissionConfig] = useState(false);
  const [isSectionPermission, setIsSectionPermission] = useState(false);
  const [selectedFieldList, setSelectedFieldList] = useState([] as ICustomFieldDetail[]);
  const [selectedTableSection, setSelectedTableSection] = useState({} as ITablePermission);
  const [isTablePermission, setIsTablePermission] = useState(false);
  const [activeTab, setActiveTab] = useState('fields' as 'fields' | 'dynamic-actions');
  const [selectedStatusAction, setSelectedStatusAction] = useState({} as IStatusAction);

  const wrapperRect = useRect(wrapperRef);

  const dispatch = useDispatch();
  const match: { params: { formId: string } } = useRouteMatch();
  const {
    company
  } = useSelector((state: IRootState) => state.auth);
  const {
    formStatuses,
    formDetail,
    formDetailField,
    formStatusesLoading,
    formDetailLoading,
    saveRoleType,
    formRoleSaveLoading,
    permissionList,
    getPermissionListLoading
  } = useSelector((state: IRootState) => state.permission);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const { userFieldListDynamic, userFieldListStatic } = useSelector((state: IRootState) => state.user);
  const screenSize = useWindowSize();

  const openSectionPermissionSettings = (section: ISection) => {
    setSelectedFieldSection(section);
    setOpenPermissionFilterConfig(true);
    setIsSectionPermission(true);
    setSelectedField({} as ICustomFieldDetail);
    setSelectedStatusAction({} as IStatusAction);
  }

  const openFieldPermissionSettings = (field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string) => {
    setIsSectionPermission(false);
    setSelectedField(field);
    setSelectedFieldSection(section as ISection);
    setOpenPermissionFilterConfig(true);
    if (updateType === 'table') {
      setSelectedTableSection(section as ITablePermission);
      setIsTablePermission(true);
    } else {
      setIsTablePermission(false);
      setSelectedTableSection({} as ITablePermission);
    }
    setSelectedStatusAction({} as IStatusAction);
  }

  const openStatusActionPermissionSettings = (action: IStatusAction) => {
    setSelectedStatusAction(action);
    setOpenPermissionFilterConfig(true);
    setIsSectionPermission(false);
    setSelectedField({} as ICustomFieldDetail);
    setSelectedFieldSection({} as ISection);
  }

  const handleActionPermissionChange = (forUpdateAction: IStatusAction, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig) => {
    const updateSelectedStatus = { ...selectedStatus };
    let updateformStatusSectionList = [...formStatusSectionList];
    const actionList = updateActionFieldPermission(updateSelectedStatus, forUpdateAction, accessLevel, permissionConfig);
    updateSelectedStatus.actions = actionList;
    updateformStatusSectionList = updateformStatusSectionList.map((formStatus: IFormPermissionSection) => {
      if (formStatus.statusId === updateSelectedStatus.statusId) {
        return updateSelectedStatus;
      }
      return formStatus;
    });
    setSelectedStatus(updateSelectedStatus);
    setFormStatusSectionList(updateformStatusSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const updateActionFieldPermission = (updateSelectedStatus: IFormPermissionSection, forUpdateAction: IStatusAction, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig) => {
    const sectionList = updateSelectedStatus.actions.map((action: IStatusAction) => {
      if (action.id === forUpdateAction.id) {
        action.permissions.accessLevel = accessLevel;
        action.permissions.config = permissionConfig;
      }
      return action;
    });
    props.hasPageChangeRef.current.hasChange = true;
    return sectionList;
  }

  const handleSelectedStatus = (formStatusPermission: IFormPermissionSection, statusIndex: number) => {
    setSelectedStatus(formStatusPermission);
    setSelectedStatusIndex(statusIndex);
  }

  const handleReferToStatusChange = (forUpdateSection: IFormPermissionSection, statusId: string) => {
    const updateSelectedStatus = { ...selectedStatus };
    let updateformStatusSectionList = [...formStatusSectionList];
    const linkFormStatus = updateformStatusSectionList.filter((status: IFormPermissionSection) => status.statusId === statusId);
    const tablePermissionList = linkFormStatus.length > 0 ? linkFormStatus[0].table : forUpdateSection.table;
    const sectionList = updateSelectedStatus.sections.map((section: ISection, sectionIndex: number) => {
      const copySectionPermission = linkFormStatus.length > 0 ? linkFormStatus[0].sections[sectionIndex].permissions : undefined;
      const copySectionAccessLevel = linkFormStatus.length > 0 ? linkFormStatus[0].sections[sectionIndex].accessLevelPerStatus : '';
      const forChangeSectionPermission = forUpdateSection.sections[sectionIndex];
      if (section.id === forChangeSectionPermission.id) {
        section.accessLevelPerStatus = copySectionAccessLevel;
        section.permissions = section.permissions.map((permission: IStatusFieldPermission, permissionIndex: number) => {
          if (copySectionPermission) {
            permission.accessLevel = copySectionPermission[permissionIndex].accessLevel;
            permission.config = copySectionPermission[permissionIndex].config ? copySectionPermission[permissionIndex].config : undefined;
          }
          return permission;
        })
      }
      return section;
    })
    updateSelectedStatus.referToStatusId = statusId;
    updateSelectedStatus.sections = sectionList;
    updateSelectedStatus.table = tablePermissionList;
    updateformStatusSectionList = updateformStatusSectionList.map((formStatus: IFormPermissionSection) => {
      if (formStatus.statusId === updateSelectedStatus.statusId) {
        return updateSelectedStatus;
      }
      const isStatusEqualToReferStatusId = formStatus.referToStatusId === updateSelectedStatus.statusId;
      if (isStatusEqualToReferStatusId) {
        return updateChildFieldSectionReferStatus(formStatus, updateSelectedStatus, statusId,);
      }
      return formStatus;
    })

    setSelectedStatus(updateSelectedStatus);
    setFormStatusSectionList(updateformStatusSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const handlePermissionChange = (forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string) => {
    const updateSelectedStatus = { ...selectedStatus };
    let updateformStatusSectionList = [...formStatusSectionList];
    if (updateType === 'table') {
      const tableList = updateTableFieldPermission(updateSelectedStatus, forUpdateSection as ITablePermission, fieldId, accessLevel, permissionConfig, 'field');
      const belongSection = updateSelectedStatus.sections.filter(e => e.fields.filter(f => f.id === forUpdateSection.id));
      updateSelectedStatus.table = tableList;
      const sectionList = updateFieldPermission(updateSelectedStatus, belongSection[0] as ISection, forUpdateSection.id, 'editable', undefined);
      updateSelectedStatus.sections = sectionList;
    } else {
      const fieldData = formDetailField.find(e => e.id === fieldId);
      if (fieldData && fieldData.type === 'table') {
        const forUpdateTable = updateSelectedStatus && updateSelectedStatus.table && updateSelectedStatus.table.find(e => e.id === fieldId);
        const tableList = updateTableFieldPermission(updateSelectedStatus, forUpdateTable as ITablePermission, '', accessLevel, permissionConfig, 'table');
        updateSelectedStatus.table = tableList;
      }
    }
    const sectionList = updateFieldPermission(updateSelectedStatus, forUpdateSection as ISection, fieldId, accessLevel, permissionConfig);
    updateSelectedStatus.sections = sectionList;
    updateSelectedStatus.referToStatusId = '';
    updateformStatusSectionList = updateformStatusSectionList.map((formStatus: IFormPermissionSection) => {
      if (formStatus.statusId === updateSelectedStatus.statusId) {
        return updateSelectedStatus;
      }
      const isStatusEqualToReferStatusId = formStatus.referToStatusId === updateSelectedStatus.statusId;
      if (isStatusEqualToReferStatusId) {
        return updateChildFieldPermission(formStatus, fieldId, accessLevel, permissionConfig, updateSelectedStatus.table);
      }
      return formStatus;
    });

    setSelectedStatus(updateSelectedStatus);
    setFormStatusSectionList(updateformStatusSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const handleSectionPermissionChange = (forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any) => {
    const updateSelectedStatus = { ...selectedStatus };
    let updateformStatusSectionList = [...formStatusSectionList];

    const sectionList = updateSectionFieldPermission(updateSelectedStatus, forUpdateSection as ISection, accessLevel, accessLevelPerSection);
    updateSelectedStatus.sections = sectionList;
    updateSelectedStatus.referToStatusId = '';
    updateSelectedStatus.table = updateTableFieldPermission(updateSelectedStatus, forUpdateSection as ITablePermission, '', accessLevel, accessLevelPerSection, 'section');
    updateformStatusSectionList = updateformStatusSectionList.map((formStatus: IFormPermissionSection) => {
      if (formStatus.statusId === updateSelectedStatus.statusId) {
        return updateSelectedStatus;
      }
      const isStatusEqualToReferStatusId = formStatus.referToStatusId === updateSelectedStatus.statusId;
      if (isStatusEqualToReferStatusId) {
        return updateChildFieldSectionPermission(formStatus, updateSelectedStatus, forUpdateSection as ISection, accessLevel, accessLevelPerSection);
      }
      return formStatus;
    })

    setSelectedStatus(updateSelectedStatus);
    setFormStatusSectionList(updateformStatusSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const updateSectionFieldPermission = (updateSelectedStatus: IFormPermissionSection, forUpdateSection: ISection, accessLevel: string, accessLevelPerSection: any): ISection[] => {
    const sectionList = updateSelectedStatus.sections.map((section: ISection) => {
      if (section.id === forUpdateSection.id) {
        section.accessLevelPerStatus = accessLevelPerSection;
        section.permissions = section.permissions.map((permission: IStatusFieldPermission) => {
          permission.accessLevel = accessLevel;
          permission.config = typeof accessLevelPerSection === 'object' ? accessLevelPerSection : undefined
          return permission;
        })
      } else {
        section.permissions = section.permissions.map((permission: IStatusFieldPermission) => {
          return permission;
        })
      }
      return section;
    })
    props.hasPageChangeRef.current.hasChange = true;
    return sectionList;
  }

  const updateFieldPermission = (updateSelectedStatus: IFormPermissionSection, forUpdateSection: ISection, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig): ISection[] => {
    const sectionList = updateSelectedStatus.sections.map((section: ISection) => {
      if (section.id === forUpdateSection.id) {
        section.accessLevelPerStatus = '';
        section.permissions = section.permissions.map((permission: IStatusFieldPermission) => {
          if (permission.fieldId === fieldId) {
            permission.config = permissionConfig;
            permission.accessLevel = accessLevel;
          }
          return permission;
        })
      }
      return section;
    });
    props.hasPageChangeRef.current.hasChange = true;
    return sectionList;
  }

  const updateChildFieldPermission = (childStatus: IFormPermissionSection, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, tablePermision?: ITablePermission[]): IFormPermissionSection => {
    const updatedChildStatus = { ...childStatus };
    updatedChildStatus.table = tablePermision;
    updatedChildStatus.sections = updatedChildStatus.sections.map((section: ISection) => {
      section.permissions = section.permissions.map((permission: IStatusFieldPermission) => {
        if (permission.fieldId === fieldId) {
          permission.config = permissionConfig;
          permission.accessLevel = accessLevel;
        }
        return permission;
      })
      return section;
    })

    props.hasPageChangeRef.current.hasChange = true;
    return updatedChildStatus;
  }

  const updateChildFieldSectionPermission = (childStatus: IFormPermissionSection, updateSelectedStatus: IFormPermissionSection, forUpdateSection: ISection, accessLevel: string, accessLevelSection: any): IFormPermissionSection => {
    const updatedChildStatus = { ...childStatus };
    let updateSectionIndex: number | undefined = undefined;
    let accessLevelPerSection: string | IPermissionFieldFilterConfig = '';
    updateSelectedStatus.sections.forEach((section: ISection, index: number) => {
      if (section.id === forUpdateSection.id) {
        updateSectionIndex = index;
        accessLevelPerSection = section.accessLevelPerStatus;
      }
    });
    updatedChildStatus.table = updateSelectedStatus.table;
    updatedChildStatus.sections = updatedChildStatus.sections.map((section: ISection, index: number) => {
      if (updateSectionIndex === index) {
        section.accessLevelPerStatus = accessLevelPerSection;
        section.permissions = section.permissions.map((permission: IStatusFieldPermission) => {
          permission.accessLevel = accessLevel;
          permission.config = accessLevelSection && typeof accessLevelSection === 'object' ? accessLevelSection : undefined;
          return permission;
        })
      }
      return section;
    })

    props.hasPageChangeRef.current.hasChange = true;
    return updatedChildStatus;
  }

  const updateChildFieldSectionReferStatus = (childStatus: IFormPermissionSection, parentStatus: IFormPermissionSection, referStatusId: string, permissionConfig?: IPermissionFieldFilterConfig): IFormPermissionSection => {
    const updatedChildStatus = { ...childStatus };
    updatedChildStatus.referToStatusId = referStatusId;
    updatedChildStatus.table = parentStatus.table;
    updatedChildStatus.sections = updatedChildStatus.sections.map((section: ISection, sectionIndex: number) => {
      section.accessLevelPerStatus = parentStatus.sections[sectionIndex].accessLevelPerStatus;
      section.permissions = section.permissions.map((permission: IStatusFieldPermission, permissionIndex: number) => {
        permission.accessLevel = parentStatus.sections[sectionIndex].permissions[permissionIndex].accessLevel;
        return permission;
      })
      return section;
    })

    props.hasPageChangeRef.current.hasChange = true;
    return updatedChildStatus;
  }

  const updateTableFieldPermission = (updateSelectedStatus: IFormPermissionSection, forUpdateSection: ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateLevel?: string) => {
    const permissions = Object.assign([], [...updateSelectedStatus.table ?? []]);
    const tablePermissionList = permissions.map((section: ITablePermission) => {
      if (forUpdateSection && Object.keys(forUpdateSection).length > 0) {
        if (section.id === forUpdateSection.id) {
          const updatedSection = Object.assign({}, section);
          if (updateLevel === 'section') {
            updatedSection.accessLevelPerTable = typeof permissionConfig === 'object' ? permissionConfig : accessLevel;
          } else {
            if (!fieldId && updateLevel === 'table') {
              updatedSection.accessLevelPerTable = typeof permissionConfig === 'object' ? permissionConfig : accessLevel;
              updatedSection.permissions = updatedSection.permissions.map((permission: IStatusFieldPermission) => {
                return {
                  ...permission,
                  config: undefined,
                  accessLevel: accessLevel ? accessLevel : 'editable',
                } as IStatusFieldPermission;
              });
            }
            else {
              updatedSection.accessLevelPerTable = '';
              updatedSection.permissions = updatedSection.permissions.map((permission: IStatusFieldPermission) => {
                if (permission.fieldId === fieldId) {
                  const updatedPermission = Object.assign({}, permission);
                  updatedPermission.config = typeof permissionConfig === 'object' ? permissionConfig : undefined;
                  updatedPermission.accessLevel = accessLevel;
                  return updatedPermission;
                }
                return permission;
              });
            }
          }

          return updatedSection;
        }
      }
      return section;
    })
    props.hasPageChangeRef.current.hasChange = true;
    return tablePermissionList;
  }

  const cancelFieldPermissions = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const saveFieldPermissions = (saveType: string) => {
    props.hasPageChangeRef.current.hasChange = false;
    dispatch(saveFormPermissionAction({
      companyId: company,
      formId: match.params.formId,
      permissionList: formStatusSectionList,
      saveType: saveType,
      sanitize: true
    }))
  }

  const updatePermissionFieldConfig = (filter: IPermissionFieldFilterConfig, updateType: string, fieldType?: string, consolidateTable?: IConsolidateTableConfig) => {
    if (updateType === 'aggregate') {
      updateConsolidateTable(consolidateTable);
      return;
    }
    const defaultPermission = fieldType === 'table' ? '' : 'editable';
    let forUpdateFilterConfig = { ...filterConfig };
    const forUpdateSection = { ...selectedFieldSection };
    const forUpdateField = { ...selectedField };
    forUpdateFilterConfig = filter;
    handlePermissionChange(forUpdateSection, forUpdateField.id || '', defaultPermission, filter, updateType);
    setFilterConfig(forUpdateFilterConfig);
  }

  const updateSectionPermissionFieldConfig = (filter: IPermissionFieldFilterConfig, updateType?: string) => {
    let forUpdateFilterConfig = { ...filterConfig };
    const forUpdateSection = { ...selectedFieldSection };
    forUpdateFilterConfig = filter;
    handleSectionPermissionChange(forUpdateSection, 'editable', forUpdateFilterConfig);
    setFilterConfig(forUpdateFilterConfig);
  }

  const updateStatusActionPermissionConfig = (filter: IPermissionFieldFilterConfig) => {
    let forUpdateFilterConfig = { ...filterConfig };
    const forUpdateStatusAction = { ...selectedStatusAction };
    forUpdateFilterConfig = filter;
    handleActionPermissionChange(forUpdateStatusAction, 'enabled', forUpdateFilterConfig);
    setFilterConfig(forUpdateFilterConfig);
  }

  const onTabChange = (tab: "fields" | "dynamic-actions") => {
    setActiveTab(tab);
    setOpenPermissionFilterConfig(false);
  }

  const updateConsolidateTable = (consolidateTable?: IConsolidateTableConfig) => {
    let updateformStatusSectionList = [...formStatusSectionList];

    const forUpdateField = { ...selectedField };
    const updateSelectedStatus = { ...selectedStatus };

    const forUpdateTable = updateSelectedStatus && updateSelectedStatus.table && updateSelectedStatus.table.find(e => e.id === forUpdateField.id);

    if (forUpdateTable && updateSelectedStatus.table) {
      forUpdateTable.consolidateTable = consolidateTable;
      updateSelectedStatus.table = updateSelectedStatus.table.map(table => {
        if (table.id === forUpdateTable.id) {
          return forUpdateTable;
        };
        return table;
      });

    }
    updateformStatusSectionList = updateformStatusSectionList.map((formStatus: IFormPermissionSection) => {
      if (formStatus.statusId === updateSelectedStatus.statusId) {
        return updateSelectedStatus;
      }
      return formStatus;
    });

    setSelectedStatus(updateSelectedStatus);
    setFormStatusSectionList(updateformStatusSectionList);
  }

  useEffect(() => {
    setPopupPermissionConfig(screenSize.width < 1600);
  }, [screenSize]);

  useEffect(() => {
    const tempFormField = [...formDetailField];
    let tempFieldList = [] as ICustomFieldDetail[];
    if (Object.keys(selectedField).length > 0 || Object.keys(selectedStatusAction).length > 0) {
      tempFieldList = tempFormField;
    } else if (Object.keys(selectedFieldSection).length > 0) {
      const { fields } = { ...selectedFieldSection };
      const sectionFields = fields && fields.map((field: ICustomFieldDetail) => { return field.id });
      tempFieldList = tempFormField.filter((field: ICustomFieldDetail) => {
        return sectionFields && sectionFields.indexOf(field.id) === -1;
      });
    }
    setSelectedFieldList(tempFieldList);
  }, [selectedField, formDetailField, selectedFieldSection, selectedStatusAction])

  useEffect(() => {
    if (saveRoleType === 'publish') {
      dispatch(publishPermissionAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRoleType]);

  useEffect(() => {
    if (match.params.formId && company) {
      dispatch(getPermissionListAction({ company: company, formId: match.params.formId }));
      dispatch(getFormStatusesAction({ companyId: company, formId: match.params.formId }));
      dispatch(getFormDetailAction({ company: company, formId: match.params.formId }));
      dispatch(getUserFieldListAction({ company: company }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.formId, company])

  useEffect(() => {
    const permission = new StructurePermissionList();
    const permissions = permission.setPermission({
      formDetail,
      formDetailField,
      formStatuses,
      permissionList
    });
    setFormStatusSectionList(permissions);
    setSelectedStatus(permissions[0]);
    setSelectedStatusIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDetail, formDetailField, formStatuses, permissionList]);

  return (
    <>
      {
        (formStatusesLoading || formDetailLoading || formRoleSaveLoading || getPermissionListLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <PermissionTab
        setActiveTab={onTabChange}
        activeTab={activeTab}
      />
      <div className={`permission-container ${(openPermissionFilterConfig && !popupPermissionConfig) ? 'settings-active' : ''}`}>
        <StatusContainer
          formStatusSectionList={formStatusSectionList}
          handleSelectedStatus={handleSelectedStatus}
          selectedStatus={selectedStatus}
        />
        {activeTab === 'fields' ?
          <StatusSectionFields
            showStatusOption={selectedStatusIndex > 0}
            selectedStatus={selectedStatus}
            selectedStatusIndex={selectedStatusIndex}
            formPermissionSection={formStatusSectionList}
            handleReferToStatus={handleReferToStatusChange}
            handleSectionPermissionChange={handleSectionPermissionChange}
            handlePermissionChange={handlePermissionChange}
            openFieldPermissionSettings={openFieldPermissionSettings}
            openPermissionFilterConfig={openPermissionFilterConfig}
            openSectionPermissionSettings={openSectionPermissionSettings}
            wrapperRef={wrapperRef}
          />
          :
          <ActionContainer
            selectedStatus={selectedStatus}
            handleActionPermissionChange={handleActionPermissionChange}
            openStatusActionPermissionSettings={openStatusActionPermissionSettings}
            openPermissionFilterConfig={openPermissionFilterConfig}
          />
        }
        {(openPermissionFilterConfig && !popupPermissionConfig) &&
          <FieldPermissionSettings
            cancel={() => { setOpenPermissionFilterConfig(false) }}
            fieldData={selectedField}
            currentBP={formDetail}
            fieldList={selectedFieldList}
            updatePermissionConfig={isSectionPermission
              ? updateSectionPermissionFieldConfig
              : (selectedStatusAction && Object.keys(selectedStatusAction).length > 0)
                ? updateStatusActionPermissionConfig
                : updatePermissionFieldConfig
            }
            selectedStatus={selectedStatus}
            selectedFieldSection={selectedFieldSection}
            open={openPermissionFilterConfig}
            isSectionPermission={isSectionPermission}
            filterConfig={filterConfig}
            isTablePermission={isTablePermission}
            selectedTableSection={selectedTableSection &&
              Object.keys(selectedTableSection).length > 0
              ? selectedTableSection : undefined
            }
            selectedStatusAction={selectedStatusAction}
            userFieldList={[
              ...userFieldListDynamic,
              ...userFieldListStatic
            ]}
          />
        }
        {(openPermissionFilterConfig && popupPermissionConfig) &&
          <Grid columns={2}>
            <Grid.Column>
              <Portal open={(openPermissionFilterConfig && popupPermissionConfig)}>
                <Segment className='permission-settings-popup transition'
                  style={{
                    left: `calc(${wrapperRect.left}px + 12%)`,
                    right: `calc(${wrapperRect.left}px - 8%)`,
                    position: 'fixed',
                    top: `calc(${wrapperRect.top}px + 4%)`,
                    zIndex: 50
                  }}>
                  <Header className='bg-primary'>
                    {(selectedField.label || selectedFieldSection.title) || selectedStatusAction.name || 'Untitled Section'}
                    &nbsp;-&nbsp;Permission Config
                  </Header>
                  <FieldPermissionSettings
                    cancel={() => { setOpenPermissionFilterConfig(false) }}
                    fieldData={selectedField}
                    currentBP={formDetail}
                    fieldList={selectedFieldList}
                    updatePermissionConfig={isSectionPermission
                      ? updateSectionPermissionFieldConfig
                      : (selectedStatusAction && Object.keys(selectedStatusAction).length > 0)
                        ? updateStatusActionPermissionConfig
                        : updatePermissionFieldConfig
                    }
                    selectedStatus={selectedStatus}
                    selectedFieldSection={selectedFieldSection}
                    open={openPermissionFilterConfig}
                    isSectionPermission={isSectionPermission}
                    filterConfig={filterConfig}
                    isTablePermission={isTablePermission}
                    selectedTableSection={selectedTableSection &&
                      Object.keys(selectedTableSection).length > 0
                      ? selectedTableSection : undefined
                    }
                    selectedStatusAction={selectedStatusAction}
                    userFieldList={[
                      ...userFieldListDynamic,
                      ...userFieldListStatic
                    ]}
                  />
                </Segment>
              </Portal>
            </Grid.Column>
          </Grid>
        }
      </div>
      <div>
        {!openPermissionFilterConfig &&
          <ButtonContainer
            save={() => saveFieldPermissions('draft')}
            publish={() => saveFieldPermissions('publish')}
            cancel={cancelFieldPermissions}
          />}
      </div>
    </>
  )
}

export default PermissionContainer;
import React, { useState, useEffect } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { IField, IFieldAccessType, IOptionValue } from '../../../../../../component/admin-module/module/users/interface/field';
import { v4 as uuid } from 'uuid';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { FieldProperties } from '../../../service/field-properties.service';
import { EnumProps } from '../../../service/type/enum';
import { TValidationType } from '../../../service/field-properties.handler';

const getOption = (props: AcceptedProps): IOptionValue[] => {
  if (!props.defaultValueElement) {
    return props.inputConfig.config.items || [];
  }
  if (props.inputConfig.config.dataType !== 'database') {
    return props.inputConfig.config.data || [];
  }
  return [];
}

export interface IDropdownOption {
  key: string;
  text: string;
  value: string;
  deleted?: boolean;
}

const ChecklistType: React.FC<AcceptedProps> = (props) => {
  const name = props.getFieldId();
  const [refresher, setRefresher] = useState(0)

  const elementProperties = new FieldProperties(name, props.forwardedRef, new EnumProps());

  const validateSelectedValue = () => {
    let schema = {
      ...props.validationSchema,
      isRequired: props.accessType === IFieldAccessType.Required
    } as any;
    if (schema.hasOwnProperty('anyOf')) {
      if (Array.isArray(schema.anyOf)) {
        schema.anyOf.forEach((e: any) => {
          if (props.inputConfig.config.multiselect) {
            if (e.type === 'array') {
              schema = { ...e, isRequired: props.accessType === IFieldAccessType.Required };
            }
          }
        });
      }
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema, isRequired: props.accessType === IFieldAccessType.Required } }, TValidationType.onBlur);
    setRefresher(refresher + 1);
  }

  const handleOnClick = (value: string) => {
    let tempValue = value;
    let automationValue = null;
    if (elementProperties.current.value === tempValue) {
      tempValue = '';
    }
    automationValue = tempValue;

    setRefresher(refresher + 1); // to refresh state
    elementProperties.setCurrent({ value: tempValue })
    if (props.accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    if (props.triggerFilter) {
      props.triggerFilter('')
    }
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, automationValue)
      }
    }
  }

  const elementStatus = () => {
    let hasValues = false;
    let hasError = false;

    if (elementProperties.isSet()) {
      const values = getValue();
      hasError = elementProperties.hasError();
      hasValues = values ? true : false;
      if (props.accessType === IFieldAccessType.Required && !hasValues) {
        hasError = true;
      }
    }
    return hasValues && !hasError ? 'valid' : hasError ? 'error' : '';
  }

  const getValue = () => {
    return elementProperties.current.value;
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue = props.defaultValue ? props.defaultValue : '';
    if (!props.defaultValueElement && defaultValue) {
      elementProperties.setCurrent({ value: defaultValue as string });
      setRefresher(refresher + 1);
    }
  }

  const removeDeletedItemInOptions = (option: IDropdownOption[], value: any): IDropdownOption[] => {
    return option.filter((e: IDropdownOption) => {
      let isValueAlreadySelected = false;
      if (value instanceof Array) {
        isValueAlreadySelected = (value.indexOf(e.value) > -1);
      } else {
        isValueAlreadySelected = e.value === value;
      }

      if (e.deleted && !isValueAlreadySelected) {
        return false;
      }
      return true;
    })
  }

  const renderError = () => {
    return <>
      {
        elementProperties.hasError() && props.showErrorMessage &&
        elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
        <small className={`field error ${props.configName}`}
          dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }} />
      }
    </>
  }

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const values = getValue();
  const optionList = removeDeletedItemInOptions(getOption(props).map((e: IOptionValue): IDropdownOption => { return { key: e.id, text: e.label, value: e.id, deleted: e.deleted || undefined } }), values);

  useEffect(() => {
    if (props.defaultValueElement && elementProperties.current.value) {
      const filterOption = optionList.filter((option: IDropdownOption) => option.value === values);
      if (!filterOption || filterOption.length === 0) {
        elementProperties.setCurrent({ value: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionList.length])

  useEffect(() => {
    if (props.isClearValue && elementProperties.current.value) {
      elementProperties.setCurrent({ value: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  return <>

    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}
          >
            {props.label}
          </label>
          {props.hint && <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>}
        </span>
        <div className={`checklist-container ${elementStatus()}`}>
          {
            optionList.map((option: IDropdownOption) => {
              return <div
                key={uuid()}
                className={`field-input ui checkbox ${elementProperties.hasError() ? 'error' : ''} checklist-element`}
              >
                <input
                  className="hidden"
                  type="checkbox"
                  disabled={props.accessType === IFieldAccessType.Readonly}
                  onChange={() => { }}
                  checked={!!values && values.indexOf(option.value) > -1} />
                <label
                  onClick={() => props.accessType === IFieldAccessType.Readonly ? {} : handleOnClick(option.value)}
                >{option.text}</label>
              </div>
            })
          }
          {renderError()}
        </div>
      </>
    }
  </>
}

export default React.memo(ChecklistType);
export const sortByCreationDate = (list: any[], ascending: boolean): any[] => {
  function compare(a: any, b: any) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.createdAt;
    const bandB = b.createdAt;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    if (!ascending) {
      return comparison * -1;
    }
    return comparison;
  }
  return list.sort(compare)
}
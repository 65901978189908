import { IFormStatusWithAction } from './interface/form-status';
import {
  GET_FORM_STATUS_LIST,
  GET_FORM_STATUS_LIST_RETURN,
  GET_FORM_STATUS_LIST_ERROR,
  SAVE_FORM_STATUS,
  SAVE_FORM_STATUS_RETURN,
  SAVE_FORM_STATUS_ERROR,
  GET_FORM_ACTION_LIST,
  GET_FORM_ACTION_LIST_RETURN,
  GET_FORM_ACTION_LIST_ERROR,
  PUBLISH_WORKFLOW,
  PUBLISH_WORKFLOW_RETURN,
  PUBLISH_WORKFLOW_ERROR,
  CLEAR_SAVE_FORM_STATUS_SUCCESS,
  CLEAR_SAVE_FORM_STATUS_ERROR
} from './constant';
import { IFormAction } from './interface/form-action';
import { message } from './message';

export interface IFormWorkflowReducer {
  type?: string;
  formStatusList: IFormStatusWithAction[];
  formStatusListLoading: boolean;
  formActionList: IFormAction[];
  formActionListLoading: boolean;
  formStatusSaveLoading: boolean;
  formSaveErrorMessage: string;
  formSaveMessage: string;
  saveWorkflowType: string;
}

const initialState: IFormWorkflowReducer = {
  formStatusList: [],
  formStatusListLoading: false,
  formActionList: [],
  formActionListLoading: false,
  formStatusSaveLoading: false,
  formSaveMessage: '',
  formSaveErrorMessage: '',
  saveWorkflowType: ''
};

const workflowReducer = (state = initialState, action: IFormWorkflowReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_STATUS_LIST:
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      newState.formStatusListLoading = true;
      newState.saveWorkflowType = '';
      return newState;

    case GET_FORM_STATUS_LIST_RETURN:
      newState.formStatusList = action.formStatusList;
      newState.formStatusListLoading = false;
      return newState;

    case GET_FORM_STATUS_LIST_ERROR:
      newState.formStatusListLoading = false;
      return newState;

    case SAVE_FORM_STATUS:
      newState.formStatusSaveLoading = true;
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      newState.saveWorkflowType = '';
      return newState;

    case SAVE_FORM_STATUS_RETURN:

      if (action.saveWorkflowType === 'draft') {
        newState.formStatusSaveLoading = false;
        newState.formSaveMessage = message.saveFormStatusSuccessMessage;
      }
      newState.formStatusList = action.formStatusList;
      newState.formActionList = action.formActionList;
      newState.saveWorkflowType = action.saveWorkflowType;
      return newState;

    case SAVE_FORM_STATUS_ERROR:
      newState.formStatusSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      newState.saveWorkflowType = '';
      return newState;

    case PUBLISH_WORKFLOW:
      newState.saveWorkflowType = '';
      return newState;

    case PUBLISH_WORKFLOW_RETURN:
      newState.formStatusSaveLoading = false;
      newState.formSaveMessage = message.publishFormStatusSuccessMessage;
      return newState;

    case PUBLISH_WORKFLOW_ERROR:
      newState.formStatusSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      return newState;

    case GET_FORM_ACTION_LIST:
      newState.formActionListLoading = true;
      return newState

    case GET_FORM_ACTION_LIST_RETURN:
      newState.formActionListLoading = false;
      newState.formActionList = action.formActionList;
      return newState

    case GET_FORM_ACTION_LIST_ERROR:
      newState.formActionListLoading = false;
      return newState

    case CLEAR_SAVE_FORM_STATUS_SUCCESS:
      newState.formSaveMessage = '';
      return newState;

    case CLEAR_SAVE_FORM_STATUS_ERROR:
      newState.formSaveErrorMessage = '';
      return newState;

    default:
      return state;
  }
}

export default workflowReducer

import React from 'react';
import { IReferencedFieldCondition, ICustomFieldDetail, IConfig } from '../interface/custom-field-detail';
import { IForm } from '../../../component/admin-module/module/business-process/module/form/interface/form';
import { Dropdown } from 'semantic-ui-react-bpm';
import { FieldValue } from './field-value';
import { useDispatch } from 'react-redux';
import { IFieldConfig } from '../../../component/admin-module/module/users/interface/field';
import { IDataTable } from '../../../component/admin-module/module/data-tables/reducer';
import { IOption } from '../../interface/option';
import DropdownPopperOption from '../../general/dropdown-popper-option';
import { hideValueAndFieldOption } from '../../utils/field-operator';
import IDFieldCompare from './field-compare';
import { checkIfUUID } from '../../utils/check-uuid-value';
import { IConfigName } from '../../field/type/interface/field-type-object';

export interface IFilterConfigDetail {
  condition: IReferencedFieldCondition;
  rowIndex: number | null;
  currentBp: IForm | IDataTable;
  getOperatorOption(condition: IReferencedFieldCondition): IOption[];
  getSelectedField(condition: IReferencedFieldCondition): ICustomFieldDetail;
  getSelectedCompareField(fieldId: string): ICustomFieldDetail;
  removeCondition(conditionIndex: number | null): void;
  handleConfig(name: 'fieldId' | 'fromBp' | 'operator' | 'compareType' | 'tableId', value: string, index: number | null): void;
  handleCompareField(name: 'fieldId' | 'fromBp' | 'fieldRef', value: string, index: number | null): void;
  getRefFieldSourceFormDetails(selectedCompareField: ICustomFieldDetail): { id: string; name: string; } | null;
  defaultvalueRef: any;
  company: string;
  fieldData: ICustomFieldDetail;
  configData: IFieldConfig;
  error: { compareTypeField: boolean };
  tableId?: string;
  referencedBPDetail: { id: string, name: string };
  currentBpFieldOptions: IOption[];
  referencedBPFieldOptions: IOption[];
  tableFieldOptions: IOption[];
  defaultField: ICustomFieldDetail[];
  currBPRefFieldsOptions: IOption[];
  tableDetails?: ICustomFieldDetail;
  userFieldList?: ICustomFieldDetail[];
  referenceFieldList: ICustomFieldDetail[];
}

interface ILeftConfig extends IFilterConfigDetail {
  handleSourceFieldLabel(id: string): void;
  setFieldOption(): IOption[];
}

interface IRightConfig extends IFilterConfigDetail {
  hideValueOptionComponent(): boolean;
  triggerOptionGet(): boolean;
  setCompareTypeFieldOption(): IOption[];
  hideFieldOption: boolean;
  isTableField: boolean;
}

interface IOperatorConfig extends IFilterConfigDetail {
}


const FilterConfigDetail: React.FC<IFilterConfigDetail> = (props) => {
  const {
    condition,
    removeCondition,
    rowIndex,
    handleConfig,
    getSelectedField,
    getSelectedCompareField,
    referencedBPDetail,
    currentBpFieldOptions,
    referencedBPFieldOptions,
    currentBp,
    currBPRefFieldsOptions,
    getRefFieldSourceFormDetails,
  } = { ...props };

  const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(condition.operator);
  const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(condition.operator);
  const selectedField = getSelectedField(condition);
  const selectedCompareField = getSelectedCompareField(condition.compareField.fieldId);
  const refFieldSourceFormDetails = getRefFieldSourceFormDetails(selectedCompareField);

  const setFieldOption = (): IOption[] => {
    return referencedBPFieldOptions;
  }

  const setCompareTypeFieldOption = (): IOption[] => {
    if (isIDField()) {
      return currBPRefFieldsOptions;
    }
    if (isTableField()) {
      return referencedBPFieldOptions;
    }
    return currentBpFieldOptions;
  }

  const hideValueOptionComponent = (): boolean => {
    if (Object.keys(currentBp).length) {
      return condition.fromBp === currentBp.id;
    }
    return hideValueOption;
  }

  const isIDField = (): boolean => {
    return selectedField.type === 'id';
  }

  const handleSourceFieldLabel = (id: string) => {
    if (props.tableId) handleConfig('tableId', id, rowIndex);
    if (id && currentBp.id) {
      handleConfig('fromBp', currentBp.id, rowIndex);
    } else {
      handleConfig('fromBp', referencedBPDetail.id, rowIndex);
    }
  }

  const isTableField = (): boolean => {
    return condition.tableId !== undefined
      && Object.keys(currentBp).length > 0
      && condition.fromBp === currentBp.id;
  }

  const handleIDFieldClick = () => {
    const value = !isIDField() ? 'id' as string : '';
    handleConfig('fieldId', value, rowIndex);
  }

  const triggerOptionGet = () => {
    let trigger = false;
    const field = getSelectedField(condition);
    if (checkIfUUID(field.id as string)) {
      if (field.configName === IConfigName.Referenced) {
        trigger = true;
      }
      if (field.configName === IConfigName.Autopopulated
        && field.config && field.config.labelType === 'enum') {
        trigger = true;
      }
    }
    return trigger;
  }

  return <>
    <div className={`condition-field`}>
      <i className={`minus circular icon remove-condtion`} onClick={() => removeCondition(rowIndex)} />
      <div className={`condition-configuration`}>
        <div className='field-container selection-id'>
          <label className='label selection-id-label'>ID</label>
          <div className='checkbox-id component'>
            <div className="ui fitted checkbox" onClick={handleIDFieldClick}>
              <input className="hidden" type="checkbox" checked={isIDField()} value=""
                onChange={() => { }}
              />
              <label></label>
            </div>
          </div>
        </div>
        {isIDField() ?
          <IDFieldCompare
            {...props}
            setCompareTypeFieldOption={setCompareTypeFieldOption}
            refFieldSourceFormDetails={refFieldSourceFormDetails}
            handleSourceFieldLabel={handleSourceFieldLabel}
          />
          : <>
            <LeftConfigWidget
              {...props}
              handleSourceFieldLabel={handleSourceFieldLabel}
              setFieldOption={setFieldOption}
            />
            <OperatorConfigWidget {...props} />
            <RightConfigWidget
              {...props}
              hideValueOptionComponent={hideValueOptionComponent}
              triggerOptionGet={triggerOptionGet}
              setCompareTypeFieldOption={setCompareTypeFieldOption}
              hideFieldOption={hideFieldOption}
              isTableField={isTableField()}
            />
          </>
        }
      </div>
    </div>
  </>
}

const OperatorConfigWidget: React.FC<IOperatorConfig> = (props) => {
  const {
    condition,
    rowIndex,
    handleConfig,
    getOperatorOption,
  } = { ...props };

  return <div className='field-container selection-operator'>
    <label className='label selection-operator-label'>&nbsp;</label>
    <Dropdown
      fluid
      selection
      clearable
      search
      placeholder='Select operator'
      selectOnBlur={false}
      value={condition.operator}
      options={getOperatorOption(condition)}
      onChange={(event, target) => handleConfig('operator', target.value as string, rowIndex)}
    />
  </div>;
}


const RightConfigWidget: React.FC<IRightConfig> = (props) => {
  const dispatch = useDispatch();
  const {
    condition,
    rowIndex,
    handleConfig,
    getSelectedField,
    defaultvalueRef,
    company,
    handleCompareField,
    error,
    referencedBPDetail,
    currentBp,
    referenceFieldList,
    hideValueOptionComponent,
    hideFieldOption,
    isTableField,
    triggerOptionGet,
    setCompareTypeFieldOption,
  } = { ...props };

  return <div className='field-container selection-field-value'>
    <div className='label selection-field-value-label'>
      {!hideValueOptionComponent() &&
        <label onClick={() => handleConfig('compareType', 'value', rowIndex)}
          className={`label ${condition.compareType === 'value' ? 'selected' : ''}`}>
          Value
        </label>
      }
      {!hideFieldOption &&
        <label onClick={() => handleConfig('compareType', 'field', rowIndex)}
          className={`label ${condition.compareType === 'field' ? 'selected' : ''}`}>
          Field {` : ${!isTableField ? currentBp.name : referencedBPDetail.name || 'Untitled Source'}`}
        </label>
      }
    </div>
    {!hideValueOptionComponent() && condition.compareType === 'value' &&
      <div className={`condition-value`}>
        <FieldValue.Render
          field={getSelectedField(condition)}
          forwardedRef={defaultvalueRef}
          dispatch={dispatch}
          referenceBp={referencedBPDetail}
          companyId={company}
          currentFormField={{
            ...props.fieldData,
            config: {
              dataType: props.configData.dataType || '',
            } as IConfig
          }}
          valueId={condition.id}
          configType={condition.tableId ? 'tableConfig' : undefined}
          parentFormFieldId={condition.tableId}
          currentBpId={props.currentBp.id}
          fromPermission={triggerOptionGet()}
          currentFieldList={referenceFieldList}
        />
      </div>
    }
    {!hideFieldOption && condition.compareType === 'field' &&
      <Dropdown
        popperContainer={DropdownPopperOption}
        error={error.compareTypeField}
        fluid
        selection
        search
        clearable
        selectOnBlur={false}
        value={condition.compareField.fieldId}
        options={setCompareTypeFieldOption()}
        onChange={(event, target) => handleCompareField('fieldId', target.value as string, rowIndex)}
        placeholder='Select field'
      />
    }
  </div>;
}

const LeftConfigWidget: React.FC<ILeftConfig> = (props) => {
  const {
    condition,
    rowIndex,
    handleConfig,
    tableId,
    referencedBPDetail,
    tableFieldOptions,
    tableDetails,
    handleSourceFieldLabel,
    setFieldOption,
  } = { ...props };

  return <div className='field-container selection-field-source'>
    <div className='label source-field-label'>
      <label className={`label ${!condition.tableId ? 'selected' : ''}`} onClick={() => handleSourceFieldLabel('')}>
        Field
      </label>
      {tableId && <label className={`label ${condition.tableId ? 'selected' : ''}`}
        onClick={() => handleSourceFieldLabel(tableId as string)}>Table</label>
      }
      <label className='label selected'>:&nbsp;
        {condition.tableId && tableDetails ? ` ${tableDetails.label || 'Untitled Source'}` : ` ${referencedBPDetail.name || 'Untitled Source'}`}
      </label>
    </div>

    {condition.tableId ?
      <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        selection
        clearable
        search
        selectOnBlur={false}
        value={condition.fieldId}
        options={tableFieldOptions}
        onChange={(event, target) => {
          handleConfig('fieldId', target.value as string, rowIndex);
          handleConfig('compareType', 'field', rowIndex);
        }}
        placeholder='Select field'
      />
      : <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        selection
        clearable
        search
        selectOnBlur={false}
        value={condition.fieldId}
        options={setFieldOption()}
        onChange={(event, target) => {
          handleConfig('fromBp', referencedBPDetail.id, rowIndex);
          handleConfig('fieldId', target.value as string, rowIndex);
        }}
        placeholder='Select field'
      />
    }

  </div>
}

export default FilterConfigDetail;

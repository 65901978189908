import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IFieldTypeProps {
  field: ICustomFieldDetail;
  bpImage?: { src: any, seq: number };
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): any;
}

const FieldType: React.FC<IFieldTypeProps> = (props) => {
  const [draggedObject, dragRef] = useDrag({
    item: { type: 'field', data: props.field },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  return <div
    ref={dragRef}
    style={draggedObject}
    key={`field-type-${props.field.label}`}
    className='field-type'>
    <div className='field-type-image'>
      {props.viewIcon(props.field, props.bpImage)}
    </div>
    <div className='field-type-title'>
      <span>{props.field.label}</span>
    </div>
  </div>
}

export default FieldType;
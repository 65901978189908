import {AggregateFunction} from "../fx.agg-func";

export class CountPositiveAggregateFunction implements AggregateFunction {
  name = 'count-positive';

  aggregate(values: number[]): number {
    return values
      .filter(value => value !== undefined && value !== null && value > 0)
      .reduce((count, curr) => count + 1, 0)
  }
}
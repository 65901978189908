import React from 'react';
import { AcceptedProps } from './interface/accepted-props';
import NumberType from './type/number/number';
import NumberArrayType from './type/number/number-array';

const Number: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <NumberArrayType {...props} />
  } else {
    return <NumberType {...props} />
  }
}

export default React.memo(Number);
import React from 'react';
import AutomationLogs from './automation-logs';
import AutomationRules from './automation-rules';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';

const ModuleList = [
  new AutomationLogs(),
  new AutomationRules()
]

interface IRender {
  type: string;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

interface IAutomationModuleRegistry {
  name: string;
  render: React.FC<any>
}

export class AutomationModuleRegistry {

  registry: { [name: string]: IAutomationModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const adminModule = new AutomationModuleRegistry();
    const Render = adminModule.getModule(props.type);
    return <Render {...props} />
  }

}
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ConfigService from '../../../../../common/config';
import { currentRouteAction, setDownloadRequestsAction } from '../../../../main/action';
import DatatablesGrid from './grid';

interface IDataTablesTableList {
  dataTable: [];
  openDeleteConfirmationModal(formId: string, formName: string): void;
  openDataTableRecords: (id: string) => void;
  triggerFilter(limit?: number | undefined, offset?: number | undefined, search?: string | undefined): void;
  company: string;
  currentOffset: number;
}

export class DataTablesTableList {
  static Render: React.FC<IDataTablesTableList> = (props) => {

    const dispatch = useDispatch();

    const downloadData = () => {
      const axiosURL = ConfigService.loadConfig().general.apiBaseUrl + `/${props.company}/databases?load=total&load=access&export=xlsx`;
      dispatch(setDownloadRequestsAction({
        method: 'get',
        url: axiosURL,
        data: {},
        match: { params: { submodule: 'datatable' } },
        downloadName: 'datatable',
        headers: {
          'Accept': 'application/json'
        }
      }));
    }

    useEffect(() => {
      dispatch(currentRouteAction('data-tables'));
      // eslint-disable-next-line
    }, []);

    return <div className='data-table-container table-wrapper'>
      <DatatablesGrid
        dataTable={props.dataTable}
        openDeleteConfirmationModal={props.openDeleteConfirmationModal}
        openDataTableRecords={props.openDataTableRecords}
        triggerFilter={props.triggerFilter}
        downloadData={downloadData}
      />
    </div>
  }
}
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../icons';
import { useDrop } from 'react-dnd';

interface IDragToDelete {
  removeFormula(index: number): void;
}

const DragToDelete: React.FC<IDragToDelete> = (props) => {

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'formula',
    drop: (item: { type: string, data: any }) => {
      props.removeFormula(item.data)
    }
  })

  return <div ref={dropRef} className="drag-delete-container" >
    <Image width='17px' src={icons.gray.delete}></Image>
    Drag and drop to delete
  </div>
}

export default DragToDelete;

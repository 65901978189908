import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';
import { getFilters } from '../../../../../../../../main/partials/header/hexToCssFilter';
import { fieldPermissionList } from '../../../constant';

interface IFieldWrapper {

}

interface IPermissionComponent {

}

const PermissionComponent: React.FC<IPermissionComponent> = (props) => {
  return <div id='permission-component'>
    {fieldPermissionList.map(permission => {
      let iconColor = '#8A9296';

      if (permission.id === 'editable') {
        iconColor = '#fff'
      }
      return <div key={permission.id} className={`permission-icon ${permission.id === 'editable' ? 'selected' : ''}`}>
        {permission.id === 'hidden' &&
          <svg className='slash' width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="14.3536" y1="0.353553" x2="0.353553" y2="14.3536" stroke={iconColor} />
          </svg>
        }
        <SVGWrapper iconName={permission.iconName} color={iconColor} width='16' height='16' />
      </div>
    })}

    <div className='permission-label'>
      EDITABLE
    </div>
  </div>
}

const FieldWrapper: React.FC<IFieldWrapper> = (props) => {
  return <div className='field-wrapper'>
    <div className='field-icon'>
      <Image style={{ filter: getFilters('#61A7B3').filter }} src={icons.black.calendar} />
    </div>
    <div className='field-label'>Supplier Name</div>
    <div className='field-permission'>
      <PermissionComponent />
    </div>
  </div>
}

export default FieldWrapper;
import React from 'react';
import { useDispatch } from 'react-redux';
import RoleTable from './role-table';
import { Route, useRouteMatch } from 'react-router-dom';
import NoIdRedirect from '../../no-id-redirect';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { useSetThirdLevelRoute } from '../../../../hooks/use-set-third-level-route';

interface IRolesProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Roles {

  name = 'roles';

  render: React.FC<IRolesProps> = (props) => {

    const dispatch = useDispatch();
    const match = useRouteMatch();

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route exact path={`${match.path}`}>
          <NoIdRedirect />
        </Route>
        <Route path={`${match.path}/:formId`}>
          <RoleTable hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </>
    );
  }
}

export default Roles;

import { useEffect } from 'react';
import {
  currentRouteAction,
  setFourthLevelRouteAction,
  resetFourthLevelRouteAction
} from '../../main/action';

interface IProps {
  mainRoute: string;
  subRoute: string;
  thirdLevelRoute: string;
  fourthLevelRoute: string;
  dispatch: any;
  currentRoute?: string;
}

export const useSetFourthLevelRoute = (props: IProps) => {
  const { mainRoute, subRoute, thirdLevelRoute, dispatch, currentRoute, fourthLevelRoute } = { ...props };

  useEffect(() => {
    dispatch(
      setFourthLevelRouteAction({
        mainRoute: mainRoute,
        subRoute: subRoute,
        thirdLevelRoute: thirdLevelRoute,
        fourthLevelRoute: fourthLevelRoute
      })
    );
    dispatch(currentRouteAction(currentRoute || ''));
    return () => {
      dispatch(resetFourthLevelRouteAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
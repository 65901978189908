import {
  IDisplayDataGetDetailInput,
  IDisplayDataSaveDetailInput,
  IDisplayDataDeleteDetailInput
} from './interface/input/display-data-action';
import {
  IDisplayDataAssignmentsGetResponse,
  IDisplayDataGetResponse,
  IDisplayDataSaveResponse
} from './interface/response/display-data-return';
import {
  GET_DISPLAY_DATA_CONFIG,
  GET_DISPLAY_DATA_CONFIG_RETURN,
  GET_DISPLAY_DATA_CONFIG_ERROR,
  SAVE_DISPLAY_DATA_CONFIG,
  SAVE_DISPLAY_DATA_CONFIG_RETURN,
  SAVE_DISPLAY_DATA_CONFIG_ERROR,
  GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN,
  DELETE_DISPLAY_DATA_CONFIG,
  DELETE_DISPLAY_DATA_CONFIG_ERROR,
  DELETE_DISPLAY_DATA_CONFIG_RETURN,
  PUBLISH_DISPLAY_DATA_CONFIG,
  PUBLISH_DISPLAY_DATA_CONFIG_ERROR,
  PUBLISH_DISPLAY_DATA_CONFIG_RETURN
} from './constant'

export const getDisplayDataConfigAction = (payload: IDisplayDataGetDetailInput) => {
  return {
    type: GET_DISPLAY_DATA_CONFIG,
    ...payload
  }
}

export const getDisplayDataConfigReturnAction = (payload: IDisplayDataGetResponse) => {
  return {
    type: GET_DISPLAY_DATA_CONFIG_RETURN,
    ...payload
  }
}

export const getDisplayDataConfigErrorAction = (error: string) => {
  return {
    type: GET_DISPLAY_DATA_CONFIG_ERROR,
    error
  }
}

export const saveDisplayDataAction = (payload: IDisplayDataSaveDetailInput) => {
  return {
    type: SAVE_DISPLAY_DATA_CONFIG,
    ...payload
  }
}

export const saveDisplayDataResponseAction = (payload: IDisplayDataSaveResponse) => {
  return {
    type: SAVE_DISPLAY_DATA_CONFIG_RETURN,
    ...payload
  }
}

export const saveDisplayDataErrorAction = (error: string) => {
  return {
    type: SAVE_DISPLAY_DATA_CONFIG_ERROR,
    error
  }
}

export const getDisplayDataConfigAssignmentReturnAction = (payload: IDisplayDataAssignmentsGetResponse) => {
  return {
    type: GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN,
    ...payload
  }
}

export const deleteDisplayDataAction = (payload: IDisplayDataDeleteDetailInput) => {
  return {
    type: DELETE_DISPLAY_DATA_CONFIG,
    ...payload
  }
}

export const deleteDisplayDataReturnAction = (payload: { deletedData: any, activeMenu: "Applied Labels" | "Created Labels" }) => {
  return {
    type: DELETE_DISPLAY_DATA_CONFIG_RETURN,
    ...payload
  }
}

export const deleteDisplayDataErrorAction = (error: string) => {
  return {
    type: DELETE_DISPLAY_DATA_CONFIG_ERROR,
    error
  }
}

export const publishDisplayDataAction = (payload: { company: string; formId: string }) => {
  return {
    type: PUBLISH_DISPLAY_DATA_CONFIG,
    ...payload
  }
}

export const publishDisplayDataReturnAction = () => {
  return {
    type: PUBLISH_DISPLAY_DATA_CONFIG_RETURN
  }
}

export const publishDisplayDataReturnErrorAction = (error: string) => {
  return {
    type: PUBLISH_DISPLAY_DATA_CONFIG_ERROR,
    error
  }
}
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../reducers';
import { changePageNextRouteAction } from './action';
import { ConfirmationMessage } from '../../common/general/confirmation-modal';
import { useHistory } from 'react-router-dom';
import { IHasPageChangeRef } from './interface/has-page-change';

interface IHasPageChangeProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

export const HasPageChangePopup: React.FC<IHasPageChangeProps> = (props) => {
  const { hasPageChange, changePageNextRoute, openNewTab } = useSelector((state: IRootState) => state.main);
  const dispatch = useDispatch();
  const history = useHistory();

  const closeModal = () => {
    dispatch(changePageNextRouteAction(''));
  }

  const hasChangePageOnRef = props.hasPageChangeRef.current.hasChange;

  const continueModal = () => {
    if (openNewTab) {
      const newWindow = window.open(changePageNextRoute, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null;
    } else {
      history.push(changePageNextRoute);
    }
    dispatch(changePageNextRouteAction(''));
    props.hasPageChangeRef.current.hasChange = false;
  }

  return <ConfirmationMessage
    errorMessage={''}
    confirmButtonName={'Confirm'}
    cancelButtonName={'Cancel'}
    confirmButtonColor={'red'}
    cancelButtonColor={'green'}
    close={closeModal}
    open={(hasPageChange === true || hasChangePageOnRef === true) && changePageNextRoute !== ''}
    confirm={continueModal}
    cancel={closeModal}
    headerText="Confirmation"
    detailText={`There are unsaved changes, are you sure you want to leave?`}
    loading={false}
    type='warning'
  />
}
import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { IDatePropertiesMinMax } from '../../../../../../../common/custom-field-config/custom-field-type/date-config';
import { IConfig } from '../../../../../../../common/field/component/field-element';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

const formulaToOperator: { [name: string]: string } = {
  'add': '+',
  'subtract': '-'
}

export class DateDeltaFields implements DeltaFieldType {

  name = 'date';

  format(targetDelta: Delta, targetInstance: any, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];

    let { delta, config } = FieldConfigChange.targetDelta(targetDelta) as { delta: Delta, config: IConfig };

    const { itemConfig }: any = { ...config as any };

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      if (config.max) {
        const newDelta = this.setDeltaConfig(config.max, 'Max', utils);
        const oldDelta = this.setDeltaConfig(targetInstance.config.max || {}, 'Max', utils);
        const maxDelta = this.setDatePropertiesDelta(newDelta, oldDelta);
        const maxDeltaArr = maxDelta
          .filter(delta => delta.diff !== delta.oldValue)
          .map(delta => {
            return {
              ...delta,
              diff: !Array.isArray(delta.diff) ? [delta.oldValue, delta.diff] : delta.diff
            }
          })
          .filter(delta => {
            return new Set(delta.diff).size === delta.diff.length;
          })
        deltaArray = deltaArray.concat(maxDeltaArr);
      }
      if (config.min) {
        const newDelta = this.setDeltaConfig(config.min, 'Min', utils);
        const oldDelta = this.setDeltaConfig(targetInstance.config.min || {}, 'Min', utils);
        const minDelta = this.setDatePropertiesDelta(newDelta, oldDelta);
        const minDeltaArr = minDelta
          .filter(delta => delta.diff !== delta.oldValue)
          .map(delta => {
            return {
              ...delta,
              diff: !Array.isArray(delta.diff) ? [delta.oldValue, delta.diff] : delta.diff
            }
          })
          .filter(delta => {
            return new Set(delta.diff).size === delta.diff.length;
          })
        deltaArray = deltaArray.concat(minDeltaArr);
      }
    }

    if (itemConfig) {
      const itemConfigArr = this.setItemConfigDelta(itemConfig[0], targetInstance.config, utils);
      deltaArray = deltaArray.concat(itemConfigArr);
    }

    return deltaArray;
  }

  setItemConfigDelta = (delta: Delta, oldValue: Delta, utils: IAdminHistoryUtils) => {
    let newConfig = {} as Delta;
    let oldConfig = {} as Delta;

    let deltaArray = [] as ICommentDeltaFields[];

    if (JSON.stringify(delta) !== JSON.stringify(oldValue)) {
      Object.keys(delta).forEach(key => {
        if (JSON.stringify(delta[key]) !== JSON.stringify(oldValue[key])) {
          if (key === 'max') {
            const formatted = this.formatDelta(delta[key], oldValue[key]);
            const newDelta = this.setDeltaConfig(formatted as IDatePropertiesMinMax, 'Max', utils);
            const oldDelta = this.setDeltaConfig(oldValue[key] || {}, 'Max', utils);
            newConfig = { ...newConfig, ...newDelta };
            oldConfig = { ...oldConfig, ...oldDelta };
          }
          if (key === 'min') {
            const formatted = this.formatDelta(delta[key], oldValue[key]);
            const newDelta = this.setDeltaConfig(formatted as IDatePropertiesMinMax, 'Min', utils);
            const oldDelta = this.setDeltaConfig(oldValue[key] || {}, 'Min', utils);
            newConfig = { ...newConfig, ...newDelta };
            oldConfig = { ...oldConfig, ...oldDelta };
          }
        }
      });
    }
    if (Object.keys(newConfig).length) {
      const configChange = FieldConfigChange.format(newConfig, oldConfig, oldValue);
      const deltaConfig = configChange.map(delta => {
        return {
          ...delta,
          diff: [delta.oldValue, delta.diff]
        }
      });
      deltaArray = deltaArray.concat(deltaConfig);
    }

    return deltaArray;
  }

  formatDelta = (delta: Delta, oldDelta: Delta) => {
    const newDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (typeof delta[key] === 'object') {
        if (JSON.stringify(delta[key] !== JSON.stringify(oldDelta[key]))) {
          newDelta[key] = this.formatDelta(delta[key], oldDelta[key]);
        }
      } else {
        if (delta[key] !== oldDelta[key]) {
          newDelta[key] = delta[key];
        }
      }
    });
    return newDelta;
  }

  setDeltaConfig = (config: IDatePropertiesMinMax, parentConfig: 'Min' | 'Max', utils: IAdminHistoryUtils) => {

    if (Array.isArray(config)) {
      config = config[0];
    }

    const { value } = config;
    let newDelta = {} as Delta;

    if (config && Object.keys(config).length > 0) {
      if (value) {
        const { operator, right, left } = value;
        if (operator) {
          const operatorValue = typeof operator === 'object' ? operator[1] : operator;
          newDelta[`${parentConfig} Date: Operator`] = formulaToOperator[operatorValue];
        }
        if (left) {
          if (typeof left === 'string') {
            newDelta[`${parentConfig} Date: Source`] = left;
          } else {
            const leftValue = Array.isArray(left) ? left[1] : left
            const { fieldId } = leftValue;
            if (fieldId && utils && utils.fields) {
              const fieldData = utils.fields.find(e => e.id === fieldId);
              if (fieldData) {
                newDelta[`${parentConfig} Date: Source`] = fieldData.label;

              } else {
                newDelta[`${parentConfig} Date: Source`] = `(Not Found): ${fieldId}`;
              }
            }
          }
        }
        if (right) {
          const rightValue = Array.isArray(right) ? right[1] : right;
          const { amount, unit } = rightValue;
          if (amount !== null) {
            const amountValue = typeof amount === 'object' ? amount[1] : amount;
            newDelta[`${parentConfig} Date: Value`] = amountValue;
          }
          if (unit) {
            const unitValue = typeof unit === 'object' ? unit[1] : unit;
            newDelta[`${parentConfig} Date: Unit`] = unitValue;
          }
        }
      }
    }

    return newDelta;
  }

  setDatePropertiesDelta = (delta: Delta, oldDelta: Delta) => {
    let deltaArray = [] as ICommentDeltaFields[];

    Object.keys(delta).forEach(key => {
      deltaArray.push({
        id: key,
        label: key,
        type: 'string',
        diff: [oldDelta[key], delta[key]],
        oldValue: oldDelta[key],
        alwaysShowLabel: true
      });
    });

    return deltaArray;
  }
}
import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import ChecklistType from '../type/enum/checklist'
import ChecklistMultiselectType from '../type/enum/checklist-multiselect'
export interface IDropdownOption {
  key: string;
  text: string;
  value: string;
  deleted?: boolean;
}

const ChecklistElement: React.FC<AcceptedProps> = (props) => {
  if (props.inputConfig?.config?.multiselect) {
    return <ChecklistMultiselectType {...props} />
  } else {
    return <ChecklistType {...props} />
  }
}

export default React.memo(ChecklistElement);
import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { fieldIconBaseOnConfigName } from '../../../../configuration/module/document-list/constant';
import { ITableMobileViewContainer } from '../../../interface/table-view';
import ContentUpperHeader from './header';
import ContentUpperWidgetBig from './widget-big';
import ContentUpperWidgetSmall from './widget-small';
import { IOption } from '../../../../../../../../../common/interface/option';

interface IContentUpper {
  tableMobileViewList: ITableMobileViewContainer;
  updateTableMobileView: (property: string, value: any) => void;
  columns: ICustomFieldDetail[];
  additionalFields: string[];
}

const MobileSectionContentUpper: React.FC<IContentUpper> = (props) => {

  const getColumnOption = (showIcon: boolean, childKeyProp: string, index: string): IOption[] => {
    let tempColumns = [...props.columns];
    const fieldsSelected = [] as string[];
    const tempTableMobileView = { ...props.tableMobileViewList };
    Object.keys(tempTableMobileView).forEach(key => {
      const tempMobileView: any = { ...tempTableMobileView[key] };
      if (tempMobileView && childKeyProp) {
        Object.keys(tempMobileView).forEach(childKey => {
          const isCurrentField = `${childKeyProp}_${index}` === `${childKey}_${key}`;
          const fieldId = tempMobileView[childKey];
          if (fieldId && !isCurrentField) {
            const exists = fieldsSelected.find(id => id === fieldId);
            if (!exists) {
              fieldsSelected.push(fieldId);
            }
          }
        });
      }
    });

    return tempColumns
      .filter(field => fieldsSelected.indexOf(field.id || '') === -1)
      .filter(field => props.additionalFields.indexOf(field.id || '') === -1)
      .map((field: ICustomFieldDetail, index: number) => {
        let fieldIcon: any = {};
        if (field && field.configName) {
          fieldIcon = fieldIconBaseOnConfigName[field.configName];
        }
        return {
          key: index,
          value: field.id || '',
          text: field.label || '',
          image: showIcon ? { src: fieldIcon.icon, style: fieldIcon.dropdownStyle } : undefined,
          disabled: fieldsSelected.indexOf(field.id || '') > -1
        }
      })
  }

  return <div className='upper-widget-content'>
    <div className='upper-widget input-header'>
      <ContentUpperHeader
        tableMobileView={props.tableMobileViewList['0']}
        updateTableMobileView={props.updateTableMobileView}
        index='0'
      />
      <ContentUpperHeader
        tableMobileView={props.tableMobileViewList['1']}
        updateTableMobileView={props.updateTableMobileView}
        index='1'
      />
      <ContentUpperHeader
        tableMobileView={props.tableMobileViewList['2']}
        updateTableMobileView={props.updateTableMobileView}
        index='2'
      />
    </div>
    <div className='upper-widget field-widget-big'>
      <ContentUpperWidgetBig
        columnOptions={getColumnOption(false, 'upperField', '0')}
        tableMobileView={props.tableMobileViewList['0']}
        updateTableMobileView={props.updateTableMobileView}
        index='0'
        columns={props.columns}
      />
      <ContentUpperWidgetBig
        columnOptions={getColumnOption(false, 'upperField', '1')}
        tableMobileView={props.tableMobileViewList['1']}
        updateTableMobileView={props.updateTableMobileView}
        index='1'
        columns={props.columns}
      />
      <ContentUpperWidgetBig
        columnOptions={getColumnOption(false, 'upperField', '2')}
        tableMobileView={props.tableMobileViewList['2']}
        updateTableMobileView={props.updateTableMobileView}
        index='2'
        columns={props.columns}
      />
    </div>
    <div className='upper-widget input-footer'>
      <ContentUpperWidgetSmall
        columnOptions={getColumnOption(true, 'lowerField', '0')}
        tableMobileView={props.tableMobileViewList['0']}
        updateTableMobileView={props.updateTableMobileView}
        index='0'
        columns={props.columns}
      />
      <ContentUpperWidgetSmall
        columnOptions={getColumnOption(true, 'lowerField', '1')}
        tableMobileView={props.tableMobileViewList['1']}
        updateTableMobileView={props.updateTableMobileView}
        index='1'
        columns={props.columns}
      />
      <ContentUpperWidgetSmall
        columnOptions={getColumnOption(true, 'lowerField', '2')}
        tableMobileView={props.tableMobileViewList['2']}
        updateTableMobileView={props.updateTableMobileView}
        index='2'
        columns={props.columns}
      />
    </div>
  </div>
}

export default MobileSectionContentUpper;

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { IRootState } from '../../../../../../reducers';
import AutomationModule from './module';
import { automationSubModule } from './constant';
import { ConfigurationSubMenuButton } from './sub-menu';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
  thirdLevelRoute: string;
  formId: string;
}

interface IConfigurationRouteProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const ConfigurationRoute: React.FC<IConfigurationRouteProps> = (props) => {
  const history = useHistory();
  const match: IRouteMatch = useRouteMatch();
  const data = useSelector((state: IRootState) => state.main);
  return (
    <>
      <Route path={`${match.path}/:formId/:fourthLevelRoute`} >
        <div className="ui segment automation-module-container">
          <ConfigurationSubMenuButton.Render
            type={data.thirdLevelRoute}
            modules={automationSubModule}
            history={history}
            main={data}
            hasPageChangeRef={props.hasPageChangeRef}
          />
        </div>
        <AutomationModule hasPageChangeRef={props.hasPageChangeRef} />
      </Route>
    </>
  );
}

export default ConfigurationRoute;

import { AcceptedProps } from './object/interface/accepted-props';
import StringType from './type/string';
import NumberType from './type/number';
import DateType from './type/date';
import FileType from './type/file';
import EnumType from './type/enum';
import ArrayType from './type/array';

export const elementComponentList: { [name: string]: React.FC<AcceptedProps> } = {
  string: StringType,
  number: NumberType,
  date: DateType,
  file: FileType,
  enum: EnumType,
  array: ArrayType
};
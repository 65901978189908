import React, { useEffect, useState } from 'react';
import { Table, Dimmer, Loader, Image } from 'semantic-ui-react-bpm';
import TableHeader from './table-header';
import TableList from './table-list';
import { useDispatch, useSelector } from 'react-redux';
import {
  publishRoleAction,
  saveFormRoleAction,
  deleteRoleAction,
} from '../action';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { IRootState } from '../../../../../../../reducers';
import { IOption } from '../../../../../../../common/interface/option';
import { IUser } from '../../../../users/interface/user';
import { getFieldId } from '../../../../../../../common/utils/get-field';
import { IFormRole } from '../interface/form-role';
import { v4 as uuid } from 'uuid';
import RoleActionModal from './action-modal';
import { IFormStatusWithAction } from '../../workflow/interface/form-status';
import { IFormAction } from '../../workflow/interface/form-action';
import ButtonContainer from '../../../button-container';
import { ConfirmationMessage } from '../../../../../../../common/general/confirmation-modal';
import ActionMessage from '../../../../../../../common/general/action-message';
import { constraintPallet, rolesActionMessage } from '../constant';
import changeRoute from '../../../../../../../common/utils/change-menu';
import { icons } from '../../../../../../../common/icons';
import ToolTip from '../../../../../../../common/general/tooltip';
import ConstraintModal from './constraint-modal';
import { IConstraint, IStatusConstraint } from '../interface/constraint';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';
import { Group } from '../../../../groups/interface/group';
import { useGetRoleTableData } from './hooks/use-get-role-table-data';

interface IRoleTableProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const RoleTable: React.FC<IRoleTableProps> = (props) => {
  const match: { params: { formId: string } } = useRouteMatch()
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = useSelector((state: IRootState) => state.auth.company);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const [selectedRoleStatus, setSelectedRoleStatus] = useState({ status: undefined, role: undefined } as { status: IFormStatusWithAction | undefined, role: IFormRole | undefined })

  const [isOpenConstraint, setIsOpenConstraint] = useState(false);
  const [constraintList, setConstraintList] = useState<IConstraint[]>([]);
  const defaultRoleStatusConstraint = { roleId: '', statusId: '' }
  const [selectedRoleStatusConstraint, setSelectedRoleStatusConstraint] = useState(defaultRoleStatusConstraint);
  const [modifyConstraint, setModifyConstraint] = useState<IConstraint | undefined>(undefined);
  const [showConstraintDeleteConfirmation, setShowConstraintDeleteConfirmation] = useState(false);

  const [currentRoleList, setCurrentRoleList] = useState([] as IFormRole[])
  const [currentStatusList, setcurrentStatusList] = useState([] as IFormStatusWithAction[])
  const [selectedRoleForDetetion, setSelectedRoleForDetetion] = useState({ id: '', name: '' } as IFormRole);
  const {
    statusList,
    groupList,
    userList,
    userFieldList,
    roleList,
    getStatusListLoading,
    getUserListLoading,
    getGroupListLoading,
    getUserFieldListLoading,
    formRoleSaveLoading,
    getRoleListLoading,
    saveRoleType,
    userCustomField,
    constraintTemplateList,
    formFieldList,
  } = useSelector((state: IRootState) => state.role);

  const addRoleList = (value: string) => {
    let [type, id, name, active] = value.split('|||||');
    let newRoleList = [...currentRoleList];
    newRoleList.push({
      id: uuid(),
      type,
      linkedId: id,
      name,
      actions: [],
      new: true,
      active: active === 'false' ? false : undefined
    });
    setCurrentRoleList(newRoleList);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const updateConstraint = (constraint: IConstraint) => {
    const tempConstraintList = [...constraintList]
    const checkConstraintExist = tempConstraintList.filter((e: IConstraint) => e.style && constraint.style && e.style.constraintId === constraint.style.constraintId)
    if (checkConstraintExist.length > 0) {
      const constraintIndex = tempConstraintList.indexOf(checkConstraintExist[0]);
      tempConstraintList.splice(constraintIndex, 1, constraint);
    } else {
      tempConstraintList.push(constraint);
    }
    setConstraintList(updateConstraintColor(tempConstraintList));
    setModifyConstraint(undefined);
  }

  const setRoleAction = (status: IFormStatusWithAction, role: IFormRole) => {
    setSelectedRoleStatus({ status, role })
    setRoleActionModal(role.id, status.id)
    setSelectedRoleStatusConstraint({ roleId: role.id, statusId: status.id })
    props.hasPageChangeRef.current.hasChange = true;
  }

  const getOptionList = (): IOption[] => {
    let options = [] as IOption[];
    let ctr = 0;
    groupList.forEach((group: Group) => {
      const hasRoleList = currentRoleList.filter((role: IFormRole) => role.type === 'group' && role.linkedId === group.id && role.deleted !== true);
      if (hasRoleList.length === 0) {
        options.push({
          key: ctr++,
          value: `group|||||${group.id}|||||${group.name}`,
          text: `Group - ${group.name}`
        } as IOption)
      }
    });
    userList.forEach((user: IUser) => {
      const hasRoleList = currentRoleList.filter((role: IFormRole) => role.type === 'user' && role.linkedId === user.id && role.deleted !== true);
      if (hasRoleList.length === 0) {
        const firstname = user.fields ? user.fields[getFieldId(userFieldList, 'First Name')] || '' : '';
        const lastname = user.fields ? user.fields[getFieldId(userFieldList, 'Last Name')] || '' : '';
        options.push({
          key: ctr++,
          value: `user|||||${user.id}|||||${(`${firstname} ${lastname}`).trim()}|||||${user.active}`,
          text: `User - ${firstname} ${lastname}`
        } as IOption)
      }
    });
    return options;
  }

  const updateRoleList = (roleId: string, statusId: string, actions: IFormAction[]) => {
    let updatedRoleList = [...currentRoleList];
    updatedRoleList = updatedRoleList.map((role: IFormRole) => {
      if (roleId === role.id) {
        let newAction = role.actions.filter((action: IFormAction) => action.statusId !== statusId);
        role.actions = [...newAction, ...actions];
      }
      return role;
    });
    setCurrentRoleList(updatedRoleList);
    setSelectedRoleStatus({ status: undefined, role: undefined });
    setSelectedRoleStatusConstraint(defaultRoleStatusConstraint)
    props.hasPageChangeRef.current.hasChange = true;
  }

  const closeDeleteRole = () => {
    setSelectedRoleForDetetion({ id: '' } as IFormRole);
  }


  const deleteRole = (forDeleteRole: IFormRole) => {
    setSelectedRoleForDetetion(forDeleteRole);
  }

  const confirmDeleteRole = () => {
    let updateRoleList = [...currentRoleList];
    updateRoleList = updateRoleList.map((role: IFormRole) => {
      if (selectedRoleForDetetion.id === role.id) {
        role.deleted = true;
      }
      return role;
    });
    setCurrentRoleList(updateRoleList);
    setSelectedRoleForDetetion({ id: '' } as IFormRole);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const setRoleActionModal = (roleId: string, statusId: string) => {
    const element: HTMLElement | null = document.getElementById(`table-role-detail-${roleId}-${statusId}`);
    const element2: HTMLElement | null = document.getElementById('role-action-modal');
    const dottedElement = element?.getBoundingClientRect();
    if (element2) {
      document.body.appendChild(element2);
      element2.style.left = `calc(${dottedElement?.left}px - 180px)`;
      element2.style.top = `calc(${dottedElement?.top}px + ${window.scrollY}px - 10px)`;
      element2.style.position = `absolute`;
    }
  }

  const setConstraintModalPosition = (formId: string) => {
    const element: HTMLElement | null = document.getElementById(`role-constraint-${formId}`);
    const element2: HTMLElement | null = document.getElementById('constraint-modal');
    const dottedElement = element?.getBoundingClientRect();

    if (element2) {
      document.body.appendChild(element2);
      element2.style.left = `calc(${dottedElement?.left}px + 20px)`;
      element2.style.top = `calc(${dottedElement?.top}px + ${window.scrollY}px)`;
      element2.style.position = `absolute`;
    }
  }

  const openCustomConstraintModal = (formId: string) => {
    setIsOpenConstraint(true)
    setConstraintModalPosition(formId);
  }

  const addStatusConstraint = (constraint: IConstraint) => {
    const tempCurrentRoleList = currentRoleList.map((role: IFormRole) => {
      const tempRole = { ...role };
      if (selectedRoleStatusConstraint.roleId === role.id) {
        if (tempRole.constraintsPerStatus && tempRole.constraintsPerStatus.length > 0) {
          const tempStatusConstraint = [...tempRole.constraintsPerStatus];
          let getStatusConstraint = tempStatusConstraint.filter((statusConstraint: IStatusConstraint) =>
            statusConstraint.statusId === selectedRoleStatusConstraint.statusId
          );
          if (getStatusConstraint.length > 0) {
            const tempConstraintList = [...getStatusConstraint[0].constraints || []];

            if (constraint.type === 'owner') {
              const filterCreatorType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'creator');
              if (filterCreatorType.length > 0) {
                const creatorTypeindex = tempConstraintList.indexOf(filterCreatorType[0]);
                tempConstraintList.splice(creatorTypeindex, 1);
              }
              const filterOwnerType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'owner');
              if (filterOwnerType.length === 0) {
                tempConstraintList.push(constraint);
              }
            }
            if (constraint.type === 'creator') {
              const filterOwnerType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'owner')
              if (filterOwnerType.length > 0) {
                const ownerTypeindex = tempConstraintList.indexOf(filterOwnerType[0]);
                tempConstraintList.splice(ownerTypeindex, 1);
              }
              const filterCreatorType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'creator');
              if (filterCreatorType.length === 0) {
                tempConstraintList.push(constraint);
              }
            }
            if (constraint.type === 'public') {
              const filterCreatorType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'creator');
              if (filterCreatorType.length > 0) {
                const creatorTypeindex = tempConstraintList.indexOf(filterCreatorType[0]);
                tempConstraintList.splice(creatorTypeindex, 1);
              }
              const filterOwnerType = tempConstraintList.filter((constraint: IConstraint) => constraint.type === 'owner');
              if (filterOwnerType.length > 0) {
                const ownerTypeindex = tempConstraintList.indexOf(filterOwnerType[0]);
                tempConstraintList.splice(ownerTypeindex, 1);
              }
            }
            if (constraint.type === 'field-compare') {
              let checkFieldCompareExist = tempConstraintList.filter((tempConstraint: IConstraint) =>
                tempConstraint.type === 'field-compare'
                && tempConstraint.style.constraintId === constraint.style.constraintId
              )

              if (checkFieldCompareExist.length > 0) {
                const fieldCompareTypeIndex = tempConstraintList.indexOf(checkFieldCompareExist[0]);
                tempConstraintList.splice(fieldCompareTypeIndex, 1);
              } else {
                tempConstraintList.push(constraint);
              }
            }
            const constraintStatusIndex = tempRole.constraintsPerStatus.indexOf(getStatusConstraint[0]);
            tempRole.constraintsPerStatus.splice(constraintStatusIndex, 1, {
              statusId: selectedRoleStatusConstraint.statusId,
              constraints: tempConstraintList
            });
          } else {
            tempRole.constraintsPerStatus.push({
              statusId: selectedRoleStatusConstraint.statusId,
              constraints: [constraint]
            })
          }
        } else {
          tempRole.constraintsPerStatus = [{
            statusId: selectedRoleStatusConstraint.statusId,
            constraints: [constraint]
          }];
        }
      }
      return tempRole;
    })
    setCurrentRoleList(tempCurrentRoleList);
  }

  const isConstraintAlreadyInUsed = (constraint: IConstraint) => {
    let status = false;
    currentRoleList.forEach((role: IFormRole) => {
      if (role.constraintsPerStatus && role.constraintsPerStatus.length > 0) {
        role.constraintsPerStatus.forEach((statusConstraint: IStatusConstraint) => {
          const checkIfExist = statusConstraint.constraints.filter((constraint: IConstraint) =>
            modifyConstraint
            && constraint.style
            && modifyConstraint.style.constraintId === constraint.style.constraintId
          )
          if (checkIfExist.length > 0) {
            status = true;
          }
        });
      }
    });

    return status;
  }

  const confirmDeleteConstraintAndPerStatus = () => {
    const tempRoleList = currentRoleList.map((role: IFormRole) => {
      const tempRole = { ...role };
      if (tempRole.constraintsPerStatus && tempRole.constraintsPerStatus.length > 0) {
        const tempStatusConstraint = tempRole.constraintsPerStatus.map((statusConstraint: IStatusConstraint) => {
          const tempConstraint = { ...statusConstraint };
          tempConstraint.constraints = tempConstraint.constraints.filter((constraint: IConstraint) =>
            modifyConstraint
            && constraint.style
            && modifyConstraint.style.constraintId !== constraint.style.constraintId
          )
          return tempConstraint;
        });
        tempRole.constraintsPerStatus = tempStatusConstraint;
      }
      return tempRole;
    });
    setCurrentRoleList(tempRoleList);
    confirmDelete();
    setShowConstraintDeleteConfirmation(false);
  }

  const deleteConstraint = () => {
    if (modifyConstraint && isConstraintAlreadyInUsed({ ...modifyConstraint })) {
      setShowConstraintDeleteConfirmation(true);
    } else {
      confirmDelete();
    }
  }

  const confirmDelete = () => {
    const tempConstraintList = [...constraintList];
    if (modifyConstraint) {
      const constraintIndex = tempConstraintList.indexOf(modifyConstraint);
      tempConstraintList.splice(constraintIndex, 1);
    }
    setConstraintList(updateConstraintColor(tempConstraintList));
    setModifyConstraint(undefined);
  }

  const closeCustomConstraintModal = () => {
    setIsOpenConstraint(false)
    props.hasPageChangeRef.current.hasChange = true;
  }

  const saveRole = (saveType: string) => {
    const forDeletionRole = currentRoleList.filter((role: IFormRole) => role.deleted === true && role.new !== true);

    dispatch(saveFormRoleAction({
      saveType: saveType,
      roleList: currentRoleList,
      companyId: companyId,
      formId: match.params.formId,
      constraint: constraintList
    }));

    if (forDeletionRole.length > 0) {
      forDeletionRole.forEach((role: IFormRole) => {
        dispatch(deleteRoleAction({
          roleId: role.id,
          companyId: companyId,
          formId: match.params.formId
        }))
      })
    }
    props.hasPageChangeRef.current.hasChange = false;
  }

  const showLoading = (): boolean => {
    return ((getStatusListLoading
      && getUserListLoading
      && getGroupListLoading
      && getUserFieldListLoading
      && getRoleListLoading
    ) || formRoleSaveLoading)
  }

  const updateConstraintColor = (list: IConstraint[]): IConstraint[] => {
    let ctr = 0;
    return [...list.map((e: IConstraint) => {
      if (ctr === 8) {
        ctr = 0;
      }
      const f = { ...e };
      f.style = { ...f.style };
      f.style.constraintColor = constraintPallet[ctr++];
      return f;
    })];
  }

  useGetRoleTableData({
    companyId,
    dispatch,
    formId: match.params.formId
  });

  useEffect(() => {
    if (saveRoleType === 'publish') {
      dispatch(publishRoleAction({ company: companyId, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRoleType]);

  useEffect(() => {
    setcurrentStatusList(statusList)
  }, [statusList])

  useEffect(() => {
    setCurrentRoleList(roleList);
  }, [roleList])

  useEffect(() => {
    const tempConstraintTemplateList = updateConstraintColor(constraintTemplateList);
    setConstraintList(tempConstraintTemplateList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintTemplateList])

  return (<>
    <ToolTip
      popupMessage='Close'
      popupTriggerComponent={
        <Image id='business-process-close-icon' src={icons.blue.closeCircle} onClick={() => {
          changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
        }} />
      }
      position='right center'
    />
    {
      showLoading() &&
      <Dimmer active inverted>
        <Loader active content='Loading' />
      </Dimmer>
    }
    <div style={{ marginTop: '15px', marginLeft: '5px', marginRight: '5px' }}>
      <ActionMessage messages={rolesActionMessage} />
    </div>
    <div className='role-main-container'>
      <div className='role-table-container'>
        <div className='role-table-parent-container'>
          <table className='role-table'>
            <Table.Header >
              <TableHeader
                statusList={currentStatusList}
                openCustomConstraintModal={openCustomConstraintModal}
                formId={match.params.formId}
                constraintList={constraintList}
              />
            </Table.Header>
            <TableList
              statusList={currentStatusList}
              currentRoleList={currentRoleList}
              optionList={getOptionList()}
              addRoleList={addRoleList}
              setRoleAction={setRoleAction}
              deleteRole={deleteRole}
              constraintList={constraintList}
              groupList={groupList}
            />
          </table>
        </div>
        <ButtonContainer
          cancel={() => {
            changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
          }}
          save={() => saveRole('draft')}
          publish={() => saveRole('publish')}
        />
        <RoleActionModal
          status={selectedRoleStatus.status}
          role={selectedRoleStatus.role}
          isHiddenContainer={!(selectedRoleStatus.status && selectedRoleStatus.role)}
          updateRoleList={updateRoleList}
          addStatusConstraint={addStatusConstraint}
          currentRoleList={currentRoleList}
          selectedRoleStatusConstraint={selectedRoleStatusConstraint}
          constraintList={constraintList}
          setSelectedRoleStatus={setSelectedRoleStatus}
        />
        <ConstraintModal
          isOpenModal={isOpenConstraint}
          closeCustomConstraintModal={closeCustomConstraintModal}
          userFieldList={userFieldList}
          constraintList={constraintList}
          updateConstraint={updateConstraint}
          userCustomField={userCustomField}
          modifyConstraint={modifyConstraint}
          setModifyConstraint={setModifyConstraint}
          deleteConstraint={deleteConstraint}
          formFieldList={formFieldList}
        />
        <ConfirmationMessage
          clearErrorMessage={() => { }}
          errorMessage={''}
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeDeleteRole}
          open={selectedRoleForDetetion.id !== ''}
          confirm={confirmDeleteRole}
          cancel={closeDeleteRole}
          headerText="Confirmation"
          detailText={`The role '${selectedRoleForDetetion.name || ''}' will be deleted. Do you still want to proceed?`}
          loading={false}
          type='warning'
        />
        <ConfirmationMessage
          clearErrorMessage={() => { }}
          errorMessage={''}
          confirmButtonName={'Delete'}
          confirmButtonColor={'red'}
          cancelButtonColor={'grey'}
          close={() => setShowConstraintDeleteConfirmation(false)}
          open={showConstraintDeleteConfirmation}
          confirm={confirmDeleteConstraintAndPerStatus}
          cancel={() => setShowConstraintDeleteConfirmation(false)}
          headerText="Confirmation"
          detailText={`Deleting this constraint will delete all constraint set in status. Do you still want to proceed.`}
          loading={false}
          type='warning'
        />
      </div>
    </div>
  </>)
}

export default RoleTable;
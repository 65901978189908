import React from 'react';
import { v4 as uuid } from 'uuid';
import { IChatFilterProps } from '.';
import { IComment } from '../../interface/comment';
import { ISenderDetail, ISenderInfo } from '../../interface/sender-info';
import CommentWidgetAvatar from '../widget/utils/avatar';

interface IChatFilterUsersProps extends IChatFilterProps {
  addToErrorImage: (userId: string) => void;
  errorImage: string[];
}

const ChatFilterUsers: React.FC<IChatFilterUsersProps> = (props) => {
  return <>
    {props.userList.map((user: ISenderInfo, index: number) => {
      const senderUserInfo = { ...user.sender.data } as ISenderDetail;
      if (index < 5 && user && senderUserInfo && senderUserInfo.id) {
        const selected = props.selectedUser.indexOf(senderUserInfo.id) > -1;

        return <CommentWidgetAvatar
          key={uuid()}
          selectUser={props.selectUser}
          addToErrorImage={props.addToErrorImage}
          comment={{ sender: user.sender } as IComment}
          avatarClassName={`user-icon ${selected ? 'selected' : ''}`}
          errorImage={props.errorImage}
          userColor={props.userColor}
          userIconClassName={`user-name-icon ${selected ? 'selected' : ''}`}
        />
      }
      return null;
    })}
  </>
}

export default ChatFilterUsers;
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Time,
  required: false,
  type: 'time',
};

interface IDefaultConfigObject {
}

const defaultConfig: IDefaultConfigObject = {

};

const value = {
  title: 'Time',
  icon: {
    black: require('../../icons/black/clock.png'),
    white: require('../../icons/white/clock.png'),
    blue: require('../../icons/blue/clock.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
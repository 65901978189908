import { useEffect, useState } from 'react';
import { getUserFieldListAction } from '../../action';
import { message } from '../../message';

interface IProps {
  dispatch: any;
  companyId: string;
  deleteUserFieldMessage: string;
}

export const useSetDeleteModalOpen = (props: IProps) => {
  const { companyId, deleteUserFieldMessage, dispatch } = { ...props };

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (deleteUserFieldMessage === message.deleteUserFieldSuccess) {
      dispatch(getUserFieldListAction({ company: companyId }));
      setIsOpenDeleteModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, deleteUserFieldMessage]);

  return { isOpenDeleteModal, setIsOpenDeleteModal, ...props };
}
import { v4 as uuid } from 'uuid';
import { IField, IInputConfig, IOptionValue } from '../../../../../../component/admin-module/module/users/interface/field';
import { AutomationService } from '../../../../../../component/document-module/module/form/automation/automation-service';
import { FieldProperties } from '../../../service/field-properties.service';
import { AcceptedProps } from '../../../object/interface/accepted-props';
import { IDropdownOption } from '../../../object/type/enum/checklist';
import { EnumProps } from '../../../service/type/enum';
import { EnumMultiselectProps } from '../../../service/type/enum-multiselect';

interface IProptypes {
  automationService?: AutomationService;
  fieldCollection?: IField[];
  getOption(props: AcceptedProps): IOptionValue[];
  removeDeletedItemInOptions(componentOption: any, value: any): IDropdownOption[];
  currentProps: AcceptedProps;
  value: any;
  elementProperties: FieldProperties<EnumProps> | FieldProperties<EnumMultiselectProps>;
}

export const triggerAutomationUpdateField = ({
  automationService,
  fieldCollection,
  getOption,
  removeDeletedItemInOptions,
  currentProps,
  value,
  elementProperties
}: IProptypes) => {
  if (automationService && fieldCollection) {
    let componentOption = getOption(currentProps).map((e: IOptionValue): IDropdownOption => { return { key: uuid(), text: e.label, value: e.id, deleted: e.deleted || undefined } });
    if (elementProperties.current.filteredOption) {
      componentOption = elementProperties.current.filteredOption.map((e: IOptionValue): IDropdownOption => { return { key: uuid(), text: e.label, value: e.id, deleted: e.deleted || undefined } });
    }
    const options = removeDeletedItemInOptions(componentOption, value);
    const items = options.map((e: IDropdownOption) => { return { id: e.value, label: e.text, deleted: e.deleted || false } });
    const field = fieldCollection.find((e: IField) => e.id === currentProps.id);
    if (field) {
      const newField = { ...field };
      if (newField.inputConfig === undefined) {
        newField.inputConfig = { config: { items: items } } as IInputConfig;
      } else {
        newField.inputConfig.config.items = items;
      }
      automationService.didUpdateFieldConfig(field);
    }
  }
}
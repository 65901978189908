import { FieldValidator } from './field-validator';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { IField, IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
import { errorMessages, errorMessagesTable } from '../error';
import ValidateField from '..';
import { IValidationError } from './validation-type';
export class TableValidator extends FieldValidator {

  name = 'table';

  validate(field: FieldElement, value: any, actionType?: string) {
    const isAllNull = typeof value === 'object' && Object.keys(value).every(key => {
      return value[key].every((e: any) => (e === 'null' || e === '[]' || e === null || e === '' || e === false));
    });

    const hasRequiredColumn = field.inputConfig.config.columns.filter((e: IField) => e.accessType === IFieldAccessType.Required);

    if (((isAllNull || !value || (typeof value === 'object' && !Object.keys(value).length) || value === '' || value === 'undefined')
      && ((field.accessType !== IFieldAccessType.Required && hasRequiredColumn.length === 0) || (hasRequiredColumn.length > 0 && actionType === 'save')))
      || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden) {
      return [];
    }

    const ajv = new Ajv();
    let schema = { ...field.validationSchema } as any;

    const { columns, minRows, maxRows } = field.inputConfig.config;
    const validateField = new ValidateField();
    const fieldsToValidate = validateField.fieldsToValidate(columns);
    const tableFields = FieldElement.getDataElement(fieldsToValidate);
    const errors: IValidationError[] = [];
    const fixedTable = minRows === maxRows;
    const rowCount = this.tableFieldDataRowsCount(value);
    const requiredFields = validateField.requiredFields(columns);

    tableFields.forEach(field => {
      const fieldValue = value[field.id || ''];
      if (fieldValue && fieldValue instanceof Array) {
        let tableRowCount = rowCount;

        if (fixedTable) {
          tableRowCount = minRows;
        } else {
          if (rowCount === 0 && minRows > 0) {
            tableRowCount = minRows;
          }
          if (rowCount > 0 && rowCount < minRows) {
            tableRowCount = rowCount + (minRows - rowCount);
          }
        }

        for (let i = 0; i < tableRowCount; i++) {
          const tableValue = fieldValue[i];
          let errorMessage = validateField.singleField(field, tableValue ? tableValue : '', actionType);
          if (errorMessage && errorMessage.length > 0 && errorMessage[0]) {
            const tempMessage = `Error: ${field.label} (Row: ${i + 1}) - ${errorMessage[0].message}`;
            errors.push({ ...errorMessage[0], message: tempMessage });
          }
        }
      }
    });

    if (!errors.length && requiredFields.length > 0 && rowCount === 0) {
      requiredFields.forEach(fields => {
        errors.push({
          keyword: '',
          dataPath: '',
          schemaPath: '',
          params: '',
          message: `Error: ${field.label} - please provide value for <b>${fields.label}</b>`
        });
      });
    }

    if (!errors.length && requiredFields.length > 0 && rowCount > 0) {
      requiredFields.forEach(fields => {
        const nullRows = value[fields.id || ''].map((e: any, i: number) => {
          if (!e || e === null || e === '') {
            return i;
          }
          return null;
        }).filter((e: any) => typeof e === 'number');
        for (let i = 0; i < nullRows.length; i++) {
          const row = nullRows[i];
          const tempMessage = `Error: ${field.label} (Row: ${row + 1}) - please provide value for <b>${fields.label}</b>`;
          errors.push({
            keyword: '',
            dataPath: '',
            schemaPath: '',
            params: '',
            message: tempMessage
          });
        }
      });
    }

    // This is not working anymore but don't remove cause maybe use in the future

    field.inputConfig.config.columns.forEach((tableField: IField) => {
      const id = tableField.id || '';
      if (!value[id]) {
        value[id] = [];
      }
      if (tableField.inputConfig && tableField.inputConfig.type === 'number') {
        value[id] = value[id].map((fieldValue: string) => {
          return (fieldValue) ? parseFloat(fieldValue.toString().replace(/,/g, '')) : null;
        })
      } else if (tableField.inputConfig && tableField.inputConfig.type === 'bool') {
        value[id] = value[id].map((fieldValue: string) => {
          return (fieldValue === 'true') ? true : false;
        })
      } else if (tableField.inputConfig && tableField.inputConfig.type === 'enum') {
        if (tableField.inputConfig.config.multiselect) {
          value[id] = value[id].map((fieldValue: string) => {
            return (fieldValue && fieldValue.length > 0) ? fieldValue : null;
          })
        }
      } else {
        value[id] = value[id].map((fieldValue: string) => {
          return (fieldValue) ? fieldValue : null;
        })
      }
      if (field.accessType !== IFieldAccessType.Required && tableField.accessType === IFieldAccessType.Required && schema && schema.hasOwnProperty('anyOf')) {
        schema.minItems = 1;
        schema.isRequired = true;
        schema.type = 'object';
        if (schema.required === undefined) {
          schema.required = [];
        }
        schema.required.push(id);
        schema.anyOf = schema.anyOf.map((e: any) => {
          if (e.hasOwnProperty('properties')) {
            e.properties[id].minItems = 1;
          }
          return e;
        })
      }
    });
    if (field.accessType !== IFieldAccessType.Required && hasRequiredColumn.length) {
      let properties = {};
      schema.anyOf.forEach((e: any) => {
        if (e.type === 'object' && e.hasOwnProperty('properties')) {
          properties = e.properties;
        }
      })
      schema.properties = properties;
      delete schema.anyOf;
      value = Object.entries(value).reduce((acc: any, [key, val]: any) => {
        if (Array.isArray(val) && val.length > 0) {
          const isAllNull = val.every(e => (e === 'null' || e === false || e === null));
          acc[key] = isAllNull ? [] : val;
        }
        return acc;
      }, {});
    } else if (field.accessType === IFieldAccessType.Required) {
      schema = Object.entries(schema).reduce((acc: any, [key, val]: any) => {

        if (typeof val === 'object' && key === 'properties') {
          acc[key] = Object.entries({ ...val }).reduce((obj: any, [k, v]: any) => {
            obj[k] = { ...v, minItems: 1 };
            return obj;
          }, {})
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      value = Object.entries(value).reduce((acc: any, [key, val]: any) => {
        if (Array.isArray(val) && val.length > 0) {
          const isAllNull = val.every(e => (e === 'null' || e === false || e === null));
          acc[key] = isAllNull ? [] : val;
        }
        return acc;
      }, {});
    }
    const validationSchema = {
      properties: {
        [field.id]: {
          ...schema,
          minItems: field.accessType === IFieldAccessType.Required || hasRequiredColumn ? 1 : undefined,
          isRequired: field.accessType === IFieldAccessType.Required || hasRequiredColumn.length > 0,
          required: hasRequiredColumn.length > 0 ? hasRequiredColumn.map((e: IField) => { return e.id }) : undefined,
        }
      },
    };

    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: value });

    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return error.keyword === 'minItems'
          ? errorMessages(error, { ...field }, value)
          : errorMessagesTable(error, field);
      });
    }

    console.log(`Log ~ errors`, errors);
    return errors;
  }

  tableFieldDataRowsCount = (data: any): number => {
    if (!data) {
      return 0
    }
    const indexesWithValue = Object.keys(data).map(key => {
      const indexOfLastItemWithValue = data[key].reduce((acc: any, value: any, index: number) => (value ? index : acc), -1);
      return indexOfLastItemWithValue + 1;
    });
    return Math.max(...indexesWithValue);
  }
}
import { IutilProps } from '../..';
import { ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IReference } from '../../../../../../../configuration/module/bp-relationship/interface/form';
import { getDatatableInputFieldAction, getInputFieldAction } from '../../../../action';
import { IAutomationComponent } from '../../../../interface/component-list';

export class SendEmailUtils {
  static getOptions = (selectedComponent: IAutomationComponent, utils: IutilProps, type: string) => {
    let allEmailType = ['to', 'cc', 'attachments'];
    let fields = [];

    if (type === 'all') {
      const tempEmailCopy: any[] = [];
      allEmailType.forEach(key => {
        const emailConfig = selectedComponent.config[key];
        if (emailConfig && emailConfig instanceof Array && emailConfig.length > 0) {
          const emailCopyConfig = emailConfig.filter(e => e instanceof Object && e.hasOwnProperty('referencedKeyPath'));
          if (emailCopyConfig.length > 0) {
            emailCopyConfig.forEach(copy => {
              const exists = tempEmailCopy.find(e => e && e.hasOwnProperty('referencedKeyPath') && e.referencedKeyPath === copy.referencedKeyPath);
              if (!exists) {
                tempEmailCopy.push(copy);
              }
            });
          }
        }
      });
      fields = tempEmailCopy;
    } else {
      fields = selectedComponent?.config[type] || [];
    }

    fields.forEach((key: any) => {
      if (key && key.hasOwnProperty('referencedKeyPath')) {
        const sourceRefField = key.referencedKeyPath.replace('fields.', '');
        if (sourceRefField !== '') {
          let bpReference = '';
          const fieldConfig = utils.formFieldList.find((e: ICustomFieldDetail) => e.id === sourceRefField);
          if (fieldConfig) {
            bpReference = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
          }
          if (!bpReference) {
            return;
          }

          const selectedRef = utils.referenceList.find((ref: IReference) => ref.id === bpReference);
          if (selectedRef) {
            if (selectedRef.dataType === 'document' && !utils.inputFieldList[selectedRef.id]) {
              utils.dispatch(getInputFieldAction({
                companyId: utils.company,
                formId: selectedRef.id,
                statusId: ''
              }))
            }
            if (selectedRef.dataType === 'database' && !utils.datatableInputField[selectedRef.id]) {
              utils.dispatch(getDatatableInputFieldAction({
                companyId: utils.company,
                formId: selectedRef.id,
                statusId: ''
              }))
            }
          }
        }
      }
    })
  }
}

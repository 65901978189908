import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import SelectedRelation from '../bp-relationship/selected-relation';
import { IAppliedLabels, IFormLabels } from './interface/label';

interface ILabelsPanes {
  openLabelForm(data: IFormLabels, actionType: 'edit' | 'create'): void;
  labelData: IFormLabels[];
  setLabelToDelete(label: IFormLabels): void;
  appliedLabelList: IAppliedLabels[];
  triggerErrorMessage(message: string): void;
}

const CreatedLabels: React.FC<ILabelsPanes> = (props) => {

  const deleteLabelConfig = (selected: IFormLabels) => {
    const isLabelSelected = props.appliedLabelList.find(e => e.label.id === selected.id && !e.deleted);
    if (isLabelSelected) {
      props.triggerErrorMessage(`${selected.name} is currently applied to an end user screen. Please delete ${selected.name} from Applied Labels first.`);
    } else {
      props.setLabelToDelete(selected);
    }
  }

  const addNewLabel = () => {
    const initialLabel: IFormLabels = {
      id: uuid(),
      name: '',
      fields: [],
      headerField: [],
      deleted: false,
      new: true
    }
    props.openLabelForm(initialLabel, 'create');
  }

  return (<>
    <div className='labels-container'>
      <div className='labels-search-container'>
        <div className={`search-filter`}>
          <div className='ui input'>
            <input placeholder='Search' />
          </div>
          <button className='ui icon button search-button'><i className={`ui icon search`}></i></button>
        </div>
        <Button id='selected-relation-new' className='add-button' onClick={addNewLabel}>Add Label</Button>
      </div>
      <div className='label-list-main'>
        <div className='labels-list-container'>
          {props.labelData.map((label: IFormLabels) => {
            if (label.deleted || (!(label.name && label.headerField.length && label.fields.length) && label.new)) {
              return null;
            }
            return <SelectedRelation
              key={uuid()}
              label={label}
              openLabelForm={props.openLabelForm}
              hasCollapseIcon={false}
              configuration='Label'
              deleteConfig={deleteLabelConfig}
            />
          })}
        </div>
      </div>
    </div>
  </>
  );
}

export default CreatedLabels;
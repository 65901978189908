import React, { useRef, useState } from 'react';
import { Button, Segment, Form, Modal, Dropdown } from 'semantic-ui-react-bpm';
import { IFormBuilderSection } from '../../interface/section';
import { ConfirmationMessage } from '../../../../../../../../common/general/confirmation-modal';
import { useSetSectionSettingsConfig } from '../hooks/use-set-section-settings-config';
import SVGWrapper from '../../../../../../../../common/icons/svg/svg-wrapper';

interface ISectionSettings {
  open: boolean;
  close(): void;
  selectedSection: IFormBuilderSection;
  updateSelectedSection(data: any): void;
  deleteEmptySection(sectionId: string): void;
  isOpenDeleteModal: boolean;
  setIsOpenDeleteModal: any;
  sectionList: IFormBuilderSection[];
}

const SectionSettings: React.FC<ISectionSettings> = (props) => {

  const defaultError = { headerField: false };

  const [errorIndicator, setErrorIndicator] = useState(defaultError);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const nameRef = useRef(document.createElement('input'));
  const additionalInfoRef = useRef(document.createElement('textarea'));

  const {
    isCollapsable,
    setIsCollapsable,
    isTitleHidden,
    setIsTitleHidden,
    selectedTitleField,
    setSelectedTitleField,
    defaultView,
    setDefaultView,
    optionField,
    setIsCurrentSectionPinned,
    isCurrentSectionPinned
  } = useSetSectionSettingsConfig({
    additionalInfoRef,
    nameRef,
    selectedSection: props.selectedSection
  });

  const updateSettings = () => {
    let tempSelectedSection = { ...props.selectedSection };
    tempSelectedSection.title = nameRef.current.value;
    tempSelectedSection.additionalInfo = additionalInfoRef.current.value;
    tempSelectedSection.collapsable = isCollapsable;
    tempSelectedSection.titleHidden = isTitleHidden;
    tempSelectedSection.defaultView = isCollapsable ? defaultView : undefined;
    tempSelectedSection.titleFieldToShow = selectedTitleField;
    tempSelectedSection.pinned = isCurrentSectionPinned;
    if (isValid()) {
      props.updateSelectedSection(tempSelectedSection);
    }
  }

  const validatePinnedSection = () => {
    const pinnedSection = props.sectionList.filter(e => e.pinned);
    if (pinnedSection && pinnedSection.length === 1) {
      const details = pinnedSection[0];
      if (details.sectionId !== props.selectedSection.sectionId) {
        const index = props.sectionList.indexOf(details);
        setErrorMessage(`${details.title || `Section ${index + 1}`} must be un-pinned before a new section is selected`)
      } else {
        setIsCurrentSectionPinned(!isCurrentSectionPinned)
      }
      return;
    } else {
      setIsCurrentSectionPinned(!isCurrentSectionPinned)
    }
  }

  const isValid = (): boolean => {
    let status = true;
    let tempErrorIndicator = { ...errorIndicator };
    if (isTitleHidden && selectedTitleField.length === 0) {
      status = false;
      tempErrorIndicator.headerField = true;
    }
    setErrorIndicator(tempErrorIndicator)
    return status;
  }

  const setFieldTitleHidden = (value: boolean) => {
    if (!value) {
      setSelectedTitleField([])
    }
    setIsTitleHidden(value)
  }

  return (<>
    <Modal
      dimmer='blurring'
      size='small'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={props.open}
      className='settings-container'
    >
      <Modal.Header className='borderless' >
        <div className='ui borderless bg-primary'  >
          Section Settings
        </div>
      </Modal.Header>
      <Modal.Content>
        <Segment className='borderless body'>
          <Form>
            <Form.Group className='settings-form' >
              <div className='borderless'>
                <div className="field">
                  <label>Section Name</label>
                  <div className="ui fluid input">
                    <input type="text" ref={nameRef} />
                  </div>
                </div>
                <div className='settings-checkbox'>
                  <div className={`ui fitted checkbox ${isCollapsable ? 'collapse' : ''}`} onClick={() => setIsCollapsable(!isCollapsable)} >
                    <input className="hidden" onChange={() => { }} checked={isCollapsable} type="checkbox" />
                    <label>Expand/Collapse</label>
                  </div>
                  {isCollapsable &&
                    <div className={`field default-view`} >
                      <label>Default View</label>
                      <Dropdown
                        clearable
                        selectOnBlur={false}
                        options={[
                          { key: 1, text: 'Expanded', value: 'expanded' },
                          { key: 2, text: 'Collapsed', value: 'collapsed' }
                        ]}
                        value={defaultView}
                        selection
                        onChange={(_, target: any) => { setDefaultView(target.value) }}
                      />
                    </div>
                  }
                  <div className="ui fitted checkbox" onClick={() => setFieldTitleHidden(!isTitleHidden)} >
                    <input className="hidden" onChange={() => { }} checked={isTitleHidden} type="checkbox" />
                    <label>Display fields into header</label>
                  </div>
                </div>
                <div className='section-select'>
                  <Dropdown
                    className={`${errorIndicator.headerField ? 'error' : ''}`}
                    clearable
                    search
                    selectOnBlur={false}
                    value={selectedTitleField}
                    options={optionField}
                    multiple
                    selection
                    placeholder='Select Fields'
                    onChange={(_, target: any) => {
                      setSelectedTitleField(target.value)
                    }}
                  />
                  {errorMessage && <div className='pinned-section'>
                    <SVGWrapper iconName='warning' width='18px' height='18px' color='#EB5757' />
                    <span className='error-text'>
                      {errorMessage}
                    </span>
                  </div>
                  }
                  <div className={`ui fitted checkbox ${isCollapsable ? 'collapse' : ''}`} onClick={validatePinnedSection} >
                    <input className="hidden" onChange={() => { }} checked={isCurrentSectionPinned} type="checkbox" />
                    <label>Pinned Section</label>
                  </div>
                </div>
              </div>
              <div className='borderless'>
                <div className="field">
                  <label>Additional Information</label>
                  <textarea rows={13} ref={additionalInfoRef} ></textarea>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions className='borderless'>
        <Button onClick={() => setIsOpenDeleteModal(true)} floated='left' color='red' className='btn-action'>Delete Section</Button>
        <Button className='btn-default btn-action' onClick={() => { props.close() }}>Cancel</Button>
        <Button className='btn-success btn-action' onClick={() => updateSettings()}>Save</Button>
      </Modal.Actions>
    </Modal >
    <ConfirmationMessage
      clearErrorMessage={() => { }}
      errorMessage={''}
      confirmButtonName={'Confirm'}
      confirmButtonColor={'green'}
      cancelButtonColor={'grey'}
      close={() => { }}
      open={isOpenDeleteModal}
      confirm={() => props.deleteEmptySection(props.selectedSection.sectionId || '')}
      cancel={() => setIsOpenDeleteModal(false)}
      headerText="Confirmation"
      detailText={`The section '${props.selectedSection.title ? props.selectedSection.title : 'Untitled Section'}' 
      will be deleted. Do you still want to proceed?`}
      loading={false}
      type='warning'
    />
  </>)
}

export default SectionSettings;

import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import {
  SAVE_FORM_LABELS_ERROR,
  CLEAR_SAVE_FORM_LABELS_ERROR,
  GET_FORM_LABELS,
  GET_FORM_LABELS_RETURN,
  GET_FORM_LABELS_ERROR,
  SET_SAVE_TYPE,
  CLEAR_SAVE_FORM_LABELS_SUCCESS,
  PUBLISH_FORM_LABELS,
  PUBLISH_FORM_LABELS_ERROR,
  PUBLISH_FORM_LABELS_RETURN
} from './constant';
import { IFormLabels, IAppliedLabels } from './interface/label';
import { formLabelMessage } from './message';

export interface IConfigurationLabelsReducer {
  type?: string;
  fieldList: ICustomFieldDetail[];
  listLoading: boolean;
  fieldError: string;
  statusList: IFormStatusWithAction[];
  formLabels: IFormLabels[];
  formLabelReturn: IFormLabels;
  fieldSuccess: string;
  formLabelSaveType: string;
  formAppliedLabels: IAppliedLabels[];
}

const initialState: IConfigurationLabelsReducer = {
  fieldList: [],
  listLoading: false,
  fieldError: '',
  statusList: [],
  formLabels: [],
  fieldSuccess: '',
  formLabelSaveType: '',
  formLabelReturn: {} as IFormLabels,
  formAppliedLabels: []
}

const configurationLabelsReducer = (state = initialState, action: IConfigurationLabelsReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case SAVE_FORM_LABELS_ERROR:
      newState.listLoading = false;
      newState.fieldError = action.fieldError;
      return newState;

    case CLEAR_SAVE_FORM_LABELS_ERROR:
      newState.fieldError = '';
      return newState;

    case GET_FORM_LABELS:
      newState.listLoading = true;
      return newState;

    case GET_FORM_LABELS_RETURN:
      newState.formLabels = action.formLabels;
      newState.fieldList = action.fieldList;
      newState.statusList = action.statusList;
      newState.formAppliedLabels = action.formAppliedLabels;
      newState.listLoading = false;
      return newState;

    case GET_FORM_LABELS_ERROR:
      newState.listLoading = false;
      newState.fieldError = action.fieldError;
      return newState;

    case SET_SAVE_TYPE:
      newState.formLabelSaveType = action.formLabelSaveType;
      if (action.formLabelSaveType === 'draft') {
        newState.fieldSuccess = formLabelMessage.saveDraftBPLabelSuccess;
      }
      return newState;

    case CLEAR_SAVE_FORM_LABELS_SUCCESS:
      newState.formLabelSaveType = '';
      newState.fieldSuccess = '';
      return newState;

    case PUBLISH_FORM_LABELS:
      newState.listLoading = true;
      newState.formLabelSaveType = '';
      newState.fieldSuccess = '';
      return newState;

    case PUBLISH_FORM_LABELS_RETURN:
      newState.listLoading = false;
      newState.fieldSuccess = formLabelMessage.publishBPLabelSuccess;
      return newState;

    case PUBLISH_FORM_LABELS_ERROR:
      newState.listLoading = false;
      newState.fieldError = action.fieldError;
      return newState;

    default:
      return state;
  }
}

export default configurationLabelsReducer;
import { CSSProperties } from 'react-router/node_modules/@types/react';
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';
import { IDisplayDataConfig, IDisplayDataFields } from './interface/display-data';

export const GET_DISPLAY_DATA_CONFIG = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG';
export const GET_DISPLAY_DATA_CONFIG_RETURN = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG_RETURN';
export const GET_DISPLAY_DATA_CONFIG_ERROR = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG_ERROR';

export const SAVE_DISPLAY_DATA_CONFIG = 'app/admin/bp/configuration/display-data/SAVE_DISPLAY_DATA_CONFIG';
export const SAVE_DISPLAY_DATA_CONFIG_RETURN = 'app/admin/bp/configuration/display-data/SAVE_DISPLAY_DATA_CONFIG_RETURN';
export const SAVE_DISPLAY_DATA_CONFIG_ERROR = 'app/admin/bp/configuration/display-data/SAVE_DISPLAY_DATA_CONFIG_ERROR';
export const PUBLISH_DISPLAY_DATA_CONFIG = 'app/admin/bp/configuration/display-data/PUBLISH_DISPLAY_DATA_CONFIG';
export const PUBLISH_DISPLAY_DATA_CONFIG_RETURN = 'app/admin/bp/configuration/display-data/PUBLISH_DISPLAY_DATA_CONFIG_RETURN';
export const PUBLISH_DISPLAY_DATA_CONFIG_ERROR = 'app/admin/bp/configuration/display-data/PUBLISH_DISPLAY_DATA_CONFIG_ERROR';
export const DELETE_DISPLAY_DATA_CONFIG = 'app/admin/bp/configuration/display-data/DELETE_DISPLAY_DATA_CONFIG';
export const DELETE_DISPLAY_DATA_CONFIG_RETURN = 'app/admin/bp/configuration/display-data/DELETE_DISPLAY_DATA_CONFIG_RETURN';
export const DELETE_DISPLAY_DATA_CONFIG_ERROR = 'app/admin/bp/configuration/display-data/DELETE_DISPLAY_DATA_CONFIG_ERROR';
export const GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS';
export const GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN';
export const GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_ERROR = 'app/admin/bp/configuration/display-data/GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_ERROR';

export const CLEAR_SAVE_DISPLAY_DATA_CONFIG_ERROR = 'app/admin/bp/configuration/display-data/CLEAR_SAVE_DISPLAY_DATA_CONFIG_ERROR';
export const CLEAR_SAVE_DISPLAY_DATA_CONFIG_SUCCESS = 'app/admin/bp/configuration/display-data/CLEAR_SAVE_DISPLAY_DATA_CONFIG_SUCCESS';

export const fieldTitleOption = {
  key: -2,
  text: 'BP Fields',
  value: 'fields',
  className: 'values-separator',
  onClick: () => { return },
  as: 'div',
  style: {
    padding: '15px !important',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    pointerEvents: 'none'
  } as CSSProperties
}

export const metadataTitleOption = {
  key: -1,
  text: 'Metadata',
  value: 'metadata',
  className: 'values-separator',
  onClick: () => { return },
  as: 'div',
  style: {
    padding: '15px !important',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    pointerEvents: 'none'
  } as CSSProperties
}

export const metaBPIcon = {
  slot: 'bp_icon',
  keyPath: 'form',
  showLabel: false
}

export const errorIndicator = {
  name: false,
  header: false,
  meta: false
}

export const defaultThumbnail: IDisplayDataConfig = {
  slot: 'thumb',
  keyPath: '',
  showLabel: false
}

export const defaultMeta: IDisplayDataFields = {
  showLabel: false,
  config: ['', '']
}

export const defaultMetadata: IDisplayDataConfig[] = [
  {
    slot: 'meta_1',
    keyPath: '',
    showLabel: false
  },
  {
    slot: 'meta_2',
    keyPath: '',
    showLabel: false
  }
]

export const defaultSelectedKeypaths: { [keyPath: string]: any } = {
  header: [],
  content: []
}

export const displayDataActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_DISPLAY_DATA_CONFIG_ERROR,
    type: 'negative',
    reducerContainer: 'configurationDisplayData',
    reducerItem: 'error',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_DISPLAY_DATA_CONFIG_SUCCESS,
    type: 'success',
    reducerContainer: 'configurationDisplayData',
    reducerItem: 'success',
    hasCloseIcon: true
  }
]
import { useEffect } from 'react';
import {
  publishWorkflowAction,
  publishWorkflowErrorAction
} from '../../action';
import { IFormAction } from '../../interface/form-action';
import { IFormStatus } from '../../interface/form-status';

interface IProps {
  saveWorkflowType: string;
  dispatch: any;
  actionList: IFormAction[];
  statusList: IFormStatus[];
  company: string;
  formId: string;
}

export const useSetPublishErrorMessage = (props: IProps) => {
  const { saveWorkflowType, dispatch, actionList, statusList, company, formId } = { ...props };

  useEffect(() => {
    if (saveWorkflowType === 'publish') {
      const hasDynamicAction = actionList.filter((action: IFormAction) => action.type === 'dynamic')
      const hasFinalStatus = statusList.filter((status: IFormStatus) => status.final === true);
      if (hasDynamicAction.length === 0) {
        dispatch(publishWorkflowErrorAction('At least one dynamic action must be specified before publishing'))
      } else if (hasFinalStatus.length === 0) {
        dispatch(publishWorkflowErrorAction('Each form should have at least one end status'))
      } else {
        dispatch(publishWorkflowAction({ company: company, formId: formId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveWorkflowType]);

  return { ...props };
}
import React, { useCallback, useRef, useState } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../../../../../common/api-request/upload';
import { icons } from '../../../../../../common/icons';
import { getFilters } from '../../../../../main/partials/header/hexToCssFilter';
import { useSetInitialArrow } from '../../action/hooks/use-set-initial-arrow';
import { useResizeEffect } from '../../hooks/use-resize-effect';
import { ScrollableListAction } from '../../utils/manage-scrollable-list-action';
import { getFileType } from '../widget/messages/system/delta-fields/utils/get-file-type';
import { renderInputFileComponent } from './utils/render-input-file-component';

interface IInputAttachment {
  removeUploadedFile(file: UploadedFile): void;
  uploadedFileList: UploadedFile[];
}

const InputAttachment: React.FC<IInputAttachment> = (props) => {
  const { uploadedFileList, removeUploadedFile } = { ...props };
  const scrollContainerRef = useRef(document.createElement('div'));

  const [isArrowLeft, setIsArrowLeft] = useState(false);
  const [isArrowRight, setIsArrowRight] = useState(false);
  const [container, setContainer] = useState<any>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const containerRef = useRef(container);
  containerRef.current = container;

  const setAttachmentListRef = (element: HTMLDivElement | null) => {
    return ScrollableListAction.setScrollListRef(element, {
      setIsScrollable,
      scrollContainerRef,
      setContainer,
    });
  }

  const setScrollableOnResize = useCallback(() => {
    if (!containerRef.current) return;
    const { clientWidth, scrollWidth }: any = containerRef.current;
    setIsScrollable(scrollWidth > clientWidth);
  }, [containerRef]);

  useResizeEffect(setScrollableOnResize, [containerRef]);

  const scrollToList = (change: number) => {
    ScrollableListAction.scrollToList(change, {
      setIsScrollable,
      scrollContainerRef,
      setContainer,
      duration: 700
    });
  }

  const onScroll = (e: any) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    const scrollPercent = Math.round((Math.floor(scrollLeft)) / (scrollWidth - clientWidth) * 100);
    if (scrollPercent === 100) {
      setIsArrowRight(false);
      setIsArrowLeft(true);
    } else if (scrollPercent === 0) {
      setIsArrowRight(true);
      setIsArrowLeft(false);
    } else {
      setIsArrowLeft(true);
      setIsArrowRight(true);
    }
  };

  useSetInitialArrow({ isScrollable, setIsArrowRight, setIsArrowLeft });

  return <div className='attachment-main-container'>
    <div id='arrow-left' className={`arrow-left ${!isArrowLeft ? 'not-shown' : ''}`}>
      <Image onClick={() => scrollToList(-420)} className={`left`}
        style={{ filter: getFilters('#686868').filter }}
        src={icons.black.collapse}
      />
    </div>
    <div id='attachment-list' ref={setAttachmentListRef} onScroll={onScroll} className='attachment-list-container'>
      {uploadedFileList.map((file: UploadedFile, index: number) => {
        const fileType = getFileType(file);

        return <div id={`attachment-${file.id}`} key={`${index}-${file.id}`}
          className={`${(fileType.isImage && fileType.isSupportedImage) ? 'image-only' : 'attachment'}`}>
          <Image title={`remove ${file.name}`}
            className='remove' src={icons.other.remove}
            onClick={() => removeUploadedFile(file)}
          />
          {renderInputFileComponent(file, false)}
        </div>
      })}
    </div>
    <div id='arrow-right' className={`arrow-right ${!isArrowRight ? 'not-shown' : ''} `}>
      <Image onClick={() => scrollToList(420)} className={`right`}
        style={{ filter: getFilters('#686868').filter }}
        src={icons.black.collapse}
      />
    </div>
  </div>
}

export default InputAttachment;
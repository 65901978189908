import React, { useState, useRef } from 'react';
import { Dropdown, Button, Image, Form } from 'semantic-ui-react-bpm';
import { useDrop } from 'react-dnd';
import RelationConfigModalField from './field';
import { IOption } from '../../../../../../../../../common/interface/option';
import { v4 as uuid } from 'uuid';
import { bprSystemFields } from '../constant';
import { IFormRelatedField, DragObjectBPRef } from '../interface/bp-relationship-field';
import { icons } from '../../../../../../../../../common/icons';
import { useSetRelationConfigModalState } from './hooks/use-set-relation-config-modal-state';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';


interface IRelationConfigModalProps {
  isHiddenContainer: boolean;
  setSelectedId(id: string): void;
  saveFieldConfig(selectedId: string, headerField: IFormRelatedField[], contentField: IFormRelatedField[], refName: string, isNew?: boolean): void;
  setDataToDelete(data: any): void;
  triggerErrorMessage(text: string): void;
  selectedId: string;
  selectedData: any[];
  formFields: any[];
  configuration: string;
  hasDelete: boolean;
  hasCancelAction: boolean;
  deleteButtonPlacement: 'header' | 'footer';
  modalId?: string;
}

const RelationConfigModal: React.FC<IRelationConfigModalProps> = (props) => {
  const refName = useRef(document.createElement('input'));
  const errorIndicator = {
    name: false,
    headerField: false,
    contentField: false
  }
  const {
    headerField,
    setHeaderField,
    contentField,
    setContentField,
    selectedField,
    setSelectedField,
    selectedData
  } = useSetRelationConfigModalState({
    configuration: props.configuration,
    dataSelected: props.selectedData,
    refName,
    selectedId: props.selectedId
  });
  const [error, setError] = useState(errorIndicator);

  const [, headerDropRef] = useDrop({
    accept: 'content',
    drop: (selectedItem: DragObjectBPRef) => {
      dropField(selectedItem as DragObjectBPRef, 'header');
    }
  });

  const [, contentDropRef] = useDrop({
    accept: 'header',
    drop: (selectedItem: DragObjectBPRef) => {
      dropField(selectedItem as DragObjectBPRef, 'content');
    }
  });

  const getOptions = (fileType?: boolean): IOption[] => {
    const fieldOptions = props.formFields
      .filter((e: any) => {
        return fileType ? e.configName === IConfigName.FileUpload : true
      })
      .filter((e: any) => e.configName !== IConfigName.GAP)
      .map((e: any, i: number) => {
        return { key: i, text: e.label, value: `${e.id}|||||${e.label}|||||${e.type}` };
      });
    if (props.selectedId !== '') {
      const tempHeaderField = [...headerField];
      const tempContentField = [...contentField];
      const combinedItem = tempHeaderField.concat(tempContentField);
      return fieldOptions.concat(!fileType ? bprSystemFields : []).filter((option: IOption) => {
        const [id] = option.value.split('|||||')
        return combinedItem.filter((field: IFormRelatedField) => field.id === id).length === 0;
      });
    } else {
      return []
    }
  }

  const dropField = (dragItem: DragObjectBPRef, type: 'header' | 'content') => {
    if (type === 'header') {
      const tempContentField = [...contentField];
      tempContentField.splice(dragItem.index, 1);
      if (headerField.length > 0) {
        tempContentField.push(headerField[0]);
      }
      setContentField(tempContentField);
      setHeaderField([dragItem.data]);
    } else {
      const tempContentField = [...contentField];
      const tempHeaderField = [...headerField];
      if (tempContentField.length < 4) {
        tempContentField.push(dragItem.data);
        setContentField(tempContentField);
        tempHeaderField.splice(dragItem.index, 1)
        setHeaderField(tempHeaderField);
      } else {
        dispatchError();
      }
    }
  }

  const addField = (value: string) => {
    const tempContentField = [...contentField];
    const [id, text, type] = value.split('|||||');
    tempContentField.push({
      id: id,
      name: text,
      type: type,
      showLabel: true
    });
    if (tempContentField.length < 5) {
      setContentField(tempContentField);
      setSelectedField('');
    } else {
      dispatchError();
    }
  }

  const isValid = () => {
    let currentError: any = { ...error };

    if (props.configuration === 'Label') {
      currentError.name = (!refName.current.value) ? true : false;
    } else {
      currentError.name = false;
    }

    currentError.contentField = contentField.length === 0 ? true : false;
    currentError.headerField = headerField.length === 0 ? true : false;

    setError(currentError);
    return Object.keys(currentError).every(key => currentError[key] === false);
  }

  const saveFieldConfig = () => {
    if (!isValid()) {
      return;
    }
    props.saveFieldConfig(props.selectedId, headerField, contentField, refName.current.value, selectedData.new);
    refName.current.value = '';
  }

  const updateField = (index: number, data: IFormRelatedField, fieldType: 'header' | 'content') => {
    if (fieldType === 'header') {
      let tempContentField = [...headerField];
      tempContentField.splice(index, 1, data);
      setHeaderField(tempContentField);
    } else {
      let tempContentField = [...contentField];
      tempContentField.splice(index, 1, data);
      setContentField(tempContentField);
    }
  }

  const removeField = (index: number, fieldType: string) => {
    if (fieldType === 'header') {
      const tempContentField = [...headerField];
      tempContentField.splice(index, 1);
      setHeaderField(tempContentField);
    } else {
      const tempContentField = [...contentField];
      tempContentField.splice(index, 1);
      setContentField(tempContentField);
    }
  }

  const sortField = (draggedItem: DragObjectBPRef, droppedItemId: string) => {
    console.log(`Log ~ draggedItem`, draggedItem);
    const tempContentField = [...contentField];
    const dragIndex = tempContentField.indexOf(draggedItem.data);
    const dropIndex = tempContentField.indexOf(tempContentField.filter(e => e.id === droppedItemId)[0]);
    const element = tempContentField[dragIndex];
    tempContentField.splice(dragIndex, 1);
    tempContentField.splice(dropIndex, 0, element);
    setContentField(tempContentField);
  }

  const dispatchError = () => {
    props.triggerErrorMessage('Maximum number of fields exceeded');
  }

  return <>
    <div hidden={props.isHiddenContainer} id='relation-config-modal-hidden-container' className='hidden-container'
      onClick={() => props.setSelectedId('')} />
    <div id={props.modalId ? props.modalId : `relation-config-modal`} hidden={props.isHiddenContainer}>
      <Image className={`btn-cancel ${props.deleteButtonPlacement === 'header' ? 'hidden' : ''}`}
        src={icons.other.circleCancel}
        onClick={() => props.setSelectedId('')}
      />
      <div className='bp-selected-header'>
        {props.configuration} Setup
      </div>
      {props.configuration === 'Label' &&
        <Form size='large' id='group-form' >
          <Form.Group widths='equal'>
            <div className={`required six wide field`}>
              <label>Name</label>
              <div className={`ui input ${error.name ? 'error' : ''}`}>
                <input ref={refName} type="text" />
              </div>
            </div>
          </Form.Group>
        </Form>
      }
      <div className='header-container '>
        <div className='header-label'>
          <label className='required'>Header</label>
          <label className={`label ${headerField.length === 0 ? 'hidden' : ''}`}>Label</label>
        </div>
        <div ref={headerDropRef} className={`header-field ${headerField.length === 0 ? 'initial' : ''} ${error.headerField ? 'error' : ''}`}>
          {
            headerField.map((field: IFormRelatedField, index: number) => {
              return <RelationConfigModalField
                key={`header-${uuid()}`}
                index={index}
                field={field}
                removeField={removeField}
                fieldType='header'
                updateField={updateField}
                sortField={sortField}
                dropField={dropField}
              />
            })
          }
        </div>
      </div>
      <div className={`selected-field-container`}>
        <div className='header-label'>
          <label className='required'>Description</label>
          <label className={`label ${contentField.length === 0 ? 'hidden' : ''}`}>Label</label>
        </div>
        <div ref={contentDropRef} className={`selected-field ${contentField.length === 0 ? 'initial' : ''} ${error.contentField ? 'error' : ''}`}>
          {
            contentField.map((field: IFormRelatedField, index: number) => {
              return <RelationConfigModalField
                key={`field-${uuid()}`}
                index={index}
                field={field}
                removeField={removeField}
                fieldType='content'
                updateField={updateField}
                sortField={sortField}
                dropField={dropField}
              />
            })
          }
        </div>
        <small>(Maximum: 4 Fields)</small>
      </div>
      <div className='field-selection'>
        <Dropdown
          fluid
          selection
          placeholder='Select Fields'
          clearable
          search
          value={selectedField}
          options={getOptions()}
          selectOnBlur={false}
          onChange={(event, target) => { setSelectedField(target.value as string) }}
        />
        <Button className={`blue add`} icon='plus' onClick={() => addField(selectedField as string)} />
      </div>
      <div className='action'>
        <Button className='btn-success' onClick={saveFieldConfig}>Save</Button>
        {props.hasCancelAction &&
          <Button className='btn-default'
            onClick={() => props.setSelectedId('')}>Cancel</Button>
        }
        {props.hasDelete &&
          <Button className={`btn-del red ${selectedData.new ? 'hidden' : ''}`}
            onClick={() => { props.setDataToDelete(selectedData) }}>Delete</Button>
        }
      </div>
    </div>
  </>
}

export default RelationConfigModal;
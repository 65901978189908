export const automationSubModule = [
  {
    id: 'automation-rules',
    name: 'Rules',
    route_label: 'AUTOMATION RULES'
  },
  {
    id: 'automation-logs',
    name: 'Automation logs',
    route_label: 'AUTOMATION LOGS'
  }
];
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_USER_BUSINESS_PROCESS, GET_END_USER_DETAIL, GET_CURRENT_USER_IN_DOCUMENT } from './constant';
import {
  getUserBusinessProcessReturnAction,
  getUserBusinessProcessErrorAction,
  getEndUserDetailReturnAction,
  getEndUserDetailErrorAction,
  setCurrentUserInDocument
} from './actions';
import { setStatusCodeAction } from '../error/action';
import { getRequest, IAxiosResponse, } from '../../common/api-request';
import { IUserBusinessProcessInput, IEndUserInput } from './interface/input/user-business-process';
import { ICurrentUserInDocumentInput } from './interface/input/current-user-in-document';

export function* getUserBusinessProcess(args: IUserBusinessProcessInput) {
  try {
    const company = args.company;
    const req: { data: { data: any[] } } = yield call(getRequest, `${company}/documents/forms?case=create&limit=0`, {});
    const datatable: { data: { data: any[] } } = yield call(getRequest, `${company}/databases?load=access`, {});

    yield put(getUserBusinessProcessReturnAction({
      businessProcess: req.data.data,
      dataTable: datatable.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getUserBusinessProcessErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getEndUserDetail(args: IEndUserInput) {
  try {
    let company = args.company;
    let userId = args.userId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/users/${userId}?company=${company}&load=groups`, {});
    yield put(getEndUserDetailReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getEndUserDetailErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* checkCurrentUserEditing(args: ICurrentUserInDocumentInput) {
  try {
    let company = args.company;
    let documentId = args.document.id;
    let document: any = { ...args.document };
    if (args.documentIdOnly) {
      const documentReq: IAxiosResponse = yield call(getRequest, `${company}/documents/${document.id}?load=creator&load=updatedBy&load=form&load=slaStatus`, {});
      document = { ...documentReq.data.data, screenState: document.screenState };
    }
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/${documentId}/currentUsers`, {});
    yield put(setCurrentUserInDocument({ currentUserEditingDocument: req.data.data, currentuserDocumentDetail: document }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* endUserSaga() {
  yield takeLatest(GET_USER_BUSINESS_PROCESS, getUserBusinessProcess);
  yield takeLatest(GET_END_USER_DETAIL, getEndUserDetail);
  yield takeLatest(GET_CURRENT_USER_IN_DOCUMENT, checkCurrentUserEditing);
}

// All sagas to be loaded
export default endUserSaga;
import { useEffect } from 'react';
import { IAutomationComponent } from '../../../../interface/component-list';
import moment from 'moment-timezone';

interface IDateRange {
  start: string;
  end: string;
}

interface IProps {
  selectedComponent: IAutomationComponent;
  setRunRangeDate(value: IDateRange): void;
  setCronInputValue(value: string): void;
  setRunTimezone(value: string): void;
  setValue(value: string): void;
}

export const useSetInitialScheduledStateValue = (props: IProps): void => {
  const {
    selectedComponent,
    setCronInputValue,
    setRunRangeDate,
    setRunTimezone,
    setValue
  } = { ...props };

  useEffect(() => {
    const { config } = selectedComponent;
    if (selectedComponent && config) {
      const { cron, timezone, start, end } = config;
      if (cron) {
        setValue(cron || '* * * * *');
        setCronInputValue(cron || '* * * * *');
      }
      if (timezone) {
        setRunTimezone(timezone || moment.tz.guess());
      }
      if (start && end) {
        setRunRangeDate({
          start: start || new Date(),
          end: end || undefined
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return;
}
/* eslint-disable complexity */
import { IField, IFieldAccessType } from '../../../component/admin-module/module/users/interface/field';
import { IReloadFieldResponse } from '../../../component/document-module/module/form/interface/response/field-trigger-reload';
import { tableFieldDataRowsCount } from '../../../component/document-module/module/form/utils/manage-form-data';
import { IFormDocument } from '../../../component/document-module/module/report/interface/form-document';
import { IConfigName } from '../type/interface/field-type-object';

interface IUtils {
  fieldList: IField[],
  companyId: string;
  statusId: string;
  formId: string;
  documentId: string;
  dispatchTriggerFieldReload: (data: IReloadFieldResponse) => void;
}
export class FieldConstruct {
  static manageNumberField = (
    formFields: IField[],
    fieldRefs: { current: { [name: string]: any } },
    fieldId: string,
    field: IField,
    actionType?: string
  ) => {
    let value = null;
    const fxFields = formFields.filter((field: IField) => field.configName === IConfigName.FX);
    if (fieldRefs.current[fieldId]) {
      fieldRefs.current[fieldId].value = fieldRefs.current[fieldId].value && typeof fieldRefs.current[fieldId].value === 'number'
        ? fieldRefs.current[fieldId].value.toString() : fieldRefs.current[fieldId].value
      if (actionType !== 'save' && field.accessType === IFieldAccessType.Required) {
        value = '';
      }
      if (!!fieldRefs.current[fieldId].value) {
        value = parseFloat(fieldRefs.current[fieldId].value.replace(/,/g, ''));
      } else {
        value = null;
        if (fxFields.length > 0 && actionType !== 'fetch') {
          const isUsedInFx = fxFields.filter(fx => fx.requiresFieldData && fx.requiresFieldData.includes(fieldId)).length > 0;
          if (isUsedInFx) {
            value = 0;
          }
        }
      }
    }
    return value;
  }

  static fieldNotAllowedToSend = (field: IField) => {
    return field.configName === IConfigName.Seq
      || field.configName === IConfigName.GAP
      || field.configName === IConfigName.FX
      || (field.configName === IConfigName.Autopopulated && (field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden))
  }

  static getFieldDataFromRefs = (formFields: IField[], fieldRefs: { current: { [name: string]: any } }, includeAllField?: boolean, actionType?: string, isTableField?: boolean): { [name: string]: any } => {
    let fields: { [name: string]: any } = {};
    formFields.forEach((field: IField) => {
      const fieldId = field.id || '';
      const fieldNotAllowedToSend = FieldConstruct.fieldNotAllowedToSend(field);
      const hiddenWithNoValue = field.accessType === IFieldAccessType.Hidden && fieldRefs.current[fieldId] && !fieldRefs.current[fieldId].value;
      if (!includeAllField && (fieldNotAllowedToSend || (hiddenWithNoValue && !isTableField))) {
        // do nothing  
      } else if (fieldRefs.current[fieldId]) {
        if (field.inputConfig && field.inputConfig.type === 'array') {
          let data: any = [];
          const dataLen = fieldRefs.current[fieldId].length;
          for (let i = 0; i < dataLen; i++) {
            const inputConfig: any = field.inputConfig.config.items;
            if (inputConfig.type === 'number') {
              if (!!fieldRefs.current[fieldId][i].value) {
                data.push(parseFloat(fieldRefs.current[fieldId][i].value.replace(/,/g, '')));
              } else {
                data.push(null);
              }
            } else if (inputConfig.type === 'enum') {
              if (inputConfig.config.multiselect) {
                data.push(fieldRefs.current[fieldId][i].value && fieldRefs.current[fieldId][i].value.length > 0
                  ? fieldRefs.current[fieldId][i].value
                  : null);
              } else {
                data.push(fieldRefs.current[fieldId][i].value || null);
              }
            } else if (inputConfig.type === 'bool') {
              data.push(fieldRefs.current[fieldId][i].value === 'true' ? true : false);
            } else if (inputConfig.type === 'file') {
              data.push(fieldRefs.current[fieldId][i].value);
            } else {
              data.push(fieldRefs.current[fieldId][i].value || null);
            }
          }
          data = data.filter((value: any) => value !== null);
          fields[fieldId] = data.length > 0 ? data : (actionType !== 'save' && field.accessType === IFieldAccessType.Required ? [null] : null);
        } else if (field.inputConfig && field.inputConfig.type === 'table') {
          let tempData = fieldRefs.current[fieldId] || [];
          fields[fieldId] = {};
          tempData.forEach((refdata: any) => {
            if (Object.keys(refdata).length > 0 && field.inputConfig && field.inputConfig.config && field.inputConfig.config.columns) {
              const tableFieldData = FieldConstruct.getFieldDataFromRefs(field.inputConfig.config.columns, refdata, includeAllField, actionType, true);
              field.inputConfig.config.columns.forEach((columnField: IField) => {
                const id = columnField.id || '';
                if (!fields[fieldId][id]) {
                  fields[fieldId][id] = [];
                }
                const value = (typeof tableFieldData[id] === 'string' && !tableFieldData[id] ? null : tableFieldData[id])
                fields[fieldId][id].push(value)
              })
            }
          })
        } else if (field.inputConfig && field.inputConfig.type === 'range') {
          const tempData = fieldRefs.current[fieldId] || {};
          const rangeKeys = ['From', 'To'];
          fields[fieldId] = {};
          const rangeValues: any = {};
          rangeKeys.forEach(key => {
            const rangeData = tempData[key].current[`${key}.${fieldId}`] || {};
            if (Object.keys(rangeData).length > 0) {
              const value = rangeData.value;
              if (value) {
                rangeValues[key.toLowerCase()] = value || null;
              }
            }
          });
          fields[fieldId] = rangeValues && Object.keys(rangeValues).length > 0 ? rangeValues : null;
        } else {
          if (field.inputConfig && field.inputConfig.type === 'number') {
            fields[fieldId] = FieldConstruct.manageNumberField(formFields, fieldRefs, fieldId, field, actionType);
          } else if (field.inputConfig && field.inputConfig.type === 'file') {
            fields[fieldId] = fieldRefs.current[fieldId].value && fieldRefs.current[fieldId].value !== 'undefined'
              ? fieldRefs.current[fieldId].value
              : (actionType !== 'save' && field.accessType === IFieldAccessType.Required ? [] : null);
          } else if (field.inputConfig && field.inputConfig.type === 'enum') {
            fields[fieldId] = fieldRefs.current[fieldId].value || (actionType !== 'save' && field.accessType === IFieldAccessType.Required ? '' : null);
            if (field.inputConfig.config.multiselect) {
              fields[fieldId] = fieldRefs.current[fieldId].value && fieldRefs.current[fieldId].value.length > 0
                ? fieldRefs.current[fieldId].value
                : (actionType !== 'save' && field.accessType === IFieldAccessType.Required ? [] : null);
            }
            if (actionType === 'automation' && fieldRefs.current[fieldId] && fieldRefs.current[fieldId].value &&
              fieldRefs.current[fieldId].selectedOptionFilter) {
              fields[fieldId] = fieldRefs.current[fieldId].selectedOptionFilter[0]
              if (field.inputConfig.config.multiselect) {
                fields[fieldId] = fieldRefs.current[fieldId].selectedOptionFilter
              }
            }
          } else if (field.inputConfig && field.inputConfig.type === 'bool') {
            fields[fieldId] = fieldRefs.current[fieldId].value || false;
          } else if (field.inputConfig && field.inputConfig.type === 'date') {
            fields[fieldId] = fieldRefs.current[fieldId].value === 'undefined' ||
              fieldRefs.current[fieldId].value === 'Invalid date' ||
              fieldRefs.current[fieldId].value === ''
              ? null
              : fieldRefs.current[fieldId].value;
            if (fieldRefs.current[fieldId].today) {
              fields[fieldId] = 'today';
            }
          } else {
            const value = fieldRefs.current[fieldId].value;
            fields[fieldId] = (typeof value === 'string' && value === 'null' ? null : value) || (actionType !== 'save' && field.accessType === IFieldAccessType.Required ? '' : null);
          }
        }
      }
      if (field.accessType === IFieldAccessType.Hidden && field.inputConfig && field.inputConfig.type === 'number' && field.configName === IConfigName.Number) {
        fields[fieldId] = FieldConstruct.manageNumberField(formFields, fieldRefs, fieldId, field, actionType);
      }
      // for automation part only
      if (fieldRefs?.current[fieldId]?.keyPath || fieldRefs?.current[fieldId]?.referencedKeyPath) {
        fields[fieldId] = {
          keyPath: fieldRefs?.current[fieldId]?.keyPath,
          referencedKeyPath: fieldRefs?.current[fieldId]?.referencedKeyPath
        };
      }
      if (fieldRefs?.current[fieldId]?.fieldId || fieldRefs?.current[fieldId]?.sourceRefField) {
        fields[fieldId] = {
          fieldId: fieldRefs?.current[fieldId]?.fieldId,
          sourceRefField: fieldRefs?.current[fieldId]?.sourceRefField
        };
      }
    })
    return fields;
  }

  static setFieldToRefs = (formFields: IField[], data: IFormDocument, fieldRefs: { current: { [name: string]: any } }, actionType?: string) => {
    formFields.forEach((field: IField) => {
      const fieldId = field.id || '';
      const fieldType = field.inputConfig && field.inputConfig.type;
      const fieldData = data.fields || {};

      if (fieldType === 'array') {
        const dataLen = fieldData[fieldId] ? fieldData[fieldId].filter((value: any) => value !== null).length : 0;
        if (dataLen > 0) {
          fieldRefs.current[fieldId] = [];
          for (let i = 0; i < dataLen; i++) {
            let element: any = { value: null };
            fieldRefs.current[fieldId][i] = element;
            if (typeof fieldData[fieldId][i] === 'object' && fieldData[fieldId][i] !== null) {
              fieldRefs.current[fieldId][i].value = fieldData[fieldId][i];
            } else {
              fieldRefs.current[fieldId][i].value = fieldData[fieldId][i] !== null ? fieldData[fieldId][i] : '';
            }
          }
        }
      } else if (fieldType === 'table') {
        const tableColumn = field.inputConfig && field.inputConfig.config && field.inputConfig.config.columns;
        const tableValue = field.inputConfig && field.inputConfig.config && field.inputConfig.config.value as any;
        const maxRows = field.inputConfig && field.inputConfig.config && field.inputConfig.config.maxRows;
        const minRows = field.inputConfig && field.inputConfig.config && field.inputConfig.config.minRows;
        if (tableColumn) {
          fieldRefs.current[fieldId] = [];
          tableColumn.forEach((tblField: IField) => {
            const tableFieldId = tblField.id || '';
            if (fieldData && fieldData[fieldId] && fieldData[fieldId] !== undefined && fieldData[fieldId][tableFieldId]) {
              const tableFieldData = fieldData[fieldId];
              let tableRows = fieldData[fieldId][tableFieldId].length;

              if (maxRows !== minRows) {
                tableRows = tableFieldDataRowsCount(tableFieldData);
              }

              if (typeof tableRows === 'number') {
                for (let idx = 0; idx < tableRows; idx++) {
                  const value = fieldData[fieldId][tableFieldId][idx];
                  fieldRefs.current[fieldId][idx] = fieldRefs.current[fieldId][idx] || {};
                  fieldRefs.current[fieldId][idx].current = fieldRefs.current[fieldId][idx].current || {};
                  fieldRefs.current[fieldId][idx].current[tableFieldId] = fieldRefs.current[fieldId][idx].current[tableFieldId] || {}
                  if (typeof value === 'object' && value !== null) {
                    if (value instanceof Array) {
                      const columnFieldType = tblField.inputConfig && tblField.inputConfig.type;
                      if (tableValue && tableValue[tableFieldId] && tableValue[tableFieldId][idx] && columnFieldType === 'enum') {
                        const fieldTableValue = tableValue[tableFieldId][idx];
                        const hasIdProperty = fieldTableValue.some((e: any) => typeof e === 'object' && e.hasOwnProperty('id'));
                        if (hasIdProperty) {
                          fieldRefs.current[fieldId][idx].current[tableFieldId].value = fieldTableValue.map((e: any) => { return e.id });
                        } else {
                          fieldRefs.current[fieldId][idx].current[tableFieldId].value = fieldTableValue
                        }
                        fieldRefs.current[fieldId][idx].current[tableFieldId].selectedOptionFilter = fieldTableValue;
                        fieldRefs.current[fieldId][idx].current[tableFieldId].filteredOption = fieldTableValue;
                      }
                    } else {
                      const columnFieldType = tblField.inputConfig && tblField.inputConfig.type;
                      if (columnFieldType === 'enum') {
                        fieldRefs.current[fieldId][idx].current[tableFieldId].value = value.id;
                        fieldRefs.current[fieldId][idx].current[tableFieldId].selectedOptionFilter = value;
                        fieldRefs.current[fieldId][idx].current[tableFieldId].filteredOption = value instanceof Array ? value : (value ? [value] : []);
                      } else {
                        fieldRefs.current[fieldId][idx].current[tableFieldId].value = value;
                      }
                    }
                  } else {
                    fieldRefs.current[fieldId][idx].current[tableFieldId].value = value;
                    const columnFieldType = tblField.inputConfig && tblField.inputConfig.type;

                    if (tableValue && tableValue[tableFieldId] && tableValue[tableFieldId][idx] && columnFieldType === 'enum') {
                      const enumTableValue = tableValue[tableFieldId][idx];
                      let noChange = false;
                      if (enumTableValue && value) {
                        if (typeof enumTableValue === 'object' && typeof value === 'object'
                          && Object.keys(enumTableValue).length > 0 && Object.keys(value).length > 0) {
                          noChange = JSON.stringify(enumTableValue) === JSON.stringify(value);
                        }
                        if (typeof enumTableValue === 'object' && value && typeof value === 'string'
                          && enumTableValue.hasOwnProperty('id')) {
                          noChange = enumTableValue.id === value;
                        }
                        if (typeof value === 'object' && enumTableValue && typeof enumTableValue === 'string'
                          && value.hasOwnProperty('id')) {
                          noChange = value.id === enumTableValue;
                        }
                      }
                      if (noChange) {
                        if (typeof enumTableValue === 'object' && enumTableValue !== null
                          && enumTableValue.hasOwnProperty('id') && enumTableValue.id === value) {
                          fieldRefs.current[fieldId][idx].current[tableFieldId].value = enumTableValue.id;
                          fieldRefs.current[fieldId][idx].current[tableFieldId].selectedOptionFilter = enumTableValue;
                          fieldRefs.current[fieldId][idx].current[tableFieldId].filteredOption = enumTableValue instanceof Array ? enumTableValue : (enumTableValue ? [enumTableValue] : []);
                        }
                      }
                    }
                  }
                }
              }
            }
          })
        }
      } else if (fieldType === 'range') {
        fieldRefs.current[fieldId] = {};
        const rangeKeys = ['From', 'To'];
        rangeKeys.forEach(key => {
          const rangeFieldId = `${key}.${fieldId}`;
          fieldRefs.current[fieldId][key] = fieldRefs.current[fieldId][key] || {};
          fieldRefs.current[fieldId][key].current = fieldRefs.current[fieldId][key].current || {};
          fieldRefs.current[fieldId][key].current[rangeFieldId] = fieldRefs.current[fieldId][key].current[rangeFieldId] || {};
          fieldRefs.current[fieldId][key].current[rangeFieldId].value = fieldData[fieldId] && fieldData[fieldId][key.toLowerCase()] ? fieldData[fieldId][key.toLowerCase()] : null;
        });
      } else {
        let element: any = { value: null };
        if (fieldType === 'geolocation') {
          element = { value: { lon: 0, lat: 0 } }
        }
        fieldRefs.current[fieldId] = element;
        if (typeof fieldData[fieldId] === 'object' && fieldData[fieldId] !== null) {
          if (fieldData[fieldId] instanceof Array) {
            const enumValue = fieldData[fieldId].map((fd: { id: string, label: string } | string) => {
              if (typeof fd === 'string') {
                return fd;
              } else if (fd.hasOwnProperty('id')) {
                return fd.id
              }
              return null;
            })
            fieldRefs.current[fieldId].value = enumValue;
            if (fieldType === 'enum') {
              fieldRefs.current[fieldId].value = fieldData[fieldId].map((value: any) => {
                if (typeof value === 'object' && value.hasOwnProperty('id')) {
                  return value.id;
                }
                return value;
              })
              fieldRefs.current[fieldId].selectedOptionFilter = fieldData[fieldId];
              fieldRefs.current[fieldId].filteredOption = fieldData[fieldId] instanceof Array ? fieldData[fieldId] : (fieldData[fieldId] ? [fieldData[fieldId]] : [])
            }
          } else {
            if (fieldType === 'enum') {
              fieldRefs.current[fieldId].value = fieldData[fieldId].id;
              fieldRefs.current[fieldId].selectedOptionFilter = fieldData[fieldId]
              fieldRefs.current[fieldId].filteredOption = fieldData[fieldId] instanceof Array ? fieldData[fieldId] : (fieldData[fieldId] ? [fieldData[fieldId]] : [])
            } else if (fieldType === 'geolocation') {
              fieldRefs.current[fieldId].value = fieldData[fieldId];
            } else {
              fieldRefs.current[fieldId].value = fieldData[fieldId];
            }
          }
        } else {
          fieldRefs.current[fieldId].value = fieldData[fieldId]
          if (fieldType === 'date' && fieldData[fieldId] === 'today') {
            fieldRefs.current[fieldId].today = true;
            fieldRefs.current[fieldId].value = null;
          }
        }
      }
      // for automation part only
      if (fieldData[fieldId]?.keyPath) {
        if (!fieldRefs?.current[fieldId]?.value) {
          fieldRefs.current[fieldId] = {};
        }
        fieldRefs.current[fieldId].keyPath = fieldData[fieldId].keyPath;
      }
      if (fieldData[fieldId]?.fieldId || fieldData[fieldId]?.sourceRefField) {
        if (!fieldRefs?.current[fieldId]) {
          fieldRefs.current[fieldId] = {};
        }
        fieldRefs.current[fieldId].fieldId = fieldData[fieldId].fieldId;
        fieldRefs.current[fieldId].sourceRefField = fieldData[fieldId].sourceRefField;
      }
    });
    return fieldRefs;
  }

  static setValueToTableFieldReload = (formFields: IField[], data: { [name: string]: any }, valueFieldReload: IField[]) => {
    formFields = formFields.map(field => {
      const updateField = valueFieldReload.filter((upField: IField) => upField.id === field.id);
      if (updateField.length > 0) {
        if (updateField[0].inputConfig && updateField[0].inputConfig.type === 'table') {
          updateField[0].inputConfig.config.value = data[field.id || ''];
        }
        return updateField[0];
      }
      return field;
    });
    return formFields;
  }

}
import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';

export const SET_AUTHENTICATION_CREDENTIAL_ERROR = 'app/settings/AUTHENTICATION_CREDENTIAL_ERROR';
export const CLEAR_AUTHENTICATION_CREDENTIAL_ERROR = 'app/settings/CLEAR_AUTHENTICATION_CREDENTIAL_ERROR';

export const authSettingsActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_AUTHENTICATION_CREDENTIAL_ERROR,
    type: 'negative',
    reducerContainer: 'authSettings',
    reducerItem: 'credentialError',
    hasCloseIcon: true,
  },
]
export const credentialOptions = [
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'phone',
    label: 'Phone'
  },
  {
    id: 'employeeId',
    label: 'Employee ID'
  }
]

export const sessionConfigOptions = [
  {
    id: 'infinite-sessions',
    label: 'Allow infinitive sessions'
  },
  {
    id: 'logout-when-app-close',
    label: 'Log users out when they close the application'
  },
  {
    id: 'logout-specific-time',
    label: 'Log users out after a specific amount of time (minimum 15 min)'
  }
]
export const GET_UNREAD_NOTIFICATION_COUNT = 'app/notificationModule/GET_UNREAD_NOTIFICATION_COUNT';
export const GET_UNREAD_NOTIFICATION_COUNT_RETURN = 'app/notificationModule/GET_UNREAD_NOTIFICATION_COUNT_RETURN';
export const GET_NOTIFICATION = 'app/notificationModule/GET_NOTIFICATION';
export const GET_NOTIFICATION_RETURN = 'app/notificationModule/GET_NOTIFICATION_RETURN';
export const GET_NOTIFICATION_SHOWMORE_RETURN = 'app/notificationModule/GET_NOTIFICATION_SHOWMORE_RETURN';
export const LOCAL_UPDATE_NOTIFICATION_LIST = 'app/notificationModule/LOCAL_UPDATE_NOTIFICATION_LIST';
export const UPDATE_UNREAD_COUNT = 'app/notificationModule/UPDATE_UNREAD_COUNT';
export const MARK_NOTIFICATION_AS_UNREAD = 'app/notificationModule/MARK_NOTIFICATION_AS_UNREAD';
export const MARK_NOTIFICATION_AS_READ = 'app/notificationModule/MARK_NOTIFICATION_AS_READ';
export const MARK_ALL_NOTIFICATION_AS_READ = 'app/notificationModule/MARK_ALL_NOTIFICATION_AS_READ';
export const DELETE_NOTIFICATION = 'app/notificationModule/DELETE_NOTIFICATION';
export const NOTIFICATION_CURRENT_USER_IN_DOCUMENT = 'app/notificationModule/NOTIFICATION_CURRENT_USER_IN_DOCUMENT';
export const CLEAR_NOTIFICATION = 'app/notificationModule/CLEAR_NOTIFICATION';

export const notificationLimit = 20;

export const notificationTabList = [
  {
    id: 'all',
    label: 'All'
  },
  {
    id: 'status-update',
    label: 'Status'
  },
  {
    id: 'document-update',
    label: 'Forms'
  },
  {
    id: 'comments',
    label: 'Comments'
  },
  {
    id: 'sla',
    label: 'SLAs'
  }
]
import { ISenderDetail } from '../form/interface/sender-info'
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_RETURN,
  GET_NOTIFICATION_SHOWMORE_RETURN,
  GET_UNREAD_NOTIFICATION_COUNT,
  GET_UNREAD_NOTIFICATION_COUNT_RETURN,
  LOCAL_UPDATE_NOTIFICATION_LIST,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  UPDATE_UNREAD_COUNT,
  NOTIFICATION_CURRENT_USER_IN_DOCUMENT,
  CLEAR_NOTIFICATION
} from './constant'
import { INotificationDelDetailInput } from './interface/input/notification-del'
import { INotificationGetDetailInput } from './interface/input/notification-get'
import { INotificationUpdateUnreadDetailInput } from './interface/input/notification-update-unread'
import { INotification } from './interface/notification'
import { INotificationResponse, INotificationUnreadResponse } from './interface/response/notification-response'

export const getUnreadNotificationAction = (payload: INotificationGetDetailInput) => {
  return {
    type: GET_UNREAD_NOTIFICATION_COUNT,
    ...payload
  }
}

export const getUnreadNotificationReturnAction = (payload: INotificationUnreadResponse) => {
  return {
    type: GET_UNREAD_NOTIFICATION_COUNT_RETURN,
    ...payload
  }
}

export const getNotificationAction = (payload: INotificationGetDetailInput) => {
  return {
    type: GET_NOTIFICATION,
    ...payload
  }
}

export const getNotificationReturnAction = (payload: INotificationResponse) => {
  return {
    type: GET_NOTIFICATION_RETURN,
    ...payload
  }
}

export const getNotificationShowMoreReturnAction = (payload: INotificationResponse) => {
  return {
    type: GET_NOTIFICATION_SHOWMORE_RETURN,
    ...payload
  }
}

export const markNotificationAsUnreadAction = (payload: INotificationUpdateUnreadDetailInput) => {
  return {
    type: MARK_NOTIFICATION_AS_UNREAD,
    ...payload
  }
}

export const markNotificationAsReadAction = (payload: INotificationUpdateUnreadDetailInput) => {
  return {
    type: MARK_NOTIFICATION_AS_READ,
    ...payload
  }
}

export const markAllNotificationAsReadAction = (payload: INotificationGetDetailInput) => {
  return {
    type: MARK_ALL_NOTIFICATION_AS_READ,
    ...payload
  }
}

export const localUpdateOfNotificationListAction = (notification: INotification[]) => {
  return {
    type: LOCAL_UPDATE_NOTIFICATION_LIST,
    notificationList: notification
  }
}

export const addUnreadCountAction = () => {
  return {
    type: UPDATE_UNREAD_COUNT
  }
}

export const deleteNotificationAction = (payload: INotificationDelDetailInput) => {
  return {
    type: DELETE_NOTIFICATION,
    ...payload
  }
}

export const setCurrentUserInDocumentAction = (payload: { [docId: string]: ISenderDetail[] }) => {
  return { type: NOTIFICATION_CURRENT_USER_IN_DOCUMENT, currentUserInDocument: payload }
}

export const clearNotificationAction = () => {
  return { type: CLEAR_NOTIFICATION }
}
import { AUTH_LOGIN, AUTH_LOGIN_RETURN, AUTH_LOGIN_ERROR, UPDATE_USER_DETAIL } from '../constant';
import { ILoginDetail } from '../interface/input/login';
import { ILoginErrorResponse } from '../interface/response/login-error';
import { ILoginResponse } from '../interface/response/login';
import { ICheckAuthTokenResponse } from '../interface/response/check-auth-token';
import { CHECK_AUTH_TOKEN_RETURN } from '../token-checker/constant';
import { AuthorisedUser } from '../authorize-user';

export const submitLoginAction = (payload: ILoginDetail) => {
  return { type: AUTH_LOGIN, ...payload };
};

export const submitLoginReturnAction = (payload: ILoginResponse) => {
  return { type: AUTH_LOGIN_RETURN, ...payload };
};

export const submitLoginRErrorAction = (payload: ILoginErrorResponse) => {
  return { type: AUTH_LOGIN_ERROR, ...payload };
};

export const checkAuthTokenActionReturn = (payload: ICheckAuthTokenResponse) => {
  return { type: CHECK_AUTH_TOKEN_RETURN, ...payload }
};

export const updateUserDetailAction = (userDetail: AuthorisedUser) => {
  return { type: UPDATE_USER_DETAIL, userDetail: userDetail }
};
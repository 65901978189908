import React from 'react';
import Configuration from './configuration';
import FormBuilder from './form-builder';
import Permission from './permission';
import Workflow from './workflow';
import Form from './form';
import Roles from './roles';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import BusinessRules from './automation';
import BusinessProcessModuleRoute from './module-route';

const ModuleList = [
  new Configuration(),
  new FormBuilder(),
  new Permission(),
  new Roles(),
  new Workflow(),
  new Form(),
  new BusinessRules(),
]

export interface IBusinessProcessRender {
  type: string;
  isCurrentUserOnlyBA?: boolean;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  allowedAccessAuditLogs?: boolean;
}

interface IBusinessProcessModuleRegistry {
  name: string;
  render: React.FC<any>
}

export class BusinessProcessModuleRegistry {

  registry: { [name: string]: IBusinessProcessModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IBusinessProcessRender> = (props) => {
    return <BusinessProcessModuleRoute {...props} />
  }

}
import { IAccessLevelConfig } from './api-permission';
import { IRulesPermission } from './rules';

export interface IStatusFieldPermission {
  fieldId: string;
  accessLevel: string | IAccessLevelConfig;
  config?: IPermissionFieldFilterConfig;
  columnPermissions?: IStatusFieldPermission[];
  consolidateTable?: IConsolidateTableConfig;
}

export interface IFormFieldPermission {
  statusId: string;
  permissions: IStatusFieldPermission[]
}

export interface IPermissionCondition {
  true: string;
  false: string;
}

export interface IPermissionFieldCondition {
  id: string;
  fieldId: string;
  fromBp: string;
  operator: string;
  compareType: string;
  compareValue: string;
  compareField: {
    fieldId: string;
  }
}

export interface IPermissionFieldFilterConfig {
  joinCondition: 'and' | 'or';
  condition: IPermissionFieldCondition[],
  conditionRule: IConditionRulePermission;
}

export enum AccessLevel {
  Hidden = 'hidden',
  Readonly = 'readonly',
  Editable = 'editable',
  Required = 'required'
}

export interface IStatusActionPermission {
  accessLevel: string | IAccessLevelConfig;
  config?: IPermissionFieldFilterConfig;
}

export interface IConditionRulePermission {
  onTrue: IRulesPermission;
  onFalse: IRulesPermission;
  disabledMessage?: string;
}

export interface IConsolidateTableConfig {
  referenceKeyPath: string;
  sourceTableId?: string;
  columns: IConsolidateTableColumn[];
}

export interface IConsolidateTableColumn {
  fromColumnOrFieldId: string;
  toColumnId: string;
  action: ConsolidateFunction;
}

export enum ConsolidateFunction {
  Group = 'group',
  Sum = 'sum',
  Min = 'min',
  Max = 'max',
  Avg = 'avg',
  Count = 'count'
}
import React, { useState, useEffect, Fragment } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../icons';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { StringProps } from '../../../service/type/string';
import { TValidationType } from '../../../service/field-properties.handler';
import { restoreCursorPosition, setCursorPosition } from './utils/cursor-position';

const EmailType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const currentElementid = props.tableColumnRowIndex ? `email-${name}-${props.tableColumnRowIndex}` : `email-${name}`;
  const [refresher, setRefresher] = useState('');
  const [validateTriggered, setValidateTriggered] = useState(false);
  let onTypeTimeout = setTimeout(() => { }, 1000);
  const elementProperties = new FieldProperties(name, props.forwardedRef, new StringProps());

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(uuid());
    setValidateTriggered(true)
    if (props.triggerFilter) props.triggerFilter('');

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ value: defaultValueString })
    }
  }

  const focusInput = (id: string, multiline: boolean) => {
    if (mobile) {
      setFocusedElement(id);
      if (multiline) {
        setRefresher(uuid());
      }
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(uuid());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.isAddRecord
  ])

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span id={refresher} className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            })}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div className={`field-input ui labeled input  single ${props.configName} ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: props.multiline || false,
          required: props.accessType === IFieldAccessType.Required,
          fieldValueObject: elementProperties
        })}`}>
          <div className={`${props.hasIconInside ? 'hidden' : 'i-mail'} ui label`}>@</div>
          <input
            id={currentElementid}
            readOnly={props.accessType === IFieldAccessType.Readonly}
            type="text"
            key={currentElementid}
            onBlur={(event) => {
              if (focusedElement && focusedElement === currentElementid) {
                removeFocusedElement();
              }
              elementProperties.setCurrent({ value: event.target.value });
              validateInput();
              if (props.throwValueOutside) {
                props.throwValueOutside()
              }

              if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
              if (props.automationService && props.fieldCollection) {
                const field = props.fieldCollection.find((e: IField) => e.id === props.id);
                if (field) {
                  props.automationService.didUpdateFieldValue(field, event.target.value)
                }
              }
            }}
            ref={(curRef: any) => {
              if (curRef) {
                if (props.isFocus && refresher === '') {
                  curRef.focus();
                }
                if (focusedElement && focusedElement === currentElementid) {
                  focusSelectedElement(focusedElement);
                }
                elementProperties.setCurrent({ value: elementProperties.current.value, fieldType: 'string' });
                curRef.value = elementProperties.current.value;
                restoreCursorPosition(curRef, name, props.tableId);
                return curRef;
              }
            }}
            autoFocus={props.isFocus}
            onChange={(event) => {
              elementProperties.setCurrent({ value: event.target.value })
              if (props.hasPageChangeRef) {
                props.hasPageChangeRef.current.hasChange = true;
              }
            }}
            onClick={(e: any) => {
              const id = props.tableColumnRowIndex ? `email-${name}-${props.tableColumnRowIndex}` : `email-${name}`;
              if (typeof props.tableColumnRowIndex === 'number') {
                focusInput(id, false);
              } else {
                focusInput(id, false);
              }
              setCursorPosition(e, name, props.tableId);
            }}
            placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
            name={name}
          />
          {props.hasIconInside &&
            <div className='flex-container with-icon'>
              <Image width='15px' src={icons.black.mail} />
            </div>
          }
        </div>
      </>
    }
    {elementProperties.hasError() && props.showErrorMessage &&
      elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
      <Fragment>
        <small style={{ display: 'flex' }} />
        <small className='field error'
          dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
        />
      </Fragment>
    }
  </>
}

export default EmailType;
import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';
import * as jsondiffpatch from 'jsondiffpatch';

export class FXDeltaFields implements DeltaFieldType {

  name = 'fx';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);

    if (delta.agg) {
      targetInstance['agg'] = delta[0] || '';
    }

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      const { newDelta, oldDelta } = this.setDelta(config, targetInstance.config);
      deltaArray = deltaArray.concat(FieldConfigChange.format(newDelta, oldDelta, targetInstance));
    }

    return deltaArray;
  }

  setDelta = (delta: Delta, oldValue: any) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'formula') {
        const { formulaText, oldFormulaText } = this.setFormulaDelta(delta[key], oldValue[key]);
        newDelta['formula'] = formulaText;
        oldDelta['formula'] = oldFormulaText;
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = oldValue[key];
      }
    });

    return { newDelta, oldDelta };
  }

  setFormulaDelta = (delta: Delta, oldValue: any) => {
    let formulaText = [] as string[];
    let oldFormulaText = '';
    const formulaItems = [[...oldValue], jsondiffpatch.patch([...oldValue], delta)];

    if (formulaItems.length > 1) {
      const prevFormula = formulaItems[0];
      const currFormula = formulaItems[1];
      const prevFormulaText = this.setFormulaText(prevFormula).join(' ').toString();
      const currFormulaText = this.setFormulaText(currFormula).join(' ').toString();
      formulaText[0] = prevFormulaText;
      formulaText[1] = currFormulaText;
      oldFormulaText = prevFormulaText;
    }

    return { formulaText, oldFormulaText }
  }

  setFormulaText = (formulaArray: any[]) => {
    return formulaArray.map(formula => {
      let text = formula.value;
      if (formula.type === 'field') {
        text = formula.label;
      }
      return text;
    });
  }
}
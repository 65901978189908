import React, { useEffect, useState } from 'react';
import { Dimmer, Loader, Portal, Tab } from 'semantic-ui-react-bpm';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../reducers';
import ButtonContainer from '../../../../button-container';
import CreatedLabels from './created-labels';
import AppliedLabels from './applied-labels';
import {
  saveFormLabelsErrorAction,
  getFormLabelsAction,
  triggerSaveTypeAction,
  publishFormLabelAction
} from './actions';
import RelationConfigModal from '../bp-relationship/relation-config-modal';
import { IAppliedLabels, IFormLabels } from './interface/label';
import { IFormRelatedField } from '../bp-relationship/interface/bp-relationship-field';
import { formLabelsActionMessage } from './constant';
import { ConfirmationMessage } from '../../../../../../../../common/general/confirmation-modal';
import ActionMessage from '../../../../../../../../common/general/action-message';
import { FormLabelConfig } from './utils/form-label-config';

interface ILabelsContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const LabelsContainer: React.FC<ILabelsContainerProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match: { params: { formId: string } } = useRouteMatch();

  const { company } = useSelector((state: IRootState) => state.auth);
  const {
    fieldList,
    statusList,
    listLoading,
    formLabels,
    formAppliedLabels
  } = useSelector((state: IRootState) => state.configurationLabels);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const [selectedId, setSelectedId] = useState('');
  const [labelData, setLabelData] = useState<IFormLabels[]>([]);
  const [labelToDelete, setLabelToDelete] = useState<IFormLabels>({} as IFormLabels);
  const [appliedLabels, setAppliedLabels] = useState<IAppliedLabels[]>([] as IAppliedLabels[]);
  const [activeMenu, setActiveMenu] = useState('Created Labels');

  const capitalize = (title: string) => {
    return title.toUpperCase();
  }

  const setValidationError = (dataExists: IAppliedLabels, isAllStatus: boolean) => {
    const { component, status } = { ...dataExists };
    let errorMessage = `Applied Label with ${component.length > 1 ? 'components' : 'a component'} of `;
    component.forEach((e, i) => {
      if (component.length > 2) {
        if (i < (component.length - 1)) {
          e = `'${capitalize(e)}', `
        } else {
          e = `and '${capitalize(e)}'`
        }
      } else {
        if (i < (component.length - 1)) {
          e = `'${capitalize(e)}' and `
        } else {
          e = `'${capitalize(e)}'`
        }
      }
      errorMessage += e;
    });
    if (isAllStatus) {
      errorMessage += ` and 'ALL' of its statuses were already selected.`;
    } else {
      errorMessage += ` and ${status.length > 1 ? 'statuses ' : 'a status'} of `;
      status.forEach((e, i) => {
        const details = statusList.filter(s => s.id === e)[0];
        if (details) {
          if (status.length > 2) {
            if (i < (status.length - 1)) {
              e = `'${capitalize(details.name)}', `
            } else {
              e = `and '${capitalize(details.name)}'`
            }
          } else {
            if (i < (status.length - 1)) {
              e = `'${capitalize(details.name)}' and `
            } else {
              e = `'${capitalize(details.name)}'`
            }
          }
        }
        if (e === 'all') {
          e = ` '${capitalize(e)}'`
        }
        errorMessage += e;
      });
      errorMessage += ` already exists.`;
    }
    return errorMessage;
  }

  const editAppliedLabel = (data: IAppliedLabels) => {
    let tempData = [...appliedLabels];
    const dataExists = tempData
      .filter(e => !e.deleted)
      .filter(e => {
        const componentExists = e.component.some(c => data.component.includes(c));
        return e.id !== data.id && componentExists &&
          (e.status.some(s => data.status.includes(s)) ||
            e.status.includes('all'))
      });
    if (dataExists.length > 0) {
      const isAllStatus = dataExists.every(e => e.status.includes('all'));
      const errorMessage = setValidationError(data, isAllStatus);
      triggerErrorMessage(errorMessage);
      return;
    }

    tempData = tempData.map(appliedLabel => {
      if (appliedLabel.id === data.id) {
        appliedLabel = data;
      }
      return appliedLabel;
    });
    setAppliedLabels(tempData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const addNewAppliedLabel = (data: IAppliedLabels) => {
    const tempData = [...appliedLabels];
    tempData.push(data);
    setAppliedLabels(tempData);
  }

  const saveDraft = (saveType: string) => {
    if (activeMenu === 'Created Labels') {
      Promise
        .all(labelData.map(data => FormLabelConfig.saveCreatedLabels(data, { company, formId: match.params.formId })))
        .then(() => {
          dispatch(getFormLabelsAction({ companyId: company, formId: match.params.formId }));
          dispatch(triggerSaveTypeAction(saveType));
        });
    } else {
      Promise
        .all(appliedLabels.map(data => FormLabelConfig.saveAppliedLabels(data, { company, formId: match.params.formId })))
        .then(() => {
          dispatch(getFormLabelsAction({ companyId: company, formId: match.params.formId }));
          dispatch(triggerSaveTypeAction(saveType));
        });
    }
    if (saveType === 'publish') {
      dispatch(publishFormLabelAction({ company: company, formId: match.params.formId }));
    }
    props.hasPageChangeRef.current.hasChange = false;
  }

  const deleteSelectedAppliedLabel = (appliedLabelId: string) => {
    let tempLabelData = [...appliedLabels];
    tempLabelData = tempLabelData.map((appliedLabel: IAppliedLabels) => {
      const tempApplied = { ...appliedLabel };
      if (tempApplied.id === appliedLabelId) {
        tempApplied.deleted = true;
      }
      return tempApplied;
    });
    setAppliedLabels(tempLabelData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteSelectedLabel = () => {
    let tempLabelData = [...labelData];
    tempLabelData = tempLabelData.map((labels: IFormLabels) => {
      const tempLabel = { ...labels };
      if (tempLabel.id === labelToDelete.id) {
        tempLabel.deleted = true;
      }
      return tempLabel;
    });
    setLabelData(tempLabelData);
    setLabelToDelete({} as IFormLabels);
    setSelectedId('');
    props.hasPageChangeRef.current.hasChange = true;
  }

  const fieldValueEqualPrev = (prev: IFormLabels, current: IFormLabels) => {
    const isHeaderEq = JSON.stringify(prev.headerField) === JSON.stringify(current.headerField);
    const isFieldsEq = JSON.stringify(prev.fields) === JSON.stringify(current.fields);
    const isNameEq = prev.name === current.name;
    return isHeaderEq && isFieldsEq && isNameEq;
  }

  const saveFieldConfig = (id: string, headerField: IFormRelatedField[], contentField: IFormRelatedField[], name: string, isNew: boolean) => {
    let tempData = [...labelData];
    let data = {
      id: id,
      name: name,
      headerField: headerField,
      fields: contentField,
      formId: match.params.formId,
      new: isNew,
      deleted: false
    } as IFormLabels;
    tempData = tempData.map((labels: IFormLabels) => {
      let tempLabel = { ...labels };
      if (tempLabel.id === data.id) {
        tempLabel = {
          ...data,
          edited: !data.new ? !fieldValueEqualPrev(tempLabel, data) : false
        };
      }
      return tempLabel;
    });
    setLabelData(tempData);
    setSelectedId('');
    props.hasPageChangeRef.current.hasChange = true;
  }

  const closeSettings = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const setLabelFormModal = (labelData: IFormLabels) => {
    setTimeout(() => {
      const main = document.getElementById('label-module-container');
      const modal: HTMLElement | null = document.getElementById('relation-config-modal');
      if (modal && main) {
        document.body.appendChild(modal);
        if (labelData.new) {
          const addButton: HTMLElement | null = document.getElementById(`selected-relation-new`);
          if (addButton) {
            const rect = addButton.getBoundingClientRect();
            modal.style.left = `calc(${rect.left - 350}px)`;
            modal.style.top = `calc(${rect.top - 50}px + ${window.scrollY}px)`;
            modal.style.position = `absolute`;
          }
        } else {
          const ellipsis: HTMLElement | null = document.getElementById(`selected-relation-icon-${labelData.id}`);
          if (ellipsis) {
            const rect = ellipsis.getBoundingClientRect();
            let left = (rect.right - 350);
            if (left < 0) {
              left = 10
            }
            modal.style.left = `calc(${left}px)`;
            modal.style.top = `calc(${rect.top - 110}px + ${window.scrollY}px)`;
            modal.style.position = `absolute`;
          }
        }
      }
    }, 0);
  }

  const openLabelForm = (data: IFormLabels, actionType: 'edit' | 'create') => {
    const tempData = [...labelData];
    if (data.new && actionType === 'create') {
      tempData.push(data);
      setLabelData(tempData);
    }
    setSelectedId(data.id);
    setLabelFormModal(data);
  }

  const triggerErrorMessage = (message: string) => {
    dispatch(saveFormLabelsErrorAction(message))
  }

  const panes = [
    {
      menuItem: 'Created Labels', render: () => <Tab.Pane attached={false}>
        <CreatedLabels
          openLabelForm={openLabelForm}
          labelData={labelData}
          setLabelToDelete={setLabelToDelete}
          appliedLabelList={appliedLabels}
          triggerErrorMessage={triggerErrorMessage}
        />
      </Tab.Pane>
    },
    {
      menuItem: 'Applied Labels', render: () => <Tab.Pane attached={false}>
        <AppliedLabels
          statusList={statusList}
          labelData={labelData}
          addNewAppliedLabel={addNewAppliedLabel}
          appliedLabelList={appliedLabels}
          editAppliedLabel={editAppliedLabel}
          deleteSelectedAppliedLabel={deleteSelectedAppliedLabel}
          hasPageChangeRef={props.hasPageChangeRef}
        />
      </Tab.Pane>
    },
  ]

  useEffect(() => {
    if (company && match.params.formId) {
      dispatch(getFormLabelsAction({ companyId: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, match.params.formId])

  useEffect(() => {
    const formLabelsList: IFormLabels[] = [...formLabels];
    setLabelData(formLabelsList);
  }, [formLabels]);

  useEffect(() => {
    const appliedLabelsList: IAppliedLabels[] = [...formAppliedLabels];
    setAppliedLabels(appliedLabelsList);
  }, [formAppliedLabels]);

  return (<>
    <ActionMessage messages={formLabelsActionMessage} />
    <div id='label-module-container' style={{ position: 'relative' }} className='labels-module'>
      {
        (listLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <div className='labels-wrapper'>
        <div className='labels-tab-container'>
          <Tab menu={{ secondary: true }} panes={panes} onTabChange={(e: any) => setActiveMenu(e.target.text)} />
        </div>
        <ButtonContainer
          cancel={() => closeSettings()}
          save={() => saveDraft('draft')}
          publish={() => saveDraft('publish')}
        />
      </div>
      <Portal open={selectedId !== ''}>
        <RelationConfigModal
          isHiddenContainer={selectedId === ''}
          setSelectedId={setSelectedId}
          saveFieldConfig={saveFieldConfig}
          selectedId={selectedId}
          selectedData={labelData}
          formFields={fieldList.filter(e => e.type !== 'table')}
          setDataToDelete={setLabelToDelete}
          configuration='Label'
          triggerErrorMessage={triggerErrorMessage}
          hasDelete={false}
          hasCancelAction={true}
          deleteButtonPlacement='header'
        />
      </Portal>
    </div>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      cancelButtonName={'Cancel'}
      confirmButtonColor={'red'}
      cancelButtonColor={'green'}
      close={() => setLabelToDelete({} as IFormLabels)}
      open={labelToDelete && labelToDelete.id !== undefined}
      confirm={() => deleteSelectedLabel()}
      cancel={() => setLabelToDelete({} as IFormLabels)}
      headerText="Confirmation"
      detailText={`${labelToDelete.name} will be deleted. Do you want to proceed?`}
      loading={false}
      type='warning'
    />
  </>)
}

export default LabelsContainer;
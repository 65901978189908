import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ICustomFilterContainer } from '../interface/custom-filter-field';
import { defaultFilterList, systemFieldLabel } from '../constant';

interface IProps {
  customFilterList: ICustomFilterContainer;
  fieldList: ICustomFieldDetail[]
}

export const useSetCustomFilterFieldList = (props: IProps) => {
  const { customFilterList, fieldList } = { ...props };

  const [customFilterFieldList, setCustomFilterFieldList] = useState(defaultFilterList);

  useEffect(() => {
    if (Object.keys(customFilterList).length > 0) {
      let data = {} as ICustomFilterContainer;
      for (let i = 0; i < 8; i++) {
        data[i.toString()] = customFilterList[i.toString()];
        if (typeof customFilterList[i.toString()] === 'object') {
          data[i.toString()] = customFilterList[i.toString()];
          if (data[i.toString()].fields.length > 0) {
            data[i.toString()].fields = data[i.toString()].fields.map((fieldValue: string) => {
              let [type, fieldName, label] = fieldValue.split('|||||');
              let existingField = fieldList.filter((field: ICustomFieldDetail) => field.id === fieldName);
              if (type === 'system') {
                label = systemFieldLabel[fieldName];
              }
              if (type === 'field' && existingField.length > 0) {
                type = existingField[0].type || 'no Type';
                label = existingField[0].label || 'No Label';
              }
              return `${type}|||||${fieldName}|||||${label}`;
            })
          }
        }
      }

      setCustomFilterFieldList(data);
    }
  }, [customFilterList, fieldList]);

  return { ...props, customFilterFieldList, setCustomFilterFieldList };
}
import React, { useEffect, useState } from 'react';
import { Icon, Image } from 'semantic-ui-react-bpm';
import ToolTip from '../../../../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../../../../common/icons';
import { ISection, ITablePermission } from '../../../../interface/section';
import SectionSlider from '../slider/section';

interface ISectionTitle {
  section: ISection;
  isSectionCollapse: boolean;
  setIsSectionCollapse(value: boolean): void;
  handleSectionPermissionChange(forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any): void;
  toggleSectionCollapse(): void;
  openSectionPermissionSettings(section: ISection): void;
}


const SectionTitle: React.FC<ISectionTitle> = (props) => {
  const [icon, setIcon]: any = useState('');

  useEffect(() => {
    if (!props.isSectionCollapse) {
      setIcon('chevron up');
    } else {
      setIcon('chevron down');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSectionCollapse])

  return <div className="section-title" >
    {`${props.section.title ? props.section.title : 'Untitled Section'}`}
    {props.section.accessLevelPerStatus && typeof props.section.accessLevelPerStatus === 'object' ?
      <Image onClick={() => {
        props.openSectionPermissionSettings(props.section);
      }} className='settings-icon' src={icons.blue.cog} />
      :
      <Image onClick={() => {
        props.openSectionPermissionSettings(props.section);
      }} className='settings-icon' src={icons.blue.cogOutline} />
    }
    <ToolTip position='bottom center'
      popupMessage={`${!props.isSectionCollapse ? 'Collapse' : 'Expand'}`}
      popupTriggerComponent={
        <Icon link className='collapse-icon'
          name={icon} size='small' onClick={() => props.setIsSectionCollapse(!props.isSectionCollapse)} />
      }
    />
    {!props.section.accessLevelPerStatus ?
      <div className="ui fitted checkbox" onClick={() => {
        props.toggleSectionCollapse()
      }}>
        <input type="checkbox"
          className="hidden"
          onChange={() => { }}
          checked={(typeof props.section.accessLevelPerStatus !== 'undefined' && props.section.accessLevelPerStatus !== '')}
        />
        <label>Set Access Level Per Section</label>
      </div> :
      <>
        <div className='slider-container'>
          <SectionSlider
            handleSectionPermissionChange={props.handleSectionPermissionChange}
            section={props.section}
          />
        </div>
        <div className="ui fitted checkbox" onClick={() => {
          props.toggleSectionCollapse()
        }}>
          <input type="checkbox"
            className="hidden"
            onChange={() => { }}
            checked={props.section.accessLevelPerStatus !== ''}
          />
          <label></label>
        </div>
      </>

    }
  </div>
}

export default SectionTitle;

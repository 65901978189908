import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS, CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS, CLEAR_CREATE_DATA_TABLE_RECORD_ERROR, CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR, CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS } from '../../../admin-module/module/data-tables/constant';

export const GET_DATATABLE_LIST = 'app/document/datatables/GET_DATATABLE_LIST';
export const GET_DATATABLE_LIST_RETURN = 'app/document/datatables/GET_DATATABLE_LIST_RETURN';

export const DatatableReponseMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true,
  }, {
    dispatchType: CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableRecordMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS,
    type: 'success',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableRecordMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_CREATE_DATA_TABLE_RECORD_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'createDataTableRecordErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR,
    type: 'negative',
    reducerContainer: 'dataTable',
    reducerItem: 'updateDataTableRecordErrorMessage',
    hasCloseIcon: true,
  },
]

export const datatableRecordLimit = 20;
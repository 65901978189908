import { useEffect, useState } from 'react';
import { hideValueAndFieldOption } from '../../../../../../../../../../../../common/utils/field-operator';
import { IAutomationComponent } from '../../../../interface/component-list';
interface IProps {
  selectedComponent: IAutomationComponent;
}

export const useSetComponentToIssueFieldsState = (props: IProps) => {
  const { selectedComponent } = { ...props };

  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedReferenceField, setSelectedReferenceField] = useState('');
  const [selectedSourceRefField, setSelectedSourceRefField] = useState('');
  const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(selectedOperator);
  const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(selectedOperator);
  const [compareType, setCompareType] = useState(hideValueOption ? 'field' : 'value');

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      const formField = selectedComponent.config.sourceField || '';
      const operator = selectedComponent.config.operator || '';
      const compareType = selectedComponent.config.compareType || '';
      const refField = selectedComponent.config.refField || '';
      const sourceRefField = selectedComponent.config.sourceRefField || '';
      setSelectedField(formField)
      setSelectedOperator(operator)
      setSelectedReferenceField(refField)
      setSelectedSourceRefField(sourceRefField)
      const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(operator);
      setCompareType(compareType || (hideValueOption ? 'field' : 'value'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent]);

  return {
    ...props,
    selectedField, setSelectedField,
    selectedOperator, setSelectedOperator,
    selectedReferenceField, setSelectedReferenceField,
    selectedSourceRefField, setSelectedSourceRefField,
    compareType, setCompareType, hideFieldOption, hideValueOption
  }
}
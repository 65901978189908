import React, { useEffect, useState } from 'react';
import { Dropdown, Image } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import { IFieldTimeTrigger, ITimeTriggerField, IValueTimeTrigger } from '../interface/status-task-list';
import DatePicker from "react-datepicker";
import PopperContainer from '../../../../../../../../../common/general/popper-component';
import moment from 'moment';
import { icons } from '../../../../../../../../../common/icons';
import { IReference } from '../../bp-relationship/interface/form';
import { BPSystemField } from '../../../../../../../../../common/custom-field-config/constant';
import { IForm } from '../../../../form/interface/form';
import { useSetFieldOptionFromBP } from '../hooks/use-set-field-option-from-bp';
import { useSetFormAndFieldValueState } from '../hooks/use-set-form-and-field-value-state';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';

interface IFieldValueColumn {
  showColumn: boolean;
  disabled?: boolean;
  triggerFieldData: ITimeTriggerField;
  onTimeTriggerFieldChange(triggerField: any, statusId: string, slaType: "task" | "process"): void;
  statusId: string;
  slaType: 'task' | 'process'
  referenceList: IReference[];
  currentFormId: string;
  formList: IForm[];
  fieldList: ICustomFieldDetail[];
  referencedBPFields: ICustomFieldDetail[];
}

interface IDateOrTimePicker {
  type: 'date' | 'time',
  setTriggerFieldData(data: any): void;
  triggerFieldData: ITimeTriggerField;
}

export interface ISlaFormOption {
  key: number;
  text: string;
  value: string;
  type: string;
}

export interface ISlaFieldOption extends ISlaFormOption {
  id: string;
  source: string;
}

const splitSystemFieldDateId = (id: string, label: string) => {
  if ((id || label).includes('createdAt')) {
    id = 'createdAt';
    label = 'createdAt';
  }
  if ((id || label).includes('updatedAt')) {
    id = 'updatedAt';
    label = 'updatedAt';
  }
  return { id, label };
}

const currentFormFieldOption = (fieldList: ICustomFieldDetail[], currentFormId: string) => {
  console.log("Log ~ fieldList", fieldList);
  if (fieldList.length > 0) {
    return fieldList.filter(e => e.type === 'date' || (e.type === 'autopopulated' && e.config && e.config.labelType === 'date'))
      .map((fields: ICustomFieldDetail, index: number) => {
        return {
          key: index,
          text: fields.label,
          value: fields.id,
          id: fields.id,
          source: currentFormId,
          type: fields.type
        } as ISlaFieldOption;
      })
  }
  return [];
}

const DateOrTimePicker: React.FC<IDateOrTimePicker> = (props) => {
  let selectedValue = null;
  let value = props.triggerFieldData && props.triggerFieldData.valueConfig && props.triggerFieldData.valueConfig.value;

  if (value && value !== 'Invalid date' && value !== undefined) {
    selectedValue = new Date(moment(value).format("YYYY-MM-DD HH:mm:ssZ"))
  }

  return <div className='field-date-time-picker-main'>
    <div className='field-date-time-picker'>
      <DatePicker
        popperContainer={PopperContainer}
        showYearDropdown={true}
        showMonthDropdown={true}
        selected={selectedValue}
        placeholderText='Select date/time'
        showTimeSelect
        dropdownMode="select"
        dateFormat='dd MMMM yyyy h:mm a'
        onChange={(date: any) => props.setTriggerFieldData({
          ...props.triggerFieldData,
          valueConfig: {
            ...props.triggerFieldData.valueConfig,
            value: moment(date).format("YYYY-MM-DD HH:mm:ssZ")
          } as IValueTimeTrigger
        })}
      />
    </div>
    <Image title='Cancel' className='close' src={icons.blue.closeCircle}
      onClick={() => props.setTriggerFieldData({
        ...props.triggerFieldData,
        valueConfig: {} as IValueTimeTrigger
      })} />
  </div>
}

const FieldValueColumn: React.FC<IFieldValueColumn> = (props) => {
  const { formList, fieldList, referencedBPFields } = { ...props };

  const [triggerFieldData, setTriggerFieldData] = useState({} as ITimeTriggerField);
  const [currentFormRefFields, setCurrentFormRefFields] = useState([] as ICustomFieldDetail[]);
  const { fieldValue, formValue } = useSetFormAndFieldValueState({
    currentFormRefFields,
    triggerFieldData
  });
  const { fieldListOption } = useSetFieldOptionFromBP({
    BPSystemField,
    currentFormFieldOption,
    currentFormId: props.currentFormId,
    currentFormRefFields,
    formValue,
    referencedBPFields,
    splitSystemFieldDateId,
    fieldList
  });

  const formListOption = () => {
    const optionList = [] as ISlaFormOption[];
    const currentForm = formList.find(e => e.id === props.currentFormId);
    const bpReference = props.referenceList.filter(ref => ref.dataType === 'document');
    let ctr = 0;
    if (currentForm) {
      const exist = optionList.find(e => e.value === currentForm.id);
      if (!exist) {
        optionList.push({
          key: ctr++,
          text: currentForm.name,
          value: currentForm.id || '',
          type: 'document'
        });
      }
    }
    bpReference.forEach(reference => {
      const exist = optionList.find(e => e.value === reference.id);
      if (!exist) {
        optionList.push({
          key: ctr++,
          text: reference.name,
          value: reference.id || '',
          type: reference.dataType
        });
      }
    });
    return optionList;
  }

  const onFormListChange = (value: string, data: ITimeTriggerField, options: ISlaFormOption[]) => {
    onTriggerFieldChange({
      ...data,
      fieldConfig: {
        ...data.fieldConfig,
        fieldId: '',
        fromBP: value,
        reference: {}
      } as IFieldTimeTrigger
    });
  }

  const onTriggerFieldChange = (data: ITimeTriggerField, value?: string, options?: ISlaFieldOption[]) => {
    const selected = options && options.find(e => e.value === value);
    let triggerField = { ...data };
    if (selected) {
      if (selected.source !== props.currentFormId) {
        triggerField = {
          ...triggerField,
          fieldConfig: {
            ...triggerField.fieldConfig,
            reference: {
              refFieldId: selected.id,
              keyPath: selected.value
            }
          } as IFieldTimeTrigger
        }
      }
    }
    setTriggerFieldData(triggerField);
    props.onTimeTriggerFieldChange(triggerField, props.statusId, props.slaType);
  }

  useEffect(() => {
    if (props.showColumn && props.triggerFieldData) {
      setTriggerFieldData(props.triggerFieldData);
    }
    // eslint-disable-next-line
  }, [props.showColumn, props.triggerFieldData]);

  useEffect(() => {
    setCurrentFormRefFields(fieldList.filter(e =>
      e.configName === IConfigName.Referenced && e.config
      && !e.config.multiselect));
    // eslint-disable-next-line
  }, [fieldList]);

  if (!props.showColumn) {
    return <></>;
  }

  return <td className={`sla-column field-value`}>
    <div className='field-value-main'>
      <div className='field-value-header'>
        <span className='menu-line' />
        <div className='field-value-menu'>
          <div className={`value-menu ${triggerFieldData && triggerFieldData.triggerType === 'value' ? 'selected' : ''}`}
            onClick={() => onTriggerFieldChange({
              ...triggerFieldData,
              triggerType: 'value'
            })}>
            <span className='label'>Value</span>
            {triggerFieldData && triggerFieldData.triggerType === 'value' &&
              <span className='underline' />
            }
          </div>
          <div className={`field-menu ${triggerFieldData && triggerFieldData.triggerType === 'field' ? 'selected' : ''}`}
            onClick={() => onTriggerFieldChange({
              ...triggerFieldData,
              triggerType: 'field'
            })}>
            <span className='label'>Field</span>
            {triggerFieldData && triggerFieldData.triggerType === 'field' &&
              <span className='underline' />
            }
          </div>
        </div>
      </div>
      <div className='field-value-content'>
        {triggerFieldData && triggerFieldData.triggerType === 'field' ?
          <div className='field' id='trigger-time-field'>
            <Dropdown
              className={`select-field ${props.disabled ? 'disabled' : ''}`}
              popperContainer={DropdownPopperOption}
              selectOnBlur={false}
              clearable
              placeholder='Select a field'
              options={fieldListOption}
              selection
              onChange={(e, target: any) => onTriggerFieldChange({
                ...triggerFieldData,
                fieldConfig: {
                  ...triggerFieldData.fieldConfig,
                  fieldId: target.value
                } as IFieldTimeTrigger
              }, target.value, target.options)}
              search
              value={fieldValue}
            />
            <Dropdown
              className={`from-field ${props.disabled ? 'disabled' : ''}`}
              popperContainer={DropdownPopperOption}
              selectOnBlur={false}
              clearable
              placeholder='from'
              options={formListOption()}
              selection
              onChange={(e, target: any) => onFormListChange(target.value, {
                ...triggerFieldData,
                fieldConfig: {
                  ...triggerFieldData.fieldConfig,
                  fromBP: target.value
                } as IFieldTimeTrigger
              }, target.options)}
              search
              value={formValue}
            />
          </div>
          :
          <div className='value'>
            <DateOrTimePicker
              triggerFieldData={triggerFieldData}
              setTriggerFieldData={onTriggerFieldChange}
              type={(triggerFieldData.valueConfig && triggerFieldData.valueConfig.fieldType) || 'time'}
            />
          </div>
        }
      </div>
    </div>
  </td>
}

export default FieldValueColumn;
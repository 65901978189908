import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';

export class NumberDeltaFields implements DeltaFieldType {

  name = 'number';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);

    if (delta.agg) {
      targetInstance['agg'] = delta[0] || '';
    }

    if (Object.keys(delta).length > 0) {
      const { newDelta, oldDelta } = this.setDelta(delta, targetInstance)
      deltaArray = FieldConfigChange.format(newDelta, oldDelta, targetInstance);
    }

    if (Object.keys(config).length > 0) {
      const configDelta = this.setConfigDelta(config, targetInstance.config);
      const { newDelta, oldDelta } = this.setDelta(configDelta.newDelta, configDelta.oldDelta);
      deltaArray = deltaArray.concat(FieldConfigChange.format(newDelta, oldDelta, targetInstance));
    }

    return deltaArray;
  }

  setConfigDelta = (delta: Delta, oldValue: Delta) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (Array.isArray(delta[key])) {
        if (key === 'itemConfig') {
          if (JSON.stringify(delta[key][0]) !== JSON.stringify(oldValue)) {
            Object.keys(delta[key][0]).forEach(childKey => {
              if (delta[key][0][childKey] !== oldValue[childKey]) {
                newDelta[childKey] = [oldValue[childKey], delta[key][0][childKey]];
                oldDelta[childKey] = oldValue[childKey];
              }
            });
          }
        }
        if (delta[key].length < 3 && key !== 'itemConfig' && key !== 'itemType') {
          newDelta[key] = delta[key];
          oldDelta[key] = oldValue[key];
        }
      }
    });
    return { newDelta, oldDelta };
  }

  setDelta = (delta: Delta, oldValue: Delta) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'defaultValue') {
        const data = this.setDefaultValue(delta[key]);
        if (data) {
          newDelta[key] = data;
          oldDelta[key] = oldValue[key];
        }
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = oldValue[key];
      }
    });

    return { newDelta, oldDelta };
  }

  setDefaultValue = (delta: Delta) => {
    let newDelta = {} as Delta;
    if (delta) {
      if (Array.isArray(delta)) {
        newDelta = delta.map(val => {
          if (Array.isArray(val)) {
            return val.toString() || null;
          }
          return val;
        });
      } else {
        newDelta = delta;
      }
    }

    if (Array.isArray(delta)) {
      const isAllNull = newDelta.every((e: string) => !e);
      return isAllNull ? null : newDelta;
    }

    return newDelta;
  }
}
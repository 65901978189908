import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IReference } from '../../../../../configuration/module/bp-relationship/interface/form';
import { IAutomationComponent } from '../../interface/component-list';
import { IPortal } from '../../interface/portals';
import { IAutomationInputFieldList, IAutomationPublishStatusList } from '../../reducer';
import { ManageFieldOptions } from './manage-options';

export interface IutilProps {
  dispatch: any;
  referenceList: IReference[];
  company: string;
  inputFieldList: IAutomationInputFieldList;
  datatableInputField: IAutomationInputFieldList;
  publishedFormStatusList: IAutomationPublishStatusList;
  formFieldList: ICustomFieldDetail[];
  portals?: IPortal[];
}

export class AutomationUtils {
  static manageFieldOption = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    ManageFieldOptions.browseSelectedComponent(selectedComponent, utils)
  }
}
import { v4 as uuid } from 'uuid';
import { ArrayFieldProperties } from '../../../../service/array-field-properties.service';
import { TValidationType } from '../../../../service/field-properties.handler';
import { FieldProperties } from '../../../../service/field-properties.service';
import { NumberProps } from '../../../../service/type/number';

export interface IValidateInput {
  name: string;
  validationSchemaProps: any;
  multiline: boolean;
  multilineCount?: number;
  forwardedRef: any;
  inputConfig: any;
  labelProps: string;
  requiredProps: boolean;
  setRefresher?(value: string): void;
  setProperty?(): void;
  setValidateTriggered?(value: boolean): void;
  triggerFilter?(value: string): void;
  triggerFieldReload?(value: string[], fieldId?: string): void;
  reloadOnChange?: string[];
  accessType?: string;
  valueFieldClass?: any;
  tableId?: string;
}

export const validateInput = (data: IValidateInput) => {
  const { setRefresher, name, validationSchemaProps, multiline, multilineCount,
    inputConfig, setValidateTriggered, setProperty, labelProps, reloadOnChange,
    triggerFieldReload, accessType, valueFieldClass, tableId } = { ...data };
  if (multiline && multilineCount) {
    let tempValue: ArrayFieldProperties<NumberProps> = valueFieldClass;
    if (setProperty) {
      setProperty();
    }
    for (let i: number = 0; i < multilineCount; i++) {
      tempValue.validate({
        id: name,
        label: labelProps,
        inputConfig: inputConfig,
        validationSchema: { ...validationSchemaProps },
        multiline: multiline,
        accessType: accessType
      } as any, TValidationType.onBlur)
    }
  } else {
    let tempValue: FieldProperties<NumberProps> = valueFieldClass;
    if (setProperty) {
      setProperty();
    }
    tempValue.validate({
      id: name,
      label: labelProps,
      inputConfig: inputConfig,
      validationSchema: { ...validationSchemaProps },
      multiline: multiline,
      accessType: accessType
    } as any, TValidationType.onBlur)
  }
  let hasTriggerState = false;
  if (setValidateTriggered) {
    setValidateTriggered(true);
  }
  if (reloadOnChange && reloadOnChange.length > 0 && triggerFieldReload) {
    triggerFieldReload(reloadOnChange, !tableId ? name : tableId);
    hasTriggerState = true;
  }
  if (!hasTriggerState && setRefresher) {
    setRefresher(uuid());
  }
}
import React, { useState, useEffect, CSSProperties, Fragment } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, setTableFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { StringProps } from '../../../service/type/string';
import { TValidationType } from '../../../service/field-properties.handler';
import { restoreCursorPosition, setCursorPosition } from './utils/cursor-position';
import { IConfigName } from '../../../../type/interface/field-type-object';

const LineType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const name = props.getFieldId();
  const focusedElement = getFocusedElement();
  const currentElementId = typeof props.tableColumnRowIndex === 'number' ? `string-${name}-${props.tableColumnRowIndex}` : `string-${name}`;

  const [refresher, setRefresher] = useState(0);
  const [validateTriggered, setValidateTriggered] = useState(false);

  const elementProperties = new FieldProperties(name, props.forwardedRef, new StringProps());

  if (props.isClearValue) {
    elementProperties.setCurrent({ value: '' });
  }

  const validateInput = (schema: any) => {
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(refresher + 1);
    setValidateTriggered(true);

    if (props.triggerFilter) props.triggerFilter('');
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange, name);
    }

  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ value: defaultValueString })
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const convertStringCase = (value: string) => {
    const stringCase = props.inputConfig && props.inputConfig.config && props.inputConfig.config.case;
    if (value && typeof value === 'string' && stringCase) {
      if (stringCase === 'lowercase') {
        value = (value).toLowerCase();
      }
      if (stringCase === 'uppercase') {
        value = (value).toUpperCase();
      }
    }
    return value;
  }

  const fieldStyle = (): CSSProperties => {
    let style = {} as CSSProperties;
    if (props.hasInlineStyle) {
      const headerElement = document.getElementById(`header-${name}`);
      if (headerElement) {
        let { width } = headerElement.getBoundingClientRect();
        width = width < 72 ? 72 : width;
        style = { maxWidth: `${width}px` }
      } else {
        style = { maxWidth: `${72}px` }
      }
    }
    return style;
  }

  const triggerUpdateAutopopField = (value: any) => {
    if (props.accessType === IFieldAccessType.ReadWrite && props.configName === IConfigName.Autopopulated) {
      if (props.triggerFieldUpdate && props.fieldCollection) {
        const field = props.fieldCollection.find((e: IField) => e.id === props.id);
        if (field) {
          if (field.inputConfig && field.inputConfig.config) {
            field.inputConfig.config.value = value;
          }
          props.triggerFieldUpdate(field)
        }
      }
    }
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(refresher + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.isAddRecord
  ])

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput({ ...props.validationSchema, minLength: 1, isRequired: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info' >
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            })}`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className={`icon info-icon ${name}`} title={props.hint}></i>
          }
        </span>
        <div style={fieldStyle()} className={`field-input ui input single ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: props.multiline || false,
          required: props.accessType === IFieldAccessType.Required,
          fieldValueObject: elementProperties
        })}`}>
          <input
            type="text"
            id={currentElementId}
            key={currentElementId}
            className={`input-${name} ${props.inputConfig && props.inputConfig.config && props.inputConfig.config.case ? props.inputConfig.config.case : ''}`}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (props.handleOnKeyPress) props.handleOnKeyPress(e);
            }}
            onBlur={(event) => {
              triggerUpdateAutopopField(event.target.value);
              elementProperties.setCurrent({ value: convertStringCase(event.target.value) })
              if (focusedElement && focusedElement === currentElementId) {
                removeFocusedElement();
              }
              validateInput({
                ...props.validationSchema,
                isRequired: props.accessType === IFieldAccessType.Required
              })
              if (props.throwValueOutside) {
                props.throwValueOutside()
              }
              if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
              if (props.automationService && props.fieldCollection) {
                const field = props.fieldCollection.find((e: IField) => e.id === props.id);
                if (field) {
                  if (field.inputConfig && field.inputConfig.config) {
                    field.inputConfig.config.value = event.target.value;
                  }
                  props.automationService.didUpdateFieldValue(field, event.target.value)
                }
              }
            }}
            readOnly={props.accessType === IFieldAccessType.Readonly}
            ref={(curRef: any) => {
              if (curRef) {
                if (props.isFocus && !refresher) {
                  curRef.focus();
                }
                if (focusedElement && focusedElement === currentElementId) {
                  focusSelectedElement(focusedElement);
                }
                curRef.style.setProperty('text-align', props.inputConfig.config ? props.inputConfig.config.alignment || 'left' : 'left', 'important');
                curRef.fieldType = 'string';
                curRef.value = elementProperties.current.value;
                elementProperties.setCurrent({ value: convertStringCase(elementProperties.current.value), fieldType: 'string' });
                restoreCursorPosition(curRef, name, props.tableId);
                return curRef;
              }
            }}
            onChange={(event) => {
              elementProperties.setCurrent({ value: convertStringCase(event.target.value) })
              if (props.hasPageChangeRef) {
                props.hasPageChangeRef.current.hasChange = true;
              }
            }}
            autoFocus={props.isFocus}
            onClick={(e: any) => {
              focusInput(currentElementId);
              setCursorPosition(e, name, props.tableId);
            }}
            placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
            name={name}
          />
        </div>
        {elementProperties.hasError() && props.showErrorMessage &&
          elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
          <Fragment>
            <small style={{ display: 'flex' }} />
            <small id={`error-${name}`} className='field error'
              dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
            />
          </Fragment>
        }
      </>
    }
  </>
}

export default React.memo(LineType, (prevProps: AcceptedProps, nextProps: AcceptedProps) => {
  return prevProps.forwardedRef.current[prevProps.id].value === nextProps.forwardedRef.current[nextProps.id].value
});
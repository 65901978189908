import React, { useEffect, useState } from 'react';
import { UploadedFile } from '../../api-request/upload';
import { Image } from 'semantic-ui-react-bpm';
import { getFileExtension } from '../../utils/get-file-extension';

const FileViewer: React.FC = () => {
  const [attachmentData, setAttachmentData] = useState({} as UploadedFile);
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    let attachment = {} as UploadedFile;
    const selectedAttachment = sessionStorage.getItem('selectedAttachment');

    if (selectedAttachment) {
      const parsedAttachment = JSON.parse(selectedAttachment) as UploadedFile;
      const ext = getFileExtension(parsedAttachment.name).toLowerCase();
      let newEncodedUrl = parsedAttachment.url;
      let type = '';

      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif' || ext === 'tiff') {
        type = 'image'
      }
      if (ext === 'pdf') {
        newEncodedUrl = parsedAttachment.url;
        type = 'pdf'
      }
      if (ext === 'doc' || ext === 'docx' || ext === 'xls' || ext === 'xlsx' || ext === 'ppt' || ext === 'pptx' || ext === 'xlsm') {
        newEncodedUrl = encodeURIComponent(parsedAttachment.url);
        type = 'office'
      }
      if (ext === 'txt') {
        newEncodedUrl = parsedAttachment.url;
        type = 'txt'
      }

      attachment = { ...parsedAttachment, url: newEncodedUrl }

      setFileType(type);
      setAttachmentData(attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage])

  return <div className='file-viewer-container'>
    {fileType === 'office' && (
      <iframe title={attachmentData.name}
        src={`https://view.officeapps.live.com/op/view.aspx?src=${attachmentData.url}`}
        width="100%" height="100%" frameBorder="0" />
    )}
    {(fileType === 'image') && (
      <div className='image-wrapper' >
        <Image src={attachmentData.url} height="100%" />
      </div>
    )}
    {(fileType === 'pdf' || fileType === 'txt') && (
      <iframe title={attachmentData.name}
        src={attachmentData.url}
        width="100%" height="100%" frameBorder="0" />
      // <iframe title={attachmentData.name}
      //   src={`https://docs.google.com/gview?embedded=true&amp&url=${attachmentData.url}`}
      //   width="100%" height="100%" frameBorder="0" />
    )}
  </div >
}

export default FileViewer;
import { useEffect } from 'react';
import {
  getFormFieldAction,
  getPublishFormAction,
  getReferenceListAction,
  getAutomationlistAction,
  getInputFieldAction,
  getSlaStatusAction,
  getAutomationInputFieldAction,
  getAutomationPlaceholdersAction,
} from '../action';
import { getFormStatusListAction } from '../../../../workflow/action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetAutomationData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (company && formId) {
      dispatch(getAutomationlistAction({
        company: company,
        formId: formId
      }))
      dispatch(getFormStatusListAction({
        companyId: company,
        formId: formId
      }))
      dispatch(getFormFieldAction({
        company: company,
        formId: formId,
      }))
      dispatch(getPublishFormAction({
        company: company
      }))
      dispatch(getReferenceListAction({
        company: company,
        formId: formId
      }))
      dispatch(getInputFieldAction({
        companyId: company,
        formId: formId,
        statusId: ''
      }))
      dispatch(getSlaStatusAction({
        companyId: company,
        formId: formId
      }));
      dispatch(getAutomationInputFieldAction({
        company: company,
        formId: formId,
      }))
      dispatch(getAutomationPlaceholdersAction({
        company: company,
        formId: formId,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId]);
}
import React, { useEffect, useRef, useState } from 'react';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from '../registry';
import { Dropdown } from 'semantic-ui-react-bpm';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IField, IFieldAccessType } from '../../../../../../../../../../users/interface/field';
import { v4 as uuid } from 'uuid';
import { useRouteMatch } from 'react-router-dom';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import SelectedFieldSection from './fields';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { DetailActionButton } from '../../../action-button';
import { FieldConstruct } from '../../../../../../../../../../../../../common/field/field-construct';
import { FormEnumLabel } from '../../../../../../../../../../../../document-module/module/form/utils/manage-enum-id-label';
import { CreateUserUtils } from '../../../../utils/manage-options/action/create-user';

class CreateUser {
  name = 'create-user';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    const dynamicRef = useRef<any>({});
    const dispatch = useDispatch();
    const match: { params: { formId: string } } = useRouteMatch();

    const { userFieldListDynamic, userFieldListStatic, userProfileList } = useSelector((state: IRootState) => state.user);
    const { portals } = useSelector((state: IRootState) => state.automation);
    const { company } = useSelector((state: IRootState) => state.auth);

    const [selectedField, setSelectedField] = useState<string[]>([]);
    const [operation, setOperation] = useState<{ [id: string]: string }>({});
    const [refresher, setRefresher] = useState(0);
    const [searchField, setSearchField] = useState('');
    const [dateFieldObject, setDateFieldObject] = useState<{ [id: string]: boolean }>({});
    const [profileIds, setProfileIds] = useState<string[]>([]);
    const [portalId, setPortalId] = useState<string>();
    const [userType, setUserType] = useState<'internal' | 'external'>();

    const defaultFields = userFieldListStatic.filter(field => field.inputConfig && field.inputConfig.config.format !== 'email');
    const defaultRequiredFields = userFieldListStatic.filter(field => field.inputConfig && field.inputConfig.config.format === 'email');

    const getUserProfileFieldOption = () => {
      return userProfileList.map(profile => {
        return {
          key: profile.id,
          text: profile.name,
          value: profile.id
        }
      });
    }

    const getPortalFieldOption = () => {
      return portals.map(e => {
        return {
          key: e.id,
          text: e.name,
          value: e.id
        }
      });
    }

    const getUserCreationOption = () => {
      return [
        { key: 1, text: 'External', value: 'external' },
        { key: 2, text: 'Internal', value: 'internal' }
      ];
    }

    const getRequiredCustomFieldOption = () => {
      const requiredCustomField = userFieldListDynamic.filter((field: IField) => field.accessType === IFieldAccessType.Required);
      const staticFields = defaultRequiredFields.map(field => {
        field.accessType = IFieldAccessType.Required;
        return field;
      });
      return [...staticFields, ...requiredCustomField];
    }

    const getSelectedCustomFieldOption = () => {
      return [...defaultFields, ...userFieldListDynamic].filter(e => selectedField.includes(e.id || '')
        && e.accessType === IFieldAccessType.ReadWrite);
    }

    const getSelectedFieldList = (fieldList: string[]) => {
      const fields: IField[] = [];
      fieldList.forEach((id: string) => {
        const field = [...userFieldListStatic, ...userFieldListDynamic].find((e: IField) => e.id === id);
        if (field) {
          fields.push(field)
        }
      })
      return fields;
    }

    const refreshComponentToChangeButton = () => {
      if (props.hasComponentChange.current === false) {
        setRefresher(refresher + 1)
        props.parentRefresher();
      }
    }

    const storeDynamicField = () => {
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const manageOperator = (value: string, fieldId: string) => {
      const tempContainer: { [id: string]: string } = {};
      getSelectedFieldList(selectedField).forEach((field: IField) => {
        const tempFieldId = field.id || ''
        const tempOperation = tempFieldId === fieldId ? value || 'value' : operation[tempFieldId] || 'value';
        if (tempOperation === 'value') {
          dynamicRef.current[fieldId] = null;
        }
        tempContainer[tempFieldId] = tempOperation;
      });
      setOperation(tempContainer);
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const openModal = (value: boolean, fieldId: string) => {
      if (value) {
        storeDynamicField();
      }
      const tempContainer: { [id: string]: boolean } = props.refsObject.current.isVisible ? props.refsObject.current.isVisible : {};
      getSelectedFieldList(selectedField).forEach((field: IField) => {
        tempContainer[field.id || ''] = field.id === fieldId ? !value : false;
      });
      props.refsObject.current.isVisible = tempContainer;
      setRefresher(refresher + 1)
    }

    const manageDateFieldToday = (value: boolean, fieldId: string) => {
      let tempDateFieldObj = { ...dateFieldObject };
      tempDateFieldObj[fieldId] = value;
      setDateFieldObject(tempDateFieldObj);
      storeDynamicField();
    }

    const openFieldDropdown = () => {
      props.refsObject.current.editFormSelectFieldOpen = true;
      openModal(false, 'none');
      setRefresher(refresher + 1);
    }

    const closeFieldDropdown = () => {
      props.refsObject.current.editFormSelectFieldOpen = false;
      setRefresher(refresher + 1);
      setSearchField('');
    }

    const manageSelectedField = (value: string) => {
      refreshComponentToChangeButton();
      const tempSelectedField = [...selectedField]
      const currentIndex = tempSelectedField.indexOf(value);
      if (currentIndex > -1) {
        tempSelectedField.splice(currentIndex, 1);
        delete dynamicRef.current[value];
      } else {
        tempSelectedField.push(value);
      }
      setSelectedField(tempSelectedField)
      props.hasComponentChange.current = true;
    }

    const onFieldSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, fieldId: string) => {
      manageSelectedField(fieldId);
      e.stopPropagation();
      if (searchField) {
        closeFieldDropdown();
      }
    }

    const additionalFieldToShow = () => {
      const additionalEnums: any = [];
      if (userType === 'internal') {
        additionalEnums.push({
          key: 'user-profile-field',
          label: 'User Type',
          value: profileIds,
          multiple: true,
          options: getUserProfileFieldOption(),
          error: profileIds.length === 0,
          onChangeValue: setProfileIds,
        });
      }
      if (userType === 'external') {
        additionalEnums.push({
          key: 'portal-type-field',
          label: 'Portal',
          value: portalId,
          multiple: false,
          error: portalId === undefined,
          options: getPortalFieldOption(),
          onChangeValue: setPortalId,
        });
      }
      return additionalEnums.map((field: any) => {
        return <div key={field.key} className={`automation-field-container with-button select-field-container`}>
          <div className={`field automation-field required full`}>
            <span className='field-info'>
              <label className={`field-label`}>{field.label}</label>
            </span>
            <Dropdown
              className={field.error ? 'error' : ''}
              fluid
              selection
              clearable
              search
              selectOnBlur={false}
              value={field.value}
              multiple={field.multiple}
              options={field.options}
              onChange={(event, target: any) => {
                field.onChangeValue(target.value);
                props.hasComponentChange.current = true;
              }}
            />
          </div>
        </div>
      });
    }

    const getFieldDataFromRefs = () => {
      let fields = FieldConstruct.getFieldDataFromRefs(getSelectedFieldList(selectedField) || [], dynamicRef);
      const hasEnumSelected = getSelectedFieldList(selectedField).filter(e => e.configName === IConfigName.Dropdown);
      if (hasEnumSelected.length > 0) {
        fields = FormEnumLabel.getEnumIdLabel(fields, dynamicRef, getSelectedFieldList(selectedField) || []);
      }
      selectedField.forEach(fieldId => {
        if (dateFieldObject[fieldId]) {
          fields[fieldId] = 'today';
        }
      });
      return Object.entries(fields).reduce((field: any, [key, value]: any) => {
        field[key] = value;
        if (value && typeof value === 'object' && (value.hasOwnProperty('keyPath') || value.hasOwnProperty('referencedKeyPath'))) {
          if (value.referencedKeyPath === 'current-form') {
            field[key] = { keyPath: value.keyPath }
          }
        }
        return field;
      }, {});
    }

    const selectFieldValueText = () => {
      let value = '';
      const fields = getSelectedCustomFieldOption();
      if (fields.length > 0) {
        const first = fields[0].label;
        value = `${first} ${fields.length > 1 ? `+${fields.length - 1}` : ''}`
      } else {
        value = 'Select Non Mandatory Fields';
      }
      return value;
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.active = true;
        tempComponent.config.fields = getFieldDataFromRefs();
        tempComponent.config.portalId = userType === 'external' ? portalId : undefined;
        tempComponent.config.profileIds = userType === 'internal' ? profileIds : undefined;
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    useEffect(() => {
      if (props.selectedComponent) {
        let tempDateFieldObj = {} as { [id: string]: boolean };

        const fields = props.selectedComponent.config.fields || {};
        const tempContainer: { [id: string]: string } = {};
        const tempSelectedField = Object.keys(fields) || [];
        const tempProfileIds = props.selectedComponent.config.profileIds || [];
        const initalSelectedFields = getRequiredCustomFieldOption().map(field => field.id || '');
        const selectedFields = Array.from(new Set(tempSelectedField.concat(initalSelectedFields)));
        const portalId = props.selectedComponent.config.portalId;

        selectedFields.forEach((id: string) => {
          if (fields[id] && (fields[id].hasOwnProperty('keyPath') || fields[id].hasOwnProperty('referencedKeyPath'))) {
            tempContainer[id] = 'copy';
          } else {
            tempContainer[id] = 'value';
          }
        });
        setUserType(portalId ? 'external' : 'internal');
        setProfileIds(tempProfileIds);
        setPortalId(portalId);
        setSelectedField(selectedFields.length > 0 ? selectedFields : initalSelectedFields);
        setOperation(tempContainer);
        setDateFieldObject(tempDateFieldObj);
        getSelectedFieldList(selectedFields).forEach(field => {
          if (field.inputConfig && field.inputConfig.type === 'date' && fields[field.id || '']) {
            tempDateFieldObj[field.id || ''] = fields[field.id || ''] === 'today';
          }
        });
        FieldConstruct.setFieldToRefs(getSelectedFieldList(selectedFields), { fields }, dynamicRef);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedComponent])

    return (<>
      <div className={`automation-form-detail-action create-user-action`}>
        <AutomationDetailHeader
          title={`Create User`}
          description={`Rule is run when Dynamic Action is executed.`}
          iconHeight={20}
          iconWidth={18}
          iconName='createUser'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field`}>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={`Select if user will be external/internal`}
              multiple={false}
              selectOnBlur={false}
              value={userType}
              options={getUserCreationOption()}
              onChange={(event, target: any) => {
                setUserType(target.value);
                if (target.value === 'external') {
                  CreateUserUtils.getPortals(props.selectedComponent, {
                    portals,
                    company,
                    dispatch,
                  })
                }
              }}
            />
          </div>
          <div className={`automation-field`}>
            <Dropdown
              text={selectFieldValueText()}
              icon='dropdown'
              floating
              labeled
              button
              className={`icon ${getSelectedCustomFieldOption().length ? 'value' : ''}`}
              popperContainer={DropdownPopperOption}
              onClick={openFieldDropdown}
              open={props.refsObject?.current?.editFormSelectFieldOpen}
              search
              onSearchChange={(e: any) => setSearchField(e.target.value)}
              onClose={closeFieldDropdown}
            >
              <Dropdown.Menu className={`${props.refsObject.current.editFormSelectFieldOpen === true ? 'visible' : ''}`}>
                <div className={`option-title`}>Common Field</div>
                <Dropdown.Menu scrolling className={`option-list`}>
                  {[...defaultFields, ...userFieldListDynamic].filter(e => e.accessType === IFieldAccessType.ReadWrite)
                    .map((field: IField, index: number) => {
                      if (field && field.label && field.label.toUpperCase().indexOf(searchField.toUpperCase()) === -1) {
                        return undefined;
                      }
                      return <div key={uuid()} className="ui fitted checkbox" onClick={(e) => onFieldSelect(e, field.id || '')}>
                        <input className="hidden" type="checkbox" onChange={() => { }} checked={selectedField.indexOf(field.id || '') > -1} />
                        <label>{field.label}</label>
                      </div>
                    })
                  }
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <SelectedFieldSection
            dynamicRef={dynamicRef}
            company={company}
            storeDynamicField={storeDynamicField}
            openModal={openModal}
            operation={operation}
            manageOperator={manageOperator}
            selectedComponent={props.selectedComponent}
            manageDateFieldToday={manageDateFieldToday}
            dateFieldObject={dateFieldObject}
            fieldList={getRequiredCustomFieldOption()}
            formId={match.params.formId}
            refsObject={props.refsObject}
            additionalFieldToShow={additionalFieldToShow()}
            headerName='Mandatory Fields'
          />
          <SelectedFieldSection
            dynamicRef={dynamicRef}
            company={company}
            storeDynamicField={storeDynamicField}
            openModal={openModal}
            operation={operation}
            manageOperator={manageOperator}
            selectedComponent={props.selectedComponent}
            manageDateFieldToday={manageDateFieldToday}
            dateFieldObject={dateFieldObject}
            fieldList={getSelectedCustomFieldOption()}
            formId={match.params.formId}
            refsObject={props.refsObject}
            headerName='Non Mandatory Fields'
          />
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>)
  }
}

export default CreateUser;
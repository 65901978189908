import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { TRulesPermission, TConfigType } from '..';
import { IFieldPermissionSettings } from '../../..';
import { getFromRefFieldListAction } from '../../../../../../../../../../common/custom-field-config/filter-configuration/action';
import { FieldValue } from '../../../../../../../../../../common/custom-field-config/filter-configuration/field-value';
import { IConfig, ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { FieldConstruct } from '../../../../../../../../../../common/field/field-construct';
import { IConfigName } from '../../../../../../../../../../common/field/type/interface/field-type-object';
import { IOption } from '../../../../../../../../../../common/interface/option';
import { checkIfUUID } from '../../../../../../../../../../common/utils/check-uuid-value';
import { IRootState } from '../../../../../../../../../../reducers';
import { FormEnumLabel } from '../../../../../../../../../document-module/module/form/utils/manage-enum-id-label';
import { IField } from '../../../../../../../users/interface/field';
import { IConditionRulePermission } from '../../../../interface/field-permission';
import { IComposeSetValuePermission, IRulesPermission, ISetValuePermission } from '../../../../interface/rules';
import { getRangeFieldValue } from '../../utils/get-range-field-value';

type TActionType = 'value' | 'field' | 'assemble';

const toActionType: { [name: string]: TActionType } = {
  'value': 'value',
  'compose': 'assemble',
  'source': 'field',
  'fieldId': 'field'
}

interface IValueConfigProps extends IFieldPermissionSettings {
  boolSelected: boolean;
  rules: IRulesPermission;
  updateConfig(configType: TConfigType, configOption: TRulesPermission, value: any): void;
  defaultvalueRef: any;
  conditionRule: IConditionRulePermission;
}

class ValueConfig {

  name = 'setValue'

  render: React.FC<IValueConfigProps> = (props) => {

    const dispatch = useDispatch();

    const { company } = useSelector((state: IRootState) => state.auth);
    const { refSourceFieldList } = useSelector((state: IRootState) => state.filterConfiguration);

    const [valueSelectedField, setValueSelectedField] = useState<string[]>([]);
    const [separator, setSeparator] = useState<'space' | 'custom'>('space');
    const [customSeparator, setCustomSeparator] = useState('');
    const [fieldSelected, setFieldSelected] = useState('');
    const [fieldRefSelected, setFieldRefSelected] = useState('');

    const [configData, setConfigData] = useState<IConfig>({} as IConfig);
    const [actionValue, setActionValue] = useState<TActionType>('value');

    const getReferenceBpDetail = (): { id: string, name: string } => {
      if (Object.keys(props.currentBP).length > 0) {
        return { id: props.currentBP.id || '', name: props.currentBP.name };
      }
      return {} as { id: string, name: string };
    }

    const getReferenceFieldOption = () => {
      let options: IOption[] = [];
      props.fieldList.forEach(field => {
        if (field.configName === IConfigName.Referenced && field.id !== props.fieldData.id) {
          options.push({
            key: `${field.id}`,
            value: `${field.id}`,
            text: field.label || '',
            type: 'referenced'
          });
        }
        if (props.selectedTableSection && props.selectedTableSection.id === field.id) {
          props.selectedTableSection.fields.forEach(e => {
            const exists = options.find(o => o.value === e.id);
            if (!exists && e.id !== props.fieldData.id && e.configName === IConfigName.Referenced) {
              options.push({
                key: `${e.id}`,
                value: `${field.id}.${e.id}`,
                text: `[${field.label}] ${e.label}`,
                type: 'tableReferenced'
              });
            }
          })
        }
      });
      return options;
    }

    const getSelectedField = (data: { fieldId: string, fromBp: string }): ICustomFieldDetail => {
      if (data.fieldId && data.fieldId !== '') {
        let fieldConfig: ICustomFieldDetail = Object.assign({});;
        if (props.currentBP.id === data.fromBp) {
          let fieldList = [...props.fieldList];
          if (props.isTablePermission && props.selectedFieldSection && props.selectedFieldSection.fields.length > 0) {
            fieldList = fieldList.concat(props.selectedFieldSection.fields);
          }
          const config: any = fieldList.find((field: ICustomFieldDetail) => field.id === data.fieldId);
          if (config && typeof config === 'object') {
            fieldConfig = { ...config };
          }
        }
        if (fieldConfig && fieldConfig.type && fieldConfig.config) {
          if (fieldConfig.type === 'enum' && fieldConfig.config) {
            fieldConfig.config = {
              ...fieldConfig.config,
              dataType: fieldConfig.config.dataType
            };
          }
          if (fieldConfig.type === 'autopopulated' && fieldConfig.config) {
            fieldConfig = {
              ...fieldConfig,
              type: fieldConfig.config.labelType
            }
          }
          return fieldConfig;
        }
      }
      return {} as ICustomFieldDetail
    }

    const getComposeValueFieldOption = (): IOption[] => {
      let options: IOption[] = [];
      props.fieldList.forEach((field: ICustomFieldDetail | IField) => {
        if (field.configName !== 'Seq' && field.id !== props.fieldData.id && field.configName !== IConfigName.Table) {
          const exists = options.find(o => o.value === field.id);
          if (!exists) {
            options.push({
              key: uuid(),
              text: `${field.label}`,
              value: `fields.${field.id}`
            })
          }
        }
        if (props.isTablePermission && props.selectedTableSection && props.selectedTableSection.fields.length > 0
          && props.selectedTableSection.id === field.id) {
          props.selectedTableSection.fields.forEach((col: ICustomFieldDetail) => {
            const exists = options.find(o => o.value === col.id);
            if (!exists) {
              options.push({
                key: uuid(),
                text: `[${field.label}] ${col.label}`,
                value: `fields.${field.id}.${col.id}`
              });
            }
          });
        }
      });
      const metadataFields = [
        {
          key: `urn`,
          value: `urn`,
          text: `URN`, 
        },
        {
          key: `seq`,
          value: `seq`,
          text: `Sequence`, 
        }
      ]
      return [...metadataFields, ...options];
    }

    const handleConfigChange = (name: 'value' | 'compose' | 'source', value: any) => {
      let tempConfig = {} as ISetValuePermission;
      tempConfig[name] = value;
      props.updateConfig(props.boolSelected ? 'onTrue' : 'onFalse', 'setValue', tempConfig);
    }

    const handleThrowValueOutside = (valueId: string) => {
      let tempCurrentField: any = props.fieldData ? { ...props.fieldData } : { id: '' };
      tempCurrentField = {
        ...tempCurrentField,
        id: valueId,
        inputConfig: {
          config: tempCurrentField.config,
          type: tempCurrentField.type
        }
      }
      if (tempCurrentField.type === 'range') {
        const tempValue = getRangeFieldValue(props.defaultvalueRef.current[valueId], valueId);
        handleConfigChange('value', tempValue);

      } else {
        let tempValue = props.defaultvalueRef.current
          && props.defaultvalueRef.current[valueId]
          && props.defaultvalueRef.current[valueId].value
          ? props.defaultvalueRef.current[valueId].value
          : '';
        if (props.defaultvalueRef.current
          && props.defaultvalueRef.current[valueId]
          && typeof props.defaultvalueRef.current[valueId] === 'string') {
          tempValue = props.defaultvalueRef.current[valueId];
        }
        const fieldData = { [valueId]: tempValue };
        const fields = FormEnumLabel.getEnumIdLabel(fieldData, props.defaultvalueRef, [tempCurrentField]);
        tempValue = fields[valueId];
        handleConfigChange('value', tempValue);
      }
    }

    const getFormFieldOption = (): IOption[] => {
      let ctr = 0;
      const options: IOption[] = [];
      const refSelected = getReferenceFieldOption().find(e => e.value === fieldRefSelected);
      if (refSelected) {
        let selectedField = props.fieldList.find((field: ICustomFieldDetail) => field.id === fieldRefSelected);
        if (refSelected.type === 'tableReferenced' && props.selectedTableSection) {
          const [tableId, columnId] = fieldRefSelected.split('.');
          if (tableId === props.selectedTableSection.id) {
            selectedField = props.selectedTableSection.fields.find((field: ICustomFieldDetail) => field.id === columnId);
          }
        }
        if (selectedField) {
          const { config } = { ...selectedField };
          if (config && config.data && selectedField.configName === IConfigName.Referenced) {
            if (config.dataType === 'user') {
              if (props.userFieldList && props.userFieldList.length > 0) {
                props.userFieldList.forEach((field: IField) => {
                  options.push({
                    key: ctr++,
                    text: field.label || '',
                    value: field.id || '',
                  });
                });
              }
            } else {
              const sourceId = config.dataType === 'database' ? config.data.databaseId : config.data.formId;
              if (refSourceFieldList[sourceId]) {
                refSourceFieldList[sourceId].forEach((field: ICustomFieldDetail) => {
                  if (field.configName !== IConfigName.Seq && field.configName !== IConfigName.GAP) {
                    options.push({
                      key: ctr++,
                      text: field.label || '',
                      value: field.id || '',
                    })
                  }
                });
              }
            }
          }
        }
      } else {
        props.fieldList.forEach(field => {
          if (field.configName !== IConfigName.Table && field.id !== props.fieldData.id) {
            options.push({
              key: ctr++,
              text: field.label || '',
              value: field.id || '',
              type: 'field'
            })
          }
          if (props.selectedTableSection && props.selectedTableSection.id === field.id) {
            props.selectedTableSection.fields.forEach(e => {
              const exists = options.find(o => o.value === e.id);
              if (!exists && e.id !== props.fieldData.id) {
                options.push({
                  key: `${e.id}`,
                  value: `${field.id}.${e.id}`,
                  text: `[${field.label}] ${e.label}`,
                  type: 'tableField'
                });
              }
            })
          }
        });
      }
      return options;
    }

    const getRefSourceField = (fieldId: string, type?: string) => {
      let selectedField = props.fieldList.find((field: ICustomFieldDetail) => field.id === fieldId);
      if (type === 'tableReferenced' && props.selectedTableSection) {
        const [tableId, columnId] = fieldId.split('.');
        if (tableId === props.selectedTableSection.id) {
          selectedField = props.selectedTableSection.fields.find((field: ICustomFieldDetail) => field.id === columnId);
        }
      }
      if (selectedField && selectedField.configName === IConfigName.Referenced) {
        const { config } = { ...selectedField };
        if (config && config.data) {
          let noSourceFields = config.dataType === 'database' ? (!refSourceFieldList[config.data.databaseId])
            : (!refSourceFieldList[config.data.formId]);
          if (noSourceFields) {
            dispatch(getFromRefFieldListAction({
              company: company,
              dataType: config.dataType,
              databaseId: config.data.databaseId,
              formId: config.data.formId
            }));
          }
        }
      }
    }

    const splitKeypathValue = (keyPath: string) => {
      if (keyPath && keyPath.indexOf('fields.') > -1) {
        return keyPath.split('.')[1];
      }
      return keyPath;
    }


    const triggerOptionGet = () => {
      let trigger = false;
      const selectedField = getSelectedField({
        fieldId: props.fieldData.id || '',
        fromBp: props.currentBP.id || ''
      })
      if (checkIfUUID(selectedField.id as string)) {
        if (selectedField.configName === IConfigName.Referenced) {
          trigger = true;
        }
        if (selectedField.configName === IConfigName.Autopopulated
          && selectedField.config && selectedField.config.labelType === 'enum') {
          trigger = true;
        }
      }
      return trigger;
    }

    useEffect(() => {
      let field = { ...props.fieldData };
      let config = {
        ...field.config,
        dataType: field.config && field.config.dataType === 'user' ? 'user' : 'document'
      } as IConfig;
      setConfigData(config);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fieldData]);

    useEffect(() => {
      if (props.conditionRule) {
        Object.keys(props.conditionRule).forEach((key: string) => {
          const tempKey = key === 'onTrue' ? 'trueValue' : 'falseValue';
          const { setValue } = { ...props.conditionRule as any }[key];
          const { value }: any = { ...setValue };
          const tempCurrentField: any = props.fieldData ? {
            ...props.fieldData,
            inputConfig: { type: props.fieldData.type }
          } : { id: '' };
          tempCurrentField.id = tempKey;
          const formDocument = { fields: { [tempKey]: value ? value : '' } };
          FieldConstruct.setFieldToRefs([tempCurrentField], formDocument, props.defaultvalueRef);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fieldData])

    useEffect(() => {
      const valueId = props.boolSelected ? 'trueValue' : 'falseValue';
      if (props.rules) {
        if (props.rules.setValue) {
          const initial = Object.keys(props.rules.setValue).find(key => !!{ ...props.rules.setValue as any }[key]) as keyof ISetValuePermission;
          const { compose, source } = { ...props.rules.setValue };
          setActionValue(toActionType[initial]);
          if (source) {
            const { keyPath, referencedKeyPath } = source;
            if (keyPath && referencedKeyPath) {
              const fieldId = keyPath.split('fields.')[1];
              const field = getReferenceFieldOption().find(e => e.value === fieldId);
              if (field) {
                getRefSourceField(fieldId, field.type);
              }
              setFieldSelected(referencedKeyPath ? splitKeypathValue(referencedKeyPath) : '');
              setFieldRefSelected(fieldId);
            }
            if (!referencedKeyPath && keyPath && !fieldRefSelected) {
              const fieldId = keyPath.split('fields.')[1];
              setFieldSelected(fieldId);
            }
          }
          if (compose) {
            if (compose.separator === ' ') {
              setSeparator('space');
              setCustomSeparator('');
            } else {
              setSeparator('custom');
              setCustomSeparator(compose.separator);
            }
            if (compose.fieldIds) {
              compose.keyPaths = compose.fieldIds.map(id => {
                if (!id.startsWith('fields.')) {
                  id = `fields.${id}`;
                }
                return id;
              })
            }
            if (compose.keyPaths) {
              setValueSelectedField(compose.keyPaths);
            }
          }
        } else {
          setFieldSelected('');
          setSeparator('space');
          setValueSelectedField([]);
          setCustomSeparator('');
          if (props.defaultvalueRef.current[valueId]) {
            props.defaultvalueRef.current[valueId] = { value: '' };
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rules]);

    const viewValue = (valueId: string) => {
      const selectedField = getSelectedField({
        fieldId: props.fieldData.id || '',
        fromBp: props.currentBP.id || ''
      })
      const parentFormFieldId = props.isTablePermission && selectedField.isTableField ? props.selectedFieldSection.id : undefined;
      const configType = props.isTablePermission && selectedField.isTableField ? 'tableConfig' : undefined;

      return <div className={`condition-value`}>
        <FieldValue.Render
          field={selectedField}
          forwardedRef={props.defaultvalueRef}
          dispatch={dispatch}
          referenceBp={getReferenceBpDetail()}
          companyId={company}
          currentFormField={{
            ...props.fieldData,
            config: {
              dataType: configData.dataType || '',
            } as IConfig
          }}
          valueId={valueId}
          configType={configType}
          parentFormFieldId={parentFormFieldId}
          throwValueOutside={() => handleThrowValueOutside(valueId)}
          fromPermission={triggerOptionGet()}
          currentFieldList={props.fieldList}
        />
      </div>
    }

    const viewField = () => {
      return <div className={`condition-field`}>
        <Dropdown
          fluid
          selection
          search
          clearable
          selectOnBlur={false}
          placeholder='Select source BP/DT'
          options={getReferenceFieldOption()}
          value={fieldRefSelected}
          onChange={(event, target: any) => {
            setFieldRefSelected(target.value);
            setFieldSelected('');
            handleConfigChange('source', { keyPath: `fields.${target.value}` });
            if (target.value) {
              const { type } = target.options.find((e: IOption) => e.value === target.value);
              if (type) {
                getRefSourceField(target.value, type);
              }
            }
          }}
          className='referenced-dropdown'
        />
        <Dropdown
          fluid
          selection
          search
          clearable
          selectOnBlur={false}
          placeholder='Select field'
          options={getFormFieldOption()}
          value={fieldSelected}
          onChange={(event, target: any) => {
            const [mainId] = fieldRefSelected.split('.');
            let selectedField = props.fieldList.find((field: ICustomFieldDetail) => field.id === mainId);
            if (selectedField) {
              if (selectedField.configName === IConfigName.Referenced) {
                handleConfigChange('source', {
                  keyPath: `fields.${fieldRefSelected}`,
                  referencedKeyPath: `fields.${target.value}`
                });
              }
              if (props.selectedTableSection && props.selectedTableSection.id === mainId) {
                handleConfigChange('source', {
                  keyPath: `fields.${fieldRefSelected}`,
                  referencedKeyPath: `fields.${target.value}`
                });
              }
            } else {
              handleConfigChange('source', { keyPath: `fields.${target.value}` });
            }
            setFieldSelected(target.value);
          }}
        />
      </div>
    }

    const viewAssemble = () => {
      return <div className={'assemble-config'}>
        <div className={`header-detail`}>
          <span>{props.fieldData.label}</span>
          <div className={`field-selection`}>
            <Dropdown
              fluid
              selection
              search
              clearable
              selectOnBlur={false}
              placeholder='Select field'
              options={getComposeValueFieldOption().filter((e: IOption) => valueSelectedField.indexOf(e.value) === -1)}
              value={''}
              onChange={(event, target) => {
                const tempSelected = [...valueSelectedField];
                const value = target.value?.toString();
                if (value) {
                  tempSelected.push(value);
                  setValueSelectedField(tempSelected);
                  handleConfigChange('compose', {
                    keyPaths: tempSelected,
                    separator: separator === 'space' ? ' '
                      : separator === 'custom' ? customSeparator : undefined
                  } as IComposeSetValuePermission);
                }
              }}
            />
          </div>
        </div>
        <div className={`selected-field-container`}>
          {valueSelectedField.map((fieldId: string, idx: number) => {
            const field = getComposeValueFieldOption().find((f: IOption) => f.value === fieldId);
            if (field) {
              const label = field.text || '';
              return <div key={idx} className={`selected-field`}>
                {label} <i className={`icon delete`} onClick={(e) => {
                  const tempSelected = [...valueSelectedField];
                  const index = tempSelected.indexOf(field.value || '');
                  tempSelected.splice(index, 1);
                  setValueSelectedField(tempSelected);
                  handleConfigChange('compose', {
                    keyPaths: tempSelected,
                    separator: separator === 'space' ? ' '
                      : separator === 'custom' ? customSeparator : undefined
                  } as IComposeSetValuePermission);
                  e.stopPropagation();
                }} />
              </div>;
            }
            return undefined;
          })}
        </div>
        <div className={`properties`} >
          <label>Separator:</label>
          <div className="ui fitted checkbox" onClick={() => {
            setSeparator('space');
            handleConfigChange('compose', {
              keyPaths: valueSelectedField,
              separator: ' '
            } as IComposeSetValuePermission);
          }}>
            <input className="hidden" type="checkbox" onChange={() => { }}
              checked={separator === 'space'}
              value="" />
            <label>Space</label>
          </div>
          <div>
            <div className="ui fitted checkbox" onClick={() => {
              if (customSeparator) {
                handleConfigChange('compose', {
                  keyPaths: valueSelectedField,
                  separator: customSeparator
                } as IComposeSetValuePermission);
              }
              setSeparator('custom')
            }}>
              <input className="hidden" type="checkbox" onChange={() => { }}
                checked={separator === 'custom'}
                value="" />
              <label>Custom</label>
            </div>
            <input type="text"
              onChange={(e) => {
                setCustomSeparator(e.target.value);
                handleConfigChange('compose', {
                  keyPaths: valueSelectedField,
                  separator: e.target.value
                } as IComposeSetValuePermission);
              }}
              value={customSeparator} />
          </div>

        </div>
      </div>
    }

    return <div className={`value-config`}>
      <div className={`type-selection`}>
        <span onClick={() => setActionValue('value')}
          className={`${actionValue === 'value' ? 'selected' : ''}`}>Value</span>
        <span onClick={() => setActionValue('field')}
          className={`${actionValue === 'field' ? 'selected' : ''}`}>Field</span>
        <span onClick={() => setActionValue('assemble')}
          className={`${actionValue === 'assemble' ? 'selected' : ''}`}>Assemble</span>
      </div>
      {actionValue === 'value' && viewValue(props.boolSelected ? 'trueValue' : 'falseValue')}
      {actionValue === 'field' && viewField()}
      {actionValue === 'assemble' && viewAssemble()}
    </div>
  }

}

export default ValueConfig;
import React from 'react';
import { IFormula } from '../../interface/formula';
import { v4 as uuid } from 'uuid';
import SelectedFormula from './selected-formula';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';

interface IFormulaProps {
  formula: IFormula[];
  updateFormulaInput(currentValue: string, currentIndex: number): void;
  displayFieldList: ICustomFieldDetail[];
}

const Formula: React.FC<IFormulaProps> = (props) => {
  return <div className='formula-contaner'>
    <label className='label'>Formula <strong> * </strong> </label>
    <div className='selected-formula'>
      {
        props.formula.map((e: IFormula, index: number) => {
          return <SelectedFormula
            key={uuid()}
            formula={e}
            index={index}
            updateFormulaInput={props.updateFormulaInput}
            displayFieldList={props.displayFieldList}
          />
        })
      }
    </div>
  </div>
}

export default Formula;
import React, { useRef, useState } from 'react';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from '../registry';
import { DetailActionButton } from '../../../action-button';
import { IAutomationPlaceholder } from '../../../../../interface/automation';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { Editor } from '@tinymce/tinymce-react';
import ConfigService from '../../../../../../../../../../../../../common/config';
import { ParseEmailBody } from '../send-email/utils/parse-email-body';
import SendSMSComponent from './send-sms';
import { v4 as uuid } from 'uuid';
import { useSetSendSMSStateRefs } from './hooks/use-set-send-sms-state-ref';
import { IAutomationComponent } from '../../../../../interface/component-list';
import { SendSMSUtils } from '../../../../utils/manage-options/action/send-sms';
import { checkIfUUID } from '../../../../../../../../../../../../../common/utils/check-uuid-value';

export interface IComputeField {
  id: string;
  selectedField: string
  selectedOperator: string;
  value: string;
  new?: boolean;
  deleted?: boolean;
}

class SendSMS {

  name = 'send-sms';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let valueTimeout = setTimeout(() => { }, 200);
    let onComponentLeave = setTimeout(() => { }, 1000);

    const dispatch = useDispatch();
    const match: { params: { formId: string } } = useRouteMatch();
    const {
      inputFieldList,
      referenceList,
      datatableInputField,
      formFieldList,
      automationPlaceholders
    } = useSelector((state: IRootState) => state.automation);
    const { company } = useSelector((state: IRootState) => state.auth);

    const [recipients, setRecipients] = useState([''] as any[]);
    const [refresher, setRefresher] = useState(0);
    const [operation, setOperation] = useState<{ [id: string]: any }>({});

    const editorRef = useRef<any>(null);

    const phoneFieldRefValue = useRef<any>({});

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent && tempComponent.config) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.to = parseConfig();
        tempComponent.config.message = editorRef && editorRef.current && editorRef.current.editor ?
          ParseEmailBody.fromHTMLString({ text: editorRef.current.editor.getContent({ format: 'text' }), automationPlaceholders })
          : '';
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    const parseConfig = () => {
      return recipients.map((e: any) => {
        if (e && typeof e === 'object' && e.fieldId === 'document-creator') {
          e = { placeholder: e.fieldId };
        }
        if (e && typeof e === 'object' && (e.hasOwnProperty('referencedKeyPath') || e.hasOwnProperty('keyPath'))) {
          if (e.referencedKeyPath === 'current-form') {
            e = { keyPath: e.keyPath };
          }
          Object.keys(e).forEach(key => {
            const values = e[key];
            if (checkIfUUID(values) && values.indexOf('fields.') === -1) {
              e[key] = `fields.${values}`;
            }
          })
        }
        return e;
      })
    }

    const refreshComponentToChangeButton = () => {
      if (props.hasComponentChange.current === false) {
        setRefresher(refresher + 1)
        props.parentRefresher();
      }
    }

    const storeDynamicField = () => {
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const openModal = (value: boolean, index: number) => {
      if (value) {
        storeDynamicField();
      }
      const tempContainer: { [id: string]: any } = { [index]: !value };
      props.refsObject.current.isVisible = tempContainer;
      setRefresher(refresher + 1);
    }

    const manageOperator = (value: string, index: number) => {
      const tempContainer: any = { ...operation };
      tempContainer[index] = value;
      updateRecipient(null, index);
      setOperation(tempContainer);
      props.hasComponentChange.current = true;
    }

    const removeOperator = (recipients: any[]) => {
      const tempContainer: any = {};
      recipients.forEach((e, i) => {
        let operation = 'copy';
        if (typeof e === 'string') {
          operation = 'value';
        }
        tempContainer[i] = operation;
      });
      setOperation(tempContainer);
    }

    const removeRecipient = (index: number) => {
      const tempRecipients = [...recipients];
      tempRecipients.splice(index, 1);
      removeOperator(tempRecipients);
      setRecipients(tempRecipients);
      storeDynamicField();
    }

    const addOperator = (index: number) => {
      const tempContainer: any = { ...operation };
      tempContainer[index] = 'value';
      setOperation(tempContainer);
    }

    const addRecipient = () => {
      const tempRecipients = [...recipients];
      tempRecipients.push('');
      setRecipients(tempRecipients);
      addOperator(tempRecipients.length - 1);
      storeDynamicField();
    }

    const updateRecipient = (data: any, index: number) => {
      const tempRecipients = [...recipients];
      tempRecipients[index] = data;
      setRecipients(tempRecipients);
      storeDynamicField();
    }

    const getOption = (selectedComponent: IAutomationComponent) => {
      SendSMSUtils.getOptions(selectedComponent, {
        dispatch,
        referenceList,
        datatableInputField,
        company: company,
        inputFieldList,
        publishedFormStatusList: {},
        formFieldList
      });
    }

    const getMatchedPlaceholderFields = (query?: string) => {
      let results = automationPlaceholders.filter(placeholder => {
        if (query) {
          return placeholder.uiPlaceholder.indexOf(query) > -1;
        }
        return true;
      });
      results.sort((a: IAutomationPlaceholder, b: IAutomationPlaceholder) => {
        let x = a.uiPlaceholder.toLowerCase();
        let y = b.uiPlaceholder.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      return results;
    }

    const triggerAutocompleterScroll = (e: any) => {
      const autocompleter = document.querySelector('.tox-collection__group');
      if (autocompleter) {
        const elements = Array.from(autocompleter.children)
        const visibleElements = elements.filter((el: any) => {
          let visible = false;
          const rect = el.getBoundingClientRect();
          const autocompleterRect = autocompleter.getBoundingClientRect();
          if (rect) {
            const top = rect.top;
            const bottom = rect.bottom;
            visible = (top >= autocompleterRect.top && bottom <= autocompleterRect.bottom);
          }
          return visible;
        });
        const lastVisibleElement = visibleElements[visibleElements.length - 1];
        const firstVisibleElement = visibleElements[0];
        if (e.key === 'ArrowDown') {
          if (lastVisibleElement.className.indexOf('tox-collection__item--active') > -1) {
            autocompleter.scrollTop = autocompleter.scrollTop += 38;
          }
        }
        if (e.key === 'ArrowUp') {
          if (firstVisibleElement.className.indexOf('tox-collection__item--active') > -1) {
            autocompleter.scrollTop = autocompleter.scrollTop -= 38;
          }
        }
      }
    }

    useSetSendSMSStateRefs({
      setOperation,
      selectedComponent: props.selectedComponent,
      company,
      setRefresher,
      refresher,
      setRecipients,
      phoneFieldRefValue
    });

    return (<>
      <div className={`automation-form-detail-action send-sms-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Send SMS`}
          description={`Rule is run when Dynamic Action is executed.`}
          iconHeight={20}
          iconWidth={20}
          iconName='sendSMS'
        />
        {recipients.map((data, index) => {
          return <SendSMSComponent
            key={uuid()}
            index={index}
            list={recipients}
            data={data}
            isVisible={props.refsObject.current.isVisible || {}}
            openModal={openModal}
            manageOperator={manageOperator}
            operation={operation}
            selectedBP={match.params.formId}
            updateRecipient={updateRecipient}
            getOption={getOption}
            phoneFieldRefValue={phoneFieldRefValue}
            addNewRecipient={addRecipient}
            removeRecipient={removeRecipient}
            selectedComponent={props.selectedComponent}
          />
        })}
        <div className={`automation-field-container`}>
          <div className={`automation-field email-body`}>
            <label>Body:</label>
            <Editor
              apiKey={ConfigService.loadConfig().general.tinymceKey}
              onChange={(event) => {
                props.hasComponentChange.current = true;
                setRefresher(refresher + 1);
              }}
              ref={editorRef}
              init={{
                height: 450,
                statusbar: false,
                menubar: false,
                toolbar: "fields",
                //eslint-disable-next-line
                noneditable_regexp: /\{\{[^\}]+\}\}/g,
                plugins: "code link lists noneditable",
                content_style: 'body { font-size: 13px; margin: 11px }' +
                  'p { margin-block-start: 3px; margin-block-end: 3px; }' +
                  `.mceNonEditable {
                    background-color: #D6F0FF;
                    padding: 1px 0;
                    color: #44719B;
                    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                    font-size: 11px !important;
                  }`,
                extended_valid_elements: 'span[field-selected-id|class|style|id]',
                force_br_newlines: true,
                deprecation_warnings: false,
                setup: (editor: any) => {
                  editor.on('init', () => {
                    document.body.id = 'automation-send-email';
                    if (props.selectedComponent && props.selectedComponent.config) {
                      const { message }: any = { ...props.selectedComponent.config };
                      const parsed = ParseEmailBody.toHTMLString({ text: message || '', automationPlaceholders: automationPlaceholders });
                      const chars = parsed.split('\n');
                      const parsedChars = chars.map((text, idx) => {
                        const next = chars[idx + 1];
                        const prev = chars[idx - 1];
                        if (!text && !next && !prev) {
                          return '<br />'
                        }
                        return text ? `<p>${text}</p>` : ''
                      }).join('')
                      if (typeof valueTimeout === 'function') {
                        clearTimeout(valueTimeout);
                      }
                      valueTimeout = setTimeout(() => {
                        editor.setContent(parsedChars);
                      }, 200);
                    }
                  });
                  editor.on('remove', () => {
                    document.body.removeAttribute('id');
                  });
                  editor.on('keydown', (e: any) => {
                    const node = editor.selection.getNode();
                    const isAutoCompleter = parseInt(node.getAttribute('data-mce-autocompleter')) === 1;
                    if (isAutoCompleter) {
                      triggerAutocompleterScroll(e);
                    }
                  });
                  editor.ui.registry.addMenuButton("fields", {
                    text: 'Fields',
                    tooltip: 'Insert Fields',
                    fetch: (callback: any) => {
                      const items = getMatchedPlaceholderFields().map((placeholder) => {
                        return {
                          type: 'menuitem',
                          text: placeholder.uiPlaceholder,
                          onAction: () => {
                            editor.insertContent(`{{${placeholder.uiPlaceholder}}}`);
                          }
                        }
                      });
                      callback(items);
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    )
  }
}

export default SendSMS
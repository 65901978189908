import axios from 'axios';
import {
  IEnumLocalFilter,
  IField,
  IInputConfig,
  IOptionValue,
  ISortEnumConfig
} from '../../../../../../component/admin-module/module/users/interface/field';
import { setFieldInput } from '../../../../../utils/trigger-field-reload';
import { AcceptedProps } from '../../interface/accepted-props';
import {
  EnumItemsDataSource,
  EnumItemsResult
} from "../../../../../../component/document-module/module/form/automation/components/action/filter-enum/enum-items-data-source";

export interface ITriggerFilterOptions {
  companyId: string,
  forwardedRef: any;
  id: string;
  filter: string;
  type: string;
  url: string;
  selectedValues: any;
  requiresFieldData: string[];
  currentFieldList: IField[];
  hasAllOption: boolean;
  fieldEnumOptionUrlAdditionalFieldBody: any;
  tableId?: string;
  originalId?: string;
  localFilter?: IEnumLocalFilter;
  enumItemsSource?: EnumItemsDataSource;
  multiple?: boolean;
  sort?: ISortEnumConfig;
}

export const getOption = (props: AcceptedProps): IOptionValue[] => {
  if (!props.defaultValueElement) {
    return props.inputConfig.config.items || [];
  }
  if (props.inputConfig.config.dataType !== 'database' && props.inputConfig.config.dataType !== 'document') {
    return props.inputConfig.config.data || [];
  }
  return [];
}

export const DropDownOptionLimit = 200;


export const manageDifferentType = (field: any, type: string): IInputConfig => {
  let config = {} as IInputConfig;
  if (field.inputConfig.type === 'anyOf') {
    const currentConfig = field.inputConfig.config.items.filter((itemConfig: any) => itemConfig.type === type);
    if (currentConfig.length > 0) {
      config = currentConfig[0];
    }
  } else if (field.inputConfig.type === 'array') {
    if (field.inputConfig.config.items.type === 'anyOf') {
      const currentConfig = field.inputConfig.config.items.config.items
        .filter((itemConfig: any) => itemConfig.type === type);
      if (currentConfig.length > 0) {
        config = currentConfig[0];
      }
    }
  } else {
    config = field.inputConfig;
  }
  return config;
}

export const getOptionItems = (field: IField, hasAllOption: boolean, type: string, localFilter?: IEnumLocalFilter) => {
  let options = [];
  if (field.hasOwnProperty('inputConfig')
    && field.inputConfig
    && field.inputConfig.hasOwnProperty('config')
    && field.inputConfig.config.hasOwnProperty('items')
  ) {
    options = manageDifferentType(field, type).config.items;
  }
  if (hasAllOption) {
    options.unshift({ id: 'all', label: 'ALL' });
  }
  if (localFilter) {
    options = options.filter(localFilter);
  }
  return options;
}

export const getOptionOnTableFieldEnum = (field: IField, fieldId: string, hasAllOption: boolean, type: string, filter?: IEnumLocalFilter) => {
  let options = [];
  if (field.inputConfig
    && field.inputConfig.hasOwnProperty('config')
    && field.inputConfig.config.hasOwnProperty('columns')
  ) {
    const enumField = field.inputConfig.config.columns && field.inputConfig.config.columns.filter((e: IField) => e.id === fieldId);
    if (enumField && enumField.length > 0) {
      options = getOptionItems(enumField[0], hasAllOption, type, filter);
    }
  }
  return options;
}

async function findEnumItemsForField(data: ITriggerFilterOptions): Promise<IOptionValue[]> {
  const url = data.url + (data.url.indexOf('?') > -1 ? '&' : '?') + (`ids=${data.tableId ? data.tableId : data.originalId || data.id}`)
  let fieldInput = {};
  data.requiresFieldData && data.requiresFieldData.forEach((reqField: string) => {
    fieldInput = setFieldInput(fieldInput, reqField, data.currentFieldList, data.forwardedRef);
  })

  let bodyFromOutside = {};
  if (data.fieldEnumOptionUrlAdditionalFieldBody) {
    bodyFromOutside = data.fieldEnumOptionUrlAdditionalFieldBody;
  }

  let input: any = {
    [`${data.originalId || data.id}`]: {
      search: data.filter,
      page: {
        offset: 0,
        limit: DropDownOptionLimit
      }
    }
  }
  if (data.tableId) {
    input = {
      [`${data.tableId}`]: {
        [`${data.id}`]: {
          search: data.filter,
          page: {
            offset: 0,
            limit: DropDownOptionLimit
          }
        }
      }
    }
  }

  const res = await axios.post(url, {
    fields: { ...fieldInput, ...bodyFromOutside },
    inputs: input
  })
  let options: IOptionValue[] = [];
  if (res.data.data.length > 0) {
    if (res.data.data[0].inputConfig && res.data.data[0].inputConfig.type === 'table') {
      options = getOptionOnTableFieldEnum(res.data.data[0], data.id, data.hasAllOption, data.type, data.localFilter)
    } else {
      options = getOptionItems(res.data.data[0], data.hasAllOption, data.type, data.localFilter)
    }
  }

  return options;
}

async function findEnumItemsWithConfig(companyId: string, search: string, config: EnumItemsDataSource, sort?: ISortEnumConfig): Promise<IOptionValue[]> {

  const configWithSearch = { ...config, search }

  if (config.cachedResult && config.cachedResult.isValidCacheForConfig(configWithSearch)) {
    await config.cachedResult.waitIfNeeded()
    return config.cachedResult!.value
  }

  const page = {
    offset: 0,
    limit: DropDownOptionLimit
  };

  const result = EnumItemsDataSource.fetch(companyId, configWithSearch, page, sort);
  config.cachedResult = EnumItemsResult.withConfigAndPromise(result, configWithSearch)

  return result
}

export const triggerFilterOptions = async (data: ITriggerFilterOptions): Promise<IOptionValue[]> => {
  try {
    console.log("TRIGGERS FILTER OPTIONS!!", data)
    let options: IOptionValue[];
    if (data.enumItemsSource) {
      // This is main method to get list of items, used with reference fields
      options = await findEnumItemsWithConfig(data.companyId, data.filter, data.enumItemsSource, data.sort);
    } else {
      // This is old method to get list of items, now can be used only for static enum items (not referenced).
      options = await findEnumItemsForField(data);
    }
    if (options.length > 0) {
      data.selectedValues.forEach((e: IOptionValue) => {
        const hasOption = options.filter((f: IOptionValue) => f.id === e.id);
        if (hasOption.length === 0) {
          options.push(e);
        }
      });
    }
    return options;
  } catch (error) {
    console.log(error);
    return [] as IOptionValue[];
  }
}
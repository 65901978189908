import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Segment } from 'semantic-ui-react-bpm';
import { setSubRouteAction } from '../main/action';

const App: React.FC<any> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSubRouteAction({
        mainRoute: '',
        subRoute: ''
      })
    );
  }, [dispatch])

  return (
    <Fragment >
      <Container className='main-container' fluid>
        <Segment className='borderless bg-light-gray' padded='very' >
        </Segment>
      </Container>
    </Fragment >
  );
}

export default App;

import { useEffect, useState } from 'react';
import { UploadedFile } from '../../../../../../../../common/api-request/upload';
import { IForm } from '../../interface/form';

interface IProps {
  formId: string;
  businessProcessRef: React.MutableRefObject<HTMLInputElement>;
  seqAbbrRef: React.MutableRefObject<HTMLInputElement>;
  descriptionRef: React.MutableRefObject<HTMLTextAreaElement>;
  getFormDetailData: IForm;
}

export const useSetFormDetailsToRefs = (props: IProps) => {
  const { formId, businessProcessRef, seqAbbrRef, descriptionRef, getFormDetailData } = { ...props };

  const [file, setFile] = useState(null as UploadedFile | null);

  useEffect(() => {
    let fileIcon = {} as UploadedFile;
    if (formId !== 'add' && getFormDetailData) {
      businessProcessRef.current.value = getFormDetailData.name;
      seqAbbrRef.current.value = getFormDetailData.abbr;
      descriptionRef.current.value = getFormDetailData.description || '';
      fileIcon.url = getFormDetailData.icon || '';
    }
    setFile(fileIcon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, JSON.stringify(getFormDetailData)]);

  return { file, setFile, ...props };
}
import React from 'react';
import SVGWrapper from '../../../../../../../../../../../common/icons/svg/svg-wrapper';
interface IAutomationDetailheaderProps {
  title: string;
  description: string;
  iconName: string;
  iconHeight: number;
  iconWidth: number;
  noBorderContainer?: boolean;
}

const AutomationDetailHeader: React.FC<IAutomationDetailheaderProps> = (props) => {
  return (
    <div className='header'>
      <div className={`svg-main ${props.noBorderContainer ? 'no-border' : ''}`}>
        <SVGWrapper
          width={props.iconWidth}
          height={props.iconHeight}
          iconName={props.iconName}
          color={`#3A85A8`}
        />
      </div>
      <div className='content'>
        <span className='title'>{props.title}</span>
        <span className='description'>{props.description}</span>
      </div>
    </div>
  );
}

export default AutomationDetailHeader;
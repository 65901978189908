import { MAIN_ROUTE, SUB_ROUTE, THIRD_LEVEL_ROUTE, FOURTH_LEVEL_ROUTE, RESET_FOURTH_LEVEL_ROUTE, CHANGE_PAGE_NEXT_ROUTE, CURRENT_ROUTE, SET_DOWNLOAD_REQUESTS, REMOVE_DOWNLOAD_REQUESTS, CLEAR_DOWNLOAD_REQUESTS, SET_CURRENT_LOCATION, SET_OPEN_ADMIN_CHATBOX } from './constant'
import { DownloadFileRequest } from '../../common/general/download-widget'

export const setMainRouteAction = (mainRoute: string) => {
  return { type: MAIN_ROUTE, mainRoute }
}

export const setSubRouteAction = (payload: { mainRoute: string, subRoute: string }) => {
  return { type: SUB_ROUTE, ...payload }
}

export const setThirdLevelRouteAction = (payload: { mainRoute: string, subRoute: string, thirdLevelRoute: string }) => {
  return { type: THIRD_LEVEL_ROUTE, ...payload }
}

export const setFourthLevelRouteAction = (payload: { mainRoute: string, subRoute: string, thirdLevelRoute: string, fourthLevelRoute: string }) => {
  return { type: FOURTH_LEVEL_ROUTE, ...payload }
}

export const resetFourthLevelRouteAction = () => {
  return { type: RESET_FOURTH_LEVEL_ROUTE }
}

export const changePageNextRouteAction = (value: string, openNewTab?: boolean) => {
  return { type: CHANGE_PAGE_NEXT_ROUTE, changePageNextRoute: value, openNewTab: openNewTab }
}

export const currentRouteAction = (route: string) => {
  return { type: CURRENT_ROUTE, currentRoute: route }
}

export const setDownloadRequestsAction = (requests: DownloadFileRequest) => {
  return { type: SET_DOWNLOAD_REQUESTS, downloadRequests: requests }
}

export const removeDownloadRequestsAction = (index: number) => {
  return { type: REMOVE_DOWNLOAD_REQUESTS, downloadIndex: index }
}

export const clearDownloadRequestsAction = () => {
  return { type: CLEAR_DOWNLOAD_REQUESTS }
}

export const setCurrentLocationAction = (location: string) => {
  return { type: SET_CURRENT_LOCATION, currentLocation: location }
}

export const setOpenAdminChatboxAction = (open: boolean) => {
  return { type: SET_OPEN_ADMIN_CHATBOX, isOpenAdminChatbox: open };
}

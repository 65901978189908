import { GET_AUTOMATION_LOGS_LIST, GET_AUTOMATION_LOGS_LIST_RETURN } from './constant';
import { IAutomationLogsList } from './interface/automationLogsList';

export interface IAutomationLogsReducer {
  automationLogsList: IAutomationLogsList[];
  automationLogsListLoading: boolean;
  automationLogsListStatus: string;
  automationLogsTotalCount: number;
}

const initialState: IAutomationLogsReducer = {
  automationLogsList: [],
  automationLogsListLoading: false,
  automationLogsListStatus: '',
  automationLogsTotalCount: 0
};

const automationLogsReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  switch (action.type) {

    case GET_AUTOMATION_LOGS_LIST:
      newState.automationLogsListLoading = true;
      newState.automationLogsListStatus = '';
      return newState;

    case GET_AUTOMATION_LOGS_LIST_RETURN:
      newState.automationLogsList = action.automationLogsList;
      newState.automationLogsListLoading = false;
      newState.automationLogsTotalCount = action.automationLogsTotalCount;
      return newState;

    default:
      return state;
  }
}

export default automationLogsReducer;
/*
 *
 * test reducer
 *
 */

// import { fromJS } from 'immutable'
import {
  GET_USER_LIST,
  GET_USER_LIST_RETURN,
  GET_USER_LIST_ERROR,
  GET_USER_FIELD_LIST_RETURN,
  GET_USER_FIELD_LIST_ERROR,
  DELETE_USER_RETURN,
  DELETE_USER,
  DELETE_USER_ERROR,
  GET_USER_FIELD_PERMISSION_RETURN,
  GET_USER_PROFILE_RETURN,
  CREATE_USER_RETURN,
  CREATE_USER_ERROR,
  CREATE_USER,
  GET_USER_DETAIL,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_RETURN,
  UPDATE_USER,
  UPDATE_USER_RETURN,
  UPDATE_USER_ERROR,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_RETURN,
  UPDATE_USER_STATUS_ERROR,
  GET_ACTIVE_USER_LIST_RETURN,
  RESET_MESSAGE_RESPONSE,
  RESET_USER_DETAIL,
  GET_USER_FIELD_DETAIL,
  GET_USER_FIELD_DETAIL_ERROR,
  GET_USER_FIELD_DETAIL_RETURN,
  CREATE_USER_FIELD,
  CREATE_USER_FIELD_RETURN,
  CREATE_USER_FIELD_ERROR,
  RESET_USER_FIELD_STATE,
  UPDATE_USER_FIELD,
  UPDATE_USER_FIELD_RETURN,
  UPDATE_USER_FIELD_ERROR,
  DELETE_USER_FIELD,
  DELETE_USER_FIELD_RETURN,
  DELETE_USER_FIELD_ERROR,
  RESET_USER_FIELD_MESSAGE_RESPONSE,
  FILTER_USERS,
  FILTER_USERS_RETURN,
  FILTER_USERS_ERROR,
  CLEAR_UPDATE_USER_SUCCESS,
  CLEAR_CREATE_USER_SUCCESS,
  CLEAR_DELETE_USER_SUCCESS,
  CLEAR_CREATE_CUSTOM_FIELD_SUCCESS,
  CLEAR_UPDATE_USER_STATUS_SUCCESS,
  CLEAR_UPDATE_USER_FIELD_SUCCESS,
  CLEAR_DELETE_USER_FIELD_SUCCESS,
  CLEAR_UPDATE_USER_STATUS_ERROR,
  CLEAR_UPDATE_USER_ERROR,
  CLEAR_CREATE_USER_ERROR,
  CLEAR_CREATE_USER_FIELD_ERROR,
  CLEAR_UPDATE_USER_FIELD_ERROR,
  CLEAR_RESET_USER_PASSWORD_ERROR,
  CLEAR_RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_RETURN,
  RESET_USER_PASSWORD_ERROR,
  UPLOAD_USERLIST_SUCCESS_MESSAGE,
  UPLOAD_USERLIST_ERROR_MESSAGE,
  CLEAR_UPLOAD_USERLIST_MESSAGE,
  GET_DATATABLE_LIST_RETURN
} from './constant';
import { message, uploadProfileErrorMessage } from './message';
import { IUser } from './interface/user';
import { IField } from './interface/field';
import { IUserFieldPermission } from './interface/user-field-permission';
import { IUserProfileName } from './interface/user-profile-name';
import { IUserField } from './interface/user-field';
import { ICompany } from '../../../bpm-manager-module/module/companies/interface/response/company';
import { IDataTable } from '../data-tables/reducer';

const user: IUser[] = [];

export interface IUserReducer {
  userList: IUser[];
  activeUserList: IUser[];
  userListLoading: boolean;
  userListError: string;
  userFieldListStatic: IField[]
  userFieldListDynamic: IField[]
  userFieldError: string;
  deleteUserMessage: string;
  createUserMessage: string;
  createUserMessageError: string;
  createAndAddNew: boolean;
  forUpdateUserDetail: any;
  forUpdateUserDetailError: boolean;
  forUpdateUserDetailMessage: string;
  forUpdateUserDetailLoading: boolean;
  forupdateUserStatusMessage: string;
  forupdateUserStatusMessageError: string;
  userFieldPermissionList: IUserFieldPermission[];
  userProfileList: IUserProfileName[];
  updateUserLoading: boolean;
  createUserLoading: boolean;
  updateUserMessage: string;
  updateUserMessageError: string;
  forUpdateUserFieldId: string;
  forUpdateUserFieldDetailLoading: boolean;
  forUpdateUserFieldDetailMessage: string;
  forUpdateUserFieldDetail: IUserField;
  userFieldLoading: boolean;
  createUserFieldMessage: string;
  createUserFieldMessageError: string;
  updateUserFieldMessage: string;
  updateUserFieldMessageError: string;
  updateUserFieldLoading: boolean;
  deleteUserFieldMessage: string;
  deleteUserFieldMessageError: string;
  userFilterLoading: boolean;
  userFilterError: string;
  userBelongCompany: ICompany[];
  resetUserPassMessage: string;
  resetUserPassError: string;
  resetUserPassLoading: boolean;
  uploadUserListMessage: string;
  uploadUserListErrorMessage: string;
  userRecordTotalCount: number;
  datatableList: IDataTable[];
  filterFields: IField[]
}

export const initialState: IUserReducer = {
  userList: user,
  activeUserList: user,
  userListLoading: false,
  userListError: '',
  userFieldListStatic: [] as IField[],
  userFieldListDynamic: [] as IField[],
  userFieldError: '',
  deleteUserMessage: '',
  createUserMessage: '',
  createUserMessageError: '',
  createAndAddNew: false,
  forUpdateUserDetail: {},
  forUpdateUserDetailError: false,
  forUpdateUserDetailMessage: '',
  forUpdateUserDetailLoading: false,
  forupdateUserStatusMessage: '',
  forupdateUserStatusMessageError: '',
  userFieldPermissionList: [],
  userProfileList: [],
  updateUserLoading: false,
  createUserLoading: false,
  updateUserMessage: '',
  updateUserMessageError: '',
  forUpdateUserFieldId: '',
  forUpdateUserFieldDetailLoading: false,
  forUpdateUserFieldDetailMessage: '',
  forUpdateUserFieldDetail: {},
  userFieldLoading: false,
  createUserFieldMessage: '',
  createUserFieldMessageError: '',
  updateUserFieldMessage: '',
  updateUserFieldMessageError: '',
  updateUserFieldLoading: false,
  deleteUserFieldMessage: '',
  deleteUserFieldMessageError: '',
  userFilterError: '',
  userFilterLoading: false,
  userBelongCompany: [] as ICompany[],
  resetUserPassError: '',
  resetUserPassMessage: '',
  resetUserPassLoading: false,
  uploadUserListMessage: '',
  uploadUserListErrorMessage: '',
  userRecordTotalCount: 0,
  datatableList: [],
  filterFields: []
};

const userReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_USER_LIST:
      newState.userListLoading = true;
      return newState;

    case GET_USER_LIST_RETURN:
      newState.userList = action.data;
      newState.userBelongCompany = action.userBelongCompany;
      newState.userListLoading = false;
      newState.userRecordTotalCount = action.userRecordTotalCount || 0;
      return newState;

    case GET_USER_LIST_ERROR:
      newState.userListError = message.userListError;
      newState.userList = [];
      newState.userListLoading = false;
      return newState;

    case GET_USER_FIELD_LIST_RETURN:
      newState.userFieldListStatic = action.data.filter((e: any) => e.custom === false);
      newState.userFieldListDynamic = action.data.filter((e: any) => e.custom === true);
      newState.filterFields = action.filterFields;
      return newState;

    case GET_USER_FIELD_LIST_ERROR:
      newState.userFieldError = message.userFieldListError;
      return newState;

    case DELETE_USER:
      newState.deleteUserMessage = '';
      return newState;

    case DELETE_USER_RETURN:
      newState.deleteUserMessage = message.deleteUserSuccess;
      newState.userList = newState.userList.filter(user => user.id !== action.userId);
      return newState;

    case DELETE_USER_ERROR:
      newState.deleteUserMessage = action.message;
      return newState;

    case GET_USER_FIELD_PERMISSION_RETURN:
      newState.userFieldPermissionList = action.data;
      return newState;

    case GET_USER_PROFILE_RETURN:
      newState.userProfileList = action.data;
      return newState;

    case CREATE_USER:
      newState.createAndAddNew = action.createAndAddNew || false;
      newState.createUserMessage = '';
      newState.createUserLoading = true;
      return newState;

    case CREATE_USER_RETURN:
      let tempUserList = [...state.userList]
      tempUserList.push(action.data)
      newState.userList = tempUserList;
      newState.createUserMessage = message.createUserSuccess;
      newState.createUserLoading = false;
      return newState;

    case CREATE_USER_ERROR:
      newState.createUserMessageError = action.message;
      newState.createUserLoading = false;
      return newState;

    case GET_USER_DETAIL:
      newState.forUpdateUserDetailLoading = true;
      newState.forUpdateUserDetail = {};
      return newState;

    case RESET_USER_DETAIL:
      newState.forUpdateUserDetailLoading = false;
      newState.forUpdateUserDetailError = false;
      newState.forUpdateUserDetail = {};
      return newState;

    case GET_USER_DETAIL_ERROR:
      newState.forUpdateUserDetailError = true;
      newState.forUpdateUserDetailMessage = '';
      newState.forUpdateUserDetailLoading = false;
      return newState;

    case GET_USER_DETAIL_RETURN:
      newState.forUpdateUserDetailError = false;
      newState.forUpdateUserDetailMessage = '';
      newState.forUpdateUserDetail = action.data;
      newState.forUpdateUserDetailLoading = false;
      return newState;

    case UPDATE_USER:
      newState.updateUserMessage = '';
      newState.updateUserLoading = true;
      return newState;

    case UPDATE_USER_RETURN:
      let updateUserList = [...state.userList]
      newState.userList = updateUserList.map(user => {
        if (action.data.id === user.id) {
          return action.data;
        }
        return user
      });
      newState.updateUserMessage = message.updateUserSuccess;
      newState.updateUserLoading = false;
      return newState;

    case UPDATE_USER_ERROR:
      newState.updateUserMessageError = action.message;
      newState.updateUserLoading = false;
      return newState;

    case UPDATE_USER_STATUS:
      newState.forupdateUserStatusMessage = ''
      return newState;

    case UPDATE_USER_STATUS_RETURN:
      let updateUserStatusList = [...state.userList];
      newState.userList = updateUserStatusList.map(user => {
        if (action.data.id === user.id) {
          return action.data;
        }
        return user
      });
      newState.forupdateUserStatusMessage = message.updateUserStatusSuccess;
      newState.updateUserLoading = false;
      return newState;

    case UPDATE_USER_STATUS_ERROR:
      newState.forupdateUserStatusMessageError = action.message;
      newState.updateUserLoading = false;
      return newState;

    case GET_ACTIVE_USER_LIST_RETURN:
      newState.activeUserList = action.data;
      return newState;

    case RESET_MESSAGE_RESPONSE:
      newState.deleteUserMessage = '';
      newState.createUserMessage = '';
      newState.createUserMessageError = '';
      newState.updateUserMessage = '';
      newState.updateUserMessageError = '';
      newState.forupdateUserStatusMessage = '';
      newState.forupdateUserStatusMessageError = '';
      return newState;

    case GET_USER_FIELD_DETAIL:
      newState.forUpdateUserFieldId = action.fieldId;
      newState.forUpdateUserFieldDetailLoading = true;
      newState.forUpdateUserFieldDetailMessage = '';
      return newState;

    case GET_USER_FIELD_DETAIL_RETURN:
      newState.forUpdateUserFieldDetail = action.data;
      newState.forUpdateUserFieldDetailLoading = false;
      return newState;

    case GET_USER_FIELD_DETAIL_ERROR:
      newState.forUpdateUserFieldDetailLoading = false;
      newState.forUpdateUserFieldDetailMessage = action.message;
      return newState;

    case CREATE_USER_FIELD:
      newState.userFieldLoading = true;
      newState.createUserFieldMessage = '';
      return newState;

    case CREATE_USER_FIELD_RETURN:
      newState.userFieldLoading = false;
      newState.createUserFieldMessage = message.createUserFieldSuccess;
      return newState;

    case CREATE_USER_FIELD_ERROR:
      newState.createUserFieldMessageError = action.message;
      newState.userFieldLoading = false;
      return newState;

    case RESET_USER_FIELD_STATE:
      return initialState;

    case UPDATE_USER_FIELD:
      newState.updateUserFieldMessage = '';
      newState.updateUserFieldLoading = true;
      return newState;

    case UPDATE_USER_FIELD_RETURN:
      newState.updateUserFieldMessage = message.updateUserFieldSuccess;
      newState.forUpdateUserFieldDetail = {}
      newState.updateUserFieldLoading = false;
      return newState;

    case UPDATE_USER_FIELD_ERROR:
      newState.updateUserFieldMessageError = action.message;
      newState.updateUserFieldLoading = false;
      return newState;

    case DELETE_USER_FIELD:
      newState.deleteUserFieldMessage = '';
      return newState;

    case DELETE_USER_FIELD_RETURN:
      newState.deleteUserFieldMessage = message.deleteUserFieldSuccess;
      return newState;

    case DELETE_USER_FIELD_ERROR:
      newState.deleteUserFieldMessage = action.message;
      return newState

    case RESET_USER_FIELD_MESSAGE_RESPONSE:
      newState.createUserFieldMessage = '';
      newState.createUserFieldMessageError = '';
      newState.updateUserFieldMessage = '';
      newState.updateUserFieldMessageError = '';
      newState.deleteUserFieldMessage = '';
      newState.forUpdateUserFieldDetail = {}
      return newState;

    case FILTER_USERS:
      newState.userFilterLoading = true;
      newState.userFilterError = '';
      return newState;

    case FILTER_USERS_RETURN:
      newState.userFilterLoading = false;
      newState.userList = action.filteredData;
      newState.userRecordTotalCount = action.userRecordTotalCount || 0;
      return newState;

    case FILTER_USERS_ERROR:
      newState.userFilterError = action.message;
      newState.userFilterLoading = false;
      return newState;

    case CLEAR_UPDATE_USER_SUCCESS:
      newState.updateUserMessage = '';
      return newState;

    case CLEAR_CREATE_USER_SUCCESS:
      newState.createUserMessage = '';
      return newState;

    case CLEAR_DELETE_USER_SUCCESS:
      newState.deleteUserMessage = '';
      return newState;

    case CLEAR_CREATE_CUSTOM_FIELD_SUCCESS:
      newState.createUserFieldMessage = '';
      return newState;

    case CLEAR_UPDATE_USER_STATUS_SUCCESS:
      newState.forupdateUserStatusMessage = '';
      return newState;

    case CLEAR_UPDATE_USER_STATUS_ERROR:
      newState.forupdateUserStatusMessageError = '';
      return newState;

    case CLEAR_UPDATE_USER_FIELD_SUCCESS:
      newState.updateUserFieldMessage = '';
      return newState;

    case CLEAR_DELETE_USER_FIELD_SUCCESS:
      newState.deleteUserFieldMessage = '';
      return newState;

    case CLEAR_UPDATE_USER_ERROR:
      newState.updateUserMessageError = '';
      return newState;

    case CLEAR_CREATE_USER_ERROR:
      newState.createUserMessageError = '';
      return newState;

    case CLEAR_CREATE_USER_FIELD_ERROR:
      newState.createUserFieldMessageError = '';
      return newState;

    case CLEAR_UPDATE_USER_FIELD_ERROR:
      newState.updateUserFieldMessageError = '';
      return newState;

    case RESET_USER_PASSWORD:
      newState.resetUserPassLoading = true;
      newState.resetUserPassMessage = '';
      return newState;

    case RESET_USER_PASSWORD_RETURN:
      newState.resetUserPassLoading = false;
      newState.resetUserPassMessage = message.resetUserPassSuccess;
      return newState;

    case RESET_USER_PASSWORD_ERROR:
      newState.resetUserPassError = action.message;
      return newState;

    case CLEAR_RESET_USER_PASSWORD_SUCCESS:
      newState.resetUserPassMessage = '';
      return newState;

    case CLEAR_RESET_USER_PASSWORD_ERROR:
      newState.resetUserPassError = ''
      return newState;

    case UPLOAD_USERLIST_SUCCESS_MESSAGE:
      newState.uploadUserListMessage = message.uploadUserListSuccess
      return newState;

    case UPLOAD_USERLIST_ERROR_MESSAGE:
      let erroredType = '';
      let errorMessage = action.message;
      const userType = 'BPM Manager|Admin|BA|End User|Users Manager';
      const hasProfileError = userType.split('|').some(type => {
        if (errorMessage.includes('user profile') && errorMessage.includes(type)) {
          erroredType = type;
          return true;
        }
        return false;
      });
      if (hasProfileError) {
        errorMessage = uploadProfileErrorMessage[erroredType];
      }
      newState.uploadUserListErrorMessage = errorMessage;
      return newState;

    case CLEAR_UPLOAD_USERLIST_MESSAGE:
      newState.uploadUserListMessage = ''
      newState.uploadUserListErrorMessage = ''
      return newState;

    case GET_DATATABLE_LIST_RETURN:
      newState.datatableList = action.data;
      return newState;

    default:
      return state;
  }
};

export default userReducer;

import React, { createRef } from 'react';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import SVGwrapper from '../../../common/icons/svg/svg-wrapper';

interface ITableField {
  data: IFieldData;
  detail: FieldElement;
}

const containerRef = createRef<HTMLDivElement>();
const valueRef = React.createRef<HTMLSpanElement>();

const TableCustomField: React.FC<ITableField> = (props) => {
  const value = props.data.fields ? props.data.fields[props.detail.id] || '' : '';
  const valueConfig = props.detail.configName;

  const valueComponent = <div ref={containerRef} style={{ textAlign: props.detail.inputConfig.config.alignment || 'left' }}
    key={`column-${props.detail.id}-${props.data.id}`}>
    <span id={`value-${props.detail.id}-${props.data.id}`} ref={valueRef}></span>
  </div>

  if (valueConfig === 'Table') {
    return (
      <div key={`column-${props.detail.id}-${props.data.id}`} style={{ textAlign: props.detail.inputConfig.config.alignment || 'center' }}>
        <span id={`value-${props.detail.id}-${props.data.id}`} ref={valueRef}>
          <SVGwrapper iconName='table' width='30' height='30' color={(value === '' ? '#737373' : '#000')} />
        </span>
      </div>
    )
  }

  return valueComponent;
}

export default TableCustomField;
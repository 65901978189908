import React from 'react';

interface ISwitchEnableDraft {
  onIncludeDraftChange(includeDraft: boolean): void;
  includeDraft: boolean;
}

const SwitchEnableDraft: React.FC<ISwitchEnableDraft> = (props) => {
  return <div className='switch-field-container'>
    <span className='switch-label'>Include DRAFT Status?</span>
    <div className="ui fitted toggle checkbox"
      onClick={() => props.onIncludeDraftChange(!props.includeDraft)}>
      <input className="hidden" type="checkbox"
        onChange={() => { }}
        checked={!!props.includeDraft}
      />
      <label></label>
    </div>
  </div>
}

export default SwitchEnableDraft;
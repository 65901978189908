import { useEffect } from 'react';
import { IAutomationComponent } from '../../../../../../interface/component-list';

interface IProps {
  company: string;
  selectedComponent: IAutomationComponent;
  setOperation: (value: React.SetStateAction<{ [id: string]: string; }>) => void;
  setRefresher: (value: React.SetStateAction<number>) => void;
  refresher: number;
  setRecipients: React.Dispatch<React.SetStateAction<any[]>>
  phoneFieldRefValue: any;
}

export const useSetSendSMSStateRefs = (props: IProps) => {

  const parseConfig = (config: any) => {
    Object.keys(config).forEach(key => {
      if (config[key] && Array.isArray(config[key])) {
        config[key] = config[key].map((e: any) => {
          if (e && typeof e === 'object' && e.hasOwnProperty('placeholder')) {
            e = {
              keyPath: e.placeholder,
              referencedKeyPath: 'current-form'
            }
          }
          if (e && typeof e === 'object' && (e.hasOwnProperty('referencedKeyPath') || e.hasOwnProperty('keyPath'))) {
            Object.keys(e).forEach(key => {
              if (e[key] && e[key].includes('fields.')) {
                e[key] = e[key].replace('fields.', '');
              }
            });
            e = {
              keyPath: e.keyPath,
              referencedKeyPath: e.keyPath && !e.referencedKeyPath ? 'current-form' : e.referencedKeyPath
            }
          }
          return e;
        })
      }
    });
    return config;
  }

  const setOperationRefs = (config: any, tempContainer: any) => {
    const tempConfig = config['to'] || [''];

    tempConfig.forEach((data: any, index: number) => {
      if (!tempContainer[index]) {
        tempContainer[index] = [];
      }
      if (data && data.hasOwnProperty('keyPath') && data.hasOwnProperty('referencedKeyPath')) {
        tempContainer[index] = 'form';
      } else {
        tempContainer[index] = 'value';
      }
    });

    return tempContainer;
  }

  const setRefValue = (to: any[]) => {
    to.forEach((e, i) => {
      if (typeof e === 'string' && props.phoneFieldRefValue.current) {
        if (!props.phoneFieldRefValue.current[i]) {
          props.phoneFieldRefValue.current[i] = {};
        }
        props.phoneFieldRefValue.current[i].value = e;
      }
    })
  }

  useEffect(() => {
    if (props.selectedComponent && props.selectedComponent.config) {
      let config = { ...props.selectedComponent.config };
      let { to }: any = parseConfig(config);
      const tempContainer: any = {};

      if (!to || (to && to.length === 0)) to = [''];

      setOperationRefs({ to }, tempContainer);
      setRefValue(to);

      props.setRecipients(to);
      props.setOperation(tempContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedComponent]);

}
import {
  GET_AUTOMATION_LOGS_LIST,
  GET_AUTOMATION_LOGS_LIST_ERROR,
  GET_AUTOMATION_LOGS_LIST_RETURN
} from './constant';
import { IAutomationLogsDetailInput } from './interface/input/automation-logs-get';

export const getAutomationLogsListAction = (payload: IAutomationLogsDetailInput) => {
  return {
    type: GET_AUTOMATION_LOGS_LIST,
    ...payload
  }
}

export const getAutomationLogsListActionError = () => {
  return { type: GET_AUTOMATION_LOGS_LIST_ERROR }
}

export const getAutomationLogsListReturnAction = (payload: any) => {
  return {
    type: GET_AUTOMATION_LOGS_LIST_RETURN,
    ...payload
  }
}
import { ICommentDeltaFields } from '../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryLogs } from '../../../interface/history';
import { IAdminHistoryUtils } from '../../manage-history';
import { GroupsDeltaModule } from './type/groups';
import { UsersDeltaModule } from './type/users';
import { UsersFieldsDeltaModule } from './type/users-fields';
import { AppsDeltaModule } from './type/apps';
import { BPDeltaModule } from './type/business-process';
import { BPFormBuilderDeltaModule } from './type/business-process/form-builder';
import { BPWorkflowDeltaModule } from './type/business-process/workflow';
import { BPRoleDeltaModule } from './type/business-process/roles';
import { DatatableDeltaModule } from './type/datatable';
import { DatatableFieldsDeltaModule } from './type/datatable/fields';

export interface IDeltaFields {
  delta: ICommentDeltaFields[];
  text: string;
  showUpdateTextOnly?: boolean;
}

export interface DeltaModuleType {
  name: string;
  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils): IDeltaFields;
}

const DeltaModuleTypeList = [
  new UsersDeltaModule(),
  new UsersFieldsDeltaModule(),
  new GroupsDeltaModule(),
  new AppsDeltaModule(),
  new BPDeltaModule(),
  new BPFormBuilderDeltaModule(),
  new BPWorkflowDeltaModule(),
  new BPRoleDeltaModule(),
  new DatatableDeltaModule(),
  new DatatableFieldsDeltaModule()
]

class DeltaModule {

  registry: { [name: string]: DeltaModuleType } = {};

  constructor(types?: DeltaModuleType[]) {

    if (!types) {
      types = DeltaModuleTypeList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  formatContext(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let validationClass = this.registry[historyLogs.category];
    return typeof validationClass !== 'undefined' ? validationClass.format(historyLogs, utils) : [];
  }

}

export default DeltaModule;
import { IModule } from '../admin-module/utils/header-menu-button/interface/module';

export const portalSubModules: IModule[] = [
  {
    id: 'stakeholders',
    name: 'Stakeholders',
    route_label: 'STAKEHOLDERS'
  },
  {
    id: 'users',
    name: 'Users',
    route_label: 'USERS'
  },
  {
    id: 'business-processes',
    name: 'Business Processes',
    route_label: 'BUSINESS PROCESS'
  }
]
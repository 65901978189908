import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Dimmer, Image, Loader } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import ToolTip from '../../../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../../../common/icons';
import { IRootState } from '../../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import ButtonContainer from '../../../../../button-container';
import { IPdfTemplateConfig } from '../interface/pdf';
import { saveDraftPDFTemplateConfigAction, publishPDFTemplateConfigAction, updateLocalPdfTemplateListAction } from '../action';
import { pdfTempConfigActionMessage } from '../constant';
import ActionMessage from '../../../../../../../../../common/general/action-message';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';

interface IPDFTemplateListProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PDFTemplateList: React.FC<IPDFTemplateListProps> = (props) => {
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const { company } = useSelector((state: IRootState) => state.auth);
  const {
    pdfTemplateConfigList,
    pdfTemplateConfigSaveType,
    saveDraftPdfTemplateConfigLoading
  } = useSelector((state: IRootState) => state.configurationPDFTemplate);

  const [forDeletionId, setForDeleteionId] = useState<undefined | string>(undefined);
  const [forDeletionName, setForDeleteionName] = useState('');

  const consfirmDeleteRecord = () => {
    let tempApiList = [...pdfTemplateConfigList.map((e: IPdfTemplateConfig) => { const f = { ...e }; return f; })];
    const forDeletionAPI = pdfTemplateConfigList.filter((e: IPdfTemplateConfig) => e.id === forDeletionId);
    if (forDeletionAPI.length > 0) {
      const forDeletionIndex = pdfTemplateConfigList.indexOf(forDeletionAPI[0]);
      tempApiList.splice(forDeletionIndex, 1);
      tempApiList = tempApiList.map((e: IPdfTemplateConfig, index: number) => {
        const f = { ...e };
        return f;
      })
      dispatch(updateLocalPdfTemplateListAction(tempApiList));
    }
    setForDeleteionId(undefined);
    setForDeleteionName('');
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteClick = (id: string, name: string) => {
    setForDeleteionId(id);
    setForDeleteionName(name);
  }

  const cancelDelete = () => {
    setForDeleteionId(undefined);
    setForDeleteionName('');
  }

  const saveDraft = (saveType: string) => {
    dispatch(saveDraftPDFTemplateConfigAction({
      company: company,
      formId: match.params.formId,
      saveType: saveType,
      pdfTemplateConfigList: pdfTemplateConfigList
    }))
    props.hasPageChangeRef.current.hasChange = false;
  }

  useEffect(() => {
    if (pdfTemplateConfigSaveType === 'publish') {
      dispatch(publishPDFTemplateConfigAction({ company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfTemplateConfigSaveType])

  return <>
    <div className={`pdf-template-list-container table-wrapper`}>
      <ActionMessage messages={pdfTempConfigActionMessage} />
      {
        (saveDraftPdfTemplateConfigLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <table className='table-container'>
        <thead>
          <tr>
            <th className='th-group'>Template ID</th>
            <th className='th-group'>Template Name</th>
            <th className='th-group'>Text</th>
            <th className='th-group'></th>
          </tr>
        </thead>
        <tbody>
          {pdfTemplateConfigList.map((templates: IPdfTemplateConfig, index: number) => {
            return <tr key={uuid()}>
              <td>{index + 1}</td>
              <td><Link to={`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}/${templates.id}`}>{templates.name}</Link></td>
              <td className='template'>{templates.template}</td>
              <td>
                <ToolTip position='right center'
                  popupMessage={'Delete'}
                  popupTriggerComponent={
                    <Image src={icons.blue.delete}
                      style={{
                        width: '12px',
                        cursor: 'pointer',
                        margin: '0px 5px',
                        display: 'inline-block'
                      }} onClick={() => deleteClick(templates.id, templates.name)} name='trash alternate outline' />
                  }
                />
              </td>
            </tr>
          })
          }
        </tbody>
      </table>
      <ConfirmationMessage
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => cancelDelete()}
        open={forDeletionId !== undefined}
        confirm={consfirmDeleteRecord}
        cancel={() => cancelDelete()}
        headerText="Confirmation"
        detailText={`The pdf template '${forDeletionName}' will be deleted. Do you still want to proceed?`}
        loading={false}
        type='warning'
      />
    </div>
    <ButtonContainer
      cancel={() => { history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}`) }}
      save={() => saveDraft('draft')}
      publish={() => saveDraft('publish')}
    />
  </>
}

export default PDFTemplateList;
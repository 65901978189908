import { changePageNextRouteAction } from '../../component/main/action';

const changeRoute = (
  link: string,
  hasPageChange: boolean,
  method: { dispatch: Function, history: { push: Function } },
  event?: any) => {
  if (hasPageChange) {
    method.dispatch(changePageNextRouteAction(link, event ? event.ctrlKey : false));
  } else {
    if (!event) {
      method.history.push(link);
    }
  }
  if ((event && event.ctrlKey && hasPageChange) || (event && !event.ctrlKey && hasPageChange)) {
    event.preventDefault();
    return;
  }
}

export default changeRoute;
import React from 'react';
import DefaultFieldConfig from './default-field-config';
import TableFieldConfig from './table-field-config';
import { ICustomFieldProps } from '..';
import FXFieldConfig from './fx-field-config';
import AutopopulatedFieldConfig from './autopopulated-field-config';
import StringFieldConfig from './string-config';
import FileFieldConfig from './file-field-config';
import DateFieldConfig from './date-config';
import GeolocationConfig from './geolocation-config';
import RangeConfig from './range-config';
import EnumFieldConfig from './enum-field-config';

const ModuleList = [
  new TableFieldConfig(),
  new FXFieldConfig(),
  new AutopopulatedFieldConfig(),
  new StringFieldConfig(),
  new FileFieldConfig(),
  new DateFieldConfig(),
  new GeolocationConfig(),
  new RangeConfig(),
  new EnumFieldConfig()
]

export interface ICustomFieldType {
  name: string;
  Render: React.FC<ICustomFieldProps>
}
export default class CustomFieldType {

  registry: { [name: string]: ICustomFieldType } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getComponent(name: string): ICustomFieldType {
    return (this.registry[name])
      ? this.registry[name]
      : new DefaultFieldConfig();
  }
}
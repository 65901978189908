import Axios from 'axios';
import { useEffect, useState } from 'react';
import { IUser } from '../../../../../../users/interface/user';
import { IAppliedLabels } from '../interface/label';

interface IProps {
  appliedLabel: IAppliedLabels;
  user: IUser;
  company: string;
}

export const useSetlabelFieldsCreator = (props: IProps) => {
  const { user, appliedLabel, company } = { ...props };

  const [creatorDetails, setCreatorDetails] = useState<IUser>({} as IUser);

  const fetchCreatorDetails = async (userId: string) => {
    if (company) {
      try {
        const request = await Axios.get(`${company}/users/${userId}`, {});
        return request.data.data
      } catch (_e) {
        return _e;
      }
    }
  }

  const getLabelCreator = (userId: string) => {
    Promise
      .resolve(fetchCreatorDetails(userId))
      .then((response: IUser) => {
        setCreatorDetails(response);
      });
  }

  useEffect(() => {
    if (appliedLabel && appliedLabel.creatorId) {
      getLabelCreator(appliedLabel.creatorId);
    } else {
      setCreatorDetails(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user), appliedLabel.creatorId]);

  return { ...props, creatorDetails, setCreatorDetails };
}
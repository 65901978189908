import React from 'react';
import File from './file-type/file';
import { AcceptedProps } from './interface/accepted-props';
import Signature from './file-type/signature';
import { IConfigName } from '../../type/interface/field-type-object';

const UploadElement: React.FC<AcceptedProps> = (props) => {

  if (props.configName === IConfigName.FileUpload) return <File {...props} />;
  if (props.configName === IConfigName.Signature) return <Signature {...props} />;
  return <div>File type not exist</div>
}

export default UploadElement;
import React from 'react';
import { AutomationLogsButton, AutomationRulesButton } from './button/automation-submenu-buttons';
import { ISubMenuList } from './interface/sub-menu-list';
import { ISubMenuListProp } from './interface/sub-menu-list-props';
import { SubMenuList } from './sub-menu-list';

const SubMenuButtonArrayList = [
  new AutomationLogsButton(),
  new AutomationRulesButton()
]

export class ConfigurationSubMenuButton {
  registry: { [name: string]: ISubMenuList } = {};

  constructor(types?: ISubMenuList[]) {

    if (!types) {
      types = SubMenuButtonArrayList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getButton: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const { Render } = this.registry[props.type] ? this.registry[props.type] : new SubMenuList();
    return <Render {...props} ></Render>;
  }

  static Render: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const headerButton = new ConfigurationSubMenuButton();
    const Render = headerButton.getButton;
    return <Render {...props} />
  }
}
import React, { Fragment, useState } from 'react';
import { Icon } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ICommentDeltaFields } from '../../../../../../interface/comment';
import SytemMessagesDeltaFieldChanges from '../field-changes';

interface IProps {
  delta: ICommentDeltaFields;
  newDeltaFields: ICommentDeltaFields[];
}

const RenderTableChanges: React.FC<IProps> = ({ delta }: IProps) => {
  const { diff, label } = delta;

  const deltaDiff = diff as ICommentDeltaFields[];
  const [previewFields, setPreviewFields] = useState(false);
  const dropdownIcon = previewFields ? 'triangle down' : 'triangle right';
  const newDeltaFields = deltaDiff.filter(deltaChange => deltaChange.diff.length > 0);

  return <Fragment key={`${delta.id}`}>
    <div className='edited-field-container'>
      <div className={`edited-field-info`}>
        <span className='field-name'>{label}</span>
      </div>
      <div key={uuid()} className={`edited-field-preview single table`}>
        <div onClick={() => setPreviewFields(!previewFields)}
          className={`icon-dropdown less-margin ${newDeltaFields.length === 1 ? 'hidden' : ''}`}>
          <span className='fields-preview-name'>
            {newDeltaFields.length === 1 ? newDeltaFields.length + ' field' : newDeltaFields.length + ' fields'}
          </span>
          <Icon className='icon-expand' name={dropdownIcon} />
        </div>
        {newDeltaFields.length === 1 ?
          <SytemMessagesDeltaFieldChanges
            isCollapse={false}
            newDeltaFields={newDeltaFields}
            isTableFieldChanges
          />
          :
          <SytemMessagesDeltaFieldChanges
            isCollapse={!previewFields}
            newDeltaFields={newDeltaFields}
            isTableFieldChanges
          />
        }
      </div>
    </div>
  </Fragment>
}

export default React.memo(RenderTableChanges);
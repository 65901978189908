import React from 'react';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import SectionContainer from './status-container/section';
import StatusList from './status-list';

interface IPermissionsView {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PermissionsView: React.FC<IPermissionsView> = (props) => {
  return <div className='permissions-view-container'>
    <StatusList />
    <SectionContainer />
  </div>
}

export default PermissionsView;
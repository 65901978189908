import { useEffect } from 'react';
import {
  getDataTableDetailsAction,
  getDataTableFieldsAction,
  getDataTableRecordsAction
} from '../../action';

interface IProps {
  companyId: string;
  dataTableId: string;
  dispatch: any;
  datatableRecordLimit: number;
}

export const useGetDatatableData = (props: IProps): void => {
  const { dispatch, dataTableId, companyId, datatableRecordLimit } = { ...props };

  useEffect(() => {
    if (dataTableId !== 'add-data-table' && companyId) {
      dispatch(getDataTableDetailsAction({ company: companyId, dataTableId: dataTableId }))
      dispatch(getDataTableFieldsAction({ company: companyId, dataTableId: dataTableId }));
      dispatch(getDataTableRecordsAction({ company: companyId, dataTableId: dataTableId, limit: datatableRecordLimit }));
    }
    // eslint-disable-next-line
  }, [dataTableId, companyId, dispatch]);
}
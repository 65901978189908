import { CSSProperties } from 'react';
import {
  CLEAR_CREATE_USER_FIELD_ERROR,
  CLEAR_UPDATE_USER_FIELD_ERROR,
} from '../../component/admin-module/module/users/constant';
import { IMessageProps } from '../general/action-message/interface/message-props';
import { IFormula } from './interface/formula';
import { IConfigName } from '../field/type/interface/field-type-object';

export const fieldType = [{ key: 1, text: 'String', value: 'string' }];

export const fieldVisibilityIndicator: { [color: string]: string } = {
  red: 'Hidden',
  orange: 'Profile',
  green: 'Public',
};

export const errorIndicator = {
  fieldname: false,
  sourceBp: false,
  sourceField: false,
  displayField: false,
  maxLength: false,
  minLength: false,
  itemType: false,
};

export const fieldVisibility = [
  { key: 1, value: 'red', text: 'Hidden' },
  { key: 2, value: 'orange', text: 'Profile' },
  { key: 3, value: 'green', text: 'Public' },
];

export const GET_FIELD_LIST = 'app/custom-field/GET_FIELD_LIST';
export const GET_FIELD_LIST_RETURN = 'app/custom-field/GET_FIELD_LIST_RETURN';

export const UserFieldErrorMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_USER_FIELD_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'createUserFieldMessageError',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_USER_FIELD_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'updateUserFieldMessageError',
    hasCloseIcon: true,
  },
];

export const fxOperatorType: IFormula[] = [
  { type: 'operator', label: '(', value: '(' },
  { type: 'operator', label: ')', value: ')' },
  { type: 'operator', label: '+', value: '+' },
  { type: 'operator', label: '-', value: '-' },
  { type: 'operator', label: 'x', value: 'x' },
  { type: 'operator', label: '/', value: '/' },
];

export const basicAllowedInput = [
  { id: 'whitespace', label: 'Spaces' },
  { id: 'digits', label: 'Digits' },
  { id: 'special', label: 'All Special Characters' },
  { id: 'custom', label: 'Specific character/s' },
];

export const BPSystemField: { id: string; label: string; type?: string }[] = [
  { id: 'urn', label: 'URN' },
  { id: 'status.name', label: 'Status' },
  { id: 'creator.firstName', label: 'Creator (Firstname)' },
  { id: 'creator.lastName', label: 'Creator (Lastname)' },
  { id: 'createdAt | DATE(YYYY/MM/dd)', label: 'Created On', type: 'date' },
  { id: 'updatedBy.firstName', label: 'Last Updated By (Firstname)' },
  { id: 'updatedBy.lastName', label: 'Last Updated By (Lastname)' },
  {
    id: 'updatedAt | DATE(YYYY/MM/dd)',
    label: 'Last Updated On',
    type: 'date',
  },
];

export const operatorOptions = ['Field', 'Array', 'Value'];

export const arrayOperator = [
  { key: 0, value: `sum`, text: 'Sum' },
  { key: 1, value: `count`, text: 'Count' },
  { key: 2, value: `count-positive`, text: 'Count Positive' },
  { key: 3, value: `avg`, text: 'Average' },
  { key: 4, value: `min`, text: 'Min' },
  { key: 5, value: `max`, text: 'Max' },
];

export const dropdownImageStyle: CSSProperties = {
  padding: '2px',
};

export const dropdownLabelStyle: CSSProperties = {
  backgroundColor: 'unset',
  color: '#333',
  padding: '5px',
  paddingRight: '8px',
};

export const dropdownOptionStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
};

export const bpListTitleOption = {
  key: -2,
  text: 'Business Processes',
  value: 'business-processes',
  className: 'values-separator',
  onClick: () => {
    return;
  },
  as: 'div',
  style: {
    padding: '0 1.14285714rem',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 700,
    textTransform: 'uppercase',
    pointerEvents: 'none',
  } as CSSProperties,
};

export const dtListTitleOption = {
  key: -1,
  text: 'Data Tables',
  value: 'data-tables',
  className: 'values-separator',
  onClick: () => {
    return;
  },
  as: 'div',
  style: {
    padding: '0 1.14285714rem',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 700,
    textTransform: 'uppercase',
  } as CSSProperties,
};

export const systemListTitleOption = {
  key: -3,
  text: 'System List',
  value: 'system-list',
  className: 'values-separator',
  onClick: () => {
    return;
  },
  as: 'div',
  style: {
    padding: '0 1.14285714rem',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 700,
    textTransform: 'uppercase',
  } as CSSProperties,
};

export const tableColumnWidthConstraints: { [id: string]: number } = {
  [IConfigName.Switch]: 50,
  [IConfigName.Time]: 100,
  [IConfigName.Date]: 100,
  [IConfigName.Timestamp]: 240,
  [IConfigName.Signature]: 200,
  [IConfigName.FileUpload]: 200,
  [IConfigName.Geolocation]: 150,
};

import React from 'react';
import AutomationDetailNewTrigger from './new-trigger';
import AutomationDetailNewAction from './new-action';
import AutomationDetailNewCondition from './new-condition';
import { IAutomation } from '../../interface/automation';
import { IAutomationChildComponent, IAutomationComponent } from '../../interface/component-list';

const ComponentList = [
  new AutomationDetailNewTrigger(),
  new AutomationDetailNewAction(),
  new AutomationDetailNewCondition(),
]

export interface IAutomationDetailRender {
  type: string;
  updateAutomationDetails(value: string, field: string): void;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  selectedComponent: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  businessRuleId: string;
  updateAutomationOnparent?(component: IAutomationComponent, number?: number): void;
  childIndex?: number;
  selectedChildComponent?: IAutomationChildComponent;
  refsObject: React.MutableRefObject<any>;
  isHasParent: boolean;
  setSelectedComponent(component: IAutomationComponent, selectedActionObject?: IAutomationChildComponent, childOnly?: boolean): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
  selectedParentComponent?: IAutomationComponent;
}

interface IAutomationDetailComponentRegistry {
  name: string;
  render: React.FC<any>
}

export class AtomationDetailComponentRegistry {

  registry: { [name: string]: IAutomationDetailComponentRegistry } = {};

  constructor() {
    const types = ComponentList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return null;
  }

  getModule(name: string): React.FC<IAutomationDetailRender> {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IAutomationDetailRender> = (props) => {
    const component = new AtomationDetailComponentRegistry();
    const Render = component.getModule(props.type);
    return <Render {...props} />
  }

}
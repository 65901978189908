import { useEffect, useRef } from 'react';
import { IAutomation } from '../../../interface/automation';

interface IProps {
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
}

export const useSetNameDescValue = ({
  currentAutomationRef
}: IProps) => {
  const ruleNameRef = useRef(document.createElement('input'));
  const descriptionRef = useRef(document.createElement('textarea'));

  useEffect(() => {
    if (currentAutomationRef.current) {
      ruleNameRef.current.value = currentAutomationRef.current.name;
      descriptionRef.current.value = currentAutomationRef.current.description;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ruleNameRef, descriptionRef };
}
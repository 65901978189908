import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';
import { icons } from '../../../../../../common/icons';

export const GET_FORM_STATUS_LIST = 'app/admin-module/business-process/role/GET_FORM_STATUS_LIST';
export const GET_FORM_STATUS_LIST_RETURN = 'app/admin-module/business-process/role/GET_FORM_STATUS_LIST_RETURN';
export const GET_GROUP_LIST = 'app/admin-module/business-process/role/GET_GROUP_LIST';
export const GET_GROUP_LIST_RETURN = 'app/admin-module/business-process/role/GET_GROUP_LIST_RETURN';
export const GET_USER_LIST = 'app/admin-module/business-process/role/GET_USER_LIST';
export const GET_USER_LIST_RETURN = 'app/admin-module/business-process/role/GET_USER_LIST_RETURN';
export const GET_USER_FIELD_LIST = 'app/admin-module/business-process/role/GET_USER_FIELD_LIST';
export const GET_USER_FIELD_LIST_RETURN = 'app/admin-module/business-process/role/GET_USER_FIELD_LIST_RETURN';
export const SAVE_ROLE = 'app/admin-module/business-process/role/SAVE_ROLE';
export const SAVE_ROLE_RETURN = 'app/admin-module/business-process/role/SAVE_ROLE_RETURN';
export const SAVE_ROLE_ERROR = 'app/admin-module/business-process/role/SAVE_ROLE_ERROR';
export const PUBLISH_ROLE = 'app/admin-module/business-process/role/PUBLISH_ROLE';
export const PUBLISH_ROLE_RETURN = 'app/admin-module/business-process/role/PUBLISH_ROLE_RETURN';
export const PUBLISH_ROLE_ERROR = 'app/admin-module/business-process/role/PUBLISH_ROLE_ERROR';
export const GET_ROLE_LIST = 'app/admin-module/business-process/role/GET_ROLE_LIST';
export const GET_ROLE_LIST_RETURN = 'app/admin-module/business-process/role/GET_ROLE_LIST_RETURN';
export const GET_ROLE_LIST_ERROR = 'app/admin-module/business-process/role/GET_ROLE_LIST_ERROR';
export const DELETE_ROLE = 'app/admin-module/business-process/role/DELETE_ROLE';
export const CLEAR_SAVE_ROLE_SUCCESS = 'app/admin-module/business-process/role/CLEAR_SAVE_ROLE_SUCCESS';
export const CLEAR_SAVE_ROLE_ERROR = 'app/admin-module/business-process/role/CLEAR_SAVE_ROLE_ERROR';
export const GET_USER_CUSTOM_FIELD = 'app/admin-module/business-process/role/GET_USER_CUSTOM_FIELD';
export const GET_USER_CUSTOM_FIELD_RETURN = 'app/admin-module/business-process/role/GET_USER_CUSTOM_FIELD_RETURN';
export const GET_CONSTRAINT_TEMPLATE = 'app/admin-module/business-process/role/GET_CONSTRAINT_TEMPLATE';
export const GET_CONSTRAINT_TEMPLATE_RETURN = 'app/admin-module/business-process/role/GET_CONSTRAINT_TEMPLATE_RETURN';
export const GET_FORM_FIELD = 'app/admin-module/business-process/role/GET_FORM_FIELD';
export const GET_FORM_FIELD_RETURN = 'app/admin-module/business-process/role/GET_FORM_FIELD_RETURN';

export const accessLevelIcon: { [access: string]: NodeRequire } = {
  owner: icons.blue.lock,
  interaction: icons.blue.interact,
  everyone: icons.blue.world
};

export const rolesActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_ROLE_SUCCESS,
    type: 'success',
    reducerContainer: 'role',
    reducerItem: 'formSaveMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_ROLE_ERROR,
    type: 'negative',
    reducerContainer: 'role',
    reducerItem: 'formSaveErrorMessage',
    hasCloseIcon: true
  }
]

export const constraintPallet = [
  '#5CAB67',
  '#0A5C82',
  '#0704d0',
  '#295432',
  '#2e400a',
  '#40340a',
  '#0a2c40',
  '#350a40'
];

export const operatorSymbol: { [operator: string]: string } = {
  'equal': '=',
  'not-equal': '≠',
  'includes': '✔',
  'excludes': '✘',
  'intersect': '+',
}
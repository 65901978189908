import { useEffect } from 'react';
import { currentRouteAction, setSubRouteAction } from '../../main/action';

export const useSetSubRoute = (props: {
  dispatch: any,
  mainRoute: string,
  subRoute: string,
  currentRoute: string
}): void => {
  useEffect(() => {
    props.dispatch(setSubRouteAction({
      mainRoute: props.mainRoute,
      subRoute: props.subRoute
    }))
    props.dispatch(currentRouteAction(props.currentRoute))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
export const message = {
  createFormSuccess: 'Business Process successfully added.',
  updateFormSuccess: 'Business Process successfully updated.',
  formRetrievalError: 'Error retrieving form.',
  deleteFormSuccess: 'Business Process successfully deleted.',
  saveFormBuilderSuccess: 'Form successfully saved.',
  publishFormBuilderSuccess: 'Form successfully published.',
  saveAutomationSuccess: 'Automation successfully saved.',
  updateAutomationSuccess: 'Automation successfully updated.',
  saveTaskViewSuccess: 'Tasks Layout successfully saved.',
  publishTaskViewSuccess: 'Tasks Layout successfully published.',
  deleteFieldSuccess: 'Business Process successfully deleted.',
};

export default message;

import * as jsondiffpatch from 'jsondiffpatch';
import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';

export class DropdownDeltaFields implements DeltaFieldType {

  name = 'enum';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);
    let newConfig = {} as Delta;
    let oldConfig = {} as Delta;

    if (delta['defaultValue']) {
      delta['defaultValue'] = this.setDefaultValue(delta['defaultValue'], targetInstance.config.data);
      targetInstance['defaultValue'] = this.setDefaultValue(targetInstance['defaultValue'], targetInstance.config.data);
    }

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      let { newDelta, oldDelta } = this.setDelta(config, targetInstance.config);
      newConfig = { ...newConfig, ...newDelta };
      oldConfig = { ...oldConfig, ...oldDelta };
    }

    if (Object.keys(newConfig).length) {
      deltaArray = deltaArray.concat(FieldConfigChange.format(newConfig, oldConfig, targetInstance));
    }

    return deltaArray;
  }

  setDelta(delta: Delta, oldValue: any) {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (oldValue.dataType === 'static') {
      Object.keys(delta).forEach(key => {
        if (key === 'data') {
          if (delta[key] instanceof Array) {
            const isSource = delta[key].filter((e: any) => e.databaseId || e.companyId);
            if (isSource.lenth > 0) {
              delta[key].forEach((e: any) => {
                if (!Array.isArray(e) && (e.databaseId || e.companyId)) {
                  newDelta['source'] = e;
                }
              });
            } else {
              newDelta['items'] = delta[key];
              oldDelta['items'] = oldValue[key];
            }
          } else {
            newDelta['items'] = this.dataItems(delta[key]);
            oldDelta['items'] = oldValue[key];
          }
        } else {
          if (key !== 'labelType') {
            newDelta[key] = delta[key];
            oldDelta[key] = oldValue[key] || '';

            if (delta[key] instanceof Array && delta[key].length === 1) {
              newDelta[key] = ['', ...delta[key]];
            }
            if (key === 'dataType') {
              newDelta[key] = this.dataType(delta[key]);
              oldDelta[key] = 'Dropdown';
            }

            if (key === 'labelFormat') {
              let separator = newDelta.separator ? newDelta.separator : ' ';
              newDelta['labelFormat'] = this.labelFormat(newDelta[key], separator || ' ', newDelta.source);
            }

            if (key === 'showCreateBpOrDt' || key === 'multiselect' || key === 'staticSource') {
              oldDelta[key] = false;
            }
          }
        }
      });
      if (newDelta.source) {
        newDelta['source'] = [null, newDelta['source']]
      }
    } else {
      if (delta.labelFormat) {
        if (delta.labelFormat instanceof Array) {
          let source = this.sourceDelta(delta.data, oldValue.data, delta.dataType);
          if (!source) {
            source = oldValue.data;
          }
          let labelFormat = this.parseLabelFormat(delta, oldValue, source);
          if (delta.dataType && delta.dataType[0] === 'database') {
            labelFormat = labelFormat.map((data: any, index: number) => {
              return {
                ...data,
                label: index === 0 ?
                  labelFormat[1].label
                  : labelFormat[0].label
              }
            });
          }
          newDelta['labelFormat'] = labelFormat;
        } else {
          const source = delta.data && oldValue.data ? [oldValue.data, delta.data] :
            delta.data ? delta.data : oldValue.data;
          const parsedLabelFormatDelta = [oldValue.labelFormat, delta.labelFormat];
          let separator = this.labelSeparator(delta.separator, oldValue.separator);
          const labelFormat = this.labelFormat(parsedLabelFormatDelta, separator, source);
          newDelta['labelFormat'] = labelFormat;
        }
      }
      oldDelta['labelFormat'] = {
        ...oldValue.data,
        label: this.parseLabel(oldValue['labelFormat'], oldValue.separator || ' ')
      };
      Object.keys(delta).forEach(key => {
        if (key !== 'labelFormat'
          && key !== 'labelType'
          && key !== 'dataFiltersJoint'
          && key !== 'data'
          && key !== 'dataFilters') {

          newDelta[key] = delta[key];
          oldDelta[key] = oldValue[key];

          if (key === 'showCreateBpOrDt'
            || key === 'multiselect'
            || key === 'staticSource') {
            oldDelta[key] = false;
          }
        }
      });
    }

    if (newDelta['labelFormat'] && newDelta['labelFormat'].length === 2) {
      if (JSON.stringify(newDelta['labelFormat'][0].label)
        === JSON.stringify(newDelta['labelFormat'][1].label)) {
        delete newDelta['labelFormat'];
      }
    }

    return { newDelta, oldDelta };
  }
  dataType = (data: string[]) => {
    return data.map(e => {
      if (e === 'database' || e === 'document') {
        return 'Referenced';
      }
      return 'Dropdown';
    });
  }

  parseLabelFormat(delta: any, oldConfig: any, source: any) {
    const parsedLabelFormatDelta = [oldConfig.labelFormat, jsondiffpatch.patch(oldConfig.labelFormat, delta.labelFormat)];
    return this.labelFormat(parsedLabelFormatDelta, delta.separator || oldConfig.separator, source);
  }

  labelFormat(delta: any, labelSeparator: any, source: any) {
    const labelFormatData = delta.map((data: any, index: number) => {
      const separator = labelSeparator instanceof Array && labelSeparator.length > 1 ?
        labelSeparator[index] : labelSeparator ? labelSeparator : ' ';
      let parsed = this.parseLabel(data, separator)
      if (parsed.length === 1) {
        parsed = this.parseLabel(data, ' ');
      }
      return parsed;
    });
    return labelFormatData.map((label: string[], index: number) => {
      const labelSource = source instanceof Array && source.length > 1 ?
        source[index] : source;
      return { ...labelSource, label: label.map(e => e ? e : null) };
    });
  }

  parseLabel(label: string, separator: string) {
    return label
      .split(`}}${separator || ' '}{{`)
      .map((item: string) => item
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/fields./g, '')
      );
  }

  dataItems(delta: any) {
    let newDelta = {} as Delta;
    const uniqueKeys = Object.keys(delta).length > 2 ? Object.keys(delta).filter((k, i) => i > 0) : Object.keys(delta)
    uniqueKeys.forEach(key => {
      newDelta[key] = delta[key];
    });
    return newDelta;
  }

  setDefaultValue = (delta: Delta, items: any[]) => {
    let newDelta = {} as Delta;
    if (delta) {
      if (Array.isArray(delta)) {
        newDelta = delta.map(item => {
          if (item && Array.isArray(item)) {
            let itemDetails = items.filter(e => item.indexOf(e.id) > -1);
            return itemDetails;
          } else if (typeof item === 'string') {
            let itemDetails = items.find(e => e.id === item);
            return itemDetails;
          }
          return item;
        });
      } else {
        if (typeof delta === 'string') {
          newDelta['defaultValue'] = items.find(e => e.id === delta);
        } else {
          Object.keys(delta).forEach(key => {
            newDelta[key] = delta[key]
            if (Array.isArray(delta[key])) {
              newDelta[key] = delta[key].map((item: string | number | string[]) => {
                if (Array.isArray(item)) {
                  let itemDetails = items.filter(e => item.indexOf(e.id) > -1);
                  return itemDetails;
                } else if (typeof item === 'string') {
                  let itemDetails = items.find(e => e.id === item);
                  return itemDetails;
                }
                return item;
              });
            }
          })
        }
      }
    }
    return newDelta;
  }

  labelSeparator = (deltaSeparator: any, oldSeparator: any) => {
    let separator: string | string[] = ' ';
    if (deltaSeparator) {
      if (deltaSeparator instanceof Array) {
        separator = deltaSeparator;
      } else {
        if (!oldSeparator) {
          separator = [' ', deltaSeparator];
        } else {
          separator = [oldSeparator, deltaSeparator];
        }
      }
    } else {
      if (oldSeparator) {
        separator = oldSeparator;
      }
    }
    return separator;
  }

  sourceDelta = (deltaSource: any, oldDeltaSource: any, dataType: any) => {
    if (deltaSource) {
      if (Array.isArray(deltaSource) && deltaSource.length === 2) {
        const newSource = deltaSource.map((e: any) => {
          return e.formId ? {
            companyId: oldDeltaSource.companyId,
            formId: e.formId
          } : {
            companyId: oldDeltaSource.companyId,
            databaseId: e.databaseId
          }
        });
        deltaSource = newSource;
      } else {
        let newSource = [] as any[];
        Object.keys(deltaSource).forEach((key, index) => {
          const source = deltaSource[key].filter((e: any) => typeof e === 'string');
          if (Array.isArray(source) && source.length > 1) {
            newSource[0] = {
              companyId: oldDeltaSource.companyId,
              [key]: source[0]
            };
            newSource[1] = {
              companyId: oldDeltaSource.companyId,
              [key]: source[1]
            };
          } else {
            newSource[index] = {
              companyId: oldDeltaSource.companyId,
              [key]: source.toString()
            }
          }
        });
        deltaSource = newSource;
      }
    }
    return deltaSource;
  }
}
import React from 'react';
import ToolTip from '../../../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';
import { IFormAction } from '../interface/form-action';

interface IAssignmentType {
  assignmentType: string;
  selectedAssignmentType: string;
  isEndStatus?: boolean;
  formAction: IFormAction;
  setAssignmentType?(value: string): void;
  editAction(formAction: IFormAction): void;
  screen?: string;
}

const AssignmentType: React.FC<IAssignmentType> = (props) => {
  let tooltipMessage = '';
  const arrAssignmentType = props.assignmentType.split('-');
  const arrowIcon = arrAssignmentType[2] === 'return' ? 'arrowReturn' : (arrAssignmentType[0] === 'forward' ? 'arrowRight' : 'arrowLeft');
  const arrowColor = arrAssignmentType[2] === 'return' ? '#d0675c' : (arrAssignmentType[0] === 'forward' ? '#5eab69' : '#d0675c')
  const userIcon = arrAssignmentType[1] === 'individual' ? 'user' : 'users';

  if (arrAssignmentType[0] === 'forward') {
    if (userIcon === 'users') {
      tooltipMessage = 'Forward routing to a group';
    } else {
      tooltipMessage = 'Forward routing to an individual';
    }
  } else if (arrAssignmentType[2] === 'return') {
    if (userIcon === 'users') {
      tooltipMessage = 'Return to a group';
    } else {
      tooltipMessage = 'Return to an individual';
    }
  } else {
    if (userIcon === 'users') {
      tooltipMessage = 'Backward routing to a group';
    } else {
      tooltipMessage = 'Backward routing to an individual';
    }
  }



  return <ToolTip
    position='top center'
    popupMessage={tooltipMessage}
    popupTriggerComponent={
      <div
        id={props.setAssignmentType ? props.formAction.id : `table-detail-${props.formAction.id}`}
        style={{ cursor: props.formAction ? 'pointer' : 'none' }}
        className={`icon-group ${props.selectedAssignmentType === props.assignmentType ? 'selected-type' : ''} ${props.isEndStatus ? 'icon-group-end-status' : ''}`}
        onClick={() => {
          if (props.setAssignmentType) {
            props.setAssignmentType(props.assignmentType)
          } else {
            props.editAction(props.formAction)
          }
        }}>
        <i className={`${userIcon} icon ${arrAssignmentType[0] || ''}`} />
        <label className={`${(arrAssignmentType[0] || '') + ' ' + (arrAssignmentType[2] || '')}`}>
          {props.screen === 'action-modal' && <span>{arrAssignmentType[0] === 'forward' ? '→' : '←'}</span>}
          {props.screen === 'workflow-table' && <SVGWrapper iconName={arrowIcon} width={15} height={18} color={arrowColor} />}
        </label>
      </div >
    }
  />
}

export default AssignmentType;
import { useEffect } from 'react';

interface IProps {
  isScrollable: boolean;
  setIsArrowRight: React.Dispatch<React.SetStateAction<boolean>>;
  setIsArrowLeft: React.Dispatch<React.SetStateAction<boolean>>
}

export const useSetInitialArrow = ({ isScrollable, setIsArrowRight, setIsArrowLeft }: IProps) => {

  useEffect(() => {
    setIsArrowRight(isScrollable);
    if (!isScrollable) {
      setIsArrowLeft(false);
    }
    // eslint-disable-next-line
  }, [isScrollable]);

  return {};
}

export const frequencyOptionsList = [
  { text: 'Minute', value: 'minute', index: 0 },
  { text: 'Hour', value: 'hour', index: 1 },
  { text: 'Day', value: 'day', index: 2 },
  { text: 'Week', value: 'week', index: 3 },
  { text: 'Month', value: 'month', index: 4 },
  { text: 'Year', value: 'year', index: 5 }
]

export const units = [
  {
    min: 0,
    max: 59,
    total: 60,
  },
  {
    min: 0,
    max: 23,
    total: 24,
  },
  {
    min: 1,
    max: 31,
    total: 31,
  },
]

export const dayOptionsList = [...Array(units[2].total)].map((e, index) => {
  const number = units[2].min === 0 ? index : index + 1
  return {
    index,
    value: number,
    text: number.toString()
  }
})

export const minuteOptionsList = [...Array(units[0].total)].map((e, index) => {
  const number = units[0].min === 0 ? index : index + 1
  return {
    index,
    value: number,
    text: number.toString()
  }
})

export const hourOptionsList = [...Array(units[1].total)].map((e, index) => {
  const number = units[1].min === 0 ? index : index + 1
  return {
    index,
    value: number,
    text: number.toString()
  }
})

export const weekOptionsList = [
  { text: 'Sunday', value: 0, index: 0 },
  { text: 'Monday', value: 1, index: 1 },
  { text: 'Tuesday', value: 2, index: 2 },
  { text: 'Wednesday', value: 3, index: 3 },
  { text: 'Thursday', value: 4, index: 4 },
  { text: 'Friday', value: 5, index: 5 },
  { text: 'Saturday', value: 6, index: 6 }
]

export const monthOptionsList = [
  { text: 'January', value: 1, index: 0 },
  { text: 'Febrauary', value: 2, index: 1 },
  { text: 'March', value: 3, index: 2 },
  { text: 'April', value: 4, index: 3 },
  { text: 'May', value: 5, index: 4 },
  { text: 'June', value: 6, index: 5 },
  { text: 'July', value: 7, index: 6 },
  { text: 'August', value: 8, index: 7 },
  { text: 'September', value: 9, index: 8 },
  { text: 'October', value: 10, index: 9 },
  { text: 'November', value: 11, index: 10 },
  { text: 'December', value: 12, index: 11 },
];

import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { isValidNumber } from '../../../utils/validate-number';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';

export class NumberValidator implements ValidationType {

  name = 'number';

  validate(field: FieldElement, value: string, actionType?: string) {
    const { multiline } = { ...field } as any;
    if (((value === '' || value === null || value === undefined) && (field.accessType !== IFieldAccessType.Required ||
      ((field.accessType === IFieldAccessType.Required || (field.validationSchema.min && field.validationSchema.min > 0))
        && actionType === 'save'))
    ) || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden) {
      return [];
    }
    const newValue = (value || typeof value === 'number') ? parseFloat(value.toString().replace(/,/g, '')) : null;
    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: multiline ? [newValue] : newValue });
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, newValue);
      });
    }
    let errMessage = '';
    if (!isValidNumber(value, field.inputConfig.config) && typeof value !== 'number') {
      errMessage = `Please provide a valid <b>Number</>`;
      if (!field.inputConfig.config.decimals && value && value.includes('.')) {
        errMessage = `<b>${field.label}</b> must not contain decimals.`;
      }
    }
    if ((!value && typeof value !== 'number') && value !== '0') {
      errMessage = `Please provide value for <b>${field.label}</b>`
    }
    validate.errors = errMessage !== ''
      ? [
        {
          keyword: '',
          dataPath: '',
          schemaPath: '',
          params: '',
          message: errMessage
        }
      ] : validate.errors;
    return validate.errors || [];
  }

}


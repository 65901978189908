import { ErrorObject } from 'ajv';
import { AcceptedProps } from '../component/object/interface/accepted-props';

enum AllowedChar {
  LowerCase = 'lowercase',
  UpperCase = 'uppercase',
  Digits = 'digits',
  Whitespace = 'whitespace',
  Special = 'special',
  Custom = 'custom',
}

const capitalize = (title: string) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};

const escapeRegEx = (str: string): string => {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
};

const setRegexPatternMessage = (label: string, errorChars: string[], customAllowedChars?: string) => {
  let message = `${label} must not contain `;
  if (errorChars.length === 1) {
    const ch = errorChars.toString();
    if (ch === 'pattern') {
      message = `Please provide a valid <b>${capitalize(label)}</b>`
    } else {
      if (ch === 'custom' && customAllowedChars) {
        message = `${label} requires '${customAllowedChars}' character only.`;
      } else {
        message = `${label} must not contain ${ch === 'uppercase' ? ch.toUpperCase() : ch}.`;
      }
    }
  }

  if (errorChars.length > 1) {
    errorChars.push(errorChars.splice(errorChars.indexOf('custom'), 1)[0]);
    errorChars.forEach((ch, i) => {
      if (errorChars.length > 2) {
        if (i < (errorChars.length - 1)) {
          ch = `${ch}, `
        } else {
          if (customAllowedChars && ch === 'custom') {
            ch = `and special characters except '${customAllowedChars}'.`
          } else {
            ch = `and ${ch}.`
          }
        }
      } else {
        if (i < errorChars.length - 1) {
          ch = `${ch} and `;
        } else {
          if (customAllowedChars && ch === 'custom') {
            ch = `special characters except '${customAllowedChars}'.`;
          } else {
            ch = `${ch}.`;
          }
        }
      }
      message += ch;
    });
  }
  return message;
};

const valueRegexPattern = (value: string, allowedChars: AllowedChar[], customAllowedChars?: string, customPattern?: string): string[] => {
  let errorCharFormats = [] as string[];
  let regEx = undefined;
  const basicFormatSet = ['lowercase', 'uppercase', 'number/digits', 'space', 'special characters', 'custom'];
  value.split('').forEach((v) => {
    if (customPattern) {
      regEx = customPattern;
      let pattern = new RegExp(regEx);
      let test = pattern.test(v);
      if (!test) {
        if (!errorCharFormats.includes('pattern')) {
          errorCharFormats.push('pattern');
        }
      }
    } else {
      basicFormatSet.forEach((type) => {
        regEx = '^[';
        switch (type) {
          case 'lowercase':
            if (!allowedChars.includes(AllowedChar.LowerCase)) {
              regEx += 'a-z';
            }
            break;
          case 'uppercase':
            if (!allowedChars.includes(AllowedChar.UpperCase)) {
              regEx += 'A-Z';
            }
            break;
          case 'number/digits':
            if (!allowedChars.includes(AllowedChar.Digits)) {
              regEx += '0-9';
            }
            break;
          case 'space':
            if (!allowedChars.includes(AllowedChar.Whitespace)) {
              regEx += '\\s';
            }
            break;
          case 'special characters':
            if (!customAllowedChars && !allowedChars.includes(AllowedChar.Special)) {
              regEx += escapeRegEx('~`!@#$%^&*()-_+={}[]|\\/:;"\'<>,.?');
            }
            break;
          case 'custom':
            if (customAllowedChars && v !== customAllowedChars) {
              regEx += escapeRegEx('~`!@#$%^&*()-_+={}[]|\\/:;"\'<>,.?');
            }
            break;
          default:
        }
        regEx += ']*$';
        let pattern = new RegExp(regEx);
        let test = pattern.test(v);
        if (test) {
          if (!errorCharFormats.includes(type)) {
            errorCharFormats.push(type);
          }
        }
      });
    }
  });
  return errorCharFormats;
};

export const errorMessages = (error: ErrorObject, field: AcceptedProps, data: any) => {
  const { validationSchema, inputConfig }: any = { ...field };
  let params = { ...error.params } as any;
  const label = `<b>${field.label}</b>`

  switch (error.keyword) {
    case 'pattern':
      let allowedChars = inputConfig.config.allowedChars;
      let customAllowedChars = inputConfig.config.customAllowedChars;
      let customPattern = inputConfig.config.pattern;

      if (inputConfig.type === 'array' && inputConfig.config.hasOwnProperty('items')) {
        if (inputConfig.config.items.config) {
          allowedChars = inputConfig.config.items.config.allowedChars;
          customAllowedChars = inputConfig.config.items.config.customAllowedChars;
        }
      }

      const errorChars = valueRegexPattern(data, allowedChars, customAllowedChars, customPattern);
      error.message = setRegexPatternMessage(label, errorChars, customAllowedChars);
      break;
    case 'minLength':
      if ((validationSchema['isRequired'] && !data) || !data) {
        error.message = `Please provide value for ${label}`;
      } else {
        error.message = `${label} requires a minimum value of ${params.limit} characters for input.`;
      }
      break;
    case 'maxLength':
      if ((validationSchema['isRequired'] && !data) || !data) {
        error.message = `Please provide value for ${label}`;
      } else {
        error.message = `${label} exceeds maximum value of ${params.limit} characters for input.`;
      }
      break;
    case 'format':
      params.format = params.format === 'uri' ? 'URL' : params.format;
      if (data) {
        error.message = `Please provide a valid <b>${capitalize(params.format)}</b>`;
      } else {
        error.message = `Please provide value for ${label}`;
      }
      break;
    case 'maximum':
      if (inputConfig.type === 'geolocation') {
        if (params.limit === 90) {
          error.message = `(${label} - Latitude)  exceeds maximum value of ${params.limit} for input.`;
        } else {
          error.message = `(${label} - Longitude) exceeds maximum value of ${params.limit} for input.`;
        }
      } else if ((validationSchema['isRequired'] && isNaN(data))) {
        error.message = `Please provide value for ${label}`;
      } else {
        error.message = `${label} exceeds maximum value of ${params.limit} for input.`;
      }
      break;
    case 'minimum':
      if ((validationSchema['isRequired'] && isNaN(data)) || isNaN(data)) {
        error.message = `Please provide value for ${label}`;
      } else {
        error.message = `${label} requires a minimum value of ${params.limit} for input.`;
      }
      break;
    case 'type':
      if (params.type === 'array' || params.type === 'object') {
        if ((validationSchema['isRequired'] && (!data || !data.length)) || !data) {
          error.message = `Please provide value for ${label}`;
        }
      } else if (params.type && (params.type !== 'null' || params.type !== 'anyOf')) {
        error.message = `Please provide a valid <b>${capitalize(params.type)}</b>`;
        if (params.type === 'number' && validationSchema['isRequired'] && !data) {
          error.message = `Please provide value for ${label}`;
        }
      }
      break;
    case 'minItems':
      if ((validationSchema['isRequired'] && !data.length) || !data.length) {
        error.message = `Please provide value for ${label}`;
      }
      break;
    default:
      return error;
  }
  return error;
};

export const errorMessagesTable = (error: ErrorObject, field: AcceptedProps): ErrorObject => {
  // Temporary - to display more readable error from table.
  const _dataPath = error.dataPath;
  const fieldWithError = field?.inputConfig.config.columns.find((e: any) => _dataPath.indexOf(e.id) > 1);
  return {
    keyword: 'tableFieldError',
    message: `Error: ${fieldWithError?.label} - ${error.message}.`
  } as ErrorObject;
}

export const getInputPatternType = (pattern: string) => {
  if (pattern.includes('a-z')) {
    return 'LowerCase';
  }
  if (pattern.includes('A-Z')) {
    return 'UpperCase';
  }
};

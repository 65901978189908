import { IValidationError } from '../../validation/type/validation-type';
import { FieldElement } from '../field-element';
import { GetTypeInComponent, TValidationType, PropType } from './field-properties.handler';

export class ArrayFieldProperties<Type extends PropType> {

  constructor(
    private fieldId: string,
    private forwardRef: any,
    public valueType: Type,
  ) {

  }

  get current(): GetTypeInComponent<Type>[] {
    const fieldValue = this.forwardRef.current[this.fieldId];
    let arrFieldValue: any = fieldValue;
    arrFieldValue = arrFieldValue ? [...arrFieldValue] : [];
    return arrFieldValue.map((objectValue: any) => {
      if (this.valueType.processValueFromRef) {
        return this.valueType.processValueFromRef(objectValue);
      }
      return objectValue;
    });
  }

  getCurrent(index: number): GetTypeInComponent<Type> {
    this.setInitialDataIfNotExist(index);

    const fieldValue = this.forwardRef.current[this.fieldId][index];
    if (this.valueType.processValueFromRef) {
      return this.valueType.processValueFromRef(fieldValue);
    }
    return fieldValue;
  }

  setCurrent(value: GetTypeInComponent<Type>, index: number) {
    const currentValue = { ...this.getCurrent(index), ...value };
    let valueToSet = currentValue;
    if (this.valueType.processValueToRef) {
      valueToSet = this.valueType.processValueToRef(currentValue);
    }
    this.setInitialDataIfNotExist(index);
    this.forwardRef.current[this.fieldId][index] = valueToSet;
  }

  validate(field: FieldElement, validationType: TValidationType, allowIndex?: boolean) {
    this.setInitialDataIfNotExist();
    const data = this.forwardRef.current[this.fieldId];
    data.forEach((rowData: any, index: number) => {
      const value = rowData;
      if (this.valueType.processValidation) {
        this.setInitialDataIfNotExist(index);
        if (allowIndex) { field.multilineIndex = index; };
        this.forwardRef.current[this.fieldId][index].error = this.valueType.processValidation(value, field, validationType);
      }
    })
  }

  hasValue(count?: number): boolean {
    if (count === undefined) {
      return this.current !== null && this.current.length > 0 && this.current.filter((e: { value: any }) => e.value !== null && e.value !== '').length > 0;
    } else {
      return this.current !== null && this.current && this.current[count] && this.current[count].value !== null && this.current[count].value !== '';
    }
  }

  hasError(index?: number): boolean {
    this.setInitialDataIfNotExist(index);
    if (index !== undefined) {
      return this.forwardRef.current[this.fieldId][index].error && this.forwardRef.current[this.fieldId][index].error.length > 0;
    }
    return this.forwardRef.current && this.forwardRef.current[this.fieldId] && this.forwardRef.current[this.fieldId].length > 0 &&
      this.forwardRef.current[this.fieldId].filter((e: { error: any }) => e.error && e.error.length > 0).length > 0;

  }

  getError(index: number): IValidationError[] {
    this.setInitialDataIfNotExist(index);
    return this.forwardRef.current[this.fieldId][index].error || [];
  }

  setError(value: any[], index: number) {
    this.setInitialDataIfNotExist(index);
    this.forwardRef.current[this.fieldId][index].error = value;
  }

  isSet(index?: number): boolean {
    if (index !== undefined) {
      return this.forwardRef.current[this.fieldId] && this.forwardRef.current[this.fieldId][index] ? true : false;
    }
    return this.forwardRef.current[this.fieldId] ? true : false;
  }

  clearElement(): void {
    this.forwardRef.current[this.fieldId] = [];
  }

  private setInitialDataIfNotExist(index?: number): void {
    if (!this.forwardRef.current[this.fieldId]) {
      this.forwardRef.current[this.fieldId] = [];
    }
    if (index !== undefined && !this.forwardRef.current[this.fieldId][index]) {
      this.forwardRef.current[this.fieldId][index] = { value: null, error: [] };
    }
  }
}
import { useEffect, useState } from 'react';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { IOption } from '../../../../../../../../common/interface/option';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IProps {
  selectedSection: IFormBuilderSection;
  nameRef: React.MutableRefObject<HTMLInputElement>;
  additionalInfoRef: React.MutableRefObject<HTMLTextAreaElement>;
}

export const useSetSectionSettingsConfig = (props: IProps) => {
  const { selectedSection, additionalInfoRef, nameRef } = { ...props };

  const [isCollapsable, setIsCollapsable] = useState(false);
  const [isTitleHidden, setIsTitleHidden] = useState(false);
  const [selectedTitleField, setSelectedTitleField] = useState([] as string[]);
  const [defaultView, setDefaultView] = useState('' as IFormBuilderSection['defaultView']);
  const [optionField, setOptionField] = useState([] as IOption[]);
  const [isCurrentSectionPinned, setIsCurrentSectionPinned] = useState(false);

  useEffect(() => {
    let ctr = 0;
    if (props.selectedSection) {
      nameRef.current.value = props.selectedSection.title || '';
      additionalInfoRef.current.value = props.selectedSection.additionalInfo || '';
      setIsCollapsable(props.selectedSection.collapsable);
      setIsTitleHidden(props.selectedSection.titleFieldToShow &&
        props.selectedSection.titleFieldToShow.length > 0);
      setSelectedTitleField(props.selectedSection.titleFieldToShow);
      setDefaultView(props.selectedSection.defaultView);
      setIsCurrentSectionPinned(props.selectedSection.pinned ? true : false);
    }
    const tempOptionsField = [] as IOption[];
    props.selectedSection && props.selectedSection.fields && props.selectedSection.fields.forEach((fieldContainer: TFormBuilderSectionRow) => {
      fieldContainer.forEach((field: ICustomFieldDetail) => {
        tempOptionsField.push({ key: ctr++, text: field.label || 'Untitled', value: field.id || '' });
      });
    });
    setOptionField(tempOptionsField);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedSection)]);

  return {
    isCollapsable, setIsCollapsable, isTitleHidden, setIsTitleHidden,
    selectedTitleField, setSelectedTitleField, defaultView, setDefaultView,
    optionField, setOptionField, setIsCurrentSectionPinned, isCurrentSectionPinned, ...props
  };
}
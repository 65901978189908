import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { ICommentDeltaFields } from '../../../../../../interface/comment';
import SourcePopupDetails, { ISourceDetails } from './source-popup-details';

interface ISourceChange {
  companyId: string;
  databaseId?: string | string[];
  formId?: string | string[];
  label?: string | string[];
}

export const renderSourceChanges = (delta: ICommentDeltaFields, link: any, newDeltaFields: ICommentDeltaFields[]) => {
  const deltaLabel = delta.label === 'LabelFormat' ? 'DisplayFields' : delta.label;
  const databaseChange = link.map((e: any) => {
    if (!e) {
      return null;
    }
    return {
      companyId: e.companyId,
      databaseId: e.databaseId
    }
  }).filter((e: any) => e && e.databaseId instanceof Array);

  const isChangesSame = (prev: any, next: any, property: any) => {
    return JSON.stringify(prev[property]) === JSON.stringify(next[property])
  }

  const renderDatabaseSourceFields = (source: ISourceChange, index: number) => {
    if (!source) return;

    const isNull = source.label && typeof source.label === 'object' && source.label.every((e: string) => e === null || e === '');
    if (isNull) return null;

    const databaseId = typeof source.databaseId === 'object' ? source.databaseId[index] : source.databaseId;
    const formId = source.formId === 'object' ? source.formId[index] : source.formId;

    const sourceDetails = source.label && typeof source.label === 'object'
      && source.label.map(label => {
        if (databaseId) {
          return {
            label,
            link: `${source.companyId}/databases/${databaseId}/fields/${label}`,
            redirect: `/admin/data-tables/${databaseId}`,
            type: 'Database Fields'
          }
        }
        return {
          label,
          link: `${source.companyId}/forms/${formId}/fields/${label}`,
          redirect: `/admin/business-process/form-builder/${formId}`,
          type: 'Form Fields'
        }
      });

    return <SourcePopupDetails
      sourceDetails={sourceDetails as ISourceDetails[]}
    />
  }

  const renderSource = (source: ISourceChange, index: number) => {
    if (!source) return;

    if (source.databaseId) {
      const sourceDetails = source.databaseId && typeof source.databaseId === 'object'
        && source.databaseId.map(label => {
          return {
            label,
            link: `${source.companyId}/databases/${label}`,
            redirect: `/admin/data-tables/${label}`,
            type: 'Database'
          }
        });
      return <SourcePopupDetails
        sourceDetails={sourceDetails as ISourceDetails[]}
      />
    }
    return null;
  }

  const renderSourceFields = (source: ISourceChange, index: number) => {
    if (!source) return;

    if (source.label) {
      return renderDatabaseSourceFields(source, index) || 'No Value'
    } else {
      return renderSource(source, index) || 'No Value'
    }
  }

  const renderSourceChange = (source: ISourceChange[]) => {
    if (isChangesSame(source[0], source[1], 'databaseId')) return null;

    return <div className='edited-field-container'>
      <div className={`edited-field-info`}>
        <span className='field-name'>{'Source'}</span>
      </div>
      <div key={uuid()} className={`edited-field-preview`}>
        <div className='preview before'>{renderSourceFields(source[0], 0)}</div>
        <div className='arrow-right'>&#8594;</div>
        <div className='preview before'>{renderSourceFields(source[1], 1)}</div>
      </div>
    </div>
  }

  return <Fragment key={`${delta.id}`}>
    <div className='edited-field-container'>
      <div className={`edited-field-info ${newDeltaFields.length === 1 && !delta.alwaysShowLabel ? 'hidden' : ''}`}>
        <span className='field-name'>{deltaLabel}</span>
      </div>
      <div key={uuid()} className={`edited-field-preview`}>
        <div className='preview before'>{renderSourceFields(link[0], 0)}</div>
        <div className='arrow-right'>&#8594;</div>
        <div className='preview before'>{renderSourceFields(link[1], 1)}</div>
      </div>
      {databaseChange && databaseChange.length > 0 &&
        renderSourceChange(databaseChange)
      }
    </div>
  </Fragment>
}
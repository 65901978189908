import { FieldElement } from '../../component/field-element';
import { FieldValidator } from './field-validator';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';

export class DateValidator extends FieldValidator {

  name = 'date';

  validate(field: FieldElement, value: string, actionType?: string) {
    const { multiline } = { ...field } as any;

    if (((value === '' || value === 'undefined' || value === 'Invalid date')
      && (field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required && actionType === 'save')))
      || field.accessType === IFieldAccessType.Readonly
      || field.accessType === IFieldAccessType.Hidden || field.accessType !== IFieldAccessType.Required) {
      return [];
    }

    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: multiline ? [value] : value });
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}


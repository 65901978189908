import { ITableWidgetConfig } from '../../component/admin-module/module/business-process/module/form-builder/field-container/section-mobile';
import { ITableMobileViewContainer } from '../../component/admin-module/module/business-process/module/form-builder/interface/table-view';
import {
  ICustomFieldDetail,
  IConfig,
  FileFieldFileType,
} from '../custom-field-config/interface/custom-field-detail';
import {
  ICustomFieldMobileConfig,
  ITableMobileConfigWidgetItems,
} from '../custom-field-config/interface/cutom-field-detail-mobile';
import { IConfigName } from '../field/type/interface/field-type-object';

interface SlotIndex {
  component: string;
  row: number;
  column: number;
}

const pattern = /^([a-zA-Z]+?)(\d*)?_?(\d*)?$/m;

const mobileConfigSlotIndex = (input: string): SlotIndex => {
  const result: any = input.match(pattern);
  const component = result[1];
  const row = (+result[2] || 1) - 1;
  const column = (+result[3] || 1) - 1;
  return { component, row, column };
};
export class SanitizeField {
  static structureIncomingField = (
    fieldList: ICustomFieldDetail[]
  ): ICustomFieldDetail[] => {
    return fieldList.map((e: ICustomFieldDetail) => {
      let field = { ...e };
      if (field.type === 'array') {
        field = SanitizeField.convertArrayTypeFieldToMultilineField(field);
      }
      if (
        !field.configName &&
        field.type === 'file' &&
        field.config &&
        field.config.fileType === FileFieldFileType.Signature
      ) {
        field.configName = IConfigName.Signature;
      }
      return field;
    });
  };
  static structureOutgoingFields = (
    fieldList: ICustomFieldDetail[],
    mainField = true
  ): ICustomFieldDetail[] => {
    return fieldList.map((field: ICustomFieldDetail) =>
      SanitizeField.structureOutgoingField(field, mainField)
    );
  };

  static structureOutgoingField = (
    field: ICustomFieldDetail,
    mainField = true
  ): ICustomFieldDetail => {
    if (field.multiline) {
      field = SanitizeField.convertMultilineFieldToArrayTypeField(field);
    }
    if (field.type === 'table' && field.config) {
      if (
        field.config.automatic &&
        !field.config.minRows &&
        !field.config.maxRows
      ) {
        field.config.maxRows = 1;
        field.config.minRows = 1;
      }
      field.config.columns = SanitizeField.structureOutgoingFields(
        field.config.columns,
        false
      );
      if (
        field.config.uniqueColumnIds &&
        field.config.uniqueColumnIds.length > 0
      ) {
        const columnIds = field.config.columns.map((e) => e.id);
        field.config.uniqueColumnIds = field.config.uniqueColumnIds.filter(
          (id) => columnIds.includes(id)
        );
      }
    }
    if (field.type === 'array' && field.config) {
      if (field.config.itemType === 'file' && !!field.maxItems) {
        delete field.maxItems;
      }
      if (field.config.itemType === 'enum' && field.config.itemConfig) {
        field.config = field.config.itemConfig;
        field.type = 'enum';
        field.defaultValue = null;
      }
    }
    if (field.type === 'string' && field.config) {
      if (field.config && field.config.displayType === 'paragraph') {
        if (
          !field.config.style &&
          (field.config.minLines || field.config.maxLines)
        ) {
          field.config.style = {
            minLines: field.config.minLines,
            maxLines: field.config.maxLines,
          };
        }
        delete field.config.minLines;
        delete field.config.maxLines;
      }
      if (field.config.minLength === 0) {
        field.config.minLength = undefined;
      }
    }
    if (
      field.type === 'file' &&
      field.configName === 'Signature' &&
      field.config
    ) {
      field.config.fileType = FileFieldFileType.Signature;
    }
    field.defaultValue =
      field.defaultValue !== null ? field.defaultValue : null;
    delete field.multiline;
    delete field.new;
    if (!mainField) {
      delete field.maxItems;
      delete field.permission;
    }

    return field;
  };

  static convertMultilineFieldToArrayTypeField = (
    field: ICustomFieldDetail
  ): ICustomFieldDetail => {
    const updateField = { ...field } as ICustomFieldDetail;
    updateField.type = 'array';
    updateField.defaultValue =
      field.defaultValue === null ? [] : [field.defaultValue];
    updateField.config = {
      itemType: field.type || '',
      itemConfig: field.config || ({} as IConfig),
      ...(field.maxItems ? { maxItems: field.maxItems } : {}),
      ...(field.config && field.config.itemConfig
        ? { enableCameraInput: field.config.itemConfig.enableCameraInput }
        : {}),
    } as IConfig;
    delete updateField.multiline;
    return updateField;
  };

  static convertArrayTypeFieldToMultilineField = (
    field: ICustomFieldDetail
  ): ICustomFieldDetail => {
    const updateField = { ...field } as ICustomFieldDetail;
    updateField.type = field.config ? field.config.itemType || '' : '';
    updateField.defaultValue =
      field.defaultValue instanceof Array && field.defaultValue.length > 0
        ? field.defaultValue[0]
        : null;
    updateField.multiline = true;
    updateField.config = field.config
      ? field.config.itemConfig
      : ({} as IConfig);
    updateField.maxItems = field.config ? field.config.maxItems : null;
    return updateField;
  };

  static structureWidgetConfigToMobileConfig = (
    tableMobileView: ITableMobileViewContainer,
    additionalFields: string[],
    enabled: boolean
  ): ICustomFieldMobileConfig => {
    const mobileContentKeys: any = { upperField: 'c1', lowerField: 'c2' };
    const configWidgetItems: ITableMobileConfigWidgetItems[] = [];
    const configWidgetHeader: string[] = [];

    Object.keys(tableMobileView).forEach((index) => {
      const tempMobileView: any = { ...tableMobileView[index] };
      if (tempMobileView) {
        Object.keys(tempMobileView).forEach((childKey) => {
          if (childKey !== 'columnHeader') {
            if (tempMobileView[childKey]) {
              configWidgetItems.push({
                slot: `${mobileContentKeys[childKey]}_${parseInt(index) + 1}`,
                columnId: tempMobileView[childKey],
              });
            }
          } else {
            const header = tempMobileView[childKey];
            configWidgetHeader.push(header ? header : null);
          }
        });
      }
    });

    additionalFields.forEach((field, index) => {
      if (field) {
        configWidgetItems.push({
          slot: `e${index + 1}`,
          columnId: field,
        });
      }
    });

    return {
      widget: {
        headers: configWidgetHeader,
        items: configWidgetItems,
        enabled: enabled,
      },
    };
  };

  static structureMobileConfigToWidgetConfig = ({
    widget,
  }: ICustomFieldMobileConfig): ITableWidgetConfig => {
    const { headers, items, enabled } = widget;
    let tableMobileView = {} as ITableMobileViewContainer;

    const upperFields = items.filter((e) => e.slot.startsWith('c1'));
    const lowerFields = items.filter((e) => e.slot.startsWith('c2'));
    const extraFields = items.filter((e) => e.slot.startsWith('e'));

    const itemsToFields = (
      fields: ITableMobileConfigWidgetItems[],
      data: ITableMobileViewContainer,
      name: 'upperField' | 'lowerField'
    ) => {
      fields.forEach((item) => {
        const { column }: SlotIndex = mobileConfigSlotIndex(item.slot);
        data[column] = { ...data[column], [name]: item.columnId };
      });
      return data;
    };

    headers.forEach((header, idx) => {
      tableMobileView[idx] = {
        ...tableMobileView[idx],
        columnHeader: header,
      };
    });

    itemsToFields(upperFields, tableMobileView, 'upperField');
    itemsToFields(lowerFields, tableMobileView, 'lowerField');

    return {
      tableMobileView: tableMobileView,
      extraFields: extraFields.map((e) => e.columnId),
      enabled: enabled,
    };
  };
}

import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { IAdminRender } from '../../module-registry';
import { BusinessProcessModuleRegistry } from './module-registry';

interface IMatch {
  path: string;
  params: {
    submodule: string;
    thirdLevelRoute?: string;
  }
}

interface IBusinessProcessModule {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const BusinessProcessModule: React.FC<IAdminRender> = (props) => {
  const match: IMatch = useRouteMatch();

  return (
    <Route path={`${match.path}`}>
      <BusinessProcessModuleRegistry.Render
        type={match.params.thirdLevelRoute || ''}
        hasPageChangeRef={props.hasPageChangeRef}
        allowedAccessAuditLogs={props.allowedAccessAuditLogs}
      />
    </Route>
  );
}

export default BusinessProcessModule;

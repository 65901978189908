import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { currentRouteAction, setThirdLevelRouteAction } from '../../../../../main/action';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import StakeholdersView from './stakeholders';

interface IStakeholdersViewProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class StakeholdersViewModule {

  name = 'stakeholders-view';

  render: React.FC<IStakeholdersViewProps> = (props) => {

    const dispatch = useDispatch();
    const match = useRouteMatch();

    useEffect(() => {
      dispatch(setThirdLevelRouteAction({
        mainRoute: 'portal',
        subRoute: 'business-processes',
        thirdLevelRoute: this.name
      }));
      dispatch(currentRouteAction(this.name))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Route path={`${match.path}`}>
        <div className='stakeholders-container bp-portal-submenu'>
          <StakeholdersView {...props} />
        </div>
      </Route>
    );
  }
}

export default StakeholdersViewModule;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../../../common/api-request';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { SanitizeField } from '../../../../../../../../common/utils/sanitize-field';
import { SanitizeTaskView } from '../../../../../../../../common/utils/sanitize-task-view';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { IFormBuilderPublishInput } from '../../../form-builder/interface/input/form-builder-save';
import { IFormFieldGetInput } from '../../../roles/interface/input/form-field-get';
import { IFormStatusListInput } from '../../../workflow/interface/input/form-status-list';
import { getFormFieldReturnAction, getFormStatusListReturnAction, getTaskViewReturnAction, publishTaskViewErrorAction, publishTaskViewReturnAction, saveTaskViewErrorAction, saveTaskViewReturnAction } from './action';
import { GET_FORM_FIELD, GET_FORM_STATUS, GET_TASK_VIEW, PUBLISH_TASK_VIEW, SAVE_TASK_VIEW } from './constant';
import { ITaskViewGetInput } from './interface/input/task-view-get';
import { ITaskViewSaveInput } from './interface/input/task-view-save';


export function* getFormStatusList(args: IFormStatusListInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true&load=actions`, {});
    yield put(getFormStatusListReturnAction({
      formStatusList: req.data.data
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e));
  }
}

export function* getTaskView(args: ITaskViewGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/tasksLayout?draft=true`, {});
    yield put(getTaskViewReturnAction({
      taskView: SanitizeTaskView.structureIncoming(req.data.data),
      apiTaskView: req.data.data
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e));
  }
}

export function* getFormField(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const fieldData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormFieldReturnAction({
      formFieldList: SanitizeField.structureIncomingField(fieldData.data.data) as ICustomFieldDetail[],
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* saveTaskView(args: ITaskViewSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const apiTaskView = args.sanitize ? SanitizeTaskView.structureOutgoing(args.taskView) : args.taskView;
    const req: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}/tasksLayout`, apiTaskView);
    const taskView = SanitizeTaskView.structureIncoming(req.data.data);
    yield put(saveTaskViewReturnAction({
      taskView: taskView,
      saveType: args.saveType
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(saveTaskViewErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* publishTaskView(args: IFormBuilderPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishTaskViewReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(publishTaskViewErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* formSagas() {
  yield takeLatest(GET_FORM_STATUS, getFormStatusList);
  yield takeLatest(GET_FORM_FIELD, getFormField);
  yield takeLatest(SAVE_TASK_VIEW, saveTaskView);
  yield takeLatest(PUBLISH_TASK_VIEW, publishTaskView);
  yield takeLatest(GET_TASK_VIEW, getTaskView);
}

// All sagas to be loaded
export default formSagas;
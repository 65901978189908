import React, { useEffect, useState } from 'react';
import { IHasPageChangeRef } from '../../../../../../main/interface/has-page-change';
import Section from './section';
import PlainSection from './plain-section';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import { IPortalPageLayout } from '../interface/page-layout';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IFormViewProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): void;
}

const ContentComponent: React.FC<IFormViewProps> = (props) => {
  const { pageLayout } = useSelector((state: IRootState) => state.portalFormView);
  const [currentPageLayout, setCurrentPagelayout] = useState<undefined | IPortalPageLayout[]>(undefined);

  useEffect(() => {
    setCurrentPagelayout(pageLayout)
  }, [pageLayout])

  return (
    <div className={`content-component`}>
      {currentPageLayout &&
        currentPageLayout.map((e: IPortalPageLayout) => {

          if (e.type === 'plain' && e.layout && e.layout.length > 0) {
            return <PlainSection key={uuid()} viewIcon={props.viewIcon} fields={e.layout[0]} />
          }

          return <Section sectionDetail={e} viewIcon={props.viewIcon} />
        })

      }
      <div className={`drop-area`}>
        Drop and drop fields here
      </div>
    </div>
  );
}

export default ContentComponent;
import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IField } from '../../../../users/interface/field';
import { IForm } from '../../form/interface/form';
import { IPermissionFieldFilterConfig } from '../interface/field-permission';
import { IFormPermissionSection, ISection, IStatusAction, ITablePermission } from '../interface/section';
import { FrontendAutomationModuleRegistry } from './config';

export interface IFieldPermissionSettings {
  cancel: Function;
  updatePermissionConfig: Function;
  fieldData: ICustomFieldDetail;
  currentBP: IForm;
  fieldList: ICustomFieldDetail[];
  selectedTableSection?: ITablePermission;
  selectedStatus: IFormPermissionSection;
  selectedFieldSection: ISection;
  open: boolean;
  isSectionPermission: boolean;
  isTablePermission: boolean;
  filterConfig: IPermissionFieldFilterConfig;
  selectedStatusAction: IStatusAction;
  type?: string;
  userFieldList: IField[];
}

const FieldPermissionSettings: React.FC<IFieldPermissionSettings> = (props) => {
  const type = props.selectedStatusAction && Object.keys(props.selectedStatusAction).length > 0 ?
    'actions' : props.fieldData.type;

  return <FrontendAutomationModuleRegistry.Render
    {...props}
    type={type || ''}
  />
}

export default FieldPermissionSettings;
import { AuthorisedUser } from '../../component/auth/authorize-user';
import {
  UserProfile,
  UserProfileStaticKey,
} from '../../component/auth/interface/user-profile';
import { Permission } from './access-permissions';

class AllowedUserListActionControl {
  userProfile!: UserProfile[];

  static withUser(user: AuthorisedUser): AllowedUserListActionControl {
    return new this(user);
  }

  constructor(public readonly user: AuthorisedUser) { }

  get userProfilesInOwnCompany(): UserProfile[] {
    return this.user.profiles[this.user.belongCompanyId] || [];
  }

  hasSomeProfiles(...profileList: UserProfileStaticKey[]): boolean {
    return this.userProfilesInOwnCompany.some((profile) =>
      profileList.includes(profile.staticKey)
    );
  }

  hasOnlyProfile(profile: UserProfileStaticKey): boolean {
    return (
      this.userProfilesInOwnCompany.length === 1 &&
      this.userProfilesInOwnCompany[0].staticKey === profile
    );
  }

  allowedUserCreate(companyId: string): boolean {
    return (
      this.user.canAccessPermission(companyId, Permission.User.Create) ||
      this.user.isEveryCompanyUserManager
    );
  }

  allowedUserUpdate(companyId: string): boolean {
    return (
      this.user.canAccessPermission(companyId, Permission.User.Update) ||
      this.user.isEveryCompanyUserManager
    );
  }

  allowedViewDataTablesTab(): boolean {
    return this.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic);
  }

  allowedCreateUserField(companyId: string): boolean {
    return (
      this.user.canAccessPermission(companyId, Permission.UserField.Create) ||
      this.user.isEveryCompanyUserManager
    );
  }

  allowedViewGroupList(): boolean {
    return (
      this.hasSomeProfiles(UserProfileStaticKey.UsersManager) ||
      this.user.isEveryCompanyUserManager
    );
  }

  allowedViewAppList(): boolean {
    return this.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic);
  }

  allowedAccessUsersModule(): boolean {
    return (
      this.hasSomeProfiles(
        UserProfileStaticKey.Admin,
        UserProfileStaticKey.UsersManager
      ) || this.user.isEveryCompanyUserManager
    );
  }

  allowedAccessCompanyModule(): boolean {
    return (
      this.hasSomeProfiles(
        UserProfileStaticKey.BPMManager
      )
    );
  }

  allowedAccessAuditLogs(): boolean {
    return this.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic);
  }

  allowedBusinessProcessModule(): boolean {
    return this.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic);
  }
}
export default AllowedUserListActionControl;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NoIdRedirect: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/admin/business-process/form`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  );
}

export default NoIdRedirect;

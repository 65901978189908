import React, { useState, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import DataTableDetails from './details';
import { IRootState } from '../../../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import {
  createDataTableRecordsAction,
  updateDataTableRecordReturnAction,
  updateDataTableRecordAction,
  updateDataTableRecordErrorAction,
  filterDatatableRecordAction,
  batchUploadDatatableRecordReturnAction,
  batchUpdateDatatableRecordErrorAction,
  getDataTableRecordsAction,
  createDataTableRecordsReturnAction,
  batchUpdateDatatableRecordAction,
  confirmDeleteDataTableRecordErrorAction
} from '../action';
import { IField, IFieldAccessType, ISortField } from '../../users/interface/field';
import ValidateField from '../../../../../common/field/validation';
import { sortDataTableFieldMultiLabel } from '../../../../../common/utils/sort-data-table-field';
import { triggerFieldReloadAction } from '../../../../../common/utils/trigger-field-reload';
import { fieldTriggerUpdateAction } from '../action';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { datatableRecordLimit } from '../../../../document-module/module/datatable/constant';
import { useGetDatatableData } from './hooks/use-get-datatable-data';
import { useSetThirdLevelRoute } from '../../../hooks/use-set-third-level-route';
import { useSetDatatableDetails } from './hooks/use-set-datatable-details';
import { IFieldData } from '../../../../../common/field/dynamic-field-list-detail/interface/field-data';
import DatatableRecordsGrid from './grid';
import actionmessage from '../message';
import { parseFilterData } from './utils/manage-grid';
import GridPagination from '../../../../../common/utils/grid-pagination';
import ConfigService from '../../../../../common/config';
import { IFilter } from '../../../../document-module/interface/input/filter';
import { setDownloadRequestsAction } from '../../../../main/action';
import FileUploadModal from '../../../../../common/general/file-upload-modal';
import { useShowGridTable } from './hooks/use-show-grid-table';
import { EditMode } from '@syncfusion/ej2-react-grids';
import { IDatatableBatchChanges } from '../interface/data-table-config';
import { IDatatableRecord } from '../../../../../common/custom-field-config/interface/datatable-record';
import { getFieldIdByConfigName } from '../../../../../common/utils/get-field';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';
import { IDataTableRecordDetailBatchInput } from '../interface/input/data-table-record-create';
import Axios, { AxiosResponse } from 'axios';

interface IDataTableRecords {
  dataRecordsList: any;
  openDeleteRecordConfirmationModal(recordId: string, dataTableId: string): void;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchParams;
}

interface IRouteMatchParams {
  submodule: string;
  dataTableId: string;
}
export class DataTableRecords {

  static Render: React.FC<IDataTableRecords> = (props) => {

    let filterTimeout = setTimeout(() => { }, 1000);
    let match: IRouteMatch = useRouteMatch();

    const dispatch = useDispatch();

    const fieldRefs: any = useRef({});
    const filterRefs: any = useRef({});

    const companyId = useSelector((state: IRootState) => state.auth.company);
    const {
      forUpdateDataTableFields,
      forUpdateDataTableDetails,
      dataTableRecordsList,
      datatableRecordTotalCount,
      unChangedDatatableRecords
    } = useSelector((state: IRootState) => state.dataTable);

    const [currentOffset, setCurrentOffset] = useState(0);
    const [sortObject, setSortObject] = useState({ fieldId: '', sort: '', ascending: false } as ISortField);
    const [filterData, setFilterData] = useState({} as IFilter);
    const [isOpenFileUploadModal, setIsOpenFileUploadModal] = useState(false);
    const [showGridTable, setShowGridTable] = useState(false);
    const [gridEditMode, setGridEditMode] = useState<EditMode>('Normal');
    const [gridBatchChanges, setGridBatchChanges] = useState<IDatatableBatchChanges>();
    const [updatedValues, setUpdatedValues] = useState<{ [rowId: string]: number[] }>();

    const { dataTableDetails } = useSetDatatableDetails({
      forUpdateDataTableDetails,
      forUpdateDataTableFields,
      sortDataTableFieldMultiLabel
    });

    const validateDataTableRecord = (data: any) => {
      let validateField = new ValidateField();
      const fieldsToValidate = validateField.fieldsToValidate(forUpdateDataTableFields as IField[]);
      // eslint-disable-next-line
      const [isValid, dynamicRefs, errorMessage] = validateField.validateForm(fieldsToValidate, fieldRefs, 'save-record');

      return !(isValid && validateField.noErrorMessages(errorMessage));
    }

    const triggerResetBatchChange = () => {
      dispatch(getDataTableRecordsAction({
        company: companyId,
        dataTableId: match.params.dataTableId,
        limit: datatableRecordLimit
      }));
      setGridBatchChanges(undefined);
      setUpdatedValues(undefined);
    }

    const prepareDataToBeSave = (currentRecordList: IDatatableRecord[]) => {
      const tempBatchChanges: any = { ...gridBatchChanges };
      let tempCurrentList = [...currentRecordList];
      let seqFieldId = getFieldIdByConfigName(forUpdateDataTableFields, IConfigName.Seq);
      const idsForDeletion: string[] = tempBatchChanges && Object.keys(tempBatchChanges).length > 0 ?
        tempBatchChanges['deletedRecords'] || [] : [];
      tempCurrentList = tempCurrentList.filter((e: IDatatableRecord) => !idsForDeletion.includes(e.id))
      return tempCurrentList.map((e: IDatatableRecord) => {
        const tempFields: { [id: string]: any } = {};
        const f = { ...e, fields: { ...e.fields } }
        forUpdateDataTableFields.forEach(field => {
          if (field.id !== seqFieldId) {
            tempFields[field.id || ''] = f.fields[field.id || ''];
          }
          if (!f.fields[field.id || '']) {
            tempFields[field.id || ''] = null;
          }
        });
        f.fields = tempFields;
        delete f.new;
        delete f.saveAlready;
        delete f.seq;
        delete f.createdAt;
        delete f.updatedAt;
        return f;
      })
    }

    const removeReadonlyField = (recordList: IDatatableRecord[], fieldList: IField[]) => {
      return [...recordList.map((field: IDatatableRecord) => {
        const tempField = { ...field, fields: { ...field.fields } };
        fieldList.forEach((customField: IField) => {
          if (customField.accessType === IFieldAccessType.Readonly) {
            delete tempField.fields[customField.id || ''];
          }
        })
        return tempField;
      })];
    }

    const triggerBatchSave = async () => {
      const tempBatchChanges: any = { ...gridBatchChanges };
      let idsForDeletion: string[] = tempBatchChanges && Object.keys(tempBatchChanges).length > 0 ? tempBatchChanges['deletedRecords'] || [] : [];
      idsForDeletion = idsForDeletion.filter(docId => {
        const existingDoc = unChangedDatatableRecords.list.find(e => e.id === docId);
        if (existingDoc) {
          return true;
        }
        return false;
      });

      await Promise.all(idsForDeletion.map(recordId => triggerDelete(recordId)));

      const newCurrentRecordList = [...dataTableRecordsList];
      const currentFieldList = [...forUpdateDataTableFields];
      const dataToSubmit: IDataTableRecordDetailBatchInput = {
        company: companyId,
        datatableId: match.params.dataTableId,
        data: removeReadonlyField(prepareDataToBeSave(newCurrentRecordList), currentFieldList),
        limit: datatableRecordLimit,
        offset: currentOffset || 0,
      };

      dispatch(batchUpdateDatatableRecordAction(dataToSubmit));
      setGridBatchChanges(undefined);
      setUpdatedValues(undefined);
    }

    const triggerDelete = async (recordId: string) => {
      if (companyId && match.params.dataTableId) {
        try {
          const request: AxiosResponse = await Axios.delete(`${companyId}/databases/${match.params.dataTableId}/records/${recordId}`, {});
          return request.data.success;
        } catch (e) {
          const error = e as any;
          dispatch(confirmDeleteDataTableRecordErrorAction(JSON.stringify(error.response.data.message || e)))
          return error;
        }
      }
    }

    const removeDeletedItemFromChanges = (name: 'updatedRecords' | 'addedRecords', recordId: string) => {
      const tempBatchChanges: any = { ...gridBatchChanges };
      let tempChanges = tempBatchChanges[name] ? tempBatchChanges[name] : [];
      if (tempChanges && tempChanges instanceof Array && tempChanges.length > 0 && tempChanges.includes(recordId)) {
        tempChanges = tempChanges.filter(id => id !== recordId);
      }
      tempBatchChanges[name] = tempChanges;
      return tempBatchChanges;
    }

    const triggerBatchUpdate = (data: any, recordId: string, action: 'add' | 'update' | 'delete') => {
      localStorage.removeItem('gridActiveCell');
      const tempAction = !action.endsWith('e') ? action + 'e' : action;
      const name = `${tempAction}dRecords`;
      const tempBatchChanges: any = { ...gridBatchChanges };
      const tempChanges = tempBatchChanges[name] ? tempBatchChanges[name] : [];
      if (tempChanges && tempChanges instanceof Array && !tempChanges.includes(recordId)) {
        tempChanges.push(recordId);
      }
      if (action === 'delete') {
        tempBatchChanges['addedRecords'] = removeDeletedItemFromChanges('addedRecords', recordId);
        tempBatchChanges['updatedRecords'] = removeDeletedItemFromChanges('updatedRecords', recordId);
        triggerSetUpdatedColumnValues(recordId, [], action);
      }
      tempBatchChanges[name] = tempChanges;
      setGridBatchChanges(tempBatchChanges);
      if (action === 'delete') return;
      if (action === 'update') {
        const record = dataTableRecordsList.find(e => e.id === recordId);
        if (record) {
          const dataForSubmit = { id: recordId, fields: data, seq: record.seq };
          dispatch(updateDataTableRecordReturnAction({
            data: dataForSubmit as any,
            fromState: true
          }));
        }
      } else {
        const dataForSubmit = { id: recordId, fields: data, seq: 0 };
        dispatch(createDataTableRecordsReturnAction({
          data: dataForSubmit,
          fromState: true
        }))
      }
    }

    const triggerDatatableRecordSave = (data: any, recordId?: string, error?: boolean) => {
      if (!validateDataTableRecord({ ...data })) {
        dispatch(updateDataTableRecordErrorAction({ message: actionmessage.validationError }))
        return;
      }
      if (!data && !recordId && error) {
        dispatch(updateDataTableRecordErrorAction({ message: actionmessage.savingWithNoChangesMessage }))
        return;
      }
      fieldRefs.current = {};
      const dataForSubmit = {
        company: companyId,
        dataTableId: match.params.dataTableId,
        data: { fields: data }
      }
      if (recordId) {
        dispatch(updateDataTableRecordAction({ ...dataForSubmit, recordId }));
      } else {
        dispatch(createDataTableRecordsAction(dataForSubmit));
      }
    }

    const triggerFilter = (limit?: number, offset?: number, sort?: string, search?: string) => {
      const filters = parseFilterData(forUpdateDataTableFields, filterRefs);
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setFilterData({ filters: filters });
        dispatch(filterDatatableRecordAction({
          filters: filters,
          company: companyId,
          dataTableId: match.params.dataTableId,
          limit: limit || datatableRecordLimit,
          offset: offset || 0,
          sort: sort !== 'none' ? (sort || sortObject.sort) : undefined,
          globalSearch: search
        }));
      }, 1000);
      setCurrentOffset(offset || 0);
    }

    const triggerFieldReload = (value: string[], docId?: string) => {
      const existingDoc = unChangedDatatableRecords.list.find(e => e.id === docId);
      triggerFieldReloadAction({
        reloadField: value,
        fieldList: forUpdateDataTableFields,
        dispatch,
        fieldTriggerUpdateAction,
        companyId: companyId,
        statusId: '',
        formId: match.params.dataTableId,
        documentId: existingDoc ? docId || '' : `${docId}|||new`,
        dynamicRefs: fieldRefs,
        gridEditMode
      })
    }

    const downloadData = () => {
      const axiosURL = ConfigService.loadConfig().general.apiBaseUrl + `/${companyId}/databases/${match.params.dataTableId}/records/search?limit=${0}&offset=${0}&export=xlsx`;
      dispatch(setDownloadRequestsAction({
        method: 'post',
        url: axiosURL,
        data: filterData,
        match: match,
        downloadName: forUpdateDataTableDetails.name,
        headers: {
          'Accept': 'application/json'
        }
      }));
    }

    const uploadRecords = (success: boolean, data: any) => {
      if (success) {
        setCurrentOffset(data.pagination.offset);
        dispatch(batchUploadDatatableRecordReturnAction())
        dispatch(getDataTableRecordsAction({ company: companyId, dataTableId: match.params.dataTableId, limit: datatableRecordLimit }));
      } else {
        let error = JSON.stringify(data);
        if (error.indexOf('Forbidden') > -1) {
          error = `Failed to upload. User doesn't have an 'Edit/Add/Delete Records' access to Datatable '${forUpdateDataTableDetails && forUpdateDataTableDetails.name}'.`
        }
        dispatch(batchUpdateDatatableRecordErrorAction(error))
      }
    }

    const sort = (fieldId: string, sort: string, ascending: boolean) => {
      if (sort === 'none') {
        setSortObject({ fieldId: '', sort: '', ascending: false });
        triggerFilter(datatableRecordLimit, 0, 'none');
        return;
      }
      setSortObject({ fieldId, sort: sort, ascending });
      triggerFilter(datatableRecordLimit, 0, sort);
    }

    const triggerSetUpdatedColumnValues = (rowId: string, columnIndexes: number[], action?: string) => {
      const tempUpdatedValues = { ...updatedValues };
      tempUpdatedValues[rowId] = columnIndexes;
      if (action === 'delete') {
        delete tempUpdatedValues[rowId];
      }
      setUpdatedValues(tempUpdatedValues);
    }

    useGetDatatableData({
      companyId,
      dispatch,
      datatableRecordLimit,
      dataTableId: match.params.dataTableId
    });

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'data-tables',
      thirdLevelRoute: 'data-table-subroute',
      currentRoute: 'data-table-record'
    });

    useShowGridTable({
      setShowGridTable,
      forUpdateDataTableDetails,
      dataTableRecordsList,
      showGridTable
    });

    const sortForUpdateDataTableFields = dataTableDetails.fields
      ? dataTableDetails.fields.map((e: IField) => { return forUpdateDataTableFields.find((i: IField) => i.id === e.id) })
      : [];

    return <React.Fragment>
      <DataTableDetails
        dataTableName={forUpdateDataTableDetails.name}
        dataTableId={forUpdateDataTableDetails.id}
        companyId={companyId}
      />
      <div className='data-tables-record-section table-wrapper'>
        {showGridTable &&
          <>
            <DatatableRecordsGrid
              fieldRefs={fieldRefs}
              dataTableRecordsField={sortForUpdateDataTableFields as IField[]}
              dataTableRecordList={dataTableRecordsList as IFieldData[]}
              openDeleteRecordConfirmationModal={props.openDeleteRecordConfirmationModal}
              triggerFieldReload={triggerFieldReload}
              hasPageChangeRef={props.hasPageChangeRef}
              datatableRecordTotalCount={datatableRecordTotalCount}
              currentOffset={currentOffset}
              triggerDatatableRecordSave={triggerDatatableRecordSave}
              dataTableId={match.params.dataTableId}
              companyId={companyId}
              forUpdateDataTableFields={forUpdateDataTableFields}
              filterRefs={filterRefs}
              triggerFilter={triggerFilter}
              downloadData={downloadData}
              setIsOpenFileUploadModal={setIsOpenFileUploadModal}
              sort={sort}
              sortObject={sortObject}
              gridEditMode={gridEditMode}
              setGridEditMode={setGridEditMode}
              setGridBatchChanges={setGridBatchChanges}
              gridBatchChanges={gridBatchChanges}
              triggerSetUpdatedColumnValues={triggerSetUpdatedColumnValues}
              updatedColumnValues={updatedValues}
              triggerBatchUpdate={triggerBatchUpdate}
              triggerResetBatchChange={triggerResetBatchChange}
              setUpdatedValues={setUpdatedValues}
              triggerBatchSave={triggerBatchSave}
            />
            <GridPagination
              totalCount={datatableRecordTotalCount}
              currentLimit={datatableRecordLimit}
              currentOffset={currentOffset}
              changePage={triggerFilter}
            />
          </>
        }
      </div>
      <FileUploadModal
        cancel={() => setIsOpenFileUploadModal(false)}
        close={() => setIsOpenFileUploadModal(false)}
        open={isOpenFileUploadModal}
        template={{
          templateName: `${forUpdateDataTableDetails && forUpdateDataTableDetails.name}`,
          templateURL: `/${companyId}/databases/${forUpdateDataTableDetails.id}/records/batch?import=xlsx&getTemplate=true`
        }}
        onUpload={uploadRecords}
        urlGet={`${companyId}/databases/${forUpdateDataTableDetails.id}/records?limit=${datatableRecordLimit}&offset=${0}`}
        urlMethod='get'
      />
    </React.Fragment>
  }
}
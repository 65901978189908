import { publishFormLabelAction } from '../../configuration/module/labels/actions';
import { IAppliedLabels, IFormLabels } from '../../configuration/module/labels/interface/label';
import { FormLabelConfig } from '../../configuration/module/labels/utils/form-label-config';

interface IConfigLabel {
  formLabels: IFormLabels[];
  formAppliedLabels: IAppliedLabels[];
}

interface IUpdateConfigUtils {
  deletedExistingField: string[];
  formConfigLabel: IConfigLabel;
  formId: string;
  companyId: string;
  dispatch: any;
}

export class CheckFieldLabel {

  static updateConfig = (utils: IUpdateConfigUtils) => {
    const data = CheckFieldLabel.removeFieldToLabelConfig(utils.deletedExistingField, utils.formConfigLabel);
    const payload = { company: utils.companyId, formId: utils.formId };

    if (data.updatedFieldLabel) {
      Promise.all(data.updatedFieldLabel.map(data => FormLabelConfig.saveCreatedLabels(data, payload)));
    }

    if (data.updatedFieldAppliedLabel) {
      Promise.all(data.updatedFieldAppliedLabel.map(data => FormLabelConfig.saveAppliedLabels(data, payload)));
    }

    utils.dispatch(publishFormLabelAction(payload));
  }

  static removeFieldToLabelConfig = (deletedFields: string[], { formLabels, formAppliedLabels }: IConfigLabel) => {
    let updatedFieldLabel: null | IFormLabels[] = null;
    let updatedFieldAppliedLabel: null | IAppliedLabels[] = null;

    const fieldsUsedInLabel = CheckFieldLabel.hasDeletedFieldUsedInLabel(deletedFields, formLabels);
    const usedFieldLabel = CheckFieldLabel.updateLabelConfig(deletedFields, formLabels);
    const toDeleteFieldLabel = CheckFieldLabel.toDeleteLabelConfig(usedFieldLabel);

    if (fieldsUsedInLabel.length > 0) {
      updatedFieldLabel = usedFieldLabel;
      updatedFieldAppliedLabel = CheckFieldLabel.updateAppliedLabelConfig(formAppliedLabels, toDeleteFieldLabel);
    }

    return { updatedFieldLabel, updatedFieldAppliedLabel };
  }

  static hasDeletedFieldUsedInLabel = (deletedFields: string[], formLabels: IFormLabels[]) => {
    return formLabels.filter(label => {
      const existsInHeader = label.headerField.filter(field => deletedFields.indexOf(field.id) > -1).length > 0;
      const existsInBody = label.fields.filter(field => deletedFields.indexOf(field.id) > -1).length > 0;
      return existsInHeader || existsInBody;
    })
  }

  static updateLabelConfig = (deletedFields: string[], formLabels: IFormLabels[]) => {
    return formLabels.map(label => {

      const headerFieldDeleted = label.headerField.filter(field => deletedFields.indexOf(field.id) > -1);
      const fieldDeleted = label.fields.filter(field => deletedFields.indexOf(field.id) > -1);
      if (headerFieldDeleted.length > 0 || fieldDeleted.length > 0) {
        label.edited = true;
      }
      label.headerField = label.headerField.filter(field => deletedFields.indexOf(field.id) === -1);
      label.fields = label.fields.filter(field => deletedFields.indexOf(field.id) === -1);
      label.new = false;

      if (label.headerField.length === 0 && label.fields.length === 0) {
        label.deleted = true;
        label.edited = false;
      }

      return label
    });
  }

  static updateAppliedLabelConfig = (formAppliedLabels: IAppliedLabels[], toDeleteFieldLabel: IFormLabels[]) => {
    return formAppliedLabels.map(appliedLabel => {
      const isDeleted = toDeleteFieldLabel.filter(label => label.id === appliedLabel.label.id).length > 0;
      if (isDeleted) {
        appliedLabel.deleted = true;
      }
      return appliedLabel;
    });
  }

  static toDeleteLabelConfig = (formLabels: IFormLabels[]) => {
    return formLabels.filter(label => {
      return label.headerField.length === 0 && label.fields.length === 0
    })
  }
}
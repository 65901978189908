import React from 'react';
import { useManageUrlToken } from './hooks/use-manage-url-token';

class DecathlonCallback {

  name = 'decathloncallback';

  render: React.FC = () => {

    const status = useManageUrlToken();

    return <>
      {status === 'process' && <span>Processing. . . </span>}
      {status === 'failed' && <span>Something went wrong. Please try again.</span>}
      {status === 'success' && <span>Redirecting</span>}
    </>
  }
}

export default DecathlonCallback;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import APIsForm from './form';
import { useGetApiListData } from './hooks/use-get-api-list-data';
import APIsList from './list';

interface IAPIsContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const APIsContainer: React.FC<IAPIsContainerProps> = (props) => {
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { company } = useSelector((state: IRootState) => state.auth);

  useGetApiListData({
    dispatch,
    company,
    formId: match.params.formId
  });

  return <div className={`apis-container`}>
    <Route path={`${match.path}`} exact>
      <button className="ui right floated button add-button"
        onClick={() => {
          history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}/add-record`);
        }}
      >Add Api</button>
      <APIsList hasPageChangeRef={props.hasPageChangeRef} />
    </Route>
    <Route path={`${match.path}/:apiId`}>
      <APIsForm hasPageChangeRef={props.hasPageChangeRef} />
    </Route>
  </div>
}

export default APIsContainer;
import React from 'react';
import { IAutomation } from '../../../../interface/automation';
import { IAutomationComponent } from '../../../../interface/component-list';
import TriggerFieldUpdate from './field-update';
import TriggerSLAUpdate from './sla-update';
import TriggerStatusUpdate from './status-update';
import TriggerScheduler from './scheduler';

const ComponentList: ITriggerTypeRegistry[] = [
  new TriggerStatusUpdate(),
  new TriggerFieldUpdate(),
  new TriggerSLAUpdate(),
  new TriggerScheduler()
]

export interface ITriggerType {
  type: string;
}


export interface ITriggerTypeRegistryRender {
  type: string;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  selectedComponent: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
}

interface ITriggerTypeRegistry {
  name: string;
  render: React.FC<ITriggerTypeRegistryRender>
}

export class TriggerTypeRegistry {

  registry: { [name: string]: ITriggerTypeRegistry } = {};

  constructor() {
    const types = ComponentList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return null;
  }

  getModule(name: string): React.FC<any> {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<ITriggerTypeRegistryRender> = (props) => {
    const component = new TriggerTypeRegistry();
    const Render = component.getModule(props.type);
    return <Render {...props} />
  }

}
import React, { useState, useRef, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Dropdown, Image } from 'semantic-ui-react-bpm';
import { ICustomFieldProps, IDefaultRef } from '../..';
import DefaultField from '../../default-value';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig } from '../../interface/custom-field-detail';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { IFieldConfig } from '../../../../component/admin-module/module/users/interface/field';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../reducers';
import { errorIndicator, fieldVisibility, basicAllowedInput } from '../../constant';
import DropdownPopperOption from '../../../general/dropdown-popper-option';
import Properties from '../../properties';
import { IUserFieldPermission } from '../../../../component/admin-module/module/users/interface/user-field-permission';
import { plainToClass } from 'class-transformer';
import { v4 as uuid } from 'uuid';
import { icons } from '../../../icons';
import { IConfigName } from '../../../field/type/interface/field-type-object';

export default class StringFieldConfig extends DefaultFieldConfig {

  name = 'string';

  Render: React.FC<ICustomFieldProps> = (props) => {
    if (props.defaultData.configName !== IConfigName.TextString) {
      const OtherElement = new DefaultFieldConfig();
      return <OtherElement.Render {...props} />
    }

    const [refreshState, setRefreshState] = useState(1);
    const [activeIndex, setActiveIndex] = useState(1);
    const [mandatory, setMandatory] = useState(false);
    const [searchable, setSearchable] = useState(false);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [alignment, setAlignment] = useState('left');
    const [commaSeparated, setCommaSeparated] = useState(false);
    const [isMultiline, setIsMultiline] = useState(false);
    const [fieldPermissionColor, setFieldPermissionColor] = useState('');
    const [advanceConfigType, setAdvanceConfigType] = useState('none');
    const [basicSelectedOption, setSelectedBasicOption] = useState<string[]>([]);
    const [stringCase, setStringCase] = useState('case-sensitive');
    const [QRInput, setQRInput] = useState(false);
    const [confidential, setConfidential] = useState(false);

    const companyId = props.companyId;

    const user = useSelector((state: IRootState) => state.user);
    const fieldNameRef = useRef(document.createElement('input'));
    const defaultvalueRef = useRef<IDefaultRef>({});
    const helpRef = useRef(document.createElement('textarea'));
    const minLengthRef = useRef(document.createElement('input'));
    const maxLengthRef = useRef(document.createElement('input'));
    const decimalRef = useRef(document.createElement('input'));
    const otherCharRef: any = useRef(document.createElement('input'));
    const regexRef: any = useRef(document.createElement('input'));

    const [error, setError] = useState(errorIndicator)
    const showAdvanceSetting = (): boolean => {
      return advanceConfigType === 'regexp' || advanceConfigType === 'allowedChars'
    }

    const manageAdvanceConfigType = (value: string) => {
      setAdvanceConfigType(value);
      setSelectedBasicOption(
        basicAllowedInput
          .filter((option: { id: string, label: string }) => option.id !== 'custom')
          .map((option: { id: string, label: string }) => { return option.id })
      )
      otherCharRef.current.value = '';
      regexRef.current.value = '';
    }

    const addCaseToAllowedChars = (tempSelectedOption: string[]) => {
      if (stringCase === 'uppercase') {
        if (tempSelectedOption.indexOf('uppercase') === -1) {
          tempSelectedOption.splice(tempSelectedOption.indexOf('lowercase'), 1);
          tempSelectedOption.push('uppercase')
        }
      } else if (stringCase === 'lowercase') {
        if (tempSelectedOption.indexOf('lowercase') === -1) {
          tempSelectedOption.splice(tempSelectedOption.indexOf('uppercase'), 1);
          tempSelectedOption.push('lowercase')
        }
      } else {
        if (tempSelectedOption.indexOf('lowercase') === -1) {
          tempSelectedOption.push('lowercase')
        }
        if (tempSelectedOption.indexOf('uppercase') === -1) {
          tempSelectedOption.push('uppercase')
        }
      }
      return tempSelectedOption;
    }

    const inputObject = () => {
      let advanceConfig = {};
      if (advanceConfigType === 'regexp') {
        advanceConfig = {
          allowedChars: undefined,
          customAllowedChars: undefined,
          pattern: regexRef.current.value
        }
      }
      if (advanceConfigType === 'allowedChars') {
        advanceConfig = {
          allowedChars: addCaseToAllowedChars([...basicSelectedOption]),
          customAllowedChars: basicSelectedOption.indexOf('custom') > -1 ? otherCharRef.current.value : undefined,
          pattern: undefined
        }
      }
      return {
        ...configData,
        ...advanceConfig,
        alignment: alignment,
        maxLength: maxLengthRef.current && maxLengthRef.current.value ? parseFloat(maxLengthRef.current.value || '0') : undefined,
        minLength: minLengthRef.current && minLengthRef.current.value ? parseFloat(minLengthRef.current.value || '0') : undefined,
        case: stringCase,
        qrInput: QRInput
      }
    }

    const handleClick = (e: any, titleProps: any) => {
      const { index } = titleProps;
      setActiveIndex(!index ? 1 : 0);
    }

    const setBasicOption = (value: string) => {
      const tempSelectedOption = [...basicSelectedOption];
      const index = basicSelectedOption.indexOf(value);

      if (index > -1) {
        tempSelectedOption.splice(index, 1);
      } else {
        if (value === 'special') {
          const specificIndex = basicSelectedOption.indexOf('custom');
          otherCharRef.current.value = '';
          if (specificIndex > -1) tempSelectedOption.splice(specificIndex, 1);
        }
        if (value === 'custom') {
          const specialIndex = basicSelectedOption.indexOf('special');
          if (specialIndex > -1) tempSelectedOption.splice(specialIndex, 1);
        }
        tempSelectedOption.push(value);
      }
      setSelectedBasicOption(tempSelectedOption);
    }

    const updateConfig = () => {
      const permission = setFieldPermission(fieldPermissionColor || 'red');
      if (validateRequiredFormField()) {


        let defaultValue: any = null;
        if (defaultvalueRef.current['defaultField']) {
          defaultValue = defaultvalueRef.current['defaultField'].value;
          defaultValue = defaultValue || null;
        }

        const config = plainToClass(IConfig, {
          ...inputObject(),
          format: advanceConfigType,
        })

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: mandatory,
            label: fieldNameRef.current.value,
            hint: helpRef.current.value,
            defaultValue: defaultValue,
            config: config,
            multiline: isMultiline,
            permission,
            searchable: props.formType === 'User' ? searchable : undefined,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '')
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      if (props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
      }

      setError(currentError)
      return !currentError.fieldname
    }

    const setFieldPermission = (permissionColor: string) => {
      const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.name === permissionColor);
      const permission = colorPermission.length > 0 ? colorPermission[0].id : undefined;
      return permission;
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config)
        setFieldData(data)
        fieldNameRef.current.value = data.label || '';
        helpRef.current.value = data.hint || '';
        if (!defaultvalueRef.current['defaultField']) {
          defaultvalueRef.current['defaultField'] = {};
        }
        if (defaultvalueRef.current['defaultField']) {
          if (typeof data.defaultValue === 'object' && data.defaultValue !== null) {
            defaultvalueRef.current['defaultField'].value = JSON.stringify(data.defaultValue)
          } else {
            defaultvalueRef.current['defaultField'].value = data.defaultValue || ''
          }
        }
        maxLengthRef.current.value = config.max?.toString() || config.maxLength?.toString() || '';
        minLengthRef.current.value = config.min?.toString() || config.minLength?.toString() || '';
        decimalRef.current.value = config.decimals?.toString() || '';
        const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.id === data.permission);
        const permissionColor = colorPermission.length > 0 ? colorPermission[0].name : undefined;
        setFieldPermissionColor(permissionColor || 'red');
        setCommaSeparated(config.thousandSeparator || false);
        setSearchable(data.searchable ? true : false);
        setAlignment(config.alignment || 'left');
        setMandatory(data.required ? true : false);
        setIsMultiline(data.multiline ? true : false);
        setAdvanceConfigType(config.format || 'none');
        setSelectedBasicOption(config.allowedChars || []);
        setStringCase(config.case || 'case-sensitive');
        setQRInput(config.qrInput ? true : false);
        setConfidential(data.confidential || false);
        regexRef.current.value = config.pattern || '';
        otherCharRef.current.value = config.customAllowedChars || '';
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='string-field-modal'
        className={`string-field-modal ${showAdvanceSetting() ? 'with-advance-setting' : ''}`}
        centered={false}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`${props.defaultData.configName || 'Custom'} Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form'  >
            <div className={`${showAdvanceSetting() ? 'show-advance-settings' : ''}`}>
              <div className='string-normal-config'>
                <Segment className='borderless' style={{
                  marginTop: `${props.formType === 'FormBuilder' ? '-20px' : '2rem'}`
                }}>
                  <div className='header-field'>
                    {props.formType === 'User' &&
                      <span className='field-visibility'>
                        Visibility&nbsp;&nbsp;&nbsp;
                        <Dropdown
                          popperContainer={DropdownPopperOption}
                          selection
                          value={fieldPermissionColor || 'red'}
                          options={fieldVisibility}
                          selectOnBlur={false}
                          onChange={(event, target: any) => { setFieldPermissionColor(target.value) }}
                        />
                      </span>
                    }
                    {(props.formType !== 'FormBuilder') &&
                      <div className="five wide field mandatory-field" onClick={() => { setMandatory(!mandatory) }}>
                        <span>Mandatory</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={mandatory} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                    {props.formType === 'User' &&
                      <div className="five wide field searchable-field" onClick={() => { setSearchable(!searchable) }}>
                        <span>Searchable</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={searchable} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                  </div>
                  {(props.formType === 'User' || props.formType === 'FormBuilder') &&
                    <Form.Group widths='equal'>
                      <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                        <label>Field Name</label>
                        <div className="ui input">
                          <input onChange={() => {
                            error.fieldname = (fieldNameRef.current.value ? false : true)
                          }
                          } ref={fieldNameRef} type="text" />
                        </div>
                      </div>
                    </Form.Group>
                  }
                  <Form.Group widths='equal'>
                    <div className="field">
                      <label>Field Info</label>
                      <textarea rows={3} ref={helpRef}></textarea>
                    </div>
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <DefaultField
                      defaultvalueRef={defaultvalueRef}
                      configData={configData}
                      fieldData={fieldData}
                      inputObject={inputObject}
                      minLengthRef={minLengthRef}
                      maxLengthRef={maxLengthRef}
                      refreshValue={refreshState}
                      showDefaultValue={true}
                    />
                  </Form.Group>
                  <Properties
                    fieldData={fieldData}
                    configData={configData}
                    handleClick={handleClick}
                    minLengthRef={minLengthRef}
                    maxLengthRef={maxLengthRef}
                    alignment={alignment}
                    decimalRef={decimalRef}
                    commaSeparated={commaSeparated}
                    setCommaSeparated={setCommaSeparated}
                    setRefreshState={setRefreshState}
                    refreshState={refreshState}
                    setAlignment={setAlignment}
                    activeIndex={activeIndex}
                    error={error}
                    isAdvanceConfigType={showAdvanceSetting()}
                    setAdvanceConfigType={manageAdvanceConfigType}
                    stringCase={stringCase}
                    setStringCase={setStringCase}
                    QRInput={QRInput}
                    setQRInput={setQRInput}
                  />
                </Segment>
              </div>
              {showAdvanceSetting() &&
                <div className='string-special-config'>
                  <span><label className={`special-config-title`}>String Format Configuration</label></span>
                  <div>
                    <div className="ui radio checkbox" onClick={() => {
                      setAdvanceConfigType('allowedChars');
                      regexRef.current.value = '';
                    }}
                    >
                      <input type="radio" className="hidden" readOnly={true} checked={advanceConfigType === 'allowedChars'} />
                      <label>Basic: <span>Allowed input</span></label>
                    </div>
                    {
                      basicAllowedInput.map((value: { id: string, label: string }) => {
                        return <div
                          key={uuid()}
                          className={`ui checkbox`}
                          onClick={() => {
                            advanceConfigType === 'regexp'
                              ? setSelectedBasicOption([])
                              : setBasicOption(value.id);
                          }}
                        >
                          <input
                            className="hidden"
                            type="checkbox"
                            onChange={() => { }}
                            checked={basicSelectedOption.indexOf(value.id) > -1}
                            disabled={advanceConfigType === 'regexp'}
                          />
                          <label>{value.label}</label>
                        </div>
                      })
                    }
                    {
                      basicSelectedOption.indexOf('custom') > -1 &&
                      <div className={`ten wide field`}>
                        <div className="ui input" >
                          <input type="text"
                            ref={(curRef) => {
                              if (curRef) {
                                curRef.value = otherCharRef.current.value;
                                return otherCharRef.current = curRef;
                              }
                            }}
                            disabled={advanceConfigType === 'regexp' || basicSelectedOption.indexOf('special-character') > -1}
                          />
                        </div>
                      </div>
                    }
                    <div className="ui radio checkbox" onClick={() => {
                      setAdvanceConfigType('regexp');
                      setSelectedBasicOption([]);
                      otherCharRef.current.value = '';
                    }}>
                      <input type="radio" className="hidden" readOnly={true} checked={advanceConfigType === 'regexp'} />
                      <label>Expert: <span>Use Custom RegExp</span></label>
                    </div>
                    <div className={`ten wide field`}>
                      <div className="ui input">
                        <input type="text" disabled={advanceConfigType === 'allowedChars'} ref={(curRef) => {
                          if (curRef) {
                            curRef.value = regexRef.current.value;
                            return regexRef.current = curRef;
                          }
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            {props.formType === 'FormBuilder' &&
              <div id='custom-field-checkbox' style={{ marginBottom: '10px' }}>
                <div className="ui fitted checkbox" onClick={() => setConfidential(!confidential)}>
                  <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} value="" />
                  <label>Confidential</label>
                </div>
              </div>
            }
            {props.formType === 'FormBuilder' && !props.tableId &&
              <div id='custom-field-checkbox'>
                <div className="ui fitted checkbox" onClick={() => setIsMultiline(!isMultiline)}>
                  <input className="hidden" type="checkbox" onChange={() => { }} checked={isMultiline} value="" />
                  <label>Multi-line</label>
                </div>
              </div>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions className='borderless'>
          <div className='label-bottom'>
            <label><strong> * </strong> Fields are required</label>
          </div>
          <div className={`string-action-container`}>
            {!props.isViewOnly && <div>
              <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
              {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
                <Button color='red' className='btn-action create-btn'
                  onClick={() => {
                    props.cancel(props.fieldId, true)
                    props.openDeleteModal(props.fieldId, fieldData.label || '');
                  }}>Delete</Button>
              }
              {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
                <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
              }
            </div>}
          </div>
        </Modal.Actions>
      </Modal>
    </>
  }
}
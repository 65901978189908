import textString from './text-string';
import textStringMultiline from './text-string-multiline';
import integer from './integer';
import date from './date';
import time from './time';
import dropdown from './dropdown';
import url from './url';
import email from './email';
import switchField from './switch';
import { IFieldTypeObj } from './interface/field-type-object';
import checklist from './checklist';
import fx from './fx';
import table from './table';
import signature from './signature';
import gap from './gap';
import file from './file';
import autopopulated from './autopopulated';
import timestamp from './date-time';
import geoloccation from './geolocation';
import range from './range';
import phone from './phone';
import reference from './reference-form';
import dropdownMultiselect from './multiple-select';

export const fieldType: IFieldTypeObj[] = [
  textString,
  integer,
  date,
  time,
  dropdown,
  url,
  email,
  switchField,
  file,
  signature,
]

export const dataTableFieldType: IFieldTypeObj[] = [
  textString,
  integer,
  date,
  dropdown,
  file,
  fx,
  autopopulated,
  email
]

export const formBuilderFieldType: IFieldTypeObj[] = [
  fx,
  autopopulated,
  table,
  textString,
  textStringMultiline,
  integer,
  date,
  time,
  timestamp,
  range,
  email,
  phone,
  dropdown,
  checklist,
  file,
  switchField,
  signature,
  url,
  gap,
  geoloccation,
]

export const tableFieldType: IFieldTypeObj[] = [
  fx,
  autopopulated,
  textString,
  textStringMultiline,
  integer,
  date,
  time,
  email,
  dropdown,
  checklist,
  switchField,
  url,
  timestamp,
  file,
]

export const rangeFieldType: IFieldTypeObj[] = [
  date,
  timestamp
]

export const allFieldType: IFieldTypeObj[] = [
  fx,
  autopopulated,
  table,
  textString,
  textStringMultiline,
  integer,
  date,
  time,
  timestamp,
  range,
  email,
  phone,
  dropdown,
  checklist,
  file,
  switchField,
  signature,
  url,
  geoloccation,
  reference,
  dropdownMultiselect
] 
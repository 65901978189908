import { fieldIconBaseOnConfigName } from '../../component/admin-module/module/business-process/module/configuration/module/document-list/constant';
import { IApiColumnView, IColumnView } from '../../component/admin-module/module/business-process/module/configuration/module/document-list/interface/custom-view';
import { IConfigName } from '../field/type/interface/field-type-object';

export class SanitizeDocumentList {
  static structureOutgoing = (customFilterList: IColumnView[]): IApiColumnView[] => {
    let columnView: IApiColumnView[] = [];
    customFilterList.forEach((field: IColumnView) => {
      const data: IApiColumnView = {
        name: field.label,
        keyPath: field.type === 'system' ? field.fieldName : `fields.${field.fieldName}`
      };
      if (field?.agg?.isBlockForEditing) {
        data.allowEdit = field?.agg?.isBlockForEditing || false;
      }
      if (field?.agg?.summaryRow) {
        data.includeAggregate = field?.agg?.summaryRow ? true : false;
        data.aggregateOperator = field?.agg?.summaryRow;
      }
      columnView.push(data)
    })
    return columnView;
  }

  static structureIncoming = (apiColumn: IApiColumnView[]): IColumnView[] => {
    const data: IColumnView[] = [];

    apiColumn.forEach((e: IApiColumnView) => {
      const tempData: IColumnView = {
        label: e.name,
        fieldName: e.keyPath.replace('fields.', ''),
        type: 'system',
        icon: fieldIconBaseOnConfigName[IConfigName.TextString].icon, // this need to update in the form once field data is existed. For now it is default value
        iconStyle: {},
        new: false,
        color: '',
        agg: {
          isBlockForEditing: e.allowEdit || false,
          summaryRow: e.aggregateOperator || ''
        }
      }

      if (e.keyPath.indexOf('fields.') > -1) {
        tempData.type = 'field';
      }
      data.push(tempData);
    })

    return data;
  }

}
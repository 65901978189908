import React from 'react';
import { IAutomationLogsList } from '../interface/automationLogsList';
import timezone from 'moment-timezone';
import { v4 as uuid } from 'uuid';

interface IAutomationsLogsProps {
  list: IAutomationLogsList[];
  tz: string;
}

const AutomationLogs: React.FC<IAutomationsLogsProps> = (props) => {

  const renderStatus = (status: string): string => {
    let _status = status.toLocaleUpperCase();
    switch (_status) {
      case 'NO_ACTION':
        return 'NO ACTION PERFORMED';
      default:
        return _status;
    }
  }

  const renderForms = (details: any, name: string) => {
    if (!details || (details && !details.hasOwnProperty('urn')
      && !details.hasOwnProperty('id') && !details.hasOwnProperty('formId'))) {
      return null;
    }
    if (name.includes('Doc')) {
      name = 'Affected Form'
    }
    return <span key={uuid()}>
      <span>{name} : </span>
      <span className={`document-urn-link`}>
        <a target='_blank' rel="noopener noreferrer" href={`https://cloud.mobbizapps.com/#/docs/${details.urn.replace(/#/g, '')}`}>
          {details.urn}
        </a>
      </span>
    </span>
  }

  const renderAffectedForms = (affectedForms: any, name: string) => {
    if (typeof affectedForms === 'object') {
      if (Array.isArray(affectedForms)) {
        return affectedForms.map(forms => {
          return renderForms(forms, name);
        });
      }
      return renderForms(affectedForms, name);
    }
    return affectedForms;
  }

  const renderDate = (date: any): string => {
    return timezone(date).tz(props.tz).format('lll');
  }

  return (
    <div className='automation-logs-container'>
      <table className='table-container automation-logs-table'>
        <thead>
          <tr>
            <th className='th-group'>Date</th>
            <th className='th-group'>Rule</th>
            <th className='th-group'>Status</th>
            <th className='th-group'>Duration</th>
            <th className='th-group'>Affected forms</th>
          </tr>
        </thead>
        <tbody>
          {props.list && props.list.map(({ id, createdAt, name, status, context, duration }: IAutomationLogsList, idx: number) => {
            const affectedFormKeys = ['triggerDoc', 'errorName', 'message'];
            return (
              <tr key={`${id}-${idx}`}>
                <td>{renderDate(createdAt)}</td>
                <td>{name}</td>
                <td><span className={`automation-logs-status-container automation-status-${status.replace('_', '-')}`}>{renderStatus(status)}</span></td>
                <td>{`${duration.toFixed(2)}s`}</td>
                <td>
                  {affectedFormKeys.map((name) => {
                    if (!context[name]) return null;

                    return <p className={`context ${name.includes('error') ? 'error' : ''}`} key={name}>
                      {renderAffectedForms(context[name], name)}
                    </p>
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(AutomationLogs);

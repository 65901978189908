import { IField } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../field-element';

interface IUtils {
  forwardedRef: any;
  name: string;
  action?: string;
}

export const setTableConfigValues = (inputConfig: any, utils: IUtils) => {
  const name = utils.name;
  const fieldValues = inputConfig && inputConfig.config && inputConfig.config.value;
  const fieldValuesId = Object.keys(fieldValues || {});
  if (fieldValues && fieldValuesId && fieldValuesId.length > 0 && fieldValuesId[0]
    && fieldValues[fieldValuesId[0]].length > 0) {
    for (let i = 0; i < fieldValues[fieldValuesId[0]].length; i++) {
      if (!utils.forwardedRef.current[name][i]) {
        utils.forwardedRef.current[name][i] = {};
      }
      if (utils.action === 'fieldReload') {
        const columnToSetValue: string[] = getColumnToSetValue(inputConfig.config.columns, name);
        if (columnToSetValue.length > 0) {
          const columns = inputConfig.config.columns.filter((e: IField) => columnToSetValue.indexOf(e.id || '') > -1);
          setConfigValues(columns, utils, name, fieldValues, i);
        }
      } else {
        setConfigValues(inputConfig.config.columns, utils, name, fieldValues, i);
      }
    }
  }
}

const getColumnToSetValue = (columns: IField[], name: string) => {
  const columnToSetValue: string[] = [];
  columns.forEach((e: IField) => {
    const field = FieldElement.getDataElement({ ...e });
    const fieldId = field.getFieldId();
    if (field.requiresFieldData && field.requiresFieldData.length > 0) {
      field.requiresFieldData.forEach(id => {
        if (id.indexOf('.') > -1) {
          const [mainId] = id.split('.');
          if (mainId === name) {
            const exist = columnToSetValue.find(e => e === fieldId);
            if (!exist) {
              columnToSetValue.push(fieldId);
            }
          }
        }
      });
    }
  });
  return columnToSetValue;
}

const setConfigValues = (columns: IField[], utils: IUtils, name: string, fieldValues: any, i: number) => {
  columns.forEach((e: IField) => {
    const field = FieldElement.getDataElement({ ...e });
    const fieldId = field.getFieldId();
    if (!utils.forwardedRef.current[name][i].current) {
      utils.forwardedRef.current[name][i].current = {};
    }
    if (!utils.forwardedRef.current[name][i].current[fieldId]) {
      utils.forwardedRef.current[name][i].current[fieldId] = {};
    }
    if (fieldValues && fieldValues[fieldId]) {
      if (fieldValues[fieldId][i] instanceof Array) {
        utils.forwardedRef.current[name][i].current[fieldId].value = JSON.stringify(fieldValues[fieldId][i].map((e: { id: string, label: string }) => { return e.id }));
      } else {
        const columnFieldType = field.inputConfig && field.inputConfig.type;
        if (columnFieldType === 'enum' && fieldValues[fieldId] && fieldValues[fieldId][i] && fieldValues[fieldId][i].hasOwnProperty('id')) {
          utils.forwardedRef.current[name][i].current[fieldId].value = fieldValues[fieldId][i].id;
        } else {
          utils.forwardedRef.current[name][i].current[fieldId].value = fieldValues[fieldId][i];
        }
      }
    }
    if (!utils.forwardedRef.current[name][i].current[fieldId].value && field.inputConfig.type !== 'number') {
      utils.forwardedRef.current[name][i].current[fieldId].value = null;
    }

    if (utils.forwardedRef.current[name][i].current[fieldId]) utils.forwardedRef.current[name][i].current[fieldId].error = [];
    if (utils.forwardedRef.current[name] && utils.forwardedRef.current[name].error) {
      let fieldError = utils.forwardedRef.current[name].error.filter((error: any) => error && error.dataPath && error.dataPath.indexOf(`['${fieldId}'][${i}]`) > -1);
      if (!utils.forwardedRef.current[name][i].current[fieldId]) utils.forwardedRef.current[name][i].current[fieldId] = {};
      utils.forwardedRef.current[name][i].current[fieldId].error = fieldError;
    }
  });
}
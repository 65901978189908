import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import ContentLowerFieldList from './field-list';
import { IOption } from '../../../../../../../../../common/interface/option';
import { ITableMobileViewContainer } from '../../../interface/table-view';

interface IContentLower {
  columns: ICustomFieldDetail[];
  updateAdditionalFields(value: string, index: number): void;
  additionalFields: string[];
  changeFieldSequence(field: string, dragIndex: number, dropIndex: number): void;
  addAdditionalFields(): void;
  tableMobileViewList: ITableMobileViewContainer;
}

const MobileSectionContentLower: React.FC<IContentLower> = (props) => {

  const getColumnOption = (index: number): IOption[] => {
    let tempColumns = [...props.columns];
    const fieldsSelected = [] as string[];
    const tempTableMobileView = { ...props.tableMobileViewList };
    Object.keys(tempTableMobileView).forEach(key => {
      const tempMobileView: any = { ...tempTableMobileView[key] };
      if (tempMobileView) {
        Object.keys(tempMobileView).forEach(childKey => {
          const fieldId = tempMobileView[childKey];
          if (fieldId) {
            const exists = fieldsSelected.find(id => id === fieldId);
            if (!exists) {
              fieldsSelected.push(fieldId);
            }
          }
        });
      }
    });
    props.additionalFields.forEach((field, idx) => {
      if (props.additionalFields.indexOf(field) > -1 && idx !== index) {
        const exists = fieldsSelected.find(id => id === field);
        if (!exists) {
          fieldsSelected.push(field);
        }
      }
    });
    return tempColumns
      .filter(field => fieldsSelected.indexOf(field.id || '') === -1)
      .map((field: ICustomFieldDetail, index: number) => {
        return {
          key: index,
          value: field.id || '',
          text: field.label || ''
        }
      })
  }

  return <div className='lower-widget-content'>
    <span className='title'>Add Item</span>
    <div className='lower-widget dropdown-select'>
      {props.additionalFields.map((fieldId, index) => {
        return <ContentLowerFieldList
          key={index}
          index={index}
          fieldId={fieldId}
          columnOptions={getColumnOption(index)}
          addAdditionalFields={props.addAdditionalFields}
          additionalFields={props.additionalFields}
          changeFieldSequence={props.changeFieldSequence}
          updateAdditionalFields={props.updateAdditionalFields}
        />
      })}
    </div>
  </div>
}

export default MobileSectionContentLower;

import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISlaFieldOption, ISlaFormOption } from '../list/field-value-column';
import { IOption } from '../../../../../../../../../common/interface/option';

interface IProps {
  referencedBPFields: ICustomFieldDetail[];
  formValue: string;
  splitSystemFieldDateId(fieldId: string, fieldLabel: string): { id: string, label: string };
  BPSystemField: {
    id: string;
    label: string;
    type?: string | undefined;
  }[];
  currentFormId: string;
  currentFormFieldOption(fieldList: ICustomFieldDetail[], currentFormId: string): ISlaFieldOption[];
  currentFormRefFields: ICustomFieldDetail[];
  fieldList: ICustomFieldDetail[];
}

export const useSetFieldOptionFromBP = (props: IProps) => {
  const { referencedBPFields, BPSystemField, splitSystemFieldDateId, formValue,
    currentFormId, currentFormFieldOption, currentFormRefFields, fieldList } = { ...props };

  const [fieldListOption, setFieldListOption] = useState([] as ISlaFieldOption[]);

  const displayFieldOption = () => {
    let ctr = 0;
    let displayOption: ISlaFormOption[] = [];
    displayOption = displayOption.concat(
      BPSystemField
        .filter(e => e.type === 'date')
        .map(field => {
          let id = splitSystemFieldDateId(field.id, field.label).id;
          let label = splitSystemFieldDateId(field.id, field.label).label;
          return { key: ctr++, value: `${id || ''}`, text: label || '', type: field.type || '' }
        })
    );
    if (referencedBPFields.length > 0) {
      displayOption = displayOption.concat(
        referencedBPFields
          .filter(field => (field.type !== 'spacer' && field.type === 'date')
            || (field.type === 'autopopulated' && field.config && field.config.labelType === 'date'))
          .map((field: ICustomFieldDetail) => {
            return { key: ctr++, value: `fields.${field.id || ''}`, text: field.label || '', type: field.type || '' }
          }));
    }
    return displayOption;
  }

  useEffect(() => {
    if (currentFormRefFields.length > 0 && referencedBPFields.length > 0) {
      const displayFields = displayFieldOption();
      let refSelectedFields = [] as ISlaFieldOption[];

      currentFormRefFields.forEach(refFields => {
        const { config } = { ...refFields };
        const { data, dataType } = { ...config };
        const sourceId = dataType === 'database' ? data.databaseId : data.formId;
        const tempLabelFormat = config && config.labelFormat ? config.labelFormat.split(`}}${config.separator || ' '}{{`) : [];
        const displayOptionItems = tempLabelFormat.map((item: string) => item.replace(/{/g, '').replace(/}/g, ''));
        if (formValue === sourceId) {
          displayOptionItems.forEach(selectedId => {
            let itemId = splitSystemFieldDateId(selectedId, '').id;
            const itemOption = displayFields.find((list: IOption) => itemId === list.value);
            if (itemOption) {
              refSelectedFields.push({
                ...itemOption,
                source: sourceId,
                id: refFields.id || '',
              });
            }
          });
        }
      });

      if (formValue === currentFormId) {
        setFieldListOption(currentFormFieldOption(fieldList, currentFormId));
      } else {
        setFieldListOption(refSelectedFields);
      }
    } else {
      if (formValue === currentFormId) {
        setFieldListOption(currentFormFieldOption(fieldList, currentFormId));
      }
    }
    // eslint-disable-next-line
  }, [referencedBPFields, formValue]);

  return { ...props, fieldListOption, setFieldListOption };
}
import React from 'react';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';
import { IFormAction } from '../interface/form-action';
import AssignmentType from './assignment-type';
// import ToolTip from '../../../../../../../common/general/tooltip';

interface ITableListDetailAction {
  formAction: IFormAction;
  editAction(formAction: IFormAction): void;
}

const TableListDetailAction: React.FC<ITableListDetailAction> = (props) => {

  const assignmentType = props.formAction.config && props.formAction.config.assignmentType && props.formAction.config.direction
    ? `${props.formAction.config.direction}-${props.formAction.config.assignmentType}-${props.formAction.config.return ? 'return' : ''}`
    : undefined;

  return <td style={{ overflowWrap: 'break-word' }} className='bg-gray'>
    <div className='action-button'>
      <div className='action-label'>
        <SVGWrapper iconName='newAction' width={25} height={25} color='#3a85a8' />
        <label className='text-blue action'>{props.formAction.name}</label>
      </div>
      {assignmentType &&
        <AssignmentType
          assignmentType={assignmentType || ''}
          selectedAssignmentType={''}
          formAction={props.formAction}
          editAction={props.editAction}
          screen='workflow-table'
        />
      }
    </div>


  </td>;
}

export default TableListDetailAction;
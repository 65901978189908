import { IPublishedFormDetailInput } from '../../../../../../admin-module/module/business-process/module/form-builder/interface/input/published-form-get'
import { IPublishedFormResponse } from '../../../../../../admin-module/module/business-process/module/form-builder/interface/response/published-form-response'
import { GET_PUBLISHED_FORM_LIST, GET_PUBLISHED_FORM_LIST_RETURN } from '../constant'

export const getPublishedFormAction = (payload: IPublishedFormDetailInput) => {
  return { type: GET_PUBLISHED_FORM_LIST, ...payload }
}

export const getPublishedFormReturnAction = (payload: IPublishedFormResponse) => {
  return { type: GET_PUBLISHED_FORM_LIST_RETURN, ...payload }
}
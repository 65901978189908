import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
  label?: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Referenced,
  required: false,
  type: 'enum',
};

interface IDefaultConfigObject {
  dataType: string;
  data: any,
  multiselect: boolean;
  displayType: string;
  separator: string;
}

const defaultConfig: IDefaultConfigObject = {
  data: [],
  multiselect: false,
  displayType: 'dropdown',
  dataType: 'static',
  separator: ''
};

const value = {
  title: 'Referenced',
  value: 'enum',
  icon: {
    black: require('../../icons/black/datatable.png'),
    white: require('../../icons/white/datatable.png'),
    blue: require('../../icons/blue/datatable.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  fieldTypeStyle: {
    width: '23px',
    height: '23px',
    right: '11px',
    top: '5px',
  },
  defaultData,
  defaultConfig
}

export default value;
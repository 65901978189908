import { useEffect } from 'react';
import { ICommentAttachment } from '../../../../../interface/comment';
import { getFileType } from '../../system/delta-fields/utils/get-file-type';

interface IProps {
  setParsedAttachments: (value: React.SetStateAction<ICommentAttachment[]>) => void;
  attachments: ICommentAttachment[];
}

export const useSetParsedAttachments = ({ setParsedAttachments, attachments }: IProps): void => {
  useEffect(() => {
    const newAttachmentList = [] as ICommentAttachment[];

    for (let attachment of attachments) {
      const fileType = getFileType({ ...attachment, type: attachment.mime });
      let type = 'other';
      if (fileType.isImage && fileType.isSupportedImage) {
        type = 'image';
      }
      if (fileType.isOffice) {
        type = 'office';
      }
      if (fileType.isText) {
        type = 'txt';
      }
      if (fileType.isPDF) {
        type = 'pdf';
      }
      if (fileType.isAudio || fileType.isVideo) {
        type = 'media';
      }
      if (fileType.isDoc) {
        type = 'document';
      }
      newAttachmentList.push({
        ...attachment,
        url: attachment.url,
        type: type,
      });
    }
    setParsedAttachments(newAttachmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);
};
import { SanitizeBPR } from './../../../../../../../../common/utils/sanitize-bpr';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SAVE_DRAFT_BP_RELATIONSHIP,
  PUBLISH_BP_RELATIONSHIP,
  GET_BPR_LIST,
  GET_BPR_FORM_FIELDS,
} from './constant';
import { setStatusCodeAction } from '../../../../../../../error/action';
import {
  saveDraftBPRelationshipReturnAction,
  publishBPRelationshipReturnAction,
  publishBPRelationshipErrorAction,
  saveDraftBPRelationshipErrorAction,
  getBPRListReturnAction,
  getBPRListErrorAction,
  getBPRFormFieldsReturnAction,
  getBPRFormFieldsErrorAction,
  getBPRFormFieldsSanitizeReturnAction,
} from './action';
import {
  getRequest,
  IAxiosResponse,
  postRequest,
  putRequest,
} from '../../../../../../../../common/api-request';
import { IReference } from './interface/form';
import { IFormGetListInput } from './interface/input/form-get';
import { IBPFormFieldsInput } from './interface/input/bp-form-fields.get';
import { IBPRelationshipSaveInput } from './interface/input/bp-relationship-save';
import { IFormRelated } from './interface/bp-relationship-field';

export function* getBPRBusinessProcessList(args: IFormGetListInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const reference: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms/${formId}/references?draft=true`,
      {}
    );
    const relation: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms/${formId}/bpr?draft=true`,
      {}
    );

    yield put(
      getBPRListReturnAction({
        referenceList: reference.data.data as IReference[],
        BPRelationshipList: SanitizeBPR.structureIncoming(
          relation.data.data.relatedReferences,
          reference.data.data
        ),
      })
    );
  } catch (e) {
    console.log(e)
    yield put(setStatusCodeAction(e));
    yield put(getBPRListErrorAction());
  }
}

export function* getBPRFormFields(args: IBPFormFieldsInput) {
  try {
    const company = args.company;
    const relatedForm = args.relatedForm;

    let req: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms/${relatedForm.relationId}/fields?draft=true`,
      {}
    );

    if (relatedForm.dataType === 'database') {
      req = yield call(
        getRequest,
        `${company}/databases/${relatedForm.relationId}/fields`,
        {}
      );
    }

    // TO BE REFACTOR
    if (args.sanitize) {
      yield put(
        getBPRFormFieldsSanitizeReturnAction({
          relatedForm: SanitizeBPR.structureRelatedForm(
            relatedForm,
            req.data.data
          ) as IFormRelated,
        })
      );
    } else {
      yield put(getBPRFormFieldsReturnAction({ data: req.data.data }));
    }
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(getBPRFormFieldsErrorAction());
  }
}

export function* saveDraftBPRelationship(args: IBPRelationshipSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const relatedReferences = args.relatedReferences;

    const relation: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}/bpr?draft=true`, { relatedReferences: relatedReferences });
    const reference: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/references?draft=true`, {});

    yield put(
      saveDraftBPRelationshipReturnAction({
        BPRelationshipList: SanitizeBPR.structureIncoming(
          relation.data.data.relatedReferences,
          reference.data.data
        ),
        referenceList: reference.data.data as IReference[],
        BPRelationshipSaveType: args.saveType,
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      saveDraftBPRelationshipErrorAction(
        JSON.stringify(e.response.data.message)
      )
    );
  }
}

export function* publishBPRelationship(args: IBPRelationshipSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(
      postRequest,
      `${company}/forms/${formId}/publish?force=false`,
      null
    );
    yield put(publishBPRelationshipReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      publishBPRelationshipErrorAction(JSON.stringify(e.response.data.message))
    );
  }
}

export function* bpRelationshipSaga() {
  yield takeLatest(GET_BPR_LIST, getBPRBusinessProcessList);
  yield takeLatest(GET_BPR_FORM_FIELDS, getBPRFormFields);
  yield takeLatest(SAVE_DRAFT_BP_RELATIONSHIP, saveDraftBPRelationship);
  yield takeLatest(PUBLISH_BP_RELATIONSHIP, publishBPRelationship);
}

// All sagas to be loaded
export default bpRelationshipSaga;

import React, { useEffect, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { IComputeField } from '.';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import SVGWrapper from '../../../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { operatorOption } from '../../../../../constant';

interface ISelectedComputeFieldProps {
  computeFields: IComputeField;
  updateComputeField(id: string, type: 'selectedOperator' | 'value', value: string): void;
  deleteComputeField(id: string): void;
  formFieldList: ICustomFieldDetail[]
}

const SelectedComputeField: React.FC<ISelectedComputeFieldProps> = (props) => {
  const selectedField = props.formFieldList.find(e => e.id === props.computeFields.selectedField);
  const valueRef = useRef(document.createElement('input'));

  useEffect(() => {
    valueRef.current.value = props.computeFields.value;
  }, [props.computeFields]);

  return <div className='automation-field-container'>
    <div className={`automation-field compute-field`}>
      <div className='compute-fields-container'>
        <div onClick={() => props.deleteComputeField(props.computeFields.id)}>
          <SVGWrapper width={12} height={12} iconName='closeIcon' color={`#0082C3`} />
        </div>
        <div className='compute-field-details'>
          <span className='field-label'>{(selectedField && selectedField.label) || ''}</span>
          <div className='field-operator-container'>
            <span className='op-label'>Operator</span>
            <div className='op-fields'>
              <Dropdown
                className='operator field'
                fluid
                selection
                clearable
                search
                value={props.computeFields.selectedOperator}
                options={operatorOption}
                selectOnBlur={false}
                onChange={(event, target: any) => props.updateComputeField(props.computeFields.id, 'selectedOperator', target.value)}
              />
              <div className={`one wide field`}>
                <div className="ui input field">
                  <input type='number' ref={valueRef} onBlur={() => props.updateComputeField(props.computeFields.id, 'value', valueRef.current.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default SelectedComputeField;
import { IField } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldConstruct } from '../../../../field-construct';

interface IUtils {
  field: IField;
  forwardedRef: any;
  tableFieldEnumOptionsRef: any;
  name: string;
  maxRowState: number;
  minRowState: number;
}

export const updateTableConfig = (utils: IUtils) => {
  const originalValue = FieldConstruct.getFieldDataFromRefs([utils.field], utils.forwardedRef, undefined, 'automation');

  if (originalValue[utils.name] && utils.field.inputConfig
    && utils.field.inputConfig.config && utils.field.inputConfig.config.value) {
    utils.field.inputConfig.config.value = originalValue[utils.name];
  }

  if (utils.field.inputConfig
    && utils.field.inputConfig.config
    && utils.field.inputConfig.config
    && utils.field.inputConfig.config.columns
    && utils.tableFieldEnumOptionsRef
    && utils.tableFieldEnumOptionsRef.current
    && utils.tableFieldEnumOptionsRef.current[utils.name]) {
    utils.field.inputConfig.config.maxRows = utils.maxRowState;
    utils.field.inputConfig.config.minRows = utils.minRowState;
    utils.field.inputConfig.config.columns = utils.field.inputConfig.config.columns.map(tblField => {
      const tableFieldId = tblField.id || '';
      const fieldOptions = utils.tableFieldEnumOptionsRef.current[utils.name];
      const columnFieldType = tblField.inputConfig && tblField.inputConfig.type;
      if (columnFieldType === 'enum' && fieldOptions[tableFieldId] && tblField.inputConfig && tblField.inputConfig.config) {
        tblField.inputConfig.config.items = fieldOptions[tableFieldId];
      }
      return tblField;
    });
  }

  return utils.field;
}
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../../../reducers';
import {
  getStatusListAction,
  getApiConfigAction
} from '../../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetApiListData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };
  const { apiConfigurationList } = useSelector((state: IRootState) => state.configurationAPIs);

  useEffect(() => {
    if (company && formId && apiConfigurationList.length === 0) {
      dispatch(getStatusListAction({
        company: company,
        formId: formId
      }))
      dispatch(getApiConfigAction({
        company: company,
        formId: formId
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId])
}
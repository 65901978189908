import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react-bpm';
import ConfigService from '../../common/config';
import { IRootState } from '../../reducers';
import { IHasPageChangeRef } from '../main/interface/has-page-change';
import { portalSubModules } from './constant';
import { PortalModuleRegistry } from './module/module-registry';
import { PortalHeaderMenu } from './utils';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
}

interface IAdminPortal {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PortalModule: React.FC<IAdminPortal> = (props) => {
  const history = useHistory();
  const match: IRouteMatch = useRouteMatch();
  const data = useSelector((state: IRootState) => state.main);

  useEffect(() => {
    if (match.url === '/portal' || !match.params.submodule) {
      history.push('/portal/stakeholders')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment>
    <Container className={`portal-main-container
    ${ConfigService.loadConfig().general.sandbox ? `portal-main-container-test` : `portal-main-container-prod`}
    `} fluid>
      <PortalHeaderMenu.Render
        type={data.subRoute}
        modules={portalSubModules}
        history={history}
        main={data}
        hasPageChangeRef={props.hasPageChangeRef}
      />
      <Route path={`${match.path}`}>
        <PortalModuleRegistry.Render
          type={match.params.submodule || ''}
          hasPageChangeRef={props.hasPageChangeRef}
        />
      </Route>
    </Container>
  </React.Fragment>
}

export default PortalModule;
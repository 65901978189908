import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getRequest, IAxiosResponse } from '../../../common/api-request';
import { setStatusCodeAction } from '../../error/action';
import { filterAdminHistoryErrorAction, filterAdminHistoryReturnAction, getAdminHistoryLogsErrorAction, getAdminHistoryLogsReturnAction } from './action';
import { GET_ADMIN_HISTORY_LOGS, FILTER_ADMIN_HISTORY } from './constant';
import { IHistoryGetInput } from './interface/input/history-get';
import { ManageAdminHistory } from './utils/manage-history';


export function* getAdminHistoryLogs(args: IHistoryGetInput) {
  try {
    let company = args.company;
    let categories = args.categories.map((category, index) => {
      if (index === 0) {
        return `categories=${category}`;
      }
      return `&categories=${category}`
    }).toString().replace(/,/g, '');
    let adminHistoryArgs = { ...args } as any;
    delete adminHistoryArgs.type;
    const req: IAxiosResponse = yield call(getRequest, `${company}/admin/history?${categories}&offset=0&limit=0${args.filters ? `&${args.filters}` : ''}`, {});

    yield put(getAdminHistoryLogsReturnAction({
      adminHistoryLogs: ManageAdminHistory.sanitizeData(req.data.data, {
        fields: args.fields,
        userList: args.users,
        selectedCategoryFilter: args.categories,
        statusList: args.statusList,
        dataTableList: args.dataTableList,
        tz: args.tz
      }),
      adminHistorySenders: ManageAdminHistory.senders(req.data.data),
      adminHistoryArgs: adminHistoryArgs
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getAdminHistoryLogsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* filterAdminHistory(args: IHistoryGetInput) {
  try {
    let company = args.company;
    let categories = args.categories.map(category => `&categories=${category}`).toString().replace(',', '');
    let senders = args.senders && args.senders.length > 0 ? args.senders.map(senders => `&senders=${senders}`).toString().replace(',', '') : '';

    const req: IAxiosResponse = yield call(getRequest, `${company}/admin/history?offset=0${categories}&limit=0${senders}${args.filters ? `&${args.filters}` : ''}`, {});
    yield put(filterAdminHistoryReturnAction({
      adminHistoryLogs: ManageAdminHistory.sanitizeData(req.data.data, {
        fields: args.fields,
        userList: args.users,
        tz: args.tz
      }),
    }));

  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(filterAdminHistoryErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* adminHistorySagas() {
  yield takeLatest(GET_ADMIN_HISTORY_LOGS, getAdminHistoryLogs);
  yield takeLatest(FILTER_ADMIN_HISTORY, filterAdminHistory)
}

// All sagas to be loaded
export default adminHistorySagas;
import { icons } from '../../../../../../../../common/icons';
import { IColumnView } from './interface/custom-view';
import { CSSProperties } from 'react';
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_COLUMN_VIEW_LIST = 'app/app/admin-module/business-process/configuration/document-list/GET_COLUMN_FIELD_LIST';
export const GET_COLUMN_VIEW_LIST_RETURN = 'app/app/admin-module/business-process/configuration/document-list/GET_COLUMN_FIELD_LIST_RETURN';
export const GET_FORM_FIELD_LIST = 'app/app/admin-module/business-process/configuration/document-list/GET_FORM_FIELD_LIST';
export const GET_FORM_FIELD_LIST_RETURN = 'app/app/admin-module/business-process/configuration/document-list/GET_FORM_FIELD_LIST_RETURN';
export const GET_FORM_DETAIL = 'app/app/admin-module/business-process/configuration/document-list/GET_FORM_DETAIL';
export const GET_FORM_DETAIL_RETURN = 'app/app/admin-module/business-process/configuration/document-list/GET_FORM_DETAIL_RETURN';
export const SAVE_DRAFT_COLUMN_VIEW = 'app/app/admin-module/business-process/configuration/document-list/SAVE_DRAFT_COLUMN_VIEW';
export const SAVE_DRAFT_COLUMN_VIEW_ERROR = 'app/app/admin-module/business-process/configuration/document-list/SAVE_DRAFT_COLUMN_VIEW_ERROR';
export const SAVE_DRAFT_COLUMN_VIEW_RETURN = 'app/app/admin-module/business-process/configuration/document-list/SAVE_DRAFT_COLUMN_VIEW_RETURN';
export const PUBLISH_COLUMN_VIEW = 'app/app/admin-module/business-process/configuration/document-list/PUBLISH_COLUMN_VIEW';
export const PUBLISH_COLUMN_VIEW_RETURN = 'app/app/admin-module/business-process/configuration/document-list/PUBLISH_COLUMN_VIEW_RETURN';
export const PUBLISH_COLUMN_VIEW_ERROR = 'app/app/admin-module/business-process/configuration/document-list/PUBLISH_COLUMN_VIEW_ERROR';
export const CLEAR_SAVE_COLUMN_VIEW_SUCCESS = 'app/app/admin-module/business-process/configuration/document-list/CLEAR_SAVE_COLUMN_VIEW_SUCCESS';
export const CLEAR_SAVE_COLUMN_VIEW_ERROR = 'app/app/admin-module/business-process/configuration/document-list/CLEAR_SAVE_COLUMN_VIEW_ERROR';

export const systemFieldList: IColumnView[] = [
  {
    type: 'system',
    label: 'Status',
    fieldName: 'statusId',
    icon: icons.blue.flag,
    iconStyle: {
      marginTop: '0px',
      width: '14px',
    },
    color: 'status',
    new: true,
  },
  {
    type: 'system',
    label: 'Owner',
    fieldName: 'owner',
    icon: icons.blue.font,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    color: 'grey',
    new: true,
  },
  {
    type: 'system',
    label: 'Doc creation Date',
    fieldName: 'createdAt',
    icon: icons.blue.calendar,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    color: 'grey',
    new: true,
  },
  {
    type: 'system',
    label: 'Doc Creator',
    fieldName: 'creator',
    icon: icons.blue.font,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    color: 'grey',
    new: true,
  },
  {
    type: 'system',
    label: 'Last Update date',
    fieldName: 'updatedAt',
    icon: icons.blue.calendar,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    color: 'grey',
    new: true,
  },
  {
    type: 'system',
    label: 'Last Updated by',
    fieldName: 'updatedBy',
    icon: icons.blue.font,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    color: 'grey',
    new: true,
  }
];

export const fieldIconBaseOnConfigName: { [x: string]: { icon: NodeRequire, iconStyle: CSSProperties, dropdownStyle?: CSSProperties } } = {
  'Checklist': {
    icon: icons.blue.checklist,
    iconStyle: {
      marginTop: '1px',
      width: '16px',
    },
    dropdownStyle: {
      marginTop: '-3px',
      width: '17px'
    }
  },
  'Date': {
    icon: icons.blue.calendar,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'Dropdown': {
    icon: icons.blue.dropdown,
    iconStyle: {
      marginTop: '3px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '19px',
    }
  },
  'Referenced': {
    icon: icons.blue.dropdown,
    iconStyle: {
      marginTop: '3px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '3px',
      width: '17px',
    }
  },
  'Email': {
    icon: icons.blue.email,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-3px',
      width: '16px',
    }
  },
  'Number': {
    icon: icons.blue.number,
    iconStyle: {},
    dropdownStyle: {
      marginTop: '2px',
      width: '22px',
    }
  },
  'Seq': {
    icon: icons.blue.number,
    iconStyle: {}
  },
  'Switch': {
    icon: icons.blue.off,
    iconStyle: {
      marginTop: '5px',
      width: '16px',
    },
    dropdownStyle: {
      marginTop: '1px',
      width: '18px',
    }
  },
  'Text String Multiline': {
    icon: icons.blue.alignJustify,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'Text String': {
    icon: icons.blue.font,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-5px',
      width: '16px',
    }
  },
  'Time': {
    icon: icons.blue.clock,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'File Upload': {
    icon: icons.blue.paperclip,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'URL': {
    icon: icons.blue.link,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'FX': {
    icon: icons.blue.fx,
    iconStyle: {
      marginTop: '4px',
      width: '15px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'Autopopulated': {
    icon: icons.blue.autopopulated,
    iconStyle: {
      marginTop: '2px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-4px',
      width: '20px',
      height: '20px'
    }
  },
  'Timestamp': {
    icon: icons.blue.dateTime,
    iconStyle: {
      marginTop: '2px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-4px',
      width: '20px',
      height: '20px'
    }
  },
  'Range': {
    icon: icons.blue.range,
    iconStyle: {
      marginTop: '2px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-4px',
      width: '20px',
      height: '20px'
    }
  },
  'Phone': {
    icon: icons.blue.phone,
    iconStyle: {
      marginTop: '2px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-4px',
      width: '20px',
      height: '20px'
    }
  }
}

export const documentListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_COLUMN_VIEW_SUCCESS,
    type: 'success',
    reducerContainer: 'documentList',
    reducerItem: 'saveColumnViewMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_COLUMN_VIEW_SUCCESS,
    type: 'negative',
    reducerContainer: 'documentList',
    reducerItem: 'saveColumnViewErrorMessage',
    hasCloseIcon: true
  }
]
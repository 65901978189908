import { IForm } from '../../../admin-module/module/business-process/module/form/interface/form';
import {
  GET_FORM_RETURN,
  GET_FORM_FIELD_RETURN,
  GET_DOCUMENT_ACTION_RETURN,
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_RETURN,
  SAVE_DOCUMENT_ERROR,
  CLEAR_MESSAGES,
  GET_FORM_FIELD,
  CLEAR_SAVE_DOCUMENT_ERROR,
  CLEAR_SAVE_DOCUMENT_SUCCESS,
  TRIGGER_FIELD_TO_UPDATE_RETURN,
  SAVE_COMMENT_RETURN,
  UPDATE_COMMENT_RETURN,
  DELETE_COMMENT_RETURN,
  RESET_DOCUMENT_FORM,
  GET_DOCUMENTS_DUPLICATE_RETURN,
  GET_DOCUMENTS_DUPLICATE,
  GET_COMMENT_SENDER_RETURN,
  GET_DOCUMENTS_ACTION_CONFIG_RETURN,
  GET_INDIVIDUAL_USERS_LIST_RETURN,
  GET_DOCUMENTS_FOLLOWERS_RETURN,
  UNFOLLOW_DOCUMENT_RETURN,
  SAVING_WITH_NO_CHANGES,
  RESET_SAVING_WITH_NO_CHANGES_MESSAGE,
  RESET_CURRENT_ACTION_TYPE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_RETURN,
  DELETE_DOCUMENT_ERROR,
  RESET_DELETE_DOCUMENT_ERROR_MESSAGE,
  RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_ATTACHMENT,
  GET_RELATED_FORMS,
  GET_RELATED_FORMS_RETURN,
  GET_RELATED_DOCUMENTS,
  GET_RELATED_DOCUMENTS_RETURN,
  RESET_RELATED_FORMS,
  GET_RELATED_DATATABLE,
  GET_RELATED_DATATABLE_RETURN,
  GET_FORM_FIELD_SHORTCUT,
  GET_FORM_FIELD_RETURN_SHORTCUT,
  GET_FORM_SHORTCUT_RETURN,
  TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN,
  GET_DOCUMENT_ACTION_SHORTCUT_RETURN,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN,
  SAVE_DOCUMENT_SHORTCUT,
  SAVE_DOCUMENT_SHORTCUT_RETURN,
  SAVE_DOCUMENT_SHORTCUT_ERROR,
  RESET_DOCUMENT_FORM_SHORTCUT,
  CLEAR_SAVE_DOCUMENT_SUCCESS_SHORTCUT,
  CLEAR_SAVE_DOCUMENT_ERROR_SHORTCUT,
  GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN,
  GET_INDIVIDUAL_USERS_LIST,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT,
  RESET_REFERENCE_FORM_FIELD_RETURN,
  RESET_REFERENCE_FORM_FIELD,
  UPDATE_COMMENT_UNREAD_COUNT,
  GET_AUTOMATION_CONFIG,
  GET_AUTOMATION_CONFIG_RETURN,
  UPDATE_DOCUMENT_ACTION,
  GET_AUTOMATION_CONFIG_SHORTCUT,
  GET_AUTOMATION_CONFIG_SHORTCUT_RETURN,
  UPDATE_DOCUMENT_ACTION_SHORTCUT,
  RESET_FORM_FIELDS_AUTOPOPULATE,
  REFRESH_FORM,
  REFRESH_FORM_SHORTCUT,
  GET_INDIVIDUAL_USERS_LIST_ERROR,
  GET_INDIVIDUAL_USERS_LIST_RESET,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR,
  CLEAR_COMMENT_LIST,
  GET_FORM_ERROR,
  GET_COMMENTS_RETURN
} from './constant';
import { IField } from '../../../admin-module/module/users/interface/field';
import { IFormStatusWithAction } from '../../../admin-module/module/business-process/module/workflow/interface/form-status';
import { IUserField } from '../../../admin-module/module/users/interface/user-field';
import { IDocumentAction } from './interface/document-action';
import { message } from './message';
import { IFormDocument } from '../report/interface/form-document';
import { IComment } from './interface/comment';
import { ISenderInfo } from './interface/sender-info';
import { IDocumentActionConfig } from './interface/document-action-config';
import { IRelatedFormField, IRelatedFormRecord } from './interface/related-form';
import { AutomationRule } from './automation/automation-config';
import { v4 as uuid } from 'uuid';
import { manageFxFieldReload } from './utils/manage-fx-field-reload';
import { manageFieldReload } from './utils/manage-form-data';

export interface IDocumentFormReducer {
  type?: string;
  loading?: boolean;
  formDetail: IForm;
  formField: IField[];
  userField: IUserField[];
  documentDetail?: IFormDocument;
  formStatus: IFormStatusWithAction;
  documentAction: IDocumentAction[];
  formFieldLoading: boolean;
  saveDocumentLoading: boolean;
  saveDocumentMessage: string;
  saveDocumentErrorMessage: string;
  commentList: IComment[];
  comment: IComment;
  commentId?: string;
  documentsDuplicate: IFormDocument[];
  documentsDuplicateLoading: boolean;
  senderList: ISenderInfo[];
  hasSystemComment: boolean;
  hasUserComment: boolean;
  hasAttachment: boolean;
  valueFieldReload: IField[];
  documentFollowers: any[];
  usersId?: string;
  documentActionConfig: IDocumentActionConfig[];
  individualUsersList: any[];
  savingWihoutChangesMessage: string;
  currentFormActionType: string;
  deleteDocumentLoading: boolean;
  deleteTriggerCloseDocument: boolean;
  deleteDocumentSuccessMessage: string;
  deleteDocumentErrorMessage: string;
  deleteAttachmentLoading: boolean;
  relatedForms: IRelatedFormField[];
  relatedDocument: IRelatedFormRecord[];
  relatedDatatable: IRelatedFormRecord[];
  relatedFormsLoading: boolean;
  formFieldLoadingShortcut: boolean,
  formFieldShortcut: IField[],
  formStatusShortcut: IFormStatusWithAction;
  documentDetailShortcut?: IFormDocument;
  saveDocumentMessageShortcut: string;
  saveDocumentErrorMessageShortcut: string;
  formDetailShortcut: IForm;
  valueFieldReloadShortcut: { [id: string]: any };
  documentActionShortcut: IDocumentAction[];
  individualUsersListShortcut: any[];
  saveDocumentShortcutLoading: boolean;
  saveDocumentShortcutMessage: string;
  saveDocumentShortcutErrorMessage: string;
  currentFormActionTypeShortcut: string;
  saveDocumentCloseDocumentShortcut: boolean;
  documentsDuplicateShortcut: IFormDocument[];
  individualUsersListLoading: boolean;
  individualUsersListShortcutLoading: boolean;
  shortcutSelectedValues: IField[];
  resetFormReferenceFieldLoading: boolean;
  commentUnread: IComment[];
  automationConfig?: AutomationRule[];
  automationConfigShortcut?: AutomationRule[];
  fieldConfigChange: IField[];
  fieldConfigChangeShortcut: IField[];
  refreshForm?: string;
  refreshFormShortcut?: string;
  getIndividualUserFailed?: boolean;
  getIndividualUserFailedShortcut?: boolean;
  duplicateActionType: string;
  fxFieldReload?: IField[];
  triggeredOnChange?: boolean;
  getFormErrorMessage: string;
  autopopulateFieldReload?: IField[];
  companyId: string;
}

const InitialState: IDocumentFormReducer = {
  formDetail: {} as IForm,
  formField: [],
  userField: [],
  formStatus: {} as IFormStatusWithAction,
  documentDetail: undefined,
  documentAction: [],
  saveDocumentLoading: false,
  formFieldLoading: false,
  saveDocumentMessage: '',
  saveDocumentErrorMessage: '',
  commentList: [],
  comment: {} as IComment,
  documentsDuplicate: [],
  documentsDuplicateLoading: false,
  senderList: [],
  hasSystemComment: false,
  hasUserComment: false,
  hasAttachment: false,
  valueFieldReload: [],
  documentFollowers: [],
  documentActionConfig: [],
  individualUsersList: [],
  savingWihoutChangesMessage: '',
  currentFormActionType: '',
  deleteDocumentLoading: false,
  deleteTriggerCloseDocument: false,
  deleteDocumentSuccessMessage: '',
  deleteDocumentErrorMessage: '',
  deleteAttachmentLoading: false,
  relatedForms: [] as IRelatedFormField[],
  relatedDatatable: [],
  relatedDocument: [],
  relatedFormsLoading: false,
  formFieldShortcut: [],
  formFieldLoadingShortcut: false,
  formStatusShortcut: {} as IFormStatusWithAction,
  documentDetailShortcut: undefined,
  saveDocumentMessageShortcut: '',
  saveDocumentErrorMessageShortcut: '',
  formDetailShortcut: {} as IForm,
  valueFieldReloadShortcut: {},
  documentActionShortcut: [],
  individualUsersListShortcut: [],
  saveDocumentShortcutLoading: false,
  saveDocumentShortcutMessage: '',
  saveDocumentShortcutErrorMessage: '',
  currentFormActionTypeShortcut: '',
  saveDocumentCloseDocumentShortcut: false,
  documentsDuplicateShortcut: [],
  individualUsersListLoading: false,
  individualUsersListShortcutLoading: false,
  shortcutSelectedValues: [],
  resetFormReferenceFieldLoading: false,
  commentUnread: [],
  automationConfig: undefined,
  automationConfigShortcut: undefined,
  fieldConfigChange: [],
  fieldConfigChangeShortcut: [],
  refreshForm: undefined,
  refreshFormShortcut: undefined,
  getIndividualUserFailed: undefined,
  getIndividualUserFailedShortcut: undefined,
  duplicateActionType: '',
  getFormErrorMessage: '',
  companyId: ''
};

const documentFormReducer = (
  state = InitialState,
  action: IDocumentFormReducer
) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_RETURN:
      newState.formDetail = action.formDetail;
      return newState;

    case GET_FORM_FIELD:
      newState.saveDocumentMessage = '';
      newState.saveDocumentErrorMessage = '';
      newState.formFieldLoading = true;
      newState.documentDetail = undefined;
      return newState;

    case GET_FORM_FIELD_RETURN:
      newState.formFieldLoading = false;
      newState.formStatus = action.formStatus;
      newState.documentDetail = action.documentDetail;
      let reload = manageFieldReload(
        action.formField,
        action.formField,
        undefined,
        action.documentDetail
      );
      newState.formField = reload.tempField;
      return newState;

    case GET_DOCUMENT_ACTION_RETURN:
      newState.documentAction = action.documentAction;
      return newState;

    case SAVE_DOCUMENT:
      newState.saveDocumentLoading = true;
      newState.saveDocumentMessage = '';
      newState.saveDocumentErrorMessage = '';
      newState.currentFormActionType = '';
      return newState;

    case SAVE_DOCUMENT_RETURN:
      newState.currentFormActionType = action.currentFormActionType;
      newState.saveDocumentLoading = false;
      newState.formFieldLoading = false;
      newState.saveDocumentMessage = message.createDocumentFormSuccess;
      return newState;

    case SAVE_DOCUMENT_ERROR:
      newState.saveDocumentLoading = false;
      newState.saveDocumentErrorMessage = action.saveDocumentErrorMessage;
      return newState;

    case CLEAR_MESSAGES:
      newState.saveDocumentMessage = '';
      newState.saveDocumentErrorMessage = '';
      newState.getFormErrorMessage = '';
      return newState;

    case CLEAR_SAVE_DOCUMENT_SUCCESS:
      newState.saveDocumentMessage = '';
      return newState;

    case CLEAR_SAVE_DOCUMENT_ERROR:
      newState.saveDocumentErrorMessage = '';
      return newState;

    case TRIGGER_FIELD_TO_UPDATE_RETURN:
      let tempField = [...newState.formField];
      const fieldReload = manageFieldReload(
        tempField,
        action.formField,
        action.autopopulateFieldReload
      );
      tempField = fieldReload.tempField;
      tempField = manageFxFieldReload(tempField, {
        triggeredOnChange: action.triggeredOnChange,
        fxFieldReload: action.fxFieldReload
      });
      newState.formField = tempField;
      localStorage.removeItem('nonTableFxReload');
      newState.valueFieldReload = action.valueFieldReload;
      if (fieldReload.autopopulateFieldReload && fieldReload.autopopulateFieldReload.length) {
        newState.autopopulateFieldReload = fieldReload.autopopulateFieldReload;
      } else {
        newState.fieldConfigChange = tempField;
      }
      return newState;

    case GET_COMMENTS_RETURN:
      newState.commentList = action.commentList;
      newState.hasSystemComment = action.hasSystemComment || false;
      newState.hasUserComment = action.hasUserComment || false;
      newState.hasAttachment = action.hasAttachment || false;
      newState.commentUnread = action.commentList.filter(e => e.type === 'comment' && e.read === false);
      return newState;

    case SAVE_COMMENT_RETURN:
      let createCommentList = [...newState.commentList];
      createCommentList.push(action.comment);
      newState.commentList = createCommentList;
      return newState;

    case UPDATE_COMMENT_RETURN:
      let updateCommentList = [...newState.commentList];
      const existingComment = newState.commentList.filter(
        (comment: IComment) => comment.id === action.comment.id
      );
      if (existingComment.length > 0) {
        const existingCommentIndex = newState.commentList.indexOf(
          existingComment[0]
        );
        if (existingCommentIndex > -1) {
          updateCommentList.splice(existingCommentIndex, 1, action.comment);
        }
      }
      newState.commentList = updateCommentList;
      newState.commentUnread = updateCommentList.filter(e => e.type === 'comment' && !e.read);
      newState.deleteAttachmentLoading = false;
      return newState;

    case DELETE_COMMENT_RETURN:
      let deleteCommentList = [...newState.commentList];
      const forDeletedComment = newState.commentList.filter(
        (comment: IComment) => comment.id === action.commentId
      );
      if (forDeletedComment.length > 0) {
        const forDeletedCommentIndex = newState.commentList.indexOf(
          forDeletedComment[0]
        );
        if (forDeletedCommentIndex > -1) {
          deleteCommentList.splice(forDeletedCommentIndex, 1);
        }
      }
      newState.commentList = deleteCommentList;
      return newState;

    case RESET_DOCUMENT_FORM:
      newState.formDetail = {} as IForm;
      newState.formField = [] as IField[];
      newState.documentDetail = undefined;
      newState.deleteDocumentLoading = false;
      newState.deleteTriggerCloseDocument = false;
      return newState;

    case GET_DOCUMENTS_DUPLICATE:
      newState.documentsDuplicateLoading = true;
      return newState;

    case GET_DOCUMENTS_DUPLICATE_RETURN:
      newState.documentsDuplicate = action.documentsDuplicate;
      newState.duplicateActionType = action.duplicateActionType;
      if (action.loading) {
        newState.individualUsersListLoading = true;
      } else {
        newState.saveDocumentLoading = false;
        newState.individualUsersListLoading = false;
      }
      return newState;

    case GET_COMMENT_SENDER_RETURN:
      newState.senderList = action.senderList;
      return newState;

    case GET_DOCUMENTS_FOLLOWERS_RETURN:
      newState.documentFollowers = action.documentFollowers;
      return newState;

    case UNFOLLOW_DOCUMENT_RETURN:
      newState.documentFollowers = [...newState.documentFollowers].filter(
        (follower: any) => follower.userInfo.id !== action.usersId
      );
      return newState;

    case GET_DOCUMENTS_ACTION_CONFIG_RETURN:
      newState.documentActionConfig = action.documentActionConfig;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST:
      newState.getIndividualUserFailed = undefined;
      newState.individualUsersListLoading = true;
      newState.individualUsersList = [];
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_RETURN:
      newState.individualUsersList = action.individualUsersList;
      newState.individualUsersListLoading = false;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_ERROR:
      newState.getIndividualUserFailed = true;
      newState.individualUsersListLoading = false;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_RESET:
      newState.getIndividualUserFailed = undefined;
      return newState;

    case SAVING_WITH_NO_CHANGES:
      newState.savingWihoutChangesMessage = message.savingWithNoChangesMessage;
      return newState;

    case RESET_SAVING_WITH_NO_CHANGES_MESSAGE:
      newState.savingWihoutChangesMessage = '';
      return newState;

    case RESET_CURRENT_ACTION_TYPE:
      newState.currentFormActionType = '';
      return newState;

    case DELETE_DOCUMENT:
      newState.deleteDocumentLoading = true;
      newState.deleteTriggerCloseDocument = false;
      return newState;

    case DELETE_DOCUMENT_RETURN:
      newState.deleteDocumentLoading = false;
      newState.deleteDocumentSuccessMessage = message.deleteDocumentFormSuccess;
      newState.deleteTriggerCloseDocument = true;
      return newState;

    case DELETE_DOCUMENT_ERROR:
      newState.deleteDocumentLoading = false;
      newState.deleteDocumentErrorMessage = message.deleteDocumentFormError;
      return newState;

    case RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE:
      newState.deleteDocumentSuccessMessage = '';
      return newState;

    case RESET_DELETE_DOCUMENT_ERROR_MESSAGE:
      newState.deleteDocumentErrorMessage = '';
      return newState;

    case DELETE_COMMENT_ATTACHMENT:
      newState.deleteAttachmentLoading = true;
      return newState;

    case GET_RELATED_FORMS:
      newState.relatedFormsLoading = true;
      return newState;

    case GET_RELATED_FORMS_RETURN:
      newState.relatedFormsLoading = false;
      newState.relatedForms = action.relatedForms;
      return newState;

    case GET_RELATED_DOCUMENTS:
      newState.relatedFormsLoading = true;
      return newState;

    case GET_RELATED_DOCUMENTS_RETURN:
      newState.relatedDocument = action.relatedDocument;
      newState.relatedFormsLoading = false;
      return newState;

    case GET_RELATED_DATATABLE:
      newState.relatedFormsLoading = true;
      return newState;

    case GET_RELATED_DATATABLE_RETURN:
      newState.relatedDatatable = action.relatedDatatable;
      newState.relatedFormsLoading = false;
      return newState;

    case RESET_RELATED_FORMS:
      newState.relatedForms = [];
      newState.relatedDatatable = [];
      newState.relatedDocument = [];
      return newState;

    /** Shortcut */
    case GET_FORM_SHORTCUT_RETURN:
      newState.formDetailShortcut = action.formDetail;
      return newState;

    case GET_FORM_FIELD_SHORTCUT:
      newState.saveDocumentMessageShortcut = '';
      newState.saveDocumentErrorMessageShortcut = '';
      newState.formFieldLoadingShortcut = true;
      newState.documentDetailShortcut = undefined;
      return newState;

    case GET_FORM_FIELD_RETURN_SHORTCUT:
      newState.formFieldLoadingShortcut = false;
      newState.formFieldShortcut = action.formField;
      newState.formStatusShortcut = action.formStatus;
      newState.documentDetailShortcut = action.documentDetail;
      return newState;

    case TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN:
      const tempShortcutField = newState.formFieldShortcut.map((field: IField) => {
        const updateField = action.formField.filter(
          (upField: IField) => upField.id === field.id
        );
        if (updateField.length > 0) {
          return updateField[0];
        }
        return field;
      });
      newState.formFieldShortcut = tempShortcutField;
      newState.valueFieldReloadShortcut = action.valueFieldReload;
      newState.fieldConfigChangeShortcut = action.formField;
      return newState;

    case GET_DOCUMENT_ACTION_SHORTCUT_RETURN:
      newState.documentActionShortcut = action.documentAction;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_SHORTCUT:
      newState.getIndividualUserFailedShortcut = undefined;
      newState.individualUsersListShortcut = [];
      newState.individualUsersListShortcutLoading = true;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN:
      newState.individualUsersListShortcut = action.individualUsersList;
      newState.individualUsersListShortcutLoading = false;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR:
      newState.getIndividualUserFailedShortcut = true;
      newState.individualUsersListShortcutLoading = false;
      return newState;

    case GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET:
      newState.getIndividualUserFailedShortcut = undefined;
      return newState;

    case SAVE_DOCUMENT_SHORTCUT:
      newState.saveDocumentShortcutLoading = true;
      newState.saveDocumentShortcutMessage = '';
      newState.saveDocumentShortcutErrorMessage = '';
      newState.currentFormActionTypeShortcut = '';
      newState.saveDocumentCloseDocumentShortcut = false;
      newState.documentsDuplicateShortcut = [];
      return newState;

    case SAVE_DOCUMENT_SHORTCUT_RETURN:
      newState.currentFormActionTypeShortcut = action.currentFormActionType;
      newState.saveDocumentShortcutLoading = false;
      newState.saveDocumentShortcutMessage = message.createDocumentFormSuccess;
      newState.saveDocumentCloseDocumentShortcut = true;
      return newState;

    case SAVE_DOCUMENT_SHORTCUT_ERROR:
      newState.saveDocumentShortcutLoading = false;
      newState.saveDocumentShortcutErrorMessage = action.saveDocumentErrorMessage;
      return newState;

    case RESET_DOCUMENT_FORM_SHORTCUT:
      newState.formDetailShortcut = {} as IForm;
      newState.formFieldShortcut = [] as IField[];
      newState.documentDetailShortcut = {} as IFormDocument;
      newState.saveDocumentCloseDocumentShortcut = false;
      newState.shortcutSelectedValues = [];
      return newState;

    case CLEAR_SAVE_DOCUMENT_ERROR_SHORTCUT:
    case CLEAR_SAVE_DOCUMENT_SUCCESS_SHORTCUT:
      newState.saveDocumentShortcutMessage = '';
      newState.saveDocumentShortcutErrorMessage = '';
      return newState;

    case GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN:
      newState.saveDocumentShortcutLoading = false;
      newState.documentsDuplicateShortcut = action.documentsDuplicate;
      newState.duplicateActionType = action.duplicateActionType;
      return newState;

    case RESET_REFERENCE_FORM_FIELD:
      newState.resetFormReferenceFieldLoading = true;
      return newState;

    case RESET_REFERENCE_FORM_FIELD_RETURN:
      newState.shortcutSelectedValues = action.formField;
      newState.resetFormReferenceFieldLoading = false;
      return newState;

    case UPDATE_COMMENT_UNREAD_COUNT:
      let newCommentList = [...newState.commentList];
      newCommentList.push(action.comment);
      newState.commentList = newCommentList;
      newState.commentUnread = newCommentList.filter(e => e.type === 'comment' && e.read === false);
      return newState;

    case GET_AUTOMATION_CONFIG:
      newState.automationConfig = undefined;
      return newState;

    case GET_AUTOMATION_CONFIG_RETURN:
      newState.automationConfig = action.automationConfig;
      return newState;

    case GET_AUTOMATION_CONFIG_SHORTCUT:
      newState.automationConfigShortcut = undefined;
      return newState;

    case GET_AUTOMATION_CONFIG_SHORTCUT_RETURN:
      newState.automationConfigShortcut = action.automationConfig;
      return newState;

    case UPDATE_DOCUMENT_ACTION:
      const tempDocumentAction = [...state.documentAction];
      action.documentAction.forEach((e: IDocumentAction) => {
        const index = tempDocumentAction.indexOf(e);
        tempDocumentAction.splice(index, 1, e);
      })
      newState.documentAction = tempDocumentAction;
      return newState;

    case UPDATE_DOCUMENT_ACTION_SHORTCUT:
      const tempDocumentActionShortcut = [...state.documentActionShortcut];
      action.documentAction.forEach((e: IDocumentAction) => {
        const index = tempDocumentActionShortcut.indexOf(e);
        tempDocumentActionShortcut.splice(index, 1, e);
      })
      newState.documentActionShortcut = tempDocumentActionShortcut;
      return newState;

    case RESET_FORM_FIELDS_AUTOPOPULATE:
      let tempFieldList = [...newState.formField];
      tempFieldList = tempFieldList.map((field: IField) => {
        if (field && field.inputConfig && field.inputConfig.type === 'table') {
          field.inputConfig.config.value = undefined;
        }
        return field;
      });
      newState.formField = tempFieldList;
      return newState;

    case REFRESH_FORM:
      if (action.formField) {
        newState.formField = manageFieldReload(state.formField, action.formField).tempField;
      }
      newState.refreshForm = uuid();
      return newState;

    case REFRESH_FORM_SHORTCUT:
      newState.refreshFormShortcut = uuid();
      return newState;

    case CLEAR_COMMENT_LIST:
      newState.commentList = [];
      newState.senderList = [];
      return newState;

    case GET_FORM_ERROR:
      newState.getFormErrorMessage = action.getFormErrorMessage;
      newState.formFieldLoading = false;
      return newState;

    default:
      return newState;
  }


};

export default documentFormReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react-bpm';
import PortalBPRedirect from './redirect';
import { IRootState } from '../../../../reducers';
import { IHasPageChangeRef } from '../../../main/interface/has-page-change';
import { bpPortalSubModules } from './constant';
import PortalBPModule from './module';
import { PortalBpSubMenuButton } from './sub-menu';

interface IMatch {
  path: string;
  params: {
    submodule: string;
    thirdLevelRoute?: string;
  }
}

interface IPortalBPModuleProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class PortalBPRoute {

  name = 'business-processes';

  render: React.FC<IPortalBPModuleProps> = (props) => {
    const match: IMatch = useRouteMatch();
    const history = useHistory();
    const data = useSelector((state: IRootState) => state.main);

    return (<>
      <Route exact path={`${match.path}`}>
        <PortalBPRedirect />
      </Route>
      <Route path={`${match.path}/:thirdLevelRoute`}>
        <Container className='portal-module-container' fluid>
          <PortalBpSubMenuButton.Render
            type={data.subRoute}
            modules={bpPortalSubModules}
            history={history}
            main={data}
            hasPageChangeRef={props.hasPageChangeRef}
          />
          <PortalBPModule hasPageChangeRef={props.hasPageChangeRef} />
        </Container>
      </Route>
    </>
    )
  }
}

export default PortalBPRoute;
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { currentRouteAction, setSubRouteAction } from '../../../main/action';

const PortalBPRedirect: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSubRouteAction({
        mainRoute: 'PORTAL',
        subRoute: 'business-processes'
      })
    );
    dispatch(currentRouteAction('business-processes'));
    history.push(`/portal/business-processes/stakeholders-view`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  );
}

export default PortalBPRedirect;
class RangeOperatorOption {

  name = 'range';

  option = [
    { key: 0, value: 'is-empty', text: 'Is Empty' },
    { key: 1, value: 'is-not-empty', text: 'Is Not Empty' },
    { key: 2, value: 'less', text: 'Is Before' },
    { key: 3, value: 'less-or-equal', text: 'Is Before or Equal' },
    { key: 4, value: 'more', text: 'Is After' },
    { key: 5, value: 'more-or-equal', text: 'Is After or Equal' },
    { key: 6, value: 'equal', text: 'Equal' },
    { key: 7, value: 'not-equal', text: 'Not Equal' },
    { key: 8, value: 'is-included', text: 'Is Included' },
    { key: 9, value: 'is-excluded', text: 'Is Excluded' },
    { key: 10, value: 'includes', text: 'Includes' },
    { key: 11, value: 'excludes', text: 'Excludes' },
    { key: 12, value: 'intersect', text: 'Intersect' },
  ]
}

export default RangeOperatorOption;
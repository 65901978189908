import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IComputeField } from '..';
import { IAutomationComponent } from '../../../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
}

export const useSetSelectedComputeFields = (props: IProps) => {
  const { selectedComponent } = { ...props };

  const [selectedComputeFields, setSelectedComputeFields] = useState([] as IComputeField[]);

  useEffect(() => {
    const tempComputeFields = [] as IComputeField[];
    if (selectedComponent) {
      const tempConfig = selectedComponent.config;
      if (tempConfig && tempConfig.fields && tempConfig.fields.length > 0) {
        selectedComponent.config.fields.forEach((config: any) => {
          tempComputeFields.push({
            id: uuid(),
            selectedField: config.fieldId,
            selectedOperator: config.operator,
            value: config.value
          });
        });
      }
    }
    setSelectedComputeFields(tempComputeFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return { ...props, selectedComputeFields, setSelectedComputeFields };
}
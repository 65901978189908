import { useEffect } from 'react';
import { getPublishedFormAction } from '../../../business-process/module/form-builder/action';
import { getUserCustomFieldAction } from '../../../business-process/module/roles/action';
import {
  getDataTableDetailsAction,
  getDataTableFieldsAction,
  checkHasDatatableRecordAction,
  getDatatableDetailPermissionOptionAction,
  getRecordsGroupedConfigAction,
  getRecordsGroupedKeypathAction
} from '../../action';
import { FieldTableEntityType } from '../../interface/data-table-config';

interface IProps {
  companyId: string;
  dataTableId: string;
  dispatch: any;
}

export const useGetDatatableFormData = (props: IProps): void => {
  const { dataTableId, companyId, dispatch } = { ...props };

  useEffect(() => {
    if (companyId) {
      if (dataTableId !== 'add-data-table') {
        dispatch(getDataTableDetailsAction({ company: companyId, dataTableId: dataTableId }))
        dispatch(getDataTableFieldsAction({ company: companyId, dataTableId: dataTableId }));
        dispatch(checkHasDatatableRecordAction({ company: companyId, dataTableId: dataTableId }));
        dispatch(getRecordsGroupedConfigAction({
          company: companyId,
          entityConfigId: dataTableId,
          entityType: FieldTableEntityType.Dt
        }));
        dispatch(getRecordsGroupedKeypathAction({
          company: companyId,
          entityConfigId: dataTableId,
          entityType: FieldTableEntityType.Dt
        }));
      }
      dispatch(getDatatableDetailPermissionOptionAction({ company: companyId }));
      dispatch(getPublishedFormAction({ company: companyId }));
      dispatch(getUserCustomFieldAction({ company: companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableId, companyId, dispatch]);
}
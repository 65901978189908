import React from 'react';
import SectionContainer from './section-container';
import ButtonContainer from '../../../button-container';
import { IFormBuilderSection } from '../interface/section';
import { DragFieldObject, DragSectionObject } from '../section-field-manager/interface/section-update-config';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IFieldContainerSection {
  sectionList: IFormBuilderSection[];
  changeSectionSequence(dragItem: DragSectionObject, dropContainerId: string): void;
  addFieldToSection(dragItem: DragFieldObject, sectionId: string): void;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  openSettings(selectedSection: IFormBuilderSection): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  cancelFormBuilder(): void;
  saveFormBuilder(): void;
  publishFormBuilder(): void;
  addFieldOnPlainSection(dragItem: DragFieldObject): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const FieldContainerSection: React.FC<IFieldContainerSection> = (props) => {
  return <div className="form-builder-field-container">
    <SectionContainer
      changeSectionSequence={props.changeSectionSequence}
      sectionList={props.sectionList}
      addFieldToSection={props.addFieldToSection}
      addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
      openSettings={props.openSettings}
      editFieldConfig={props.editFieldConfig}
      openDeleteConfirmationModal={props.openDeleteConfirmationModal}
      unlinkField={props.unlinkField}
      unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
      sortFieldSection={props.sortFieldSection}
      moveField={props.moveField}
      addFieldOnPlainSection={props.addFieldOnPlainSection}
      bpImage={props.bpImage}
    />
    <ButtonContainer
      save={props.saveFormBuilder}
      publish={props.publishFormBuilder}
      cancel={props.cancelFormBuilder}
    />
  </div>
}

export default FieldContainerSection;

import { AggregateFunction } from '../fx.agg-func';

export class MinAggregateFunction implements AggregateFunction {

  name = 'min';

  aggregate(values: number[]): number {
    return Math.min.apply(null, values);
  }

}
import { GET_PAGE_LAYOUT, GET_PAGE_LAYOUT_RETURN } from '../constant';
import { IGetPageLayoutInputDetail } from '../interface/input/page-layout-get';
import { IGetPageLayoutResponse } from '../interface/response/page-layout-get';

export const getPortalPageLayoutAction = (payload: IGetPageLayoutInputDetail) => {
  return {
    type: GET_PAGE_LAYOUT, ...payload
  }
}

export const getPortalPageLayoutReturnAction = (payload: IGetPageLayoutResponse) => {
  return {
    type: GET_PAGE_LAYOUT_RETURN, ...payload
  }
}
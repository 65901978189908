import React from 'react';
import { List } from 'semantic-ui-react-bpm';
import ToolTip from '../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import PortalUserStatus from './components/status';

interface IListDetailProps {

}

const PortalUserTableDetail: React.FC<IListDetailProps> = (props) => {
  return <tr>
    <td>1</td>
    <td>Regina</td>
    <td>Lorem</td>
    <td>reginaalorem@mail.com</td>
    <td>12345678</td>
    <td>Lorem Ipsum</td>
    <td>
      <List className="tr-alignment">
        <List.Item>{'End User'}</List.Item>
      </List>
    </td>
    <td className='status'>
      <PortalUserStatus userStatus={true} />
    </td>
    <td>
      <ToolTip position='left center'
        popupMessage={'Edit'}
        popupTriggerComponent={
          <div className={`action-icon`}
            onClick={() => []} >
            <SVGWrapper
              iconName='edit'
              width='14'
              height='14'
              color='#8A9296'
            />
          </div>
        }
      />
      <ToolTip position='right center'
        popupMessage={'Delete'}
        popupTriggerComponent={
          <div className='action-icon' onClick={() => { }}>
            <SVGWrapper
              iconName='trash'
              width='12'
              color='#8A9296'
            />
          </div>
        }
      />
    </td>
  </tr>
}

export default PortalUserTableDetail;
import Axios from 'axios';
import React, { useRef, useState } from 'react';
import MonacoEditor from 'react-monaco-editor';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import { isValidHttpUrl } from '../../../../../../../../../common/utils/validate-url';
import { IRootState } from '../../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import { IFormStatus } from '../../../../workflow/interface/form-status';
import { updateLocalApiListAction } from '../action';
import { authTypeOptionList, externalApiPlugin, methodOptionList, triggerEventOptionList } from '../constant';
import { IApiConfiguration } from '../interface/api-configuration';
import { useSetApiFormStateAndRefs } from './hooks/use-set-api-form-state';

interface IAPIsFormProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const errorIndicator = {
  name: false,
  url: false,
  authUrl: false,
  authMethod: false,
  authType: false,
  authTokenPath: false,
  triggerStatusId: false,
  triggerEvent: false,
  method: false,
  successStatusId: false,
}

const APIsForm: React.FC<IAPIsFormProps> = (props) => {
  const match: any = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { statusList, apiConfigurationList } = useSelector((state: IRootState) => state.configurationAPIs);
  const { company } = useSelector((state: IRootState) => state.auth);
  const statusOption = statusList.map((e: IFormStatus, index: number) => { return { key: index, value: e.id, text: e.name } });

  const nameRef = useRef(document.createElement('input'));
  const urlRef = useRef(document.createElement('input'));
  const descriptionRef = useRef(document.createElement('input'));
  const exampleUrnRef1 = useRef(document.createElement('input'));
  const exampleUrnRef2 = useRef(document.createElement('input'));
  const authUrlRef = useRef(document.createElement('input'));
  const authTokenPathRef = useRef(document.createElement('input'));
  const authBodyRef = useRef(document.createElement('textarea'));

  const [error, setError] = useState(errorIndicator);
  const [exampleText1, setExampleText1] = useState('');
  const [exampleText2, setExampleText2] = useState('');
  const [isApiAuthorizeCollapse, setApiAuthorizeCollapse] = useState(true);
  const [isApiCollapse, setApiCollapse] = useState(true);

  const {
    method,
    setMethod,
    triggerEvent,
    setTriggerEvent,
    statusAfterSubmit,
    setStatusAfterSubmit,
    triggerStatus,
    setTriggerStatus,
    bodyTemplate,
    setBodyTemplate,
    docFieldTemplate,
    setDocFieldTemplate,
    activeTab,
    setActiveTab,
    authMethod,
    setAuthMethod,
    authType,
    setAuthType,
    hasAuthorization,
    setHasAuthorization,
    cancelClick
  } = useSetApiFormStateAndRefs({
    apiConfigurationList,
    apiId: match.params.apiId,
    authBodyRef,
    authTokenPathRef,
    authUrlRef,
    descriptionRef,
    history,
    match,
    nameRef,
    urlRef
  });

  const manageData = (tempApiList: IApiConfiguration[]) => {
    let authorization = undefined;
    if (hasAuthorization) {
      authorization = {
        url: authUrlRef.current.value,
        method: authMethod,
        body: authBodyRef.current.value,
        type: authType,
        tokenPath: authTokenPathRef.current.value
      }
    }
    if (match.params.apiId !== 'add-record') {
      var reg = new RegExp('^[0-9]$');
      const appId = reg.test(match.params.apiId) ? parseFloat(match.params.apiId) : undefined;
      const currentData = tempApiList.find((e: IApiConfiguration) => e.seqNumber === appId);
      if (currentData) {
        const dataindex = tempApiList.indexOf(currentData)
        const data: IApiConfiguration = {
          ...currentData,
          seqNumber: match.params.apiId,
          method: method,
          url: urlRef.current.value,
          requestTemplate: bodyTemplate,
          triggerStatusId: triggerStatus || undefined,
          responseTemplate: docFieldTemplate,
          triggerEvent: triggerEvent,
          successStatusId: statusAfterSubmit || undefined,
          name: nameRef.current.value,
          description: descriptionRef.current.value,
          authorization: authorization
        }
        tempApiList.splice(dataindex, 1, data);
      }
    } else {
      tempApiList.push({
        id: uuid(),
        seqNumber: apiConfigurationList.length + 1,
        method: method,
        url: urlRef.current.value,
        requestTemplate: bodyTemplate,
        triggerStatusId: triggerStatus || undefined,
        responseTemplate: docFieldTemplate,
        triggerEvent: triggerEvent,
        successStatusId: statusAfterSubmit || undefined,
        name: nameRef.current.value,
        description: descriptionRef.current.value,
        isActive: false,
        authorization: authorization
      });
    }
    tempApiList = tempApiList.map((e: IApiConfiguration, index: number) => {
      const f = { ...e };
      f.seqNumber = index + 1;
      return f;
    })
    return tempApiList;
  }

  const saveClick = () => {
    let tempApiList = [...apiConfigurationList.map((e: IApiConfiguration) => { const f = { ...e }; return f; })];

    if (!validateField()) {
      return;
    }

    dispatch(updateLocalApiListAction(manageData(tempApiList)));
    cancelClick();
    props.hasPageChangeRef.current.hasChange = true;
  }

  const validateField = () => {
    const tempError = { ...error };
    let status = true;

    tempError.name = false;
    if (!nameRef.current.value) {
      tempError.name = true;
      status = false;
    }
    tempError.url = false;
    if (!urlRef.current.value || !isValidHttpUrl(urlRef.current.value)) {
      tempError.url = true;
      status = false;
    }
    tempError.method = false;
    if (!method) {
      tempError.method = true;
      status = false;
    }
    tempError.triggerStatusId = false;
    if (!triggerStatus && triggerEvent.indexOf('status-changed') > -1) {
      tempError.triggerStatusId = true;
      status = false;
    }
    tempError.successStatusId = false;
    if (!statusAfterSubmit && triggerEvent.indexOf('status-changed') > -1) {
      tempError.successStatusId = true;
      status = false;
    }
    tempError.triggerEvent = false;

    tempError.authUrl = false;
    tempError.authMethod = false;
    tempError.authType = false;
    tempError.authTokenPath = false;
    if (hasAuthorization) {
      if (!authUrlRef.current.value || !isValidHttpUrl(authUrlRef.current.value)) {
        tempError.authUrl = true;
        status = false;
      }
      if (!authMethod) {
        tempError.authMethod = true;
        status = false;
      }
      if (!authType) {
        tempError.authType = true;
        status = false;
      }
      if (!authTokenPathRef.current.value) {
        tempError.authTokenPath = true;
        status = false;
      }
    }

    setError(tempError)
    return status;
  }

  const manageMethod = (selected: string) => {
    setMethod(selected);
    setActiveTab(selected !== 'post' ? 'receive' : 'send')
    if (selected === 'get') {
      setBodyTemplate('');
    }
    props.hasPageChangeRef.current.hasChange = true;
  }

  const manageAuthMethod = (selected: string) => {
    setAuthMethod(selected);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const manageAuthType = (selected: string) => {
    setAuthType(selected);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const manageActiveTab = (type: string) => {
    setActiveTab(type);
  }

  const tryRenderConfig = async (template: string) => {
    let tempApiList = [...apiConfigurationList.map((e: IApiConfiguration) => { const f = { ...e }; return f; })];
    const formId = match.params.formId;
    const processExample = template === 'request' ? setExampleText1 : setExampleText2;
    try {
      tempApiList = manageData(tempApiList);
      const result = await Axios.post(`${company}/forms/${formId}/plugins/${externalApiPlugin}/try`, {
        config: {
          apis: tempApiList
        },
        documentUrn: template === 'request' ? exampleUrnRef1.current.value : exampleUrnRef2.current.value,
        input: {
          "seqNumber": match.params.apiId === 'add-record' ? tempApiList.length.toString() : match.params.apiId,
          "template": template
        }
      });

      processExample(result.data.data);
    } catch (e) {
      processExample(e?.response?.data?.message || 'Unknown Error');
    }
  }

  const manageHasAuthorization = () => {
    setHasAuthorization(!hasAuthorization);
    setApiAuthorizeCollapse(true);
    if (!hasAuthorization) {
      setAuthMethod('');
      setAuthType('');
      authUrlRef.current.value = ''
      authTokenPathRef.current.value = '';
      authBodyRef.current.value = ''
    }
    props.hasPageChangeRef.current.hasChange = true;
  }

  return <>
    <div id='apis-form-modal-container'>
      <div className='switch-field-container'>
        <span className='switch-label'>Api Requires Authorization</span>
        <div className="ui fitted toggle checkbox"
          onClick={() => manageHasAuthorization()}>
          <input className="hidden" type="checkbox"
            onChange={() => { }}
            checked={hasAuthorization}
          />
          <label></label>
        </div>
      </div>
      {hasAuthorization &&
        <div className={`api-authorization-container`}>
          <div className={`header`}
            onClick={() => {
              setApiAuthorizeCollapse(!isApiAuthorizeCollapse)
            }}
          ><i className={`angle ${isApiAuthorizeCollapse ? 'up' : 'down'} icon`}></i><label>API Authorization</label></div>
          <Form size='large' className={`form-container ${isApiAuthorizeCollapse ? '' : 'hidden'}`} >
            <Form.Group widths='equal'>
              <div className={`required six wide field ${error.authUrl ? 'error' : ''}`}>
                <label>Url</label>
                <div className={`ui input`}>
                  <input ref={(curRef) => {
                    if (curRef) {
                      curRef.value = authUrlRef.current.value || '';
                      return authUrlRef.current = curRef;
                    }
                  }} type="text" />
                </div>
              </div>
              <Form.Select
                popperContainer={DropdownPopperOption}
                clearable
                className={`${error.authMethod ? 'error' : ''}`}
                label='Method'
                required
                search
                selectOnBlur={false}
                value={authMethod}
                onChange={(e, target: any) => manageAuthMethod(target.value)}
                options={methodOptionList} width='6' selection />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Select
                popperContainer={DropdownPopperOption}
                clearable
                className={`${error.authType ? 'error' : ''}`}
                label='Type'
                required
                search
                selectOnBlur={false}
                value={authType}
                onChange={(e, target: any) => manageAuthType(target.value)}
                options={authTypeOptionList} width='6' selection />
              <div className={`required six wide field ${error.authTokenPath ? 'error' : ''}`}>
                <label>Token Path</label>
                <div className={`ui input `}>
                  <input ref={(curRef) => {
                    if (curRef) {
                      curRef.value = authTokenPathRef.current.value || '';
                      return authTokenPathRef.current = curRef;
                    }
                  }} type="text" />
                </div>
              </div>
            </Form.Group>
            <Form.Group widths='equal'>
              <div className={` 12 wide field`}>
                <label>Body</label>
                <div className={`ui input`}>
                  <textarea ref={(curRef) => {
                    if (curRef) {
                      curRef.value = authBodyRef.current.value || '';
                      return authBodyRef.current = curRef;
                    }
                  }} ></textarea>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      }
      <div className={`api-container`}>
        <div className={`header`}
          onClick={() => {
            setApiCollapse(!isApiCollapse)
          }}
        ><i className={`angle ${isApiCollapse ? 'up' : 'down'} icon`}></i><label>API</label></div>
        <Form size='large' className={`form-container ${isApiCollapse ? '' : 'hidden'}`} >
          <Form.Group widths='equal'>
            <div className={`required six wide field ${error.name ? 'error' : ''}`}>
              <label>Api Name</label>
              <div className={`ui input`}>
                <input ref={nameRef} type="text" />
              </div>
            </div>
            <div className={`required six wide field ${error.url ? 'error' : ''}`}>
              <label>Url</label>
              <div className={`ui input `}>
                <input ref={urlRef} type="text" />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal'>
            <div className={`six wide field`}>
              <label>Description</label>
              <div className={`ui input`}>
                <input ref={descriptionRef} type="text" />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal' >
            <Form.Select
              popperContainer={DropdownPopperOption}
              clearable
              className={`${error.method ? 'error' : ''}`}
              label='Method'
              required
              search
              selectOnBlur={false}
              value={method}
              onChange={(e, target: any) => manageMethod(target.value)}
              options={methodOptionList} width='6' selection />
            <Form.Select
              popperContainer={DropdownPopperOption}
              clearable
              className={`${error.triggerEvent ? 'error' : ''}`}
              label='Trigger Event'
              search
              multiple
              selectOnBlur={false}
              value={triggerEvent}
              onChange={(e, target: any) => { setTriggerEvent(target.value); props.hasPageChangeRef.current.hasChange = true; }}
              options={triggerEventOptionList} width='6' selection />
          </Form.Group>
          {triggerEvent.indexOf('status-changed') > -1 &&
            <Form.Group widths='equal' >
              <Form.Select
                popperContainer={DropdownPopperOption}
                clearable
                className={`${error.triggerStatusId ? 'error' : ''}`}
                label='Trigger on Status'
                required
                search
                selectOnBlur={false}
                value={triggerStatus}
                onChange={(e, target: any) => {
                  setTriggerStatus(target.value || undefined);
                  if (!statusAfterSubmit) {
                    setStatusAfterSubmit(target.value);
                  }
                  props.hasPageChangeRef.current.hasChange = true;
                }}
                options={statusOption} width='6' selection />
              <Form.Select
                popperContainer={DropdownPopperOption}
                clearable
                className={`${error.triggerStatusId ? 'error' : ''}`}
                label='Status after submitting'
                search
                required
                selectOnBlur={false}
                value={statusAfterSubmit}
                onChange={(e, target: any) => {
                  setStatusAfterSubmit(target.value);
                  props.hasPageChangeRef.current.hasChange = true;
                }}
                options={statusOption} width='6' selection />
            </Form.Group>
          }
          <div><div className="ui attached tabular menu">
            {method === 'post' &&
              <span onClick={() => manageActiveTab('send')} className={`${activeTab === 'send' ? 'active' : ''} item`} >Request</span>
            }
            <span onClick={() => manageActiveTab('receive')} className={`${activeTab === 'receive' ? 'active' : ''} item`}>Response</span>
          </div>
            {method === 'post' &&
              <div className={`ui bottom attached segment tab ${activeTab === 'send' ? 'active' : ''}`}>
                <Form.Group widths='equal' style={{ marginTop: '40px' }} >
                  <div className="required six wide field">
                    <div id='sqs-plugin-editor-container1'
                      style={{ width: '100%', height: '200px' }}
                    >
                      <MonacoEditor
                        language="html"
                        theme="vs"
                        value={bodyTemplate}
                        onChange={(newCode: any) => {
                          setBodyTemplate(newCode)
                          props.hasPageChangeRef.current.hasChange = true;
                        }}
                        editorDidMount={(editor: any, monaco: any) => { }}
                      />
                    </div>
                  </div>

                  <div className="required six wide field">
                    <div className={``}>
                      Try how template request a document. Set example document URN in field and press "Try"
              <div>
                        <input placeholder="URN" ref={exampleUrnRef1}></input>
                        <Button onClick={() => tryRenderConfig('request')}>Try</Button>
                      </div>
                      <div id='sqs-plugin-template-try' >
                        <pre>{exampleText1}</pre>
                      </div>

                    </div>
                  </div>
                </Form.Group>
              </div>
            }
            <div className={`ui bottom attached segment tab ${activeTab === 'receive' ? 'active' : ''}`}>
              <Form.Group widths='equal' style={{ marginTop: '40px' }} >

                <div className="required six wide field">
                  <div id='sqs-plugin-editor-container2'
                    style={{ width: '100%', height: '200px' }}
                  >
                    <MonacoEditor
                      language="html"
                      theme="vs"
                      value={docFieldTemplate}
                      onChange={(newCode: any) => {
                        setDocFieldTemplate(newCode);
                        props.hasPageChangeRef.current.hasChange = true;
                      }}
                      editorDidMount={(editor: any, monaco: any) => { }}
                    />
                  </div>
                </div>

                <div className="required six wide field">
                  <div className={``}>
                    Try how template response a document. Set example document URN in field and press "Try"
              <div>
                      <input placeholder="URN" ref={exampleUrnRef2}></input>
                      <Button onClick={() => tryRenderConfig('response')}>Try</Button>
                    </div>
                    <div id='sqs-plugin-template-try' >
                      <pre>{exampleText2}</pre>
                    </div>

                  </div>
                </div>
              </Form.Group>
            </div>

          </div>
        </Form>
      </div >
    </div >
    <div className={`action-button`}>
      <Button color='green' className='btn-action btn-success' onClick={saveClick}>{match.params.apiId !== 'add-record' ? 'Update' : 'Create'}</Button>
      <Button color='grey' className='btn-action btn-default' onClick={() => cancelClick()}>{'Cancel'}</Button>
    </div>
  </>
}

export default APIsForm;
import { IRelatedDocumentsDetailsInput, IRelatedFormsDetailsInput } from './interface/input/related-form';
import {
  GET_FORM,
  GET_FORM_RETURN,
  GET_FORM_FIELD_RETURN,
  GET_FORM_FIELD,
  GET_DOCUMENT_ACTION,
  GET_DOCUMENT_ACTION_RETURN,
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_RETURN,
  SAVE_DOCUMENT_ERROR,
  CLEAR_MESSAGES,
  TRIGGER_FIELD_TO_UPDATE,
  TRIGGER_FIELD_TO_UPDATE_RETURN,
  GET_COMMENTS,
  GET_COMMENTS_RETURN,
  SAVE_COMMENT,
  SAVE_COMMENT_RETURN,
  UPDATE_COMMENT_RETURN,
  DELETE_COMMENT,
  DELETE_COMMENT_RETURN,
  RESET_DOCUMENT_FORM,
  GET_DOCUMENTS_DUPLICATE,
  GET_DOCUMENTS_DUPLICATE_RETURN,
  GET_COMMENT_SENDER,
  GET_COMMENT_SENDER_RETURN,
  GET_DOCUMENTS_ACTION_CONFIG_RETURN,
  GET_DOCUMENTS_ACTION_CONFIG,
  GET_INDIVIDUAL_USERS_LIST,
  GET_INDIVIDUAL_USERS_LIST_RETURN,
  GET_DOCUMENTS_FOLLOWERS,
  FOLLOW_DOCUMENT,
  UNFOLLOW_DOCUMENT,
  GET_DOCUMENTS_FOLLOWERS_RETURN,
  UNFOLLOW_DOCUMENT_RETURN,
  SAVING_WITH_NO_CHANGES,
  RESET_CURRENT_ACTION_TYPE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_RETURN,
  DELETE_DOCUMENT_ERROR,
  DELETE_COMMENT_ATTACHMENT,
  GET_RELATED_FORMS_RETURN,
  GET_RELATED_FORMS,
  GET_RELATED_DOCUMENTS_RETURN,
  GET_RELATED_DOCUMENTS,
  RESET_RELATED_FORMS,
  GET_RELATED_DATATABLE,
  GET_RELATED_DATATABLE_RETURN,
  GET_FORM_FIELD_RETURN_SHORTCUT,
  GET_FORM_FIELD_SHORTCUT,
  GET_FORM_SHORTCUT,
  GET_FORM_SHORTCUT_RETURN,
  TRIGGER_FIELD_TO_UPDATE_SHORTCUT,
  TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN,
  GET_DOCUMENT_ACTION_SHORTCUT,
  GET_DOCUMENT_ACTION_SHORTCUT_RETURN,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN,
  SAVE_DOCUMENT_SHORTCUT,
  SAVE_DOCUMENT_SHORTCUT_RETURN,
  SAVE_DOCUMENT_SHORTCUT_ERROR,
  RESET_DOCUMENT_FORM_SHORTCUT,
  GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN,
  RESET_REFERENCE_FORM_FIELD,
  RESET_REFERENCE_FORM_FIELD_RETURN,
  UPDATE_COMMENT_UNREAD_COUNT,
  MARK_COMMENT,
  MARK_COMMENT_RETURN,
  GET_AUTOMATION_CONFIG,
  GET_AUTOMATION_CONFIG_RETURN,
  UPDATE_DOCUMENT_ACTION,
  GET_AUTOMATION_CONFIG_SHORTCUT_RETURN,
  GET_AUTOMATION_CONFIG_SHORTCUT,
  UPDATE_DOCUMENT_ACTION_SHORTCUT,
  RESET_FORM_FIELDS_AUTOPOPULATE,
  REFRESH_FORM,
  REFRESH_FORM_SHORTCUT,
  GET_INDIVIDUAL_USERS_LIST_ERROR,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR,
  GET_INDIVIDUAL_USERS_LIST_RESET,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET,
  CLEAR_COMMENT_LIST,
  GET_FORM_ERROR
} from './constant';
import { IFormGetDetailInput } from './interface/input/form-get';
import { IFormGetResponse } from './interface/response/form-get';
import { IFormFieldGetResponse } from './interface/response/form-field-get';
import { IFormFieldGetDetailInput } from './interface/input/form-field-get';
import { IDocumentActionGetDetailInput } from './interface/input/document-action-get';
import { IDocumentActionGetResponse } from './interface/response/document-action-get';
import { IDocumentSaveDetailInput } from './interface/input/document-save';
import { IReloadFieldDetailInput } from './interface/input/field-trigger-reload';
import { IReloadFieldResponse } from './interface/response/field-trigger-reload';
import {
  IGetCommentDetailInput,
  IGetSenderListDetailInput,
} from './interface/input/comment-get';
import {
  IGetCommentResponse,
  IGetCommentSenderResponse,
} from './interface/response/comment-get';
import { ICreateCommentDetailInput } from './interface/input/comment-create';
import { ICreateCommentResponse } from './interface/response/comment-create';
import { IDeleteCommentDetailInput } from './interface/input/comment-delete';
import { IDeleteCommentResponse } from './interface/response/comment-delete';
import { IDocsDuplicateGetDetailInput } from './interface/input/filter-documents';
import { IDocsDuplicateGetResponse } from './interface/response/filter-documents';
import { IGetDocumentFollowersDetailInput } from './interface/input/follower-get';
import { IFollowDocumentDetailInput } from './interface/input/follow-document';
import { IGetDocumentFollowersResponse } from './interface/response/follower-get';
import { IDocumentActionConfigResponse } from './interface/response/document-action-config';
import { IDocumentActionConfigInput } from './interface/input/document-action-config';
import { IDocumentDeleteDetailInput } from './interface/input/document-delete';
import { IDocumentDeleteResponse } from './interface/response/document-delete';
import { IAttachmentDeleteDetailInput } from './interface/input/attachment-delete';
import { IRelatedFormsResponse, IRelatedDocumentsResponse } from './interface/response/related-form';
import { IGetIndividualUserDetailInput } from './interface/input/individual-user-get';
import { IMarkCommentDetailInput } from './interface/input/comment-mark';
import { IGetAutomationConfigDetailInput } from './interface/input/automation-config-get';
import { IGetAutomationConfigResponse } from './interface/response/automation-config-get';
import { IDocumentAction } from './interface/document-action';
import { IField } from '../../../admin-module/module/users/interface/field';

export const getFormAction = (payload: IFormGetDetailInput) => {
  return { type: GET_FORM, ...payload };
};

export const getFormErrorAction = (message: string) => {
  return { type: GET_FORM_ERROR, getFormErrorMessage: message }
}

export const getFormReturnAction = (payload: IFormGetResponse) => {
  return { type: GET_FORM_RETURN, ...payload };
};

export const getFormFieldAction = (payload: IFormFieldGetDetailInput) => {
  return { type: GET_FORM_FIELD, ...payload };
};

export const getFormFieldReturnAction = (payload: IFormFieldGetResponse) => {
  return { type: GET_FORM_FIELD_RETURN, ...payload };
};

export const getDocumentButtonAction = (
  payload: IDocumentActionGetDetailInput
) => {
  return { type: GET_DOCUMENT_ACTION, ...payload };
};

export const getDocumentButtonReturnAction = (
  payload: IDocumentActionGetResponse
) => {
  return { type: GET_DOCUMENT_ACTION_RETURN, ...payload };
};

export const saveDocumentAction = (payload: IDocumentSaveDetailInput) => {
  return { type: SAVE_DOCUMENT, ...payload };
};

export const saveDocumentReturnAction = (actionType: string) => {
  return { type: SAVE_DOCUMENT_RETURN, currentFormActionType: actionType };
};

export const saveDocumentErrorAction = (message: string) => {
  return { type: SAVE_DOCUMENT_ERROR, saveDocumentErrorMessage: message };
};

export const clearMessages = () => {
  return { type: CLEAR_MESSAGES };
};

export const fieldTriggerUpdateAction = (data: IReloadFieldDetailInput) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE,
    ...data,
  };
};

export const fieldTriggerUpdateResponseAction = (
  data: IReloadFieldResponse
) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE_RETURN,
    ...data,
  };
};

export const fieldTriggerUpdateShortcutAction = (data: IReloadFieldDetailInput) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE_SHORTCUT,
    ...data,
  };
};

export const fieldTriggerUpdateShortcutResponseAction = (
  data: IReloadFieldResponse
) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE_SHORTCUT_RETURN,
    ...data,
  };
};

export const getCommentListAction = (data: IGetCommentDetailInput) => {
  return {
    type: GET_COMMENTS,
    ...data,
  };
};

export const getCommentListReturnAction = (data: IGetCommentResponse) => {
  return {
    type: GET_COMMENTS_RETURN,
    ...data,
  };
};

export const saveCommentAction = (data: ICreateCommentDetailInput) => {
  return {
    type: SAVE_COMMENT,
    ...data,
  };
};

export const saveCommentReturnAction = (data: ICreateCommentResponse) => {
  return {
    type: SAVE_COMMENT_RETURN,
    ...data,
  };
};

export const updateCommentReturnAction = (data: ICreateCommentResponse) => {
  return {
    type: UPDATE_COMMENT_RETURN,
    ...data,
  };
};

export const deleteCommentAction = (data: IDeleteCommentDetailInput) => {
  return {
    type: DELETE_COMMENT,
    ...data,
  };
};

export const deleteCommentReturnAction = (data: IDeleteCommentResponse) => {
  return {
    type: DELETE_COMMENT_RETURN,
    ...data,
  };
};

export const resetDocumentFormAction = () => {
  return { type: RESET_DOCUMENT_FORM };
};

export const resetDocumentFormShortcutAction = () => {
  return { type: RESET_DOCUMENT_FORM_SHORTCUT };
};

export const getDocumentsDuplicateAction = (
  payload: IDocsDuplicateGetDetailInput
) => {
  return {
    type: GET_DOCUMENTS_DUPLICATE,
    ...payload,
  };
};

export const getDocumentsDuplicateReturnAction = (
  payload: IDocsDuplicateGetResponse
) => {
  return {
    type: GET_DOCUMENTS_DUPLICATE_RETURN,
    ...payload,
  };
};

export const getDocumentsDuplicateShortcutReturnAction = (
  payload: IDocsDuplicateGetResponse
) => {
  return {
    type: GET_DOCUMENTS_DUPLICATE_SHORTCUT_RETURN,
    ...payload,
  };
};

export const getCommentSenderAction = (payload: IGetSenderListDetailInput) => {
  return {
    type: GET_COMMENT_SENDER,
    ...payload,
  };
};

export const getCommentSenderReturnAction = (
  payload: IGetCommentSenderResponse
) => {
  return {
    type: GET_COMMENT_SENDER_RETURN,
    ...payload,
  };
};

export const getDocumentFollowersAction = (
  payload: IGetDocumentFollowersDetailInput
) => {
  return {
    type: GET_DOCUMENTS_FOLLOWERS,
    ...payload,
  };
};
export const getDocumentActionConfigAction = (
  payload: IDocumentActionConfigInput
) => {
  return {
    type: GET_DOCUMENTS_ACTION_CONFIG,
    ...payload,
  };
};

export const getDocumentFollowersReturnAction = (
  payload: IGetDocumentFollowersResponse
) => {
  return {
    type: GET_DOCUMENTS_FOLLOWERS_RETURN,
    ...payload,
  };
};

export const getDocumentActionConfigReturnAction = (
  payload: IDocumentActionConfigResponse
) => {
  return {
    type: GET_DOCUMENTS_ACTION_CONFIG_RETURN,
    ...payload,
  };
};

export const followDocumentAction = (payload: IFollowDocumentDetailInput) => {
  return {
    type: FOLLOW_DOCUMENT,
    ...payload,
  };
};

export const getIndividualUsersListAction = (payload: IGetIndividualUserDetailInput) => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST,
    ...payload,
  };
};

export const unfollowDocumentAction = (payload: IFollowDocumentDetailInput) => {
  return {
    type: UNFOLLOW_DOCUMENT,
    ...payload,
  };
};

export const unfollowDocumentReturnAction = (payload: any) => {
  return {
    type: UNFOLLOW_DOCUMENT_RETURN,
    ...payload,
  };
};

export const getIndividualUsersListReturnAction = (payload: any) => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_RETURN,
    ...payload,
  };
};

export const getIndividualUserListErrorAction = () => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_ERROR
  }
}

export const getIndividualUserListResetAction = () => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_RESET
  }
}

export const saveWithoutChangeOnNewDocumentAction = () => {
  return { type: SAVING_WITH_NO_CHANGES };
};

export const resetCurrentActionTypeAction = () => {
  return { type: RESET_CURRENT_ACTION_TYPE };
};

export const deleteDocumentAction = (payload: IDocumentDeleteDetailInput) => {
  return { type: DELETE_DOCUMENT, ...payload };
};

export const deleteDocumentReturnAction = (
  payload: IDocumentDeleteResponse
) => {
  return { type: DELETE_DOCUMENT_RETURN, ...payload };
};

export const deleteDocumentErrorAction = () => {
  return { type: DELETE_DOCUMENT_ERROR };
};

export const deleteCommentAttachmentAction = (
  payload: IAttachmentDeleteDetailInput
) => {
  return {
    type: DELETE_COMMENT_ATTACHMENT,
    ...payload,
  };
};

export const getRelatedFormsAction = (payload: IRelatedFormsDetailsInput) => {
  return {
    type: GET_RELATED_FORMS,
    ...payload
  }
}

export const getRelatedFormsReturnAction = (payload: IRelatedFormsResponse) => {
  return {
    type: GET_RELATED_FORMS_RETURN,
    ...payload
  }

}
export const getRelatedDocumentsAction = (payload: IRelatedDocumentsDetailsInput) => {
  return {
    type: GET_RELATED_DOCUMENTS,
    ...payload
  }
}

export const getRelatedDocumentsReturnAction = (payload: IRelatedDocumentsResponse) => {
  return {
    type: GET_RELATED_DOCUMENTS_RETURN,
    ...payload
  }
}
export const getRelatedDatatableAction = (payload: IRelatedDocumentsDetailsInput) => {
  return {
    type: GET_RELATED_DATATABLE,
    ...payload
  }
}

export const getRelatedDatatableReturnAction = (payload: IRelatedDocumentsResponse) => {
  return {
    type: GET_RELATED_DATATABLE_RETURN,
    ...payload
  }
}
export const clearRelatedForms = () => {
  return {
    type: RESET_RELATED_FORMS
  }
}

export const getFormFieldShortcutAction = (payload: IFormFieldGetDetailInput) => {
  return { type: GET_FORM_FIELD_SHORTCUT, ...payload };
};

export const getFormFieldShortcutReturnAction = (payload: IFormFieldGetResponse) => {
  return { type: GET_FORM_FIELD_RETURN_SHORTCUT, ...payload };
};

export const getFormShortcutAction = (payload: IFormGetDetailInput) => {
  return { type: GET_FORM_SHORTCUT, ...payload };
};

export const getFormShortcutReturnAction = (payload: IFormGetResponse) => {
  return { type: GET_FORM_SHORTCUT_RETURN, ...payload };
};

export const getDocumentButtonShortcutAction = (
  payload: IDocumentActionGetDetailInput
) => {
  return { type: GET_DOCUMENT_ACTION_SHORTCUT, ...payload };
};

export const getDocumentButtonShortcutReturnAction = (
  payload: IDocumentActionGetResponse
) => {
  return { type: GET_DOCUMENT_ACTION_SHORTCUT_RETURN, ...payload };
};

export const getIndividualUsersListShortcutAction = (payload: IGetIndividualUserDetailInput) => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_SHORTCUT,
    ...payload,
  };
};

export const getIndividualUsersListShortcutReturnAction = (payload: any) => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RETURN,
    ...payload,
  };
};

export const getIndividualUserListShortcutErrorAction = () => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_SHORTCUT_ERROR
  }
}

export const getIndividualUserListShortcutResetAction = () => {
  return {
    type: GET_INDIVIDUAL_USERS_LIST_SHORTCUT_RESET
  }
}

export const saveDocumentShortcutAction = (payload: IDocumentSaveDetailInput) => {
  return { type: SAVE_DOCUMENT_SHORTCUT, ...payload };
};

export const saveDocumentShortcutReturnAction = (actionType: string) => {
  return { type: SAVE_DOCUMENT_SHORTCUT_RETURN, currentFormActionType: actionType };
};

export const saveDocumentShortcutErrorAction = (message: string) => {
  return { type: SAVE_DOCUMENT_SHORTCUT_ERROR, saveDocumentErrorMessage: message };
};

export const resetReferenceFormFieldAction = (payload: IReloadFieldDetailInput) => {
  return { type: RESET_REFERENCE_FORM_FIELD, ...payload };
};

export const resetReferenceFormFieldReturnAction = (
  data: IReloadFieldResponse
) => {
  return {
    type: RESET_REFERENCE_FORM_FIELD_RETURN,
    ...data,
  };
};

export const addCommentUnreadCountAction = (payload: { comment: any }) => {
  return {
    type: UPDATE_COMMENT_UNREAD_COUNT,
    ...payload
  }
}

export const markCommentAction = (payload: IMarkCommentDetailInput) => {
  return {
    type: MARK_COMMENT,
    ...payload
  }
}

export const markCommentReturnAction = (payload: ICreateCommentResponse) => {
  return {
    type: MARK_COMMENT_RETURN,
    ...payload
  }
}

export const getClientAutomationConfigAction = (payload: IGetAutomationConfigDetailInput) => {
  return {
    type: GET_AUTOMATION_CONFIG,
    ...payload
  }
}

export const getClientAutomationConfigReturnAction = (payload: IGetAutomationConfigResponse) => {
  return {
    type: GET_AUTOMATION_CONFIG_RETURN,
    ...payload
  }
}

export const getClientAutomationConfigShortcutAction = (payload: IGetAutomationConfigDetailInput) => {
  return {
    type: GET_AUTOMATION_CONFIG_SHORTCUT,
    ...payload
  }
}

export const getClientAutomationConfigShortcutReturnAction = (payload: IGetAutomationConfigResponse) => {
  return {
    type: GET_AUTOMATION_CONFIG_SHORTCUT_RETURN,
    ...payload
  }
}

export const updateDocumentButtonAction = (payload: { documentAction: IDocumentAction[] }) => {
  return {
    type: UPDATE_DOCUMENT_ACTION,
    ...payload
  }
}

export const updateDocumentButtonShorcutAction = (payload: { documentAction: IDocumentAction[] }) => {
  return {
    type: UPDATE_DOCUMENT_ACTION_SHORTCUT,
    ...payload
  }
}

export const resetFormFieldsAutoPopulateAction = () => {
  return {
    type: RESET_FORM_FIELDS_AUTOPOPULATE
  }
}

export const refreshFormAction = (formField?: IField[]) => {
  return {
    type: REFRESH_FORM,
    formField
  }
}

export const refreshFormShortCutAction = () => {
  return {
    type: REFRESH_FORM_SHORTCUT
  }
}

export const clearCommentListAction = () => {
  return {
    type: CLEAR_COMMENT_LIST
  }
}

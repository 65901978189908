import React from 'react';
import TimeAgo from '../../../../../../../common/general/time-ago';
import { IComment } from '../../../interface/comment';
import { ISenderDetail } from '../../../interface/sender-info';

interface ICommentSenderInfo {
  comment: IComment;
}

const CommentWidgetSenderInfo: React.FC<ICommentSenderInfo> = ({ comment }) => {
  const senderUserInfo = { ...(comment.sender && comment.sender.data) } as ISenderDetail;

  const firstName = senderUserInfo?.firstName ? senderUserInfo.firstName : '';
  const lastName = senderUserInfo?.lastName ? senderUserInfo.lastName : '';

  let userName = `${firstName} ${lastName}`;

  if (comment.type === 'history') {
    userName = 'Mobbiz BPM'
  }

  return <div>
    <span className={`user-name`}>{userName}</span>
    <TimeAgo className={`message-date`} date={comment.createdAt} />
  </div>
};

export default CommentWidgetSenderInfo;

class UploadOperatorOption {

  name = 'file';

  option = [
    { key: 0, value: 'is-empty', text: 'Is Empty' },
    { key: 1, value: 'is-not-empty', text: 'Is Not Empty' },
  ]

}

export default UploadOperatorOption;
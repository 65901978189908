import { FieldValidator } from './field-validator';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
export class GeolocationValidator extends FieldValidator {

  name = 'geolocation';

  validate(field: FieldElement, value: any, actionType?: string) {
    if ((value.hasOwnProperty('lat') && value.hasOwnProperty('lon')
      && !value.lat && value.lat !== 0
      && !value.lon && value.lon !== 0)
      &&
      ((field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required || actionType === 'save'))
        || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden)) {
      return [];
    }

    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: value })
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}


import React, { useState, useEffect } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { removeInput } from '../../../utils/remove-input';
import { v4 as uuid } from 'uuid';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../icons';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { StringProps } from '../../../service/type/string';
import { TValidationType } from '../../../service/field-properties.handler';

const EmailArrayType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher] = useState('');
  const [validateTriggered, setValidateTriggered] = useState(false);
  const [multilineCount, setMultilineCount] = useState(1);
  let onTypeTimeout = setTimeout(() => { }, 1000);
  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new StringProps());

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    for (let row: number = 0; row < multilineCount; row++) {
      elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur)
    }

    setRefresher(uuid());
    setValidateTriggered(true)
    if (props.triggerFilter) props.triggerFilter('');

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ value: defaultValueArray[row] || '' }, row);
      }
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      setRefresher(uuid());
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `email-${name}-${row}`;
      field.push(
        <React.Fragment key={id}>
          {row > 0 && <span className='multiline-gap' />}
          <div className='multi-line-container'>
            <div className={`field-input ui labeled input multi-line ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            }, row)}`} >
              <div className={`${props.hasIconInside ? 'hidden' : ''} ui label`}>@</div>
              <input
                id={id}
                readOnly={props.accessType === IFieldAccessType.Readonly}
                type="text"
                onBlur={(event) => {
                  if (focusedElement && focusedElement === id) {
                    removeFocusedElement();
                  }
                  elementProperties.setCurrent({ value: event.target.value }, row);
                  validateInput();
                  if (props.throwValueOutside) {
                    props.throwValueOutside()
                  }
                  if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
                }}
                ref={(curRef: any) => {
                  if (curRef) {
                    if (focusedElement) {
                      if (id === focusedElement) {
                        curRef.focus();
                      }
                    }
                    curRef.fieldType = 'string';
                    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), fieldType: 'string' }, row);
                    curRef.value = elementProperties.getCurrent(row).value;
                    return curRef;
                  }
                }}
                onClick={() => { focusInput(id) }}
                onChange={(event) => {
                  elementProperties.setCurrent({ value: event.target.value }, row);
                  if (props.hasPageChangeRef) {
                    props.hasPageChangeRef.current.hasChange = true;
                  }
                }}
                name={name}
              />
              {elementProperties.hasError(row) && props.showErrorMessage &&
                elementProperties.getError(row)[0].message.indexOf('Please provide value for') === -1 &&
                <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError(row)[0].message }} />
              }
              {props.hasIconInside &&
                <div className='flex-container with-icon'>
                  <Image width='15px' src={icons.black.mail} />
                </div>
              }
            </div>
            <span>
              {(row > 0 || (row === 0 && row !== (count - 1))) && <i className="minus circular icon multiline-minus-icon" onClick={() => removeInput({
                forwardedRef: props.forwardedRef,
                name: name,
                multilineCount: multilineCount,
                setMultilineCount: setMultilineCount
              }, row)} />}

              {row === (count - 1) && props.multiline && <i className="plus circular icon multiline-icon"
                onClick={() => addNewInput()} />}
            </span>
          </div>
        </React.Fragment >
      )
    }
    return field;
  }

  const addNewInput = () => {
    const newLen = multilineCount + 1;
    setMultilineCount(newLen);
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      elementProperties.setCurrent({ value: defaultValue[0] || '' }, multilineCount);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (mobile) setFocusedElement(`email-${name}-${multilineCount}`);
  }

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount])

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(uuid());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.multiline, multilineCount, props.isAddRecord
  ])

  useEffect(() => {
    if (props.multiline) {
      const len = elementProperties.current.length;
      setMultilineCount(len === 0 ? 1 : len);
    }
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span id={refresher} className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label
            ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            })}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }

  </>
}

export default EmailArrayType;
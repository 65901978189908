import React from 'react';
import Section from './section';
import StatusRefer from '../../refer';
import { IFormPermissionSection, ISection, ITablePermission } from '../../../interface/section';
import PermissionSectionPlain from './section/plain';
import { IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IStatusSectionContainer {
  selectedStatus: IFormPermissionSection;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  handleSectionPermissionChange(forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any): void;
  formPermissionSection: IFormPermissionSection[];
  selectedStatusIndex: number;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  handleReferToStatus(forUpdateSection: IFormPermissionSection, statusId: string): void;
  showStatusOption: boolean;
  openPermissionFilterConfig: boolean;
  openSectionPermissionSettings(section: ISection): void;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const StatusSectionContainer: React.FC<IStatusSectionContainer> = (props) => {
  const containerStyle = {
    borderRight: `${props.openPermissionFilterConfig ? '0.12em solid #3B86A8' : 'none'}`
  };

  return (
    <div ref={props.wrapperRef} className='status-section-container' style={containerStyle}>
      <StatusRefer
        formPermissionSection={props.formPermissionSection}
        selectedStatusIndex={props.selectedStatusIndex}
        selectedStatus={props.selectedStatus}
        showStatusOption={props.showStatusOption}
        handleReferToStatus={props.handleReferToStatus}
      />
      {props.selectedStatus && props.selectedStatus.sections &&
        props.selectedStatus.sections.map((section: ISection, index: number) => {

          if (section.style === 'plain') {
            return <PermissionSectionPlain
              key={`status-${props.selectedStatus.statusId}-plain-section-${index}`}
              section={section}
              handlePermissionChange={props.handlePermissionChange}
              openFieldPermissionSettings={props.openFieldPermissionSettings}
              tablePermission={props.selectedStatus.table}
            />
          }

          return <Section
            key={`status-${props.selectedStatus.statusId}-section-${index}`}
            section={section}
            handlePermissionChange={props.handlePermissionChange}
            handleSectionPermissionChange={props.handleSectionPermissionChange}
            openFieldPermissionSettings={props.openFieldPermissionSettings}
            openSectionPermissionSettings={props.openSectionPermissionSettings}
            tablePermission={props.selectedStatus.table}
          />
        })}
    </div>
  )
}

export default StatusSectionContainer;
import React from 'react';
import { Icon } from 'semantic-ui-react-bpm';
import { IField } from '../../../../../users/interface/field';

interface IMobileSectionTitle {
  collapse: boolean;
  setCollapse(value: boolean, fieldId: string): void;
  field: IField;
  showConfig: boolean;
  setShowConfig(value: boolean): void;
}

const MobileSectionTitle: React.FC<IMobileSectionTitle> = (props) => {

  return <div className={`title`}>
    <div className='ui fitted toggle checkbox checked checkbox-toggle' onClick={() => props.setShowConfig(!props.showConfig)}>
      <input checked={props.showConfig} type="checkbox" onChange={() => { }} />
      <label></label>
    </div>
    <span className='name'>{props.field.label || 'Untitled'}</span>
    <Icon onClick={() => props.setCollapse(!props.collapse, props.field.id as string)} link className='collapse-icon' name={props.collapse ? 'chevron down' : 'chevron up'} size='small' />
  </div>
}

export default MobileSectionTitle;

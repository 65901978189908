/*
 *
 * test reducer
 *
 */

// import { fromJS } from 'immutable'
import {
  SAMPLE_ACTION,
  SAMPLE_ACTION_UPDATE
} from './constant'

export interface ITestComponentReducer {
  test: Object;
  loading: boolean;
}

const initialState: ITestComponentReducer = {
  test: {},
  loading: false
};

const testComponentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SAMPLE_ACTION:
      // console.log('Trigger get test data');
      let testActionState = { ...state };
      testActionState.loading = true;
      return testActionState;
    case SAMPLE_ACTION_UPDATE:
      // console.log('pass here update test', action.payload);
      let testActionUpdateState = { ...state };
      testActionUpdateState.test = action.payload;
      testActionUpdateState.loading = false;
      return testActionUpdateState;
    default:
      return state;
  }
}

export default testComponentReducer

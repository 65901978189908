import React, { useEffect, useState } from 'react';
import { conditionWidget } from '../../../constant';
import { IOptionWidget } from '../../../interface/option-widget';
import { IAutomationDetailRender } from '../component-registry';
import AutomationDetailContent from '../widget/detail-content-widget';
import AutomationDetailHeader from '../widget/detail-header-widget';
import { ConditionTypeRegistry } from './conditionType/registry';

class AutomationDetailNewCondition {

  name = 'condition';

  render: React.FC<IAutomationDetailRender> = (props) => {
    const [selectedCondition, setSelectedCondition] = useState<IOptionWidget | undefined>(undefined);

    const getAllWidgetList = () => {
      let widget = conditionWidget.filter((e: IOptionWidget) => !e.recommended);
      if (props.updateAutomationOnparent) {
        widget = conditionWidget.filter((e: IOptionWidget) => !e.recommended && e.name !== 'if-else-block')
      }
      return widget;
    }

    const selectCondition = (e: IOptionWidget) => {
      setSelectedCondition(e);
      const tempSelectedComponent = { ...props.selectedComponent };
      const tempConfig = { ...tempSelectedComponent.config };
      tempConfig.condition = e;
      tempSelectedComponent.config = tempConfig;
      if (props.updateAutomationOnparent) {
        props.updateAutomationOnparent(tempSelectedComponent, props.childIndex);
      } else {
        props.updateCurrentAutomation(tempSelectedComponent, false);
      }
      props.setSelectedComponent(
        tempSelectedComponent,
        props.selectedChildComponent ? { ...props.selectedChildComponent, component: tempSelectedComponent } : undefined
        , props.isHasParent
      );
    }

    useEffect(() => {
      const tempSelected = props.selectedComponent.config && props.selectedComponent.config.condition;
      setSelectedCondition(tempSelected);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedComponent]);

    const noSelectedAction = () => {
      return <div className='automation-form-detail-new-condition'>
        <AutomationDetailHeader
          title={`New Condition`}
          description={`Action will only execute if all conditions preceding them pass.`}
          iconHeight={30}
          iconWidth={30}
          iconName='fieldIssue'
        />

        <AutomationDetailContent
          recommended={conditionWidget.filter((e: IOptionWidget) => e.recommended === true)}
          allComponent={getAllWidgetList()}
          selected={selectedCondition}
          setSelected={selectCondition}
          contentScreen='Conditions'
        />
      </div>
    }

    const withSelectedAction = (condition: IOptionWidget) => {
      return <ConditionTypeRegistry.Render
        type={condition.name}
        currentAutomationRef={props.currentAutomationRef}
        selectedComponent={props.selectedComponent}
        updateCurrentAutomation={props.updateCurrentAutomation}
        updateAutomationOnparent={props.updateAutomationOnparent}
        childIndex={props.childIndex}
        selectedChildComponent={props.selectedChildComponent}
        refsObject={props.refsObject}
        setSelectedComponent={props.setSelectedComponent}
        hasComponentChange={props.hasComponentChange}
        parentRefresher={props.parentRefresher}
        isHasParent={props.isHasParent}
      />
    }

    return (<>
      {!selectedCondition
        ? noSelectedAction()
        : withSelectedAction(selectedCondition)
      }
    </>
    );
  }
}

export default AutomationDetailNewCondition;
import { Editor } from '@tinymce/tinymce-react';
import React, { createRef, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../../../../../common/api-request/upload';
import ConfigService from '../../../../../../common/config';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import { icons } from '../../../../../../common/icons';
import { useRect } from '../../../../../../common/utils/useRect';
import { IRootState } from '../../../../../../reducers';
import { getFilters } from '../../../../../main/partials/header/hexToCssFilter';
import InputAttachment from './attachment';
import { useSetChatContainerHeight } from './hooks/use-set-chat-container-height';
import { useSetEditorHeightWhenUploadedFile } from './hooks/use-set-editor-height-when-uploaded-file';
import { defineEditorHeight } from './utils/define-editor-height';
import { fetchUserMentionList } from './utils/fetch-user-mention-list';

interface IInputbox {
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  openUpload?(value: boolean): void;
  removeUploadedFile(file: UploadedFile): void;
  commentId?: string;
  message?: string;
  setSelectedId?(value: string): void;
  uploadedFileList: UploadedFile[];
  removeFileAfterSave(): void;
  documentId: string;
  mobile: boolean;
  actionType: 'create' | 'edit';
  selectedId?: string;
  module: string;
}

const inputRef = createRef<HTMLDivElement>();

const InputBox: React.FC<IInputbox> = (props) => {
  const { width, height } = useDeviceDetect();

  const { comment } = useSelector((rootState: IRootState) => rootState.main.companyColor);
  const { company } = useSelector((state: IRootState) => state.auth);

  const textRef: any = useRef(null);
  const inputRect = useRect(inputRef);

  let inputBoxHeight = 170;

  if (props.mobile) {
    if (props.actionType === 'create') {
      inputBoxHeight = 100;
    } else {
      inputBoxHeight = 200;
    }
  }

  const setAttachmentElement = () => {
    const element = document.getElementById('attachments');
    if (element) {
      element.style.removeProperty('display');
      ReactDOM.render(<InputAttachment
        uploadedFileList={props.uploadedFileList}
        removeUploadedFile={props.removeUploadedFile}
      />, element)
    }
  }

  const saveComment = (comment: string) => {
    props.saveComment(comment, props.commentId, textRef.current.attachment);
    if (props.setSelectedId) props.setSelectedId('');
    props.removeFileAfterSave();
  }

  const setEditorHeight = (editor: any) => {
    const contentRect: DOMRect = editor.selection.getNode().getBoundingClientRect();
    const lineCount = Math.round(contentRect.height / 19);
    defineEditorHeight({
      actionType: props.actionType,
      hasUploadedFile: textRef.current.attachment.length > 0,
      lineCount,
      contentHeight: contentRect.height,
      mobile: props.mobile,
    })
  }

  useSetEditorHeightWhenUploadedFile({
    actionType: props.actionType,
    mobile: props.mobile,
    removeUploadedFile: props.removeUploadedFile,
    textRef,
    uploadedFileList: props.uploadedFileList,
    setAttachmentElement
  });

  useSetChatContainerHeight({
    actionType: props.actionType,
    height,
    inputRect,
    width,
    selectedId: props.selectedId
  });

  return <>
    <div className={`input-box`} ref={inputRef}>
      {props.selectedId && <Image
        className='img-cancel'
        src={icons.other.circleCancel}
        width='20px'
        title='cancel'
        onClick={() => { if (props.setSelectedId) props.setSelectedId('') }}
      />}
      <Editor
        apiKey={ConfigService.loadConfig().general.tinymceKey}
        ref={(curRef: any) => {
          if (curRef) {
            curRef.attachment = [...props.uploadedFileList];
            return textRef.current = curRef;
          }
        }}
        init={{
          height: inputBoxHeight,
          menubar: false,
          statusbar: false,
          placeholder: 'Message...',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount emoticons'
          ],
          toolbar1: "bold italic strikethrough code link numlist bullist forecolor cutomSendButton customUploadButton emoticons ",
          content_style: '.mymention{ color: gray; }' +
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          extended_valid_elements: 'span[comment-mention-user-id|class|style]',
          mobile: {
            toolbar_mode: 'floating',
            toolbar1: 'formatText forecolor listText link emoticons customUploadButton cutomSendButton '
          },
          setup: (editor: any) => {
            editor.on('execCommand', (e: any) => {
              if (e.command === 'mceInsertContent') {
                setEditorHeight(editor);
              }
            })
            editor.on('init', () => {
              const parentEl = document.querySelector('.tox-editor-header');
              const editorFrame = document.getElementById(editor.id + '_ifr');
              const div = document.createElement('div');
              div.style.setProperty('display', 'none');
              div.id = 'attachments';
              if (!editorFrame || !parentEl) return;
              editorFrame.setAttribute('scrolling', 'no');
              parentEl.insertBefore(div, parentEl.childNodes[1]);
              editor.editorContainer.id = 'chat-editor';
              editor.editorContainer.style.setProperty('height', 'max-content', 'important')
              editor.contentAreaContainer.id = 'chat-content-area';
              if (props.message) {
                editor.execCommand('mceInsertContent', false, props.message);
                editor.contentAreaContainer.style.setProperty('height', `${inputBoxHeight}px`, 'important');
                setEditorHeight(editor);
              }
              editor.contentDocument.body.focus();
            });
            editor.on('keyup', (e: any) => {
              setEditorHeight(editor);
            });
            editor.on('keydown', (e: any) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                const node = editor.selection.getNode();
                const isAutoCompleter = parseInt(node.getAttribute('data-mce-autocompleter')) === 1;
                if (isAutoCompleter) return e.preventDefault();
                saveComment(editor.getContent());
                setTimeout(() => {
                  const element = document.getElementById('attachments');
                  if (element) element.style.setProperty('display', 'none', 'important');
                  editor.setContent('');
                  if (props.actionType === 'create') {
                    setEditorHeight(editor);
                  }
                }, 1000)
                e.preventDefault();
              }
            });
            editor.ui.registry.addGroupToolbarButton('listText', {
              icon: 'unordered-list',
              items: 'numlist bullist'
            });
            editor.ui.registry.addGroupToolbarButton('formatText', {
              icon: 'change-case',
              items: 'bold italic underline strikethrough code'
            });
            editor.ui.registry.addButton('customUploadButton', {
              text: `<img style='width: 18px; margin-top: 5px;' src='${icons.other.attachment}' />`,
              onAction: (e: any) => {
                if (props.openUpload) props.openUpload(true);
              }
            });
            editor.ui.registry.addButton('cutomSendButton', {
              text: `<img style='width: 18px; margin-top: 5px; filter:${getFilters(comment.sendIcon).filter}' src='${icons.other.chatSubmit}' />`,
              onAction: () => {
                saveComment(editor.getContent());
                const element = document.getElementById('attachments');
                if (element) element.style.setProperty('display', 'none', 'important');
                editor.setContent('');
                if (props.actionType === 'create') {
                  setEditorHeight(editor);
                }
              }
            })
            editor.ui.registry.addAutocompleter('autocompleter-mention', {
              ch: '@',
              minChars: 0,
              columns: 1,
              fetch: function (query: string) {
                return fetchUserMentionList(query, {
                  company,
                  documentId: props.documentId
                });
              },
              onAction: function (autocompleteApi: any, rng: any, value: any) {
                const data = value.split('|');
                const id = data[0];
                const name = data[1];

                var span = editor.getDoc().createElement('span');
                span.className = 'mymention';
                span.setAttribute('comment-mention-user-id', id);
                span.style.setProperty('padding', '0px 0.3em 2px 0.23em');
                span.style.setProperty('line-height', '1.714');
                span.style.setProperty('word-break', 'break-word');
                span.style.setProperty('background', 'rgba(0, 0, 0, 0.08)');
                span.style.setProperty('border-radius', '20px');
                span.appendChild(editor.getDoc().createTextNode('@' + name));

                editor.selection.setRng(rng);
                editor.insertContent(span.outerHTML + '&#8203;&nbsp;');
                autocompleteApi.hide();
              }
            })
          }
        }}
      />
    </div>
  </>;
}

export default InputBox;
import { IFormPermissionSection, ISection, IStatusAction, ITablePermission } from '../../../interface/section';
import { IRulesPermission } from '../../../interface/rules';
import { IConsolidateTableConfig, IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../interface/field-permission';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IUtils {
  selectedStatus: IFormPermissionSection;
  selectedFieldSection: ISection;
  selectedTableSection?: ITablePermission | undefined;
  isTablePermission: boolean;
  isSectionPermission: boolean;
  fieldData: ICustomFieldDetail;
}

export class StructureSettingsConfig {
  setConfig = ({
    selectedStatus,
    selectedFieldSection,
    selectedTableSection,
    isTablePermission,
    isSectionPermission,
    fieldData
  }: IUtils) => {
    let tempOnFalseConfig = {} as IRulesPermission;
    let tempOnTrueConfig = {} as IRulesPermission;
    let join: 'and' | 'or' = 'and';

    if (Object.keys(selectedStatus).length > 0) {
      const { sections, table } = { ...selectedStatus };
      if (isTablePermission) {
        const tablePermissionConf = this.setTableConfig(
          table || [],
          selectedTableSection || {} as ITablePermission,
          fieldData
        );
        if (Object.keys(tablePermissionConf).length > 0) {
          tempOnTrueConfig = tablePermissionConf.trueConfig;
          tempOnFalseConfig = tablePermissionConf.falseConfig;
          join = tablePermissionConf.join;
        }
      } else {
        sections.forEach((sectionList: ISection) => {
          const { permissions } = { ...sectionList };
          if (selectedFieldSection.id === sectionList.id) {
            if (isSectionPermission && typeof sectionList.accessLevelPerStatus === 'object') {
              const sectionAccessLevel = this.setSectionAccessLevel(sectionList.accessLevelPerStatus);
              tempOnTrueConfig = sectionAccessLevel.trueConfig;
              tempOnFalseConfig = sectionAccessLevel.falseConfig;
              join = sectionAccessLevel.join;
            } else {
              const permissionConfig = this.setPermissionConfig(permissions, fieldData);
              if (Object.keys(permissionConfig).length > 0) {
                tempOnTrueConfig = permissionConfig.trueConfig;
                tempOnFalseConfig = permissionConfig.falseConfig;
                join = permissionConfig.join;
              }
            }
          }
        });
      }
    }
    return { join, tempOnFalseConfig, tempOnTrueConfig };
  }

  setPermissionConfig = (permissions: IStatusFieldPermission[], fieldData: ICustomFieldDetail) => {
    let falseConfig = {} as IRulesPermission;
    let trueConfig = {} as IRulesPermission;
    let join: 'and' | 'or' = 'and';
    permissions.length > 0 && permissions.forEach((permission: IStatusFieldPermission) => {
      if (fieldData && permission.fieldId === fieldData.id) {
        const { config } = { ...permission };
        if (config && Object.keys(config).length) {
          const { joinCondition, conditionRule } = { ...config };
          join = joinCondition;
          if (conditionRule) {
            trueConfig = conditionRule.onTrue;
            falseConfig = conditionRule.onFalse;
          }
        }
      }
    });
    return { join, falseConfig, trueConfig };
  }

  setSectionAccessLevel = (accessLevelSection: string | IPermissionFieldFilterConfig) => {
    let falseConfig = {} as IRulesPermission;
    let trueConfig = {} as IRulesPermission;
    let join: 'and' | 'or' = 'and';
    if (typeof accessLevelSection === 'object') {
      const { joinCondition, conditionRule } = accessLevelSection;
      join = joinCondition;
      if (conditionRule) {
        trueConfig = conditionRule.onTrue;
        falseConfig = conditionRule.onFalse;
      }
    }
    return { join, falseConfig, trueConfig };
  }

  setTableConfig = (
    tablePermission: ITablePermission[],
    selectedTableSection: ITablePermission,
    fieldData: ICustomFieldDetail) => {
    let falseConfig = {} as IRulesPermission;
    let trueConfig = {} as IRulesPermission;
    let join: 'and' | 'or' = 'and';
    tablePermission.forEach(table => {
      if (table && selectedTableSection && table.id === selectedTableSection.id) {
        const { permissions } = { ...table };
        const permissionConfig = this.setPermissionConfig(permissions, fieldData);
        if (Object.keys(permissionConfig).length > 0) {
          trueConfig = permissionConfig.trueConfig;
          falseConfig = permissionConfig.falseConfig;
          join = permissionConfig.join;
        }
      }
    });
    return { join, falseConfig, trueConfig };
  }

  setActionConfig = (selectedStatus: IFormPermissionSection, selectedStatusAction: IStatusAction) => {
    let falseConfig = {} as IRulesPermission;
    let trueConfig = {} as IRulesPermission;
    let join: 'and' | 'or' = 'and';
    let disabledMessage: string | undefined = undefined;

    if (Object.keys(selectedStatus).length > 0) {
      const { actions } = { ...selectedStatus };
      actions.forEach((actionList: IStatusAction) => {
        if (selectedStatusAction && selectedStatusAction.id === actionList.id) {
          const { permissions: { config } } = { ...actionList };
          if (config && Object.keys(config).length) {
            const { joinCondition, conditionRule } = { ...config };
            join = joinCondition;
            if (conditionRule) {
              trueConfig = conditionRule.onTrue;
              falseConfig = conditionRule.onFalse;
              disabledMessage = conditionRule.disabledMessage;
            }
          }
        }
      });
    }
    return { join, falseConfig, trueConfig, disabledMessage };
  }

  setTableConsolidateTableConfig = (selectedStatus: IFormPermissionSection, fieldData: ICustomFieldDetail) => {
    let tempConsolidateConfig: IConsolidateTableConfig = {
      referenceKeyPath: '',
      sourceTableId: '',
      columns: []
    } as IConsolidateTableConfig;
    if (selectedStatus && selectedStatus.table) {
      const tablePermission = selectedStatus.table.filter(e => e.id === fieldData.id);
      if (tablePermission.length > 0 && tablePermission[0] && tablePermission[0].consolidateTable) {
        tempConsolidateConfig = tablePermission[0].consolidateTable;
      }
    }
    return { ...tempConsolidateConfig };
  }
}
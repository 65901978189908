import React from 'react';
import { Table } from 'semantic-ui-react-bpm';
import { useSelector } from 'react-redux';
import TableHeaderAction from './table-header-action';
import TableHeaderTitle from './table-header-title';
import TableList from './table-list';
import { IRootState } from '../../../../../reducers';
import { IUser } from '../interface/user';
import { IField } from '../interface/field';
import { IFilterData } from '../user';
import { IOption } from '../../../../../common/interface/option';

interface IUserProps {
  user: IUser[];
  staticField: IField[];
  dynamicField: IField[];
  openDeleteuserConfirmation?: any;
  openCustomField?: any;
  openUpdateUserFormModal?: any;
  openUpdateCustomFieldModal?: any;
  companyId: string;
  filterData: IFilterData;
  setIsOpenFileUploadModal(value: boolean): void;
  hasFileUpload: boolean;
  allowedCreateCustomField: boolean;
  userTypeOption: IOption[];
}

export class UserTableList {
  static Render: React.FC<IUserProps> = (props) => {
    const userLoggedIn = useSelector((state: IRootState) => state.auth.userLoggedIn);
    const companyId = props.companyId;

    return <div id='user-table' className='admin-table-container table-wrapper'>
      <table className='admin-table table-container' >
        <Table.Header >
          <TableHeaderAction
            staticField={props.staticField}
            dynamicField={props.dynamicField}
            openUpdateCustomFieldModal={props.openUpdateCustomFieldModal}
            filterData={props.filterData}
            companyId={companyId}
            setIsOpenFileUploadModal={props.setIsOpenFileUploadModal}
            hasFileUpload={props.hasFileUpload}
            allowedCreateCustomField={props.allowedCreateCustomField}
            userTypeOption={props.userTypeOption}
          />
          <TableHeaderTitle
            staticField={props.staticField}
            dynamicField={props.dynamicField}
            openCustomField={props.openCustomField}
            userLoggedIn={userLoggedIn}
            companyId={companyId}
            allowedCreateCustomField={props.allowedCreateCustomField}
          />
        </Table.Header>
        <TableList
          user={props.user}
          staticField={props.staticField}
          dynamicField={props.dynamicField}
          openDeleteuserConfirmation={props.openDeleteuserConfirmation}
          openUpdateUserFormModal={props.openUpdateUserFormModal}
          userLoggedIn={userLoggedIn}
          companyId={companyId}
        />
      </table>
    </div>
  }
}
import { IDisplayData, IDisplayDataConfigKeypaths } from './interface/display-data';
import {
  GET_DISPLAY_DATA_CONFIG,
  GET_DISPLAY_DATA_CONFIG_RETURN,
  GET_DISPLAY_DATA_CONFIG_ERROR,
  SAVE_DISPLAY_DATA_CONFIG,
  SAVE_DISPLAY_DATA_CONFIG_RETURN,
  SAVE_DISPLAY_DATA_CONFIG_ERROR,
  GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN,
  DELETE_DISPLAY_DATA_CONFIG,
  DELETE_DISPLAY_DATA_CONFIG_ERROR,
  DELETE_DISPLAY_DATA_CONFIG_RETURN,
  CLEAR_SAVE_DISPLAY_DATA_CONFIG_ERROR,
  CLEAR_SAVE_DISPLAY_DATA_CONFIG_SUCCESS,
  PUBLISH_DISPLAY_DATA_CONFIG,
  PUBLISH_DISPLAY_DATA_CONFIG_RETURN,
  PUBLISH_DISPLAY_DATA_CONFIG_ERROR
} from './constant';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { displayDataMessage } from './message';
import { IDisplayComponent, IDisplayDataConfigAssignments } from './interface/display-data-assignments';

export interface IConfigurationDisplayDataReducer {
  type?: string;
  displayData: IDisplayData[];
  listLoading: boolean;
  error: string;
  success: string;
  displayDataConfigKeypath: IDisplayDataConfigKeypaths[]
  fieldList: ICustomFieldDetail[];
  displayDataAssignments: IDisplayDataConfigAssignments[];
  displayDataComponents: IDisplayComponent[];
  activeMenu?: "Applied Labels" | "Created Labels";
  saveType?: string;
  deletedData?: any;
}

const initialState: IConfigurationDisplayDataReducer = {
  displayData: [],
  listLoading: false,
  error: '',
  success: '',
  displayDataConfigKeypath: [],
  fieldList: [],
  displayDataAssignments: [],
  displayDataComponents: []
}

const configurationDisplayDataReducer = (state = initialState, action: IConfigurationDisplayDataReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_DISPLAY_DATA_CONFIG:
      newState.displayData = [];
      newState.listLoading = true;
      return newState;

    case GET_DISPLAY_DATA_CONFIG_RETURN:
      newState.displayData = action.displayData;
      newState.displayDataConfigKeypath = action.displayDataConfigKeypath;
      newState.fieldList = action.fieldList;
      newState.listLoading = false;
      return newState;

    case GET_DISPLAY_DATA_CONFIG_ERROR:
      newState.error = action.error;
      newState.listLoading = false;
      return newState;

    case SAVE_DISPLAY_DATA_CONFIG:
      newState.listLoading = true;
      newState.error = '';
      newState.success = '';
      return newState;

    case SAVE_DISPLAY_DATA_CONFIG_RETURN:
      if (action.saveType === 'draft') {
        newState.listLoading = false;
        newState.success = displayDataMessage.saveDraftDisplayDataSuccess;
      }
      newState.displayDataAssignments = action.displayDataAssignments;
      newState.saveType = action.saveType;
      return newState;

    case SAVE_DISPLAY_DATA_CONFIG_ERROR:
      newState.error = action.error;
      newState.listLoading = false;
      return newState;

    case GET_DISPLAY_DATA_CONFIG_ASSIGNMENTS_RETURN:
      newState.displayDataAssignments = action.displayDataAssignments;
      newState.displayDataComponents = action.displayDataComponents;
      newState.listLoading = false;
      return newState;

    case DELETE_DISPLAY_DATA_CONFIG:
      newState.error = '';
      newState.listLoading = true;
      return newState;

    case DELETE_DISPLAY_DATA_CONFIG_RETURN:
      newState.listLoading = false;
      if (action.activeMenu === 'Created Labels') {
        newState.success = displayDataMessage.deleteDraftDisplayDataSuccess;
        if (action.deletedData) {
          newState.displayData = newState.displayData.filter(e => e.id !== action.deletedData.id);
        }
      } else {
        newState.success = displayDataMessage.deleteDraftDisplayDataAssignmentsSuccess;
        if (action.deletedData) {
          const index = newState.displayDataAssignments.indexOf(action.deletedData);
          newState.displayDataAssignments = newState.displayDataAssignments.filter((e, i) => i !== index);
        }
      }
      return newState;

    case DELETE_DISPLAY_DATA_CONFIG_ERROR:
      newState.error = action.error;
      newState.listLoading = false;
      return newState;

    case CLEAR_SAVE_DISPLAY_DATA_CONFIG_SUCCESS:
      newState.success = '';
      return newState;

    case CLEAR_SAVE_DISPLAY_DATA_CONFIG_ERROR:
      newState.error = '';
      return newState;

    case PUBLISH_DISPLAY_DATA_CONFIG:
      newState.listLoading = true;
      newState.error = '';
      newState.success = '';
      return newState;

    case PUBLISH_DISPLAY_DATA_CONFIG_RETURN:
      newState.success = displayDataMessage.publishDisplayDataSuccess;
      newState.listLoading = false;
      return newState;

    case PUBLISH_DISPLAY_DATA_CONFIG_ERROR:
      newState.error = action.error;
      newState.listLoading = false;
      return newState;

    default:
      return state;
  }
}

export default configurationDisplayDataReducer;
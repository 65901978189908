import React, { useState, useEffect } from 'react';
import { formBuilderFieldType } from '../../../../../../common/field/type';
import referenceForm from '../../../../../../common/field/type/reference-form';
import { Image } from 'semantic-ui-react-bpm';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { IFieldTypeObj } from '../../../../../../common/field/type/interface/field-type-object';
import { getPublishedFormAction } from './action';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../../../../reducers';
import { IForm } from '../form/interface/form';
import { v4 as uuid } from 'uuid';
import { IDataTable } from '../../../data-tables/reducer';
import { systemReferencedFields } from './constant';

interface IFieldSection {
  onAddSection(): void;
  currentBp: IForm;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

interface IFieldType {
  field: IFieldTypeObj;
  fieldType: string;
  bpImage?: { src: any, seq: number };
}

const FieldType: React.FC<IFieldType> = (props) => {
  const [imageError, setImageError] = useState(false);

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'field', data: props.field },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  const viewIcon = () => {
    let view = <Image src={props.fieldType === 'referenced' ? props.field.icon.blue : props.field.icon.white} style={props.field.style} />;
    if (props.bpImage && props.bpImage.seq) {
      view = <div className={`sequence-icon`}>{props.bpImage.seq}</div>
    }
    if (props.bpImage && props.bpImage.src) {
      if (!imageError) {
        view = <img src={props.bpImage.src} alt={props.bpImage.seq.toString()} onError={(e) => setImageError(true)} />;
      } else {
        view = <div className={`sequence-icon`}>{props.bpImage.seq}</div>
      }
    }
    return view;
  }

  return <div
    ref={dragRef}
    style={draggedObject}
    key={`field-type-${props.field.title}`}
    className='field-type'>
    <div className='field-type-image'>
      {viewIcon()}
    </div>
    <div className='field-type-title'>
      <span>{props.field.title}</span>
    </div>
  </div>
}

const FieldTypeSection: React.FC<IFieldSection> = (props) => {
  const [selectedtab, setSelectedtab] = useState('field');
  const dispatch = useDispatch();
  const company = useSelector((state: IRootState) => state.auth.company);
  const { publishedForm, datatable } = useSelector((state: IRootState) => state.formBuilder);

  useEffect(() => {
    if (company) {
      dispatch(getPublishedFormAction({ company: company }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return <div className='form-builder-field-type'>
    <div className='add-section' onClick={() => props.onAddSection()} ><span>abc</span>&nbsp;Add Section</div>
    <div className='field-type-tab'>
      <label className={`field ${selectedtab === 'field' ? 'selected' : ''}`} onClick={() => setSelectedtab('field')}>Fields</label>
      <label className={`references ${selectedtab === 'references' ? 'selected' : ''}`} onClick={() => setSelectedtab('references')}>References</label>
    </div>
    {selectedtab === 'field' &&
      <div>
        {
          formBuilderFieldType.map((field: IFieldTypeObj) => {
            return <FieldType
              key={`field-type-section-${uuid()}`}
              field={field}
              fieldType='regular'
            />
          })
        }
      </div>
    }
    {selectedtab === 'references' &&
      <div className='referenced-field-container'>
        <label>System Lists</label>
        {
          systemReferencedFields.map((field) => {
            return <FieldType
              key={`field-type-section-${uuid()}`}
              field={field}
              fieldType='referenced'
            />
          })
        }
        <br />
        <label>Business Processes</label>
        {
          publishedForm.map((form: IForm) => {
            const publishedFormField = { ...referenceForm };
            publishedFormField.title = form.name;
            publishedFormField.defaultConfig = {
              ...referenceForm.defaultConfig,
              dataType: 'document',
              data: { companyId: company, formId: form.id }
            };
            publishedFormField.defaultData = {
              ...referenceForm.defaultData,
              label: form.name,
            };

            if (props.currentBp.id === form.id) {
              return undefined;
            }

            return <FieldType
              key={`field-type-section-${uuid()}`}
              field={publishedFormField}
              fieldType='referenced'
              bpImage={props.bpImage[form.id || '']}
            />
          })
        }
        <br />
        <label>Data Tables</label>
        {
          datatable.map((detail: IDataTable) => {
            const datatableField = { ...referenceForm };
            datatableField.title = detail.name;
            datatableField.defaultConfig = {
              ...referenceForm.defaultConfig,
              dataType: 'database',
              data: { companyId: company, databaseId: detail.id }
            }
            datatableField.defaultData = {
              ...referenceForm.defaultData,
              label: detail.name,
            }

            return <FieldType
              key={`field-type-section-${uuid()}`}
              field={datatableField}
              fieldType='referenced'
            />
          })
        }
      </div>
    }
  </div>
}

export default FieldTypeSection;

import { useEffect } from 'react';
import { IFieldAccessType } from '../../../../../../../component/admin-module/module/users/interface/field';
import { IInputConfig } from '../../../../field-element';
import { ArrayFieldProperties } from '../../../../service/array-field-properties.service';
import { FieldProperties } from '../../../../service/field-properties.service';
import { NumberProps } from '../../../../service/type/number';
import { validateInput } from '../utils/validate-input';

interface InitialLoad {
  setMultilineCount?(value: number): void;
  isAddRecord?: boolean;
  setDefaultValue(): void;
  name: string;
  multiline?: boolean;
  multilineCount?: number;
  setRefresher(value: string): void;
  accessType: IFieldAccessType;
  validationSchema: any;
  forwardedRef: any;
  inputConfig: IInputConfig;
  setProperty(): void;
  label: string;
  elementProperties: ArrayFieldProperties<NumberProps> | FieldProperties<NumberProps>;
}

export const useInitialLoad = ({
  setMultilineCount,
  isAddRecord,
  setDefaultValue,
  accessType,
  name,
  setRefresher,
  validationSchema,
  forwardedRef,
  inputConfig,
  setProperty,
  label,
  elementProperties,
  multiline,
  multilineCount
}: InitialLoad): void => {

  useEffect(() => {
    if (multiline && setMultilineCount && elementProperties instanceof ArrayFieldProperties) {
      const len = elementProperties.current.length;
      setMultilineCount(len === 0 ? 1 : len);
    }
    if (isAddRecord) {
      setDefaultValue();
    }
    if (accessType === IFieldAccessType.Required) {
      validateInput({
        setRefresher: setRefresher,
        name,
        validationSchemaProps: {
          ...validationSchema,
          isRequired: true,
          type: 'number'
        },
        multiline: multiline || false,
        multilineCount: multilineCount,
        forwardedRef,
        inputConfig,
        setProperty: setProperty,
        labelProps: label,
        requiredProps: true,
        accessType: accessType,
        valueFieldClass: elementProperties
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Dropdown, Popup } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { v4 as uuid } from 'uuid';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { IAutomationComponent } from '../../../../../interface/component-list';
import { IReference } from '../../../../../../../../configuration/module/bp-relationship/interface/form';
import { IField } from '../../../../../../../../../../users/interface/field';
import phoneField from '../../../../../../../../../../../../../common/field/type/phone';
import { FieldElement } from '../../../../../../../../../../../../../common/field/component/field-element';
import { plainToClass } from 'class-transformer';

interface ISendSMSProps {
  data: any;
  index: number;
  addNewRecipient: () => void;
  list: string[];
  removeRecipient: (index: number) => void;
  getOption(component: IAutomationComponent): void;
  selectedBP: string;
  openModal(isVisible: boolean, index: number): void;
  isVisible: { [id: string]: any };
  manageOperator(value: string, index: number): void;
  operation: any;
  updateRecipient(data: any, index: number): void;
  phoneFieldRefValue: any;
  selectedComponent: IAutomationComponent;
}

const SendSMSComponent: React.FC<ISendSMSProps> = (props) => {
  const {
    formFieldList,
    inputFieldList,
    referenceList,
    datatableInputField
  } = useSelector((state: IRootState) => state.automation);
  const { userFieldListDynamic, userFieldListStatic } = useSelector((state: IRootState) => state.user);

  let tempPhoneField: IField = {
    ...phoneField.defaultData,
    id: props.index.toString(),
    inputConfig: {
      type: phoneField.defaultData.type,
      config: phoneField.defaultConfig,
    },
  };

  const field = plainToClass(FieldElement, tempPhoneField);
  const Element = field.getFieldElement();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const operation = props.operation[props.index];

  let fieldId = '';
  let sourceReference = '';

  if (props.data && typeof props.data === 'object' && (props.data.hasOwnProperty('referencedKeyPath') || props.data.hasOwnProperty('keyPath'))) {
    fieldId = props.data.keyPath;
    sourceReference = props.data.referencedKeyPath;
  }

  const manageElementValue = () => {
    if (props.phoneFieldRefValue
      && props.phoneFieldRefValue.current
      && props.phoneFieldRefValue.current[props.index].hasOwnProperty('value')) {
      const data = props.phoneFieldRefValue.current[props.index].value;
      props.updateRecipient(data, props.index);
    }
  }

  const getReferenceFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [{
      key: uuid(),
      value: 'current-form',
      text: 'Current Form'
    }];
    formFieldList.forEach((field: ICustomFieldDetail) => {
      if (field.configName === IConfigName.Referenced) {
        options.push({
          key: uuid(),
          value: field.id || '',
          text: field.label || ''
        })
      }
    })
    return options;
  }

  const manageSourceReference = (value: string) => {
    if (value !== props.selectedBP) {
      if (!props.selectedComponent.config['to']) {
        props.selectedComponent.config['to'] = [];
      }
      const data = props.selectedComponent.config['to'];
      if (data instanceof Array) {
        data[props.index] = { referencedKeyPath: value };
      }
      props.selectedComponent.config['to'] = data;
    }
    props.getOption(props.selectedComponent);
    props.updateRecipient({ referencedKeyPath: value, keyPath: '' }, props.index);
  }

  const getFormFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    let sourceId = props.selectedBP;
    let fieldList: any[] = [];
    if (sourceReference !== 'current-form') {
      const fieldConfig = formFieldList.find((e: ICustomFieldDetail) => e.id === sourceReference);
      if (fieldConfig) {
        sourceId = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
      }
      const selectedRef = referenceList.find((ref: IReference) => ref.id === sourceId);
      if (selectedRef) {
        fieldList = inputFieldList[sourceId];
        if (selectedRef.dataType === 'database') {
          fieldList = datatableInputField[sourceId];
        }
      }
      if (fieldConfig && fieldConfig.config && fieldConfig.config.dataType === 'user') {
        fieldList = [...userFieldListDynamic, ...userFieldListStatic];
      }
    } else {
      fieldList = inputFieldList[sourceId];
    }

    fieldList && fieldList.forEach((e: IField) => {
      if (e.inputConfig && e.inputConfig.config && (e.configName === IConfigName.Phone
        || (e.label === IConfigName.Phone && e.inputConfig.config.format === 'regexp')
        || e.inputConfig.config.format === 'phone'
      )) {
        options.push({
          key: uuid(),
          value: e.id || '',
          text: e.label || ''
        })
      }
    })
    return options;
  }

  const manageFieldId = (value: string) => {
    props.updateRecipient({
      referencedKeyPath: sourceReference,
      keyPath: value
    }, props.index);
  }

  const getFieldLabel = () => {
    const label = getFormFieldOption().find((e: IDropdownOption) => e.value === fieldId);
    if (label) {
      return label.text;
    }
    return 'Phone Field';
  }

  const getSoureceReferenceLabel = () => {
    const label = getReferenceFieldOption().find((e: IDropdownOption) => e.value === sourceReference);
    if (label) {
      return label.text;
    }
    return '';
  }

  const setOperator = (value: 'copy' | 'value') => {
    setIsPopupOpen(false);
    props.manageOperator(value, props.index);
    if (value === 'copy') {
      delete props.phoneFieldRefValue.current[field.id].value;
    }
  }

  return <div className={`automation-field-container with-action-btn select-field-container ${props.index > 0 ? 'no-padding-top' : ''}`}>
    <div className={`field automation-field full ${field.inputConfig.config.format}`}>
      {props.index === 0 && <label>To:</label>}
      {operation === 'value' ?
        <Element
          {...field}
          hasIconInside={false}
          forwardedRef={props.phoneFieldRefValue}
          defaultValueElement={false}
          isAddRecord={true}
          throwValueOutside={manageElementValue}
        />
        : <div className='select-field-container'>
          <Dropdown
            text={``}
            icon='dropdown'
            floating
            labeled
            button
            className='icon'
            closeOnChange={false}
            popperContainer={DropdownPopperOption}
          >
            <Dropdown.Menu className={`${props.isVisible[props.index] === true ? 'visible' : ''}`}>
              <Dropdown.Menu scrolling className={`option-list`}>
                <div className={`option-title`}>Select Source Form</div>
                <Dropdown
                  fluid
                  selection
                  clearable
                  search
                  selectOnBlur={false}
                  value={sourceReference}
                  options={getReferenceFieldOption()}
                  onChange={(event, target: any) => { manageSourceReference(target.value) }}
                  popperContainer={DropdownPopperOption}
                />
                <div className={`option-title`}>Field to copy value form</div>
                <Dropdown
                  fluid
                  selection
                  clearable
                  search
                  selectOnBlur={false}
                  value={fieldId}
                  options={getFormFieldOption()}
                  onChange={(event, target: any) => { manageFieldId(target.value) }}
                  popperContainer={DropdownPopperOption}
                />
                <span className={`close-button`} onClick={(e) => {
                  props.openModal(props.isVisible[props.index], props.index);
                  e.stopPropagation();
                }}>Close</span>
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
          <div style={{ position: 'relative' }}>
            <label className={`dropdown-text-cover`}
              onClick={() => props.openModal(props.isVisible[props.index] ?
                props.isVisible[props.index] : false, props.index)}>
              <label>{getFieldLabel()}</label> {fieldId === 'document-creator' ? 'of' : 'from'} <label>{getSoureceReferenceLabel()}</label>
            </label>
          </div>
        </div>

      }
    </div>
    <div className='button-container'>
      <Popup
        basic
        on='click'
        position='bottom left'
        id='copy-from-popup'
        style={{ filter: 'none', zIndex: '10000' }}
        trigger={
          <Button
            className={`btn-action-collapse `}
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            icon>
            <i className="ellipsis horizontal icon"></i>
          </Button>
        }
        onClose={() => setIsPopupOpen(!isPopupOpen)}
        open={isPopupOpen}>
        <Container className='copy-from-popup-popup-container email' >
          <div>OPERATION</div>
          <ul>
            <li onClick={() => setOperator('value')}>
              {operation === 'value' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Manual Input</li>
            <li onClick={() => setOperator('copy')}>
              {operation === 'copy' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Choose from list</li>
          </ul>
        </Container>
      </Popup>
    </div>
    {props.list.length > 0 && !((props.list.length - 1) === 0) &&
      <div className='button-container delete'>
        <i className={`icon alternate trash outline`} color={'#3b86a8'} onClick={() => props.removeRecipient(props.index)} />
      </div>
    }
    {props.index === props.list.length - 1 ?
      <div className='button-container add'>
        <i className={`plus circular icon new-email`} onClick={props.addNewRecipient} />
      </div>
      :
      <div />
    }
  </div>
}

export default SendSMSComponent;
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IForm } from '../../../../../admin-module/module/business-process/module/form/interface/form';
import { GET_FORM_FIELD_LIST, GET_FORM_FIELD_LIST_RETURN, GET_PAGE_LAYOUT, GET_PAGE_LAYOUT_RETURN, GET_PUBLISHED_FORM_LIST, GET_PUBLISHED_FORM_LIST_RETURN } from './constant';
import { IPortalPageLayout } from './interface/page-layout';


export interface IPortalFormViewReducer {
  type?: string;
  formFieldList?: ICustomFieldDetail[];
  publishedForm?: IForm[];
  pageLayout?: IPortalPageLayout[];
}

const initialState: IPortalFormViewReducer = {
  formFieldList: undefined,
  publishedForm: undefined,
  pageLayout: undefined,

};

const workflowReducer = (state = initialState, action: IPortalFormViewReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_FIELD_LIST:
      newState.formFieldList = undefined;
      return newState;

    case GET_FORM_FIELD_LIST_RETURN:
      newState.formFieldList = action.formFieldList;
      return newState;

    case GET_PUBLISHED_FORM_LIST:
      newState.publishedForm = undefined;
      return newState;

    case GET_PUBLISHED_FORM_LIST_RETURN:
      newState.publishedForm = action.publishedForm;
      return newState;

    case GET_PAGE_LAYOUT:
      newState.pageLayout = undefined;
      return newState;

    case GET_PAGE_LAYOUT_RETURN:
      newState.pageLayout = action.pageLayout;
      return newState;

    default:
      return newState;
  }
}

export default workflowReducer

import { Delta } from 'jsondiffpatch';
import { IUserInfo } from '../../../document-module/module/report/interface/form-document';

export interface IAdminHistoryLogs {
  id: string
  type: AdminHistoryType
  category: AdminHistoryCategory
  eventMeta: { event: string; eventExecution: string }
  context: { targetInstance: any; targetDelta?: Delta }
  createdAt: Date
  sender: AdminHistorySender
  text: string
}

export interface AdminHistorySender {
  type: EventSenderType
  data: IUserInfo
}

export enum AdminHistoryType {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  ChangePassword = 'change-password'
}

export enum AdminHistoryCategory {
  Users = 'Users',
  UsersFields = 'UsersFields',
  Groups = 'Groups',
  BusinessProcess = 'BusinessProcess ',
  FormBuilder = 'FormBuilder',
  Workflow = 'Workflow',
  Roles = 'Roles',
  PermissionsDynamicActions = 'PermissionsDynamicActions',
  PermissionsFieldPermissions = 'PermissionsFieldPermissions',
  ConfigurationsTaskLayout = 'ConfigurationsTaskLayout',
  ConfigurationsReportsLayout = 'ConfigurationsReportsLayout',
  ConfigurationsReportsFilter = 'ConfigurationsReportsFilter',
  ConfigurationsLabels = 'ConfigurationsLabels',
  ConfigurationsSQSPlugin = 'ConfigurationsSQSPlugin',
  ConfigurationsAPIs = 'ConfigurationsApis',
  ConfigurationsDuplicateChecker = 'ConfigurationsDuplicateChecker',
  ConfigurationsAutomation = 'ConfigurationsAutomation',
  Apps = 'Apps',
  DataTable = 'DataTable',
  DataTableFields = 'DataTableFields',
  DataTablePermissions = 'DataTablePermissions'
}

export enum EventSenderType {
  User = 'user',
  Plugin = 'plugin',
  System = 'system',
  Automation = 'automation',
  Scheduler = 'scheduler'
}
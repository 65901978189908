import { CSSProperties } from 'react';
import { icons } from '../../../../../../common/icons';
import { AdminHistoryCategory } from '../../../../history/interface/history';

export const SUB_ROUTE = 'app/app/admin-module/business-process/configuration/SUB_ROUTE';

export const configurationSubModules = [
  {
    id: 'duplicate-checker',
    name: 'Duplicate Checker',
    route_label: 'DUPLICATE CHECKER',
    categoryLogs: [AdminHistoryCategory.ConfigurationsDuplicateChecker]
  },
  {
    id: 'slas',
    name: 'SLAs',
    route_label: 'SLAs',
  },
  {
    id: 'task-view',
    name: 'Tasks Layout',
    route_label: 'TASKS LAYOUT',
    categoryLogs: [AdminHistoryCategory.ConfigurationsTaskLayout]
  },
  {
    id: 'reports-layout',
    name: 'Reports Layout',
    route_label: 'REPORTS LAYOUT',
    categoryLogs: [AdminHistoryCategory.ConfigurationsReportsLayout]
  },
  {
    id: 'report-filters',
    name: 'Report Filters',
    route_label: 'REPORT FILTERS',
    categoryLogs: [AdminHistoryCategory.ConfigurationsReportsFilter]
  },
  {
    id: 'bp-relationships',
    name: 'BP Relationships',
    route_label: 'BP RELATIONSHIPS',
  },
  {
    id: 'gantt-chart',
    name: 'Gantt Chart',
    route_label: 'GANTT CHART'
  },
  {
    id: 'labels',
    name: 'Labels',
    route_label: 'LABELS',
    categoryLogs: [AdminHistoryCategory.ConfigurationsLabels]
  },
  {
    id: 'flutter-labels',
    name: 'Flutter Labels',
    route_label: 'FLUTTER LABELS'
  },
  {
    id: 'sqs-plugin',
    name: 'SQS Plugin',
    route_label: 'SQS PLUGIN',
    categoryLogs: [AdminHistoryCategory.ConfigurationsSQSPlugin]
  },
  {
    id: 'apis',
    name: 'APIs',
    route_label: 'APIs',
    categoryLogs: [AdminHistoryCategory.ConfigurationsAPIs]
  },
  {
    id: 'pdf-template',
    name: 'PDF Template',
    route_label: 'PDF Template',
    categoryLogs: []
  }
];


export const fieldIconBaseOnConfigName: { [x: string]: { icon: NodeRequire, iconStyle: CSSProperties, dropdownStyle?: CSSProperties } } = {
  'Checklist': {
    icon: icons.blue.checklist,
    iconStyle: {
      marginTop: '1px',
      width: '16px',
    },
    dropdownStyle: {
      marginTop: '-3px',
      width: '17px'
    }
  },
  'Date': {
    icon: icons.blue.calendar,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'Dropdown': {
    icon: icons.blue.dropdown,
    iconStyle: {
      marginTop: '3px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '19px',
    }
  },
  'Referenced': {
    icon: icons.blue.dropdown,
    iconStyle: {
      marginTop: '3px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '3px',
      width: '17px',
    }
  },
  'Email': {
    icon: icons.blue.email,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-3px',
      width: '16px',
    }
  },
  'Number': {
    icon: icons.blue.number,
    iconStyle: {},
    dropdownStyle: {
      marginTop: '2px',
      width: '22px',
    }
  },
  'Seq': {
    icon: icons.blue.number,
    iconStyle: {}
  },
  'Switch': {
    icon: icons.blue.off,
    iconStyle: {
      marginTop: '5px',
      width: '16px',
    },
    dropdownStyle: {
      marginTop: '1px',
      width: '18px',
    }
  },
  'Text String Multiline': {
    icon: icons.blue.alignJustify,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'Text String': {
    icon: icons.blue.font,
    iconStyle: {
      marginTop: '1px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-5px',
      width: '16px',
    }
  },
  'Time': {
    icon: icons.blue.clock,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'File Upload': {
    icon: icons.blue.paperclip,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'URL': {
    icon: icons.blue.link,
    iconStyle: {
      marginTop: '3px',
      width: '14px',
    },
    dropdownStyle: {
      marginTop: '-2px',
      width: '16px',
    }
  },
  'FX': {
    icon: icons.blue.fx,
    iconStyle: {
      marginTop: '4px',
      width: '15px',
    },
    dropdownStyle: {
      marginTop: '-1px',
      width: '16px',
    }
  },
  'Autopopulated': {
    icon: icons.blue.autopopulated,
    iconStyle: {
      marginTop: '2px',
      width: '17px',
    },
    dropdownStyle: {
      marginTop: '-4px',
      width: '20px',
      height: '20px'
    }
  },
}
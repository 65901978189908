import { IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import ValidateField from '../../../validation';
import { IValidationError } from '../../../validation/type/validation-type';
import { FieldElement } from '../../field-element';
import { TValidationType, PropType } from '../field-properties.handler';

export interface INumberProps {
  value: string;
  fieldType?: string;
  error?: IValidationError[];
}

export class NumberProps extends PropType<INumberProps, INumberProps> {

  processValueFromRef(props: INumberProps): INumberProps {
    props.value = typeof props.value !== 'undefined' && props.value !== null ? props.value : '';
    return props;
  }

  processValueToRef(newProps: INumberProps): INumberProps {
    newProps.value = typeof newProps.value !== 'undefined' && newProps.value !== null ? newProps.value : '';
    return newProps;
  }

  processValidation(data: INumberProps, field: FieldElement, validationType: TValidationType): IValidationError[] {
    const value = data.value;
    const validateField = new ValidateField();
    const validate = validateField.singleField(field, value, validationType);
    return field.accessType === IFieldAccessType.Required || !!value ? validate : [];
  }
}
interface IDataTableFields {
  id: string;
  type: string;
  config: object;
}
class DataTypeCount {
  dataTableFields!: IDataTableFields[];

  countAttributeDataType = () => {
    let count = this.dataTableFields && this.dataTableFields.filter((element: any) => {
      return element.config && element.config.dataType !== 'database'
    });
    return count.length;
  }

  countDataTableRelationshipDataType = () => {
    let count = this.dataTableFields && this.dataTableFields.filter((element: any) => {
      return element.config && element.config.dataType === 'database'
    });
    return count.length;
  }

}

export default DataTypeCount;
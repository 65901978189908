import React, { useState } from 'react';
import { icons } from '../../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';
import PlainSection from './plain-section';
import { IPortalPageLayout } from '../interface/page-layout';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ISectionProps {
  sectionDetail: IPortalPageLayout;
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): void;
}

const Section: React.FC<ISectionProps> = (props) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <div className={`section-container`}>
      <div className={`section-drag`}>
        <Image src={icons.blue.dragIcon} />
      </div>
      <div className={`content`}>
        <div className={`section-header`}>
          <label>{props.sectionDetail?.header?.title || ''}</label>
          <i onClick={() => { setCollapse(!collapse) }} className={`ui icon chevron ${collapse ? 'up' : 'down'}`}></i>
          <i className={`ui icon cog`}></i>
        </div>
        {collapse && <>
          {props.sectionDetail.layout &&
            props.sectionDetail.layout.map((e: string[]) => {
              return <PlainSection fields={e} viewIcon={props.viewIcon} />;
            })

          }
          <div className={`drop-area`}>
            Drop and drop fields here
          </div>
        </>
        }
      </div>
    </div>
  );
}

export default Section;
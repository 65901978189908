import React, { useEffect } from 'react';
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Inject,
  CommandModel,
  Sort,
  Search,
  ExcelExport,
  Grid,
  Toolbar,
  Page
} from '@syncfusion/ej2-react-grids';
import { Link, useHistory } from "react-router-dom";
import { IDataTable } from '../reducer';
import DataTypeCount from '../../../../../common/utils/data-type-count';
import { plainToClass } from 'class-transformer';
import styles from '../css/datatable-grid.module.scss';
import { dataTableLimit } from '../constant';

interface TableRowData {
  [key: string]: any
}

interface IDatatablesGrid {
  openDeleteConfirmationModal(formId: string, formName: string): void;
  openDataTableRecords: (id: string) => void;
  dataTable: IDataTable[];
  triggerFilter(limit?: number | undefined, offset?: number | undefined, search?: string | undefined): void;
  downloadData(): void;
}

const datatableNameTemplate = (props: any) => {
  return (<Link to={`/admin/data-tables/records/${props.id}`} onClick={() => props.openDataTableRecords(props.id)}>
    {props.name}
  </Link>)
}

const DatatablesGrid: React.FC<IDatatablesGrid> = (props) => {
  let gridInstance: Grid;

  const history = useHistory();

  const commands: CommandModel[] = [
    { type: 'Edit', buttonOption: { iconCss: `e-icons e-edit ${styles.datatable_icons}`, cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: `e-icons e-delete ${styles.datatable_icons}`, cssClass: 'e-flat' } }
  ];
  const headers = ['Datatable Name', '# of Attributes', '# of Relationships'];
  const clearSearch = {
    title: 'Clear Search',
    cssClass: 'e-clear-btn',
    tooltipText: 'Clear Search',
    prefixIcon: 'e-input-group-icon e-close',
  }
  const toolbarOptions = ['ExcelExport', 'Search', clearSearch]

  const commandClick = (args: any): void => {
    if (args.commandColumn.type === "Delete") {
      const row: TableRowData = args.rowData!;
      props.openDeleteConfirmationModal(row.id, row.name);
    } else if (args.commandColumn.type === "Edit") {
      const row: TableRowData = args.rowData!;
      history.push(`/admin/data-tables/${row.id}`);
    }
  }

  const gridDataBound = () => {
    const toolbarLeftNodes = document.getElementsByClassName('e-toolbar-left')[0].childNodes;
    const items = [].slice.call(toolbarLeftNodes).filter((item: any) => { return item.getAttribute('title') === 'Clear Search' });
    items.forEach((element: any) => {
      element.remove();
    });
    for (var i = 0; i < items.length; i++) {
      document.getElementsByClassName('e-toolbar-right')[0].appendChild(items[i]);
    }
  }

  const gridActionBegin = (event: any) => {
    if (event.requestType === 'searching') {
      const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
      props.triggerFilter(dataTableLimit, 0, event.searchString);
      if (clear) {
        clear.className = clear.className.replace('e-focus', '');
      }
      event.cancel = true;
    }
  }

  const gridToolbarClick = (event: any) => {
    const input: any = document.getElementById(gridInstance.element.id + '_searchbar');
    if (event && event.item) {
      if (event.item.text === 'Excel Export') {
        props.downloadData();
      }
      if (event.item.title === 'Clear Search') {
        if (input && input.value) {
          input.value = '';
          props.triggerFilter(dataTableLimit, 0, '');
        }
      }
      if (event.item.type === 'Input') {
        const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
        if (input) {
          const searchWrap = input.parentElement;
          if (searchWrap) {
            clear.className = clear.className + ' e-focus';
          }
          input.onblur = (() => {
            clear.className = clear.className.replace('e-focus', '');
          });
        }
      }
    }
  }

  useEffect(() => {
    if (props.dataTable) {
      gridInstance.dataSource = props.dataTable.map(datatable => {
        let countDataType = plainToClass(DataTypeCount, { dataTableFields: datatable.fields });
        return {
          'Datatable Name': datatable.name,
          '# of Attributes': countDataType.countAttributeDataType(),
          '# of Relationships': countDataType.countDataTableRelationshipDataType(),
          ...datatable,
          ...props
        }
      });
    }
    // eslint-disable-next-line
  }, [props.dataTable]);

  useEffect(() => {
    if (gridInstance && gridInstance.element) {
      const search = gridInstance.element.querySelector('#' + gridInstance.element.getAttribute('id') + '_searchbar');
      if (search) {
        search.addEventListener('keyup', function (e: any) {
          const timer = setTimeout(() => {
            props.triggerFilter(dataTableLimit, 0, e.target.value);
          }, 1000);
          return () => { clearTimeout(timer) }
        });
      }
      gridInstance.dataSource = [];
    }
    // eslint-disable-next-line
  }, []);

  return <GridComponent
    toolbar={toolbarOptions}
    commandClick={commandClick}
    allowSorting={true}
    allowSelection={false}
    className={styles.datatable_grid}
    rowHeight={31}
    dataBound={gridDataBound}
    toolbarClick={gridToolbarClick}
    allowPaging={true}
    pageSettings={{ pageSize: 20 }}
    actionBegin={gridActionBegin}
    ref={grid => { if (grid) gridInstance = grid }}
  >
    <ColumnsDirective >
      <ColumnDirective isPrimaryKey field='key' visible={false} />
      {headers.map((header, idx) => {
        const template = header === 'Datatable Name' ? datatableNameTemplate : undefined;
        return <ColumnDirective key={idx} field={header} headerText={header} textAlign='Center' template={template} />
      })}
      <ColumnDirective headerText='' width='110' commands={commands} textAlign='Right' />
    </ColumnsDirective>
    <Inject services={[CommandColumn, Sort, Search, Toolbar, ExcelExport, Page]} />
  </GridComponent>
}

export default DatatablesGrid;
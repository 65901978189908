import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IDropdownOption } from '../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import DropdownPopperOption from '../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../reducers';
import { IFormStatusWithAction } from '../../../../../../../workflow/interface/form-status';
import { DetailActionButton } from '../../action-button';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from './registry';

const errorIndicator = {
  selectedStatus: false,
}

class ActionStatusUpdate {

  name = 'update-status';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const { formStatusList } = useSelector((state: IRootState) => state.workflow);

    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [error, setError] = useState(errorIndicator);

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.statusId = selectedStatus;
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const validateInput = (value?: string) => {
      const tempError = { ...error };
      if (!value) {
        tempError.selectedStatus = false;
        if (selectedStatus === '') {
          tempError.selectedStatus = true;
        }
      } else if (value === '') {
        tempError.selectedStatus = true;
      }
      setError(tempError)
    }

    const getStatusOption = (): IDropdownOption[] => {
      return formStatusList.map((status: IFormStatusWithAction) => {
        return {
          key: uuid(),
          value: status.id,
          text: status.name
        }
      })
    }

    const manageStatus = (value: string) => {
      setSelectedStatus(value);
      validateInput(value)
      props.hasComponentChange.current = true;
      props.parentRefresher();
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    useEffect(() => {
      const statusId = props.selectedComponent?.config?.statusId || '';
      setSelectedStatus(statusId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
      <div className={`automation-form-detail-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Status Update`}
          description={`Rules is run when Dynamic Action is executed.`}
          iconHeight={20}
          iconWidth={25}
          iconName='flag'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field ${error.selectedStatus ? 'error' : ''}`}>
            <label>Destination Status <span className={`required`}>*</span></label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              clearable
              search
              multiple={false}
              onBlur={() => {
                validateInput()
              }}
              selectOnBlur={false}
              value={selectedStatus}
              options={getStatusOption()}
              onChange={(event, target: any) => { manageStatus(target.value) }}
            />
          </div>
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}

export default ActionStatusUpdate;
import React from 'react';
import { customFilterSingleField, customFilterRangeField } from '../constant';
import { ICustomFilterField } from '../interface/custom-filter-field';
import Field from './field';
import FieldRange from './field-range';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ICustomFilterFieldProps {
  fieldList: ICustomFieldDetail[];
  customFilterFieldList: { [x: string]: any }
}

const CustomFilterField: React.FC<ICustomFilterFieldProps> = (props) => {

  const hasNumber = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'number').length > 0;
  }

  const hasTime = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'time').length > 0;
  }

  const hasDropdown = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'enum').length > 0;
  }

  const hasFileupload = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'file').length > 0;
  }

  const hasSwitch = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'bool').length > 0;
  }

  const hasTimestamp = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'date-time').length > 0;
  }

  const showField = (customFilterField: ICustomFilterField, fieldList: ICustomFieldDetail[]): boolean => {
    switch (customFilterField.type) {
      case 'number':
      case 'numberRange':
        return hasNumber(fieldList);
      case 'time':
      case 'timeRange':
        return hasTime(fieldList);
      case 'dropdown':
        return hasDropdown(fieldList);
      case 'fileupload':
        return hasFileupload(fieldList);
      case 'switch':
        return hasSwitch(fieldList);
      case 'date-timeRange':
      case 'date-time':
        return hasTimestamp(fieldList);
      default:
        return false;
    }
  }

  return (
    <div className={`custom-filter-field`}>
      <div className='single-field'>
        {
          customFilterSingleField.map((singleField: ICustomFilterField, index: number) => {
            singleField.show = singleField.show || showField(singleField, props.fieldList);
            return <Field key={`single-field-${index}`} {...singleField} />
          })
        }
      </div>
      <div className='range-field'>
        {
          customFilterRangeField.map((rangeField: ICustomFilterField, index: number) => {
            rangeField.show = rangeField.show || showField(rangeField, props.fieldList);
            return <FieldRange key={`range-field-${index}`}  {...rangeField} />
          })
        }
      </div>
    </div>
  );
}

export default CustomFilterField;

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { setToken, clearLocalStorage } from '../../../common/api-request';
import { useHistory } from 'react-router-dom';
import message from '../message';
import { IRootState } from '../../../reducers';

const UnauthorizedRequestHanlder = () => {
  const history = useHistory();
  const data = useSelector((state: IRootState) => state.errorStatusHandler);

  useEffect(() => {
    if (data.statusCode === 401) {
      alert(message.unauthorizeMessage)
      setToken('')
      clearLocalStorage();
      history.push('/auth');
    }
  }, [data.statusCode, history]);

  return (
    <></>
  )
}

export default UnauthorizedRequestHanlder
import { IField } from '../../component/admin-module/module/users/interface/field';

export const sortNonCustomField = (nonCustomFieldList: IField[]) => {
  const labelList = ['First Name', 'Last Name', 'Email Address', 'Phone'];
  nonCustomFieldList.sort(
    (config1: IField, config2: IField) => {
      const index1 = labelList.indexOf(config1.label || '');
      const index2 = labelList.indexOf(config2.label || '');
      return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
    });
  return nonCustomFieldList
}

export const sortUserField = (sortUserField: IField[], labelList: string[]) => {
  sortUserField.sort(
    (config1: IField, config2: IField) => {
      const index1 = labelList.indexOf(config1.label || '');
      const index2 = labelList.indexOf(config2.label || '');
      return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
    });
  return sortUserField
}
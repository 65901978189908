import React, { useState } from 'react';
import { Popup, Button, Container, Image } from 'semantic-ui-react-bpm';
import { Method } from 'axios';
import ConfigService from '../config';
import { useRouteMatch } from 'react-router-dom';
import { IFilter } from '../../component/document-module/interface/input/filter';
import { useDispatch } from 'react-redux';
import { setDownloadRequestsAction } from '../../component/main/action';
import { IField } from '../../component/admin-module/module/users/interface/field';
import { DropdownItemProps } from 'semantic-ui-react-bpm';
import SwitchComponent from './switch-component';
import { icons } from '../icons';

export interface IOption extends DropdownItemProps {
  key: number;
  text: string,
  value: string,
}

interface IDownloadPopup {
  downloadLink?: string;
  filter?: IFilter;
  method?: Method;
  downloadName?: string;
  hasFileUpload?: boolean;
  hasUpdateFileUpload?: boolean;
  dataFileUpload?(): void;
  tableFields?: IField[];
  tableList?: IOption[];
  page?: string;
}

const DownloadPopup: React.FC<IDownloadPopup> = (props) => {
  const dispatch = useDispatch();
  const match: { params: { submodule: string } } = useRouteMatch();

  const [includeTable, setIncludeTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [displayIncludeTable, setDisplayIncludeTable] = useState(false);

  const triggerDownload = () => {
    let url = props.downloadLink;

    if (selectedTable !== '' && includeTable) {
      url += `&exportTableIds=${selectedTable}`;
    }

    const axiosURL = ConfigService.loadConfig().general.apiBaseUrl + `${url}`;
    const axiosBody = props.filter;

    dispatch(setDownloadRequestsAction({
      method: props.method,
      url: axiosURL,
      data: axiosBody,
      downloadName: props.downloadName,
      match: match,
      headers: {
        'Accept': 'application/json'
      }
    }));
  }

  const downloadData = () => {
    if (props.tableList && props.tableList.length) {
      setDisplayIncludeTable(!displayIncludeTable);
      return;
    }
    triggerDownload();
    document.body.click();
  }

  const triggerUpload = () => {
    if (props.dataFileUpload) props.dataFileUpload();
    document.body.click();
  }

  const confirmDownload = () => {
    triggerDownload();
    setDisplayIncludeTable(false);
    document.body.click();
  }

  return <>
    <div className='download-popup-container'>
      <div hidden className='hidden-container' />
      <Popup
        basic
        on='click'
        position='bottom right'
        id='download-popup'
        trigger={
          <Button className='btn-download' icon>
            <span className='dynamic-actions-collapse'>...</span>
          </Button>
        }>
        <Container className='download-container' >
          <div className='download-content'>
            <div className='action-label download-header' onClick={downloadData}>
              <div className='flex-container with-icon'>
                <Image width='15px' src={icons.black.download} />
                <span>DOWNLOAD DATA</span>
              </div>
            </div>
            {displayIncludeTable &&
              <>
                <div className="border"></div>
                <div className='action-label include-table'>
                  <SwitchComponent label='Include Table' value={includeTable} onChange={() => setIncludeTable(!includeTable)} />
                  {includeTable &&
                    <>
                      <div className="table-list-container">
                        {props.tableList && props.tableList.map(table => {
                          return <div className={`field-input ui checkbox checklist-element`} key={table.value}
                            onClick={() => selectedTable ? setSelectedTable('') : setSelectedTable(table.value)}>
                            <input className="hidden" type="checkbox" onChange={() => { }} checked={selectedTable === table.value} />
                            <label className="list-label">{table.text}</label>
                          </div>
                        })}
                      </div>
                    </>
                  }
                  <div className="action-container">
                    <label className="confirm-action" onClick={confirmDownload}>CONFIRM</label>
                  </div>
                </div>
              </>
            }
            <div className='separator' />
            {props.hasFileUpload &&
              <div onClick={triggerUpload} className='action-label'>
                Upload/Add Records
              </div>
            }
            <div className='separator' />
            {props.hasUpdateFileUpload &&
              <div onClick={triggerUpload} className='action-label'>
                Upload/Update Records
              </div>
            }
          </div>
        </Container>
      </Popup>
    </div>
  </>
}

export default DownloadPopup;
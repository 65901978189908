class StringOperatorOption {

  name = 'string';

  option = [
    { key: 0, value: 'is-empty', text: 'Is empty' },
    { key: 1, value: 'is-not-empty', text: 'Is not empty' },
    { key: 2, value: 'contains', text: 'Contains' },
    { key: 3, value: 'does-not-contain', text: 'Does Not Contain' },
    { key: 4, value: 'starts-with', text: 'Starts With' },
    { key: 5, value: 'ends-with', text: 'Ends With' },
    { key: 6, value: 'equal', text: 'Equal' },
    { key: 7, value: 'not-equal', text: 'Not Equal' },
  ]

}

export default StringOperatorOption;
import React from 'react';
import { IFieldConfig } from '../../component/admin-module/module/users/interface/field';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject } from './interface/custom-field-detail';
import { IDataTable } from '../../component/admin-module/module/data-tables/reducer';
import { IForm } from '../../component/admin-module/module/business-process/module/form/interface/form';
import CustomFieldType from './custom-field-type';

export interface ICustomFieldProps {
  open: boolean;
  close(): void;
  defaultData: ICustomFieldDetail;
  defaultConfig: IFieldConfig;
  openDeleteModal(fieldId: string, label: string): void;
  loading: boolean;
  submit(data: IOnCustomFieldSubmitObject, fieldId: string): void;
  cancel(fieldId: string, onDelete?: boolean): void;
  fieldId: string;
  formType: 'User' | 'Datatable' | 'FormBuilder',
  datatable?: IDataTable[],
  publishedForm?: IForm[],
  formDetail?: IForm | IDataTable;
  datatableDetail?: IDataTable;
  forceNoMultiline?: boolean;
  currentFieldList?: ICustomFieldDetail[];
  companyId: string;
  tableId?: string;
  isViewOnly?: boolean;
  tableFieldList?: ICustomFieldDetail[];
  userFieldList?: ICustomFieldDetail[];
}

export interface IDefaultRef {
  defaultField?: any;
}

export interface IMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: { fieldId: string; }
}

const CustomFieldConfig: React.FC<ICustomFieldProps> = (props) => {
  const CustomField = new CustomFieldType();
  const type = props.defaultData.type as string;
  const Component = CustomField.getComponent(type)
  return <Component.Render {...props} />
}

export default CustomFieldConfig;
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Route } from 'react-router-dom';
import { useSetFourthLevelRoute } from '../../../../../../hooks/use-set-fourth-level-route';
import AutomationLogs from './automation-logs';

interface IMatch {
  path: string;
  params: {
    thirdLevelRoute: string;
  }
}

interface IAutomationLogsProps { }

class AutomationsLogsRoute {

  name = 'automation-logs';

  render: React.FC<IAutomationLogsProps> = (props) => {
    const match: IMatch = useRouteMatch();
    const dispatch = useDispatch();

    useSetFourthLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: match.params.thirdLevelRoute,
      fourthLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route path={`${match.path}`} exact>
          <AutomationLogs />
        </Route>
      </>
    );
  }
}

export default AutomationsLogsRoute;

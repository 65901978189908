import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../common/icons';
import { getFilters } from '../../../../../../../main/partials/header/hexToCssFilter';

interface IListHeaderField {

}

const ListHeaderField: React.FC<IListHeaderField> = (props) => {
  return <div className='list-header-field'>
    <div className='field'>
      <Image style={{ filter: getFilters('#61A7B3').filter }} src={icons.black.calendar} className={`icon`} />
      <label className='label'>{'Order Date'}</label>
      <Image style={{ filter: getFilters('#8A9296').filter }} src={icons.black.dragIcon} className={'drag-icon'} />
    </div>
  </div>
}

export default ListHeaderField;
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getRequest,
  deleteRequest,
  postRequest,
  putRequest,
  IAxiosResponse,
} from '../../../../common/api-request';

import {
  GET_USER_LIST,
  GET_USER_FIELD_LIST,
  DELETE_USER,
  GET_USER_FIELD_PERMISSION,
  GET_USER_PROFILE,
  CREATE_USER,
  GET_USER_DETAIL,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  GET_ACTIVE_USER_LIST,
  GET_USER_FIELD_DETAIL,
  CREATE_USER_FIELD,
  UPDATE_USER_FIELD,
  DELETE_USER_FIELD,
  FILTER_USERS,
  RESET_USER_PASSWORD,
  userListRecordLimit,
  GET_DATATABLE_LIST,
} from './constant';

import {
  getUserListReturnErrorAction,
  getUserFieldListReturnAction,
  getUserListReturnAction,
  deleteUserReturnAction,
  deleteUserErrorAction,
  getUserFieldPermissionReturnAction,
  getUserProfileReturnAction,
  createUserReturnAction,
  createUserErrorAction,
  getUserDetailReturnAction,
  getUserDetailErrorAction,
  updateUserReturnAction,
  updateUserErrorAction,
  updateUserStatusReturnAction,
  updateUserStatusErrorAction,
  getActiveUserListReturnAction,
  getUserFieldDetailReturnAction,
  getUserFieldDetailErrorAction,
  deleteUserFieldErrorAction,
  deleteUserFieldReturnAction,
  updateUserFieldErrorAction,
  updateUserFieldReturnAction,
  createUserFieldErrorAction,
  createUserFieldReturnAction,
  filterUsersReturnAction,
  filterUsersErrorAction,
  resetUserPasswordReturnAction,
  resetUserPasswordErrorAction,
  getDataTableListReturnAction,
} from './action';
import { setStatusCodeAction } from '../../../error/action';
import { IUserListInput } from './interface/input/user-list';
import { IUser } from './interface/user';
import { UserFieldListInput } from './interface/input/user-field-list';
import { IField } from './interface/field';
import { IUserDelete } from './interface/input/user-delete';
import { IUserCreate } from './interface/input/user-create';
import { IUserFieldPermissionInput } from './interface/input/user-field-permission';
import { IUserProfileInput } from './interface/input/user-profile';
import { IUserInput } from './interface/input/user-detail';
import { IUserUpdateInput } from './interface/input/user-update';
import { IUserUpdateStatusInput } from './interface/input/user-status-update';
import { IUserFieldInput } from './interface/input/user-field-detail';
import { IUserFieldCreateInput } from './interface/input/user-field-detail-create';
import { IUserFieldUpdateInput } from './interface/input/user-field-update';
import { IUserFieldDeleteInput } from './interface/input/user-field-delete';
import { IUserFilterInput } from './interface/input/user-filter';
import { IUserResetPassInput } from './interface/input/user-reset-pass';

export function* getUserList(args: IUserListInput) {
  try {
    let company = args.company;
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }
    const req: { data: { data: IUser[]; pagination: { total: number } } } =
      yield call(
        getRequest,
        `${company}/users?company=${company}${offset}&limit=${userListRecordLimit}&load=profiles`,
        {}
      );
    yield put(
      getUserListReturnAction({
        data: req.data.data as IUser[],
        userRecordTotalCount: req.data.pagination.total,
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(getUserListReturnErrorAction());
  }
}

export function* getActiveUserList(args: IUserListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/users?company=${company}&limit=0&active=true`,
      {}
    );
    yield put(
      getActiveUserListReturnAction({ data: req.data.data as IUser[] })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response || e);
    // nothing to do
  }
}

export function* getUserFieldList(args: UserFieldListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(
      postRequest,
      `${company}/users/fields?preloadItemsCount=0`,
      {}
    );
    const filter: IAxiosResponse = yield call(
      postRequest,
      `${company}/users/filterFields?ids=profile`,
      {}
    );

    yield put(
      getUserFieldListReturnAction({
        data: req.data.data as IField[],
        filterFields: filter.data.data,
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e);
    // do nothing for now
  }
}

export function* deleteUser(args: IUserDelete) {
  try {
    let company = args.company;
    let userId = args.id;
    yield call(
      deleteRequest,
      `${company}/users/${userId}?company=${company}`,
      {}
    );
    yield put(deleteUserReturnAction({ userId: userId }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    yield put(deleteUserErrorAction({ message: e.response.data.message }));
    // do nothing for now
  }
}

export function* createUser(args: IUserCreate) {
  try {
    let company = args.company;
    let data = args.data;
    const req: IAxiosResponse = yield call(
      postRequest,
      `${company}/users?company=${company}&load=profiles`,
      data
    );
    let userData = req.data.data as IUser;
    const user: IAxiosResponse = yield call(
      getRequest,
      `${company}/users/${userData.id}?company=${company}&load=profiles`,
      {}
    );
    yield put(createUserReturnAction({ ...user.data }));
  } catch (e) {
    console.log(e.response);
    yield put(setStatusCodeAction(e));
    yield put(
      createUserErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
    // do nothing for now
  }
}

export function* getUserFieldPermission(args: IUserFieldPermissionInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/userFieldPermissions?company=${company}`,
      {}
    );
    yield put(getUserFieldPermissionReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    // do nothing for now
  }
}

export function* getUserProfile(args: IUserProfileInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/users/profiles?company=${company}`,
      {}
    );
    yield put(getUserProfileReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    // do nothing for now
  }
}

export function* getUserDetail(args: IUserInput) {
  try {
    let company = args.company;
    let userId = args.userId;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/users/${userId}?company=${company}&load=profiles`,
      {}
    );
    yield put(getUserDetailReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    yield put(getUserDetailErrorAction());
  }
}

export function* updateUser(args: IUserUpdateInput) {
  try {
    const company = args.company;
    const userId = args.userId;
    const data = args.data;
    const req: IAxiosResponse = yield call(
      putRequest,
      `${company}/users/${userId}?company=${company}&load=profiles`,
      data
    );
    yield put(updateUserReturnAction({ ...req.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    yield put(
      updateUserErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* updateUserStatus(args: IUserUpdateStatusInput) {
  try {
    const userId = args.userId;
    const data = args.data;
    const company = args.company;
    const req: IAxiosResponse = yield call(
      putRequest,
      `${company}/users/${userId}?load=profiles`,
      data
    );
    yield put(updateUserStatusReturnAction({ ...req.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    console.log(e.response);
    yield put(
      updateUserStatusErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* getUserFieldDetail(args: IUserFieldInput) {
  try {
    let company = args.company;
    let fieldId = args.fieldId;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/userFields/${fieldId}`,
      {}
    );
    yield put(getUserFieldDetailReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      getUserFieldDetailErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* createUserField(args: IUserFieldCreateInput) {
  try {
    let company = args.company;
    let data = args.data;
    yield call(postRequest, `${company}/userFields?company=${company}`, data);
    yield put(createUserFieldReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      createUserFieldErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* updateUserField(args: IUserFieldUpdateInput) {
  try {
    let company = args.company;
    let data = args.data;
    let fieldId = args.fieldId;
    yield call(putRequest, `${company}/userFields/${fieldId}`, data);
    yield put(updateUserFieldReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      updateUserFieldErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* deleteUserField(args: IUserFieldDeleteInput) {
  try {
    const company = args.company;
    const fieldId = args.fieldId;
    yield call(deleteRequest, `${company}/userFields/${fieldId}`, {});
    yield put(deleteUserFieldReturnAction());
  } catch (e) {
    console.log(e.response);
    yield put(
      deleteUserFieldErrorAction({
        message: JSON.stringify(e.response.data.message),
      })
    );
  }
}

export function* filterUsers(args: IUserFilterInput) {
  const company = args.company;
  const search = args.search;
  const filterData = args.filterData;
  let offset = '';
  let globalSearch = '';
  if (args.offset !== undefined) {
    offset = `&offset=${args.offset}`;
  }
  if (args.search) {
    globalSearch = `globalSearch=${search}`;
  }
  try {
    const request: { data: { data: IUser[]; pagination: { total: number } } } =
      yield call(
        postRequest,
        `${company}/users/search?${globalSearch}${offset}&load=profiles&limit=${userListRecordLimit}`,
        filterData
      );
    yield put(
      filterUsersReturnAction({
        filteredData: request.data.data,
        userRecordTotalCount: request.data.pagination.total,
      })
    );
  } catch (e) {
    console.log(e.response);
    yield put(filterUsersErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* resetUserPassword(args: IUserResetPassInput) {
  try {
    let company = args.company;
    let data = args.data;
    let userId = args.userId;
    yield call(postRequest, `${company}/users/${userId}/setPassword`, data);
    yield put(resetUserPasswordReturnAction());
  } catch (e) {
    console.log(e.response);
    yield put(setStatusCodeAction(e));
    yield put(
      resetUserPasswordErrorAction(JSON.stringify(e.response.data.message))
    );
  }
}

export function* getDataTableList(args: any) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(
      getRequest,
      `${company}/databases?load=fields&limit=0`,
      {}
    );
    yield put(getDataTableListReturnAction({ ...req.data, error: '' }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(getDataTableListReturnAction(e));
  }
}

export function* userSagas() {
  yield takeLatest(GET_USER_LIST, getUserList);
  yield takeLatest(GET_ACTIVE_USER_LIST, getActiveUserList);
  yield takeLatest(GET_USER_FIELD_DETAIL, getUserFieldDetail);
  yield takeLatest(GET_USER_FIELD_LIST, getUserFieldList);
  yield takeLatest(DELETE_USER, deleteUser);
  yield takeLatest(GET_USER_FIELD_PERMISSION, getUserFieldPermission);
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(GET_USER_DETAIL, getUserDetail);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(UPDATE_USER_STATUS, updateUserStatus);
  yield takeLatest(CREATE_USER_FIELD, createUserField);
  yield takeLatest(UPDATE_USER_FIELD, updateUserField);
  yield takeLatest(DELETE_USER_FIELD, deleteUserField);
  yield takeLatest(FILTER_USERS, filterUsers);
  yield takeLatest(RESET_USER_PASSWORD, resetUserPassword);
  yield takeLatest(GET_DATATABLE_LIST, getDataTableList);
}

// All sagas to be loaded
export default userSagas;

import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { UploadedFile } from '../../../../../../../common/api-request/upload';
import { IComment } from '../../../interface/comment';
import CommentWidget from '../comment';
import { useSetCommentList } from '../hooks/use-set-comment-list';
import ChatAttachmentMessage from './attachments';
import ChatSystemMessage from './system';

export interface IChatWidgetMessage {
  commentList: IComment[];
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  deleteComment(id: string): void;
  deleteCommentAttachment(attachmentId: string, commentId: string): void;
  removeUploadedFile(file: UploadedFile): void;
  userColor: { [id: string]: string };
  uploadedFileList: UploadedFile[];
  removeFileAfterSave(): void;
  documentId: string;
  company: string;
  setSelectedId(value: string): void;
  selectedId: string;
  markCommentAsUnread(comment: IComment): void;
  lastReadMessage: IComment;
  module: string;
  sort: boolean;
}

const ChatWidgetMessage: React.FC<IChatWidgetMessage> = (props) => {

  const [errorImage, setErrorImage] = useState<string[]>([]);
  const [commentList, setCommentList] = useState([] as IComment[]);

  const addToErrorImage = (userId: string) => {
    const tempErrorImage = [...errorImage];
    if (errorImage.indexOf(userId) === -1) {
      tempErrorImage.push(userId);
      setErrorImage(tempErrorImage);
    }
  };

  useSetCommentList({
    commentList: props.commentList,
    setCommentList,
    sort: props.sort
  });

  return <>
    {commentList.map((comment: IComment) => {
      if (comment.type === 'history') {
        return <ChatSystemMessage
          comment={comment}
          key={uuid()}
          lastReadMessage={props.lastReadMessage}
          moduleType={props.module}
        />
      }
      if (comment.attachments !== null) {
        return <ChatAttachmentMessage
          key={uuid()}
          comment={comment}
          lastReadMessage={props.lastReadMessage}
          errorImage={errorImage}
          userColor={props.userColor}
          addToErrorImage={addToErrorImage}
          deleteCommentAttachment={props.deleteCommentAttachment}
        />
      }
      return (
        <CommentWidget
          documentId={props.documentId}
          removeFileAfterSave={props.removeFileAfterSave}
          removeUploadedFile={props.removeUploadedFile}
          uploadedFileList={props.uploadedFileList}
          userColor={props.userColor}
          errorImage={errorImage}
          addToErrorImage={addToErrorImage}
          deleteComment={props.deleteComment}
          key={uuid()}
          saveComment={props.saveComment}
          selectedId={props.selectedId}
          setSelectedId={props.setSelectedId}
          comment={comment}
          markCommentAsUnread={props.markCommentAsUnread}
          lastReadMessage={props.lastReadMessage}
          module={props.module}
        />
      );
    })}
  </>
};

export default ChatWidgetMessage;

import {
  CREATE_DATA_TABLE,
  CREATE_DATA_TABLE_RETURN,
  CREATE_DATA_TABLE_ERROR,
  GET_DATA_TABLE_LIST,
  GET_DATA_TABLE_LIST_RETURN,
  GET_DATA_TABLE_LIST_ERROR,
  RESET_DATA_TABLE_MESSAGE,
  DELETE_DATATABLE,
  DELETE_DATATABLE_CANCEL,
  DELETE_DATATABLE_CONFIRM,
  DELETE_DATATABLE_CONFIRM_RETURN,
  DELETE_DATATABLE_CONFIRM_ERROR,
  CLEAR_DELETE_DATATABLE_MESSAGE,
  GET_DATA_TABLE_FIELDS,
  GET_DATA_TABLE_FIELDS_ERROR,
  GET_DATA_TABLE_FIELDS_RETURN,
  GET_DATA_TABLE_DETAILS,
  GET_DATA_TABLE_DETAILS_RETURN,
  GET_DATA_TABLE_DETAILS_ERROR,
  CREATE_DATA_TABLE_RECORDS,
  CREATE_DATA_TABLE_RECORDS_RETURN,
  CREATE_DATA_TABLE_RECORDS_ERROR,
  GET_DATA_TABLE_RECORDS,
  GET_DATA_TABLE_RECORDS_RETURN,
  GET_DATA_TABLE_RECORDS_ERROR,
  UPDATE_DATA_TABLE,
  UPDATE_DATA_TABLE_RETURN,
  UPDATE_DATA_TABLE_ERROR,
  SET_RECORD_EDITABLE,
  DELETE_DATA_TABLE_FIELDS,
  DELETE_DATA_TABLE_FIELDS_RETURN,
  DELETE_DATA_TABLE_FIELDS_ERROR,
  UPDATE_DATA_TABLE_RECORD,
  UPDATE_DATA_TABLE_RECORD_RETURN,
  UPDATE_DATA_TABLE_RECORD_ERROR,
  DELETE_DATATABLE_RECORD,
  DELETE_DATATABLE_RECORD_CONFIRM,
  DELETE_DATATABLE_RECORD_CANCEL,
  DELETE_DATATABLE_RECORD_CONFIRM_RETURN,
  DELETE_DATATABLE_RECORD_CONFIRM_ERROR,
  CLEAR_DELETE_DATATABLE_RECORD_MESSAGE,
  GET_DATATABLE_DETAIL_PERMISSION_OPTION,
  GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN,
  CHECK_DATATABLE_RECORD,
  CHECK_DATATABLE_RECORD_RETURN,
  TRIGGER_FIELD_TO_UPDATE,
  TRIGGER_FIELD_TO_UPDATE_RETURN,
  SET_RECORD_EDITABLE_RETURN,
  RESET_RECORD_EDITABLE,
  FILTER_DATATABLE_RECORD,
  FILTER_DATATABLE_RECORD_RETURN,
  GET_FILTER_FIELD_DATATABLE_RECORD,
  GET_FILTER_FIELD_DATATABLE_RECORD_RETURN,
  SORT_DATATABLE_RECORD_FIELD_LIST,
  BATCH_UPDATE_DATATABLE_RECORD,
  BATCH_UPDATE_DATATABLE_RECORD_RETURN,
  BATCH_UPDATE_DATATABLE_RECORD_ERROR,
  RESET_DATATABLE_FIELDS,
  BATCH_UPLOAD_DATATABLE_RECORD_RETURN,
  SET_ENUM_ITEMS_FROM_RECORDS,
  RESET_DATATABLE_BATCH_CHANGE,
  REFRESH_DATATABLE_RECORDS,
  SET_RECORDS_GROUPED_CONFIG,
  SET_RECORDS_GROUPED_CONFIG_RETURN,
  GET_RECORDS_GROUPED_CONFIG,
  GET_RECORDS_GROUPED_CONFIG_RETURN,
  GET_RECORDS_GROUPED_KEYPATHS,
  GET_RECORDS_GROUPED_KEYPATHS_RETURN,
  DELETE_RECORDS_GROUPED_CONFIG
} from './constant';
import { IDatatableDeleteInput } from './interface/input/datatable-delete';
import { IDatatableConfirmDeleteDetailInput } from './interface/input/datatable-confirm-delete';
import { IDatatableDeleteResponse, IDatatableRecordDeleteResponse } from './interface/response/datatable-delete';

import { IDataTableListDetailInput } from './interface/input/data-table-list';
import { IDataTableListResponse } from './interface/response/data-table-list';
import { IDataTableDetailCreateInput, IDTDeleteRecordsGroupedInputDetails, IDTSetRecordsGroupedInputDetails } from './interface/input/data-table-create';
import { IDataTableFieldInput } from './interface/input/data-table-field';
import { IDataTableFieldResponse } from './interface/response/data-table-field';
import { IDataTableDetailsInput, IDTRecordsGroupedDetailInput } from './interface/input/data-table-detail';
import { IDataTableDetailsReturn, IDTRecordsGroupedKeypathReturn, IDTRecordsGroupedReturn } from './interface/response/data-table-details';
import { IDataTableRecordDetailCreateInput, IDataTableRecordDetailBatchInput } from './interface/input/data-table-record-create';
import { IDataTableRecordCreateResponse } from './interface/response/data-table-record-create';
import { IDataTableCreateResponse } from './interface/response/data-table-create';
import { IDataTableRecordsDetailsInput, IFilterDataTableRecordsDetailsInput, IGetFilterFieldDataTableRecordsDetailsInput } from './interface/input/data-table-records';
import { IDataTableRecordsResponse, IGetFilterFieldDataTableRecordResponse } from './interface/response/data-table-record';
import { IDataTableDetailUpdateInput } from './interface/input/data-table-update';
import { IDataTableUpdateResponse } from './interface/response/data-table-update';
import { IDataTableFieldDetailDeleteInput } from './interface/input/data-table-field-delete';
import { IDataTableRecordDetailUpdateInput, IFieldRecord } from './interface/input/data-table-record-update';
import { IDataTableRecordUpdateResponse } from './interface/response/data-table-record-update';
import { IDatatableRecordDeleteInput } from './interface/input/data-table-record.delete';
import { IDataTableRecordConfirmDeleteDetailInput } from './interface/input/data-table-record-confirm-delete';
import { IUserListDetailInput } from '../users/interface/input/user-list';
import { IReloadFieldDetailInput } from '../../../document-module/module/form/interface/input/field-trigger-reload';
import { IReloadFieldResponse } from '../../../document-module/module/form/interface/response/field-trigger-reload';
import { IDatatableRecord } from '../../../../common/custom-field-config/interface/datatable-record';

export const resetDataTableMessageAction = (payload: {}) => {
  return { type: RESET_DATA_TABLE_MESSAGE, ...payload }
}

export const getDataTableListAction = (payload: IDataTableListDetailInput) => {
  return { type: GET_DATA_TABLE_LIST, ...payload }
}

export const getDataTableListReturnAction = (payload: IDataTableListResponse) => {
  return { type: GET_DATA_TABLE_LIST_RETURN, ...payload }
}

export const getDataTableListErrorAction = (payload: {}) => {
  return { type: GET_DATA_TABLE_LIST_ERROR, ...payload }
}

export const createDataTableAction = (payload: IDataTableDetailCreateInput) => {
  return { type: CREATE_DATA_TABLE, ...payload }
}

export const createDataTableReturnAction = (payload: IDataTableCreateResponse) => {
  return { type: CREATE_DATA_TABLE_RETURN, ...payload }
}

export const createDataTableErrorAction = (payload: {}) => {
  return { type: CREATE_DATA_TABLE_ERROR, ...payload }
}

export const deleteDatatableAction = (payload: IDatatableDeleteInput) => {
  return { type: DELETE_DATATABLE, ...payload }
}

export const confirmDeleteDatatableAction = (payload: IDatatableConfirmDeleteDetailInput) => {
  return { type: DELETE_DATATABLE_CONFIRM, ...payload }
}

export const cancelDeleteDatatableAction = () => {
  return { type: DELETE_DATATABLE_CANCEL }
}

export const confirmDeleteDatatableReturnAction = (payload: IDatatableDeleteResponse) => {
  return { type: DELETE_DATATABLE_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteDatatableErrorAction = (message: string) => {
  return { type: DELETE_DATATABLE_CONFIRM_ERROR, message }
}

export const clearDeleteDatatableMessage = () => {
  return { type: CLEAR_DELETE_DATATABLE_MESSAGE }
}

export const getDataTableFieldsAction = (payload: IDataTableFieldInput) => {
  return { type: GET_DATA_TABLE_FIELDS, ...payload }
}

export const getDataTableFieldsReturnAction = (payload: IDataTableFieldResponse) => {
  return { type: GET_DATA_TABLE_FIELDS_RETURN, ...payload }
}

export const getDataTableFieldsErrorAction = (payload: {}) => {
  return { type: GET_DATA_TABLE_FIELDS_ERROR, ...payload }
}

export const getDataTableDetailsAction = (payload: IDataTableDetailsInput) => {
  return { type: GET_DATA_TABLE_DETAILS, ...payload }
}

export const getDataTableDetailsReturnAction = (payload: IDataTableDetailsReturn) => {
  return { type: GET_DATA_TABLE_DETAILS_RETURN, ...payload }
}

export const getDataTableDetailsErrorAction = (payload: {}) => {
  return { type: GET_DATA_TABLE_DETAILS_ERROR, ...payload }
}

export const createDataTableRecordsAction = (payload: IDataTableRecordDetailCreateInput) => {
  return { type: CREATE_DATA_TABLE_RECORDS, ...payload }
}

export const createDataTableRecordsReturnAction = (payload: IDataTableRecordCreateResponse) => {
  return { type: CREATE_DATA_TABLE_RECORDS_RETURN, ...payload }
}

export const createDataTableRecordsErrorAction = (payload: {}) => {
  return { type: CREATE_DATA_TABLE_RECORDS_ERROR, ...payload }
}

export const getDataTableRecordsAction = (payload: IDataTableRecordsDetailsInput) => {
  return { type: GET_DATA_TABLE_RECORDS, ...payload }
}

export const checkHasDatatableRecordAction = (payload: IDataTableRecordsDetailsInput) => {
  return { type: CHECK_DATATABLE_RECORD, ...payload }
}

export const getDataTableRecordsReturnAction = (payload: IDataTableRecordsResponse) => {
  return { type: GET_DATA_TABLE_RECORDS_RETURN, ...payload }
}

export const checkHasDatatableRecordReturnAction = (payload: IDataTableRecordsResponse) => {
  return { type: CHECK_DATATABLE_RECORD_RETURN, ...payload }
}

export const getDataTableRecordsErrorAction = (payload: {}) => {
  return { type: GET_DATA_TABLE_RECORDS_ERROR, ...payload }
}

export const updateDataTableAction = (payload: IDataTableDetailUpdateInput) => {
  return { type: UPDATE_DATA_TABLE, ...payload }
}

export const updateDataTableReturnAction = (payload: IDataTableUpdateResponse) => {
  return { type: UPDATE_DATA_TABLE_RETURN, ...payload }
}

export const updateDataTableErrorAction = (payload: {}) => {
  return { type: UPDATE_DATA_TABLE_ERROR, ...payload }
}

export const setDataTableRecordEditable = (payload: {}) => {
  return { type: SET_RECORD_EDITABLE, ...payload }
}

export const resetDataTableRecordEditable = () => {
  return { type: RESET_RECORD_EDITABLE }
}

export const setDataTableRecordEditableReturn = (payload: {}) => {
  return { type: SET_RECORD_EDITABLE_RETURN, ...payload }
}

export const deleteDataTableFieldsAction = (payload: IDataTableFieldDetailDeleteInput) => {
  return { type: DELETE_DATA_TABLE_FIELDS, ...payload }
}

export const deleteDataTableFieldsReturnAction = () => {
  return { type: DELETE_DATA_TABLE_FIELDS_RETURN }
}

export const deleteDataTableFieldsErrorAction = (payload: {}) => {
  return { type: DELETE_DATA_TABLE_FIELDS_ERROR, ...payload }
}

export const updateDataTableRecordAction = (payload: IDataTableRecordDetailUpdateInput) => {
  return { type: UPDATE_DATA_TABLE_RECORD, ...payload }
}

export const updateDataTableRecordReturnAction = (payload: IDataTableRecordUpdateResponse) => {
  return { type: UPDATE_DATA_TABLE_RECORD_RETURN, ...payload }
}

export const updateDataTableRecordErrorAction = (payload: {}) => {
  return { type: UPDATE_DATA_TABLE_RECORD_ERROR, ...payload }
}

export const deleteDataTableRecordAction = (payload: IDatatableRecordDeleteInput) => {
  return { type: DELETE_DATATABLE_RECORD, ...payload }
}

export const confirmDeleteDataTableRecordAction = (payload: IDataTableRecordConfirmDeleteDetailInput) => {
  return { type: DELETE_DATATABLE_RECORD_CONFIRM, ...payload }
}

export const cancelDeleteDataTableRecordAction = () => {
  return { type: DELETE_DATATABLE_RECORD_CANCEL }
}

export const confirmDeleteDataTableRecordReturnAction = (payload: IDatatableRecordDeleteResponse) => {
  return { type: DELETE_DATATABLE_RECORD_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteDataTableRecordErrorAction = (message: string) => {
  return { type: DELETE_DATATABLE_RECORD_CONFIRM_ERROR, message }
}

export const clearDeleteDataTableRecordMessage = () => {
  return { type: CLEAR_DELETE_DATATABLE_RECORD_MESSAGE }
}

export const getDatatableDetailPermissionOptionAction = (payload: IUserListDetailInput) => {
  return { type: GET_DATATABLE_DETAIL_PERMISSION_OPTION, ...payload }
}

export const getDatatableDetailPermissionOptionReturnAction = (payload: any) => {
  return { type: GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN, ...payload }
}

export const fieldTriggerUpdateAction = (data: IReloadFieldDetailInput) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE,
    ...data
  }
}

export const fieldTriggerUpdateResponseAction = (data: IReloadFieldResponse) => {
  return {
    type: TRIGGER_FIELD_TO_UPDATE_RETURN,
    ...data
  }
}

export const filterDatatableRecordAction = (data: IFilterDataTableRecordsDetailsInput) => {
  return {
    type: FILTER_DATATABLE_RECORD,
    ...data
  }
}

export const filterDatatableRecordReturnAction = (data: IDataTableRecordsResponse) => {
  return {
    type: FILTER_DATATABLE_RECORD_RETURN,
    ...data
  }
}

export const getfilterFieldDatatableRecordAction = (data: IGetFilterFieldDataTableRecordsDetailsInput) => {
  return {
    type: GET_FILTER_FIELD_DATATABLE_RECORD,
    ...data
  }
}

export const getfilterFieldDatatableRecordReturnAction = (data: IGetFilterFieldDataTableRecordResponse) => {
  return {
    type: GET_FILTER_FIELD_DATATABLE_RECORD_RETURN,
    ...data
  }
}

export const sortDatatableRecordListAction = (data: { dataTableRecordsList?: IDatatableRecord[], fieldId: string, ascendingValue: boolean }) => {
  return {
    type: SORT_DATATABLE_RECORD_FIELD_LIST,
    ...data
  }
}

export const batchUpdateDatatableRecordAction = (data: IDataTableRecordDetailBatchInput) => {
  return {
    type: BATCH_UPDATE_DATATABLE_RECORD,
    ...data
  }
}

export const batchUpdateDatatableRecordReturnAction = (payload: {
  dataTableRecordsList: IFieldRecord[];
  datatableRecordTotalCount?: number;
}) => {
  return {
    type: BATCH_UPDATE_DATATABLE_RECORD_RETURN,
    ...payload
  }
}

export const batchUploadDatatableRecordReturnAction = () => {
  return {
    type: BATCH_UPLOAD_DATATABLE_RECORD_RETURN
  }
}

export const batchUpdateDatatableRecordErrorAction = (message: string) => {
  return { type: BATCH_UPDATE_DATATABLE_RECORD_ERROR, createDataTableRecordErrorMessage: message }
}

export const resetDatatableFields = () => {
  return { type: RESET_DATATABLE_FIELDS }
}

export const setEnumItemsFromRecordsAction = () => {
  return { type: SET_ENUM_ITEMS_FROM_RECORDS };
}

export const resetDatatableBatchChangesAction = () => {
  return { type: RESET_DATATABLE_BATCH_CHANGE };
}

export const refreshDatatableRecordsAction = () => {
  return { type: REFRESH_DATATABLE_RECORDS };
}

export const setRecordsGroupedConfigAction = (payload: IDTSetRecordsGroupedInputDetails) => {
  return { type: SET_RECORDS_GROUPED_CONFIG, ...payload };
}

export const deleteRecordsGroupedConfigAction = (payload: IDTDeleteRecordsGroupedInputDetails) => {
  return { type: DELETE_RECORDS_GROUPED_CONFIG, ...payload };
}

export const setRecordsGroupedConfigReturnAction = (payload: IDTRecordsGroupedReturn) => {
  return { type: SET_RECORDS_GROUPED_CONFIG_RETURN, ...payload };
}

export const getRecordsGroupedConfigAction = (payload: IDTRecordsGroupedDetailInput) => {
  return { type: GET_RECORDS_GROUPED_CONFIG, ...payload };
}

export const getRecordsGroupedConfigReturnAction = (payload: IDTRecordsGroupedReturn) => {
  return { type: GET_RECORDS_GROUPED_CONFIG_RETURN, ...payload };
}

export const getRecordsGroupedKeypathAction = (payload: IDTRecordsGroupedDetailInput) => {
  return { type: GET_RECORDS_GROUPED_KEYPATHS, ...payload };
}

export const getRecordsGroupedKeypathReturnAction = (payload: IDTRecordsGroupedKeypathReturn) => {
  return { type: GET_RECORDS_GROUPED_KEYPATHS_RETURN, ...payload };
} 
import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
import { formatEnumValidationSchema } from '../../component/object/type/enum/util/format-validation-schema';

export class EnumValidator implements ValidationType {

  name = 'enum';

  validate(field: FieldElement, value: string, actionType?: string) {
    if (
      (
        (value === '' || value === '[]' || !value || !value.length)
        && (field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required && actionType === 'save'))
      ) || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden
    ) {
      return [];
    }
    let currentValue: null | string | string[] = null;
    if (field.inputConfig.config.multiselect) {
      const tempValue: any = value;
      currentValue = tempValue && tempValue.length === 0 ? null : value;
    } else {
      currentValue = (value === '' || value === 'null') ? '' : value;
    }
    const schema = actionType === 'dynamic' ? formatEnumValidationSchema(field) : field.validationSchema;
    const ajv = new Ajv();
    const validationSchema = { properties: { [field.id]: { ...schema } } };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: currentValue })
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}


import React from 'react';

interface ISwitchComponent {
  label: string;
  value: boolean;
  onChange: Function;
}

const SwitchComponent: React.FC<ISwitchComponent> = ({ label = '', value, onChange }) => {

  return (
    <div id="switch-component">
      <div className="ui fitted toggle checkbox" onClick={() => onChange()}>
        <input className="hidden"
          type="checkbox"
          checked={value}
          onChange={() => { }}
        />
        <label>{label.toUpperCase()}</label>
      </div>
    </div>
  )
}

export default SwitchComponent;
import React from 'react';
import { IRender } from '.';
import { IField } from '../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../field/component/field-element';

class FxFieldValue {

  name = 'fx';

  Render: React.FC<IRender> = (props) => {
    const tempField: IField = {
      id: props.valueId,
      inputConfig: {
        type: 'number',
        config: {
          alignment: "left",
          decimals: props.field.config ? props.field.config.decimals : 0,
          thousandSeparator: props.field.config ? props.field.config.thousandSeparator : false
        },
      },
      label: props.field.label,
    };
    const field = FieldElement.getDataElement(tempField);
    const Element = field.getFieldElement();
    return (
      <div>
        <Element
          {...field}
          forwardedRef={props.forwardedRef}
          defaultValueElement={true}
          throwValueOutside={props.throwValueOutside}
        />
      </div>
    );
  }
}

export default FxFieldValue;

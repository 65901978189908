import React from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import LineType from './line/index';

const StringType: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <>String Array</>
  }
  return <LineType {...props} />;
}

export default StringType;
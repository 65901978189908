import React from 'react';
import { IHasPageChangeRef } from '../../main/interface/has-page-change';
import PortalBPRoute from './business-process/route';
import Stakeholders from './stakeholders';
import Users from './users';

const ModuleList = [
  new Stakeholders(),
  new Users(),
  new PortalBPRoute()
]

interface IPortalModuleRegistry {
  name: string;
  render: React.FC<any>;
}

interface IRender {
  type: string;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

export class PortalModuleRegistry {

  registry: { [name: string]: IPortalModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const portalModule = new PortalModuleRegistry();
    const Render = portalModule.getModule(props.type);
    return <Render {...props} />
  }

}
import {
  EnumExclusiveMode,
  EnumSelectAllMode,
  IEnumLocalFilter,
  IField,
  IFieldAccessType,
  ISortEnumConfig
} from '../../../component/admin-module/module/users/interface/field';
import { IDatePropertiesMinMax } from '../../custom-field-config/custom-field-type/date-config';
import { IFileGenerationContext } from '../../custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../type/interface/field-type-object';
import { elementList } from './element';
import NotFound from './object/not-found';
import { EnumItemsDataSource } from "../../../component/document-module/module/form/automation/components/action/filter-enum/enum-items-data-source";
import { elementComponentList } from '.';

export interface IConfig {
  format: 'none' | 'email' | 'url' | 'phone'
  displayType: 'line' | 'paragraph' | 'dropdown' | 'checklist';
  custom: boolean;
  multiselect: boolean;
  items: [],
  dataType: 'static' | 'database' | 'document';
  alignment: '' | 'left' | 'center' | 'right'
  thousandSeparator: boolean;
  decimals: number;
  data: []
  labelFormat: string;
  tableType: 'vertical' | 'horizontal' | 'fixed';
  minRows: number;
  maxRows: number;
  columns: IField[];
  value: any;
  maxItems: number;
  enableCameraInput: boolean;
  source?: {
    databaseId?: string;
    formId?: string;
  },
  phoneCountryCode?: string;
  /** Enum reference field source, that can be used to fetch items directly */
  dataSource?: EnumItemsDataSource;
  min?: IDatePropertiesMinMax;
  max?: IDatePropertiesMinMax;
  filterEnumOptions?: IEnumLocalFilter;
  case?: 'uppercase' | 'lowercase' | 'case-sensitive';
  showCreateBpOrDt?: boolean;
  systemGenerated?: boolean;
  generationContext?: IFileGenerationContext;
  type?: string;
  from?: IConfig;
  to?: IConfig;
  percent?: boolean;
  noWeekends?: boolean;
  sort?: ISortEnumConfig;
  selectAllMode?: EnumSelectAllMode;
  exclusiveMode?: EnumExclusiveMode;
}

export interface IInputConfig {
  type: string;
  config: IConfig;
  maxItems?: number;
}

export interface IValidationSchema {
  type: string;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  maximum?: number;
  minimum?: number;
}

export class FieldElement {
  id!: string;
  label!: string;
  inputConfig!: IInputConfig;
  accessType!: IFieldAccessType;
  hint!: string;
  configName!: IConfigName;
  validationSchema!: IValidationSchema;
  reloadOnChange!: string[];
  requiresFieldData!: string[];
  flags?: string[];
  multilineIndex?: number;

  static getDataElement(config: IField): FieldElement
  static getDataElement(config: IField[]): FieldElement[]
  static getDataElement(config: IField | IField[]): FieldElement | FieldElement[] {
    if (config instanceof Array) {
      return config.map(conf => {
        const element = new FieldElement();
        Object.assign(element, conf);
        return element;
      });
    } else {
      const element = new FieldElement();
      Object.assign(element, config);
      return element;
    }
  }

  getFieldElement = () => {
    return elementList[this.inputConfig.type] || NotFound;
  }

  getFieldId = () => {
    return this.id;
  }

  getFieldComponent = () => {
    return elementComponentList[this.inputConfig.type] || NotFound;
  }

  setConfigValue = (fieldRefs: any) => {
    if (this.accessType === IFieldAccessType.Readonly && this.inputConfig.config && this.inputConfig.config.hasOwnProperty('value')) {
      if (!fieldRefs.current[this.id]) {
        fieldRefs.current[this.id] = {};
        if (this.inputConfig.type === 'table') {
          fieldRefs.current[this.id] = [];
        }
      }
      let data = this.inputConfig.config.value;

      if (
        this.inputConfig.type === 'enum'
        && this.inputConfig.config.value
        && typeof this.inputConfig.config.value === 'object'
      ) {
        if (this.inputConfig.config.value instanceof Array) {
          data = JSON.stringify(
            this.inputConfig.config.value.map((fd: { id: string, label: string }) => fd.id)
          );
        } else {
          data = this.inputConfig.config.value.id;
        }
      }
      if (this.inputConfig.type !== 'number') {
        data = data ? data : '';
      }
      if (this.inputConfig.type === 'table') {
        const tableRef = fieldRefs.current[this.id];
        if (tableRef instanceof Array) {
          const tableData = tableRef.map((ref, index) => {
            if (ref.current) {
              const columnKeys = Object.keys(ref.current);
              columnKeys.forEach(key => {
                const getTableColumnDetails = this.inputConfig.config.columns.find((column: IField) => column.id === key)
                let tableColumnValue = data[key] && !!data[key][index] ? data[key][index] : '';
                if (getTableColumnDetails?.inputConfig?.type === 'enum' && tableColumnValue !== '') {
                  if (tableColumnValue instanceof Object) {
                    if (tableColumnValue instanceof Array) {
                      tableColumnValue = tableColumnValue.map((fd: { id: string, label: string }) => fd.id);
                    } else if (tableColumnValue.hasOwnProperty('id')) {
                      tableColumnValue = tableColumnValue.id
                    }
                  }
                }
                if (data[key] && data[key] instanceof Array) {
                  ref.current[key].value = tableColumnValue;
                }
              });
            }
            return ref;
          });
          fieldRefs.current[this.id] = tableData;
        }
      } else {
        fieldRefs.current[this.id].value = data;
      }
    }
    if (this.accessType === IFieldAccessType.ReadWrite && this.configName === IConfigName.Autopopulated) {
      if (!fieldRefs.current[this.id]) {
        fieldRefs.current[this.id] = {};
      }
      let data = this.inputConfig.config.value;
      fieldRefs.current[this.id].value = data;
    }
  }

  getConfigValue = () => {
    return this.inputConfig.config.value ? this.inputConfig.config.value : '';
  }
}

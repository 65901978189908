import { useState } from 'react';
import { getRequest } from '../../../../../../../../../../../common/api-request';
import { IAutomationInputFieldList } from '../../../reducer';

interface IProps {
  inputFieldList: IAutomationInputFieldList;
  company: string;
}

export const useGetInputFieldOption = (props: IProps) => {
  const [detailInputFieldList, setDetailInputFieldList] = useState<IAutomationInputFieldList>({});

  const getInputField = async (formId: string, statusId?: string) => {
    try {
      const status = statusId ? `&statusId=${statusId}` : '';
      const response = await getRequest(`${props.company}/documents/inputForm?formId=${formId}${status}`, {});
      const tempInputFieldList = { ...detailInputFieldList };
      tempInputFieldList[formId] = response.data.data.fields;
      setDetailInputFieldList(tempInputFieldList);
    } catch (e) {
      console.log(e);
    }
  }

  return { getInputField, detailInputFieldList };
}
import { IFormStatusListDetailInput } from './interface/input/form-status-list';
import { GET_FORM_STATUS_LIST, GET_FORM_STATUS_LIST_ERROR, GET_FORM_STATUS_LIST_RETURN, SAVE_FORM_STATUS, GET_FORM_ACTION_LIST, GET_FORM_ACTION_LIST_RETURN, GET_FORM_ACTION_LIST_ERROR, SAVE_FORM_STATUS_RETURN, SAVE_FORM_STATUS_ERROR, PUBLISH_WORKFLOW, PUBLISH_WORKFLOW_RETURN, PUBLISH_WORKFLOW_ERROR } from './constant';
import { IFormStatusListResponse } from './interface/response/form-status-list';
import { IFormStatusSaveDetailInput, IFormWorkflowPublishDetailInput } from './interface/input/form-status-save';
import { IFormActionListDetailInput } from './interface/input/form-action-list';
import { IFormActionListResponse } from './interface/response/form-action-list';
import { IFormStatusSaveResponse } from './interface/response/form-status-save';

export const getFormStatusListAction = (payload: IFormStatusListDetailInput) => {
  return { type: GET_FORM_STATUS_LIST, ...payload };
}

export const getFormStatusListReturnAction = (payload: IFormStatusListResponse) => {
  return { type: GET_FORM_STATUS_LIST_RETURN, ...payload };
}

export const getFormStatusListErrorAction = (message: string) => {
  return { type: GET_FORM_STATUS_LIST_ERROR, message };
}

export const saveFormStatusAction = (payload: IFormStatusSaveDetailInput) => {
  return { type: SAVE_FORM_STATUS, ...payload };
}

export const saveFormStatusReturnAction = (payload: IFormStatusSaveResponse) => {
  return { type: SAVE_FORM_STATUS_RETURN, ...payload };
}

export const saveFormStatusErrorAction = (message: string) => {
  return { type: SAVE_FORM_STATUS_ERROR, formSaveErrorMessage: message };
}

export const getFormActionListAction = (payload: IFormActionListDetailInput) => {
  return { type: GET_FORM_ACTION_LIST, ...payload };
}

export const getFormActionListReturnAction = (payload: IFormActionListResponse) => {
  return { type: GET_FORM_ACTION_LIST_RETURN, ...payload };
}

export const getFormActionListErrorAction = () => {
  return { type: GET_FORM_ACTION_LIST_ERROR };
}

export const publishWorkflowAction = (payload: IFormWorkflowPublishDetailInput) => {
  return { type: PUBLISH_WORKFLOW, ...payload };
}

export const publishWorkflowReturnAction = () => {
  return { type: PUBLISH_WORKFLOW_RETURN };
}

export const publishWorkflowErrorAction = (message: string) => {
  return { type: PUBLISH_WORKFLOW_ERROR, formSaveErrorMessage: message };
}
import React, { useState } from 'react';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import Dropbox from '../custom-filter/configuration/drop-box';
import { ICustomFilterContainer, ICustomFilterField } from '../custom-filter/interface/custom-filter-field';
import { Dropdown } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../../../../../../common/general/dropdown-popper-option';
import ConfigurationModal from '../custom-filter/configuration/configuration-modal';
import SideView from './side-view';
import { IDragItem } from '../custom-filter/custom-filter';
import { AlertModal } from '../../../../../../../../common/general/alert-modal';
import { IColumnView } from '../document-list/interface/custom-view';
import { ITaskView, ITaskViewConfig } from './interface/task-view';
import { IDropdownOption } from '../../../../../../../../common/field/component/object/enum-type/checklist';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import TaskViewAggregateModal from './aggregate-modal';
import SelectedFieldContainer from './column-header/selected-field-container';
import { useSetTaskViewConfigState } from './hooks/use-set-task-view-config-state';

interface ITaskViewConfigProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  selectedStatus: string;
  setSelectedStatus(value: string): void;
  selectedViewConfig: ITaskViewConfig;
  defaultTaskConfig: ITaskViewConfig;
  statusOption: IDropdownOption[];
  updateStatusConfig(status: string, config: ITaskViewConfig): void;
  taskView: React.MutableRefObject<ITaskView>;
  isUsedAsReferer: boolean;
  formFieldList: ICustomFieldDetail[];
  configRef: React.MutableRefObject<ITaskViewConfig>;
}

const TaskViewConfig: React.FC<ITaskViewConfigProps> = (props) => {

  const [selectedFieldFilter, setSelectedFieldFilter] = useState(null as number | null);
  const [rangeFieldErrorMessage, setRangeFieldErrorMessage] = useState('');
  const [currentSideView, setCurrentSideView] = useState<'column' | 'filter'>('column');
  const [selectedColumnHeader, setSelectedColumnHeader] = useState<IColumnView | undefined>(undefined);

  const {
    customViewList,
    setCustomViewList,
    customFilterFieldList,
    setCustomFilterFieldList,
    selectedReferStatus,
    setSelectedReferStatus
  } = useSetTaskViewConfigState({ ...props });

  const isAllowedFieldInsert = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropAreaItem: any, dropIndex: number): boolean => {
    let isAllowedFieldInsert = true;
    if (dragItem.new && dropAreaItem !== undefined) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't insert field if there is already existing field.`);
    }

    if (dragItem.itemType === 'field-range') {
      if ((dropIndex === 3)) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
      if (newCustomFilterFieldList[(dropIndex + 1).toString()] !== undefined && dragItem.new) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
    }

    const isMoveSingleItemDoesNotFullfillRangeSlot = newCustomFilterFieldList[dropIndex.toString()]
      && newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range'
      && dragItem.itemType !== 'field-range'
      && dragItem.itemIndex !== undefined
      && (newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] || dragItem.itemIndex === 3)
      && !dragItem.new;

    if (isMoveSingleItemDoesNotFullfillRangeSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }
    const isMoveRangeDoesNotFullfillDropAreaSlot = (!newCustomFilterFieldList[dropIndex.toString()]
      || (newCustomFilterFieldList[dropIndex.toString()]
        && newCustomFilterFieldList[dropIndex.toString()].itemType !== 'field-range'))
      && dragItem.itemType === 'field-range'
      && newCustomFilterFieldList[(dropIndex + 1).toString()]
      && dragItem.itemIndex !== (dropIndex + 1)
      && !dragItem.new;

    if (isMoveRangeDoesNotFullfillDropAreaSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }

    return isAllowedFieldInsert;
  }

  const cleanDragItemSlot = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropIndex: number): { [x: string]: any } => {
    if (!dragItem.new && dragItem.itemIndex !== undefined) { // move drop area ite to drag item position
      if (newCustomFilterFieldList[dropIndex.toString()]) { // with exiting field in drop area
        if (newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range') { // for drop area field-range
          if (dragItem.itemType === 'field-range') { // for drag item field-range and drop area with field-range
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          } else {
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
            newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = 'contained';
            newCustomFilterFieldList[(dropIndex + 1).toString()] = undefined;
          }
        } else {
          if (dragItem.itemType === 'field-range') { // for drag item field-range but drop area with single field
            if (dragItem.itemIndex === (dropIndex + 1)) { // drag field range and position is of field is on left side
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = newCustomFilterFieldList[dropIndex.toString()];
            } else {
              newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
            }
          } else { // drag item field range and no item on drop area
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          }
        }
      } else {
        if (dragItem.itemType === 'field-range') { // drag item field range and no item on drop area
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
          newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
        } else {
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
        }
      }
    }

    props.hasPageChangeRef.current.hasChange = true;
    return newCustomFilterFieldList;
  }

  const insertDropField = (dragItem: IDragItem, dropIndex: number, dropAreaItem: any) => {
    let newCustomFilterFieldList = { ...customFilterFieldList };
    if (isAllowedFieldInsert(dragItem, newCustomFilterFieldList, dropAreaItem, dropIndex)) {
      newCustomFilterFieldList = cleanDragItemSlot(dragItem, newCustomFilterFieldList, dropIndex);

      if (dragItem.itemType === 'field-range') {
        newCustomFilterFieldList[(dropIndex + 1).toString()] = 'contained';
      }

      newCustomFilterFieldList[dropIndex.toString()] = dragItem.item;
      setCustomFilterFieldList(newCustomFilterFieldList)
      props.configRef.current.filterField = { ...newCustomFilterFieldList };
      props.hasPageChangeRef.current.hasChange = true;
      setSelectedReferStatus('');
    }
  }

  const addCustomViewList = (dragItem: { data: IColumnView }) => {
    const newColumnView = [...customViewList];
    let dragField = { ...dragItem.data };
    dragField.new = false;
    newColumnView.push(dragField);
    setCustomViewList(newColumnView);
    props.configRef.current.columnHeader = [...newColumnView];
    props.hasPageChangeRef.current.hasChange = true;
    setSelectedReferStatus('');
  }

  const arrangeSequence = (dragItem: { data: IColumnView }, dropItem: IColumnView) => {
    let newColumnView = [...customViewList];
    const dragItemIndex = newColumnView.indexOf(dragItem.data);
    const dropItemIndex = newColumnView.indexOf(dropItem);
    newColumnView.splice(dragItemIndex, 1);
    newColumnView.splice(dropItemIndex, 0, dragItem.data);
    setCustomViewList(newColumnView);
    props.configRef.current.columnHeader = [...newColumnView];
    props.hasPageChangeRef.current.hasChange = true;
    setSelectedReferStatus('');
  }

  const manageSelectedReferToStatus = (id: string) => {
    setSelectedReferStatus(id);

    props.configRef.current = props.taskView.current[id] ? { ...props.taskView.current[id] } : { ...props.defaultTaskConfig };
    props.configRef.current.referToStatus = id;
    setCustomViewList(props.taskView.current[id]?.columnHeader || []);
    setCustomFilterFieldList(props.taskView.current[id]?.filterField || {});
  }

  const saveConfiguration = (filterIndex: number, selectedFilter: ICustomFilterField) => {
    const tempCustomFilterField = { ...customFilterFieldList };
    tempCustomFilterField[filterIndex.toString()] = selectedFilter;
    setCustomFilterFieldList(tempCustomFilterField);
    props.configRef.current.filterField = tempCustomFilterField;
    setSelectedFieldFilter(null);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const manageSelectedColumnHeaderField = (field: IColumnView) => {
    setSelectedColumnHeader(field);
  }

  const updateFieldAgg = (field: IColumnView) => {
    let newColumnView = [...customViewList];
    const selectedColView = newColumnView.find((e: IColumnView) => e.fieldName === field.fieldName);
    if (selectedColView) {
      const index = newColumnView.indexOf(selectedColView);
      newColumnView[index] = field;
      setCustomViewList(newColumnView);
      props.configRef.current.columnHeader = [...newColumnView];
      props.hasPageChangeRef.current.hasChange = true;
      setSelectedColumnHeader(undefined);
    }
  }

  const getFieldList = (): ICustomFieldDetail[] => {
    const field: ICustomFieldDetail[] = [];

    props.formFieldList.forEach((e: ICustomFieldDetail) => {
      if (customViewList.find((f: IColumnView) => f.fieldName === e.id)) {
        field.push(e);
      }
    })

    return field;
  }

  const removeSelectedField = (field: IColumnView) => {
    let newColumnView = [...customViewList];
    const currentField = newColumnView.find((e: IColumnView) => e.fieldName === field.fieldName);
    if (currentField) {
      const index = newColumnView.indexOf(currentField);
      newColumnView.splice(index, 1);
      props.configRef.current.columnHeader = newColumnView;
      setCustomViewList(newColumnView);
    }
  }

  const getReferToStatusOption = (): IDropdownOption[] => {
    const option: IDropdownOption[] = [];
    props.statusOption.forEach((e: IDropdownOption) => {
      if (
        e.value !== props.selectedStatus
        && (!props.taskView.current[e.value]
          || (props.taskView.current[e.value] && !props.taskView.current[e.value].referToStatus))
      ) {
        option.push(e);
      }
    })
    return option;
  }

  const deleteFilter = (index: number, itemType: string) => {
    const newCustomFilterFieldList = { ...customFilterFieldList };
    if (itemType === 'field-range') {
      newCustomFilterFieldList[(index + 1).toString()] = undefined;
    }
    newCustomFilterFieldList[index.toString()] = undefined;
    setCustomFilterFieldList(newCustomFilterFieldList)
    props.configRef.current.filterField = newCustomFilterFieldList;
    props.hasPageChangeRef.current.hasChange = true;
  }

  return (
    <div className={`task-view-config`}>
      <div className='main-view'>
        <Dropdown
          popperContainer={DropdownPopperOption}
          selection
          value={props.selectedStatus}
          placeholder='Status'
          options={props.statusOption}
          search
          clearable
          selectOnBlur={false}
          onChange={(event, target: any) => props.setSelectedStatus(target.value)}
        />
        {props.selectedStatus !== '' &&
          <>
            <div className='refer-to-status-container'>
              <span>Refer to status</span>
              <Dropdown
                popperContainer={DropdownPopperOption}
                selection
                value={selectedReferStatus}
                options={getReferToStatusOption()}
                search
                clearable
                disabled={props.isUsedAsReferer}
                selectOnBlur={false}
                onChange={(event, target: any) => manageSelectedReferToStatus(target.value)}
              />
            </div>
            <hr />
            {currentSideView === 'filter' &&
              <>
                <h5>Filter</h5>
                <div className={`filter-container`}>
                  <div className={`dropbox-container`}>
                    <Dropbox
                      itemIndex={0}
                      withItem={customFilterFieldList['0']}
                      insertDropField={insertDropField}
                      deleteFilter={deleteFilter}
                      editConfiguration={setSelectedFieldFilter}
                    />
                    <Dropbox
                      itemIndex={1}
                      withItem={customFilterFieldList['1']}
                      insertDropField={insertDropField}
                      deleteFilter={deleteFilter}
                      editConfiguration={setSelectedFieldFilter}
                    />
                    <Dropbox
                      itemIndex={2}
                      withItem={customFilterFieldList['2']}
                      insertDropField={insertDropField}
                      deleteFilter={deleteFilter}
                      editConfiguration={setSelectedFieldFilter}
                    />
                    <Dropbox
                      itemIndex={3}
                      withItem={customFilterFieldList['3']}
                      insertDropField={insertDropField}
                      deleteFilter={deleteFilter}
                      editConfiguration={setSelectedFieldFilter}
                    />
                  </div>
                </div>
              </>
            }
            {currentSideView === 'column' &&
              <>
                <h5>Column Header</h5>
                <SelectedFieldContainer
                  customViewList={customViewList}
                  addCustomViewList={addCustomViewList}
                  arrangeSequence={arrangeSequence}
                  onClick={manageSelectedColumnHeaderField}
                  deleteColumn={removeSelectedField}
                />
              </>
            }
          </>
        }
      </div>
      {props.selectedStatus !== '' &&
        <SideView
          currentSideView={currentSideView}
          setCurrentSideView={setCurrentSideView}
          fieldList={props.formFieldList}
          customViewList={customViewList}
          removeSelectedField={removeSelectedField}
        />
      }

      <ConfigurationModal
        cancel={() => setSelectedFieldFilter(null)}
        selectedFieldFilter={selectedFieldFilter}
        customFilterFieldList={customFilterFieldList}
        saveConfiguration={saveConfiguration}
        fieldList={getFieldList()}
      />

      <AlertModal
        open={rangeFieldErrorMessage !== ''}
        close={() => setRangeFieldErrorMessage('')}
        message={rangeFieldErrorMessage}
        type='error'
      />

      <TaskViewAggregateModal
        cancel={() => setSelectedColumnHeader(undefined)}
        open={selectedColumnHeader !== undefined}
        save={updateFieldAgg}
        field={selectedColumnHeader}
        fieldList={getFieldList()}
      />
    </div>
  )
}

export default TaskViewConfig;
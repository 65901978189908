import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { DetailActionButton } from '../../../action-button';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { Dropdown } from 'semantic-ui-react-bpm';
import { IActionTypeRegistryRender } from '../registry';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { v4 as uuid } from 'uuid';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';

export interface IGeneratePdf {
  id: string;
  selectedField: string
  selectedOperator: string;
  value: string;
  new?: boolean;
  deleted?: boolean;
}

class GeneratePdf {

  name = 'generate-pdf';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const { formFieldList } = useSelector((state: IRootState) => state.automation);

    const [selectedField, setSelectedField] = useState();

    const getFromFieldOption = (): IDropdownOption[] => {
      return formFieldList
        .filter((field: ICustomFieldDetail) => field.type === 'file' && field.config?.generationContext?.isStored)
        .map((field: ICustomFieldDetail) => {
          return {
            key: uuid(),
            value: field.id || '',
            text: field.label || ''
          }
        });
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.fieldId = selectedField;
        console.log(`tempComponent ${tempComponent}`)
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    const refreshComponentToChangeButton = () => {
      if (props.hasComponentChange.current === false) {
        props.parentRefresher();
      }
    }

    useEffect(() => {
      if (props.selectedComponent) {
        const fieldId = props.selectedComponent.config.fieldId;
        setSelectedField(fieldId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
      <div className={`automation-form-detail-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Auto-generate PDF`}
          description={`Generates PDF automatically when a dynamic action is executed.`}
          iconHeight={20}
          iconWidth={20}
          iconName='generatePdf'
        />
        <div className={`automation-field-container`}>
          <div className='automation-field'>
            <label>Choose field field to auto generate<span className={`required`}>*</span></label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              clearable
              search
              multiple={false}
              value={selectedField}
              selectOnBlur={false}
              options={getFromFieldOption()}
              onChange={(event, target: any) => {
                setSelectedField(target.value);
                refreshComponentToChangeButton();
                props.hasComponentChange.current = true;
              }}
            />
          </div>
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    )
  }
}

export default GeneratePdf;
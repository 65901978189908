import { AggregateFunction } from '../fx.agg-func';

export class CountAggregateFunction implements AggregateFunction {

  name = 'count';

  aggregate(values: number[]): number {
    return values.reduce((count, curr) => {
      if (curr !== undefined && curr !== null) {
        count += 1;
      }
      return count;
    }, 0);
  }

}
import { ClassType } from 'class-transformer/ClassTransformer';
import { functionList } from './functions';
import { AggregateFunction } from './fx.agg-func';


export class AggregateService {

  static aggregateFunctionClasses: { type: ClassType<any>; name: string }[];

  static registry: { [name: string]: AggregateFunction };

  static register(triggerType: ClassType<any>, name: string): void {
    this.aggregateFunctionClasses.push({ type: triggerType, name });
  }

  static availableFunctions(): string[] {
    return this.aggregateFunctionClasses.map(s => s.name);
  }

  static aggregate(values: number[], funcName: string): number {
    this.setupRegistryIfNeeded();
    const aggFunc = this.registry[funcName];
    if (!aggFunc) {
      throw new Error(`Unable to find aggregate function "${funcName}"`);
    }
    return aggFunc.aggregate(values);
  }

  private static setupRegistryIfNeeded(): void {
    if (!this.registry) {
      this.registry = {};
      const types = functionList;
      for (let type of types) {
        this.registry[type.name] = type;
      }
    }
  }



}
import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IApiColumnView, IColumnView } from '../interface/custom-view';
import { systemFieldList, fieldIconBaseOnConfigName } from '../constant';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';


interface IProps {
  columnViewList: IApiColumnView[];
  fieldList: ICustomFieldDetail[];
}

export const useSetColumnViewList = (props: IProps) => {
  const { columnViewList, fieldList } = { ...props };

  const [customViewList, setCustomViewList] = useState([] as IColumnView[]);

  useEffect(() => {
    const newColumnView: IColumnView[] = [];
    if (columnViewList.length > 0 && fieldList.length > 0) {
      columnViewList.length > 0 && columnViewList.forEach((apiColumnView: IApiColumnView) => {
        const type = apiColumnView.keyPath.indexOf('fields.') > -1 ? 'field' : 'system';

        let fieldName = apiColumnView.keyPath;
        if (type === 'field') {
          fieldName = apiColumnView.keyPath.split('.')[1];
          const data = fieldList.filter((field: ICustomFieldDetail) => field.id === fieldName);
          if (data.length > 0 && data[0]) {
            console.log("Log ~ data", data);
            newColumnView.push({
              type: type,
              label: data[0].label || '',
              fieldName: fieldName,
              icon: fieldIconBaseOnConfigName[data[0].configName || IConfigName.TextString].icon,
              iconStyle: fieldIconBaseOnConfigName[data[0].configName || IConfigName.TextString].iconStyle,
              color: '',
              new: false,
            });
          }
        } else {
          const data = systemFieldList.filter((columnView: IColumnView) => columnView.fieldName === fieldName);
          if (data.length > 0 && data[0]) {
            newColumnView.push({
              type: type,
              label: data[0].label,
              fieldName: fieldName,
              icon: data[0].icon,
              iconStyle: data[0].iconStyle,
              color: data[0].color,
              new: false,
            });
          }
        }
      });
    }
    setCustomViewList(newColumnView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columnViewList), JSON.stringify(fieldList)]);

  return { ...props, customViewList, setCustomViewList };
}
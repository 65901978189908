import { useEffect } from 'react';
import { getFormStatusListAction } from '../../../../workflow/action';
import {
  getFormFieldsAction,
  getDuplicateCheckerAction
} from '../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetDuplicateCheckerData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (formId && company) {
      dispatch(getFormFieldsAction({ companyId: company, formId: formId }));
      dispatch(getDuplicateCheckerAction({ company: company, formId: formId }))
      dispatch(getFormStatusListAction({ companyId: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, company]);
}
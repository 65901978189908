import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';

export class FileDeltaFields implements DeltaFieldType {

  name = 'file';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];
    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      deltaArray = deltaArray.concat(FieldConfigChange.format(config, targetInstance.config, targetInstance));
    }
    return deltaArray;
  }
}
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react-bpm';
import { ISortEnumConfig, ISortEnumDirection, ISortEnumKey } from '../../../../component/admin-module/module/users/interface/field';
import SVGWrapper from '../../../icons/svg/svg-wrapper';
import { sortItemList } from './constants';

interface IEnumSortPopup {
  sort: ISortEnumConfig;
  setSort(value: ISortEnumConfig): void;
}

const EnumSortPopup: React.FC<IEnumSortPopup> = (props) => {

  const isSelected = (key: ISortEnumKey, direction: ISortEnumDirection) => {
    return props.sort.key === key && props.sort.direction === direction;
  }

  const sortIcon = (key: ISortEnumKey, direction: ISortEnumDirection) => {
    let iconName = '';
    if (key === ISortEnumKey.Label) {
      if (direction === ISortEnumDirection.Desc) {
        iconName = 'alphanumericDesc';
      } else {
        iconName = 'alphanumericAsc';
      }
    }
    if (key === ISortEnumKey.Id) {
      if (direction === ISortEnumDirection.Desc) {
        iconName = 'IDDesc';
      } else {
        iconName = 'IDAsc';
      }
    }
    return iconName;
  }

  return <div className='enum-sort-config'>
    <Popup
      on='click'
      id='enum-sort-popup'
      basic
      position='bottom right'
      trigger={
        <Icon className='sort-icon' name='sort amount down' />
      }
    >
      <Popup.Header>Sort by</Popup.Header>
      <Popup.Content>
        {sortItemList.map((item, idx) => {
          const selected = isSelected(item.key, item.direction);
          return <div key={idx} className={`enum-sort-item ${selected ? 'selected' : ''}`}
            onClick={() => props.setSort({ key: item.key, direction: item.direction })}>
            <SVGWrapper iconName={sortIcon(item.key, item.direction)} width='18' height='18' color={selected ? '#3d87ab' : '#000'} />
            <label>{item.label} <span style={{ fontStyle: 'italic', fontSize: '12px' }}>({item.direction + 'ending'})</span></label>
          </div>
        })}
      </Popup.Content>
    </Popup>
  </div>
}

export default EnumSortPopup;
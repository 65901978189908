import { useEffect } from 'react';

interface IProps {
  open: boolean;
  setMount: (value: React.SetStateAction<boolean>) => void;
}

export const useMountWhenChatboxOpen = ({ open, setMount }: IProps): void => {
  useEffect(() => {
    setTimeout(() => {
      setMount(open);
    }, 900);
    // eslint-disable-next-line
  }, [open])
};
import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

export class AutopopulatedDeltaFields implements DeltaFieldType {

  name = 'autopopulated';

  format(targetDelta: Delta, targetInstance: any, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      let { newDelta, oldDelta } = this.setDelta(config, targetInstance.config, utils, targetInstance.parentId);
      if (Object.keys(newDelta).length > 0) {
        deltaArray = deltaArray.concat(FieldConfigChange.format(newDelta, oldDelta, targetInstance));
      }
    }

    return deltaArray;
  }

  setDelta = (delta: Delta, oldValue: any, utils: IAdminHistoryUtils, parentId?: string) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'sourceField') {
        const data = this.setSourceFieldDelta(delta[key], utils, parentId);
        const oldData = this.setSourceFieldDelta(oldValue[key], utils, parentId);
        newDelta['sourceField'] = data;
        oldDelta['sourceField'] = oldData;
      }
      if (key === 'labelFormat') {
        const source = delta['sourceField'] ? delta['sourceField'] : oldValue['sourceField'];
        const data = this.setLabelFormat(delta['labelFormat'], source, utils, parentId);
        const oldData = this.setLabelFormat([oldValue['labelFormat']], oldValue['sourceField'], utils, parentId);
        newDelta['labelFormat'] = data;
        oldDelta['labelFormat'] = oldData;
      }
    });

    return { newDelta, oldDelta };
  }

  setSourceFieldDelta = (delta: Delta, utils: IAdminHistoryUtils, parentId?: string) => {
    let newDelta = '' as any;

    if (Array.isArray(delta)) {
      const sourceFields = delta.map(sourceId => {
        sourceId = this.parseSourceId(sourceId, parentId);
        const sourceDetails = utils.fields && utils.fields.find(e => e.id === sourceId);
        if (sourceDetails) {
          sourceId = sourceDetails.label;
        }
        return sourceId;
      });
      newDelta = sourceFields;
    } else if (typeof delta === 'string') {
      let sourceId = this.parseSourceId(delta, parentId);
      const sourceDetails = utils.fields && utils.fields.find(e => e.id === sourceId);
      if (sourceDetails) {
        newDelta = sourceDetails.label;
      }
    }
    return newDelta;
  }

  setLabelFormat = (delta: any, source: string | string[], utils: IAdminHistoryUtils, parentId?: string) => {
    const labelFormatData = delta.map((data: any) => this.parseLabel(data));
    if (typeof source === 'object' && Array.isArray(source) && source.length > 1) {
      const sourceData = source.map(id => {
        let sourceId = this.parseSourceId(id, parentId);
        return utils.fields && utils.fields.find(e => e.id === sourceId);
      });
      const labelFormat = labelFormatData.map((label: string[], index: number) => {
        const { config: { data } } = sourceData[index];
        return { ...data, label: label.map(e => e ? e : null) };
      });
      return labelFormat;
    } else {
      let sourceId = this.parseSourceId(source as string, parentId);
      const { config: { data } } = utils.fields && utils.fields.find(e => e.id === sourceId);
      const labelFormat = labelFormatData.map((label: string[], index: number) => {
        return { ...data, label: label.map(e => e ? e : null) };
      });
      return labelFormat;
    }
  }


  parseLabel(label: string) {
    return label.split(`}}{{`)
      .map((item: string) => item
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/fields./g, '')
      );
  }

  parseSourceId = (sourceId: string, parentSource?: string) => {
    if (typeof sourceId === 'string' && sourceId.indexOf('.') > -1 && parentSource) {
      sourceId = sourceId.split('.').filter(e => e !== parentSource).toString();
    }
    return sourceId;
  }
}
import React, { useEffect, useState } from 'react';
import { IFieldPermissionSettings } from '../..';
import { IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import { IRulesPermission } from '../../../interface/rules';
import BoolSelection from '../utils/bool-selection';
import ConditionConfigSelection from '../utils/config-selection';
import { DefaultTypeModuleRegistry } from './type';

export type TConfigOption = 'permission' | 'value' | 'clear';
export type TConfigType = 'onTrue' | 'onFalse';
export type TRulesPermission = keyof IRulesPermission;

interface IFrontendAutomationConfig extends IFieldPermissionSettings {
  permissionConfigRef: React.MutableRefObject<IPermissionFieldFilterConfig | undefined>;
  defaultvalueRef: any;
}

const DefaultFrontendAutomationConfig: React.FC<IFrontendAutomationConfig> = (props) => {

  const [boolSelected, setBoolSelected] = useState(true);
  const [configSelected, setConfigSelected] = useState<TRulesPermission>('setPermission');
  const [refresher, setRefresher] = useState(0);

  const updateConfig = (configType: TConfigType, name: 'shouldClear' | 'setPermission' | 'setValue', value: any) => {
    if (props.permissionConfigRef.current) {
      let tempConfig: any = { ...props.permissionConfigRef.current.conditionRule[configType] };
      tempConfig[name] = value;
      if (name === 'setValue' && !!tempConfig['shouldClear']) {
        delete tempConfig.shouldClear;
      }
      if (name === 'shouldClear' && !!tempConfig['setValue']) {
        delete tempConfig.setValue;
      }
      setConfig(tempConfig, configType);
    }
  }

  const setConfig = (config: IRulesPermission, configType: TConfigType) => {
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.conditionRule[configType] = config
      setRefresher(refresher + 1);
    }
  }

  const onTrueConfig = props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule.onTrue : {};
  const onFalseConfig = props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule.onFalse : {};

  const defaultTypeModuleProps = {
    ...props,
    boolSelected,
    updateConfig,
    rules: boolSelected ? onTrueConfig : onFalseConfig,
    defaultvalueRef: props.defaultvalueRef,
    conditionRule: props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule : {}
  }

  const configSettingsType = props.isTablePermission ? 'table' : props.isSectionPermission ? 'section' : 'fields';

  const setActiveConfig = (rule: IRulesPermission) => {
    const keys = Object.keys(rule) as TRulesPermission[];
    const activeConfig = keys.find(key => key !== 'setPermission' && !!rule[key]) as TRulesPermission;
    setConfigSelected(activeConfig || 'setPermission');
  }

  useEffect(() => {
    if (props.permissionConfigRef.current) {
      const { onFalse, onTrue } = props.permissionConfigRef.current.conditionRule;
      setActiveConfig(boolSelected ? onTrue : onFalse)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.permissionConfigRef.current, boolSelected]);

  return <>
    <BoolSelection
      value={boolSelected}
      setBoolSelected={setBoolSelected}
    />
    <ConditionConfigSelection
      setConfigSelected={setConfigSelected}
      configOption={configSelected}
      configSettingsType={configSettingsType}
      fieldData={props.fieldData}
      boolSelected={boolSelected}
    />
    <DefaultTypeModuleRegistry.Render
      {...defaultTypeModuleProps}
      type={configSelected}
    />
  </>
}

export default DefaultFrontendAutomationConfig;
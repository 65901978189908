import React from 'react';
import AutomationLogsList from './list';

interface Props { }

const AutomationLogs: React.FC<Props> = (props) => {
  return (
    <>
      <AutomationLogsList />
    </>
  );
}

export default AutomationLogs;

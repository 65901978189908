import React, { useState, useEffect } from 'react';
import DataTableRowAttribute from './data-table-attributes';
import { Button, Form } from 'semantic-ui-react-bpm';
import DataTablePermissions from './data-table-permissions';
import DataTableConfig from './data-table-config';
import { IDatatableRecord } from '../../../../../common/custom-field-config/interface/datatable-record';
import { useRouteMatch } from 'react-router-dom';
import { IDataTable } from '../reducer';
import DatatableCustomField from './data-table-custom-field';
import { ICustomFieldDetail, IConfig, IOnCustomFieldSubmitObject } from '../../../../../common/custom-field-config/interface/custom-field-detail';
import { sortDataTableCustomField } from '../../../../../common/utils/sort-data-table-field';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { IInternalDatatablePermission } from '../interface/data-table-permission';
import { IOption } from '../../../../../common/interface/option';
import { ConfirmationMessage } from '../../../../../common/general/confirmation-modal';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { FieldTableEntityType, IDatatableConfig, IDatatableConfigUniqueConstraint, IDatatableRecordsGroupedConfig, IDTRecordGroupedKeypath } from '../interface/data-table-config';
import { useSetDatatableCustomField } from './hooks/use-set-datatable-custom-field';
import { useSetDatatableConfig } from './hooks/use-set-datatable-config';
import { useSetDatatablePermissionOption } from './hooks/use-set-datatable-permission-option';
import { useSetDatatablePermission } from './hooks/use-set-datatable-permission';
import { IRootState } from '../../../../../reducers';

interface IDatatableBody {
  forwardedRef: any;
  datatable: IDatatableRecord[];
  forUpdateDataTableFields: ICustomFieldDetail[];
  forUpdateDataTableDetails: IDataTable;
  onClickCancel(): void;
  onClickSave(
    dataTableFields: ICustomFieldDetail[],
    permission: { view: string[], modify: string[] },
    unique: IDatatableConfig,
    groupings?: IDatatableRecordsGroupedConfig,
  ): void;
  datatablePermission: IInternalDatatablePermission;
  dataTablePermissionOption: IOption[];
  hasDatatableRecords: boolean;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  dataTableNameRef: React.MutableRefObject<HTMLInputElement>;
  datatableRecordsGroupedConfig: IDatatableRecordsGroupedConfig | null;
  groupedRecordsKeypath: IDTRecordGroupedKeypath[];
}
interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchParams;
}

interface IRouteMatchParams {
  dataTableId: string;
}

const AttributesTable: React.FC<IDatatableBody> = (props) => {
  const initialConfig: IDatatableConfigUniqueConstraint = { name: uuid(), fields: [] };

  let match: IRouteMatch = useRouteMatch();

  const companyId = useSelector((state: any) => state.auth.company);

  const { userCustomField } = useSelector((state: IRootState) => state.role);

  const [refresher, setRefresher]: any = useState(0);
  const [isOpenCustomField, setIsOpenCustomField] = useState(false);
  const [selectedField, setSelectedField] = useState({} as ICustomFieldDetail);
  const [openDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [forDeleteCustomField, setForDeleteCustomField] = useState({} as { fieldId: string, rowId: string });
  const [DTRecordsGroupedKeyPaths, setDTRecordsGroupedKeyPaths] = useState([] as string[]);
  const [DTRecordsGroupedConfigId, setDTRecordsGroupedConfigId] = useState(undefined as undefined | string);


  const { datatableConfig, setDatatableConfig } = useSetDatatableConfig({
    companyId,
    dataTableId: match.params.dataTableId,
    forUpdateDataTableDetails: props.forUpdateDataTableDetails
  });
  const { datatableCustomField, setDatatableCustomField } = useSetDatatableCustomField({
    companyId,
    dataTableId: match.params.dataTableId,
    forUpdateDataTableFields: props.forUpdateDataTableFields,
  });
  const { permissionOption } = useSetDatatablePermissionOption({
    dataTablePermissionOption: props.dataTablePermissionOption
  });
  const { viewPermission, setViewPermission, modifyPermission, setModifyPermission } = useSetDatatablePermission({
    datatablePermission: props.datatablePermission
  });

  const handleAddDatatableConfig = () => {
    let newDataConfig = { ...datatableConfig, uniqueConstraints: [...datatableConfig.uniqueConstraints] };
    newDataConfig.uniqueConstraints.push(initialConfig);
    setDatatableConfig(newDataConfig);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const handleAddRow = () => {
    let newData = [...datatableCustomField];
    newData.push({ id: uuid(), new: true } as ICustomFieldDetail);
    setDatatableCustomField(newData);  
    props.hasPageChangeRef.current.hasChange = true;
  }

  const handleEditDatatableConfig = (name: string, value: string | string[], action?: 'add' | 'delete') => {
    if (name === 'groupings' && typeof value === 'string') {
      const tempValue = [...DTRecordsGroupedKeyPaths];
      if (action === 'add') {
        tempValue.push(value);
      }
      if (action === 'delete') {
        const idx = tempValue.indexOf(value);
        tempValue.splice(idx, 1);
      }
      setDTRecordsGroupedKeyPaths(tempValue);
      return;
    }
    if (name === 'groupings' && typeof value === 'object' && !action) {
      setDTRecordsGroupedKeyPaths(value);
      return;
    }
    let newDataConfig = { ...datatableConfig, uniqueConstraints: [...datatableConfig.uniqueConstraints] };
    newDataConfig.uniqueConstraints = datatableConfig.uniqueConstraints.map(config => {
      if (config.name === name && typeof value === 'object') {
        config.fields = value;
      }
      return config;
    });
    setDatatableConfig(newDataConfig);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const openDeleteConfirmation = (fieldId: string, rowId: string) => {
    let selectedData = [...datatableCustomField];
    let filteredData = selectedData.filter((field: ICustomFieldDetail) => field.id === fieldId)[0];
    if (filteredData.new === true) {
      setDeleteModalMessage('Are you sure you want to delete the data table record?');
    } else {
      setDeleteModalMessage('Are you sure you want to delete the data table column and its data?');
    }
    setForDeleteCustomField({ fieldId: fieldId, rowId: rowId });
    setIsOpenDeleteModal(true);
  }

  const closeDeleteConfirmation = () => {
    setForDeleteCustomField({ fieldId: '', rowId: '' });
    setIsOpenDeleteModal(false);
    setDeleteModalMessage('');
  }

  const confirmDeleteCustomField = () => {
    const selectedField = { ...forDeleteCustomField };
    handleDeleteRow(selectedField.fieldId, selectedField.rowId);
    closeDeleteConfirmation();
  }

  const handleDeleteRow = (fieldId: string, rowId: string) => {
    props.forwardedRef.current[`row${fieldId}`].deleted = true;
    setRefresher(refresher + 1);
    let newData = [...datatableCustomField];
    let filteredData = newData.map((e: ICustomFieldDetail) => {
      e.config = props.forwardedRef.current[`row${e.id}`].config;
      e.label = props.forwardedRef.current[`row${e.id}`].columnHeading;
      e.type = props.forwardedRef.current[`row${e.id}`].fieldType;
      e.configName = props.forwardedRef.current[`row${e.id}`].configName;
      e.deleted = props.forwardedRef.current[`row${e.id}`].deleted;
      if (rowId === e.id) {
        return { ...e, deleted: true }
      } else {
        return { ...e, deleted: false }
      }
    });
    setDatatableCustomField(filteredData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const isValidFields = () => {
    let fieldsData = [...datatableCustomField];

    let hasError = false;
    fieldsData.forEach((e: any) => {
      if (!props.forwardedRef.current[`row${e.id}`].deleted) {
        if (!hasError) {
          hasError = (props.forwardedRef.current[`row${e.id}`].columnHeading ? false : true)
            || (props.forwardedRef.current[`row${e.id}`].configName ? false : true)
            || (props.forwardedRef.current[`row${e.id}`].dataType ? false : true)
        }

        props.forwardedRef.current[`row${e.id}`].error = {
          columnHeading: !props.forwardedRef.current[`row${e.id}`].columnHeading ? true : false,
          configName: !props.forwardedRef.current[`row${e.id}`].configName ? true : false,
          dataType: !props.forwardedRef.current[`row${e.id}`].dataType ? true : false
        }
      }
    });
    return !hasError;
  }

  const openCustomField = (field: ICustomFieldDetail) => {
    setSelectedField(field); 
    setIsOpenCustomField(true)
  }

  const closeCustomField = () => {
    setSelectedField({} as ICustomFieldDetail);
    setIsOpenCustomField(false)
  }

  const setFieldWithCurrentRefs = () => {
    let fieldsData = [...datatableCustomField];
    setDatatableCustomField(updateFieldWithCurrentRefs(fieldsData));
  }

  const updateFieldWithCurrentRefs = (datatableCustomField: ICustomFieldDetail[]): ICustomFieldDetail[] => {
    const forUpdateField = [...datatableCustomField];
    props.hasPageChangeRef.current.hasChange = true;
    return forUpdateField.map((e: ICustomFieldDetail) => {
      const tempField = { ...e };
      tempField.config = { ...props.forwardedRef.current[`row${e.id}`].config };
      tempField.label = props.forwardedRef.current[`row${e.id}`].columnHeading;
      tempField.type = props.forwardedRef.current[`row${e.id}`].fieldType;
      tempField.configName = props.forwardedRef.current[`row${e.id}`].configName;
      tempField.deleted = props.forwardedRef.current[`row${e.id}`].deleted;
      if (tempField.type === 'enum') {
        if (!tempField.config) {
          tempField.config = {} as IConfig;
        } 
 
        if (props.forwardedRef.current[`row${e.id}`].dataType === 'Relation' 
        && !tempField.config.dataType) {
          tempField.config.dataType = props.forwardedRef.current[`row${e.id}`].relationType;
        } 
      }   
        return tempField;
    });
  }

  const onClickSave = (datatableCustomField: ICustomFieldDetail[]) => {
    let fieldsData = [...datatableCustomField];
    if (isValidFields()) { 
      const permission = {
        view: viewPermission,
        modify: modifyPermission
      }
      const config = (DTRecordsGroupedKeyPaths.length || DTRecordsGroupedConfigId) ? {
        id: DTRecordsGroupedConfigId,
        entityConfigId: match.params.dataTableId,
        entityType: FieldTableEntityType.Dt,
        keyPaths: DTRecordsGroupedKeyPaths
      } : undefined 
      props.onClickSave(fieldsData, permission, datatableConfig, config);
    } else {
      setRefresher(refresher + 1);
    }
  }

  const updateFieldList = (submitData: IOnCustomFieldSubmitObject, fieldId: string) => {
    let oldData = [...datatableCustomField];
    let newData = oldData.map((e: ICustomFieldDetail) => {
      if (fieldId === e.id) {
        submitData.data.id = fieldId;
        return submitData.data;
      }
      return e;
    })
    setDatatableCustomField(newData);
    closeCustomField();
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteConstraint = (index: number) => {
    const tempDatatableConfig: IDatatableConfig = { ...datatableConfig, uniqueConstraints: [...datatableConfig.uniqueConstraints] };
    tempDatatableConfig.uniqueConstraints.splice(index, 1);
    setDatatableConfig(tempDatatableConfig);
  }

  const changeCustomFieldListSequence = (draggedItem: { data: ICustomFieldDetail }, droppedItemId: string) => {
    let newdataTableData = [...datatableCustomField];
    const dragItemIndex = newdataTableData.indexOf(draggedItem.data);
    const dropContainerIdIndex = newdataTableData.indexOf(newdataTableData.filter(e => e.id === droppedItemId)[0]);
    const element = newdataTableData[dragItemIndex];
    newdataTableData.splice(dragItemIndex, 1);
    newdataTableData.splice(dropContainerIdIndex, 0, element);

    setDatatableCustomField( newdataTableData );
  }

  useEffect(() => {
    if (props.datatableRecordsGroupedConfig) {
      setDTRecordsGroupedKeyPaths(props.datatableRecordsGroupedConfig.keyPaths || []);
      setDTRecordsGroupedConfigId(props.datatableRecordsGroupedConfig.id);
    }
  }, [props.datatableRecordsGroupedConfig]);

  return (<>
    <Form size='large' id='database-form'>
      <div className="ui medium header">
        {match.params.dataTableId !== 'add-data-table' ? 'Update Data Table' : 'Create New Data Table'}
      </div>
      <Form.Group widths='equal'>
        <div className='required six wide field'>
          <label>Data Table Name</label>
          <div className="ui input">
            <input
              onChange={(e) => { props.dataTableNameRef.current.value = e.target.value }}
              ref={(curRef) => {
                if (curRef) {
                  curRef.value = props.dataTableNameRef.current.value && props.dataTableNameRef.current.value !== 'undefined' ? props.dataTableNameRef.current.value : '';
                  return props.dataTableNameRef.current = curRef;
                }
              }}
              type="text" />
          </div>
        </div>
      </Form.Group>
      <div className='attribute-content'>
        <table id='attributes-container'>
          <thead>
            <tr>
              <th hidden>id</th>
              <th className="th-primary"></th>
              <th className="th-primary">Column Heading</th>
              <th className="th-primary">Data Type</th>
              <th className="th-primary">Field Type</th>
            </tr>
          </thead>
          <tbody>
            {
              sortDataTableCustomField(datatableCustomField, 'ID').map((customField: ICustomFieldDetail, index: number) => { 
                return (
                  <DataTableRowAttribute
                    customFieldObject={customField}
                    forUpdateDataTableFields={props.forUpdateDataTableFields}
                    key={`row-${index}`}
                    datatable={props.datatable}
                    forwardedRef={props.forwardedRef}
                    columnId={index}
                    handleDelete={openDeleteConfirmation}
                    keys={index}
                    openCustomField={openCustomField}
                    hasDatatableRecords={props.hasDatatableRecords}
                    changeCustomFieldListSequence={changeCustomFieldListSequence}
                    setFieldWithCurrentRefs={setFieldWithCurrentRefs}
                    hasPageChangeRef={props.hasPageChangeRef}
                  />
                )
              })
            }

            <tr>
              <td className='td-add-new-column' colSpan={4}>
                <Button className='add-column-button' onClick={handleAddRow}>Add a new column</Button>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <DatatableCustomField
        isCustomFieldFormOpen={isOpenCustomField}
        closeCustomField={closeCustomField}
        field={selectedField}
        openDeleteModal={() => { }}
        updateDetailList={updateFieldList}
        forUpdateDataTableDetails={props.forUpdateDataTableDetails}
        companyId={companyId}
        currentFieldList={datatableCustomField}
        userCustomField={userCustomField}
      />
      <DataTableConfig
        datatableCustomField={datatableCustomField}
        handleAddDatatableConfig={handleAddDatatableConfig}
        datatableConfig={datatableConfig}
        handleEditDatatableConfig={handleEditDatatableConfig}
        hasPageChangeRef={props.hasPageChangeRef}
        deleteConstraint={deleteConstraint}
        DTRecordsGroupedConfig={DTRecordsGroupedKeyPaths}
        datatableId={match.params.dataTableId}
        groupedRecordsKeypath={props.groupedRecordsKeypath}
      />
      <DataTablePermissions
        modifyPermission={modifyPermission}
        viewPermission={viewPermission}
        setModifyPermission={setModifyPermission}
        setViewPermission={setViewPermission}
        datatablePermissionOption={permissionOption}
        hasPageChangeRef={props.hasPageChangeRef}
      />
    </Form>
    <label className='label-bottom'><strong> * </strong> Fields are required</label>
    <div className='dt-action-button'>
      <Button color='grey' className='btn-action' onClick={() => props.onClickCancel()}>Cancel</Button>
      <Button color='green' className='btn-action' onClick={() => onClickSave(datatableCustomField)}>Save</Button>
    </div>
    <ConfirmationMessage
      clearErrorMessage={() => { }}
      errorMessage={''}
      confirmButtonName={'Confirm'}
      confirmButtonColor={'green'}
      cancelButtonColor={'grey'}
      close={closeDeleteConfirmation}
      open={openDeleteModal}
      confirm={confirmDeleteCustomField}
      cancel={closeDeleteConfirmation}
      headerText="Confirmation"
      detailText={deleteModalMessage}
      loading={false}
      type='warning'
    />
  </>
  )
}

export default React.memo(AttributesTable);
export const setCursorPosition = (event: any, name: string, tableId?: string) => {
  if (tableId) {
    const cursorPosition = localStorage.getItem('cursorPosition');
    if (cursorPosition) {
      const old = JSON.parse(cursorPosition);
      if (Object.keys(old).indexOf(name) === -1) {
        localStorage.removeItem('cursorPosition');
      }
    }
    const data = { [name]: event.target.selectionStart ? event.target.selectionStart : null };
    localStorage.setItem('cursorPosition', JSON.stringify(data));
  }
}

export const restoreCursorPosition = (curRef: any, name: string, tableId?: string) => {
  if (tableId) {
    const cursorPosition = localStorage.getItem('cursorPosition');
    if (cursorPosition) {
      const data = JSON.parse(cursorPosition);
      curRef.selectionStart = data[name];
      curRef.selectionEnd = data[name];
    }
  }
}

export const removeCursorPosition = () => {
  localStorage.removeItem('cursorPosition');
}
import React, { Fragment } from 'react';
import { CSSProperties } from 'react-router/node_modules/@types/react';
import { v4 as uuid } from 'uuid';
import { ICommentDeltaFields } from '../../../../../../interface/comment';
import { setEditedFieldRef } from '../../utils/system-delta-fields';
import { renderFieldValue } from './render-field-value';

const renderPreview = (fieldValues: any, delta: ICommentDeltaFields) => {
  const arrowStyle: CSSProperties = { position: 'relative', top: '-2px' };

  const firstProp = delta.type === 'range' ? 'from' : 'lat';
  const secondProp = delta.type === 'range' ? 'to' : 'lon';

  if (!fieldValues) {
    return <div>&nbsp;No Value&nbsp;</div>;
  }

  return <div>
    {delta.type === 'geolocation' && <label>{`${firstProp} : `}</label>}
    &nbsp;
    {renderFieldValue(fieldValues[firstProp], delta)}
    &nbsp;
    {delta.type === 'range' && <label style={arrowStyle}>&#8594;</label>}
    {delta.type === 'geolocation' && <label>{`${secondProp} : `}</label>}
    &nbsp;
    {renderFieldValue(fieldValues[secondProp], delta)}
    &nbsp;&nbsp;&nbsp;
  </div>
}

export const renderRangeChanges = (fieldValues: any, delta: ICommentDeltaFields, newDeltaFields: ICommentDeltaFields[]) => {
  if (!fieldValues || (fieldValues && fieldValues.length === 0)) {
    return null;
  }

  const previousValue = fieldValues[0] ? fieldValues[0] : '';
  const currentValue = fieldValues[1] ? fieldValues[1] : '';

  return <Fragment key={`${delta.id}`}>
    <div ref={setEditedFieldRef} className='edited-field-container'>
      <div className={`edited-field-info ${newDeltaFields.length === 1 ? 'hidden' : ''}`}>
        <span className='field-name'>{delta.label}</span>
      </div>
      <div key={uuid()}
        className={`edited-field-preview ${newDeltaFields.length === 1 ? 'single' : ''}`}>
        <div className='preview before'>{renderPreview(previousValue, delta)}</div>
        <div className='arrow-right'>&#8594;</div>
        <div className='preview after'>{renderPreview(currentValue, delta)}</div>
      </div>
    </div>
  </Fragment>
}
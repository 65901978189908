import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  ExcelExport,
  GridComponent,
  Inject,
  Toolbar,
  Grid,
  Filter,
  Sort,
  Search,
  EditMode,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFieldData } from '../../../../../common/field/dynamic-field-list-detail/interface/field-data';
import { FieldConstruct } from '../../../../../common/field/field-construct';
import { IRootState } from '../../../../../reducers';
import { datatableRecordLimit } from '../../../../document-module/module/datatable/constant';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { IField, IFieldAccessType, ISortField } from '../../users/interface/field';
import {
  IDTemplate,
  IDEditTemplate,
  datatableRecordEditTemplate,
  datatableRecordTemplate,
  filterTemplate,
  parseData,
  parsePreviousData,
  readonlyFieldTemplates
} from './utils/manage-grid';
import { L10n } from '@syncfusion/ej2-base';
import { deleteDataTableRecordAction, refreshDatatableRecordsAction, setEnumItemsFromRecordsAction } from '../action';
import { FormEnumLabel } from '../../../../document-module/module/form/utils/manage-enum-id-label';
import { IDatatableBatchChanges } from '../interface/data-table-config';
import { v4 as uuid } from 'uuid';

export interface IDatatableRecordsGrid {
  dataTableRecordsField: IField[];
  dataTableRecordList: IFieldData[];
  fieldRefs: any;
  openDeleteRecordConfirmationModal(recordId: string, dataTableId: string, recordName?: string): void;
  triggerFieldReload?(value: string[], docId?: string): void;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  currentOffset: number;
  datatableRecordTotalCount: number;
  triggerDatatableRecordSave(data: any, recordId?: string, error?: boolean): void;
  dataTableId: string;
  companyId: string;
  forUpdateDataTableFields: IField[];
  filterRefs: any;
  triggerFilter(limit?: number, offset?: number, sort?: string, search?: string): void;
  downloadData(): void;
  setIsOpenFileUploadModal(value: boolean): void;
  sort(fieldId: string, sort: string, ascending: boolean): void;
  sortObject: ISortField;
  setGridEditMode(value: EditMode): void;
  gridEditMode: EditMode;
  setGridBatchChanges(value: IDatatableBatchChanges | undefined): void;
  gridBatchChanges: IDatatableBatchChanges | undefined;
  triggerSetUpdatedColumnValues(rowId: string, columnIndexes: number[]): void;
  updatedColumnValues: { [rrowId: string]: number[] } | undefined;
  triggerBatchUpdate(data: any, recordId: string, action: 'add' | 'update' | 'delete'): void;
  triggerResetBatchChange(): void;
  setUpdatedValues(value: { [rowId: string]: number[] } | undefined): void;
  triggerBatchSave(): void;
}

const DatatableRecordsGrid: React.FC<IDatatableRecordsGrid> = (props) => {
  const dispatch = useDispatch();

  const [refresher, setRefresher] = useState(0);

  const { fieldConfigValue, dataTableRecordEditableId } = useSelector((state: IRootState) => state.dataTable);
  const { dataTableRecordsList, datatableRecordGridMessage } = useSelector((state: IRootState) => state.dataTable);

  let gridInstance: Grid;

  const toolBarExcelImport = {
    text: 'Excel Import',
    tooltipText: 'Import Excel',
    prefixIcon: 'e-export-excel e-import',
    id: 'ExcelImport',
  }
  const clearSearch = {
    title: 'Clear Search',
    cssClass: 'e-clear-btn',
    tooltipText: 'Clear Search',
    prefixIcon: 'e-input-group-icon e-close',
  }
  const batchBtn = {
    text: 'Batch Edit',
    tooltipText: 'Batch Edit',
    cssClass: `e-batch-btn`,
    prefixIcon: 'e-input-group-icon e-edit-6',
    id: 'BatchEdit'
  }
  const toolBarOptions = ['Add', { text: 'Edit', cssClass: 'e-edit-btn' }, 'Delete', 'Update', 'Cancel', 'ExcelExport', toolBarExcelImport, batchBtn, 'Search', clearSearch];
  const injectServices = [Edit, Toolbar, ExcelExport, Filter, Sort, Search];
  const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: props.gridEditMode };

  const gridActionComplete = (event: any) => {
    if ((event.requestType === 'beginEdit' || event.requestType === 'add')) {
      event.form[1].focus();
      if (event.form[1].tagName === 'SELECT') {
        event.form[2].focus();
      }
      if (props.gridEditMode === 'Normal') {
        gridInstance.toolbarModule.enableItems(['BatchEdit'], false);
      }
    }
    if (event.requestType === 'save' && (event.action === 'add' || event.action === 'edit')) {
      let previousData = parsePreviousData({ ...event.previousData }, props.dataTableRecordsField);
      let data = parseData({ ...event.data }, props.dataTableRecordsField, props.fieldRefs, props.gridEditMode);
      data = FormEnumLabel.getEnumIdLabel(data, { ...props.fieldRefs }, props.dataTableRecordsField);
      let noChange = JSON.stringify(previousData) === JSON.stringify(data);
      const currentDataEmpty = Object.values(event.data).every(e => !e);
      if (!noChange) {
        delete data.seq;
        delete data.id;
        if (!currentDataEmpty) {
          props.triggerDatatableRecordSave(data, event.data.id);
        } else {
          props.triggerDatatableRecordSave(null, '', true);
        }
      }
      if (props.gridEditMode === 'Normal') {
        gridInstance.toolbarModule.enableItems(['BatchEdit'], true);
      }
    }
  }

  const gridActionBegin = (event: any) => {
    if (event.requestType === 'cancel') {
      if (props.gridEditMode === 'Normal') {
        gridInstance.toolbarModule.enableItems(['BatchEdit'], true);
      }
    }
    if (event.requestType === 'delete') event.cancel = true;
    if (event.requestType === 'save') {
      const preventSaveAction = localStorage.getItem('preventSaveAction');
      event.cancel = preventSaveAction && preventSaveAction === 'true' ? true : false;
      localStorage.setItem('preventSaveAction', 'true');
    }
    if (event.requestType === 'beginEdit') {
      if (event.rowData && event.rowData.id) {
        const data = props.dataTableRecordList.find(e => e.id === event.rowData.id);
        if (data) {
          FieldConstruct.setFieldToRefs(props.dataTableRecordsField, data, props.fieldRefs);
        }
      }
      if (props.gridEditMode === 'Normal') {
        gridInstance.toolbarModule.enableItems(['BatchEdit'], false);
      }
    }
    if (event.requestType === 'sorting') {
      event.cancel = true;
      const column: any = gridInstance.getColumnHeaderByField(event.columnName);
      const fieldId = `fields.${event.columnName}`;
      if (props.sortObject.fieldId === fieldId && !props.sortObject.ascending) {
        column.lastChild.className = "e-sortfilterdiv e-icons"
        props.sort(fieldId, 'none', false);
        return;
      }
      if (props.sortObject.fieldId && props.sortObject.fieldId !== fieldId) {
        const columnId = props.sortObject['fieldId'].split('fields.')[1];
        if (columnId) {
          const column: any = gridInstance.getColumnHeaderByField(columnId);
          if (column && column.lastChild && column.lastChild.className) {
            column.lastChild.className = "e-sortfilterdiv e-icons";
          }
        }
      }
      const ascending = props.sortObject.fieldId === fieldId ? !props.sortObject.ascending : true;
      const sort = `${fieldId}|${ascending ? 'asc' : 'desc'}`;
      props.sort(fieldId, sort, ascending);
      if (column && column.lastChild && column.lastChild.className) {
        if (ascending) {
          column.lastChild.className = "e-sortfilterdiv e-icons e-icon-ascending";
        } else {
          column.lastChild.className = "e-sortfilterdiv e-icons e-icon-descending"
        }
      }
    }
    if (event.requestType === 'searching') {
      const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
      props.triggerFilter(datatableRecordLimit, 0, props.sortObject.sort, event.searchString);
      if (clear) {
        clear.className = clear.className.replace('e-focus', '');
      }
      event.cancel = true;
    }
  }

  const gridToolbarClick = (event: any) => {
    const input: any = document.getElementById(gridInstance.element.id + '_searchbar');
    if (event && event.item) {
      if (event.item.text === 'Update' && props.gridEditMode === 'Normal') {
        localStorage.setItem('preventSaveAction', 'false');
      }
      if (event.item.text === 'Delete' && props.gridEditMode === 'Normal') {
        const selectedRecord = gridInstance.getSelectedRecords() as IFieldData[];
        if (selectedRecord && selectedRecord.length && selectedRecord[0]) {
          props.openDeleteRecordConfirmationModal(selectedRecord[0].id, props.dataTableId)
        }
      }
      if (event.item.text === 'Batch Edit') {
        const mode = props.gridEditMode === 'Batch' ? 'Normal' : 'Batch';
        props.setGridEditMode(mode)
        const batchBtn = document.getElementsByClassName('e-toolbar-left')[0].getElementsByClassName('e-batch-btn')[0];
        if (batchBtn) {
          const gridEditMode = `${props.gridEditMode.toLowerCase()}-edit`;
          if (batchBtn.className.includes(gridEditMode)) {
            batchBtn.className = batchBtn.className.replace(gridEditMode, '');
          }
          batchBtn.className = batchBtn.className + ` ${mode.toLowerCase()}-edit`;
        }
        props.setGridBatchChanges(undefined);
        props.setUpdatedValues(undefined);
        gridInstance.refresh();
        setRefresher(refresher + 1);
      }
      if (event.item.text === 'Excel Export') {
        props.downloadData();
      }
      if (event.item.text === 'Excel Import') {
        props.setIsOpenFileUploadModal(true);
      }
      if (event.item.title === 'Clear Search') {
        if (input && input.value) {
          input.value = '';
          props.triggerFilter(datatableRecordLimit, 0, props.sortObject.sort, '');
        }
      }
      if (event.item.type === 'Input') {
        const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
        if (input) {
          const searchWrap = input.parentElement;
          if (searchWrap) {
            clear.className = clear.className + ' e-focus';
          }
          input.onblur = (() => {
            clear.className = clear.className.replace('e-focus', '');
          });
        }
      }
    }
  }

  const gridDataBound = () => {
    dispatch(setEnumItemsFromRecordsAction())
    const toolbarLeftNodes = document.getElementsByClassName('e-toolbar-left')[0].childNodes;
    const items = [].slice.call(toolbarLeftNodes).filter((item: any) => { return item.getAttribute('title') === 'Clear Search' });
    items.forEach((element: any) => {
      element.remove();
    });
    for (var i = 0; i < items.length; i++) {
      document.getElementsByClassName('e-toolbar-right')[0].appendChild(items[i]);
    }
  }

  const gridCellEdit = (event: any) => {
    const rowIndex = gridInstance.getRowInfo(event.row).rowIndex;
    const cellIndex = gridInstance.getColumnIndexByField(event.columnName);
    if (event.rowData && event.rowData.id) {
      const data = dataTableRecordsList.find(e => e.id === event.rowData.id);
      if (data) {
        FieldConstruct.setFieldToRefs(props.dataTableRecordsField, data, props.fieldRefs);
      }
    }
    if (rowIndex !== undefined && rowIndex > -1 && cellIndex > -1) {
      localStorage.setItem('gridActiveCell', JSON.stringify({ rowIndex, cellIndex }));
    }
  }

  const gridBeforeBatchAdd = (event: any) => {
    props.dataTableRecordsField.forEach(field => {
      const fieldId = field.id || '';
      event.defaultData[fieldId] = field.defaultValue ? field.defaultValue : undefined;
    });
    event.defaultData.seq = null;
    FieldConstruct.setFieldToRefs(props.dataTableRecordsField, { fields: event.defaultData }, props.fieldRefs);
    event.defaultData = FormEnumLabel.getEnumIdLabel(event.defaultData, { ...props.fieldRefs }, props.dataTableRecordsField)
    return event;
  }

  const gridBatchAdd = (event: any) => {
    const docId = uuid();
    let fieldReload: string[] = [];
    let columns: number[] = [];
    props.dataTableRecordsField.forEach((field, idx) => {
      const fieldId = field.id || '';
      event.defaultData[fieldId] = field.defaultValue ? field.defaultValue : undefined;
      if (field.reloadOnChange && field.reloadOnChange.length > 0) {
        fieldReload = fieldReload.concat(field.reloadOnChange)
      }
      columns.push(idx);
    });
    if (props.triggerFieldReload) {
      props.triggerFieldReload(fieldReload, docId);
    }
    const columnIndexes = gridInstance.getColumnFieldNames().map(
      field => gridInstance.getColumnIndexByField(field)
    );
    props.triggerSetUpdatedColumnValues(docId, Array.from(new Set(columnIndexes)));
    props.triggerBatchUpdate(event.defaultData, docId, 'add');
  }

  const gridBatchDelete = (event: any) => {
    let rowIndex = event.rowIndex;
    const recordId = event.rowData.id;
    const batchChanges = gridInstance.getBatchChanges();
    let tempRecord = [...dataTableRecordsList];
    if (batchChanges) {
      const { deletedRecords }: any = { ...batchChanges };
      if (deletedRecords && deletedRecords instanceof Array && deletedRecords.length > 0) {
        tempRecord = tempRecord.filter(e => deletedRecords.filter(del => del.id === e.id).length === 0);
        if (rowIndex > tempRecord.length - 1) {
          rowIndex = tempRecord.length - 1;
        }
        dispatch(deleteDataTableRecordAction({
          recordsToDelete: deletedRecords.map(e => e.id),
          dataTableId: props.dataTableId,
          recordId: recordId
        }));
      }
    }
    gridInstance.selectRow(rowIndex);
    props.triggerBatchUpdate(null, recordId, 'delete');
  }

  const gridRecordClck = (event: any) => {
    const gridActiveCell = localStorage.getItem('gridActiveCell');
    const targetCellIndex = { rowIndex: event.rowIndex, cellIndex: event.cellIndex };
    if (gridActiveCell) {
      if (JSON.stringify(targetCellIndex) !== gridActiveCell) {
        const activeCell = JSON.parse(gridActiveCell);
        const row = gridInstance.getRowByIndex(activeCell.rowIndex);
        const rowInfo: any = gridInstance.getRowInfo(row);
        if (rowInfo.rowData && rowInfo.rowData.hasOwnProperty('id')) {
          let data = parseData({ ...rowInfo.rowData }, props.dataTableRecordsField, props.fieldRefs, props.gridEditMode);
          data = FormEnumLabel.getEnumIdLabel(data, { ...props.fieldRefs }, props.dataTableRecordsField);
          let noChange = JSON.stringify({ ...rowInfo.rowData }) === JSON.stringify(data);
          if (!noChange) {
            const fieldData = props.dataTableRecordsField.reduce((obj: any, field: IField) => {
              obj[field.id || ''] = data[field.id || ''];
              return obj;
            }, {});
            props.triggerBatchUpdate(fieldData, rowInfo.rowData.id, 'update');
          } else {
            dispatch(refreshDatatableRecordsAction())
          }
        }
      }
    }
  }

  const gridBatchCancel = () => {
    props.triggerResetBatchChange();
  }

  const highlightUpdatedValues = () => {
    if (props.gridBatchChanges) {
      const { updatedRecords, addedRecords } = { ...props.gridBatchChanges };
      if ((updatedRecords && updatedRecords.length > 0) || (addedRecords && addedRecords.length > 0)) {
        gridInstance.toolbarModule.enableItems([
          gridInstance.element.id + '_update',
          gridInstance.element.id + '_cancel'
        ], true);
      }
      if ((updatedRecords && updatedRecords.length === 0) || (addedRecords && addedRecords.length === 0)) {
        gridInstance.toolbarModule.enableItems([
          gridInstance.element.id + '_update',
          gridInstance.element.id + '_cancel'
        ], false);
      }
    }
    const gridActiveCell = localStorage.getItem('gridActiveCell');
    if (props.updatedColumnValues && Object.keys(props.updatedColumnValues).length > 0
      && props.gridEditMode === 'Batch' && !gridActiveCell) {
      const updatedColumnValues: any = { ...props.updatedColumnValues };
      Object.keys(updatedColumnValues).forEach(rowId => {
        const rowIndex = dataTableRecordsList.findIndex(e => e.id === rowId);
        const columnValues = updatedColumnValues[rowId];
        const row = gridInstance.getRowByIndex(rowIndex);
        if (columnValues && columnValues instanceof Array && columnValues.length > 0 && row && row.children.length > 0) {
          Array.from(row.children).forEach(cell => {
            if (cell) {
              const cellIndex = cell.getAttribute('aria-colindex');
              const cellRowIndex = cell.getAttribute('index');
              if (!!cellIndex && !!cellRowIndex && parseInt(cellRowIndex) === rowIndex) {
                const isUpdated = columnValues.includes(parseInt(cellIndex));
                if (isUpdated) {
                  cell.className = cell.className + ' e-updatedtd';
                }
              }
            }
          });
        }
      });
    }
  }

  const setFieldConfigValueToInput = (fieldConfigValue: { [id: string]: any; }) => {
    Object.keys(fieldConfigValue).forEach(fieldId => {
      const formElement = gridInstance.element.querySelector('form');
      if (formElement) {
        const input = formElement.getElementsByClassName('e-input')
        const inputItems = [].slice.call(input).filter((item: any) => { return item.getAttribute('name') === fieldId });
        inputItems.forEach((element: any) => {
          element.value = fieldConfigValue[fieldId];
        });
      }
    });
  }

  const saveFieldConfigValueToRecord = (fieldConfigValue: { [id: string]: any; }) => {
    if (dataTableRecordEditableId && gridInstance.dataSource instanceof Array && props.gridEditMode === 'Batch') {
      const recordId = dataTableRecordEditableId.includes('new') ? dataTableRecordEditableId.split('|||')[0] : dataTableRecordEditableId;
      const rowIndex = gridInstance.dataSource.findIndex(e => e.id === recordId);
      const records = dataTableRecordsList.find(e => e.id === recordId);
      Object.keys(fieldConfigValue).forEach(fieldId => {
        if (props.fieldRefs && props.fieldRefs.current && props.fieldRefs.current[fieldId]) {
          props.fieldRefs.current[fieldId].value = fieldConfigValue[fieldId];
        }
        const cellIndex = gridInstance.getColumnIndexByField(fieldId);
        const cell = gridInstance.getCellFromIndex(rowIndex, cellIndex);
        if (cell) {
          const cellRowIndex = cell.getAttribute('index');
          if (!!cellIndex && !!cellRowIndex && parseInt(cellRowIndex) === rowIndex) {
            cell.textContent = fieldConfigValue[fieldId];
          }
        }
        if (records) {
          records.fields[fieldId] = fieldConfigValue[fieldId];
        }
      });
    }
  }

  const handleClickEvent = (event: MouseEvent | TouchEvent) => {
    const rootEl = document.getElementById('root')
    const target = event.target as HTMLElement;
    const toolbarEl = document.getElementById(gridInstance.element.id + '_toolbarItems');
    if (props.gridEditMode === 'Batch' && rootEl && rootEl.contains(target)) {
      const clickedOnToolbar = toolbarEl && toolbarEl.contains(target);
      const clickedOutsideGrid = !gridInstance.element.contains(target);
      if (clickedOnToolbar || clickedOutsideGrid) {
        const gridActiveCell = localStorage.getItem('gridActiveCell');
        if (gridActiveCell) {
          const activeCell = JSON.parse(gridActiveCell);
          const row = gridInstance.getRowByIndex(activeCell.rowIndex);
          const rowInfo: any = gridInstance.getRowInfo(row);
          if (rowInfo.rowData && rowInfo.rowData.hasOwnProperty('id')) {
            let data = parseData({ ...rowInfo.rowData }, props.dataTableRecordsField, props.fieldRefs, props.gridEditMode);
            data = FormEnumLabel.getEnumIdLabel(data, { ...props.fieldRefs }, props.dataTableRecordsField);
            let noChange = JSON.stringify({ ...rowInfo.rowData }) === JSON.stringify(data);
            if (!noChange) {
              const fieldData = props.dataTableRecordsField.reduce((obj: any, field: IField) => {
                obj[field.id || ''] = data[field.id || ''];
                return obj;
              }, {});
              props.triggerBatchUpdate(fieldData, rowInfo.rowData.id, 'update');
            } else {
              dispatch(refreshDatatableRecordsAction())
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEvent);
    document.addEventListener('touchstart', handleClickEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickEvent);
      document.removeEventListener('touchstart', handleClickEvent);
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (fieldConfigValue && Object.keys(fieldConfigValue).length > 0) {
      setFieldConfigValueToInput(fieldConfigValue);
      saveFieldConfigValueToRecord(fieldConfigValue);
    }
    // eslint-disable-next-line
  }, [fieldConfigValue, dataTableRecordEditableId]);

  useEffect(() => {
    gridInstance.dataSource = dataTableRecordsList.map(records => {
      return { id: records.id, seq: records.seq, ...records.fields };
    });
    localStorage.setItem('preventSaveAction', 'true');
    setRefresher(refresher + 1);
    // eslint-disable-next-line
  }, [dataTableRecordsList]);

  useEffect(() => {
    if (gridInstance) {
      const search = gridInstance.element.querySelector('#' + gridInstance.element.getAttribute('id') + '_searchbar');
      if (search) {
        search.addEventListener('keyup', function (e: any) {
          const timer = setTimeout(() => {
            props.triggerFilter(datatableRecordLimit, 0, props.sortObject.sort, e.target.value);
          }, 1000);
          return () => { clearTimeout(timer) }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      highlightUpdatedValues();
    }, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [props.gridBatchChanges, dataTableRecordsList, props.updatedColumnValues])

  L10n.load({
    'en-US': {
      'grid': {
        'EmptyRecord': datatableRecordGridMessage
      }
    }
  });

  return (<GridComponent
    {...props}
    toolbar={toolBarOptions}
    editSettings={editSettings}
    rowHeight={31}
    actionComplete={gridActionComplete}
    actionBegin={gridActionBegin}
    allowFiltering={true}
    toolbarClick={gridToolbarClick}
    allowSorting={true}
    dataBound={gridDataBound}
    cellEdit={gridCellEdit}
    cellSave={(event) => event.cancel = true}
    beforeBatchAdd={gridBeforeBatchAdd}
    batchAdd={gridBatchAdd}
    batchDelete={gridBatchDelete}
    recordClick={gridRecordClck}
    beforeBatchSave={(event) => {
      props.triggerBatchSave();
      event.cancel = true;
    }}
    created={() => { gridInstance.refresh() }}
    batchCancel={gridBatchCancel}
    ref={grid => {
      if (grid) {
        gridInstance = grid;
        highlightUpdatedValues();
        const previousMode = props.gridEditMode === 'Batch' ? 'normal-edit' : 'batch-edit';
        const gridEditMode = `${props.gridEditMode.toLowerCase()}-edit`;
        if (grid.element.className.includes(previousMode)) {
          grid.element.className = grid.element.className.replace(previousMode, '');
        }
        if (!grid.element.className.includes(gridEditMode)) {
          grid.element.className = grid.element.className + ` ${gridEditMode}`;
        }
      }
    }}
  >
    <ColumnsDirective>
      <ColumnDirective
        field='seq'
        headerText='ID'
        width='100'
        isPrimaryKey={true}
        editTemplate={IDEditTemplate}
        template={IDTemplate}
        isIdentity
        editType='string'
      />
      {props.dataTableRecordsField.map(fields => {
        if (!fields) { return null };
        const args: any = { fieldId: fields.id };

        if (fields.accessType === IFieldAccessType.Readonly) {
          return <ColumnDirective
            key={`field-${fields.id}`}
            field={fields.id}
            headerText={fields.label}
            textAlign='Left'
            type='string'
            allowEditing={false}
            editTemplate={readonlyFieldTemplates}
            filterTemplate={filterTemplate}
            {...args}
          />
        }

        return <ColumnDirective
          key={`field-${fields.id}`}
          field={fields.id}
          headerText={fields.label}
          textAlign='Left'
          template={datatableRecordTemplate}
          editTemplate={datatableRecordEditTemplate}
          filterTemplate={filterTemplate}
          {...args}
        />
      })}
      <ColumnDirective headerText='' width='0' textAlign='Right' />
    </ColumnsDirective>
    <Inject services={injectServices} />
  </GridComponent >
  )
}

export default DatatableRecordsGrid;
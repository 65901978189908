import React from 'react';
import { Segment, Button } from 'semantic-ui-react-bpm';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetDataTableMessageAction } from '../action';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import { setOpenAdminChatboxAction } from '../../../../main/action';
import { getAdminHistoryLogsAction } from '../../../history/action';
import { IRootState } from '../../../../../reducers';
import { AdminHistoryCategory } from '../../../history/interface/history';

interface IDataTableFilter {
  isOpenChatbox: boolean;
  allowedAccessAuditLogs: boolean;
}

export class DataTableFilter {

  static Render: React.FC<IDataTableFilter> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const companyId = useSelector((state: IRootState) => state.auth.company);
    const { dataTableList } = useSelector((state: any) => state.dataTable);
    const { userLoggedIn: { tz } } = useSelector((state: IRootState) => state.auth);

    const openDataTableForm = () => {
      history.push('/admin/data-tables/add-data-table');
      dispatch(resetDataTableMessageAction({}))
    }

    const setIsOpenChatbox = () => {
      dispatch(setOpenAdminChatboxAction(true));
      dispatch(getAdminHistoryLogsAction({
        company: companyId,
        categories: [
          AdminHistoryCategory.DataTable,
          AdminHistoryCategory.DataTableFields,
          AdminHistoryCategory.DataTablePermissions
        ],
        dataTableList: dataTableList,
        tz
      }));
    }

    return <Segment className='borderless filter-wrapper'>
      {props.allowedAccessAuditLogs &&
        <Button className={`filter-users chat-icon ${!props.isOpenChatbox ? 'inactive' : ''}`} primary onClick={setIsOpenChatbox}>
          <SVGWrapper iconName='adminChatIcon' width='20' color={`${!props.isOpenChatbox ? '#3a85a8' : '#fff'}`} />
        </Button>
      }
      <Button type='button' className={`add-button ${props.allowedAccessAuditLogs ? 'hasAuditLogAccess' : ''}`}
        primary size={"large"} floated={"right"} onClick={() => openDataTableForm()} >Add Data Table</Button>
    </Segment>
  }

}
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';

interface Props {
  loading: boolean;
}
const LoaderComponent: React.FC<Props> = (props) => {
  const { loading = false } = { ...props };
  return (
    <>
      {loading &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
    </>
  )
}
export default React.memo(LoaderComponent);
import React, { createRef, ReactElement } from 'react';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import SVGWrapper from '../../icons/svg/svg-wrapper';
import { icons } from '../../icons';
import { Grid, Image, Popup } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../api-request/upload';
import ConfigService from '../../config';
import { CSSProperties } from 'react-router/node_modules/@types/react';

interface IFileField {
  data: IFieldData;
  detail: FieldElement;
}

const containerRef = createRef<HTMLDivElement>();
const valueRef = React.createRef<HTMLSpanElement>();

const style: CSSProperties = {
  display: 'flex',
  width: '45px',
  backgroundColor: '#E8EDF1',
  padding: '5px',
  alignItems: 'center',
  marginRight: '5px',
  borderRadius: '40%',
  justifyContent: 'space-around',
  fontWeight: 600,
  fontSize: '13px'
}

const FileCustomField: React.FC<IFileField> = (props) => {
  const mime = require('mime-types');
  const value = props.data.fields ? props.data.fields[props.detail.id] || '' : '';
  const valueConfig = props.detail.configName;

  const valueComponent = <div ref={containerRef} style={{ textAlign: props.detail.inputConfig.config.alignment || 'center' }}
    key={`column-${props.detail.id}-${props.data.id}`}>
    <span id={`value-${props.detail.id}-${props.data.id}`} ref={valueRef}></span>
  </div>

  const openDocumentAttachment = (value: UploadedFile) => {
    const documentsAttachmentData: UploadedFile = value
    sessionStorage.setItem('selectedAttachment', JSON.stringify(documentsAttachmentData));
    window.open(`${ConfigService.loadConfig().general.siteUrl}/attachment/${documentsAttachmentData.id}`, '_blank');
  }

  const popUpFlowing = (icon: string, files: UploadedFile[], index?: number): ReactElement => {
    return (
      <Popup
        key={`file-${props.detail.id}-${props.data.id}-${index}`}
        flowing hoverable
        trigger={
          <div className='file-component' style={style}>
            <Image src={icon} width="15px" />
            {files.length}
          </div>
        }
      >
        <Grid centered divided columns={1}>
          <Grid.Column >
            {
              files.map((file: UploadedFile) => {
                return <p
                  key={file.id}
                  onClick={() => openDocumentAttachment(file)}
                  style={{ display: 'flex', cursor: 'pointer' }}>
                  <Image src={icon} width="15px" style={{ marginRight: '5px' }} />
                  <span style={{ color: '#4183c4' }}>{file.name}</span>
                </p>
              })
            }
          </Grid.Column>
        </Grid>
      </Popup>
    )
  }

  const renderFileUploadValueIcon = (type: string, files: UploadedFile[], index: number): ReactElement => {
    const fileType = mime.extension(type);
    switch (fileType) {
      case 'pdf':
        return (popUpFlowing(icons.other.pdf, files, index))
      case 'docx':
        return (popUpFlowing(icons.other.word, files, index))
      case 'ppt':
        return (popUpFlowing(icons.other.ppt, files, index))
      case 'xlsx':
      case 'xls':
        return (popUpFlowing(icons.other.excel, files, index))
      case 'jpeg':
      case 'png':
      case 'gif':
        return (popUpFlowing(icons.other.png, files, index))
      default:
        return (popUpFlowing(icons.other.unknown, files, index))
    }
  }

  const groupBy = (items: UploadedFile[], key: keyof UploadedFile) => items.reduce(
    (result: any, item: UploadedFile) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const renderFileUploadMultipleValue = (files: UploadedFile[]): ReactElement => {
    const groupFilesByType = groupBy(
      files
        .filter((file: UploadedFile) => {
          return file !== null
        }), 'type')

    return (
      <div style={{ display: '-webkit-box' }}>
        {
          Object.keys(groupFilesByType).map((value: string, index) => {
            return (
              renderFileUploadValueIcon(value, groupFilesByType[value], index)
            )
          })
        }
      </div>
    )
  }

  const renderFileUploadValue = (file: UploadedFile): ReactElement => {
    return (renderFileUploadValueIcon(file.type, [file], -1))
  }

  if (valueConfig === 'File Upload' && value !== '') {
    if (Array.isArray(value)) {
      return renderFileUploadMultipleValue(value)
    } else {
      return renderFileUploadValue(value)
    }
  }

  if (valueConfig === 'Signature') {
    return (
      <div key={`column-${props.detail.id}-${props.data.id}`} style={{ textAlign: props.detail.inputConfig.config.alignment || 'center' }}>
        <span id={`value-${props.detail.id}-${props.data.id}`} ref={valueRef}>
          <SVGWrapper iconName='signature' width='30' height='30' color={(value === '' ? '#737373' : '#000')} />
        </span>
      </div>
    )
  }
  return valueComponent
}

export default FileCustomField;
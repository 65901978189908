import { icons } from '../../../../../../../../common/icons';
import { ICustomFilterField, ICustomFilterContainer } from './interface/custom-filter-field';
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_FORM_FIELD_LIST = 'app/app/admin-module/business-process/configuration/custom-filter/GET_FORM_FIELD_LIST';
export const GET_CUSTOM_FILTER_LIST = 'app/app/admin-module/business-process/configuration/custom-filter/GET_CUSTOM_FILTER_LIST';
export const GET_CUSTOM_FILTER_LIST_RETURN = 'app/app/admin-module/business-process/configuration/custom-filter/GET_CUSTOM_FILTER_LIST_RETURN';
export const GET_FORM_FIELD_LIST_RETURN = 'app/app/admin-module/business-process/configuration/custom-filter/GET_FORM_FIELD_LIST_RETURN';
export const SAVE_DRAFT_CUSTOM_FILTER = 'app/app/admin-module/business-process/configuration/custom-filter/SAVE_DRAFT_CUSTOM_FILTER';
export const SAVE_DRAFT_CUSTOM_FILTER_RETURN = 'app/app/admin-module/business-process/configuration/custom-filter/SAVE_DRAFT_CUSTOM_FILTER_RETURN';
export const SAVE_DRAFT_CUSTOM_FILTER_ERROR = 'app/app/admin-module/business-process/configuration/custom-filter/SAVE_DRAFT_CUSTOM_FILTER_ERROR';
export const PUBLISH_CUSTOM_FILTER = 'app/app/admin-module/business-process/configuration/custom-filter/PUBLISH_CUSTOM_FILTER';
export const PUBLISH_CUSTOM_FILTER_RETURN = 'app/app/admin-module/business-process/configuration/custom-filter/PUBLISH_CUSTOM_FILTER_RETURN';
export const PUBLISH_CUSTOM_FILTER_ERROR = 'app/app/admin-module/business-process/configuration/custom-filter/PUBLISH_CUSTOM_FILTER_ERROR';
export const CLEAR_SAVE_CUSTOM_FILTER_SUCCESS = 'app/app/admin-module/business-process/configuration/custom-filter/CLEAR_SAVE_CUSTOM_FILTER_SUCCESS';
export const CLEAR_SAVE_CUSTOM_FILTER_ERROR = 'app/app/admin-module/business-process/configuration/custom-filter/CLEAR_SAVE_CUSTOM_FILTER_ERROR';

export const customFilterSingleField: ICustomFilterField[] = [
  {
    itemType: 'field',
    type: 'searchBox',
    label: 'Search Box',
    icon: icons.blue.magnifier,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: true,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'number',
    label: 'Number',
    icon: icons.blue.number,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '6px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'date',
    label: 'Date',
    icon: icons.blue.calendar,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: true,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'time',
    label: 'Time',
    icon: icons.blue.clock,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'dropdown',
    label: 'Dropdown',
    icon: icons.blue.dropdown,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'fileupload',
    label: 'File Upload',
    icon: icons.blue.paperclip,
    iconStyle: { width: '17px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'switch',
    label: 'Switch',
    icon: icons.blue.off,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '5px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field',
    type: 'date-time',
    label: 'Date/Time',
    icon: icons.blue.dateTime,
    iconStyle: { width: '17px', float: 'left', marginRight: '4px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  }
];

export const customFilterRangeField: ICustomFilterField[] = [
  {
    itemType: 'field-range',
    type: 'dateRange',
    label: 'Date Range',
    icon: icons.blue.calendar,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: true,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field-range',
    type: 'timeRange',
    label: 'Time Range',
    icon: icons.blue.clock,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field-range',
    type: 'numberRange',
    label: 'Number Range',
    icon: icons.blue.number,
    iconStyle: { width: '15px', float: 'left', marginRight: '5px', marginTop: '6px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  },
  {
    itemType: 'field-range',
    type: 'date-timeRange',
    label: 'Date/Time Range',
    icon: icons.blue.dateTime,
    iconStyle: { width: '17px', float: 'left', marginRight: '4px', marginTop: '2px' },
    show: false,
    name: '',
    fields: [],
    isMultiSelection: false,
  }
];

export const defaultFilterList: ICustomFilterContainer = {
  '0': undefined,
  '1': undefined,
  '2': undefined,
  '3': undefined,
  '4': undefined,
  '5': undefined,
  '6': undefined,
  '7': undefined,
};

export const systemFieldLabel: { [x: string]: string } = {
  'createdAt': 'Doc. Creation Date',
  'updatedAt': 'Last Update Date',
  'creator': 'Doc. Creator',
  'updatedBy': 'Last Updated By',
  'owner': 'Owner',
  'status': 'Status'
}

export const customFilterActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_CUSTOM_FILTER_SUCCESS,
    type: 'success',
    reducerContainer: 'customFilter',
    reducerItem: 'saveCustomFilterMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_CUSTOM_FILTER_ERROR,
    type: 'negative',
    reducerContainer: 'customFilter',
    reducerItem: 'saveCustomFilterErrorMessage',
    hasCloseIcon: true
  }
]
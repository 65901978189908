import moment from 'moment';
import { IInputConfig } from '../../../../field-element';
import { ComputeDate } from '../../../enum-type/utils/compute-date';

interface IUtils {
  inputConfig: IInputConfig;
  minDate: any;
  maxDate: any;
  fromRange?: boolean;
  parentRef?: any;
  forwardedRef?: any;
  setMinDate: (value: any) => void;
  setMaxDate: (value: any) => void;
  setExcludeDates: (value: Date[] | undefined) => void;
}

export const setMinMaxDateState = ({
  inputConfig,
  minDate,
  maxDate,
  parentRef,
  forwardedRef,
  fromRange,
  setExcludeDates,
  setMaxDate,
  setMinDate
}: IUtils) => {
  const { config } = { ...inputConfig };
  let tempMinDate = moment.isMoment(minDate) ? ComputeDate.toISOString(minDate.format('MM/DD/YYYY')) : minDate;
  let tempMaxDate = moment.isMoment(maxDate) ? ComputeDate.toISOString(maxDate.format('MM/DD/YYYY')) : maxDate;

  if (config && config.min) {
    const min = ComputeDate.compute({
      config: config.min,
      dynamicRef: fromRange ? parentRef : forwardedRef,
      dateFormat: 'MM/DD/YYYY',
      fromRange: fromRange,
      noWeekends: config.noWeekends
    });
    if (min) {
      setMinDate(min);
    }
    tempMinDate = min;
  }
  if (config && config.max) {
    const max = ComputeDate.compute({
      config: config.max,
      dynamicRef: fromRange ? parentRef : forwardedRef,
      dateFormat: 'MM/DD/YYYY',
      fromRange: fromRange,
      noWeekends: config.noWeekends
    });
    if (max) {
      setMaxDate(max);
    }
    tempMaxDate = max;
  }
  if (inputConfig.config.noWeekends) {
    const largeUnits = ['months', 'years']
    let startDate = tempMinDate;
    let endDate = tempMaxDate;
    if (!tempMinDate || (tempMinDate && config.min && config.min.value && largeUnits.indexOf(config.min.value.right.unit) > -1)) {
      startDate = moment().startOf('month').startOf('week').format('MM/DD/YYYY');
    }
    if (!tempMaxDate || (tempMaxDate && config.max && config.max.value && largeUnits.indexOf(config.max.value.right.unit) > -1)) {
      endDate = moment().endOf('month').endOf('week').format('MM/DD/YYYY');
    }
    const weekends = ComputeDate.getWeekends(startDate, endDate);
    setExcludeDates(weekends);
  }
}
import { useEffect } from 'react';
import {
  getFormFieldListAction,
  getCustomFilterListAction
} from '../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetCustomFilterData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (company && formId) {
      dispatch(getFormFieldListAction({ company: company, formId: formId }));
      dispatch(getCustomFilterListAction({ company: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId])
}

import {
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_RETURN,
  GET_USER_SETTINGS_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_RETURN,
  UPDATE_USER_SETTINGS_ERROR,
  CLEAR_UPDATE_USER_SETTINGS_SUCCESS,
} from './constant';
import { message } from './message';

export interface INoticationsEmails {
  enabled: boolean;
  formFollower: boolean;
  formIndividualOwner: boolean;
  formCoOwner: boolean;
  mentions: boolean;
}

export interface IUserSettings {
  notificationsEmail: INoticationsEmails
}

export interface IUserSettingsReducer {
  userSettings: IUserSettings;
  userSettingsLoading: boolean;
  userSettingsError: string;
  updateUserSettingsMessage: string;
}

const initialState: IUserSettingsReducer = {
  userSettings: {
    notificationsEmail: {
      enabled: false,
      formFollower: false,
      formIndividualOwner: false,
      formCoOwner: false,
      mentions: false,
    }
  } as IUserSettings,
  updateUserSettingsMessage: '',
  userSettingsLoading: false,
  userSettingsError: '',
}

const userSettingsReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_USER_SETTINGS:
      newState.userSettingsError = '';
      newState.userSettingsLoading = true;
      return newState;

    case GET_USER_SETTINGS_RETURN:
      newState.userSettings = action.data
      newState.userSettingsError = '';
      newState.userSettingsLoading = false;

      return newState;

    case GET_USER_SETTINGS_ERROR:
      newState.userSettingsError = action.message;
      newState.userSettingsLoading = false;
      return newState;

    case UPDATE_USER_SETTINGS:
      newState.userSettingsError = '';
      newState.userSettingsLoading = true;
      return newState;

    case UPDATE_USER_SETTINGS_RETURN:
      newState.userSettings = action.data;
      newState.updateUserSettingsMessage = message.updateUserSettingsSuccess;
      newState.userSettingsLoading = false;
      newState.userSettingsError = '';
      return newState;

    case UPDATE_USER_SETTINGS_ERROR:
      newState.userSettingsError = action.message;
      newState.userSettingsLoading = false;
      return newState;

    case CLEAR_UPDATE_USER_SETTINGS_SUCCESS:
      newState.updateUserSettingsMessage = '';
      return newState;

    default:
      return state;
  }
}

export default userSettingsReducer;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../common/icons';

interface IStakeHolderProfileDetail {
  headerName: string;
}

const PortalSHProfileDetail: React.FC<IStakeHolderProfileDetail> = (props) => {
  return <div className='sh-profile-detail'>
    <div className='sh-profile-header'>
      <div className='sh-profile-header-name'>{props.headerName.toUpperCase()}</div>
      <div className='sh-profile-header-action'>
        <i className="ellipsis vertical icon" />
      </div>
    </div>
    <div className='sh-profile-content'>
      <div className='info'>
        <Image src={icons.black.interact} />
        <div className='name'>Business Process Sales Order</div>
      </div>
      <div className='description'>
        Lorem ipsum dolor
      </div>
    </div>
  </div>
}

export default PortalSHProfileDetail;
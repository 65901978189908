import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { FieldConstruct } from '../../../../../../../../../../../../../common/field/field-construct';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { FormEnumLabel } from '../../../../../../../../../../../../document-module/module/form/utils/manage-enum-id-label';
import { setRowRefState } from '../../../../../../../../../../../../document-module/module/form/utils/manage-form-data';
import { IField, IFieldAccessType } from '../../../../../../../../../../users/interface/field';
import { IForm } from '../../../../../../../../form/interface/form';
import { IFormStatusWithAction } from '../../../../../../../../workflow/interface/form-status';
import { DetailActionButton } from '../../../action-button';
import { useGetFormStatusOption } from '../../../hooks/use-get-form-status-option';
import { useGetInputFieldOption } from '../../../hooks/use-get-input-field-option';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from '../registry';
import { useSetComponentToRefs } from './hooks/use-set-component-to-refs-and-state';
import { useSetInitialData } from './hooks/use-set-initial-data';
import SelectedFieldSection from './selected-field';

class CreateDocument {

  name = 'create-document';

  render: React.FC<IActionTypeRegistryRender> = (props) => {

    let onComponentLeave = setTimeout(() => { }, 1000);

    const dynamicRef = useRef({});
    const tableFocusRef: any = useRef({});
    const tableRowRef: any = useRef({});

    const [refresher, setRefresher] = useState(0);
    const [sectionExpanded, setSectionExpanded] = useState<{ [id: string]: boolean }>({});

    const { company } = useSelector((state: IRootState) => state.auth);

    const {
      publishedFormList,
      publishedFormStatusList,
      inputFieldList,
    } = useSelector((state: IRootState) => state.automation);

    const { getInputField, detailInputFieldList } = useGetInputFieldOption({
      inputFieldList,
      company
    });

    const { getFormStatus, detailFormStatusList } = useGetFormStatusOption({
      company,
      publishedFormStatusList
    })

    const {
      selectedFields,
      setSelectedField,
      selectedBP,
      landingStatus,
      manageLandingStatus,
      manageSelectBP,
      copyFromObject,
      setCopyFromObject
    } = useSetInitialData({
      selectedComponent: props.selectedComponent,
      getInputField,
      getFormStatus,
      hasComponentChange: props.hasComponentChange,
      inputFieldList: detailInputFieldList,
    });

    useSetComponentToRefs({
      dynamicRef,
      inputFieldList: detailInputFieldList,
      selectedComponent: props.selectedComponent,
    });

    const triggerExpandSection = (value: boolean, id: string) => {
      const tempExpandedSection = { ...sectionExpanded };
      tempExpandedSection[id] = value;
      setSectionExpanded(tempExpandedSection);
    }

    const getBPOptions = () => {
      return publishedFormList.map((form: IForm) => {
        return {
          key: uuid(),
          value: form.id,
          text: form.name
        }
      })
    }

    const getLandingStatusOption = () => {
      return detailFormStatusList[selectedBP]
        ? detailFormStatusList[selectedBP].map((status: IFormStatusWithAction) => {
          return {
            key: uuid(),
            value: status.id,
            text: status.name
          }
        })
        : [];
    }

    const getNonRequiredFieldOption = () => {
      return detailInputFieldList[selectedBP]
        ? detailInputFieldList[selectedBP]
          .filter((field: IField) => field.configName !== IConfigName.GAP && field.configName !== IConfigName.Seq)
          .filter((field: IField) => field.accessType !== IFieldAccessType.Required && field.accessType !== IFieldAccessType.Hidden)
          .map((field: IField) => {
            return {
              key: uuid(),
              value: field.id,
              text: field.label
            }
          })
        : []
    }

    const getRequiredFieldOption = () => {
      return detailInputFieldList[selectedBP]
        ? detailInputFieldList[selectedBP]
          .filter((field: IField) => field.accessType === IFieldAccessType.Required)
        : []
    }

    const getFieldDataFromRefs = () => {
      const requiredFields = detailInputFieldList[selectedBP].filter((field: IField) => field.accessType === IFieldAccessType.Required);
      const selectedFieldsData = detailInputFieldList[selectedBP].filter((field: IField) => selectedFields.indexOf(field.id || '') > -1);
      const fieldList = [...selectedFieldsData, ...requiredFields] || [];

      let fieldData = FieldConstruct.getFieldDataFromRefs(fieldList, dynamicRef, undefined, 'submit');

      const hasEnumSelected = fieldList.filter(e => e.configName === IConfigName.Dropdown);
      const fields = hasEnumSelected.length > 0 ? FormEnumLabel.getEnumIdLabel(fieldData, dynamicRef, fieldList) : fieldData;

      const fieldValues = Object.entries(fields).reduce((field: any, [key, value]: any) => {
        field[key] = value;
        if (value && value instanceof Array && value.length === 0) {
          field[key] = null;
        }
        if (value && value instanceof Object && Object.keys(value).length === 0) {
          field[key] = null;
        }
        if (typeof value === 'string' && !value) {
          field[key] = null;
        }
        return field;
      }, {});

      return fieldValues;
    }

    const setLandingStatus = (target: any) => {
      dynamicRef.current = {};
      manageLandingStatus(selectedBP, target.value, false, true, true);
    }

    const setSelectedBP = (target: any) => {
      dynamicRef.current = {};
      manageSelectBP(target.value, true, true);
    }

    const validationError = () => {
      const emptyFields = [] as string[];

      const requiredFields = detailInputFieldList[selectedBP].filter((field: IField) => field.accessType === IFieldAccessType.Required);
      const value = getFieldDataFromRefs();

      requiredFields.forEach(fields => {
        const fieldId = fields.id as string;
        const data = value[fieldId];
        if (!data || (data instanceof Object && Object.keys(data).length === 0)
          || (Array.isArray(data) && data.length === 0) || data === undefined) {
          const exists = emptyFields.find(e => e === fieldId);
          if (!exists) {
            emptyFields.push(fieldId);
          }
        }
      });

      return emptyFields.length > 0 ? 'Please fill-up all mandatory fields before saving.' : null;
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.formId = selectedBP;
        tempComponent.config.landingStatusId = landingStatus;
        tempComponent.config.selectedFields = selectedFields;
        tempComponent.config.fields = getFieldDataFromRefs();
        tempComponent.config.validationError = validationError();
        props.updateCurrentAutomation(tempComponent, true);
      }
    }

    const manageSelectedField = (value: string[]) => {
      setSelectedField(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const storeDynamicField = () => {
      if (props.hasComponentChange.current === false) {
        props.parentRefresher();
        setRefresher(refresher + 1);
      }
      props.hasComponentChange.current = true;
    }

    const handleTableRowState = (fieldId: string, value: { minRows: number; maxRows: number; }) => {
      tableRowRef.current[fieldId] = value;
      setRefresher(refresher + 1);
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    useEffect(() => {
      if (detailInputFieldList[selectedBP] &&
        detailInputFieldList[selectedBP].length > 0) {
        setRowRefState(tableRowRef, detailInputFieldList[selectedBP]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailInputFieldList, selectedBP])

    return (<>
      <div className={`automation-form-detail-action create-document`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Create Document`}
          description={`Create a new Document in:`}
          iconHeight={20}
          iconWidth={18}
          iconName='newDoc'
        />
        <div className={`automation-field-container two`}>
          <div className={`automation-field`}>
            <label>Business Process <span className={`required`}>*</span></label>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={`Business Process Name`}
              search
              selectOnBlur={false}
              value={selectedBP}
              options={getBPOptions()}
              onChange={(event, target: any) => { setSelectedBP(target) }}
            />
          </div>
          <div className={`automation-field`}>
            <label>Landing Status <span className={`required`}>*</span></label>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={`Select Status`}
              search
              multiple={false}
              selectOnBlur={false}
              value={landingStatus}
              options={getLandingStatusOption()}
              onChange={(event, target: any) => { setLandingStatus(target) }}
            />
          </div>
        </div>
        <div className={`automation-field-container`}>
          <div className={`automation-field field-selection`}>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={`Select Non Mandatory Fields`}
              search
              multiple
              selectOnBlur={false}
              value={selectedFields}
              options={getNonRequiredFieldOption()}
              onChange={(event, target: any) => { manageSelectedField(target.value) }}
            />
          </div>
        </div>
        <SelectedFieldSection
          key={uuid()}
          selectedBP={selectedBP}
          dynamicRef={dynamicRef}
          storeDynamicField={storeDynamicField}
          company={company}
          hasComponentChange={props.hasComponentChange}
          copyFromObject={copyFromObject}
          setCopyFromObject={setCopyFromObject}
          handleTableRowState={handleTableRowState}
          tableFocusRef={tableFocusRef}
          tableRowRef={tableRowRef}
          fieldList={getRequiredFieldOption()}
          headerName='Mandatory Fields'
          setSectionExpanded={triggerExpandSection}
          sectionExpanded={sectionExpanded}
          id='mandatory'
        />
        <SelectedFieldSection
          key={uuid()}
          selectedBP={selectedBP}
          dynamicRef={dynamicRef}
          storeDynamicField={storeDynamicField}
          company={company}
          hasComponentChange={props.hasComponentChange}
          copyFromObject={copyFromObject}
          setCopyFromObject={setCopyFromObject}
          handleTableRowState={handleTableRowState}
          tableFocusRef={tableFocusRef}
          tableRowRef={tableRowRef}
          fieldList={detailInputFieldList[selectedBP] ? detailInputFieldList[selectedBP].filter(field => {
            return selectedFields.includes(field.id || '');
          }) : []}
          headerName='Non Mandatory Fields'
          setSectionExpanded={triggerExpandSection}
          sectionExpanded={sectionExpanded}
          id='nonMandatory'
        />
      </div >
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}



export default CreateDocument;
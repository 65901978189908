import React from 'react';
import { Form } from 'semantic-ui-react-bpm';
import { IOptionWidget } from '../../../interface/option-widget';
import { triggerOptionList } from '../../../constant';
import { IAutomation } from '../../../interface/automation';
import AutomationDetailHeader from '../widget/detail-header-widget';
import AutomationDetailContent from '../widget/detail-content-widget';
import { TriggerTypeRegistry } from './triggerType/registry';
import { IAutomationComponent } from '../../../interface/component-list';
import { DetailActionButton } from '../action-button';

interface IMainTriggerProps {
  error: any;
  ruleNameRef: React.MutableRefObject<any>;
  descriptionRef: React.MutableRefObject<any>;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  resetTriggerIfEmpty(): void;
  selectedTrigger: IOptionWidget | undefined;
  selectTrigger(e: IOptionWidget): void;
  selectedComponent: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  saveClick(): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
  setRefresher(value: number): void;
  refresher: number;
}

const MainTrigger: React.FC<IMainTriggerProps> = (props) => {

  const hasChange = () => {
    if (props.hasComponentChange.current === false) {
      props.setRefresher(props.refresher + 1);
      props.parentRefresher()
    }
    props.hasComponentChange.current = true;
  }

  const noSelectedTrigger = () => {
    return <>
      <div className='automation-form-detail-trigger'>
        <Form>
          <Form.Group widths='equal'>
            <div className={`required ten wide field ${props.error.ruleName ? 'error' : ''}`}>
              <label>Rule Name</label>
              <div className="ui input">
                <input
                  ref={props.ruleNameRef}
                  onBlur={() => props.resetTriggerIfEmpty()}
                  onChange={hasChange}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal'>
            <div className="ten wide field">
              <label>Description</label>
              <textarea
                rows={3}
                ref={props.descriptionRef}
                onChange={hasChange}
              ></textarea>
            </div>
          </Form.Group>
        </Form>

        <AutomationDetailHeader
          title={`New Trigger`}
          description={`Triggers start the execution of a rule. Triggers can listen for events or be scheduled to run.`}
          iconHeight={30}
          iconWidth={30}
          iconName='trigger'
          noBorderContainer={true}
        />
        <AutomationDetailContent
          recommended={triggerOptionList.filter((e: IOptionWidget) => e.recommended === true)}
          allComponent={triggerOptionList.filter((e: IOptionWidget) => !e.recommended)}
          selected={props.selectedTrigger}
          setSelected={props.selectTrigger}
          contentScreen='Triggers'
        />
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => props.saveClick()} />
      }
    </>
  }

  const withSelectedTrigger = (selectedTrigger: IOptionWidget) => {
    return <TriggerTypeRegistry.Render
      type={selectedTrigger.name}
      currentAutomationRef={props.currentAutomationRef}
      selectedComponent={props.selectedComponent}
      updateCurrentAutomation={props.updateCurrentAutomation}
      hasComponentChange={props.hasComponentChange}
      parentRefresher={props.parentRefresher}
    />
  }

  return (<>
    {!props.selectedTrigger
      ? noSelectedTrigger()
      : withSelectedTrigger(props.selectedTrigger)
    }
  </>
  );
}

export default MainTrigger;
import io from 'socket.io-client';
import ConfigService from '../config';

export class RealtimeService {

  static shared = new RealtimeService()

  currentConnection?: SocketIOClient.Socket;

  private host?: string;

  constructor() {
    const urlString = ConfigService.loadConfig().general.apiBaseUrl;
    if (urlString) {
      const baseUrl = new URL(urlString);
      this.host = baseUrl.host;
    }
  }

  setToken(token: string): void {
    this.closeConnection();
    if (token && token.length > 0) {
      this.setupConnection(token);
    }
  }

  private setupConnection(token: string): void {
    if (!this.host) {
      // Skip connection if host wasn't specified
      return;
    }

    if (this.currentConnection) {
      this.closeConnection();
    }
    this.currentConnection = io(this.host, {
      transports: ['websocket'],
      query: `token=${token}`,
      timeout: 60000,
    });

    // Allow polling in case websocket failed due to firewall or something
    this.currentConnection.on('reconnect_attempt', () => {
      this.currentConnection!.io.opts.transports = ['polling', 'websocket'];
    });

    // Show errors in console for debug purposes:
    this.currentConnection.on('exception', (message: string) => {
      console.error(`[Socket] Internal Server Error: `, message);
    });
    this.currentConnection.on('bad-request', (message: string) => {
      console.error(`[Socket] Bad Request: `, message);
    });

  }

  private closeConnection(): void {
    this.currentConnection?.close();
    this.currentConnection = undefined;
  }



}
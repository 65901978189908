import moment from 'moment';
import { useEffect, useState } from 'react';
import { IWorkingHours } from '../interface/status-task-list';

interface IProps {
  workingHours: IWorkingHours;
}

export const useSetSLAStartAndEndTime = (props: IProps) => {
  const { workingHours } = { ...props };

  const [startTime, setStartTime]: any = useState(null);
  const [endTime, setEndTime]: any = useState(null);

  useEffect(() => {
    if (workingHours.startTime) {
      let dateToday = moment().format("YYYY-MM-DD");
      let yourDate = new Date(dateToday + ' ' + workingHours.startTime);
      setStartTime(yourDate);
    } else {
      setStartTime(null);
    }

    if (workingHours.endTime) {
      let dateToday = moment().format("YYYY-MM-DD");
      let yourDate = new Date(dateToday + ' ' + workingHours.endTime);
      setEndTime(yourDate);
    } else {
      setEndTime(null);
    }
  }, [workingHours])

  return { ...props, startTime, setStartTime, endTime, setEndTime };
}
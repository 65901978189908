import { IInputConfig } from '../../../field-element';
import { ComputeDate } from '../../../object/enum-type/utils/compute-date';

interface IUtils {
  inputConfig: IInputConfig;
  minDate: any;
  maxDate: any;
  fromRange?: boolean;
  parentRef?: any;
  forwardedRef?: any;
  setMinDate: (value: any) => void;
  setMaxDate: (value: any) => void;
}

export const setMinMaxDateState = ({
  inputConfig,
  minDate,
  maxDate,
  parentRef,
  forwardedRef,
  fromRange,
  setMaxDate,
  setMinDate
}: IUtils) => {
  const { config } = { ...inputConfig };

  if (config && config.min) {
    const min = ComputeDate.compute({
      config: config.min,
      dynamicRef: fromRange ? parentRef : forwardedRef,
      dateFormat: 'MM/DD/YYYY',
      fromRange: fromRange,
      noWeekends: config.noWeekends
    });
    if (min) {
      setMinDate(min);
    }
  }
  if (config && config.max) {
    const max = ComputeDate.compute({
      config: config.max,
      dynamicRef: fromRange ? parentRef : forwardedRef,
      dateFormat: 'MM/DD/YYYY',
      fromRange: fromRange,
      noWeekends: config.noWeekends
    });
    if (max) {
      setMaxDate(max);
    }
  }
}
import { useEffect, useState } from 'react';
import { IInternalDatatablePermission } from '../../interface/data-table-permission';

interface IProps {
  datatablePermission: IInternalDatatablePermission;
}

export const useSetDatatablePermission = (props: IProps) => {

  let [viewPermission, setViewPermission]: any = useState([]);
  let [modifyPermission, setModifyPermission]: any = useState([]);

  useEffect(() => {
    setViewPermission(props.datatablePermission.view);
    setModifyPermission(props.datatablePermission.modify);
  }, [props.datatablePermission])

  return { viewPermission, setViewPermission, modifyPermission, setModifyPermission, ...props };
}
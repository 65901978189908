import {
  GET_BUSINESS_PROCESS_OPTION,
  GET_BUSINESS_PROCESS_OPTION_RETURN,
  GET_BUSINESS_PROCESS_OPTION_ERROR,
  CREATE_APP,
  CREATE_APP_RETURN,
  CREATE_APP_ERROR,
  GET_APP_LIST,
  GET_APP_LIST_RETURN,
  GET_APP_LIST_ERROR,
  CLEAR_APP_MESSAGE,
  GET_APP_DETAILS,
  GET_APP_DETAILS_RETURN,
  GET_APP_DETAILS_ERROR,
  DELETE_APP,
  DELETE_APP_CONFIRM,
  DELETE_APP_CONFIRM_RETURN,
  DELETE_APP_CONFIRM_ERROR,
  DELETE_APP_CANCEL,
  UPDATE_APP,
  UPDATE_APP_RETURN,
  UPDATE_APP_ERROR
} from './constant'
import { IBusinessProcessOptionDetailInput } from './interface/input/business-process-option'
import { IBusinessProcessOptionReturn } from './interface/response/business-process-option'
import { IAppDetailCreateInput } from './interface/input/app-create'
import { IAppListDetailInput } from './interface/input/app-list'
import { IAppListResponse } from './interface/response/app-list'
import { IAppGetDetailsInput } from './interface/input/app-details'
import { IAppDeleteInput } from './interface/input/app-delete'
import { IAppConfirmDeleteDetailsInput } from './interface/input/app-confirm-delete'
import { IAppConfirmDeleteResponse } from './interface/response/app-confirm-delete'
import { IAppDetailUpdateInput } from './interface/input/app-update'
import { IAppDetailsResponse } from './interface/response/app-details'
import { IAppUpdateResponse } from './interface/response/app-update'
import { IAppCreateResponse } from './interface/response/app-create'

export const getBusinessProcessOptionAction = (payload: IBusinessProcessOptionDetailInput) => {
  return { type: GET_BUSINESS_PROCESS_OPTION, ...payload }
}

export const getBusinessProcessOptionReturnAction = (payload: IBusinessProcessOptionReturn) => {
  return { type: GET_BUSINESS_PROCESS_OPTION_RETURN, ...payload }
}

export const getBusinessProcessOptionErrorAction = () => {
  return { type: GET_BUSINESS_PROCESS_OPTION_ERROR }
}

export const createAppAction = (payload: IAppDetailCreateInput) => {
  return { type: CREATE_APP, ...payload }
}

export const createAppReturnAction = (payload: IAppCreateResponse) => {
  return { type: CREATE_APP_RETURN, ...payload }
}

export const createAppErrorAction = (payload: {}) => {
  return { type: CREATE_APP_ERROR, ...payload }
}

export const getAppListAction = (payload: IAppListDetailInput) => {
  return { type: GET_APP_LIST, ...payload }
}

export const getAppListReturnAction = (payload: IAppListResponse) => {
  return { type: GET_APP_LIST_RETURN, ...payload }
}

export const getAppListErrorAction = (payload: {}) => {
  return { type: GET_APP_LIST_ERROR, ...payload }
}

export const clearAppMessageAction = () => {
  return { type: CLEAR_APP_MESSAGE }
}

export const getAppDetailsAction = (payload: IAppGetDetailsInput) => {
  return { type: GET_APP_DETAILS, ...payload }
}

export const getAppDetailsReturnAction = (payload: IAppDetailsResponse) => {
  return { type: GET_APP_DETAILS_RETURN, ...payload }
}

export const getAppDetailsErrorAction = (payload: {}) => {
  return { type: GET_APP_DETAILS_ERROR, ...payload }
}

export const deleteAppAction = (payload: IAppDeleteInput) => {
  return { type: DELETE_APP, ...payload }
}

export const confirmDeleteAppAction = (payload: IAppConfirmDeleteDetailsInput) => {
  return { type: DELETE_APP_CONFIRM, ...payload }
}

export const confirmDeleteAppReturnAction = (payload: IAppConfirmDeleteResponse) => {
  return { type: DELETE_APP_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteAppErrorAction = (payload: {}) => {
  return { type: DELETE_APP_CONFIRM_ERROR, ...payload }
}

export const cancelDeleteAppAction = () => {
  return { type: DELETE_APP_CANCEL }
}

export const updateAppAction = (payload: IAppDetailUpdateInput) => {
  return { type: UPDATE_APP, ...payload }
}

export const updateAppReturnAction = (payload: IAppUpdateResponse) => {
  return { type: UPDATE_APP_RETURN, ...payload }
}

export const updateAppErrorAction = (payload: {}) => {
  return { type: UPDATE_APP_ERROR, ...payload }
}
import React from 'react';
import { IDisplayDataConfigKeypaths, IDisplayDataFields, IDisplayDataConfig } from '../../interface/display-data';

export const renderFields = (fields: IDisplayDataFields, displayDataConfigKeypath: IDisplayDataConfigKeypaths[]) => {
  return fields.config.map((field, idx) => {
    const data = displayDataConfigKeypath.find(e => e.keyPath === field);
    const separator = idx === (fields.config.length - 1) ? '' : ';';
    return <span key={`display-data-fields-${idx}`}>
      {data && data.name ? `${data.name}${separator} ` : <span className='deleted-flag'>Deleted Field</span>}
    </span>
  });
}

export const renderMetaFields = (fields: IDisplayDataConfig[], displayDataConfigKeypath: IDisplayDataConfigKeypaths[]) => {
  return fields.map((field, idx) => {
    const even = idx % 2 === 0;
    const data = displayDataConfigKeypath.find(e => e.keyPath === field.keyPath);
    const separator = idx === (fields.length - 1) ? '' : ';';
    return <span key={`display-data-fields-${idx}`} className={!even ? 'right' : 'left'}>
      {data && data.name ? `${data.name}${separator} ` : <span className='deleted-flag'>Deleted Field</span>}
    </span>
  });
}
import { IField } from '../../../../admin-module/module/users/interface/field';
import { IOptionValue } from '../../../../../common/custom-field-config/interface/custom-field-detail-config';

export class FormEnumLabel {
  static getUniqueEnumItem = (items: any[], key: any) => {
    if (typeof key !== 'function') {
      const property = key;
      key = function (item: any) { return item[property]; };
    }
    return Array.from(items.reduce(function (map, item) {
      const k = key(item);
      if (!map.has(k)) map.set(k, item);
      return map;
    }, new Map()).values());
  }

  static getTableEnumLabel = (fields: { [field: string]: any }, tableFields: IField[] | undefined, fieldRef: any) => {
    const fieldIds = Object.keys(fields);
    let newField = { ...fields };
    if (tableFields && tableFields.length > 0) {
      fieldIds.forEach((id: string) => {
        const fieldData = fields[id];
        const hasEnum = tableFields.filter((field: IField) => field.id === id && field.inputConfig?.type === 'enum')
        if (hasEnum.length > 0 && newField[id]) {
          let { inputConfig: { config: { items } } }: any = { ...hasEnum[0] };
          const isInstanceOfArray = newField[id].some((e: any) => { return e instanceof Array });
          if (fieldData && fieldData instanceof Array) {
            for (let i = 0; i < fieldData.length; i++) {
              const refData = fieldRef[i] && fieldRef[i].current && fieldRef[i].current[id];
              if (refData && refData.filteredOption && refData.filteredOption.length > 0) {
                const enumItems: any[] = FormEnumLabel.getUniqueEnumItem([...refData.filteredOption], 'id');
                items = items.concat(enumItems);
              }
            }
          }
          const uniqueItems = FormEnumLabel.getUniqueEnumItem([...items], 'id') as IOptionValue[];
          if (isInstanceOfArray) {
            newField[id] = newField[id].map((value: string[] | null) => {
              return value && value instanceof Array && value.length > 0 ? uniqueItems.filter((item: IOptionValue) => value.indexOf(item.id) > -1) : null;
            });
          } else {
            newField[id] = newField[id].map((value: string | null) => {
              return value ? uniqueItems.filter((item: IOptionValue) => value === item.id)[0] : null;
            });
          }
        }
      })
    }
    return newField;
  }
  static getEnumIdLabel = (fields: { [field: string]: any }, dynamicRefs: any, formField: IField[]) => {
    const fieldIds = Object.keys(fields);
    let newField = { ...fields };
    let tableEnumValues: { [field: string]: any } = {};

    fieldIds.forEach((id: string) => {
      const refData = dynamicRefs.current[id];
      const hasEnum = formField.filter((field: IField) => field.id === id && field.inputConfig?.type === 'enum')
      const tableField = formField.filter((field: IField) => field.id === id && field.inputConfig?.type === 'table')
      tableField.forEach((tableField: IField) => {
        if (tableField.inputConfig && tableField.inputConfig.type === 'table') {
          const { columns } = { ...tableField.inputConfig.config }
          tableEnumValues = fields[tableField.id || ''];
          newField[id] = FormEnumLabel.getTableEnumLabel(tableEnumValues, columns, refData)
        }
      });
      if (hasEnum.length > 0 && hasEnum[0] && newField[id]) {
        if (refData && !refData.filteredOption && !refData.selectedOptionFilter && hasEnum[0].inputConfig?.config.items.length > 0) {
          const enumItems: any[] = FormEnumLabel.getUniqueEnumItem([...hasEnum[0].inputConfig?.config.items || []], 'id');
          if (newField[id] instanceof Array) {
            newField[id] = enumItems.filter((item: IOptionValue) => newField[id].indexOf(item.id) > -1);
          } else {
            if (typeof newField[id] === 'string') {
              newField[id] = enumItems.filter((item: IOptionValue) => newField[id] === item.id)[0];
            } else {
              if (newField[id].hasOwnProperty('id')) {
                newField[id] = enumItems.filter((item: IOptionValue) => newField[id].id === item.id)[0];
              }
            }
          }
        }
        if (refData && refData.filteredOption && refData.filteredOption.length > 0) {
          let selectedOptionFilter = refData.selectedOptionFilter || [];
          if (refData.selectedOptionFilter && !Array.isArray(refData.selectedOptionFilter)) {
            selectedOptionFilter = [refData.selectedOptionFilter];
          }
          const enumItems: any[] = FormEnumLabel.getUniqueEnumItem([...refData.filteredOption || [], ...selectedOptionFilter, ...hasEnum[0].inputConfig?.config.items || []], 'id');
          if (newField[id] instanceof Array) {
            newField[id] = enumItems
              .filter((item: IOptionValue) => newField[id].indexOf(item.id) > -1)
              .map((item: IOptionValue) => { return { id: item.id, label: item.label } });

          } else {
            if (typeof newField[id] === 'string') {
              newField[id] = enumItems
                .filter((item: IOptionValue) => newField[id] === item.id)
                .map((item: IOptionValue) => { return { id: item.id, label: item.label } })[0];
            } else {
              if (newField[id].hasOwnProperty('id')) {
                newField[id] = enumItems
                  .filter((item: IOptionValue) => newField[id].id === item.id)
                  .map((item: IOptionValue) => { return { id: item.id, label: item.label } })[0];
              }
            }
          }
        }
        if (refData && refData.selectedOptionFilter && Array.isArray(refData.selectedOptionFilter) &&
          refData.selectedOptionFilter.length > 0 && hasEnum[0] && hasEnum[0].inputConfig &&
          hasEnum[0].inputConfig.config && hasEnum[0].inputConfig.config.multiselect) {
          newField[id] = refData.selectedOptionFilter;
        }
      }
    });
    return newField;
  }
}
import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../interface/section';

interface IProps {
  sectionList: IFormBuilderSection[];
}

export const useSetCurrentFieldList = (props: IProps) => {
  const { sectionList } = { ...props };

  const [currentFieldList, setCurrentFieldList] = useState<ICustomFieldDetail[]>([]);

  useEffect(() => {
    /**
     * Get all field from section so we can get all 
     * the field even the newly added and not being saved yet
     */
    const tempCurrentFieldList: ICustomFieldDetail[] = [];
    sectionList.forEach((e: IFormBuilderSection) => {
      const tempSection = { ...e };
      if (tempSection.fields) {
        tempSection.fields.forEach((f: TFormBuilderSectionRow) => {
          const tempSectionRow = [...f];
          tempSectionRow.forEach((g: ICustomFieldDetail) => {
            if (!g.deleted) {
              tempCurrentFieldList.push({ ...g });
            }
          })
        })
      }
    })
    setCurrentFieldList(tempCurrentFieldList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sectionList)]);

  return { currentFieldList, setCurrentFieldList, ...props };
}
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { ICustomFilterField } from '../interface/custom-filter-field';
import { DragSourceMonitor, useDrag } from 'react-dnd';

const Field: React.FC<ICustomFilterField> = (props) => {
  // eslint-disable-next-line
  const [draggedObject, dragRef] = useDrag({
    item: { type: 'customFilterField', itemType: 'field', item: props, new: true },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  if (!props.show) {
    return <></>;
  }

  return (
    <div ref={dragRef} style={{ opacity: draggedObject.opacity }} className='field'>
      <Image src={props.icon}
        style={props.iconStyle}
      />
      {props.label}
      <Image src={icons.blue.dragIcon}
        style={{ width: '10px', float: 'right', marginTop: '4px' }}
      />
    </div>
  );
}

export default Field;

import React from 'react';
import { FieldElement, IConfig } from '../../../field-element';
import { AcceptedProps } from '../../interface/accepted-props';
import { setRangeFieldBaseOnType } from './utils/set-range-field-type';

interface IRangeFieldProps extends AcceptedProps {
  fieldConfig: IConfig;
  placeHolder: string;
  name: string;
}

const RangeFieldType: React.FC<IRangeFieldProps> = (props) => {
  const name = props.name;
  const fieldData = setRangeFieldBaseOnType({ ...props.fieldConfig }, props.name, props.placeHolder, props.defaultValue);
  const field = FieldElement.getDataElement({ ...fieldData } as any);
  const Element = field.getFieldElement();

  if (!props.forwardedRef.current[name][props.placeHolder]) {
    props.forwardedRef.current[name][props.placeHolder] = {};
  }

  if (!props.forwardedRef.current[name][props.placeHolder].current) {
    props.forwardedRef.current[name][props.placeHolder].current = {};
  }

  return <Element
    {...props}
    {...field}
    hasPageChangeRef={props.hasPageChangeRef}
    forwardedRef={props.forwardedRef.current[name][props.placeHolder]}
    defaultValueElement={false}
    fieldCollection={props.fieldCollection}
    isAddRecord={props.isAddRecord}
    hasIconInside
    havePlaceholder
    placeHolder={props.placeHolder}
    fromRange
    parentRef={props.forwardedRef}
  />
}

export default RangeFieldType;
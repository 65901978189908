import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { ICommentAttachment } from '../../../../../interface/comment';

export const renderAttachmentWithPreview = (attachment: ICommentAttachment) => {
  return (
    <div className={`attachment-preview-container ${attachment.type}`}>
      <Image src={attachment.url} />
    </div>
  );
};
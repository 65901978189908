import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ActionPermission } from '../../../../../../document-module/module/form/automation/components/action/set-action-permission/set-action-permission.action.config';
import { IFieldLayoutObject, IFieldsLayout, IForm } from '../../form/interface/form';
import { IFormAction } from '../../workflow/interface/form-action';
import { IFormStatusWithAction } from '../../workflow/interface/form-status';
import { IAccessLevelCondition, IAccessLevelConfig, IApiPermission, IApiPermissionObject, IApiSection } from '../interface/api-permission';
import { AccessLevel, IConsolidateTableConfig, IPermissionFieldCondition, IPermissionFieldFilterConfig, IStatusFieldPermission } from '../interface/field-permission';
import { IFormPermissionSection, ISection, IStatusAction, ITablePermission } from '../interface/section';

interface IUtils {
  formDetail: IForm;
  formDetailField: ICustomFieldDetail[];
  formStatuses: IFormStatusWithAction[];
  permissionList: IApiPermissionObject[];
}

interface ITableUtils {
  tableFields: ICustomFieldDetail[];
  statusPermission: IApiPermissionObject[];
  fieldLayout: IFieldsLayout[];
  formDetail: IForm;
}

export class StructurePermissionList {
  setPermission = ({ formDetail, formDetailField, formStatuses, permissionList }: IUtils) => {
    let formPermissionData = [] as IFormPermissionSection[];
    formStatuses.forEach((status: IFormStatusWithAction) => {
      let sections = [] as ISection[];
      let tablePermission = [] as ITablePermission[];
      let actions = [] as IStatusAction[];

      let permissionStatus = permissionList.filter((permission: IApiPermissionObject) => permission.statusId === status.id);

      if (permissionStatus.length > 0 && permissionStatus[0].actions) {
        actions = this.setActionPermission(status.actions, permissionStatus[0].actions, formDetail);
      }

      let referToStatusId = permissionStatus.length > 0 ? permissionStatus[0].referToStatusId : '';
      if (referToStatusId) {
        permissionStatus = permissionList.filter((permission: IApiPermissionObject) => permission.statusId === referToStatusId);
      }

      const tableFields = formDetailField.filter(e => e && e.type === 'table');
      if (tableFields.length > 0) {
        tablePermission = this.setTablePermission({
          tableFields: tableFields,
          statusPermission: permissionStatus,
          fieldLayout: formDetail.fieldsLayout as IFieldsLayout[],
          formDetail
        });
      }

      if (formDetail.fieldsLayout && formDetail.fieldsLayout) {
        formDetail.fieldsLayout.forEach((fieldsLayout: IFieldsLayout) => {
          let hasPermissionSectionField: IApiSection[] = [];
          if (permissionStatus.length > 0 && permissionStatus[0]
            && permissionStatus[0].sections && permissionStatus[0].sections.length) {
            hasPermissionSectionField = permissionStatus[0].sections.filter((permSection: IApiSection) => permSection.id === fieldsLayout.id);
          }
          let sectionFields = [] as ICustomFieldDetail[];
          let permissions = [] as IStatusFieldPermission[];
          let allFieldsAccessLevel: string | IPermissionFieldFilterConfig = '';

          if (hasPermissionSectionField.length > 0) {
            if (typeof hasPermissionSectionField[0].allFieldsAccessLevel === 'object') {
              allFieldsAccessLevel = this.setPermissionConfig(hasPermissionSectionField[0].allFieldsAccessLevel, formDetail.id || '');
            } else {
              allFieldsAccessLevel = hasPermissionSectionField[0].allFieldsAccessLevel as string;
            }
          }

          fieldsLayout.body.forEach((fieldContainer: IFieldLayoutObject) => {
            fieldContainer.fields.forEach((field: string) => {
              let hasfieldPermission: IApiPermission[] = [];
              let accessLevel: string | IAccessLevelConfig = 'editable';
              let config = null as IPermissionFieldFilterConfig | null;

              if (hasPermissionSectionField.length > 0) {
                if (hasPermissionSectionField[0].fields && hasPermissionSectionField[0].fields.length > 0) {
                  hasfieldPermission = hasPermissionSectionField[0].fields.filter((fieldPermission: IApiPermission) => fieldPermission.id === field);

                  if (hasfieldPermission.length > 0) {
                    if (typeof hasfieldPermission[0].accessLevel === 'object') {
                      const accessLevelObject = hasfieldPermission[0].accessLevel as IAccessLevelConfig;
                      config = this.setPermissionConfig(accessLevelObject, formDetail.id || '');
                    } else {
                      accessLevel = hasfieldPermission[0].accessLevel
                    }
                  }
                }

                if (hasPermissionSectionField[0].allFieldsAccessLevel) {
                  if (typeof hasPermissionSectionField[0].allFieldsAccessLevel === 'object') {
                    const accessLevelObject = hasPermissionSectionField[0].allFieldsAccessLevel as IAccessLevelConfig;
                    config = this.setPermissionConfig(accessLevelObject, formDetail.id || '');
                  } else {
                    const accessLevelString = hasPermissionSectionField[0].allFieldsAccessLevel as string;
                    accessLevel = accessLevelString;
                  }
                }
              }
              const fieldExists = formDetailField.find(e => e.id === field);
              if (fieldExists) {
                permissions.push({
                  fieldId: field,
                  accessLevel: config ? 'editable' : accessLevel,
                  config: config ? config : undefined
                });
                formDetailField.forEach((customField: ICustomFieldDetail) => {
                  if (customField.id === field) {
                    sectionFields.push(customField);
                  }
                });
              }
            });
          });
          sections.push({
            id: fieldsLayout.id,
            title: fieldsLayout.title,
            fields: sectionFields,
            permissions: permissions,
            accessLevelPerStatus: allFieldsAccessLevel,
            style: fieldsLayout.style
          })
        })
      }
      formPermissionData.push({
        statusId: status.id,
        statusName: status.name,
        sections: sections,
        referToStatusId: referToStatusId || '',
        table: tablePermission,
        actions: actions
      });
    });
    return formPermissionData;
  }
  setTablePermission = ({ tableFields, statusPermission, fieldLayout, formDetail }: ITableUtils) => {
    let tablePermision = [] as ITablePermission[];
    tableFields.forEach(field => {
      let tableFieldList = [] as ICustomFieldDetail[];
      let permissions = [] as IStatusFieldPermission[];
      let tableAccessLevel: string | IPermissionFieldFilterConfig = '';
      let columnPermissions = [] as IApiPermission[];
      let consolidateTable = undefined as IConsolidateTableConfig | undefined;

      let tableBelongSection = fieldLayout.find(layout => layout.body.filter(body => body.fields.filter(f => f === field.id).length > 0).length > 0) as IFieldsLayout;
      let accessLevelPermission = statusPermission.map(status => {
        const belongSectionPermission = status.sections && status.sections.find(sec => sec.id === tableBelongSection.id) as IApiSection;
        const tableFieldPermission = belongSectionPermission && belongSectionPermission.fields && belongSectionPermission.fields.find(e => e.id === field.id);

        if (tableFieldPermission && tableFieldPermission.columnPermissions) {
          columnPermissions = tableFieldPermission.columnPermissions;
        }
        if (belongSectionPermission && belongSectionPermission.allFieldsAccessLevel) {
          return belongSectionPermission.allFieldsAccessLevel;
        }
        if (tableFieldPermission && tableFieldPermission.consolidateTable) {
          consolidateTable = tableFieldPermission.consolidateTable;
        }
        return tableFieldPermission && tableFieldPermission.accessLevel ? tableFieldPermission.accessLevel : 'editable';
      })[0] as string | IAccessLevelConfig;

      if (accessLevelPermission) {
        if (typeof accessLevelPermission === 'object') {
          tableAccessLevel = this.setPermissionConfig(accessLevelPermission, formDetail.id || '');
        } else {
          if (accessLevelPermission !== AccessLevel.Editable) {
            tableAccessLevel = accessLevelPermission;
          }
        }
      }

      if (field && field.config && field.config.columns && field.config.columns.length > 0) {
        field.config.columns.forEach(columnFields => {
          const permission = columnPermissions.find(e => e.id === columnFields.id);
          let accessLevel: string | IAccessLevelConfig = 'editable';
          let config = null as IPermissionFieldFilterConfig | null;
          let fields = { ...columnFields, isTableField: true } as ICustomFieldDetail;

          if (permission) {
            if (typeof permission.accessLevel === 'object') {
              config = this.setPermissionConfig(permission.accessLevel, formDetail.id || '');
            } else {
              accessLevel = permission.accessLevel;
            }
          }

          permissions.push({
            fieldId: columnFields.id || '',
            accessLevel: config ? 'editable' : accessLevel,
            config: config ? config : undefined,

          });

          tableFieldList.push(fields);
        });

        tablePermision.push({
          id: field.id || '',
          title: field.label || '',
          fields: tableFieldList,
          permissions: permissions,
          accessLevelPerTable: tableAccessLevel,
          consolidateTable: consolidateTable,
        });
      }
    });

    return tablePermision;
  }
  setPermissionConfig = (accessLevelObject: IAccessLevelConfig, formId: string) => {
    let config = {} as IPermissionFieldFilterConfig;
    const { conditions, joint, conditionRule } = { ...accessLevelObject };
    if (conditions && conditions.length > 0) {
      const conditionPermission = conditions.map((con: IAccessLevelCondition) => {
        return {
          id: uuid(),
          fieldId: con.keyPath,
          fromBp: formId,
          operator: con.operator,
          compareType: con.valueType,
          compareValue: con.valueType === 'value' ? con.value : '',
          compareField: {
            fieldId: con.valueType === 'field' ? con.value : ''
          }
        }
      }) as IPermissionFieldCondition[];
      config = {
        condition: conditionPermission,
        joinCondition: joint,
        conditionRule: conditionRule
      }
    }
    return config;
  }

  setActionPermission = (dynamicActions: IFormAction[], actionPermission: IApiPermission[], formDetail: IForm) => {
    let actions = [] as IStatusAction[];
    dynamicActions.forEach(action => {
      const permission = actionPermission.find(e => e.id === action.id);

      let accessLevel: string | IAccessLevelConfig = ActionPermission.Enabled;
      let config = undefined as IPermissionFieldFilterConfig | undefined;

      if (permission) {
        if (typeof permission.accessLevel === 'object') {
          config = this.setPermissionConfig(permission.accessLevel, formDetail.id || '');
        } else {
          accessLevel = permission.accessLevel;
        }
      }
      if (action.type === 'dynamic') {
        actions.push({
          id: action.id,
          name: action.name,
          permissions: { accessLevel, config }
        });
      }
      return undefined;
    })
    return actions;
  }
}

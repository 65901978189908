import { useEffect, useState } from 'react';
import { IAutomationChildComponent, IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
  selectedChildComponent?: IAutomationChildComponent | undefined;
}

export const useSetIfElseBlockTitle = (props: IProps) => {
  const { selectedComponent, selectedChildComponent } = { ...props };

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (selectedComponent && !props.selectedChildComponent) {
      setTitle('If block');
    }
    if (selectedComponent && selectedChildComponent) {
      const component = selectedChildComponent.component;
      const rowIndex = selectedChildComponent.rowIndex;
      const elseRowIndex = selectedChildComponent.elseRowIndex;

      if (!component && !rowIndex && elseRowIndex !== undefined) {
        const selectedElse = selectedComponent.config.elseConditionList[elseRowIndex];
        if (selectedElse.conditionList && selectedElse.conditionList.length > 0) {
          setTitle('Else If block');
        } else {
          setTitle('Else block');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent, selectedChildComponent]);

  return { ...props, title, setTitle }
}
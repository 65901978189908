import React from 'react';
import TableHeaderDetail from './table-header-detail';
import { IFormStatus } from '../interface/form-status';
import { IFormAction } from '../interface/form-action';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';

interface ITableHeaderProps {
  formStatusList: IFormStatus[],
  editStatus(formStatus: IFormStatus): void;
  actionList: IFormAction[];
  sortStatus(drag: { type: string, dragIndex: number, dragItem: IFormStatus }, dropAreaIndex: number): void;
  addNewStatus(): void;
}

const tableHeader: React.FC<ITableHeaderProps> = (props) => {
  return (
    <tr>
      {
        props.formStatusList.map((formStatus: IFormStatus, index: number) => {
          if (formStatus.deleted === true) {
            return undefined;
          }

          let color = 'bg-blue';
          let textColor = 'text-white';
          let iconColor = '#fff';
          let isShownHamburger = true;

          if (formStatus.initial) {
            color = 'bg-gray';
            textColor = 'text-blue';
            isShownHamburger = false;
            iconColor = '#3a85a8';
          }

          if (formStatus.final && !formStatus.finalPositive) {
            color = 'bg-red';
          }

          if (formStatus.finalPositive && formStatus.final) {
            color = 'bg-green';
          }

          return <TableHeaderDetail
            key={`table-header-form-status-${formStatus.id}`}
            buttonColor={color}
            labelColor={textColor}
            formStatus={formStatus}
            isShownHamburger={isShownHamburger}
            iconColor={iconColor}
            editStatus={props.editStatus}
            formStatusIndex={index}
            sortStatus={props.sortStatus}
          />
        })
      }
      <th id='last-child' className="last-child" onClick={() => { props.addNewStatus() }}>
        <div id='add-new-status' style={{ whiteSpace: 'normal', display: 'flex', alignItems: 'center' }} className="ui label">
          <label className="text-white header-text">
            <SVGWrapper iconName='plus' width={15} height={15} color='#fff' />
            <span>NEW STATUS</span>
          </label>
        </div>
      </th>
    </tr >
  )
}

export default tableHeader;
import { useEffect, useState } from 'react';
import { FieldConstruct } from '../../../../../../../../../../../../../../common/field/field-construct';
import { IField } from '../../../../../../../../../../../users/interface/field';
import { IAutomationComponent } from '../../../../../../interface/component-list';
import { IAutomationInputFieldList } from '../../../../../../reducer';

interface IProps {
  selectedComponent: IAutomationComponent;
  inputFieldList: IAutomationInputFieldList;
  dynamicRef: React.MutableRefObject<{}>;
  match: { params: { formId: string } };
}

export const useSetEditFormRefsAndState = (props: IProps) => {
  const { inputFieldList, selectedComponent, dynamicRef, match } = { ...props };

  const [selectedField, setSelectedField] = useState<string[]>([])
  const [operation, setOperation] = useState<{ [id: string]: string }>({});
  const [dateFieldObject, setDateFieldObject] = useState<{ [id: string]: boolean }>({});

  const getSelectedFieldList = (fieldList: string[]) => {
    const fields: IField[] = [];
    fieldList.forEach((id: string) => {
      const field = inputFieldList[match.params.formId].find((e: IField) => e.id === id);
      if (field) {
        fields.push(field)
      }
    })
    return fields;
  }

  useEffect(() => {
    if (selectedComponent) {
      let tempDateFieldObj = {} as { [id: string]: boolean };

      const fields = selectedComponent.config.fields || {};
      const tempContainer: { [id: string]: string } = {};
      const tempSelectedField = Object.keys(fields) || [];

      tempSelectedField.forEach((id: string) => {
        if (fields[id] && (fields[id].hasOwnProperty('keyPath') || fields[id].hasOwnProperty('referencedKeyPath'))) {
          tempContainer[id] = 'copy';
        } else {
          tempContainer[id] = 'value';
        }
      });

      setSelectedField(tempSelectedField);
      setOperation(tempContainer);
      setDateFieldObject(tempDateFieldObj);
      getSelectedFieldList(tempSelectedField).forEach(field => {
        if (field.inputConfig && field.inputConfig.type === 'date' && fields[field.id || '']) {
          tempDateFieldObj[field.id || ''] = fields[field.id || ''] === 'today';
        }
      });
      FieldConstruct.setFieldToRefs(getSelectedFieldList(tempSelectedField), { fields }, dynamicRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedField,
    setSelectedField,
    operation,
    setOperation,
    getSelectedFieldList,
    dateFieldObject,
    setDateFieldObject
  };
}
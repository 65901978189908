import { put, takeLatest } from 'redux-saga/effects';
import { IPublishedFormInput } from '../../../../../admin-module/module/business-process/module/form-builder/interface/input/published-form-get';
import { getFormFieldReturnAction } from './action/get-form-field';
import { getPortalPageLayoutReturnAction } from './action/get-page-layout';
import { getPublishedFormReturnAction } from './action/get-publish-form';
import { GET_FORM_FIELD_LIST, GET_PAGE_LAYOUT, GET_PUBLISHED_FORM_LIST } from './constant';
import { IGetFormFieldInput } from './interface/input/form-field-get';
import { IPortalPageLayout } from './interface/page-layout';

export function* getFormFieldList(args: IGetFormFieldInput) {
  const data: any = [
    {
      "id": "d403a45b-e12b-46b5-96e4-f8ccd04438ad",
      "type": "string",
      "label": "Supplier Name",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "none",
        "alignment": "left",
        "maxLength": 6,
        "minLength": 2,
        "displayType": "paragraph"
      },
      "flags": null,
      "configName": "String"
    },
    {
      "id": "88b87aec-6ea1-4311-b298-dafdc3714dbc",
      "type": "array",
      "label": "Supplier Name MultiLine",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "string",
        "itemConfig": {
          "format": "none",
          "alignment": "left",
          "maxLength": 6,
          "minLength": 2,
          "displayType": "paragraph"
        }
      },
      "flags": null,
      "configName": "String"
    },
    {
      "id": "9c4d49f0-7f8d-4e5e-8121-b4b766fefc78",
      "type": "string",
      "label": "email",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "email",
        "displayType": "line"
      },
      "flags": null,
      "configName": "Email"
    },
    {
      "id": "8468443f-d1ad-41dd-ae61-3dbf4487da45",
      "type": "array",
      "label": "email array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "string",
        "itemConfig": {
          "format": "email",
          "displayType": "line"
        }
      },
      "flags": null,
      "configName": "Email"
    },
    {
      "id": "0532ede0-9a8a-4947-9ec6-874abec34312",
      "type": "string",
      "label": "url",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "url",
        "displayType": "line"
      },
      "flags": null,
      "configName": "URL"
    },
    {
      "id": "d5bb6caf-0d03-4671-8a0d-ffba99aa5720",
      "type": "array",
      "label": "url array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "string",
        "itemConfig": {
          "format": "url",
          "displayType": "line"
        }
      },
      "flags": null,
      "configName": "URL"
    },
    {
      "id": "f4ee2968-5e8f-40b2-ab7e-4d7537efcb00",
      "type": "date",
      "label": "date",
      "hint": "",
      "defaultValue": null,
      "config": {

      },
      "flags": null,
      "configName": "Date"
    },
    {
      "id": "c1a5f647-02db-4c0c-9271-3fcc298e34fb",
      "type": "array",
      "label": "date array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "date",
        "itemConfig": {

        }
      },
      "flags": null,
      "configName": "Date"
    },
    {
      "id": "37c70b64-9c1d-4ad1-8808-f225baf77423",
      "type": "time",
      "label": "time",
      "hint": "",
      "defaultValue": null,
      "config": {

      },
      "flags": null,
      "configName": "Time"
    },
    {
      "id": "7fa1df85-6573-4122-a1a5-5a954777a054",
      "type": "array",
      "label": "time array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "time",
        "itemConfig": {

        }
      },
      "flags": null,
      "configName": "Time"
    },
    {
      "id": "17bfbaca-8636-407f-ac22-e8fd4f347b96",
      "type": "string",
      "label": "no digit",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "allowedChars",
        "alignment": "left",
        "maxLength": 256,
        "minLength": 1,
        "displayType": "line",
        "allowedChars": [
          "uppercase",
          "lowercase",
          "whitespace",
          "special"
        ]
      },
      "flags": null,
      "configName": "Text String"
    },
    {
      "id": "f54a3422-b6f5-4c0b-b5b0-98176950c355",
      "type": "string",
      "label": "no uppercase",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "allowedChars",
        "alignment": "left",
        "maxLength": 256,
        "minLength": 1,
        "displayType": "line",
        "allowedChars": [
          "lowercase",
          "whitespace",
          "digits",
          "special"
        ]
      },
      "flags": null,
      "configName": "Text String"
    },
    {
      "id": "55d84cd9-a392-4099-8450-258fea4e6077",
      "type": "string",
      "label": "Text",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "none",
        "alignment": "left",
        "maxLength": 6,
        "minLength": 2,
        "displayType": "line"
      },
      "flags": null,
      "configName": "Text String"
    },
    {
      "id": "47fa59b6-716b-4ecd-9e51-2641cd3214d1",
      "type": "array",
      "label": "text array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "string",
        "itemConfig": {
          "format": "none",
          "alignment": "left",
          "maxLength": 6,
          "minLength": 2,
          "displayType": "line"
        }
      },
      "flags": null,
      "configName": "Text String"
    },
    {
      "id": "f8e41168-7c11-48b7-bfb0-9a557512fee6",
      "type": "string",
      "label": "text area ",
      "hint": "",
      "defaultValue": null,
      "config": {
        "format": "none",
        "alignment": "left",
        "maxLength": 6,
        "minLength": 2,
        "displayType": "paragraph"
      },
      "flags": null,
      "configName": "Text String Multiline"
    },
    {
      "id": "7323c7ea-bede-42f0-8c96-186d928dbc87",
      "type": "array",
      "label": "text area array",
      "hint": "",
      "defaultValue": [

      ],
      "config": {
        "itemType": "string",
        "itemConfig": {
          "format": "none",
          "alignment": "left",
          "maxLength": 6,
          "minLength": 2,
          "displayType": "paragraph"
        }
      },
      "flags": null,
      "configName": "Text String Multiline"
    },
    {
      "id": "35fab686-32db-4e45-8e50-e76c13889a24",
      "type": "enum",
      "label": "Barangay",
      "hint": "",
      "defaultValue": null,
      "config": {
        "data": {
          "companyId": "ca7d350b-4a7d-427a-bb6d-1350b79cb8c0",
          "databaseId": "7889f457-391b-4beb-b409-38e7f07f720c"
        },
        "dataType": "database",
        "labelType": "string",
        "separator": "",
        "displayType": "dropdown",
        "labelFormat": "{{fields.31305596-96c1-40e9-ba0b-d79166c18ad6}}",
        "multiselect": false,
        "dataFiltersJoint": "or"
      },
      "flags": null,
      "configName": "Referenced"
    },
    {
      "id": "da6cb046-f678-4e72-acf2-c729b8a67d69",
      "type": "enum",
      "label": "BP",
      "hint": "",
      "defaultValue": null,
      "config": {
        "data": {
          "formId": "5caaadbc-a9dc-4c9f-92bc-92c32d32d737",
          "companyId": "ca7d350b-4a7d-427a-bb6d-1350b79cb8c0"
        },
        "dataType": "document",
        "labelType": "no-type",
        "separator": "",
        "displayType": "dropdown",
        "labelFormat": "{{status.name}}",
        "multiselect": false,
        "dataFiltersJoint": "or"
      },
      "flags": null,
      "configName": "Referenced"
    }
  ];
  yield put(getFormFieldReturnAction({
    formFieldList: data
  }));
}

export function* getpublishFormList(args: IPublishedFormInput) {
  const data: any = [
    {
      "id": "e5519ca0-4d06-4bf2-94dd-7909f740feae",
      "abbr": "ASD1",
      "description": "asdfsdf",
      "seq": 1,
      "name": "ASD1",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "2fa8e17d-fb84-425b-81e0-ddffcf6d6b13",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "90fb067b-0332-4e5e-9c6b-a8eaa4038e22",
                "6f0f979c-0527-4740-bf62-daa3f579479f"
              ]
            },
            {
              "fields": [
                "29876e03-820b-4eb4-bdf4-8f98c3e022b9"
              ]
            }
          ],
          "style": "grouped"
        },
        {
          "id": "eed1f6ae-b58a-4628-9136-1140bf45251f",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "27d0e622-523d-44f9-a0a4-dfee04359f46",
                "050264b3-cf7f-469a-a195-9667830ce2dc",
                "2bc151e1-2567-4e29-b3ab-75004b79a797",
                "856eecc1-0bdd-482e-a9f8-7f09ed6cd559"
              ]
            },
            {
              "fields": [
                "7f834944-5fb1-4f19-b5ee-e1ffee4c3971",
                "4d409627-1b1f-4b4a-af8b-a92a1cd9d950",
                "375ef49e-bcec-42c3-95b2-56b3a7715791",
                "56836cb3-8d14-4db4-b99a-a662fe777563"
              ]
            },
            {
              "fields": [
                "cd145778-3ce7-4af4-bf29-605841b121ff",
                "f47473da-a834-4742-8df8-7e77ef3c5188",
                "9ded7809-9d9f-463d-ae1a-d44104042f4f",
                "65312383-4130-412a-9ac9-f433726d47f6",
                "0f7508e5-7bbe-4b72-bbab-b7c1c096edad",
                "aa4c0cea-a525-4bf8-a0c1-d796a1de3eb9",
                "3eb55572-23c9-4b5d-a0ee-3436ae9c24a9",
                "ada80853-3c42-4368-8933-13bf540b6c8a"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "cf7661a0-6612-4ef6-9ecb-bc473c10cfb4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "4c4497dd-bd57-4d4a-8146-3b1a80434663"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "85eac6c8-06db-4821-b518-018de0b13bf5",
        "5abe7bfa-789f-4a7e-af29-1ac62517b393",
        "4520aff5-aca7-49f0-a814-25dd77e0d1bc"
      ]
    },
    {
      "id": "05814704-28c9-4835-bdd6-3c64542f82ab",
      "abbr": "BP2",
      "description": "asfasdf",
      "seq": 3,
      "name": "BP2",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "d8811af7-9e19-45fa-83c7-ae21fb3b5259",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "841a70cb-9d57-4af6-8c13-2498576d48d9",
                "d9162fe4-3a61-437b-a238-c843b2e0bcff",
                "5075351f-a2a4-45e7-84ed-17ab9cc81cb4",
                "2b2c7f18-4a62-4dc4-9704-69d7986eddb1"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "7a251945-dcbc-4d4b-b5b8-14a6b754c967",
        "2efcd25c-5e20-46d5-ab66-38a61969f5f2",
        "05d96595-9c48-4fff-aa71-793c03e0cfd8",
        "4174fdcb-9c29-41ba-a8bf-a54821fff4e2"
      ]
    },
    {
      "id": "9e29a1b0-b4e5-4508-9582-f6096801696c",
      "abbr": "BPM5",
      "description": "bpm5",
      "seq": 5,
      "name": "BPM5",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "9e12b3b7-f3aa-4725-a6f2-d089895be78c",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1d7d1da9-a3b2-45ec-a79f-84a5bb10a1f0"
              ]
            },
            {
              "fields": [
                "fdea036b-8712-4f7b-937d-61dbede2e8e2"
              ]
            },
            {
              "fields": [
                "03bb4984-c3d6-441b-9c63-ccc359e74e5c"
              ]
            },
            {
              "fields": [
                "fcb3fa5f-d10f-4519-83ec-eac407706f48"
              ]
            },
            {
              "fields": [
                "87a67714-3ca5-4aac-9473-77faed78f5ab"
              ]
            },
            {
              "fields": [
                "d91cda5a-17fc-4c63-b9da-9c4c5ad9a182"
              ]
            },
            {
              "fields": [
                "b4253708-765f-4581-89d7-f18b2550d493"
              ]
            },
            {
              "fields": [
                "f3411b73-eb7a-4090-b533-2c85bec0e01a"
              ]
            },
            {
              "fields": [
                "6b8eba5a-1fa8-4412-b07b-2a9b581ca800"
              ]
            },
            {
              "fields": [
                "9f842a40-9866-43ff-bd0a-b6c8a454398d"
              ]
            },
            {
              "fields": [
                "5bd4c45f-c9a2-4a8b-a6c7-a8133c2e893e"
              ]
            }
          ],
          "style": "grouped"
        },
        {
          "id": "34a9a9c9-e479-44d9-8608-a5d6e446bd4b",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "c9b5c82e-3ef4-4a84-8a5a-a2419961b75f",
                "e5ec47af-452f-4066-a9c7-0a28c55affff",
                "e7942775-7c81-4f44-b79e-73868c668f38"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "faf3323f-cbff-474f-b9ed-62f3e336a3db",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "63ed8187-b493-4fef-b4a1-638ca2121da8",
                "b66dc4cf-7035-4a41-8075-fe80ee1923c6",
                "8b4429d4-6f32-441d-8a26-9cf9ee96cbfe",
                "05b43dbe-7daa-4acc-9ddf-f12c7a25dc62",
                "8c634c77-1833-4e60-8096-fe8740303eb2",
                "a09cadfd-0232-4a78-a5a3-ba09ea97ac57"
              ]
            }
          ],
          "style": "grouped"
        }
      ],
      "statusLayout": [
        "d0487c99-1d38-4d16-8e3a-cfa9f5f1d05b",
        "785ec2b7-7548-43a9-9351-c50db56da98e"
      ]
    },
    {
      "id": "b6ab6691-88bc-4bb1-b90b-7eb0d526afe2",
      "abbr": "PR",
      "description": "12345",
      "seq": 7,
      "name": "Purchase Request",
      "icon": "https://bpm-dev-attachments.s3.ap-southeast-1.amazonaws.com/ca7d350b-4a7d-427a-bb6d-1350b79cb8c0/ee355865-20a3-4926-b80e-75b57fa5b0f3?AWSAccessKeyId=AKIA4Y4MREH6FOXKD4YG&Expires=1612762034&Signature=MGUjIcBngLN1tV%2FPwRNPOuRq1UE%3D",
      "fieldsLayout": [
        {
          "id": "a467129b-4321-4abd-9c4e-dbaa946f6423",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "9db92801-c1c2-4f60-8b49-db0733edc4d1",
                "dd682c90-8c5c-4702-8848-6ea0b9bf214d"
              ]
            },
            {
              "fields": [
                "8a48ea72-b509-4c19-b0d7-5df30042c35a",
                "0246308e-5a72-49dc-9313-f1e75ff78f59",
                "6b997745-af6e-435b-b01d-a3ac739637cc",
                "6c7eae23-a45b-4794-a513-2486c8f8d0ba"
              ]
            },
            {
              "fields": [
                "e35fcc89-22d7-474b-81a8-62b1fea95e5e",
                "bde46cf0-ac0c-4ee7-b420-74cb88b8af8f"
              ]
            },
            {
              "fields": [
                "ab0d792a-85d2-49df-8d86-0839f25959c2"
              ]
            },
            {
              "fields": [
                "e889a16d-3d66-4cb5-bf93-cd13b5923f88"
              ]
            },
            {
              "fields": [
                "f5e6e6e9-2e16-4bf0-b64a-afb6e5b92454"
              ]
            },
            {
              "fields": [
                "54491821-bb11-464b-a2b8-d43c333a8ba0"
              ]
            },
            {
              "fields": [
                "40c0c1cb-f804-45fc-94f5-c91345b4f0b0",
                "71b6f399-8493-4784-8359-e1ff5b3549e9",
                "f2610e4d-2913-473a-be27-341128331712"
              ]
            },
            {
              "fields": [
                "91573420-0074-4a5e-a772-d6aa00c0881d",
                "9de7ded9-75f6-4240-b85e-629b01d035f7",
                "c597ea0e-47cd-48ae-ad81-039d36ddf7d5"
              ]
            },
            {
              "fields": [
                "6d94b145-6af1-4701-8012-8fd109a0d4b4",
                "143d4860-2e3e-4bde-af10-a0888ba0c95a",
                "79ca28b7-395b-4c41-861b-200c1e50c285"
              ]
            }
          ],
          "style": "grouped"
        },
        {
          "id": "4a296a2f-c50a-4c85-b391-f5a8ca7d276f",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "d4fd3970-8b95-4eed-80aa-7b925cae6675",
                "fe07b55d-95ec-4ae8-bfb2-e8bff6272075",
                "3d1b8e57-12cc-4d06-a0f4-1d9675c0d2b9",
                "a3a92177-ca22-450f-804b-f8d13d53c16c"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "0f91f76c-5fc4-41df-90b9-ec7087750fc7",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "bedcf8c7-902b-4e30-93cc-7d587c841758"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "92e2b8c5-c286-45b5-a89b-b3611817e50a",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "5076012d-48fd-40a1-b2bc-3931db65cff0",
                "2e3b79f5-8170-473f-932e-5a98c4aaded0",
                "146ca69c-d5ce-4589-ba3f-cf17d1ab2ed8",
                "be31aef8-d10b-4442-90cf-85ca419b6f53",
                "bc01078f-0ebc-4d05-87e7-5b3439e0e721",
                "61f3d9e2-57b4-4e4d-ad0a-658871aa3a84",
                "c5d0c03d-e3f6-48f3-b84e-477b81a72270",
                "866e88bb-e7d1-4cf6-8a3c-b0bb86121837",
                "d7f359af-0e60-4f30-b362-fe94ee9ed1ab",
                "9c31132d-788f-4bd2-9f52-80778582e9d8",
                "8992d610-b71a-4d1d-982e-d8b1d2c32bb4",
                "323e3e06-86f1-42a6-932f-92492f8710ec",
                "4fc8b1f5-f865-45ac-95a3-8922b079c85e",
                "a2682570-a8dd-47d3-a0be-1b46f6c9286a",
                "f32de6a8-ad67-40f6-b2d4-ae4ba0a7e26d",
                "aa576d7a-d83f-467f-b063-89843c2f184c"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "48e2a197-391c-4001-a8d0-b553598c2d87",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "86970985-0f7e-43c4-8b41-3ea3c2014968"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "d9ad672e-cb15-4841-89ee-8f1a1ff0e15c",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "75e5651c-f3a3-4001-bdbc-c48e357e9b70"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "6371fac3-f231-48ad-8028-1f03da227ed2",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "cd530562-07d0-4460-bee9-3efc7820f05e"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "c02f463a-7748-420b-a96a-9dbbf4455ee6",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "86fe38e5-82a5-49b5-b6d3-37ce014df283"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ea1e29ed-c8f1-4103-9a51-56678c505c03",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "b0e1c81f-f38d-41dd-8383-e130cf81b7bf"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "41effe75-05ac-4784-ad01-f4967370ba7c",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "2d3880cd-399c-4f3a-8367-93fa6d826bad"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "5f9dff0b-68e6-4b42-a335-aad32ed6f485",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "a176af5c-d994-4920-86a0-fad4ab283f72"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "6b8e3f7e-e594-429b-bffb-d15f30d6dd2d",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1a1d6e33-4ee6-466a-b742-a2987528059d"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "f1cd3aad-8070-41a5-b26a-ce0c44438ab8",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "16fe7493-b757-4aac-a2ee-0fcb6b8281da"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "074da163-4fcd-4d5f-a294-75590fae8def",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1cae17ad-8eba-4f4c-a5e7-3791a35fe431"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "4428eef4-7af4-46a8-9a7b-a0b38f8eb7b1",
        "1fc8ae38-d15b-4fd1-9bd1-539f357013a4",
        "0922cef1-a92f-4b1f-a572-944ff58ee2aa"
      ]
    },
    {
      "id": "c034922f-4036-44d3-abf9-2ab3052c08d8",
      "abbr": "SEQ",
      "description": "SEQ",
      "seq": 8,
      "name": "SEQ",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "b9d0121f-b7a9-4787-9e07-4fac2842ee2f",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1bc95c16-9c0f-45a5-920a-037dd9eb4483"
              ]
            },
            {
              "fields": [
                "afcdaa94-b069-4b75-a2a1-fcbe458e97ae"
              ]
            }
          ],
          "style": "grouped"
        },
        {
          "id": "f1281b9c-816d-40b1-b6b7-892a787bf904",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "934c69be-c10d-4f0e-9680-fd57db208683"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ffaf99ed-de82-4479-a214-6d4c9e857e15",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "67f56345-213c-4e69-a12d-a6eea3a48099",
                "57719837-a87c-45d6-b1e4-5383b43de512",
                "2313c4e0-3636-4971-a2b2-02d848cc0d04",
                "f31806ee-3e82-4b6d-b839-8016446eac66",
                "f2b03ff5-d2a9-4af8-9f9f-6c4b03ca30eb"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "e822a544-bd6f-44d1-9b63-6a169d10e600",
        "be36dff8-ccbd-41f4-9db5-d58e646fd152",
        "b6d056f4-6226-4615-85f1-e348119998ee",
        "9a1411de-a0b7-43f0-bfde-ed4579faa75d",
        "bf46b77b-026b-4b45-a39a-bb15f3f6cf8f",
        "b8d15aa0-9b95-4df9-8499-ce209f7aac56",
        "34de354b-48d3-4a09-9dee-f7e1df521cd8",
        "5ac3099c-4f28-4b11-8423-03e500c79e7c",
        "0a082227-7412-49d3-9272-447e2c90b1bd",
        "66a07541-362a-49d9-b352-c9fecd922fd9",
        "0c21c2ec-1408-4d80-ac3d-b0e6851fbb7a"
      ]
    },
    {
      "id": "7d3357b9-2ca0-46d6-8ef0-b46ad4b0df3c",
      "abbr": "tst1-",
      "description": "adf",
      "seq": 9,
      "name": "test 1",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "a3a91df9-fd67-4374-92f4-6c95bbc80b6e",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "7afa5c26-a361-4c2b-9b16-7ee37f888b91"
              ]
            },
            {
              "fields": [
                "426f53c3-c4da-41f9-8323-959517e375c5"
              ]
            },
            {
              "fields": [
                "01334c17-131c-4045-bfbb-043a57b37b2c"
              ]
            },
            {
              "fields": [
                "1ff35192-1a7d-4741-a8d3-5c0fbaaf6f2e"
              ]
            },
            {
              "fields": [
                "40149147-a077-4d45-94e6-431cb3c54a46"
              ]
            },
            {
              "fields": [
                "a98eb5a3-15da-46a3-b1b9-48c8bf9137e2"
              ]
            },
            {
              "fields": [
                "cf9ae3d4-30c3-41ab-a51d-8ad0c93bf2cd"
              ]
            },
            {
              "fields": [
                "96d01373-0a31-40f2-9ab4-1810962beebc"
              ]
            },
            {
              "fields": [
                "45781325-e6e1-4cd1-98fe-201eb5013b2b"
              ]
            },
            {
              "fields": [
                "d2c7bb98-e79e-42d3-bcc2-5bb21b8f6c18"
              ]
            },
            {
              "fields": [
                "2bfad4d0-4ac8-4e97-90ac-533eebd97593"
              ]
            }
          ],
          "style": "grouped"
        }
      ],
      "statusLayout": [
        "8721d075-22ce-4d42-aa1f-412b8a046d36",
        "db89e9ae-8bac-4384-b871-4d36035532e7"
      ]
    },
    {
      "id": "240e74e4-2b90-4168-b2ec-30ebac5d0a2a",
      "abbr": "tbl",
      "description": "",
      "seq": 10,
      "name": "table setup",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "335bb009-f797-4a35-8e32-ff2b08a0e10d",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "0d154921-7da4-42ae-891c-a92c53f6ea68"
              ]
            },
            {
              "fields": [
                "b1b74050-1830-4dd1-a887-6fc2c6d7ab1c"
              ]
            },
            {
              "fields": [
                "c7ee524d-9674-4e48-8d2d-5bc57381e2f5"
              ]
            },
            {
              "fields": [
                "4a7e5c29-7088-4198-9e65-1d7d030d095a"
              ]
            },
            {
              "fields": [
                "c46afef0-4dbb-4114-96a5-d60c2d063e91"
              ]
            },
            {
              "fields": [
                "41a1d9d2-186c-4815-9b42-4fa4401c3a13"
              ]
            }
          ],
          "style": "grouped"
        },
        {
          "id": "b9b82347-cad2-4f1a-bb9d-fd55a14fff28",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "f5f5ee0f-9600-4e7d-ba5d-484a8c07043f"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "289d18f2-a669-4712-83de-bb53b1c0e3e7",
        "012f2604-284a-4888-a37f-8237b9724d96"
      ]
    },
    {
      "id": "e03a7b73-6744-46ea-8e57-3c97218c134c",
      "abbr": "AF",
      "description": "",
      "seq": 11,
      "name": "autopopulated field",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "c1218f73-d517-4367-ab82-e897e9f398f2",
          "title": "",
          "collapsable": true,
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "0e241993-45e9-4a7f-a5a3-0914a176337b"
              ]
            },
            {
              "fields": [
                "ae3400be-736a-441a-aeff-defc88e68d29"
              ]
            },
            {
              "fields": [
                "bebd0dc1-9ca2-4285-9d58-b54edc0c7d10"
              ]
            },
            {
              "fields": [
                "3d9c8a11-8889-4e11-b6a5-ea30b5a81622"
              ]
            },
            {
              "fields": [
                "1ca1ae4b-7242-4d01-b268-7acb9096ab2e"
              ]
            },
            {
              "fields": [
                "1f7edb88-53a3-42ab-b2c3-91659fd46aeb"
              ]
            },
            {
              "fields": [
                "97ec193d-5379-4b59-bfa3-005b047075a4"
              ]
            },
            {
              "fields": [
                "21f7c21e-eb32-4766-94bb-f3f708a9c175"
              ]
            }
          ],
          "style": "grouped"
        }
      ],
      "statusLayout": [
        "592ad308-edf9-4bc6-86de-e9fc79484a32",
        "2f266933-2f1f-4327-ae01-7cdf7b041d6c"
      ]
    },
    {
      "id": "8892b2d8-8948-4d74-9bc6-e10b745af2d5",
      "abbr": "a",
      "description": "",
      "seq": 13,
      "name": "asdf",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "0133641b-113b-4159-908c-ae07e33cbb8d",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "eafda8e1-761a-4050-bd20-6dd925f35aa3"
              ]
            },
            {
              "fields": [
                "587338ce-e8bb-4665-9aef-daa0e5b7194d"
              ]
            }
          ],
          "style": "grouped"
        }
      ],
      "statusLayout": [
        "f36f7b81-ac9c-4db6-bb70-0d4420427535",
        "6673bb64-076c-4421-83c4-184bc4b11c30"
      ]
    },
    {
      "id": "d8fa9893-6e2e-4064-9e73-dec86bcfa31d",
      "abbr": "FX-",
      "description": "",
      "seq": 14,
      "name": "test fx field",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "ea3dfacd-ec26-4e44-a819-511728af3efa",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "90fea425-35a6-4b9e-8867-129c75067b8b"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "a3e6a20b-e121-492d-86a6-ca0e62dce086",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "74d68778-609f-4b3a-b76d-bcd7adb3e5dc"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ee072f5e-5321-45dc-ab93-4077956e544a",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "a38ea47a-daa0-4275-b530-395567a1b2a8"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "98a61336-0dad-4b69-87cb-741f6ce25b2f",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "bda8c1fb-e2e4-4e63-932f-db50b02686e5"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "2872ecc9-2603-43a7-9c6e-d879fcaf9080",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "04b0624a-95ab-43ea-8ee0-0354b61daa4f"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "d4ee9478-d085-4629-b88e-c5a2431f4cf4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "8cc3a883-0d6f-45d1-a590-ef46bf3e5eb7"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "43f17d35-27d6-4f6f-bc56-93637c8253b0",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "bb9a1a3e-b31c-4ffe-8f09-53037396df27"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "196ae21c-1ba8-4a3e-b6a8-c0e69989d53d",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "58871a9e-a60b-41dd-aaca-776313a39ba9"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "a0791c14-da17-4446-8cfd-ad355a0736e3",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "3fe7322f-9fc7-41ee-94f0-8ce94a613eb5"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "8ef5a716-c0dc-4e92-98f3-17d4a40fdf50",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "a7b09e16-63aa-4155-afc6-ac42d96b8b79"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "83ce21a5-b88d-40bb-8d31-6eba9377c826",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "793e6a40-0cf2-4dc7-8fe8-442d1119569a"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "2ee3b5bc-0f06-44e9-9b35-69831cb99bfc",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "312848b9-466d-488e-9116-cc7b49a3b1f2"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "a1b24888-752b-45dc-8740-a8174a31ca07",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "5633e9a2-5f12-4273-b5bd-e6c823121f7e"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "95425b00-1a4b-4b5c-b820-0bdce29a8059",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "c3abd024-e3a3-42f8-839d-b6141c6c4f96"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "236eda1d-67e0-4b33-9c3f-294cf4c617f1",
        "0d3695c7-2e46-41b7-a685-763553a213d9"
      ]
    },
    {
      "id": "02db1681-ea80-4c5c-9e52-5c8c1f55eea7",
      "abbr": "FG-",
      "description": "asdf",
      "seq": 15,
      "name": "Field Gap",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "e009c5f6-0055-4f76-ba17-54ec5cc9bf56",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "30f39629-6e35-42a1-8529-6169ed19d1a2",
                "686a56eb-d4b6-40b7-bf14-7a0f4d6d7b3a",
                "ce9b6252-782f-4997-940f-e191e582af7c",
                "2be3c335-1b72-4d77-8cc2-956a263ade09"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ae2310fa-97c2-48e2-ba10-ef8f3385f438",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "019a62c8-205d-4b50-a6c4-21c4d932f98d",
                "85daf4ad-34c5-4067-b174-8c5835138947",
                "b615a240-5752-4bb5-a9ae-cd106643bc91",
                "fe133f68-78f2-40d0-8c00-21ccf2133867"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "e9b533e2-d046-407a-8a6b-541058d27a00",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "132085b7-a2c8-4ae2-a388-08abd7e911e4",
                "28869ff8-c604-4925-96ef-2d7895bacebe",
                "71609b91-b8b4-406f-8f68-99a7a03c50fc",
                "9f6e761c-2c76-44d9-a44e-7b39f0c72df7"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "05fefd3b-eab5-442c-a357-4d3a18de3fac",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "42730c9a-cbbb-4c6c-a6a8-e212a8a42f3d",
                "307af0f4-4b2a-4f65-a8de-a52d8ad3b580",
                "bb39b404-5b7f-4134-a562-6b20f58cb660",
                "68d27338-0613-4cec-a695-90ac42615d21"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "8d97035b-b78b-4531-b843-c3585e628d76",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "aa03636e-7ad2-45a9-b028-a8322b74f312",
                "3c10ea2a-87da-4479-a404-2779bcc54793",
                "f51cb0b9-93a8-4916-ae24-7ff22b414e6e",
                "2b2db1d6-aef5-483b-acae-69b501a71846"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "c55e1219-84e5-4d29-ad37-beacc37b522b",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "25dd7cfd-852d-46fc-842a-528e5797f701",
                "88244d47-63f5-42b4-85e8-307091f02485",
                "16afe992-af65-4041-bc54-fc44adc34e37"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "cd90416f-31eb-48da-87ee-b6d2e4062ca5",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "762eb4f3-36ea-4956-864f-be03a191e800"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "9126513b-6991-4fda-ad8d-6126f1c28a18",
        "bccf7fb5-ab05-4136-ab50-22356f81d6d8"
      ]
    },
    {
      "id": "e8eafd35-ecb2-47bf-8542-69cd39c843dd",
      "abbr": "NT",
      "description": "",
      "seq": 16,
      "name": "notification test",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "7c192234-446d-40ac-b0f2-03a40df28d95",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "2f276949-0712-4de1-80ba-1895117c7179"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "9893ce56-e659-40fa-863a-e80e469485ce",
        "070616c3-c335-44a5-9fe5-002d907852b4"
      ]
    },
    {
      "id": "1a168e90-641c-4dfe-b76f-5fcd982265c2",
      "abbr": "MFA",
      "description": "123sdfaasdfasdf",
      "seq": 17,
      "name": "multiple field array",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "613e99be-6436-49dc-999c-9ea5ecae5a3b",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "06203e39-9ef6-42f0-b6ee-01dacd17ed64",
                "7aa16821-01e5-43f3-a3aa-82d69d52a9b2"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ca6b5379-f806-4a59-b165-b641f7511c84",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "8dcb1a5d-0538-4efc-90c7-8f0c78083ba4"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "3e49c32e-f5ab-42a1-a578-941e12f92b81",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "01b18fa9-f338-4f98-9bc5-faa1a5f1a6a7"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ceca439d-a60e-4c89-84aa-b953a3d48eac",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "9b8d564e-3019-43d3-bc33-39a8b6a54ca4"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "a4d3ee21-64f4-4424-a12a-5fa7ff3610e4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "a8ced495-811c-4b49-b5bd-e4321161c8d5"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "ee68a6d8-ffbe-4660-bd06-b78bf72de3bb",
        "de2c1610-2433-41b1-938e-24fa54945547",
        "c41387e1-27d0-46a4-96b7-c963f4e30e17"
      ]
    },
    {
      "id": "735e8f4e-7f42-4b07-a793-a200c43dd1e3",
      "abbr": "FV",
      "description": "",
      "seq": 18,
      "name": "Field validation",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "406c6ceb-f560-4c61-a1fc-c83e1c3b22f4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "55d84cd9-a392-4099-8450-258fea4e6077",
                "47fa59b6-716b-4ecd-9e51-2641cd3214d1"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "17941a5c-f394-48e3-bb25-db1cafc3a9aa",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "f8e41168-7c11-48b7-bfb0-9a557512fee6",
                "7323c7ea-bede-42f0-8c96-186d928dbc87"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "e5b70e17-31ee-41e4-8311-921c5beef139",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "d403a45b-e12b-46b5-96e4-f8ccd04438ad",
                "88b87aec-6ea1-4311-b298-dafdc3714dbc"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "83094465-5064-4e9c-9b89-d5e538225c47",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "9c4d49f0-7f8d-4e5e-8121-b4b766fefc78",
                "8468443f-d1ad-41dd-ae61-3dbf4487da45"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "fc90b6b0-a43e-42a6-b8ba-f33ca3595107",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "0532ede0-9a8a-4947-9ec6-874abec34312",
                "d5bb6caf-0d03-4671-8a0d-ffba99aa5720"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "cb69bbc4-7430-4cb5-88ed-3ffcc62a3690",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "f4ee2968-5e8f-40b2-ab7e-4d7537efcb00",
                "c1a5f647-02db-4c0c-9271-3fcc298e34fb"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "79fc737e-1062-445f-96c8-ec81cb866e4b",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "37c70b64-9c1d-4ad1-8808-f225baf77423",
                "7fa1df85-6573-4122-a1a5-5a954777a054"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "cfd9d07f-ba87-4d65-9b21-b98be56a0880",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "17bfbaca-8636-407f-ac22-e8fd4f347b96"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "2ff5b3de-b435-4084-ac7c-9257a5fb31aa",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "f54a3422-b6f5-4c0b-b5b0-98176950c355"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "7b114708-c88d-4d07-893f-990781106489",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "35fab686-32db-4e45-8e50-e76c13889a24"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "6855e450-4d49-499c-a6d1-d4d4da51fdf2",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "da6cb046-f678-4e72-acf2-c729b8a67d69"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "83082076-47a1-4ec0-ae2b-c772458e24ee",
        "3567dc47-7589-4e33-9d19-6cefdf030665"
      ]
    },
    {
      "id": "5caaadbc-a9dc-4c9f-92bc-92c32d32d737",
      "abbr": "SBD",
      "description": "",
      "seq": 19,
      "name": "Shorcut BP/DT",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "df00b46f-046b-4766-85be-57d826db9339",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "eb3be924-9162-473a-9815-03923f5c789d"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "c4c7b68b-2067-484d-a977-c19207dc6ac1",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "ce7fe6bb-2ef3-434a-b902-d19ef9284420"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ef2225bb-387d-4840-8ed5-a38fcc30d363",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "3f808b46-8d95-4668-ac3d-b082e5ba88ea"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "c5544a9c-df2c-4032-aeee-11b657832289",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "b94b5489-2acb-46ae-85ae-e225219dd8e3"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "bc5c6253-39a6-4a44-ab78-916e4e5ef0f0",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "5c9b3655-290d-4f11-b261-b45e4b43f86e"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "7120a195-de98-4ed7-9766-8196cb5be41d",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "5960a724-6df8-4c27-9fd1-0cb61c86515f"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "4230a1ba-b539-404e-8100-fb9e7a9b81e4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "220eb065-21c1-4fda-bbc1-dbe58064d22e"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "423580b8-2a99-4da7-8de4-dbbf40b71aca",
        "2fb55850-8d44-49b4-822c-39f5b1c20965"
      ]
    },
    {
      "id": "78ee597c-de5b-46cc-be49-d5cd712d311a",
      "abbr": "TC",
      "description": "",
      "seq": 20,
      "name": "TEST CHECKLIST",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "41bc4776-7827-4e48-a18b-9c6e41bfd1e4",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "b925c24c-3778-4f3b-9645-9709f2041b59"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "9e514555-313f-4ed2-bfe8-21698d2c513f",
        "aff998e7-8313-4265-b4ee-4b83784e1322",
        "8efe182e-a99f-41ef-8b0d-4e476f01245e"
      ]
    },
    {
      "id": "8dec7d1a-773a-4cab-8a74-1b4d59e9055d",
      "abbr": "DV",
      "description": "",
      "seq": 21,
      "name": "Default Value",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "78c052f5-b88c-4b80-a552-adfdaf4be049",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "101c5c08-4443-4e5a-ba07-414cb54b9db4"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "e55bb1a0-b531-44f9-b22b-382d97b5104e",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "4753f137-3cc8-4326-a382-00a3ef1d3a70"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "ab7ec8ea-d611-4837-861b-4c18b8123604",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1acd5a72-c6b4-409d-ba67-c6a3f5217b62"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "6dfb5432-e64e-4368-a06e-018db5189d70",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "30471cbd-f943-4147-92ce-21402663cfe5"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "b1462a8a-6ca3-4abc-ac63-7a243b3d0596",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "1d45da85-043b-46d7-bd1a-12297d08b017"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "87c9a6c1-6026-441d-957e-220360d86822",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "12891312-fa74-4335-b6ed-2e51ba433269"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "930bb122-b80f-4157-85d6-ff89f0a2875c",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "11abfe09-8e89-497e-912b-b74c1680cb6f"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "98a90f15-2cbe-4001-9380-525d070e2cd1",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "99d13401-fa10-42c5-9620-f86d8a8d530e"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "1b5c7a28-d58b-465f-a735-2d562d605635",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "0708d60e-a467-49ef-b17c-f3c94c33c90e"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "0df96c9d-ceb8-4733-a808-b09f62e90afa",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "7305d464-3b3c-48ac-a01a-23cf9249b072"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "bd4366de-3346-4929-97ab-3735cb662eab",
        "189895ed-2cfa-416b-b7c1-23a5ba977660"
      ]
    },
    {
      "id": "f3403f05-d5fa-464d-abc3-f61087743ca8",
      "abbr": "TP",
      "description": "",
      "seq": 22,
      "name": "table permission",
      "icon": null,
      "fieldsLayout": [
        {
          "id": "6fb5f9d0-93df-48a9-a24f-ab521d5f240b",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "2719eebb-3605-485b-ada1-e40b9cd40ca9"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "171907e3-d998-4a3a-aa94-48763ca566c6",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "13b6b408-9d40-43c5-9ffd-9e31bea75d4d"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "e481b12d-9f4f-4625-85ce-a74de9633de1",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "40ecc0cc-ef3c-4a2e-936a-4e3d5197062b"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "c71599d8-5e79-4e99-b836-698213aaf8d6",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "95ada456-9d09-4083-b08a-21daf595d024"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "d41336e6-7a4e-489c-bb06-a2741a94f25e",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "887f9a82-76d9-4b93-af07-eab94870c464"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "0178b33a-9187-4d4d-9314-2415d886b7be",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "ca37bf7d-2a64-42bc-9091-ed4f60fa3a4a"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "4709efe6-b88d-4468-ad27-fbd43d21f08c",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "34f1396e-15f0-4a8d-8387-cbf7ffdf3c46"
              ]
            }
          ],
          "style": "plain"
        },
        {
          "id": "390b1cfe-5e92-410c-a300-f23eb8c657e1",
          "title": "",
          "collapsable": false,
          "description": "",
          "header": {
            "fields": [

            ]
          },
          "body": [
            {
              "fields": [
                "48627067-b13f-4f0c-adf4-306d69f5c012"
              ]
            }
          ],
          "style": "plain"
        }
      ],
      "statusLayout": [
        "2fed2390-44d2-4754-a4d6-0d99297b267d",
        "55a00fdb-9b4c-4129-a574-66f8260c085f"
      ]
    }
  ];
  yield put(getPublishedFormReturnAction({
    publishedForm: data,
    datatable: []
  }));
}

export function* getPortalPageLayout(args: IPublishedFormInput) {
  const data: IPortalPageLayout[] = [
    {
      type: 'section',
      header: {
        title: 'Header',
      },
      layout: [
        ['d403a45b-e12b-46b5-96e4-f8ccd04438ad', '88b87aec-6ea1-4311-b298-dafdc3714dbc'],
        ['9c4d49f0-7f8d-4e5e-8121-b4b766fefc78', '8468443f-d1ad-41dd-ae61-3dbf4487da45'],
      ]
    },
    {
      type: 'section',
      header: {
        title: 'Lorem Ipsum Dolor',
      },
      layout: []
    },
    {
      type: 'plain',
      header: {
        title: 'Header',
      },
      layout: [['0532ede0-9a8a-4947-9ec6-874abec34312', 'd5bb6caf-0d03-4671-8a0d-ffba99aa5720']]
    },
  ];
  yield put(getPortalPageLayoutReturnAction({
    pageLayout: data,
  }));
}

export function* workflowSagas() {
  yield takeLatest(GET_FORM_FIELD_LIST, getFormFieldList);
  yield takeLatest(GET_PUBLISHED_FORM_LIST, getpublishFormList);
  yield takeLatest(GET_PAGE_LAYOUT, getPortalPageLayout);
}

// All sagas to be loaded
export default workflowSagas;
import { GET_FORM_FIELD_LIST_RETURN, SAVE_DRAFT_CUSTOM_FILTER, SAVE_DRAFT_CUSTOM_FILTER_RETURN, SAVE_DRAFT_CUSTOM_FILTER_ERROR, PUBLISH_CUSTOM_FILTER, PUBLISH_CUSTOM_FILTER_RETURN, PUBLISH_CUSTOM_FILTER_ERROR, GET_CUSTOM_FILTER_LIST, GET_CUSTOM_FILTER_LIST_RETURN, CLEAR_SAVE_CUSTOM_FILTER_ERROR, CLEAR_SAVE_CUSTOM_FILTER_SUCCESS } from './constant';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IApiCustomFilterField, ICustomFilterContainer } from './interface/custom-filter-field';
import { message } from './message';

export interface IConfigurationCustomFilterReducer {
  type?: string;
  getCustomFieldListLoading: boolean;
  fieldList: ICustomFieldDetail[];
  saveCustomFilterErrorMessage: string;
  saveCustomFilterMessage: string;
  customFilterList: ICustomFilterContainer;
  saveCustomFilterLoading: boolean;
  customFilterSaveType: string;
  customFilterListAPI: IApiCustomFilterField[]
}

const initialState: IConfigurationCustomFilterReducer = {
  fieldList: [],
  getCustomFieldListLoading: false,
  saveCustomFilterErrorMessage: '',
  saveCustomFilterMessage: '',
  customFilterList: {},
  saveCustomFilterLoading: false,
  customFilterSaveType: '',
  customFilterListAPI: []
};

const configurationCustomFilterReducer = (state = initialState, action: IConfigurationCustomFilterReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_FIELD_LIST_RETURN:
      newState.fieldList = action.fieldList;
      return newState;

    case GET_CUSTOM_FILTER_LIST:
      newState.getCustomFieldListLoading = true;
      newState.saveCustomFilterMessage = '';
      newState.saveCustomFilterErrorMessage = '';
      return newState;

    case GET_CUSTOM_FILTER_LIST_RETURN:
      newState.customFilterList = action.customFilterList;
      newState.customFilterListAPI = action.customFilterListAPI;
      newState.getCustomFieldListLoading = false;
      return newState;

    case SAVE_DRAFT_CUSTOM_FILTER:
      newState.saveCustomFilterLoading = true;
      newState.saveCustomFilterMessage = '';
      newState.saveCustomFilterErrorMessage = '';
      newState.customFilterSaveType = '';
      return newState;

    case SAVE_DRAFT_CUSTOM_FILTER_RETURN:
      if (action.customFilterSaveType === 'draft') {
        newState.saveCustomFilterLoading = false;
        newState.saveCustomFilterMessage = message.saveDraftCustomFilterMessage;
      }
      newState.customFilterList = action.customFilterList;
      newState.customFilterSaveType = action.customFilterSaveType;
      return newState;

    case SAVE_DRAFT_CUSTOM_FILTER_ERROR:
      newState.saveCustomFilterLoading = false;
      newState.saveCustomFilterErrorMessage = action.saveCustomFilterErrorMessage;
      return newState;

    case PUBLISH_CUSTOM_FILTER:
      newState.saveCustomFilterMessage = '';
      newState.saveCustomFilterErrorMessage = '';
      newState.customFilterSaveType = '';
      return newState;

    case PUBLISH_CUSTOM_FILTER_RETURN:
      newState.saveCustomFilterLoading = false;
      newState.saveCustomFilterMessage = message.publishCustomFilterMessage;
      return newState;

    case PUBLISH_CUSTOM_FILTER_ERROR:
      newState.saveCustomFilterLoading = false;
      newState.saveCustomFilterErrorMessage = action.saveCustomFilterErrorMessage;
      return newState;

    case CLEAR_SAVE_CUSTOM_FILTER_SUCCESS:
      newState.saveCustomFilterMessage = '';
      return newState;

    case CLEAR_SAVE_CUSTOM_FILTER_ERROR:
      newState.saveCustomFilterErrorMessage = '';
      return newState;

    default:
      return state;
  }
}

export default configurationCustomFilterReducer;
import { useEffect, useState } from 'react';
import { IFormRole } from '../../interface/form-role';
import { IConstraint, IStatusConstraint } from '../../interface/constraint';

interface IProps {
  selectedRoleStatusConstraint: { statusId: string, roleId: string };
  currentRoleList: IFormRole[];
}

export const useSetConstraintAccessLevel = (props: IProps) => {
  const [accessLevel, setAccessLevel] = useState('everyone');
  const [fieldCompareConstraint, setFieldCompareConstraint] = useState<IConstraint[]>([]);

  useEffect(() => {
    const selectedRole = props.currentRoleList.filter((e: IFormRole) => props.selectedRoleStatusConstraint.roleId === e.id);
    let accessLevel = 'everyone';
    let fieldCompareConstraint: IConstraint[] = [];
    if (selectedRole.length > 0 && selectedRole[0].hasOwnProperty('constraintsPerStatus') && selectedRole[0].constraintsPerStatus instanceof Array) {
      const selectedStatusConstraint = selectedRole[0].constraintsPerStatus.filter((e: IStatusConstraint) => e.statusId === props.selectedRoleStatusConstraint.statusId);
      if (selectedStatusConstraint.length > 0) {
        fieldCompareConstraint = selectedStatusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'field-compare');

        if (selectedStatusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'owner').length > 0) {
          accessLevel = 'owner'
        }
        if (selectedStatusConstraint[0].constraints.filter((constraint: IConstraint) => constraint.type === 'creator').length > 0) {
          accessLevel = 'creator'
        }
      }
    }
    setFieldCompareConstraint(fieldCompareConstraint);
    setAccessLevel(accessLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.currentRoleList), JSON.stringify(props.selectedRoleStatusConstraint)]);

  return { accessLevel, setAccessLevel, fieldCompareConstraint, setFieldCompareConstraint, ...props }
}
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { removeInput } from '../../../utils/remove-input';
import { v4 as uuid } from 'uuid';
import PopperContainer from '../../../../../general/popper-component';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { ComputeDate } from '../../enum-type/utils/compute-date';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { DateProps } from '../../../service/type/date';
import { TValidationType } from '../../../service/field-properties.handler';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { renderError } from './utils/render-error';
import { renderIcon } from './utils/render-icon';
import { removePopperPosition } from '../date-time/utils/remove-popper-position';
import { returnPopperPosition } from '../date-time/utils/return-popper-position';
import { setMinMaxDateState } from './utils/set-min-max-date-state';

const DateArrayType: React.FC<AcceptedProps> = (props) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new DateProps());

  const [refresher, setRefresher]: any = useState(1);
  const [multilineCount, setMultilineCount] = useState(1);
  const [minDate, setMinDate] = useState<any>(moment().subtract(100, 'years'));
  const [maxDate, setMaxDate] = useState<any>(moment().add(100, 'years'));
  const [excludeDates, setExcludeDates] = useState<Date[] | undefined>([] as Date[] | undefined);

  let calendarRef = useRef<any>({});

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(uuid());
  }

  const setMinMaxDate = () => {
    setMinMaxDateState({
      inputConfig: { ...props.inputConfig },
      forwardedRef: props.forwardedRef,
      fromRange: props.fromRange,
      parentRef: props.parentRef,
      maxDate: maxDate,
      minDate: minDate,
      setExcludeDates,
      setMaxDate,
      setMinDate,
    });
  }

  const onCalendarClose = (id: string) => {
    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    validateInput();

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
    setRefresher(refresher + 1);
  }

  const handleDate = (date: any, count: number) => {
    const value = date ? moment(date).format("YYYY-MM-DD") : '';
    elementProperties.setCurrent({ ...elementProperties.getCurrent(count), value }, count);
    if (props.triggerFilter) { props.triggerFilter('') }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  const handleYearAndMonthChange = (date: Date) => {
    if (props.inputConfig.config.noWeekends) {
      const startDateOfMonth = moment(date).startOf('month').startOf('week');
      const endDateOfMonth = moment(date).endOf('month').endOf('week');
      const weekends = ComputeDate.getWeekends(startDateOfMonth.format('MM/DD/YYYY'), endDateOfMonth.format('MM/DD/YYYY'));
      setExcludeDates(weekends);
    }
  }

  const getValue = (count: number) => {
    const data = elementProperties.getCurrent(count).value;
    return data ? new Date(moment(data).tz(tz).format("MMMM/DD/YYYY")) : null;
  }

  const addNewInput = () => {
    const newLen = multilineCount + 1;
    elementProperties.setCurrent({ ...elementProperties.getCurrent(multilineCount), value: '' }, multilineCount);
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      elementProperties.setCurrent({ ...elementProperties.getCurrent(multilineCount), value: defaultValue[0] || '' }, multilineCount);
    }
    setMultilineCount(newLen);
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const focusInput = (id: string) => {
    setFocusedElement(id);
    if (mobile) {
      setRefresher(uuid());
      if (props.tableColumnRowIndex !== undefined) {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
    setMinMaxDate();
  }

  const setRef = (curRef: any, id: string, row: number) => {
    if (curRef && focusedElement && id === focusedElement) {
      curRef.input.focus();
    }
    if (calendarRef.current) {
      calendarRef.current[row] = curRef;
    }
  }

  const renderAddRow = (row: number, count: number) => {
    return row === (count - 1) && props.multiline
      && <i
        className={`plus circular icon multiline-icon ${row === 0 ? '' : 'more'}`}
        onClick={() => addNewInput()}
      />
  }

  const renderDeleteRow = (row: number, count: number) => {
    return (row > 0 || (row === 0 && row !== (count - 1)))
      && <i className="minus circular icon multiline-minus-icon"
        onClick={() => removeInput({
          forwardedRef: props.forwardedRef,
          name: name,
          multilineCount: multilineCount,
          setMultilineCount: setMultilineCount
        }, row)}
      />
  }

  const handleClickOutside = (row: number) => {
    if (calendarRef.current && calendarRef.current[row]) {
      calendarRef.current[row].setOpen(false);
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(id);
    }
  }

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `date-picker-${name}-${row}`;
      field.push(
        <React.Fragment key={id}>
          {row > 0 && <span className='multiline-gap' />}
          <div className='multi-line-container'>
            <div className='flex-container'>
              <DatePicker
                id={id}
                onCalendarClose={() => onCalendarClose(id)}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                dropdownMode="select"
                showYearDropdown={true}
                showMonthDropdown={true}
                popperContainer={PopperContainer}
                selected={getValue(row)}
                onChange={(date) => handleDate(date, row)}
                readOnly={props.accessType === IFieldAccessType.Readonly}
                className={`field-input multi-line ${name} ${elementStatus({
                  forwardedRef: props.forwardedRef,
                  name: name,
                  multiline: props.multiline || false,
                  required: props.accessType === IFieldAccessType.Required,
                  fieldValueObject: elementProperties
                }, row)}`}
                onInputClick={() => focusInput(id)}
                onFocus={() => returnPopperPosition(id)}
                ref={(curRef: any) => setRef(curRef, id, row)}
                popperClassName={id}
                shouldCloseOnSelect
                onClickOutside={() => handleClickOutside(row)}
                onKeyDown={(e) => handleKeydown(e, id)}
                excludeDates={excludeDates}
                onMonthChange={(date: Date) => handleYearAndMonthChange(date)}
                onYearChange={(date: Date) => handleYearAndMonthChange(date)}
              />
              {renderIcon('multi-line', props.hasIconInside || false)}
              {renderError({ elementProperties, showErrorMessage: props.showErrorMessage })}
            </div>
            <span>
              {renderDeleteRow(row, count)}
              {renderAddRow(row, count)}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return field;
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ value: defaultValueArray[row] || '' }, row)
      }
    }
  }

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount])

  useEffect(() => {
    if (props.multiline) {
      const len = elementProperties.current.length;
      setMultilineCount(len === 0 ? 1 : len);
    }
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }
  </>)
}

export default DateArrayType;
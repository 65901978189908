import React from 'react';
import { FieldElement } from '../../../../../common/field/component/field-element';
import { IFieldData } from '../../../../../common/field/dynamic-field-list-detail/interface/field-data';

const GridDTRecordsTemplate: React.FC<{ data: any }> = ({ data }) => {

  const props = { ...data.column.parent };
  const fieldData = props.dataTableRecordsField.find((e: IFieldData) => e.id === data.column.fieldId);

  if (!fieldData) {
    return null;
  }

  if (!data.seq && !data.index && props.gridEditMode === 'Normal') {
    if (props.fieldRefs.current[fieldData.id]) {
      props.fieldRefs.current[fieldData.id].value = '';
    }
  }

  const getValue = () => {
    let tempValue = props.fieldRefs.current
      && props.fieldRefs.current[fieldData.id]
      && props.fieldRefs.current[fieldData.id].value
      ? props.fieldRefs.current[fieldData.id].value
      : '';
    if (props.fieldRefs.current
      && props.fieldRefs.current[fieldData.id]
      && typeof props.fieldRefs.current[fieldData.id] === 'string') {
      tempValue = props.fieldRefs.current[fieldData.id];
    }
    return tempValue;
  }

  const parseData = (data: any) => {
    const type = fieldData.inputConfig && fieldData.inputConfig.type;
    if (type === 'enum') {
      if (data && data instanceof Array && data.length > 0) {
        data = data.map(e => { return e instanceof Object && e.hasOwnProperty('id') ? e.id : e });
      } else {
        if (data && Object.keys(data).length > 0 && data.hasOwnProperty('id')) {
          data = data.id;
        }
      }
    }
    if (data && data instanceof Object) {
      data = JSON.stringify(data);
    }
    if (data && typeof data !== 'string') {
      data = data.toString();
    }
    return data;
  }

  const triggerThrowValueOutside = () => {
    const currentValue = parseData(getValue());
    const oldValue = parseData(data[fieldData.id]);
    if ((oldValue && currentValue && oldValue === currentValue) || (
      fieldData && fieldData.reloadOnChange && fieldData.reloadOnChange.length > 0
    ) || (!oldValue && !currentValue)) return;

    if (data && data.id) {
      const dataCleared = !!oldValue && !currentValue;
      const columnIdx = data.column.parent.getColumnIndexByField(data.column.field);
      const tempUpdatedColumnValues = { ...props.updatedColumnValues };
      const tempColumns = tempUpdatedColumnValues[data.id] || [];
      if (tempColumns && tempColumns instanceof Array) {
        if (!tempColumns.includes(columnIdx)) {
          tempColumns.push(columnIdx);
        } else {
          if (dataCleared) {
            const index = tempColumns.findIndex(e => e === columnIdx);
            tempColumns.splice(index, 1);
          }
        }
      }
      props.triggerSetUpdatedColumnValues(data.id, tempColumns);
    }
  }

  const triggerFieldReload = (fieldReload: string[]) => {
    const currentValue = parseData(getValue());
    const oldValue = parseData(data[fieldData.id]);
    const noChange = ((oldValue && currentValue && oldValue === currentValue) || (!oldValue && !currentValue));
    if (noChange) return;
    if (fieldReload.length > 0) {
      const tempUpdatedColumnValues = { ...props.updatedColumnValues };
      const tempColumns = tempUpdatedColumnValues[data.id] || [];
      const columnIdx = data.column.parent.getColumnIndexByField(data.column.field);
      if (tempColumns && tempColumns instanceof Array && !tempColumns.includes(columnIdx)) {
        tempColumns.push(columnIdx);
      }
      const fieldReloadIdx = fieldReload.map(fieldId => {
        return data.column.parent.getColumnIndexByField(fieldId) as number;
      });
      props.triggerSetUpdatedColumnValues(data.id, Array.from(new Set([...tempColumns, ...fieldReloadIdx])));
    }
    props.triggerFieldReload(fieldReload, data.id);
  }

  const field = FieldElement.getDataElement(fieldData);
  const Element = field.getFieldComponent();

  return <Element
    {...field}
    key={fieldData.id}
    dataFromColumn={data}
    triggerFieldReload={triggerFieldReload}
    forwardedRef={props.fieldRefs}
    defaultValueElement={false}
    defaultValue={fieldData.defaultValue}
    isAddRecord={!data.seq && !data.index}
    fieldCollection={props.dataTableRecordsField}
    fieldEnumOptionUrl={`${props.companyId}/databases/${props.dataTableId}/records/fields`}
    throwValueOutside={triggerThrowValueOutside}
  />
}

export default GridDTRecordsTemplate

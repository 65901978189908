import {
  GET_FIELD_LIST,
  GET_FIELD_LIST_RETURN
} from './constant'
import { IFieldGetDetailInput } from './interface/input/field-get'
import { IFieldGetResponse } from './interface/response/field-get'

export const getFieldListAction = (payload: IFieldGetDetailInput) => {
  return { type: GET_FIELD_LIST, ...payload }
}

export const getFieldListReturnAction = (payload: IFieldGetResponse) => {
  return { type: GET_FIELD_LIST_RETURN, ...payload }
}
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { getFilters } from '../../../../../../../../main/partials/header/hexToCssFilter';

export const renderAttachmentDeleted = () => {
  return (
    <div className={`attachment-header deleted`}>
      <div className="icon-deleted">
        <Image style={{ filter: getFilters('#7a797a').filter }}
          className="file-icon deleted"
          src={icons.black.delete}
        />
      </div>
      <div className="file-info">This file was deleted.</div>
    </div>
  );
};
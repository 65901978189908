import React from 'react';
import { UserButton } from './button/user';
import { GroupButton } from './button/group';
import { BusinessProcessButton } from './button/business-process';
import { AppButton } from './button/app';
import { DatatableButton } from './button/datatable';
import { IButtonList } from './interface/button-list';
import { IButtonListProp } from './interface/button-list-props';
import { ButtonList } from './button-list';

const ButtonArrayList = [
  new UserButton(),
  new GroupButton(),
  new BusinessProcessButton(),
  new AppButton(),
  new DatatableButton()
]

export class HeaderMenuButton {

  registry: { [name: string]: IButtonList } = {};

  constructor(types?: IButtonList[]) {

    if (!types) {
      types = ButtonArrayList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getButton: React.FC<IButtonListProp> = (props: IButtonListProp) => {
    const { Render } = this.registry[props.type] ? this.registry[props.type] : new ButtonList();
    return <Render {...props} ></Render>;
  }

  static Render: React.FC<IButtonListProp> = (props: IButtonListProp) => {
    const headerButton = new HeaderMenuButton();
    const Render = headerButton.getButton;
    return <Render {...props} />
  }

}
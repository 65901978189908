import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IAutomationComponent } from '../../../../../interface/component-list';
import { SendEmailUtils } from '../../../../utils/manage-options/action/send-email';
import { DetailActionButton } from '../../../action-button';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from '../registry';
import SendEmail from './email';
import SendEmailFile from './file';
import { useSetSendEmailStateRefs } from './hooks/use-set-send-email-state-refs';
import { v4 as uuid } from 'uuid';
import { checkIfUUID } from '../../../../../../../../../../../../../common/utils/check-uuid-value';
import { Editor } from '@tinymce/tinymce-react';
import ConfigService from '../../../../../../../../../../../../../common/config';
import { IAutomationPlaceholder } from '../../../../../interface/automation';
import { ParseEmailBody } from './utils/parse-email-body';

class SendEmailComponent {

  name = 'send-email';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const dispatch = useDispatch();
    const match: { params: { formId: string } } = useRouteMatch();
    const {
      inputFieldList,
      referenceList,
      datatableInputField,
      formFieldList,
      automationPlaceholders
    } = useSelector((state: IRootState) => state.automation);
    const { company } = useSelector((state: IRootState) => state.auth);

    const [selectedEmail, setSelectedEmail] = useState([''] as any[]);
    const [selectedEmailCc, setSelectedEmailCc] = useState([''] as any[]);
    const [selectedEmailBcc, setSelectedEmailBcc] = useState([''] as any[]);
    const [selectedEmailFile, setSelectedEmailFile] = useState([''] as any[]);
    const [refresher, setRefresher] = useState(0);
    const [operation, setOperation] = useState<{ [id: string]: any }>({});

    const emailStringRef = useRef<any>({});
    const emailInputFocusRef = useRef<any>({});
    const fileInputRef: any = useRef({});
    const fileInputRefValue: any = useRef({});
    const deletedFileInputRef: any = useRef({});
    const editorRef = useRef<any>(null);

    let valueTimeout = setTimeout(() => { }, 200);

    const subjRef = useRef(document.createElement('input'));

    const getOption = (selectedComponent: IAutomationComponent, emailType: string) => {
      SendEmailUtils.getOptions(selectedComponent, {
        dispatch,
        referenceList,
        datatableInputField,
        company: company,
        inputFieldList,
        publishedFormStatusList: {},
        formFieldList
      }, emailType);
    }

    useSetSendEmailStateRefs({
      emailStringRef,
      setOperation,
      setSelectedEmail,
      setSelectedEmailCc,
      subjRef,
      selectedComponent: props.selectedComponent,
      setSelectedEmailFile,
      company,
      fileInputRef,
      fileInputRefValue,
      setRefresher,
      refresher,
      setSelectedEmailBcc,
    });

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent && tempComponent.config) {
        const { to, cc, bcc }: any = { ...emailStringRef.current };
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.to = to && isEmailNotEmpty(to) ? parseEmail(to) : [];
        tempComponent.config.cc = cc && isEmailNotEmpty(cc) ? parseEmail(cc) : [];
        tempComponent.config.bcc = bcc && isEmailNotEmpty(bcc) ? parseEmail(bcc) : [];
        tempComponent.config.attachments = fileInputRef && isEmailNotEmpty(fileInputRef.current) ? setEmailAttachments(fileInputRef.current) : null;
        tempComponent.config.subject = subjRef.current ? subjRef.current.value : '';
        tempComponent.config.body = editorRef && editorRef.current && editorRef.current.editor ?
          ParseEmailBody.fromHTMLString({ text: editorRef.current.editor.getContent(), automationPlaceholders })
          : ''; 
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const parseEmail = (emailList: any[]) => {
      return emailList.map((e: any) => {
        if (e && typeof e === 'object' && e.fieldId === 'document-creator') {
          e = { placeholder: e.fieldId };
        }
        if (e && typeof e === 'object' && (e.hasOwnProperty('referencedKeyPath') || e.hasOwnProperty('keyPath'))) {
          if (e.referencedKeyPath === 'current-form') {
            e = { keyPath: e.keyPath };
          }
          Object.keys(e).forEach(key => {
            const values = e[key];
            if (checkIfUUID(values) && values.indexOf('fields.') === -1) {
              e[key] = `fields.${values}`;
            }
          })
        }
        return e;
      })
    }

    const setEmailAttachments = (tempAttachments: any[]) => {
      let attachments = tempAttachments.filter((e, i) => deletedFileInputRef.current && !deletedFileInputRef.current[i]);
      attachments = attachments.map(data => {
        if (data && typeof data === 'object' && (data.hasOwnProperty('referencedKeyPath') || data.hasOwnProperty('keyPath'))) {
          if (data.referencedKeyPath === 'current-form') {
            data = { keyPath: data.keyPath };
          }
          Object.keys(data).forEach(key => {
            const values = data[key];
            if (checkIfUUID(values) && values.indexOf('fields.') === -1) {
              data[key] = `fields.${values}`;
            }
          });
        }
        return data;
      })
      return attachments
    }

    const isEmailNotEmpty = (email: string[]) => {
      return email.every(e => e !== '' && !!e)
    }

    const addNewEmailTo = () => {
      const tempSelected = [...selectedEmail];
      tempSelected.push('');
      setSelectedEmail(tempSelected);
      addOperator('to');
      storeDynamicField();
    }

    const removeEmailTo = (index: number) => {
      const tempSelected = [...selectedEmail];
      tempSelected.splice(index, 1);
      setSelectedEmail(tempSelected);
      removeOperator(index, 'to');
      if (emailStringRef.current['to']) {
        const tempRef = emailStringRef.current['to'];
        tempRef.splice(index, 1);
        emailStringRef.current['to'] = tempRef;
      }
      storeDynamicField();
    }

    const addNewEmailCc = () => {
      const tempSelected = [...selectedEmailCc];
      tempSelected.push('');
      setSelectedEmailCc(tempSelected);
      addOperator('cc');
      storeDynamicField();
    }

    const removeEmailCc = (index: number) => {
      const tempSelected = [...selectedEmailCc];
      tempSelected.splice(index, 1);
      setSelectedEmailCc(tempSelected);
      removeOperator(index, 'cc');
      if (emailStringRef.current['cc']) {
        const tempRef = emailStringRef.current['cc'];
        tempRef.splice(index, 1);
        emailStringRef.current['cc'] = tempRef;
      }
      storeDynamicField();
    }
    const addNewEmailBcc = () => {
      const tempSelected = [...selectedEmailBcc];
      tempSelected.push('');
      setSelectedEmailBcc(tempSelected);
      addOperator('bcc');
      storeDynamicField();
    }

    const removeEmailBcc = (index: number) => {
      const tempSelected = [...selectedEmailBcc];
      tempSelected.splice(index, 1);
      setSelectedEmailBcc(tempSelected);
      removeOperator(index, 'bcc');
      if (emailStringRef.current['bcc']) {
        const tempRef = emailStringRef.current['bcc'];
        tempRef.splice(index, 1);
        emailStringRef.current['bcc'] = tempRef;
      }
      storeDynamicField();
    }

    const updateEmail = (data: any, index: number, emailType: 'to' | 'cc' | 'attachments') => {
      const tempData: any = emailStringRef.current[emailType] ? emailStringRef.current[emailType] : [];
      tempData[index] = data;
      emailStringRef.current[emailType] = tempData;
      setRefresher(refresher + 1);
    }

    const refreshComponentToChangeButton = () => {
      if (props.hasComponentChange.current === false) {
        setRefresher(refresher + 1)
        props.parentRefresher();
      }
    }

    const storeDynamicField = () => {
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const openModal = (value: boolean, emailType: string, index: number) => {
      if (value) {
        storeDynamicField();
      }
      const tempContainer: { [id: string]: any } = { [emailType]: { [index]: !value } };
      props.refsObject.current.isVisible = tempContainer;
      setRefresher(refresher + 1);
    }

    const manageOperator = (value: string, index: number, emailType: string) => {
      const tempContainer: any = { ...operation };
      const tempOperator: any = operation[emailType] ? operation[emailType] : {};
      tempOperator[index] = value;
      tempContainer[emailType] = tempOperator;
      setOperation(tempContainer);
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const removeOperator = (index: number, emailType: string) => {
      const tempContainer: any = { ...operation };
      const tempOperator: any = operation[emailType] ? operation[emailType] : {};
      if (tempOperator && Array.isArray(tempOperator)) {
        tempOperator.splice(index, 1);
        tempContainer[emailType] = tempOperator;
        setOperation(tempContainer);
      }
    }

    const addOperator = (emailType: string) => {
      const tempContainer: any = { ...operation };
      const tempOperator: any = operation[emailType] ? operation[emailType] : {};
      if (tempOperator && Array.isArray(tempOperator)) {
        tempOperator.push('manual');
        tempContainer[emailType] = tempOperator;
        setOperation(tempContainer);
      }
    }

    const addNewEmailFile = () => {
      const tempSelected = [...selectedEmailFile];
      tempSelected.push('');
      setSelectedEmailFile(tempSelected);
      addOperator('attachments');
      storeDynamicField();
    }

    const updateEmailFile = (data: any, index: number) => {
      fileInputRef.current[index] = data;
      props.hasComponentChange.current = true;
      setRefresher(refresher + 1);
    }

    const removeEmailFile = (index: number) => {
      removeOperator(index, 'attachments');
      deletedFileInputRef.current[index] = true;
      storeDynamicField();
    }

    const getMatchedPlaceholderFields = (query?: string) => {
      let results = automationPlaceholders.filter(placeholder => {
        if (query) {
          return placeholder.uiPlaceholder.indexOf(query) > -1;
        }
        return true;
      });
      results.sort((a: IAutomationPlaceholder, b: IAutomationPlaceholder) => {
        let x = a.uiPlaceholder.toLowerCase();
        let y = b.uiPlaceholder.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      return results;
    }

    const triggerAutocompleterScroll = (e: any) => {
      const autocompleter = document.querySelector('.tox-collection__group');
      if (autocompleter) {
        const elements = Array.from(autocompleter.children)
        const visibleElements = elements.filter((el: any) => {
          let visible = false;
          const rect = el.getBoundingClientRect();
          const autocompleterRect = autocompleter.getBoundingClientRect();
          if (rect) {
            const top = rect.top;
            const bottom = rect.bottom;
            visible = (top >= autocompleterRect.top && bottom <= autocompleterRect.bottom);
          }
          return visible;
        });
        const lastVisibleElement = visibleElements[visibleElements.length - 1];
        const firstVisibleElement = visibleElements[0];
        if (e.key === 'ArrowDown') {
          if (lastVisibleElement.className.indexOf('tox-collection__item--active') > -1) {
            autocompleter.scrollTop = autocompleter.scrollTop += 38;
          }
        }
        if (e.key === 'ArrowUp') {
          if (firstVisibleElement.className.indexOf('tox-collection__item--active') > -1) {
            autocompleter.scrollTop = autocompleter.scrollTop -= 38;
          }
        }
      }
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    return (<>
      <div className={`automation-form-detail-action send-email-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Send Email`}
          description={`Rule is run when Dynamic Action is executed.`}
          iconHeight={20}
          iconWidth={18}
          iconName='mail'
        />
        {selectedEmail.map((data, index) => {
          return <SendEmail
            data={data}
            index={index}
            key={uuid()}
            addNewEmail={addNewEmailTo}
            emailList={selectedEmail}
            removeEmail={removeEmailTo}
            label='To'
            getOption={getOption}
            selectedComponent={props.selectedComponent}
            selectedBP={match.params.formId}
            openModal={openModal}
            isVisible={props.refsObject.current.isVisible || {}}
            manageOperator={manageOperator}
            operation={operation}
            updateEmail={updateEmail}
            emailStringRef={emailStringRef}
            storeDynamicField={storeDynamicField}
            emailInputFocusRef={emailInputFocusRef}
          />
        })}
        {selectedEmailCc.map((data, index) => {
          return <SendEmail
            key={uuid()}
            data={data}
            index={index}
            addNewEmail={addNewEmailCc}
            removeEmail={removeEmailCc}
            emailList={selectedEmailCc}
            label='Cc'
            getOption={getOption}
            selectedComponent={props.selectedComponent}
            selectedBP={match.params.formId}
            openModal={openModal}
            isVisible={props.refsObject.current.isVisible || {}}
            manageOperator={manageOperator}
            operation={operation}
            updateEmail={updateEmail}
            emailStringRef={emailStringRef}
            storeDynamicField={storeDynamicField}
            emailInputFocusRef={emailInputFocusRef}
          />
        })}
        {selectedEmailBcc.map((data, index) => {
          return <SendEmail
            key={uuid()}
            data={data}
            index={index}
            addNewEmail={addNewEmailBcc}
            removeEmail={removeEmailBcc}
            emailList={selectedEmailBcc}
            label='Bcc'
            getOption={getOption}
            selectedComponent={props.selectedComponent}
            selectedBP={match.params.formId}
            openModal={openModal}
            isVisible={props.refsObject.current.isVisible || {}}
            manageOperator={manageOperator}
            operation={operation}
            updateEmail={updateEmail}
            emailStringRef={emailStringRef}
            storeDynamicField={storeDynamicField}
            emailInputFocusRef={emailInputFocusRef}
          />
        })}
        <div className={`automation-field-container`}>
          <div className={`automation-field input`}>
            <label>Subject:</label>
            <input ref={subjRef} type='text' onChange={storeDynamicField} />
          </div>
        </div>
        <div className={`automation-field-container`}>
          <div className={`automation-field email-body`}>
            <label>Body:</label>
            <Editor
              apiKey={ConfigService.loadConfig().general.tinymceKey}
              onBlur={(event) => {
                props.hasComponentChange.current = true;
                setRefresher(refresher + 1);
              }}
              ref={editorRef}
              init={{
                height: 385,
                statusbar: false,
                menubar: false,
                toolbar: "fields | fontselect | styleselect | fontsizeselect | forecolor | link | numlist bullist",
                //eslint-disable-next-line
                noneditable_regexp: /\{\{[^\}]+\}\}/g,
                plugins: "code link lists noneditable",
                content_style: 'body { font-size: 13px; margin: 11px }' +
                  'p { margin-block-start: 3px; margin-block-end: 3px; }' +
                  `.mceNonEditable {
                    background-color: #D6F0FF;
                    padding: 1px 0;
                    color: #44719B;
                    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                    font-size: 11px !important;
                  }`,
                extended_valid_elements: 'span[field-selected-id|class|style|id]',
                force_br_newlines: true,
                deprecation_warnings: false,
                setup: (editor: any) => {
                  editor.on('init', () => {
                    document.body.id = 'automation-send-email';
                    if (props.selectedComponent && props.selectedComponent.config) {
                      let { body }: any = { ...props.selectedComponent.config };
                      const parsed = ParseEmailBody.toHTMLString({ text: body || '', automationPlaceholders: automationPlaceholders });
                      if (typeof valueTimeout === 'function') {
                        clearTimeout(valueTimeout);
                      }
                      valueTimeout = setTimeout(() => {
                        editor.setContent(parsed);
                      }, 200);
                    }
                  });
                  editor.on('remove', () => {
                    document.body.removeAttribute('id');
                  });
                  editor.on('keydown', (e: any) => {
                    const node = editor.selection.getNode();
                    const isAutoCompleter = parseInt(node.getAttribute('data-mce-autocompleter')) === 1;
                    if (isAutoCompleter) {
                      triggerAutocompleterScroll(e);
                    }
                  });
                  editor.ui.registry.addMenuButton("fields", {
                    text: 'Fields',
                    tooltip: 'Insert Fields',
                    fetch: (callback: any) => {
                      const items = getMatchedPlaceholderFields().map((placeholder) => {
                        return {
                          type: 'menuitem',
                          text: placeholder.uiPlaceholder,
                          onAction: () => {
                            editor.insertContent(`{{${placeholder.uiPlaceholder}}}`);
                          }
                        }
                      });
                      callback(items);
                    },
                  });
                  // editor.ui.registry.addAutocompleter('placeholderfields', {
                  //   type: 'autocompleter',
                  //   ch: '[',
                  //   minChars: 0,
                  //   columns: 1,
                  //   fetch: function (query: string) {
                  //     return new Promise(async (resolve) => {
                  //       const results = getMatchedPlaceholderFields(query).map(placeholder => {
                  //         return {
                  //           type: 'autocompleteitem',
                  //           id: placeholder.placeholder,
                  //           text: placeholder.uiPlaceholder,
                  //           value: placeholder.placeholder + '|' + placeholder.uiPlaceholder
                  //         }
                  //       });
                  //       resolve(results);
                  //     })
                  //   },
                  //   onAction: function (autocompleteApi: any, rng: any, value: any) {
                  //     const data = value.split('|');
                  //     const id = data[0];
                  //     const name = data[1];

                  //     var span = editor.getDoc().createElement('span');
                  //     span.className = 'placeholderfields';
                  //     span.id = id;
                  //     span.appendChild(editor.getDoc().createTextNode('[' + name + ']'));

                  //     editor.selection.setRng(rng);
                  //     editor.insertContent('[' + name + ']');
                  //     autocompleteApi.hide();
                  //   }
                  // })
                }
              }}
            />
          </div>
        </div>
        {selectedEmailFile.map((data, index) => {
          const notDeletedItemIndex = selectedEmailFile
            .map((e, i) => {
              if (deletedFileInputRef.current && deletedFileInputRef.current[i]) {
                return null;
              }
              return i;
            }).filter(e => e !== null) as number[];
          if (deletedFileInputRef.current && deletedFileInputRef.current[index]) {
            return null;
          }
          return <SendEmailFile
            key={uuid()}
            data={data}
            index={index}
            storeDynamicField={storeDynamicField}
            label='Attach files'
            openModal={openModal}
            selectedBP={match.params.formId}
            isVisible={props.refsObject.current.isVisible || {}}
            fileInputRef={fileInputRef}
            fileList={selectedEmailFile}
            addNewEmailFile={addNewEmailFile}
            manageOperator={manageOperator}
            operation={operation}
            getOption={getOption}
            selectedComponent={props.selectedComponent}
            fileInputRefValue={fileInputRefValue}
            updateEmailFile={updateEmailFile}
            removeEmailFile={removeEmailFile}
            firstItemIdx={Math.min(...notDeletedItemIndex)}
          />
        })}
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>);
  }
}

export default SendEmailComponent;
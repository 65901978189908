import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { formBuilderFieldType } from '../../../../../../common/field/type';
import { IFieldTypeObj } from '../../../../../../common/field/type/interface/field-type-object';
import { IRootState } from '../../../../../../reducers';
import ButtonContainer from '../../../../../admin-module/module/business-process/button-container';
import { IForm } from '../../../../../admin-module/module/business-process/module/form/interface/form';
import { currentRouteAction, setThirdLevelRouteAction } from '../../../../../main/action';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { getPublishedFormAction } from './action/get-publish-form';
import ContentComponent from './content';
import SideViewComponent from './side-view';
import { Image } from 'semantic-ui-react-bpm';
import referenceForm from '../../../../../../common/field/type/reference-form';
import { getFilters } from '../../../../../main/partials/header/hexToCssFilter';
import { v4 as uuid } from 'uuid';
import { getPortalPageLayoutAction } from './action/get-page-layout';
import { getFormFieldAction } from './action/get-form-field';

interface IFormViewProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class FormViewModule {

  name = 'form-view';

  render: React.FC<IFormViewProps> = (props) => {

    const dispatch = useDispatch();
    const match = useRouteMatch();

    const { publishedForm } = useSelector((state: IRootState) => state.portalFormView);
    const { company } = useSelector((state: IRootState) => state.auth);

    const [bpImageList, setBpmImageList] = useState({} as { [bpId: string]: { src: any, seq: number } });
    const filters = { filter: getFilters('#61A7B3').filter };
    const grayfilters = { filter: getFilters('#797979').filter }

    const viewIcon = (field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string) => {
      let iconFilter = { ...filters };
      if (type === 'gray') {
        iconFilter = grayfilters;
      }
      let fieldType = formBuilderFieldType.find((e: IFieldTypeObj) => e.defaultData.type === field.type && (e.defaultConfig?.format === field.config?.format || field.config?.format === 'allowedChars'));
      if (field.type === 'array') {
        fieldType = formBuilderFieldType.find((e: IFieldTypeObj) => (
          e.defaultData.type === field?.config?.itemType
          && (
            (e.defaultConfig.format === field?.config?.itemConfig?.format) || field?.config?.itemConfig.format === 'allowedChars'
          )
        ));
      }
      if (fieldType) {
        let view = <Image className={`field-icon`} src={fieldType.icon.black} style={{ ...fieldType.style, ...iconFilter }} />;
        if (field.configName === 'Referenced') {
          view = <Image className={`field-icon`} src={referenceForm.icon.black} style={{ ...referenceForm.style, ...iconFilter }} />;
          if (bpImage) {
            view = bpImage.src ? <Image className={`field-icon`} src={bpImage.src} /> : <div className={`sequence-icon`}>{bpImage.seq}</div>;
          }
        }
        return view;
      }
      return undefined;
    }

    useEffect(() => {
      dispatch(setThirdLevelRouteAction({
        mainRoute: 'portal',
        subRoute: 'business-processes',
        thirdLevelRoute: this.name
      }))
      dispatch(currentRouteAction(this.name))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (company) {
        dispatch(getPublishedFormAction({ company: company }))
        dispatch(getPortalPageLayoutAction({ company: company, formId: uuid() }));
        dispatch(getFormFieldAction({ companyId: company, formId: uuid() }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    useEffect(() => {
      let newBpImage = { ...bpImageList };
      publishedForm && publishedForm.forEach((form: IForm) => {
        if (form.id) {
          newBpImage[form.id] = {
            src: form.icon,
            seq: form.seq ? form.seq : 0
          }
          setBpmImageList(newBpImage);
        }
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(publishedForm)])

    return (
      <Route path={`${match.path}`}>
        <div className='bp-portal-submenu'>
          <div className={`form-view-container`}>
            <SideViewComponent
              bpImageList={bpImageList}
              viewIcon={viewIcon}
            />
            <ContentComponent
              hasPageChangeRef={props.hasPageChangeRef}
              viewIcon={viewIcon}
            />
          </div>
          <ButtonContainer
            cancel={() => { }}
            save={() => { }}
            publish={() => { }}
          />
        </div>
      </Route>
    );
  }
}

export default FormViewModule;
import React from 'react';

interface IHeaderProps {

}

const PortalUserTableHeader: React.FC<IHeaderProps> = (props) => {
  return <tr className='header-name'>
    <th>#</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Email Address</th>
    <th>Phone</th>
    <th>Organization</th>
    <th>Stakeholder Profile</th>
    <th className='status'>Status</th>
    <th />
  </tr>
}

export default PortalUserTableHeader;
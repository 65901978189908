import {
  GET_FORM_STATUS_LIST_RETURN,
  GET_GROUP_LIST_RETURN,
  GET_USER_LIST_RETURN,
  GET_USER_FIELD_LIST_RETURN,
  GET_FORM_STATUS_LIST,
  GET_USER_LIST,
  GET_GROUP_LIST,
  GET_USER_FIELD_LIST,
  SAVE_ROLE,
  PUBLISH_ROLE,
  SAVE_ROLE_ERROR,
  SAVE_ROLE_RETURN,
  PUBLISH_ROLE_RETURN,
  PUBLISH_ROLE_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_RETURN,
  GET_ROLE_LIST_ERROR,
  CLEAR_SAVE_ROLE_ERROR,
  CLEAR_SAVE_ROLE_SUCCESS,
  GET_USER_CUSTOM_FIELD_RETURN,
  GET_CONSTRAINT_TEMPLATE_RETURN,
  GET_FORM_FIELD_RETURN,
  GET_USER_CUSTOM_FIELD
} from './constant';
import { IFormStatusWithAction } from '../workflow/interface/form-status';
import { IUser } from '../../../users/interface/user';
import { Group } from '../../../groups/interface/group';
import { IField } from '../../../users/interface/field';
import { message } from './message'
import { IFormRole } from './interface/form-role';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConstraint } from './interface/constraint';

export interface IFormRoleReducer {
  type?: string;
  statusList: IFormStatusWithAction[];
  getStatusListLoading: boolean;
  userList: IUser[],
  getUserListLoading: boolean;
  groupList: Group[]
  getGroupListLoading: boolean;
  userFieldList: IField[];
  getUserFieldListLoading: boolean;
  formRoleSaveLoading: boolean;
  formSaveErrorMessage: string;
  formSaveMessage: string;
  saveRoleType: string;
  roleList: IFormRole[];
  getRoleListLoading: boolean;
  getRoleListErrorMessage: string;
  userCustomField: ICustomFieldDetail[];
  constraintTemplateList: IConstraint[];
  formFieldList: ICustomFieldDetail[];
}

const initialState: IFormRoleReducer = {
  statusList: [],
  getStatusListLoading: false,
  userList: [],
  getUserListLoading: false,
  groupList: [],
  getGroupListLoading: false,
  userFieldList: [],
  getUserFieldListLoading: false,
  formRoleSaveLoading: false,
  formSaveErrorMessage: '',
  formSaveMessage: '',
  saveRoleType: '',
  roleList: [],
  getRoleListLoading: false,
  getRoleListErrorMessage: '',
  userCustomField: [],
  constraintTemplateList: [],
  formFieldList: [],
};

const formRoleReducer = (state = initialState, action: IFormRoleReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_FORM_STATUS_LIST:
      newState.getStatusListLoading = true;
      return newState;

    case GET_FORM_STATUS_LIST_RETURN:
      newState.statusList = action.statusList;
      return newState;

    case GET_USER_LIST:
      newState.getUserListLoading = true;
      newState.getUserFieldListLoading = false;
      return newState;

    case GET_USER_LIST_RETURN:
      newState.userList = action.userList;
      newState.getUserFieldListLoading = false;
      return newState;

    case GET_GROUP_LIST:
      newState.getGroupListLoading = true;
      return newState;

    case GET_GROUP_LIST_RETURN:
      newState.groupList = action.groupList;
      newState.getUserFieldListLoading = false;
      return newState;

    case GET_USER_FIELD_LIST:
      newState.getUserFieldListLoading = true;
      return newState;

    case GET_USER_FIELD_LIST_RETURN:
      newState.getUserFieldListLoading = false;
      newState.userFieldList = action.userFieldList;
      return newState;

    case SAVE_ROLE:
      newState.formRoleSaveLoading = true;
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      newState.saveRoleType = '';
      return newState;

    case SAVE_ROLE_RETURN:
      if (action.saveRoleType === 'draft') {
        newState.formRoleSaveLoading = false;
        newState.formSaveMessage = message.saveFormRoleSuccessMessage;
      }
      newState.roleList = action.roleList;
      newState.constraintTemplateList = action.constraintTemplateList;
      newState.saveRoleType = action.saveRoleType;
      return newState;

    case SAVE_ROLE_ERROR:
      newState.formRoleSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_ROLE:
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_ROLE_RETURN:
      newState.formRoleSaveLoading = false;
      newState.formSaveMessage = message.publishFormRoleSuccessMessage;
      return newState;

    case PUBLISH_ROLE_ERROR:
      newState.formRoleSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      return newState;

    case GET_ROLE_LIST:
      newState.getRoleListLoading = true;
      newState.getRoleListErrorMessage = '';
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      newState.getRoleListErrorMessage = '';
      return newState;

    case GET_ROLE_LIST_RETURN:
      newState.getRoleListLoading = false;
      newState.getRoleListErrorMessage = '';
      newState.roleList = action.roleList;
      return newState;

    case GET_ROLE_LIST_ERROR:
      newState.getRoleListLoading = false;
      newState.getRoleListErrorMessage = action.getRoleListErrorMessage;
      return newState;

    case CLEAR_SAVE_ROLE_SUCCESS:
      newState.formSaveMessage = '';
      return newState;

    case CLEAR_SAVE_ROLE_ERROR:
      newState.formSaveErrorMessage = '';
      return newState;

    case GET_USER_CUSTOM_FIELD:
      newState.userCustomField = [];
      return newState;

    case GET_USER_CUSTOM_FIELD_RETURN:
      newState.userCustomField = action.userCustomField;
      return newState;

    case GET_CONSTRAINT_TEMPLATE_RETURN:
      newState.constraintTemplateList = action.constraintTemplateList;
      return newState;

    case GET_FORM_FIELD_RETURN:
      newState.formFieldList = action.formFieldList;
      return newState;

    default:
      return newState;
  }
}

export default formRoleReducer

import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';

export class BoolValidator implements ValidationType {

  name = 'bool';

  validate(field: FieldElement, value: string) {
    // no validation at the moment
    return [];
  }

}
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_FORM_STATUS_LIST = 'app/admin-module/business-process/configuration/slas/GET_FORM_STATUS_LIST';
export const GET_FORM_STATUS_LIST_RETURN = 'app/admin-module/business-process/configuration/slas/GET_FORM_STATUS_LIST_RETURN';
export const GET_FORM_STATUS_LIST_ERROR = 'app/admin-module/business-process/configuration/slas/GET_FORM_STATUS_LIST_ERROR';
export const SAVE_SLAS = 'app/admin-module/business-process/configuration/slas/SAVE_SLAS';
export const SAVE_SLAS_RETURN = 'app/admin-module/business-process/configuration/slas/SAVE_SLAS_RETURN';
export const SAVE_SLAS_ERROR = 'app/admin-module/business-process/configuration/slas/SAVE_SLAS_ERROR';
export const PUBLISH_SLAS = 'app/admin-module/business-process/configuration/slas/PUBLISH_SLAS';
export const PUBLISH_SLAS_RETURN = 'app/admin-module/business-process/configuration/slas/PUBLISH_SLAS_RETURN';
export const PUBLISH_SLAS_ERROR = 'app/admin-module/business-process/configuration/slas/PUBLISH_SLAS_ERROR';
export const CLEAR_SAVE_SLAS_SUCCESS = 'app/admin-module/business-process/configuration/slas/CLEAR_SAVE_SLAS_SUCCESS';
export const CLEAR_SAVE_SLAS_ERROR = 'app/admin-module/business-process/configuration/slas/CLEAR_SAVE_SLAS_ERROR';
export const CLEAR_SLAS_VALIDATION_ERROR = 'app/admin-module/business-process/configuration/slas/CLEAR_SLAS_VALIDATION_ERROR';
export const SET_SLAS_ERROR_MESSAGE = 'app/admin-module/business-process/configuration/slas/SET_SLAS_ERROR_MESSAGE';

export const daysOfWeek = [
  {
    id: 1,
    value: 'mon',
    label: 'Monday',
    isChecked: false
  },
  {
    id: 2,
    value: 'tue',
    label: 'Tuesday',
    isChecked: false
  },
  {
    id: 3,
    value: 'wed',
    label: 'Wednesday',
    isChecked: false
  },
  {
    id: 4,
    value: 'thu',
    label: 'Thursday',
    isChecked: false
  },
  {
    id: 5,
    value: 'fri',
    label: 'Friday',
    isChecked: false
  },
  {
    id: 6,
    value: 'sat',
    label: 'Saturday',
    isChecked: false
  },
  {
    id: 7,
    value: 'sun',
    label: 'Sunday',
    isChecked: false
  }
]

export const slasActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_SLAS_SUCCESS,
    type: 'success',
    reducerContainer: 'slas',
    reducerItem: 'saveSlasMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_SLAS_ERROR,
    type: 'negative',
    reducerContainer: 'slas',
    reducerItem: 'saveSlasErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SLAS_VALIDATION_ERROR,
    type: 'negative',
    reducerContainer: 'slas',
    reducerItem: 'slasValidationErrorMessage',
    hasCloseIcon: true
  }
]
import { AvgAggregateFunction } from './avg.agg-func';
import { CountAggregateFunction } from './count.agg-func';
import { MaxAggregateFunction } from './max.agg-func';
import { MinAggregateFunction } from './min.agg-func';
import { SumAggregateFunction } from './sum.agg-func';
import {CountPositiveAggregateFunction} from "./count-positive.agg-func";

export const functionList = [
  new AvgAggregateFunction(),
  new CountAggregateFunction(),
  new CountPositiveAggregateFunction(),
  new MaxAggregateFunction(),
  new MinAggregateFunction(),
  new SumAggregateFunction()
]
import React from 'react';
import 'react-phone-input-2/lib/style.css'
import ReactPhoneInput from 'react-phone-input-2';

interface Props {
  setPhoneCountryCode?(value: string): void;
  phoneCountryCode?: string;
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const PhonePrefixField: React.FC<Props> = (props) => {
  return (<div id="phone-prefix">
    <label>Prefix</label>
    <ReactPhoneInput
      inputProps={{
        name: "phone",
        className: 'phone-input-field',
        type: 'text',
        style: { marginLeft: '35px', width: '25%' },
        disabled: true,
      }}
      country={props.phoneCountryCode ? props.phoneCountryCode.toLocaleLowerCase() : 'ph'}
      onChange={(_, data: CountryData) => {
        props.setPhoneCountryCode && props.setPhoneCountryCode(data.countryCode.toUpperCase())
      }}
      value={undefined}
      enableSearch
      disableSearchIcon
      autoFormat={false}
    />
  </div>
  )
}

export default PhonePrefixField;
import React from 'react';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';
import { actionPermissionList } from '../constant';
import { IStatusAction } from '../interface/section';

interface IActionsSlider {
  action: IStatusAction;
  handleActionPermissionChange: Function;
}

const ActionsSlider: React.FC<IActionsSlider> = (props) => {
  return <div className='action-details'>
    <div className='action-icon'>
      <SVGWrapper width='30' height='30' iconName={'newAction'} color={`#3b86a8`} />
    </div>
    <div className='action-label'>
      <span>{props.action.name}</span>
    </div>
    <div className='action-permission-list'>
      {actionPermissionList.map(permission => {
        const selected = props.action.permissions.accessLevel === permission.value;
        return <div key={permission.id} className={`action-permission-btn ${selected ? 'selected' : ''}`}
          onClick={() => props.handleActionPermissionChange(props.action, permission.value)}>
          {permission.label.toUpperCase()}
        </div>
      })}
    </div>
  </div>
}

export default ActionsSlider;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../common/api-request';
import { GET_FORM_STATUSES, GET_FORM_DETAIL, GET_FORM_STATUS, SAVE_PERMISSION, PUBLISH_PERMISSION, GET_PERMISSION_LIST } from './constant';
import {
  getFormStatusesErrorAction,
  getFormStatusesReturnAction,
  getFormDetailReturnAction,
  getFormDetailErrorAction,
  getFormStatusReturnAction,
  saveFormPermissionErrorAction,
  saveFormPermissionReturnAction,
  publishPermissionReturnAction,
  publishPermissionErrorAction,
  getPermissionListReturnAction,
  getPermissionListErrorAction
} from './action';
import { setStatusCodeAction } from '../../../../../error/action';
import { IFormStatusListInput } from '../workflow/interface/input/form-status-list';
import { IFormInput, IFormPermissionListInput } from './interface/input/form-details';
import { IForm } from '../form/interface/form';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IStatusFieldPermissionInput, } from './interface/input/field-permission';
import { IFormStatusWithAction } from '../workflow/interface/form-status';
import { IFormPermissionSaveInput, IFormPermissionPublishInput } from './interface/input/form-permission-save';
import { SanitizeFormPermission } from '../../../../../../common/utils/sanitize-form-permission';
import { SanitizeField } from '../../../../../../common/utils/sanitize-field';
import { IFormPermissionSection } from './interface/section';

export function* getFormStatusList(args: IFormStatusListInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true&load=actions`, {});
    yield put(getFormStatusesReturnAction({
      formStatusList: req.data.data
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getFormStatusesErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getFormDetail(args: IFormInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const formData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}?draft=true`, {});
    const fieldData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormDetailReturnAction({
      formData: formData.data.data as IForm,
      fieldData: SanitizeField.structureIncomingField(fieldData.data.data) as ICustomFieldDetail[] as ICustomFieldDetail[]
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getFormDetailErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getFormStatus(args: IStatusFieldPermissionInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const statusId = args.statusId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses/${statusId}?draft=true`, {});
    yield put(getFormStatusReturnAction({
      formStatusList: req.data.data as IFormStatusWithAction[]
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getFormStatusesErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* savePermission(args: IFormPermissionSaveInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const data = args.sanitize ? SanitizeFormPermission.structureOutgoing(args.permissionList as IFormPermissionSection[]) : args.permissionList;
    yield call(putRequest, `${companyId}/forms/${formId}/businessRules`, data);
    const reqBizRules: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/businessRules?draft=true`, {});
    yield put(saveFormPermissionReturnAction({
      permissionList: SanitizeFormPermission.structureIncoming(reqBizRules.data.data),
      saveRoleType: args.saveType
    }));
  } catch (e) {
    const error = e as any;
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    yield put(saveFormPermissionErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* publishRole(args: IFormPermissionPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishPermissionReturnAction())
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(publishPermissionErrorAction(JSON.stringify(error.response.data.message)))
  }
}

export function* getPermissionList(args: IFormPermissionListInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const reqBizRules: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/businessRules?draft=true`, {});
    yield put(getPermissionListReturnAction({
      permissionList: args.raw ? reqBizRules.data.data : SanitizeFormPermission.structureIncoming(reqBizRules.data.data)
    }));
  } catch (e) {
    console.log('e: ', e);
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getPermissionListErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* permissionSagas() {
  yield takeLatest(GET_FORM_STATUSES, getFormStatusList);
  yield takeLatest(GET_FORM_DETAIL, getFormDetail);
  yield takeLatest(GET_FORM_STATUS, getFormStatus);
  yield takeLatest(SAVE_PERMISSION, savePermission);
  yield takeLatest(PUBLISH_PERMISSION, publishRole);
  yield takeLatest(GET_PERMISSION_LIST, getPermissionList);
}

// All sagas to be loaded
export default permissionSagas;
import React, { useState } from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import { IField, IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import { DateProps } from '../../service/type/date';
import { FieldProperties } from '../../service/field-properties.service';
import { setMinMaxDateState } from './utils/set-min-max-date-state';
import { DatePickerComponent, RenderDayCellEventArgs, DatePicker } from '@syncfusion/ej2-react-calendars';
import moment from 'moment-timezone';
import { TValidationType } from '../../service/field-properties.handler';

const SingleDate: React.FC<AcceptedProps> = (props) => {
  let dateObject: DatePicker;
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const name = props.getFieldId();
  const elementProperties = new FieldProperties(name, props.forwardedRef, new DateProps());

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'date' })
  }
  const [refresher, setRefresher]: any = useState(1);
  const [minDate, setMinDate] = useState<any>(moment().subtract(100, 'years'));
  const [maxDate, setMaxDate] = useState<any>(moment().add(100, 'years'));

  const validateInput = (action: 'created' | 'blur') => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    if (action === 'created') {
      setRefresher(refresher + 1);
    }
  }

  const setMinMaxDate = () => {
    setMinMaxDateState({
      inputConfig: { ...props.inputConfig },
      forwardedRef: props.forwardedRef,
      fromRange: props.fromRange,
      parentRef: props.parentRef,
      maxDate: minDate,
      minDate: maxDate,
      setMaxDate,
      setMinDate,
    });
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValueString });
      setRefresher(refresher + 1);
    }
  }

  const getValue = () => {
    const data = elementProperties.getCurrent().value;
    return data ? new Date(moment(data).tz(tz).format("MMMM/DD/YYYY")) : undefined;
  }

  const triggerOnRenderCell = (args: RenderDayCellEventArgs) => {
    const { config } = { ...props.inputConfig };
    if (config && config.noWeekends) {
      if ((args.date as Date).getDay() === 0 || (args.date as Date).getDay() === 6) {
        args.isDisabled = true;
      }
    }
  }

  const triggerOnClick = () => {
    setMinMaxDate();
  }

  const triggerOnCreate = () => {
    if (props.isAddRecord) {
      setDefaultValue();;
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput('created');
    }
  }

  const triggerAutomationService = (value: any) => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value)
      }
    }
  }

  const triggerOnBlur = (event: any) => {
    validateInput('blur');
    const value = dateObject.value;
    const date = value ? moment(value).format("YYYY-MM-DD") : '';
    elementProperties.setCurrent({ ...elementProperties.current, value: date });
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    if (props.throwValueOutside) { props.throwValueOutside() };
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    triggerAutomationService(event.target.value);
  }

  const tiggerOnChange = ({ value }: any) => {
    const date = value ? moment(value).format("YYYY-MM-DD") : '';
    elementProperties.setCurrent({ ...elementProperties.current, value: date });
    if (props.filterElement) {
      if (props.triggerFilter) props.triggerFilter('');
    }
  }

  return <DatePickerComponent
    id={name}
    name={name}
    immediateRender
    showTodayButton
    showClearButton
    floatLabelType="Never"
    min={new Date(minDate)}
    max={new Date(maxDate)}
    value={getValue()}
    readOnly={props.accessType === IFieldAccessType.Readonly}
    placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
    renderDayCell={triggerOnRenderCell}
    created={triggerOnCreate}
    onClick={triggerOnClick}
    onBlur={triggerOnBlur}
    onChange={tiggerOnChange}
    ref={ref => { if (ref) dateObject = ref }}
  />
}
export default SingleDate;
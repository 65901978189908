import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteRequest, getRequest, IAxiosResponse, postRequest } from '../../../../../../../../common/api-request';
import {
  GET_DISPLAY_DATA_CONFIG,
  SAVE_DISPLAY_DATA_CONFIG,
  DELETE_DISPLAY_DATA_CONFIG,
  PUBLISH_DISPLAY_DATA_CONFIG
} from './constant';
import { IDisplayDataGetInput, IDisplayDataSaveInput, IDisplayDataDeleteInput } from './interface/input/display-data-action';
import {
  getDisplayDataConfigReturnAction,
  getDisplayDataConfigErrorAction,
  saveDisplayDataResponseAction,
  saveDisplayDataErrorAction,
  getDisplayDataConfigAssignmentReturnAction,
  deleteDisplayDataErrorAction,
  deleteDisplayDataReturnAction,
  publishDisplayDataReturnAction,
  publishDisplayDataReturnErrorAction
} from './actions';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { SanitizeDisplayData } from '../../../../../../../../common/utils/sanitize-display-data';

export function* getDisplayDataConfig(args: IDisplayDataGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;

    const configs: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/displayData/configs`, {});
    const configKeyPaths: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/displayData/configKeyPaths`, {});
    const fields: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/fields?draft=true`, {});

    const configAssignment: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/displayData/configs/assignments`, {});
    const configComponents: IAxiosResponse = yield call(getRequest, `${companyId}/displayDataComponents`, {});

    const displayData = SanitizeDisplayData.structureIncoming(configs.data.data);
    const displayDataAssignments = SanitizeDisplayData.structureIncomingDisplayDataAssignments(configAssignment.data.data, displayData);

    yield put(getDisplayDataConfigAssignmentReturnAction({
      displayDataAssignments: displayDataAssignments,
      displayDataComponents: configComponents.data.data
    }));

    yield put(getDisplayDataConfigReturnAction({
      displayData: displayData,
      displayDataConfigKeypath: configKeyPaths.data.data,
      fieldList: fields.data.data
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getDisplayDataConfigErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* saveDisplayDataConfig(args: IDisplayDataSaveInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;

    yield call(postRequest, `${companyId}/forms/${formId}/displayData/configs`, SanitizeDisplayData.structureOutgoing(args.displayDataList));
    yield call(postRequest, `${companyId}/forms/${formId}/displayData/configs/assignments`, SanitizeDisplayData.structureOutgoingDisplayDataAssignments(args.displayDataAssignments));

    const configs: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/displayData/configs`, {});
    const configAssignment: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/displayData/configs/assignments`, {});
    const displayData = SanitizeDisplayData.structureIncoming(configs.data.data);
    const displayDataAssignments = SanitizeDisplayData.structureIncomingDisplayDataAssignments(configAssignment.data.data, displayData);

    yield put(saveDisplayDataResponseAction({
      displayData: displayData,
      displayDataAssignments: displayDataAssignments,
      saveType: args.saveType,
    }))
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(saveDisplayDataErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* deleteDisplayDataConfig(args: IDisplayDataDeleteInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;

    if (args.activeMenu === 'Created Labels') {
      yield call(deleteRequest, `${companyId}/forms/${formId}/displayData/configs/${args.dataToDelete.id}`, {});
    }
    if (args.activeMenu === 'Applied Labels') {
      const statusId = args.dataToDelete.statusId === 'all' ? '' : `statusId=${args.dataToDelete.statusId}`;
      const component = args.dataToDelete.component === 'all' ? '' : `&component=${args.dataToDelete.component}`;
      yield call(deleteRequest, `${companyId}/forms/${formId}/displayData/configs/assignments?${statusId}${component}`, {});
    }

    yield put(deleteDisplayDataReturnAction({
      activeMenu: args.activeMenu,
      deletedData: args.dataToDelete
    }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(deleteDisplayDataErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* publishDisplayData(args: { company: string; formId: string, type: string }) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishDisplayDataReturnAction());
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e));
    yield put(publishDisplayDataReturnErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* displayDataSagas() {
  yield takeLatest(GET_DISPLAY_DATA_CONFIG, getDisplayDataConfig);
  yield takeLatest(SAVE_DISPLAY_DATA_CONFIG, saveDisplayDataConfig);
  yield takeLatest(DELETE_DISPLAY_DATA_CONFIG, deleteDisplayDataConfig);
  yield takeLatest(PUBLISH_DISPLAY_DATA_CONFIG, publishDisplayData);
}

// All sagas to be loaded
export default displayDataSagas;
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.FileUpload,
  required: false,
  type: 'file',
};

const value = {
  title: 'File Upload',
  style: {
    width: '20px',
    height: '20px'
  },
  icon: {
    black: require('../../icons/black/paperclip-vector-6.png'),
    white: require('../../icons/white/paperclip-vector-6.png'),
    blue: require('../../icons/blue/paperclip-vector-6.png'),
  },
  defaultData,
  defaultConfig: {}
}

export default value;
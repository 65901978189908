import prettyBytes from 'pretty-bytes';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import "react-sweet-progress/lib/style.css";
import { IRootState } from '../../../../../../reducers';
import { UploadedFile, uploadFile } from '../../../../../api-request/upload';
import { generatePdf } from '../../../../../api-request/generate-pdf';
import { icons } from '../../../../../icons';
import { AcceptedProps } from '../../interface/accepted-props';
import { v4 as uuid } from 'uuid';
import ToolTip from '../../../../../general/tooltip';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { FileCamera } from '../../../../../general/file-camera';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { useRouteMatch } from 'react-router';
import { TValidationType } from '../../../service/field-properties.handler';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { FileProps } from '../../../service/type/file';

const SweetProgress = require('react-sweet-progress');

const MultipleFileType: React.FC<AcceptedProps> = (props) => {
  const match: any = useRouteMatch();
  const name = props.getFieldId();
  const [errorUpload, setErrorUpload] = useState(null as Error | null);
  const [uploadProgress, setUploadProgress] = useState(null as number | null);
  const { company } = useSelector((state: IRootState) => state.auth);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [refresher, setRefresher] = useState('');

  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new FileProps())

  const validateIfRequired = () => {
    elementProperties.validate({ ...props }, TValidationType.onBlur);
  }

  const dataURLtoFile = (dataurl: any, filename: string): any => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onUpload = async (files: any, isGenerate?: boolean) => {
    if (!!files && files.length === 0 && !isGenerate) {
      // Nothing to upload
      return;
    }
    try {
      setErrorUpload(null);
      const uploadedFile = isGenerate && !files ?
        await generatePdf({
          companyId: company,
          documentId: match.params.documentId,
          fieldId: name,
          progress: (progress) => {
            setUploadProgress(progress);
          }
        })
        : await uploadFile({
          companyId: company,
          file: files[0],
          progress: (progress) => {
            setUploadProgress(progress);
          }
        });
      setUploadedFile(uploadedFile);
      setIsCameraOpen(false);
    } catch (e) {
      const error = e as any;
      console.log(`Upload error: `, e, ` Type `, error.constructor.name);
      setIsCameraOpen(false);
      setErrorUpload(error);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    window.removeEventListener('focus', handleFocusBack);
  }

  const setUploadedFile = async (uploadedFiles: UploadedFile, initial?: boolean) => {
    setUploadProgress(null);
    elementProperties.setCurrent({ value: uploadedFiles }, elementProperties.current.length)
    setRefresher(uuid())
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside && !initial) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  const handleTakePhoto = async (photoUri: string) => {
    var file = dataURLtoFile(photoUri, `${uuid()}.png`);
    onUpload([file]);
  }

  const handleGeneratePDF = async () => {
    onUpload(null, true);
  }

  const removeUploadFile = (index: number) => {
    const uploadedFiles = elementProperties.current.filter((_value: any, e: number) => { return e !== index });
    elementProperties.clearElement();
    uploadedFiles.forEach((file, index: number) => {
      elementProperties.setCurrent({ ...file }, index);
    })
    setUploadProgress(null);
    setRefresher(uuid());

    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateIfRequired();
    }
    // eslint-disable-next-line
  }, [])

  const iconForFile = (file: UploadedFile): string => {
    if (file.type.startsWith('image')) {
      return file.url;
    } else {
      return icons.black.paperclip;
    }
  }

  const partUploadedFile = (file: UploadedFile, index: number) => {
    return <li key={`uploaded-files-${uuid()}`} className='uploaded-files'>
      <span style={styles.uploadedFileSpan} className='uploaded-file-details-container'>
        <img className='uploaded-file-icon' src={iconForFile(file)} alt="" style={styles.uploadedFileIcon} />
        <ToolTip position='bottom center'
          popupMessage={file.name}
          popupTriggerComponent={
            <span className='uploaded-file-details'>
              <p className='file-name' style={styles.fileName} onClick={() => {
                if (props.openDocumentAttachment) {
                  props.openDocumentAttachment(name, index, isSystemGenerated())
                }
              }}>
                {file.name}
              </p>
              <p className='file-size' style={styles.fileSizeStyle}>
                ({prettyBytes(file.size)})
              </p>
            </span>
          }
        />
        {isWritable() && <i className={`icon close`} onClick={() => removeUploadFile(index)} style={styles.deleteButton} />}
      </span>
    </li >
  }

  const handleFocusBack = () => {
    window.removeEventListener('focus', handleFocusBack);
    if (props.accessType === IFieldAccessType.Required) {
      validateIfRequired();
    }
  }

  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  }

  const partNoFile = () => {

    const partNoFileFieldId = uuid();

    if (isWritable()) {
      return (
        <div className='upload-button field-input' style={styles.uploadButton}>
          <label style={styles.fileInput}>
            {isSystemGenerated() ?
              <button style={{ marginLeft: '15px', cursor: 'pointer' }} onClick={handleGeneratePDF}>Generate</button>
              :
              <>
                <input type="file" onClick={clickedFileInput} onChange={(e) => onUpload(e.target.files)} style={{ display: 'none' }} id={`inputFile-${partNoFileFieldId}`} />
                <button style={{ marginLeft: '15px' }}
                  onClick={() => {
                    const fileInputElement = document.querySelector(`input#inputFile-${partNoFileFieldId}`) as HTMLElement;
                    fileInputElement.click()
                  }}>Choose file</button>
              </>}
          </label>
          {(props.inputConfig && props.inputConfig.config.enableCameraInput) &&
            <div style={styles.cameraInput}>
              <img src={require('../../../../type/icon/camera.png')} alt="" style={{ cursor: 'pointer' }} onClick={() => setIsCameraOpen(!isCameraOpen)} />
            </div>
          }
        </div>
      )
    } else {
      return <span></span>;
    }
  }

  const isWritable = (): boolean => {
    return props.accessType !== IFieldAccessType.Readonly;
  }

  const isSystemGenerated = (): boolean => {
    return props.inputConfig && props.inputConfig.config &&
      props.inputConfig.config.systemGenerated === true;
  }

  const maxItems = props.inputConfig?.maxItems || 1;

  return <>
    <span className={`field-info ${refresher}`} style={{ height: '45px' }}>
      <label
        ref={(element) => setLabelRef(element, name)}
        className={`field-label`}>
        {props.label}
      </label>
      {
        props.hint &&
        <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
      }
    </span>
    {(uploadProgress !== null) ?
      <SweetProgress.Progress
        percent={Math.round(uploadProgress * 10000) / 100}
        style={styles.progess}
        theme={styles.progressTheme}
      > </SweetProgress.Progress>
      : <>
        {!(elementProperties.current.length >= maxItems) ? partNoFile() : <></>}
      </>
    }
    {!(elementProperties.current.length >= maxItems) ?
      <span className={`gap ${(!props.multiline) ? 'hidden' : ''}`} /> :
      <></>}
    {elementProperties.hasError() && props.showErrorMessage &&
      elementProperties.getError(0)[0].message.indexOf('Please provide value for') === -1 &&
      <Fragment>
        <span className={`gap ${(props.multiline) ? 'hidden' : ''}`} />
        <small className='field error' style={{ marginTop: '-5px' }}
          dangerouslySetInnerHTML={{ __html: elementProperties.getError(0)[0].message }} />
      </Fragment>
    }
    <ul className='uploaded-file-list' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
      {elementProperties.current.map((file, idx) => {
        if (idx < maxItems || maxItems === 1) {
          return file.value ? partUploadedFile(file.value, idx) : <></>
        } else {
          return <></>
        }
      })}
    </ul>
    {errorUpload && <pre className="ui prompt label" style={styles.errorMessage}>{errorUpload.message}</pre>}
    <FileCamera
      open={isCameraOpen}
      handleTakePhoto={handleTakePhoto}
      close={() => setIsCameraOpen(false)}
    />

  </>
}

const styles: { [key: string]: any } = {
  progess: {
    maxWidth: "200px"
  },
  progressTheme: {
    success: {
      color: '#86d94c'
    },
    active: {
      color: '#86d94c'
    },
    default: {
      color: '#86d94c'
    }
  },
  uploadedFileSpan: {
    "display": "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  uploadedFileIcon: {
    width: '100%',
    height: '100%',
    marginRight: '10px',
  },
  deleteButton: {
    cursor: 'pointer',
    marginLeft: '10px'
  },
  fileSizeStyle: {
    fontSize: '11px',
    color: 'gray',
    fontStyle: 'italic'
  },
  errorMessage: {
    gridColumn: '1/3'
  },
  fileName: {
    cursor: 'pointer',
    color: '#4183c4'
  },
  uploadButton: {
    display: 'flex',
    border: '1px solid #a9a9a9',
    backgroundColor: '#fff',
    minHeight: '35px'
  },
  uploadButtonDisabled: {
    border: '1px solid #a9a9a9',
    backgroundColor: '#e5e5e5',
    minHeight: '35px'
  },
  cameraInput: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    marginRight: '7px'
  },
  fileInput: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
}

export default MultipleFileType;
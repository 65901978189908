import { useEffect } from 'react';
import { IAutomationComponent } from '../../../../../../interface/component-list';
import Axios from 'axios';
import { checkIfUUID } from '../../../../../../../../../../../../../../common/utils/check-uuid-value';
import { fileInputField } from '../../../../../../constant';
import { FieldConstruct } from '../../../../../../../../../../../../../../common/field/field-construct';
import { IField } from '../../../../../../../../../../../users/interface/field';

interface IProps {
  company: string;
  selectedComponent: IAutomationComponent;
  emailStringRef: React.MutableRefObject<any>;
  subjRef: React.MutableRefObject<HTMLInputElement>;
  setSelectedEmail: (value: React.SetStateAction<any[]>) => void;
  setSelectedEmailCc: (value: React.SetStateAction<any[]>) => void;
  setSelectedEmailBcc: (value: React.SetStateAction<any[]>) => void;
  setOperation: (value: React.SetStateAction<{ [id: string]: string }>) => void;
  setSelectedEmailFile: (value: React.SetStateAction<any[]>) => void;
  fileInputRef: any;
  fileInputRefValue: any;
  setRefresher: (value: React.SetStateAction<number>) => void;
  refresher: number;
}

export const useSetSendEmailStateRefs = (props: IProps) => {
  const {
    selectedComponent,
    emailStringRef,
    subjRef,
    setSelectedEmail,
    setSelectedEmailCc,
    setOperation,
    setSelectedEmailFile,
    company,
    fileInputRef,
    setRefresher,
    refresher,
    setSelectedEmailBcc,
  } = { ...props };

  const fetchFileInfo = async (file: any, index: number) => {
    if (typeof file === 'object') {
      return file;
    }
    if (props.company && file && typeof file === 'string') {
      try {
        const request = await Axios.get(`${company}/uploads/${file}/file`);
        return {
          ...request.data.data,
          name: `attachment-${index + 1}`,
          type: 'unknown',
          size: 0,
        };
      } catch (e) {
        return e;
      }
    }
  };

  const setSelectedEmailFileToRefs = (attachments: any[]) => {
    if (attachments.length) {
      Promise.all(
        attachments.map((attach, index) => fetchFileInfo(attach, index))
      ).then((files) => {
        const attachmentData = files.reduce(function (acc, cur, i) {
          acc[`attachments-${i + 1}`] = cur;
          return acc;
        }, {});
        const attachmentFields = attachments
          .map((attach: any, index: number) => {
            const attachmentId = `attachments-${index + 1}`;
            if (attach && typeof attach === 'string' && checkIfUUID(attach)) {
              return { ...fileInputField, id: attachmentId };
            }
            return null;
          })
          .filter((e: any) => !!e) as IField[];
        FieldConstruct.setFieldToRefs(
          attachmentFields,
          { fields: attachmentData },
          props.fileInputRefValue
        );
        setRefresher(refresher + 1);
      });
    }
  };

  const setOperationRefs = (config: any, tempContainer: any) => {
    const configKeys = ['cc', 'to', 'bcc', 'attachments'];
    if (config) {
      configKeys.forEach((keys) => {
        if (!tempContainer[keys]) {
          tempContainer[keys] = [];
        }
        if (config[keys] && Array.isArray(config[keys])) {
          config[keys].forEach((data: any, index: number) => {
            if (
              data &&
              data.hasOwnProperty('keyPath') &&
              data.hasOwnProperty('referencedKeyPath')
            ) {
              tempContainer[keys][index] = 'form';
            } else {
              tempContainer[keys][index] = 'manual';
            }
          });
        }
      });
    }
    return tempContainer;
  };

  const parseEmailConfig = (config: any) => {
    Object.keys(config).forEach((key) => {
      if (config[key] && Array.isArray(config[key])) {
        config[key] = config[key].map((e: any) => {
          if (e && typeof e === 'object' && e.hasOwnProperty('placeholder')) {
            e = {
              keyPath: e.placeholder,
              referencedKeyPath: 'current-form',
            };
          }
          if (
            e &&
            typeof e === 'object' &&
            (e.hasOwnProperty('referencedKeyPath') ||
              e.hasOwnProperty('keyPath'))
          ) {
            Object.keys(e).forEach((key) => {
              if (e[key] && e[key].includes('fields.')) {
                e[key] = e[key].replace('fields.', '');
              }
            });
            e = {
              keyPath: e.keyPath,
              referencedKeyPath:
                e.keyPath && !e.referencedKeyPath
                  ? 'current-form'
                  : e.referencedKeyPath,
            };
          }
          return e;
        });
      }
    });
    return config;
  };

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      let config = { ...selectedComponent.config };
      let { cc, to, attachments, bcc }: any = parseEmailConfig(config);
      const tempContainer: any = {};
      const subject = selectedComponent.config.subject || '';

      if (!cc || (cc && cc.length === 0)) cc = [''];
      if (!to || (to && to.length === 0)) to = [''];
      if (!bcc || (bcc && bcc.length === 0)) bcc = [''];
      if (!attachments || (attachments && attachments.length === 0))
        attachments = [''];

      fileInputRef.current = attachments;
      subjRef.current.value = subject;
      emailStringRef.current['to'] = to;
      emailStringRef.current['cc'] = cc;
      emailStringRef.current['bcc'] = bcc;
      setSelectedEmailFileToRefs(attachments);
      setSelectedEmail(to);
      setSelectedEmailCc(cc);
      setSelectedEmailBcc(bcc);
      setSelectedEmailFile(attachments);
      setOperationRefs({ cc, to, attachments, bcc }, tempContainer);
      setOperation(tempContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

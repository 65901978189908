import { useEffect } from 'react';
import { IDataTable } from '../../reducer';

interface IProps {
  dataTableNameRef: React.MutableRefObject<HTMLInputElement>;
  forUpdateDataTableDetails: IDataTable;
  dataTableId: string;
}

export const useSetDatatableDetailsToRefs = (props: IProps) => {
  const { dataTableNameRef, dataTableId, forUpdateDataTableDetails } = { ...props };

  useEffect(() => {
    if (forUpdateDataTableDetails && dataTableId !== 'add-data-table') {
      dataTableNameRef.current.value = forUpdateDataTableDetails.name ? forUpdateDataTableDetails.name : '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forUpdateDataTableDetails, dataTableId]);

  return { ...props };
}
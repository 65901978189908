import React from 'react';

interface IBoolSelectionProps {
  value: boolean;
  setBoolSelected(value: boolean): void;
}

const BoolSelection = (props: IBoolSelectionProps) => {

  const setBoolSelected = (value: boolean) => {
    props.setBoolSelected(value);
  }

  return <div className={`bool-selection`}>
    <span onClick={() => setBoolSelected(true)} className={`${props.value ? 'selected' : ''}`}>True</span>
    <span onClick={() => setBoolSelected(false)} className={`${!props.value ? 'selected' : ''}`}>False</span>
  </div>
}

export default BoolSelection;
import { useEffect, useState } from 'react';
import { IForm } from '../../form/interface/form';

interface IProps {
  publishedForm: IForm[];
}

export const useSetBPImage = (props: IProps) => {
  const { publishedForm } = { ...props };

  const [bpImage, setBpmImage] = useState({} as { [bpId: string]: { src: any, seq: number } });

  useEffect(() => {
    let newBpImage = { ...bpImage };
    publishedForm.forEach((form: IForm) => {
      if (form.id) {
        newBpImage[form.id] = {
          src: form.icon,
          seq: form.seq ? form.seq : 0
        }
        setBpmImage(newBpImage);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(publishedForm)])

  return { bpImage, setBpmImage, ...props };
}
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { UploadedFile } from '../../../../../../../common/api-request/upload';
import { getFileType } from '../../widget/messages/system/delta-fields/utils/get-file-type';
import { imageForFile } from '../../widget/messages/system/delta-fields/utils/image-for-file';

export const renderInputFileComponent = (file: UploadedFile, displaySize: boolean) => {
  const fileType = getFileType(file);
  const fileImage = imageForFile(file, false);

  if (fileType.isImage && fileType.isSupportedImage) {
    return <Image
      title={file.name}
      className='uploaded-image'
      src={file.url}
    />;
  } else {
    return <div className='attachment-info'>
      <Image className='file-icon'
        style={{ ...fileImage.iconStyle }}
        src={fileImage.icon}
      />
      <div style={{ color: fileImage.iconStyle.color }}
        title={file.name} className='file-name'>
        {file.name}
      </div>
      {displaySize &&
        <div className='file-size'>
          {prettyBytes(file.size)}
        </div>
      }
    </div>
  }
}
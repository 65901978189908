import React from 'react';
import { useDispatch } from 'react-redux';
import StatusActionTable from './status-action-table';
import { Route, useRouteMatch } from 'react-router-dom';
import NoIdRedirect from '../../no-id-redirect';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { useSetThirdLevelRoute } from '../../../../hooks/use-set-third-level-route';

interface IWorkflowProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Workflow {

  name = 'workflow';

  render: React.FC<IWorkflowProps> = (props) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: this.name,
      currentRoute: this.name
    });

    return (
      <>
        <Route exact path={`${match.path}`}>
          <NoIdRedirect />
        </Route>
        <Route path={`${match.path}/:formId`}>
          <StatusActionTable hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </>
    );
  }
}

export default Workflow;

import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';
import { IOption } from '../../../../../../../../../common/interface/option';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';

interface IContentLowerFieldList {
  index: number;
  fieldId: string;
  columnOptions: IOption[];
  addAdditionalFields(): void;
  additionalFields: string[];
  changeFieldSequence(field: string, dragIndex: number, dropIndex: number): void;
  updateAdditionalFields(value: any, index: number): void;
}

const ContentLowerFieldList: React.FC<IContentLowerFieldList> = (props) => {

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'additionalFields',
    drop: selectedItem => {
      const data: any = selectedItem;
      props.changeFieldSequence(data.field, data.index, props.index)
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'additionalFields', field: props.fieldId, index: props.index },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  return <div ref={dropRef} className='dropdown-select-list'>
    <div ref={dragRef} style={{ ...draggedObject }} className='field-select'>
      <SVGWrapper iconName='dragIcon' width='18' height='18' color='#3d87ab' />
      <Dropdown
        className='input-dropdown'
        search
        clearable
        selectOnBlur={false}
        placeholder='Select Field'
        selection
        options={props.columnOptions}
        onChange={(e, target: DropdownProps) => {
          props.updateAdditionalFields(target.value, props.index);
        }}
        value={props.fieldId}
      />
    </div>
    {((props.additionalFields.length === 0 && props.index === 0)
      || (props.additionalFields.length < 4 && props.index === props.additionalFields.length - 1)) &&
      <div className='btn-plus' onClick={props.addAdditionalFields}>
        <SVGWrapper iconName='plus' width='13' height='13' color='#fff' />
      </div>
    }
  </div>
}

export default ContentLowerFieldList;

import React from 'react';

export type TConditionConnectorType = 'and' | 'or';

interface IConditionConnectorProps {
  type: TConditionConnectorType;
  setConditionConnector(value: TConditionConnectorType): void;
}

const ConditionConnector = (props: IConditionConnectorProps) => {
  return <div className="condition-join-method">
    <label>Condition: </label>
    <div className="condition-container">
      <label onClick={() => props.setConditionConnector('and')} className={`${props.type === 'and' ? 'selected' : ''}`}>And</label>
      <label onClick={() => props.setConditionConnector('or')} className={`${props.type === 'or' ? 'selected' : ''}`}>Or</label>
    </div>
  </div>
}

export default ConditionConnector;
import React, { createRef, useRef, useEffect, useState } from 'react';
import { List, Popup } from 'semantic-ui-react-bpm';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import { v4 as uuid } from 'uuid';
import { useRect } from '../../utils/useRect';

interface IDropdownField {
  data: IFieldData;
  detail: FieldElement;
  overflowed?: boolean;
  useChip?: boolean;
  containerRect?: any;
}

const containerRef = createRef<HTMLDivElement>();
const valueRef = React.createRef<HTMLSpanElement>();


const DropdownCustomField: React.FC<IDropdownField> = (props) => {
  const { config } = props.detail.inputConfig;

  const containerRect = useRect(containerRef);
  const valueRect = useRect(valueRef);
  const componentRef = useRef<HTMLDivElement>(null);

  const [showMoreItems, setShowMoreItems] = useState<any>([]);

  let fieldKey = props.data.fields && props.data.fields[props.detail.id] ? props.data.fields[props.detail.id] : [];

  if (fieldKey && !(fieldKey instanceof Array)) {
    fieldKey = [fieldKey];
  }

  useEffect(() => {
    const hiddenChildren: any[] = [];
    if (componentRef && componentRef.current) {
      const childNodes: any = componentRef.current.children;
      for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        if (child.style.display === 'none') {
          hiddenChildren.push(child.textContent);
        }
      }
    }
    setShowMoreItems(hiddenChildren);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerRect])

  const itemsToShow = () => {

    if (!showMoreItems.length) {
      return null;
    }

    return <Popup
      id='e-chip-list-overflow'
      position='top center'
      on='click'
      className='e-tooltip-wrap'
      basic
      trigger={
        <span className='show-more'>+{showMoreItems.length} more..</span>
      }>
      {showMoreItems && showMoreItems.map((children: any, idx: number) => {
        return <span key={idx} className='e-chip-list e-chip'>
          {children}
        </span>
      })}
    </Popup>
  }

  const listRef = (ref: any) => {
    if (ref) {
      const position = ref.getBoundingClientRect();
      if (position && position.right > 0 && config.multiselect && props.containerRect
        && props.containerRect.right > 0 && position.right > props.containerRect.right) {
        ref.style.display = 'none';
      }
    }
    return ref;
  }

  if (props.overflowed) {
    const component = <div id={`value-${props.detail.id}-${props.data.id}`} ref={containerRef} className={`single line ${config.multiselect ? 'tr-dropdown-type' : ''}`}>
      <span ref={valueRef} className='list-wrap'>
        {fieldKey && fieldKey.map((list: any) => {
          return (
            <List key={uuid()} className="tr-alignment overflow" >
              <List.Item>{list.label}</List.Item>
            </List>
          )
        })}
      </span>
    </div>;
    if (valueRect.width > containerRect.width) {
      return <Popup id='popup-dropdown-overflow'
        style={{ maxWidth: '400px', padding: '10px' }}
        position='top center' trigger={component}>
        {component}
      </Popup>
    } else {
      return component
    }
  }

  if (props.useChip) {
    return <div ref={componentRef} className='e-chip-list-wrap'>
      {fieldKey && fieldKey.map((list: any, index: number) => {
        if (!config.multiselect) {
          return <span key={uuid()} className="e-chip-text">{list.label}</span>
        }
        return <span
          key={uuid()}
          id='chip-avatar'
          className={config.multiselect ? `e-control e-chip-list e-lib e-chip` : ''}
          role="listbox"
          ref={listRef}>
          <span className='e-chip' >
            <span className="e-chip-text">{list.label}</span>
          </span>
        </span>
      })}
      {itemsToShow()}
    </div>
  }

  return (
    <div className={`single line ${config.multiselect ? 'tr-dropdown-type' : ''}`}>
      <span ref={valueRef}>
        {fieldKey && fieldKey.map((list: any) => {
          return (
            <List key={uuid()} className={`tr-alignment`}>
              <List.Item>{list.label}</List.Item>
            </List>
          )
        })}
      </span>
    </div>
  )
}

export default DropdownCustomField;
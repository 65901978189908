import React from 'react';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';

interface ISwitchField {
  data: IFieldData;
  detail: FieldElement;
}

const SwitchCustomField: React.FC<ISwitchField> = (props) => {
  const isChecked = props.data.fields ? props.data.fields[props.detail.id] || false : false;

  return (<div>
    <div key={`column-${props.detail.id}-${props.data.id}`} className="ui read-only toggle checkbox checked table-checkbox-toggle">
      <input style={{ cursor: 'default' }} type="checkbox" disabled readOnly checked={isChecked} />
      <label></label>
    </div>
  </div>)
}

export default SwitchCustomField;
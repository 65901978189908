import { useEffect } from 'react';
import { getRoleListAction } from '../../../roles/action';
import {
  getFormStatusListAction,
  getFormActionListAction
} from '../../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetRoleListData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (formId && company) {
      dispatch(getRoleListAction({ companyId: company, formId: formId }));
      dispatch(getFormStatusListAction({ companyId: company, formId: formId }));
      dispatch(getFormActionListAction({ companyId: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, company]);
}
import { useEffect } from 'react';
import { currentRouteAction, setThirdLevelRouteAction } from '../../main/action';

interface IProps {
  mainRoute: string;
  subRoute: string;
  thirdLevelRoute: string;
  dispatch: any;
  currentRoute?: string;
}

export const useSetThirdLevelRoute = (props: IProps) => {
  const { mainRoute, subRoute, thirdLevelRoute, dispatch, currentRoute } = { ...props };

  useEffect(() => {
    dispatch(
      setThirdLevelRouteAction({
        mainRoute: mainRoute,
        subRoute: subRoute,
        thirdLevelRoute: thirdLevelRoute
      })
    );
    dispatch(currentRouteAction(currentRoute || ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
import React, { useEffect, useState } from 'react';
import { TRulesPermission, TConfigType } from '..';
import { IFieldPermissionSettings } from '../../..';
import { accessLevelToValue, valueToAccessLevel } from '../../../../constant';
import { IRulesPermission } from '../../../../interface/rules';

interface IPermissionConfigProps extends IFieldPermissionSettings {
  boolSelected: boolean;
  rules: IRulesPermission;
  updateConfig(configType: TConfigType, configOption: TRulesPermission, value: any): void;
}

class PermissionConfig {

  name = 'setPermission'

  render: React.FC<IPermissionConfigProps> = (props) => {

    const [permission, setPermission] = useState(3);

    const handlePermissionChange = (value: string) => {
      setPermission(parseInt(value));
      props.updateConfig(props.boolSelected ? 'onTrue' : 'onFalse', 'setPermission', valueToAccessLevel[value]);
    }

    useEffect(() => {
      if (props.rules) {
        setPermission(accessLevelToValue[props.rules.setPermission || 'editable']);
      }
    }, [props.rules])

    return <div className='slider-container-main'>
      <div className='slider-container no-label'>
        <label></label>
        <div id="slider-config-container">
          <ul>
            <li>Hidden</li>
            <li>Read-only</li>
            <li>Editable</li>
            <li>Required</li>
          </ul>
          <input type="range" step='1' min="1" max="4" value={permission || 1}
            onChange={(e) => handlePermissionChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  }
}

export default PermissionConfig;
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_STATUS_LIST = 'app/admin-module/business-process/configuration/apis/GET_STATUS_LIST';
export const GET_API_CONFIG = 'app/admin-module/business-process/configuration/apis/GET_API_CONFIG';
export const GET_API_CONFIG_RETURN = 'app/admin-module/business-process/configuration/apis/GET_API_CONFIG_RETURN';
export const GET_STATUS_LIST_RETURN = 'app/admin-module/business-process/configuration/apis/GET_STATUS_LIST_RETURN';
export const UPDATE_LOCAL_API_LIST = 'app/admin-module/business-process/configuration/apis/UPDATE_LOCAL_API_LIST';
export const SAVE_DRAFT_API_CONFIG = 'app/admin-module/business-process/configuration/apis/SAVE_DRAFT_API_CONFIG';
export const SAVE_DRAFT_API_CONFIG_RETURN = 'app/admin-module/business-process/configuration/apis/SAVE_DRAFT_API_CONFIG_RETURN';
export const PUBLISH_API_CONFIG = 'app/admin-module/business-process/configuration/apis/PUBLISH_API_CONFIG';
export const PUBLISH_API_CONFIG_RETURN = 'app/admin-module/business-process/configuration/apis/PUBLISH_API_CONFIG_RETURN';
export const SAVE_API_CONFIG_ERROR = 'app/admin-module/business-process/configuration/apis/SAVE_API_CONFIG_ERROR';
export const CLEAR_SAVE_API_CONFIG_SUCCESS = 'app/admin-module/business-process/configuration/apis/CLEAR_SAVE_API_CONFIG_SUCCESS';
export const CLEAR_SAVE_API_CONFIG_ERROR = 'app/admin-module/business-process/configuration/apis/CLEAR_SAVE_API_CONFIG_ERROR';

export const externalApiPlugin = 'com.bpm.external-api';

export const methodOptionList = [
  {
    key: 0,
    value: 'post',
    text: 'post'
  },
  {
    key: 1,
    value: 'get',
    text: 'get'
  },
];

export const authTypeOptionList = [
  {
    key: 0,
    value: 'api-key',
    text: 'api-key'
  },
  {
    key: 1,
    value: 'bearer-token',
    text: 'bearer-token'
  },
  {
    key: 2,
    value: 'custom_op',
    text: 'custom_op'
  }
];

export const triggerEventOptionList = [
  {
    key: 0,
    value: 'creation',
    text: 'creation',
  },
  {
    key: 1,
    value: 'updated',
    text: 'updated',
  },
  {
    key: 2,
    value: 'status-changed',
    text: 'status-changed',
  },
];

export const apiConfigActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_API_CONFIG_SUCCESS,
    type: 'success',
    reducerContainer: 'configurationAPIs',
    reducerItem: 'saveApiConfigMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_API_CONFIG_ERROR,
    type: 'negative',
    reducerContainer: 'configurationAPIs',
    reducerItem: 'saveApiConfigErrorMessage',
    hasCloseIcon: true
  }
]
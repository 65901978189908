import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { IComment } from '../../../interface/comment';
import { ISenderDetail } from '../../../interface/sender-info';
import { commentSenderUserInfoData } from '../../utils/sender-user-info';

interface IChatCommentAvatar {
  comment: IComment;
  errorImage: string[];
  userColor: { [id: string]: string };
  addToErrorImage(imageId: string): void;
  avatarClassName?: string;
  selectUser?(value: string): void;
  userIconClassName?: string;
}

const CommentWidgetAvatar: React.FC<IChatCommentAvatar> = ({
  comment,
  userColor,
  errorImage,
  addToErrorImage,
  avatarClassName,
  selectUser,
  userIconClassName
}) => {

  const senderUserInfo = { ...(comment.sender && comment.sender.data) } as ISenderDetail;

  const {
    firstLetter,
    firstName,
    lastLetter,
    lastName,
    userNameBg,
  } = commentSenderUserInfoData(senderUserInfo, userColor);

  const hasUserAvatar = () => {
    return senderUserInfo && errorImage.indexOf(senderUserInfo.id || 'noId') === -1
      && senderUserInfo.avatar;
  }

  const handleSelectUser = () => {
    if (!selectUser) return;
    selectUser(senderUserInfo.id);
  }

  if (!hasUserAvatar()) {
    return <div title={`${firstName} ${lastName}`} onClick={handleSelectUser}
      className={`${userIconClassName ? userIconClassName : 'user-name-icon'}`}>
      <span style={userNameBg}>{firstLetter}{lastLetter}</span>
    </div>
  }

  return <div title={`${firstName} ${lastName}`} onClick={handleSelectUser}
    className={`${avatarClassName ? avatarClassName : 'user-name-avatar'}`}>
    <Image className={`${!avatarClassName ? 'user-icon' : ''}`} src={senderUserInfo.avatar}
      onError={() => addToErrorImage(senderUserInfo?.id)}
    />
  </div>
};

export default CommentWidgetAvatar;

import { useEffect, useState } from 'react';
import { IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
}

export const useSetInitialFieldUpdateStateValue = (props: IProps) => {
  const { selectedComponent } = { ...props };

  const [fieldSelected, setFieldSelected] = useState<string[]>([]);
  const [selectedForAction, setSelectedForAction] = useState<string[]>([]);

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      const fromStatusIds = selectedComponent.config.sourceFields || [];
      const toStatusIds = selectedComponent.config.forAction || [];
      setFieldSelected(fromStatusIds)
      setSelectedForAction(toStatusIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return {
    fieldSelected, setFieldSelected,
    selectedForAction, setSelectedForAction,
    ...props
  };
}
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react-bpm';

interface IRecordsHeader {
  dataTableName: string;
  dataTableId: string;
  companyId: string;
}

const DataTableDetails: React.FC<IRecordsHeader> = (props) => {
  const history = useHistory();

  const handleEditTable = (id: string) => {
    history.push(`/admin/data-tables/${id}`);
  }

  return <Segment className='borderless filter-wrapper table-record-heading'>
    <span className="e-icons e-arrow-left" onClick={() => history.goBack()} />
    <Header as='h2' floated='left'>{props.dataTableName}</Header>
    <Button className='add-button' primary size={"large"} floated={'right'}
      onClick={() => handleEditTable(props.dataTableId)}>
      Edit Table
    </Button>
  </Segment>
}

export default DataTableDetails;
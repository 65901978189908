import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import { CLEAR_TASK_VIEW_MESSAGE, GET_FORM_FIELD, GET_FORM_FIELD_RETURN, GET_FORM_STATUS, GET_FORM_STATUS_RETURN, GET_TASK_VIEW, GET_TASK_VIEW_RETURN, PUBLISH_TASK_VIEW, PUBLISH_TASK_VIEW_ERROR, PUBLISH_TASK_VIEW_RETURN, SAVE_TASK_VIEW, SAVE_TASK_VIEW_ERROR, SAVE_TASK_VIEW_RETURN } from './constant';
import { IApiTaskView, ITaskView } from './interface/task-view';
import { message } from '../../../../message';

export interface ITaskViewReducer {
  type?: string;
  saveType: string;
  formStatusList: IFormStatusWithAction[];
  formFieldList: ICustomFieldDetail[];
  taskView: ITaskView;
  saveTaskViewSuccessMessage: string;
  saveTaskViewErrorMessage: string;
  triggerSuccessfulSave: boolean;
  taskViewLoading: boolean;
  apiTaskView: IApiTaskView;
}

const initialState: ITaskViewReducer = {
  formStatusList: [],
  formFieldList: [],
  taskView: {},
  saveTaskViewSuccessMessage: '',
  saveTaskViewErrorMessage: '',
  saveType: '',
  triggerSuccessfulSave: false,
  taskViewLoading: false,
  apiTaskView: {} as IApiTaskView
};

const taskViewReducer = (state = initialState, action: ITaskViewReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_STATUS:
      newState.formStatusList = [];
      return newState;

    case GET_FORM_STATUS_RETURN:
      newState.formStatusList = action.formStatusList;
      return newState;

    case GET_FORM_FIELD:
      newState.formFieldList = [];
      return newState;

    case GET_FORM_FIELD_RETURN:
      newState.formFieldList = action.formFieldList;
      return newState;

    case GET_TASK_VIEW:
      newState.triggerSuccessfulSave = false;
      newState.taskView = {};
      newState.taskViewLoading = true;
      return newState;

    case GET_TASK_VIEW_RETURN:
      newState.taskView = action.taskView;
      newState.apiTaskView = action.apiTaskView;
      newState.taskViewLoading = false;
      return newState;

    case SAVE_TASK_VIEW:
      newState.triggerSuccessfulSave = false;
      newState.taskViewLoading = true;
      return newState;

    case SAVE_TASK_VIEW_RETURN:
      newState.triggerSuccessfulSave = true;
      newState.taskView = action.taskView;
      newState.saveType = action.saveType
      if (action.saveType !== 'publish') {
        newState.saveTaskViewSuccessMessage = message.saveFormBuilderSuccess;
      }
      newState.taskViewLoading = false;
      return newState;

    case SAVE_TASK_VIEW_ERROR:
      newState.triggerSuccessfulSave = false;
      newState.taskViewLoading = false;
      newState.saveTaskViewErrorMessage = action.saveTaskViewErrorMessage;
      return newState;

    case PUBLISH_TASK_VIEW:
      newState.triggerSuccessfulSave = false;
      newState.taskViewLoading = true;
      newState.saveType = '';
      return newState;

    case PUBLISH_TASK_VIEW_RETURN:
      newState.saveTaskViewSuccessMessage = message.publishTaskViewSuccess;
      newState.taskViewLoading = false;
      return newState;

    case PUBLISH_TASK_VIEW_ERROR:
      newState.saveTaskViewErrorMessage = action.saveTaskViewErrorMessage;
      newState.taskViewLoading = false;
      return newState;

    case CLEAR_TASK_VIEW_MESSAGE:
      newState.saveTaskViewErrorMessage = '';
      newState.saveTaskViewSuccessMessage = '';
      newState.triggerSuccessfulSave = false;
      newState.taskViewLoading = false;
      return newState;

    default:
      return state;
  }
}

export default taskViewReducer

import { IUserSettingsUpdateInput } from './interface/input/user-settings-update';
import { updateUserAccountErrorAction } from './../user-account/action';
import { GET_USER_SETTINGS, UPDATE_USER_SETTINGS } from './constant';
import { getUserSettingsReturnAction, getUserSettingsErrorAction, updateUserSettingsReturnAction } from './action';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, putRequest } from '../../../../common/api-request';
import { setStatusCodeAction } from '../../../error/action';
import { IUserSettingsInput } from './interface/input/user-settings';

export function* getUserSettings(args: IUserSettingsInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/userSettings`, {});
    yield put(getUserSettingsReturnAction({ data: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.error(e.response);
    yield put(getUserSettingsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* updateUserSettings(args: IUserSettingsUpdateInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(putRequest, `${company}/userSettings`, { notificationsEmail: args.data.notificationsEmail });
    yield put(updateUserSettingsReturnAction({ data: req.data.data }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.error(e.response);
    yield put(updateUserAccountErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* userSettingsSagas() {
  yield takeLatest(GET_USER_SETTINGS, getUserSettings);
  yield takeLatest(UPDATE_USER_SETTINGS, updateUserSettings)
}

// All sagas to be loaded
export default userSettingsSagas;
import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { TRulesPermission } from '../default';

type TConfigSettingsType = 'action' | 'section' | 'fields' | 'table';

interface IConditionConfigSelection {
  setConfigSelected(configOption: TRulesPermission): void;
  configOption: TRulesPermission;
  configSettingsType: TConfigSettingsType;
  fieldData: ICustomFieldDetail;
  boolSelected: boolean;
}

const ConditionConfigSelection = (props: IConditionConfigSelection) => {

  const showSetValueConfig = () => {
    return props.configSettingsType !== 'section' && props.fieldData.type !== 'table' && props.configSettingsType !== 'action';
  }

  const showClearConfig = () => {
    return props.configSettingsType !== 'action'
  }

  return <div className={`frontend-automation-config-selection`}>
    <span className={`${props.configOption === 'setPermission' ? 'active' : ''}`}
      onClick={() => props.setConfigSelected('setPermission')}>Set Permissions</span>

    {props.configSettingsType === 'action' &&
      <span className={`${props.configOption === 'setDisabledMessage' ? 'active' : ''}`}
        onClick={() => props.setConfigSelected('setDisabledMessage')}>Set Disabled Message</span>
    }

    {showSetValueConfig() &&
      <span className={`${props.configOption === 'setValue' ? 'active' : ''}`}
        onClick={() => props.setConfigSelected('setValue')}>Set Value</span>
    }

    {showClearConfig() && <span className={`${props.configOption === 'shouldClear' ? 'active' : ''}`}
      onClick={() => props.setConfigSelected('shouldClear')}>Clear</span>
    }
  </div>
}

export default ConditionConfigSelection;
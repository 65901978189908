import { useEffect, useState } from 'react';
import { FieldElement } from '../../../../../../../../../../../../../../common/field/component/field-element';
interface IProps {
  operation: { [id: string]: string };
  dynamicRef: any;
  field: FieldElement;
}

export const useSetEditFormSelectedFields = (props: IProps) => {
  const { dynamicRef, field } = { ...props };

  const [operation, setOperation] = useState<'copy' | 'value' | ''>('');
  const [sourceReference, setSourceReference] = useState('');
  const [fieldId, setFieldId] = useState('');

  const setStateValues = (current: any) => {
    if (!current || current === undefined) return;

    const { referencedKeyPath, keyPath } = current;
    if (referencedKeyPath || keyPath) {
      setFieldId(keyPath)
      setSourceReference(referencedKeyPath);
      if (keyPath && !referencedKeyPath) {
        setSourceReference('current-form');
      }
    }
  }

  useEffect(() => {
    if (dynamicRef && dynamicRef.current && dynamicRef.current[field.id]) {
      if (dynamicRef.current[field.id].hasOwnProperty('value')) {
        setStateValues(dynamicRef.current[field.id].value);
      } else {
        setStateValues(dynamicRef.current[field.id]);
      }
    }
    setOperation(props.operation[field.id] as 'copy' | 'value' || 'value')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ...props, operation, setOperation, sourceReference, setSourceReference, fieldId, setFieldId }
}
import { useEffect, useState } from 'react';
import { IApiConfiguration } from '../../interface/api-configuration';

interface IProps {
  apiConfigurationList: IApiConfiguration[];
  apiId: any;
  authBodyRef: React.MutableRefObject<HTMLTextAreaElement>;
  authTokenPathRef: React.MutableRefObject<HTMLInputElement>;
  authUrlRef: React.MutableRefObject<HTMLInputElement>;
  nameRef: React.MutableRefObject<HTMLInputElement>;
  descriptionRef: React.MutableRefObject<HTMLInputElement>;
  urlRef: React.MutableRefObject<HTMLInputElement>;
  history: any;
  match: any;
}

export const useSetApiFormStateAndRefs = (props: IProps) => {
  const { apiConfigurationList, apiId, authBodyRef, authTokenPathRef,
    authUrlRef, nameRef, descriptionRef, urlRef, history, match } = { ...props };

  const [method, setMethod] = useState('');
  const [triggerEvent, setTriggerEvent] = useState<string[]>([]);
  const [statusAfterSubmit, setStatusAfterSubmit] = useState('');
  const [triggerStatus, setTriggerStatus] = useState<string>('');
  const [bodyTemplate, setBodyTemplate] = useState('');
  const [docFieldTemplate, setDocFieldTemplate] = useState('');
  const [activeTab, setActiveTab] = useState('receive');
  const [authMethod, setAuthMethod] = useState('');
  const [authType, setAuthType] = useState('');
  const [hasAuthorization, setHasAuthorization] = useState(false);

  const cancelClick = () => {
    history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}`)
  }

  useEffect(() => {
    if (apiId !== 'add-record') {
      var reg = new RegExp('^[0-9]$');
      const appId = reg.test(apiId) ? parseFloat(apiId) : undefined;
      const tempApi = apiConfigurationList.find((e: IApiConfiguration) => e.seqNumber === appId);
      if (tempApi) {
        nameRef.current.value = tempApi.name;
        descriptionRef.current.value = tempApi.description;
        urlRef.current.value = tempApi.url;
        setBodyTemplate(tempApi.requestTemplate);
        setDocFieldTemplate(tempApi.responseTemplate);
        setTriggerStatus(tempApi.triggerStatusId || '');
        setTriggerEvent(tempApi.triggerEvent);
        setStatusAfterSubmit(tempApi.successStatusId || '');
        setMethod(tempApi.method);
        setActiveTab(tempApi.method !== 'post' ? 'receive' : 'send');

        if (tempApi.authorization) {
          setHasAuthorization(true)
          authUrlRef.current.value = tempApi.authorization.url
          setAuthMethod(tempApi.authorization.method);
          setAuthType(tempApi.authorization.type);
          authTokenPathRef.current.value = tempApi.authorization.tokenPath;
          authBodyRef.current.value = tempApi.authorization.body || ''
        }
      } else {
        // cancelClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiId, apiConfigurationList]);

  return {
    ...props, method, setMethod, triggerEvent, setTriggerEvent,
    statusAfterSubmit, setStatusAfterSubmit, triggerStatus, setTriggerStatus,
    bodyTemplate, setBodyTemplate, docFieldTemplate, setDocFieldTemplate,
    activeTab, setActiveTab, authMethod, setAuthMethod, authType, setAuthType,
    hasAuthorization, setHasAuthorization, cancelClick
  };
}
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: false;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Date,
  required: false,
  type: 'date',
};

const defaultConfig = {
  min: {
    type: 'basicExpression',
    value: {
      left: 'today',
      operator: 'subtract',
      right: {
        amount: 100,
        unit: 'years'
      }
    }
  },
  max: {
    type: 'basicExpression',
    value: {
      left: 'today',
      operator: 'add',
      right: {
        amount: 10,
        unit: 'years'
      }
    }
  }
}


const value = {
  title: 'Date',
  value: 'date',
  icon: {
    black: require('../../icons/black/calendar.png'),
    white: require('../../icons/white/calendar.png'),
    blue: require('../../icons/blue/calendar.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  fieldTypeStyle: {
    width: '23px',
    height: '23px',
    right: '30px',
    top: '4px'
  },
  defaultData,
  defaultConfig
}

export default value;
import { useEffect, useState } from 'react';
import { IConstraint } from '../../../interface/constraint';

interface IProps {
  modifyConstraint: IConstraint | undefined;
}

export const useSetCustomConstraintsToState = (props: IProps) => {

  const [operator, setOperator] = useState('');
  const [selectedCustomField, setSelectedCustomField] = useState('');
  const [selectedBp, setSelectedBp] = useState('');

  useEffect(() => {
    if (props.modifyConstraint) {
      setOperator(props.modifyConstraint.operator);
      setSelectedCustomField(props.modifyConstraint.userKeyPath);
      setSelectedBp(props.modifyConstraint.documentKeyPath)
    }
  }, [props.modifyConstraint]);

  return {
    operator, setOperator, selectedCustomField, setSelectedCustomField,
    selectedBp, setSelectedBp, ...props
  }
}
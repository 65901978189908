import { useEffect } from 'react';
import { RectResult } from '../../../../../../../common/utils/useRect';

interface IProps {
  inputRect: RectResult;
  width: number;
  height: number;
  actionType: "create" | "edit";
  selectedId?: string;
}

export const useSetChatContainerHeight = ({ inputRect, width, height, actionType, selectedId }: IProps): void => {
  useEffect(() => {
    const chatContainer = document.getElementById('chat-content');
    const editModal = document.getElementById('comment-edit-modal');

    if (actionType === 'create') {
      if (chatContainer) {
        chatContainer.style.setProperty('height', `calc(${height - 59 - 40 - inputRect.height}px)`);
      }
    } else {
      if (chatContainer) {
        const inputHeight = selectedId ? inputRect.height + 40 : 0;
        chatContainer.style.setProperty('height', `calc(${height - 59 - 40 + 40 - inputHeight}px)`);
      }
      if (editModal) {
        editModal.style.setProperty('height', `calc(${inputRect.height + 40}px)`);
      }
    }
    // eslint-disable-next-line
  }, [inputRect, width, height, actionType, selectedId]);
};
import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, Image, Loader } from 'semantic-ui-react-bpm'
import ConfigService from '../../common/config';
import { submitLoginAction } from './action/login';
import { triggerForgotPasswordModalAction } from './action/forgot-password';
import { checkAuthTokenAction } from './token-checker/action';
import Message from '../../common/general/response-message';
import { IRootState } from '../../reducers';
import AuthFooterComponent from './footer';
import useDeviceDetect from '../../common/general/device-detect';

const ForgotPasswordModal = lazy(() => import('./forgot-password'));

const Auth: React.FC = () => {
  const { isMobile, isTablet, isPortrait, width, height } = useDeviceDetect();
  /**
   * Use ref to avoid state change while typing
   */
  const userRefElement = document.createElement('input');
  const inputUsernameRef = useRef(userRefElement);
  const inputPasswordRef = useRef(userRefElement);
  /**
   * Use state to manage single action state: 
   * - first value is to get value of state
   * - second value is to update value of state
   */
  const [rememberMe, setRememberMe] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');
  const [openMessageResponse, setOpenMessageResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  /**
   * use dispatch to trigger update of reducer and saga
   */
  const dispatch = useDispatch();

  /**
   * Use selector to get data from reducer
   */
  const data = useSelector((state: IRootState) => state.auth);

  /**
   * Use history to manage routing of pages
   */
  const history = useHistory();

  /**
   * Use useEffect to manage process on state changes base
   * on specific variable set
   */
  useEffect(() => {
    if (data.token && !data.loginError) {
      dispatch(checkAuthTokenAction(data.token))
      history.push('/');
    }
    if (data.loginError) {
      setMessageResponse('Your username and/or password is invalid');
      setIsError(true);
      setOpenMessageResponse(true);
    }
  }, [dispatch, data.token, data.loginError, history]);

  /**
   * this will run once because no state will be triggerred on this state
   */
  useEffect(() => {
    inputUsernameRef.current.value = localStorage.getItem('storedUsername') || '';
    setRememberMe((localStorage.getItem('rememberMe') === 'true') ? true : false);
  }, []);
  /**
   * set const variable for function/actions
   */
  const closeMessageResponse = () => {
    setOpenMessageResponse(false);
  }

  const tiggerSaveOnEnter = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      const saveButton = document.getElementById('login-btn');
      if (saveButton) {
        saveButton.click();
      }
    }
  }

  const triggerLogin = () => {
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('storedUsername');

    if (rememberMe) {
      localStorage.setItem('rememberMe', rememberMe.toString());
      localStorage.setItem('storedUsername', inputUsernameRef.current.value);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('storedUsername');
    }
    dispatch(
      submitLoginAction({
        username: inputUsernameRef.current.value,
        password: inputPasswordRef.current.value
      })
    );
  };

  const rememberMeClick = () => {
    setRememberMe(!rememberMe);
  }

  const forgotPassword = () => {
    dispatch(triggerForgotPasswordModalAction({ forgotPasswordModal: true }));
  }

  useEffect(() => {
    window.removeEventListener("keypress", tiggerSaveOnEnter, true);
    window.addEventListener("keypress", tiggerSaveOnEnter, true);
    const iFrameArrayElement = document.getElementsByTagName('iframe');
    if (iFrameArrayElement.length) {
      for (let i = 0; i < iFrameArrayElement.length; i++) {
        iFrameArrayElement[i].onfocus = () => {
          iFrameArrayElement[i].blur();
        }
      }
    }
    return () => {
      window.removeEventListener("keypress", tiggerSaveOnEnter, true);
    }
  }, [inputUsernameRef, inputPasswordRef])

  return (
    <div id='app' style={{ width: '100%', height: '100%' }} className='App'>
      <Image style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: '-1'
      }} src={require('./auth-background.png')} />
      <div id='auth-main-container'
        className={`auth-main ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'} ${isPortrait ? 'portrait' : 'landscape'}
         ${(width <= 340 || height <= 340) ? 'small' : ''} ${!isPortrait && height <= 480 ? 'small-landscape' : ''}`}
        style={{ background: ConfigService.loadConfig().general.loginBackground }}>
        <div id='auth-wrapper'>
          <div className='login-logo column'>
            <div className={`logo ${(isMobile || isTablet) && isPortrait ? 'border-x' : 'border-y'} `}>
              <Image className='image' src={require('./mobbiz-logo.png')} />
            </div>
          </div>
          <div id='auth-form' className='column'>
            <div id='auth-message' className={ConfigService.loadConfig().general.sandbox ? 'auth-message-light' : 'auth-message-dark'}>
              <Message message={messageResponse} open={openMessageResponse} close={closeMessageResponse} error={isError} />
            </div>
            <Form size='small' className='form'>
              <Form.Field className='input-container'>
                <input type='text' className='input-field' autoComplete='off' ref={inputUsernameRef} placeholder='Username' />
              </Form.Field>
              <Form.Field className='input-container'>
                <div className="ui icon input">
                  <input type={`${!viewPassword ? 'password' : 'text'}`} autoComplete='off' className='input-field' ref={inputPasswordRef} placeholder='Password' />
                  <i aria-hidden="true" className={`${!viewPassword ? 'eye slash' : 'eye'} link icon`} onClick={() => setViewPassword(!viewPassword)} />
                </div>
              </Form.Field>
              <Form.Field className='checkbox-container'>
                <div className='remember-me'>
                  <div className='ui checkbox' onClick={() => rememberMeClick()} >
                    <input type='checkbox' className='hidden' checked={rememberMe} onChange={() => { }} />
                    <label>Remember me</label>
                  </div>
                </div>
                <div className='forgot-password'>
                  <label onClick={() => forgotPassword()}>Forgot password?</label>
                </div>
              </Form.Field>
              <Form.Field className='action-container'>
                <div className='action'>
                  <div className='login'>
                    <Button floated='left' type='button' id='login-btn' className='login-btn' disabled={data.loading} onClick={() => triggerLogin()}>
                      {data.loading ? 'Loading...' : 'Log in'}
                    </Button>
                  </div>
                </div>
              </Form.Field>
            </Form>
          </div>
        </div>
      </div>
      <AuthFooterComponent />
      <Suspense fallback={<Loader active inline />}>
        <ForgotPasswordModal />
      </Suspense>
    </div>
  );
}

export default Auth;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Dropdown, Icon, Popup } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { v4 as uuid } from 'uuid';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { IAutomationComponent } from '../../../../../interface/component-list';
import { IReference } from '../../../../../../../../configuration/module/bp-relationship/interface/form';
import { IField } from '../../../../../../../../../../users/interface/field';

interface ISendEmailProps {
  data: any;
  index: number;
  addNewEmail: () => void;
  emailList: string[];
  removeEmail: (index: number) => void;
  label: string;
  getOption(component: IAutomationComponent, emailType: string): void;
  selectedComponent: IAutomationComponent;
  selectedBP: string;
  openModal(isVisible: boolean, emailType: string, index: number): void;
  isVisible: { [id: string]: any };
  manageOperator(value: string, index: number, emailType: string): void;
  operation: any;
  updateEmail(data: any, index: number, emailType: string): void;
  emailStringRef: any;
  storeDynamicField(): void;
  emailInputFocusRef: any;
}

const SendEmail: React.FC<ISendEmailProps> = (props) => {
  const emailId = props.label.toLowerCase();

  const {
    formFieldList,
    inputFieldList,
    referenceList,
    datatableInputField
  } = useSelector((state: IRootState) => state.automation);
  const { userFieldListDynamic, userFieldListStatic } = useSelector((state: IRootState) => state.user);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const data = props.emailStringRef.current[emailId] ? props.emailStringRef.current[emailId][props.index] : '';
  const operation = props.operation[emailId] ? props.operation[emailId][props.index] : 'manual';

  let fieldId = '';
  let emailStringValue = '';
  let sourceReference = '';

  if (data && typeof data === 'object' && (data.hasOwnProperty('referencedKeyPath') || data.hasOwnProperty('keyPath'))) {
    fieldId = data.keyPath;
    sourceReference = data.referencedKeyPath;
  } else {
    emailStringValue = data || '';
  }

  const getSoureceReferenceLabel = () => {
    const label = getReferenceFieldOption().find((e: IDropdownOption) => e.value === sourceReference);
    if (label) {
      return label.text;
    }
    return '';
  }

  const getFieldLabel = () => {
    const label = getFormFieldOption().find((e: IDropdownOption) => e.value === fieldId);
    if (label) {
      return label.text;
    }
    return 'Email Field';
  }

  const getReferenceFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [{
      key: uuid(),
      value: 'current-form',
      text: 'Current Form'
    }];
    formFieldList.forEach((field: ICustomFieldDetail) => {
      if (field.configName === IConfigName.Referenced) {
        options.push({
          key: uuid(),
          value: field.id || '',
          text: field.label || ''
        })
      }
    })
    return options;
  }

  const manageSourceReference = (value: string) => {
    const key = emailId;
    if (value !== props.selectedBP) {
      if (!props.selectedComponent.config[key]) {
        props.selectedComponent.config[key] = [];
      }
      const data = props.selectedComponent.config[key];
      if (data instanceof Array) {
        data[props.index] = { referencedKeyPath: value };
      }
      props.selectedComponent.config[key] = data;
    }
    props.getOption(props.selectedComponent, key);
    props.updateEmail({ referencedKeyPath: value, keyPath: '' }, props.index, emailId);
  }

  const setOperator = (value: 'form' | 'manual') => {
    setIsPopupOpen(false);
    props.manageOperator(value, props.index, emailId);
    props.updateEmail('', props.index, emailId);
  }

  const getFormFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    let sourceId = props.selectedBP;
    let fieldList: any[] = [];
    if (sourceReference !== 'current-form') {
      const fieldConfig = formFieldList.find((e: ICustomFieldDetail) => e.id === sourceReference);
      if (fieldConfig) {
        sourceId = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
      }
      const selectedRef = referenceList.find((ref: IReference) => ref.id === sourceId);
      if (selectedRef) {
        fieldList = inputFieldList[sourceId];
        if (selectedRef.dataType === 'database') {
          fieldList = datatableInputField[sourceId];
        }
      }
      if (fieldConfig && fieldConfig.config && fieldConfig.config.dataType === 'user') {
        fieldList = [...userFieldListDynamic, ...userFieldListStatic];
      }
    } else {
      fieldList = inputFieldList[sourceId];
    }
    fieldList && fieldList.forEach((e: IField) => {
      if ((e.configName === IConfigName.Email && e.inputConfig && e.inputConfig.type === 'string')
        || (e.inputConfig && e.inputConfig.config && e.inputConfig.config.format === 'email')) {
        options.push({
          key: uuid(),
          value: e.id || '',
          text: e.label || ''
        })
      }
    })
    if (sourceReference === 'current-form') {
      options.unshift({
        key: uuid(),
        value: 'creator.email',
        text: 'Form creator'
      })
    }
    return options;
  }

  const manageFieldId = (value: string) => {
    props.updateEmail({
      referencedKeyPath: sourceReference,
      keyPath: value
    }, props.index, emailId);
  }

  const manageStringEmailChange = (value: string) => {
    props.updateEmail(value, props.index, emailId);
  }

  const manageStringEmailFocus = (focus: boolean) => {
    const ref = props.emailInputFocusRef.current
      && props.emailInputFocusRef.current[emailId] ?
      props.emailInputFocusRef.current[emailId] : {};
    ref[props.index] = focus;
    props.emailInputFocusRef.current[emailId] = ref;
    props.storeDynamicField();
  }

  return <div className={`automation-field-container with-action-btn ${props.index > 0 ? 'no-padding-top' : ''}`}>
    <div className={`field automation-field`}>
      {props.index === 0 && <label>{props.label}:</label>}
      <div className='with-left-icon'>
        {operation === 'manual' && <Icon name='at' />}
        {operation === 'form' ?
          <div className='select-field-container'>
            <Dropdown
              text={``}
              icon='dropdown'
              floating
              labeled
              button
              className='icon'
              closeOnChange={false}
              popperContainer={DropdownPopperOption}
            >
              <Dropdown.Menu className={`${props.isVisible[emailId] && props.isVisible[emailId][props.index] === true ? 'visible' : ''}`}>
                <Dropdown.Menu scrolling className={`option-list`}>
                  <div className={`option-title`}>Select Source Form</div>
                  <Dropdown
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={sourceReference}
                    options={getReferenceFieldOption()}
                    onChange={(event, target: any) => { manageSourceReference(target.value) }}
                    popperContainer={DropdownPopperOption}
                  />
                  <div className={`option-title`}>Field to copy value form</div>
                  <Dropdown
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={fieldId}
                    options={getFormFieldOption()}
                    onChange={(event, target: any) => { manageFieldId(target.value) }}
                    popperContainer={DropdownPopperOption}
                  />
                  <span className={`close-button`} onClick={(e) => {
                    props.openModal(props.isVisible[emailId], emailId, props.index);
                    e.stopPropagation();
                  }}>Close</span>
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ position: 'relative' }}>
              <label className={`dropdown-text-cover`}
                onClick={() => props.openModal(props.isVisible[emailId] ?
                  props.isVisible[emailId][props.index] : false, emailId, props.index)}>
                <label>{getFieldLabel()}</label> {fieldId === 'document-creator' ? 'of' : 'from'} <label>{getSoureceReferenceLabel()}</label>
              </label>
            </div>
          </div>
          :
          <input value={emailStringValue || ''} placeholder='Input email' className='email' type='text'
            onChange={(e) => manageStringEmailChange(e.target.value)}
            onFocus={() => manageStringEmailFocus(true)}
            onBlur={() => manageStringEmailFocus(false)}
            autoFocus={(props.emailInputFocusRef.current && props.emailInputFocusRef.current[emailId] &&
              props.emailInputFocusRef.current[emailId][props.index]) || false}
          />
        }
      </div>
    </div>
    <div className='button-container'>
      <Popup
        basic
        on='click'
        position='bottom left'
        id='copy-from-popup'
        style={{ filter: 'none', zIndex: '10000' }}
        trigger={
          <Button
            className={`btn-action-collapse `}
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            icon>
            <i className="ellipsis horizontal icon"></i>
          </Button>
        }
        onClose={() => setIsPopupOpen(!isPopupOpen)}
        open={isPopupOpen}>
        <Container className='copy-from-popup-popup-container email' >
          <div>OPERATION</div>
          <ul>
            <li onClick={() => setOperator('manual')}>
              {operation === 'manual' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Manual Input</li>
            <li onClick={() => setOperator('form')}>
              {operation === 'form' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Choose from list</li>
          </ul>
        </Container>
      </Popup>
    </div>
    {props.emailList.length > 0 && !((props.emailList.length - 1) === 0) &&
      <div className='button-container delete'>
        <i className={`icon alternate trash outline`} color={'#3b86a8'} onClick={() => props.removeEmail(props.index)} />
      </div>
    }
    {props.index === props.emailList.length - 1 ?
      <div className='button-container add'>
        <i className={`plus circular icon new-email`} onClick={() => props.addNewEmail()} />
      </div>
      :
      <div />
    }
  </div>
}

export default SendEmail;
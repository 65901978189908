import { useEffect } from 'react';
import {
  getPdfTemplateListAction
} from '../../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string;
  hasActionTriggered: boolean;
}

export const useGetPDFTemplateData = (props: IProps): void => {
  const { dispatch, company, formId, hasActionTriggered } = { ...props };

  useEffect(() => {
    if (company && formId && !hasActionTriggered) {
      dispatch(getPdfTemplateListAction({
        company: company,
        formId: formId
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId, hasActionTriggered])
}
import StringElement from './object/string';
import BoolElement from './object/bool';
import TimeElement from './object/time';
import DateElement from './object/date';
import NumberElement from './object/number';
import EnumElement from './object/enum';
import ArrayElement from './object/array';
import UploadElement from './object/upload';
import { AcceptedProps } from './object/interface/accepted-props';
import TableElement from './object/table';
import GapElement from './object/gap';
import DateTimeElement from './object/date-time';
import GeolocationElement from './object/geolocation';
import Range from './object/range';

export const elementList: { [name: string]: React.FC<AcceptedProps> } = {
  string: StringElement,
  bool: BoolElement,
  time: TimeElement,
  date: DateElement,
  number: NumberElement,
  enum: EnumElement,
  array: ArrayElement,
  file: UploadElement,
  table: TableElement,
  spacer: GapElement,
  'date-time': DateTimeElement,
  geolocation: GeolocationElement,
  range: Range
};
import React, { useState, useEffect, Fragment } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { StringProps } from '../../../service/type/string';
import { TValidationType } from '../../../service/field-properties.handler';
import { restoreCursorPosition, setCursorPosition } from './utils/cursor-position';

const ParagraphType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher] = useState('');
  const [validateTriggered, setValidateTriggered] = useState(false);
  let onTypeTimeout = setTimeout(() => { }, 1000);
  const elementProperties = new FieldProperties(name, props.forwardedRef, new StringProps());

  const validateInput = (schema: any) => {
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(uuid());
    setValidateTriggered(true);

    if (props.triggerFilter) props.triggerFilter('');
    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      elementProperties.setCurrent({ value: defaultValue ? defaultValue as string : '' })
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (props.tableColumnRowIndex !== undefined) {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const convertStringCase = (value: string) => {
    const stringCase = props.inputConfig && props.inputConfig.config && props.inputConfig.config.case;
    if (value && stringCase) {
      if (stringCase === 'lowercase') {
        value = (value).toLowerCase();
      }
      if (stringCase === 'uppercase') {
        value = (value).toUpperCase();
      }
    }
    return value;
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(uuid());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.isAddRecord
  ])

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput({ ...props.validationSchema, minLength: 1, isRequired: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentElementid = props.tableColumnRowIndex ? `paragraph-${name}-${props.tableColumnRowIndex}` : `paragraph-${name}`;

  return <>

    {props.accessType !== 'hidden' &&
      <>
        <span id={refresher} className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label
            ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            })}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div className={`field-input ui input single ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: props.multiline || false,
          required: props.accessType === IFieldAccessType.Required,
          fieldValueObject: elementProperties
        })}`}>
          <textarea
            id={currentElementid}
            rows={3}
            readOnly={props.accessType === IFieldAccessType.Readonly}
            style={{ textAlign: props.inputConfig.config.alignment || 'left' }}
            onBlur={(event) => {
              if (focusedElement && focusedElement === currentElementid) {
                removeFocusedElement();
              }
              elementProperties.setCurrent({ value: convertStringCase(event.target.value) })
              validateInput({
                ...props.validationSchema,
                isRequired: props.accessType === IFieldAccessType.Required
              })
              if (props.throwValueOutside) {
                props.throwValueOutside()
              }
              if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
              if (props.automationService && props.fieldCollection) {
                const field = props.fieldCollection.find((e: IField) => e.id === props.id);
                if (field) {
                  props.automationService.didUpdateFieldValue(field, event.target.value)
                }
              }
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (props.handleOnKeyPress) props.handleOnKeyPress(e);
            }}
            ref={(curRef: any) => {
              if (curRef) {
                if (props.isFocus && refresher === '') {
                  curRef.focus();
                }
                if (focusedElement && focusedElement === currentElementid) {
                  focusSelectedElement(focusedElement);
                }
                elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'string', value: convertStringCase(elementProperties.current.value) });
                curRef.style.setProperty('text-align', props.inputConfig.config.alignment || 'left', 'important')
                curRef.value = elementProperties.current.value;
                restoreCursorPosition(curRef, name, props.tableId);
                return curRef
              }
            }}
            onClick={(e) => {
              const id = props.tableColumnRowIndex ? `paragraph-${name}-${props.tableColumnRowIndex}` : `paragraph-${name}`;
              focusInput(id);
              setCursorPosition(e, name, props.tableId);
            }}
            onChange={(event) => {
              elementProperties.setCurrent({ value: convertStringCase(event.target.value) })
              if (props.hasPageChangeRef) {
                props.hasPageChangeRef.current.hasChange = true;
              }
            }}
            autoFocus={props.isFocus}
            name={name} />
        </div>
        {elementProperties.hasError() && props.showErrorMessage &&
          elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
          <Fragment>
            <small style={{ display: 'flex' }} />
            <small className='field  error'
              dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
            />
          </Fragment>
        }
      </>
    }
  </>
}

export default ParagraphType;
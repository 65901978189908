import React from 'react'
import {
  Container,
  Segment,
} from 'semantic-ui-react-bpm'
import { Route } from 'react-router-dom'
import SandboxComponent from '../main/partials/sandbox';
import useDeviceDetect from '../../common/general/device-detect';

const AuthFooterComponent: React.FC = () => {
  const { isMobile, isTablet, isPortrait, width, height } = useDeviceDetect();

  return (
    <>
      <Route exact path='/auth'>
        <Segment id='auth-footer'
          className={`auth-main ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'} ${isPortrait ? 'portrait' : 'landscape'}
          ${(width <= 340 || height <= 340) ? 'small' : ''} ${!isPortrait && height <= 480 ? 'small-landscape' : ''}`}
          vertical>
          <Container className='footer' textAlign='center' style={{ width: '90%' }}>
            <div className='footer-main'>
              <span>
                <span className='mobbiz'>MOBBIZ</span>
                <span className='apps'>APPS</span>
              </span>
              <span className='info'>© 2020 Mobbiz Solutions Pte Ltd. All rights reserved. Version 2.0</span>
            </div>
          </Container>
          <SandboxComponent screen="auth" />
        </Segment>
      </Route>
    </>
  )
}

export default AuthFooterComponent;
import React, { useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IDateProperties } from '.';
import { IDropdownOption } from '../../../field/component/object/enum-type/checklist';
import DropdownPopperOption from '../../../general/dropdown-popper-option';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';

interface IDatePropertiesProps {
  fieldId: string;
  fieldList: ICustomFieldDetail[];
  propertiesRef: React.MutableRefObject<IDateProperties>;
  properties: IDateProperties;
}

const DateProperties: React.FC<IDatePropertiesProps> = (props) => {
  const [active, setActive] = useState(true);
  const [minValueField, setMinValueField] = useState('field');
  const [maxValueField, setMaxValueField] = useState('field');
  const [selectedMinField, setSelectedMinField] = useState('');
  const [selectedMaxField, setSelectedMaxField] = useState('');
  const [selectedMinOther, setSelectedMinOther] = useState('');
  const [selectedMaxOther, setSelectedMaxOther] = useState('');
  const [selectedMinOperator, setSelectedMinOperator] = useState('');
  const [selectedMaxOperator, setSelectedMaxOperator] = useState('');
  const [selectedMinUnit, setSelectedMinUnit] = useState('');
  const [selectedMaxUnit, setSelectedMaxUnit] = useState('');
  const [dateCountMin, setDateCountMin] = useState<number | undefined>(undefined);
  const [dateCountMax, setDateCountMax] = useState<number | undefined>(undefined);

  const getFieldOption = (): IDropdownOption[] => {
    const dateFieldOption: IDropdownOption[] = [];
    props.fieldList.forEach((e: ICustomFieldDetail) => {
      if (e.type === 'date' && !e.multiline && e.id !== props.fieldId) {
        dateFieldOption.push({ key: uuid(), text: e.label || '', value: e.id || '' });
      }
    })
    return dateFieldOption;
  }

  const getOperatorOption = (): IDropdownOption[] => {
    return [
      { key: uuid(), text: '+', value: 'add' },
      { key: uuid(), text: '-', value: 'subtract' },
    ]
  }

  const getUnitOption = (): IDropdownOption[] => {
    return [
      { key: uuid(), value: 'days', text: 'Days' },
      { key: uuid(), value: 'weeks', text: 'Weeks' },
      { key: uuid(), value: 'months', text: 'Months' },
      { key: uuid(), value: 'years', text: 'Years' },
    ]
  }

  const getOtherOption = (): IDropdownOption[] => {
    return [
      { key: uuid(), value: 'today', text: 'Today' },
    ]
  }

  const manageType = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setMinValueField(value)
    } else {
      setMaxValueField(value)
    }
    props.propertiesRef.current[type].type = 'basicExpression';
  }

  const manageSelectField = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setSelectedMinField(value)
    } else {
      setSelectedMaxField(value)
    }
    props.propertiesRef.current[type].value.left = value ? { fieldId: value, keyPath: `fields.${value}` } : '';
  }

  const manageSelectOperator = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setSelectedMinOperator(value)
    } else {
      setSelectedMaxOperator(value)
    }
    props.propertiesRef.current[type].value.operator = value;
  }

  const manageSelectUnit = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setSelectedMinUnit(value || '')
    } else {
      setSelectedMaxUnit(value || '')
    }
    props.propertiesRef.current[type].value.right.unit = value;
  }

  const manageSelectOther = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setSelectedMinOther(value || '')
    } else {
      setSelectedMaxOther(value || '')
    }

    props.propertiesRef.current[type].value.left = value;
  }

  const manageDateCount = (value: number, type: 'min' | 'max') => {
    if (typeof value !== 'number') {
      value = parseFloat(value);
    }

    if (type === 'min') {
      setDateCountMin(value)
    } else {
      setDateCountMax(value)
    }

    props.propertiesRef.current[type].value.right.amount = value;
  }

  useEffect(() => {
    const tempProperties = { ...props.properties };
    const min = tempProperties?.min;
    const max = tempProperties?.max;
    const minLeft = min?.value?.left;
    const maxLeft = max?.value?.left;
    const minType = minLeft instanceof Object && minLeft.hasOwnProperty('fieldId') ? 'field' : 'other';
    const maxType = maxLeft instanceof Object && maxLeft.hasOwnProperty('fieldId') ? 'field' : 'other';
    const minOperator = min?.value.operator;
    const maxOperator = max?.value.operator;
    const minFieldValue = minLeft instanceof Object && minLeft.hasOwnProperty('fieldId') ? minLeft.fieldId : '';
    const maxFieldValue = maxLeft instanceof Object && maxLeft.hasOwnProperty('fieldId') ? maxLeft.fieldId : '';
    const minOtherValue = typeof minLeft === 'string' ? minLeft : '';
    const maxOtherValue = typeof maxLeft === 'string' ? maxLeft : '';
    const minRight = min?.value?.right;
    const maxRight = max?.value?.right;
    const minCount = minRight?.amount;
    const maxCount = maxRight?.amount;
    const minUnit = minRight?.unit;
    const maxUnit = maxRight?.unit;
    setMinValueField(minType);
    setMaxValueField(maxType);
    setSelectedMinField(minFieldValue)
    setSelectedMaxField(maxFieldValue)
    setSelectedMinOperator(minOperator)
    setSelectedMaxOperator(maxOperator)
    setSelectedMinOther(minOtherValue)
    setSelectedMaxOther(maxOtherValue)
    setDateCountMin(minCount)
    setDateCountMax(maxCount)
    setSelectedMinUnit(minUnit)
    setSelectedMaxUnit(maxUnit)
    props.propertiesRef.current = props.properties;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.properties])

  return <div className={`date-properties-container`}>
    <div className={`${active ? 'active' : ''} title flex-container`}>
      <label>Properties</label>
      {active ?
        <Icon onClick={(e: any) => setActive(false)} name={`chevron up`} size='big' />
        :
        <Icon onClick={(e: any) => setActive(true)} name={`chevron down`} size='big' />
      }
    </div>
    {active &&
      <>
        <div className={`selection-grid-container`}>
          <span>Min Value</span>
          <div className={`field-container`}>
            <div>
              <span onClick={() => manageType('field', 'min')} className={`${minValueField === 'field' ? 'active' : ''}`}>Field</span>
              <span onClick={() => manageType('other', 'min')} className={`${minValueField === 'other' ? 'active' : ''}`}>Other</span>
            </div>
            {minValueField === 'field'
              ? <Dropdown
                popperContainer={DropdownPopperOption}
                selection
                clearable
                search
                value={selectedMinField}
                options={getFieldOption()}
                selectOnBlur={false}
                onChange={(event, target: any) => manageSelectField(target.value, 'min')}
              />
              : <Dropdown
                popperContainer={DropdownPopperOption}
                selection
                clearable
                search
                value={selectedMinOther}
                options={getOtherOption()}
                selectOnBlur={false}
                onChange={(event, target: any) => manageSelectOther(target.value, 'min')}
              />
            }

          </div>
          <Dropdown
            className={`count-type`}
            popperContainer={DropdownPopperOption}
            selection
            clearable
            search
            value={selectedMinOperator}
            options={getOperatorOption()}
            selectOnBlur={false}
            onChange={(event, target: any) => manageSelectOperator(target.value, 'min')}
          />
          <input type='number' className={`no-of-days`} value={dateCountMin} onChange={(e: any) => manageDateCount(e.target.value, 'min')} />
          <Dropdown
            className={`date-part`}
            popperContainer={DropdownPopperOption}
            selection
            clearable
            search
            value={selectedMinUnit}
            options={getUnitOption()}
            selectOnBlur={false}
            onChange={(event, target: any) => manageSelectUnit(target.value, 'min')}
          />
        </div>
        <div className={`selection-grid-container`}>
          <span>Max Value</span>
          <div className={`field-container`}>
            <div>
              <span onClick={() => manageType('field', 'max')} className={`${maxValueField === 'field' ? 'active' : ''}`}>Field</span>
              <span onClick={() => manageType('other', 'max')} className={`${maxValueField === 'other' ? 'active' : ''}`}>Other</span>
            </div>
            {maxValueField === 'field'
              ? <Dropdown
                popperContainer={DropdownPopperOption}
                selection
                clearable
                search
                value={selectedMaxField}
                options={getFieldOption()}
                selectOnBlur={false}
                onChange={(event, target: any) => manageSelectField(target.value, 'max')}
              />
              : <Dropdown
                popperContainer={DropdownPopperOption}
                selection
                clearable
                search
                value={selectedMaxOther}
                options={getOtherOption()}
                selectOnBlur={false}
                onChange={(event, target: any) => manageSelectOther(target.value, 'max')}
              />
            }
          </div>
          <Dropdown
            className={`count-type`}
            popperContainer={DropdownPopperOption}
            selection
            clearable
            search
            value={selectedMaxOperator}
            options={getOperatorOption()}
            selectOnBlur={false}
            onChange={(event, target: any) => manageSelectOperator(target.value, 'max')}
          />
          <input type='number' className={`no-of-days`} value={dateCountMax} onChange={(e: any) => manageDateCount(e.target.value, 'max')} />
          <Dropdown
            className={`date-part`}
            popperContainer={DropdownPopperOption}
            selection
            clearable
            search
            value={selectedMaxUnit}
            options={getUnitOption()}
            selectOnBlur={false}
            onChange={(event, target: any) => manageSelectUnit(target.value, 'max')}
          />
        </div>
      </>
    }
  </div>
}

export default DateProperties;
import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react-bpm';
import { IAutomation } from '../../../interface/automation';
import { DetailActionButton } from '../action-button';
import { useSetNameDescValue } from '../hooks/use-set-name-desc-value';

const errorIndicator = {
  ruleName: false,
}

interface IAutomationMainDetailProps {
  updateAutomationDetails(value: string, field: string): void;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
}

const AutomationMainDetail: React.FC<IAutomationMainDetailProps> = (props) => {
  const { ruleNameRef, descriptionRef } = useSetNameDescValue({ currentAutomationRef: props.currentAutomationRef });
  const [error, setError] = useState(errorIndicator);
  const [refresher, setRefresher] = useState(0);

  const validateInput = () => {
    const tempError = { ...error };
    tempError.ruleName = false;
    if (ruleNameRef.current.value === '') {
      tempError.ruleName = true;
    }
    setError(tempError)
  }

  const hasChange = () => {
    if (props.hasComponentChange.current === false) {
      setRefresher(refresher + 1);
      props.parentRefresher()
    }
    props.hasComponentChange.current = true;
  }

  const saveClick = () => {
    props.updateAutomationDetails(ruleNameRef.current.value, 'name');
    props.updateAutomationDetails(descriptionRef.current.value, 'description');
    setRefresher(refresher + 1);
  }

  useEffect(() => {
    if (props.currentAutomationRef.current) {
      ruleNameRef.current.value = props.currentAutomationRef.current.name;
      descriptionRef.current.value = props.currentAutomationRef.current.description;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='automation-form-detail'>
        <div className='automation-form-detail-trigger'>
          <Form>
            <Form.Group widths='equal'>
              <div className={`required ten wide field ${error.ruleName ? 'error' : ''}`}>
                <label>Rule Name</label>
                <div className="ui input">
                  <input ref={ruleNameRef} onBlur={() => validateInput()} onChange={hasChange} />
                </div>
              </div>
            </Form.Group>
            <Form.Group widths='equal'>
              <div className="ten wide field">
                <label>Description</label>
                <textarea rows={3} ref={descriptionRef} onChange={hasChange}></textarea>
              </div>
            </Form.Group>
          </Form>
        </div>
        {props.hasComponentChange.current === true &&
          <DetailActionButton
            automationId={props.currentAutomationRef.current?.id as string}
            saveAutomation={() => saveClick()}
          />
        }
      </div>
    </>
  );
}


export default AutomationMainDetail;

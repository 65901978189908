import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_COMPANY_SETTINGS, UPDATE_COMPANY_SETTINGS, } from './constant';
import { setStatusCodeAction } from '../../../error/action';
import { getRequest, putRequest, IAxiosResponse } from '../../../../common/api-request';
import {
  getCompanySettingsReturnAction,
  getCompanySettingsReturnErrorAction,
  updateCompanySettingsReturnAction,
  updateCompanySettingsErrorAction
} from './action';
import { ICompanySettings } from './interface/company-settings';
import { ICompanySettingsInput } from './interface/input/company-settings';
import { ICompanySettingsUpdateInput } from './interface/input/company-settings-update';

export function* getCompanySettings(args: ICompanySettingsInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/settings`, {});
    yield put(
      getCompanySettingsReturnAction({ data: req.data.data as ICompanySettings })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getCompanySettingsReturnErrorAction());
  }
}

export function* updateCompanySettings(args: ICompanySettingsUpdateInput) {
  try {
    const company = args.company;
    const data = args.data;
    const req: IAxiosResponse = yield call(putRequest, `${company}/settings`, data);
    yield put(updateCompanySettingsReturnAction({ ...req.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(updateCompanySettingsErrorAction({ message: JSON.stringify(e.response.data.message) }))
  }
}

// All sagas to be loaded
export function* companySettingsSagas() {
  yield takeLatest(GET_COMPANY_SETTINGS, getCompanySettings);
  yield takeLatest(UPDATE_COMPANY_SETTINGS, updateCompanySettings)
}
export default companySettingsSagas;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../../reducers';
import { useGetAutomationLogs } from '.././hooks/use-get-automation-logs';
import AutomationLogsTable from './table';
import { automationLogsLimit, automationLogStatus } from '../constant';
import AutomationLogsStatus from './automation-log-status';
import PaginationComponent from '../../../../../../../../../common/general/pagination';

interface Props { }

const AutomationLogs: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const match: { params: { formId: string } } = useRouteMatch();

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { company } = useSelector((state: IRootState) => state.auth);
  const { automationLogsList, automationLogsTotalCount } = useSelector((state: IRootState) => state.automationLogs);

  const [currentOffset, setCurrentOffset] = useState(0);

  const changePage = (limit: number, offset: number) => {
    setCurrentOffset(offset);
  }

  useGetAutomationLogs({
    dispatch,
    company,
    formId: match.params.formId,
    offset: currentOffset
  });

  return (
    <div className="automation-main-container">
      <AutomationLogsTable list={automationLogsList} tz={tz} />
      <PaginationComponent
        totalCount={automationLogsTotalCount}
        currentLimit={automationLogsLimit}
        currentOffset={currentOffset}
        changePage={changePage}
      />
      <AutomationLogsStatus list={automationLogStatus} />
    </div>
  );
}

export default AutomationLogs;

import { useEffect } from 'react';
import {
  getFormFieldListAction,
  getFormDetailAction,
  getColumnViewListAction
} from '../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetCustomViewListData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (company && formId) {
      dispatch(getFormFieldListAction({ company: company, formId: formId }));
      dispatch(getFormDetailAction({ company: company, formId: formId }));
      dispatch(getColumnViewListAction({ company: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId])
}
import React, { createRef, CSSProperties } from 'react';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import { v4 as uuid } from 'uuid';
import { Popup } from 'semantic-ui-react-bpm';
import { useRect } from '../../utils/useRect';
import { IConfigName } from '../type/interface/field-type-object';

interface IStringField {
  data: IFieldData;
  detail: FieldElement;
  overflowed?: boolean;
  multiline?: boolean;
}

const containerRef = createRef<HTMLDivElement>();
const valueRef = React.createRef<HTMLSpanElement>();

const StringCustomField: React.FC<IStringField> = (props) => {
  let value = props.data.fields ? props.data.fields[props.detail.id] || '' : '';

  const containerRect = useRect(containerRef);
  const valueRect = useRect(valueRef);
  const multilineStyle: CSSProperties = props.detail.configName === IConfigName.TextStringMultiline ? {
    whiteSpace: 'normal',
    minWidth: '300px',
    maxWidth: '400px',
  } : {};

  if (props.detail.configName === IConfigName.Phone && !props.multiline && value && value.indexOf('+') === -1) {
    value = `+${value}`;
  }

  const convertStringCase = (value: string) => {
    const stringCase = props.detail.inputConfig && props.detail.inputConfig.config && props.detail.inputConfig.config.case;
    if (value && typeof value === 'string' && stringCase) {
      if (stringCase === 'lowercase') {
        value = (value).toLowerCase();
      }
      if (stringCase === 'uppercase') {
        value = (value).toUpperCase();
      }
    }
    return value;
  }

  const valueComponent = <div ref={containerRef} style={{ textAlign: props.detail.inputConfig.config.alignment || 'left' }}
    key={`column-${props.detail.id}-${props.data.id}`}>
    <span id={`value-${props.detail.id}-${props.data.id}`} ref={valueRef}>{convertStringCase(value)}</span>
  </div>

  if (value && typeof value === 'object') {
    return value.length > 0 && value.map((values: string, index: number) => {
      return (
        <div
          style={{ textAlign: props.detail.inputConfig.config.alignment || 'left', ...multilineStyle }}
          key={`array-${props.detail.id}-${props.data.id}-${uuid()}`}>
          {convertStringCase(values)}
          {(value.length > 1 && index < value.length - 1) ? ',' : ''}
        </div>
      )
    })
  }

  if (props.overflowed) {
    if (valueRect.width > containerRect.width) {
      return <Popup id='popup-string-overflow' style={{ maxWidth: '400px' }}
        position='top center' trigger={valueComponent}>
        {valueComponent}
      </Popup>
    } else {
      return valueComponent
    }
  }

  return valueComponent;
}

export default StringCustomField;
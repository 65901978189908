import React from 'react';
import { v4 as uuid } from 'uuid';
import { IAutomationChildComponent, IAutomationComponent } from '../../interface/component-list';
import AutomationOutlineAddComponent from './add-component';
import { AtomationOutlineComponentRegistry } from './component/component-registry';

interface IAutomationOutline {
  componentList: IAutomationComponent[];
  setSelectedComponent(component: IAutomationComponent): void;
  selectedComponent: IAutomationComponent | undefined;
  tempComponentList: any;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  deleteCreatedComponent(type: string, parentIndex: number, childProperty?: any): void;
  setAlertMessage(value: string): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  selectedChildComponent?: IAutomationChildComponent;
}

const AutomationOutline: React.FC<IAutomationOutline> = (props) => {
  const hasTrigger = !!props.componentList.find((e) => e.type === 'trigger')?.config.trigger;
  return (
    <div className='automation-form-outline'>
      <div className='outline-detail'>
        <ul className="rule-outline">
          {
            props.componentList.map((e: IAutomationComponent, index: number) => {
              return <AtomationOutlineComponentRegistry.Render
                type={e.type}
                key={uuid()}
                setSelectedComponent={props.setSelectedComponent}
                component={e}
                updateCurrentAutomation={props.updateCurrentAutomation}
                deleteCreatedComponent={props.deleteCreatedComponent}
                currentIndex={index}
                setAlertMessage={props.setAlertMessage}
                hasComponentChange={props.hasComponentChange}
                selectedComponent={props.selectedComponent}
                selectedChildComponent={props.selectedChildComponent}
              />;
            })
          }
          {hasTrigger &&
            <AutomationOutlineAddComponent setSelectedComponent={props.setSelectedComponent} />
          }
        </ul>
      </div>
    </div>
  );
}

export default AutomationOutline;
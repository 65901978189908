import React, { useState } from 'react';
import { IField } from '../../../../../users/interface/field';
import { IConstraint } from '../../interface/constraint';
import { constraintPallet } from '../../constant';
import { v4 as uuid } from 'uuid';
import { Dropdown, Image } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { icons } from '../../../../../../../../common/icons';
import { useSetCustomConstraintsToState } from './hooks/use-set-custom-constraint-to-state';

interface ICustomConstraintProps {
  userFieldList: IField[];
  updateConstraint(constraint: IConstraint): void;
  setIsAddNew(value: boolean): void;
  constraintList: IConstraint[];
  userCustomField: ICustomFieldDetail[];
  modifyConstraint: IConstraint | undefined;
  deleteConstraint(): void;
  formFieldList: ICustomFieldDetail[];
}

const error = {
  userField: false,
  operator: false
}

const CustomConstraint: React.FC<ICustomConstraintProps> = (props) => {
  const [currentFieldError, setCurrentFieldError] = useState(error);

  const {
    operator,
    setOperator,
    selectedCustomField,
    setSelectedCustomField,
    selectedBp,
    setSelectedBp
  } = useSetCustomConstraintsToState({
    modifyConstraint: props.modifyConstraint
  });

  const getOperatorOption = (userField: string) => {
    const operatorList: { key: number, value: string, text: string }[] = [];
    const currentField = props.userCustomField.filter((e: ICustomFieldDetail) => userField && userField.split('.')[1] === e.id);
    if (currentField.length > 0) {
      let isMulti = currentField[0].config?.multiselect;
      let hasSelectedBPField = false;
      let isMultiSelectedBp = false;
      if (selectedBp) {
        hasSelectedBPField = true;
        isMultiSelectedBp = props.formFieldList.find((e: ICustomFieldDetail) => selectedBp && selectedBp.split('.')[1] === e.id && e.config?.multiselect) ? true : false;
      }

      if (isMulti) {
        operatorList.push({ key: 0, value: 'includes', text: 'Includes' });
        operatorList.push({ key: 1, value: 'excludes', text: 'Excludes' });
        if (!hasSelectedBPField || (hasSelectedBPField && isMultiSelectedBp)) {
          operatorList.push({ key: 2, value: 'intersect', text: 'Intersect' });
        }
      } else {
        operatorList.push({ key: 0, value: 'equal', text: 'Equal' });
        operatorList.push({ key: 1, value: 'not-equal', text: 'Non Equal' });
      }
    }

    return operatorList;
  }

  const getBpExistingFieldOption = () => {
    if (selectedCustomField && selectedCustomField.split('.').length === 2) {
      const selectedField = props.userCustomField.filter((e: ICustomFieldDetail) => selectedCustomField.split('.')[1] === e.id);
      if (selectedField.length > 0) {
        const userDatatabseId = selectedField[0].config?.data.databaseId;
        const userFormId = selectedField[0].config?.data.formId;
        return props.formFieldList.filter((e: ICustomFieldDetail) =>
          e.config && e.config.data
          && (
            (
              e.config.data.hasOwnProperty('databaseId')
              && e.config.data.databaseId
              && userDatatabseId === e.config.data.databaseId
            )
            || (
              e.config.data.hasOwnProperty('formId')
              && e.config.data.formId
              && userFormId === e.config.data.formId
            )
          )
        ).map((e: ICustomFieldDetail, index: number) => {
          return { key: index, value: `fields.${e.id}`, text: e.label }
        });
      }
    }
    return [];
  }

  const getReferenceFieldOption = () => {
    return props.userCustomField.filter((e: ICustomFieldDetail) => {
      const hasConstraint = props.constraintList.filter((f: IConstraint) =>
        f.userKeyPath
        && f.userKeyPath.split('.')[1] === e.id
        && (!props.modifyConstraint
          || (props.modifyConstraint && props.modifyConstraint.userKeyPath.split('.')[1] !== e.id))
      );
      if (e.config && (e.config.dataType === 'database' || e.config.dataType === 'document') && hasConstraint.length === 0) {
        return true;
      }
      return false;
    })
      .map((e: ICustomFieldDetail, index: number) => {
        return { key: index, value: `fields.${e.id}`, text: e.label }
      });
  }

  const addConstraint = () => {
    if (!validateInputs()) {
      return;
    }
    const selectedField = props.userCustomField.filter((e: ICustomFieldDetail) => selectedCustomField !== '' && selectedCustomField.split('.')[1] === e.id)
    if (selectedField.length > 0) {
      let style = {
        constraintName: selectedField[0].label || '',
        constraintColor: constraintPallet[props.constraintList.length],
        constraintId: uuid()
      };
      if (props.modifyConstraint) {
        style = { ...style, ...props.modifyConstraint.style };
      }

      props.updateConstraint({
        style: style,
        operator: operator,
        userKeyPath: selectedCustomField,
        documentKeyPath: selectedBp,
        type: 'field-compare'
      } as IConstraint);
      props.setIsAddNew(false);
    }
  }

  const validateUserField = () => {
    let tempError = { ...currentFieldError };
    tempError.userField = false;
    if (!selectedCustomField) {
      tempError.userField = true;
    }
    setCurrentFieldError(tempError)
  }

  const validateOperator = () => {
    let tempError = { ...currentFieldError };
    tempError.operator = false;
    if (!operator) {
      tempError.operator = true;
    }
    setCurrentFieldError(tempError)
  }

  const validateInputs = () => {
    let status = true;
    let tempError = { ...currentFieldError };
    tempError.userField = false;
    if (!selectedCustomField) {
      tempError.userField = true;
      status = false;
    }
    tempError.operator = false;
    if (!operator) {
      tempError.operator = true;
      status = false;
    }
    setCurrentFieldError(tempError)
    return status;
  }

  return <>
    <div className={`custom-constraint-container`} >
      <Dropdown
        selectOnBlur={false}
        options={getReferenceFieldOption()}
        placeholder='User Custom Field'
        selection
        search
        clearable
        className={`user-reference-field-selection ${currentFieldError.userField ? 'error' : ''}`}
        value={selectedCustomField}
        onChange={(e: any, target: any) => {
          setSelectedCustomField(target.value);
          setSelectedBp('');
        }}
        onBlur={() => validateUserField()}
      />
      <Dropdown
        selectOnBlur={false}
        options={getOperatorOption(selectedCustomField)}
        placeholder='Operator'
        selection
        search
        clearable
        className={`user-reference-field-selection ${currentFieldError.operator ? 'error' : ''}`}
        value={operator}
        onChange={(e: any, target: any) => {
          setOperator(target.value);
        }}
        onBlur={() => validateOperator()}
      />
      <Dropdown
        selectOnBlur={false}
        options={getBpExistingFieldOption()}
        placeholder='BP Field Field'
        selection
        search
        clearable
        className={`user-reference-field-selection`}
        value={selectedBp}
        onChange={(e: any, target: any) => {
          setSelectedBp(target.value);
        }}
      />
      <div className='flex-container'>
        {props.constraintList.length > 0 && !props.modifyConstraint &&
          <Image className={`action-modal-btn cancel`} onClick={() => props.setIsAddNew(false)} src={icons.other.circleCancel} />
        }
        {props.modifyConstraint &&
          <Image className={`action-modal-btn cancel`} onClick={() => props.deleteConstraint()} src={icons.other.circleCancel} />
        }
        <Image className={`action-modal-btn ok`} onClick={() => addConstraint()} src={icons.other.circleOk} />
      </div>
    </div>
  </>
}

export default CustomConstraint;
import { IutilProps } from '../..';
import { ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IReference } from '../../../../../../../configuration/module/bp-relationship/interface/form';
import { getDatatableInputFieldAction, getInputFieldAction } from '../../../../action';
import { IAutomationComponent } from '../../../../interface/component-list';

export class SendSMSUtils {
  static getOptions = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    let fields = selectedComponent?.config['to'] || [];;

    fields.forEach((key: any) => {
      if (key && key.hasOwnProperty('referencedKeyPath')) {
        const sourceRefField = key.referencedKeyPath.replace('fields.', '');
        if (sourceRefField !== '') {
          let bpReference = '';
          const fieldConfig = utils.formFieldList.find((e: ICustomFieldDetail) => e.id === sourceRefField);
          if (fieldConfig) {
            bpReference = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
          }
          if (!bpReference) {
            return;
          }

          const selectedRef = utils.referenceList.find((ref: IReference) => ref.id === bpReference);
          if (selectedRef) {
            if (selectedRef.dataType === 'document' && !utils.inputFieldList[selectedRef.id]) {
              utils.dispatch(getInputFieldAction({
                companyId: utils.company,
                formId: selectedRef.id,
                statusId: ''
              }))
            }
            if (selectedRef.dataType === 'database' && !utils.datatableInputField[selectedRef.id]) {
              utils.dispatch(getDatatableInputFieldAction({
                companyId: utils.company,
                formId: selectedRef.id,
                statusId: ''
              }))
            }
          }
        }
      }
    })
  }
}
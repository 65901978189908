import React from 'react';
import SelectedFieldContainer from './selected-field-container';
import { IColumnView, IDragColumnView } from '../interface/custom-view';

interface IColumnHeaderProps {
  customViewList: IColumnView[];
  addCustomViewList(dragItem: { data: IColumnView }): void;
  arrangeSequence(selectedItem: IDragColumnView, field: IColumnView): void;
}

const ColumnHeader: React.FC<IColumnHeaderProps> = (props) => {

  return (
    <div className='column-header'>
      <label>Column Header</label>
      <SelectedFieldContainer
        customViewList={props.customViewList}
        addCustomViewList={props.addCustomViewList}
        arrangeSequence={props.arrangeSequence}
      />
    </div>
  );
}

export default ColumnHeader;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Axios from 'axios';
import { IRootState } from '../../../../../reducers';
import { getBusinessProcessErrorAction } from './form/action';
import { BusinessProcessModuleRegistry, IBusinessProcessRender } from './module-registry';

interface IMatch {
  params: { formId: string };
}

const BusinessProcessModuleRoute: React.FC<IBusinessProcessRender> = (props) => {

  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const companyId = useSelector((state: IRootState) => state.auth.company);

  const { fourthLevelRoute } = useSelector((state: IRootState) => state.main);

  const formId = location.pathname.split(match.url + '/')[1] ? location.pathname.split(match.url + '/')[1]
    .split('/' + fourthLevelRoute)[0] : '';

  const [formExists, setFormExists] = useState<boolean | null>(null);

  const checkFormIfExisting = async (company: string, formId: string) => {
    if (company && formId) {
      try {
        const request = await Axios.get(`${company}/forms/${formId}?draft=true`);
        if (request.data.success) {
          setFormExists(true);
        }
      } catch (e) {
        const err = e as any;
        const errorMessage = JSON.stringify(err.response.data.message);
        if (errorMessage.includes('Form not found')) {
          setFormExists(false);
          dispatch(getBusinessProcessErrorAction(errorMessage));
        }
      }
    }
  }

  useEffect(() => {
    checkFormIfExisting(companyId, formId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, companyId])

  useEffect(() => {
    if (typeof formExists === 'boolean' && formExists === false) {
      history.push(`/admin/business-process/form`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formExists]);

  const adminModule = new BusinessProcessModuleRegistry();
  const Render = adminModule.getModule(props.type);

  return <Render {...props} />
}

export default BusinessProcessModuleRoute;

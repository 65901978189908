// import { fromJS } from 'immutable'
import { SET_REQUEST_STATUS_CODE } from './constant';

export interface IErrorStatusHandlerReducer {
  statusCode: number;
  statusMessage: string;
}

const initialState = {
  statusCode: 0,
  statusMessage: ''
};

const errorStatusHandlerReducer = (state = initialState, action: any) => {
  let newState = state;

  switch (action.type) {
    case SET_REQUEST_STATUS_CODE:
      newState = { ...state };
      newState.statusCode = action.statusCode;
      newState.statusMessage = action.statusMessage;
      return newState;

    default:
      return state;
  }
}

export default errorStatusHandlerReducer

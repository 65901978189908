import React from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { IProcessLaunch, ITimeTriggerField } from '../interface/status-task-list';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import FieldValueColumn from './field-value-column';
import { IReference } from '../../bp-relationship/interface/form';
import { IForm } from '../../../../form/interface/form';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IProcessLaunchProp {
  processLaunch: IProcessLaunch;
  onCountInputChange(event: any, statusId: string, type: string, isExcelValue: boolean): void;
  onTimeUnitChange(target: any, statusId: string, type: string): void
  onCountChange(action: string, type: string, statusId: string, value: number, isExcelValue: boolean): void;
  onTimeTriggerChange(value: string, statusId: string, type: string): void;
  onTimeTriggerFieldChange(triggerField: any, statusId: string, slaType: "task" | "process"): void;
  referenceList: IReference[];
  currentFormId: string;
  formList: IForm[];
  fieldList: ICustomFieldDetail[];
  referencedBPFields: ICustomFieldDetail[];
}

const ProcessLaunch: React.FC<IProcessLaunchProp> = (props) => {

  return <>
    <tr>
      <td className='sla-column process header-label'>
        Process Launch
      </td>
      <td className='sla-column process enum'>
        <Dropdown
          className={`time-trigger`}
          id='process-unit'
          popperContainer={DropdownPopperOption}
          selectOnBlur={false}
          clearable
          search
          placeholder='Select'
          value={props.processLaunch.timeTrigger}
          options={[
            { key: 1, text: 'Task Assigned', value: 'task-assigned' },
            { key: 2, text: 'Deadline', value: 'deadline' },
          ]}
          selection
          onChange={(e, target: any) => props.onTimeTriggerChange(target.value, '', 'process')}
        />
      </td>
      <td className='sla-column process'>
        <div id='process-excel' className='ui icon input'>
          <input type='number' value={props.processLaunch.warningInterval} onChange={(e) =>
            props.onCountInputChange(e, '', 'process', true)}
          />
          <i aria-hidden="true" className="caret down icon" onClick={() => {
            if (props.processLaunch.warningInterval) {
              props.onCountChange('decrement', 'process', '', props.processLaunch.warningInterval, true)
            }
          }} />
          <i aria-hidden="true" className="caret up icon" onClick={() => {
            props.onCountChange('increment', 'process', '', props.processLaunch.warningInterval, true)
          }} />
        </div>
      </td>
      <td className='sla-column process'>
        <div id='process-sla' className={`ui icon input ${props.processLaunch.timeTrigger === 'deadline' ? 'disabled' : ''}`}>
          <input type='number' value={props.processLaunch.count} onChange={(e) =>
            props.onCountInputChange(e, '', 'process', false)}
          />
          <i aria-hidden="true" className="caret down icon" onClick={() => {
            if (props.processLaunch.count) {
              props.onCountChange('decrement', 'process', '', props.processLaunch.count, false)
            }
          }} />
          <i aria-hidden="true" className="caret up icon" onClick={() => {
            props.onCountChange('increment', 'process', '', props.processLaunch.count, false)
          }} />
        </div>
      </td>
      <td className='sla-column process'>
        <Dropdown
          id='process-unit'
          popperContainer={DropdownPopperOption}
          selectOnBlur={false}
          clearable
          options={[
            { key: 1, text: 'Days', value: 'd' },
            { key: 2, text: 'Hours', value: 'h' },
          ]}
          selection
          value={props.processLaunch.unit}
          onChange={(e, target: any) => { props.onTimeUnitChange(target, '', 'process') }}
        />
      </td>
      <FieldValueColumn
        showColumn={props.processLaunch.timeTrigger === 'deadline'}
        triggerFieldData={props.processLaunch.timeTriggerField || {} as ITimeTriggerField}
        onTimeTriggerFieldChange={props.onTimeTriggerFieldChange}
        slaType='process'
        statusId=''
        referenceList={props.referenceList}
        currentFormId={props.currentFormId}
        fieldList={props.fieldList}
        formList={props.formList}
        referencedBPFields={props.referencedBPFields}
      />
    </tr>
  </>
}

export default ProcessLaunch;
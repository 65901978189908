import React, { CSSProperties, ReactElement } from 'react';
import { Popup } from 'semantic-ui-react-bpm';
import useDeviceDetect from '../device-detect';

interface IToolTip {
  position?: "top left" | "top right" | "bottom right" | "bottom left" | "right center" | "left center" | "top center" | "bottom center" | undefined;
  popupMessage: string;
  popupTriggerComponent: ReactElement
  style?: CSSProperties;
  inverted?: boolean;
  basic?: boolean;
}

const toolTipStyle = {
  filter: 'none'
}

const ToolTip: React.FC<IToolTip> = (props) => {
  const { width } = useDeviceDetect();

  return <Popup
    basic={props.basic}
    inverted={props.inverted}
    disabled={width <= 1366}
    size='small'
    position={props.position}
    mouseEnterDelay={700}
    content={props.popupMessage}
    offset='0, 5px'
    style={{ ...toolTipStyle, ...props.style }}
    trigger={props.popupTriggerComponent}
  />
}

export default ToolTip;
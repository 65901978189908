import { useEffect } from 'react';

export const useRemoveCustomPopup = (): void => {
  useEffect(() => {
    return () => {
      const notificationnModal = document.getElementById('custom-popup-container');
      const hiddenContainer = document.getElementById('custom-popup-container-hidden-container');
      if (notificationnModal) {
        notificationnModal.parentNode?.removeChild(notificationnModal);
      }
      if (hiddenContainer) {
        hiddenContainer.parentNode?.removeChild(hiddenContainer);
      }
    }
  }, []);
}
import { useEffect, useState } from 'react';
import { ICustomFilterField } from '../../interface/custom-filter-field';


interface IProps {
  selectedFieldFilter: number | null;
  customFilterFieldList: { [x: string]: ICustomFilterField };
  filterNameRef: React.MutableRefObject<HTMLInputElement>
}

export const useSetConfigModalState = (props: IProps) => {
  const { customFilterFieldList, selectedFieldFilter, filterNameRef } = { ...props };

  const [isMultipleSelection, setIsMultipleSelection] = useState(false);
  const [selectedfieldOptions, setSelectedFieldOptions] = useState([] as string[]);
  const [fieldType, setFieldType] = useState('');

  useEffect(() => {
    if (selectedFieldFilter !== null) {
      let selectedIndex = selectedFieldFilter + '';
      const selectedConfig = customFilterFieldList[selectedIndex];
      if (selectedConfig) {
        filterNameRef.current.value = selectedConfig.name;
        setSelectedFieldOptions(selectedConfig.fields);
        setIsMultipleSelection(selectedConfig.isMultiSelection);
        setFieldType(selectedConfig.type)
      }
    } else {
      setSelectedFieldOptions([]);
      setIsMultipleSelection(false);
      setFieldType('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldFilter, customFilterFieldList]);

  return {
    ...props, isMultipleSelection, setIsMultipleSelection,
    selectedfieldOptions, setSelectedFieldOptions, fieldType, setFieldType
  };
}
import { IFormFieldGetDetailInput } from './interface/input/form-field-get';
import {
  GET_FORM_FIELD_LIST,
  GET_FORM_FIELD_LIST_RETURN,
  SAVE_DRAFT_CUSTOM_FILTER,
  SAVE_DRAFT_CUSTOM_FILTER_RETURN,
  SAVE_DRAFT_CUSTOM_FILTER_ERROR,
  PUBLISH_CUSTOM_FILTER,
  PUBLISH_CUSTOM_FILTER_RETURN,
  PUBLISH_CUSTOM_FILTER_ERROR,
  GET_CUSTOM_FILTER_LIST,
  GET_CUSTOM_FILTER_LIST_RETURN
} from './constant';
import { IFormFieldGetResponse } from './interface/response/form-field-get';
import { ICustomFilterSaveDetailInput, ICustomFilterPublishDetailInput } from './interface/input/custom-filter-save';
import { IFormFieldSaveResponse } from './interface/response/custom-filter-save';
import { ICustomFilterGetDetailInput } from './interface/input/custom-filter-get';
import { ICustomFilterGetResponse } from './interface/response/custom-filter-get';

export const getFormFieldListAction = (payload: IFormFieldGetDetailInput) => {
  return {
    type: GET_FORM_FIELD_LIST,
    ...payload
  }
}

export const getCustomFilterListAction = (payload: ICustomFilterGetDetailInput) => {
  return {
    type: GET_CUSTOM_FILTER_LIST,
    ...payload
  }
}

export const getCustomFilterListReturnAction = (payload: ICustomFilterGetResponse) => {
  return {
    type: GET_CUSTOM_FILTER_LIST_RETURN,
    ...payload
  }
}

export const getFormFieldListReturnAction = (payload: IFormFieldGetResponse) => {
  return {
    type: GET_FORM_FIELD_LIST_RETURN,
    ...payload
  }
}

export const saveDraftCustomFilterAction = (payload: ICustomFilterSaveDetailInput) => {
  return {
    type: SAVE_DRAFT_CUSTOM_FILTER,
    ...payload
  }
}

export const saveDraftCustomFilterReturnAction = (payload: IFormFieldSaveResponse) => {
  return {
    type: SAVE_DRAFT_CUSTOM_FILTER_RETURN,
    ...payload
  }
}

export const saveDraftCustomFilterErrorAction = (message: string) => {
  return {
    type: SAVE_DRAFT_CUSTOM_FILTER_ERROR,
    saveCustomFilterErrorMessage: message
  }
}

export const publishCustomFilterAction = (payload: ICustomFilterPublishDetailInput) => {
  return {
    type: PUBLISH_CUSTOM_FILTER,
    ...payload
  }
}

export const publishCustomFilterReturnAction = () => {
  return {
    type: PUBLISH_CUSTOM_FILTER_RETURN
  }
}

export const publishCustomFilterErrorAction = (message: string) => {
  return {
    type: PUBLISH_CUSTOM_FILTER_ERROR,
    saveCustomFilterErrorMessage: message
  }
}
import { useEffect, useState } from 'react';
import { IColumnView } from '../../document-list/interface/custom-view';
import { defaultFilterList } from '../../custom-filter/constant';
import { ITaskView, ITaskViewConfig } from '../interface/task-view';
import { fieldIconBaseOnConfigName } from '../../../constant';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { systemFieldList } from '../../document-list/constant';

interface IProps {
  taskView: React.MutableRefObject<ITaskView>;
  selectedViewConfig: ITaskViewConfig;
  updateStatusConfig(status: string, config: ITaskViewConfig): void;
  configRef: React.MutableRefObject<ITaskViewConfig>;
  selectedStatus: string;
  formFieldList: ICustomFieldDetail[];
}

export const useSetTaskViewConfigState = (props: IProps) => {
  const { taskView, selectedViewConfig, updateStatusConfig, configRef,
    selectedStatus, formFieldList } = { ...props };

  const [customViewList, setCustomViewList] = useState([] as IColumnView[]);
  const [customFilterFieldList, setCustomFilterFieldList] = useState(defaultFilterList);
  const [selectedReferStatus, setSelectedReferStatus] = useState('');

  useEffect(() => {
    configRef.current = { ...selectedViewConfig };
    const tempColumnHeader = selectedViewConfig.columnHeader &&
      selectedViewConfig.columnHeader.map((e: IColumnView) => {
        const field = formFieldList.find((field: ICustomFieldDetail) => field.id === e.fieldName)
        e.icon = fieldIconBaseOnConfigName[field?.configName || 'Text String'].icon;
        e.iconStyle = fieldIconBaseOnConfigName[field?.configName || 'Text String'].iconStyle;
        const data = systemFieldList.find((columnView: IColumnView) => columnView.fieldName === e.fieldName);
        if (e.type === 'system' && data) {
          e.icon = data?.icon;
          e.iconStyle = data?.iconStyle;
        }
        return e;
      })
    setSelectedReferStatus(selectedViewConfig.referToStatus);
    setCustomViewList(tempColumnHeader);
    setCustomFilterFieldList(selectedViewConfig.filterField);
    return () => {
      updateStatusConfig(selectedStatus, configRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskView, selectedViewConfig])

  return {
    ...props, customViewList, setCustomViewList,
    customFilterFieldList, setCustomFilterFieldList,
    selectedReferStatus, setSelectedReferStatus
  };
}
import React from 'react';
import { Form, Dropdown, Image } from 'semantic-ui-react-bpm';
import { IOption } from '../interface/option';
import { v4 as uuid } from 'uuid';
import { icons } from '../icons';
import ToolTip from '../general/tooltip';
import { IConfigName } from '../field/type/interface/field-type-object';
import SVGWrapper from '../icons/svg/svg-wrapper';
import EnumConfigPopup from '../custom-field-config/custom-field-type/enum-field-config/enum-config-popup';
import EnumSortPopup from '../custom-field-config/custom-field-type/enum-field-config/enum-sort-popup';
import { IEnumFieldConfig } from './interface/enum-field-config';

const DisplayFieldForm: React.FC<IEnumFieldConfig> = (props) => {

  const showAutopopulate = () => {
    return props.condition && props.condition instanceof Array && props.condition.length > 0;
  }

  return <>
    <div className={`display-field-from ${props.configName === IConfigName.Referenced ? '' : 'hidden'}`}>
      <Form.Group widths='equal' className={`required`}>
        <EnumSortPopup sort={props.sort} setSort={props.setSort} />
        <ToolTip position='right center'
          popupMessage='Filter Values...'
          popupTriggerComponent={
            <Image
              src={icons.blue.funnel}
              className={`filter-icon`}
              onClick={() => props.openFilterConfiguration(true)}
            />
          }
        />
        <div className={`field  ${props.errorDisplayField ? 'error' : ''}`}>
          <label>Display field from: </label>
          <Dropdown
            fluid
            selection
            clearable
            search
            value={''}
            selectOnBlur={false}
            options={props.displayFieldOption.filter((option: IOption) => props.selectedDisplayField.indexOf(option.value) === -1)}
            onChange={(event, target) => { props.setSelectionOfDisplayField(target.value as string) }}
          />
        </div>
      </Form.Group>
      <Form.Group widths='equal'>
        <div className='six wide field'>
          <div aria-invalid="true" role="listbox"
            aria-expanded="false" aria-multiselectable="true"
            className="ui multiple selection dropdown" tabIndex={0}>
            {
              props.selectedDisplayField.map((selectedId: string) => {
                const itemOption = props.displayFieldOption.filter((list: IOption) => selectedId === list.value)
                if (itemOption.length > 0) {
                  return <li key={uuid()} className="ui label">
                    {itemOption[0].text}
                    <i aria-hidden="true" className="delete icon" onClick={
                      () => props.removeSelectedField(itemOption[0].value)
                    }></i>
                  </li>
                }
                return undefined;
              })
            }
            <div className="text" role="alert" aria-live="polite" aria-atomic="true"></div>
            {!!props.selectedDisplayField.length &&
              <i aria-hidden="true" className="dropdown icon clear" onClick={
                () => props.setSelectedDisplayField([])
              }></i>
            }
          </div>
        </div>
      </Form.Group>
      <Form.Group widths='equal'>
        <div className={`field  ${props.errorDisplayField ? 'error' : ''}`}>
          <label>Image: </label>
          <Dropdown
            fluid
            selection
            clearable
            search
            value={props.enumImageField}
            selectOnBlur={false}
            options={props.imageFieldOption.filter((option: IOption) => props.selectedDisplayField.indexOf(option.value) === -1)}
            onChange={(event, target) => { props.setEnumImageField(target.value as string) }}
          />
        </div>
      </Form.Group>
      <Form.Group widths='equal'>
        <div className={`field  ${props.errorDisplayField ? 'error' : ''}`}>
          <label>Grouping: </label>
          <Dropdown
            fluid
            selection
            clearable
            search
            value={props.enumGroupField}
            selectOnBlur={false}
            options={props.groupingFieldOption.filter((option: IOption) => props.selectedDisplayField.indexOf(option.value) === -1)}
            onChange={(event, target) => { props.setEnumGroupField(target.value as string) }}
          />
        </div>
      </Form.Group>
      {props.enumDataType === 'user' && props.setAdditionalInfo &&
        <Form.Group widths='equal'>
          <div className={`field  ${props.errorDisplayField ? 'error' : ''}`}>
            <label>Additional Info: </label>
            <Dropdown
              fluid
              selection
              clearable
              search
              value={props.additionalInfo}
              selectOnBlur={false}
              options={props.displayFieldOption}
              onChange={(event, target) => { props.setAdditionalInfo!(target.value as string) }}
            />
          </div>
        </Form.Group>
      }
      <div className={`display-field-checkbox`}>
        <EnumConfigPopup {...props}
          showAutopopulate={showAutopopulate()}
        />
        <Form.Group widths='equal' className={`separator-container ${props.selectedDisplayField.length > 1 ? '' : 'hidden'}`}>
          <div className='horizontal-border' />
          <div className='enum-config-separator'>
            <div className='title'>
              <span>Separator: </span>
              <span className='info'>(Default: space)</span>
            </div>
            <div className='input-text'>
              <input type="text" value={props.separator} onChange={(e) => props.setSeparator(e.target.value)} />
              {props.separator !== ' ' && props.separator !== '' &&
                <div className='clear' onClick={() => props.setSeparator('')}>
                  <SVGWrapper
                    iconName='closeIcon'
                    color='#0000008a'
                    width={12}
                    height={12}
                  />
                </div>
              }
            </div>
          </div>
        </Form.Group>
      </div>
    </div>

  </>
}

export default DisplayFieldForm;
import { IUser } from '../../../../../../admin-module/module/users/interface/user';
import { IComment } from '../../../interface/comment';

export const setCommentBody = (body: IComment, user: IUser) => {
  let commentBody = body.text;
  if (commentBody.includes('@')) {
    commentBody = commentBody.replace(/[$@%]/g, '');
  }
  const divElement = document.createElement('div');
  divElement.innerHTML = commentBody;
  const textElement: any = divElement.getElementsByTagName('p');

  if (textElement.length > 0) {
    for (let i = 0; i < textElement[0].children.length; i++) {
      const mentionedId = textElement[0].children[i].getAttribute(
        'comment-mention-user-id'
      );
      if (mentionedId === user.id) {
        textElement[0].children[i].style.removeProperty('background');
        textElement[0].children[i].style.setProperty(
          'background',
          'rgba(0, 0, 0, 0.20)'
        );
      }
    }
    commentBody = textElement[0].outerHTML;
  }

  return commentBody;
}
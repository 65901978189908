import { IUser } from '../../../admin-module/module/users/interface/user';
import {
  GET_USER_MANAGER_DETAILS,
  GET_USER_MANAGER_DETAILS_RETURN,
  GET_USER_MANAGER_DETAILS_ERROR,
  UPDATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT_RETURN,
  UPDATE_USER_ACCOUNT_ERROR,
  CLEAR_UPDATE_USER_ACCOUNT_SUCCESS,
  CLEAR_UPDATE_USER_ACCOUNT_ERROR
} from './constant';
import { message } from './message';

export interface IUserAccountReducer {
  userManagerDetail: IUser;
  userManagerLoading: boolean;
  userManagerError: string;
  userAccountLoading: boolean;
  userAccountError: string;
  userAccountMessage: string;
}

const initialState: IUserAccountReducer = {
  userManagerDetail: {} as IUser,
  userManagerError: '',
  userManagerLoading: false,
  userAccountError: '',
  userAccountLoading: false,
  userAccountMessage: ''
}

const userAccountReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_USER_MANAGER_DETAILS:
      newState.userManagerDetail = {};
      newState.userManagerError = '';
      newState.userManagerLoading = true;
      return newState;

    case GET_USER_MANAGER_DETAILS_RETURN:
      newState.userManagerDetail = action.userManagerDetails;
      newState.userManagerLoading = false;
      return newState;

    case GET_USER_MANAGER_DETAILS_ERROR:
      newState.userManagerError = action.message;
      newState.userManagerLoading = false;
      return newState;

    case UPDATE_USER_ACCOUNT:
      newState.userAccountLoading = true;
      newState.userAccountMessage = '';
      newState.userAccountError = '';
      return newState;

    case UPDATE_USER_ACCOUNT_RETURN:
      newState.userAccountLoading = false;
      newState.userAccountMessage = message.userAccountSuccess;
      return newState;

    case UPDATE_USER_ACCOUNT_ERROR:
      newState.userAccountLoading = false;
      newState.userAccountError = action.message;
      return newState;

    case CLEAR_UPDATE_USER_ACCOUNT_SUCCESS:
      newState.userAccountMessage = '';
      return newState;

    case CLEAR_UPDATE_USER_ACCOUNT_ERROR:
      newState.userAccountError = '';
      return newState;

    default:
      return state;
  }
}

export default userAccountReducer;
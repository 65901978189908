import React from 'react';
import { useSelector } from 'react-redux';
import { UploadedFile } from '../../../../../../common/api-request/upload';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import { IRootState } from '../../../../../../reducers';
import { IComment } from '../../interface/comment';
import { ISenderDetail } from '../../interface/sender-info';
import CommentActionPopup from '../popups/action';
import InputBox from '../input';
import CommentWidgetAvatar from './utils/avatar';
import CommentWidgetBody from './utils/body';
import CommentWidgetSenderInfo from './utils/sender-info';

interface IChatWidgetMessage {
  comment: IComment;
  selectedId: string;
  setSelectedId(value: string): void;
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  deleteComment(id: string): void;
  errorImage: string[];
  addToErrorImage(imageId: string): void;
  removeUploadedFile(file: UploadedFile): void;
  userColor: { [id: string]: string };
  uploadedFileList: UploadedFile[];
  removeFileAfterSave(): void;
  documentId: string;
  markCommentAsUnread(comment: IComment): void;
  lastReadMessage: IComment;
  module: string;
}

const CommentWidget: React.FC<IChatWidgetMessage> = (props) => {
  const senderUserInfo = { ...(props.comment.sender && props.comment.sender.data) } as ISenderDetail;

  const { width } = useDeviceDetect();
  const { user } = useSelector((state: IRootState) => state.auth.userLoggedIn);

  const showNewCommentIndicator = () => {
    return props.lastReadMessage && props.lastReadMessage.id === props.comment.id;
  }

  if (props.selectedId === props.comment.id && width > 480) {
    return <InputBox
      documentId={props.documentId}
      removeFileAfterSave={props.removeFileAfterSave}
      removeUploadedFile={props.removeUploadedFile}
      uploadedFileList={props.uploadedFileList}
      saveComment={props.saveComment}
      message={props.comment.text}
      setSelectedId={props.setSelectedId}
      commentId={props.comment.id}
      mobile={width <= 480}
      actionType='edit'
      selectedId={props.selectedId}
      module={props.module}
    />
  }

  return <div id={props.comment.id} className={`chat-message-widget`}>
    {showNewCommentIndicator() &&
      <span className='chat-unread-mark'>New</span>
    }
    <CommentWidgetAvatar
      addToErrorImage={props.addToErrorImage}
      comment={props.comment}
      errorImage={props.errorImage}
      userColor={props.userColor}
    />
    <div className={`message-content-container`}>
      <CommentWidgetSenderInfo comment={props.comment} />
      <CommentWidgetBody body={{ ...props.comment }} />
    </div>
    <CommentActionPopup
      deleteComment={props.deleteComment}
      setSelectedId={props.setSelectedId}
      comment={props.comment}
      canEditOrDelete={senderUserInfo?.id === user.id}
      markCommentAsUnread={props.markCommentAsUnread}
    />
  </div>
};

export default CommentWidget;

import {
  GET_PDF_TEMPLATE_LIST,
  GET_PDF_TEMPLATE_LIST_RETURN,
  UPDATE_LOCAL_PDF_TEMPLATE_LIST,
  PUBLISH_PDF_TEMPLATE_CONFIG,
  PUBLISH_PDF_TEMPLATE_CONFIG_RETURN,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN
} from './constant';
import { IPdfTemplateGetDetailInput } from './interface/input/template-get';
import { IPdfTemplatePublishDetailInput, IPdfTemplateSaveDetailInput } from './interface/input/template-save';
import { IPdfTemplateConfig } from './interface/pdf';
import { IPdfTemplateGetResponse } from './interface/response/template-get';
import { IPdfTemplateSaveResponse } from './interface/response/template-save';

export const getPdfTemplateListAction = (payload: IPdfTemplateGetDetailInput) => {
  return { type: GET_PDF_TEMPLATE_LIST, ...payload }
}

export const getPdfTemplateListReturnAction = (payload: IPdfTemplateGetResponse) => {
  return { type: GET_PDF_TEMPLATE_LIST_RETURN, ...payload }
}

export const updateLocalPdfTemplateListAction = (pdfTemplateConfigList: IPdfTemplateConfig[]) => {
  return { type: UPDATE_LOCAL_PDF_TEMPLATE_LIST, pdfTemplateConfigList }
}

export const saveDraftPDFTemplateConfigAction = (payload: IPdfTemplateSaveDetailInput) => {
  return { type: SAVE_DRAFT_PDF_TEMPLATE_CONFIG, ...payload }
}

export const saveDraftPDFTemplateConfigReturnAction = (payload: IPdfTemplateSaveResponse) => {
  return { type: SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN, ...payload }
}

export const saveDraftPDFTemplateConfigErrorAction = (message: string) => {
  return { type: SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR, savePDFTempErrorMessage: message }
}

export const publishPDFTemplateConfigAction = (payload: IPdfTemplatePublishDetailInput) => {
  return { type: PUBLISH_PDF_TEMPLATE_CONFIG, ...payload }
}

export const publishPDFTemplateConfigReturnAction = () => {
  return { type: PUBLISH_PDF_TEMPLATE_CONFIG_RETURN }
}
import React from 'react';
import FieldContainer from './field-container';

interface ISectionBody {

}

const SectionBody: React.FC<ISectionBody> = (props) => {
  return <div className='section-body'>
    <FieldContainer />
    <FieldContainer />
    <FieldContainer />
  </div>
}

export default SectionBody;
import { useEffect } from 'react';
import { getAutomationLogsListAction } from '../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string;
  offset: number;
}

export const useGetAutomationLogs = (props: IProps): void => {
  const { dispatch, company, formId, offset } = { ...props };

  useEffect(() => {
    if (company && formId) {
      dispatch(getAutomationLogsListAction({
        company: company,
        formId: formId,
        offset: offset
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId, offset]);
}
import React from 'react';
import { ButtonList } from '../button-list';
import { businessProcessSubModules } from '../../../constant';
import { IButtonListProp } from '../interface/button-list-props';
import { Button } from 'semantic-ui-react-bpm';
import { useRouteMatch, Route, Link } from 'react-router-dom';
import { IMainReducer } from '../../../../main/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../reducers';
import changeRoute from '../../../../../common/utils/change-menu';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import { configurationSubModules } from '../../../module/business-process/module/configuration/constant';
import { getAdminHistoryLogsAction } from '../../../history/action';

export interface IButtonListContainerProp {
  history: { push: Function }
  main: IMainReducer;
  name: string;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  setIsOpenChatbox?: Function;
  isOpenChatbox?: boolean;
  companyId?: string;
}

const BusinessProcessButtonContainer: React.FC<IButtonListContainerProp> = (props) => {
  const match: { params: { formId: string, submodule: string; thirdLevelRoute: string } } = useRouteMatch();
  const { hasPageChange, thirdLevelRoute, fourthLevelRoute } = useSelector((state: IRootState) => state.main);

  const dispatch = useDispatch();

  const { formBuilderField } = useSelector((state: IRootState) => state.formBuilder);
  const { statusList } = useSelector((state: IRootState) => state.role);
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const openChatbox = () => {
    let adminCategory = [] as string[];
    if (props.setIsOpenChatbox) {
      props.setIsOpenChatbox(true);
      if (thirdLevelRoute === 'configuration') {
        const { categoryLogs }: any = configurationSubModules.find(e => e.id === fourthLevelRoute);
        if (categoryLogs) {
          adminCategory = categoryLogs;
        }
      } else {
        const { categoryLogs }: any = businessProcessSubModules.find(e => e.id === thirdLevelRoute);
        if (categoryLogs) {
          adminCategory = categoryLogs;
        }
      }
    }
    if (adminCategory.length > 0 && props.companyId) {
      // const newFilter = `targetFilters[]=formId=${match.params.formId}`;
      dispatch(getAdminHistoryLogsAction({
        company: props.companyId,
        categories: adminCategory,
        fields: formBuilderField,
        statusList: statusList,
        filters: `instanceFieldKey=formId&instanceFieldBelongKey=formId&instanceFieldValue=${match.params.formId}`,
        tz: clientTimezone
      }));
    }
  }

  const handleLinkClick = (e: any, url: string) => {
    changeRoute(url, hasPageChange || props.hasPageChangeRef.current.hasChange, { history: props.history, dispatch }, e);
  }

  return <div id='route-container' className='flex'>
    {
      businessProcessSubModules.map(({ id, name }, key) => {
        return (
          <React.Fragment key={key}>
            <Link to={`/admin/${props.name}/${id}/${match.params.formId}`}
              onClick={(event) => handleLinkClick(event, `/admin/${props.name}/${id}/${match.params.formId}`)}
              onContextMenu={(event) => handleLinkClick(event, `/admin/${props.name}/${id}/${match.params.formId}`)}>
              <Button className={`admin-item-menu ${id.toLowerCase() === props.main.thirdLevelRoute.toLowerCase() ? 'active-subroute' : ''}`}>
                {name}
              </Button>
            </Link>
          </React.Fragment>
        )
      })
    }
    <Button className={`chat-icon form ${props.isOpenChatbox ? 'active' : 'inactive'}`} onClick={openChatbox}>
      <SVGWrapper iconName='adminChatIcon' width='22' color={`${props.isOpenChatbox ? '#fff' : '#3a85a8'}`} />
    </Button>
  </div>
}

export class BusinessProcessButton extends ButtonList {
  name = 'business-process';

  Render: React.FC<IButtonListProp> = (props) => {

    const match = useRouteMatch()
    if (!props.main.thirdLevelRoute) {
      let CButtonList = new ButtonList()
      return <CButtonList.Render {...props} />
    }

    return <>
      <Route path={`${match.path}/:thirdLevelRoute/:formId`} >
        <BusinessProcessButtonContainer
          name={this.name}
          history={props.history}
          main={props.main}
          hasPageChangeRef={props.hasPageChangeRef}
          setIsOpenChatbox={props.setIsOpenChatbox}
          isOpenChatbox={props.isOpenChatbox}
          companyId={props.companyId}
        />
      </Route>
    </>
  }
}
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IButtonList } from './interface/button-list';
import { IButtonListProp } from './interface/button-list-props';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../../reducers';
import changeRoute from '../../../../common/utils/change-menu';
import { Link, useRouteMatch } from 'react-router-dom';

export class ButtonList implements IButtonList {

  name = 'default';

  Render: React.FC<IButtonListProp> = (props) => {
    const match: { params: { submodule: string } } = useRouteMatch();
    const { hasPageChange } = useSelector((state: IRootState) => state.main);
    const dispatch = useDispatch();

    const handleLinkClick = (e: any, url: string) => {
      changeRoute(url, hasPageChange || props.hasPageChangeRef.current.hasChange, { history: props.history, dispatch }, e);
    }

    return <>
      <div id='route-container' className={match.params.submodule === 'settings' ? 'hidden' : ''}>
        {
          props.modules.map(({ id, name }, key) => {
            if (props.isCurrentUserNotBA &&
              (id === 'apps' || id === 'business-process' || id === 'data-tables')) {
              return null;
            }
            if ((!props.allowedAccesUser) && (id === 'users')) {
              return null;
            }
            if (!props.allowedAccessGroups && id === 'groups') {
              return null;
            }

            return (
              <React.Fragment key={key}>
                <Link to={`/admin/${id}`}
                  onClick={(event) => handleLinkClick(event, `/admin/${id}`)}
                  onContextMenu={(event) => handleLinkClick(event, `/admin/${id}`)}>
                  <Button className={`admin-item-menu ${id.toLowerCase() === props.main.subRoute.toLowerCase() ? 'active-subroute' : ''}`}>
                    {name}
                  </Button>
                </Link>
              </React.Fragment>
            )
          })
        }
      </div>
    </>
  }
}

import React from 'react';
import { IApp } from '../reducer';
import AppsGrid from './apps-grid';
interface IAppTableList {
  appList: IApp[];
  openDeleteModal(appListId: string, appListLabel: string): void;
  triggerFilter(limit?: number | undefined, offset?: number | undefined, search?: string | undefined): void;
  currentOffset: number;
}

const parseBPs = (forms: { name: string }[]) => {
  return forms.map(item => item.name).join(', ');
}

export class AppTableList {
  static Render: React.FC<IAppTableList> = (props) => {
    const appList: IApp[] = Object.assign([], props.appList);

    return <div className='app-table-container table-wrapper'>
      <AppsGrid
        className={`app-table-container, table-wrapper`}
        headers={['App ID', 'App Name', 'Business Process']}
        rows={appList.map(item => { return { key: item.id, 'App ID': item.seq, 'App Name': item.name, 'Business Process': parseBPs(item.forms) } })}
        deleteApp={props.openDeleteModal}
        triggerFilter={props.triggerFilter}
        currentOffset={props.currentOffset}
      />
    </div>
  }
}
import React from 'react';
import PortalUserFilter from './filter';
import PortalUserList from './list';

interface IPortalUser {

}

const PortalUsers: React.FC<IPortalUser> = (props) => {
  return <div className='portal-user-container'>
    <PortalUserFilter />
    <PortalUserList />
  </div>
}

export default PortalUsers;
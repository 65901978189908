import { IUserManagerInput } from './interface/input/user-manager';

import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, putRequest } from '../../../../common/api-request';
import { getUserManagerDetailsReturnAction, getUserManagerDetailsErrorAction, updateUserAccountErrorAction, updateUserAccountReturnAction } from './action';
import { setStatusCodeAction } from '../../../error/action';
import { GET_USER_MANAGER_DETAILS, UPDATE_USER_ACCOUNT } from './constant';
import { IUserAccountUpdateInput } from './interface/input/update-user-account';
import { getEndUserDetailReturnAction } from '../../actions';

export function* getUserManagerDetails(args: IUserManagerInput) {
  try {
    const userId = args.userId;
    const company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/users/${userId}`, {});
    yield put(getUserManagerDetailsReturnAction({ userManagerDetails: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getUserManagerDetailsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* updateUserAccount(args: IUserAccountUpdateInput) {
  try {
    const userId = args.userId;
    const company = args.company;
    const data = args.data;
    const req: IAxiosResponse = yield call(putRequest, `${company}/users/${userId}`, data);
    yield put(getEndUserDetailReturnAction({ data: req.data.data }));
    yield put(updateUserAccountReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(updateUserAccountErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* userAccountSagas() {
  yield takeLatest(GET_USER_MANAGER_DETAILS, getUserManagerDetails);
  yield takeLatest(UPDATE_USER_ACCOUNT, updateUserAccount);
}

// All sagas to be loaded
export default userAccountSagas;
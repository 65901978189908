import {
  SCREEN_INACTIVITY_MODAL,
  AUTH_LOGOUT,
  AUTH_LOGOUT_MESSAGE_RETURN,
  AUTH_LOGOUT_MESSAGE_RESET,
  AUTH_LOGOUT_RETURN,
} from '../constant';

import { ILogoutDetail } from '../interface/input/logout';
import { IAutomaticLogout } from '../interface/input/automatic-logout';

export const triggerAutomaticLogoutAction = (payload: IAutomaticLogout) => {
  return { type: SCREEN_INACTIVITY_MODAL, ...payload };
};

export const triggerLogoutAction = (payload: ILogoutDetail) => {
  return { type: AUTH_LOGOUT, ...payload };
};

export const triggerLogoutReturnAction = (payload: ILogoutDetail) => {
  return { type: AUTH_LOGOUT_RETURN, ...payload };
};

export const triggerLogoutMessageReturn = (payload: ILogoutDetail) => {
  return { type: AUTH_LOGOUT_MESSAGE_RETURN, ...payload };
};

export const closeLogoutMessageModal = () => {
  return { type: AUTH_LOGOUT_MESSAGE_RESET };
};
import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import PhoneType from '../type/string/phone';
import PhoneArrayType from '../type/string/phone-array';

const Phone: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <PhoneArrayType {...props} />;
  } else {
    return <PhoneType {...props} />
  }
}

export default Phone;
import {
  GET_FORM_STATUS_LIST,
  GET_FORM_STATUS_LIST_RETURN,
  GET_FORM_STATUS_LIST_ERROR,
  SAVE_SLAS,
  SAVE_SLAS_RETURN,
  SAVE_SLAS_ERROR,
  PUBLISH_SLAS,
  PUBLISH_SLAS_RETURN,
  PUBLISH_SLAS_ERROR,
  SET_SLAS_ERROR_MESSAGE
} from './constant';
import { ISlaSaveDetailInput, ISlaPublishDetailInput } from './interface/input/slas-save';
import { ISlaStatusesResponse } from './interface/response/slaStatuses';
import { ISlaSaveResponse } from './interface/response/slas-save';
import { ISlaStatusesDetailInput } from './interface/input/slaStatuses';

export const getStatusListAction = (payload: ISlaStatusesDetailInput) => {
  return { type: GET_FORM_STATUS_LIST, ...payload };
}

export const getStatusListReturnAction = (payload: ISlaStatusesResponse) => {
  return { type: GET_FORM_STATUS_LIST_RETURN, ...payload };
}

export const getStatusListErrorAction = (message: string) => {
  return { type: GET_FORM_STATUS_LIST_ERROR, message };
}

export const saveSlasAction = (payload: ISlaSaveDetailInput) => {
  return { type: SAVE_SLAS, ...payload }
}

export const saveSlasReturnAction = (payload: ISlaSaveResponse) => {
  return { type: SAVE_SLAS_RETURN, ...payload }
}

export const saveSlasErrorAction = (message: string) => {
  return { type: SAVE_SLAS_ERROR, formSaveErrorMessage: message }
}

export const publishSlasAction = (payload: ISlaPublishDetailInput) => {
  return { type: PUBLISH_SLAS, ...payload }
}

export const publishSlasReturnAction = () => {
  return { type: PUBLISH_SLAS_RETURN }
}

export const publishSlasErrorAction = (message: string) => {
  return { type: PUBLISH_SLAS_ERROR, formSaveErrorMessage: message }
}

export const setSlasErrorValidationMessage = (message: string) => {
  return { type: SET_SLAS_ERROR_MESSAGE, validationMessage: message }
}
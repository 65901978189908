import { getPortalsAction } from '../../../../action';
import { IAutomationComponent } from '../../../../interface/component-list';

export class CreateUserUtils {
  static getPortals = (selectedComponent: IAutomationComponent, utils: any) => {
    const portalId = selectedComponent?.config?.portalId;

    if (portalId !== null && utils.portals.length > 0) return;

    utils.dispatch(getPortalsAction({ companyId: utils.company }));
  }
}

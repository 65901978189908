import React from 'react';
import { v4 as uuid } from 'uuid';
import { IOptionWidget } from '../../../interface/option-widget';
import AutomationDetailOptionWidget from './option-widget';

interface IAutomationDetailContentProps {
  recommended: IOptionWidget[];
  allComponent: IOptionWidget[];
  selected: IOptionWidget | undefined;
  setSelected(option: IOptionWidget): void;
  contentScreen: 'Triggers' | 'Actions' | 'Conditions';
}

const AutomationDetailContent: React.FC<IAutomationDetailContentProps> = (props) => {
  return (
    <div className='automation-detail-content'>
      {props.recommended.length > 0 &&
        <>
          <h4>Recommended</h4>
          <div className='option-widget-selection' >
            {
              props.recommended.map((e: IOptionWidget) => {
                return <AutomationDetailOptionWidget
                  key={uuid()}
                  {...e}
                  selected={(props.selected && props.selected.name === e.name) === true}
                  setSelected={() => props.setSelected(e)}
                />
              })
            }
          </div>
        </>
      }
      <h4>All {props.contentScreen}</h4>
      <div className='option-widget-selection' >
        {
          props.allComponent.map((e: IOptionWidget, index: number) => {
            return <AutomationDetailOptionWidget
              key={uuid()}
              {...e}
              selected={(props.selected && props.selected.name === e.name) === true}
              setSelected={() => props.setSelected(e)}
            />
          })
        }
      </div>
    </div>
  );
}

export default AutomationDetailContent;
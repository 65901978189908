import React from 'react';
import { v4 as uuid } from 'uuid';
import DynamicField from '.';
import { FieldElement, IConfig } from '../component/field-element';
import { setRangeFieldBaseOnType } from '../component/object/type/range/utils/set-range-field-type';
import { IFieldData } from './interface/field-data';

interface IRangeCustomField {
  data: IFieldData;
  detail: any;
}

interface IRangeField extends IRangeCustomField {
  config: IConfig;
  id: string;
  rangeType: string;
  defaultValue: any;
}

const RangeCustomField: React.FC<IRangeCustomField> = (props) => {

  const setRangeValues = (rangeType: 'from' | 'to') => {
    let fieldData = { ...props.data.fields };
    if (props.data && fieldData) {
      if (fieldData[props.detail.id]) {
        fieldData[props.detail.id] = fieldData[props.detail.id][rangeType];
      }
    }
    return fieldData;
  }

  const itemConfigFrom = props.detail.inputConfig.config.from || props.detail.inputConfig.config.itemConfigFrom;
  const itemConfigTo = props.detail.inputConfig.config.to || props.detail.inputConfig.config.itemConfigTo;

  const hasData = !!props.data.fields[props.detail.id]
    && Object.keys(props.data.fields[props.detail.id]).length > 0;

  return <>
    {hasData &&
      <div className='range-field-detail'>
        <Range {...props}
          config={{ ...itemConfigFrom, type: itemConfigFrom.type || props.detail.inputConfig.config.itemType }}
          id={props.detail.id}
          defaultValue={props.detail.defaultValue}
          rangeType={'from'}
          data={{ ...props.data, fields: setRangeValues('from') }}
        />
        <label>&#8594;</label>
        <Range {...props}
          config={{ ...itemConfigTo, type: itemConfigTo.type || props.detail.inputConfig.config.itemType }}
          id={props.detail.id}
          defaultValue={props.detail.defaultValue}
          rangeType={'from'}
          data={{ ...props.data, fields: setRangeValues('to') }}
        />
      </div>
    }
  </>
}

const Range: React.FC<IRangeField> = (props) => {
  const fieldData = setRangeFieldBaseOnType(props.config, props.id, props.rangeType, props.defaultValue);
  fieldData.id = props.id;
  const field = FieldElement.getDataElement(fieldData);

  return <DynamicField.Render
    key={`column-${field.id}-${props.id}-${uuid()}`}
    data={props.data as IFieldData}
    detail={field}
  />
}

export default RangeCustomField;
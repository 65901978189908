import React from 'react';
import { IAutomation } from '../../../../interface/automation';
import { IAutomationComponent } from '../../../../interface/component-list';
import ComputeField from './compute-field';
import CreateDocument from './create-document';
import EditForm from './edit-form';
import ActionStatusUpdate from './status-update';
import SendEmail from './send-email';
import CreateUser from './create-user';
import SendSMS from './send-sms';
import GeneratePdf from './generate-pdf';

const ComponentList: IActionTypeRegistry[] = [
  new ActionStatusUpdate(),
  new CreateDocument(),
  new EditForm(),
  new ComputeField(),
  new SendEmail(),
  new CreateUser(),
  new SendSMS(),
  new GeneratePdf(),
]

export interface IActionType {
  type: string;
}


export interface IActionTypeRegistryRender {
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  selectedComponent: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  refsObject: React.MutableRefObject<any>;
  isHasParent: boolean;
  type: string;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
}

interface IActionTypeRegistry {
  name: string;
  render: React.FC<IActionTypeRegistryRender>
}

export class ActionTypeRegistry {

  registry: { [name: string]: IActionTypeRegistry } = {};

  constructor() {
    const types = ComponentList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC<IActionTypeRegistryRender> = () => {
    return null;
  }

  getModule(name: string): React.FC<IActionTypeRegistryRender> {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IActionTypeRegistryRender> = (props) => {
    const component = new ActionTypeRegistry();
    const Render = component.getModule(props.type);
    return <Render {...props} />
  }

}
import { IInternalDatatablePermission, IExternalDatatablePermission } from '../../component/admin-module/module/data-tables/interface/data-table-permission';
import { IUser } from '../../component/admin-module/module/users/interface/user';
import { IOption } from '../interface/option';
import { IField } from '../../component/admin-module/module/users/interface/field';
import { getFieldId } from './get-field';
import { IGroup } from '../../component/admin-module/module/groups/interface/group';

export class SanitizeDatatablePermission {

  static convertOutgoingPermission = (permission: IInternalDatatablePermission): IExternalDatatablePermission[] => {
    let permissionList = [] as IExternalDatatablePermission[];
    permission.view.forEach((value: string) => {
      const valueObject = value.split('|||||');
      permissionList.push({
        subjectType: valueObject[0],
        subjectId: valueObject[1],
        permissions: ['read']
      });
    });
    permission.modify.forEach((value: string) => {
      const valueObject = value.split('|||||');
      const tempPermissionList = permissionList.filter((e: IExternalDatatablePermission) => e.subjectType === valueObject[0] && e.subjectId === valueObject[1])
      if (tempPermissionList.length === 1) {
        permissionList = permissionList.map((e: IExternalDatatablePermission) => {
          if (e.subjectType === valueObject[0] && e.subjectId === valueObject[1]) {
            e.permissions = e.permissions.concat(['create', 'update', 'delete']);
          }
          return e;
        })
      } else {
        permissionList.push({
          subjectType: valueObject[0],
          subjectId: valueObject[1],
          permissions: ['create', 'update', 'delete']
        });
      }
    })

    return permissionList;
  }

  static convertIncomingPermission = (permission: IExternalDatatablePermission[]): IInternalDatatablePermission => {
    let convertedPermission: IInternalDatatablePermission = {
      view: [],
      modify: []
    };

    permission.forEach((e: IExternalDatatablePermission) => {
      if (e.permissions.indexOf('read') > -1) {
        convertedPermission.view.push(`${e.subjectType}${e.subjectId ? `|||||${e.subjectId}` : ''}`);
      }
      if (e.permissions.indexOf('create') > -1 || e.permissions.indexOf('update') > -1 || e.permissions.indexOf('delete') > -1) {
        convertedPermission.modify.push(`${e.subjectType}${e.subjectId ? `|||||${e.subjectId}` : ''}`);
      }
    });

    return convertedPermission;
  }

  static convertUserPermissionOption = (userList: IUser[], groupList: IGroup[], fieldList: IField[]): IOption[] => {

    let permissionOption: IOption[] = [];
    let ctr = 0;
    const user = userList.map((e: IUser, index: number) => {
      return {
        key: ctr++,
        text: e.fields ? e.fields[getFieldId(fieldList, 'Email Address')] : '',
        value: `user|||||${e.id}`
      }
    });
    const group = groupList.map((e: IGroup, index: number) => {
      return {
        key: ctr++,
        text: e.name,
        value: `group|||||${e.id}`
      }
    })
    permissionOption = permissionOption.concat(user, group);
    return permissionOption
  }


}
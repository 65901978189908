import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Email,
  type: 'string',
  required: false
};

interface IDefaultConfigObject {
  format: string;
  displayType: string;
}

const defaultConfig: IDefaultConfigObject = {
  format: 'email',
  displayType: 'line'
};

const value = {
  title: 'Email',
  icon: {
    black: require('../../icons/black/email-black.png'),
    white: require('../../icons/white/email-white.png'),
    blue: require('../../icons/blue/email-blue.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
import React, { CSSProperties } from 'react';
import { Popup, Container, Icon } from 'semantic-ui-react-bpm';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import SVGWrapper from '../../../../../../common/icons/svg/svg-wrapper';
import { IComment } from '../../interface/comment';

interface ICommentActionPopup {
  setSelectedId(value: string): void;
  deleteComment(id: string): void;
  markCommentAsUnread(comment: IComment): void;
  canEditOrDelete: boolean;
  comment: IComment;
}

const actionStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '15px auto',
  gridColumnGap: '10px',
  alignItems: 'center'
}

const contentStyle: CSSProperties = {
  padding: '7px 15px',
  fontSize: '13px'
}

const CommentActionPopup: React.FC<ICommentActionPopup> = (props) => {
  const { width } = useDeviceDetect();

  const closePopup = () => {
    document.body.click();
  }

  const triggerAction = (action: 'edit' | 'delete' | 'markUnread') => {
    if (action === 'delete') {
      closePopup();
      props.deleteComment(props.comment.id);
      return;
    }
    if (action === 'edit') {
      closePopup();
      props.setSelectedId(props.comment.id);
      return;
    }
    if (action === 'markUnread') {
      closePopup();
      props.markCommentAsUnread(props.comment);
      return;
    }
  }

  return <Popup
    basic
    on='click'
    position='bottom right'
    id='dtr-bulk-actions-popup'
    className={width <= 480 ? 'mobile' : ''}
    trigger={
      <Icon className='comment-action-popup' name='ellipsis vertical' />
    }>
    <Container className='bulk-actions-popup-container'>
      <div className='bulk-actions-content' style={contentStyle}
        onClick={() => triggerAction('markUnread')}>
        <div style={actionStyle}>
          <SVGWrapper iconName='check' color='#000' width='15' height='15' />
          <span>Mark as unread</span>
        </div>
      </div>
      {props.canEditOrDelete &&
        <>
          <div className='bulk-actions-separator' />
          <div onClick={() => triggerAction('edit')} style={contentStyle}
            className='bulk-actions-content'>
            <div style={actionStyle}>
              <SVGWrapper iconName='pen' color='#000' width='15' height='15' />
              <span>Edit Comment</span>
            </div>
          </div>
          <div className='bulk-actions-separator' />
          <div onClick={() => triggerAction('delete')} style={contentStyle}
            className='bulk-actions-content del'>
            <div style={{ ...actionStyle, color: '#db2828' }}>
              <SVGWrapper iconName='trash' color='#db2828' width='15' height='15' />
              <span>Delete Comment</span>
            </div>
          </div>
          <div className='bulk-actions-separator' />
        </>
      }
    </Container>
  </Popup>;
}

export default CommentActionPopup;
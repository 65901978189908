import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormLogoAction } from '../action';
import { Button, Segment } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../../common/icons/svg/svg-wrapper';
import { setOpenAdminChatboxAction } from '../../../../../../main/action';
import { getAdminHistoryLogsAction } from '../../../../../history/action';
import { IRootState } from '../../../../../../../reducers';
import { AdminHistoryCategory } from '../../../../../history/interface/history';

interface IBusinessProcessFilter {
  isOpenChatbox: boolean;
  allowedAccessAuditLogs: boolean;
}

const BusinessProcessFilter: React.FC<IBusinessProcessFilter> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyId = useSelector((state: IRootState) => state.auth.company);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleAdd = () => {
    history.push('/admin/business-process/form/add');
    dispatch(resetFormLogoAction());
  }

  const setIsOpenChatbox = () => {
    dispatch(setOpenAdminChatboxAction(true));
    dispatch(getAdminHistoryLogsAction({
      company: companyId,
      categories: [AdminHistoryCategory.BusinessProcess],
      tz
    }));
  }

  const onExportImportClick = (type: 'export' | 'import') => {
    history.push(`/admin/business-process/form/file/${type}`);
  }

  return (
    <Segment className='borderless filter-wrapper'>
      <div className='toolbar left'>
        <span className={`export btn`} title='Import BP' onClick={() => onExportImportClick('export')}>
          <SVGWrapper iconName='exportToFile' width='25' color='#3a85a8' />
          <span className='title'>Export BPs</span>
        </span>
        <span title='Export BP' className={`import btn`} onClick={() => onExportImportClick('import')} >
          <SVGWrapper iconName='importFromFile' width='21' color='#3a85a8' />
          <span className='title'>Import BPs</span>
        </span>
      </div>
      <div className='toolbar right'>
        {props.allowedAccessAuditLogs &&
          <Button className={`filter-users chat-icon ${!props.isOpenChatbox ? 'inactive' : ''}`} primary onClick={setIsOpenChatbox}>
            <SVGWrapper iconName='adminChatIcon' width='20' color={`${!props.isOpenChatbox ? '#3a85a8' : '#fff'}`} />
          </Button>
        }
        <Button onClick={handleAdd} floated='right' className={`add-button ${props.allowedAccessAuditLogs ? 'hasAuditLogAccess' : ''}`}>
          Add Business Process
        </Button>
      </div>
    </Segment>
  );
}

export default BusinessProcessFilter;
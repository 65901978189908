import { camelCase } from 'change-case';
import { ConfigObject } from './object';

export class ConfigService {

  static loadConfig = (): ConfigObject => {
    return {
      general: ConfigService.extractWithPrefix(process.env,
        'BPM_' + (process.env && process.env.BPM_BUILD_ENV ? `${process.env.BPM_BUILD_ENV.toUpperCase()}_` : '') + 'GENERAL_')
    }
  }

  static extractWithPrefix = (content: any, prefix: string) => {
    const result: any = {};
    Object.getOwnPropertyNames(content).forEach(prop => {
      if (prop.startsWith(prefix)) {
        const key = prop.substr(prefix.length);
        result[key] = content[prop];
      }
    });
    return ConfigService.changeCase(result);
  };

  static changeCase = (content: any) => {
    const result: any = {};
    Object.getOwnPropertyNames(content).forEach(prop => {
      result[camelCase(prop)] = content[prop];
    });
    return result;
  };
}

export default ConfigService;

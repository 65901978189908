import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { systemField } from '../../../../../../../../../../../../common/custom-field-config/filter-configuration/constant';
import { FieldValue } from '../../../../../../../../../../../../common/custom-field-config/filter-configuration/field-value';
import { OperatorOption } from '../../../../../../../../../../../../common/custom-field-config/filter-configuration/operator-option';
import { IConfig, ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IDropdownOption } from '../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { FieldConstruct } from '../../../../../../../../../../../../common/field/field-construct';
import { IOption } from '../../../../../../../../../../../../common/interface/option';
import { allowedFieldCompare, regularFieldOperator, statusFieldOperator } from '../../../../../../../../../../../../common/utils/field-operator';
import { IField } from '../../../../../../../../../users/interface/field';
import { IReference } from '../../../../../../../configuration/module/bp-relationship/interface/form';
import { FieldCondition } from '../../../utils/manage-options/condition/field-condition';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { useSetComponentToIssueFieldsState } from '../hooks/set-component-to-issue-fields-state';
import { useManageIssueFieldsConfig } from '../hooks/use-manage-issue-fields-config';
import { useSetIssueFieldsRefs } from '../hooks/use-set-issue-fields-refs';
import { IConditionTypeRegistryRender } from './registry';
import { statusInputField } from '../../../../constant';
import { IConfigName } from '../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { DetailActionButton } from '../../action-button';
import { IRootState } from '../../../../../../../../../../../../reducers';
import DropdownComponent from '../../../../../../../../../../../../common/general/dropdown';
import { FormEnumLabel } from '../../../../../../../../../../../document-module/module/form/utils/manage-enum-id-label';
import { IAutomationComponent } from '../../../../interface/component-list';
import { checkIfUUID } from '../../../../../../../../../../../../common/utils/check-uuid-value';

class IssueFieldCondition {

  name = 'field-condition';

  render: React.FC<IConditionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const dispatch = useDispatch();
    const match: { params: { formId: string } } = useRouteMatch();
    const defaultvalueRef = useRef<any>({ currentValue: {} });

    const { company } = useSelector((state: IRootState) => state.auth);
    const { referenceFieldList } = useSelector((state: IRootState) => state.filterConfiguration);
    let {
      formFieldList,
      referenceList,
      inputFieldList,
      datatableInputField
    } = useSelector((state: IRootState) => state.automation);
    const { formStatusList } = useSelector((state: IRootState) => state.workflow);
    const { userFieldListDynamic, userFieldListStatic } = useSelector((state: IRootState) => state.user);

    const [refresher, setRefresher] = useState(0);

    const {
      selectedField,
      setSelectedField,
      selectedOperator,
      setSelectedOperator,
      selectedReferenceField,
      setSelectedReferenceField,
      selectedSourceRefField,
      setSelectedSourceRefField,
      compareType,
      setCompareType,
      hideFieldOption,
      hideValueOption
    } = useSetComponentToIssueFieldsState({ selectedComponent: props.selectedComponent });

    const statusField = FieldCondition.setStatusFieldItems(systemField.filter(e => e.id === 'statusId'), formStatusList);
    formFieldList = formFieldList.concat(statusField);
    const {
      enumBpReference,
      fieldData,
      configData,
      selectedFieldConfig,
    } = useManageIssueFieldsConfig({
      referenceFieldList,
      formFieldList,
      formId: match.params.formId,
      selectedField
    });

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };

      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.sourceField = selectedField;
        tempComponent.config.compareType = compareType;
        tempComponent.config.refField = selectedReferenceField;
        tempComponent.config.operator = selectedOperator;
        tempComponent.config.sourceRefField = selectedSourceRefField;
        let tempValue = defaultvalueRef.current && defaultvalueRef.current.currentValue
          && defaultvalueRef.current.currentValue.value ? defaultvalueRef.current.currentValue.value : '';
        if (defaultvalueRef.current && defaultvalueRef.current.currentValue &&
          typeof defaultvalueRef.current.currentValue === 'string') {
          tempValue = defaultvalueRef.current.currentValue;
        }
        const dynamicRefs = { current: { currentValue: { value: tempValue } } }
        const currentSelectedField = getCurrentFieldElementConfig(selectedField);
        const tempCurrentField: any = currentSelectedField ? { ...currentSelectedField, id: 'currentValue' } : { id: '' };
        let currentData = FieldConstruct.getFieldDataFromRefs([tempCurrentField], dynamicRefs);

        if (tempCurrentField.inputConfig.type === 'enum') {
          currentData = FormEnumLabel.getEnumIdLabel(currentData, defaultvalueRef, [tempCurrentField]);
        }

        tempComponent.config.value = selectedField === 'statusId' &&
          (tempComponent.config.operator === 'includes' || tempComponent.config.operator === 'excludes') ?
          [currentData['currentValue']] : (currentData['currentValue'] || tempValue);

        if (typeof tempComponent.config.value === 'string' && (tempComponent.config.value.indexOf('[') && tempComponent.config.value.indexOf(']')) > - 1) {
          tempComponent.config.value = JSON.parse(tempComponent.config.value);
        }

        if (props.updateAutomationOnparent) {
          props.updateAutomationOnparent(tempComponent, props.childIndex);
        } else {
          props.updateCurrentAutomation(tempComponent, false);
        }
      }
    }

    const getOperatorOptionBasedOnFieldType = (): IDropdownOption[] => {
      const options: IDropdownOption[] = [];
      const field = formFieldList.find((e: ICustomFieldDetail) => e.id === selectedField);
      if (field) {
        let type = field.type || '';
        if (field.multiline || field.config?.multiselect) {
          type = 'multiple-enum'
        }
        if (type === 'autopopulated') {
          if (field.config && field.config.labelFormat) {
            const labelId = field.config.labelFormat.replace(/fields./g, '').replace(/{/g, '').replace(/}/g, '');
            if (referenceFieldList.length > 0) {
              const labelConfig = referenceFieldList.find(e => e.id === labelId);
              if (labelConfig) {
                type = labelConfig.type || '';
                if (labelConfig.multiline || (labelConfig.config && labelConfig.config.multiselect)) {
                  type = 'multiple-enum'
                }
              }
            } else {
              type = field.config.labelType
            }
          }
        }
        let operator = field.id === 'statusId' ? statusFieldOperator : (regularFieldOperator[type] || '');
        const operatorList = OperatorOption.getOption(type);
        operatorList.forEach((e: IOption) => {
          if (operator.indexOf(e.value) > -1) {
            const option: any = e;
            options.push(option);
          }
        })
      }
      return options;
    }

    const getFromFieldOption = (): IDropdownOption[] => {
      return formFieldList.map((field: ICustomFieldDetail) => {
        return {
          key: uuid(),
          value: field.id || '',
          text: field.label || ''
        }
      });
    }

    const getReferenceFieldOption = (): IDropdownOption[] => {
      const options: IDropdownOption[] = [];
      options.push({
        key: uuid(),
        value: 'current-form',
        text: 'Current Form'
      });
      formFieldList.forEach((field: ICustomFieldDetail) => {
        if (field.configName === IConfigName.Referenced) {
          options.push({
            key: uuid(),
            value: field.id || '',
            text: field.label || ''
          })
        }
      })
      return options;
    }

    const getRefFieldOption = (): IDropdownOption[] => {
      let bpReference = '';
      const fieldConfig = formFieldList.find((e: ICustomFieldDetail) => e.id === selectedSourceRefField);
      if (fieldConfig) {
        bpReference = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
      }
      if (selectedSourceRefField === 'current-form') {
        bpReference = match.params.formId;
      }
      let field: any = inputFieldList[bpReference] || [];
      const selectedRef = referenceList.find((ref: IReference) => ref.id === bpReference);
      if (selectedRef && selectedRef.dataType === 'database') {
        field = datatableInputField[bpReference] || [];
      }
      if (fieldConfig && fieldConfig.config && fieldConfig.config.dataType === 'user') {
        field = [...userFieldListDynamic, ...userFieldListStatic];
      }
      if (selectedFieldConfig && selectedFieldConfig.configName) {
        const allowedField = allowedFieldCompare[selectedFieldConfig.configName];
        const allowedFields = allowedField ? allowedField.split('|') : [];
        if (allowedFields && allowedFields.length > 0) {
          field = field.filter((e: IField) => e && e.configName && allowedFields.indexOf(e.configName) > -1);
        }
      }
      return field
        .filter((field: IField) => field.id !== selectedField)
        .map((field: IField) => {
          return {
            key: uuid(),
            value: field.id,
            text: field.label
          }
        })
    }

    const manageField = (value: string) => {
      defaultvalueRef.current.currentValue = '';
      setSelectedField(value);
      setSelectedOperator('');
      setCompareType('value');
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageOperator = (value: string) => {
      setSelectedOperator(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageCompareType = (value: string) => {
      setCompareType(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageSourceRefField = (value: string) => {
      setSelectedSourceRefField(value);
      getOption({
        ...props.selectedComponent,
        config: {
          ...props.selectedComponent.config,
          sourceRefField: value
        }
      });
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageRefField = (value: string) => {
      setSelectedReferenceField(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const getCurrentFieldElementConfig = (fieldId: string): IField | undefined => {
      const tempStatusField = FieldCondition.setStatusFieldItems(statusInputField, formStatusList);
      return inputFieldList[match.params.formId] ? inputFieldList[match.params.formId].concat(tempStatusField).find((e: IField) => e.id === fieldId) : undefined;
    }

    const manageValue = () => {
      if (props.hasComponentChange.current === false) {
        setRefresher(refresher + 1)
        props.parentRefresher();
      }
      props.hasComponentChange.current = true;
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    const getOption = (selectedComponent: IAutomationComponent) => {
      FieldCondition.getOptions(selectedComponent, {
        dispatch,
        referenceList,
        datatableInputField,
        company: company,
        inputFieldList,
        publishedFormStatusList: {},
        formFieldList
      })
    }

    const triggerOptionGet = () => {
      let trigger = false;
      if (checkIfUUID(selectedFieldConfig.id as string)) {
        if (selectedFieldConfig.configName === IConfigName.Referenced) {
          trigger = true;
        }
        if (selectedFieldConfig.configName === IConfigName.Autopopulated
          && selectedFieldConfig.config && selectedFieldConfig.config.labelType === 'enum') {
          trigger = true;
        }
      }
      return trigger;
    }

    useSetIssueFieldsRefs({
      defaultvalueRef,
      getCurrentFieldElementConfig,
      selectedComponent: props.selectedComponent
    });

    return (<>
      <div className={`automation-form-detail-condition`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Issue fields condition`}
          description={`Check whether an issue's field meets certain criteria`}
          iconHeight={30}
          iconWidth={30}
          iconName='fieldIssue'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field`}>
            <label>Field <span className={`required`}>*</span></label>
            <DropdownComponent
              fluid
              selection
              clearable
              placeholder={`Field`}
              search
              selectOnBlur={false}
              value={selectedField}
              options={getFromFieldOption()}
              onChange={(event, target: any) => { manageField(target.value) }}
            />
          </div>
        </div>
        <div className={`automation-field-container`}>
          <div className={`automation-field`}>
            <label>Condition <span className={`required`}>*</span></label>
            <DropdownComponent
              fluid
              selection
              clearable
              placeholder={`Condition`}
              search
              selectOnBlur={false}
              value={selectedOperator}
              options={getOperatorOptionBasedOnFieldType()}
              onChange={(event, target: any) => { manageOperator(target.value) }}
            />
          </div>
        </div>
        {
          fieldData && selectedOperator !== 'is-empty' && selectedOperator !== 'is-not-empty' &&
          <div className={`automation-field-container`}>
            <div className={`automation-field`}>
              <div className='selection-field-value'>
                {!hideValueOption &&
                  <label onClick={() => manageCompareType('value')} className={`${compareType === 'value' ? 'selected' : ''}`}>Value</label>
                }

                {!hideFieldOption && selectedField !== 'statusId' &&
                  <label style={{ marginLeft: '10px' }} onClick={() => manageCompareType('field')} className={`${compareType === 'field' ? 'selected' : ''}`}>Field</label>
                }
              </div>
              {compareType === 'value' &&
                < div className={`condition-value`}>
                  <FieldValue.Render
                    field={selectedFieldConfig}
                    forwardedRef={defaultvalueRef}
                    dispatch={dispatch}
                    referenceBp={enumBpReference}
                    companyId={company}
                    currentFormField={{
                      ...fieldData,
                      config: {
                        dataType: configData.dataType || '',
                      } as IConfig
                    }}
                    valueId={'currentValue'}
                    throwValueOutside={() => manageValue()}
                    fromPermission={triggerOptionGet()}
                    currentFieldList={formFieldList}
                  />
                </div>
              }
              {compareType === 'field' &&
                <>
                  <div className={`automation-field-container two`}>
                    <div className={`automation-field`}>
                      <label>Source Reference <span className={`required`}>*</span></label>
                      <DropdownComponent
                        fluid
                        selection
                        clearable
                        search
                        selectOnBlur={false}
                        value={selectedSourceRefField}
                        options={getReferenceFieldOption()}
                        onChange={(event, target: any) => { manageSourceRefField(target.value) }}
                      />
                    </div>
                    <div className={`automation-field`}>
                      <label>Selected bp field <span className={`required`}>*</span></label>
                      <DropdownComponent
                        fluid
                        selection
                        clearable
                        search
                        selectOnBlur={false}
                        value={selectedReferenceField}
                        options={getRefFieldOption()}
                        onChange={(event, target: any) => { manageRefField(target.value) }}
                      />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        }
      </div >
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}

export default IssueFieldCondition;
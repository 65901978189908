import { useEffect, useState } from 'react';
import { IOption } from '../../../../../../common/interface/option';
import { defaultDatabasePermisionsOption } from '../../constant';

interface IProps {
  dataTablePermissionOption: IOption[];
}

export const useSetDatatablePermissionOption = (props: IProps) => {
  const [permissionOption, setPermisionOption] = useState([] as IOption[]);

  useEffect(() => {
    let ctr = 1;
    let tempPermissionOption = [...defaultDatabasePermisionsOption] as IOption[];
    props.dataTablePermissionOption.forEach((permissionOption: IOption) => {
      tempPermissionOption.push({ key: ctr++, text: permissionOption.text, value: permissionOption.value })
    });
    setPermisionOption(tempPermissionOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.dataTablePermissionOption)]);

  return { permissionOption, setPermisionOption, ...props };
}
export interface IDatatableConfig {
  uniqueConstraints: IDatatableConfigUniqueConstraint[];
}

export interface IDatatableConfigUniqueConstraint {
  name: string,
  fields: string[]
}

export interface IDatatableBatchChanges {
  addedRecords: string[];
  updatedRecords: string[];
  deletedRecords: string[];
}

export enum FieldTableEntityType {
  Document = 'document',
  Dt = 'dt',
  User = 'user'
}

export interface IDatatableRecordsGroupedConfig {
  id?: string;
  entityType: FieldTableEntityType;
  entityConfigId?: string;
  keyPaths: string[];
}

export interface IDTRecordGroupedKeypath {
  name: string;
  keyPath: string;
}

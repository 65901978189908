import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import { IActionTypeRegistryRender } from '../registry';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { Dropdown } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import SelectedComputeField from './selected';
import { useSetSelectedComputeFields } from './hooks/use-set-selected-compute-fields';
import { DetailActionButton } from '../../../action-button';

export interface IComputeField {
  id: string;
  selectedField: string
  selectedOperator: string;
  value: string;
  new?: boolean;
  deleted?: boolean;
}

class ComputeField {

  name = 'compute-field';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const { formFieldList } = useSelector((state: IRootState) => state.automation);

    const { selectedComputeFields, setSelectedComputeFields } = useSetSelectedComputeFields({
      selectedComponent: props.selectedComponent
    });

    const getFromFieldOption = (): IDropdownOption[] => {
      return formFieldList
        .filter((field: ICustomFieldDetail) => field.type === 'number' && !selectedComputeFields.find(e => e.selectedField === field.id))
        .map((field: ICustomFieldDetail) => {
          return {
            key: uuid(),
            value: field.id || '',
            text: field.label || ''
          }
        });
    }

    const saveClick = () => {
      let tempSelected = [...selectedComputeFields];
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        const fields = tempSelected.map(selected => {
          return {
            fieldId: selected.selectedField,
            operator: selected.selectedOperator,
            value: parseFloat(selected.value)
          }
        });
        tempComponent.config = {
          ...tempComponent.config,
          fields: fields
        };
        props.updateCurrentAutomation(tempComponent, true);
      }
    }

    const addComputeField = (selected: string, option: IDropdownOption[]) => {
      let tempSelected = [...selectedComputeFields];
      const selectedOption = option.filter(e => e.value === selected);
      const exists = tempSelected.find(e => e.selectedField === selected);
      if (!exists) {
        tempSelected.push({
          id: uuid(),
          selectedField: selectedOption[0].value,
          selectedOperator: '',
          value: '',
          new: true
        });
      }
      setSelectedComputeFields(tempSelected);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const updateComputeField = (id: string, type: 'selectedOperator' | 'value', value: string) => {
      let tempSelected = [...selectedComputeFields];
      tempSelected = tempSelected.map(computeField => {
        if (computeField.id === id) {
          computeField[type] = value;
        }
        return computeField;
      });
      setSelectedComputeFields(tempSelected);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const deleteComputeField = (id: string) => {
      let tempSelected = [...selectedComputeFields];
      const index = tempSelected.findIndex(e => e.id === id);
      tempSelected.splice(index, 1);
      setSelectedComputeFields(tempSelected);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    return (<>
      <div className={`automation-form-detail-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Compute Fields`}
          description={`Set values for fields on the issue. Simply add the fields you want to edit.`}
          iconHeight={20}
          iconWidth={18}
          iconName='computeField'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field compute-field`}>
            <Dropdown
              popperContainer={DropdownPopperOption}
              className='field-option'
              fluid
              selection
              clearable
              search
              multiple={false}
              value=''
              selectOnBlur={false}
              placeholder='Choose fields to set'
              options={getFromFieldOption()}
              onChange={(event, target: any) => { addComputeField(target.value, target.options) }}
            />
          </div>
        </div>
        {selectedComputeFields.length > 0 && selectedComputeFields.filter(e => !e.deleted)
          .map(computeFields => {
            return <SelectedComputeField
              key={computeFields.id}
              computeFields={computeFields}
              updateComputeField={updateComputeField}
              deleteComputeField={deleteComputeField}
              formFieldList={formFieldList}
            />
          })}

      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    )
  }
}

export default ComputeField
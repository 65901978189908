import React from 'react';
import { IDaysCheckbox } from '../interface/days-checkbox';
import ReactDatePicker from 'react-datepicker';
import { IWorkingHours } from '../interface/status-task-list';
import SwitchEnableDraft from '../list/switch-draft';
import { useSetSLAStartAndEndTime } from '../hooks/use-set-sla-start-and-end-time';

interface ICheckbox {
  id: number,
  value: string,
  label: string,
  isChecked: boolean
}

interface ITaskManager {
  selectedDays: IDaysCheckbox[];
  workingHours: IWorkingHours;
  isAllDaysChecked: boolean;
  isTaskAssigned: boolean;
  isProcessLaunch: boolean;
  is24Hour: boolean;
  setIs24Hour: any;
  includeDraft: boolean;
  onAllDayChange(event: any): void;
  onDaysChange(event: any): void;
  updateSelectedSLAs(type: string, value: boolean): void;
  onWorkingTimeChange(time: any, name: string): void;
  onIncludeDraftChange(value: boolean): void;
}

const Checkbox = ({ type = 'checkbox', value, checked, onClick, label }: any) => {
  return <div className="ui checkbox" onClick={onClick}>
    <input type={type} value={value || ''} checked={!!checked} onChange={() => { }} />
    <label>{label}</label>
  </div>
}

const CustomInput = React.forwardRef((props: any, ref: any) => {
  return (
    <button className={`ui icon right labeled button ${props.disabled ? 'disabled' : ''}`} onClick={props.onClick} ref={ref}>
      {props.value || props.placeholder}
      <i aria-hidden="true" className="clock outline icon"></i>
    </button>
  )
})

const TaskManager: React.FC<ITaskManager> = (props) => {
  const {
    startTime,
    endTime
  } = useSetSLAStartAndEndTime({
    workingHours: props.workingHours
  });

  return <div className='slas-task-manager-container'>
    <div className='task-assigned' onClick={() => {
      props.updateSelectedSLAs('task', props.isTaskAssigned);
    }}>
      <span>Task Assigned</span>
      <div className="ui fitted checkbox">
        <input type="checkbox"
          className="hidden"
          onChange={() => { }}
          checked={!!props.isTaskAssigned}
        />
        <label></label>
      </div>
    </div>
    <div className='process-launch' onClick={() => {
      props.updateSelectedSLAs('process', props.isProcessLaunch);
    }}>
      <span>Process Launch</span>
      <div className="ui fitted checkbox">
        <input type="checkbox"
          className="hidden"
          onChange={(e) => { }}
          checked={!!props.isProcessLaunch}
        />
        <label></label>
      </div>
    </div>
    <SwitchEnableDraft
      onIncludeDraftChange={props.onIncludeDraftChange}
      includeDraft={props.includeDraft}
    />
    {false && <> <span>or</span>
      <div className='input-date'>
        <div className='ui input'>
          <input type='text' placeholder='Input Date' />
        </div>
        <div className="ui fitted checkbox">
          <input type="checkbox"
            className="hidden"
            onChange={() => { }}
            checked={true}
          />
          <label></label>
        </div>
      </div>
    </>}
    <div className='label-days'>Count only the following days:</div>
    <div className='days-list-wrapper'>
      <div className='days-column-1'>
        <div className="ui checkbox" onClick={(event) => { props.onAllDayChange(event) }}>
          <input type="checkbox"
            onChange={() => { }}
            checked={props.isAllDaysChecked}
          />
          <label>All Days</label>
        </div>
        {
          props.selectedDays.map((days: ICheckbox) => {
            if (days.id < 5) {
              return <Checkbox
                key={days.id}
                value={days.value}
                label={days.label}
                checked={days.isChecked}
                onClick={props.onDaysChange}
              />
            }
            return undefined
          })
        }
      </div>
      <div className='days-column-2'>
        {
          props.selectedDays.map((days: ICheckbox) => {
            if (days.id > 4) {
              return <Checkbox
                key={days.id}
                value={days.value}
                label={days.label}
                checked={days.isChecked}
                onClick={props.onDaysChange}
              />
            }
            return undefined
          })
        }
      </div>
    </div>
    <div className='working-hours-container'>
      <div className='label-hours'>Count only the following hours:</div>
      <div className="ui checkbox" onClick={() => props.setIs24Hour(!props.is24Hour)}>
        <input type='checkbox' checked={props.is24Hour} onChange={() => { }} />
        <label>24 hours</label>
      </div>
      <div className='time-widget-wrapper'>
        <div className='date-picker-container'>
          <label>From</label>
          <ReactDatePicker
            onChange={time => {
              props.onWorkingTimeChange(time, 'startTime')
            }}
            selected={startTime}
            customInput={<CustomInput />}
            showTimeSelect
            showTimeSelectOnly
            dateFormat='h:mm a'
            timeIntervals={60}
            disabled={props.is24Hour}
            placeholderText='Click to select'
          />
        </div>
        <div className='date-picker-container'>
          <label>To</label>
          <ReactDatePicker
            onChange={time => {
              props.onWorkingTimeChange(time, 'endTime')
            }}
            selected={endTime}
            customInput={<CustomInput />}
            showTimeSelect
            showTimeSelectOnly
            dateFormat='h:mm a'
            disabled={props.is24Hour}
            timeIntervals={60}
            placeholderText='Click to select'
          />
        </div>
      </div>
    </div>
  </div >
}

export default TaskManager;
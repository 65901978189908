import {
  SAVE_DRAFT_BP_RELATIONSHIP,
  SAVE_DRAFT_BP_RELATIONSHIP_RETURN,
  SAVE_DRAFT_BP_RELATIONSHIP_ERROR,
  PUBLISH_BP_RELATIONSHIP,
  PUBLISH_BP_RELATIONSHIP_RETURN,
  PUBLISH_BP_RELATIONSHIP_ERROR,
  GET_BPR_LIST,
  GET_BPR_LIST_RETURN,
  GET_BPR_LIST_ERROR,
  GET_BPR_FORM_FIELDS,
  GET_BPR_FORM_FIELDS_RETURN,
  GET_BPR_FORM_FIELDS_ERROR,
  GET_BPR_FORM_FIELDS_SANITIZE_RETURN
} from './constant';
import { IBPFormFieldsDetailInput } from './interface/input/bp-form-fields.get';
import { IBPRelationshipDetailInput } from './interface/input/bp-relationship-get';
import { IBPRelationshipDetailsInput } from './interface/input/bp-relationship-save';
import { IFormGetListDetailInput } from './interface/input/form-get';
import { IFormRelatedSanitizeGetResponse } from './interface/response/bp-relationship-get';
import { IBPRelationshipSaveResponse } from './interface/response/bp-relationship-save';
import { IFormGetResponse } from './interface/response/form-get';


export const getBPRListAction = (payload: IFormGetListDetailInput) => {
  return {
    type: GET_BPR_LIST,
    ...payload
  }
}

export const getBPRListReturnAction = (payload: IFormGetResponse) => {
  return {
    type: GET_BPR_LIST_RETURN,
    ...payload
  }
}

export const getBPRListErrorAction = () => {
  return { type: GET_BPR_LIST_ERROR }
}

export const getBPRFormFieldsAction = (payload: IBPFormFieldsDetailInput) => {
  return {
    type: GET_BPR_FORM_FIELDS,
    ...payload
  }
}

export const getBPRFormFieldsReturnAction = (payload: IFormGetResponse) => {
  return {
    type: GET_BPR_FORM_FIELDS_RETURN,
    ...payload
  }
}

export const getBPRFormFieldsSanitizeReturnAction = (payload: IFormRelatedSanitizeGetResponse) => {
  return {
    type: GET_BPR_FORM_FIELDS_SANITIZE_RETURN,
    ...payload
  }
}

export const getBPRFormFieldsErrorAction = () => {
  return { type: GET_BPR_FORM_FIELDS_ERROR }
}

export const saveDraftBPRelationshipAction = (payload: IBPRelationshipDetailsInput) => {
  return {
    type: SAVE_DRAFT_BP_RELATIONSHIP,
    ...payload
  }
}

export const saveDraftBPRelationshipReturnAction = (payload: IBPRelationshipSaveResponse) => {
  return {
    type: SAVE_DRAFT_BP_RELATIONSHIP_RETURN,
    ...payload
  }
}

export const saveDraftBPRelationshipErrorAction = (message: string) => {
  return {
    type: SAVE_DRAFT_BP_RELATIONSHIP_ERROR,
    saveBPRelationshipErrorMessage: message
  }
}

export const publishBPRelationshipAction = (payload: IBPRelationshipDetailInput) => {
  return {
    type: PUBLISH_BP_RELATIONSHIP,
    ...payload
  }
}

export const publishBPRelationshipReturnAction = () => {
  return {
    type: PUBLISH_BP_RELATIONSHIP_RETURN
  }
}

export const publishBPRelationshipErrorAction = (message: string) => {
  return {
    type: PUBLISH_BP_RELATIONSHIP_ERROR,
    saveBPRelationshipErrorMessage: message
  }
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToolTip from '../../../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../../../common/icons';
import { IRootState } from '../../../../../../../../../reducers';
import { IApiConfiguration } from '../interface/api-configuration';
import { Image } from 'semantic-ui-react-bpm';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { publishApiConfigAction, saveDraftApiConfigAction, updateLocalApiListAction } from '../action';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import ButtonContainer from '../../../../../button-container';
import ActionMessage from '../../../../../../../../../common/general/action-message';
import { apiConfigActionMessage } from '../constant';
import { v4 as uuid } from 'uuid';

interface IAPIsListProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const APIsList: React.FC<IAPIsListProps> = (props) => {

  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { company } = useSelector((state: IRootState) => state.auth);
  const { apiConfigurationList, apiConfigSaveType } = useSelector((state: IRootState) => state.configurationAPIs);
  const [forDeletionId, setForDeleteionId] = useState<undefined | number>(undefined);
  const [forDeletionName, setForDeleteionName] = useState('');

  const consfirmDeleteRecord = () => {
    let tempApiList = [...apiConfigurationList.map((e: IApiConfiguration) => { const f = { ...e }; return f; })];
    const forDeletionAPI = apiConfigurationList.filter((e: IApiConfiguration) => e.seqNumber === forDeletionId);
    if (forDeletionAPI.length > 0) {
      const forDeletionIndex = apiConfigurationList.indexOf(forDeletionAPI[0]);
      tempApiList.splice(forDeletionIndex, 1);
      tempApiList = tempApiList.map((e: IApiConfiguration, index: number) => {
        const f = { ...e };
        f.seqNumber = index + 1;
        return f;
      })
      dispatch(updateLocalApiListAction(tempApiList));
    }
    setForDeleteionId(undefined);
    setForDeleteionName('');
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteClick = (id: number, name: string) => {
    setForDeleteionId(id);
    setForDeleteionName(name);
  }

  const cancelDelete = () => {
    setForDeleteionId(undefined);
    setForDeleteionName('');
  }

  const manageActiveStatus = (id: number, status: boolean) => {
    const tempApiList = [...apiConfigurationList.map((e: IApiConfiguration) => { const f = { ...e }; return f; })];
    const forUpdateAPI = apiConfigurationList.filter((e: IApiConfiguration) => e.seqNumber === id);
    if (forUpdateAPI.length > 0) {
      const updateApiConfig = { ...forUpdateAPI[0] };
      updateApiConfig.isActive = !status;
      const forUpdateIndex = apiConfigurationList.indexOf(forUpdateAPI[0]);
      tempApiList.splice(forUpdateIndex, 1, updateApiConfig);
      dispatch(updateLocalApiListAction(tempApiList));
    }
  }

  const saveDraft = (saveType: string) => {
    dispatch(saveDraftApiConfigAction({
      company: company,
      formId: match.params.formId,
      saveType: saveType,
      apiConfigurationList: apiConfigurationList
    }
    ))
    props.hasPageChangeRef.current.hasChange = false;
  }

  useEffect(() => {
    if (apiConfigSaveType === 'publish') {
      dispatch(publishApiConfigAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiConfigSaveType])

  return <>
    <div className={`apis-list-container table-wrapper`}>
      <ActionMessage messages={apiConfigActionMessage} />
      <table className='table-container'>
        <thead>
          <tr>
            <th className='th-group'>API ID</th>
            <th className='th-group'>API Name</th>
            <th className='th-group'>Method</th>
            <th className='th-group'>Active</th>
            <th className='th-group'>Description</th>
            <th className='th-group'></th>
          </tr>
        </thead>
        <tbody>
          {
            apiConfigurationList.map((api: IApiConfiguration, index: number) => {
              return <tr key={uuid()}>
                <td>{index + 1}</td>
                <td><Link to={`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}/${api.seqNumber}`}>{api.name}</Link></td>
                <td>{api.method}</td>
                <td>
                  <div className="ui read-only toggle checkbox checked table-checkbox-toggle">
                    <input style={{ cursor: 'default' }} type="checkbox" checked={api.isActive} onClick={() => manageActiveStatus(api.seqNumber, api.isActive)} />
                    <label></label>
                  </div>
                </td>
                <td>{api.description}</td>
                <td>
                  <ToolTip position='right center'
                    popupMessage={'Delete'}
                    popupTriggerComponent={
                      <Image src={icons.blue.delete}
                        style={{
                          width: '12px',
                          cursor: 'pointer',
                          margin: '0px 5px',
                          display: 'inline-block'
                        }} onClick={() => deleteClick(api.seqNumber, api.name)} name='trash alternate outline' />
                    }
                  />
                </td>
              </tr>
            })
          }
        </tbody>
      </table>

      <ConfirmationMessage
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => cancelDelete()}
        open={forDeletionId !== undefined}
        confirm={consfirmDeleteRecord}
        cancel={() => cancelDelete()}
        headerText="Confirmation"
        detailText={`The api '${forDeletionName}' will be deleted. Do you still want to proceed?`}
        loading={false}
        type='warning'
      />
    </div >
    <ButtonContainer
      cancel={() => { history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}`); }}
      save={() => saveDraft('draft')}
      publish={() => saveDraft('publish')}
    />
  </>
}

export default APIsList;
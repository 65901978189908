import React, { useRef } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';
import AttributesTable from './data-table-body';
import { useSelector, useDispatch } from 'react-redux';
import { createDataTableAction, updateDataTableAction, deleteDataTableFieldsAction, setRecordsGroupedConfigAction, deleteRecordsGroupedConfigAction } from '../action';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IDatatableRecord } from '../../../../../common/custom-field-config/interface/datatable-record';
import { ICustomFieldDetail } from '../../../../../common/custom-field-config/interface/custom-field-detail';
import ActionMessage from '../../../../../common/general/action-message';
import { dataTableFormActionMessage } from '../constant';
import changeRoute from '../../../../../common/utils/change-menu';
import { IRootState } from '../../../../../reducers';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { IDataTable } from '../reducer';
import { IDatatableConfig, IDatatableRecordsGroupedConfig } from '../interface/data-table-config';
import { useSetThirdLevelRoute } from '../../../hooks/use-set-third-level-route';
import { useGetDatatableFormData } from './hooks/use-get-datatable-form-data';
import { useRedirectFormToList } from './hooks/use-redirect-form-to-list';
import { useSetDatatableDetailsToRefs } from './hooks/use-set-datatable-details-to-refs';
import uuid from 'uuid';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchParams;
}

interface IRouteMatchParams {
  dataTableId: string;
}

interface IDatatableForm {
  dataTable: IDatatableRecord[];
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const DataTableForm: React.FC<IDatatableForm> = (props) => {
  let match: IRouteMatch = useRouteMatch();
  const columnsRef: any = useRef({});
  const dispatch = useDispatch();
  const history = useHistory();

  const inputElement = document.createElement('input');
  const dataTableNameRef = useRef(inputElement);
  const companyId = useSelector((state: IRootState) => state.auth.company);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const {
    createDataTableMessage,
    updateDataTableMessage,
    forUpdateDataTableDetails,
    forUpdateDataTablePermission,
    forUpdateDataTablePermissionOption,
    hasDatatableRecords,
    dataTableLoading,
    forUpdateDataTableDetailsLoading,
    updateDataTableLoading,
    datatableRecordsGroupedConfig,
    groupedRecordsKeypath
  } = useSelector((state: IRootState) => state.dataTable);

  const onClickSave = (
    dataTableFields: ICustomFieldDetail[],
    permission: { view: string[], modify: string[] },
    config: IDatatableConfig,
    groupingConfig?: IDatatableRecordsGroupedConfig,
  ) => {
    const deletedFields = dataTableFields.filter((e: ICustomFieldDetail) => e.deleted === true);
    const deletedFieldsRemoveNew = deletedFields.filter((e: ICustomFieldDetail) => !e.new);
    const fields = dataTableFields.filter((e: ICustomFieldDetail) => !e.deleted);
    const id = match.params.dataTableId === 'add-data-table' ? uuid() : match.params.dataTableId;

    let dataForSubmit: { company: string, data: IDataTable } = {
      company: companyId,
      data: {
        id: id,
        name: dataTableNameRef.current.value,
        fields: fields,
        permissionRules: {
          view: parseDTPermission(permission.view),
          modify: parseDTPermission(permission.modify),
        },
        config
      },
    }
    if (match.params.dataTableId !== 'add-data-table') {
      let datatableId = match.params.dataTableId;
      dispatch(updateDataTableAction({ ...dataForSubmit, dataTableId: datatableId }));
      deletedFieldsRemoveNew.forEach((field) => {
        if (field.id) {
          dispatch(deleteDataTableFieldsAction({
            company: companyId,
            dataTableId: match.params.dataTableId,
            fieldId: field.id
          }));
        }
      });
    } else {
      dispatch(createDataTableAction(dataForSubmit));
    }
    if (groupingConfig) {
      if (groupingConfig.keyPaths.length) {
        dispatch(setRecordsGroupedConfigAction({
          company: companyId,
          data: groupingConfig
        }));
      }
      else if (groupingConfig.id) {
        dispatch(deleteRecordsGroupedConfigAction({ id: groupingConfig.id, company: companyId } ));
      }
    }
  
    props.hasPageChangeRef.current.hasChange = false;
  }

  const parseDTPermission = (permission: string[]) => {
    const permissionOption = forUpdateDataTablePermissionOption.map(e => e.value);
    return permission.filter(data => {
      if (data.indexOf('user') > -1 || data.indexOf('group') > -1) {
        return permissionOption.includes(data);
      }
      return true;
    });
  }

  const onClickCancel = () => {
    changeRoute('/admin/data-tables', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  useRedirectFormToList({
    history,
    updateDataTableMessage,
    createDataTableMessage
  });

  useSetThirdLevelRoute({
    dispatch,
    mainRoute: 'admin',
    subRoute: 'data-tables',
    thirdLevelRoute: 'data-table-subroute',
    currentRoute: 'data-table-form'
  });

  useGetDatatableFormData({
    dispatch,
    companyId,
    dataTableId: match.params.dataTableId,
  });

  useSetDatatableDetailsToRefs({
    dataTableNameRef,
    forUpdateDataTableDetails,
    dataTableId: match.params.dataTableId
  });

  return (<>
    <ActionMessage messages={dataTableFormActionMessage} />
    <div className='data-table-form-container'>
      {
        (dataTableLoading || forUpdateDataTableDetailsLoading || updateDataTableLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <AttributesTable
        forwardedRef={columnsRef}
        datatable={props.dataTable}
        forUpdateDataTableDetails={forUpdateDataTableDetails}
        forUpdateDataTableFields={forUpdateDataTableDetails.fields}
        datatablePermission={forUpdateDataTablePermission}
        dataTablePermissionOption={forUpdateDataTablePermissionOption}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
        hasDatatableRecords={hasDatatableRecords}
        hasPageChangeRef={props.hasPageChangeRef}
        dataTableNameRef={dataTableNameRef}
        datatableRecordsGroupedConfig={datatableRecordsGroupedConfig}
        groupedRecordsKeypath={groupedRecordsKeypath}
      />
    </div >
  </>)
}

export default React.memo(DataTableForm);
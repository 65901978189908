import React from 'react';
import { v4 as uuid } from 'uuid';
import { newComponentIcon } from '../../../../constant';
import { IAutomationChildComponent, IAutomationComponent } from '../../../../interface/component-list';
import { IOptionWidget } from '../../../../interface/option-widget';
import { AtomationDetailComponentRegistry } from '../../component-registry';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { useSetIfElseBlockCondition } from '../hooks/use-set-if-else-blobk-condition';
import { useSetIfElseBlockTitle } from '../hooks/use-set-if-else-blobk-title';
import { useSetIfElseBlockType } from '../hooks/use-set-if-else-blobk-type';
import { IConditionTypeRegistryRender } from './registry';

class IfElseBlock {

  name = 'if-else-block';

  render: React.FC<IConditionTypeRegistryRender> = (props) => {

    const { type } = useSetIfElseBlockType({
      selectedComponent: props.selectedComponent,
      selectedChildComponent: props.selectedChildComponent
    });
    const { title } = useSetIfElseBlockTitle({
      selectedComponent: props.selectedComponent,
      selectedChildComponent: props.selectedChildComponent
    });
    const { conditionList, setConditionList } = useSetIfElseBlockCondition({
      selectedComponent: props.selectedComponent,
      selectedChildComponent: props.selectedChildComponent
    });

    const saveWhenComponentHasChange = () => {
      const selectedComponent: any = { ...props.selectedChildComponent };
      if (selectedComponent && selectedComponent.component && props.hasComponentChange.current === true) {
        const btnSaveCurrComponent = document.getElementById(selectedComponent.component.id);
        if (btnSaveCurrComponent) {
          btnSaveCurrComponent.click();
        }
      }
    }

    const addCondition = () => {
      saveWhenComponentHasChange();
      const tempCondition = [...conditionList.map((condition: IAutomationComponent) => { return { ...condition } })]
      const currentId = uuid();

      const condition = newComponentIcon.find((option: IOptionWidget) => option.type === 'condition');
      if (condition) {
        tempCondition.push({
          id: currentId,
          type: condition.type,
          name: condition.title,
          config: {},
        })
      }
      setConditionList(tempCondition);
      let type = 'if';
      let elseRowIndex = undefined;
      if (props.selectedComponent && !props.selectedChildComponent) {
        const tempComponent = { ...props.selectedComponent };
        if (tempComponent) {
          props.setSelectedComponent(props.selectedComponent, {
            type,
            rowIndex: tempCondition.length - 1,
            component: tempCondition[tempCondition.length - 1]
          } as IAutomationChildComponent, true);

          tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
          tempComponent.config.conditionList = tempCondition
          props.updateCurrentAutomation(tempComponent, false);
        }
      }
      if (props.selectedComponent && props.selectedChildComponent) {
        type = 'else';
        elseRowIndex = props.selectedChildComponent.elseRowIndex;

        props.setSelectedComponent(props.selectedComponent, {
          type,
          elseRowIndex: elseRowIndex,
          rowIndex: tempCondition.length - 1,
          component: tempCondition[tempCondition.length - 1]
        } as IAutomationChildComponent)

        const tempComponent = { ...props.selectedComponent };
        if (tempComponent) {
          tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
          tempComponent.config.elseConditionList[elseRowIndex].conditionList = tempCondition
          props.updateCurrentAutomation(tempComponent, false);
        }
      }
    }

    const updateAutomationConditionOnparent = (component: IAutomationComponent) => {
      const tempComponent = { ...props.selectedComponent };
      const tempSelectedChildComponent = props.selectedChildComponent ? { ...props.selectedChildComponent } : undefined;
      const elseRowIndex = tempSelectedChildComponent?.elseRowIndex;
      const rowIndex = tempSelectedChildComponent?.rowIndex;
      const type = tempSelectedChildComponent?.type;

      tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};

      if (type === 'if' && rowIndex !== undefined) {
        if (!tempComponent.config.conditionList) {
          tempComponent.config.conditionList = [];
        }
        tempComponent.config.conditionList[rowIndex] = component;
        console.log('tempComponent: ', tempComponent);
        props.updateCurrentAutomation(tempComponent, false);
      }

      if (type === 'else' && elseRowIndex !== undefined && rowIndex !== undefined) {
        if (!tempComponent.config.elseConditionList) {
          tempComponent.config.elseConditionList = [];
        }
        tempComponent.config.elseConditionList[elseRowIndex].conditionList[rowIndex] = component;
        console.log('tempComponent: ', tempComponent);
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const updateAutomationActionOnparent = (component: IAutomationComponent) => {
      const tempComponent = { ...props.selectedComponent };
      const tempSelectedChildComponent = props.selectedChildComponent ? { ...props.selectedChildComponent } : undefined;
      const elseRowIndex = tempSelectedChildComponent?.elseRowIndex;
      const rowIndex = tempSelectedChildComponent?.rowIndex;
      const type = tempSelectedChildComponent?.type;
      if (type === 'if' && rowIndex !== undefined) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.actionList[rowIndex] = component;
        props.updateCurrentAutomation(tempComponent, false);
      }
      if (type === 'else' && elseRowIndex !== undefined && rowIndex !== undefined) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.elseConditionList[elseRowIndex].actionList[rowIndex] = component;
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    return (
      <div>
        {type === 'condition' && !props.selectedChildComponent?.component &&
          <div className={`automation-form-detail-condition`}>
            <AutomationDetailHeader
              title={title}
              description={`The if block executes the action that block when all the specified condition matches. Otherwise, the following blocks will be evaluated.`}
              iconHeight={25}
              iconWidth={17}
              iconName='flowchart'
            />

            {
              conditionList.map((condition: IAutomationComponent, index: number) => {
                return <div className={`child-automation-container`} key={uuid()}>
                  <div onClick={() => {
                    props.setSelectedComponent(props.selectedComponent, {
                      type: 'else',
                      elseRowIndex: props.selectedChildComponent?.elseRowIndex,
                      rowIndex: index,
                      component: condition
                    } as IAutomationChildComponent)
                  }}
                    className='child-title'>{condition.config?.condition?.title || 'New Condition'}</div>
                </div>
              })
            }

            <p className={`add-condition`} onClick={() => addCondition()}>+ Add condition</p>
          </div>
        }

        {type === 'condition' && props.selectedChildComponent?.component &&
          <AtomationDetailComponentRegistry.Render
            type={props.selectedChildComponent.component.type}
            updateAutomationDetails={() => { }}
            currentAutomationRef={props.currentAutomationRef}
            selectedComponent={props.selectedChildComponent.component}
            updateCurrentAutomation={updateAutomationConditionOnparent}
            businessRuleId={''}
            updateAutomationOnparent={updateAutomationConditionOnparent}
            refsObject={props.refsObject}
            isHasParent={true}
            setSelectedComponent={props.setSelectedComponent}
            hasComponentChange={props.hasComponentChange}
            parentRefresher={props.parentRefresher}
            selectedChildComponent={props.selectedChildComponent}
            selectedParentComponent={props.selectedComponent}
          />
        }

        {type === 'action' && props.selectedChildComponent?.component &&
          <AtomationDetailComponentRegistry.Render
            type={props.selectedChildComponent.component.type}
            updateAutomationDetails={() => { }}
            currentAutomationRef={props.currentAutomationRef}
            selectedComponent={props.selectedChildComponent.component}
            updateCurrentAutomation={updateAutomationActionOnparent}
            businessRuleId={''}
            updateAutomationOnparent={updateAutomationActionOnparent}
            refsObject={props.refsObject}
            isHasParent={true}
            setSelectedComponent={props.setSelectedComponent}
            hasComponentChange={props.hasComponentChange}
            parentRefresher={props.parentRefresher}
            selectedChildComponent={props.selectedChildComponent}
            selectedParentComponent={props.selectedComponent}
          />
        }

      </div>
    );
  }
}

export default IfElseBlock;
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';
import { IOption } from '../../../../../../../../common/interface/option';

export const SAVE_FORM_LABELS_ERROR = 'app/admin/bp/configuration/labels/SAVE_FORM_LABELS_ERROR';
export const GET_FORM_LABELS = 'app/admin/bp/configuration/labels/GET_FORM_LABELS';
export const GET_FORM_LABELS_RETURN = 'app/admin/bp/configuration/labels/GET_FORM_LABELS_RETURN';
export const GET_FORM_LABELS_ERROR = 'app/admin/bp/configuration/labels/GET_FORM_LABELS_ERROR';
export const CLEAR_SAVE_FORM_LABELS_ERROR = 'app/admin/bp/configuration/labels/SAVE_FORM_LABELS_ERROR';
export const CLEAR_SAVE_FORM_LABELS_SUCCESS = 'app/admin/bp/configuration/labels/CLEAR_SAVE_FORM_LABELS_SUCCESS';
export const SET_SAVE_TYPE = 'app/admin/bp/configuration/labels/SET_SAVE_TYPE';
export const SET_SAVE_MESSAGE = 'app/admin/bp/configuration/labels/SET_SAVE_MESSAGE';
export const PUBLISH_FORM_LABELS = 'app/admin/bp/configuration/PUBLISH_FORM_LABELS';
export const PUBLISH_FORM_LABELS_RETURN = 'app/admin/bp/configuration/PUBLISH_FORM_LABELS_RETURN';
export const PUBLISH_FORM_LABELS_ERROR = 'app/admin/bp/configuration/PUBLISH_FORM_LABELS_ERROR';

export const formLabelsActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_FORM_LABELS_ERROR,
    type: 'negative',
    reducerContainer: 'configurationLabels',
    reducerItem: 'fieldError',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_FORM_LABELS_SUCCESS,
    type: 'success',
    reducerContainer: 'configurationLabels',
    reducerItem: 'fieldSuccess',
    hasCloseIcon: true
  }
]

export const componentOption: IOption[] = [
  {
    key: 1,
    text: 'Tasks',
    value: 'tasks'
  },
  {
    key: 2,
    text: 'Reports',
    value: 'reports'
  },
  {
    key: 3,
    text: 'Calendar',
    value: 'calendar'
  },
  {
    key: 4,
    text: 'Notifications',
    value: 'notifications'
  },
  {
    key: 5,
    text: 'BP Relationships',
    value: 'bp-relationship'
  },
  {
    key: 6,
    text: 'Timelines',
    value: 'timelines'
  },
  {
    key: 7,
    text: 'Mobile App - Doc List View (REGULAR)',
    value: 'mobile-list'
  }
]
import { IutilProps } from '../..';
import { IAutomationComponent } from '../../../../interface/component-list';
import { FieldCondition } from './field-condition';
import { IfElseBlock } from './if-else-block';

export class ConditionType {
  static browse = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    switch (selectedComponent?.config?.condition?.name) {
      case 'field-condition':
        FieldCondition.getOptions(selectedComponent, utils);
        break;
      case 'if-else-block':
        IfElseBlock.getOptions(selectedComponent, utils);
        break;
    }
  }


}
import { Delta } from 'jsondiffpatch';
import DeltaFields, { DeltaFieldType } from '..';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

export class TableDeltaFields implements DeltaFieldType {

  name = 'table';

  format(targetDelta: Delta, targetInstance: any, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);

    deltaArray = FieldConfigChange.format(delta, targetInstance, targetInstance);

    if (config) {
      deltaArray = deltaArray.concat(this.setDelta(config, targetInstance, utils));
    }

    return deltaArray;
  }

  setDelta = (delta: Delta, targetInstance: any, utils: IAdminHistoryUtils) => {
    let deltaArray = [] as ICommentDeltaFields[];

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'columns') {
        deltaArray.push({
          id: 'columns',
          label: 'Columns',
          type: 'tableUpdate',
          diff: this.setDeltaColumn(delta[key], targetInstance.config[key], utils, targetInstance),
          oldValue: null
        })
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = targetInstance.config[key];
      }
    });

    if (Object.keys(newDelta).length > 0) {
      deltaArray.push({
        id: 'config',
        label: 'Config',
        type: 'tableUpdate',
        diff: FieldConfigChange.format(newDelta, oldDelta, targetInstance),
        oldValue: null
      })
    }

    return deltaArray;
  }

  setDeltaColumn = (delta: Delta, instance: any, utils: IAdminHistoryUtils, targetInstance: any) => {
    const deltaKeys = Object.keys(delta).filter(e => delta[e] instanceof Array);
    const stringDeltaKeys = Object.keys(delta).filter((key, index) => key !== '_t').join('').toString();
    let deltaArray = [] as ICommentDeltaFields[];

    if (stringDeltaKeys.indexOf('_') > -1) {
      let newDelta = {} as Delta;
      let oldDelta = {} as Delta;

      deltaKeys.forEach(key => {
        if (key.indexOf('_') > -1) {
          const newKey = key.split('_')[1];
          oldDelta[newKey] = delta[key][0];
        } else {
          newDelta[key] = delta[key][0];
        }
      });

      if (Object.keys(newDelta).length > 0) {
        let columnDeltaArray = [] as ICommentDeltaFields[];
        Object.keys(newDelta).forEach(key => {
          if (newDelta[key] && oldDelta[key] && newDelta[key].id === oldDelta[key].id) {
            columnDeltaArray = columnDeltaArray.concat(this.setColumnFieldsDelta(newDelta[key], oldDelta[key], targetInstance, utils));
          } else {
            const field = newDelta[key];
            const oldField = oldDelta[key];
            if (field && oldField) {
              columnDeltaArray.push(this.setFieldDeltaDetails(field, 'Added'));
              columnDeltaArray.push(this.setFieldDeltaDetails(oldField, 'Deleted'));
            } else {
              columnDeltaArray.push(this.setFieldDeltaDetails(field, 'Added'));
            }
          }
        });
        deltaArray = deltaArray.concat(columnDeltaArray);
      } else {
        let columnDeltaArray = [] as ICommentDeltaFields[];
        Object.keys(oldDelta).forEach(key => {
          columnDeltaArray.push(this.setFieldDeltaDetails(oldDelta[key], 'Deleted'));
        });
        deltaArray = deltaArray.concat(columnDeltaArray);
      }
    } else {
      let columnDeltaArray = [] as ICommentDeltaFields[];
      Object.keys(delta).forEach(key => {
        if (Array.isArray(delta[key])) {
          if (key.indexOf('_') > -1) {
            columnDeltaArray.push(this.setFieldDeltaDetails(delta[key][0], 'Deleted'));
          } else {
            columnDeltaArray.push(this.setFieldDeltaDetails(delta[key][0], 'Added'));
          }
        }
      });
      deltaArray = deltaArray.concat(columnDeltaArray);
    }
    return deltaArray;
  }

  setColumnFieldsDelta = (delta: Delta, oldDelta: Delta, targetInstance: any, utils: IAdminHistoryUtils) => {
    let newData = {} as Delta;
    let oldData = {} as Delta;

    let columnDeltaArray = [] as ICommentDeltaFields[];

    Object.keys(delta).forEach(key => {
      if (delta[key]) {
        if (JSON.stringify(delta[key]) !== JSON.stringify(oldDelta[key])) {
          if (typeof delta[key] === 'object' && !Array.isArray(delta[key])) {
            let newDeltaCol = {} as Delta;
            let oldDeltaCol = {} as Delta;
            Object.keys(delta[key]).forEach(childKey => {
              if (JSON.stringify(delta[key][childKey]) !== JSON.stringify(oldDelta[key][childKey])) {
                newDeltaCol[childKey] = delta[key][childKey];
                if (oldDelta.type !== 'date') {
                  newDeltaCol[childKey] = [oldDelta[key][childKey] || null, delta[key][childKey]];
                }
                oldDeltaCol[childKey] = oldDelta[key][childKey] || '';
              }
            });
            newData[key] = newDeltaCol;
            oldData[key] = oldDeltaCol || '';
          } else {
            newData[key] = [oldDelta[key] || null, delta[key]];
            oldData[key] = oldDelta[key] || '';
          }
        }
      } else {
        if (oldDelta[key] && key === 'defaultValue') {
          newData[key] = [oldDelta[key], null];
          oldData[key] = oldDelta[key] || '';
        }
      }
    });
    if (newData) {
      const instance: any = {
        ...oldDelta,
        config: Object.keys(oldDelta.config).length > 0 ?
          { ...oldDelta.config } : { ...oldData.config },
        parentId: targetInstance.id
      };

      const fieldsDelta = new DeltaFields();
      const deltaArray = fieldsDelta.formatContext(newData, instance, {
        ...utils,
        fields: [...utils.fields || [], ...targetInstance.config.columns]
      });

      const columnDelta = deltaArray.map(delta => {
        if (typeof delta.oldValue === 'object' && delta.oldValue.hasOwnProperty('defaultValue')) {
          delta.oldValue = delta.oldValue['defaultValue']
        }
        return {
          ...delta,
          diff: !Array.isArray(delta.diff) ?
            [delta.oldValue || null, delta.diff || null]
            : delta.diff,
          fieldLabel: instance.label
        }
      });
      columnDeltaArray = columnDelta;
    }

    return columnDeltaArray;
  }

  columnItemsDelta = (columns: any[]) => {
    return columns.map(e => {
      return {
        id: e.id,
        label: e.label,
        value: e.id
      }
    })
  }

  setFieldDeltaDetails = (field: ICustomFieldDetail, actionType: 'Added' | 'Deleted') => {
    let text = `Added new Column Field (name: ${field.label}, type: ${field.type})`;
    if (actionType === 'Deleted') {
      text = `Deleted Column Field (name: ${field.label}, type: ${field.type})`;
    }
    return {
      id: field.id,
      diff: ['table column action'],
      label: 'Table Column Action',
      oldValue: '',
      text: text,
      type: 'update/delete',
      textOnly: true,
    } as ICommentDeltaFields;
  }
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IDropdownOption } from '../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import DropdownPopperOption from '../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../reducers';
import { IFormAction } from '../../../../../../../workflow/interface/form-action';
import { IFormStatusWithAction } from '../../../../../../../workflow/interface/form-status';
import { DetailActionButton } from '../../action-button';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { useSetInitialStatusUpdateStateValue } from '../hooks/use-set-initial-status-update-state-values';
import { ITriggerTypeRegistryRender } from './registry';

const errorIndicator = {
  fromStatus: false,
}
class TriggerStatusUpdate {

  name = 'status-update';

  render: React.FC<ITriggerTypeRegistryRender> = (props) => {
    const {
      formStatusList
    } = useSelector((state: IRootState) => state.workflow);
    const {
      fromStatus,
      setFromStatus,
      toStatus,
      setToStatus
    } = useSetInitialStatusUpdateStateValue({
      selectedComponent: props.selectedComponent
    });
    const [error, setError] = useState(errorIndicator);

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.fromStatusId = fromStatus;
        tempComponent.config.toStatusId = toStatus;
        props.updateCurrentAutomation(tempComponent, true);
      }
    }

    const validateInput = (value?: string[]) => {
      const tempError = { ...error };
      if (!value) {
        tempError.fromStatus = false;
        if (fromStatus.length === 0) {
          tempError.fromStatus = true;
        }
      } else if (value.length === 0) {
        tempError.fromStatus = true;
      }
      setError(tempError)
    }

    const getFromStatusOption = (): IDropdownOption[] => {
      return formStatusList.map((status: IFormStatusWithAction) => {
        return {
          key: uuid(),
          value: status.id,
          text: status.name
        }
      })
    }

    const getToStatusList = (): IDropdownOption[] => {
      const toStatus: IDropdownOption[] = [];
      fromStatus.forEach((statusId: string) => {
        const fromStatusId = formStatusList.find((status: IFormStatusWithAction) => status.id === statusId);
        if (fromStatusId) {
          const actionList = fromStatusId.actions.filter((action: IFormAction) => action.config !== undefined);
          actionList.forEach((action: IFormAction) => {
            if (action.config
              && action.config.targetStatusId
              && !toStatus.find((to: IDropdownOption) => action.config && to.value === action.config.targetStatusId)
            ) {
              const statusDetail = formStatusList.find((status: IFormStatusWithAction) => action.config && status.id === action.config.targetStatusId);
              if (statusDetail) {
                toStatus.push({
                  key: uuid(),
                  value: statusDetail.id,
                  text: statusDetail.name
                })
              }
            }
          })
        }
      })
      return toStatus;
    }

    const manageFromStatus = (value: string[]) => {
      setFromStatus(value);
      validateInput(value);
      setToStatus([]);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageToStatus = (value: string[]) => {
      setToStatus(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    return (<>
      <div className={`automation-form-detail-trigger`}>
        <AutomationDetailHeader
          title={`Status Update`}
          description={`Rules is run when Dynamic Action is executed.`}
          iconHeight={20}
          iconWidth={15}
          iconName='flag'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field ${error.fromStatus ? 'error' : ''}`}>
            <label>From Status <span className={`required`}>*</span></label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              clearable
              search
              multiple
              onBlur={() => {
                validateInput()
              }}
              selectOnBlur={false}
              value={fromStatus}
              options={getFromStatusOption()}
              onChange={(event, target: any) => { manageFromStatus(target.value) }}
            />
          </div>
          <div className={`automation-field`}>
            <label>To Status <span className={`required`}>*</span></label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              placeholder='Leave blank to match all statuses'
              clearable
              search
              multiple
              disabled={fromStatus.length === 0}
              selectOnBlur={false}
              value={toStatus}
              options={getToStatusList()}
              onChange={(event, target: any) => { manageToStatus(target.value) }}
            />
          </div>
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}

export default TriggerStatusUpdate;
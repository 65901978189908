import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
import { UploadedFile } from '../../../api-request/upload';
export class ArrayValidator implements ValidationType {

  name = 'array';

  validate(field: FieldElement, values: any, actionType?: string) {
    let itemField: any = field.inputConfig.config.items;

    if (values === undefined || values === null) {
      values = [];
    }

    if (itemField.type === 'file') {
      const _values = values.map((e: UploadedFile) => {
        return e ? e : null
      });
      values = _values
    }

    if (itemField.type === 'number') {
      values = values.map((value: string) => {
        if (typeof value === 'number') {
          return value;
        }
        var regexvalue = new RegExp('^[0-9]\\d*(\\.\\d+)?$');
        var res = value && regexvalue.test(value.replace(/,/g, ''));
        const tempValue = res ? parseFloat(value.replace(/,/g, '')) : value
        return value === '' ? undefined : tempValue;
      })
    }

    const errorMessage: any[] = [];
    values.forEach((value: any) => {
      if (((value === undefined || value === null || value === '') && (field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required && actionType === 'save')))
        || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden) {
        errorMessage.push({ error: [] });
        return;
      }

      const ajv = new Ajv();
      const validationSchema = {
        properties: {
          [field.id]: { ...field.validationSchema }
        },
      };
      const validate = ajv.compile(validationSchema);
      validate({ [field.id]: [value] });

      if (validate.errors) {
        validate.errors = validate.errors.map(error => {
          errorMessages(error, { ...field }, value);
          return error;
        });
      }
      errorMessage.push({ error: validate.errors || [] });
    })
    return errorMessage;
  }

}
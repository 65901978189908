import React, { useState, useEffect } from 'react';
import ConstraintList from './list';
import CustomConstraint from './custom-constraint';
import { IField } from '../../../../../users/interface/field';
import { IConstraint } from '../../interface/constraint';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { useRemoveConstraintModalEl } from './hooks/use-remove-constraint-modal-el';

interface IConstraintModalProps {
  isOpenModal: boolean;
  closeCustomConstraintModal(): void;
  userFieldList: IField[];
  constraintList: IConstraint[];
  updateConstraint(constraint: IConstraint): void;
  userCustomField: ICustomFieldDetail[];
  modifyConstraint: IConstraint | undefined;
  setModifyConstraint(value: IConstraint | undefined): void;
  deleteConstraint(): void;
  formFieldList: ICustomFieldDetail[];
}

const ConstraintModal: React.FC<IConstraintModalProps> = (props) => {
  const [isAddNew, setIsAddNew] = useState(true);

  useEffect(() => {
    if (props.constraintList.length > 0) {
      setIsAddNew(false);
    } else {
      setIsAddNew(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.constraintList])

  useRemoveConstraintModalEl();

  return <>
    <div hidden={!props.isOpenModal} id='constraint-modal-hidden-container' className='hidden-container' onClick={() => props.closeCustomConstraintModal()} />
    <div id='constraint-modal' hidden={!props.isOpenModal} >
      {(isAddNew || props.modifyConstraint)
        ? <CustomConstraint
          userCustomField={props.userCustomField}
          constraintList={props.constraintList}
          setIsAddNew={setIsAddNew}
          userFieldList={props.userFieldList}
          updateConstraint={props.updateConstraint}
          modifyConstraint={props.modifyConstraint}
          deleteConstraint={props.deleteConstraint}
          formFieldList={props.formFieldList}
        />
        : <ConstraintList
          constraintList={props.constraintList}
          setIsAddNew={setIsAddNew}
          setModifyConstraint={props.setModifyConstraint}
        />
      }
    </div>
  </>
}

export default ConstraintModal;
import { Delta } from 'jsondiffpatch';
import { v4 as uuid } from 'uuid';
import { DeltaModuleType } from '../..';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ICommentDeltaFields } from '../../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IDatatableConfigUniqueConstraint } from '../../../../../../module/data-tables/interface/data-table-config';
import { IDataTable } from '../../../../../../module/data-tables/reducer';
import { AdminHistoryCategory, AdminHistoryType, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils } from '../../../../manage-history';
import { FieldConfigChange } from '../../../utils/field-config-change';
import { DatatableFieldsDeltaModule } from './fields';

export class DatatableDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.DataTable;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];
    let showUpdateTextOnly = false;
    let deltaText = historyLogs.text;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (targetDelta) {
      const keysToDisplay = ['name', 'config', 'fieldsOrder'];
      const deltaKeys = Object.keys(targetDelta).filter(e => keysToDisplay.includes(e));
      const dataTableList = this.datatableList(historyLogs, utils);
      const belongDataTable = dataTableList.find(e => e.id === targetInstance.id);
      const datatableFields = belongDataTable && belongDataTable.fields
        && belongDataTable.fields.concat(this.deletedFieldInstance(utils, belongDataTable.id) as any[]);

      if (deltaKeys.length === 1 && deltaKeys[0] === 'fieldsOrder') {
        showUpdateTextOnly = true;
        deltaText = `${commentSender} re-ordered fields for DataTable: (name: ${targetInstance.name})`;
      } else {
        deltaKeys.forEach(key => {
          if (key === 'config') {
            const configDeltaArr = this.configDelta(targetDelta[key], targetInstance[key], datatableFields || []);
            deltaArray = deltaArray.concat(configDeltaArr);
            deltaText = `${commentSender} has updated config for DataTable: (name: ${targetInstance.name})`;
          } else {
            newDelta[key] = targetDelta[key];
            oldDelta[key] = targetInstance[key];
          }
        });
      }
      if (Object.keys(newDelta).length) {
        deltaArray = deltaArray.concat(FieldConfigChange.format(newDelta, oldDelta, targetInstance));
      }
    }

    return {
      delta: deltaArray,
      text: deltaText,
      showUpdateTextOnly
    };
  }

  datatableList(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {

    const dataTableField = new DatatableFieldsDeltaModule();

    let dataTableList = dataTableField.deletedDTInstance(utils) as IDataTable[];

    if (utils.dataTableList) {
      dataTableList = dataTableList.concat(utils.dataTableList);
    }

    return dataTableList;
  }

  deletedFieldInstance = (utils: IAdminHistoryUtils, clientDtId: string) => {
    return utils.history && utils.history
      .filter(e => e.type === AdminHistoryType.Delete
        && e.category === AdminHistoryCategory.DataTableFields)
      .map(e => e.context.targetInstance)
      .filter(e => e.clientDbId === clientDtId);
  }

  configDelta(delta: any, instance: any, datatableFields: ICustomFieldDetail[]) {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    if (instance && Object.keys(instance).length > 0) {
      const constraintInstance = this.uniqueConstraints(instance['uniqueConstraints'])
        .map(e => this.uniqueConstaintFields(e, datatableFields));
      oldDelta['uniqueRecords'] = constraintInstance[0];
    } else {
      oldDelta['uniqueRecords'] = [];
    }

    if (delta instanceof Array) {
      const constraintDelta = delta
        .map(e => {
          if (e && e.uniqueConstraints) {
            return this.uniqueConstraints(e.uniqueConstraints)[0];
          }
          return e;
        })
        .map(e => {
          return this.uniqueConstaintFields(e, datatableFields);
        });
      newDelta['uniqueRecords'] = constraintDelta;
    } else {
      const newConstraint = {} as Delta;
      const oldConstraint = {} as Delta;
      const constraintKeys = Object.keys(delta['uniqueConstraints']);

      if (constraintKeys.length > 2) {
        Object.keys(delta['uniqueConstraints']).forEach(key => {
          let data = delta['uniqueConstraints'][key];
          if (data instanceof Array) {
            if (key.indexOf('_') > -1) {
              oldConstraint['uniqueConstraints'] = data;
            } else {
              newConstraint['uniqueConstraints'] = data;
            }
          }
        });
      } else {
        Object.keys(delta['uniqueConstraints']).forEach(key => {
          let data = delta['uniqueConstraints'][key];
          if (data instanceof Array) {
            newConstraint['uniqueConstraints'] = data;
            oldConstraint['uniqueConstraints'] = instance['uniqueConstraints'];
          }
        });
      }

      if (Object.keys(newConstraint).length > 0) {
        let delta = [] as any[];
        let constaintsData = this.uniqueConstraints(newConstraint['uniqueConstraints']).map(e => {
          return this.uniqueConstaintFields(e, datatableFields)
        })[0] || [];
        let constraintInstance = this.uniqueConstraints(oldConstraint['uniqueConstraints']).map(e => {
          return this.uniqueConstaintFields(e, datatableFields)
        })[0] || [];
        const bothSame = constaintsData.length === constraintInstance.length
          && JSON.stringify(constaintsData) === JSON.stringify(constraintInstance);
        if (bothSame) {
          delta = [constraintInstance, null];
        } else {
          delta = [
            constraintInstance.length > 0 ? constraintInstance : null,
            constaintsData.length > 0 ? constaintsData : null
          ];
        }
        newDelta['uniqueRecords'] = delta;
        oldDelta['uniqueRecords'] = constraintInstance;
      }
    }

    return FieldConfigChange.format(newDelta, oldDelta, oldDelta);
  }

  uniqueConstraints(constaints: IDatatableConfigUniqueConstraint[]) {
    return constaints.map(e => e.fields ? e.fields : e);
  }

  uniqueConstaintFields(constaints: any, datatableFields: ICustomFieldDetail[]) {
    if (constaints && constaints instanceof Array) {
      return constaints.map(e => {
        const fieldData = datatableFields.find(field => field.id === e);
        if (fieldData) {
          return {
            id: fieldData.id,
            label: fieldData.label
          }
        }
        return {
          id: uuid(),
          label: 'Field not found'
        };
      });
    }
    return constaints;
  }
}
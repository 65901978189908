import React from 'react';
import { useSelector } from 'react-redux';
import { FieldElement, IInputConfig } from '../../../../../common/field/component/field-element';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';
import { IRootState } from '../../../../../reducers';
import { IField, IFieldAccessType } from '../../users/interface/field';


const GridDTFilterTemplate: React.FC<{ data: any }> = ({ data }) => {

  const { enumItemsFromRecords } = useSelector((state: IRootState) => state.dataTable);

  const props = { ...data.column.parent };
  const fieldList = [...props.forUpdateDataTableFields];
  let fieldData: any = fieldList.find((e: IField) => e.id === data.column.fieldId);

  if (!fieldData) {
    return null;
  }

  if (fieldData && fieldData.configName === IConfigName.FileUpload) {
    fieldData = {
      ...fieldData,
      inputConfig: {
        ...fieldData.inputConfig,
        config: { ...fieldData.inputConfig?.config },
        type: 'string'
      } as IInputConfig
    }
  }

  const field = FieldElement.getDataElement(fieldData);
  const Element = field.getFieldComponent();
  field.id = `fields.${field.id}`;
  return <Element
    {...field}
    accessType={IFieldAccessType.ReadWrite}
    forwardedRef={props.filterRefs}
    defaultValueElement={false}
    defaultValue={undefined}
    isAddRecord={false}
    triggerFilter={props.triggerFilter}
    fieldEnumOptionUrl={`${props.companyId}/databases/${props.dataTableId}/filterFields`}
    fieldCollection={props.forUpdateDataTableFields}
    filterElement={true}
    customEnumItems={enumItemsFromRecords &&
      enumItemsFromRecords[fieldData.id] &&
      enumItemsFromRecords[fieldData.id].length > 0 ?
      enumItemsFromRecords[fieldData.id] : undefined
    }
  />
}

export default GridDTFilterTemplate

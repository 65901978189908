import React, { useState } from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { IField, IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import { NumberProps } from '../../service/type/number';
import { FieldProperties } from '../../service/field-properties.service';
import { validateInput } from './utils/validate-input';

const SingleNumber: React.FC<AcceptedProps> = (props) => {

  const name = props.getFieldId();

  const [refresher, setRefresher] = useState(0);

  const elementProperties = new FieldProperties(name, props.forwardedRef, new NumberProps());
  const numberProperties: any = { ...props.inputConfig.config };

  if (props.isClearValue && elementProperties.current) {
    elementProperties.setCurrent({ ...elementProperties.current, value: '' })
  }

  const parseNumberValue = (value: string) => {
    if (value && typeof value === 'string') {
      const hasComma = value.indexOf(',') > -1;
      return parseFloat(hasComma ? value.replace(/,/g, '') : value);
    }
    return value;
  }

  const numberFormat = () => {
    if (numberProperties.percent) {
      return "#######0.#####'%'";
    }
    let format = '#';
    if (numberProperties.decimals) {
      let decimal = '0'.repeat(numberProperties.decimals);
      format = format + `.${decimal}`
    }
    if (numberProperties.thousandSeparator) {
      format = format.substring(0, 0) + '###,##' + format;
    }
    return format;
  }

  const triggerAutomationService = (value: any) => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value)
      }
    }
  }

  const setRef = (curRef: any) => {
    if (curRef) {
      if (elementProperties.isSet()) {
        curRef.element.style.setProperty('text-align', numberProperties.alignment, 'important');
        curRef.error = elementProperties.getError();
        curRef.value = parseNumberValue(elementProperties.current.value);
        curRef.fieldType = 'number';
        curRef.element.addEventListener('keyup', (e: any) => triggerOnChange(e, true));
        curRef.element.parentElement.addEventListener('mousedown', triggerOnClick);
      }
      elementProperties.setCurrent({
        ...elementProperties.current,
        value: elementProperties.current.value, fieldType: 'number'
      });
      return curRef;
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = typeof props.defaultValue !== 'undefined' && props.defaultValue !== null
      ? JSON.stringify(props.defaultValue) : '';
    if (defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = elementProperties.isSet() && defaultValue;
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValueString });
      setRefresher(refresher + 1);
    }
  }

  const triggerOnBlur = (event: any) => {
    elementProperties.setCurrent({ ...elementProperties.current, value: event.target.value });
    validateInput({
      setRefresher: setRefresher,
      refresher: refresher,
      name: name,
      validationSchemaProps: {
        ...props.validationSchema,
        isRequired: props.accessType === IFieldAccessType.Required || false,
      },
      multiline: false,
      forwardedRef: props.forwardedRef,
      inputConfig: props.inputConfig,
      labelProps: props.label,
      requiredProps: props.accessType === IFieldAccessType.Required || false,
      reloadOnChange: props.reloadOnChange,
      accessType: props.accessType,
      valueFieldClass: elementProperties,
    });
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    if (props.throwValueOutside) props.throwValueOutside(name, event.target.value);
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    triggerAutomationService(event.target.value);
  }

  const triggerOnCreate = () => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput({
        name,
        setRefresher: setRefresher,
        refresher: refresher,
        validationSchemaProps: {
          ...props.validationSchema,
          isRequired: true,
          type: 'number'
        },
        multiline: props.multiline || false,
        forwardedRef: props.forwardedRef,
        inputConfig: props.inputConfig,
        labelProps: props.label,
        requiredProps: true,
        accessType: props.accessType,
        valueFieldClass: elementProperties
      });
    }
  }

  const triggerOnChange = (event: any, allowFilter?: boolean) => {
    elementProperties.setCurrent({ ...elementProperties.current, value: event.target.value });
    if (!props.filterElement) setRefresher(refresher + 1);
    if (props.filterElement && props.triggerFilter && allowFilter) {
      props.triggerFilter('');
    }
  }

  const triggerOnClick = (event: any) => {
    if (event.target.className.includes('e-clear-icon')) {
      elementProperties.setCurrent({ ...elementProperties.current, value: '' });
      if (props.filterElement && props.triggerFilter) {
        props.triggerFilter('');
      }
    }
  }

  const getValue = () => {
    return parseNumberValue(elementProperties.current.value) as number;
  }

  return <NumericTextBoxComponent
    id={name}
    floatLabelType="Never"
    name={name}
    value={getValue()}
    decimals={numberProperties.decimals}
    validateDecimalOnType
    ref={setRef}
    showClearButton
    format={numberFormat()}
    disabled={props.accessType === IFieldAccessType.Readonly}
    min={numberProperties.min > 0 ? numberProperties.min : undefined}
    max={numberProperties.max > 0 ? numberProperties.max : undefined}
    placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
    immediateRender
    onBlur={triggerOnBlur}
    onChange={(e: any) => triggerOnChange(e, false)}
    created={triggerOnCreate}
  />
}

export default SingleNumber;
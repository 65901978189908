import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { IRender } from '.';
import { IField, IInputConfig } from '../../../../component/admin-module/module/users/interface/field';
import { IAxiosResponse } from '../../../api-request';
import { FieldElement } from '../../../field/component/field-element';
import { DropDownOptionLimit } from '../../../field/component/object/enum-type/utils';
import { IConfigName } from '../../../field/type/interface/field-type-object';

class EnumFieldValue {

  name = 'enum';

  Render: React.FC<IRender> = (props) => {

    const [triggeredGetenum, setTriggeredEnum] = useState(false);
    const [currentFieldItem, setCurrentFieldItem] = useState([]);
    const [autopopEnumConfig, setAutopopEnumConfig] = useState<{ configName: IConfigName, config: IInputConfig }>();
    const [fieldSearch, setFieldSearch] = useState<string>();

    const companyId = props.companyId;
    const databaseId = props.referenceBp.id || ''
    const formId = props.referenceBp.id || ''
    const dataType = props.field.config && props.field.config.dataType ? props.field.config.dataType : '';
    const currentBp = props.currentBpId;

    const setSearchUrl = (dataType: string, sourceId: string, company: string) => {
      if (dataType === 'document') {
        return `${company}/documents/fields?formId=${sourceId}`;
      }
      if (dataType === 'database') {
        return `${company}/databases/${sourceId}/records/fields`;
      }
      if (dataType === 'user') {
        return `${company}/users/fields`;
      }
    }

    const getEnumTypeAutopopulateConfig = async () => {
      let req: IAxiosResponse = { data: { data: [] as IField } };
      let sourceFieldConfig = {} as { configName: IConfigName, config: IInputConfig };
      if (props.field && props.currentFieldList && props.currentFieldList.length > 0) {
        const { sourceField, labelFormat, labelType } = { ...props.field.config };
        if (labelType === 'enum' && labelFormat && sourceField) {
          const labelId = labelFormat.replace(/fields./g, '').replace(/{/g, '').replace(/}/g, '');
          const sourceFieldData = props.currentFieldList.find(e => e.id === sourceField);
          if (sourceFieldData && sourceFieldData.configName) {
            const { dataType, data } = { ...sourceFieldData.config };
            sourceFieldConfig.configName = sourceFieldData.configName;
            if (dataType === 'document') {
              req = await Axios.post(`${data.companyId}/documents/fields/${labelId}?formId=${data.formId}`);
            }
            if (dataType === 'database') {
              req = await Axios.post(`${data.companyId}/databases/${data.databaseId}/records/fields/${labelId}`);
            }
            if (dataType === 'user') {
              req = await Axios.post(`${data.companyId}/users/fields/${labelId}`);
            }
          }
        }
      }
      if (req.data.success && req.data.data && req.data.data.inputConfig) {
        const fieldReq = req.data.data;
        const { config }: any = { ...fieldReq.inputConfig };
        sourceFieldConfig.config = {
          ...config,
          dataSource: {
            ...config.dataSource,
            dataFilters: undefined,
            dataFiltersJoint: undefined,
          }
        };
        if (config.dataSource && config.dataSource.source) {
          let sourceId = '';
          if (config.dataSource.source.type === 'database') {
            sourceId = config.dataSource.source.config.databaseId
          }
          if (config.dataSource.source.type === 'document') {
            sourceId = config.dataSource.source.config.formId;
          }
          const url = setSearchUrl(config.dataSource.source.type, sourceId, config.dataSource.source.config.companyId);
          setFieldSearch(url);
        } else {
          setFieldSearch(undefined);
        }
        setAutopopEnumConfig(sourceFieldConfig);
      }
    }

    const inputConfig = () => {
      let sourceType = '';
      if (props.field.config && props.field.config.data) {
        sourceType = props.field.config.dataType;
        if (props.field.config.data.databaseId) {
          sourceType = 'database';
        }
        if (props.field.config.data.formId) {
          sourceType = 'document';
        }
      }
      return {
        items: !props.fromPermission ? getItemList() : [],
        displayType: 'dropdown',
        multiselect: props.field.config ? props.field.config.multiselect : false,
        dataSource: props.field.configName === IConfigName.Referenced ? {
          labelFormat: props.field.config?.labelFormat as string,
          dataFilters: undefined,
          dataFiltersJoint: undefined,
          source: {
            type: sourceType,
            config: props.field.config?.data
          }
        } as any : undefined
      };
    }

    const getTableFieldItems = (tableFieldData: IField) => {
      let selectedFieldData = [] as IField[];
      const { config } = { ...tableFieldData.inputConfig };
      if (config && config.columns && config.columns instanceof Array) {
        selectedFieldData = config.columns.filter((e: IField) => e.id === props.field.id);
      }
      return selectedFieldData;
    }

    const getEnumItems = async () => {
      if (companyId
        && (databaseId !== '' || formId !== '')
        && (dataType === 'database' || dataType === 'document')
        && props.field.id && props.fromPermission
      ) {
        try {
          let req = null;
          let filter = {};
          let data = [] as any;
          if (req === null) {
            if (props.forwardedRef.current[props.valueId]?.value) {
              filter = {
                fields: {},
                inputs: {
                  [`${props.field.id}`]: {
                    ids: props.forwardedRef.current[props.valueId]?.value.indexOf('[') > -1
                      ? JSON.parse(props.forwardedRef.current[props.valueId]?.value)
                      : [props.forwardedRef.current[props.valueId]?.value],
                    page: {
                      offset: 0,
                      limit: DropDownOptionLimit
                    }
                  }
                }
              }
            }
            if (props.currentFormField.config && props.currentFormField.config.dataType === 'document') {
              req = await Axios.post(`${companyId}/documents/fields?formId=${formId}&ids=${props.field.id}`, filter);
              if (props.configType === 'tableConfig' && props.parentFormFieldId) {
                let newReq = await Axios.post(`${companyId}/documents/fields?formId=${formId}&ids=${props.parentFormFieldId}`, filter);
                if (newReq.data.data.length > 0) {
                  req = { data: { data: getTableFieldItems(newReq.data.data[0]) as IField[] } }
                }
              }
            }
            if (currentBp && props.configType === 'tableConfig' && props.parentFormFieldId) {
              let newReq = await Axios.post(`${companyId}/documents/fields?formId=${currentBp}&ids=${props.parentFormFieldId}`, filter);
              if (newReq.data.data.length > 0) {
                req = { data: { data: getTableFieldItems(newReq.data.data[0]) as IField[] } }
              }
            }
            if (req !== null && req.data.data.length > 0 && req.data.data[0].inputConfig && req.data.data[0].inputConfig.config.items instanceof Array) {
              data = req.data.data[0].inputConfig.config.items;
            }
            setCurrentFieldItem(data);
          }
        } catch (e) {
          setCurrentFieldItem([]);
        }
      }

      setTriggeredEnum(true);
    }

    const getItemList = () => {
      let option = props.field.config && props.field.config.data instanceof Array ? props.field.config.data : [];
      if (props.field.config && (props.field.config.dataType === 'document' || props.field.config.dataType === 'database')) {
        return currentFieldItem;
      }
      return option
    }

    const fetchEnumItemsOnLoad = () => {
      return props.forwardedRef.current[props.valueId]
        && props.forwardedRef.current[props.valueId].value && dataType !== 'static';
    }

    useEffect(() => {
      if (props.field.configName === IConfigName.Autopopulated) {
        getEnumTypeAutopopulateConfig();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.field]);

    useEffect(() => {
      const dataType = props.currentFormField?.config?.dataType as string;
      setFieldSearch(setSearchUrl(dataType, props.referenceBp.id || '', companyId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentFormField]);

    useEffect(() => {
      if (!triggeredGetenum) {
        getEnumItems();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggeredGetenum]);

    const tempField: IField = {
      id: props.valueId,
      inputConfig: {
        type: 'enum',
        config: props.field.configName === IConfigName.Autopopulated && autopopEnumConfig ? autopopEnumConfig.config : inputConfig(),
      },
      label: props.field.label,
    };

    const field = FieldElement.getDataElement(tempField);
    const Element = field.getFieldElement();

    return (<>
      {triggeredGetenum &&
        <div>
          <Element
            {...field}
            originalId={props.field.id}
            forwardedRef={props.forwardedRef}
            defaultValueElement={false}
            throwValueOutside={props.throwValueOutside}
            fieldEnumOptionUrl={dataType === 'static' ? undefined : fieldSearch}
            fetchEnumItemsOnLoad={fetchEnumItemsOnLoad()}
          />
        </div>
      }
    </>
    );
  }
}

export default EnumFieldValue;

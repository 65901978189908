import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { icons } from '../../../../common/icons';

export const GET_COMPANY_SETTINGS = 'app/settings/GET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_RETURN = 'app/settings/GET_COMPANY_SETTINGS_RETURN';
export const GET_COMPANY_SETTINGS_ERROR = 'app/settings/GET_COMPANY_SETTINGS_ERROR,'
export const UPDATE_COMPANY_SETTINGS = 'app/settings/UPDATE_COMPANY_SETTINGS';
export const UPDATE_COMPANY_SETTINGS_RETURN = 'app/settings/UPDATE_COMPANY_SETTINGS_RETURN';
export const UPDATE_COMPANY_SETTINGS_ERROR = 'app/settings/UPDATE_COMPANY_SETTINGS_ERROR';
export const CLEAR_UPDATE_COMPANY_SETTINGS_ERROR = 'app/settings/CLEAR_UPDATE_COMPANY_SETTINGS_ERROR';
export const RESET_MESSAGE_RESPONSE = 'app/settings/RESET_MESSAGE_RESPONSE';
export const CLEAR_UPDATE_COMPANY_SETTINGS_SUCCESS = 'app/settings/CLEAR_UPDATE_COMPANY_SETTINGS_SUCCESS';

export const companySettingsActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_COMPANY_SETTINGS_SUCCESS,
    type: 'success',
    reducerContainer: 'companySettings',
    reducerItem: 'updateCompanySettingsMessage',
    hasCloseIcon: true,
  }
]

export const settingsMenuList = [
  // {
  //   id: 'authentication',
  //   label: 'Authentication',
  //   icon: icons.black.shield,
  //   style: {
  //     width: '22px',
  //     height: '30px',
  //     marginLeft: '4px'
  //   }
  // },
  {
    id: 'language-and-region',
    label: 'Language & Region',
    icon: icons.black.world,
    style: {
      width: '22px',
      height: '22px',
      margin: '2px 2px 2px 4px'
    }
  },
  // {
  //   id: 'data-formats',
  //   label: 'Data Formats',
  //   icon: icons.black.dataFormats,
  //   style: {
  //     width: '25px',
  //     height: '25px',
  //     margin: '0px 2px 2px 4px'
  //   }
  // },
  {
    id: 'advance-settings',
    label: 'Advanced',
    icon: icons.black.cog,
    style: {
      width: '20px',
      height: '20px',
      margin: '2px 2px 2px 6px'
    }
  },
]
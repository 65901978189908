import { DELETE_DOCUMENT_RETURN } from '../form/constant';
import { IFormDocument } from '../report/interface/form-document';
import { GET_DRAFT_LIST, GET_DRAFT_LIST_RETURN } from './constant';

export interface IDraftReducer {
  type?: string;
  draftList: IFormDocument[];
  draftListLoading: boolean;
  draftRecordTotalCount: number;
  documentId?: string;

}

const initialState: IDraftReducer = {
  draftList: [],
  draftListLoading: false,
  draftRecordTotalCount: 0,

}

const draftReducer = (state = initialState, action: IDraftReducer) => {
  let newState = { ...state };
  let tempDraftList = [...newState.draftList];

  switch (action.type) {

    case GET_DRAFT_LIST:
      newState.draftListLoading = true;
      return newState;

    case GET_DRAFT_LIST_RETURN:
      newState.draftListLoading = false;
      newState.draftList = action.draftList;
      newState.draftRecordTotalCount = action.draftRecordTotalCount;
      return newState;

    case DELETE_DOCUMENT_RETURN:
      newState.draftList = tempDraftList.filter((e: IFormDocument) => e.id !== action.documentId);
      return newState;

    default:
      return newState;
  }
}

export default draftReducer;
class BoolOperatorOption {

  name = 'bool';

  // option = [
  //   { key: 0, value: 'equal', text: 'Equal' },
  // ]

  option = [
    { key: 0, value: 'is-empty', text: 'Is Empty' },
    { key: 1, value: 'is-not-empty', text: 'Is Not Empty' },
    { key: 3, value: 'equal', text: 'Equal' },
    { key: 4, value: 'not-equal', text: 'Not Equal' },
  ]

}

export default BoolOperatorOption;
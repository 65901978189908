import React, { useState, useRef, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Dropdown, Image } from 'semantic-ui-react-bpm';
import { ICustomFieldProps, IDefaultRef } from '../..';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig, FileFieldFileType, FileFieldFitMode, FileFieldDisplayConfig, FileFieldDisplayDimension, IFileGenerationContext } from '../../interface/custom-field-detail';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { IFieldConfig } from '../../../../component/admin-module/module/users/interface/field';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../reducers';
import { errorIndicator, fieldVisibility } from '../../constant';
import { IUserFieldPermission } from '../../../../component/admin-module/module/users/interface/user-field-permission';
import { plainToClass } from 'class-transformer';
import { isValidNumber } from '../../../utils/validate-number';
import DropdownPopperOption from '../../../general/dropdown-popper-option';
import { getApiConfigAction } from '../../../../component/admin-module/module/business-process/module/configuration/module/apis/action';
import { IOption } from '../../../interface/option';
import { icons } from '../../../icons';
import { getPdfTemplateListAction } from '../../../../component/admin-module/module/business-process/module/configuration/module/pdf-template/action';
import { IConfigName } from '../../../field/type/interface/field-type-object';

type DisplayConfig = keyof FileFieldDisplayConfig;

export default class FileFieldConfig extends DefaultFieldConfig {

  name = 'file';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const dispatch = useDispatch();

    if (props.defaultData.configName !== IConfigName.FileUpload) {
      const OtherElement = new DefaultFieldConfig();
      return <OtherElement.Render {...props} />
    }

    const [mandatory, setMandatory] = useState(false);
    const [searchable, setSearchable] = useState(false);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [fieldPermissionColor, setFieldPermissionColor] = useState('');
    const [selectedAPI, setSelectedAPI] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [maxItems, setMaxItems] = useState() as any;
    const [fileType, setFileType] = useState<FileFieldFileType>();
    const [displayConfig, setDisplayConfig] = useState<FileFieldDisplayConfig | null>();
    console.log(`Log  displayConfig:`, displayConfig);

    const [isMultiline, setIsMultiline] = useState(false);
    const [enableCameraInput, setEnableCameraInput] = useState(false);
    const [isSytemGenerated, setIsSystemGenerated] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [displayEnabled, setDisplayEnabled] = useState(false);

    const companyId = props.companyId;
    const user = useSelector((state: IRootState) => state.user);
    const fieldNameRef = useRef(document.createElement('input'));
    const defaultvalueRef = useRef<IDefaultRef>({});
    const helpRef = useRef(document.createElement('textarea'));

    const { apiConfigurationList } = useSelector((state: IRootState) => state.configurationAPIs);
    const { pdfTemplateConfigList } = useSelector((state: IRootState) => state.configurationPDFTemplate);

    const [error, setError] = useState({ ...errorIndicator, template: false })

    const inputObject = () => {
      const displayConfig = getDisplayConfig();
      return plainToClass(IConfig, {
        ...configData,
        enableCameraInput: enableCameraInput,
        systemGenerated: isSytemGenerated,
        generationContext: isSytemGenerated && (selectedAPI || selectedTemplate) ?
          {
            apiId: selectedAPI ? selectedTemplate : undefined,
            templateId: selectedTemplate ? selectedTemplate : undefined
          } as IFileGenerationContext
          : undefined,
        fileType: fileType,
        displayEnabled: displayEnabled,
        display: displayConfig && Object.keys(displayConfig).length > 0 ? displayConfig : undefined,
      });
    }

    const getDisplayConfig = () => {
      if (displayConfig == null) return undefined;
      return {
        aspectRatio: displayConfig?.aspectRatio || undefined,
        fitMode: displayConfig?.fitMode || undefined,
        width: displayConfig.width && displayConfig.width.value
          ? {
            ...displayConfig.width,
            unit: displayConfig.width.unit || 'px'
          } : undefined,
        height: displayConfig.height && displayConfig.height.value
          ? {
            ...displayConfig.height,
            unit: displayConfig.height.unit || 'px'
          } : undefined,
      } as FileFieldDisplayConfig;
    }

    const updateConfig = () => {
      const permission = setFieldPermission(fieldPermissionColor || 'red');
      if (validateRequiredFormField()) {

        let defaultValue: any = null;
        if (defaultvalueRef.current['defaultField']) {
          defaultValue = defaultvalueRef.current['defaultField'].value;
          defaultValue = defaultValue || null;
        }

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: mandatory,
            label: fieldNameRef.current.value,
            hint: helpRef.current.value,
            defaultValue: defaultValue,
            config: inputObject(),
            multiline: isMultiline,
            permission,
            maxItems: maxItems,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '');
      }
    }

    const pdfTemplatesOption = (): IOption[] => {
      if (pdfTemplateConfigList && pdfTemplateConfigList.length > 0) {
        return pdfTemplateConfigList.map((templates, index) => {
          return {
            key: index,
            text: templates.name,
            value: templates.id
          }
        })
      }
      return [];
    }

    const apiConfigurationOption = (): IOption[] => {
      if (apiConfigurationList.length > 0) {
        return apiConfigurationList.map((api, index) => {
          return {
            key: index,
            text: `${api.name} (${api.url})`,
            value: api.url,
            content: (
              <span>
                {api.name}
                <span style={{
                  fontStyle: 'italic',
                  fontSize: '13px',
                  marginLeft: '5px'
                }}>({api.url})</span>
              </span>
            )
          }
        })
      }
      return [];
    }

    const handleSystemGenerateFile = (value: boolean) => {
      setIsSystemGenerated(value);
      if (!value) {
        setSelectedAPI('');
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      if (props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
        currentError.template = !selectedTemplate && isSytemGenerated ? true : false;
      }

      setError(currentError)
      return !currentError.fieldname && !currentError.template;
    }

    const setFieldPermission = (permissionColor: string) => {
      const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.name === permissionColor);
      const permission = colorPermission.length > 0 ? colorPermission[0].id : undefined;
      return permission;
    }

    const onMaxItemsChange = (value: string) => {
      const isValid = isValidNumber(value, { thousandSeparator: false, decimals: 0 })
      if (isValid) {
        const fileMaxItem = (value === '' && isNaN(parseInt(value))) || parseInt(value) === 0 ? '' : parseInt(value);
        setMaxItems(fileMaxItem)
      }
    }

    const onSetDisplayConfig = (key: DisplayConfig, value: any) => {
      const tempConfig: FileFieldDisplayConfig = { ...displayConfig };
      tempConfig[key] = value;
      setDisplayConfig(tempConfig);
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config)
        setFieldData(data)
        fieldNameRef.current.value = data.label || '';
        helpRef.current.value = data.hint || '';
        if (defaultvalueRef.current['defaultField']) {
          if (typeof data.defaultValue === 'object' && data.defaultValue !== null) {
            defaultvalueRef.current['defaultField'].value = JSON.stringify(data.defaultValue)
          } else {
            defaultvalueRef.current['defaultField'].value = data.defaultValue || ''
          }
        }
        const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.id === data.permission);
        const permissionColor = colorPermission.length > 0 ? colorPermission[0].name : undefined;
        setFieldPermissionColor(permissionColor || 'red');
        setSearchable(data.searchable ? true : false);
        setMandatory(data.required ? true : false);
        setIsMultiline(data.multiline ? true : false);
        setMaxItems(data.maxItems || 1)
        setEnableCameraInput(config.enableCameraInput ? true : false);
        setIsSystemGenerated(config.systemGenerated ? true : false);
        setSelectedAPI(config.generationContext && config.generationContext.apiId ? config.generationContext.apiId : '');
        setSelectedTemplate(config.generationContext && config.generationContext.templateId ? config.generationContext.templateId : '');
        setConfidential(data.confidential || false);
        setDisplayConfig(config.display)
        setDisplayEnabled(config.displayEnabled || false)
        setFileType(config.fileType)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    useEffect(() => {
      if (companyId && props.formDetail && props.formDetail.id && isSytemGenerated) {
        dispatch(getApiConfigAction({ company: companyId, formId: props.formDetail.id }));
        dispatch(getPdfTemplateListAction({ company: companyId, formId: props.formDetail.id }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSytemGenerated, props.formDetail, companyId]);

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='custom-field-modal'
        className={`string-field-modal `}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`${props.defaultData.configName || 'Custom'} Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form' >
            <div>
              <div className='string-normal-config'>
                <Segment className='borderless' style={{
                  marginTop: `${props.formType === 'FormBuilder' ? '-20px' : '2rem'}`
                }}>
                  <div className='header-field'>
                    {props.formType === 'User' &&
                      <span className='field-visibility'>
                        Visibility&nbsp;&nbsp;&nbsp;
                        <Dropdown
                          popperContainer={DropdownPopperOption}
                          selection
                          value={fieldPermissionColor || 'red'}
                          options={fieldVisibility}
                          selectOnBlur={false}
                          onChange={(event, target: any) => { setFieldPermissionColor(target.value) }}
                        />
                      </span>
                    }
                    {(props.formType !== 'FormBuilder') &&
                      <div className="five wide field mandatory-field" onClick={() => { setMandatory(!mandatory) }}>
                        <span>Mandatory</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={mandatory} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                    {props.formType === 'User' &&
                      <div className="five wide field searchable-field" onClick={() => { setSearchable(!searchable) }}>
                        <span>Searchable</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={searchable} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                  </div>
                  {(props.formType === 'User' || props.formType === 'FormBuilder') &&
                    <Form.Group widths='equal'>
                      <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                        <label>Field Name</label>
                        <div className="ui input">
                          <input onChange={() => {
                            error.fieldname = (fieldNameRef.current.value ? false : true)
                          }
                          } ref={fieldNameRef} type="text" />
                        </div>
                      </div>
                    </Form.Group>
                  }
                  <Form.Group widths='equal'>
                    <div className="field">
                      <label>Field Info</label>
                      <textarea rows={3} ref={helpRef}></textarea>
                    </div>
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <div className='field'>
                      <label>File Type</label>
                      <Dropdown
                        fluid
                        search
                        selection
                        clearable
                        value={fileType}
                        selectOnBlur={false}
                        options={Object.entries(FileFieldFileType).map(([key, value]) => {
                          return {
                            id: value,
                            value: value,
                            text: key,
                          }
                        })}
                        onChange={(event, target: any) => {
                          setFileType(target.value as FileFieldFileType);
                          setDisplayEnabled(false);
                          setDisplayConfig(null);
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group widths="equal" id='file-option-container'>
                    <div id="file-option">
                      <div id="camera-option">
                        <div className='file-custom-field-checkbox'>
                          <div className="ui fitted checkbox" onClick={() => setEnableCameraInput(!enableCameraInput)}>
                            <input className="hidden" type="checkbox" onChange={() => { }} checked={enableCameraInput} value="" />
                            <label>Enable Camera Input</label>
                          </div>
                        </div>
                      </div>
                      <div id="camera-option">
                        <div className='file-custom-field-checkbox'>
                          <div className="ui fitted checkbox" onClick={() => setConfidential(!confidential)}>
                            <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} value="" />
                            <label>Confidential</label>
                          </div>
                        </div>
                      </div>
                      {!props.tableId &&
                        <div id="multi-line-option">
                          <div className='file-custom-field-checkbox'>
                            <div className="ui fitted checkbox" onClick={() => setIsMultiline(!isMultiline)}>
                              <input className="hidden" type="checkbox" onChange={() => { }} checked={isMultiline} value="" />
                              <label>Enable Multi-line</label>
                            </div>
                          </div>
                          {isMultiline &&
                            <div className="file-limit">
                              <label>Limit to</label>
                              <input
                                onChange={(e) => onMaxItemsChange(e.target.value)}
                                onBlur={() => onMaxItemsChange(maxItems || 1)}
                                value={maxItems}
                                maxLength={2}
                                type="text"
                              />
                            </div>
                          }
                        </div>
                      }
                      {fileType === FileFieldFileType.Image &&
                        <div id="camera-option">
                          <div className='file-custom-field-checkbox'>
                            <div className="ui fitted checkbox" onClick={() => setDisplayEnabled(!displayEnabled)}>
                              <input className="hidden" type="checkbox" onChange={() => { }} checked={displayEnabled} value="" />
                              <label>Display</label>
                            </div>
                          </div>
                        </div>
                      }
                      {displayEnabled &&
                        <div id='file-display-config'>
                          {!props.tableId &&
                            <div className="input-field-display">
                              <label>Width</label>
                              <input
                                onChange={(e) => onSetDisplayConfig('width', {
                                  value: e.target.value ? parseInt(e.target.value) : null,
                                  unit: displayConfig?.width?.unit,
                                } as FileFieldDisplayDimension)
                                }
                                placeholder='Value'
                                className='field-input right'
                                value={displayConfig?.width?.value || ''}
                                type="number"
                                min={0}
                              />
                              <div className={`field`}>
                                <Dropdown
                                  compact
                                  selection
                                  clearable
                                  value={displayConfig?.width?.unit}
                                  options={[
                                    { key: 1, text: 'px', value: 'px' },
                                    { key: 2, text: 'col', value: 'col' }
                                  ]}
                                  selectOnBlur={false}
                                  onChange={(event, target: any) => onSetDisplayConfig('width', {
                                    value: displayConfig?.width?.value,
                                    unit: target.value,
                                  } as FileFieldDisplayDimension)}
                                />
                              </div>
                            </div>
                          }
                          <div className="input-field-display">
                            <label>Height</label>
                            <input
                              onChange={(e) => onSetDisplayConfig('height', {
                                value: e.target.value ? parseInt(e.target.value) : null,
                                unit: displayConfig?.height?.unit,
                              } as FileFieldDisplayDimension)
                              }
                              onBlur={() => { }}
                              placeholder='Value'
                              className='field-input right'
                              value={displayConfig?.height?.value || ''}
                              type="number"
                              min={0}
                            />
                            <div className={`field`}>
                              <Dropdown
                                compact
                                selection
                                clearable
                                value={displayConfig?.height?.unit}
                                options={[
                                  { key: 1, text: 'px', value: 'px' },
                                  { key: 2, text: 'col', value: 'col' }
                                ]}
                                selectOnBlur={false}
                                onChange={(event, target: any) => onSetDisplayConfig('height', {
                                  value: displayConfig?.height?.value,
                                  unit: target.value,
                                } as FileFieldDisplayDimension)}
                              />
                            </div>
                          </div>
                          <div className="input-field-display" style={{ gridTemplateColumns: '0.5fr 1fr' }}>
                            <label>Aspect Ratio</label>
                            <input
                              onChange={(e) => onSetDisplayConfig('aspectRatio', e.target.value ? e.target.value : null)}
                              value={displayConfig?.aspectRatio}
                              placeholder='Value'
                              className='field-input'
                              type="text"
                            />
                          </div>
                          <div className='field api'>
                            <Dropdown
                              fluid
                              search
                              selection
                              clearable
                              value={displayConfig?.fitMode}
                              selectOnBlur={false}
                              options={Object.entries(FileFieldFitMode).map(([key, value]) => {
                                return {
                                  id: value,
                                  value: value,
                                  text: key,
                                }
                              })}
                              onChange={(event, target: any) => onSetDisplayConfig('fitMode', target.value)}
                              placeholder='Fit Mode'
                            />
                          </div>
                        </div>
                      }
                      <div id='system-generated-option'
                        ref={(element) => {
                          if (!element) return;
                          const container = document.getElementById('file-option-container');
                          if (container) {
                            const rect = container.getBoundingClientRect();
                            element.style.setProperty('width', `${rect.width - 20}px`, 'important');
                          }
                        }}>
                        <div className='file-custom-field-checkbox'>
                          <div className="ui fitted checkbox" onClick={() => handleSystemGenerateFile(!isSytemGenerated)}>
                            <input className="hidden" type="checkbox" onChange={() => { }} checked={!!isSytemGenerated} value="" />
                            <label>System Generated File</label>
                          </div>
                        </div>
                        {isSytemGenerated && <div className='api-select-field'>
                          <span className='title'>Select API</span>
                          <div className='field api'>
                            <Dropdown
                              fluid
                              search
                              selection
                              clearable
                              value={selectedAPI}
                              selectOnBlur={false}
                              options={apiConfigurationOption()}
                              onChange={(event, target: any) => setSelectedAPI(target.value)}
                            />
                          </div>
                          <span className={`title required ${error.template ? 'error' : ''}`}>Select Template</span>
                          <div className={`field ${error.template ? 'error' : ''}`}>
                            <Dropdown
                              fluid
                              search
                              selection
                              clearable
                              value={selectedTemplate}
                              selectOnBlur={false}
                              options={pdfTemplatesOption()}
                              onChange={(event, target: any) => setSelectedTemplate(target.value)}
                            />
                          </div>
                        </div>}
                      </div>
                    </div>
                  </Form.Group>
                </Segment>
              </div>
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions className='borderless'>
          <div className={`string-action-container`}>
            <div>
              <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
              {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
                <Button color='red' className='btn-action create-btn'
                  onClick={() => {
                    props.cancel(props.fieldId, true);
                    props.openDeleteModal(props.fieldId, fieldData.label || '');
                  }}>Delete</Button>
              }
              {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
                <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
              }
            </div>
          </div>
        </Modal.Actions >
      </Modal >
    </>
  }
}
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../../reducers';
import UserComponent from './user';
import { setSubRouteAction, currentRouteAction } from '../../../main/action';
import { IAdminRender } from '../module-registry';

class UserModule {

  name = 'users';

  render: React.FC<IAdminRender> = (props) => {
    const dispatch = useDispatch();
    const companyId = useSelector((state: IRootState) => state.auth.company);
    const rootLink = '/admin/users';


    useEffect(() => {
      dispatch(
        setSubRouteAction({
          mainRoute: 'admin',
          subRoute: 'users'
        })
      );
      dispatch(currentRouteAction(this.name))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <UserComponent
      companyId={companyId}
      rootLink={rootLink}
      allowedAccessAuditLogs={props.allowedAccessAuditLogs}
    />
  }
}


export default UserModule;

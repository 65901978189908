import { useEffect } from 'react';
import {
  getTaskViewAction,
  getFormFieldAction,
  getFormStatusListAction
} from '../action';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetTaskViewData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (company && formId) {
      dispatch(getFormStatusListAction({
        companyId: company,
        formId: formId,
      }));
      dispatch(getFormFieldAction({
        company: company,
        formId: formId,
      }));
      dispatch(getTaskViewAction({
        company: company,
        formId: formId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, formId]);
}
import React from 'react';
import { IField, IInputConfig } from '../../../../../component/admin-module/module/users/interface/field';
import { FieldConstruct } from '../../../field-construct';
import { FieldElement } from '../../field-element';
import { AcceptedProps } from '../../object/interface/accepted-props';

const ArrayType: React.FC<AcceptedProps> = (props) => {
  const maxItems = props.inputConfig.config.maxItems ? { maxItems: props.inputConfig.config.maxItems } : {}
  const items: any = { ...props.inputConfig.config.items, ...maxItems };

  const triggerAutomationFieldUpdate = () => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);

      if (field) {
        const value = FieldConstruct.getFieldDataFromRefs([field], props.forwardedRef);
        props.automationService.didUpdateFieldValue(field, value[props.id] ? value[props.id] : undefined);
      }
    }
  }

  let tempField: IField = {
    id: props.id,
    inputConfig: { ...items } as IInputConfig,
    label: props.label,
    validationSchema: { ...props.validationSchema },
    defaultValue: props.defaultValue,
    accessType: props.accessType,
    reloadOnChange: props.reloadOnChange,
    requiresFieldData: props.requiresFieldData,
    configName: props.configName,
  };

  const field = FieldElement.getDataElement(tempField);

  const Element = field.getFieldComponent();

  return <Element {...field}
    hint={props.hint}
    key={`array-element-${field.id}}`}
    hasIconInside={props.hasIconInside}
    isAddRecord={props.isAddRecord}
    forwardedRef={props.forwardedRef}
    defaultValueElement={false} multiline={true}
    triggerFieldReload={props.triggerFieldReload}
    hasPageChangeRef={props.hasPageChangeRef}
    fieldEnumOptionUrl={props.fieldEnumOptionUrl}
    fieldCollection={props.fieldCollection}
    openDocumentAttachment={props.openDocumentAttachment}
    showErrorMessage={props.showErrorMessage}
    createDataForReference={props.createDataForReference}
    throwValueOutside={props.throwValueOutside}
    sendTriggerToParentContainer={triggerAutomationFieldUpdate}
  />;
}

export default ArrayType;
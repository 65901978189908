import { call, put, takeLatest } from 'redux-saga/effects';
import { IFormFieldGetInput } from './interface/input/form-field-get';
import { GET_COLUMN_VIEW_LIST, GET_FORM_DETAIL, GET_FORM_FIELD_LIST, SAVE_DRAFT_COLUMN_VIEW, PUBLISH_COLUMN_VIEW } from './constant';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { getColumnViewListReturnAction, getFormFieldListReturnAction, getFormDetailReturnAction, publishColumnViewReturnAction, publishColumnViewErrorAction, saveDraftColumnViewReturnAction } from './action';
import { getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../../../common/api-request';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormBuilderInput } from '../../../form-builder/interface/input/form-builder';
import { IColumnViewPublishInput, IColumnViewSaveInput } from './interface/input/column-view-save';
import { SanitizeDocumentList } from '../../../../../../../../common/utils/sanitize-document-list';
export function* getColumnViewList(args: any) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/listColumns?draft=true`, {});
    yield put(getColumnViewListReturnAction({
      columnViewList: req.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormFieldList(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormFieldListReturnAction({
      fieldList: req.data.data as ICustomFieldDetail[],
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormDetail(args: IFormBuilderInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}?draft=true`, {});
    yield put(getFormDetailReturnAction({
      formDetail: req.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* saveDraftColumnView(args: IColumnViewSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const data = args.sanitize ? SanitizeDocumentList.structureOutgoing(args.customViewList) : args.customViewList;
    const req: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}/listColumns`, data);
    yield put(saveDraftColumnViewReturnAction({
      columnViewList: req.data.data,
      columnViewSaveType: args.saveType
    }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishColumnViewErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* publishColumnView(args: IColumnViewPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishColumnViewReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishColumnViewErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* formBuilderSagas() {
  yield takeLatest(GET_COLUMN_VIEW_LIST, getColumnViewList);
  yield takeLatest(GET_FORM_FIELD_LIST, getFormFieldList);
  yield takeLatest(GET_FORM_DETAIL, getFormDetail);
  yield takeLatest(SAVE_DRAFT_COLUMN_VIEW, saveDraftColumnView);
  yield takeLatest(PUBLISH_COLUMN_VIEW, publishColumnView);
}

// All sagas to be loaded
export default formBuilderSagas;
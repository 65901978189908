import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { IDropdownOption } from '../../../../../../../../common/field/component/object/enum-type/checklist';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import { IRootState } from '../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import ButtonContainer from '../../../../button-container';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import { publishTaskViewAction, saveTaskViewAction } from './action';
import { taskViewActionMessage } from './constant';
import { ITaskView, ITaskViewConfig } from './interface/task-view';
import TaskViewConfig from './task-view-config';
import ActionMessage from '../../../../../../../../common/general/action-message';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';
import { useGetTaskViewData } from './hooks/use-get-task-view-data';
import { useSetTaskViewState } from './hooks/use-set-task-view-state';

interface ITaskViewContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const TaskViewContainer: React.FC<ITaskViewContainerProps> = (props) => {
  const defaultTaskConfig = { referToStatus: '', filterField: [], columnHeader: [] };
  const taskViewRef = useRef<ITaskView>({});
  const configRef = useRef<ITaskViewConfig>({ ...defaultTaskConfig });

  const dispatch = useDispatch();
  const match: { params: { formId: string } } = useRouteMatch();
  const history = useHistory();

  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const {
    formStatusList,
    formFieldList,
    saveType,
    taskView,
    triggerSuccessfulSave,
    taskViewLoading
  } = useSelector((state: IRootState) => state.taskView);
  const { company } = useSelector((state: IRootState) => state.auth);

  const [refresher, setRefresher] = useState(0);
  const {
    taskViewConfig,
    setTaskViewConfig,
    selectedStatus,
    setSelectedStatus
  } = useSetTaskViewState({
    defaultTaskConfig,
    formFieldList,
    formStatusList,
    taskView,
    taskViewRef
  });

  const manageSelectedStatus = (id: string) => {
    setSelectedStatus(id);
    const viewConfig = taskViewRef.current[id] || { ...defaultTaskConfig };
    setTaskViewConfig(viewConfig);
  }

  const updateStatusConfig = (status: string, config: ITaskViewConfig) => {
    if (status !== '') {
      const tempTaskView = { ...taskViewRef.current };
      tempTaskView[status] = config;

      formStatusList.forEach((e: IFormStatusWithAction) => {
        if (taskViewRef.current[e.id] && taskViewRef.current[e.id].referToStatus === status) {
          tempTaskView[e.id].columnHeader = config.columnHeader;
          tempTaskView[e.id].filterField = config.filterField;
        }
      })
      taskViewRef.current = tempTaskView;
      setRefresher(refresher + 1);
    }
  }

  const isUsedAsReferer = (): boolean => {
    let isUsed = false;
    formStatusList.forEach((e: IFormStatusWithAction) => {
      if (taskViewRef.current[e.id] && taskViewRef.current[e.id].referToStatus === selectedStatus) {
        isUsed = true;
      }
    })
    return isUsed;
  }

  const getStatusOptionList = () => {
    return formStatusList.map((status: IFormStatusWithAction): IDropdownOption => {
      return { key: uuid(), text: status.name, value: status.id };
    })
  }

  const saveTaskView = (type: string) => {
    if (selectedStatus) {
      taskViewRef.current[selectedStatus] = configRef.current;
    }

    dispatch(
      saveTaskViewAction({
        formId: match.params.formId,
        company: company,
        taskView: { ...taskViewRef.current },
        saveType: type,
        sanitize: true
      })
    )
  }

  useGetTaskViewData({
    dispatch,
    company,
    formId: match.params.formId
  });

  useEffect(() => {
    if (saveType === 'publish') {
      dispatch(publishTaskViewAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveType])

  useEffect(() => {
    if (triggerSuccessfulSave === true) {
      props.hasPageChangeRef.current.hasChange = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSuccessfulSave])

  return (
    <div className={`task-view-main-container`}>
      {taskViewLoading &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <ActionMessage messages={taskViewActionMessage} />
      <TaskViewConfig
        hasPageChangeRef={props.hasPageChangeRef}
        selectedStatus={selectedStatus}
        setSelectedStatus={manageSelectedStatus}
        selectedViewConfig={taskViewConfig}
        statusOption={getStatusOptionList()}
        updateStatusConfig={updateStatusConfig}
        defaultTaskConfig={defaultTaskConfig}
        taskView={taskViewRef}
        isUsedAsReferer={isUsedAsReferer()}
        formFieldList={formFieldList}
        configRef={configRef}
      />
      <ButtonContainer
        cancel={() => {
          changeRoute(
            '/admin/business-process/form',
            hasPageChange || props.hasPageChangeRef.current.hasChange,
            {
              dispatch, history
            })
        }}
        save={() => saveTaskView('draft')}
        publish={() => saveTaskView('publish')}
      />
    </div>
  )
}

export default TaskViewContainer;
import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IOption } from '../../../../../../../../../../common/interface/option';

interface IProps {
  fieldList: ICustomFieldDetail[];
  fieldType: string;
}

export const useSetConfigModalFieldOptions = (props: IProps) => {
  const { fieldList, fieldType } = { ...props };
  const [fieldOptions, setFieldOptions] = useState([] as IOption[]);

  const allowedField = (customFilterFieldType: string, fieldList: ICustomFieldDetail[]): ICustomFieldDetail[] => {
    switch (customFilterFieldType) {
      case 'searchBox':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'string' || field.type === 'number' || field.type === 'enum');
      case 'date':
      case 'dateRange':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'date');
      case 'number':
      case 'numberRange':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'number');
      case 'time':
      case 'timeRange':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'time');
      case 'dropdown':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'enum');
      case 'fileupload':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'file');
      case 'switch':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'bool');
      case 'date-time':
      case 'date-timeRange':
        return fieldList.filter((field: ICustomFieldDetail) => field.type === 'date-time');
      default:
        return [];
    }
  }

  useEffect(() => {
    const fieldOption = [];
    let ctr = 0;
    if (fieldType === 'date' || fieldType === 'dateRange') {
      fieldOption.push({
        key: ctr++,
        value: 'system|||||createdAt|||||Doc. Creation Date',
        text: 'Doc. Creation Date'
      });
      fieldOption.push({
        key: ctr++,
        value: 'system|||||updatedAt|||||Last Update Date',
        text: 'Doc Update Date'
      });
    }

    if (fieldType === 'searchBox') {
      fieldOption.push({
        key: ctr++,
        value: 'system|||||creator|||||Doc. Creator',
        text: 'Doc. Creator'
      });
      fieldOption.push({
        key: ctr++,
        value: 'system|||||updatedBy|||||Last Updated By',
        text: 'Last Updated By'
      });
    }

    if (fieldType === 'dropdown') {
      fieldOption.push({
        key: ctr++,
        value: 'system|||||status|||||Status',
        text: 'Status'
      });
    }

    let newFieldList = allowedField(fieldType, fieldList);
    newFieldList.forEach((field: ICustomFieldDetail) => {
      fieldOption.push({
        key: ctr++,
        value: `${field.type}|||||${field.id}|||||${field.label}`,
        text: field.label || ''
      })
    });

    setFieldOptions(fieldOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fieldList), fieldType])

  return { ...props, fieldOptions, setFieldOptions };
}
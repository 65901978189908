import React from 'react';
import { CustomFilterButton } from './button/custom-filter';
import { GeneralSetupButton } from './button/general-setup';
import { DuplicateCheckerButton } from './button/duplicate-checker';
import { SLAsButton } from './button/slas';
import { DocumentsListButton } from './button/documents-list';
import { ReportsListButton } from './button/reports-list';
import { LabelsButton } from './button/labels';
import { SubMenuList } from './sub-menu-list';
import { ISubMenuListProp } from './interface/sub-menu-list-props';
import { ISubMenuList } from './interface/sub-menu-list';
import { TaskViewButton } from './button/task-view';

const SubMenuButtonArrayList = [
  new GeneralSetupButton(),
  new DuplicateCheckerButton(),
  new SLAsButton(),
  new DocumentsListButton(),
  new CustomFilterButton(),
  new ReportsListButton(),
  new LabelsButton(),
  new TaskViewButton(),
]

export class ConfigurationSubMenuButton {
  registry: { [name: string]: ISubMenuList } = {};

  constructor(types?: ISubMenuList[]) {

    if (!types) {
      types = SubMenuButtonArrayList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getButton: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const { Render } = this.registry[props.type] ? this.registry[props.type] : new SubMenuList();
    return <Render {...props} ></Render>;
  }

  static Render: React.FC<ISubMenuListProp> = (props: ISubMenuListProp) => {
    const headerButton = new ConfigurationSubMenuButton();
    const Render = headerButton.getButton;
    return <Render {...props} />
  }
}
import React from 'react';
import moment from 'moment';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import { v4 as uuid } from 'uuid';

interface IDateTimeField {
  data: IFieldData;
  detail: FieldElement;
}

const DateTimeCustomField: React.FC<IDateTimeField> = (props) => {
  const dataTime = props.data.fields ? props.data.fields[props.detail.id] : '';
  let newDate = new Date(moment(dataTime).format("YYYY-MM-DD HH:mm:ssZ"))
  let formattedTime: string = '';

  if (dataTime) {
    formattedTime = moment(newDate).format('DD MMMM YYYY h:mm A')
  }

  if (dataTime && typeof dataTime === 'object') {
    return dataTime.length > 0 && dataTime.map((date: string, index: number) => {
      let newTimeDate = new Date(moment(date).format("YYYY-MM-DD HH:mm:ssZ"))
      let formatTime: string = '';

      if (date) {
        formatTime = moment(newTimeDate).format('DD MMMM YYYY h:mm A');
      }

      return (<div key={`column-${props.detail.id}-${props.data.id}-${uuid()}`}>
        <div>
          {formatTime || ''}
          {(dataTime.length > 1 && index < dataTime.length - 1) ? ',' : ''}
        </div>
      </div>)
    })
  }

  return (<div>
    <div key={`column-${props.detail.id}-${props.data.id}`}>
      {formattedTime || ''}
    </div>
  </div>)
}

export default DateTimeCustomField;
import {
  GET_USER_LIST,
  GET_USER_FIELD_LIST,
  GET_USER_LIST_RETURN,
  GET_USER_FIELD_LIST_RETURN,
  GET_USER_LIST_ERROR,
  DELETE_USER,
  DELETE_USER_RETURN,
  DELETE_USER_ERROR,
  GET_USER_FIELD_PERMISSION,
  GET_USER_FIELD_PERMISSION_RETURN,
  GET_USER_PROFILE,
  GET_USER_PROFILE_RETURN,
  CREATE_USER,
  CREATE_USER_RETURN,
  CREATE_USER_ERROR,
  GET_USER_DETAIL,
  GET_USER_DETAIL_RETURN,
  GET_USER_DETAIL_ERROR,
  UPDATE_USER,
  UPDATE_USER_RETURN,
  UPDATE_USER_ERROR,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_RETURN,
  UPDATE_USER_STATUS_ERROR,
  GET_ACTIVE_USER_LIST,
  GET_ACTIVE_USER_LIST_RETURN,
  RESET_MESSAGE_RESPONSE,
  RESET_USER_DETAIL,
  GET_USER_FIELD_DETAIL,
  GET_USER_FIELD_DETAIL_ERROR,
  GET_USER_FIELD_DETAIL_RETURN,
  UPDATE_USER_FIELD,
  UPDATE_USER_FIELD_ERROR,
  UPDATE_USER_FIELD_RETURN,
  DELETE_USER_FIELD,
  DELETE_USER_FIELD_RETURN,
  DELETE_USER_FIELD_ERROR,
  CREATE_USER_FIELD_ERROR,
  CREATE_USER_FIELD_RETURN,
  CREATE_USER_FIELD,
  RESET_USER_FIELD_MESSAGE_RESPONSE,
  FILTER_USERS,
  FILTER_USERS_RETURN,
  FILTER_USERS_ERROR,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_RETURN,
  RESET_USER_PASSWORD_ERROR,
  GET_DATATABLE_LIST_RETURN,
  GET_DATATABLE_LIST
} from './constant'
import { IUserListDetailInput } from './interface/input/user-list'
import { UserFieldListDetailInput } from './interface/input/user-field-list';
import { IUserListResponse } from './interface/response/user-list';
import { IUserFieldListResponse } from './interface/response/user-field-list';
import { IUserDeleteDetail } from './interface/input/user-delete';
import { IUserDeleteResponse, IUserDeleteError } from './interface/response/user-delete';
import { IUserCreateDetail } from './interface/input/user-create';
import { IUserCreateResponse, IUserCreateError } from './interface/response/user-create';
import { IUserFieldPermissionDetailInput } from './interface/input/user-field-permission';
import { IUserFieldPermissionResponse } from './interface/response/user-field-permission';
import { IUserProfileDetailInput } from './interface/input/user-profile';
import { IUserProfileResponse } from './interface/response/user-profile';
import { IUserDetailInput } from './interface/input/user-detail';
import { IUserDetailResponse } from './interface/response/user-detail';
import { IUserUpdateDetailInput } from './interface/input/user-update';
import { IUserUpdateResponse, IUserUpdateError } from './interface/response/user-update';
import { IUserUpdateStatusDetailInput } from './interface/input/user-status-update';
import { IUserFieldDetailInput } from './interface/input/user-field-detail';
import { IUserFieldDetailResponse, IUserFieldDetailError } from './interface/response/user-field-detail';
import { IUserFieldDetailUpdateInput } from './interface/input/user-field-update';
import { IUserFieldUpdateError } from './interface/response/user-field-update';
import { IUserFieldDeleteDetailInput } from './interface/input/user-field-delete';
import { IUserFieldDeleteError } from './interface/response/user-field-delete';
import { IUserFieldCreateError } from './interface/response/user-field-create';
import { IUserFieldDetailCreateInput } from './interface/input/user-field-detail-create';
import { IUserFilterDetailInput } from './interface/input/user-filter';
import { IUserFilterResponse } from './interface/response/user-filter';
import { IUserResetPassDetailInput } from './interface/input/user-reset-pass';
import { IDataTableListResponse } from '../data-tables/interface/response/data-table-list';
import { IDataTableListDetailInput } from '../data-tables/interface/input/data-table-list';

export const getUserListAction = (payload: IUserListDetailInput) => {
  return { type: GET_USER_LIST, ...payload };
}

export const getUserListReturnAction = (payload: IUserListResponse) => {
  return { type: GET_USER_LIST_RETURN, ...payload };
}

export const getUserListReturnErrorAction = () => {
  return { type: GET_USER_LIST_ERROR };
}

export const getUserFieldListAction = (payload: UserFieldListDetailInput) => {
  return { type: GET_USER_FIELD_LIST, ...payload };
}

export const getUserFieldListReturnAction = (payload: IUserFieldListResponse) => {
  return { type: GET_USER_FIELD_LIST_RETURN, ...payload };
}

export const deleteUserAction = (payload: IUserDeleteDetail) => {
  return { type: DELETE_USER, ...payload };
}

export const deleteUserReturnAction = (payload: IUserDeleteResponse) => {
  return { type: DELETE_USER_RETURN, ...payload };
}

export const deleteUserErrorAction = (payload: IUserDeleteError) => {
  return { type: DELETE_USER_ERROR, ...payload };
}

export const getUserFieldPermissionAction = (payload: IUserFieldPermissionDetailInput) => {
  return { type: GET_USER_FIELD_PERMISSION, ...payload };
}

export const getUserFieldPermissionReturnAction = (payload: IUserFieldPermissionResponse) => {
  return { type: GET_USER_FIELD_PERMISSION_RETURN, ...payload };
}

export const getUserProfileAction = (payload: IUserProfileDetailInput) => {
  return { type: GET_USER_PROFILE, ...payload };
}

export const getUserProfileReturnAction = (payload: IUserProfileResponse) => {
  return { type: GET_USER_PROFILE_RETURN, ...payload };
}

export const createUserAction = (payload: IUserCreateDetail) => {
  return { type: CREATE_USER, ...payload }
}

export const createUserReturnAction = (payload: IUserCreateResponse) => {
  return { type: CREATE_USER_RETURN, ...payload }
}

export const createUserErrorAction = (payload: IUserCreateError) => {
  return { type: CREATE_USER_ERROR, ...payload }
}

export const getUserDetailAction = (payload: IUserDetailInput) => {
  return { type: GET_USER_DETAIL, ...payload }
}

export const getUserDetailReturnAction = (payload: IUserDetailResponse) => {
  return { type: GET_USER_DETAIL_RETURN, ...payload }
}

export const getUserDetailErrorAction = () => {
  return { type: GET_USER_DETAIL_ERROR }
}

export const updateUserAction = (payload: IUserUpdateDetailInput) => {
  return { type: UPDATE_USER, ...payload }
}

export const updateUserReturnAction = (payload: IUserUpdateResponse) => {
  return { type: UPDATE_USER_RETURN, ...payload }
}

export const updateUserErrorAction = (payload: IUserUpdateError) => {
  return { type: UPDATE_USER_ERROR, ...payload }
}

export const updateUserStatusAction = (payload: IUserUpdateStatusDetailInput) => {
  return { type: UPDATE_USER_STATUS, ...payload }
}

export const updateUserStatusReturnAction = (payload: IUserUpdateResponse) => {
  return { type: UPDATE_USER_STATUS_RETURN, ...payload }
}

export const updateUserStatusErrorAction = (payload: IUserUpdateError) => {
  return { type: UPDATE_USER_STATUS_ERROR, ...payload }
}

export const getActiveUserListAction = (payload: IUserListDetailInput) => {
  return { type: GET_ACTIVE_USER_LIST, ...payload }
}

export const getActiveUserListReturnAction = (payload: IUserListResponse) => {
  return { type: GET_ACTIVE_USER_LIST_RETURN, ...payload }
}

export const resetMessageResponseAction = (payload: {}) => {
  return { type: RESET_MESSAGE_RESPONSE, ...payload }
}

export const resetUserDetailAction = () => {
  return { type: RESET_USER_DETAIL }
}

export const getUserFieldDetailAction = (payload: IUserFieldDetailInput) => {
  return { type: GET_USER_FIELD_DETAIL, ...payload }
}

export const getUserFieldDetailReturnAction = (payload: IUserFieldDetailResponse) => {
  return { type: GET_USER_FIELD_DETAIL_RETURN, ...payload }
}

export const getUserFieldDetailErrorAction = (payload: IUserFieldDetailError) => {
  return { type: GET_USER_FIELD_DETAIL_ERROR, ...payload }
}

export const updateUserFieldAction = (payload: IUserFieldDetailUpdateInput) => {
  return { type: UPDATE_USER_FIELD, ...payload }
}

export const updateUserFieldErrorAction = (payload: IUserFieldUpdateError) => {
  return { type: UPDATE_USER_FIELD_ERROR, ...payload }
}

export const updateUserFieldReturnAction = () => {
  return { type: UPDATE_USER_FIELD_RETURN }
}

export const deleteUserFieldAction = (payload: IUserFieldDeleteDetailInput) => {
  return { type: DELETE_USER_FIELD, ...payload }
}

export const deleteUserFieldReturnAction = () => {
  return { type: DELETE_USER_FIELD_RETURN }
}

export const deleteUserFieldErrorAction = (payload: IUserFieldDeleteError) => {
  return { type: DELETE_USER_FIELD_ERROR, ...payload }
}

export const createUserFieldAction = (payload: IUserFieldDetailCreateInput) => {
  return { type: CREATE_USER_FIELD, ...payload }
}

export const createUserFieldReturnAction = () => {
  return { type: CREATE_USER_FIELD_RETURN }
}

export const createUserFieldErrorAction = (payload: IUserFieldCreateError) => {
  return { type: CREATE_USER_FIELD_ERROR, ...payload }
}

export const resetUserFieldAction = () => {
  return { type: RESET_USER_FIELD_MESSAGE_RESPONSE }
}

export const filterUsersAction = (payload: IUserFilterDetailInput) => {
  return { type: FILTER_USERS, ...payload }
}

export const filterUsersReturnAction = (payload: IUserFilterResponse) => {
  return { type: FILTER_USERS_RETURN, ...payload }
}

export const filterUsersErrorAction = (message: string) => {
  return { type: FILTER_USERS_ERROR, message }
}

export const resetUserPasswordAction = (payload: IUserResetPassDetailInput) => {
  return { type: RESET_USER_PASSWORD, ...payload }
}

export const resetUserPasswordReturnAction = () => {
  return { type: RESET_USER_PASSWORD_RETURN }
}

export const resetUserPasswordErrorAction = (message: string) => {
  return { type: RESET_USER_PASSWORD_ERROR, message }
}

export const getDataTableListAction = (payload: IDataTableListDetailInput) => {
  return { type: GET_DATATABLE_LIST, ...payload }
}

export const getDataTableListReturnAction = (payload: IDataTableListResponse) => {
  return { type: GET_DATATABLE_LIST_RETURN, ...payload }
}
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IFieldPermissionSettings } from '../..';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';
import { IConsolidateTableColumn, IConsolidateTableConfig, IPermissionFieldCondition, IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import { IRulesPermission } from '../../../interface/rules';
import ConditionConnector, { TConditionConnectorType } from '../utils/condition-connector';
import ConditionConfig from './condition';
import DefaultFrontendAutomationConfig from './config';
import { StructureSettingsConfig } from '../utils/structure-settings-config';
import { hideValueAndFieldOption } from '../../../../../../../../../common/utils/field-operator';
import { useDispatch } from 'react-redux';
import { saveFormPermissionErrorAction } from '../../../action';
import AggregateConfig from './aggregate';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';

export type TConfigOption = 'permission' | 'value' | 'clear';
export type TConfigType = 'onTrue' | 'onFalse';
export type TRulesPermission = keyof IRulesPermission;
export type TConsolidateTableColumn = keyof IConsolidateTableColumn;
export type TConsolidateTableConfig = keyof IConsolidateTableConfig;

const DefaultFrontendAutomation: React.FC<any> = (props: IFieldPermissionSettings) => {
  const dispatch = useDispatch();

  const [refresher, setRefresher] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [tab, setTab] = useState<'condition' | 'aggregate'>('condition');

  const fieldLabel = (props.fieldData.label || props.selectedFieldSection.title) || 'Untitled Section';

  const permissionConfigRef = useRef<IPermissionFieldFilterConfig | undefined>(undefined);
  const consolidateTableRef = useRef<IConsolidateTableConfig | undefined>(undefined);
  const defaultvalueRef = useRef<any>({ trueValue: {}, falseValue: {} });

  const removeConfig = () => {
    props.updatePermissionConfig(undefined, props.isTablePermission ? 'table' : null, props.type);
    props.cancel();
  }

  const showDeleteBtn = (): boolean => {
    let show = false;
    props.selectedFieldSection && props.selectedFieldSection.permissions &&
      props.selectedFieldSection.permissions.forEach(e => {
        if (props.fieldData && props.fieldData.id === e.fieldId) {
          show = !!e.config;
        }
      })
    return show;
  }

  const cancelConfig = () => {
    props.cancel();
  }

  const isConfigValid = () => {
    const data: any = { ...permissionConfigRef.current };
    if (data) {
      const error: boolean[] = data.condition && data.condition.map((condition: IPermissionFieldCondition) => {
        const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(condition.operator);
        const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(condition.operator);
        let hasError = false;
        if (!(hideValueOption && hideFieldOption)) {
          if (condition.compareType === 'value') {
            hasError = !condition.compareValue;
          } else {
            hasError = !condition.compareField.fieldId;
          }
        }
        return !condition.fieldId || !condition.operator || hasError;
      });
      return error.every(e => !e);
    }
    return true;
  }

  const saveConfig = () => {
    if (!isConfigValid()) {
      dispatch(saveFormPermissionErrorAction('Configuration is not complete. Field, Operator, and Compare Field/Value cannot be empty.'));
      return;
    }
    const data = { ...permissionConfigRef.current };
    props.updatePermissionConfig(data, props.isTablePermission ? 'table' : null);
    props.cancel();
  }

  const updateJoinCondition = (value: TConditionConnectorType) => {
    if (permissionConfigRef.current) {
      permissionConfigRef.current.joinCondition = value;
    }
    setRefresher(refresher + 1);
  }

  const showAggregateTab = () => {
    return props.fieldList.filter(e =>
      e.configName === IConfigName.Referenced && e.config && e.config.multiselect
    ).length > 0 && props.fieldData.type === 'table';
  }

  useEffect(() => {
    const config = new StructureSettingsConfig();
    const { join, tempOnFalseConfig, tempOnTrueConfig } = config.setConfig({
      fieldData: props.fieldData,
      isSectionPermission: props.isSectionPermission,
      isTablePermission: props.isTablePermission,
      selectedFieldSection: props.selectedFieldSection,
      selectedStatus: props.selectedStatus,
      selectedTableSection: props.selectedTableSection
    });
    const conditionRule = { onFalse: tempOnFalseConfig, onTrue: tempOnTrueConfig };
    permissionConfigRef.current = {
      joinCondition: join,
      condition: [],
      conditionRule: conditionRule
    };
    if (props.fieldData && (tempOnFalseConfig.setValue || tempOnTrueConfig.setValue)) {
      const values: { [id: string]: any } = {};
      const tempFields: any = [];
      const tempCurrentField: any = { ...props.fieldData, inputConfig: { type: props.fieldData.type } };
      if (tempOnFalseConfig.setValue) {
        values['onFalse'] = tempOnFalseConfig.setValue.value || null;
        tempFields.push({ ...tempCurrentField, id: 'onFalse' })
      }
      if (tempOnTrueConfig.setValue) {
        values['onTrue'] = tempOnTrueConfig.setValue.value || null;
        tempFields.push({ ...tempCurrentField, id: 'onTrue' })
      }
    }
    return () => {
      setTab('condition');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedStatus, props.open, props.selectedFieldSection, props.fieldData]);

  useEffect(() => {
    const config = new StructureSettingsConfig();
    if (tab === 'aggregate' && props.type === 'table') {
      const { columns, referenceKeyPath, sourceTableId } = config.setTableConsolidateTableConfig(
        { ...props.selectedStatus },
        props.fieldData
      );
      consolidateTableRef.current = {
        columns: columns,
        referenceKeyPath: referenceKeyPath,
        sourceTableId: sourceTableId
      }
      setRefresher(refresher + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.selectedStatus, props.fieldData, tab])

  const joinCondition: TConditionConnectorType = permissionConfigRef.current ? permissionConfigRef.current.joinCondition : 'and';

  return <>
    <div className={`frontend-automation-container`}>
      <div className='automation-header'>
        <label className='field-label'>{fieldLabel.toUpperCase()}</label>
        {showAggregateTab() &&
          <div className='automation-header-tab-main'>
            <span className='line' />
            <div className='automation-header-tab'>
              <span className={`tab ${tab === 'condition' ? 'active' : ''}`} onClick={() => setTab('condition')}>Condition</span>
              <span className={`tab  ${tab === 'aggregate' ? 'active' : ''}`} onClick={() => setTab('aggregate')}>Aggregate</span>
            </div>
          </div>
        }
      </div>
      {tab === 'aggregate' &&
        <AggregateConfig
          {...props}
          consolidateTableRef={consolidateTableRef}
        />
      }
      {tab === 'condition' &&
        <div className='permission-settings-main'>
          <ConditionConnector
            type={joinCondition}
            setConditionConnector={updateJoinCondition}
          />
          <div className='permission-settings-container'>
            <div className='permission-settings'>
              <ConditionConfig {...props}
                permissionConfigRef={permissionConfigRef}
              />
              <DefaultFrontendAutomationConfig
                {...props}
                permissionConfigRef={permissionConfigRef}
                defaultvalueRef={defaultvalueRef}
              />
            </div>
          </div>
          <div className="button-container">
            <Button className='btn-success' onClick={saveConfig}>Save</Button>
            {showDeleteBtn() &&
              <Button color='red' className='btn-action' onClick={() => setShowDeleteConfirm(true)}>Delete</Button>
            }
            <Button className='btn-default' onClick={cancelConfig}>Cancel</Button>
          </div>
        </div>
      }

    </div>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      confirmButtonColor={'green'}
      cancelButtonColor={'grey'}
      close={() => setShowDeleteConfirm(false)}
      open={showDeleteConfirm}
      confirm={removeConfig}
      cancel={() => setShowDeleteConfirm(false)}
      headerText="Confirmation"
      detailText={`Dynamic permission for this field will be deleted . Do you still want to proceed?`}
      type='warning'
    />
  </>
}

export default DefaultFrontendAutomation;
import React from 'react';
import { IButtonList } from '../../admin-module/utils/header-menu-button/interface/button-list';
import { IButtonListProp } from '../../admin-module/utils/header-menu-button/interface/button-list-props';
import { PortalButtonList } from './button-list';
import { BusinessProcessButton } from './button/business-process';
import { StakeholderButton } from './button/stakeholder';
import { UsersButton } from './button/users';

const ButtonArrayList = [
  new StakeholderButton(),
  new UsersButton(),
  new BusinessProcessButton()
]

export class PortalHeaderMenu {
  registry: { [name: string]: IButtonList } = {};

  constructor(types?: IButtonList[]) {

    if (!types) {
      types = ButtonArrayList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getButton: React.FC<IButtonListProp> = (props: IButtonListProp) => {
    const { Render } = this.registry[props.type] ? this.registry[props.type] : new PortalButtonList();
    return <Render {...props}></Render>;
  }

  static Render: React.FC<IButtonListProp> = (props: IButtonListProp) => {
    const headerButton = new PortalHeaderMenu();
    const Render = headerButton.getButton;
    return <Render {...props} />
  }

}
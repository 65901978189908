import React from 'react';
import { timeAgo } from '../utils/get-time-ago';
import timezone from 'moment-timezone';

interface ITimeAgoProps {
  date: string;
  className?: string;
}

const TimeAgo: React.FC<ITimeAgoProps> = (props) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const title = timezone(props.date).tz(tz).format('MMMM D, YYYY, h:mm A').toString();

  return <>
    <span title={title} className={props.className}>
      {timeAgo(props.date, tz)}
    </span>
  </>
};

export default TimeAgo;
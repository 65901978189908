import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"
import { Button, Form, Image } from 'semantic-ui-react-bpm';
import useDeviceDetect from '../../../common/general/device-detect';
import Message from '../../../common/general/response-message';
import { IRootState } from '../../../reducers';
import AuthFooterComponent from '../footer';
import { checkAuthTokenAction } from '../token-checker/action';
import './app.scss';
import { RESET_PASSWORD } from './constant';
import resetPassMessage from './message';

const ForgotPassword: React.FC = () => {
  const { isMobile, isTablet, isPortrait, width, height } = useDeviceDetect();
  const defaultViewPassword = { newPassword: false, confirmPassword: false };
  const defaultPassword = { newPassword: '', confirmPassword: '' };

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [messageResponse, setMessageResponse] = useState('');
  const [openMessageResponse, setOpenMessageResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [viewPassword, setViewPassword] = useState(defaultViewPassword);
  const [password, setPassword] = useState(defaultPassword);

  const data = useSelector((state: IRootState) => state.resetPassword);
  const { token } = useSelector((state: IRootState) => state.auth);

  const handleSubmit = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (password.confirmPassword === password.newPassword) {
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          token: token,
          newPassword: password.newPassword
        }
      });
    } else {
      setMessageResponse('Password and Confirm Password does not match');
      setIsError(true);
      setOpenMessageResponse(true);
    }
  }

  const closeMessageResponse = () => {
    setOpenMessageResponse(false);
  }

  const handleViewPassword = (name: 'confirmPassword' | 'newPassword', value: boolean) => {
    const tempViewPassword = { ...viewPassword };
    tempViewPassword[name] = value;
    setViewPassword(tempViewPassword);
  }

  const handleSetPassword = (name: 'confirmPassword' | 'newPassword', value: string) => {
    let tempPassword = { ...password };
    tempPassword[name] = value;
    setPassword(tempPassword);
  }

  const buttonSaveText = () => {
    let text = 'Save';
    if (data.resetPasswordLoading && !token) {
      text = 'Saving...';
    }
    if (token && !data.resetPasswordLoading && data.resetPasswordMessage === resetPassMessage.resetPasswordSuccess) {
      text = 'Logging in...'
    }
    return text;
  }

  useEffect(() => {
    if (data.resetPasswordMessage) {
      setMessageResponse(data.resetPasswordMessage);
      setIsError(data.resetPasswordError);
      setOpenMessageResponse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.resetPasswordMessage, data.resetPasswordError]);

  useEffect(() => {
    if (token && data.resetPasswordMessage === resetPassMessage.resetPasswordSuccess) {
      const timer = setTimeout(() => {
        dispatch(checkAuthTokenAction(data.token))
        history.push('/');
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.resetPasswordMessage, token, history]);

  return <div className='reset-password-container'>
    <Image src={require('../auth-background.png')} className='background' />
    <div id='auth-main-container' className={`auth-main ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'} ${isPortrait ? 'portrait' : 'landscape'}
         ${(width <= 340 || height <= 340) ? 'small' : ''} ${!isPortrait && height <= 480 ? 'small-landscape' : ''}`}>
      <div id='auth-wrapper' className='forgot-password'>
        <div className='login-logo column'>
          <div className={`logo ${(isMobile || isTablet) && isPortrait ? 'border-x' : 'border-y'} `}>
            <Image className='image' src={require('../mobbiz-logo.png')} />
          </div>
        </div>
        <div id='auth-form' className={`column ${token && data.resetPasswordMessage === resetPassMessage.resetPasswordSuccess ? 'disabled' : ''}`}>
          <div id='auth-message' className='auth-message-dark' style={{ marginBottom: '30px' }}>
            <Message message={messageResponse} open={openMessageResponse} close={closeMessageResponse} error={isError} />
          </div>
          <Form size='small' className='form'>
            <Form.Field className='input-container'>
              <div className="ui icon input">
                <input type={`${!viewPassword.newPassword ? 'password' : 'text'}`} autoComplete='off' value={password.newPassword}
                  className='input-field' placeholder='New Password' onChange={(e) => handleSetPassword('newPassword', e.target.value)} />
                <i aria-hidden="true" className={`${!viewPassword.newPassword ? 'eye slash' : 'eye'} link icon`} onClick={() => handleViewPassword('newPassword', !viewPassword.newPassword)} />
              </div>
            </Form.Field>
            <Form.Field className='input-container'>
              <div className="ui icon input">
                <input type={`${!viewPassword.confirmPassword ? 'password' : 'text'}`} autoComplete='off' className='input-field'
                  placeholder='Confirm Password' value={password.confirmPassword} onChange={(e) => handleSetPassword('confirmPassword', e.target.value)} />
                <i aria-hidden="true" className={`${!viewPassword.confirmPassword ? 'eye slash' : 'eye'} link icon`} onClick={() => handleViewPassword('confirmPassword', !viewPassword.confirmPassword)} />
              </div>
            </Form.Field>
            <Form.Field className='action-container'>
              <div className='action'>
                <div className='login'>
                  {password.confirmPassword && password.newPassword &&
                    <Button floated='left' type='button' id='login-btn' className='login-btn'
                      disabled={data.resetPasswordLoading} onClick={() => handleSubmit()}>
                      {buttonSaveText()}
                    </Button>
                  }
                </div>
              </div>
            </Form.Field>
          </Form>
        </div>
      </div>
    </div>
    <AuthFooterComponent />
  </div>;
}

export default ForgotPassword;

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';
import { IDisplayComponent, IDisplayDataConfigAssignments } from '../interface/display-data-assignments';
import AppliedDisplayDataList from './list/applied';
import { IFormStatusWithAction } from '../../../../workflow/interface/form-status';
import { IOption } from '../../../../../../../../../common/interface/option';
import { IDisplayData, IDisplayDataConfigKeypaths } from '../interface/display-data';

interface IAppliedDisplayData {
  displayDataComponents: IDisplayComponent[];
  displayDataApplied: IDisplayDataConfigAssignments[];
  statusList: IFormStatusWithAction[];
  displayDataList: IDisplayData[];
  addNewAppliedDisplayData(appLabel?: IDisplayDataConfigAssignments): void;
  editAppliedDisplayData(value: IDisplayDataConfigAssignments, index: number): void;
  deleteAppliedDisplayData(value: IDisplayDataConfigAssignments): void;
  formId: string;
  displayDataConfigKeypath: IDisplayDataConfigKeypaths[];
}

const AppliedDisplayData: React.FC<IAppliedDisplayData> = (props) => {

  const [showAddButton, setShowAddButton] = useState(true);

  const componentOptions: IOption[] = props.displayDataComponents.map((component, index) => {
    return {
      key: index,
      text: component.name,
      value: component.value || '',
    }
  })
  componentOptions.unshift({ key: -1, text: 'All Components', value: 'all' });

  const statusOptions: IOption[] = props.statusList.map((status, index) => {
    return {
      key: index,
      text: status.name,
      value: status.id,
    }
  });
  statusOptions.unshift({ key: -1, text: 'All Statuses', value: 'all' });

  const displayDataOptions = props.displayDataList.map((data, index) => {
    return {
      key: index,
      text: data.name,
      value: data.id,
    }
  });

  const addNewAppliedDisplayData = () => {
    const initialAppliedDisplayData: IDisplayDataConfigAssignments = {
      statusId: '',
      component: '',
      configId: '',
      formId: props.formId,
      new: true,
    };
    props.addNewAppliedDisplayData(initialAppliedDisplayData)
    setShowAddButton(false);
  }

  return <div className='display-data-container'>
    <div className='applied-display-data-container'>
      <table className='applied-display-data-table'>
        <thead>
          <tr>
            <th className='th-group h-component'>Component</th>
            <th className='th-group h-status'>Status</th>
            <th className='th-group h-label'>Label</th>
            <th className='th-group h-contents'>Label Contents</th>
            <th className='th-group'></th>
          </tr>
        </thead>
        <tbody>
          {props.displayDataApplied.map((appliedLabel, idx) => {
            if (appliedLabel.deleted) {
              return null;
            }
            return <tr key={`applied-display-data-${idx}`}>
              <AppliedDisplayDataList
                displayDataApplied={appliedLabel}
                displayDataComponents={props.displayDataComponents}
                statusOptions={statusOptions || []}
                displayDataOptions={displayDataOptions}
                editAppliedDisplayData={props.editAppliedDisplayData}
                index={idx}
                setShowAddButton={setShowAddButton}
                componentOptions={componentOptions}
                deleteAppliedDisplayData={props.deleteAppliedDisplayData}
                displayDataConfigKeypath={props.displayDataConfigKeypath}
                displayDataList={props.displayDataList}
              />
            </tr>
          })}
          {showAddButton &&
            <tr>
              <td>
                <Button onClick={addNewAppliedDisplayData} className='add-row'>
                  <SVGWrapper iconName='plus' height='15' width='15' color='#fff' />
                </Button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
}

export default AppliedDisplayData;
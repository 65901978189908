import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Route } from 'react-router-dom';
import ConfigurationRedirect from './configuration-redirect';
import ConfigurationRoute from './configuration-route';
import NoIdRedirect from '../../no-id-redirect';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { useSetThirdLevelRoute } from '../../../../hooks/use-set-third-level-route';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
  thirdLevelRoute: string;
  formId: string;
}

interface IConfigurationRouteProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Configuration {

  name = 'configuration';

  render: React.FC<IConfigurationRouteProps> = (props) => {
    const dispatch = useDispatch();
    const match: IRouteMatch = useRouteMatch();

    useSetThirdLevelRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      thirdLevelRoute: this.name
    });

    return (
      <>
        <Route exact path={`${match.path}`}>
          <NoIdRedirect />
        </Route>
        <ConfigurationRoute hasPageChangeRef={props.hasPageChangeRef} />
        <Route path={`${match.path}/:formId`} exact>
          <ConfigurationRedirect />
        </Route>
      </>
    );
  }
}

export default Configuration;

import React, { RefObject } from 'react';
import { Segment, Dropdown, Button, Image } from 'semantic-ui-react-bpm';
import { useSelector } from 'react-redux';
// import { resetMessageResponseAction } from '../action';
// import { useHistory } from 'react-router-dom';
import { IRootState } from '../../../../../reducers';
import { icons } from '../../../../../common/icons';
import { IField } from '../interface/field';
import DynamicFilterField from './dynamic-filter-field';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
// import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
// import { setOpenAdminChatboxAction } from '../../../../main/action';
// import { getAdminHistoryLogsAction } from '../../../history/action';

interface IUserFilter {
  filterUsers(): void;
  clearFilters(): void;
  showClearFilter: boolean;
  dynamicRefs: any;
  searchableDynamicFields: IField[];
  rootLink: string;
  gridColumns: number;
  filterRef: RefObject<HTMLDivElement>;
  allowedAccessAuditLogs: boolean;
  isOpenChatbox: boolean;
  fields: IField[];
  profileFilter: IField[];
}

export class UserFilter {

  static Render: React.FC<IUserFilter> = (props) => {
    // const dispatch = useDispatch();
    // const history = useHistory();

    // const user = useSelector((state: IRootState) => state.user);
    const companyId = useSelector((state: IRootState) => state.auth.company);
    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const userTypeOption = props.profileFilter && props.profileFilter.filter(e => e.id === 'profile')
      .map(profile => {
        const { inputConfig: { config: { items } } }: any = { ...profile };
        return items.map((e: any, i: any) => { return { key: i, text: e.label, value: e.id } })
      })[0];

    // const openCreateUserModal = () => {
    //   history.push(`${props.rootLink}/add-user`)
    //   dispatch(resetMessageResponseAction({}));
    // }

    // const setIsOpenChatbox = () => {
    //   dispatch(setOpenAdminChatboxAction(true));
    //   dispatch(getAdminHistoryLogsAction({
    //     company: companyId,
    //     categories: ['Users', 'UsersFields'],
    //     fields: props.fields,
    //     users: user.userList,
    //     tz
    //   }));
    // }

    const filterUserTypeValue = props.dynamicRefs && props.dynamicRefs.current['profile'] ? props.dynamicRefs.current['profile'].value : undefined;
    const filterStatusValue = props.dynamicRefs && props.dynamicRefs.current['active'] ? props.dynamicRefs.current['active'].value : undefined;
    const searchValue = props.dynamicRefs && props.dynamicRefs.current['search'] ? props.dynamicRefs.current['search'].value : undefined;

    return <Segment className='borderless filter-wrapper'>
      <div id='user-filter-container'>
        <div className='search-filter'>
          <div className="ui input">
            <input
              defaultValue={searchValue || ''}
              placeholder='Search'
              onChange={(e) => {
                props.dynamicRefs.current['search'].value = e.target.value
                props.filterUsers()
              }}
              ref={(curRef: any) => {
                if (curRef) {
                  if (props.dynamicRefs) {
                    curRef.fieldType = 'string';
                    return props.dynamicRefs.current['search'] = curRef
                  }
                }
              }}
            />
          </div>
        </div>
        <div ref={props.filterRef} className='dropdown-filters' style={{ gridTemplateColumns: `repeat(${props.gridColumns}, 1fr)` }}>
          <Button style={{ display: 'none' }} className="search-users" primary icon={{ name: 'search' }} />
          <>
            <Dropdown
              search
              options={userTypeOption || []}
              placeholder='User Type'
              selection
              selectOnBlur={false}
              value={filterUserTypeValue}
              onChange={(e, target: any) => {
                props.dynamicRefs.current['profile'].value = target.value;
                props.filterUsers()
              }}
              text={!filterUserTypeValue ? 'User Type' : undefined}
            />
            <input type='hidden'
              defaultValue={filterUserTypeValue || ''}
              ref={(curRef: any) => {
                if (curRef) {
                  if (props.dynamicRefs) {
                    curRef.fieldType = 'enum';
                    return props.dynamicRefs.current['profile'] = curRef
                  }
                }
              }} />
          </>
          <>
            <Dropdown
              options={[
                { key: 1, text: 'Active', value: "true" },
                { key: 2, text: 'Inactive', value: "false" },
              ]}
              popperContainer={DropdownPopperOption}
              onChange={(e, target: any) => {
                props.dynamicRefs.current['active'].value = target.value;
                props.filterUsers()
              }}
              placeholder='Status'
              selection
              value={filterStatusValue}
              selectOnBlur={false}
              search
              text={!filterStatusValue ? 'Status' : undefined}
            />
            <input type='hidden'
              defaultValue={filterStatusValue || ''}
              ref={(curRef: any) => {
                if (curRef) {
                  if (props.dynamicRefs) {
                    curRef.fieldType = 'bool';
                    return props.dynamicRefs.current['active'] = curRef
                  }
                }
              }} />
          </>
          <DynamicFilterField
            triggerFilter={props.filterUsers}
            dynamicRefs={props.dynamicRefs}
            customFieldList={props.searchableDynamicFields}
            company={companyId}
          />
        </div>
      </div>
      {
        props.showClearFilter &&
        <Button className='filter-users' primary onClick={() => props.clearFilters()}>
          <Image src={icons.white.clearFilters} width='30px' />
        </Button>
      }
      {/* {props.allowedAccessAuditLogs && <Button className={`chat-icon ${!props.isOpenChatbox ? 'inactive' : ''}`} primary onClick={setIsOpenChatbox}>
        <SVGWrapper iconName='adminChatIcon' width='20' color={`${!props.isOpenChatbox ? '#3a85a8' : '#fff'}`} />
      </Button>
      }
      <Button type='button' className={`add-button ${props.allowedAccessAuditLogs ? 'hasAuditLogAccess' : ''}`}
        primary size={"large"} floated={"right"} onClick={() => openCreateUserModal()}>Add User</Button> */}
    </Segment >
  }

}
import React from 'react';
import Section from './section';
import { IFormBuilderSection } from '../../interface/section';
import PlainDragDrop from './plain-drag-drop';
import PlainSection from './plain-section';
import { DragFieldObject, DragSectionObject } from '../../section-field-manager/interface/section-update-config';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ISectionContainer {
  sectionList: IFormBuilderSection[];
  changeSectionSequence(dragItem: DragSectionObject, dropContainerId: string): void;
  addFieldToSection(dragItem: DragFieldObject, sectionId: string): void;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  openSettings(selectedSection: IFormBuilderSection): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  addFieldOnPlainSection(dragItem: DragFieldObject): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const SectionContainer: React.FC<ISectionContainer> = (props) => {

  return <div className="section-container">
    {
      props.sectionList.map((section: IFormBuilderSection) => {
        if (section.style === 'plain') {
          return <PlainSection
            key={`section-${section.sectionId}`}
            section={section}
            changeSectionSequence={props.changeSectionSequence}
            addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
            editFieldConfig={props.editFieldConfig}
            openDeleteConfirmationModal={props.openDeleteConfirmationModal}
            unlinkField={props.unlinkField}
            unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
            sortFieldSection={props.sortFieldSection}
            moveField={props.moveField}
            bpImage={props.bpImage}
          />
        }
        return <Section
          key={`section-${section.sectionId}`}
          section={section}
          changeSectionSequence={props.changeSectionSequence}
          addFieldToSection={props.addFieldToSection}
          addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
          openSettings={props.openSettings}
          editFieldConfig={props.editFieldConfig}
          openDeleteConfirmationModal={props.openDeleteConfirmationModal}
          unlinkField={props.unlinkField}
          unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
          sortFieldSection={props.sortFieldSection}
          moveField={props.moveField}
          bpImage={props.bpImage}
        />;
      })
    }
    <PlainDragDrop
      addFieldOnPlainSection={props.addFieldOnPlainSection}
    />
  </div>
}

export default SectionContainer;

import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react-bpm';

interface Props {
  id?: string;
  className?: string;
  style?: any;
  icon?: any;
  fluid?: boolean;
  clearable?: boolean;
  selection?: boolean;
  search?: boolean;
  disabled?: boolean;
  searchInput?: any;
  multiple?: boolean;
  selectOnBlur?: boolean;
  placeholder?: string;
  isDatatable?: boolean;
  value: any;
  options: DropdownItemProps[];
  onOpen?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onClose?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onSearchChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onBlur?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

let border: string = '';

const DropdownComponent: React.FC<Props> = (props) => {

  const onOpen = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const element: HTMLElement | null | undefined = document.getElementById(`${props.id}`)?.parentElement?.parentElement;
    if (element && element.tagName === 'TD' && !props.isDatatable) {
      const width = element.offsetWidth
      const borderLeft = getComputedStyle(element).borderLeft;
      const borderBottom = getComputedStyle(element).borderLeft;
      border = getComputedStyle(element).border;
      element.style.position = 'absolute';
      element.style.border = 'unset';
      element.style.borderBottom = borderBottom;
      element.style.borderLeft = borderLeft;
      element.style.width = `${width}px`;
    }
    props.onOpen && props.onOpen(event, data);
  }

  const onClose = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const element: HTMLElement | null | undefined = document.getElementById(`${props.id}`)?.parentElement?.parentElement;
    if (element && element.tagName === 'TD' && !props.isDatatable) {
      element.style.position = 'unset';
      element.style.border = border;
    }
    props.onClose && props.onClose(event, data);
  }

  const {
    id = '',
    className = '',
    style,
    icon,
    fluid = false,
    clearable = false,
    selection = false,
    search = false,
    disabled = false,
    searchInput,
    multiple = false,
    selectOnBlur = false,
    placeholder = '',
    value = '',
    options = undefined,
    onChange,
    onFocus,
    onSearchChange,
    onBlur } = { ...props };

  return <>
    <Dropdown
      id={id}
      className={className}
      style={style}
      icon={icon}
      fluid={fluid}
      clearable={clearable}
      selection={selection}
      search={search}
      disabled={disabled}
      searchInput={searchInput}
      multiple={multiple}
      selectOnBlur={selectOnBlur}
      placeholder={placeholder}
      value={value}
      options={options}
      onOpen={(event, data) => onOpen(event, data)}
      onChange={(event, data) => onChange && onChange(event, data)}
      onClose={(event, data) => onClose(event, data)}
      onFocus={(event, data) => onFocus && onFocus(event, data)}
      onSearchChange={(event, data) => onSearchChange && onSearchChange(event, data)}
      onBlur={(event, data) => onBlur && onBlur(event, data)}
      text={!value && !multiple && placeholder ? placeholder : !value && !multiple ? ' ' : undefined}
    />
  </>
}
export default React.memo(DropdownComponent);
import { v4 as uuid } from 'uuid';
import { bprSystemFields } from '../../component/admin-module/module/business-process/module/configuration/module/bp-relationship/constant';
import { IApiFormRelatedField, IBPRSystemField, IConvertBPRSystemField, IFormField, IFormRelated, IFormRelatedField, IRelatedField } from '../../component/admin-module/module/business-process/module/configuration/module/bp-relationship/interface/bp-relationship-field';
import { IReference } from '../../component/admin-module/module/business-process/module/configuration/module/bp-relationship/interface/form';
import { ISelectedRelation } from '../../component/admin-module/module/business-process/module/configuration/module/bp-relationship/interface/selected-relation';
import { IRelatedFormField } from '../../component/document-module/module/form/interface/related-form';

export class SanitizeBPR {
  static structureOutgoing = (relatedForms: IFormRelated[]): IApiFormRelatedField[] => {
    const relatedFormsContainer: IApiFormRelatedField[] = [];
    relatedForms.forEach((relation: ISelectedRelation) => {
      let headerField: IRelatedField = relation.headerField.map((e: IFormRelatedField) => {
        return {
          keyPath: SanitizeBPR.isBPRSystemField(e.id) ? e.id : `fields.${e.id}`,
          showLabel: e.showLabel ? true : false
        }
      })[0];
      let bodyFields: IRelatedField[] = relation.fields.map((e: IFormRelatedField) => {
        return { keyPath: SanitizeBPR.isBPRSystemField(e.id) ? e.id : `fields.${e.id}`, showLabel: e.showLabel ? true : false }
      });
      relatedFormsContainer.push(
        {
          id: relation.relationId,
          dataType: relation.dataType,
          config: {
            header: headerField ? headerField : {} as IRelatedField,
            body: bodyFields.length > 0 ? bodyFields : []
          }
        }
      )
    })
    return relatedFormsContainer
  }

  static structureRelatedForm = (relatedForm: IFormRelated | IRelatedFormField, formFields: IFormField[]): IFormRelated | IRelatedFormField => {
    const relatedFormFields: IFormRelatedField[] = [];
    const relatedFormHeaderId = relatedForm.config.header.keyPath.replace('fields.', '');
    let relatedFormHeaderProperties = formFields.find((f: IFormField) => `fields.${f.id}` === relatedForm.config.header.keyPath);
    let headerBPRSystemField = bprSystemFields.find((f: IBPRSystemField) => {
      return SanitizeBPR.convertBPRSystemField(f.value).id === relatedForm.config.header.keyPath;
    });
    const sequenceField = formFields.filter(f => f.type === 'seq');

    relatedForm.config.body.forEach((e: IRelatedField) => {
      const relatedFormFieldId = e.keyPath.replace('fields.', '');

      let fieldBPRSystemField = bprSystemFields.find((f: IBPRSystemField) => {
        return SanitizeBPR.convertBPRSystemField(f.value).id === relatedFormFieldId;
      });
      if (fieldBPRSystemField) {
        const { id, text, type } = SanitizeBPR.convertBPRSystemField(fieldBPRSystemField.value);
        relatedFormFields.push({ id: id, name: text, type: type, showLabel: e.showLabel })
      }

      const relatedFormProperties = formFields.find((f: IFormField) => f.id === relatedFormFieldId);
      if (relatedFormProperties) {
        relatedFormFields.push({ ...relatedFormProperties, id: relatedFormFieldId, name: relatedFormProperties.label, type: relatedFormProperties.type, showLabel: e.showLabel })
      }
    });

    relatedForm.headerField = headerBPRSystemField
      ? [{
        id: SanitizeBPR.convertBPRSystemField(headerBPRSystemField.value).id,
        name: SanitizeBPR.convertBPRSystemField(headerBPRSystemField.value).text,
        type: SanitizeBPR.convertBPRSystemField(headerBPRSystemField.value).type,
        showLabel: relatedForm.config.header.showLabel
      }]
      : relatedFormHeaderProperties
        ? [{
          ...relatedFormHeaderProperties,
          id: relatedFormHeaderId,
          name: relatedFormHeaderProperties.label,
          type: relatedFormHeaderProperties.type,
          showLabel: relatedForm.config.header.showLabel
        }]
        : relatedForm.headerField
    relatedForm.seqField = sequenceField;
    relatedForm.fields = relatedFormFields;
    return relatedForm;
  }

  static structureIncoming = (relationshipList: IApiFormRelatedField[], referenceList: IReference[]): IFormRelated[] => {
    const formRelatedList: IFormRelated[] = [];
    if (relationshipList && relationshipList.length > 0) {
      relationshipList.forEach((related: IApiFormRelatedField) => {
        const reference = referenceList.filter(e => e.id === related.id)[0];
        if (reference) {
          formRelatedList.push({
            title: reference ? reference.name : '',
            id: uuid(),
            relationId: related.id,
            headerField: [],
            fields: [],
            showRelatedForm: false,
            config: related.config,
            new: false,
            dataType: related.dataType
          });
        }
      });
    }
    return formRelatedList;
  }

  static isBPRSystemField = (fieldToCompare: string): boolean => {
    return bprSystemFields.find((field) => {
      const [id] = field.value.split('|||||');
      return id === fieldToCompare
    }) ? true : false
  }

  static convertBPRSystemField = (fieldValue: string): IConvertBPRSystemField => {
    let [id, text, type] = fieldValue.split('|||||');
    return { id, text, type };
  }
}
import React from 'react';
import { DynamicField } from '../../../../../common/field/dynamic-field-list-detail';
import AllowedUserListActionControl from '../../../../../common/utils/user-list-action-control';
import UserStatus from './user-update-status';
import { List } from 'semantic-ui-react-bpm';
// import { staticFieldLabel } from '../constant';
// import { getFieldId } from '../../../../../common/utils/get-field';
import { IUser } from '../interface/user';
import { IFieldData } from '../../../../../common/field/dynamic-field-list-detail/interface/field-data';
import { IField } from '../interface/field';
import { FieldElement } from '../../../../../common/field/component/field-element';
import { IUserProfileName } from '../interface/user-profile-name';
import { v4 as uuid } from 'uuid';
// import ToolTip from '../../../../../common/general/tooltip';
import { sortNonCustomField } from '../../../../../common/utils/sort-non-custom-field';
// import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';

interface ITableDetail {
  userLoggedIn: any;
  user: IUser;
  staticField: IField[];
  dynamicField: any;
  openUpdateUserFormModal: any;
  openDeleteuserConfirmation: any;
  companyId: string;
}

const TableListDetail: React.FC<ITableDetail> = (props) => {

  const validateUserProfile = AllowedUserListActionControl.withUser(props.userLoggedIn)
  const compamyId = props.companyId

  return <tr>
    <td className='disabled'>
      {props.user.seq}
    </td>
    {
      props.staticField && sortNonCustomField(props.staticField).map((detail: IField) => {
        return <td className='disabled' key={`column-${detail.id}-${props.user.id}`} >
          {props.user.fields ? props.user.fields[detail.id || ''] || '' : ''}
        </td>
      })
    }
    <td className='disabled single line tr-dropdown-type'>{props.user.profiles && props.user.profiles.map((list: IUserProfileName) => {
      if (!list) return undefined;

      return (
        <List key={uuid()} className="tr-alignment" >
          <List.Item>{list.name}</List.Item>
        </List>
      )
    })}</td>
    <td className='disabled'>
      <UserStatus
        validateUserProfile={validateUserProfile}
        userId={props.user.id || ''}
        userStatus={props.user.active || false}
        companyId={compamyId}
      />
    </td>
    {
      props.dynamicField && props.dynamicField.map((detail: IField) => {
        return <td key={`column-${detail.id}-${props.user.id}`} >
          <DynamicField.Render data={props.user as IFieldData} detail={FieldElement.getDataElement(detail)} />
        </td>
      })
    }
    <td style={{ backgroundColor: '#fff' }}>
      {/* {(validateUserProfile.allowedUserUpdate(compamyId) || props.user.id === props.userLoggedIn.user.id) &&
        <ToolTip position='left center'
          popupMessage={'Edit'}
          popupTriggerComponent={
            <div className={`table-action-icon ${props.user.id === props.userLoggedIn.user.id ? 'edit-only' : ''}`}
              onClick={() => props.openUpdateUserFormModal(props.user.id)} >
              <SVGWrapper
                iconName='edit'
                width='14'
                height='14'
                color='#3a86a7'
              />
            </div>
          }
        />
      }
      {validateUserProfile.allowedUserUpdate(compamyId) && props.user.id !== props.userLoggedIn.user.id &&
        <ToolTip position='right center'
          popupMessage={'Delete'}
          popupTriggerComponent={
            <div className='table-action-icon' onClick={() => {
              props.openDeleteuserConfirmation(props.user.id, props.user.fields ?
                props.user.fields[getFieldId(props.staticField, staticFieldLabel.email)] || ''
                : '')
            }}>
              <SVGWrapper
                iconName='trash'
                width='12'
                color='#3a86a7'
              />
            </div>
          }
        />
      } */}
    </td>
  </tr>
}

export default TableListDetail;
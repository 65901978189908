import React from 'react';
import { useSelector } from 'react-redux';
import { IDatatableRecord } from '../../../../../common/custom-field-config/interface/datatable-record';
import DynamicField from '../../../../../common/field/dynamic-field-list-detail';
import { IFieldData } from '../../../../../common/field/dynamic-field-list-detail/interface/field-data';
import { IRootState } from '../../../../../reducers';

const GridDTRecordsDataTemplate: React.FC<{ data: any }> = ({ data }) => {

  const { dataTableRecordsList, } = useSelector((state: IRootState) => state.dataTable);

  const props = { ...data.column.parent };
  const fieldData = props.dataTableRecordsField.find((e: IFieldData) => e.id === data.column.fieldId);
  const record = dataTableRecordsList.find((e: IDatatableRecord) => e.id === data.id);

  if (!record && !props.fieldData) {
    return null;
  }
  return <DynamicField.Render detail={fieldData} data={record as IFieldData} useChip />
}
export default GridDTRecordsDataTemplate;

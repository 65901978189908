import React, { useState, useEffect } from 'react';
import ColumnHeader from './column-header';
import FieldSelection from './field-selection';
import { IColumnView } from './interface/custom-view';
import { useDispatch, useSelector } from 'react-redux';
import { saveDraftColumnViewAction, publishColumnViewAction } from './action';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { IRootState } from '../../../../../../../../reducers';
import ButtonContainer from '../../../../button-container';
import { documentListActionMessage } from './constant';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';
import ActionMessage from '../../../../../../../../common/general/action-message';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { useSetColumnViewList } from './hooks/use-set-custom-view-list';
import { useGetCustomViewListData } from './hooks/use-get-custom-view-list-data';

interface IDocumentListProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const DocumentList: React.FC<IDocumentListProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match: { params: { formId: string } } = useRouteMatch();
  const {
    company
  } = useSelector((state: IRootState) => state.auth);
  const {
    formDetail,
    columnViewList,
    fieldList,
    columnViewSaveType,
    saveColumnViewLoading,
    getColumnViewListLoading,
    getFieldListListLoading,
    getFormDetailLoading,
  } = useSelector((state: IRootState) => state.documentList);
  console.log("Log ~ columnViewSaveType", columnViewSaveType);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const [searchText, setSearchText] = useState('');
  const [fieldTypeFilter, setFieldTypeFilter] = useState('');
  const [fieldSectionFilter, setFieldSectionFilter] = useState('');
  const { customViewList, setCustomViewList } = useSetColumnViewList({ fieldList, columnViewList });

  const addCustomViewList = (dragItem: { data: IColumnView }) => {
    const newColumnView = [...customViewList];
    let dragField = { ...dragItem.data };
    dragField.new = false;
    newColumnView.push(dragField);
    setCustomViewList(newColumnView);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const removeSelectedColumnView = (dragItem: { data: IColumnView }) => {
    let newColumnView = [...customViewList];
    newColumnView = newColumnView.filter((field: IColumnView) => field.fieldName !== dragItem.data.fieldName);
    setCustomViewList(newColumnView);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const arrangeSequence = (dragItem: { data: IColumnView }, dropItem: IColumnView) => {
    let newColumnView = [...customViewList];
    const dragItemIndex = newColumnView.indexOf(dragItem.data);
    const dropItemIndex = newColumnView.indexOf(dropItem);
    newColumnView.splice(dragItemIndex, 1);
    newColumnView.splice(dropItemIndex, 0, dragItem.data);
    setCustomViewList(newColumnView);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const saveDocumentList = (saveType: string) => {
    dispatch(saveDraftColumnViewAction({
      company: company,
      formId: match.params.formId,
      customViewList: customViewList,
      saveType: saveType,
      sanitize: true
    }));
    props.hasPageChangeRef.current.hasChange = false;
  }

  const filterSearchInput = (searchText: string) => {
    setSearchText(searchText);
  }

  const filterByType = (type: string) => {
    setFieldTypeFilter(type)
  }

  const filterBySection = (section: string) => {
    setFieldSectionFilter(section)
  }

  useGetCustomViewListData({
    dispatch,
    company,
    formId: match.params.formId
  });

  useEffect(() => {
    if (columnViewSaveType === 'publish') {
      dispatch(publishColumnViewAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnViewSaveType])

  return (<>
    <ActionMessage messages={documentListActionMessage} />
    <div className='configuration-document-list'>
      {
        (saveColumnViewLoading || getColumnViewListLoading || getFieldListListLoading || getFormDetailLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <div className='document-list-container'>
        <ColumnHeader
          customViewList={customViewList}
          addCustomViewList={addCustomViewList}
          arrangeSequence={arrangeSequence}
        />
        <FieldSelection
          customViewList={customViewList}
          removeSelectedColumnView={removeSelectedColumnView}
          formDetail={formDetail}
          fieldList={fieldList}
          filterSearchInput={filterSearchInput}
          filterByType={filterByType}
          filterBySection={filterBySection}
          searchText={searchText}
          fieldTypeFilter={fieldTypeFilter}
          fieldSectionFilter={fieldSectionFilter}
        />
      </div>
      <ButtonContainer
        cancel={() => {
          changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
        }}
        save={() => saveDocumentList('draft')}
        publish={() => saveDocumentList('publish')}
      />
    </div>
  </>);
}

export default DocumentList;
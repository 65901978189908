import React from 'react';
import { ITableType } from '.';
import { AcceptedProps } from '../interface/accepted-props';
import VerticalTableTypeComponent from '../type/table/vertical';

export default class VerticalTableType implements ITableType {

  name = 'vertical';

  Render: React.FC<AcceptedProps> = (props) => {
    return <VerticalTableTypeComponent {...props} />
  }
}
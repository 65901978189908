import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import PopperContainer from '../../../../../general/popper-component';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, removeFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { TimeProps } from '../../../service/type/time';
import { TValidationType } from '../../../service/field-properties.handler';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { removeInput } from '../../../utils/remove-input';
import { triggerOnChange } from './utils/trigger-on-change';
import { onCalendarClose } from './utils/on-calendar-close';
import { useInitialLoad } from './hook/use-initial-load';
import { renderError } from './utils/render-error';
import { hasIconInside } from './utils/has-icon';
import { removePopperPosition } from '../date-time/utils/remove-popper-position';
import { returnPopperPosition } from '../date-time/utils/return-popper-position';

const TimeArrayType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher]: any = useState(1);
  const [multilineCount, setMultilineCount] = useState(1);

  let calendarRef = useRef<any>({});

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new TimeProps());

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur)
    setRefresher(uuid());
  }

  const handleTime = (time: any, count: number) => {
    elementProperties.setCurrent({ ...elementProperties.getCurrent(count), value: time ? moment(time).format("HH:mm:ssZ") : '' }, count)
    triggerOnChange(props);
  }

  const getValue = (count: number) => {
    let dateToday = moment().format("YYYY-MM-DD");
    const data = elementProperties.getCurrent(count).value || null;
    return data ? new Date(dateToday + ' ' + data) : null;
  }

  const addNewInput = () => {
    const newLen = multilineCount + 1;
    const dataObject = elementProperties.getCurrent(multilineCount);
    elementProperties.setCurrent({ ...dataObject, value: '' }, multilineCount);
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      elementProperties.setCurrent({ ...dataObject, value: defaultValue[0] || '' }, multilineCount);
    }
    setMultilineCount(newLen);
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      setRefresher(uuid());
    }
  }

  const renderAddnewInput = (row: number, count: number) => {
    return <>
      {
        row === (count - 1) && props.multiline
        && <i className={`plus circular icon multiline-icon ${row === 0 ? '' : 'more'}`}
          onClick={() => addNewInput()} />
      }
    </>
  }

  const renderMinusButton = (row: number, count: number) => {
    return <>
      {
        (row > 0 || (row === 0 && row !== (count - 1)))
        && <i className="minus circular icon multiline-minus-icon" onClick={() => removeInput({
          forwardedRef: props.forwardedRef,
          name: name,
          multilineCount: multilineCount,
          setMultilineCount: setMultilineCount
        }, row)} />
      }
    </>
  }

  const setRef = (curRef: any, id: string, row: number) => {
    if (curRef && focusedElement && id === focusedElement) {
      curRef.input.focus();
    }
    if (calendarRef.current) {
      calendarRef.current[row] = curRef;
    }
  }

  const handleClickOutside = (row: number) => {
    if (calendarRef.current && calendarRef.current[row]) {
      calendarRef.current[row].setOpen(false);
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(id);
    }
  }

  const renderElement = (id: string, row: number) => {
    return <div className='flex-container'>
      <DatePicker
        id={id}
        popperContainer={PopperContainer}
        onChange={(time) => handleTime(time, row)}
        selected={getValue(row)}
        onCalendarClose={() =>
          onCalendarClose({
            id,
            focusedElement,
            removeFocusedElement,
            validateInput,
            setRefresher,
            refresher,
            onTypeTimeout,
            reloadOnChange: props.reloadOnChange,
            triggerFieldReload: props.triggerFieldReload
          })
        }
        showTimeSelect
        showTimeSelectOnly
        dateFormat='HH:mm:ss'
        className={`field-input multi-line ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: true,
          required: props.accessType === IFieldAccessType.Required,
          fieldValueObject: elementProperties
        }, row)}`}
        onInputClick={() => { focusInput(id) }}
        onFocus={() => returnPopperPosition(id)}
        ref={(curRef: any) => setRef(curRef, id, row)}
        popperClassName={id}
        shouldCloseOnSelect
        onClickOutside={() => handleClickOutside(row)}
        onKeyDown={(e) => handleKeydown(e, id)}
      />
      {hasIconInside('multi-line', props.hasIconInside)}
      {renderError({ elementProperties, row, showErrorMessage: props.showErrorMessage })}
    </div>
  }

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `time-picker-${name}-${row}`;
      field.push(
        <React.Fragment key={uuid()}>
          {row > 0 && <span className='multiline-gap' />}
          <div className='multi-line-container'>
            <>
              {props.accessType !== IFieldAccessType.Readonly
                ? renderElement(id, row)
                : <span>{elementProperties.getCurrent(row)}</span>
              }
            </>
            <span>
              {renderMinusButton(row, count)}
              {renderAddnewInput(row, count)}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return field;
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: defaultValueArray[0] || '' }, row);
      }
    }
  }

  useInitialLoad({
    setDefaultValue,
    validateInput,
    accessType: props.accessType,
    isAddRecord: props.isAddRecord || false,
    setMultilineCount,
    elementProperties
  });

  useEffect(() => {
    if (props.isClearValue && elementProperties.current) {
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: '' }, row)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}
          >
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }
  </>
}

export default TimeArrayType;
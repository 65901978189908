import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.TextString,
  type: 'string',
  required: false
};

interface IDefaultConfigObject {
  format: string;
  displayType: string;
}

const defaultConfig: IDefaultConfigObject = {
  format: 'none',
  displayType: 'line',
};

const value = {
  title: 'Text String',
  value: 'string',
  icon: {
    black: require('../../icons/black/font.png'),
    white: require('../../icons/white/font.png'),
    blue: require('../../icons/blue/font.png'),
  },
  style: {
    width: '17px',
    height: '20px',
    left: '2px'
  },
  fieldTypeStyle: {
    width: '22px',
    height: '25px',
    right: '10px',
    top: '3px'
  },
  defaultData,
  defaultConfig
}

export default value;
import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SAMPLE_ACTION } from './constant';
import { IRootState } from '../../reducers';

const Sample: React.FC = () => {
  // console.log('Rendering . . .');
  const [test, setTest] = useState({});
  const [loading, setLoading] = useState(false);
  const data = useSelector((state: IRootState) => state.sample);
  const dispatch = useDispatch();

  // Trigger initial mount
  useEffect(() => {
    // console.log('Trigger dispatch . . .');
    dispatch({ type: SAMPLE_ACTION, payload: {} });
  }, [dispatch]);

  // Trigger update mount and update
  useEffect(() => {
    // console.log('Trigger update state . . .', data.test, data.loading);
    setTest(data.test);
    setLoading(data.loading);
  }, [data.test, data.loading]);

  return (
    <div className="App">
      <header className="App-header">
        {loading
          ? 'Loading . . .'
          : `Test component ${JSON.stringify(test)}`
        }
      </header>
    </div>
  );
}

export default Sample;

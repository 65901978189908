class IDOperatorOption {

  name = 'id';

  option = [
    { key: 0, value: 'any-in', text: 'Any In' }
  ]

}

export default IDOperatorOption;
import React, { useState, useCallback, useEffect } from 'react';
import SVGWrapper from '../../../../../../../../../../../../common/icons/svg/svg-wrapper';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { ITriggerTypeRegistryRender } from './registry';
import { zones } from 'tzdata';
import moment from 'moment-timezone';
import CronGenerator from '../../components/cron-generator';
import * as cronParser from 'cron-parser';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { DetailActionButton } from '../../action-button';
import { useSetInitialScheduledStateValue } from '../hooks/use-set-initial-scheduled-state-values';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../../../../../reducers';

interface IDateRange {
  start: string;
  end: string;
}

const timeZoneOption = Object.keys(zones).map((keys: string, index: number) => {
  return {
    index: index,
    text: keys,
    value: keys,
    offset: moment().tz(keys).format('Z')
  }
});

const parseSelectedDate = (type: 'start' | 'end', rangeData: any, timeZone: string) => {
  let value = undefined;
  if (rangeData && rangeData[type] && timeZone) {
    const time = moment.tz(rangeData[type], timeZone)
    const date = moment.tz(rangeData[type], timeZone).format("YYYY-MM-DD");
    const dateTimeValue = new Date(date);
    dateTimeValue.setHours(time.hours());
    dateTimeValue.setMinutes(time.minutes());
    value = dateTimeValue;
  }
  return value;
}
class TriggerScheduler {

  name = 'scheduled'

  render: React.FC<ITriggerTypeRegistryRender> = (props) => {
    let tzPickerObject: ComboBoxComponent;

    const { companySettings: { tz } } = useSelector((state: IRootState) => state.companySettings);
    const defaultValue = '* * * * *';
    const timezone = tz ? tz : moment.tz.guess();
    const defaultRangeDate = { start: new Date(), end: undefined };

    const [value, setValue] = useState(defaultValue);
    const [cronInputValue, setCronInputValue] = useState(defaultValue);
    const [nextRun, setNextRun] = useState<any>();
    const [next10Runs, setNext10Runs] = useState<any[]>([]);
    const [showNextRuns, setShowNextRuns] = useState(false);
    const [runRangeDate, setRunRangeDate] = useState<IDateRange | null>(null);
    const [runTimezone, setRunTimezone] = useState(timezone);
    const [triggerReset, setTriggerReset] = useState(false);

    useSetInitialScheduledStateValue({
      setValue,
      setRunTimezone,
      setRunRangeDate,
      setCronInputValue,
      selectedComponent: props.selectedComponent
    });

    const options = {
      currentDate: runRangeDate && runRangeDate.start ? new Date(moment(runRangeDate.start).tz(runTimezone).format()) : defaultRangeDate.start,
      endDate: runRangeDate && runRangeDate.end ? new Date(moment(runRangeDate.end).tz(runTimezone).format()) : defaultRangeDate.end,
      iterator: true
    };

    const customSetValue = useCallback((newValue: string) => {
      setCronInputValue(newValue);
      refreshParentComponent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCronInputValue]);

    const refreshParentComponent = () => {
      props.hasComponentChange.current = true;
    }

    const triggerShowNextRuns = () => {
      setShowNextRuns(!showNextRuns);
    }

    const triggerOnRunRangeDateChange = (event: any) => {
      if (event.value) {
        setRunRangeDate({
          start: event.value.start,
          end: event.value.end
        });
      } else {
        setRunRangeDate(null);
      }
      refreshParentComponent();
    }

    const triggerCronInputChange = (event: any) => {
      setCronInputValue(event.target.value);
      refreshParentComponent();
    }

    const triggerCronInputBlur = (event: any) => {
      setValue(cronInputValue);
      if (cronInputValue === defaultValue) {
        setTriggerReset(true);
      } else {
        setTriggerReset(false);
      }
    }

    const triggerTimezoneChange = (event: any) => {
      setRunTimezone(event.value);
      refreshParentComponent();
    }

    const triggerResetButton = () => {
      if (!props.hasComponentChange.current) {
        return;
      }
      setRunRangeDate(null);
      setShowNextRuns(false);
      setRunTimezone(timezone);
      setTriggerReset(true);
      setCronInputValue(defaultValue);
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.cron = cronInputValue;
        tempComponent.config.start = runRangeDate ? runRangeDate.start : undefined;
        tempComponent.config.end = runRangeDate ? runRangeDate.end : undefined;
        tempComponent.config.timezone = runTimezone;
        props.updateCurrentAutomation(tempComponent, true);
      }
    }

    const round = (date: moment.Moment, duration: moment.Duration, method: 'ceil' | 'round') => {
      return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    useEffect(() => {
      const start = runRangeDate ? runRangeDate.start : defaultRangeDate.start.toISOString();
      const end = runRangeDate ? runRangeDate.end : defaultRangeDate.end;

      const parseOptions = {
        currentDate: parseSelectedDate('start', { start, end }, runTimezone),
        endDate: parseSelectedDate('end', { start, end }, runTimezone),
        iterator: true
      };

      try {
        const tempNextRuns = [] as string[];
        const interval = cronParser.parseExpression(cronInputValue, runRangeDate ? parseOptions : options);
        const nextRun: any = interval.next();
        const nextInterval = cronParser.parseExpression(cronInputValue, {
          currentDate: new Date(nextRun.value.toString()),
          endDate: options.endDate,
          iterator: true
        });

        for (let i = 0; i < 10; i++) {
          try {
            const nextRuns: any = nextInterval.next();
            tempNextRuns.push(nextRuns.value.toString());
          } catch (e) {
            break;
          }
        }

        setNext10Runs(tempNextRuns);
        setNextRun(nextRun.value.toString());
      } catch (err) {
        const error = err as any;
        console.log('Error: ' + error.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cronInputValue, runRangeDate, runTimezone])

    return (<>
      <div className={`automation-form-detail-trigger`}>
        <AutomationDetailHeader
          title={`Scheduler`}
          description={`Executes this rule on the provided schedule. You can choose if you’d like to perform actions on a set of issues gathered with a JQL search or simply execute the rule.`}
          iconHeight={16}
          iconWidth={16}
          iconName='scheduler'
        />
        <div className={`scheduler-container automation-field-container`}>
          <div className={`automation-field`}>
            <div className='field input-rule-cron-exp'>
              <span className='field-header'>Cron expression</span>
              <div className='input-cron-exp'>
                <input className='rule-count' type='text' value={cronInputValue}
                  onChange={triggerCronInputChange} onBlur={triggerCronInputBlur}
                />
              </div>
            </div>
          </div>
          <h4 className='divider'><span>OR</span></h4>
          <CronGenerator
            value={value}
            setValue={customSetValue}
            reset={triggerReset}
            triggerResetButton={triggerResetButton}
          />
          <div className={`automation-field`}>
            <div className='field input-rule-range'>
              <div className='from-picker'>
                <span className='field-header'>
                  Run Rule
                </span>
                <DateTimePickerComponent
                  id="scheduled-daterangepicker_from"
                  cssClass='e-scheduled-range-picker'
                  placeholder='FROM'
                  openOnFocus={true}
                  min={new Date(moment.tz(new Date(), runTimezone).format())}
                  max={runRangeDate && runRangeDate.end ?
                    new Date(moment.tz(runRangeDate.end, runTimezone).format())
                    : new Date(moment.tz(new Date(), runTimezone).add(100, 'years').format())
                  }
                  ref={(ref: any) => {
                    if (ref) {
                      ref.onClick = () => {
                        ref.show();
                      }
                    }
                  }}
                  showTodayButton={false}
                  showClearButton
                  value={parseSelectedDate('start', runRangeDate, runTimezone)}
                  step={15}
                  onChange={(event: any) => {
                    const nextTimeInterval = round(moment(), moment.duration(15, "minutes"), "ceil");
                    const start = new Date(event.value);
                    if (!runRangeDate || (runRangeDate && !runRangeDate.start)) {
                      start.setHours(nextTimeInterval.hours());
                      start.setMinutes(nextTimeInterval.minutes());
                    }
                    start.setSeconds(0);
                    let end = undefined;
                    if (runRangeDate) {
                      end = new Date(runRangeDate.end);
                      end.setSeconds(0);
                    }
                    triggerOnRunRangeDateChange({
                      value: {
                        start: event.value ? moment(start).utc().format() : undefined,
                        end: end ? moment(end).utc().format() : undefined
                      }
                    });
                  }}
                />
              </div>
              <div className='to-picker'>
                <DateTimePickerComponent
                  id="scheduled-daterangepicker_to"
                  cssClass='e-scheduled-range-picker'
                  placeholder='TO'
                  openOnFocus={true}
                  min={runRangeDate ? new Date(moment.tz(new Date(runRangeDate.start), runTimezone).toString()) : new Date()}
                  ref={(ref: any) => {
                    if (ref) {
                      ref.onClick = () => {
                        ref.show();
                      }
                    }
                  }}
                  value={parseSelectedDate('end', runRangeDate, runTimezone)}
                  onChange={(event: any) => {
                    const nextTimeInterval = round(moment(), moment.duration(15, "minutes"), "ceil");
                    const end = new Date(event.value);
                    if (!runRangeDate || (runRangeDate && !runRangeDate.end)) {
                      end.setHours(nextTimeInterval.hours());
                      end.setMinutes(nextTimeInterval.minutes());
                    }
                    end.setSeconds(0);
                    let start = undefined;
                    if (runRangeDate) {
                      start = new Date(runRangeDate.start);
                      start.setSeconds(0);
                    }
                    triggerOnRunRangeDateChange({
                      value: {
                        end: event.value ? moment(end).utc().format() : undefined,
                        start: runRangeDate ? moment(start).utc().format() : undefined
                      }
                    });
                  }}
                  showTodayButton={false}
                />
              </div>

            </div>
            <div className='field input-rule-zone'>
              <span className='field-header'>
                Timezone
              </span>
              <ComboBoxComponent
                id="scheduled-timezonepicker"
                dataSource={timeZoneOption}
                floatLabelType="Never"
                onChange={triggerTimezoneChange}
                ref={ref => { if (ref) tzPickerObject = ref }}
                cssClass='e-scheduled-zone-picker'
                fields={{ text: 'text', value: 'value' }}
                onClick={() => tzPickerObject.showPopup()}
                onFocus={() => tzPickerObject.showPopup()}
                value={runTimezone}
                sortOrder='Ascending'
                allowFiltering
              />
            </div>
          </div>
          {false &&
            <div>
              <div className='automation-info'>
                <SVGWrapper iconName='informationIcon' width='20px' height='20px' color='#3B86A7' />
                <p>A CRON expression gives you more control over the frequency of your rule.
                  CRON expressions are scheduled in UTC, currently: 19/08/21 05:48:00 am.</p>
              </div>
              <div className={`automation-field`}>
                <div className='field input-rule-cron-exp'>
                  <span className='field-header'>Cron expression</span>
                  <div className='input-cron-exp'>
                    <input className='rule-count' type='text' />
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={`automation-field`}>
            <div className='field rule-run-info'>
              <SVGWrapper iconName='alarmClock' width='25px' height='25px' color='#0000008a' />
              <div className='run-info'>
                <span className='title'>Next run:</span>
                <div className='content'>
                  {moment(nextRun).format("dddd, MMMM D, YYYY, h:mm A z") + ' ' + moment.tz(runTimezone).zoneAbbr()}
                </div>
                <span className='button' onClick={triggerShowNextRuns}>
                  {`${!showNextRuns ? 'Show' : 'Hide'} next 10 runs`}
                </span>
              </div>
            </div>
            {showNextRuns &&
              <div className='field rule-run-info next-runs'>
                <div className='run-info'>
                  <div className='content'>
                    <span className='title'>Next 10 runs</span>
                    <ul>
                      {next10Runs.map((value, index) => {
                        return <li key={index}>
                          {moment(value).format("dddd, MMMM D, YYYY, h:mm A z") + ' ' + moment.tz(runTimezone).zoneAbbr()}
                        </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
    </>)
  }
}

export default TriggerScheduler;
import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../../common/field/type/interface/field-type-object';
import { IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../../interface/field-permission';
import { ISection, ITablePermission } from '../../../../interface/section';
import SectionFields from './fields';

interface ISectionBody {
  section: ISection | ITablePermission;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  tablePermission?: ITablePermission[];
  sectionType: 'section' | 'table';
}

const SectionBody: React.FC<ISectionBody> = (props) => {
  let isAccessLevelSection = false;

  if (props.sectionType === 'section') {
    const section = props.section as ISection;
    isAccessLevelSection = (typeof section.accessLevelPerStatus !== 'undefined' && section.accessLevelPerStatus !== '');
  }

  if (props.sectionType === 'table') {
    const section = props.section as ITablePermission;
    isAccessLevelSection = (typeof section.accessLevelPerTable !== 'undefined' && section.accessLevelPerTable !== '');
  }

  return <div className={`section-body ${props.sectionType} ${isAccessLevelSection ? 'disabled' : ''}`} >
    {
      props.section && props.section.fields && props.section.fields.map((field: ICustomFieldDetail, index: number) => {

        const fieldPermission = props.section.permissions.filter((permission: IStatusFieldPermission) => field.id === permission.fieldId);
        const fieldPermissionTable = props.tablePermission && props.tablePermission.length > 0 && props.tablePermission.filter(e => e.id === field.id);

        return field.deleted !== true && field.configName !== IConfigName.Seq
          ? <SectionFields
            key={`section-body-field-container-${index}`}
            fields={field}
            index={index}
            section={props.section as ISection}
            fieldPermission={fieldPermission[0]}
            handlePermissionChange={props.handlePermissionChange}
            openFieldPermissionSettings={props.openFieldPermissionSettings}
            tablePermission={fieldPermissionTable as ITablePermission[]}
            sectionType={props.sectionType}
          />
          : undefined
      })
    }
  </div>
}

export default SectionBody;
import React, { useState, useEffect } from 'react';
import SectionTitle from './title';
import { ISection, ITablePermission } from '../../../../interface/section';
import SectionBody from './body';
import { IPermissionFieldFilterConfig } from '../../../../interface/field-permission';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ISectionContainer {
  section: ISection;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  handleSectionPermissionChange(forUpdateSection: ISection | ITablePermission, accessLevel: string, accessLevelPerSection: any): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  openSectionPermissionSettings(section: ISection): void;
  tablePermission?: ITablePermission[];
}

const Section: React.FC<ISectionContainer> = (props) => {
  const [isSectionCollapse, setIsSectionCollapse] = useState(false);

  const toggleSectionCollapse = () => {
    let accessLevel = props.section.permissions[0] && props.section.permissions[0].accessLevel
      ? props.section.permissions[0].accessLevel : 'editable';

    props.handleSectionPermissionChange(
      props.section,
      accessLevel ? accessLevel as string : 'editable',
      props.section.accessLevelPerStatus ? '' : accessLevel
    );

    if (accessLevel !== '') {
      setIsSectionCollapse(false);
    }
  };

  useEffect(() => {
    if (props.section.accessLevelPerStatus) {
      setIsSectionCollapse(true);
    }
  }, [props.section.accessLevelPerStatus])

  return <>
    <div className='section-list-container'>
      <SectionTitle
        section={props.section}
        isSectionCollapse={isSectionCollapse}
        setIsSectionCollapse={setIsSectionCollapse}
        handleSectionPermissionChange={props.handleSectionPermissionChange}
        toggleSectionCollapse={toggleSectionCollapse}
        openSectionPermissionSettings={props.openSectionPermissionSettings}
      />
      {!isSectionCollapse &&
        <SectionBody
          section={props.section}
          handlePermissionChange={props.handlePermissionChange}
          openFieldPermissionSettings={props.openFieldPermissionSettings}
          tablePermission={props.tablePermission}
          sectionType='section'
        />
      }
    </div>
  </>
}

export default Section;
import { EnumExclusiveMode, EnumSelectAllMode, ISortEnumDirection, ISortEnumKey } from '../../../../component/admin-module/module/users/interface/field';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import stringField from '../../../field/type/text-string';
import boolField from '../../../field/type/switch';
import fileField from '../../../field/type/file';

export const sortItemList = [
  {
    key: ISortEnumKey.Id,
    label: 'ID',
    direction: ISortEnumDirection.Asc,
  },
  {
    key: ISortEnumKey.Id,
    label: 'ID',
    direction: ISortEnumDirection.Desc,
  },
  {
    key: ISortEnumKey.Label,
    label: 'Alphanumeric',
    direction: ISortEnumDirection.Asc,
  },
  {
    key: ISortEnumKey.Label,
    label: 'Alphanumeric',
    direction: ISortEnumDirection.Desc,
  },
]

export const selectAllModeList = [
  {
    key: 1,
    text: EnumSelectAllMode.None,
    value: EnumSelectAllMode.None,
  },
  {
    key: 2,
    text: EnumSelectAllMode.Manual,
    value: EnumSelectAllMode.Manual,
  },
  {
    key: 3,
    text: EnumSelectAllMode.Automatic,
    value: EnumSelectAllMode.Automatic,
  }
]

export const exclusiveModeList = [
  {
    key: 1,
    text: EnumExclusiveMode.None,
    value: EnumExclusiveMode.None,
  },
  {
    key: 2,
    text: EnumExclusiveMode.Field,
    value: EnumExclusiveMode.Field,
  }
]

export const formDtoCustomField: ICustomFieldDetail[] = [
  { ...stringField.defaultData, label: 'Bp Name', id: 'name', config: stringField.defaultConfig } as ICustomFieldDetail,
  { ...fileField.defaultData, label: 'Image', id: 'icon', config: fileField.defaultConfig } as ICustomFieldDetail,
  { ...boolField.defaultData, label: 'Enabled', id: 'enabled', config: boolField.defaultConfig } as ICustomFieldDetail,
]

export const userDtoCustomField: ICustomFieldDetail[] = [
  { ...boolField.defaultData, label: 'Deleted', id: 'deleted', config: boolField.defaultConfig } as ICustomFieldDetail,
  { ...boolField.defaultData, label: 'Active', id: 'active', config: boolField.defaultConfig } as ICustomFieldDetail,
]

export const referencesFieldType = [
  'document',
]
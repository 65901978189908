import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../reducers';
import ChatArea from '../../document-module/module/form/chatbox/content/area';
import { useSetUserColor } from '../../document-module/module/form/chatbox/hooks/use-set-user-color';
import { IComment } from '../../document-module/module/form/interface/comment';
import { filterAdminHistoryAction } from './action';

interface IAdminChatboxProps {
  setIsOpenChatbox: Function;
  isOpenChatbox: boolean;
  module: string;
}

const AdminHistoryLogs: React.FC<IAdminChatboxProps> = (props) => {

  const dispatch = useDispatch();

  const {
    adminHistoryLogs,
    adminHistoryArgs,
    adminHistorySenders
  } = useSelector((state: IRootState) => state.adminHistoryLogs);

  const [userColor, setUserColor] = useState<{ [id: string]: string }>({});
  const [selectedUser, setSelectedUser] = useState<string[]>([]);

  const closeChatbox = () => props.setIsOpenChatbox(false);

  const manageFilter = (senders?: string[]) => {
    dispatch(filterAdminHistoryAction({
      ...adminHistoryArgs,
      senders: senders,
    }));
  };

  const selectUser = (value: string) => {
    let tempSelectedUser = [...selectedUser];
    const indexValue = selectedUser.indexOf(value);
    if (indexValue === -1) {
      tempSelectedUser.push(value);
    } else {
      tempSelectedUser.splice(indexValue, 1);
    }
    setSelectedUser(tempSelectedUser);
    manageFilter(tempSelectedUser);
  };

  const clearFilter = () => {
    setSelectedUser([]);
    dispatch(filterAdminHistoryAction({
      ...adminHistoryArgs,
      senders: [],
    }));
  };

  useSetUserColor({
    senderList: adminHistorySenders,
    setUserColor
  });


  return <div id='admin-audit-chatbox'>
    <div className={`chatbox-dimmer ${!props.isOpenChatbox ? 'hidden' : ''}`} onClick={closeChatbox} />
    <ChatArea
      userList={adminHistorySenders}
      selectedFilter={[]}
      filterComment={() => { }}
      closeChatbox={closeChatbox}
      open={props.isOpenChatbox}
      saveComment={() => { }}
      commentList={adminHistoryLogs}
      selectedUser={selectedUser}
      selectUser={selectUser}
      openUpload={() => { }}
      clearFilter={clearFilter}
      userColor={userColor}
      uploadedFileList={[]}
      removeUploadedFile={() => { }}
      removeFileAfterSave={() => { }}
      documentId={''}
      company={''}
      lastReadMessage={{} as IComment}
      markCommentAsUnread={() => { }}
      commentsUnread={[]}
      dismissUnreadNavigator={() => { }}
      module='admin'
      hideInput={true}
      hideFilterType={true}
      disableScrollToBottom
      sort={false}
    />
  </div>
}

export default AdminHistoryLogs;
import React from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';

interface IPortalSHProfileFormDetails {

}

const PortalSHProfileFormDetails: React.FC<IPortalSHProfileFormDetails> = (props) => {

  return <div className='portal-sh-field'>
    <div className='field-detail'>
      <Dropdown
        options={[{ key: 1, text: 'Business Process Sales Order', value: "sales-order" }]}
        onChange={(e, target: any) => { }}
        placeholder='Select Field'
        selection
        value={'sales-order'}
        selectOnBlur={false}
        search
      />
      <Dropdown
        options={[]}
        onChange={(e, target: any) => { }}
        placeholder='Select Field'
        selection
        selectOnBlur={false}
        search
      />
      <div className='checkbox-toggle'>
        <div className="ui fitted toggle checkbox checked">
          <input type="checkbox" onChange={() => { }} checked={true} />
          <label />
        </div>
        <label className='toggle-label'>Publish</label>
      </div>
      <div className='action-icon' onClick={() => { }}>
        <SVGWrapper
          iconName='trash'
          width='12'
          color='#8A9296'
        />
      </div>
    </div>
  </div>
}

export default PortalSHProfileFormDetails;
import { IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import ValidateField from '../../../validation';
import { IValidationError } from '../../../validation/type/validation-type';
import { FieldElement } from '../../field-element';
import { TValidationType, PropType } from '../field-properties.handler';

export interface ITimeProps {
  value: string;
  error?: IValidationError[];
  fieldType?: string;
}

export class TimeProps extends PropType<ITimeProps, ITimeProps> {

  processValueFromRef(props: ITimeProps): ITimeProps {
    props.value = props.value || '';
    return props;
  }

  processValueToRef(newProps: ITimeProps): ITimeProps {
    newProps.value = newProps.value || '';
    return newProps;
  }

  processValidation(props: ITimeProps, field: FieldElement, validationType: TValidationType): IValidationError[] {
    const value = props?.value || '';
    const validateField = new ValidateField();
    const validate = validateField.singleField(field, value || '', validationType);
    return field.accessType === IFieldAccessType.Required || !!value ? validate : [];
  }
}
import React from 'react';
import { Modal, Segment, Button, Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../common/field/type/interface/field-type-object';
import DropdownPopperOption from '../../../../../../../../common/general/dropdown-popper-option';
import { IColumnView } from '../document-list/interface/custom-view';
import { useSetAggregateModalState } from './hooks/use-set-aggregate-modal-state';

interface IConfigurationModalProps {
  cancel(): void;
  open: boolean;
  field?: IColumnView;
  save(data: any): void;
  fieldList: ICustomFieldDetail[];
}

const TaskViewAggregateModal: React.FC<IConfigurationModalProps> = (props) => {

  const {
    isBlockBForEditing,
    setIsBlockedBForEditing,
    isShowSummary,
    setIsShowSummary,
    aggregate,
    setAggregate
  } = useSetAggregateModalState({ field: props.field });

  const allowedAggregate = () => {
    const field = props.fieldList.find((e: ICustomFieldDetail) => e.id === props.field?.fieldName);
    const isArray = field?.multiline;
    const fieldType = field?.type;
    const configName = field?.configName;
    const labelType = configName === IConfigName.Autopopulated || configName === IConfigName.Referenced ? field?.config?.labelType : '';
    return field && !isArray && (
      fieldType === 'number' || fieldType === 'fx'
      || ((labelType === 'number' || labelType === 'fx') && (configName === IConfigName.Autopopulated || configName === IConfigName.Referenced))
    )
  }

  const manageIsShowSummary = () => {
    setIsShowSummary(!isShowSummary)
    setAggregate('')
  }

  const saveAggregate = () => {
    if (props.field) {
      const tempField = { ...props.field };
      tempField.agg = {
        isBlockForEditing: isBlockBForEditing,
        summaryRow: aggregate
      }
      props.save(tempField);
    }
  }

  return <>
    <Modal
      dimmer='blurring'
      size='small'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={props.open}
      onClose={() => { }}
      id='task-view-aggregate-modal'
      centered={false}
    >
      <Modal.Header className='borderless' >
        <Segment className={`borderless bg-primary`}>
          <h4>Aggregate Setup</h4>
        </Segment>
      </Modal.Header>
      <Modal.Content>
        <div className="ui checkbox" onClick={(event) => setIsBlockedBForEditing(!isBlockBForEditing)}>
          <input type="checkbox"
            onChange={() => { }}
            checked={isBlockBForEditing}
          />
          <label>Blocked for Editing</label>
        </div>
        <div className={`show-summary-row`}>
          <div className={`ui checkbox ${!allowedAggregate() ? 'disabled' : ''}`} onClick={(event) => {
            if (allowedAggregate()) {
              manageIsShowSummary();
            }
          }}>
            <input type="checkbox"
              onChange={() => { }}
              checked={isShowSummary}
            />
            <label>Show summary row as</label>
          </div>
          <Dropdown
            popperContainer={DropdownPopperOption}
            selection
            value={aggregate}
            disabled={!isShowSummary || !allowedAggregate()}
            options={[
              { key: uuid(), value: 'sum', text: 'Sum' },
              { key: uuid(), value: 'count', text: 'Count' },
              { key: uuid(), value: 'average', text: 'Average' },
            ]}
            search
            clearable
            selectOnBlur={false}
            onChange={(event, target: any) => setAggregate(target.value)}
          />
        </div>
      </Modal.Content>
      <Modal.Actions className='borderless'>
        <Button color='grey' className='btn-action' onClick={() => props.cancel()}>Cancel</Button>
        <Button color='green' className='btn-action' onClick={() => saveAggregate()}>Save</Button>
      </Modal.Actions>
    </Modal>
  </>
}

export default TaskViewAggregateModal;
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IProps {
  companyId: string;
  dataTableId: string;
  forUpdateDataTableFields: ICustomFieldDetail[];
}

export const useSetDatatableCustomField = (props: IProps) => {

  let [datatableCustomField, setDatatableCustomField] = useState([] as ICustomFieldDetail[]);

  useEffect(() => {
    if (props.dataTableId !== 'add-data-table' &&
      (props.forUpdateDataTableFields && props.forUpdateDataTableFields.length > 0)) {
      let newData = [] as ICustomFieldDetail[];
      props.forUpdateDataTableFields.forEach((customField: ICustomFieldDetail) => {
        newData.push(customField);
      });
      setDatatableCustomField(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataTableId, props.forUpdateDataTableFields]);

  useEffect(() => {
    if (props.dataTableId === 'add-data-table') {
      let newData = [...datatableCustomField];
      newData.push({ id: uuid(), new: true } as ICustomFieldDetail);
      setDatatableCustomField(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataTableId]);

  return { datatableCustomField, setDatatableCustomField, ...props };
}
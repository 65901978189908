import { emptyFilterField } from '../../component/admin-module/module/business-process/module/configuration/module/task-view/constant';
import { IApiTaskView, IApiTaskViewConfig, ITaskView } from '../../component/admin-module/module/business-process/module/configuration/module/task-view/interface/task-view';
import { SanitizeCustomFilter } from './sanitize-custom-filter';
import { SanitizeDocumentList } from './sanitize-document-list';

export class SanitizeTaskView {
  static structureOutgoing = (taskView: ITaskView): IApiTaskView => {
    const apiTaskView: IApiTaskView = { statusLayouts: [] };

    const taskViewKeys = Object.keys(taskView);
    if (taskViewKeys.length > 0) {
      taskViewKeys.forEach((statusId: string) => {
        const taskViewConfig = taskView[statusId];
        const columns = SanitizeDocumentList.structureOutgoing(taskViewConfig.columnHeader);
        if (taskViewConfig && (taskViewConfig.referToStatus || (!taskViewConfig.referToStatus && columns.length > 0))) {
          const tempData: any = {
            statusId: statusId,
            referToStatusId: taskViewConfig.referToStatus,
          }
          if (!taskViewConfig.referToStatus && columns.length > 0) {
            const filters = SanitizeCustomFilter.structureOutgoing(taskViewConfig.filterField)
            tempData.layout = {
              filters: filters.length > 0 ? filters : new Array(emptyFilterField),
              columns: columns,
            }
          }
          apiTaskView.statusLayouts.push(tempData)
        }
      })
    }

    return apiTaskView;
  }

  static structureIncoming = (apiTaskView: IApiTaskView): ITaskView => {
    const taskView: ITaskView = {};

    apiTaskView?.statusLayouts?.forEach((config: IApiTaskViewConfig) => {
      taskView[config.statusId] = {
        referToStatus: config.referToStatusId,
        filterField: SanitizeCustomFilter.structureIncoming(config?.layout?.filters || []),
        columnHeader: SanitizeDocumentList.structureIncoming(config?.layout?.columns || [])
      }
    })

    return taskView;
  }
}
interface IProps {
  thousandSeparator: boolean;
  decimals: number;
}

export const isValidNumber = (value: string, props: IProps) => {
  let regExStr = '^-*\\d*$';
  if (props.thousandSeparator && props.decimals) {
    regExStr = `^-*\\d{1,3}(,\\d{3})*(\\.\\d{1,${props.decimals}})?$`;
  }
  if (props.thousandSeparator && !props.decimals) {
    regExStr = '^-*\\d{1,3}(,\\d{3})*$';
  }
  if (!props.thousandSeparator && props.decimals) {
    regExStr = `^-*\\d*(\\.\\d{1,${props.decimals}})?$`;
  }

  const regex = RegExp(regExStr, 'i');
  return regex.test(value);
}
import React from 'react';
import { IField } from '../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../field/component/field-element';

interface IRangeFieldProps {
  fieldData: any;
  configData: any;
  inputObject: any;
  defaultvalueRef: any;
  havePlaceholder?: boolean;
  placeHolder?: string;
}

const RangeField: React.FC<IRangeFieldProps> = (props) => {
  let validationSchema: any = props.fieldData ? { type: props.fieldData.type || 'string' } : { type: 'string' };

  let tempField: IField = {
    id: 'defaultField',
    inputConfig: {
      type: props.fieldData.type,
      config: {
        from: {
          config: props.configData.itemConfigFrom,
          type: props.configData.itemType
        } as any,
        to: {
          config: props.configData.itemConfigTo,
          type: props.configData.itemType
        } as any,
      },
    },
    label: '',
    validationSchema
  };

  const field = FieldElement.getDataElement(tempField);
  const Element = field.getFieldElement();

  return <div style={{ flexDirection: 'column' }} className={`flex-wrapper ${props.configData.itemType}`}>
    <Element
      showErrorMessage {...field}
      forwardedRef={props.defaultvalueRef}
      defaultValueElement={true}
      havePlaceholder={props.havePlaceholder}
      placeHolder={props.placeHolder}
    />
  </div>
}

export default RangeField;
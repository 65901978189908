import React, { useEffect, useState } from 'react';
import { IFieldPermissionSettings } from '../..';
import { actionPermissionList } from '../../../constant';
import { IRulesPermission } from '../../../interface/rules';
import { TConfigType, TRulesPermission } from './index';

interface IPermissionConfigProps extends IFieldPermissionSettings {
  boolSelected: boolean;
  rules: IRulesPermission;
  updateConfig(configType: TConfigType, configOption: TRulesPermission, value: any): void;
}

const ActionPermissionConfig: React.FC<IPermissionConfigProps> = (props) => {

  const [permissionAction, setActionPermission] = useState<string>('enabled');

  const handlePermissionChange = (value: string) => {
    setActionPermission(value);
    props.updateConfig(props.boolSelected ? 'onTrue' : 'onFalse', 'setPermission', value);
  }

  useEffect(() => {
    if (props.rules) {
      setActionPermission(props.rules.setPermission || 'enabled');
    }
  }, [props.rules])

  return <div className='slider-container-main action'>
    <div className='slider-container no-label'>
      <div className='slider-container'>
        <div id="button-config-container">
          <div className='action-permission-list'>
            {actionPermissionList.map(permission => {
              return <div key={permission.id}
                onClick={() => handlePermissionChange(permission.value)}
                className={`action-permission-btn ${permissionAction === permission.value ? 'selected' : ''}`}>
                {permission.label.toUpperCase()}
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default ActionPermissionConfig;
import React from 'react';
import TableListDetail from './table-list-detail';
import { IFormStatus } from '../interface/form-status';
import { IFormAction } from '../interface/form-action';

interface ITableListProps {
  formStatusList: IFormStatus[],
  createNewAction(originStatusId: string, targetStatusId: string, originStatusIndex: number, targetStatusIndex: number): void;
  actionList: IFormAction[];
  editAction(formAction: IFormAction): void;
}

const tableList: React.FC<ITableListProps> = (props) => {
  return <>
    <tbody>
      {
        props.formStatusList.map((formStatus: IFormStatus, index: number) => {
          if (formStatus.deleted === true) {
            return undefined;
          }
          let color = 'bg-blue';
          let textColor = 'text-white';
          let iconColor = '#fff';

          if (formStatus.initial) {
            color = 'bg-gray';
            textColor = 'text-blue';
            iconColor = '#3a85a8';
          }

          if (formStatus.final && !formStatus.finalPositive) {
            color = 'bg-red';
          }

          if (formStatus.finalPositive && formStatus.final) {
            color = 'bg-green';
          }

          return <TableListDetail
            targetStatus={formStatus}
            targetStatusIndex={index}
            formStatusList={props.formStatusList}
            key={`table-list-form-status-${formStatus.id}`}
            bgColor={color}
            labelColor={textColor}
            labelText={formStatus.name.toUpperCase()}
            iconColor={iconColor}
            createNewAction={props.createNewAction}
            actionList={props.actionList}
            editAction={props.editAction}
          />
        })
      }
    </tbody>
  </>
}

export default tableList;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, postRequest, deleteRequest, putRequest, IAxiosResponse } from '../../../../../../common/api-request';
import { setStatusCodeAction } from '../../../../../error/action';
import {
  GET_FORM_STATUS_LIST,
  GET_USER_LIST,
  GET_GROUP_LIST,
  GET_USER_FIELD_LIST,
  SAVE_ROLE,
  GET_ROLE_LIST,
  DELETE_ROLE,
  PUBLISH_ROLE,
  GET_USER_CUSTOM_FIELD,
  GET_CONSTRAINT_TEMPLATE,
  GET_FORM_FIELD
} from './constant';
import { getFormStatusListReturnAction, getUserListReturnAction, getGroupListReturnAction, getUserFieldListReturnAction, saveFormRoleReturnAction, saveFormRoleErrorAction, getRoleListReturnAction, getRoleListErrorAction, publishRoleReturnAction, publishRoleErrorAction, getUserCustomFieldReturnAction, getConstraintTemplateReturnAction, getFormFieldReturnAction } from './action';
import { IFormStatusListInput } from '../workflow/interface/input/form-status-list';
import { IUserListInput } from '../../../users/interface/input/user-list';
import { IGroupListInput } from '../../../groups/interface/input/group-list';
import { UserFieldListInput } from '../../../users/interface/input/user-field-list';
import { IFormRoleSaveInput, IFormRolePublishInput } from './interface/input/form-role-save';
import { SanitizeRole } from '../../../../../../common/utils/sanitize-form-role';
import { IFormRoleGetInput } from './interface/input/form-role-get';
import { IFormRoleDeleteInput } from './interface/input/form-role-delete';
import { IUserCustomFieldGetInput } from './interface/input/user-custom-field-get';
import { IConstraintTemplateGetInput, IConstraintTemplateUpdateInput } from './interface/input/constraint-template';
import { IFormFieldGetInput } from './interface/input/form-field-get';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { SanitizeField } from '../../../../../../common/utils/sanitize-field';

export function* getFormStatusList(args: IFormStatusListInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true&load=actions`, {});
    yield put(getFormStatusListReturnAction({
      statusList: req.data.data
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getUserList(args: IUserListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/users?company=${company}&limit=0&load=profiles`, {});
    yield put(getUserListReturnAction({ userList: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getGroupList(args: IGroupListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/groups?load=users&load=groups&offset=0&limit=0`, {});
    yield put(getGroupListReturnAction({ groupList: req.data.data }))
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getUserFieldList(args: UserFieldListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/users/fields?company=${company}`, {});
    yield put(getUserFieldListReturnAction({ userFieldList: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e);
    // do nothing for now
  }
}

export function* saveRole(args: IFormRoleSaveInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    let roleList = args.roleList;
    let constraint = args.constraint;
    const constraintReq: IAxiosResponse = yield call(putRequest, `${companyId}/forms/${formId}/constraintTemplates`, constraint);
    if (constraintReq.data.data) {
      const constraintList = constraintReq.data.data;
      roleList = SanitizeRole.updateRoleListConstraint(roleList, constraintList);

      yield call(postRequest, `${companyId}/forms/${formId}/roles/batch?load=groups&load=users&load=actions&load=constraints`, SanitizeRole.outgoingFormRole(roleList));

      const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/roles?load=groups&load=users&load=actions&draft=true&load=constraints`, {});
      yield put(saveFormRoleReturnAction({ roleList: SanitizeRole.incomingFormRole(req.data.data), saveRoleType: args.saveType, constraintTemplateList: constraintList }));
    }
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    yield put(saveFormRoleErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* publishRole(args: IFormRolePublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishRoleReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishRoleErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* getRoleList(args: IFormRoleGetInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/roles?load=groups&load=users&load=actions&draft=true&load=constraints`, {});
    yield put(getRoleListReturnAction({ roleList: SanitizeRole.incomingFormRole(req.data.data) }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    yield put(getRoleListErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* deleteRole(args: IFormRoleDeleteInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const roleId = args.roleId;
    yield call(deleteRequest, `${companyId}/forms/${formId}/roles/${roleId}`, {});
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getUserCustomField(args: IUserCustomFieldGetInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/userFields`, {});
    yield put(getUserCustomFieldReturnAction({ userCustomField: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e);
    // do nothing for now
  }
}

export function* getConstraintList(args: IConstraintTemplateGetInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/constraintTemplates`, {});
    yield put(getConstraintTemplateReturnAction({ constraintTemplateList: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e);
    // do nothing for now
  }
}

export function* updateConstraintTemplate(args: IConstraintTemplateUpdateInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    let constraint = args.constraint;
    yield call(putRequest, `${company}/forms/${formId}/constraintTemplates`, constraint);
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e);
    // do nothing for now
  }
}

export function* getFormFieldList(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const fieldData: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormFieldReturnAction({
      formFieldList: SanitizeField.structureIncomingField(fieldData.data.data) as ICustomFieldDetail[]
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* formSagas() {
  yield takeLatest(GET_FORM_STATUS_LIST, getFormStatusList);
  yield takeLatest(GET_USER_LIST, getUserList);
  yield takeLatest(GET_GROUP_LIST, getGroupList);
  yield takeLatest(GET_USER_FIELD_LIST, getUserFieldList);
  yield takeLatest(SAVE_ROLE, saveRole);
  yield takeLatest(PUBLISH_ROLE, publishRole);
  yield takeLatest(GET_ROLE_LIST, getRoleList);
  yield takeLatest(DELETE_ROLE, deleteRole);
  yield takeLatest(GET_USER_CUSTOM_FIELD, getUserCustomField);
  yield takeLatest(GET_CONSTRAINT_TEMPLATE, getConstraintList);
  yield takeLatest(GET_FORM_FIELD, getFormFieldList);
}

// All sagas to be loaded
export default formSagas;
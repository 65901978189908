import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { ISubMenuList } from '../../../../admin-module/module/business-process/module/configuration/sub-menu/interface/sub-menu-list';
import { ISubMenuListProp } from '../../../../admin-module/module/business-process/module/configuration/sub-menu/interface/sub-menu-list-props';

export class PortalBPSubMenuList implements ISubMenuList {

  name = 'business-processes'

  Render: React.FC<ISubMenuListProp> = (props) => {
    return <div id='route-container'>
      {
        props.modules.map(({ id, name }, key) => {
          return (
            <React.Fragment key={key}>
              <Button onClick={() => {
                props.history.push(`/portal/${this.name}/${id}`)
              }} className={`portal-item-menu ${id.toLowerCase() === props.main.thirdLevelRoute.toLowerCase() ? 'active-portal-subroute' : ''}`}>{name}</Button>
            </React.Fragment>
          )
        })
      }
    </div>
  }
}
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';
import { IField, IFieldAccessType, IFieldConfig } from '../../../../admin-module/module/users/interface/field';

interface IUtils {
  fxFieldReload?: IField[];
  triggeredOnChange?: boolean;
}

export const manageFxFieldReload = (fieldState: IField[], { fxFieldReload, triggeredOnChange }: IUtils) => {
  if (triggeredOnChange && fxFieldReload && fxFieldReload.length) {
    fieldState = fieldState.map((field: IField) => {
      const oldFxData = fxFieldReload && fxFieldReload.find(e => e.id === field.id);
      if (oldFxData) {
        const accessType = (oldFxData.accessType === IFieldAccessType.ReadWrite
          || oldFxData.accessType === IFieldAccessType.Readonly)
          ? IFieldAccessType.Readonly : oldFxData.accessType;
        field.accessType = accessType;
        if (oldFxData.configName === IConfigName.Table) {
          const { inputConfig } = { ...oldFxData };
          if (inputConfig) {
            const { columns }: IFieldConfig = { ...inputConfig.config };
            if (columns) {
              const fxColumns = columns.filter(e => e.configName === IConfigName.FX);
              const { config } = { ...field.inputConfig };
              if (config && config.columns && fxColumns.length > 0) {
                config.columns = config.columns.map(e => {
                  const oldColData = columns.find(fx => fx.id === e.id);
                  if (oldColData) {
                    e.accessType = oldColData.accessType;
                  }
                  return e;
                });
              }
            }
          }
        }
      }
      return field;
    });
  }
  return fieldState;
}
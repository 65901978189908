import React from 'react';
import { Segment, Button, Input, Dropdown } from 'semantic-ui-react-bpm';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAppMessageAction } from '../action';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import { setOpenAdminChatboxAction } from '../../../../main/action';
import { getAdminHistoryLogsAction } from '../../../history/action';
import { IRootState } from '../../../../../reducers';
import { AdminHistoryCategory } from '../../../history/interface/history';

interface IAppFilter {
  isOpenChatbox: boolean;
  allowedAccessAuditLogs: boolean;
}

export class AppFilter {

  static Render: React.FC<IAppFilter> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const companyId = useSelector((state: IRootState) => state.auth.company);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const options = [
      { key: 1, text: 'Choice 1', value: 1 },
      { key: 2, text: 'Choice 2', value: 2 },
      { key: 3, text: 'Choice 3', value: 3 },
    ]

    const openAppFormModal = () => {
      history.push('/admin/apps/add-app');
      dispatch(clearAppMessageAction());
    }

    const setIsOpenChatbox = () => {
      dispatch(setOpenAdminChatboxAction(true));
      dispatch(getAdminHistoryLogsAction({
        company: companyId,
        categories: [AdminHistoryCategory.Apps],
        tz: tz
      }));
    }

    return <Segment className='borderless filter-wrapper'>
      {props.allowedAccessAuditLogs &&
        <Button className={`filter-users chat-icon ${!props.isOpenChatbox ? 'inactive' : ''}`} primary onClick={setIsOpenChatbox}>
          <SVGWrapper iconName='adminChatIcon' width='20' color={`${!props.isOpenChatbox ? '#3a85a8' : '#fff'}`} />
        </Button>
      }
      <Button type='button' className={`add-button ${props.allowedAccessAuditLogs ? 'hasAuditLogAccess' : ''}`}
        primary size={"large"} floated={"right"} onClick={() => { openAppFormModal() }}>Add App</Button>
      <div style={{ display: 'none' }} className='search-app-container'>
        <Input placeholder='App ID/Name' />
        <Button className="search-app" primary icon={{ name: 'search' }}></Button>
        <Dropdown selectOnBlur={false} clearable options={options} text='Business Processes' selection
          popperContainer={DropdownPopperOption}
        />
        <Button className="filter-app" primary icon={{ name: 'filter' }}></Button>
      </div>
    </Segment>
  }

}
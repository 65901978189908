import prettyBytes from 'pretty-bytes';
import React, { Fragment } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { UploadedFile } from '../../../../../../../../../../common/api-request/upload';
import { isEllipsisActive } from '../../../../../../../../../../common/utils/check-text-overflow';
import { getFileType } from './get-file-type';
import { imageForFile } from './image-for-file';

export const renderFilePreview = (attachment: UploadedFile[]) => {
  let attachmentBefore: any = [];
  let attachmentAfter: any = [];

  if (
    (attachment[1] && attachment[1].hasOwnProperty('name')) ||
    (attachment[0] && attachment[0].hasOwnProperty('name'))
  ) {
    attachmentBefore = new Array(attachment[0]);
    attachmentAfter = new Array(attachment[1]);
  } else {
    attachmentBefore = attachment[0];
    attachmentAfter = attachment[1];
  }

  const fileRef = (element: HTMLParagraphElement | null) => {
    if (!element) return;
    if (isEllipsisActive(element)) {
      element.title = element.innerText;
    }
  }

  const fileInfo = (file: UploadedFile) => {
    const url = getFileType(file).isSupportedImage ? file.url
      : imageForFile({ ...file, mime: file.type }, false).icon;

    return <span key={file.id} className='file-container'>
      <Image className='file-icon' src={url} />
      <span className='file-info'>
        <p ref={fileRef} className='file-name'>{file.name}</p>
        <p className='file-size'> ({prettyBytes(file.size)})</p>
      </span>
    </span>
  }

  const renderPreview = (file: UploadedFile[]) => {
    return <>
      {file && file instanceof Array && file.length > 0 ? file.map(info => {
        if (info && typeof (info.name && info.size && info.type) !== 'undefined') {
          return fileInfo(info)
        }
        return 'No File'
      }) : 'No File'}
    </>
  }

  return <Fragment key={uuid()}>
    <div className='preview before'>{renderPreview(attachmentBefore)}</div>
    <div className='arrow-right'>&#8594;</div>
    <div className='preview after'>{renderPreview(attachmentAfter)}</div>
  </Fragment>
}
import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react-bpm';
import { IRootState } from '../../../../../../reducers';
import { commentFilterTypeList } from '../../constant';

interface IChatFilterTypeProps {
  selectedFilter: string[];
  filterComment(value: any): void;
}

const ChatFilterType: React.FC<IChatFilterTypeProps> = (props) => {
  const {
    hasAttachment,
    hasSystemComment,
    hasUserComment
  } = useSelector((state: IRootState) => state.documentForm);
  const { comment } = useSelector((rootState: IRootState) => rootState.main.companyColor);

  return <div className={`filter-by-type`}>
    {commentFilterTypeList.map(filter => {
      const isSelected = props.selectedFilter.indexOf(filter.id) > -1;
      const isDisabled = ((filter.id === 'user' && !hasUserComment)
        || (filter.id === 'system' && !hasSystemComment)
        || (filter.id === 'attachments' && !hasAttachment));

      return <Image
        key={filter.id}
        className={`${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
        onClick={() => { if (hasUserComment) props.filterComment(filter.id) }}
        src={isSelected ? filter.iconSelected : filter.iconDefault}
        style={isSelected ? { backgroundColor: comment.filterHighlight } : {}}
      />
    })}
  </div>
}

export default ChatFilterType;
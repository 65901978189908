import { publishTaskViewAction, saveTaskViewAction } from '../../configuration/module/task-view/action';
import { emptyFilterField } from '../../configuration/module/task-view/constant';
import { IApiTaskView, IApiTaskViewConfig } from '../../configuration/module/task-view/interface/task-view';

interface IUpdateConfigUtils {
  deletedExistingField: string[];
  apiTaskView: IApiTaskView;
  formId: string;
  companyId: string;
  dispatch: any;
}

export class CheckFieldTaskLayout {

  static updateConfig = (utils: IUpdateConfigUtils) => {
    const data = CheckFieldTaskLayout.removeFieldFromConfig(utils.deletedExistingField, utils.apiTaskView);
    const payload = { company: utils.companyId, formId: utils.formId };
    if (data && data.statusLayouts) {
      utils.dispatch(saveTaskViewAction({
        ...payload,
        taskView: { ...data as any },
        saveType: 'draft'
      })
      );
      utils.dispatch(publishTaskViewAction(payload));
    }
  }

  static removeFieldFromConfig = (deletedFields: string[], apiTaskView: IApiTaskView) => {
    const fieldTaskLayout = CheckFieldTaskLayout.isUsedInTaskLayout(deletedFields, apiTaskView.statusLayouts);

    if (!fieldTaskLayout || (fieldTaskLayout && !fieldTaskLayout.length)) {
      return null;
    }

    return {
      statusLayouts: CheckFieldTaskLayout.updateTaskLayoutConfig(deletedFields, apiTaskView.statusLayouts)
    } as IApiTaskView;
  }

  static isUsedInTaskLayout = (deletedFields: string[], apiTaskViewConfig: IApiTaskViewConfig[]) => {
    const deletedFieldsKeypath = deletedFields.map(id => `fields.${id}`);
    return apiTaskViewConfig && apiTaskViewConfig.filter(taskView => {
      if (taskView.layout) {
        return taskView.layout.columns.filter(columns => deletedFieldsKeypath.indexOf(columns.keyPath) > -1).length > 0 ||
          taskView.layout.filters.filter(filters => {
            return filters.keyPaths.filter(keyPath => deletedFieldsKeypath.indexOf(keyPath) > -1).length > 0
          }).length > 0;
      }
      return false;
    })
  }

  static transformKeypaths = (keyPaths: string[]) => {
    return keyPaths.map(key => {
      if (key.indexOf('fields.') > -1) {
        key = key.split('fields.')[1];
      }
      return key;
    })
  }

  static updateTaskLayoutConfig = (deletedFields: string[], apiTaskViewConfig: IApiTaskViewConfig[]) => {
    const deletedFieldsKeypath = deletedFields.map(id => `fields.${id}`);
    const tempAPITaskViewConfig = apiTaskViewConfig.map(taskView => {
      if (taskView.layout) {
        taskView.layout.filters = taskView.layout.filters
          .map(filters => {
            filters.keyPaths = filters.keyPaths.filter(keyPath => deletedFieldsKeypath.indexOf(keyPath) === -1);
            return filters;
          })
          .filter(filters => filters.keyPaths.length > 0);
        taskView.layout.columns = taskView.layout.columns.filter(columns => {
          return deletedFieldsKeypath.indexOf(columns.keyPath) === -1
        });
        if (!taskView.layout.filters.length && taskView.layout.columns.length) {
          taskView.layout.filters = new Array(emptyFilterField);
        }
      }
      return taskView;
    });
    return tempAPITaskViewConfig.filter(taskView => {
      if (taskView && taskView.referToStatusId) {
        const dataReferred = apiTaskViewConfig.find(e => e.statusId === taskView.referToStatusId);
        if (dataReferred && dataReferred.layout && !dataReferred.layout.columns.length && !dataReferred.layout.filters.length) {
          return false;
        }
      }
      if (taskView && taskView.layout && !taskView.layout.columns.length && !taskView.layout.filters.length) {
        return false;
      }
      return true;
    });
  }
}
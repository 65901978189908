import React from 'react';
import Email from './string-type/email';
import Url from './string-type/url';
import Paragraph from './string-type/paragraph';
import Line from './string-type/line';
import Phone from './string-type/phone';
import { AcceptedProps } from './interface/accepted-props';
import { v4 as uuid } from 'uuid';

const StringObject: React.FC<AcceptedProps> = (props) => {
  let { config } = props.inputConfig;
  const { displayType, format } = { ...config };

  if (format && format === 'email') return <Email key={`email-${props.id}-${uuid()}`} {...props} />;
  if (format && format === 'url') return <Url key={`url-${props.id}-${uuid()}`} {...props} />;
  if (format && format === 'phone') return <Phone key={`phone-${props.id}-${uuid()}`} {...props} />;
  if (displayType && displayType === 'paragraph') return <Paragraph key={`paragraph-${props.id}-${uuid()}`} {...props} />;
  return <Line key={`line-${props.id}-${uuid()}`} {...props} />;
}

export default StringObject;
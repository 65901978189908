export const GET_USER_BUSINESS_PROCESS = 'app/endUserModule/GET_USER_BUSINESS_PROCESS';
export const GET_USER_BUSINESS_PROCESS_RETURN = 'app/endUserModule/GET_USER_BUSINESS_PROCESS_RETURN';
export const GET_USER_BUSINESS_PROCESS_ERROR = 'app/endUserModule/GET_USER_BUSINESS_PROCESS_ERROR';
export const GET_END_USER_DETAIL = 'app/endUserModule/GET_END_USER_DETAIL';
export const GET_END_USER_DETAIL_RETURN = 'app/endUserModule/GET_END_USER_DETAIL_RETURN';
export const GET_END_USER_DETAIL_ERROR = 'app/endUserModule/GET_END_USER_DETAIL_ERROR';
export const SET_SELECTED_BP = 'app/endUserModule/SET_SELECTED_BP';
export const SET_SELECTED_APP = 'app/endUserModule/SET_SELECTED_APP';
export const GET_CURRENT_USER_IN_DOCUMENT = 'app/endUserModule/GET_CURRENT_USER_IN_DOCUMENT';
export const SET_CURRENT_USER_IN_DOCUMENT = 'app/endUserModule/SET_CURRENT_USER_IN_DOCUMENT';
export const SET_DOCUMENT_ACTION_MESSAGE = 'app/endUserModule/SET_DOCUMENT_ACTION_MESSAGE';
export const CLEAR_DOCUMENT_ACTION_MESSAGE = 'app/endUserModule/CLEAR_DOCUMENT_ACTION_MESSAGE';
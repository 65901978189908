import React from 'react';
import StakeholdersModule from '../module/stakeholders-view';
import FormViewModule from '../module/form-view';
import ListViewModule from '../module/list-view';
import PermissionModule from '../module/permissions';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';

const ModuleList = [
  new StakeholdersModule(),
  new FormViewModule(),
  new PermissionModule(),
  new ListViewModule()
]

interface IRender {
  type: string;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

interface IPortalBPModuleRegistry {
  name: string;
  render: React.FC<any>
}

export class PortalBPModuleRegistry {
  registry: { [name: string]: IPortalBPModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const portalBPSubModule = new PortalBPModuleRegistry();
    const Render = portalBPSubModule.getModule(props.type);
    return <Render {...props} />
  }
}
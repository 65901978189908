import {
  GET_FIELD_LIST_RETURN,
  GET_FIELD_LIST,
} from './constant';
import { ICustomFieldDetail } from './interface/custom-field-detail';

export interface ICustomFieldReducer {
  type?: string;
  createCustomFieldMessage: string;
  updateCustomFieldMessage: string;
  deleteCustomFieldMessage: string;
  displayFieldList: ICustomFieldDetail[],
}

const initialState: ICustomFieldReducer = {
  createCustomFieldMessage: '',
  updateCustomFieldMessage: '',
  deleteCustomFieldMessage: '',
  displayFieldList: [],
};

const reducer = (state = { ...initialState }, action: ICustomFieldReducer) => {
  let newState = { ...state };
  switch (action.type) {

    case GET_FIELD_LIST:
      newState.displayFieldList = [];
      return newState;

    case GET_FIELD_LIST_RETURN:
      newState.displayFieldList = action.displayFieldList;
      return newState;

    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { IOptionValue } from '../../component/admin-module/module/users/interface/field';
import { ValueType } from 'react-select';
import { IConfigName } from '../field/type/interface/field-type-object';
import EnumConfigPopup from './custom-field-type/enum-field-config/enum-config-popup';
import EnumSortPopup from './custom-field-type/enum-field-config/enum-sort-popup';
import { IEnumSelectableItems } from './interface/enum-field-config';

const SelectableItems: React.FC<IEnumSelectableItems> = (props) => {

  return <div id='dropdown-section' className={`${props.fieldData && props.fieldData.type === 'enum' && props.configName !== IConfigName.Referenced ? '' : 'hidden'}`}>
    <div className='dropdown-header'>
      <label>Selectable Items: </label>
      <EnumSortPopup sort={props.sort} setSort={props.setSort} />
    </div>
    {props.enumDataType !== 'database' && props.enumDataType !== 'document' &&
      <div className='dropdown-content'>
        <CreatableSelect
          components={props.components}
          inputValue={props.inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onInputChange={(inputValue: string) => props.handleOptionInputChange(inputValue)}
          onKeyDown={props.handleOptionKeyDown}
          value={props.optionValue.filter((option: IOptionValue) => !option.deleted)}
          onChange={(value: ValueType<IOptionValue>) => props.handleOptionOnChange(value)}
          placeholder="Type something and press enter..."
        />
      </div>
    }
    <div className={`display-field-checkbox`}>
      <EnumConfigPopup {...props} />
    </div>
  </div>
}

export default SelectableItems;
import { useEffect } from 'react';

export const useRemoveConstraintModalEl = (): void => {
  useEffect(() => {
    return () => {
      const modal = document.getElementById('constraint-modal');
      const hiddenContainer = document.getElementById('constraint-modal-hidden-container');
      if (modal) {
        modal.parentNode?.removeChild(modal);
      }
      if (hiddenContainer) {
        hiddenContainer.parentNode?.removeChild(hiddenContainer);
      }
    }
  }, []);
}
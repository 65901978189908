import React from 'react';
import { Dropdown, DropdownProps, Image } from 'semantic-ui-react-bpm';
import { IOption } from '../../../../../../../../../common/interface/option';
import { ITableMobileView } from '../../../interface/table-view';
import { fieldIconBaseOnConfigName } from '../../../../configuration/module/document-list/constant';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IContentUpperWidgetSmall {
  columnOptions: IOption[];
  tableMobileView: ITableMobileView;
  updateTableMobileView: (property: string, value: any) => void;
  index: string;
  columns: ICustomFieldDetail[];
}

const ContentUpperWidgetSmall: React.FC<IContentUpperWidgetSmall> = (props) => {
  const value = props.tableMobileView.lowerField;

  const iconToDisplay = () => {
    if (value) {
      const field = props.columns.find(e => e.id === value);
      if (field && field.configName) {
        const fieldIcon = fieldIconBaseOnConfigName[field.configName];
        return <Image src={fieldIcon.icon} className={`field icon ${field.configName}`} />
      }
    }
    return null;
  }

  return <div className='input-dropdown-container'>
    {iconToDisplay()}
    <Dropdown
      className={`input-dropdown ${value ? 'hasValue' : ''}`}
      search
      clearable
      selectOnBlur={false}
      placeholder='Select Field'
      selection
      options={props.columnOptions}
      onChange={(e, target: DropdownProps) => {
        props.updateTableMobileView(props.index, {
          ...props.tableMobileView,
          lowerField: target.value
        });
      }}
      value={value}
      text={!value ? 'Select Field' : ''}
    />
  </div>
}

export default ContentUpperWidgetSmall;

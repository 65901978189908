import React from 'react';
import { Image, Dropdown } from 'semantic-ui-react-bpm';
import { IFormRole } from '../interface/form-role';
import { IFormStatusWithAction } from '../../workflow/interface/form-status';
import { IFormAction } from '../../workflow/interface/form-action';
import { IOption } from '../../../../../../../common/interface/option';
import ToolTip from '../../../../../../../common/general/tooltip';
import DropdownPopperOption from '../../../../../../../common/general/dropdown-popper-option';
import { icons } from '../../../../../../../common/icons';
import { IConstraint } from '../interface/constraint';
import { v4 as uuid } from 'uuid';
import { operatorSymbol } from '../constant';
import { useRemoveRoleActionModalEl } from './hooks/use-remove-role-action-modal-el';
import { useSetUserRoleActions } from './hooks/use-set-user-role-actions';
import { useSetConstraintAccessLevel } from './hooks/use-set-constraint-access-level';

interface IRoleActionModalProps {
  status: IFormStatusWithAction | undefined;
  role: IFormRole | undefined;
  isHiddenContainer: boolean;
  updateRoleList(roleId: string, statusId: string, actions: IFormAction[]): void;
  addStatusConstraint(constraint: IConstraint): void;
  currentRoleList: IFormRole[];
  selectedRoleStatusConstraint: { statusId: string, roleId: string };
  constraintList: IConstraint[];
  setSelectedRoleStatus(value: { status: IFormStatusWithAction | undefined, role: IFormRole | undefined }): void;
}

const RoleActionModal: React.FC<IRoleActionModalProps> = (props) => {

  const {
    accessLevel,
    fieldCompareConstraint
  } = useSetConstraintAccessLevel({
    currentRoleList: props.currentRoleList,
    selectedRoleStatusConstraint: props.selectedRoleStatusConstraint
  });
  const {
    noAccess,
    setNoAccess,
    view,
    setView,
    save,
    setSave,
    assign,
    setAssign,
    upload,
    setUpload,
    selectedActions,
    setSelectedActions
  } = useSetUserRoleActions({ role: props.role, status: props.status });

  let noAccessIcon = require('../../../../../../../common/icons/blue/no-access.png');
  let eyeIcon = require('../../../../../../../common/icons/blue/eye.png');
  let editIcon = require('../../../../../../../common/icons/blue/icon-edit.png');
  let assignIcon = require('../../../../../../../common/icons/blue/open.png');
  let uploadIcon = require('../../../../../../../common/icons/blue/bulk-upload.png');

  if (noAccess) {
    noAccessIcon = require('../../../../../../../common/icons/white/no-access.png');
  }
  if (view) {
    eyeIcon = require('../../../../../../../common/icons/white/eye.png');
  }
  if (save) {
    editIcon = require('../../../../../../../common/icons/white/icon-edit.png');
  }
  if (assign) {
    assignIcon = require('../../../../../../../common/icons/white/open.png');
  }
  if (upload) {
    uploadIcon = require('../../../../../../../common/icons/white/bulk-upload.png');
  }

  let ownerIcon = icons.blue.lock;
  let interactionIcon = icons.blue.interact;
  let everyoneIcon = icons.blue.world;

  if (accessLevel === 'creator') {
    ownerIcon = icons.white.lock;
  }
  if (accessLevel === 'owner') {
    interactionIcon = icons.white.interact;
  }
  if (accessLevel === 'everyone') {
    everyoneIcon = icons.white.world;
  }

  const updateRoleAction = () => {
    const actionList = props.status && props.status.actions ? props.status.actions : [];
    let actions: IFormAction[] = [];

    const viewAction = actionList.filter((e: IFormAction) => e.type === 'view');
    const saveAction = actionList.filter((e: IFormAction) => e.type === 'save');
    const uploadAction = actionList.filter((e: IFormAction) => e.type === 'upload');
    const assignAction = actionList.filter((e: IFormAction) => e.type === 'assign');

    if (view) {
      actions = actions.concat(viewAction)
    }
    if (save) {
      const includeSave = props.status && props.status.initial === false;
      actions = actions.concat(viewAction);
      if (includeSave) {
        actions = actions.concat(saveAction);
      }
    }
    if (assign) {
      actions = actions.concat(assignAction)
    }
    if (upload) {
      actions = actions.concat(uploadAction)
    }
    if (selectedActions.length > 0) {
      actions = actions.concat(selectedActions);
    }
    if (noAccess) {
      actions = [];
    }
    const roleId = props.role ? props.role.id : '';
    const statusId = props.status ? props.status.id : '';
    console.log(actions)
    props.updateRoleList(roleId, statusId, actions)
  }

  const setNoAccessToAll = (value: boolean) => {
    setNoAccess(value);
    if (value) {
      setView(false);
      setSave(false);
      setAssign(false);
      setUpload(false);
      setSelectedActions([]);
    }
  }

  const setViewAccess = (value: boolean) => {
    if (value) {
      setNoAccess(false);
      setSave(false);
    }
    setView(value);
    setSelectedActions([]);
  }

  const setSaveAccess = (value: boolean) => {
    if (value) {
      setNoAccess(false);
      setView(false);
    }
    setSave(value);
  }

  const setAssignAccess = (value: boolean) => {
    if (value) {
      setNoAccess(false);
    }
    setAssign(value);
  }

  const setUploadAccess = (value: boolean) => {
    if (value) {
      setNoAccess(false);
    }
    setUpload(value);
  }

  const deleteSelectedAction = (deleteAction: IFormAction) => {
    const updateSelectedActions = selectedActions.filter((action: IFormAction) => deleteAction.id !== action.id);
    setSelectedActions(updateSelectedActions);
  }

  const addConstraint = (constraintId: string) => {
    const selectedConstraint = props.constraintList.filter((e: IConstraint) => e.style.constraintId === constraintId);
    if (selectedConstraint.length > 0) {
      props.addStatusConstraint(selectedConstraint[0]);
    }
  }

  const getOptions = (): IOption[] => {
    const options = [] as IOption[];
    if (props.status && props.status.actions) {
      props.status.actions.forEach((action: IFormAction, index: number) => {
        const includeSave = props.status && props.status.initial === true;
        const selected = selectedActions.filter((selAction: IFormAction) => selAction.id === action.id && props.status && props.status.id === selAction.statusId);
        if (selected.length === 0 && (action.type === 'dynamic' || (action.type === 'save' && includeSave))) {
          const name = !action.name && action.type === 'save' ? 'SAVE' : action.name;
          options.push({ key: index, value: action.id, text: name })
        }
      })
    }
    return options;
  }

  const getConstraintListOptions = (): IOption[] => {
    const options = [] as IOption[];
    props.constraintList.forEach((constraint: IConstraint, index: number) => {
      const selected = fieldCompareConstraint.filter((e: IConstraint) => e.style && constraint.style && e.style.constraintId === constraint.style.constraintId);
      if (selected.length === 0) {
        options.push({ key: index, value: constraint.style.constraintId, text: constraint.style.constraintName })
      }
    })
    return options;
  }

  useRemoveRoleActionModalEl();

  return <>
    <div hidden={props.isHiddenContainer} id='role-action-modal-hiddencontainer' className='hidden-container' onClick={updateRoleAction} />
    <div id='role-action-modal' hidden={props.isHiddenContainer} >
      {
        props.role &&
        <>
          <div className={`header-name`}>User Rights</div>
          <div className={`static-button-container`}>
            <ToolTip position='left center'
              popupMessage='No access'
              popupTriggerComponent={
                <Image className={`${noAccess ? 'active' : 'inactive'}`} src={noAccessIcon}
                  onClick={() => setNoAccessToAll(true)}
                />
              }
            />
            <ToolTip position='top center'
              popupMessage='View access'
              popupTriggerComponent={
                <Image className={`${view ? 'active' : 'inactive'} eye`} src={eyeIcon} onClick={() => setViewAccess(true)} />
              }
            />
            <ToolTip position='right center'
              popupMessage='Edit access'
              popupTriggerComponent={
                <Image className={`${save ? 'active' : 'inactive'}`} src={editIcon} onClick={() => setSaveAccess(true)} />
              }
            />
            {!noAccess &&
              <>
                <ToolTip position='left center'
                  popupMessage='Reassign to different owner'
                  popupTriggerComponent={
                    <Image className={`${assign ? 'active' : 'inactive'}`} src={assignIcon} onClick={() => setAssignAccess(!assign)} />
                  }
                />
                <ToolTip position='right center'
                  popupMessage='Bulk Upload'
                  popupTriggerComponent={
                    <Image className={`${upload ? 'active' : 'inactive'}`} src={uploadIcon} onClick={() => setUploadAccess(!upload)} />
                  }
                />
              </>
            }
          </div>
          <div className={`header-name`}>Constraints</div>
          <div className={`static-button-container constraint`}>
            <ToolTip position='bottom center'
              popupMessage={'Docs accessible only by its creator'}
              popupTriggerComponent={
                <Image className={`${accessLevel === 'creator' ? 'active' : 'inactive'}`} src={ownerIcon}
                  onClick={() => props.addStatusConstraint({ type: 'creator' } as IConstraint)}
                />
              }
            />
            <ToolTip position='bottom center'
              popupMessage={'Docs accessible only by its owners'}
              popupTriggerComponent={
                <Image className={`${accessLevel === 'owner' ? 'active' : 'inactive'} eye`} src={interactionIcon}
                  onClick={() => props.addStatusConstraint({ type: 'owner' } as IConstraint)} />
              }
            />
            <ToolTip position='bottom center'
              popupMessage={'Docs accessible by everyone'}
              popupTriggerComponent={
                <Image className={`${accessLevel === 'everyone' ? 'active' : 'inactive'}`} src={everyoneIcon} onClick={() => props.addStatusConstraint({ type: 'public' } as IConstraint)} />
              }
            />
          </div>
          <Dropdown
            popperContainer={DropdownPopperOption}
            selectOnBlur={false}
            options={getConstraintListOptions()}
            text='Field Constraint'
            selection
            className={`option-selection`}
            value=''
            onChange={(e, target: any) => addConstraint(target.value)}
          />
          <div className={`custom-constraint`} >
            {
              props.constraintList.map((constraint: IConstraint) => {
                const selected = fieldCompareConstraint.filter((e: IConstraint) => e.style && constraint.style && e.style.constraintId === constraint.style.constraintId);
                if (selected.length > 0) {
                  return <div
                    key={uuid()}
                    className={`created-constraint ${selected.length > 0 ? 'selected' : ''}`}
                    style={{ backgroundColor: constraint.style ? constraint.style.constraintColor : '#000' }}
                    onClick={() => props.addStatusConstraint(constraint)}
                  >
                    <div className='label'>{constraint.style ? constraint.style.constraintName : ''} {`${operatorSymbol[constraint.operator] || ''}`}</div>
                    {selected.length > 0 &&
                      <Image src={icons.white.closePlain} />
                    }
                  </div>
                }
                return undefined;
              })
            }
          </div>
          {!noAccess &&
            <div className={`dynamic-action-container`}>
              <Dropdown
                popperContainer={DropdownPopperOption}
                selectOnBlur={false}
                options={getOptions()}
                text='Actions'
                selection
                className={`option-selection ${view ? 'disabled' : ''}`}
                value=''
                onChange={(e, target: any) => {
                  if (props.status && props.status.actions) {
                    props.status.actions.forEach((action: IFormAction, index: number) => {
                      if (target.value === action.id) {
                        setNoAccess(false);
                        let newSelectedAction = [...selectedActions];
                        newSelectedAction.push(action);
                        setSelectedActions(newSelectedAction);
                      }
                    })
                  }
                }}
              />
              <div className={'selected-dynamic-action-container'}>
                {selectedActions.map((action: IFormAction) => {
                  const name = !action.name && action.type === 'save' ? 'SAVE' : action.name;
                  return <div
                    key={`selected-action-detail-${action.id}`}
                    className={`selected-dynamic-action ${action.type} ${action.config && action.config.direction === 'backward' ? 'backward' : 'forward'}`}>
                    <ToolTip position='right center'
                      popupMessage={action.name}
                      popupTriggerComponent={
                        <label>{name}</label>
                      }
                    />
                    <i className="close icon" onClick={() => deleteSelectedAction(action)}></i>
                  </div>
                })
                }
              </div>
            </div>
          }
        </>
      }
    </div>
  </>
}

export default RoleActionModal;
import { IApiPermissionObject } from '../../permission/interface/api-permission';
import { IBusinessRules } from '../../permission/interface/rules';

interface IUpdateConfigUtils {
  deletedExistingField: string[];
  permissionList: IApiPermissionObject[];
  formId: string;
  companyId: string;
  dispatch: any;
}

export class CheckFieldPermission {

  static isUsedInBizRulesConfig = (businessRules: IBusinessRules[], deletedExistingField: string[]) => {
    let fieldUsed = [] as string[];
    if (businessRules.length > 0) {
      businessRules.forEach(rules => {
        if (rules.fields && rules.fields.sections) {
          rules.fields.sections.forEach(section => {
            if (section.fields) {
              section.fields.forEach(fields => {
                const { onFalse, onTrue, when } = { ...fields.rules };
                const rules: any = { onFalse, onTrue };
                const isUsed = when && when.conditions && when.conditions.filter(e => {
                  const keyPath = e.keyPath && e.keyPath.startsWith('fields.') ? e.keyPath.split('fields.')[1] : e.keyPath;
                  return deletedExistingField.indexOf(keyPath) > -1;
                }).length > 0;
                const isUsedInRule = Object.keys(rules).filter(key => {
                  const usedInValue = rules[key]
                    && rules[key]['setValue']
                    && rules[key]['setValue']['source']
                    && rules[key]['setValue']['source']['keyPath']
                    && deletedExistingField.map(e => `fields.${e}`).includes(rules[key]['setValue']['source']['keyPath']);
                  const usedInValueAssemble = rules[key]
                    && rules[key]['setValue']
                    && rules[key]['setValue']['compose']
                    && rules[key]['setValue']['compose']['keyPaths']
                    && deletedExistingField.filter(e => rules[key]['setValue']['compose']['keyPaths'].includes(`fields.${e}`)).length > 0;
                  return usedInValueAssemble || usedInValue;
                }).length > 0;
                if ((isUsed || isUsedInRule) && fields.id) {
                  const exists = fieldUsed.includes(fields.id);
                  if (!exists) {
                    fieldUsed.push(fields.id);
                  }
                }
              })
            }
          })
        }
      })
    }
    return fieldUsed;
  }

  static isConsolidateTable = (businessRules: IBusinessRules[], tableId: string) => {
    let fieldUsed = false;
    if (businessRules.length > 0) {
      businessRules.forEach(rules => {
        if (rules.fields && rules.fields.sections) {
          rules.fields.sections.forEach(section => {
            if (section.fields) {
              const consolidateTable = section.fields.filter(e => {
                return e.id === tableId && e.consolidateTable != null && e.consolidateTable.columns.length > 0;
              });
              if (consolidateTable.length > 0) {
                fieldUsed = true;
              }
            }
          })
        }
      })
    }
    return fieldUsed;
  };
}
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_PDF_TEMPLATE_LIST = 'app/admin/bpconfiguration/pdf/GET_PDF_TEMPLATE_LIST';
export const GET_PDF_TEMPLATE_LIST_RETURN = 'app/admin/bp/configuration/pdf/GET_PDF_TEMPLATE_LIST_RETURN';
export const UPDATE_LOCAL_PDF_TEMPLATE_LIST = 'app/admin/bp/configuration/pdf/UPDATE_LOCAL_PDF_TEMPLATE_LIST';
export const SAVE_DRAFT_PDF_TEMPLATE_CONFIG = 'app/admin/bp/configuration/pdf/SAVE_DRAFT_PDF_TEMPLATE_CONFIG';
export const SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN = 'app/admin/bp/configuration/pdf/SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN';
export const SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR = 'app/admin/bp/configuration/pdf/SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR';
export const PUBLISH_PDF_TEMPLATE_CONFIG = 'app/admin/bp/configuration/pdf/PUBLISH_PDF_TEMPLATE_CONFIG';
export const PUBLISH_PDF_TEMPLATE_CONFIG_RETURN = 'app/admin/bp/configuration/pdf/PUBLISH_PDF_TEMPLATE_CONFIG_RETURN';
export const CLEAR_PDF_TEMPLATE_CONFIG_SUCCESS = 'app/admin/bp/configuration/pdf/CLEAR_PDF_TEMPLATE_CONFIG_SUCCESS';
export const CLEAR_PDF_TEMPLATE_CONFIG_ERROR = 'app/admin/bp/configuration/pdf/CLEAR_PDF_TEMPLATE_CONFIG_ERROR';

export const pdfTempConfigActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_PDF_TEMPLATE_CONFIG_SUCCESS,
    type: 'success',
    reducerContainer: 'configurationPDFTemplate',
    reducerItem: 'savePDFTempConfigMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_PDF_TEMPLATE_CONFIG_ERROR,
    type: 'negative',
    reducerContainer: 'configurationPDFTemplate',
    reducerItem: 'savePDFTempErrorMessage',
    hasCloseIcon: true
  }
]
import React from 'react';
import { IOption } from '../../../interface/option';
import EnumOperatorOption from './enum';
import MultipleEnumOperatorOption from './multiple-enum';
import StringOperatorOption from './string';
import NumberOperatorOption from './number';
import TimeOperatorOption from './time';
import UploadOperatorOption from './upload';
import DateOperatorOption from './date';
import BoolOperatorOption from './bool';
import IDOperatorOption from './id';
import DateTimeOperatorOption from './date-time';
import FXOperatorOption from './fx';
import RangeOperatorOption from './range';

const optionType = [
  new EnumOperatorOption(),
  new MultipleEnumOperatorOption(),
  new StringOperatorOption(),
  new NumberOperatorOption(),
  new DateOperatorOption(),
  new TimeOperatorOption(),
  new UploadOperatorOption(),
  new BoolOperatorOption(),
  new IDOperatorOption(),
  new DateTimeOperatorOption(),
  new FXOperatorOption(),
  new RangeOperatorOption()
]

interface IOperatiorOption {
  name: string;
  option: IOption[];
}

export class OperatorOption {

  registry: { [name: string]: IOperatiorOption } = {};

  constructor() {
    const types = optionType;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getOption(name: string): IOption[] {
    return (this.registry[name])
      ? this.registry[name].option
      : [];
  }

  static getOption = (type: string) => {
    const adminModule = new OperatorOption();
    return adminModule.getOption(type);
  }

}
import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import EmailArrayType from '../type/string/email-array';
import EmailType from '../type/string/email';

const Email: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <EmailArrayType {...props} />;
  } else {
    return <EmailType {...props} />
  }
}

export default Email;
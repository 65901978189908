import { IAutomationLogStatus } from './interface/automationLogStatus';

export const GET_AUTOMATION_LOGS_LIST = 'app/app/admin-module/business-process/automation/automation-logs/GET_AUTOMATION_LOGS_LIST';
export const GET_AUTOMATION_LOGS_LIST_ERROR = 'app/app/admin-module/business-process/automation/automation-logs/GET_AUTOMATION_LOGS_LIST_ERROR';
export const GET_AUTOMATION_LOGS_LIST_RETURN = 'app/app/admin-module/business-process/automation/automation-logs/GET_AUTOMATION_LOGS_LIST_RETURN'

export const automationLogStatus: IAutomationLogStatus[] = [
  { class: 'automation-status-success', value: 'success', label: 'SUCCESS', description: 'this rule completed without errors and performed some actions successfully.' },
  { class: 'automation-status-no-action', value: 'no_action', label: 'NO ACTION PERFORMED', description: 'this rule executed successfully, however no actions were performed due to a condition stopping the rule execution.' },
  { class: 'automation-status-loop', value: 'loop', label: 'LOOP', description: 'this rule execution loop detected. This usually means rules are calling themselves too many times, please reload your page.' },
  { class: 'automation-status-error', value: 'error', label: 'ERROR', description: 'some actions in this rule produced errors, please check your rule configuration.' },
  { class: 'automation-status-failure', value: 'failure', label: 'FAILURE', description: 'a system error occured, please contact support.' },
];

export const automationLogsLimit = 25;
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';

export interface IMultiselectDropdownOptions {
  label: string;
  value: string;
}

interface IMultiselectDropdownProps {
  defaultText?: string;
  value?: string[];
  option?: IMultiselectDropdownOptions[]
  open?: boolean;
  setOpen?(value: boolean): void;
  onChange?(value: string[], triggerFilter?: boolean): void;
  onBlur?(event: any): void;
  isSearchable?: boolean;
  onSearchChange?(event: any): void;
  customDropdownIcon?: JSX.Element;
  isClearable?: boolean;
  name: string;
  isExpand: boolean;
}

const MultiselectDropdown: React.FC<IMultiselectDropdownProps> = (props) => {
  const parentRef = useRef(document.createElement('div'))
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [collapse, setCollapse] = useState(props.isExpand);
  const [filter, setFilter] = useState('');
  const [hasTriggeredSelection, setHasTriggeredSelection] = useState(false);

  const manageSelection = (value: string) => {
    const selectedOptions = props.value ? [...props.value] : [...selected];
    if (selectedOptions.indexOf(value) > -1) {
      const index = selectedOptions.findIndex(e => e === value);
      selectedOptions.splice(index, 1);
    } else {
      selectedOptions.push(value);
    }
    setSelected(selectedOptions);
    setHasTriggeredSelection(true);
    props.onChange ? props.onChange(selectedOptions, true) : setSelected(selectedOptions);
  }

  const getSelectedValueLabel = (values: string[], options: IMultiselectDropdownOptions[]): string => {
    return values.map((optionId: string) => {
      const option = options.find((e: IMultiselectDropdownOptions) => e.value === optionId);
      if (option) {
        return ` ${option.label}`
      }
      return undefined;
    }).filter(e => e).join(',')
  }

  const clearSelection = () => {
    props.onChange ? props.onChange([], true) : setSelected([]);
  }

  const triggerPopUp = (value: boolean) => {
    props.setOpen ? props.setOpen(value) : setOpen(value);
  }

  const getRemainingOptions = (): IMultiselectDropdownOptions[] => {
    const remOption: IMultiselectDropdownOptions[] = [];

    props.option && props.option.forEach((e: IMultiselectDropdownOptions) => {
      const optionId = e.value || '';
      if (selected && selected.indexOf(optionId) === -1) {
        remOption.push(e);
      }
    })
    return remOption;
  }

  const selectedValue = props.value ? [...props.value] : [...selected];
  const optionList = props.option ? [...props.option] : []


  const handleClickEvent = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    const mainEl = document.getElementById(props.name);
    if (mainEl && !mainEl.contains(target) && hasTriggeredSelection) {
      setOpen(false);
      if (props.onChange) { props.onChange(selected, false) }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEvent);
    document.addEventListener('touchstart', handleClickEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickEvent);
      document.removeEventListener('touchstart', handleClickEvent);
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (hasTriggeredSelection) {
      setCollapse(selected.length > 0)
    }
  }, [selected, hasTriggeredSelection]);

  return <>
    <div className={`multiselect-dropdown`} ref={parentRef}>
      {props.isClearable && <i aria-hidden="true"
        className={`customize-dropdown-icon ${selectedValue.length > 0 ? 'remove' : 'dropdown'} icon`}
        onClick={() => { if (selectedValue.length > 0) clearSelection(); }}
      ></i>}
      <Dropdown
        text={`${selectedValue.length > 0 ? getSelectedValueLabel(selectedValue, optionList) : props.defaultText || ''}`}
        icon={props.customDropdownIcon ? props.customDropdownIcon : undefined}
        floating
        labeled
        button
        className='icon'
        closeOnChange={false}
        onBlur={(e) => { if (props.onBlur) props.onBlur(e); console.log('pass') }}
        onClick={() => triggerPopUp(!open)}
        onFocus={() => triggerPopUp(true)}
      >
        <Dropdown.Menu id={props.name} className={`multiselect-dropdown-popup ${(props.open || open) ? 'visible' : ''}`}
          style={{ width: parentRef.current.clientWidth, }}>
          {selectedValue.length > 0 &&
            <div className={`selected-option-container`}>
              <div className={`selected-option-title`} onClick={() => setCollapse(!collapse)}>Selected Options <i className={`angle ${collapse ? 'down' : 'up'} icon`}></i></div>
              {collapse && <>
                <span className={`clear-selected`} onClick={() => {
                  setHasTriggeredSelection(true);
                  props.onChange ? props.onChange([], true) : setSelected([]);
                }}>Clear All</span>
                <div className={`option-list`}>
                  {
                    selectedValue.map((optionId: string) => {
                      const option = optionList.find((f: IMultiselectDropdownOptions) => f.value === optionId);
                      if (option) {
                        return <div key={uuid()} className="ui fitted checkbox" onClick={() => manageSelection(optionId)}>
                          <input className="hidden" type="checkbox" onChange={() => { }} checked={true} />
                          <label title={option.label}>{option.label}</label>
                        </div>
                      }
                      return null;
                    })
                  }
                </div>
              </>
              }
            </div>
          }
          {(props.isSearchable || props.onSearchChange) &&
            <input className={`search-filter`}
              placeholder={`Search text`}
              onChange={(e) => { if (props.onSearchChange) { props.onSearchChange(e) } else { setFilter(e.target.value) } }}
            />
          }
          <div className={`option-title`}>Options</div>
          <Dropdown.Menu scrolling className={`option-list selection`}>
            {getRemainingOptions().map((e: IMultiselectDropdownOptions) => {
              const optionId = e.value || '';
              if ((selectedValue && selectedValue.indexOf(optionId) > -1) || (filter && e.label.toLowerCase().indexOf(filter.toLowerCase()) === -1)) {
                return null;
              }
              return <div key={uuid()} className="ui fitted checkbox" onClick={() => manageSelection(optionId)}>
                <input className="hidden" type="checkbox" onChange={() => { }} checked={false} />
                <label title={e.label}>{e.label}</label>
              </div>
            })}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </>
}

export default MultiselectDropdown;
import Axios from 'axios';
import { getRandomColor } from '../../../../../../../common/utils/get-random-color';
import { IUserInfo } from '../../../../report/interface/form-document';

interface IUtils {
  company: string;
  documentId: string;
}

export const fetchUserMentionList = async (query: string, utils: IUtils) => {
  const userFetchURL = `${utils.company}/documents/users?documentId=${utils.documentId}&search=${query}&limit=${50}`;
  return new Promise(async (resolve, reject) => {
    return await Axios.get(userFetchURL).then((response: { data: { data: IUserInfo[] } }) => {
      let results: { id: string, text: string, icon: string, value: string }[] = [];
      Array.from(response.data.data).forEach((user: IUserInfo) => {
        const avatarColor = getRandomColor();
        const firstName = user.firstName ? user.firstName : '';
        const lastName = user.lastName ? user.lastName : '';
        const firstLetter = firstName ? firstName.substr(0, 1).toUpperCase() : '';
        const lastLetter = lastName ? lastName.substr(0, 1).toUpperCase() : '';
        let icon = '<div id="default-avatar" style="background-color: ' + avatarColor + '">' + firstLetter + lastLetter + '</div>'
        if (user.avatar) {
          icon = '<img id="avatar" src="' + user.avatar + '" alt="" />'
        }
        results.push({
          id: user.id || '',
          text: (firstName || '') + ' ' + (lastName || ''),
          icon: icon,
          value: user.id + '|' + (firstName || '') + ' ' + (lastName || '')
        });
      })
      results.sort((a: any, b: any) => {
        let x = a.text.toLowerCase();
        let y = b.text.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      resolve(results);
    });
  });
}

import React, { useState } from 'react';
import { AcceptedProps } from '../../../object/interface/accepted-props';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { StringProps } from '../../../service/type/string';
import { FieldProperties } from '../../../service/field-properties.service';
import { TValidationType } from '../../../service/field-properties.handler';

const SingleLine: React.FC<AcceptedProps> = (props) => {
  const name = props.getFieldId();

  const elementProperties = new FieldProperties(name, props.forwardedRef, new StringProps());

  if (props.isClearValue) {
    elementProperties.setCurrent({ value: '' });
  }

  const [refresher, setRefresher] = useState(0);

  const convertStringCase = (value: string) => {
    const { stringCase } = getStringProperties();
    if (value && typeof value === 'string' && stringCase) {
      if (stringCase === 'lowercase') {
        value = (value).toLowerCase();
      }
      if (stringCase === 'uppercase') {
        value = (value).toUpperCase();
      }
    }
    return value;
  }

  const getStringProperties = () => {
    const config: any = { ...props.inputConfig.config };
    let minLength = 0, maxLength = 0, stringCase = '', alignment = 'left';
    if (config) {
      if (config.case) {
        stringCase = config.case;
      }
      if (config.minLength) {
        minLength = config.minLength;
      }
      if (config.maxLength) {
        maxLength = config.maxLength;
      }
      if (config.alignment) {
        alignment = config.alignment;
      }
    }
    return { minLength, maxLength, stringCase, alignment };
  }

  const validateInput = (schema: any) => {
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(refresher + 1);

    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ value: defaultValueString })
      setRefresher(refresher + 1);
    }
  }

  const setRef = (curRef: any) => {
    if (curRef) {
      curRef.element.style.setProperty('text-align', getStringProperties().alignment, 'important');
      curRef.element.fieldType = 'string';
      curRef.value = elementProperties.current.value || '';
      elementProperties.setCurrent({ value: convertStringCase(elementProperties.current.value), fieldType: 'string' });
      return curRef;
    }
  }

  const triggerOnBlur = (event: any) => {
    elementProperties.setCurrent({ value: convertStringCase(event.target.value) });
    validateInput({
      ...props.validationSchema,
      isRequired: props.accessType === IFieldAccessType.Required
    })
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, event.target.value)
      }
    }
  }

  const triggerOnChange = (event: any) => {
    elementProperties.setCurrent({ value: convertStringCase(event.value) });
    if (props.filterElement) {
      if (props.triggerFilter) props.triggerFilter('');
    }
  }

  const triggerOnCreate = () => {
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput({ ...props.validationSchema, minLength: 1, isRequired: true });
    }
  }

  return <TextBoxComponent
    id={name}
    floatLabelType="Never"
    name={name}
    className={`e-info ${getStringProperties().stringCase}`}
    readOnly={props.accessType === IFieldAccessType.Readonly}
    disabled={props.accessType === IFieldAccessType.Readonly}
    placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
    onBlur={triggerOnBlur}
    onChange={triggerOnChange}
    immediateRender
    showClearButton
    title={elementProperties.hasError() ? elementProperties.getError()[0].message : props.hint}
    ref={setRef}
    created={triggerOnCreate}
  />
}

export default SingleLine;
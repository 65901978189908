import {
  GET_COMPANIES_LIST,
  GET_COMPANIES_LIST_ERROR,
  GET_COMPANIES_LIST_RETURN,
  UPLOAD_COMPANY_LOGO,
  UPLOAD_COMPANY_LOGO_RETURN,
  UPLOAD_COMPANY_LOGO_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_RETURN,
  CREATE_COMPANY_ERROR,
  GET_COMPANY_LOGO,
  GET_COMPANY_LOGO_RETURN,
  GET_COMPANY_LOGO_ERROR,
  CLEAR_COMPANY_RESPONSE_MESSAGE,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_RETURN,
  GET_COMPANY_DETAILS_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_CONFIRM,
  DELETE_COMPANY_CONFIRM_RETURN,
  DELETE_COMPANY_CONFIRM_ERROR,
  DELETE_COMPANY_CANCEL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_RETURN,
  UPDATE_COMPANY_ERROR,
  CLEAR_CREATE_COMPANY_ERROR,
  CLEAR_CREATE_COMPANY_SUCCESS,
  CLEAR_DELETE_COMPANY_ERROR,
  CLEAR_DELETE_COMPANY_SUCCESS,
  CLEAR_UPDATE_COMPANY_ERROR,
  CLEAR_UPDATE_COMPANY_SUCCESS,
  CLEAR_COMPANY_LOGO_ERROR,
  CLEAR_COMPANY_ACTION_MESSAGE
} from './constant';
import { ICompany } from './interface/response/company';
import companyMessage from './message';

export interface ICompaniesReducer {
  companiesLoading: boolean;
  companies: ICompany[];
  companiesError: string;
  companyLogoId: '';
  uploadLoading: boolean;
  createCompanyLoading: boolean;
  createCompanyMessage: string;
  companyLogo: string;
  companyLogoError: string;
  createCompanyErrorMessage: string;
  companyDetailsLoading: boolean;
  companyDetails: ICompany;
  companyDetailsError: string;
  forDeleteCompanyId: string;
  forDeleteCompanyName: string;
  isOpenDeleteConfirmationModal: boolean;
  confirmDeletetionLoading: boolean;
  confirmDeletionSuccessMessage: string;
  confirmDeletionErrorMessage: string;
  updateCompanyLoading: boolean;
  updateCompanyMessage: string;
  updateCompanyError: string;
  companiesTotalCount: number;
  companiesGridMessage: string;
}

const initialState: ICompaniesReducer = {
  companiesLoading: false,
  companiesError: '',
  companies: [] as ICompany[],
  companyLogoId: '',
  uploadLoading: false,
  companyLogoError: '',
  createCompanyLoading: false,
  createCompanyMessage: '',
  companyLogo: '',
  createCompanyErrorMessage: '',
  companyDetailsLoading: false,
  companyDetails: {} as ICompany,
  companyDetailsError: '',
  forDeleteCompanyId: '',
  forDeleteCompanyName: '',
  isOpenDeleteConfirmationModal: false,
  confirmDeletetionLoading: false,
  confirmDeletionSuccessMessage: '',
  confirmDeletionErrorMessage: '',
  updateCompanyError: '',
  updateCompanyMessage: '',
  updateCompanyLoading: false,
  companiesTotalCount: 0,
  companiesGridMessage: ''
}

const companiesReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  let tempCompanyList: any = [];

  switch (action.type) {
    case GET_COMPANIES_LIST:
      newState.companies = [];
      newState.companiesLoading = true;
      newState.companiesGridMessage = 'Loading companies...';
      return newState;

    case GET_COMPANIES_LIST_RETURN:
      newState.companies = action.data;
      newState.companiesLoading = false;
      newState.companiesTotalCount = action.companiesTotalCount;
      if (action.data.length === 0) {
        newState.companiesGridMessage = 'No companies found.';
      } else {
        newState.companiesGridMessage = '';
      }
      return newState;

    case GET_COMPANIES_LIST_ERROR:
      newState.companiesError = action.message;
      newState.companiesLoading = false;
      return newState;

    case UPLOAD_COMPANY_LOGO:
      newState.uploadLoading = true;
      newState.companyLogoError = '';
      return newState;

    case UPLOAD_COMPANY_LOGO_RETURN:
      newState.uploadLoading = false;
      newState.companyLogoId = action.file[0].filename;
      return newState;

    case UPLOAD_COMPANY_LOGO_ERROR:
      newState.uploadLoading = false;
      newState.companyLogoError = action.message;
      return newState;

    case CREATE_COMPANY:
      newState.createCompanyLoading = true;
      newState.createCompanyMessage = '';
      return newState;

    case CREATE_COMPANY_RETURN:
      tempCompanyList = [...state.companies];
      tempCompanyList.push(action.data);
      newState.companies = tempCompanyList;
      newState.createCompanyLoading = false;
      newState.createCompanyMessage = companyMessage.createCompanySuccess;
      newState.companiesTotalCount = state.companiesTotalCount += 1;
      return newState;

    case CREATE_COMPANY_ERROR:
      newState.createCompanyErrorMessage = action.message;
      newState.createCompanyLoading = false;
      newState.companiesLoading = false;
      return newState;

    case GET_COMPANY_LOGO:
      newState.companyLogo = '';
      return newState;

    case GET_COMPANY_LOGO_RETURN:
      newState.companyLogo = action.file;
      newState.companyLogoError = '';
      return newState;

    case GET_COMPANY_LOGO_ERROR:
      newState.companyLogoError = action.message;
      return newState;

    case CLEAR_COMPANY_RESPONSE_MESSAGE:
      newState.createCompanyMessage = '';
      newState.confirmDeletionSuccessMessage = '';
      newState.updateCompanyMessage = '';
      return newState;

    case GET_COMPANY_DETAILS:
      newState.companyDetailsLoading = true;
      newState.companyDetailsError = '';
      return newState;

    case GET_COMPANY_DETAILS_RETURN:
      newState.companyDetailsLoading = false;
      newState.companyDetailsError = '';
      newState.companyDetails = action.data;
      return newState;

    case GET_COMPANY_DETAILS_ERROR:
      newState.companyDetailsError = action.message;
      newState.companyDetailsLoading = false;
      return newState;

    case DELETE_COMPANY:
      newState.forDeleteCompanyId = action.companyId;
      newState.forDeleteCompanyName = action.companyName;
      newState.isOpenDeleteConfirmationModal = true;
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_COMPANY_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_COMPANY_CONFIRM_RETURN:
      tempCompanyList = [...state.companies];
      newState.companies = tempCompanyList.filter((e: ICompany) => e.id !== action.companyId)
      newState.confirmDeletetionLoading = false;
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletionSuccessMessage = companyMessage.deleteCompanySuccess;
      newState.companiesTotalCount = state.companiesTotalCount -= 1;
      return newState;

    case DELETE_COMPANY_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_COMPANY_CANCEL:
      newState.forDeleteCompanyId = '';
      newState.forDeleteCompanyName = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case UPDATE_COMPANY:
      newState.updateCompanyLoading = true;
      newState.updateCompanyMessage = '';
      return newState;

    case UPDATE_COMPANY_RETURN:
      tempCompanyList = [...state.companies];
      newState.companies = tempCompanyList.map((company: ICompany) => {
        if (action.data.id === company.id) {
          return action.data;
        }
        return company
      });
      newState.updateCompanyLoading = false;
      newState.updateCompanyMessage = companyMessage.updateCompanySuccess;
      return newState;

    case UPDATE_COMPANY_ERROR:
      newState.updateCompanyLoading = false;
      newState.updateCompanyError = action.message;
      return newState;

    case CLEAR_CREATE_COMPANY_SUCCESS:
      newState.createCompanyMessage = '';
      return newState;

    case CLEAR_UPDATE_COMPANY_SUCCESS:
      newState.updateCompanyMessage = '';
      return newState;

    case CLEAR_DELETE_COMPANY_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case CLEAR_DELETE_COMPANY_ERROR:
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case CLEAR_UPDATE_COMPANY_ERROR:
      newState.updateCompanyError = '';
      return newState;

    case CLEAR_CREATE_COMPANY_ERROR:
      newState.createCompanyErrorMessage = '';
      return newState;

    case CLEAR_COMPANY_LOGO_ERROR:
      newState.companyLogoError = '';
      return newState;

    case CLEAR_COMPANY_ACTION_MESSAGE:
      newState.companyLogoError = '';
      newState.createCompanyErrorMessage = '';
      newState.updateCompanyError = '';
      newState.confirmDeletionErrorMessage = '';
      newState.confirmDeletionSuccessMessage = '';
      newState.updateCompanyMessage = '';
      newState.createCompanyMessage = '';
      return newState;

    default:
      return state;
  }
}

export default companiesReducer;
import { useEffect, useState } from 'react';

const useDeviceDetect = () => {
  const portrait = window.matchMedia && window.matchMedia("(orientation: portrait)").matches;
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [portraitMode, setPortraitMode] = useState(portrait);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const handleWindowOrientationChange = () => {
    setPortraitMode(portrait)
  }

  useEffect(() => {
    setPortraitMode(portrait);
  }, [portrait])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('orientationchange', handleWindowOrientationChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('orientationchange', handleWindowOrientationChange);
    }
    // eslint-disable-next-line
  }, []);

  return {
    width: width,
    height: height,
    isPortrait: portraitMode,
    isTablet: width >= 600 && width <= 1024,
    isMobile: width < 600
  };
}

export default useDeviceDetect;
import { IField, IFieldAccessType } from '../../../admin-module/module/users/interface/field';
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { IConfigName } from '../../../../common/field/type/interface/field-type-object';

export const GET_USER_MANAGER_DETAILS = 'app/documentModule/userAccount/GET_USER_MANAGER_DETAILS';
export const GET_USER_MANAGER_DETAILS_RETURN = 'app/documentModule/userAccount/GET_USER_MANAGER_DETAILS_RETURN';
export const GET_USER_MANAGER_DETAILS_ERROR = 'app/documentModule/userAccount/GET_USER_MANAGER_DETAILS_ERROR';
export const UPDATE_USER_ACCOUNT = 'app/documentModule/userAccount/UPDATE_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT_RETURN = 'app/documentModule/userAccount/UPDATE_USER_ACCOUNT_RETURN';
export const UPDATE_USER_ACCOUNT_ERROR = 'app/documentModule/userAccount/UPDATE_USER_ACCOUNT_ERROR';
export const CLEAR_UPDATE_USER_ACCOUNT_SUCCESS = 'app/documentModule/userAccountCLEAR_UPDATE_USER_ACCOUNT_SUCCESS';
export const CLEAR_UPDATE_USER_ACCOUNT_ERROR = 'app/documentModule/userAccountCLEAR_UPDATE_USER_ACCOUNT_ERROR';

export const userAccountActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_USER_ACCOUNT_SUCCESS,
    type: 'success',
    reducerContainer: 'userAccountReducer',
    reducerItem: 'userAccountMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_UPDATE_USER_ACCOUNT_ERROR,
    type: 'negative',
    reducerContainer: 'userAccountReducer',
    reducerItem: 'userAccountError',
    hasCloseIcon: true
  }
]

export const usersManagerField: IField = {
  id: 'usersManager',
  label: 'Manager',
  inputConfig: {
    type: 'enum',
    config: {
      data: [],
      multiselect: false,
      displayType: 'dropdown',
      dataType: 'static'
    }
  },
  accessType: IFieldAccessType.Readonly,
  configName: IConfigName.Dropdown
}

export const userSignatureField: IField = {
  id: 'signatureField',
  label: 'Signature',
  inputConfig: {
    type: 'file',
    config: {}
  },
  configName: IConfigName.Signature,
  accessType: IFieldAccessType.ReadWrite,
  defaultValue: null,
  validationSchema: {
    anyOf: [
      {
        type: 'object',
        required: ["id", "name", "type", "size", "url"],
        properties: {
          id: { type: "string" },
          name: { type: "string" },
          type: { type: "string" },
          size: { type: "number" },
          url: { type: "string", format: "url" }
        }
      },
      { type: "null" }
    ]
  }
}

export const uploadProgressStyles: { [key: string]: any } = {
  progess: {
    maxWidth: "200px",
  },
  progressTheme: {
    success: {
      color: '#86d94c'
    },
    active: {
      color: '#86d94c'
    },
    default: {
      color: '#86d94c'
    }
  }
}

export const userLabelList = ['First Name', 'Last Name', 'Email Address', 'Phone'];
import React, { useState, useEffect } from 'react';
import { Portal } from 'react-overlays';
import { usePopper } from 'react-popper';

const DropdownPopperOption: React.FC<any> = ({ children, style, parentRef, open }) => {
  const [popperElement, setPopperElement] = useState<any>(null);
  const [isPLacementTop, setIsPLacementTop] = useState<boolean>(false);

  const setInitialOpen = () => {
    const isPopupOnTop = popperElement
      && popperElement.getBoundingClientRect()
      && (popperElement.getBoundingClientRect().bottom) > (window.innerHeight || document.documentElement.clientHeight)
    setIsPLacementTop(isPopupOnTop);
  }
  const placement = isPLacementTop ? 'top-start' : 'bottom-start'
  const { styles, attributes, } = usePopper(parentRef.current, popperElement, {
    placement: placement,
  });

  useEffect(() => {
    if (open) {
      setInitialOpen()
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (popperElement && popperElement.firstChild) {
      if (popperElement.firstChild.children.length > 0) {
        for (let i = 0; i < popperElement.firstChild.children.length; i++) {
          if (popperElement.firstChild.children[i]) {
            const { width } = popperElement.firstChild.children[i].getBoundingClientRect();
            if (width > style.width) {
              popperElement.firstChild.children[i].style.width = `${style.width}px`
              popperElement.firstChild.children[i].style.overflow = `hidden`;
              popperElement.firstChild.children[i].style.textOverflow = `ellipsis`;
              popperElement.firstChild.children[i].title = popperElement.firstChild.children[i].textContent;
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [style])

  const el = document.getElementById('dropdown-popper');
  return <Portal container={el} >
    <div ref={setPopperElement} className='ui dropdown dropdown-popper-option'
      style={{ ...styles.popper, width: style.width }} {...attributes.popper}>
      {children}
    </div>
  </Portal>;
}

export default DropdownPopperOption;
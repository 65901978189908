import React, { useRef, useState, useEffect } from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import { CheckBoxSelection, Inject, MultiSelectComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { IField, IFieldAccessType, IOptionValue } from '../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../service/field-properties.service';
import { EnumMultiselectProps } from '../../service/type/enum-multiselect';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../reducers';
import { IForm } from '../../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../../../component/admin-module/module/data-tables/reducer';
import { IConfigName } from '../../../type/interface/field-type-object';
import { getOption, triggerFilterOptions } from '../../object/enum-type/utils';
import { setTableEnumOptions } from '../../object/table-type/utils/set-table-enum-options';
import { setConstraintInitialValue } from '../../object/type/enum/util/set-constraint-initial-value';
import { TValidationType } from '../../service/field-properties.handler';
import { formatEnumValidationSchema } from './utils/format-validation-schema';
import { triggerAutomationUpdateField } from './utils/trigger-automation-update-field';

interface IDropdownOption {
  key: string;
  text: string;
  value: string;
  deleted: boolean | undefined;
}

let searchFilterTimeout = setTimeout(() => { }, 1000);

const MultipleEnumType: React.FC<AcceptedProps> = (props) => {
  let dropdownObject: MultiSelectComponent;
  let setValueTimeout: any = 0;

  const mountedRef = useRef(true);
  const { company } = useSelector((state: IRootState) => state.auth);
  const { userBusinessProcess, userDataTable } = useSelector((state: IRootState) => state.endUser);

  const [refresher, setRefresher] = useState(0);
  const [triggerSearchFilter, setTriggerSearchFilter] = useState(0);
  // eslint-disable-next-line
  const [hasAccessShorctut, setHasAccessShortCut] = useState(false);
  const [forceReadOnlyElement, setForceReadOnlyElement] = useState(false);

  const name = props.getFieldId();
  const elementProperties = new FieldProperties(name, props.forwardedRef, new EnumMultiselectProps());

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'multiple-enum' })
  }

  const resetSearchOptionData = () => {
    elementProperties.setCurrent({ ...elementProperties.current, optionsFilterSearch: '' });
    clearTimeout(searchFilterTimeout);
  }

  const setFullDetailOfSelectedValue = (value: string[]) => {
    let tempSelectedValue: IOptionValue[] = [];
    let options = getOption(props);
    if (elementProperties.current.filteredOption) {
      options = elementProperties.current.filteredOption;
    }

    options.forEach((e: IOptionValue) => {
      if (value.indexOf(e.id) > -1) {
        tempSelectedValue.push(e);
      }
    });

    elementProperties.setCurrent({ ...elementProperties.current, selectedOptionFilter: tempSelectedValue });
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue = props.defaultValue ? props.defaultValue as string[] : [];
    if (!props.defaultValueElement && defaultValue) {
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValue });
      setRefresher(refresher + 1);
    }
  }

  const removeDeletedItemInOptions = (option: IDropdownOption[], value: any): IDropdownOption[] => {
    return option.filter((e: IDropdownOption) => {
      let isValueAlreadySelected = false;
      if (value instanceof Array) {
        isValueAlreadySelected = (value.indexOf(e.value) > -1);
      } else {
        isValueAlreadySelected = e.value === value;
      }

      if (e.deleted && !isValueAlreadySelected) {
        return false;
      }
      return true;
    })
  }

  const validateSelectedValue = () => {
    let schema = formatEnumValidationSchema(props);
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(refresher + 1);
  }

  const checkIfAllowedToCreate = async () => {
    let hasAccess = false;
    if (props.inputConfig.config.source && props.inputConfig.config.source.formId) {
      const myForm = userBusinessProcess.find((form: IForm) => props.inputConfig.config.source && form.id === props.inputConfig.config.source.formId)
      if (myForm) {
        hasAccess = true;
      }
    }
    if (props.inputConfig.config.source && props.inputConfig.config.source.databaseId) {
      const mydatatable = userDataTable.find((dt: IDataTable) =>
        props.inputConfig.config.source && dt.id === props.inputConfig.config.source.databaseId
        && dt.allowedAccess === 'edit'
      )
      if (mydatatable) {
        hasAccess = true;
      }
    }
    setHasAccessShortCut(hasAccess)
  }

  const getValue = () => {
    return elementProperties.current.value || []
  }

  const triggerSearchOption = async (filter?: boolean, showPopup?: boolean) => {
    if (props.fieldEnumOptionUrl && elementProperties.isSet() && !props.isUser && !props.filterElement) {
      const retOptions = await triggerFilterOptions({
        companyId: company,
        id: name,
        originalId: props.originalId,
        forwardedRef: props.forwardedRef,
        filter: elementProperties.current.optionsFilterSearch || '',
        url: props.fieldEnumOptionUrl,
        selectedValues: elementProperties.current.selectedOptionFilter || [],
        requiresFieldData: props.requiresFieldData,
        currentFieldList: props.fieldCollection || [],
        hasAllOption: (props.inputConfig.config.items.filter((e: IOptionValue) => e.id === 'all')).length > 0,
        fieldEnumOptionUrlAdditionalFieldBody: props.fieldEnumOptionUrlAdditionalFieldBody,
        type: props.inputConfig.type,
        tableId: props.tableId,
        localFilter: props.inputConfig.config.filterEnumOptions,
        enumItemsSource: props.inputConfig.config.dataSource,
        multiple: true
      });
      if (mountedRef.current) {
        elementProperties.setCurrent({ ...elementProperties.current, filteredOption: retOptions })
        triggerAutomationUpdateField({
          value: getValue(),
          removeDeletedItemInOptions,
          getOption,
          automationService: props.automationService,
          fieldCollection: props.fieldCollection,
          currentProps: props,
          elementProperties
        });
        if (props.tableFieldEnumOptionsRef && props.tableId) {
          setTableEnumOptions(props.tableFieldEnumOptionsRef, retOptions, props.tableId, name);
        }
        setRefresher(refresher + 1);
        if (setValueTimeout) clearTimeout(setValueTimeout);
        setValueTimeout = setTimeout(() => {
          if (showPopup && !props.filterElement) dropdownObject.showPopup();
          componentOption(false, filter, showPopup);
        }, 500);
      }
    }
  }

  const triggerOnCreate = () => {
    if (props.isAddRecord) {
      if (props.flags && props.flags.indexOf('constraint') > -1) {
        setConstraintInitialValue({
          getOption,
          currentProps: props,
          setForceReadOnlyElement,
          setRefresher,
          elementProperties,
          refresher
        });
      } else {
        setDefaultValue();
      }
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    checkIfAllowedToCreate();
    componentOption(true);
  }

  const triggerOnFocus = () => {
    triggerSearchOption(false, true);
  }

  const triggerOnOpen = () => {
    if (props.filterElement) {
      return;
    }
    triggerSearchOption();
  }

  const triggerOnClose = () => {
    resetSearchOptionData();
    if (props.accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    triggerRelatedApplication();
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) props.throwValueOutside();
    triggerAutomation(getValue());
  }

  const triggerOnChange = (event: any) => {
    const { value }: any = { ...event };
    const currentValue = getValue();
    resetSearchOptionData();
    elementProperties.setCurrent({ ...elementProperties.current, value });
    localStorage.setItem(`multiselect-${name}`, JSON.stringify(getValue()));
    setRefresher(refresher + 1);
    setFullDetailOfSelectedValue(getValue());
    if (props.triggerFilter) props.triggerFilter('');
    if (currentValue.length > value.length) {
      dropdownObject.dataSource = [...dropdownObject.dataSource as any[]];
    }
  }

  const triggerRelatedApplication = () => {
    if (props.triggerRelatedApplication && props.configName === IConfigName.Referenced) {
      const config: any = props.inputConfig.config;
      const dataType = Object.keys(config.source).includes('formId') ? 'document' : 'database';
      props.triggerRelatedApplication({
        id: elementProperties.current.value,
        source: config.source[Object.keys(config.source).toString()],
        dataType: dataType,
        multiple: config.multiselect,
        fieldId: name
      });
    }
  }

  const triggerAutomation = (value: string[]) => {
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value);
      }
    }
  }

  const triggerOnFiltering = (event: FilteringEventArgs) => {
    const { tempValues, mainData } = { ...dropdownObject } as any;
    if (props.fieldEnumOptionUrl) {
      dropdownObject.dataSource = [];
      dropdownObject.noRecordsTemplate = 'Searching...';
      clearTimeout(searchFilterTimeout);
      searchFilterTimeout = setTimeout(() => {
        elementProperties.setCurrent({
          ...elementProperties.current,
          value: tempValues,
          selectedOptionFilter: tempValues && tempValues.map((e: string) => {
            const value = mainData.find((f: IOptionValue) => f.value === e);
            if (value) {
              return value;
            }
            return null;
          }),
          optionsFilterSearch: event.text
        });
        setTriggerSearchFilter(triggerSearchFilter + 1);
      }, 1000);
    }
  }

  const componentOption = (initial?: boolean, filter?: boolean, showPopup?: boolean): any[] => {
    let option = getOption(props).map((e: IOptionValue): IDropdownOption => {
      return {
        key: e.id,
        text: e.label,
        value: e.id,
        deleted: e.deleted || undefined
      }
    });
    if (elementProperties.current.filteredOption && !initial) {
      option = elementProperties.current.filteredOption.map((e: IOptionValue): IDropdownOption => {
        return {
          key: e.id,
          text: e.label,
          value: e.id,
          deleted: e.deleted || undefined
        }
      });
    }
    if (props.filterElement && props.customEnumItems) {
      option = props.customEnumItems.map((e: IOptionValue): IDropdownOption => {
        return {
          key: e.id,
          text: e.label,
          value: e.id,
          deleted: e.deleted || undefined
        }
      });
    }
    let valuesFromStorage = localStorage.getItem(`multiselect-${name}`);
    let values = getValue();
    let options = removeDeletedItemInOptions(option, values);

    dropdownObject.dataSource = options as any;
    dropdownObject.value = getValue();
    if (!options.length) {
      dropdownObject.noRecordsTemplate = 'No records found.';
    }
    if (options.length && filter && !elementProperties.current.optionsFilterSearch && valuesFromStorage) {
      dropdownObject.value = JSON.parse(valuesFromStorage);
    }
    return options.length ? options : [];
  }

  useEffect(() => {
    if (!props.filterElement) {
      dropdownObject.showClearButton = true;
    }
    localStorage.setItem(`multiselect-${name}`, JSON.stringify(getValue()))
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerSearchFilter > 0) {
      triggerSearchOption(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearchFilter]);

  useEffect(() => {
    if (props.filterElement && props.customEnumItems) {
      componentOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customEnumItems]);

  setFullDetailOfSelectedValue(getValue());

  return <MultiSelectComponent
    id={name}
    floatLabelType="Never"
    name={name}
    readOnly={props.accessType === IFieldAccessType.Readonly || forceReadOnlyElement}
    placeholder={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
    sortOrder='Ascending'
    allowFiltering
    showDropDownIcon
    closePopupOnSelect={false}
    fields={{ text: 'text', value: 'value' }}
    noRecordsTemplate='Loading...'
    created={triggerOnCreate}
    filtering={triggerOnFiltering}
    changeOnBlur={false}
    close={triggerOnClose}
    onChange={triggerOnChange}
    open={triggerOnOpen}
    dataSource={[]}
    mode={props.filterElement ? 'CheckBox' : 'Default'}
    focus={triggerOnFocus}
    ref={ref => { if (ref) dropdownObject = ref }}
  >
    <Inject services={[CheckBoxSelection]} />
  </MultiSelectComponent>
}

export default MultipleEnumType;
import {
  GET_GROUP_LIST,
  GET_GROUP_LIST_RETURN,
  GET_GROUP_LIST_ERROR,
  CREATE_GROUP,
  CREATE_GROUP_RETURN,
  CREATE_GROUP_ERROR,
  GET_USER_OPTION_LIST,
  GET_USER_OPTION_LIST_RETURN,
  GET_USER_OPTION_LIST_ERROR,
  CLEAR_GROUP_MESSAGE,
  DELETE_GROUP,
  DELETE_GROUP_CONFIRM,
  DELETE_GROUP_CONFIRM_RETURN,
  DELETE_GROUP_CONFIRM_ERROR,
  DELETE_GROUP_CANCEL,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_RETURN,
  GET_GROUP_DETAIL_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_RETURN,
  UPDATE_GROUP_ERROR,
  FILTER_GROUPS,
  FILTER_GROUPS_RETURN,
  FILTER_GROUPS_ERROR
} from './constant'
import { IGroupListDetailInput, IFilterGroupsDetailInput } from './interface/input/group-list'
import { IGroupListResponse } from './interface/response/group-list'
import { IGroupDetailCreateInput } from './interface/input/group-create'
import { IGroupCreateResponse } from './interface/response/group-create'
import { IUserOptionListDetailInput } from './interface/input/user-option-list'
import { IUserOptionListResponse } from './interface/response/user-option-list'
import { IGroupDeleteInput } from './interface/input/group-delete'
import { IGroupConfirmDeleteDetailInput } from './interface/input/group-delete-confirm'
import { IGroupDeleteResponse } from './interface/response/group-delete'
import { IGroupDetailInput } from './interface/input/group-details'
import { IGroupDetailResponse } from './interface/response/group-details'
import { IGroupDetailUpdateInput } from './interface/input/group-update'

export const getGroupListAction = (payload: IGroupListDetailInput) => {
  return { type: GET_GROUP_LIST, ...payload }
}

export const getGroupListReturnAction = (payload: IGroupListResponse) => {
  return { type: GET_GROUP_LIST_RETURN, ...payload }
}

export const getGroupListErrorAction = (payload: {}) => {
  return { type: GET_GROUP_LIST_ERROR, ...payload }
}

export const createGroupAction = (payload: IGroupDetailCreateInput) => {
  return { type: CREATE_GROUP, ...payload }
}

export const createGroupReturnAction = (payload: IGroupCreateResponse) => {
  return { type: CREATE_GROUP_RETURN, ...payload }
}

export const createGroupErrorAction = (payload: {}) => {
  return { type: CREATE_GROUP_ERROR, ...payload }
}

export const getUserOptionListAction = (payload: IUserOptionListDetailInput) => {
  return { type: GET_USER_OPTION_LIST, ...payload }
}

export const getUserOptionListReturnAction = (payload: IUserOptionListResponse) => {
  return { type: GET_USER_OPTION_LIST_RETURN, ...payload }
}

export const getUserOptionListErrorAction = (payload: {}) => {
  return { type: GET_USER_OPTION_LIST_ERROR, ...payload }
}

export const clearGroupMessageAction = () => {
  return { type: CLEAR_GROUP_MESSAGE }
}

export const deleteGroupAction = (payload: IGroupDeleteInput) => {
  return { type: DELETE_GROUP, ...payload }
}

export const confirmDeleteGroupAction = (payload: IGroupConfirmDeleteDetailInput) => {
  return { type: DELETE_GROUP_CONFIRM, ...payload }
}

export const confirmDeleteGroupReturnAction = (payload: IGroupDeleteResponse) => {
  return { type: DELETE_GROUP_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteGroupErrorAction = (payload: {}) => {
  return { type: DELETE_GROUP_CONFIRM_ERROR, ...payload }
}

export const cancelDeleteGroupAction = () => {
  return { type: DELETE_GROUP_CANCEL }
}

export const getGroupDetailAction = (payload: IGroupDetailInput) => {
  return { type: GET_GROUP_DETAIL, ...payload }
}

export const getGroupDetailReturnAction = (payload: IGroupDetailResponse) => {
  return { type: GET_GROUP_DETAIL_RETURN, ...payload }
}

export const getGroupDetailErrorAction = (payload: {}) => {
  return { type: GET_GROUP_DETAIL_ERROR, ...payload }
}

export const updateGroupAction = (payload: IGroupDetailUpdateInput) => {
  return { type: UPDATE_GROUP, ...payload }
}

export const updateGroupReturnAction = (payload: any) => {
  return { type: UPDATE_GROUP_RETURN, ...payload }
}

export const updateGroupErrorAction = (payload: any) => {
  return { type: UPDATE_GROUP_ERROR, ...payload }
}

export const filterGroupsAction = (payload: IFilterGroupsDetailInput) => {
  return { type: FILTER_GROUPS, ...payload }
}

export const filterGroupsReturnAction = (payload: IGroupListResponse) => {
  return { type: FILTER_GROUPS_RETURN, ...payload }
}

export const filterGroupsErrorAction = (message: string) => {
  return { type: FILTER_GROUPS_ERROR, message }
}
import { GET_API_CONFIG, GET_API_CONFIG_RETURN, GET_STATUS_LIST, GET_STATUS_LIST_RETURN, PUBLISH_API_CONFIG, PUBLISH_API_CONFIG_RETURN, SAVE_API_CONFIG_ERROR, SAVE_DRAFT_API_CONFIG, SAVE_DRAFT_API_CONFIG_RETURN, UPDATE_LOCAL_API_LIST } from './constant';
import { IApiConfiguration } from './interface/api-configuration';
import { IApiGetDetailInput } from './interface/input/api-get';
import { IApiPublishDetailInput, IApiSaveDetailInput } from './interface/input/api-save';
import { IStatusListGetDetailInput } from './interface/input/status-get';
import { IApiGetResponse } from './interface/response/api-get';
import { IApiSaveResponse } from './interface/response/api-save';
import { IStatusGetResponse } from './interface/response/status-get';

export const getStatusListAction = (payload: IStatusListGetDetailInput) => {
  return {
    type: GET_STATUS_LIST,
    ...payload
  }
}

export const getStatusListReturnAction = (payload: IStatusGetResponse) => {
  return {
    type: GET_STATUS_LIST_RETURN,
    ...payload
  }
}

export const getApiConfigAction = (payload: IApiGetDetailInput) => {
  return {
    type: GET_API_CONFIG,
    ...payload
  }
}

export const getApiConfigReturnAction = (payload: IApiGetResponse) => {
  return {
    type: GET_API_CONFIG_RETURN,
    ...payload
  }
}

export const updateLocalApiListAction = (apiList: IApiConfiguration[]) => {
  return {
    type: UPDATE_LOCAL_API_LIST,
    apiConfigurationList: apiList
  }
}

export const saveDraftApiConfigAction = (payload: IApiSaveDetailInput) => {
  return {
    type: SAVE_DRAFT_API_CONFIG,
    ...payload
  }
}

export const saveDraftApiConfigReturnAction = (payload: IApiSaveResponse) => {
  return {
    type: SAVE_DRAFT_API_CONFIG_RETURN,
    ...payload
  }
}

export const publishApiConfigAction = (payload: IApiPublishDetailInput) => {
  return {
    type: PUBLISH_API_CONFIG,
    ...payload
  }
}
export const publishApiConfigReturnAction = () => {
  return {
    type: PUBLISH_API_CONFIG_RETURN
  }
}

export const saveApiConfigErrorAction = (message: string) => {
  return { type: SAVE_API_CONFIG_ERROR, saveApiConfigErrorMessage: message }
}
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal, Segment, Button } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IAutomationChildComponent, IAutomationComponent } from '../interface/component-list';
import AutomationDetail from './detail';
import AutomationOutline from './outline';
import { IOptionWidget } from '../interface/option-widget';
import { IAutomation } from '../interface/automation';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedAutomation, createAutomationAction, updateAutomationAction } from '../action';
import { IRootState } from '../../../../../../../../../reducers';
import AutomationMainDetail from './detail/automation-detail';
import { AutomationUtils } from './utils';
import { AlertModal } from '../../../../../../../../../common/general/alert-modal';
import { useSetSelectedAutomationComponentState } from './hooks/use-set-selected-automation-component-state';
import message from '../message';
import LoaderComponent from '../../../../../../../../../common/general/loader';
import { useGetAutomationContent } from './hooks/use-get-automation-content';

const AutomationForm: React.FC<any> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match: {
    params: {
      businessRulesId: string,
      formId: string,
      submodule: string,
      thirdLevelRoute: string,
      editType: string,
      fourthLevelRoute: string
    }
  } = useRouteMatch();
  const {
    selectedAutomation,
    getAutomationContentLoading,
    referenceList,
    inputFieldList,
    datatableInputField,
    publishedFormStatusList,
    formFieldList,
    portals,
    triggerCloseAutomationModal,
    createAutomationLoading,
    updateAutomationLoading,
  } = useSelector((state: IRootState) => state.automation);
  const { company } = useSelector((state: IRootState) => state.auth);

  const currentAutomationRef = useRef<IAutomation | undefined>(undefined);
  const refsObject = useRef({});
  const hasComponentChange = useRef(false);
  const hasAutomationChange = useRef(false);

  const [selectedChildComponent, setSelectedChildComponent] = useState<IAutomationChildComponent | undefined>(undefined);
  const [showDetail, setShowDetail] = useState(false);
  const [tempComponentList, setTempComponentList] = useState<any>(undefined);
  const [alertMessage, setAlertMessage] = useState('');
  const [refresher, setRefresher] = useState(0);
  const [selectedComponent, setSelectedComponent] = useState<IAutomationComponent | undefined>(undefined);
  const [componentHasSet, setComponentHasSet] = useState(false);

  useSetSelectedAutomationComponentState({
    businessRulesId: match.params.businessRulesId,
    currentAutomationRef,
    getAutomationContentLoading,
    selectedAutomation,
    setSelectedComponent,
    setComponentHasSet,
    componentHasSet
  });

  useGetAutomationContent({
    businessRulesId: match.params.businessRulesId,
    company,
    currentAutomationRef,
    dispatch,
    formId: match.params.formId,
    setSelectedComponent,
    setComponentHasSet,
    componentHasSet
  })

  const parentRefresher = () => {
    setRefresher(refresher + 1);
  }

  const saveAutomation = () => {
    if (currentAutomationRef.current) {
      if (hasComponentChange.current) {
        selectOutlineComponent();
      }
      if (!isValid(currentAutomationRef.current, true)) {
        return;
      }
      if (match.params.businessRulesId === 'add-record') {
        dispatch(createAutomationAction({
          automation: currentAutomationRef.current,
          company: company,
          formId: match.params.formId
        }));
      } else {
        dispatch(updateAutomationAction({
          automation: currentAutomationRef.current,
          company: company,
          formId: match.params.formId
        }));
      }
    } else {
      setAlertMessage('Please specify automation details before saving.');
    }
  }

  const validateComponent = (component: IAutomationComponent[]) => {
    let isValid = true as boolean | string;
    let errorMessage = [] as string[];

    if (component.length > 0) {
      component.forEach((e: IAutomationComponent) => {
        if (e.type === 'action' && e.config.hasOwnProperty('action')) {
          if (e.config.action.name === 'update-status' && !e.config.statusId) {
            isValid = false;
          } else if (e.config.action.name === 'create-document' && !e.config.landingStatusId && !e.config.formId) {
            isValid = false;
          } else if (e.config.action.name === 'edit-form' && (!e.config.fields ||
            (e.config.fields && Object.keys(e.config.fields).length === 0))) {
            isValid = false;
          } else if (e.config.action.name === 'send-email' && (!e.config.to
            || (e.config.to && e.config.to.length === 0))) {
            const { to, cc, bcc, subject, body } = e.config;

            const isNotEmpty = (value: any) => {
              return value !== '' || value !== null ||
                (Array.isArray(value) && value.length > 0) || value !== undefined
            }
            isValid = isNotEmpty(subject) && isNotEmpty(body)
              && (isNotEmpty(cc) || isNotEmpty(bcc) || isNotEmpty(to));

          } else if (e.config.action.name === 'compute-field' && (!e.config.fields ||
            (e.config.fields && e.config.fields.length === 0))) {
            isValid = false;
          } else if (e.config.action.name === 'create-document' && e.config.validationError) {
            errorMessage.push(e.config.validationError);
          } else if (e.config.action.name === 'create-user' && (!e.config.fields ||
            (e.config.fields && e.config.fields.length === 0) || (!e.config.profileIds && !e.config.portalId))) {
            isValid = false;
          } else if (e.config.action.name === 'generate-pdf' && !e.config.fieldId) {
            isValid = false;
          }
        } else if (e.type === 'condition') {
          if (e.config.condition && e.config.condition.name === 'if-else-block') {
            isValid = validateComponent(e.config.conditionList) && validateComponent(e.config.actionList);
          } else {
            isValid = !e.config.sourceField && !e.config.operator ? false : true;
          }
        }
      });
    }

    if (errorMessage.length > 0) {
      return errorMessage.join('\n')
    }

    return isValid;
  }

  const isValid = (automation: IAutomation, withAlert?: boolean): boolean => {
    if (!automation.name) {
      withAlert && setAlertMessage('Please specify automation name.')
      return false;
    }

    const hasTrigger = automation.component.find(e => e.type === 'trigger' && Object.keys(e.config).length > 1);
    let isValid = validateComponent(automation.component);

    if (typeof isValid === 'string' && isValid) {
      withAlert && setAlertMessage(isValid);
      return false;
    }

    if (!hasTrigger || !isValid) {
      withAlert && setAlertMessage('Please specify automation details before saving.');
      return false;
    }

    return true;
  }

  const updateCurrentAutomation = (component: IAutomationComponent, saveToApi: boolean) => {
    if (currentAutomationRef.current) {
      const tempCurrentAutomation = { ...currentAutomationRef.current };
      const filteredComponent = tempCurrentAutomation.component.filter((e: IAutomationComponent) => e.id === component.id);
      if (filteredComponent.length === 1) {
        const curIndex = tempCurrentAutomation.component.indexOf(filteredComponent[0]);
        const tempComponent = [...tempCurrentAutomation.component];
        tempComponent.splice(curIndex, 1, component);
        tempCurrentAutomation.component = tempComponent;
        currentAutomationRef.current = tempCurrentAutomation;
      }
      // setSelectedComponent(component);
      // currentSelectedComponent.current = component;
      // setRefresher(refresher + 1);
      if (selectedChildComponent) {
        manageChildComponent(component);
      }
      setTempComponentList(tempCurrentAutomation.component);
      hasComponentChange.current = false;
      hasAutomationChange.current = true;
    }
  }

  const manageChildComponent = (component: IAutomationComponent) => {
    const elseRowIndex = selectedChildComponent?.elseRowIndex;
    const rowIndex = selectedChildComponent?.rowIndex;
    const type = selectedChildComponent?.type;
    if (type === 'if' && rowIndex !== undefined) {
      const tempSelectedChildComponent = selectedChildComponent ? { ...selectedChildComponent } : undefined;
      if (tempSelectedChildComponent && tempSelectedChildComponent.component && tempSelectedChildComponent.component.type) {
        const componentType = tempSelectedChildComponent.component.type;
        if (componentType === 'condition') {
          tempSelectedChildComponent.component = component.config.conditionList && component.config.conditionList[rowIndex]
            ? { ...component.config.conditionList[rowIndex] } : component.config;
        }
        if (componentType === 'action') {
          tempSelectedChildComponent.component = component.config.actionList[rowIndex]
            ? { ...component.config.actionList[rowIndex] } : component.config;
        }
      }
      setSelectedChildComponent(tempSelectedChildComponent);
    }
    if (type === 'else' && elseRowIndex !== undefined && rowIndex !== undefined) {
      const tempSelectedChildComponent = selectedChildComponent ? { ...selectedChildComponent } : undefined;
      if (tempSelectedChildComponent && tempSelectedChildComponent.component && tempSelectedChildComponent.component.type) {
        const componentType = tempSelectedChildComponent.component.type;
        if (componentType === 'condition') {
          tempSelectedChildComponent.component = component.config.elseConditionList[elseRowIndex].conditionList[rowIndex]
            ? component.config.elseConditionList[elseRowIndex].conditionList[rowIndex] : component.config;
        }
        if (componentType === 'action') {
          tempSelectedChildComponent.component = component.config.elseConditionList[elseRowIndex].actionList[rowIndex]
            ? component.config.elseConditionList[elseRowIndex].actionList[rowIndex] : component.config;
        }
      }
      setSelectedChildComponent(tempSelectedChildComponent);
    }
  }

  const closeModal = () => {
    if (hasAutomationChange.current === true) {
      setAlertMessage(message.closeAutomationModalConfirmation)
      return;
    }
    forceCloseModal();
  }

  const forceCloseModal = () => {
    currentAutomationRef.current = undefined;
    setSelectedComponent(undefined);
    // currentSelectedComponent.current = undefined;
    // setRefresher(refresher + 1);
    dispatch(clearSelectedAutomation());
    history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}`);
  }

  const addNewComponent = (e: IOptionWidget) => {
    if (hasComponentChange.current === true) {
      setAlertMessage(message.notAllowedChangeComponent)
      return;
    }
    if (currentAutomationRef.current) {
      const tempAutomation = { ...currentAutomationRef.current };
      const tempComponentList = [...tempAutomation.component];
      const currentId = uuid();
      const data = {
        id: currentId,
        type: e.type,
        name: e.title,
        config: {},
      }
      tempComponentList.push(data);
      tempAutomation.component = tempComponentList;
      currentAutomationRef.current = tempAutomation;
      setSelectedComponent(data);
      // currentSelectedComponent.current = data;
      // setRefresher(refresher + 1);
    }
  }

  const updateAutomationDetails = (value: string, field: string) => {
    if (!currentAutomationRef.current) {
      currentAutomationRef.current = {} as IAutomation;
    }
    const tempAutomation: any = { ...currentAutomationRef.current };
    tempAutomation[field] = value;
    currentAutomationRef.current = tempAutomation;
    hasComponentChange.current = false;
    setRefresher(Math.random() + refresher + 1);
  }

  const deleteCreatedComponent = (type: string, parentIndex: number, childProperty?: any) => {
    if (currentAutomationRef.current) {
      const tempAutomation = { ...currentAutomationRef.current };
      if (childProperty !== undefined
        && childProperty.hasOwnProperty('parent')
        && childProperty.hasOwnProperty('type')) {
        switch (childProperty.parent) {
          case 'if':
            switch (childProperty.type) {
              case 'action':
                tempAutomation.component[parentIndex].config.actionList.splice(childProperty.childIndex, 1);
                break;
              case 'condition':
                tempAutomation.component[parentIndex].config.conditionList.splice(childProperty.childIndex, 1);
                break;
            }
            break;
          case 'else':
            switch (childProperty.type) {
              case 'action':
                if (childProperty?.childIndex !== undefined) {
                  tempAutomation.component[parentIndex].config.elseConditionList[childProperty.parentIndex].actionList.splice(childProperty.childIndex, 1);
                } else {
                  tempAutomation.component[parentIndex].config.elseConditionList.splice(childProperty.parentIndex, 1);
                }
                break;
              case 'condition':
                if (childProperty?.childIndex !== undefined) {
                  tempAutomation.component[parentIndex].config.elseConditionList[childProperty.parentIndex].conditionList.splice(childProperty.childIndex, 1);
                } else {
                  tempAutomation.component[parentIndex].config.elseConditionList.splice(childProperty.parentIndex, 1);
                }
                break;
            }
            break;
        }
      } else {
        if (type === 'trigger') {
          tempAutomation.component[parentIndex].config = {}
        } else {
          tempAutomation.component.splice(parentIndex, 1)
        }
      }

      currentAutomationRef.current = tempAutomation;
      // currentSelectedComponent.current = tempAutomation.component[0];
      setTempComponentList(JSON.stringify(tempAutomation.component));
      setSelectedComponent(tempAutomation.component[0]);
      hasComponentChange.current = false;
    }
  }

  const triggerSelectedComponentSave = () => {
    const currentAutomation = currentAutomationRef.current;
    if (selectedComponent) {
      const btnSaveCurrComponent = document.getElementById(selectedComponent.id);
      if (btnSaveCurrComponent) {
        btnSaveCurrComponent.click();
      }
    }
    if (showDetail && currentAutomation) {
      const btnSaveCurrComponent = document.getElementById(currentAutomation.id);
      if (btnSaveCurrComponent) {
        btnSaveCurrComponent.click();
      }
    }
  }

  const selectOutlineComponent = (e?: IAutomationComponent, child?: IAutomationChildComponent, childOnly?: boolean) => {
    triggerSelectedComponentSave();
    // currentSelectedComponent.current = e;
    // setRefresher(refresher + 1); 
    setShowDetail(false);
    if (!childOnly) { setSelectedComponent(e); };
    setSelectedChildComponent(child);
    if (e) {
      AutomationUtils.manageFieldOption(e, {
        dispatch,
        referenceList,
        company,
        inputFieldList,
        datatableInputField,
        publishedFormStatusList,
        formFieldList,
        portals,
      });
    }
  }

  const showSaveButton = () => {
    let show = false;
    if (currentAutomationRef.current) {
      const automationComponent = currentAutomationRef.current.component;
      if (automationComponent.length > 0) {
        show = automationComponent.some(e => e.type === 'action' ||
          (e.type === 'condition' && e.config.condition && e.config.condition.name === 'if-else-block'
            && e.config.conditionList && e.config.conditionList.length > 0
            && e.config.conditionList.every((con: any) => con.config && Object.keys(con.config).length > 0)
          )
        );
      }
    }
    return show && selectedComponent && Object.keys(selectedComponent.config).length > 0;
  }

  const ruleHeaderTitle = () => {
    const currAutomation = currentAutomationRef.current;
    let title = 'Create Rule';
    if (currAutomation && currAutomation.name) {
      title = currAutomation.name;
    }
    return title;
  }

  useEffect(() => {
    if (triggerCloseAutomationModal) {
      hasAutomationChange.current = false;
      forceCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCloseAutomationModal])

  return (
    <Modal
      size='large'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={true}
      onClose={closeModal}
      closeIcon
      id='automation-form-modal'
      className={`automation-form-modal`}
      centered={false}
    >
      <LoaderComponent loading={createAutomationLoading || updateAutomationLoading} />
      <AlertModal
        open={alertMessage !== ''}
        close={() => setAlertMessage('')}
        confirm={() => forceCloseModal()}
        message={alertMessage}
        type={alertMessage === message.closeAutomationModalConfirmation ? 'warning' : 'error'}
      />
      <Modal.Header className='borderless' id='automation-form-modal-header' >
        <Segment className={`borderless bg-primary automation-name`} onClick={() => setShowDetail(true)}>
          <h4>{ruleHeaderTitle()}</h4>
        </Segment>
      </Modal.Header>
      <Modal.Content>
        {getAutomationContentLoading &&
          <span>Loading...</span>
        }
        {currentAutomationRef.current &&
          <div className='automation-form-container'>
            <AutomationOutline
              componentList={currentAutomationRef.current.component}
              setSelectedComponent={selectOutlineComponent}
              selectedComponent={selectedComponent}
              tempComponentList={tempComponentList}
              updateCurrentAutomation={updateCurrentAutomation}
              deleteCreatedComponent={deleteCreatedComponent}
              setAlertMessage={setAlertMessage}
              hasComponentChange={hasComponentChange}
              selectedChildComponent={selectedChildComponent}
            />
            {showDetail &&
              <AutomationMainDetail
                updateAutomationDetails={updateAutomationDetails}
                currentAutomationRef={currentAutomationRef}
                hasComponentChange={hasComponentChange}
                parentRefresher={parentRefresher}
              />
            }
            {!showDetail &&
              <AutomationDetail
                updateAutomationDetails={updateAutomationDetails}
                selectedComponent={selectedComponent}
                selectedChildComponent={selectedChildComponent}
                addNewComponent={addNewComponent}
                currentAutomationRef={currentAutomationRef}
                updateCurrentAutomation={updateCurrentAutomation}
                businessRuleId={match.params.businessRulesId}
                refsObject={refsObject}
                setSelectedComponent={selectOutlineComponent}
                hasComponentChange={hasComponentChange}
                parentRefresher={parentRefresher}
              />
            }
          </div>
        }
        {
          !currentAutomationRef.current && <span>Not Found</span>
        }
      </Modal.Content>
      <Modal.Actions className='borderless'>
        <div className={`string-action-container`}>
          <div style={{ height: '35px' }}>
            {showSaveButton() &&
              <Button color='green' className='btn-action' onClick={() => saveAutomation()} >{'Save'}</Button>
            }
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default AutomationForm;

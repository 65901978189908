import { CSSProperties } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { customFilterSingleField, customFilterRangeField } from '../../custom-filter/constant';
import { ICustomFilterField } from '../../custom-filter/interface/custom-filter-field';

export const setGanttChartFilterOptions = (fieldList: ICustomFieldDetail[]) => {

  const hasNumber = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'number').length > 0;
  }

  const hasTime = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'time').length > 0;
  }

  const hasDropdown = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'enum').length > 0;
  }

  const hasFileupload = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'file').length > 0;
  }

  const hasSwitch = (fieldList: ICustomFieldDetail[]) => {
    return fieldList.filter((field: ICustomFieldDetail) => field.type === 'bool').length > 0;
  }

  const showField = (customFilterField: ICustomFilterField, fieldList: ICustomFieldDetail[]): boolean => {
    switch (customFilterField.type) {
      case 'number':
      case 'numberRange':
        return hasNumber(fieldList);
      case 'time':
      case 'timeRange':
        return hasTime(fieldList);
      case 'dropdown':
        return hasDropdown(fieldList);
      case 'fileupload':
        return hasFileupload(fieldList);
      case 'switch':
        return hasSwitch(fieldList);
      default:
        return false;
    }
  }


  const singleFilterField = customFilterSingleField.filter(e => showField(e, fieldList));
  const rangeFilterField = customFilterRangeField.filter(e => showField(e, fieldList));

  return [...singleFilterField, ...rangeFilterField].map((filter: ICustomFilterField, index: number) => {
    let iconStyle: CSSProperties = filter.iconStyle;

    return {
      key: index,
      text: filter.label,
      value: filter.type,
      image: { src: filter.icon, style: iconStyle }
    }
  })
}
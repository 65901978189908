import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dimmer, Image, Loader } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../common/field/type/interface/field-type-object';
import ActionMessage from '../../../../../../common/general/action-message';
import { AlertModal } from '../../../../../../common/general/alert-modal';
import { ConfirmationMessage } from '../../../../../../common/general/confirmation-modal';
import ToolTip from '../../../../../../common/general/tooltip';
import { icons } from '../../../../../../common/icons';
import changeRoute from '../../../../../../common/utils/change-menu';
import { IRootState } from '../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { getCustomFilterListAction } from '../configuration/module/custom-filter/action';
import { getColumnViewListAction } from '../configuration/module/document-list/action';
import { getFormLabelsAction } from '../configuration/module/labels/actions';
import { getTaskViewAction } from '../configuration/module/task-view/action';
import { IForm } from '../form/interface/form';
import { getPermissionListAction } from '../permission/action';
import {
  deleteFormBuilderFieldAction,
  publishFormBuilderConfigurationAction,
  saveFormBuilderConfigurationAction,
  saveFormBuilderConfigurationErrorAction,
} from './action';
import {
  formBuilderActionMessage,
  formBuilderDetailMessage,
  maxFieldInARow,
} from './constant';
import FormBuilderCustomField from './custom-field';
import FieldContainerSection from './field-container';
import FieldLayoutTab from './field-container/layout-tab';
import SectionSettings from './field-container/section-container/section-settings';
import MobileSectionContainer, {
  ITableWidgetConfig,
} from './field-container/section-mobile';
import FieldTypeSection from './field-type';
import { useGetFormBuilderConfig } from './hooks/use-get-form-builder-config';
import { useSetBPImage } from './hooks/use-set-bp-image';
import { useSetCurrentFieldList } from './hooks/use-set-current-field-list';
import { useSetSectionList } from './hooks/use-set-section-list';
import {
  IFormBuilderSection,
  TFormBuilderSectionRow,
} from './interface/section';
import { SectionFieldManager } from './section-field-manager';
import {
  DragFieldObject,
  DragSectionObject,
  ISectionUpdateConfig,
} from './section-field-manager/interface/section-update-config';
import { CheckFieldColumn } from './utils/check-field-column';
import { CheckFieldFilter } from './utils/check-field-filter';
import { CheckFieldFxUsage } from './utils/check-field-fx-usage';
import { CheckFieldLabel } from './utils/check-field-label';
import { CheckFieldTaskLayout } from './utils/check-field-task-layout';
import { SanitizeSectionList } from './utils/sanitize-section-list';
import Axios, { AxiosResponse } from 'axios';

interface IMatch {
  params: { formId: string };
}

interface IFormBuilderContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const FormBuilderContainer: React.FC<IFormBuilderContainerProps> = (props) => {
  const match: IMatch = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const companyId = useSelector((state: IRootState) => state.auth.company);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const {
    formBuilderDetailErrorMessage,
    formDetail,
    formBuilderField,
    saveFormBuilderLoading,
    formBuilderDetailLoading,
    saveFormBuilderType,
    publishedForm,
    datatable,
    userCustomField,
  } = useSelector((state: IRootState) => state.formBuilder);
  const { customFilterListAPI } = useSelector(
    (state: IRootState) => state.customFilter
  );
  const { columnViewList } = useSelector(
    (state: IRootState) => state.documentList
  );
  const { formLabels, formAppliedLabels } = useSelector(
    (state: IRootState) => state.configurationLabels
  );
  const { apiTaskView } = useSelector((state: IRootState) => state.taskView);

  const [selectedSection, setSelectedSection] = useState(
    null as IFormBuilderSection | null
  );
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isOpenCustomField, setIsOpenCustomField] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [updateConfig, setUpdateConfig] = useState({} as ISectionUpdateConfig);
  const [selectedCustomField, setSelectedCustomField] = useState(
    {} as ICustomFieldDetail
  );
  const [forDeletionField, setForDeletionField] = useState(
    {} as ICustomFieldDetail
  );
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('web' as 'web' | 'mobile');
  const [tableWidgetConfig, setTableWidgetConfig] = useState<{
    [id: string]: ITableWidgetConfig;
  }>({});

  const { sectionList, setSectionList } = useSetSectionList({
    formBuilderField,
    formDetail,
    formId: match.params.formId,
  });
  const { currentFieldList } = useSetCurrentFieldList({ sectionList });
  const { bpImage } = useSetBPImage({ publishedForm });

  const openSettings = (selectedSection: IFormBuilderSection) => {
    setSelectedSection(selectedSection);
    setIsSettingsOpen(true);
  };

  const closeSettings = () => {
    setSelectedSection(null);
    setIsSettingsOpen(false);
  };

  const addSection = () => {
    const newSectionList = [...sectionList];
    newSectionList.push({
      sectionId: uuid(),
      title: '',
      collapsable: false,
      fields: [] as TFormBuilderSectionRow[],
      titleFieldToShow: [],
      titleHidden: false,
      additionalInfo: '',
      style: 'grouped',
    } as IFormBuilderSection);
    setSectionList(newSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  };

  const deleteEmptySection = (sectionId: string) => {
    let oldSectionList = [...sectionList];
    let newSectionList = [] as IFormBuilderSection[];
    newSectionList = oldSectionList.filter(
      (section) => section.sectionId !== sectionId
    );
    const deletedExistingField: string[] = [];
    oldSectionList.forEach((section: IFormBuilderSection) => {
      if (section.sectionId === sectionId) {
        section.fields.forEach((fields: TFormBuilderSectionRow) => {
          if (fields) {
            fields.forEach((field: ICustomFieldDetail) => {
              deletedExistingField.push(field.id || '');
            });
          }
        });
      }
    });
    if (deletedExistingField.length) {
      deletedExistingField.forEach((fieldId: string) => {
        dispatch(
          deleteFormBuilderFieldAction({
            company: companyId,
            formId: match.params.formId,
            fieldId: fieldId,
          })
        );
      });
    }
    setSectionList(newSectionList);
    closeSettings();
    props.hasPageChangeRef.current.hasChange = true;
  };

  const addFieldOnPlainSection = (dragItem: DragFieldObject) => {
    if (dragItem.data) {
      let customFieldData: ICustomFieldDetail = {
        ...dragItem.data.defaultData,
        config: dragItem.data.defaultConfig,
        id: uuid(),
        new: true,
      };
      const newSectionList = [...sectionList];
      newSectionList.push({
        sectionId: uuid(),
        title: '',
        collapsable: false,
        fields: [[customFieldData]] as TFormBuilderSectionRow[],
        titleFieldToShow: [],
        titleHidden: false,
        additionalInfo: '',
        style: 'plain',
      } as IFormBuilderSection);
      setSectionList(newSectionList);
      props.hasPageChangeRef.current.hasChange = true;
    }
  };

  const changeSectionSequence = (
    dragItem: DragSectionObject,
    dropContainerId: string
  ) => {
    const dragItemIndex = sectionList.indexOf(dragItem.data);
    const dropContainerIdIndex = sectionList.indexOf(
      sectionList.filter((e) => e.sectionId === dropContainerId)[0]
    );
    const updatedSectionList = [...sectionList];
    const element = updatedSectionList[dragItemIndex];
    updatedSectionList.splice(dragItemIndex, 1);
    updatedSectionList.splice(dropContainerIdIndex, 0, element);
    setSectionList(updatedSectionList);
    props.hasPageChangeRef.current.hasChange = true;
  };

  const addFieldToSection = (dragItem: DragFieldObject, sectionId: string) => {
    if (dragItem.data) {
      let customFieldData: ICustomFieldDetail = {
        ...dragItem.data.defaultData,
        config: dragItem.data.defaultConfig,
        id: uuid(),
        new: true,
      };
      let setUpdateConfig = {
        updateType: 'FieldTypeToDropbox',
        sectionId,
      };
      updateDetailList(
        customFieldData,
        customFieldData.id || '',
        setUpdateConfig
      );
      props.hasPageChangeRef.current.hasChange = true;
    }
  };

  const addNewFieldInSectionFieldList = (
    dragItem: DragFieldObject,
    sectionId: string,
    dropAreaRowIndex: number
  ) => {
    if (!isAllowedToAddFieldInARow(sectionId, dropAreaRowIndex)) {
      dispatch(
        saveFormBuilderConfigurationErrorAction(
          `Maximum of (${maxFieldInARow}) fields allowed per row`
        )
      );
      return;
    }

    if (dragItem.data) {
      let customFieldData: ICustomFieldDetail = {
        ...dragItem.data.defaultData,
        config: { ...dragItem.data.defaultConfig },
        id: uuid(),
        new: true,
      };
      let setUpdateConfig = {
        updateType: 'FieldTypeToSectionField',
        sectionId,
        dropAreaRowIndex,
      };
      updateDetailList(
        customFieldData,
        customFieldData.id || '',
        setUpdateConfig
      );
      props.hasPageChangeRef.current.hasChange = true;
    }
  };

  const editFieldConfig = (field: ICustomFieldDetail, sectionId: string) => {
    setUpdateConfig({ updateType: 'UpdateField', sectionId });
    openCustomField(field);
    if (field.type === 'table') {
      dispatch(
        getPermissionListAction({
          company: companyId,
          formId: match.params.formId,
          raw: true,
        })
      );
    }
  };

  const unlinkField = (
    sectionId: string,
    field: ICustomFieldDetail,
    rowIndex: number,
    currentFieldIndex: number
  ) => {
    let updateConfigData: ISectionUpdateConfig = {
      updateType: 'UnlinkField',
      sectionId,
      rowIndex,
      currentFieldIndex,
    };
    setSectionList(
      SectionFieldManager.updateSectionField(
        sectionList,
        field,
        updateConfigData
      )
    );
    props.hasPageChangeRef.current.hasChange = true;
  };

  const unlinkFieldToPlainSection = (
    sectionId: string,
    field: ICustomFieldDetail,
    rowIndex: number,
    currentFieldIndex: number
  ) => {
    let updateConfigData: ISectionUpdateConfig = {
      updateType: 'UnlinkFieldOnPlainField',
      sectionId,
      rowIndex,
      currentFieldIndex,
    };
    setSectionList(
      SectionFieldManager.updateSectionField(
        sectionList,
        field,
        updateConfigData
      )
    );
    props.hasPageChangeRef.current.hasChange = true;
  };

  const sortFieldSection = (
    dragItem: DragFieldObject,
    sectionId: string,
    dropAreaRowIndex: number
  ) => {
    if (sectionId === dragItem.fieldSectionId) {
      let updateConfigData: ISectionUpdateConfig = {
        updateType: 'SortFieldSection',
        dropAreaRowIndex,
        sectionId,
        dragItemRowIndex: dragItem.fieldRowIndex,
        dragFieldSectionId: dragItem.fieldSectionId,
        dragItemRow: dragItem.rowItem,
      };
      setSectionList(
        SectionFieldManager.updateSectionField(
          sectionList,
          {} as ICustomFieldDetail,
          updateConfigData
        )
      );
      props.hasPageChangeRef.current.hasChange = true;
    } else {
      setAlertMessage(`Field should be moved to the same section.`);
      setOpenAlertMessage(true);
    }
  };

  const moveField = (
    dragItem: DragFieldObject,
    sectionId: string,
    dropAreaRowIndex: number,
    dropAreaFieldIndex: number
  ) => {
    if (
      !isAllowedToAddFieldInARow(
        sectionId,
        dropAreaRowIndex,
        dragItem.fieldRowIndex
      )
    ) {
      dispatch(
        saveFormBuilderConfigurationErrorAction(
          `Maximum of (${maxFieldInARow}) fields allowed per row`
        )
      );
      return;
    }

    // if (sectionId === dragItem.fieldSectionId) {
    let updateConfigData: ISectionUpdateConfig = {
      updateType: 'MoveField',
      dropAreaRowIndex,
      dropAreaFieldIndex,
      sectionId,
      dragItemRowIndex: dragItem.fieldRowIndex,
      dragFieldIndex: dragItem.fieldIndex,
      dragFieldSectionId: dragItem.fieldSectionId,
    };
    setSectionList(
      SectionFieldManager.updateSectionField(
        sectionList,
        dragItem.field as ICustomFieldDetail,
        updateConfigData
      )
    );
    // } else {
    //   setAlertMessage(`Field should be move on the same section.`)
    //   setApenAlertMessage(true)
    // }
    props.hasPageChangeRef.current.hasChange = true;
  };

  const isAllowedToAddFieldInARow = (
    sectionId: string,
    dropAreaRowIndex: number,
    dragItemRowIndex?: number
  ): boolean => {
    let allowedToAddFieldInARow = true;

    sectionList.forEach((section: IFormBuilderSection) => {
      if (section.style === 'plain') {
        section.fields.forEach(
          (plainfield: TFormBuilderSectionRow, index: number) => {
            if (sectionId === section.sectionId) {
              allowedToAddFieldInARow =
                maxFieldInARow >
                plainfield.filter(
                  (e: ICustomFieldDetail) =>
                    e.deleted !== true && e.configName !== IConfigName.Seq
                ).length;
            }
          }
        );
      } else {
        section.fields.forEach(
          (fields: TFormBuilderSectionRow, index: number) => {
            if (
              section.fields.length &&
              sectionId === section.sectionId &&
              dropAreaRowIndex === index &&
              dropAreaRowIndex !== dragItemRowIndex
            ) {
              allowedToAddFieldInARow =
                maxFieldInARow >
                fields.filter(
                  (e: ICustomFieldDetail) =>
                    e.deleted !== true && e.configName !== IConfigName.Seq
                ).length;
            }
          }
        );
      }
    });
    return allowedToAddFieldInARow;
  };

  const updateSectionDetail = (updatedSection: IFormBuilderSection) => {
    const updatedSectionList = sectionList.map(
      (section: IFormBuilderSection) => {
        if (section.sectionId === updatedSection.sectionId) {
          section = updatedSection;
        }
        return section;
      }
    );
    setSectionList(updatedSectionList);
    closeSettings();
    props.hasPageChangeRef.current.hasChange = true;
  };

  const openCustomField = (field: ICustomFieldDetail) => {
    setSelectedCustomField(field);
    setIsOpenCustomField(true);
  };

  const closeCustomField = () => {
    setUpdateConfig({} as ISectionUpdateConfig);
    setSelectedCustomField({} as ICustomFieldDetail);
    setIsOpenCustomField(false);
  };

  const updateDetailList = (
    field: ICustomFieldDetail,
    fieldId: string,
    manualUpdateConfig?: ISectionUpdateConfig
  ) => {
    let updateConfigData = manualUpdateConfig
      ? manualUpdateConfig
      : { ...updateConfig };
    let newField = { ...field };
    newField.id = fieldId;
    setSectionList(
      SectionFieldManager.updateSectionField(
        sectionList,
        newField,
        updateConfigData
      )
    );
    closeCustomField();
    props.hasPageChangeRef.current.hasChange = true;
  };

  const openDeleteConfirmationModal = (
    field: ICustomFieldDetail,
    sectionId: string
  ) => {
    const fxFieldUsage = CheckFieldFxUsage.isUsedInFxField({
      forDeleteField: field,
      sectionList,
    });
    if (field.new) {
      setDeleteModalMessage(
        `The field '${field.label || 'Untitled'
        }' will be deleted. Do you still want to proceed?`
      );
    } else {
      setDeleteModalMessage(
        `The field '${field.label || 'Untitled'
        }' and all its records will be deleted. Do you still want to proceed?`
      );
    }
    if (fxFieldUsage) {
      setDeleteModalMessage(`The field '${field.label || 'Untitled'
        }' was used in FX Field '${fxFieldUsage.label || 'Untitled'}'. 
      If you continue all its records will be deleted including its usage on FX Field '${fxFieldUsage.label || 'Untitled'
        }'. 
      Do you still want to proceed?`);
    }
    setUpdateConfig({
      sectionId,
      updateType: 'DeleteField',
    } as ISectionUpdateConfig);
    setForDeletionField(field);
    setIsOpenDeleteModal(true);
    getConfigOfFieldsFromModule();
  };

  const closeDeleteFieldSection = () => {
    setUpdateConfig({} as ISectionUpdateConfig);
    setForDeletionField({} as ICustomFieldDetail);
    setIsOpenDeleteModal(false);
  };

  const confirmDeleteFieldSection = () => {
    let updateConfigData = { ...updateConfig };
    setSectionList(
      SectionFieldManager.updateSectionField(
        sectionList,
        forDeletionField,
        updateConfigData
      )
    );
    closeDeleteFieldSection();
    props.hasPageChangeRef.current.hasChange = true;
  };

  const closeAlertMessage = () => {
    setAlertMessage(``);
    setOpenAlertMessage(false);
  };

  const onTabChange = (tab: 'web' | 'mobile') => {
    setActiveTab(tab);
  };

  const getConfigOfFieldsFromModule = () => {
    dispatch(
      getCustomFilterListAction({
        company: companyId,
        formId: match.params.formId,
      })
    );
    dispatch(
      getColumnViewListAction({
        company: companyId,
        formId: match.params.formId,
      })
    );
    dispatch(
      getFormLabelsAction({ companyId: companyId, formId: match.params.formId })
    );
    dispatch(
      getTaskViewAction({ company: companyId, formId: match.params.formId })
    );
    dispatch(
      getPermissionListAction({
        company: companyId,
        formId: match.params.formId,
        raw: true,
      })
    );
  };

  const updateConfigOfFieldsFromModule = (deletedExistingField: string[]) => {
    Promise.all(
      deletedExistingField.map((fieldId) =>
        triggerDeleteField(companyId, match.params.formId, fieldId)
      )
    ).then((response) => {
      const allDeleted = response.every((e) => e === true);
      if (allDeleted) {
        CheckFieldFilter.updateConfig({
          deletedExistingField,
          customFilterListAPI,
          formId: match.params.formId,
          companyId,
          dispatch,
        });
        CheckFieldColumn.updateConfig({
          deletedExistingField,
          columnViewList,
          formId: match.params.formId,
          companyId,
          dispatch,
        });
        CheckFieldLabel.updateConfig({
          deletedExistingField,
          formConfigLabel: {
            formLabels,
            formAppliedLabels,
          },
          formId: match.params.formId,
          companyId,
          dispatch,
        });
        CheckFieldTaskLayout.updateConfig({
          deletedExistingField,
          formId: match.params.formId,
          companyId,
          dispatch,
          apiTaskView,
        });
      }
    });
  };

  const triggerDeleteField = async (
    company: string,
    formId: string,
    fieldIdToDelete: string
  ) => {
    if (company && formId) {
      try {
        const request: AxiosResponse = await Axios.delete(
          `${company}/forms/${formId}/fields/${fieldIdToDelete}`,
          {}
        );
        return request.data.success;
      } catch (_e) {
        return _e;
      }
    }
  };

  const saveFormBuilder = (saveType?: string) => {
    let isError = false;
    const formDetailObject = { ...formDetail } as IForm;
    delete formDetailObject.icon;

    const deletedExistingField: string[] = [];
    sectionList.forEach((section: IFormBuilderSection) => {
      section.fields.forEach((fields: TFormBuilderSectionRow) => {
        if (fields) {
          fields.forEach((field: ICustomFieldDetail) => {
            if (field.label === undefined && !field.deleted) {
              isError = true;
            }
            if (field.deleted === true && field.new !== true) {
              deletedExistingField.push(field.id || '');
            }
          });
        }
      });
    });

    if (isError) {
      return dispatch(
        saveFormBuilderConfigurationErrorAction('Every field must have a name')
      );
    }

    dispatch(
      saveFormBuilderConfigurationAction({
        company: companyId,
        formId: match.params.formId,
        data: SanitizeSectionList.structureOutgoing(sectionList, {
          ...tableWidgetConfig,
        }),
        formDetail: formDetailObject,
        saveType: saveType ? saveType : '',
        updateConfigOfFieldsFromModule,
        deletedExistingField,
      })
    );

    props.hasPageChangeRef.current.hasChange = false;
  };

  const cancelFormBuilder = () => {
    changeRoute(
      '/admin/business-process/form',
      hasPageChange || props.hasPageChangeRef.current.hasChange,
      { dispatch, history }
    );
  };

  useEffect(() => {
    if (saveFormBuilderType === 'publish') {
      dispatch(
        publishFormBuilderConfigurationAction({
          company: companyId,
          formId: match.params.formId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFormBuilderType]);

  useGetFormBuilderConfig({
    companyId: companyId,
    dispatch,
    formId: match.params.formId,
    getConfigOfFieldsFromModule,
  });

  return (
    <>
      {(saveFormBuilderLoading || formBuilderDetailLoading) && (
        <Dimmer active inverted>
          <Loader active content="Loading" />
        </Dimmer>
      )}
      <ToolTip
        popupMessage="Close"
        popupTriggerComponent={
          <Image
            id="business-process-close-icon"
            src={icons.blue.closeCircle}
            onClick={() => {
              changeRoute(
                '/admin/business-process/form',
                hasPageChange || props.hasPageChangeRef.current.hasChange,
                { dispatch, history }
              );
            }}
          />
        }
        position="right center"
      />
      <div style={{ marginTop: '15px', marginLeft: '5px', marginRight: '5px' }}>
        <ActionMessage messages={formBuilderActionMessage} />
      </div>
      {formBuilderDetailErrorMessage ? (
        <div
          style={{ marginTop: '15px', marginLeft: '5px', marginRight: '25px' }}
        >
          <ActionMessage messages={formBuilderDetailMessage} />
        </div>
      ) : (
        <div className="form-builder-container-main">
          <div className="form-builder-header">
            <FieldLayoutTab setActiveTab={onTabChange} activeTab={activeTab} />
          </div>
          {activeTab === 'web' ? (
            <div className="form-builder-container">
              <FieldTypeSection
                onAddSection={addSection}
                currentBp={formDetail}
                bpImage={bpImage}
              />
              <FieldContainerSection
                sectionList={sectionList}
                changeSectionSequence={changeSectionSequence}
                addFieldToSection={addFieldToSection}
                addNewFieldInSectionFieldList={addNewFieldInSectionFieldList}
                openSettings={openSettings}
                editFieldConfig={editFieldConfig}
                openDeleteConfirmationModal={openDeleteConfirmationModal}
                unlinkField={unlinkField}
                unlinkFieldToPlainSection={unlinkFieldToPlainSection}
                sortFieldSection={sortFieldSection}
                moveField={moveField}
                saveFormBuilder={() => saveFormBuilder('draft')}
                cancelFormBuilder={cancelFormBuilder}
                publishFormBuilder={() => saveFormBuilder('publish')}
                addFieldOnPlainSection={addFieldOnPlainSection}
                bpImage={bpImage}
              />
            </div>
          ) : (
            <MobileSectionContainer
              currentFieldList={currentFieldList}
              updateDetailList={updateDetailList}
              setUpdateConfig={setUpdateConfig}
              sectionList={sectionList}
              setTableWidgetConfig={setTableWidgetConfig}
              tableWidgetConfig={tableWidgetConfig}
              saveFormBuilder={saveFormBuilder}
              cancelFormBuilder={cancelFormBuilder}
            />
          )}
        </div>
      )}
      <AlertModal
        open={openAlertMessage}
        close={closeAlertMessage}
        message={alertMessage}
        type="error"
      />
      {selectedSection && (
        <SectionSettings
          close={closeSettings}
          open={isSettingsOpen}
          selectedSection={selectedSection}
          updateSelectedSection={updateSectionDetail}
          deleteEmptySection={deleteEmptySection}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          isOpenDeleteModal={isOpenDeleteModal}
          sectionList={sectionList}
        />
      )}
      <FormBuilderCustomField
        isCustomFieldFormOpen={isOpenCustomField}
        closeCustomField={closeCustomField}
        field={selectedCustomField}
        updateDetailList={updateDetailList}
        publishedForm={publishedForm}
        datatable={datatable}
        formDetail={formDetail}
        currentFieldList={currentFieldList}
        companyId={companyId}
        userFieldList={userCustomField}
      />
      <ConfirmationMessage
        clearErrorMessage={() => { }}
        errorMessage={''}
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={closeDeleteFieldSection}
        open={isOpenDeleteModal}
        confirm={confirmDeleteFieldSection}
        cancel={closeDeleteFieldSection}
        headerText="Confirmation"
        detailText={deleteModalMessage}
        loading={false}
        type="warning"
      />
    </>
  );
};

export default FormBuilderContainer;

import React, { useRef, useState, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Image } from 'semantic-ui-react-bpm';
import { errorIndicator } from '../../constant';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig } from '../../interface/custom-field-detail';
import { useDispatch } from 'react-redux';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { plainToClass } from 'class-transformer';
import { ICustomFieldProps } from '../..';
import Properties from '../../properties';
import Operator from './operator';
import Formula from './formula';
import { IFormula } from '../../interface/formula';
import { getFieldListAction } from '../../action';
import DragToDelete from './drag-to-delete';
import { icons } from '../../../icons';

export default class FXFieldConfig extends DefaultFieldConfig {

  name = 'fx';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const dispatch = useDispatch();

    const companyId = props.companyId;
    const fieldNameRef = useRef(document.createElement('input'));
    const infoRef = useRef(document.createElement('input'));
    const [error, setError] = useState(errorIndicator);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<ICustomFieldDetailConfig>({});
    const [activeIndex, setActiveIndex] = useState(1);
    const [alignment, setAlignment] = useState('left');
    const [commaSeparated, setCommaSeparated] = useState(false);
    const [formula, setFormula] = useState<IFormula[]>([]);
    const [confidential, setConfidential] = useState(false);

    const minLengthRef = useRef(document.createElement('input'));
    const maxLengthRef = useRef(document.createElement('input'));
    const decimalRef = useRef(document.createElement('input'));

    const handleClick = (e: any, titleProps: any) => {
      const { index } = titleProps;
      setActiveIndex(!index ? 1 : 0);
    }

    const addFormula = (e: IFormula) => {
      let tempFormula = [...formula];
      tempFormula.push(e);
      setFormula(tempFormula)
    }

    const updateFormulaInput = (currentValue: string, currentIndex: number) => {
      let tempFormula = [...formula];
      tempFormula = tempFormula.map((value: IFormula, index: number) => {
        const tempValue = { ...value };
        if (index === currentIndex) {
          tempValue.value = parseFloat(currentValue || '0');
        }
        return tempValue;
      });
      setFormula(tempFormula)
    }

    const removeFormula = (index: number) => {
      let tempFormula = [...formula];
      tempFormula.splice(index, 1);
      setFormula(tempFormula)
    }

    const updateConfig = () => {
      if (validateRequiredFormField()) {
        const config = plainToClass(IConfig, {
          ...configData,
          formula: formula as IFormula[],
          decimals: decimalRef.current.value ? parseFloat(decimalRef.current.value) : 0,
          thousandSeparator: commaSeparated,
          alignment: alignment
        })

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: false,
            label: fieldNameRef.current.value,
            hint: infoRef.current.value,
            config: config,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '')
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      if (props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
      }

      setError(currentError)
      return !currentError.fieldname
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config);
        setFieldData(data);
        fieldNameRef.current.value = data.label || '';
        infoRef.current.value = data.hint || '';
        decimalRef.current.value = config.decimals?.toString() || '';
        setFormula(config.formula || []);
        setCommaSeparated(config.thousandSeparator || false);
        setAlignment(config.alignment || 'left');
        setConfidential(data.confidential || false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    useEffect(() => {
      if (props.formDetail) {
        dispatch(getFieldListAction({
          company: companyId,
          databaseId: '',
          formId: props.formDetail.id || '',
          dataType: 'document',
        }))
      }
      // eslint-disable-next-line
    }, [props.formDetail, companyId])

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='fx-field-modal'
        centered={false}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`Computation Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form'>
            <div className='field-info-properties-container'>
              <div className='field-info-container'>
                <Form.Group widths='equal'>
                  <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                    <label className='label'>Field Name</label>
                    <div className="ui input">
                      <input onChange={() => {
                        error.fieldname = (fieldNameRef.current.value ? false : true)
                      }} ref={fieldNameRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group widths='equal'>
                  <div className=" six wide field">
                    <label className='label'>Field Info</label>
                    <div className="ui input">
                      <input onChange={() => {
                        error.fieldname = (infoRef.current.value ? false : true)
                      }} ref={infoRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
              </div>
              <Properties
                fieldData={fieldData}
                configData={configData}
                handleClick={handleClick}
                minLengthRef={minLengthRef}
                maxLengthRef={maxLengthRef}
                alignment={alignment}
                decimalRef={decimalRef}
                commaSeparated={commaSeparated}
                setCommaSeparated={setCommaSeparated}
                setRefreshState={() => { }}
                refreshState={0}
                setAlignment={setAlignment}
                activeIndex={activeIndex}
                error={error}
                setConfidential={setConfidential}
                confidential={confidential}
              />
            </div>
            <div className='operator-properties-container'>
              <Operator
                addFormula={addFormula}
                displayFieldList={props.currentFieldList || []}
                currentFieldId={props.fieldId}
                tableId={props.tableId}
              />
            </div>
            <Formula
              formula={formula}
              updateFormulaInput={updateFormulaInput}
              displayFieldList={props.currentFieldList || []}
            />
            <DragToDelete
              removeFormula={removeFormula}
            />
          </Form>
          <div className='label-bottom'>
            <label><strong> * </strong> Fields are required</label>
          </div>
        </Modal.Content>
        <Modal.Actions className='borderless'>
          {!props.isViewOnly && <React.Fragment  >
            <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
            {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
              <Button color='red' className='btn-action create-btn'
                onClick={() => {
                  props.cancel(props.fieldId)
                  props.openDeleteModal(props.fieldId, fieldData.label || '');
                }}>Delete</Button>
            }
            {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
              <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
            }
          </React.Fragment>}
        </Modal.Actions>
      </Modal>
    </>
  }
}
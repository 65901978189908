import { useEffect, useState } from 'react';
import { IColumnView } from '../../document-list/interface/custom-view';

interface IProps {
  field: IColumnView | undefined
}

export const useSetAggregateModalState = (props: IProps) => {
  const { field } = { ...props };

  const [isBlockBForEditing, setIsBlockedBForEditing] = useState(false);
  const [isShowSummary, setIsShowSummary] = useState(false);
  const [aggregate, setAggregate] = useState('');

  useEffect(() => {
    setIsBlockedBForEditing((field && field.agg && field.agg.isBlockForEditing) || false)
    setIsShowSummary((field && field.agg && field.agg.summaryRow) ? true : false);
    setAggregate((field && field.agg && field.agg.summaryRow) || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  return {
    ...props, isBlockBForEditing, setIsBlockedBForEditing,
    isShowSummary, setIsShowSummary, aggregate, setAggregate
  };
}
import { DeltaModuleType } from '..';
import { ISenderDetail } from '../../../../../../document-module/module/form/interface/sender-info';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../interface/history';
import { IAdminHistoryUtils } from '../../../manage-history';
import DeltaFields from '../../fields';

export class UsersFieldsDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.UsersFields;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {

    const { targetDelta, targetInstance } = { ...historyLogs.context };

    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    const text = `${commentSender} has updated User Custom Field: ${targetInstance.label}`;

    const fieldsDelta = new DeltaFields();
    const delta = fieldsDelta.formatContext(targetDelta || {}, targetInstance);

    return { delta, text };
  }
}
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_FORM_BUILDER_DETAIL,
  SAVE_FORM_BUILDER_CONFIGURATION,
  DELETE_FORM_BUILDER_FIELD,
  PUBLISH_FORM_BUILDER_CONFIGURATION,
  GET_PUBLISHED_FORM_LIST,
} from './constant';
import { IFormBuilderInput } from './interface/input/form-builder';
import {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
  IAxiosResponse,
} from '../../../../../../common/api-request';
import {
  getFormBuilderDetailReturnAction,
  getFormBuilderDetailErrorAction,
  saveFormBuilderConfigurationErrorAction,
  saveFormBuilderConfigurationReturnAction,
  publishFormBuilderConfigurationReturnAction,
  publishFormBuilderConfigurationErrorAction,
  getPublishedFormReturnAction,
} from './action';
import { setStatusCodeAction } from '../../../../../error/action';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IForm } from '../form/interface/form';
import {
  IFormBuilderSaveInput,
  IFormBuilderDeleteFieldInput,
  IFormBuilderPublishInput,
} from './interface/input/form-builder-save';
import { SanitizeFormBuilder } from '../../../../../../common/utils/sanitize-form-builder';
import { IPublishedFormInput } from './interface/input/published-form-get';
import { SanitizeField } from '../../../../../../common/utils/sanitize-field';
export function* getFormBuilderDetail(args: IFormBuilderInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const formData: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms/${formId}?draft=true`,
      {}
    );
    const fieldData: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms/${formId}/fields?draft=true`,
      {}
    );
    const userFields: IAxiosResponse = yield call(
      getRequest,
      `${company}/userFields`,
      {}
    );
    yield put(
      getFormBuilderDetailReturnAction({
        formDetail: formData.data.data as IForm,
        formBuilderField: SanitizeField.structureIncomingField(
          fieldData.data.data
        ) as ICustomFieldDetail[],
        userCustomField: userFields.data.data,
      })
    );
  } catch (e) {
    const err = e as any;
    yield put(setStatusCodeAction(e));
    yield put(
      getFormBuilderDetailErrorAction(JSON.stringify(err.response.data.message))
    );
  }
}

export function* saveFormBuilderConfiguration(args: IFormBuilderSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const formDetail = args.formDetail;

    formDetail.fieldsLayout = SanitizeFormBuilder.outgoingSectionFieldLayout(
      args.data
    );
    formDetail.cover =
      formDetail.cover instanceof Object ? formDetail.cover.id : undefined;

    const customFields = SanitizeFormBuilder.outgoingCustomField(args.data);
    const customFieldReg: IAxiosResponse = yield call(
      postRequest,
      `${company}/forms/${formId}/fields/batch`,
      SanitizeField.structureOutgoingFields(
        customFields as ICustomFieldDetail[]
      )
    );
    const formDataReq: IAxiosResponse = yield call(
      putRequest,
      `${company}/forms/${formId}`,
      formDetail
    );
    if (
      args.updateConfigOfFieldsFromModule &&
      args.deletedExistingField &&
      args.deletedExistingField.length > 0 &&
      formDataReq.data.success &&
      customFieldReg.data.success
    ) {
      args.updateConfigOfFieldsFromModule(args.deletedExistingField);
    }
    yield put(
      saveFormBuilderConfigurationReturnAction({
        formDetail: formDataReq.data.data as IForm,
        formBuilderField: SanitizeField.structureIncomingField(
          customFieldReg.data.data
        ) as ICustomFieldDetail[],
        saveFormBuilderType: args.saveType,
      })
    );
  } catch (e) {
    const err = e as any;
    yield put(setStatusCodeAction(e));
    yield put(
      saveFormBuilderConfigurationErrorAction(
        JSON.stringify(err.response.data.message)
      )
    );
  }
}

export function* deleteFormBuilderField(args: IFormBuilderDeleteFieldInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const fieldId = args.fieldId;
    yield call(
      deleteRequest,
      `${company}/forms/${formId}/fields/${fieldId}`,
      {}
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* publishFormBuilderConfiguration(
  args: IFormBuilderPublishInput
) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(
      postRequest,
      `${company}/forms/${formId}/publish?force=false`,
      null
    );
    yield put(publishFormBuilderConfigurationReturnAction());
  } catch (e) {
    const err = e as any;
    yield put(setStatusCodeAction(e));
    yield put(
      publishFormBuilderConfigurationErrorAction(
        JSON.stringify(err.response.data.message)
      )
    );
  }
}

export function* getPublishedForm(args: IPublishedFormInput) {
  try {
    const company = args.company;
    const formData: IAxiosResponse = yield call(
      getRequest,
      `${company}/forms?draft=false&limit=0`,
      {}
    );
    const datatable: IAxiosResponse = yield call(
      getRequest,
      `${company}/databases`,
      {}
    );
    yield put(
      getPublishedFormReturnAction({
        publishedForm: formData.data.data,
        datatable: datatable.data.data,
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* formBuilderSagas() {
  yield takeLatest(GET_FORM_BUILDER_DETAIL, getFormBuilderDetail);
  yield takeLatest(
    SAVE_FORM_BUILDER_CONFIGURATION,
    saveFormBuilderConfiguration
  );
  yield takeLatest(DELETE_FORM_BUILDER_FIELD, deleteFormBuilderField);
  yield takeLatest(
    PUBLISH_FORM_BUILDER_CONFIGURATION,
    publishFormBuilderConfiguration
  );
  yield takeLatest(GET_PUBLISHED_FORM_LIST, getPublishedForm);
}

// All sagas to be loaded
export default formBuilderSagas;

import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { PortalBPModuleRegistry } from './module-registry';

interface IMatch {
  path: string;
  params: {
    submodule: string;
    thirdLevelRoute?: string;
  }
}

interface IBusinessProcessModule {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PortalBPModule: React.FC<IBusinessProcessModule> = (props) => {
  const match: IMatch = useRouteMatch();

  return (
    <Route path={`${match.path}`}>
      <PortalBPModuleRegistry.Render
        type={match.params.thirdLevelRoute || ''}
        hasPageChangeRef={props.hasPageChangeRef}
      />
    </Route>
  );
}

export default PortalBPModule;

import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import AutomationList from './list';
import AutomationForm from './form';
interface IAutomationComponentProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const AutomationComponent: React.FC<IAutomationComponentProps> = (props) => {
  const match: {
    params: {
      formId: string,
      submodule: string,
      thirdLevelRoute: string,
    },
    path: string
  } = useRouteMatch();
  return (<>
    <Route path={`${match.path}`}>
      <AutomationList hasPageChangeRef={props.hasPageChangeRef} />
    </Route>
    <Route exact path={`${match.path}/:businessRulesId`}>
      <AutomationForm />
    </Route>
    <Route exact path={`${match.path}/:businessRulesId/:editType`}>
      <AutomationForm />
    </Route>
  </>)
}

export default AutomationComponent;
import { ConditionType } from './index';
import { IutilProps } from '../..';
import { IAutomationComponent } from '../../../../interface/component-list';
import { ActionType } from '../action';

export class IfElseBlock {
  static getOptions = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    if (selectedComponent?.config?.conditionList) {
      selectedComponent?.config?.conditionList.forEach((condition: IAutomationComponent) => {
        ConditionType.browse(condition, utils);
      });
    }
    if (selectedComponent?.config?.actionList) {
      selectedComponent?.config?.actionList.forEach((action: IAutomationComponent) => {
        ActionType.browse(action, utils);
      });
    }
    if (selectedComponent?.config?.elseConditionList) {
      selectedComponent?.config?.elseConditionList.forEach((component: { actionList: IAutomationComponent[], conditionList: IAutomationComponent[] }) => {
        if (component?.conditionList) {
          component?.conditionList.forEach((condition: IAutomationComponent) => {
            ConditionType.browse(condition, utils);
          });
        }
        if (component?.actionList) {
          component?.actionList.forEach((action: IAutomationComponent) => {
            ActionType.browse(action, utils);
          });
        }
      });
    }
  }
}
import { FieldElement } from '../field-element';

export abstract class PropType<TypeInComponent = any, TypeInRef = any> {
  processValueFromRef?(value: TypeInRef): TypeInComponent;
  processValueToRef?(newValue: TypeInComponent): TypeInRef;
  processValidation?(value: TypeInRef, field: FieldElement, validationType: TValidationType): void;
}

export type GetTypeInComponent<T> = T extends PropType<infer R, any> ? R : any;

export enum TValidationType {
  onBlur = 'onBlur',
  save = 'save',
}
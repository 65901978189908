import React from 'react';

interface IFieldLayoutTab {
  setActiveTab(tab: "web" | "mobile"): void;
  activeTab: 'web' | 'mobile';
}

const FieldLayoutTab: React.FC<IFieldLayoutTab> = (props) => {
  return <div className="field-layout-tab-container">
    <span className='field-layout-line' />
    <div className='field-layout-tab-list'>
      <div className={`field-layout-tab ${props.activeTab === 'web' ? 'selected' : ''}`}
        onClick={() => props.setActiveTab('web')}>
        <span className='tab-label'>WEB</span>
        {props.activeTab === 'web' && < span className='tab-underline' />}
      </div>
      <div className={`field-layout-tab ${props.activeTab === 'mobile' ? 'selected' : ''}`}
        onClick={() => props.setActiveTab('mobile')}>
        <span className='tab-label'>MOBILE</span>
        {props.activeTab === 'mobile' && <span className='tab-underline' />}
      </div>
    </div>
  </div>
}

export default FieldLayoutTab;

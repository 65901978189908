import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import FormModal from './form-modal';
import { Segment, Loader, Dimmer } from 'semantic-ui-react-bpm';
import Filter from './filter';
import TableList from './list';
import { IRootState } from '../../../../../../reducers';
import { deleteBusinessProcessAction, confirmDeleteBusinessProcessAction, clearDeleteBusinessProcessMessage, cancelDeleteBusinessProcessAction } from './action';
import { ConfirmationMessage } from '../../../../../../common/general/confirmation-modal';
import ActionMessage from '../../../../../../common/general/action-message';
import { businessProcessListActionMessage } from './constant';
import { useSetSubRoute } from './hooks/use-set-sub-route';
import BackupMoodal from './backup';
import { IBusinessProcessRender } from '../module-registry';
import TableStatusTab from '../../../../../../common/utils/table-status-tab';

class Form {

  name = 'form';

  render: React.FC<IBusinessProcessRender> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [statusFilter, setStatusFilter] = useState<'active' | 'inactive' | 'all'>('active');

    useSetSubRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'business-process',
      currentRoute: 'business-process'
    });

    const match = useRouteMatch();
    const {
      isOpenDeleteConfirmationModal,
      confirmDeletetionLoading,
      confirmDeletionErrorMessage,
      forDeleteFormId,
      formLoading,
      forDeleteFormName
    } = useSelector((state: IRootState) => state.businessProcess);
    const { company } = useSelector((state: any) => state.auth);
    const { isOpenAdminChatbox } = useSelector((state: any) => state.main);

    const openDeleteConfirmationModal = (formId: string, formName: string) => {
      dispatch(deleteBusinessProcessAction({ formId: formId, formName: formName }));
    }

    const cancelDeleteBusinessProcess = () => {
      dispatch(cancelDeleteBusinessProcessAction());
    }

    const confirmDeleteBusinessProcess = () => {
      dispatch(confirmDeleteBusinessProcessAction({ formId: forDeleteFormId, company }));
      history.push(`/admin/business-process/form`);
    }

    return (
      <>
        {formLoading &&
          <Dimmer active inverted >
            <Loader active content='Loading' />
          </Dimmer >
        }
        <ActionMessage messages={businessProcessListActionMessage} />
        <Segment className='borderless business-process-container'>
          <TableStatusTab
            statusTab={statusFilter}
            setStatusTab={setStatusFilter}
          />
          <Filter
            isOpenChatbox={isOpenAdminChatbox}
            allowedAccessAuditLogs={props.allowedAccessAuditLogs ? props.allowedAccessAuditLogs : false}
          />
          <TableList statusFilter={statusFilter} />
          <Suspense fallback={<Loader active inline />}>
            <Route path={`${match.path}/:formId`} exact>
              <FormModal openDeleteConfirmationModal={openDeleteConfirmationModal} />
            </Route>
            <Route path={`${match.path}/:formId/:fileAction`} exact>
              <BackupMoodal />
            </Route>
            <ConfirmationMessage
              clearErrorMessage={() => dispatch(clearDeleteBusinessProcessMessage())}
              errorMessage={confirmDeletionErrorMessage}
              confirmButtonName={'Confirm'}
              confirmButtonColor={'green'}
              cancelButtonColor={'grey'}
              close={cancelDeleteBusinessProcess}
              open={isOpenDeleteConfirmationModal}
              confirm={confirmDeleteBusinessProcess}
              cancel={cancelDeleteBusinessProcess}
              headerText="Confirmation"
              detailText={`The business process '${forDeleteFormName}' and all its documents will be deleted. Do you still want to proceed?`}
              loading={confirmDeletetionLoading}
              type='warning'
            />
          </Suspense>
        </Segment>
      </>
    );
  }
}

export default Form;

import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const GET_FORM_FIELDS = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_FORM_FIELDS';
export const GET_FORM_FIELDS_RETURN = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_FORM_FIELDS_RETURN';
export const GET_FORM_FIELDS_ERROR = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_FORM_FIELDS_ERROR';
export const GET_DUPLICATE_CHECKER = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_DUPLICATE_CHECKER';
export const GET_DUPLICATE_CHECKER_RETURN = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_DUPLICATE_CHECKER_RETURN';
export const GET_DUPLICATE_CHECKER_ERROR = 'app/app/admin-module/business-process/configuration/duplicate-checker/GET_DUPLICATE_CHECKER_ERROR';
export const SAVE_DUPLICATE_CHECKER = 'app/app/admin-module/business-process/configuration/duplicate-checker/SAVE_DUPLICATE_CHECKER';
export const SAVE_DUPLICATE_CHECKER_RETURN = 'app/app/admin-module/business-process/configuration/duplicate-checker/SAVE_DUPLICATE_CHECKER_RETURN';
export const SAVE_DUPLICATE_CHECKER_ERROR = 'app/app/admin-module/business-process/configuration/duplicate-checker/SAVE_DUPLICATE_CHECKER_ERROR';
export const PUBLISH_DUPLICATE_CHECKER = 'app/app/admin-module/business-process/configuration/duplicate-checker/PUBLISH_DUPLICATE_CHECKER';
export const PUBLISH_DUPLICATE_CHECKER_RETURN = 'app/app/admin-module/business-process/configuration/duplicate-checker/PUBLISH_DUPLICATE_CHECKER_RETURN';
export const PUBLISH_DUPLICATE_CHECKER_ERROR = 'app/app/admin-module/business-process/configuration/duplicate-checker/PUBLISH_DUPLICATE_CHECKER_ERROR';
export const CLEAR_SAVE_DUPLICATE_CHECKER_SUCCESS = 'app/app/admin-module/business-process/configuration/duplicate-checker/CLEAR_SAVE_DUPLICATE_CHECKER_SUCCESS';
export const CLEAR_SAVE_DUPLICATE_CHECKER_ERROR = 'app/app/admin-module/business-process/configuration/duplicate-checker/CLEAR_SAVE_DUPLICATE_CHECKER_ERROR';

export const duplicateCheckerActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_DUPLICATE_CHECKER_SUCCESS,
    reducerContainer: 'duplicateChecker',
    reducerItem: 'saveDuplicateCheckerMessage',
    type: 'success',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_DUPLICATE_CHECKER_ERROR,
    reducerContainer: 'duplicateChecker',
    reducerItem: 'saveDuplicateCheckerError',
    type: 'negative',
    hasCloseIcon: true
  }
]
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThirdLevelRouteAction, currentRouteAction } from '../../../../../main/action';
import PermissionContainer from './permission';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import ActionMessage from '../../../../../../common/general/action-message';
import { permissionActionMessage } from './constant';
import changeRoute from '../../../../../../common/utils/change-menu';
import { IRootState } from '../../../../../../reducers';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../common/icons';
import ToolTip from '../../../../../../common/general/tooltip';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';

interface IPermissionProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class Permission {

  name = 'permissions';

  render: React.FC<IPermissionProps> = (props) => {
    const { hasPageChange } = useSelector((state: IRootState) => state.main);
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setThirdLevelRouteAction({
        mainRoute: 'admin',
        subRoute: 'business-process',
        thirdLevelRoute: this.name
      }))
      dispatch(currentRouteAction(this.name))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (<>
      <ToolTip
        popupMessage='Close'
        popupTriggerComponent={
          <Image id='business-process-close-icon' src={icons.blue.closeCircle} onClick={() => {
            changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
          }} />
        }
        position='right center'
      />
      <div style={{ marginTop: '15px', marginLeft: '5px', marginRight: '25px' }}>
        <ActionMessage messages={permissionActionMessage} />
      </div>
      <div className='permission-main-container'>
        <Route path={`${match.path}/:formId`}>
          <PermissionContainer hasPageChangeRef={props.hasPageChangeRef} />
        </Route>
      </div>
    </>);
  }
}

export default Permission;

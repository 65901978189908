import { IutilProps } from '..';
import { IAutomationComponent } from '../../../interface/component-list';
import { ActionType } from './action';
import { ConditionType } from './condition';

export class ManageFieldOptions {
  static browseSelectedComponent = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    switch (selectedComponent.type) {
      case 'trigger':
        break;
      case 'condition':
        ConditionType.browse(selectedComponent, utils);
        break;
      case 'action':
        ActionType.browse(selectedComponent, utils);
        break;
    }
  }


}
import {
  FORGOT_PASSWORD_MODAL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_RETURN,
  FORGOT_PASSWORD_RETURN_ERROR,
} from '../constant';
import { IForgotPasswordShowModal } from '../interface/input/forgot-password-show-modal'
import { IForgotPasswordDetail } from '../interface/input/forgot-password';
import { IForgotPasswordResponse } from '../interface/response/forgot-password';


export const triggerForgotPasswordModalAction = (payload: IForgotPasswordShowModal) => {
  return { type: FORGOT_PASSWORD_MODAL, ...payload };
};

export const submitForgotPasswordAction = (payload: IForgotPasswordDetail) => {
  return { type: FORGOT_PASSWORD, ...payload };
};

export const submitForgotPasswordReturnAction = (payload: IForgotPasswordResponse) => {
  return { type: FORGOT_PASSWORD_RETURN, ...payload };
};

export const submitForgotPasswordErrorAction = (payload: IForgotPasswordResponse) => {
  return { type: FORGOT_PASSWORD_RETURN_ERROR, ...payload };
};


import { IFormBuilderSection } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';

class FieldTypeToDropBox {

  name = 'FieldTypeToDropbox';

  updateSection = (sectionList: IFormBuilderSection[], field: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    const newSectionList = [...sectionList];
    return newSectionList.map((section: IFormBuilderSection) => {
      if (section.sectionId === sectionUpdateConfig.sectionId) {
        section.fields.push([field])
      }

      return section;
    });
  }
}

export default FieldTypeToDropBox;
// import { fromJS } from 'immutable'

import { IFormDocument } from '../report/interface/form-document';
import { GET_TASKS_LIST, GET_TASKS_LIST_RETURN, GET_TASKS_LIST_ERROR, TRIGGER_BULK_ACTION, TRIGGER_BULK_ACTION_ERROR, TRIGGER_BULK_ACTION_RETURN, CLEAR_TASKS_LIST_ERROR, CLEAR_TASKS_BULK_ACTION_SUCCESS, SET_TASK_LIST_VALIDATION_ERROR, CLEAR_TASK_LIST_VALIDATION_ERROR, UNDO_TASK_ACTION, GET_ACTION_USER_LIST, GET_ACTION_USER_LIST_ERROR, GET_ACTION_USER_LIST_RETURN, GET_BP_FIELD_PERMISSIONS, GET_BP_FIELD_PERMISSIONS_ERROR, GET_BP_FIELD_PERMISSIONS_RETURN, SAVE_TASK_DATA_RETURN, SAVE_TASK_DATA_ERROR, GET_TASK_CUSTOM_LAYOUT, GET_TASK_CUSTOM_LAYOUT_ERROR, GET_TASK_CUSTOM_LAYOUT_RETURN, RESET_TASK_LIST, UPDATE_CURRENT_USER_IN_DOCUMENT } from './constant';
import { IFormStatus } from '../../../admin-module/module/business-process/module/workflow/interface/form-status';
import { message } from './message';
import { IIndividualUserInput } from '../form/interface/individual-users';
import { IField } from '../../../admin-module/module/users/interface/field';
import { IFormFieldPermission } from '../../../admin-module/module/business-process/module/permission/interface/field-permission';
import { ITaskAggregate, ITaskCustomLayout } from './interface/task-layout';
import { ISenderDetail } from '../form/interface/sender-info';

export interface ITasksReducer {
  activeTasks: IFormDocument[];
  tasksListLoading: boolean;
  tasksListError: string;
  statusList: { [formId: string]: IFormStatus[] };
  taskBulkActionLoading: boolean;
  taskBulkActionError: string;
  taskBulkActionSuccess: string;
  taskListValidationError: string;
  taskRecordTotalCount: number;
  individualUserList: IIndividualUserInput[];
  bpFieldPermission: IFormFieldPermission[];
  taskLayout: ITaskCustomLayout[];
  defaultFilterFields: IField[];
  taskAggregates: ITaskAggregate[];
  currentUserInDocument: { [docId: string]: ISenderDetail[] };
}

const initialState: ITasksReducer = {
  activeTasks: [] as IFormDocument[],
  tasksListLoading: false,
  tasksListError: '',
  statusList: {},
  taskBulkActionLoading: false,
  taskBulkActionError: '',
  taskBulkActionSuccess: '',
  taskListValidationError: '',
  taskRecordTotalCount: 0,
  individualUserList: [],
  bpFieldPermission: [],
  taskLayout: [],
  defaultFilterFields: [],
  taskAggregates: [],
  currentUserInDocument: {},

}

const tasksReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_TASKS_LIST:
      newState.activeTasks = [];
      newState.taskAggregates = [];
      newState.tasksListLoading = true;
      newState.tasksListError = '';
      return newState;

    case GET_TASKS_LIST_RETURN:
      newState.tasksListLoading = false;
      newState.taskBulkActionLoading = false;
      newState.activeTasks = action.activeTasksList;
      newState.taskRecordTotalCount = action.taskRecordTotalCount;
      newState.taskAggregates = action.taskAggregates;
      return newState;

    case GET_TASKS_LIST_ERROR:
      newState.tasksListLoading = false;
      newState.tasksListError = action.message;
      return newState;

    case CLEAR_TASKS_LIST_ERROR:
      newState.tasksListError = '';
      return newState;

    case TRIGGER_BULK_ACTION:
      newState.taskBulkActionLoading = true;
      return newState;

    case TRIGGER_BULK_ACTION_RETURN:
      newState.activeTasks = action.activeTasksList;
      newState.taskBulkActionSuccess = message.taskBulkActionSuccess;
      newState.taskBulkActionLoading = false;
      return newState;

    case TRIGGER_BULK_ACTION_ERROR:
      newState.taskBulkActionLoading = false;
      newState.tasksListError = action.message;
      return newState;

    case CLEAR_TASKS_BULK_ACTION_SUCCESS:
      newState.taskBulkActionSuccess = '';
      return newState;

    case SET_TASK_LIST_VALIDATION_ERROR:
      newState.taskListValidationError = action.message;
      return newState;

    case CLEAR_TASK_LIST_VALIDATION_ERROR:
      newState.taskListValidationError = '';
      return newState;

    case UNDO_TASK_ACTION:
      newState.taskBulkActionLoading = true;
      return newState;

    case GET_ACTION_USER_LIST:
      newState.taskBulkActionLoading = true;
      return newState;

    case GET_ACTION_USER_LIST_RETURN:
      newState.individualUserList = action.individualUserList;
      newState.taskBulkActionLoading = false;
      return newState;

    case GET_ACTION_USER_LIST_ERROR:
      newState.taskListValidationError = action.message;
      return newState;

    case GET_BP_FIELD_PERMISSIONS:
      newState.tasksListError = '';
      newState.bpFieldPermission = [];
      newState.defaultFilterFields = [];
      return newState;

    case GET_BP_FIELD_PERMISSIONS_RETURN:
      newState.tasksListError = '';
      newState.bpFieldPermission = action.bpFieldPermission;
      newState.defaultFilterFields = action.defaultFilterFields;
      return newState;

    case GET_BP_FIELD_PERMISSIONS_ERROR:
      newState.tasksListLoading = false;
      newState.tasksListError = action.message;
      return newState;

    case SAVE_TASK_DATA_ERROR:
      newState.tasksListError = action.message;
      return newState;

    case SAVE_TASK_DATA_RETURN:
      let updateTaskList = [...state.activeTasks];
      newState.activeTasks = updateTaskList.map(task => {
        if (action.data.id === task.id) {
          return action.data;
        }
        return task
      });
      return newState;

    case GET_TASK_CUSTOM_LAYOUT:
      newState.taskLayout = [];
      return newState;

    case GET_TASK_CUSTOM_LAYOUT_RETURN:
      newState.taskLayout = action.taskLayout;
      return newState;

    case GET_TASK_CUSTOM_LAYOUT_ERROR:
      newState.tasksListError = action.message;
      return newState;

    case RESET_TASK_LIST:
      newState.activeTasks = [];
      newState.taskAggregates = [];
      return newState;

    case UPDATE_CURRENT_USER_IN_DOCUMENT:
      newState.currentUserInDocument = action.currentUserInDocument;
      return newState;

    default:
      return state;
  }
}

export default tasksReducer;
import React, { useState, useEffect } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { removeInput } from '../../../utils/remove-input';
import { v4 as uuid } from 'uuid';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { IFieldAccessType, IInputConfig } from '../../../../../../component/admin-module/module/users/interface/field';
import { addThousandSeparator } from '../../../../../utils/add-thousand-separator';
import { setFocusedElement, getFocusedElement, removeFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { NumberProps } from '../../../service/type/number';
import { validateInput } from './utils/validate-input';
import { useInitialLoad } from './hook/use-initial-load';
import { renderIcon } from './utils/render-icon';
import { renderError } from './utils/render-error';

const NumberArrayType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();
  const [refresher, setRefresher] = useState('');
  const [validateTriggered, setValidateTriggered] = useState(false)
  const [multilineCount, setMultilineCount] = useState(1);

  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new NumberProps());

  const setProperty = () => {
    for (let row: number = 0; row < multilineCount; row++) {
      const value = elementProperties.getCurrent(row).value;
      elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: addThousandSeparator(value, props.inputConfig as IInputConfig) }, row);
    }
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      setRefresher(uuid());
    }
  }

  const onBlur = (event: any, row: number, id: string) => {
    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: event.target.value }, row)
    validateInput({
      setRefresher: setRefresher,
      name: name,
      validationSchemaProps: {
        ...props.validationSchema,
        isRequired: props.accessType === IFieldAccessType.Required,
      },
      multiline: props.multiline || false,
      multilineCount: multilineCount,
      forwardedRef: props.forwardedRef,
      inputConfig: props.inputConfig,
      setValidateTriggered: setValidateTriggered,
      setProperty: setProperty,
      labelProps: props.label,
      requiredProps: props.required || false,
      triggerFilter: props.triggerFilter,
      triggerFieldReload: props.triggerFieldReload,
      reloadOnChange: props.reloadOnChange,
      accessType: props.accessType,
      valueFieldClass: elementProperties
    })

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  const setRef = (curRef: any, row: number, id: string): any => {
    if (curRef) {
      curRef.fieldType = 'number';
      if (focusedElement) {
        if (id === focusedElement) {
          curRef.focus();
        }
      }
      if (elementProperties.isSet(row)) {
        curRef.error = elementProperties.getError(row);
        setProperty();
        curRef.value = elementProperties.getCurrent(row).value;
      }
      elementProperties.setCurrent({ ...elementProperties.getCurrent(row), fieldType: 'number' }, row);
      return curRef;
    }
  }

  const onChange = (event: any, row: number) => {
    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: event.target.value }, row)
    if (props.triggerFilter) props.triggerFilter('');
    if (props.hasPageChangeRef) props.hasPageChangeRef.current.hasChange = true;
  }

  const onFocus = (row: number) => {
    const value = elementProperties.getCurrent(row).value;
    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: value.replace(/,/g, '') }, row);
  }

  const removeRow = (row: number, count: number) => {
    return (row > 0 || (row === 0 && row !== (count - 1))) && <i className="minus circular icon multiline-minus-icon"
      onClick={() => {
        removeInput({
          forwardedRef: props.forwardedRef,
          name: name,
          multilineCount: multilineCount,
          setMultilineCount: setMultilineCount
        }, row);

        if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
          props.triggerFieldReload(props.reloadOnChange);
        }
      }} />
  }

  const addRow = (row: number, count: number) => {
    return row === (count - 1) && props.multiline &&
      <i className={`plus circular icon multiline-icon ${row === 0 ? '' : 'more'}`} onClick={() => addNewInput()} />
  }

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `number-${name}-${row}`;
      field.push(
        <React.Fragment key={id}>
          {row > 0 && <span className='multiline-gap' />}

          <div className='multi-line-container'>
            <div className={`field-input ui input multi-line ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties,
            }, row)}`}>
              <input type="text"
                id={id}
                className={props.hasIconInside ? 'with-icon' : ''}
                onBlur={(event) => onBlur(event, row, id)}
                onChange={(event) => onChange(event, row)}
                onFocus={() => onFocus(row)}
                name={name}
                disabled={props.accessType === IFieldAccessType.Readonly}
                ref={(curRef: any) => setRef(curRef, row, id)}
                onClick={() => { focusInput(id) }}
                style={{ textAlign: props.inputConfig.config.alignment || 'left' }}
              />
              {renderIcon(props.hasIconInside || false, props.inputConfig.config.percent ? true : false)}
              {renderError({ showErrorMessage: props.showErrorMessage, row, elementProperties })}
            </div>
            <span>
              {removeRow(row, count)}
              {addRow(row, count)}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return field;
  }

  const addNewInput = () => {
    const newLen = multilineCount + 1;
    setMultilineCount(newLen);
    props.forwardedRef.current[name][multilineCount] = document.createElement('input');
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      props.forwardedRef.current[name][multilineCount].value = defaultValue[0] !== '' ? defaultValue[0] : '';
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (mobile) setFocusedElement(`number-${name}-${multilineCount}`);
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: defaultValueArray[row] !== '' ? defaultValueArray[row] : '' }, row);
      }

    }
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement) {
      validateInput({
        setRefresher: setRefresher,
        name: name,
        validationSchemaProps: props.validationSchema,
        multiline: props.multiline || false,
        multilineCount: multilineCount,
        forwardedRef: props.forwardedRef,
        inputConfig: props.inputConfig,
        setValidateTriggered: setValidateTriggered,
        setProperty: setProperty,
        labelProps: props.label,
        requiredProps: props.accessType === IFieldAccessType.Required || false,
        triggerFilter: props.triggerFilter,
        accessType: props.accessType,
        valueFieldClass: elementProperties
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateTriggered,
    props.defaultValueElement,
    props.label,
    props.validationSchema,
    props.forwardedRef,
    name,
    props.inputConfig,
    props.required,
    props.multiline,
    multilineCount
  ])

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateInput({
        setRefresher: setRefresher,
        name: name,
        validationSchemaProps: { ...props.validationSchema, minimum: 1, isRequired: true },
        multiline: props.multiline || false,
        multilineCount: multilineCount,
        forwardedRef: props.forwardedRef,
        inputConfig: props.inputConfig,
        setProperty: setProperty,
        labelProps: props.label,
        requiredProps: true,
        accessType: props.accessType,
        valueFieldClass: elementProperties
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount]);

  useInitialLoad({
    setMultilineCount,
    isAddRecord: props.isAddRecord,
    setDefaultValue,
    setRefresher,
    name,
    validationSchema: props.validationSchema,
    multiline: true,
    multilineCount: multilineCount,
    forwardedRef: props.forwardedRef,
    inputConfig: props.inputConfig,
    setProperty: setProperty,
    label: props.label,
    accessType: props.accessType,
    elementProperties
  })

  useEffect(() => {
    if (props.isClearValue && elementProperties.current) {
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: '' }, row)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span id={refresher} className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>
            {props.label}
          </label>
          {
            (props.hint && !props.tableId) &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }
  </>
}

export default React.memo(NumberArrayType);
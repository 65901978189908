import React from 'react';
import { FieldConstruct } from '../../../../../../common/field/field-construct';
import { IField, IFieldAccessType } from '../../../users/interface/field';
import moment from 'moment-timezone';
import GridDTRecordsTemplate from '../records';
import { getValue } from '@syncfusion/ej2-base';
import GridDTFilterTemplate from '../filter';
import { IDatatableRecord } from '../../../../../../common/custom-field-config/interface/datatable-record';
import { EditMode } from '@syncfusion/ej2-react-grids';
import GridDTRecordsDataTemplate from '../data';

export const datatableRecordTemplate = (data: any): any => {
  if (!data) return null;
  return <GridDTRecordsDataTemplate key={data.column.field} data={data} />
}

export const datatableRecordEditTemplate = (data: any) => {
  if (!data) return null;
  return <GridDTRecordsTemplate key={data.column.field} data={data} />

}

export const IDEditTemplate = (data: any) => {
  return <input
    id="seq"
    className='e-input'
    name="seq"
    type="text"
    disabled={true}
    value={getValue('seq', data)}
  />
}

export const IDTemplate = (data: any) => {
  const value = getValue('seq', data);
  return <div>
    {value === 0 ? '' : value}
  </div>
}

export const filterTemplate = (data: any) => {
  if (!data) return null;
  return <GridDTFilterTemplate data={data} />
}

export const pagerTemplate = (data: any) => {
  return <div>Pagination</div>
}

export const parseData = (data: any, fields: IField[], fieldRefs: any, gridEditMode: EditMode) => {
  let fieldData = FieldConstruct.getFieldDataFromRefs(fields, fieldRefs, gridEditMode === 'Batch', 'fetch');
  fields.forEach(field => {
    const type = field.inputConfig && field.inputConfig.type;
    const fieldId = field.id as string;
    data[fieldId] = fieldData[fieldId];
    if (type === 'date') {
      const value = data[fieldId] ? moment(data[fieldId]).format("YYYY-MM-DD") : '';
      data[fieldId] = value ? value : null;
    }
    if (type === 'file') {
      if (fieldData[fieldId] && fieldData[fieldId].new) {
        delete fieldData[fieldId].new;
      }
      data[fieldId] = fieldData[fieldId];
    }
    if (type === 'array') {
      if (fieldData[fieldId] && fieldData[fieldId].length) {
        fieldData[fieldId] = fieldData[fieldId].map((e: any) => {
          if (e.new) {
            delete e.new;
          }
          return e;
        });
      }
      data[fieldId] = fieldData[fieldId];
    }
    if (gridEditMode === 'Normal') {
      if (field.accessType === IFieldAccessType.Readonly) {
        data[fieldId] = undefined;
      }
    }
  });
  return data;
}

export const parsePreviousData = (data: any, fields: IField[]) => {
  fields.forEach(field => {
    const type = field.inputConfig && field.inputConfig.type;
    const fieldId = field.id as string;
    if (type === 'enum' && data[fieldId] && Object.keys(data[fieldId])
      && data[fieldId].hasOwnProperty('id')) {
      data[fieldId] = data[fieldId].id;
    }
  });
  return data;
}

export const parseFilterData = (dataTableFields: IField[], filterRefs: any) => {
  let newFieldList = [...dataTableFields];
  newFieldList = newFieldList.map((field: IField) => {
    const e = { ...field };
    e.accessType = IFieldAccessType.ReadWrite;
    e.id = `fields.${e.id}`;
    return e;
  })
  let fields = FieldConstruct.getFieldDataFromRefs(newFieldList, filterRefs);
  const filters: { [id: string]: any } = {};
  newFieldList.forEach((field: IField) => {
    const id = field.id || '';
    if (fields[id]) {
      if (field.inputConfig && field.inputConfig.type === 'enum' && !field.inputConfig.config.multiselect) {
        if (fields[id] === 'all') {
          filters[id] = undefined;
        } else {
          filters[id] = [fields[id]];
        }
      } else {
        filters[id] = fields[id];
      }
    }
  });
  return filters;
}


export const readonlyFieldTemplates = (data: any) => {
  return <input
    className='e-input'
    name={data.column.field}
    type="text"
    disabled={true}
    value={getValue(data.column.field, data)}
  />
}

export const getEnumItemsFromRecords = (recordsList: IDatatableRecord[], fieldList: IField[]) => {
  const enumItemsFromRecords: { [fieldId: string]: any[] } = {};
  recordsList.forEach(({ fields }) => {
    if (fields) {
      Object.keys(fields).forEach(fieldId => {
        const field: any = fieldList.find(e => e.id === fieldId);
        const enumItems = (enumItemsFromRecords[fieldId] || []);
        if (field && field.inputConfig.type === 'enum' && fields[fieldId]) {
          if (!enumItemsFromRecords[fieldId]) {
            enumItemsFromRecords[fieldId] = [];
          }
          if (field.inputConfig.config.multiselect && fields[fieldId] instanceof Array) {
            fields[fieldId].forEach((data: any) => {
              const exists = enumItems.find(e => e.id === data.id);
              if (!exists) {
                enumItemsFromRecords[fieldId].push(data);
              }
            });
          } else {
            const exists = enumItems.find(e => e.id === fields[fieldId].id);
            if (!exists) {
              enumItemsFromRecords[fieldId].push(fields[fieldId]);
            }
          }
        }
      });
    }
  });
  return enumItemsFromRecords;
}
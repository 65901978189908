import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import ParagraphArrayType from '../type/string/paragraph-array';
import ParagraphType from '../type/string/paragraph';

const ParagraphElement: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <ParagraphArrayType {...props} />;
  } else {
    return <ParagraphType {...props} />
  }
}

export default ParagraphElement;
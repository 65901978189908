import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';

export class FieldValidator implements ValidationType {

  name = 'field-validator';

  validate(field: FieldElement, value: string, actionType?: string) {
    if (
      (
        (value === '' || value === 'undefined' || value === 'Invalid date')
        && (field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required && actionType === 'save'))
      )
      || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden) {
      return [];
    }
    // add min length if required
    field.validationSchema.minLength = field.validationSchema.minLength || 1;
    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: value })

    return validate.errors || [];
  }

}


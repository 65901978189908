import React, { useEffect, useRef, useState } from 'react';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { StringProps } from '../../../service/type/string';
import { elementStatus } from '../../../utils/element-status';
import { AcceptedProps } from '../../interface/accepted-props';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import parsePhoneNumber, { getExampleNumber, CountryCode } from 'libphonenumber-js';
import {
  setFocusedElement,
  getFocusedElement,
  removeFocusedElement,
  setTableFocusedElement
} from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { TValidationType } from '../../../service/field-properties.handler';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { removeInput } from '../../../utils/remove-input';

const examples = require('libphonenumber-js/examples.mobile.json');

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const PhoneArrayType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();

  const name = props.getFieldId();

  const [refresher, setRefresher] = useState(0);
  const [validateTriggered, setValidateTriggered] = useState(false);
  const [multilineCount, setMultilineCount] = useState(1);

  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new StringProps());
  const elementId = props.tableColumnRowIndex ? `phone-${name}-${props.tableColumnRowIndex}` : `phone-${name}`;

  let onTypeTimeout = setTimeout(() => { }, 1000);
  let maxPhoneLengthRef: any = useRef({});

  const setValidationSchema = () => {
    const schema: any = {};
    for (let i: number = 0; i < multilineCount; i++) {
      schema[i] = {
        ...props.validationSchema,
        minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
        anyOf: { ...props.validationSchema as any }.anyOf.map((e: any) => {
          if (e.hasOwnProperty('items')) {
            return {
              type: 'string',
              minLength: maxPhoneLengthRef.current[i] ? maxPhoneLengthRef.current[i] : 0
            }
          }
          return e;
        })
      } as any;
    }
    return schema;
  }

  const validateInput = () => {
    elementProperties.validate({
      id: name,
      label: props.label,
      inputConfig: props.inputConfig,
      validationSchema: setValidationSchema(),
      multiline: props.multiline,
      accessType: props.accessType,
    } as any, TValidationType.onBlur, true);

    setRefresher(refresher + 1);
    setValidateTriggered(true)
    if (props.triggerFilter) props.triggerFilter('');

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: defaultValueArray[row] || '' }, row)
      }
    }
  }

  const addNewInput = () => {
    const row = multilineCount;
    const newLen = multilineCount + 1;
    setMultilineCount(newLen);
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: defaultValue[0] || '' }, row)
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (mobile) setFocusedElement(`url-${name}-${row}`);
  }

  const getValue = (row: number) => {
    return elementProperties.getCurrent(row).value;
  }

  const focusInput = (id: string, multiline: boolean) => {
    if (mobile) {
      setFocusedElement(id);
      if (multiline) {
        setRefresher(refresher + 1);
      }
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const handleOnBlur = (event: any, row: number, id: string) => {
    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: event.target.value }, row)
    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    validateInput();
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  const handleOnChange = (value: string, data: CountryData, row: number) => {
    const countryCode = data.countryCode.toUpperCase() as CountryCode;
    const exampleNumber = getExampleNumber(countryCode, examples);
    if (exampleNumber) {
      const number = exampleNumber.number.toString();
      maxPhoneLengthRef.current[row] = number.length;
    }
    elementProperties.setCurrent({ ...elementProperties.getCurrent(row), value: value }, row);
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    setRefresher(refresher + 1);
  }

  const setPhoneMinLength = (count: number) => {
    for (let i: number = 0; i < count; i++) {
      const phoneNumber = parsePhoneNumber(getValue(i));
      if (phoneNumber && phoneNumber.country) {
        const exampleNumber = getExampleNumber(phoneNumber.country, examples);
        if (exampleNumber) {
          const number = exampleNumber.number.toString();
          maxPhoneLengthRef.current[i] = number.length;
        }
      }
    }
  }

  useEffect(() => {
    if (validateTriggered && props.defaultValueElement && props.isAddRecord) {
      const validationSchema = {
        type: 'object',
        required: [`${name}`],
        properties: {
          [name]: { minLength: props.accessType === IFieldAccessType.Required ? 1 : 0, ...props.validationSchema }
        },
      };
      elementProperties.validate({ ...props, validationSchema }, TValidationType.onBlur);
      setRefresher(refresher + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateTriggered, props.defaultValueElement, props.accessType, name,
    props.forwardedRef, props.validationSchema, props.multiline, multilineCount, props.isAddRecord
  ])

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount])

  useEffect(() => {
    if (props.multiline) {
      const len = elementProperties.current.length;
      setMultilineCount(len === 0 ? 1 : len);
      setPhoneMinLength(len === 0 ? 1 : len);
    }
    if (props.isAddRecord) {
      setDefaultValue();
    }
    if (props.accessType === IFieldAccessType.Required) {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `url-${name}-${row}`;
      if (!maxPhoneLengthRef.current) {
        maxPhoneLengthRef.current = {};
      }
      if (!maxPhoneLengthRef.current[row]) {
        maxPhoneLengthRef.current[row] = null;
      }
      field.push(
        <React.Fragment key={id}>
          {row > 0 && <span className='multiline-gap' />}
          <div className='multi-line-container'>
            <div className={`ui labeled input multi-line ${elementStatus({
              forwardedRef: props.forwardedRef,
              name: name,
              multiline: props.multiline || false,
              required: props.accessType === IFieldAccessType.Required,
              fieldValueObject: elementProperties
            }, row)}`} >
              <ReactPhoneInput
                key={id}
                inputProps={{
                  name: "phone",
                  autoFocus: props.isFocus || focusedElement === id,
                  className: 'phone-input-field multi-line',
                  type: 'text',
                  maxLength: maxPhoneLengthRef.current[row],
                  id: elementId,
                  placeholder: props.havePlaceholder && props.placeHolder ? props.placeHolder : ''
                }}
                value={getValue(row)}
                onChange={(value: string, data: CountryData) => handleOnChange(value, data, row)}
                enableSearch
                disableSearchIcon
                onBlur={(event) => handleOnBlur(event, row, id)}
                onClick={() => { focusInput(id, true) }}
                inputStyle={{
                  textAlign: props.inputConfig.config ? props.inputConfig.config.alignment || 'left' : 'left'
                }}
                autoFormat={false}
                disabled={props.accessType === IFieldAccessType.Readonly}
                containerClass={props.accessType}
              />
              {elementProperties.hasError(row) && props.showErrorMessage &&
                elementProperties.getError(row)[0].message.indexOf('Please provide value for') === -1 &&
                <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError(row)[0].message }} />
              }
            </div>
            <span>
              {(row > 0 || (row === 0 && row !== (count - 1))) && <i className="minus circular icon multiline-minus-icon" onClick={() => removeInput({
                forwardedRef: props.forwardedRef,
                name: name,
                multilineCount: multilineCount,
                setMultilineCount: setMultilineCount
              }, row)} />}

              {row === (count - 1) && props.multiline && <i className={`plus circular icon multiline-icon ${row === 0 ? '' : 'more'}`}
                onClick={() => addNewInput()} />}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return field;
  }

  return <>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }
  </>
}

export default React.memo(PhoneArrayType, (prevProps: AcceptedProps, nextProps: AcceptedProps) => {
  return prevProps.forwardedRef.current[prevProps.id].value === nextProps.forwardedRef.current[nextProps.id].value;
});
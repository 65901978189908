import { v4 as uuid } from 'uuid';
import { actionWidget, conditionWidget, triggerOptionList } from '../../component/admin-module/module/business-process/module/automation/module/automation-rules/constant';
import { IAutomation, IAutomationApi, IAutomationApiTypeConfig, IAutomationComponentActionApi, IAutomationComponentApi, IAutomationComponentIfElseApi } from '../../component/admin-module/module/business-process/module/automation/module/automation-rules/interface/automation';
import { IAutomationComponent, IAutomationElseConfig } from '../../component/admin-module/module/business-process/module/automation/module/automation-rules/interface/component-list';
import { IOptionWidget } from '../../component/admin-module/module/business-process/module/automation/module/automation-rules/interface/option-widget';
import { ISenderDetail } from '../../component/document-module/module/form/interface/sender-info';
import { checkIfUUID } from './check-uuid-value';

const actionLoadDocuments: IAutomationComponentApi = {
  component: 'action',
  config: {
    type: 'load-documents',
    config: {}
  }
}

const actionForEach: IAutomationComponentApi = {
  component: 'for-each',
  config: {
    itemKey: 'document',
    arrayKey: 'documents'
  }
}

export class SanitizeAutomation {
  static structureOutgoing = (automation: IAutomation): IAutomationApi => {
    const triggerConfigComponent = automation.component.find((component: IAutomationComponent) => component.type === 'trigger');
    const conditionActionsComponent = automation.component.filter((component: IAutomationComponent) => component.type !== 'trigger');

    if (!triggerConfigComponent) {
      throw new Error('trigger is not defined');
    }
    const triggerConfig = { ...triggerConfigComponent.config };
    delete triggerConfig.trigger;

    const components: IAutomationComponentApi[] = [];

    conditionActionsComponent.forEach((component: IAutomationComponent) => {

      if (component.config.hasOwnProperty('condition')) {
        if (component?.config?.condition?.name === 'if-else-block') {
          components.push(SanitizeAutomation.manageApiIfElseConfig(component))
        } else {
          components.push({
            component: 'condition',
            config: SanitizeAutomation.manageApiCondtionConfig(component),
            uiContext: {
              type: 'condition',
              name: component?.config?.condition?.name
            }
          })
        }
      }
      if (component.config.hasOwnProperty('action')) {
        components.push({
          component: 'action',
          config: SanitizeAutomation.manageApiActionConfig(component),
          uiContext: {
            type: 'action',
            name: component.config.action.name
          }
        });
      }

    });

    const trigger = triggerConfigComponent.config?.trigger?.name;

    if (trigger === 'scheduled') {
      components.unshift(actionForEach);
      components.unshift(actionLoadDocuments);
    }

    const automationApi: IAutomationApi = {
      id: automation.id,
      name: automation.name,
      description: automation.description,
      enabled: automation.enabled,
      config: {
        trigger: {
          type: trigger,
          config: triggerConfig
        },
        components: components
      }
    };

    return automationApi;
  }

  static structureIncomingMultiple = (automationList: IAutomationApi[]): IAutomation[] => {
    return automationList.map((automation: IAutomationApi) => {
      return SanitizeAutomation.structureIncoming(automation);
    });
  }

  static structureIncoming = (automation: IAutomationApi): IAutomation => {
    const tempAutomation: IAutomation = {
      id: automation.id,
      name: automation.name,
      description: automation.description,
      component: [],
      enabled: automation.enabled,
      creator: automation.creator || {} as ISenderDetail,
    };

    const triggerOption = triggerOptionList.find((option: IOptionWidget) => option.name === automation?.config?.trigger?.type);

    tempAutomation.component.push({
      id: uuid(),
      name: automation?.config?.trigger?.type,
      type: 'trigger',
      config: {
        ...automation.config.trigger.config,
        trigger: triggerOption,
      }
    });

    if (automation?.config?.components) {
      let automationConfigComponents = automation?.config?.components;
      automationConfigComponents = automationConfigComponents.filter(e => e.component !== 'for-each');
      automationConfigComponents = automationConfigComponents.filter(e => e.config.type !== 'load-documents');
      automationConfigComponents.forEach((conditionAction: IAutomationComponentApi) => {
        if (conditionAction?.component === 'condition' || conditionAction?.component === 'if-else') {
          tempAutomation.component.push(SanitizeAutomation.manageConditionConfig(conditionAction, conditionAction?.uiContext?.name));
        }
        if (conditionAction?.component === 'action') {
          tempAutomation.component.push(SanitizeAutomation.manageActionConfig(conditionAction, conditionAction?.uiContext?.name));
        }
      });
    }
    return tempAutomation;
  }

  static manageApiCondtionConfig = (condition: IAutomationComponent): IAutomationApiTypeConfig => {
    let conditionConfig = { ...condition.config };
    delete conditionConfig.condition;

    switch (condition?.config?.condition?.name) {
      case 'field-condition':

        if (conditionConfig.compareType === 'field') {
          conditionConfig.value = {
            refField: conditionConfig.refField,
            sourceRefField: conditionConfig.sourceRefField,
          }
        }
        if (conditionConfig.operator === 'is-empty' || conditionConfig.operator === 'is-not-empty') {
          conditionConfig.value = undefined;
        }
        delete conditionConfig.bpReference;
        delete conditionConfig.compareType;
        delete conditionConfig.refField;
        delete conditionConfig.sourceRefField;
        break;
    }

    return {
      type: condition?.config?.condition?.name,
      config: conditionConfig
    }
  }

  static manageApiActionConfig = (action: IAutomationComponent): IAutomationApiTypeConfig => {
    const actionConfig = { ...action.config };
    delete actionConfig.action;

    switch (action?.config?.action?.name) {
      case 'edit-form':
        if (actionConfig.fields === undefined) {
          actionConfig.fields = {}
        }
        actionConfig.selectedField?.map((fieldId: string) => {
          if (Object.keys(actionConfig.fields).indexOf(fieldId) === -1) {
            actionConfig.fields[fieldId] = null;
          }
        });
        break;
      case 'send-sms':
      case 'send-email':
        Object.keys(actionConfig).forEach(config => {
          if (actionConfig[config] && Array.isArray(actionConfig[config])) {
            actionConfig[config] = SanitizeAutomation.manageActionConfigFields(actionConfig[config]);
          }
        });
        break;
    }

    if (actionConfig.selectedField) {
      delete actionConfig.selectedField;
    }
    if (actionConfig.selectedFields) {
      delete actionConfig.selectedFields;
    }
    if (actionConfig.hasOwnProperty('validationError')) {
      delete actionConfig.validationError;
    }

    return {
      type: action?.config?.action?.name,
      config: actionConfig
    }
  }

  static manageApiIfElseConfig = (component: IAutomationComponent): IAutomationComponentIfElseApi => {
    const conditions = component?.config?.conditionList
      ? component?.config?.conditionList.map((condition: IAutomationComponent) => {
        return SanitizeAutomation.manageApiCondtionConfig(condition);
      })
      : [];
    const actions = component?.config?.actionList
      ? component?.config?.actionList.map((action: IAutomationComponent) => {
        const actionConfig = { ...action.config };
        delete actionConfig.action;
        return {
          component: 'action',
          config: {
            type: action?.config?.action?.name,
            config: actionConfig
          },
          uiContext: {
            type: 'action',
            name: action.config.action.name
          }
        }
      })
      : [];

    const elseConditions: IAutomationComponentApi[] = [];
    if (component?.config?.elseConditionList) {
      const elseConditionCount = component?.config?.elseConditionList.length;
      if (elseConditionCount === 1) {
        const elseCondition = component?.config?.elseConditionList[0];
        if (elseCondition?.conditionList && elseCondition?.conditionList.length > 0) {
          elseConditions.push(SanitizeAutomation.manageApiElseConfig(component?.config?.elseConditionList, 0));
        } else {
          if (elseCondition?.actionList) {
            elseCondition?.actionList.forEach((actionComponent: IAutomationComponent) => {
              elseConditions.push({
                component: 'action',
                config: SanitizeAutomation.manageApiActionConfig(actionComponent),
                uiContext: {
                  type: 'action',
                  name: actionComponent.config.action.name
                }
              })
            });
          }
        }
      }
      if (elseConditionCount > 1) {
        elseConditions.push(SanitizeAutomation.manageApiElseConfig(component?.config?.elseConditionList, 0));
      }
    }

    return {
      component: 'if-else',
      config: {
        conditions: conditions,
        onTrue: actions,
        onFalse: elseConditions
      },
      uiContext: {
        type: 'condition',
        name: component?.config?.condition?.name
      }
    }
  }

  static manageApiElseConfig = (elseComponents: IAutomationElseConfig[], currentIndex: number): IAutomationComponentIfElseApi => {
    const conditions: IAutomationApiTypeConfig[] = [];
    const actions: IAutomationComponentApi[] = [];
    const elseConditions: IAutomationComponentApi[] = [];
    const totalCount = elseComponents.length;
    const lastComponent = (currentIndex + 1) === totalCount;
    const nextComponentIsLast = (totalCount - (currentIndex + 1)) === 1;
    if (lastComponent) {
      const elseComponent = elseComponents[currentIndex];
      if (elseComponent?.conditionList) {
        elseComponent?.conditionList.forEach((component: IAutomationComponent) => {
          conditions.push(SanitizeAutomation.manageApiCondtionConfig(component));
        })
      }
      if (elseComponent?.actionList) {
        elseComponent?.actionList.forEach((component: IAutomationComponent) => {
          actions.push({
            component: 'action',
            config: SanitizeAutomation.manageApiActionConfig(component),
            uiContext: {
              type: 'action',
              name: component?.config?.condition?.name
            }
          })
        })
      }
    } else if (nextComponentIsLast) {
      const elseComponent1 = elseComponents[currentIndex];
      const elseComponent2 = elseComponents[currentIndex + 1];
      if (elseComponent1?.conditionList) {
        elseComponent1?.conditionList.forEach((component: IAutomationComponent) => {
          conditions.push(SanitizeAutomation.manageApiCondtionConfig(component));
        })
      }
      if (elseComponent1?.actionList) {
        elseComponent1?.actionList.forEach((component: IAutomationComponent) => {
          actions.push({
            component: 'action',
            config: SanitizeAutomation.manageApiActionConfig(component),
            uiContext: {
              type: 'action',
              name: component?.config?.condition?.name
            }
          })
        })
      }

      if (elseComponent2?.conditionList && elseComponent2?.conditionList.length > 0) {
        elseConditions.push(SanitizeAutomation.manageApiElseConfig(elseComponents, currentIndex + 1));
      } else {
        if (elseComponent2?.actionList) {
          elseComponent2?.actionList.forEach((actionComponent: IAutomationComponent) => {
            elseConditions.push({
              component: 'action',
              config: SanitizeAutomation.manageApiActionConfig(actionComponent),
              uiContext: {
                type: 'action',
                name: actionComponent.config.action.name
              }
            })
          });
        }
      }
    } else {
      const elseComponent = elseComponents[currentIndex];
      if (elseComponent?.conditionList) {
        elseComponent?.conditionList.forEach((component: IAutomationComponent) => {
          conditions.push(SanitizeAutomation.manageApiCondtionConfig(component));
        })
      }
      if (elseComponent?.actionList) {
        elseComponent?.actionList.forEach((component: IAutomationComponent) => {
          actions.push({
            component: 'action',
            config: SanitizeAutomation.manageApiActionConfig(component),
            uiContext: {
              type: 'action',
              name: component?.config?.condition?.name
            }
          })
        })
      }
      elseConditions.push(SanitizeAutomation.manageApiElseConfig(elseComponents, currentIndex + 1));
    }
    return {
      component: 'if-else',
      config: {
        conditions: conditions,
        onTrue: actions,
        onFalse: elseConditions
      }
    }
  }

  static manageActionConfig = (action: IAutomationComponentActionApi, name: string): IAutomationComponent => {
    const actionOption = actionWidget.find((option: IOptionWidget) => option.name === name);
    return {
      id: uuid(),
      name: name,
      type: 'action',
      config: {
        ...action.config.config,
        action: actionOption,
      }
    }
  }

  static manageConditionConfig = (condition: IAutomationComponentApi, name: string): IAutomationComponent => {
    const conditionOption = conditionWidget.find((option: IOptionWidget) => option.name === name)
    let conditionObject: any = {};
    if (condition.component !== 'if-else') {
      conditionObject = condition?.config?.config || {};
    }
    switch (name) {
      case 'if-else-block':
        conditionObject = SanitizeAutomation.manageIfElseCondition(condition as any)
        break;
      case 'field-condition':
        if (conditionObject.value
          && conditionObject.value instanceof Object
          && conditionObject.value.hasOwnProperty('refField')
          && conditionObject.value.hasOwnProperty('sourceRefField')
        ) {
          conditionObject.refField = conditionObject?.value?.refField
          conditionObject.sourceRefField = conditionObject?.value?.sourceRefField
          conditionObject.compareType = 'field';
          conditionObject.value = '';
        }
        break;
    }

    return {
      id: uuid(),
      name: name,
      type: 'condition',
      config: {
        ...conditionObject,
        condition: conditionOption,
      }
    }
  }

  static manageIfElseCondition = (condition: IAutomationComponentIfElseApi) => {
    const onTrueActionList: any = condition.config.onTrue;
    const conditions: any = condition.config.conditions;
    const actionList = SanitizeAutomation.manageIfElseActionConfig(onTrueActionList);
    const conditionList = SanitizeAutomation.manageIfElseConditionConfig(conditions);
    let elseConditionList: any[] = [];
    if (condition.config.onFalse.length === 1 && condition.config.onFalse[0].component === 'if-else') {
      elseConditionList = SanitizeAutomation.manageElseIfCondition(condition.config.onFalse[0], elseConditionList);
    } else {
      const onFalseActionList: any = condition.config.onFalse;
      elseConditionList.push({
        actionList: SanitizeAutomation.manageIfElseActionConfig(onFalseActionList),
        conditionList: []
      })
    }
    return {
      actionList,
      conditionList,
      elseConditionList
    }
  }

  static manageElseIfCondition = (condition: IAutomationComponentIfElseApi, elseConditionContainer?: IAutomationElseConfig[]): IAutomationElseConfig[] => {
    const onTrueActionList: any = condition.config.onTrue;
    const conditions: any = condition.config.conditions;
    const actionList = SanitizeAutomation.manageIfElseActionConfig(onTrueActionList);
    const conditionList = SanitizeAutomation.manageIfElseConditionConfig(conditions);
    const tempElseContainer = elseConditionContainer ? [...elseConditionContainer] : [];
    tempElseContainer.push({
      actionList,
      conditionList
    })
    if (condition?.config?.onFalse && condition?.config?.onFalse.length === 1 && condition?.config?.onFalse[0].component === 'if-else') {
      SanitizeAutomation.manageElseIfCondition(condition?.config?.onFalse[0], tempElseContainer);
    } else {
      const onFalseActionList: any = condition.config.onFalse;
      tempElseContainer.push({
        actionList: SanitizeAutomation.manageIfElseActionConfig(onFalseActionList),
        conditionList: []
      })
    }
    return tempElseContainer
  }

  static manageIfElseActionConfig = (actions: IAutomationComponentActionApi[]) => {
    return actions.map((action: IAutomationComponentActionApi) => {
      return SanitizeAutomation.manageActionConfig(
        {
          component: 'action',
          config: action.config,
          uiContext: {}
        },
        action.config.type);
    });
  }

  static manageIfElseConditionConfig = (conditions: IAutomationComponentApi[]) => {
    return conditions.map((condition: IAutomationComponentApi) => {
      const conditionConfig: any = condition;
      return SanitizeAutomation.manageConditionConfig(
        {
          component: 'condition',
          config: conditionConfig,
          uiContext: {}
        },
        conditionConfig.type);
    });
  }

  static manageActionConfigFields = (config: any[]) => {
    return config.map((data: any) => {
      if (data && typeof data === 'object' && (data.hasOwnProperty('referencedKeyPath') || data.hasOwnProperty('keyPath'))) {
        const tempDataObj = { ...data };
        Object.keys(data).forEach(key => {
          if (data.referencedKeyPath === 'current-form' || (data.keyPath && !data.referencedKeyPath)) {
            data = { keyPath: data.keyPath };
          }
          const values = data[key];
          if (checkIfUUID(values) && values.indexOf('fields.') === -1) {
            tempDataObj[key] = `fields.${values}`;
          }
        });
        data = tempDataObj;
      }
      return data;
    });
  }
}
import {
  GET_USER_BUSINESS_PROCESS,
  GET_USER_BUSINESS_PROCESS_RETURN,
  GET_USER_BUSINESS_PROCESS_ERROR,
  GET_END_USER_DETAIL,
  GET_END_USER_DETAIL_RETURN,
  GET_END_USER_DETAIL_ERROR,
  SET_SELECTED_APP,
  SET_SELECTED_BP,
  GET_CURRENT_USER_IN_DOCUMENT,
  SET_CURRENT_USER_IN_DOCUMENT,
  SET_DOCUMENT_ACTION_MESSAGE
} from './constant'
import { ICurrentUserInDocumentDetailInput } from './interface/input/current-user-in-document'
import { IUserBusinessProcessDetailInput, IEndUserDetailInput } from './interface/input/user-business-process'
import { ICurrentUserInDocumentResponse } from './interface/response/current-user-in-document'
import { IUserBusinessProcessResponse, IUserDetailResponse } from './interface/response/user-business-process'

export const getUserBusinessProcessAction = (payload: IUserBusinessProcessDetailInput) => {
  return { type: GET_USER_BUSINESS_PROCESS, ...payload }
}

export const getUserBusinessProcessReturnAction = (payload: IUserBusinessProcessResponse) => {
  return { type: GET_USER_BUSINESS_PROCESS_RETURN, ...payload }
}

export const getUserBusinessProcessErrorAction = (message: any) => {
  return { type: GET_USER_BUSINESS_PROCESS_ERROR, message }
}

export const getEndUserDetailAction = (payload: IEndUserDetailInput) => {
  return { type: GET_END_USER_DETAIL, ...payload }
}

export const getEndUserDetailReturnAction = (payload: IUserDetailResponse) => {
  return { type: GET_END_USER_DETAIL_RETURN, ...payload }
}

export const getEndUserDetailErrorAction = (message: any) => {
  return { type: GET_END_USER_DETAIL_ERROR, message }
}

export const setSelectedAppAction = (apps: string[]) => {
  return { type: SET_SELECTED_APP, selectedApp: apps }
}

export const setSelectedBpAction = (bps: string[]) => {
  return { type: SET_SELECTED_BP, selectedBp: bps }
}

export const getCurrentUserInDocument = (payload: ICurrentUserInDocumentDetailInput) => {
  return { type: GET_CURRENT_USER_IN_DOCUMENT, ...payload }
}

export const setCurrentUserInDocument = (payload: ICurrentUserInDocumentResponse) => {
  return { type: SET_CURRENT_USER_IN_DOCUMENT, ...payload }
}

export const setDocumentActionMessage = (message: string) => {
  return { type: SET_DOCUMENT_ACTION_MESSAGE, documentActionMessage: message }
}
import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';
import { v4 as uuid } from 'uuid';

class UnlinkFieldOnPlainField {

  name = 'UnlinkFieldOnPlainField';

  updateSection = (sectionList: IFormBuilderSection[], forUpdateField: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    let newSectionList = [...sectionList];
    let sectionIndex = 0;
    newSectionList = newSectionList.map((section: IFormBuilderSection, index: number) => {
      if (section.sectionId === sectionUpdateConfig.sectionId) {
        if (sectionUpdateConfig.rowIndex !== undefined && sectionUpdateConfig.currentFieldIndex !== undefined) {
          sectionIndex = index;
          section.fields[sectionUpdateConfig.rowIndex].splice(sectionUpdateConfig.currentFieldIndex, 1);
        }
      }
      return section;
    });
    const newPlainSection: IFormBuilderSection = {
      sectionId: uuid(),
      title: '',
      collapsable: false,
      fields: [[forUpdateField]] as TFormBuilderSectionRow[],
      titleFieldToShow: [],
      titleHidden: false,
      additionalInfo: '',
      style: 'plain'
    }
    newSectionList.splice(sectionIndex + 1, 0, newPlainSection);

    return newSectionList;
  }
}

export default UnlinkFieldOnPlainField;
import React from 'react';
import { IFormStatus } from '../interface/form-status';
import { IFormAction } from '../interface/form-action';
import TableListDetailAction from './table-list-detail-action';

interface ITableListDetail {
  bgColor: string;
  labelColor: string;
  labelText: string;
  iconColor?: string;
  formStatusList: IFormStatus[];
  targetStatus: IFormStatus;
  targetStatusIndex: number;
  createNewAction(originStatusId: string, targetStatusId: string, originStatusIndex: number, targetStatusIndex: number): void;
  actionList: IFormAction[];
  editAction(formAction: IFormAction): void;
}

const TableListDetail: React.FC<ITableListDetail> = (props) => {
  return <tr>
    {
      props.formStatusList.map((formStatus: IFormStatus, originIndex: number) => {
        if (formStatus.deleted === true) {
          return undefined;
        }
        const actionData = props.actionList.filter(
          (formAction: IFormAction) =>
            formAction.config
            && formAction.config.targetStatusId === props.targetStatus.id
            && formAction.statusId === formStatus.id
            && formAction.deleted !== true
        );
        if (formStatus.id === props.targetStatus.id) {
          return <td
            key={`table-header-form-status-column-${formStatus.id}`}
            className={`bg-dark-gray`}>
            <label className={`text-white`}>SAVE</label>
          </td>
        } else {
          if (actionData.length > 0) {
            return <TableListDetailAction
              key={`table-header-form-status-column-${formStatus.id}`}
              formAction={actionData[0]}
              editAction={props.editAction}
            />
          } else {
            return <td
              key={`table-header-form-status-column-${formStatus.id}`}
              onClick={() => props.createNewAction(formStatus.id, props.targetStatus.id, originIndex, props.targetStatusIndex)}
            ></td>
          }
        }
      })
    }
    <td style={{ overflowWrap: 'break-word' }} className={props.bgColor}>
      <div className="ui label">
        <label className={props.labelColor}>{props.labelText}</label>
      </div>
    </td>
  </tr>
}

export default TableListDetail;
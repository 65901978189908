import { useEffect } from 'react';
import { sortByCreationDate } from '../../../../../../../common/utils/sort-by-creation-date';
import { IComment, ICommentDeltaFields } from '../../../interface/comment';
import { ISenderDetail } from '../../../interface/sender-info';

interface IProps {
  commentList: IComment[];
  setCommentList: (value: React.SetStateAction<IComment[]>) => void;
  sort: boolean;
}

export const useSetCommentList = ({ commentList, setCommentList, sort }: IProps): void => {
  useEffect(() => {
    const tableUpdateComment = [] as IComment[];
    const tempCommentList = commentList
      .map((comment: IComment) => {
        let { deltaFields, type, sender, text } = { ...comment };
        const senderUserInfo = { ...(sender && sender.data) } as ISenderDetail;

        const hasTableUpdate = deltaFields && deltaFields.filter((delta: ICommentDeltaFields) => delta.type === 'table');
        if (type === 'history' && deltaFields && deltaFields.length > 0 && hasTableUpdate.length > 0) {
          tableUpdateComment.push({
            ...comment,
            text: `${(senderUserInfo?.firstName || '') + ' ' + (senderUserInfo?.lastName || '')} 
          has updated ${hasTableUpdate[0].label} table for this form`,
            deltaFields: []
          });
        }

        const hasGeneratePDFUpdate = deltaFields && deltaFields.filter((delta: ICommentDeltaFields) => delta.type === 'file' &&
          delta.diff.length === 2 && delta.diff[0] && delta.diff[0].id === 'generated');

        if (text && ((text.indexOf('updated status') > -1 && hasGeneratePDFUpdate && hasGeneratePDFUpdate.length > 0)
          || (text.indexOf('updated status') > -1 && deltaFields.length > 0))) {
          deltaFields = [];
        }

        return {
          ...comment,
          deltaFields: deltaFields &&
            deltaFields.filter((delta: ICommentDeltaFields) => delta.type !== 'table')
        };
      })
      .filter(comment => {
        let filter = true;
        if (comment.type === 'history') {
          if (!!comment.deltaFields
            && comment.deltaFields.length > 0
            && comment.deltaFields.every((delta: ICommentDeltaFields) => delta.type === 'table')) {
            filter = false;
          }
          if (!!comment.action && !comment.text) {
            filter = false;
          }
          if (!!comment.deltaFields && comment.deltaFields.length === 0 && !comment.text.includes('status')) {
            filter = false;
          }
        }
        return filter;
      });
    const newCommentList = [...tempCommentList, ...tableUpdateComment];
    setCommentList(sort ? sortByCreationDate(newCommentList, true) : newCommentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentList]);
};
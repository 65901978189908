import { IField } from '../../component/admin-module/module/users/interface/field';

const splitArrayToChunk = (listArray: IField[], countPerArray: number) => {
  const arrayToSplice = [...listArray];
  const totalArrayToChunck = Math.ceil(arrayToSplice.length / 2);
  let returnArray = [];
  for (let i = 0; i < totalArrayToChunck; i++) {
    returnArray.push(arrayToSplice.splice(0, 2));
  }
  return returnArray;
}

export default splitArrayToChunk;
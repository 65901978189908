import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  type: string;
  label: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.GAP,
  type: 'spacer',
  label: 'GAP',
};

const value = {
  title: 'GAP',
  icon: {
    black: require('../../icons/black/gap.png'),
    white: require('../../icons/white/gap.png'),
    blue: require('../../icons/blue/gap.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig: {}
}

export default value;
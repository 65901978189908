import React from 'react';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject } from '../../../../../common/custom-field-config/interface/custom-field-detail';

import CustomFieldForm from '../../../../../common/custom-field-config';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../reducers';
import { IDataTable } from '../reducer';
import { useDataTableFieldConfigData } from './hooks/use-set-field-config-and-data';

interface IDatatableCustomField {
  isCustomFieldFormOpen: boolean;
  closeCustomField(): void;
  field: ICustomFieldDetail;
  openDeleteModal(appId: string, appName: string): void;
  updateDetailList(data: IOnCustomFieldSubmitObject, fieldId: string): void;
  forUpdateDataTableDetails: IDataTable;
  companyId: string;
  currentFieldList: ICustomFieldDetail[];
  userCustomField: ICustomFieldDetail[];
}

interface IMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: { fieldId: string; }
}

const DatatableCustomField: React.FC<IDatatableCustomField> = (props) => {

  const { fieldData, configData } = useDataTableFieldConfigData({ field: props.field })
  const { dataTableList, forUpdateDataTableDetails } = useSelector((state: IRootState) => state.dataTable);
  const { publishedForm } = useSelector((state: IRootState) => state.formBuilder);

  const dataTable = configData && configData.dataType === 'database' ? dataTableList : undefined;
  const forms = configData && configData.dataType === 'document' ? publishedForm : undefined;

  const onSubmit = (data: IOnCustomFieldSubmitObject, fieldId: string) => {
    props.updateDetailList(data, fieldId);
  }

  return <>
    <CustomFieldForm
      open={props.isCustomFieldFormOpen}
      close={props.closeCustomField}
      defaultConfig={configData}
      defaultData={fieldData}
      openDeleteModal={() => { }}
      loading={false}
      submit={onSubmit}
      cancel={props.closeCustomField}
      fieldId={fieldData.id || ''}
      formType='Datatable'
      datatable={dataTable}
      formDetail={forUpdateDataTableDetails}
      companyId={props.companyId}
      currentFieldList={props.currentFieldList}
      publishedForm={forms}
      userFieldList={props.userCustomField}
    />
  </>
}

export default DatatableCustomField;
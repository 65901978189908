interface IOnCalendarClose {
  onTypeTimeout: NodeJS.Timeout;
  focusedElement: string | null;
  id: string;
  removeFocusedElement(): void;
  validateInput(): void;
  reloadOnChange: string[];
  triggerFieldReload?(value: string[]): void;
  setRefresher(value: number): void;
  refresher: number;
}

export const onCalendarClose = (props: IOnCalendarClose) => {
  if (props.focusedElement && props.focusedElement === props.id) {
    props.removeFocusedElement();
  }
  props.validateInput();
  if (typeof props.onTypeTimeout === 'function') {
    clearTimeout(props.onTypeTimeout);
  }
  props.onTypeTimeout = setTimeout(() => {
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
  }, 1000);
  props.setRefresher(props.refresher + 1);
}
import { IInputConfig } from '../../component/admin-module/module/users/interface/field';

export const addThousandSeparator = (value: string, inputConfig: IInputConfig, addDecimals?: boolean) => {
  value += '';
  value = value.replace(/,/g, '');
  if (inputConfig && inputConfig.config) {
    if (inputConfig.config.decimals && inputConfig.config.decimals > 0) {
      value = value !== '' && inputConfig.config.decimals
        ? parseFloat(value).toFixed(inputConfig.config.decimals)
        : value;
    }
    if (inputConfig.config.thousandSeparator) {
      let x = value.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1))
        x1 = x1.replace(rgx, `$1,$2`);
      value = x1 + x2;
    }
  }
  return value !== 'NaN' ? value : '';
}
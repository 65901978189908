import React from 'react';
import ConfigService from '../../../common/config';
import { AppVersion } from '../../../common/config/version';
interface ISandbox {
  screen?: string;
}

const SandboxComponent: React.FC<ISandbox> = (props) => {
  return (
    <>
      {
        (ConfigService.loadConfig().general.sandbox) &&
        <div
          style={{
            textAlign: 'center',
            color: '#FFF',
            fontWeight: 'bolder',
            padding: props.screen === 'auth' ? '15px' : '5px',
            background: ConfigService.loadConfig().general.sandboxColor,
            position: props.screen === 'auth' ? 'unset' : 'absolute',
            bottom: '0',
            lineHeight: props.screen === 'auth' ? '0' : '0.7',
            width: '100%',
            zIndex: 5
          }}>
          <label>{(ConfigService.loadConfig().general.sandboxText).toUpperCase()} ENVIRONMENT</label>
          {AppVersion.current.number !== 'local' && <label>(Version: #{AppVersion.current.number}, Commit: {AppVersion.current.commit}, Built at: {AppVersion.current.buildTime.toLocaleString()})</label>}
        </div>
      }
    </>
  )
}


export default SandboxComponent;
import { EditMode } from '@syncfusion/ej2-react-grids';
import { IField, IFieldAccessType } from '../../component/admin-module/module/users/interface/field';
import { DropDownOptionLimit } from '../field/component/object/enum-type/utils';
import { IConfigName } from '../field/type/interface/field-type-object';

interface ITriggerFieldReload {
  reloadField: string[],
  fieldList: IField[],
  dynamicRefs: any;
  dispatch?(action: any): void;
  fieldTriggerUpdateAction?(data: any): void;
  companyId: string;
  statusId: string;
  formId: string;
  documentId: string;
  returnDataOnly?: boolean;
  triggeredOnChange?: boolean;
  triggerredFieldId?: string
  autopopulateFieldReload?: IField[];
  gridEditMode?: EditMode;
}

export const setFilteForFieldInsideTable = (fieldInput: { [id: string]: any }, reqField: string, fieldList: IField[], dynamicRefs: any) => {
  const [mainId] = reqField.split('.');
  let fieldDetail: IField[] | undefined = fieldList.filter((field: IField) => field.id === mainId);
  // send all column inside field
  const tempInputValue = dynamicRefs.current[mainId];
  fieldDetail[0].inputConfig?.config.columns?.map((field: IField) => {
    const fieldId = field.id || '';
    const arrayValue: any[] = [];
    const type = field && field.inputConfig ? field.inputConfig.type : '';
    tempInputValue && tempInputValue.length > 0 && tempInputValue.forEach((inputValue: any) => {
      let value = inputValue.current[fieldId] && inputValue.current[fieldId].value && inputValue.current[fieldId].value !== 'null' ? inputValue.current[fieldId].value : null;
      if (type === 'number') {
        value = inputValue.current && inputValue.current[fieldId] && inputValue.current[fieldId].value !== null ? inputValue.current[fieldId].value : null;
        const thousandSeparator = field && field.inputConfig && field.inputConfig.config && field.inputConfig.config.thousandSeparator;
        if (thousandSeparator && typeof value === 'string') {
          value = value.replace(/,/g, '');
        }
      }
      arrayValue.push(value)
    })
    if (!fieldInput[mainId]) {
      fieldInput[mainId] = {};
    }
    fieldInput[mainId][fieldId] = arrayValue;
  })

  return fieldInput;
}

export const setNormalFieldValue = (fieldInput: { [id: string]: any }, reqField: string, fieldList: IField[], dynamicRefs: any, triggerredFieldId?: string) => {
  const reqFieldObj = fieldList.find((field: IField) => field.id === reqField);
  const fxFields = fieldList.filter((field: IField) => field.configName === IConfigName.FX);
  const type = reqFieldObj && reqFieldObj.inputConfig ? reqFieldObj.inputConfig.type : '';
  const isUsedInFx = fxFields.length > 0 ? fxFields.filter(fx => fx.requiresFieldData && fx.requiresFieldData.includes(reqField)).length > 0 : false;

  const tempInputValue = dynamicRefs.current[reqField];

  let value = tempInputValue && tempInputValue.value && tempInputValue.value !== 'null' ? tempInputValue.value : null;

  if (reqFieldObj && reqFieldObj.configName === IConfigName.Autopopulated) {
    value = isUsedInFx && !value && reqFieldObj && reqFieldObj.accessType === IFieldAccessType.Hidden ? '0' : value;
  }
  if (type === 'number') {
    if (tempInputValue && tempInputValue.value) {
      tempInputValue.value = typeof tempInputValue.value === 'number' ? tempInputValue.value.toString() : tempInputValue.value;
    }
    value = tempInputValue && tempInputValue.value ? tempInputValue.value.replace(/,/g, '') : null;
    value = isUsedInFx && !value && reqFieldObj && reqFieldObj.accessType === IFieldAccessType.Hidden ? 0 : value;
  }
  if (type === 'enum') {
    if (tempInputValue && tempInputValue.value && typeof tempInputValue.value === 'string' &&
      (tempInputValue.value.indexOf('[') && tempInputValue.value.indexOf(']')) > -1) {
      value = tempInputValue && tempInputValue.value ? JSON.parse(tempInputValue.value) : null;
    }
  }
  if (type === 'array' && Array.isArray(tempInputValue)) {
    value = tempInputValue.length > 0 && tempInputValue.map(({ value }) => { return value });
  }
  fieldInput[reqField] = value;
  return fieldInput;
}

export const setFieldInput = (fieldInput: { [id: string]: any }, reqField: string, fieldList: IField[], dynamicRefs: any, triggerredFieldId?: string) => {
  const reqId = reqField.split('.');
  let fieldDetail: IField[] | undefined = fieldList.filter((field: IField) => field.id === reqId[0]);
  const type = fieldDetail && fieldDetail.length > 0 && fieldDetail[0].inputConfig ? fieldDetail[0].inputConfig.type : '';
  if (type === 'table') {
    fieldInput = setFilteForFieldInsideTable(fieldInput, reqField, fieldList, dynamicRefs)
  } else {
    fieldInput = setNormalFieldValue(fieldInput, reqField, fieldList, dynamicRefs, triggerredFieldId);
  }
  return fieldInput;
}

export const triggerFieldReloadAction = (data: ITriggerFieldReload) => {
  const {
    fieldList,
    reloadField,
    dynamicRefs,
    dispatch,
    fieldTriggerUpdateAction,
    companyId,
    statusId,
    formId,
    documentId,
    returnDataOnly,
    triggeredOnChange,
    triggerredFieldId,
    autopopulateFieldReload,
    gridEditMode
  } = { ...data };

  const inputDetail: { [id: string]: any } = {};
  let fieldInput: { [id: string]: any } = {};
  const fieldIds: string[] = [];
  const fxFieldReload: IField[] = [];

  reloadField && reloadField.forEach((fieldId: string) => {
    const [mainId] = fieldId.split('.');
    if (fieldIds.indexOf(mainId) === -1) {
      fieldIds.push(mainId)
    }
    let fieldDetail: IField[] | undefined = fieldList.filter((field: IField) => field.id === mainId);
    inputDetail[mainId] = {
      search: '',
      page: {
        offset: 0,
        limit: DropDownOptionLimit
      }
    }
    if (fieldDetail && fieldDetail.length > 0) {
      // override main Field if type is table to use column field id
      if (fieldDetail[0].inputConfig && fieldDetail[0].inputConfig.type === 'table') {
        let mainRequiresFieldData = fieldDetail && fieldDetail[0].requiresFieldData;
        fieldDetail[0].inputConfig?.config.columns?.forEach((field: IField) => {
          // send all field values inside table
          fieldInput = setFilteForFieldInsideTable(fieldInput, `${mainId}.${field.id}`, fieldList, dynamicRefs);
          if (mainRequiresFieldData && mainRequiresFieldData.length > 0) {
            mainRequiresFieldData.forEach((reqField: string) => {
              fieldInput = setFieldInput(fieldInput, reqField, fieldList, dynamicRefs);
            });
          } else {
            if (field.requiresFieldData) {
              field.requiresFieldData.forEach((reqField: string) => {
                fieldInput = setFieldInput(fieldInput, reqField, fieldList, dynamicRefs);
              });
              if (field.configName === IConfigName.FX) {
                const exist = fxFieldReload.find(e => fieldDetail && fieldDetail[0] && e.id === fieldDetail[0].id);
                if (!exist && fieldDetail && fieldDetail[0]) {
                  fxFieldReload.push(fieldDetail[0]);
                }
              }
            }
          }
        })
      } else {
        if (fieldDetail && fieldDetail[0].requiresFieldData) {
          fieldDetail[0].requiresFieldData.forEach((reqField: string) => {
            fieldInput = setFieldInput(fieldInput, reqField, fieldList, dynamicRefs, triggerredFieldId);
          })
        }
      }
    }
  });

  const dataToReturn = {
    fieldId: fieldIds,
    inputs: inputDetail,
    fields: fieldInput,
    companyId: companyId,
    statusId: statusId,
    formId: formId,
    documentId: documentId,
    triggeredOnChange,
    fxFieldReload,
    autopopulateFieldReload,
    gridEditMode
  };

  if (returnDataOnly) {
    return dataToReturn;
  }

  if (fieldTriggerUpdateAction && dispatch) {
    dispatch(fieldTriggerUpdateAction(dataToReturn))
  }
}

import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';

export const GET_FORM_STATUS_LIST = 'app/admin-module/business-process/workflow/GET_FORM_STATUS_LIST';
export const GET_FORM_STATUS_LIST_RETURN = 'app/admin-module/business-process/workflow/GET_FORM_STATUS_LIST_RETURN';
export const GET_FORM_STATUS_LIST_ERROR = 'app/admin-module/business-process/workflow/GET_FORM_STATUS_LIST_ERROR';
export const SAVE_FORM_STATUS = 'app/admin-module/business-process/workflow/SAVE_FORM_STATUS';
export const SAVE_FORM_STATUS_RETURN = 'app/admin-module/business-process/workflow/SAVE_FORM_STATUS_RETURN';
export const SAVE_FORM_STATUS_ERROR = 'app/admin-module/business-process/workflow/SAVE_FORM_STATUS_ERROR';
export const GET_FORM_ACTION_LIST = 'app/admin-module/business-process/workflow/GET_FORM_ACTION_LIST';
export const GET_FORM_ACTION_LIST_RETURN = 'app/admin-module/business-process/workflow/GET_FORM_ACTION_LIST_RETURN';
export const GET_FORM_ACTION_LIST_ERROR = 'app/admin-module/business-process/workflow/GET_FORM_ACTION_LIST_ERROR';
export const PUBLISH_WORKFLOW = 'app/admin-module/business-process/workflow/PUBLISH_WORKFLOW';
export const PUBLISH_WORKFLOW_RETURN = 'app/admin-module/business-process/workflow/PUBLISH_WORKFLOW_RETURN';
export const PUBLISH_WORKFLOW_ERROR = 'app/admin-module/business-process/workflow/PUBLISH_WORKFLOW_ERROR';
export const CLEAR_SAVE_FORM_STATUS_SUCCESS = 'app/admin-module/business-process/workflow/CLEAR_SAVE_FORM_STATUS_SUCCESS';
export const CLEAR_SAVE_FORM_STATUS_ERROR = 'app/admin-module/business-process/workflow/CLEAR_SAVE_FORM_STATUS_ERROR';

export const actionAssignmentType = ['forward-individual', 'forward-none', 'backward-individual', 'backward-none'];

export const workflowActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_FORM_STATUS_SUCCESS,
    type: 'success',
    reducerContainer: 'workflow',
    reducerItem: 'formSaveMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_SAVE_FORM_STATUS_ERROR,
    type: 'negative',
    reducerContainer: 'workflow',
    reducerItem: 'formSaveErrorMessage',
    hasCloseIcon: true,
  }
]
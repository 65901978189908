interface IRemoveInputProps {
  name: string;
  forwardedRef: any;
  multilineCount: number;
  setMultilineCount(value: number): void;
}
export const removeInput = (args: IRemoveInputProps, index: number) => {
  const { name, forwardedRef, multilineCount, setMultilineCount } = { ...args }
  forwardedRef.current[name].splice(index, 1);
  const newLen = multilineCount - 1;
  setMultilineCount(newLen);
}
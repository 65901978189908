import Axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Placeholder, Popup } from 'semantic-ui-react-bpm';
import { IAxiosResponse } from '../../../../../../../../../../common/api-request';

export interface ISourceDetails {
  label: string;
  link: string;
  redirect: string;
  type?: string;
}

interface ISourcePopupDetails {
  sourceDetails: ISourceDetails[]
}

const SourcePopupDetails: React.FC<ISourcePopupDetails> = ({ sourceDetails }) => {

  const [data, setData] = useState<any>(null);

  const timer = React.useRef<any>();

  const fetchDetails = async (link: string) => {
    setData(null);
    try {
      let response = await Axios.get(link) as IAxiosResponse;
      if (response.data.data) {
        const name = response.data.data.label || response.data.data.name || '';
        const description = response.data.data.hint || '';
        const title = response.data.data.type || '';
        timer.current = setTimeout(() => {
          setData({ title, description, name });
        }, 500)
      }
    } catch (e) {
      timer.current = setTimeout(() => {
        setData({ name: 'Not Found' });
      }, 500)
    }
  }

  const renderPopupDetails = (source: ISourceDetails) => {
    return (
      <Popup
        on='click'
        onClose={() => {
          setData(null)
          clearTimeout(timer.current)
        }}
        onOpen={() => fetchDetails(source.link)}
        popperDependencies={[!!data]}
        trigger={<span className='source-link-label'>{source.label}</span>}
        size='tiny'
      >
        {data === null ? (
          <Placeholder style={{ minWidth: '200px' }}>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
          </Placeholder>
        ) : (
          <>
            <Link to={source.redirect} target='_blank'>
              <Header as='h4' content={data.name}
                subheader={data.title ? data.title : source.type}
              />
            </Link>
            {data.description &&
              <p>{data.description}</p>
            }
          </>
        )}
      </Popup>
    )
  }

  return <>
    {sourceDetails
      && sourceDetails instanceof Array
      && sourceDetails.length > 0
      && sourceDetails.map((source, index) => {
        return <React.Fragment key={index}>
          {renderPopupDetails(source)}
          {(sourceDetails.length > 1 && index < sourceDetails.length - 1) ? ',' : ''}
          <br />
        </React.Fragment>
      })}
  </>
}

export default React.memo(SourcePopupDetails);

import React, { useState } from 'react';
import ListSideViewFields from './field';

interface IListSideView {

}

const ListSideView: React.FC<IListSideView> = (props) => {
  const [selectedTab, setSelectedTab] = useState<'columns' | 'filters'>('columns');

  return <div className='list-side-view'>
    <div className='sv-tab-container'>
      <span className={`sv-tab ${selectedTab === 'columns' ? 'active' : ''}`} onClick={() => setSelectedTab('columns')}>Columns</span>
      <span className={`sv-tab ${selectedTab === 'filters' ? 'active' : ''}`} onClick={() => setSelectedTab('filters')}>Filters</span>
    </div>
    <div className='sv-column-tab'>
      {selectedTab === 'columns' &&
        <>
          <div className="ui search">
            <div className="ui icon input">
              <i className="search icon" />
              <input className="prompt" type="text" placeholder="Search" />
            </div>
            <div className="results" />
          </div>
          <hr />
        </>
      }
    </div>
    <ListSideViewFields />
  </div>
}

export default ListSideView;
import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Dimmer, Loader, Portal } from 'semantic-ui-react-bpm';
import SelectedRelation from './selected-relation';
import RelationConfigModal from './relation-config-modal';
import { v4 as uuid } from 'uuid';
import ButtonContainer from '../../../../button-container';
import DropdownPopperOption from '../../../../../../../../common/general/dropdown-popper-option';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../reducers';
import { getBPRListAction, getBPRFormFieldsAction, publishBPRelationshipAction, saveDraftBPRelationshipAction, saveDraftBPRelationshipErrorAction } from './action';
import { useHistory, useRouteMatch } from 'react-router-dom';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import ActionMessage from '../../../../../../../../common/general/action-message';
import { bpRelationshipActionMessage } from './constant';
import { SanitizeBPR } from '../../../../../../../../common/utils/sanitize-bpr';
import { IApiFormRelatedField, IFormRelated, IFormRelatedField, IRelatedField } from './interface/bp-relationship-field';
import { ConfirmationMessage } from '../../../../../../../../common/general/confirmation-modal';
import { useSetBPRelationsState } from './hooks/use-set-bp-relations-state';

interface IBPRelationshipProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const BPRelationship: React.FC<IBPRelationshipProps> = (props) => {
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();

  const {
    bprFormFields,
    BPRelationshipSaveType,
    BPRelationshipList,
    saveBPRelationshipLoading,
    formListLoading,
    referenceList,
    sanitizingRelatedForm
  } = useSelector((state: IRootState) => state.bpRelationship);
  const { company } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedRelationId, setSelectedRelationId] = useState<string>('');
  const [relationToDelete, setRelationToDelete] = useState<IFormRelated>({} as IFormRelated);
  const {
    selectedRelation,
    setSelectedRelation,
    relatedOptions,
    setRelatedOptions,
    setOptions
  } = useSetBPRelationsState({ BPRelationshipList, referenceList });

  const setRelationConfigModal = (id: string) => {
    setTimeout(() => {
      const bpRelElment: HTMLElement | null = document.getElementById(`selected-relation-${id}`);
      const modal: HTMLElement | null = document.getElementById('relation-config-modal');
      const dottedElement = bpRelElment?.getBoundingClientRect();
      if (modal && dottedElement) {
        document.body.appendChild(modal);
        modal.style.left = `calc(${dottedElement.left - 340}px)`;
        modal.style.top = `calc(${dottedElement.top - 10}px + ${window.scrollY}px)`;
        modal.style.position = `absolute`;
      }
    }, 0);
  }

  const editRelationConfig = (id: string) => {
    const selected = selectedRelation.find((e: any) => e.id === id);
    if (selected) {
      dispatch(getBPRFormFieldsAction({ company: company, relatedForm: selected, sanitize: false }))
    }
    setRelationConfigModal(id);
    setSelectedRelationId(id);
  }

  const saveFieldConfig = (id: string, headerField: IFormRelatedField[], contentField: IFormRelatedField[]) => {
    let tempSelection = [...selectedRelation];
    tempSelection = tempSelection.map((relation: IFormRelated) => {
      const tempRelation = { ...relation };
      if (relation.id === id) {
        tempRelation.headerField = headerField;
        tempRelation.fields = contentField;
      }
      return tempRelation;
    })
    setSelectedRelation(tempSelection);
    setSelectedRelationId('');
    props.hasPageChangeRef.current.hasChange = true;
  }

  const addSelectedRelation = () => {
    if (selectedOption !== '') {
      const [id, title, dataType] = selectedOption.split('|||||');
      const tempSelection = [...selectedRelation];

      tempSelection.push({
        title: title,
        id: uuid(),
        dataType: dataType,
        relationId: id,
        headerField: [],
        fields: [],
        config: {
          header: {} as IRelatedField,
          body: []
        },
        new: true,
        showRelatedForm: true
      });

      setSelectedRelation(tempSelection);
      setSelectedOption('');
      setRelatedOptions(removeFromList([...relatedOptions], id));
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const removeFromList = (list: any[], idToRemove: string) => {
    const newList = [...list];
    const index = newList.indexOf(newList.filter(e => (e.value === idToRemove || e.id === idToRemove))[0]);
    newList.splice(index, 1);
    return newList;
  }

  const addToList = (list: any[], dataToAdd: any) => {
    const newList = [...list];
    newList.push(dataToAdd);
    return newList;
  }

  const hasEmptyHeaderField = (): boolean => {
    const hasEmptyHeaderField = selectedRelation.filter((e: IFormRelated) => { return e.headerField.length === 0 });
    return hasEmptyHeaderField.length > 0;
  }

  const saveDraft = (saveType: string) => {

    if (hasEmptyHeaderField()) {
      dispatch(saveDraftBPRelationshipErrorAction('Header Field should not be empty.'))
      return;
    }

    dispatch(
      saveDraftBPRelationshipAction({
        company: company,
        formId: match.params.formId,
        saveType: saveType,
        relatedReferences: SanitizeBPR.structureOutgoing(selectedRelation) as IApiFormRelatedField[]
      })
    );
    props.hasPageChangeRef.current.hasChange = false;
  }

  const closeSettings = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const deleteSelectedRelation = () => {
    const newRelatedOptions = [...relatedOptions];
    const option = setOptions(referenceList.filter(e => e.id === relationToDelete.relationId));
    setSelectedRelation(removeFromList([...selectedRelation], relationToDelete.relationId));
    setSelectedRelationId('');
    setRelationToDelete({} as IFormRelated);
    setRelatedOptions(addToList(newRelatedOptions, option[0]));
  }

  const triggerErrorMessage = (message: string) => {
    dispatch(saveDraftBPRelationshipErrorAction(message))
  }

  useEffect(() => {
    if (BPRelationshipSaveType === 'publish') {
      dispatch(publishBPRelationshipAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BPRelationshipSaveType])

  useEffect(() => {
    if (company) {
      dispatch(getBPRListAction({ company: company, formId: match.params.formId }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <>
      <ActionMessage messages={bpRelationshipActionMessage} />
      <div className={`bp-relationship-module`}>
        {
          (saveBPRelationshipLoading || formListLoading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <div className='bp-relationship-container'>
          <div className={`bp-selection-container`}>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              placeholder='Related BP / Data Table'
              clearable
              search
              selectOnBlur={false}
              value={selectedOption}
              options={relatedOptions}
              onChange={(event, target) => { setSelectedOption(target.value as string) }}
            />
            <Button onClick={addSelectedRelation}>Add</Button>
          </div>
          <div className={`bp-selected-container`}>
            {selectedRelation.map((relation: IFormRelated) => {
              return <SelectedRelation
                key={uuid()}
                relation={relation}
                editRelationConfig={editRelationConfig}
                hasCollapseIcon={true}
                configuration='Referenced Field'
                sanitizingRelatedForm={sanitizingRelatedForm}
              />
            })
            }
          </div>
        </div>
        <ButtonContainer
          cancel={() => closeSettings()}
          save={() => saveDraft('draft')}
          publish={() => saveDraft('publish')}
        />
        <Portal open={selectedRelationId !== ''}>
          <RelationConfigModal
            isHiddenContainer={selectedRelationId === ''}
            setSelectedId={setSelectedRelationId}
            saveFieldConfig={saveFieldConfig}
            selectedId={selectedRelationId}
            selectedData={selectedRelation}
            formFields={bprFormFields}
            setDataToDelete={setRelationToDelete}
            configuration='Referenced Field'
            triggerErrorMessage={triggerErrorMessage}
            hasDelete={true}
            deleteButtonPlacement='footer'
            hasCancelAction={false}
          />
        </Portal>
      </div>
      <ConfirmationMessage
        confirmButtonName={'Confirm'}
        cancelButtonName={'Cancel'}
        confirmButtonColor={'red'}
        cancelButtonColor={'green'}
        close={() => setRelationToDelete({} as IFormRelated)}
        open={relationToDelete && relationToDelete.id !== undefined}
        confirm={() => deleteSelectedRelation()}
        cancel={() => setRelationToDelete({} as IFormRelated)}
        headerText="Confirmation"
        detailText={`The BP relation to ${relationToDelete.title} will be deleted. Do you want to proceed?`}
        loading={false}
        type='warning'
      />
    </>);
}

export default BPRelationship;

import React, { useRef, useState } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { arrayOperator, fxOperatorType, operatorOptions } from '../../constant';
import { IFormula } from '../../interface/formula';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import { IOption } from '../../../interface/option';
import { v4 as uuid } from 'uuid';
import { IConfigName } from '../../../field/type/interface/field-type-object';

interface IOperatorProps {
  addFormula(formula: IFormula): void;
  displayFieldList: ICustomFieldDetail[];
  currentFieldId: string;
  tableId?: string;
}

const errorIndicator = {
  field: false,
  aggOperator: false,
  inputValue: false,
};

const Operator: React.FC<IOperatorProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState('Field');
  const [selectedField, setSelectedField] = useState('');
  const [selectedArrayOperator, setSelectedArrayOperator] = useState('');
  const [error, setError] = useState(errorIndicator);
  const inputFieldRef = useRef(document.createElement('input'));

  const getOption = (): IOption[] => {
    let options: IOption[] = [];
    let ctr = 0;
    props.displayFieldList.forEach((field: ICustomFieldDetail) => {
      if (props.tableId === field.id && field.type === 'table') {
        field.config?.columns.forEach((tblField: ICustomFieldDetail) => {
          if (tblField.id !== props.currentFieldId) {
            options = setOptions(options, ctr++, tblField, field.id);
          }
        })
        return;
      }
      if (field.id !== props.currentFieldId) {
        options = setOptions(options, ctr++, field);
      }
    });
    return options;
  }

  const getArrayOption = () => {
    let options: IOption[] = [];
    let ctr = 0;
    props.displayFieldList.forEach((field: ICustomFieldDetail) => {
      if (field.id === props.currentFieldId) {
        return;
      }
      if (field.type === 'table') {
        field.config?.columns.forEach((tblField: ICustomFieldDetail) => {
          if (tblField.id === props.currentFieldId) {
            return;
          }
          options = setOptions(options, ctr++, tblField, field.id);
        })
      } else {
        options = setOptions(options, ctr++, field, undefined, 'array');
      }
    });
    return options;
  }

  const setOptions = (options: IOption[], ctr: number, field: ICustomFieldDetail, tableId?: string, type?: string): IOption[] => {
    const isNormalField = (field.type === 'number'
      || field.type === 'fx'
      || ((field.configName === IConfigName.Referenced || field.type === 'autopopulated')
        && field.config && !field.config.multiselect
        && (field.config.labelType === 'number' || field.config.labelType === 'fx')
      ));

    const isArrayField = (type === 'array' && field.type === 'array' && field.config?.itemType === 'number');

    if ((type !== 'array' && isNormalField) || isArrayField) {
      const value = field.id || '';
      const label = field.label || '';
      options.push({
        value: `${`${tableId ? `${tableId}.` : ''}${value}`}|||||${label}`,
        key: ctr,
        text: label
      })
    }
    return options;
  }

  const addToFormula = () => {
    if (!validate()) {
      return;
    }

    let formula: IFormula | undefined = undefined;
    if (selectedOption === 'Field' || selectedOption === 'Array') {
      const [value, label] = selectedField.split('|||||');
      formula = {
        type: 'field',
        value: value,
        label: label,
      }
      if (selectedOption === 'Array') {
        const operatorDetail = arrayOperator.filter((aggOperator) => aggOperator.value === selectedArrayOperator);
        if (operatorDetail.length > 0) {
          formula.label = formula.label + ' ' + operatorDetail[0].text
        }
        formula.agg = selectedArrayOperator;
      }
    }
    if (selectedOption === 'Value') {
      formula = {
        type: 'value',
        value: parseFloat(inputFieldRef.current.value),
        label: 'Value',
      }
    }

    if (formula) {
      props.addFormula(formula);
      selectAddOption('Field')
    }
  }

  const validate = () => {
    const tempError = { ...error };
    let status = true;

    if (selectedOption === 'Field' || selectedOption === 'Array') {
      tempError.field = false;
      if (!selectedField) {
        tempError.field = true;
        status = false;
      }

      if (selectedOption === 'Array') {
        tempError.aggOperator = false;
        if (!selectedArrayOperator) {
          tempError.aggOperator = true;
          status = false;
        }
      }
    }

    if (selectedOption === 'Value') {
      tempError.inputValue = false;
      if (inputFieldRef.current.value === '') {
        tempError.inputValue = true;
        status = false;
      }
    }

    setError(tempError);
    return status;
  }

  const selectAddOption = (value: string) => {
    setSelectedOption(value);
    setError({ ...errorIndicator });
    setSelectedField('');
    setSelectedArrayOperator('');
    inputFieldRef.current.value = '';
  }

  const plusButton = () => {
    return <i className={`ui icon plus`} onClick={() => addToFormula()}></i>
  }

  const operatorView = () => {
    return <div>
      <label className='label'>Add Operator:</label>
      <div className='operator-button'>
        {
          fxOperatorType.map((e: IFormula) => {
            return <button key={uuid()} onClick={() => props.addFormula(e)} className={e.type}>{e.label}</button>
          })
        }
      </div>
    </div>
  }

  const operatorFieldOptionView = () => {
    return <div className={`value-options`}>
      <span>Add</span>
      {
        operatorOptions.map((option: string) => {
          return <div
            key={uuid()}
            className={selectedOption === option ? 'selected' : ''}
            onClick={() => selectAddOption(option)}
          >{option}</div>
        })
      }
    </div>
  }

  const normalFieldSelection = () => {
    return <div className={`normal-field`}>
      <Dropdown
        className={error.field ? 'error' : ''}
        fluid
        selection
        clearable
        search
        placeholder='Select Field'
        value={selectedField}
        selectOnBlur={false}
        options={getOption()}
        onChange={(event, target) => { setSelectedField(target.value as string) }}
      />
      {plusButton()}
    </div>
  }

  const arrayFieldSelection = () => {
    return <div className={`array-field`}>
      <Dropdown
        className={error.field ? 'error' : ''}
        fluid
        selection
        clearable
        search
        placeholder='Array Field'
        value={selectedField}
        selectOnBlur={false}
        options={getArrayOption()}
        onChange={(event, target) => { setSelectedField(target.value as string) }}
      />
      <Dropdown
        className={error.aggOperator ? 'error' : ''}
        fluid
        selection
        clearable
        search
        placeholder='Select Value'
        value={selectedArrayOperator}
        selectOnBlur={false}
        options={arrayOperator}
        onChange={(event, target) => { setSelectedArrayOperator(target.value as string) }}
      />
      {plusButton()}
    </div>
  }

  const valueField = () => {
    return <div className={`value-field`}>
      <input type='number'
        className={error.inputValue ? 'error' : ''}
        ref={(curRef) => {
          if (curRef) {
            return inputFieldRef.current = curRef
          }
        }} />
      {plusButton()}
    </div>
  }


  const operatorFieldValueView = () => {
    return <div className={`value-field`}>
      {selectedOption === 'Field' && normalFieldSelection()}
      {selectedOption === 'Array' && arrayFieldSelection()}
      {selectedOption === 'Value' && valueField()}
    </div>
  }

  return <div className='operator-container'>
    {operatorView()}
    <div className={`operator-value-container`}>
      {operatorFieldOptionView()}
      {operatorFieldValueView()}
    </div>
  </div>
}

export default Operator;
import React from 'react';
import ListHeaderField from '../field';

interface IListHeaderConfig {

}

const ListHeaderConfig: React.FC<IListHeaderConfig> = (props) => {
  const fields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return <div className='list-header-main-container'>
    <h5>Column Header</h5>
    <div className='list-header-container'>
      {fields.map(field => {
        return <ListHeaderField key={field} />
      })}
    </div>
  </div>
}

export default ListHeaderConfig;
import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react-bpm';

interface IPortalSHProfileFilter {

}

const PortalSHProfileFilter: React.FC<IPortalSHProfileFilter> = (props) => {
  const history = useHistory();

  const openFormModal = () => {
    history.push('/portal/stakeholders/new-stakeholder')
  }

  return <div className='portal-sh-profile-filter'>
    <Button type='button' className={`add-button`}
      primary size={"large"} floated={"right"}
      onClick={openFormModal}>Add Stakeholder</Button>
  </div>
}

export default PortalSHProfileFilter;
import { IField } from '../../../../../../../component/admin-module/module/users/interface/field';
import { setDateValidationSchema } from './set-date-validation-schema';

export const setRangeFieldBaseOnType = (config: any, id: string, rangeType: string, defaultValue?: object): IField => {
  let tempConfig: any = {};
  const type = config.type;

  let validationSchema: any = type ? { type: type || 'string' } : { type: 'string' };
  const rangeDefaultValue = defaultValue ? { ...defaultValue as any }[rangeType.toLowerCase()] : undefined;

  if (type === 'date') {
    tempConfig = setDateValidationSchema({ ...config.config }, id, rangeType);
  }
  if (type === 'date-time') {
    tempConfig = setDateValidationSchema({ ...config.config }, id, rangeType);
  }

  return {
    id: `${rangeType}.${id}`,
    inputConfig: {
      type: type || 'string',
      config: { ...tempConfig },
    },
    label: '',
    defaultValue: rangeDefaultValue,
    validationSchema
  }
}
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { ISenderDetail } from '../../../../../../../../../document-module/module/form/interface/sender-info';
import { getUserFieldListAction, getUserProfileAction } from '../../../../../../../users/action';
import { getAutomationContentAction } from '../../action';
import { IAutomation } from '../../interface/automation';
import { IAutomationComponent } from '../../interface/component-list';

interface IProps {
  dispatch: any;
  company: string;
  formId: string;
  businessRulesId: string;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  setSelectedComponent: (value: React.SetStateAction<IAutomationComponent | undefined>) => void;
  setComponentHasSet: (value: boolean) => void;
  componentHasSet: boolean;
}

export const useGetAutomationContent = (props: IProps): void => {
  const {
    dispatch,
    company,
    formId,
    businessRulesId,
    currentAutomationRef,
    setSelectedComponent,
    componentHasSet,
    setComponentHasSet
  } = { ...props };


  useEffect(() => {
    if (businessRulesId === 'add-record') {
      const automationId = uuid();

      const currentId = uuid();
      const initialComponent = {
        id: currentId,
        type: 'trigger',
        name: 'New Trigger',
        config: {},
      };

      const automation = {
        id: automationId,
        name: '',
        description: '',
        creator: {} as ISenderDetail,
        enabled: false,
        component: [initialComponent]
      }
      currentAutomationRef.current = automation;
      if (!componentHasSet) {
        setSelectedComponent(initialComponent);
        setComponentHasSet(true);
      }
    } else {
      if (company && formId) {
        dispatch(getAutomationContentAction({
          automationId: businessRulesId,
          company: company,
          formId: formId,
        }))
      }
    }
    dispatch(getUserProfileAction({ company }));
    dispatch(getUserFieldListAction({ company }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, businessRulesId, formId])
}
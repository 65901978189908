import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { icons } from '../../../../../../../../common/icons';
import { IComment } from '../../../../interface/comment';
import CommentWidgetSenderInfo from '../../utils/sender-info';
import SytemMessageComment from './comment';

interface IChatSystemMessage {
  comment: IComment;
  lastReadMessage: IComment;
  moduleType: string;
}

const ChatSystemMessage: React.FC<IChatSystemMessage> = ({ comment, lastReadMessage, moduleType }) => {

  const { deltaFields, action } = { ...comment };
  const botIcon = moduleType === 'admin' ? icons.white.bot : icons.other.bot;

  const isComponentNull = () => {
    return !ReactDOMServer.renderToStaticMarkup(<SytemMessageComment comment={comment} />).trim() ||
      (action && action.type === 'save' && !deltaFields.length && comment.text.includes('updated'));
  }

  if (isComponentNull()) {
    return null;
  }

  return <div id={comment.id} key={uuid()} className={`chat-message-widget`}>
    {lastReadMessage && lastReadMessage.id === comment.id &&
      <span className='chat-unread-mark'>New</span>
    }
    <Image className='bot-icon' src={botIcon} />
    <div className={`message-content-container`}>
      <CommentWidgetSenderInfo comment={comment} />
      <div className={`message-content`}>
        <SytemMessageComment comment={comment} />
      </div>
    </div>
  </div>
};

export default ChatSystemMessage;

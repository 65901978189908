import React from 'react';
import { icons } from '../../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';
import Field from './field';
import { numberToStringName } from '../../../../../../admin-module/module/business-process/module/form-builder/constant';
import { v4 as uuid } from 'uuid';
import { IRootState } from '../../../../../../../reducers';
import { useSelector } from 'react-redux';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IPlainSectionProps {
  fields: any[];
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): void;
}

const PlainSection: React.FC<IPlainSectionProps> = (props) => {
  const { formFieldList } = useSelector((state: IRootState) => state.portalFormView)
  return (
    <div className={`section-plain-container`}>
      <div className={`section-plain-drag`}>
        <Image src={icons.blue.dragIcon} />
      </div>
      <div className={`field-container ${numberToStringName[props.fields.length]}`}>
        {
          props.fields.map((e: string) => {
            const field = formFieldList?.find((f: ICustomFieldDetail) => e === f.id);
            if (!field) {
              return undefined;
            }
            return <Field field={field} key={uuid()} viewIcon={props.viewIcon} />;
          })
        }
      </div>
    </div>
  );
}

export default PlainSection;
import React from 'react';
import { Portal } from 'react-overlays';
import { useRouteMatch } from 'react-router-dom';

const PopperContainer: React.FC<any> = ({ children }) => {
  const match: { params: { submodule: string } } = useRouteMatch();

  const el = document.getElementById('popper');
  const dimmerEl: any = document.getElementsByClassName('dimmer');

  let style = {};
  if (dimmerEl.length > 0) {
    for (let i = 0; i < dimmerEl.length; i++) {
      dimmerEl[i].style.zIndex = 0;
    }
    const popperParentInput: any = document.getElementsByClassName('react-datepicker-ignore-onclickoutside');
    if (popperParentInput.length > 0) {
      style = {
        position: match.params.submodule !== 'business-process' ? 'absolute' : '',
        top: '0px',
        left: '0px',
        width: popperParentInput[0].offsetWidth,
        filter: 'blur(0px) grayscale(0)',
        transition: 'none',
        zIndex: 1000,
      }
    }
  }

  const datepickerTriangleIcon: any = document.getElementsByClassName('react-datepicker__triangle');
  if (datepickerTriangleIcon.length > 0) {
    datepickerTriangleIcon[0].style.position = '';
    datepickerTriangleIcon[0].style.left = '';
    datepickerTriangleIcon[0].style.transform = '';
    datepickerTriangleIcon[0].style.zIndex = 1;
    datepickerTriangleIcon[0].style.marginTop = '-7px';
  }

  return <Portal container={el} >
    <div style={{ ...style }}>
      {children}
    </div>
  </Portal>;
}

export default PopperContainer;


import React, { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { icons } from '../../common/icons'
import { Image, Modal } from 'semantic-ui-react-bpm';
import 'react-html5-camera-photo/build/css/index.css';
import SVGWrapper from '../icons/svg/svg-wrapper';

interface IFileComera {
  close(): void;
  open: boolean;
  handleTakePhoto(value: string): void;
}
export const FileCamera: React.FC<IFileComera> = (props) => {
  const [cameraFacing, setCameraFacing] = useState(true);
  const [previewImage, setPreviewImage] = useState('');

  return (
    <Modal
      id='file-camera-popup'
      dimmer='blurring'
      size='fullscreen'
      closeIcon={<Image
        className={`close-icon ${previewImage !== '' ? 'captured' : ''}`}
        src={icons.black.closePlain} />}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={props.open}
      onClose={() => props.close()}
      style={{ right: '0!important' }}
    >
      <Modal.Content className={previewImage !== '' ? 'captured' : ''}>
        {
          (previewImage !== '')
            ?
            <div style={{ textAlign: 'center' }} className='image-preview'>
              <Image src={previewImage}
                style={{ display: 'unset' }}
                onClick={() => setCameraFacing(!cameraFacing)} />
              <div className='image-action'>
                <span title='Re-take'
                  className='retake'
                  onClick={() => setPreviewImage('')}>
                  <SVGWrapper
                    iconName='cameraRetake'
                    width={30}
                    height={30}
                    color='#fff'
                  />
                </span>
                <button type="button" className="ui large primary button confirm"
                  onClick={() => props.handleTakePhoto(previewImage)}>
                  Confirm captured image
                  </button>
              </div>
            </div>
            :
            <React.Fragment>
              <Camera
                onTakePhoto={(dataUri: any) => { setPreviewImage(dataUri); }}
                idealFacingMode={cameraFacing ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
                isImageMirror={cameraFacing}
              >
              </Camera>
              <div style={{
                textAlign: 'center',
                bottom: '35px',
                left: '60%',
                position: 'absolute',
              }}>
                <Image width='40px' style={{
                  padding: '5px',
                  cursor: 'pointer',
                  display: 'unset',
                  backgroundColor: 'rgb(255 255 255 / 45%)',
                  borderRadius: '50%'
                }} src={icons.white.switchCamera} onClick={() => setCameraFacing(!cameraFacing)} />
              </div>
            </React.Fragment>
        }
      </Modal.Content>
    </Modal >
  )
}
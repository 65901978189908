import { icons } from '../../../../common/icons';
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { colorIndicator } from '../workspace/constant';
import { ICustomFilterContainer } from '../../../admin-module/module/business-process/module/configuration/module/custom-filter/interface/custom-filter-field';
import { v4 as uuid } from 'uuid';

export const GET_TASKS_LIST = 'app/documentModule/taks/GET_TASKS_LIST';
export const GET_TASKS_LIST_RETURN = 'app/documentModule/taks/GET_TASKS_LIST_RETURN';
export const GET_TASKS_LIST_ERROR = 'app/documentModule/taks/GET_TASKS_LIST_ERROR';
export const CLEAR_TASKS_LIST_ERROR = 'app/documentModule/taks/CLEAR_TASKS_LIST_ERROR';
export const CLEAR_TASKS_BULK_ACTION_SUCCESS = 'app/documentModule/taks/CLEAR_TASKS_BULK_ACTION_SUCCESS';
export const TRIGGER_BULK_ACTION = 'app/documentModule/tasks/TRIGGER_BULK_ACTION';
export const TRIGGER_BULK_ACTION_RETURN = 'app/documentModule/tasks/TRIGGER_BULK_ACTION_RETURN';
export const TRIGGER_BULK_ACTION_ERROR = 'app/documentModule/tasks/TRIGGER_BULK_ACTION_ERROR';
export const SET_TASK_LIST_VALIDATION_ERROR = 'app/documentModule/tasks/SET_TASK_LIST_VALIDATION_ERROR';
export const CLEAR_TASK_LIST_VALIDATION_ERROR = 'app/documentModule/tasks/CLEAR_TASK_LIST_VALIDATION_ERROR';
export const UNDO_TASK_ACTION = 'app/documentModule/tasks/UNDO_TASK_ACTION';
export const GET_ACTION_USER_LIST = 'app/documentModule/taks/GET_ACTION_USER_LIST';
export const GET_ACTION_USER_LIST_RETURN = 'app/documentModule/taks/GET_ACTION_USER_LIST_RETURN';
export const GET_ACTION_USER_LIST_ERROR = 'app/documentModule/taks/GET_ACTION_USER_LIST_ERROR';
export const GET_BP_FIELD_PERMISSIONS = 'app/documentModule/taks/GET_BP_FIELD_PERMISSIONS';
export const GET_BP_FIELD_PERMISSIONS_RETURN = 'app/documentModule/taks/GET_BP_FIELD_PERMISSIONS_RETURN';
export const GET_BP_FIELD_PERMISSIONS_ERROR = 'app/documentModule/taks/GET_BP_FIELD_PERMISSIONS_ERROR';
export const SAVE_TASK_DATA = 'app/documentModule/taks/SAVE_TASK_DATA';
export const SAVE_TASK_DATA_RETURN = 'app/documentModule/taks/SAVE_TASK_DATA_RETURN';
export const SAVE_TASK_DATA_ERROR = 'app/documentModule/taks/SAVE_TASK_DATA_ERROR';
export const GET_TASK_CUSTOM_LAYOUT = 'app/documentModule/taks/GET_TASK_CUSTOM_LAYOUT';
export const GET_TASK_CUSTOM_LAYOUT_RETURN = 'app/documentModule/taks/GET_TASK_CUSTOM_LAYOUT_RETURN';
export const GET_TASK_CUSTOM_LAYOUT_ERROR = 'app/documentModule/taks/GET_TASK_CUSTOM_LAYOUT_ERROR';
export const RESET_TASK_LIST = 'app/documentModule/taks/RESET_TASK_LIST';
export const UPDATE_CURRENT_USER_IN_DOCUMENT = 'app/documentModule/taks/UPDATE_CURRENT_USER_IN_DOCUMENT';

export type tasksFilterOwnershipType = 'owned' | 'unique-co-owned' | 'co-owned' | 'all';
export type tasksFilterOpenVsCompleted = 'pending' | 'completed';
export type tasksfilterSLAsType = 'ok' | 'warn' | 'late' | 'all';

export const taskOwnershipFilter = [
  {
    id: 'owned',
    label: 'Owned',
    iconDefault: icons.black.user,
    iconSelected: icons.orange.user,
    style: {
      width: '10px',
      height: '15px',
      marginRight: '12px'
    }
  },
  {
    id: 'co-owned',
    label: 'Co-Owned',
    iconDefault: icons.black.users,
    iconSelected: icons.orange.users,
    style: {
      width: '15px',
      height: '15px',
      marginRight: '12px'
    }
  },
  {
    id: 'follower',
    label: 'Followed',
    iconDefault: icons.black.star,
    iconSelected: icons.orange.star,
    style: {
      width: '16px',
      height: '16px',
      marginRight: '7px'
    }
  },
  {
    id: 'all',
    label: 'ALL',
    colorDefault: '#000',
    colorSelected: '#DDA749',
    style: {
      fontSize: '10px',
      color: '#000',
      fontWeight: 'bolder',
      marginRight: '15px'
    }
  }
]

export const taskSLAFilter = [
  {
    id: 'ok',
    label: 'Excel',
    background: colorIndicator.green
  },
  {
    id: 'warn',
    label: 'SLA',
    background: colorIndicator.amber
  },
  {
    id: 'late',
    label: 'Late',
    background: colorIndicator.red
  },
  {
    id: 'all',
    label: 'ALL',
    colorDefault: '#000',
    colorSelected: '#DDA749',
  }
]

export const taskListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_TASKS_LIST_ERROR,
    type: 'negative',
    reducerItem: 'tasksListError',
    reducerContainer: 'tasksReducer',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_TASKS_BULK_ACTION_SUCCESS,
    type: 'success',
    reducerItem: 'taskBulkActionSuccess',
    reducerContainer: 'tasksReducer',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_TASK_LIST_VALIDATION_ERROR,
    type: 'negative',
    reducerItem: 'taskListValidationError',
    reducerContainer: 'tasksReducer',
    hasCloseIcon: true
  }
]

export const taskRecordLimit = 50;
export const systemFieldIds = [
  'app',
  'form',
  'creator',
  'updatedBy',
  'owner',
  'status',
  'createdAt',
  'updatedAt',
];

export const defaultTaskFilterList: ICustomFilterContainer = {
  '0': undefined,
  '1': undefined,
  '2': undefined,
  '3': undefined
};

export const aggFuncValues = {
  'sum': 'sum',
  'count': 'count',
  'average': 'avg'
}

export const aggregateFuncList = [
  { key: uuid(), value: 'sum', text: 'Sum' },
  { key: uuid(), value: 'count', text: 'Count' },
  { key: uuid(), value: 'avg', text: 'Average' },
]
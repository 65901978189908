import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import { IForm } from '../interface/form';
import { getDataTableListAction } from '../../../../data-tables/action';
import { IDataTable } from '../../../../data-tables/reducer';
import { IBackupItem } from '../interface/export-backup';
import { getBackupRefsAction, setExportBackupItem, setSelectAllBPDT } from '../action';

interface IMatch {
  params: { fileAction: string, formId: string };
}
interface IOnSelectAll {
  type: 'form' | 'database';
  all: boolean;
}

const ExportBackup: React.FC = () => {
  const dispatch = useDispatch();
  const match: IMatch = useRouteMatch();
  const { company } = useSelector((state: IRootState) => state.auth);
  const { formList, selectedBackupItem, backupRefs, selectAll } = useSelector((state: IRootState) => state.businessProcess);
  const { dataTableList } = useSelector((state: IRootState) => state.dataTable);

  useEffect(() => {
    if (company && dataTableList.length === 0) dispatch(getDataTableListAction({ company: company }));
  }, [dispatch, match.params.formId, company, dataTableList])

  const onSelect = async (selectedItem: IBackupItem) => {
    let selected = [...selectedBackupItem];
    const isSelected = !!selected.find((item: IBackupItem) => item.id === selectedItem.id);

    if (!isSelected) {
      selected.push(selectedItem);
    } else {
      selected = selected.filter((item: IBackupItem) => item.id !== selectedItem.id);
    }

    dispatchSelectedItem(selected);
  }

  const onSelectAll = ({ type, all }: IOnSelectAll) => {
    let selected: IBackupItem[] = [];
    switch (type) {
      case 'form':
        if (all) {
          selected = formList.map((e: IForm) => { return { id: e.id, type: type } }) as IBackupItem[];
          selected = [...selected, ...selectedBackupItem];
        } else {
          selected = selectedBackupItem.filter((e: IBackupItem) => { return e.type !== type })
        }
        break;
      case 'database':
        if (all) {
          selected = dataTableList.map((e: IDataTable) => { return { id: e.id, type: type } }) as IBackupItem[]
          selected = [...selected, ...selectedBackupItem]
        } else {
          selected = selectedBackupItem.filter((e: IBackupItem) => { return e.type !== type })
        }
        break;
      default:
        break;
    }

    // selected = [...selected];
    dispatch(setSelectAllBPDT({ [type]: all }))
    dispatchSelectedItem(selected)
  }

  const dispatchSelectedItem = (selected: IBackupItem[]) => {
    let query = '';
    for (const item of selected) {
      query += `&${item.type}Ids=${item.id}`
    }
    dispatch(setExportBackupItem(selected));
    dispatch(getBackupRefsAction({ company: company, query: query.substring(1), selectedBackupItem: selected }));
  }

  const isSelected = (item: IBackupItem): boolean => {
    const isSelected = !!selectedBackupItem.find((backupItem: IBackupItem) => backupItem.id === item.id);
    const isBackupRef = isSelectedAsBackupRef(item);
    return isSelected || isBackupRef;
  }

  const isSelectedAsBackupRef = (item: IBackupItem): boolean => {
    let isSelected = false;

    for (const ref of backupRefs) {
      if (ref.requires) {
        for (const req of ref.requires) {
          if (req.id === item.id && !selectedBackupItem.find((item: IBackupItem) => item.id === req.id)) {
            isSelected = true
          }
        }
      } else if (ref.id === item.id && !selectedBackupItem.find((item: IBackupItem) => item.id === ref.id)) {
        isSelected = true;
      }
    };
    return isSelected;
  }

  return (
    <div>
      <label className="file-to-export-label">Select which data to export</label>
      <div className="export-bp-list-container">
        <div
          className="ui checkbox"
          onClick={() => onSelectAll({ type: 'form', all: !selectAll.form })}>
          <input type="checkbox" className="hidden"
            checked={selectAll.form}
          />
          <label className="file-to-export-label">All BP's</label>
        </div>
        <div className='export-bp-list'>
          {formList && formList.map((e: IForm, idx: number) => {
            return (
              <div key={idx}>
                <div
                  className={`ui checkbox ${isSelectedAsBackupRef({ id: e.id, type: 'form' } as IBackupItem) ? ' disabled disabled-field' : ''}`}
                  onClick={() => onSelect({ id: e.id, type: 'form' } as IBackupItem)}>
                  <input type="checkbox" className="hidden"
                    checked={isSelected({ id: e.id, type: 'form' } as IBackupItem)}
                  />
                  <label>{e.name}</label>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="export-bp-list-container">
        <div
          className="ui checkbox"
          onClick={() => onSelectAll({ type: 'database', all: !selectAll.database })}>
          <input type="checkbox" className="hidden"
            checked={selectAll.database}
          />
          <label className="file-to-export-label">All DT's</label>
        </div>
        <div className='export-bp-list'>
          {dataTableList && dataTableList.map((e: IDataTable, idx: number) => {
            return (
              <div key={idx}>
                <div
                  className={`ui checkbox ${isSelectedAsBackupRef({ id: e.id, type: 'database' } as IBackupItem) ? ' disabled disabled-field' : ''}`}
                  onClick={() => onSelect({ id: e.id, type: 'database' })}>
                  <input type="checkbox" className="hidden"
                    checked={isSelected({ id: e.id, type: 'database' } as IBackupItem)}
                  />
                  <label>{e.name}</label>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default ExportBackup;

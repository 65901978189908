import React from 'react';
import { IDuplicateCheckerData } from '../interface/duplicate-checker';

interface IFieldsManager {
  duplicateCheckerData: IDuplicateCheckerData;
  onDuplicateCheckerChange(value: boolean): void;
}

const FieldsManager: React.FC<IFieldsManager> = (props) => {
  return <div className='field-manager-container'>
    <div className='activate-checker' onClick={() => {
      props.onDuplicateCheckerChange(props.duplicateCheckerData.isDuplicateChecker)
    }}>
      <span>Activate Duplicate Checker</span>
      <div className="ui fitted checkbox">
        <input type="checkbox"
          className="hidden"
          onChange={() => { }}
          checked={!!props.duplicateCheckerData.isDuplicateChecker}
        />
        <label></label>
      </div>
    </div>
  </div>

}

export default FieldsManager;
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import React, { useEffect, useRef, useState } from 'react';
import {
  dayOptionsList,
  frequencyOptionsList,
  hourOptionsList,
  minuteOptionsList,
  monthOptionsList,
  weekOptionsList
} from './options';
import './style.scss';
import { Button } from 'semantic-ui-react-bpm';
import * as cronParser from 'cron-parser';

type PeriodType =
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'reboot';

interface Props {
  value: string;
  setValue(value: string): void;
  reset: boolean;
  triggerResetButton(): void;
}

const CronGenerator: React.FC<Props> = (props) => {
  const { setValue, value, reset, triggerResetButton } = props;

  const defaultCronExpression = '* * * * *';
  const defaultInterval = cronParser.parseExpression(defaultCronExpression);
  const defaultIntervalFields = JSON.parse(JSON.stringify(defaultInterval.fields));

  const hasChange = useRef(false);

  const [minuteSelectedList, setMinuteSelectedList] = useState<number[]>([]);
  const [hourSelectedList, setHourSelectedList] = useState<number[]>([]);
  const [daySelectedList, setDaySelectedList] = useState<number[]>([]);
  const [weekSelectedList, setWeekSelectedList] = useState<number[]>([]);
  const [monthSelectedList, setMonthSelectedList] = useState<number[]>([]);
  const [period, setPeriod] = useState<PeriodType>();

  const updateValuesFromExpression = (selectedValuesList: number[], unitOfTime: string) => {
    const allSelected = JSON.stringify(defaultIntervalFields[unitOfTime]) === JSON.stringify(selectedValuesList);

    switch (unitOfTime) {
      case 'minute':
        if (!allSelected) {
          setMinuteSelectedList(selectedValuesList);
        }
        break;

      case 'hour':
        if (!allSelected) {
          setHourSelectedList(selectedValuesList);
        }
        break;

      case 'dayOfMonth':
        if (!allSelected) {
          setDaySelectedList(selectedValuesList);
        }
        break;

      case 'dayOfWeek':
        if (!allSelected) {
          setWeekSelectedList(selectedValuesList);
        }
        break;

      case 'month':
        if (!allSelected) {
          setMonthSelectedList(selectedValuesList);
        }
        break;

      default:
        return null;
    }
  }

  const updateSelectedValues = (selectedValuesList: any[], unitOfTime: string) => {
    hasChange.current = true;

    switch (unitOfTime) {
      case 'minute':
        setMinuteSelectedList(selectedValuesList);
        break;

      case 'hour':
        setHourSelectedList(selectedValuesList);
        break;

      case 'day':
        setDaySelectedList(selectedValuesList);
        break;

      case 'week':
        setWeekSelectedList(selectedValuesList);
        break;

      case 'month':
        setMonthSelectedList(selectedValuesList);
        break;

      default:
        return null;
    }
  }

  const renderMonthDayPicker = () => {
    return (
      <div className="month-days-picker field">
        <span className="supporting-words">
          on
        </span>
        <div className="form-control-wrapper">
          <MultiSelectComponent
            fields={{ text: 'text', value: 'value' }}
            dataSource={dayOptionsList}
            closePopupOnSelect={false}
            showClearButton
            id='cron-grid-picker_monthday'
            ref={(ref: any) => {
              if (ref) {
                ref.inputElement.readOnly = true;
              }
            }}
            value={daySelectedList}
            cssClass='e-cron-select'
            hideSelectedItem={false}
            mode='Delimiter'
            placeholder='every day of the month'
            onChange={(event: any) => {
              updateScheduleConfiguration(event.value, 'day');
            }}
            changeOnBlur={false}
            showDropDownIcon
            title='every day of the month'
          />
        </div>
      </div>
    );
  }

  const renderMonthPicker = () => {
    return (
      <div className="month-picker field">
        <span className="supporting-words">in</span>
        <div className="form-control-wrapper">
          <MultiSelectComponent
            fields={{ text: 'text', value: 'value' }}
            dataSource={monthOptionsList}
            closePopupOnSelect={false}
            showClearButton
            id='cron-normal-picker_month'
            ref={(ref: any) => {
              if (ref) {
                ref.inputElement.readOnly = true;
              }
            }}
            value={monthSelectedList}
            cssClass='e-cron-select'
            hideSelectedItem={false}
            mode='Delimiter'
            placeholder='every month'
            onChange={(event: any) => {
              updateScheduleConfiguration(event.value, 'month');
            }}
            changeOnBlur={false}
            showDropDownIcon
            title='every month'
          />
        </div>
      </div>
    );
  }

  const renderWeekPicker = () => {
    return (
      <div className="week-picker field">
        <span className="supporting-words">
          {periodForRender === 'year' || periodForRender === 'month' ? 'and' : 'on'}
        </span>
        <div className="form-control-wrapper">
          <MultiSelectComponent
            fields={{ text: 'text', value: 'value' }}
            dataSource={weekOptionsList}
            closePopupOnSelect={false}
            showClearButton
            id='cron-normal-picker_week'
            ref={(ref: any) => {
              if (ref) {
                ref.inputElement.readOnly = true;
              }
            }}
            value={weekSelectedList}
            cssClass='e-cron-select'
            hideSelectedItem={false}
            mode='Delimiter'
            placeholder='every day of week'
            onChange={(event: any) => {
              updateScheduleConfiguration(event.value, 'week');
            }}
            changeOnBlur={false}
            showDropDownIcon
            title='every day of week'
          />
        </div>
      </div>
    );
  }

  const renderHourPicker = () => {
    return (
      <div className="hour-picker field">
        <span className="supporting-words">at</span>
        <div className="form-control-wrapper">
          <MultiSelectComponent
            fields={{ text: 'text', value: 'value' }}
            dataSource={hourOptionsList}
            closePopupOnSelect={false}
            showClearButton
            id='cron-grid-picker_hour'
            ref={(ref: any) => {
              if (ref) {
                ref.inputElement.readOnly = true;
              }
            }}
            value={hourSelectedList}
            cssClass='e-cron-select'
            hideSelectedItem={false}
            mode='Delimiter'
            placeholder='every hour'
            onChange={(event: any) => {
              updateScheduleConfiguration(event.value, 'hour');
            }}
            changeOnBlur={false}
            showDropDownIcon
            title='every hour'
          />
        </div>
      </div>
    );
  }

  const renderMinutePicker = () => {
    return (
      <div className="minute-picker field">
        <span className="supporting-words">
          {periodForRender === 'hour' ? 'at' : ''}
        </span>
        <div className="form-control-wrapper">
          <MultiSelectComponent
            fields={{ text: 'text', value: 'value' }}
            dataSource={minuteOptionsList}
            closePopupOnSelect={false}
            showClearButton
            id='cron-grid-picker_minute'
            ref={(ref: any) => {
              if (ref) {
                ref.inputElement.readOnly = true;
              }
            }}
            value={minuteSelectedList}
            cssClass='e-cron-select'
            hideSelectedItem={false}
            mode='Delimiter'
            placeholder={periodForRender === 'hour' ? 'every' : 'every minute'}
            onChange={(event: any) => {
              updateScheduleConfiguration(event.value, 'minute');
            }}
            changeOnBlur={false}
            showDropDownIcon
            title={periodForRender === 'hour' ? 'every' : 'every minute'}
          />
        </div>
        {periodForRender === 'hour' && (
          <span className="supporting-words">
            minute(s)
          </span>
        )}
      </div>
    );
  }

  const updateScheduleConfiguration = (selected: any[], unitOfTime: string) => {
    let selectedValuesList = selected.map(value => parseInt(value));
    updateSelectedValues(selectedValuesList, unitOfTime);
  }

  const clearScheduleConfiguration = (type: 'onValueChange' | 'onChange') => {
    setMinuteSelectedList([]);
    setHourSelectedList([]);
    setDaySelectedList([]);
    setWeekSelectedList([]);
    setMonthSelectedList([]);
    if (type === 'onChange') {
      const interval = cronParser.parseExpression('* * * * *');
      const fields = JSON.parse(JSON.stringify(interval.fields));
      const modifiedInterval = cronParser.fieldsToExpression(fields);
      const cronString = modifiedInterval.stringify();
      setValue(cronString);
    }
  }

  const getPeriodFromCronparts = (parsedIntervalFields: { [unitOfTime: string]: number[] }): PeriodType => {
    if (parsedIntervalFields['hour'] &&
      parsedIntervalFields['hour'].length > 0) {
      return 'day';
    }
    if (parsedIntervalFields['minute'] &&
      parsedIntervalFields['minute'].length > 0) {
      return 'hour';
    }
    if (parsedIntervalFields['dayOfWeek'] &&
      parsedIntervalFields['dayOfWeek'].length > 0) {
      return 'week';
    }
    if (parsedIntervalFields['dayOfMonth'] &&
      parsedIntervalFields['dayOfMonth'].length > 0) {
      return 'month';
    }
    if (parsedIntervalFields['month'] &&
      parsedIntervalFields['month'].length > 0) {
      return 'year';
    }
    return 'minute';
  }

  useEffect(() => {
    if (reset) {
      clearScheduleConfiguration('onValueChange');
      setPeriod('minute');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  useEffect(() => {
    const interval = cronParser.parseExpression(value);
    const fields = JSON.parse(JSON.stringify(interval.fields));
    const intervalFields = Object.entries(fields).reduce((acc: any, [key, val]: any) => {
      const allSelected = JSON.stringify(defaultIntervalFields[key]) === JSON.stringify(val);
      if (!allSelected) { acc[key] = val; }
      return acc;
    }, {});
    Object.keys(fields).forEach(unitOfTime => {
      updateValuesFromExpression(fields[unitOfTime], unitOfTime);
    });
    setPeriod(getPeriodFromCronparts(intervalFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (hasChange.current) {

      const interval = cronParser.parseExpression('* * * * *');
      const fields = JSON.parse(JSON.stringify(interval.fields));

      fields.minute = minuteSelectedList.length ? minuteSelectedList : defaultIntervalFields.minute;
      fields.hour = hourSelectedList.length ? hourSelectedList : defaultIntervalFields.hour;
      fields.dayOfMonth = daySelectedList.length ? daySelectedList : defaultIntervalFields.dayOfMonth;
      fields.dayOfWeek = weekSelectedList.length ? weekSelectedList : defaultIntervalFields.dayOfWeek;
      fields.month = monthSelectedList.length ? monthSelectedList : defaultIntervalFields.month;

      const modifiedInterval = cronParser.fieldsToExpression(fields);
      const cronString = modifiedInterval.stringify();

      setValue(cronString);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      minuteSelectedList,
      hourSelectedList,
      daySelectedList,
      weekSelectedList,
      monthSelectedList,
    ]
  )

  const periodForRender = period;

  return (
    <div className="cron-tab-container">
      <div className="supporting-words">Run rule every </div>
      <div className="form-control-wrapper">
        <DropDownListComponent
          fields={{ text: 'text', value: 'value' }}
          dataSource={frequencyOptionsList}
          id='cron-normal-picker_period'
          ref={(ref: any) => {
            if (ref) {
              ref.value = periodForRender;
              ref.inputElement.readOnly = true;
            }
          }}
          cssClass='e-cron-select'
          onChange={(event: any) => {
            setPeriod(event.value);
            clearScheduleConfiguration('onChange');
          }}
        />
      </div>
      <div className={`form-control-wrapper ${periodForRender}`}>
        <div className='e-day-month-picker'>

          {periodForRender === 'year' && (
            renderMonthPicker()
          )}

          {(periodForRender === 'year' || periodForRender === 'month') && (
            renderMonthDayPicker()
          )}

          {(periodForRender === 'year' ||
            periodForRender === 'month' ||
            periodForRender === 'week') && (
              renderWeekPicker()
            )}

        </div>

        <div className='e-time-picker'>

          {periodForRender !== 'minute' && periodForRender !== 'hour' && (
            renderHourPicker()
          )}

          {periodForRender !== 'minute' && (
            renderMinutePicker()
          )}

        </div>
      </div>
      <Button className='e-btn-clear' type='primary' onClick={() => triggerResetButton()}>
        Reset
      </Button>
    </div>
  )
}

export default CronGenerator;
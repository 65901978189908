import React, { Fragment, useState } from 'react';
import RoleActions from './role-actions';
import { Icon, Image } from 'semantic-ui-react-bpm';
import { IFormStatus, IFormStatusWithAction } from '../../workflow/interface/form-status';
import { IFormRole } from '../interface/form-role';
import { IFormAction } from '../../workflow/interface/form-action';
import ToolTip from '../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../common/icons';
import { IConstraint } from '../interface/constraint';
import { Group, IUserGroup } from '../../../../groups/interface/group';
import { setLabelRef } from '../../../../../../../common/utils/check-text-overflow';

interface ITableListDetail {
  statusList: IFormStatus[];
  currentRoleList: IFormRole[];
  setRoleAction(status: IFormStatusWithAction, role: IFormRole): void;
  deleteRole(forDeleteRole: IFormRole): void;
  constraintList: IConstraint[];
  groupList: Group[];
}

interface IGroupTypeRole {
  role: IFormRole;
  groupList: Group[];
}

const GroupTypeRoleComponent: React.FC<IGroupTypeRole> = (props) => {
  const [expandGroup, setExpandGroup] = useState(false);
  const groupList = props.groupList.filter(g => g.id === props.role.linkedId)
  const expandIcon = expandGroup ? 'chevron up' : 'chevron down';

  return <div key={props.role.id} className={`group-role-list ${expandGroup ? 'expand' : 'collapse'}`}>
    <span className='role-name'>
      {props.role.name}
      {!props.role.disabled &&
        <Icon onClick={() => setExpandGroup(!expandGroup)}
          className='expand-group' name={expandIcon} />
      }
    </span>
    {expandGroup && groupList.length > 0 &&
      <span className='list-container' >
        {groupList.map((group: Group) => {
          const { users } = { ...group };
          return <Fragment key={group.id}>
            {users && users.map((user: IUserGroup) => {
              const name = `${user.firstName || ''} ${user.lastName || ''}`;
              return <label ref={(element) => setLabelRef(element, '')} className='list' key={user.id}>
                {name}
              </label>
            })}
          </Fragment>
        })}
      </span>
    }
  </div>
}

const TableListDetail: React.FC<ITableListDetail> = (props) => {

  return <>
    {
      props.currentRoleList.map((role: IFormRole) => {
        if (role.deleted) {
          return undefined;
        }
        let inactiveStyle = {};
        if (role.active === false) {
          inactiveStyle = { backgroundColor: '#f1d3d3' }
        }
        const name = role.name ? role.name.replace('null', '') : '';
        return <React.Fragment key={`table-list-detail-role-list-${role.id}`}>
          <tr className={role.disabled ? 'disabled' : ''}>
            <td style={{
              color: '#3D87AB',
              fontWeight: 'bold',
              ...inactiveStyle
            }}>
              {role.type === 'group' ? <GroupTypeRoleComponent
                groupList={props.groupList}
                role={role} />
                : name}
            </td>
            {
              props.statusList.map((status: IFormStatus) => {
                const hasAction = role.actions.filter((action: IFormAction) => action.statusId === status.id)
                if (hasAction.length > 0) {
                  return <td key={`table-list-detail-columne-status-list-${status.id}`}
                    style={{ verticalAlign: 'top' }}>
                    <RoleActions
                      constraintList={props.constraintList}
                      actions={hasAction}
                      setRoleAction={props.setRoleAction}
                      status={status}
                      role={role}
                    />
                  </td>
                } else {
                  return <td key={`table-list-detail-columne-status-list-${status.id}`}
                    onClick={() => props.setRoleAction(status as IFormStatusWithAction, role)} style={{ verticalAlign: 'top' }}>
                    <Icon link name='ellipsis vertical' style={{ float: 'right', color: 'transparent', cursor: 'default' }} id={`table-role-detail-${role.id}-${status.id}`} />
                  </td>
                }
              })
            }
            <td>
              {!role.disabled &&
                <ToolTip position='left center'
                  popupMessage='Delete Role'
                  popupTriggerComponent={
                    <Image src={icons.blue.delete}
                      style={{
                        width: '12px',
                        cursor: 'pointer',
                        margin: '0px 5px',
                        display: 'inline-block'
                      }} onClick={() => props.deleteRole(role)} name='trash alternate outline' />
                  }
                />
              }
            </td>
          </tr>
        </React.Fragment>
      })
    }
  </>
}

export default TableListDetail;
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import MobileSectionContentLower from './lower-content';
import MobileSectionContentUpper from './upper-content/index';
import MobileSectionTitle from './title';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../../section-field-manager/interface/section-update-config';
import { ITableWidgetConfig } from '.';

interface IMobileSection {
  field: ICustomFieldDetail;
  columns: ICustomFieldDetail[];
  updateDetailList(data: ICustomFieldDetail, fieldId: string, manualUpdateConfig?: ISectionUpdateConfig): void;
  setUpdateConfig: (value: ISectionUpdateConfig) => void;
  tableWidgetConfig: { [id: string]: ITableWidgetConfig }
  updateConfig(fieldId: string, value: ITableWidgetConfig): void;
  resetConfig(field: ICustomFieldDetail): void;
  triggerCollapse(value: boolean, fieldId: string): void;
  collapse: boolean;
}

const MobileSection: React.FC<IMobileSection> = (props) => {

  const tableMobileView = props.tableWidgetConfig[props.field.id || ''].tableMobileView;
  const additionalFields = props.tableWidgetConfig[props.field.id || ''].extraFields;
  const enabled = props.tableWidgetConfig[props.field.id || ''].enabled;

  const triggerShowConfig = (value: boolean) => {
    const tempTableMobileView = { ...tableMobileView };
    props.updateConfig(props.field.id || '', {
      extraFields: additionalFields,
      tableMobileView: tempTableMobileView,
      enabled: value,
    });
  }

  const updateTableMobileView = (property: string, value: any) => {
    const tempTableMobileView = { ...tableMobileView };
    tempTableMobileView[property] = value;
    props.updateConfig(props.field.id || '', {
      extraFields: additionalFields,
      tableMobileView: tempTableMobileView,
      enabled: enabled,
    });
  }

  const updateAdditionalFields = (value: string, index: number) => {
    const tempAdditionalFields = [...additionalFields];
    tempAdditionalFields[index] = value;
    props.updateConfig(props.field.id || '', {
      tableMobileView,
      extraFields: tempAdditionalFields,
      enabled: enabled,
    });
  }

  const changeFieldSequence = (field: string, dragIndex: number, dropIndex: number) => {
    const tempAdditionalFields = [...additionalFields];
    tempAdditionalFields.splice(dragIndex, 1);
    tempAdditionalFields.splice(dropIndex, 0, field);
    props.updateConfig(props.field.id || '', {
      tableMobileView,
      extraFields: tempAdditionalFields,
      enabled: enabled,
    });
  }

  const addAdditionalFields = () => {
    const tempAdditionalFields = [...additionalFields];
    tempAdditionalFields.push('');
    props.updateConfig(props.field.id || '', {
      tableMobileView,
      extraFields: tempAdditionalFields,
      enabled: enabled,
    });
  }

  return <div className="mobile-section-container">
    <div className={`mobile-section ${!enabled ? 'disabled' : ''}`}>
      <MobileSectionTitle
        setCollapse={props.triggerCollapse}
        collapse={props.collapse}
        field={props.field}
        showConfig={enabled === true}
        setShowConfig={triggerShowConfig}
      />
      {!props.collapse &&
        <div className={props.collapse ? 'hidden' : ''}>
          <div className='content'>
            <MobileSectionContentUpper
              tableMobileViewList={tableMobileView}
              updateTableMobileView={updateTableMobileView}
              columns={props.columns}
              additionalFields={additionalFields}
            />
            <MobileSectionContentLower
              columns={props.columns}
              additionalFields={additionalFields}
              updateAdditionalFields={updateAdditionalFields}
              changeFieldSequence={changeFieldSequence}
              addAdditionalFields={addAdditionalFields}
              tableMobileViewList={tableMobileView}
            />
          </div>
          <div className='action'>
            {props.field.mobileConfig && <Button className='red'
              onClick={() => props.resetConfig(props.field)}>Delete</Button>}
          </div>
        </div>
      }
    </div>
  </div>
}

export default MobileSection;

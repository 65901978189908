import { useEffect } from 'react';
import { IOptionValue } from '../../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../../service/field-properties.service';
import { EnumProps } from '../../../../service/type/enum';
import { EnumMultiselectProps } from '../../../../service/type/enum-multiselect';
import { AcceptedProps } from '../../../interface/accepted-props';
import { ISetConstraintInitialValueProps } from '../util/set-constraint-initial-value';

interface IProps {
  props: AcceptedProps;
  elementProperties: FieldProperties<EnumProps> | FieldProperties<EnumMultiselectProps>;
  refresher: number;
  setConstraintInitialValue(value: ISetConstraintInitialValueProps): void;
  setDefaultValue(): void;
  getOption(props: AcceptedProps): IOptionValue[];
  setForceReadOnlyElement(value: boolean): void;
  setRefresher(value: number): void;
}

export const useIsAddRecord = ({
  props,
  setConstraintInitialValue,
  setDefaultValue,
  getOption,
  setForceReadOnlyElement,
  setRefresher,
  elementProperties,
  refresher
}: IProps) => {
  useEffect(() => {
    if (props.isAddRecord) {
      if (props.flags && props.flags.indexOf('constraint') > -1) {
        setConstraintInitialValue({
          getOption,
          currentProps: props,
          setForceReadOnlyElement,
          setRefresher,
          elementProperties,
          refresher
        });
      } else {
        setDefaultValue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAddRecord])
}
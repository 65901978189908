import { useEffect } from 'react';
import { getFormBuilderDetailAction } from '../action';

interface IProps {
  dispatch: any;
  formId: string;
  companyId: string;
  getConfigOfFieldsFromModule(): void;
}

export const useGetFormBuilderConfig = (props: IProps): void => {
  const { dispatch, formId, companyId, getConfigOfFieldsFromModule } = { ...props };

  useEffect(() => {
    if (formId && companyId) {
      dispatch(getFormBuilderDetailAction({ company: companyId, formId: formId }));
      getConfigOfFieldsFromModule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, companyId]);

}
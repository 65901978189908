import React, { useRef, useState } from 'react';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react-bpm';
import { IRootState } from '../../../../../../../../../reducers';
import { useSetPDFTemplateFormStateAndRefs } from './hooks/use-set-pdf-template-form-state';
import { IPdfTemplateConfig } from '../interface/pdf';
import { updateLocalPdfTemplateListAction } from '../action';
import { v4 as uuid } from 'uuid';

interface IPDFTemplateFormsProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const errorIndicator = {
  name: false,
  template: false
}

const PDFTemplateForms: React.FC<IPDFTemplateFormsProps> = (props) => {
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const nameRef = useRef(document.createElement('input'));
  const templateRef = useRef(document.createElement('textarea'));

  const { pdfTemplateConfigList } = useSelector((state: IRootState) => state.configurationPDFTemplate);

  const [error, setError] = useState(errorIndicator);

  const { cancelClick } = useSetPDFTemplateFormStateAndRefs({
    history,
    match,
    nameRef,
    templateRef,
    pdfTemplateConfigList,
    pdfTemplateId: match.params.pdfTemplateId,
  });

  const manageData = (tempPdfTemplateList: IPdfTemplateConfig[]) => {
    if (match.params.pdfTemplateId !== 'new-template') {
      const pdfTemplateId = match.params.pdfTemplateId;
      const currentData = tempPdfTemplateList.find((e: IPdfTemplateConfig) => e.id === pdfTemplateId);
      if (currentData) {
        const dataIndex = tempPdfTemplateList.indexOf(currentData)
        const data: IPdfTemplateConfig = {
          ...currentData,
          name: nameRef.current.value,
          template: templateRef.current.value
        }
        tempPdfTemplateList.splice(dataIndex, 1, data);
      }
    } else {
      tempPdfTemplateList.push({
        id: uuid(),
        name: nameRef.current.value,
        template: templateRef.current.value
      });
    }
    tempPdfTemplateList = tempPdfTemplateList.map((e: IPdfTemplateConfig, index: number) => {
      const f = { ...e };
      return f;
    });
    return tempPdfTemplateList;
  }

  const savePDFTemplateConfig = () => {
    let tempPdfTemplateList = [...pdfTemplateConfigList.map((e: IPdfTemplateConfig) => { const f = { ...e }; return f; })];

    if (!validateField()) {
      return;
    }
    dispatch(updateLocalPdfTemplateListAction(manageData(tempPdfTemplateList)));
    cancelClick();
    props.hasPageChangeRef.current.hasChange = true;
  }

  const validateField = () => {
    const tempError = { ...error };
    let status = true;

    tempError.name = false;
    if (!nameRef.current.value) {
      tempError.name = true;
      status = false;
    }
    tempError.template = false;
    if (!templateRef.current.value) {
      tempError.template = true;
      status = false;
    }

    setError(tempError)
    return status;
  }

  return <>
    <div id='pdf-template-form-modal-container'>
      <div className='pdf-template-container'>
        <div className={`header`}>
          <label>{match.params.pdfTemplateId !== 'new-template' ? 'Update PDF Template' : 'New PDF Template'}</label>
        </div>
        <Form size='large' className={`form-container`} >
          <Form.Group widths='equal'>
            <div className={`required six wide field ${error.name ? 'error' : ''}`}>
              <label>Template Name</label>
              <div className={`ui input`}>
                <input ref={nameRef} type="text" />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal' className='textarea'>
            <div className={`required 12 wide field ${error.template ? 'error' : ''}`}>
              <label>Template Text</label>
              <div className={`ui input`}>
                <textarea ref={(curRef) => {
                  if (curRef) {
                    curRef.value = templateRef.current.value || '';
                    return templateRef.current = curRef;
                  }
                }}>
                </textarea>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
    <div className={`pdf-form-action`}>
      <Button color='green' className='btn-action btn-success' onClick={savePDFTemplateConfig}>
        {match.params.pdfTemplateId !== 'new-template' ? 'Update' : 'Create'}
      </Button>
      <Button color='grey' className='btn-action btn-default' onClick={() => cancelClick()}>{'Cancel'}</Button>
    </div>
  </>
}

export default PDFTemplateForms;
import { call, put, takeLatest } from 'redux-saga/effects';
import { setStatusCodeAction } from '../../../error/action';
import { getRequest, postRequest, putRequest, deleteRequest, IAxiosResponse } from '../../../../common/api-request';
import {
  GET_FORM, GET_FORM_FIELD, GET_DOCUMENT_ACTION,
  SAVE_DOCUMENT, TRIGGER_FIELD_TO_UPDATE, GET_COMMENTS, SAVE_COMMENT,
  GET_DOCUMENTS_DUPLICATE, DELETE_COMMENT, GET_COMMENT_SENDER,
  GET_DOCUMENTS_ACTION_CONFIG, GET_INDIVIDUAL_USERS_LIST,
  GET_DOCUMENTS_FOLLOWERS, FOLLOW_DOCUMENT, UNFOLLOW_DOCUMENT,
  DELETE_DOCUMENT, DELETE_COMMENT_ATTACHMENT, GET_RELATED_FORMS,
  GET_RELATED_DOCUMENTS,
  GET_RELATED_DATATABLE,
  GET_FORM_FIELD_SHORTCUT,
  GET_FORM_SHORTCUT,
  TRIGGER_FIELD_TO_UPDATE_SHORTCUT,
  GET_DOCUMENT_ACTION_SHORTCUT,
  GET_INDIVIDUAL_USERS_LIST_SHORTCUT,
  SAVE_DOCUMENT_SHORTCUT,
  RESET_REFERENCE_FORM_FIELD,
  GET_AUTOMATION_CONFIG,
  GET_AUTOMATION_CONFIG_SHORTCUT
} from './constant';
import { IFormGetInput } from './interface/input/form-get';
import {
  getFormReturnAction, getFormFieldReturnAction,
  getDocumentButtonReturnAction, saveDocumentReturnAction, saveDocumentErrorAction,
  fieldTriggerUpdateResponseAction, getCommentListReturnAction, saveCommentReturnAction,
  getDocumentsDuplicateReturnAction, updateCommentReturnAction, deleteCommentReturnAction,
  getCommentSenderReturnAction, getDocumentActionConfigReturnAction, getIndividualUsersListReturnAction,
  getDocumentFollowersReturnAction, unfollowDocumentReturnAction, deleteDocumentReturnAction,
  deleteDocumentErrorAction, getRelatedFormsReturnAction, getRelatedDocumentsReturnAction,
  getRelatedDatatableReturnAction, getFormFieldShortcutReturnAction, getFormShortcutReturnAction,
  fieldTriggerUpdateShortcutResponseAction, getDocumentButtonShortcutReturnAction,
  getIndividualUsersListShortcutReturnAction, saveDocumentShortcutReturnAction,
  getDocumentsDuplicateShortcutReturnAction, resetReferenceFormFieldReturnAction,
  getClientAutomationConfigReturnAction, getClientAutomationConfigShortcutReturnAction,
  getIndividualUserListErrorAction, getIndividualUserListShortcutErrorAction,
  saveDocumentShortcutErrorAction, getFormErrorAction
} from './action';
import { IDocumentActionGetInput } from './interface/input/document-action-get';
import { IDocumentSaveInput } from './interface/input/document-save';
import { IFormStatusWithAction } from '../../../admin-module/module/business-process/module/workflow/interface/form-status';
import { IFormFieldGetInput } from './interface/input/form-field-get';
import { IFormDocument } from '../report/interface/form-document';
import { IField } from '../../../admin-module/module/users/interface/field';
import { IReloadFieldInput } from './interface/input/field-trigger-reload';
import { IGetCommentInput, IGetSenderListInput } from './interface/input/comment-get';
import { ICreateCommentInput } from './interface/input/comment-create';
import { IDocsDuplicateGetInput } from './interface/input/filter-documents';
import { IDeleteCommentInput } from './interface/input/comment-delete';
import { sortByCreationDate } from '../../../../common/utils/sort-by-creation-date';
import { IFollowDocumentInput } from './interface/input/follow-document';
import { IGetDocumentsFollowersInput } from './interface/input/follower-get';
import { IDocumentDeleteInput } from './interface/input/document-delete';
import { IAttachmentDeleteInput } from './interface/input/attachment-delete';
import { IRelatedDocumentsInput, IRelatedFormsInput } from './interface/input/related-form';
import { v4 as uuid } from 'uuid';
import { IGetIndividualUserInput } from './interface/input/individual-user-get';
import { IRelatedFormField, IRelatedFormRecord } from './interface/related-form';
import { SanitizeBPR } from '../../../../common/utils/sanitize-bpr';
import { IGetAutomationConfigInput } from './interface/input/automation-config-get';
import { message } from './message';
import { setFieldReloadInput } from './utils/field-reload-input';

export function* getForm(args: IFormGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}?draft=false`, {});
    yield put(getFormReturnAction({ formDetail: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormShortcut(args: IFormGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}?draft=false`, {});
    yield put(getFormShortcutReturnAction({ formDetail: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormField(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const documentId = args.documentId
    let field = [] as IField[];
    let formStatus = {} as IFormStatusWithAction;
    let documentDetail = {} as IFormDocument;
    if (documentId === 'new') {
      const fieldResponse: IAxiosResponse = yield call(postRequest, `${company}/documents/fields?formId=${formId}`, {});
      field = fieldResponse.data.data;
      const initailStatus: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/statuses/initial?load=actions`, {});
      formStatus = initailStatus.data.data;
    } else {
      const document: IAxiosResponse = yield call(getRequest, `${company}/documents/${documentId}?load=creator&load=updatedBy&load=form&load=slaStatus`, {});
      documentDetail = document.data.data;
      if (documentDetail) {
        const status: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/statuses/${documentDetail.statusId}?load=actions`, {});
        formStatus = status.data.data;
        const fieldResponse: IAxiosResponse = yield call(postRequest, `${company}/documents/fields?formId=${formId}&statusId=${documentDetail.statusId}&documentId=${documentId}`, {});
        field = fieldResponse.data.data;
        const fieldReload = setFieldReloadInput(field, documentDetail, formStatus, args.company);
        const documentQuery = documentDetail && documentDetail.id ? `&documentId=${documentDetail.id}` : '';
        const statusQuery = formStatus && formStatus.id ? `&statusId=${formStatus.id}` : '';
        if (fieldReload && Object.keys(fieldReload).length) {
          let fieldId = '';
          if (fieldReload.fieldId && fieldReload.fieldId.length > 0) {
            fieldReload.fieldId.forEach((id: string) => {
              fieldId += `&ids[]=${id}`;
            });
            const fieldReloadResponse: IAxiosResponse = yield call(postRequest, `${company}/documents/fields?formId=${formId}${documentQuery}${statusQuery}${fieldId}`,
              { fields: fieldReload.fields }
            );
            field = field.map(data => {
              const update = fieldReloadResponse.data.data.find((e: IField) => e.id === data.id);
              if (update) {
                return update;
              }
              return data;
            });
          }
        }
      } else {
        yield put(getFormErrorAction(message.noAccessMessage))
      }
    }
    if (formStatus && Object.keys(formStatus).length) {
      const statusId = formStatus.id;
      const params = [];
      if (statusId) params.push(`statusId=${statusId}`);
      let paramsLink = params.join('&');
      if (paramsLink !== '') paramsLink = `?${paramsLink}`;
      const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/clientAutomations${paramsLink}`, {});
      yield put(getClientAutomationConfigReturnAction({ automationConfig: req.data.data }));
    }
    yield put(getFormFieldReturnAction({
      formField: field,
      formStatus: formStatus,
      documentDetail: documentDetail,
      companyId: args.company
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getFormFieldShortcut(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    let field = [] as IField[];
    let formStatus = {} as IFormStatusWithAction;
    let documentDetail = {} as IFormDocument;
    const fieldResponse: IAxiosResponse = yield call(postRequest, `${company}/documents/fields?formId=${formId}`, {});
    field = fieldResponse.data.data;
    const initailStatus: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/statuses/initial?load=actions`, {});
    formStatus = initailStatus.data.data;

    yield put(getFormFieldShortcutReturnAction({
      formField: field,
      formStatus: formStatus,
      documentDetail: documentDetail
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getDocumenAction(args: IDocumentActionGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/actions?formId=${formId}${documentQuery}`, {});
    yield put(getDocumentButtonReturnAction({ documentAction: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getDocumenActionShortcut(args: IDocumentActionGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/actions?formId=${formId}${documentQuery}`, {});
    yield put(getDocumentButtonShortcutReturnAction({ documentAction: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* saveDocument(args: IDocumentSaveInput) {
  try {
    const company = args.company;
    const actionId = args.actionId;
    const formId = args.formId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    yield call(postRequest, `${company}/documents/actions?formId=${formId}&actionId=${actionId}${documentQuery}`, args.data);
    yield put(saveDocumentReturnAction(args.actionType));
  } catch (e) {
    const error = { ...e as any };
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    if (error.response.data.documents !== undefined) {
      yield put(getDocumentsDuplicateReturnAction({ documentsDuplicate: error.response.data.documents, duplicateActionType: error.response.data.type }))
    } else {
      yield put(saveDocumentErrorAction(JSON.stringify(error.response.data.message)));
    }
  }
}

export function* saveDocumentShortcut(args: IDocumentSaveInput) {
  try {
    const company = args.company;
    const actionId = args.actionId;
    const formId = args.formId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    yield call(postRequest, `${company}/documents/actions?formId=${formId}&actionId=${actionId}${documentQuery}`, args.data);
    yield put(saveDocumentShortcutReturnAction(args.actionType));
  } catch (e) {
    const error = { ...e as any };
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    if (error.response.data.documents !== undefined) {
      yield put(getDocumentsDuplicateShortcutReturnAction({ documentsDuplicate: error.response.data.documents, duplicateActionType: error.response.data.type }))
    } else {
      yield put(saveDocumentShortcutReturnAction(JSON.stringify(error.response.data.message)));
    }
  }
}

export function* getDocumentActionConfig(args: any) {
  try {
    const company = args.company;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/actions?formId=${formId}${documentQuery}`, {});
    yield put(getDocumentActionConfigReturnAction({ documentActionConfig: req.data.data }))
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* triggerFieldToUpdate(args: IReloadFieldInput) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    const statusId = args.statusId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    let fieldId = '';
    if (args.fieldId && args.fieldId.length > 0) {
      args.fieldId.forEach((id: string) => {
        fieldId += `&ids[]=${id}`;
      })
    }
    const fieldResponse: IAxiosResponse = yield call(postRequest,
      `${company}/documents/fields?formId=${formId}${documentQuery}&statusId=${statusId}${fieldId}`,
      { fields: args.fields, inputs: args.inputs }
    );
    yield put(fieldTriggerUpdateResponseAction({
      formField: fieldResponse.data.data,
      valueFieldReload: args.fields,
      fxFieldReload: args.fxFieldReload,
      triggeredOnChange: args.triggeredOnChange,
      autopopulateFieldReload: args.autopopulateFieldReload
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* triggerFieldToUpdateShortcut(args: IReloadFieldInput) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    const statusId = args.statusId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    let fieldId = '';
    if (args.fieldId && args.fieldId.length > 0) {
      args.fieldId.forEach((id: string) => {
        fieldId += `&ids[]=${id}`;
      })
    }
    const fieldResponse: IAxiosResponse = yield call(postRequest,
      `${company}/documents/fields?formId=${formId}${documentQuery}&statusId=${statusId}${fieldId}`,
      { fields: args.fields, inputs: args.inputs }
    );
    yield put(fieldTriggerUpdateShortcutResponseAction({
      formField: fieldResponse.data.data,
      valueFieldReload: args.fields
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getComments(args: IGetCommentInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    let commentList = [];
    let hasAttachment = false;
    let hasSystemComment = false;
    let hasUserComment = false;
    if (documentId !== 'new') {
      let filterType = '';
      args.filterType.forEach((value) => {
        filterType = filterType + `&types[]=${value}`
      })
      let filterUser = '';
      args.filterByUser.forEach((value) => {
        filterUser = filterUser + `&senders[]=${value}`
      })

      const req: IAxiosResponse = yield call(getRequest,
        `${company}/documents/${documentId}/comments-history?limit=0${filterType}${filterUser}`,
        {}
      );
      commentList = req.data.data;
      const reqUser: IAxiosResponse = yield call(getRequest,
        `${company}/documents/${documentId}/comments-history?limit=1&types[]=user`,
        {}
      );
      hasUserComment = reqUser.data.data.length > 0;
      const reqSystem: IAxiosResponse = yield call(getRequest,
        `${company}/documents/${documentId}/comments-history?limit=1&types[]=system`,
        {}
      );
      hasSystemComment = reqSystem.data.data.length > 0;;
      const reqAttachment: IAxiosResponse = yield call(getRequest,
        `${company}/documents/${documentId}/comments-history?limit=1&types[]=attachments`,
        {}
      );
      hasAttachment = reqAttachment.data.data.length > 0;
    }
    yield put(getCommentListReturnAction({
      commentList: sortByCreationDate(commentList, true),
      hasAttachment,
      hasSystemComment,
      hasUserComment,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getCommentSender(args: IGetSenderListInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    let senderList = [];
    if (documentId !== 'new') {
      const req: IAxiosResponse = yield call(getRequest,
        `${company}/documents/${documentId}/comments-history/senders?limit=0`,
        {}
      );
      senderList = req.data.data;
    }
    yield put(getCommentSenderReturnAction({ senderList: senderList }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* saveComment(args: ICreateCommentInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    const text = args.text;
    const commentId = args.commentId;
    const attachments = args.attachment;
    if (commentId) {
      const req: IAxiosResponse = yield call(putRequest,
        `${company}/documents/${documentId}/comments/${commentId}`,
        { text: text, attachments: attachments && attachments.length > 0 ? attachments : undefined }
      );
      yield put(updateCommentReturnAction({ comment: req.data.data }));
    } else {
      const req: IAxiosResponse = yield call(postRequest,
        `${company}/documents/${documentId}/comments`,
        { text: text, attachments: attachments && attachments.length > 0 ? attachments : undefined }
      );
      yield put(saveCommentReturnAction({ comment: req.data.data }));
    }
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* deleteComment(args: IDeleteCommentInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    const commentId = args.commentId;
    yield call(deleteRequest,
      `${company}/documents/${documentId}/comments/${commentId}?limit=0`,
      {}
    );
    yield put(deleteCommentReturnAction({ commentId: commentId }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getDocumentsDuplicate(args: IDocsDuplicateGetInput) {
  try {
    let company = args.company;
    let filterData = args.filterData
    const filter: IAxiosResponse = yield call(postRequest, `${company}/documents/search?load=owner&load=statusName&load=form&limit=0`, filterData);
    yield put(getDocumentsDuplicateReturnAction({ documentsDuplicate: filter.data.data }))
  } catch (e) {
    const error = { ...e as any };
    console.log(error.response);
    yield put(setStatusCodeAction(e))
  }
}

export function* getDocumentFollowers(args: IGetDocumentsFollowersInput) {
  try {
    let company = args.company;
    let documentId = args.documentId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/${documentId}/followers`, {});
    yield put(getDocumentFollowersReturnAction({ documentFollowers: req.data.data }))
  } catch (e) {
    const error = { ...e as any };
    console.log(error.response);
    yield put(setStatusCodeAction(e))
  }
}

export function* followDocument(args: IFollowDocumentInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    const usersId = args.usersId;
    yield call(putRequest, `${company}/documents/${documentId}/follow/${usersId}`, {});
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/${documentId}/followers`, {});
    yield put(getDocumentFollowersReturnAction({ documentFollowers: req.data.data }))
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* unfollowDocument(args: IFollowDocumentInput) {
  try {
    const company = args.company;
    const documentId = args.documentId;
    const usersId = args.usersId;
    yield call(deleteRequest, `${company}/documents/${documentId}/unfollow/${usersId}`, {});
    yield put(unfollowDocumentReturnAction({ usersId: usersId }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getIndividualUsersList(args: IGetIndividualUserInput) {
  try {
    const company = args.companyId;
    const actionId = args.actionId;
    const formId = args.formId;
    const data = args.data;
    const saveActionId = args.saveActionId;
    let saveReqInit = true;
    let saveReq: IAxiosResponse | undefined = undefined;
    if (saveActionId) {
      saveReqInit = false;
      const documentQuery = args.documentId ? `&documentId=${args.documentId}` : '';
      saveReq = yield call(postRequest, `${company}/documents/actions?formId=${formId}&actionId=${saveActionId}${documentQuery}`, data);
    }
    if (saveReqInit || (!saveReqInit && saveReq)) {
      try {
        const documentId = saveActionId ? data.document.id : args.documentId;
        const documentQuery = documentId ? `&documentId=${documentId}` : '';
        const req: IAxiosResponse = yield call(postRequest, `${company}/documents/actions/${actionId}/inputFields?formId=${formId}${documentQuery}`, {});
        yield put(getIndividualUsersListReturnAction({ individualUsersList: req.data.data }));
      } catch (e) {
        yield put(getIndividualUsersListReturnAction({ individualUsersList: [] }));
      }
    }
  } catch (e) {
    const error = { ...e as any };
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    if (error.response.data.documents !== undefined) {
      yield put(getDocumentsDuplicateReturnAction({ documentsDuplicate: error.response.data.documents, duplicateActionType: error.response.data.type }))
    } else {
      yield put(saveDocumentErrorAction(JSON.stringify(error.response.data.message)));
      yield put(getIndividualUserListErrorAction())
    }
  }
}

export function* getIndividualUsersListShortcut(args: IGetIndividualUserInput) {
  try {
    const company = args.companyId;
    const actionId = args.actionId;
    const formId = args.formId;
    const data = args.data;
    const saveActionId = args.saveActionId;
    let saveReqInit = true;
    let saveReq: IAxiosResponse | undefined = undefined;
    if (saveActionId) {
      saveReqInit = false;
      const documentQuery = args.documentId ? `&documentId=${args.documentId}` : '';
      saveReq = yield call(postRequest, `${company}/documents/actions?formId=${formId}&actionId=${saveActionId}${documentQuery}`, data);
    }
    if (saveReqInit || (!saveReqInit && saveReq)) {
      try {
        const documentId = saveActionId ? data.document.id : args.documentId;
        const documentQuery = documentId ? `&documentId=${documentId}` : '';
        const req: IAxiosResponse = yield call(postRequest, `${company}/documents/actions/${actionId}/inputFields?formId=${formId}${documentQuery}`, {});
        yield put(getIndividualUsersListShortcutReturnAction({ individualUsersList: req.data.data }));
      } catch (e) {
        yield put(getIndividualUsersListShortcutReturnAction({ individualUsersList: [] }));
      }
    }
  } catch (e) {
    const error = { ...e as any };
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    if (error.response.data.documents !== undefined) {
      yield put(getDocumentsDuplicateShortcutReturnAction({ documentsDuplicate: error.response.data.documents, duplicateActionType: error.response.data.type }))
    } else {
      yield put(saveDocumentShortcutErrorAction(JSON.stringify(error.response.data.message)));
      yield put(getIndividualUserListShortcutErrorAction())
    }
  }
}

export function* deleteDocument(args: IDocumentDeleteInput) {
  try {
    const company = args.companyId;
    const documentId = args.documentId;

    yield call(deleteRequest, `${company}/documents/${documentId}`, {});
    yield put(deleteDocumentReturnAction({ documentId: args.documentId }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
    yield put(deleteDocumentErrorAction())
  }
}

export function* deleteCommentAttachment(args: IAttachmentDeleteInput) {
  try {
    const company = args.company;
    const comment = args.commentId;
    const attachment = args.attachmentId;
    const document = args.documentId;
    yield call(deleteRequest, `${company}/documents/${document}/comments/${comment}/attachment/${attachment}`, {});
    const commentReq: IAxiosResponse = yield call(getRequest,
      `${company}/documents/${document}/comments/${comment}`,
      {}
    );
    yield put(updateCommentReturnAction({ comment: commentReq.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getRelatedForms(args: IRelatedFormsInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const relation: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/bpr?draft=false`, {});
    let referenceList = relation.data.data.relatedReferences;
    let relatedFormFields: IAxiosResponse = { data: { data: {} } };
    let relatedForms: IRelatedFormField[] = [];

    if (relation.data.data && referenceList) {
      referenceList = referenceList.map((reference: any) => {
        return {
          ...reference,
          id: uuid(),
          headerField: {},
          fields: [],
          showRelatedForm: false,
          relationId: reference.id
        }
      });
      for (let i = 0; i < referenceList.length; i++) {
        const relatedForm = { ...referenceList[i] }
        if (relatedForm.dataType === 'document') {
          relatedFormFields = yield call(getRequest, `${company}/forms/${relatedForm.relationId}/fields`, {});
        }
        if (relatedForm.dataType === 'database') {
          relatedFormFields = yield call(getRequest, `${company}/databases/${relatedForm.relationId}/fields`, {});
        }
        relatedForms.push({
          ...SanitizeBPR.structureRelatedForm(relatedForm, relatedFormFields.data.data) as IRelatedFormField,
        });
      }
    }
    yield put(getRelatedFormsReturnAction({ relatedForms: relatedForms }));

  } catch (e) {
    console.error(e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getRelatedDocuments(args: IRelatedDocumentsInput) {
  try {
    const company = args.company;
    const relatedFormRecords = args.relatedFormRecords;

    let relatedRecords: IRelatedFormRecord[] = [];
    if (relatedFormRecords) {
      for (let i = 0; i < relatedFormRecords.length; i++) {
        const { source, dataType, id } = { ...relatedFormRecords[i] };
        const relatedDocument: IAxiosResponse = yield call(getRequest, `${company}/documents/${id}?load=owner&load=status&load=creator&load=updatedBy&load=ownerType&load=slaStatus`, {});
        relatedRecords.push({
          ...relatedDocument.data.data,
          dataType: dataType,
          formId: source,
        });
      }
    }
    yield put(getRelatedDocumentsReturnAction({ relatedDocument: relatedRecords }));
  } catch (e) {
    console.error(e)
    yield put(setStatusCodeAction(e))
  }
}

export function* getRelatedDatatable(args: IRelatedDocumentsInput) {
  try {
    const company = args.company;
    const relatedFormRecords = args.relatedFormRecords;
    let relatedRecords: IRelatedFormRecord[] = [];
    if (relatedFormRecords) {
      for (let i = 0; i < relatedFormRecords.length; i++) {
        const { source, dataType, id } = { ...relatedFormRecords[i] };
        const relatedDatabase: IAxiosResponse = yield call(getRequest, `${company}/databases/${source}/records/${id}`, {});
        relatedRecords.push({
          ...relatedDatabase.data.data,
          dataType: dataType,
          clientDbId: source,
        });
      }
    }
    yield put(getRelatedDatatableReturnAction({ relatedDatatable: relatedRecords }));
  } catch (e) {
    console.error(e)
    yield put(setStatusCodeAction(e))
  }
}

export function* resetReferenceFormField(args: IReloadFieldInput) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    const statusId = args.statusId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    let fieldId = '';
    if (args.fieldId && args.fieldId.length > 0) {
      args.fieldId.forEach((id: string) => {
        fieldId += `&ids[]=${id}`;
      })
    }
    const fieldResponse: IAxiosResponse = yield call(postRequest,
      `${company}/documents/fields?formId=${formId}${documentQuery}&statusId=${statusId}${fieldId}`,
      { fields: args.fields, inputs: args.inputs }
    );
    yield put(resetReferenceFormFieldReturnAction({
      formField: fieldResponse.data.data,
      valueFieldReload: args.fields
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getClientAutomationConfig(args: IGetAutomationConfigInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const statusId = args.statusId;
    const params = [];
    if (statusId) {
      params.push(`statusId=${statusId}`);
    }
    let paramsLink = params.join('&');
    if (paramsLink !== '') {
      paramsLink = `?${paramsLink}`
    }

    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/clientAutomations${paramsLink}`, {});
    yield put(getClientAutomationConfigReturnAction({ automationConfig: req.data.data }));
  } catch (e) {
    console.error(e)
    yield put(setStatusCodeAction(e))
  }
}

export function* getClientAutomationConfigShortCut(args: IGetAutomationConfigInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const statusId = args.statusId;
    const params = [];
    if (statusId) {
      params.push(`statusId=${statusId}`);
    }
    let paramsLink = params.join('&');

    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/clientAutomations${paramsLink}`, {});
    yield put(getClientAutomationConfigShortcutReturnAction({ automationConfig: req.data.data }));
  } catch (e) {
    console.error(e)
    yield put(setStatusCodeAction(e))
  }
}

export function* Sagas() {
  yield takeLatest(GET_FORM, getForm);
  yield takeLatest(GET_FORM_SHORTCUT, getFormShortcut);
  yield takeLatest(GET_FORM_FIELD_SHORTCUT, getFormFieldShortcut);
  yield takeLatest(GET_FORM_FIELD, getFormField);
  yield takeLatest(GET_DOCUMENT_ACTION, getDocumenAction);
  yield takeLatest(GET_DOCUMENT_ACTION_SHORTCUT, getDocumenActionShortcut);
  yield takeLatest(SAVE_DOCUMENT, saveDocument);
  yield takeLatest(TRIGGER_FIELD_TO_UPDATE, triggerFieldToUpdate);
  yield takeLatest(RESET_REFERENCE_FORM_FIELD, resetReferenceFormField);
  yield takeLatest(TRIGGER_FIELD_TO_UPDATE_SHORTCUT, triggerFieldToUpdateShortcut);
  yield takeLatest(GET_COMMENTS, getComments);
  yield takeLatest(SAVE_COMMENT, saveComment);
  yield takeLatest(DELETE_COMMENT, deleteComment);
  yield takeLatest(GET_DOCUMENTS_DUPLICATE, getDocumentsDuplicate);
  yield takeLatest(GET_COMMENT_SENDER, getCommentSender);
  yield takeLatest(GET_DOCUMENTS_FOLLOWERS, getDocumentFollowers);
  yield takeLatest(FOLLOW_DOCUMENT, followDocument)
  yield takeLatest(UNFOLLOW_DOCUMENT, unfollowDocument)
  yield takeLatest(GET_DOCUMENTS_ACTION_CONFIG, getDocumentActionConfig);
  yield takeLatest(GET_INDIVIDUAL_USERS_LIST, getIndividualUsersList);
  yield takeLatest(GET_INDIVIDUAL_USERS_LIST_SHORTCUT, getIndividualUsersListShortcut);
  yield takeLatest(DELETE_DOCUMENT, deleteDocument);
  yield takeLatest(DELETE_COMMENT_ATTACHMENT, deleteCommentAttachment);
  yield takeLatest(GET_RELATED_FORMS, getRelatedForms);
  yield takeLatest(GET_RELATED_DOCUMENTS, getRelatedDocuments);
  yield takeLatest(GET_RELATED_DATATABLE, getRelatedDatatable);
  yield takeLatest(SAVE_DOCUMENT_SHORTCUT, saveDocumentShortcut);
  yield takeLatest(GET_AUTOMATION_CONFIG, getClientAutomationConfig);
  yield takeLatest(GET_AUTOMATION_CONFIG_SHORTCUT, getClientAutomationConfigShortCut);

}

// All sagas to be loaded
export default Sagas;
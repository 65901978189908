import { IConfigName } from './interface/field-type-object';

interface IItemObj {
  id: string;
  value: string;
  label?: string;
}

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.MultipleSelect,
  required: false,
  type: 'enum',
};

interface IDefaultConfigObject {
  dataType: string;
  data: IItemObj[];
  multiselect: boolean;
  displayType: string;
}

const defaultConfig: IDefaultConfigObject = {
  dataType: 'static',
  multiselect: true,
  displayType: 'dropdown',
  data: []
};

const value = {
  title: 'Multi Select',
  icon: {
    black: require('../../icons/black/list.png'),
    white: require('../../icons/white/list.png'),
    blue: require('../../icons/blue/list.png'),
  },
  style: {
    width: '17px',
    height: '17px',
    left: '2px',
    top: '1px'
  },
  defaultData,
  defaultConfig
}

export default value;
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { IRootState } from '../../../../../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { getBusinessProcessListAction, deactivateBusinessProcessAction } from '../action';
import { useHistory, Link } from 'react-router-dom';
import ToolTip from '../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../common/icons';
import { IForm } from '../interface/form';
import DownloadPopup from '../../../../../../../common/general/download-popup';
import { ConfirmationMessage } from '../../../../../../../common/general/confirmation-modal';
import ActionMessage from '../../../../../../../common/general/action-message';
import { businessProcessListFormActionMessage } from '../constant';

interface IBusinessProcessList {
  statusFilter: 'active' | 'inactive' | 'all';
}

const BusinessProcessList: React.FC<IBusinessProcessList> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { formList, deactivateLoading } = useSelector((state: IRootState) => state.businessProcess);
  const { company } = useSelector((state: IRootState) => state.auth);

  const [selectedBP, setSelectedBP] = useState(undefined as undefined | IForm);

  const openDeactivateModal = (bp: IForm) => {
    if (bp.enabled) {
      setSelectedBP(bp);
    } else {
      dispatch(deactivateBusinessProcessAction({
        company: company,
        formId: bp.id as string,
        enabled: true
      }));
    }
  }

  const confirmDeactivateBP = () => {
    if (selectedBP) {
      dispatch(deactivateBusinessProcessAction({
        company: company,
        formId: selectedBP.id as string,
        enabled: false
      }));
      setSelectedBP(undefined);
    }
  }

  useEffect(() => {
    if (company) {
      dispatch(getBusinessProcessListAction({ company: company }))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return (<>
    <ActionMessage messages={businessProcessListFormActionMessage} />
    <div className='business-process-table-container table-wrapper'>
      <table className='business-process-table table-container'>
        <thead>
          <tr>
            <th className='th-group'>ID</th>
            <th className='th-group'>Business Process</th>
            <th className='th-group'>App</th>
            <th className='th-group'># of Users</th>
            <th className='th-group bp-action'>Enabled</th>
            <th>
              <DownloadPopup
                downloadLink={`/${company}/forms?limit=0&draft=true&export=xlsx`}
                method='get'
                filter={{}}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {formList
            .filter(form => {
              if (props.statusFilter !== 'all') {
                return props.statusFilter === 'active' ? form.enabled : !form.enabled;
              }
              return true;
            })
            .map((form: IForm, index: number) => {
              return <tr key={`form-bp-${index}`}>
                <td>{form.seq}</td>
                <td><Link to={`/admin/business-process/form-builder/${form.id}`}>
                  {form.name}
                </Link></td>
                <td></td>
                <td></td>
                <td className='bp-action'>
                  <div className="ui read-only toggle checkbox checked table-checkbox-toggle" onClick={() => openDeactivateModal(form)}>
                    <input style={{ cursor: 'pointer' }} type="checkbox" checked={form.enabled} onChange={() => { }} />
                    <label></label>
                  </div>
                </td>
                <td>
                  <ToolTip position='right center'
                    popupMessage={'Edit'}
                    popupTriggerComponent={
                      <Image src={icons.blue.edit}
                        style={{
                          width: '13px',
                          cursor: 'pointer',
                          display: 'inline-block'
                        }} onClick={() => history.push(`/admin/business-process/form/${form.id}`)} name='edit' />
                    }
                  />
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
    <ConfirmationMessage
      confirmButtonName={'Confirm'}
      confirmButtonColor={'green'}
      cancelButtonColor={'grey'}
      close={() => setSelectedBP(undefined)}
      open={selectedBP !== undefined}
      confirm={confirmDeactivateBP}
      cancel={() => setSelectedBP(undefined)}
      headerText="Confirmation"
      detailText={`Are you sure you want  deactivate the Business process?`}
      loading={deactivateLoading}
      type='warning'
    />
  </>
  );
}

export default BusinessProcessList;
import React, { useRef, useState } from 'react';
import { Modal, Segment, Button, Form, Dropdown } from 'semantic-ui-react-bpm';
import { IOption } from '../../../../../../../../../common/interface/option';
import { ICustomFilterField } from '../interface/custom-filter-field';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import { useSetConfigModalFieldOptions } from './hooks/use-set-config-modal-field-options';
import { useSetConfigModalState } from './hooks/use-set-config-modal-state';

interface IConfigurationModalProps {
  cancel(): void;
  selectedFieldFilter: number | null;
  customFilterFieldList: { [x: string]: ICustomFilterField };
  saveConfiguration(selectedFieldFilter: number | null, newConfiguration: any): void;
  fieldList: ICustomFieldDetail[];
}

const ConfigurationModal: React.FC<IConfigurationModalProps> = (props) => {
  const filterNameRef = useRef(document.createElement('input'));
  const errorIndicator = {
    filterName: false
  }
  const [error, setError] = useState(errorIndicator)
  const {
    isMultipleSelection,
    setIsMultipleSelection,
    selectedfieldOptions,
    setSelectedFieldOptions,
    fieldType
  } = useSetConfigModalState({
    filterNameRef,
    customFilterFieldList: props.customFilterFieldList,
    selectedFieldFilter: props.selectedFieldFilter,
  });
  const { fieldOptions } = useSetConfigModalFieldOptions({
    fieldType,
    fieldList: props.fieldList
  });

  const getOption = () => {
    let options = [...fieldOptions];
    selectedfieldOptions.forEach((value: string) => {
      options = options.filter((option: IOption) => option.value !== value)
    });
    return options;
  }

  const onSelect = (value: any) => {
    let newSelectedOption = [...selectedfieldOptions];
    newSelectedOption.push(value);
    setSelectedFieldOptions(newSelectedOption);
  }

  const deleteOption = (forDeleteValue: string) => {
    let newSelectedOption = [...selectedfieldOptions];
    setSelectedFieldOptions(newSelectedOption.filter((value: string) => value !== forDeleteValue));
  }

  const onSave = () => {
    if (isValid()) {
      const selectedIndex = props.selectedFieldFilter + '';
      const newConfiguration = { ...props.customFilterFieldList[selectedIndex] };
      newConfiguration.name = filterNameRef.current.value;
      newConfiguration.fields = selectedfieldOptions;
      newConfiguration.isMultiSelection = isMultipleSelection;
      props.saveConfiguration(props.selectedFieldFilter, newConfiguration)
    }
  }

  const isValid = (): boolean => {
    let isValid = true;
    const newError = { ...errorIndicator };
    if (!filterNameRef.current.value) {
      isValid = false;
      newError.filterName = true;
    }
    setError(newError)
    return isValid;
  }

  return <>
    <Modal
      dimmer='blurring'
      size='large'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={props.selectedFieldFilter !== null}
      onClose={() => { }}
      id='custom-filter-configuration-modal'
      centered={false}
    >
      <Modal.Header className='borderless' >
        <Segment className={`borderless bg-primary`}>
          <h4>Filter Configuration Modal</h4>
        </Segment>
      </Modal.Header>
      <Modal.Content>
        <Form size='big' id='custom-filter-config-form'>
          <Form.Group widths='equal'>
            <div className={`required six wide field ${error.filterName ? 'error' : ''}`}>
              <label>Filter Name</label>
              <div className="ui input">
                <input className={`${error.filterName ? 'error' : ''}`} onChange={() => {
                  error.filterName = (filterNameRef.current.value ? false : true)
                }
                } ref={filterNameRef} type="text" />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal' style={{ margin: '0px' }}>
            <div className='six wide field fields-container'>
              <label>Fields where filter applies:</label>
              <Dropdown
                popperContainer={DropdownPopperOption}
                fluid
                multiple={false}
                selection
                placeholder='Fields'
                clearable
                value={''}
                search
                selectOnBlur={false}
                options={getOption()}
                onChange={(element, target) => onSelect(target.value)}
              />
              <div id='selected-users' aria-invalid="true" role="listbox"
                aria-expanded="false" aria-multiselectable="true"
                className="ui multiple selection dropdown" tabIndex={0}>
                {
                  selectedfieldOptions.map((value: string) => {
                    const [type, fieldName, label] = value.split('|||||');
                    return <li
                      key={`field-filter-value-${type}-${fieldName}`}
                      className={`ui label`}>
                      {label}
                      <i aria-hidden="true" className="delete icon" onClick={
                        () => deleteOption(value)
                      }></i>
                    </li>
                  })
                }
                <div className="text" role="alert" aria-live="polite" aria-atomic="true"></div>
              </div>
              {fieldType === 'dropdown' &&
                <div id='custom-filter-configuration-checkbox'>
                  <div className="ui fitted checkbox" onClick={() => setIsMultipleSelection(!isMultipleSelection)}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={isMultipleSelection} value="" />
                    <label>Multiple Selection</label>
                  </div>
                </div>
              }
            </div>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className='borderless'>
        <Button color='grey' className='btn-action' onClick={() => props.cancel()}>Cancel</Button>
        <Button color='green' className='btn-action' onClick={() => onSave()}>Save</Button>
      </Modal.Actions>
    </Modal>
  </>
}

export default ConfigurationModal;
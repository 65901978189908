import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';
import Ajv from 'ajv';
import { errorMessages } from '../error';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';
import { UploadedFile } from '../../../api-request/upload';

export class FileValidator implements ValidationType {

  name = 'file';

  validate(field: FieldElement, value: UploadedFile | UploadedFile[], actionType?: string) {
    if (((value === null) && (field.accessType !== IFieldAccessType.Required || (field.accessType === IFieldAccessType.Required && actionType === 'save')))
      || field.accessType === IFieldAccessType.Readonly || field.accessType === IFieldAccessType.Hidden
      || (field.accessType === IFieldAccessType.ReadWrite && !value)) {
      return [];
    }
    const schema = {
      type: Array.isArray(value) ? 'array' : 'object',
      items: { ...field.validationSchema, type: 'object' },
      minItems: field.accessType === IFieldAccessType.Required ? 1 : 0,
      maxItems: field.inputConfig.maxItems || 1,
      isRequired: field.accessType === IFieldAccessType.Required,
    };
    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...schema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: value });
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field, validationSchema: schema }, value);
      });
    }
    return validate.errors || [];
  }
}

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getFormFieldsReturnAction,
  getFormFieldsErrorAction,
  saveDuplicateCheckerReturnAction,
  saveDuplicateCheckerErrorAction,
  getDuplicateCheckerErrorAction,
  getDuplicateCheckerReturnAction,
  publishDuplicateCheckerReturnAction,
  publishDuplicateCheckerErrorAction
} from './action';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { GET_FORM_FIELDS, SAVE_DUPLICATE_CHECKER, GET_DUPLICATE_CHECKER, PUBLISH_DUPLICATE_CHECKER } from './constant';
import { getRequest, putRequest, postRequest, deleteRequest, IAxiosResponse } from '../../../../../../../../common/api-request';
import { IFormFieldsInput } from './interface/input/form-fields';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IDuplicateCheckerSaveInput, IDuplicateCheckerPublishInput } from './interface/input/duplicate-checker-save';
import { IDuplicateCheckerGetInput } from './interface/input/duplicate-checker-get';

export function* getFormFields(args: IFormFieldsInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/fields`, {});
    const formOptionList = req.data && req.data.data
      .filter((field: ICustomFieldDetail) => field.type !== 'spacer')
      .map((field: ICustomFieldDetail, key: number) => {
        return {
          key: key,
          text: field.label,
          value: field.id
        }
      });
    yield put(getFormFieldsReturnAction({
      data: formOptionList
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getFormFieldsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* saveDuplicateChecker(args: IDuplicateCheckerSaveInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const data = args.duplicateChecker;
    let req: IAxiosResponse = {
      data: { data: {} }
    };
    if (data) {
      req = yield call(putRequest, `${companyId}/forms/${formId}/duplicateChecker`, data);
    } else {
      req = yield call(deleteRequest, `${companyId}/forms/${formId}/duplicateChecker`, {});
    }
    yield put(saveDuplicateCheckerReturnAction({
      duplicateChecker: req.data.data || {},
      duplicateCheckerSaveType: args.saveType
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(saveDuplicateCheckerErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getDuplicateChecker(args: IDuplicateCheckerGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/duplicatechecker?draft=true`, {});
    yield put(getDuplicateCheckerReturnAction({
      duplicateChecker: req.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getDuplicateCheckerErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* publishDuplicateChecker(args: IDuplicateCheckerPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishDuplicateCheckerReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishDuplicateCheckerErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* duplicateCheckerSagas() {
  yield takeLatest(GET_FORM_FIELDS, getFormFields);
  yield takeLatest(SAVE_DUPLICATE_CHECKER, saveDuplicateChecker);
  yield takeLatest(GET_DUPLICATE_CHECKER, getDuplicateChecker);
  yield takeLatest(PUBLISH_DUPLICATE_CHECKER, publishDuplicateChecker);
}

// All sagas to be loaded
export default duplicateCheckerSagas;
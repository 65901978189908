import { useEffect, useState } from 'react';
import { IFormStatus } from '../../interface/form-status';

interface IProps {
  selectedStatus: IFormStatus;
  statusNameRef: React.MutableRefObject<HTMLInputElement>;
}

export const useSetStatusToStateAndRef = (props: IProps) => {
  const { statusNameRef, selectedStatus } = { ...props };

  const [selectedStatusId, setSelectedStatusId] = useState('');

  useEffect(() => {
    statusNameRef.current.value = props.selectedStatus.name;
    setSelectedStatusId(props.selectedStatus.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  return {
    selectedStatusId, setSelectedStatusId,
    ...props
  };
}
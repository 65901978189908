import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IField } from '../../../users/interface/field';
import { IUserField } from '../../../users/interface/user-field';
import { IDataTable } from '../../reducer';

interface IProps {
  forUpdateDataTableFields: IField[];
  forUpdateDataTableDetails: IDataTable;
  sortDataTableFieldMultiLabel(sortDataTableFieldList: ICustomFieldDetail[], fieldLabel: string[]): ICustomFieldDetail[]
}

export const useSetDatatableDetails = (props: IProps) => {
  const { forUpdateDataTableFields, forUpdateDataTableDetails, sortDataTableFieldMultiLabel } = { ...props };

  const [dataTableDetails, setDataTableDetails] = useState({} as IDataTable);

  useEffect(() => {
    let dataTableDetails = {} as IDataTable;
    if (forUpdateDataTableFields.length > 0 && forUpdateDataTableDetails) {
      let labelList = [] as string[];
      if (forUpdateDataTableDetails.fields !== undefined) {
        forUpdateDataTableDetails.fields.forEach((fields: IUserField) => {
          if (fields.type !== 'seq' && fields.label !== 'ID') {
            labelList.push(fields.label || '')
          }
        });
      }
      const dataTableFields = sortDataTableFieldMultiLabel(forUpdateDataTableDetails.fields, ['ID', ...labelList]);
      dataTableDetails = { ...forUpdateDataTableDetails, fields: dataTableFields }
      setDataTableDetails(dataTableDetails);
    }
    // eslint-disable-next-line
  }, [forUpdateDataTableFields, forUpdateDataTableDetails]);

  return { dataTableDetails, setDataTableDetails, ...props };
}
import { IFormDocument } from '../../report/interface/form-document';
import { IFormStatusWithAction } from '../../../../admin-module/module/business-process/module/workflow/interface/form-status';
import { tableFieldDataRowsCount } from './manage-form-data';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';
import { IField } from '../../../../admin-module/module/users/interface/field';
import { IReloadFieldDetailInput } from '../interface/input/field-trigger-reload';
import { triggerFieldReloadAction } from '../../../../../common/utils/trigger-field-reload';

export const setFieldReloadInput = (
  fieldList: IField[],
  documentDetail: IFormDocument,
  formStatus: IFormStatusWithAction,
  companyId: string
) => {
  let tempField = [...fieldList];
  let fieldReloadArgs = null;
  if (documentDetail && formStatus && companyId) {
    const { id } = { ...documentDetail.form };
    const fxFieldsWithNoConfigValue = tempField.filter(field => {
      const data = { ...documentDetail.fields };
      const { value } = { ...field.inputConfig?.config };
      return field.configName === IConfigName.FX && !data[field.id || ''] && !value;
    });
    if (fxFieldsWithNoConfigValue.length > 0 && documentDetail.fields) {
      let fieldData = {} as { [fieldId: string]: any };
      const data = { ...documentDetail.fields };
      fxFieldsWithNoConfigValue.forEach(field => {
        if (field.requiresFieldData && field.requiresFieldData.length) {
          field.requiresFieldData.forEach(id => {
            if (fieldData[id] === undefined) {
              fieldData[id] = { value: data[id] };
              const source = tempField.find(e => e.id === id);
              if (source) {
                const { value, columns } = { ...source.inputConfig?.config };
                if (value && !data[id]) {
                  fieldData[id] = { value };
                }
                if (source.configName === IConfigName.Table && columns && columns.length) {
                  const rows = tableFieldDataRowsCount(data[id]);
                  const rowsArrayData = new Array(rows);
                  for (let i = 0; i < rows; i++) {
                    const columnData = {} as any;
                    columns.forEach(col => {
                      const config: any = { ...col.inputConfig?.config };
                      const columnId = col.id || '';
                      let columnValue = data[id][columnId] && Array.isArray(data[id][columnId])
                        && data[id][columnId].length > 0 ? data[id][columnId][i] : null;
                      if (config && config.value && !columnValue) {
                        columnValue = config.value;
                      }
                      columnData[columnId] = { value: columnValue };
                    });
                    rowsArrayData[i] = { current: columnData };
                  }
                  fieldData[id] = rowsArrayData;
                }
              }
            }
          });
        }
      });
      fieldReloadArgs = triggerFieldReloadAction({
        fieldList: tempField,
        companyId: companyId,
        statusId: formStatus.id,
        formId: id || '',
        documentId: documentDetail.id || '',
        returnDataOnly: true,
        reloadField: fxFieldsWithNoConfigValue.map(e => e.id as string),
        dynamicRefs: { current: fieldData }
      }) as IReloadFieldDetailInput;

    }
  }

  return fieldReloadArgs;
}
import { DeltaModuleType } from '../..';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IDataTable } from '../../../../../../module/data-tables/reducer';
import { AdminHistoryCategory, AdminHistoryType, IAdminHistoryLogs } from '../../../../../interface/history';
import { IAdminHistoryUtils } from '../../../../manage-history';
import DeltaFields from '../../../fields';

export class DatatableFieldsDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.DataTableFields;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    const { targetDelta, targetInstance } = { ...historyLogs.context };

    let dataTableList = this.deletedDTInstance(utils) as IDataTable[];

    if (utils.dataTableList) {
      dataTableList = dataTableList.concat(utils.dataTableList);
    }

    const fieldsDelta = new DeltaFields();
    const delta = fieldsDelta.formatContext(targetDelta || {}, targetInstance);

    return { delta, text: this.formatText(historyLogs, dataTableList, historyLogs.type) };
  }

  deletedDTInstance(utils: IAdminHistoryUtils) {
    return utils.history && utils.history
      .filter(e => e.type === AdminHistoryType.Delete
        && e.category === AdminHistoryCategory.DataTable)
      .map(e => e.context.targetInstance)
  }

  formatText(historyLogs: IAdminHistoryLogs, dataTableList: IDataTable[], type: AdminHistoryType) {
    const { targetInstance } = { ...historyLogs.context };

    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');
    const belongDataTable = dataTableList.find(e => e.id === targetInstance.clientDbId);
    let text = historyLogs.text;

    if (belongDataTable) {
      let action = `${type.toLocaleLowerCase()}d`;
      if (type === AdminHistoryType.Create) {
        action = 'added';
      }
      text = `${commentSender} has ${action} Column: (label: ${targetInstance.label}) for DataTable (name: ${belongDataTable.name})`
    }

    return text;
  }
}
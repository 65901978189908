import { useEffect } from 'react';

interface IProps {
  height: number;
}

export const useSetUserTableHeight = (props: IProps): void => {
  const { height } = { ...props };

  useEffect(() => {
    const userTable = document.getElementById('user-table');
    const filterComponentHeight = height;
    let totalHeight = (75 + 86 + 65 + 155);
    if (userTable) {
      userTable.style.setProperty('height', `calc(100vh - ${(totalHeight + (filterComponentHeight - 42))}px)`)
    }
  }, [height]);
}
import React, { useEffect, useState, useRef } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Modal, Dimmer, Loader, Button, Form } from 'semantic-ui-react-bpm';
import ResponseMessage from '../../../../../../../common/general/response-message';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBusinessProcessAction,
  getBusinessProcessAction,
  updateBusinessProcessAction
} from '../action';
import { IRootState } from '../../../../../../../reducers';
import { message } from '../../../message';
import { IForm } from '../interface/form';
import ActionMessage from '../../../../../../../common/general/action-message';
import { businessProcessListFormActionMessage, progressStyles } from '../constant';
import { uploadFile, UploadedFile } from '../../../../../../../common/api-request/upload';
import { useSetFormDetailsToRefs } from './hooks/use-set-form-details-to-refs';
import { useClearAndRedirectFormToList } from './hooks/use-clear-and-redirect-form-to-list';

const SweetProgress = require('react-sweet-progress');

interface IMatch {
  params: { formId: string };
}

interface IModalForm {
  openDeleteConfirmationModal(formId: string, formName: string): void;
}

const ModalForm: React.FC<IModalForm> = (props) => {
  const match: IMatch = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    company
  } = useSelector((state: IRootState) => state.auth);
  const {
    createFormLoading,
    createFormSuccessMessage,
    createFormErrorMessage,
    getFormDetailLoading,
    getFormDetailStatus,
    getFormDetailData,
    selectedBusinessProcessId,
    updateFormLoading,
    updateFormSuccessMessage
  } = useSelector((state: IRootState) => state.businessProcess);

  const descriptionRef = useRef(document.createElement('textarea'));
  const businessProcessRef = useRef(document.createElement('input'));
  const seqAbbrRef = useRef(document.createElement('input'));

  const defaultError = { bpError: false, seqError: false };

  const [error, setError] = useState(defaultError);
  const [errorUpload, setErrorUpload] = useState(null as Error | null);
  const [uploadProgress, setUploadProgress] = useState(null as number | null);
  const [errorImage, setErrorImage] = useState(false);

  const {
    file,
    setFile
  } = useSetFormDetailsToRefs({
    getFormDetailData,
    businessProcessRef,
    descriptionRef,
    seqAbbrRef,
    formId: match.params.formId,
  });

  const setUploadedFile = (file: UploadedFile) => {
    setFile(file);
    setUploadProgress(null);
  }

  const onUploadIcon = async (event: any) => {
    if (event.target.files.length === 0) {
      return;
    }
    try {
      setErrorUpload(null);
      const uploadedFile = await uploadFile({
        companyId: company,
        file: event.target.files[0],
        progress: (progress) => {
          setUploadProgress(progress);
        }
      });
      setUploadedFile(uploadedFile);
    } catch (e) {
      const error = e as any;
      console.log(`Upload error: `, e, ` Type `, error.constructor.name);
      setErrorUpload(error);
    }
  }

  const submitClick = (): void => {
    if (!isValidInput()) {
      return;
    }
    let data: IForm = {
      name: businessProcessRef.current.value,
      abbr: seqAbbrRef.current.value,
      description: descriptionRef.current.value,
      icon: file ? file.id : undefined
    }
    if (match.params.formId === 'add') {
      dispatch(createBusinessProcessAction({ data, company }))
    } else {
      dispatch(updateBusinessProcessAction({ data, company, formId: match.params.formId }))
    }
  }

  const isValidInput = (): boolean => {
    let currentError = { ...error };
    const isEmptyBusinessProcess = businessProcessRef.current && !businessProcessRef.current.value;
    const isEmptySeqAbbr = seqAbbrRef.current && !seqAbbrRef.current.value;
    if (isEmptyBusinessProcess) currentError.bpError = true;
    if (isEmptySeqAbbr) currentError.seqError = true;
    setError(currentError);
    return !currentError.bpError && !currentError.seqError;
  }

  useClearAndRedirectFormToList({
    dispatch,
    history,
    createFormSuccessMessage,
    updateFormSuccessMessage,
    selectedBusinessProcessId,
    formId: match.params.formId
  });

  useEffect(() => {
    if (match.params.formId !== 'add' && company) {
      dispatch(getBusinessProcessAction({ company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.formId, company]);

  const errorMessage = createFormErrorMessage
    || (getFormDetailStatus === 'error' ? message.formRetrievalError : '');

  return (
    <Modal
      dimmer='blurring'
      size='large'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={true}
      className='business-process-modal'
    >
      {
        (createFormLoading || getFormDetailLoading || updateFormLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }

      <Modal.Header className='borderless' >
        <div
          className='ui borderless bg-primary'
          style={{ height: '42px' }} >
          {`${(match.params.formId !== 'add' ? 'Edit' : 'Create')} Business Process`}
        </div>
      </Modal.Header>
      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
        <ActionMessage messages={businessProcessListFormActionMessage} />
      </div>

      {(errorMessage) &&
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <ResponseMessage
            message={errorMessage}
            open={true}
            close={() => { }}
            error={true}
          />
        </div>
      }
      <Modal.Content>
        {(getFormDetailStatus === '' || getFormDetailStatus === 'success') &&
          <Form>
            <div>
              <div className='upper-input-with-logo-container'>
                <div className='logo-container'>
                  {(uploadProgress !== null) ?
                    <div className='progress-container'>
                      <SweetProgress.Progress
                        type='circle'
                        strokeWidth={3}
                        percent={Math.round(uploadProgress * 10000) / 100}
                        style={progressStyles.progress}
                        theme={progressStyles.progressTheme}
                        status={errorUpload === null ? 'success' : 'error'}
                      />
                    </div>
                    :
                    <>
                      {file && file.url && !errorImage ?
                        <img className='ui image logo-background' src={file.url} alt=' '
                          onError={(e: any) => { setErrorImage(true) }} />
                        :
                        <div className='logo-alternative' />
                      }
                      <input
                        hidden
                        type='file'
                        onChange={(change) => { onUploadIcon(change) }}
                        className='inputfile'
                        accept="image/*"
                        id='file-upload-logo' />
                      <label
                        className='upload-logo'
                        htmlFor='file-upload-logo'>
                        Upload Logo
                      </label>
                    </>
                  }
                </div>
                <div className='upper-input-container'>
                  <Form.Group widths='equal'>
                    <div className={`wide required field ${error.bpError ? 'error' : ''}`}>
                      <label>Business Process</label>
                      <div className={`ui input`}>
                        <input type="text" ref={businessProcessRef} />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <div className={`wide required field ${error.seqError ? 'error' : ''}`}>
                      <label>Sequence Abbreviation</label>
                      <div className={`ui input`}>
                        <input type="text" ref={seqAbbrRef} />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <Form.Group widths='equal'>
                <div className='wide field'>
                  <label>Description</label>
                  <div className={`ui input `}>
                    <textarea rows={4} ref={descriptionRef}></textarea>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Form>
        }
      </Modal.Content>
      <Modal.Actions className='borderless ' >
        {match.params.formId !== 'add' &&
          <Button onClick={() => props.openDeleteConfirmationModal(match.params.formId, businessProcessRef.current.value)}
            floated='left' color='red' className='btn-action'>Delete</Button>
        }
        <Button className='btn-default btn-action cancel-btn' onClick={() => history.push('/admin/business-process/form')}>Cancel</Button>
        {(getFormDetailStatus === '' || getFormDetailStatus === 'success') &&
          <Button className='btn-success btn-action create-btn' onClick={() => submitClick()}>{match.params.formId === 'add' ? `Create` : 'Update'}</Button>
        }
      </Modal.Actions>
    </Modal >
  );
}

export default ModalForm;

import React from 'react';
import { IMessageProps } from './interface/message-props';
import Message from './message';
import { v4 as uuid } from 'uuid';

interface ActionMessageProps {
  messages: IMessageProps[];
  className?: string;
}

const ActionMessage: React.FC<ActionMessageProps> = (props) => {

  return <div className={`response-message-container ${props.className}`}>
    {
      props.messages.map((message: IMessageProps) => {
        return <Message key={uuid()} {...message} />;
      })
    }

  </div>
};

export default React.memo(ActionMessage);
import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import DropdownType from '../type/enum/dropdown'
import DropdownMultiselectType from '../type/enum/dropdown-multiselect'

const DropdownElement: React.FC<AcceptedProps> = (props) => {
  if (props.inputConfig?.config?.multiselect) {
    return <DropdownMultiselectType {...props} />
  } else {
    return <DropdownType {...props} />
  }
}

export default React.memo(DropdownElement);
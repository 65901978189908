import { useEffect } from 'react';
import { RectResult } from '../../../../../../../../common/utils/useRect';
import { IFormStatus } from '../../interface/form-status';

interface IProps {
  tableContainerRect: RectResult;
  selectedStatus: IFormStatus;
}

export const useSetModalPosition = (props: IProps) => {
  const { tableContainerRect, selectedStatus } = { ...props };

  useEffect(() => {
    const statusModalEl: HTMLElement | null = document.getElementById('status-modal');
    const tableRect = tableContainerRect;
    const addNewStatus: HTMLElement | null = document.getElementById('add-new-status');
    const addNewStatusRect = addNewStatus?.getBoundingClientRect();

    if (selectedStatus.new) {
      if (statusModalEl) {
        document.body.appendChild(statusModalEl);
        statusModalEl.style.left = `calc(${addNewStatusRect?.left}px + 80px)`;
        statusModalEl.style.top = `calc(${tableRect?.top}px + ${window.scrollY}px + 5px)`;
        statusModalEl.style.position = `absolute`;
      }
    }
  }, [tableContainerRect, selectedStatus]);

  return { ...props };
}
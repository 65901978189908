import React, { SyntheticEvent } from 'react';
import { Form, DropdownProps } from 'semantic-ui-react-bpm';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';

interface IDataTablePermissions {
  modifyPermission: string[];
  viewPermission: string[];
  setModifyPermission(value: string[]): void;
  setViewPermission(value: string[]): void;
  datatablePermissionOption: any;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const DataTablePermissions: React.FC<IDataTablePermissions> = (props) => {
  return (<>
    <div className="ui small header">Data Table Permissions</div>
    <Form.Group className='datatable-permission-container'>
      <Form.Select
        popperContainer={DropdownPopperOption}
        clearable
        label='View Records'
        options={props.datatablePermissionOption} width='6'
        multiple
        search
        value={props.viewPermission}
        onChange={(e: SyntheticEvent<HTMLElement, Event>, target: DropdownProps) => {
          props.setViewPermission(target.value as string[]);
          props.hasPageChangeRef.current.hasChange = true;
        }}
        selectOnBlur={false}
        selection />
      <Form.Select
        popperContainer={DropdownPopperOption}
        clearable
        label='Edit/Add/Delete Records'
        multiple
        search
        value={props.modifyPermission}
        onChange={(e: SyntheticEvent<HTMLElement, Event>, target: DropdownProps) => {
          props.setModifyPermission(target.value as string[]);
          props.hasPageChangeRef.current.hasChange = true;
        }}
        selectOnBlur={false}
        options={props.datatablePermissionOption} width='6'
        selection />
    </Form.Group>
  </>
  )
}

export default React.memo(DataTablePermissions);

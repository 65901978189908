import { IDataTable } from '../../../admin-module/module/data-tables/reducer';
import { GET_DATATABLE_LIST_RETURN } from './constant';

export interface IEndUserDatatableReducer {
  type?: string;
  datatableList: IDataTable[];
}


const InitialState: IEndUserDatatableReducer = {
  datatableList: [],
}

const endUserDatatableReducer = (state = InitialState, action: IEndUserDatatableReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_DATATABLE_LIST_RETURN:
      newState.datatableList = action.datatableList;
      return newState;

    default:
      return newState;
  }

}

export default endUserDatatableReducer;
import React, { useRef } from 'react';
import { IAutomationLogStatus } from '../interface/automationLogStatus';

interface Props {
  list: IAutomationLogStatus[];
}

const AutomationLogsStatus: React.FC<Props> = (props) => {
  const accordTitle = useRef<HTMLDivElement>(null);
  const accordContent = useRef<HTMLDivElement>(null);

  const toggle = () => {
    const isActive = accordTitle.current?.classList?.contains('active');
    if (isActive) {
      accordTitle.current?.classList.remove('active')
      accordContent.current?.classList.remove('active')
    } else {
      accordTitle.current?.classList.add('active')
      accordContent.current?.classList.add('active')
    }
  }
  return (
    <div className="accordion ui" >
      <div className="title active" ref={accordTitle} onClick={toggle}>
        <i aria-hidden="true" className="dropdown icon"></i>What do the different statuses mean?
      </div>
      <div className="content active" ref={accordContent}>
        {props.list.map((status: IAutomationLogStatus, idx: number) => {
          return <p key={idx}><span className={`automation-logs-status-container ${status.class}`}>{status.label}</span> - {status.description}</p>
        })}
      </div>
    </div>
  );
}

export default React.memo(AutomationLogsStatus);

import React, { useEffect } from "react"
import { Route, useLocation, useHistory } from "react-router-dom"
import Main from './component/main'
import Sample from './component/sample';
import Auth from './component/auth';
import RestPassword from './component/auth/reset-password';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './reducers';
import { setCurrentLocationAction } from './component/main/action';
import AllowedUserListActionControl from './common/utils/user-list-action-control';
import { ConfirmationMessage } from './common/general/confirmation-modal';
import { UserProfileStaticKey } from './component/auth/interface/user-profile';

const Routes = () => {
  let location = useLocation();
  let dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem('token') || '';

  const { initializeApp, userLoggedIn } = useSelector((state: IRootState) => state.auth);
  const { statusMessage } = useSelector((state: IRootState) => state.errorStatusHandler);
  const validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn)

  useEffect(() => {
    if (location.pathname === '/forgotPassword/') {
      return history.push(`${location.pathname}${location.search}`);
    }
    if (token && location.pathname === '/auth') {
      history.push('/');
    }
    if (token && location.pathname === '/') {
      if (userLoggedIn.belongCompanyId) {
        if (validateUserProfile.hasOnlyProfile(UserProfileStaticKey.EndUser)) {
          history.push('/documents/workspaces');
        } else if (validateUserProfile.allowedAccessUsersModule()) {
          history.push('/admin/users');
        } else if (validateUserProfile.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic)) {
          history.push('/admin/business-process/form');
        } else if (validateUserProfile.hasSomeProfiles(UserProfileStaticKey.BPMManager)) {
          history.push('/bpm-manager/companies');
        } else {
          history.push('/');
        }
      }
    }
    if (!token && location.pathname !== '/auth') {
      history.push('/auth');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname, location.search, userLoggedIn]);

  useEffect(() => {
    dispatch(setCurrentLocationAction(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (initializeApp) {
    return <div>Initializing. . .</div>
  }

  if (statusMessage === 'Network Error' && !initializeApp) {
    return <ConfirmationMessage
      confirmButtonName={'Confirm'}
      confirmButtonColor={'green'}
      cancelButtonColor={'grey'}
      close={() => { }}
      open={true}
      confirm={() => window.location.reload()}
      cancel={() => { }}
      headerText="No Internet Connection"
      detailText={`Please check your Internet connection and try again.`}
      loading={false}
      type='error'
      showHeaderText={true}
      noCancelButton={true}
      reloadButton={true}
    />
  }

  return (
    <>
      <Route path="/auth" component={Auth} />
      {location.pathname === '/forgotPassword/' ?
        <Route path="/forgotPassword" component={RestPassword} />
        :
        <>{token && <Route path="/" component={Main} />}</>
      }
      <Route path="/sample" component={Sample} />
    </>
  )
}

export default Routes
import {
  IFieldConfig,
  IField,
} from '../../../../component/admin-module/module/users/interface/field';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import {
  ICustomFieldDetail,
  IReferencedFieldCondition,
} from '../../interface/custom-field-detail';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IOption } from '../../../interface/option';
import { URNFilterField, systemField } from '../constant';
import { IFormStatus } from '../../../../component/admin-module/module/business-process/module/workflow/interface/form-status';
import { OperatorOption } from '../operator-option';
import {
  currentBPOperator,
  regularFieldOperator,
} from '../../../utils/field-operator';

export class FilterConfigurationUtils {
  setFilterMetaDataFields = (configData: IFieldConfig) => {
    const fields = [...systemField].filter((e: ICustomFieldDetail) => {
      if (
        e.id === 'statusId' &&
        configData &&
        configData.dataType === 'database'
      ) {
        return false;
      }
      return true;
    });
    fields.push(URNFilterField);
    return fields;
  };

  setFilterDefaultFields = (
    filterMetaDataFields: ICustomFieldDetail[],
    selectedBpStatusList: IFormStatus[]
  ) => {
    return filterMetaDataFields.map((field: ICustomFieldDetail) => {
      if (field.id === 'statusId') {
        const { config } = { ...field };
        if (selectedBpStatusList.length > 0) {
          if (config) {
            config.data = selectedBpStatusList.map((e: IFormStatus) => {
              return { id: e.id, label: e.name };
            });
          }
        }
      }
      return field;
    });
  };

  setFilterInitialConfig = (
    referencedBPDetail: { id: string; name: string },
    currentBp: IForm | IDataTable,
    tableId?: string
  ): IReferencedFieldCondition => {
    return {
      id: '',
      fieldId: '',
      fromBp: !tableId ? referencedBPDetail.id : '',
      operator: '',
      compareType: 'value',
      compareValue: '',
      compareField: {
        fieldId: '',
        fromBp: '',
      },
    };
  };

  setTableFieldOptions = (
    fieldId: string,
    tableId?: string,
    tableFieldList?: ICustomFieldDetail[]
  ): IOption[] => {
    let ctr = 0;
    const options: IOption[] = [];
    if (tableId && tableFieldList) {
      tableFieldList.forEach((field: ICustomFieldDetail) => {
        if (field.id !== fieldId) {
          options.push({
            key: ctr++,
            text: field.label || '',
            value: field.id || '',
          });
        }
      });
    }
    return options;
  };

  setFieldsToOption = (fieldList: ICustomFieldDetail[]) => {
    let ctr = 0;
    const options: IOption[] = [];
    fieldList.forEach((field: ICustomFieldDetail | IField) => {
      if (
        field.configName !== IConfigName.Seq &&
        field.configName !== IConfigName.GAP
      ) {
        options.push({
          key: ctr++,
          text: field.label || '',
          value: field.id || '',
        });
      }
    });
    return options;
  };

  getReferenceBpDetail = (
    configData: IFieldConfig,
    fieldData: ICustomFieldDetail,
    publishedForm: IForm[],
    datatables: IDataTable[]
  ): { id: string; name: string } => {
    let referenceBpId = '';
    if (fieldData.configName === IConfigName.Referenced && configData) {
      if (configData.dataType === 'document') {
        referenceBpId = configData.data ? configData.data.formId || '' : '';
      }
      if (configData.dataType === 'database') {
        referenceBpId = configData.data ? configData.data.databaseId || '' : '';
      }
    }
    const referenceBp = publishedForm.filter(
      (form: IForm) => form.id === referenceBpId
    );
    if (referenceBp.length > 0) {
      return { id: referenceBp[0].id || '', name: referenceBp[0].name };
    }
    const datatable = datatables.filter(
      (dtTable: IDataTable) => dtTable.id === referenceBpId
    );
    if (datatable.length > 0) {
      return { id: datatable[0].id, name: datatable[0].name };
    }
    if (fieldData.configName === IConfigName.Referenced && configData) {
      if (configData.dataType === 'user') {
        return { id: '', name: 'Users Table' };
      }
    }
    return {} as { id: string; name: string };
  };

  getCurrentBpFieldOptions = (
    defaultField: ICustomFieldDetail[],
    fieldList: ICustomFieldDetail[],
    fieldId: string
  ): IOption[] => {
    let ctr = 0;
    const options: IOption[] = [];
    defaultField.forEach((system: ICustomFieldDetail) => {
      options.push({
        key: ctr++,
        text: system.label || '',
        value: system.id || '',
      });
    });
    fieldList.forEach((field: ICustomFieldDetail | IField) => {
      if (
        field.configName !== IConfigName.Seq &&
        field.id !== fieldId &&
        field.configName !== IConfigName.GAP
      ) {
        options.push({
          key: ctr++,
          text: field.label || '',
          value: field.id || '',
        });
      }
    });
    return options;
  };

  getReferencedBpFieldOptions = (
    defaultField: ICustomFieldDetail[],
    referenceFieldList: ICustomFieldDetail[],
    configData: IFieldConfig,
    userFieldList?: ICustomFieldDetail[]
  ): IOption[] => {
    let ctr = 0;
    const options: IOption[] = [];
    if (configData.dataType === 'user') {
      if (
        configData.dataType === 'user' &&
        userFieldList &&
        userFieldList.length > 0
      ) {
        userFieldList.forEach((field) => {
          options.push({
            key: ctr++,
            text: field.label || '',
            value: field.id || '',
          });
        });
      }
    } else {
      defaultField.forEach((system: ICustomFieldDetail) => {
        options.push({
          key: ctr++,
          text: system.label || '',
          value: system.id || '',
        });
      });
    }
    referenceFieldList.forEach((field: ICustomFieldDetail) => {
      if (
        field.configName !== IConfigName.Seq &&
        field.configName !== IConfigName.GAP
      ) {
        options.push({
          key: ctr++,
          text: field.label || '',
          value: field.id || '',
        });
      }
    });
    return options;
  };

  getFilterOperatorOption = (option: IOption[], valuesToShow: string[]) => {
    let newOption = [] as IOption[];
    let ctr = 0;
    valuesToShow.forEach((values: string) => {
      option.forEach((option: IOption) => {
        if (option.value === values) {
          newOption.push({
            key: ctr++,
            text: option.text,
            value: option.value,
          });
        }
      });
    });
    return newOption;
  };

  getOperatorOptionToShow = (
    fieldType: string,
    condition: IReferencedFieldCondition,
    currentBp: IForm | IDataTable,
    configData?: IFieldConfig
  ) => {
    let operatorOption = OperatorOption.getOption(fieldType);
    let newOption = [] as IOption[];

    if (configData && configData.dataType === 'user' && fieldType === 'id') {
      operatorOption = [
        ...operatorOption,
        { key: 1, value: 'excludes', text: 'Not In' },
      ];
    }

    if (!currentBPOperator[fieldType]) {
      return [];
    }
    if (currentBp.id === condition.fromBp) {
      newOption = this.getFilterOperatorOption(
        operatorOption,
        currentBPOperator[fieldType].split('|')
      );
    } else {
      newOption = this.getFilterOperatorOption(
        operatorOption,
        regularFieldOperator[fieldType].split('|')
      );
    }
    return newOption;
  };

  getOperatorOption = (
    condition: IReferencedFieldCondition,
    referencedBPDetail: { id: string; name: string },
    currentBp: IForm | IDataTable,
    fieldList: ICustomFieldDetail[],
    referenceFieldList: ICustomFieldDetail[],
    defaultField: ICustomFieldDetail[],
    tableFieldList?: ICustomFieldDetail[],
    userFieldList?: ICustomFieldDetail[],
    configData?: IFieldConfig
  ): IOption[] => {
    if (condition.fieldId && condition.fieldId !== '') {
      let fieldConfig: ICustomFieldDetail | undefined = undefined;
      if (
        configData &&
        configData.dataType === 'user' &&
        userFieldList &&
        userFieldList.length > 0
      ) {
        fieldConfig = userFieldList.find((e) => e.id === condition.fieldId);
      }
      if (condition.fromBp) {
        if (currentBp.id === condition.fromBp) {
          fieldConfig = fieldList.find(
            (field: ICustomFieldDetail) => field.id === condition.fieldId
          );
          if (condition.tableId && tableFieldList) {
            fieldConfig = tableFieldList.find(
              (field: ICustomFieldDetail) => field.id === condition.fieldId
            );
          }
        }
        if (referencedBPDetail.id === condition.fromBp) {
          fieldConfig = referenceFieldList.find(
            (field: ICustomFieldDetail) => field.id === condition.fieldId
          );
        }
      }
      if (!fieldConfig) {
        fieldConfig = defaultField.find(
          (field: ICustomFieldDetail) => field.id === condition.fieldId
        );
      }
      if (fieldConfig && fieldConfig.type) {
        if (
          fieldConfig.type === 'enum' &&
          fieldConfig.config &&
          fieldConfig.config.multiselect
        ) {
          return this.getOperatorOptionToShow(
            'multiple-enum',
            condition,
            currentBp,
            configData
          );
        }
        if (fieldConfig.config && fieldConfig.type === 'autopopulated') {
          return this.getOperatorOptionToShow(
            fieldConfig.config?.labelType || '',
            condition,
            currentBp,
            configData
          );
        }
        return this.getOperatorOptionToShow(
          fieldConfig.type || '',
          condition,
          currentBp,
          configData
        );
      }
    }
    return [];
  };

  getSelectedField = (
    condition: IReferencedFieldCondition,
    formBuilderField: ICustomFieldDetail[],
    referencedBPDetail: { id: string; name: string },
    currentBp: IForm | IDataTable,
    referenceFieldList: ICustomFieldDetail[],
    defaultField: ICustomFieldDetail[],
    tableFieldList?: ICustomFieldDetail[],
    userFieldList?: ICustomFieldDetail[],
    configData?: IFieldConfig
  ): ICustomFieldDetail => {
    if (condition.fieldId && condition.fieldId !== '') {
      let fieldConfig: ICustomFieldDetail | undefined = undefined;
      if (
        configData &&
        configData.dataType === 'user' &&
        userFieldList &&
        userFieldList.length > 0
      ) {
        fieldConfig = userFieldList.find((e) => e.id === condition.fieldId);
      }
      if (condition.fromBp) {
        if (currentBp.id === condition.fromBp) {
          fieldConfig = formBuilderField.find(
            (field: ICustomFieldDetail) => field.id === condition.fieldId
          );
          if (condition.tableId && tableFieldList) {
            fieldConfig = tableFieldList.find(
              (field: ICustomFieldDetail) => field.id === condition.fieldId
            );
          }
        }
        if (referencedBPDetail.id === condition.fromBp) {
          fieldConfig = referenceFieldList.find(
            (field: ICustomFieldDetail) => field.id === condition.fieldId
          );
        }
      }
      if (!fieldConfig) {
        fieldConfig = defaultField.find(
          (field: ICustomFieldDetail) => field.id === condition.fieldId
        );
      }
      if (fieldConfig && Object.keys(fieldConfig).length > 0) {
        if (fieldConfig.type === 'autopopulated' && fieldConfig.config) {
          fieldConfig = {
            ...fieldConfig,
            type: fieldConfig.config.labelType,
          };
        }
        return fieldConfig;
      }
    }
    return {} as ICustomFieldDetail;
  };

  getSelectedCompareField = (
    fieldId: string,
    formBuilderField: ICustomFieldDetail[],
    referenceFieldList: ICustomFieldDetail[],
    defaultField: ICustomFieldDetail[],
    tableFieldList?: ICustomFieldDetail[]
  ): ICustomFieldDetail => {
    let fieldList = [
      ...defaultField,
      ...referenceFieldList,
      ...formBuilderField,
    ];
    if (tableFieldList) {
      fieldList = fieldList.concat(tableFieldList);
    }
    const compareFieldConfig = fieldList.find((e) => e.id === fieldId);
    return compareFieldConfig as ICustomFieldDetail;
  };

  getReferencedFieldType = (
    fieldList: ICustomFieldDetail[],
    fieldId: string
  ) => {
    return fieldList.filter(
      (e) => e.id !== fieldId && e.configName === IConfigName.Referenced
    );
  };

  getFieldDetails = (fieldList: ICustomFieldDetail[], fieldId?: string) => {
    return fieldList.find((e) => e.id === fieldId);
  };
}

import { ISenderDetail } from '../form/interface/sender-info'
import { GET_TASKS_LIST, GET_TASKS_LIST_RETURN, GET_TASKS_LIST_ERROR, TRIGGER_BULK_ACTION, TRIGGER_BULK_ACTION_RETURN, TRIGGER_BULK_ACTION_ERROR, CLEAR_TASKS_LIST_ERROR, SET_TASK_LIST_VALIDATION_ERROR, UNDO_TASK_ACTION, GET_ACTION_USER_LIST, GET_ACTION_USER_LIST_RETURN, GET_ACTION_USER_LIST_ERROR, GET_BP_FIELD_PERMISSIONS, GET_BP_FIELD_PERMISSIONS_ERROR, GET_BP_FIELD_PERMISSIONS_RETURN, SAVE_TASK_DATA_RETURN, SAVE_TASK_DATA, SAVE_TASK_DATA_ERROR, GET_TASK_CUSTOM_LAYOUT, GET_TASK_CUSTOM_LAYOUT_ERROR, GET_TASK_CUSTOM_LAYOUT_RETURN, RESET_TASK_LIST, UPDATE_CURRENT_USER_IN_DOCUMENT } from './constant'
import { IBPFieldPermissionDetailInput } from './interface/input/bp-field-permission'
import { ITaskCustomLayoutDetailInput } from './interface/input/task-custom-layout'
import { ITaskSaveDataDetailInput } from './interface/input/task-save'
import { ITasksListDetailInput, ITaskBulkActionDetailInput, IIndividualUserListDetailInput } from './interface/input/tasks-list'
import { IBpFieldPermissionResponse } from './interface/response/bp-field-permission'
import { ITaskCustomLayoutResponse } from './interface/response/task-custom-layout'
import { ITaskSaveDataResponse } from './interface/response/task-save'
import { ITasksListResponse, ITasksBulkActionsResponse, IIndividualUserListResponse } from './interface/response/tasks-list'

export const getTasksListAction = (payload: ITasksListDetailInput) => {
  return { type: GET_TASKS_LIST, ...payload }
}

export const getTasksListReturnAction = (payload: ITasksListResponse) => {
  return { type: GET_TASKS_LIST_RETURN, ...payload }
}

export const getTasksListErrorAction = (message: string) => {
  return { type: GET_TASKS_LIST_ERROR, message }
}

export const triggerBulkActionsAction = (payload: ITaskBulkActionDetailInput) => {
  return { type: TRIGGER_BULK_ACTION, ...payload }
}

export const triggerBulkActionsReturnAction = (payload: ITasksBulkActionsResponse) => {
  return { type: TRIGGER_BULK_ACTION_RETURN, ...payload }
}

export const triggerBulkActionsErrorAction = (message: string) => {
  return { type: TRIGGER_BULK_ACTION_ERROR, message }
}

export const clearTaskListErrorAction = () => {
  return { type: CLEAR_TASKS_LIST_ERROR }
}

export const setTaskListValidationError = (message: string) => {
  return { type: SET_TASK_LIST_VALIDATION_ERROR, message }
}

export const undoTaskAction = (payload: ITaskBulkActionDetailInput) => {
  return { type: UNDO_TASK_ACTION, ...payload }
}

export const getTaskActionUserListAction = (payload: IIndividualUserListDetailInput) => {
  return { type: GET_ACTION_USER_LIST, ...payload }
}

export const getTaskActionUserListReturnAction = (payload: IIndividualUserListResponse) => {
  return { type: GET_ACTION_USER_LIST_RETURN, ...payload }
}

export const getTaskActionUserListErrorAction = (message: string) => {
  return { type: GET_ACTION_USER_LIST_ERROR, message }
}

export const getBpFieldPermissionAction = (payload: IBPFieldPermissionDetailInput) => {
  return { type: GET_BP_FIELD_PERMISSIONS, ...payload }
}

export const getBpFieldPermissionReturnAction = (payload: IBpFieldPermissionResponse) => {
  return { type: GET_BP_FIELD_PERMISSIONS_RETURN, ...payload }
}

export const getBpFieldPermissionErrorAction = (message: string) => {
  return { type: GET_BP_FIELD_PERMISSIONS_ERROR, message }
}

export const saveTaskDataAction = (payload: ITaskSaveDataDetailInput) => {
  return { type: SAVE_TASK_DATA, ...payload }
}

export const saveTaskDataReturnAction = (payload: ITaskSaveDataResponse) => {
  return { type: SAVE_TASK_DATA_RETURN, ...payload }
}

export const saveTaskDataErrorAction = (message: string) => {
  return { type: SAVE_TASK_DATA_ERROR, message }
}

export const getTaskCustomLayoutAction = (payload: ITaskCustomLayoutDetailInput) => {
  return { type: GET_TASK_CUSTOM_LAYOUT, ...payload }
}

export const getTaskCustomLayoutReturnAction = (payload: ITaskCustomLayoutResponse) => {
  return { type: GET_TASK_CUSTOM_LAYOUT_RETURN, ...payload }
}

export const getTaskCustomLayoutErrorAction = (message: string) => {
  return { type: GET_TASK_CUSTOM_LAYOUT_ERROR, message }
}

export const resetTaskListAction = () => {
  return { type: RESET_TASK_LIST }
}

export const updateCurrentUserInDocument = (payload: { [docId: string]: ISenderDetail[] }) => {
  return { type: UPDATE_CURRENT_USER_IN_DOCUMENT, currentUserInDocument: payload }
}
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';

interface IStatusList {

}

const StatusList: React.FC<IStatusList> = (props) => {
  return <div className='status-list-main'>
    <Button className={`btn-status active`}>
      QUOTATION
    </Button>
    <Button className={`btn-status`}>
      CANCELLED
    </Button>
    <Button className={`btn-status`}>
      PURCHASE ORDER
    </Button>
    <Button className={`btn-status`}>
      FOR APPROVAL
    </Button>
    <Button className={`btn-status`}>
      REJECTED
    </Button>
  </div>
}

export default StatusList;
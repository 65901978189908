import React from 'react';
import { IFormPermissionSection, IStatusAction } from '../interface/section';
import ActionsList from './list';

interface IActionContainer {
  selectedStatus: IFormPermissionSection;
  handleActionPermissionChange: Function;
  openStatusActionPermissionSettings: Function;
  openPermissionFilterConfig: boolean;
}

const ActionContainer: React.FC<IActionContainer> = (props) => {
  const containerStyle = {
    borderRight: `${props.openPermissionFilterConfig ? '0.12em solid #3B86A8' : 'none'}`
  };

  return <div className='action-field-container'>
    <div className='action-list-container' style={containerStyle}>
      {props.selectedStatus && props.selectedStatus.actions &&
        props.selectedStatus.actions.length > 0 &&
        <div className='action-list-wrap'>
          {props.selectedStatus.actions.map((actions: IStatusAction) => {
            return <ActionsList
              key={actions.id}
              action={actions}
              handleActionPermissionChange={props.handleActionPermissionChange}
              openStatusActionPermissionSettings={props.openStatusActionPermissionSettings}
            />
          })}
        </div>
      }
    </div>
  </div>
}

export default ActionContainer;
import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react-bpm';

interface IPortalUserFilter {

}

const PortalUserFilter: React.FC<IPortalUserFilter> = (props) => {
  return <div className='portal-user-filter'>
    <div className='filter-container'>
      <div className='search-filter'>
        <div className="ui input">
          <input defaultValue={''}
            placeholder='Search'
            onChange={() => { }}
          />
        </div>
      </div>
      <div className='dropdown-filters'>
        <Dropdown
          options={[{ key: 1, text: 'Organization', value: "organization" }]}
          onChange={(e, target: any) => { }}
          placeholder='Organization'
          selection
          value={'organization'}
          selectOnBlur={false}
          search
        />
      </div>
    </div>
    <div>
      <Button type='button' className={`add-button`}
        primary size={"large"} floated={"right"} onClick={() => { }}>Add User</Button>
    </div>
  </div>
}

export default PortalUserFilter;
import React from 'react';
import { Icon, Pagination } from 'semantic-ui-react-bpm';
import useDeviceDetect from '../device-detect';

interface IPaginationProps {
  totalCount: number;
  currentLimit: number;
  currentOffset: number;
  changePage(activePage: number, offset: number): void;
}

const PaginationComponent: React.FC<IPaginationProps> = (props) => {
  const { width } = useDeviceDetect();
  const currentTotalCountInPage = ((props.currentOffset + 1) * props.currentLimit)
  const currentPageTotal = currentTotalCountInPage > props.totalCount ? props.totalCount : currentTotalCountInPage;
  const totalPage = Math.ceil(props.totalCount / props.currentLimit);

  const changePage = (activePage: number) => {
    const offset = (activePage - 1) * props.currentLimit;
    props.changePage(props.currentLimit, offset)
  }

  const _activePage = Math.ceil(props.currentOffset / props.currentLimit) + 1;

  return <div className={`pagination-container ${width <= 480 ? 'mobile' : ''}`}>
    {`${props.currentOffset + 1}-${currentPageTotal} of ${props.totalCount} records `}
    <Pagination
      boundaryRange={0}
      ellipsisItem={null}
      firstItem={_activePage === 1 ? null : { content: <span>First</span>, icon: false }}
      lastItem={_activePage === totalPage ? null : { content: <span>Last</span>, icon: false }}
      prevItem={_activePage === 1 ? null : { content: <Icon name={`angle left`} />, icon: true }}
      nextItem={_activePage === totalPage ? null : { content: <Icon name={`angle right`} />, icon: true }}
      totalPages={totalPage}
      activePage={_activePage}
      siblingRange={width <= 480 ? 1 : 4}
      onPageChange={(e, props) => { changePage((props.activePage || 1) as number) }}
    />
  </div>
}

export default PaginationComponent;
import React, { useState } from 'react';
import { Dropdown, Form, Icon, Popup } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../common/custom-field-config/interface/custom-field-detail';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
import { IOption } from '../../../../../common/interface/option';
import { IHasPageChangeRef } from '../../../../main/interface/has-page-change';
import { IDatatableConfig, IDatatableConfigUniqueConstraint, IDTRecordGroupedKeypath } from '../interface/data-table-config';
import DataTableSelectedGroupings from './data-table-selected-groupings';

interface IDataTableConfig {
  datatableCustomField: ICustomFieldDetail[];
  handleAddDatatableConfig(): void;
  handleEditDatatableConfig(name: string, value: string | string[], action?: 'add' | 'delete'): void;
  datatableConfig: IDatatableConfig;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
  deleteConstraint(index: number): void;
  DTRecordsGroupedConfig: string[];
  datatableId: string;
  groupedRecordsKeypath: IDTRecordGroupedKeypath[];
}

const DataTableConfig: React.FC<IDataTableConfig> = (props) => {

  const [isDTGroupingOpen, setIsDTGroupingOpen] = useState(false);

  const getDTFieldOptions = (): IOption[] => {
    const options: IOption[] = [];
    props.datatableCustomField.forEach((field, index) => {
      if (field && field.label && !field.deleted && field.label !== 'ID') {
        const exists = options.find(e => e.value === field.id);
        if (!exists) {
          options.push({ key: index, text: field.label, value: field.id || '' });
        }
      }
    });
    return options;
  }

  const getDTGroupFieldOptions = (): IOption[] => {
    const options: IOption[] = [];
    props.groupedRecordsKeypath.forEach((field, index) => {
      const exists = options.find(e => e.value === field.keyPath);
      if (!exists) {
        options.push({ key: index, text: field.name, value: field.keyPath });
      }
    });
    return options;
  }

  const groupedDTTriggerRef = (ref: any) => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      const popup = document.getElementById('dt-group-popup');
      if (popup) {
        popup.style.setProperty('min-width', `${rect.width}px`);
      }
    }
  }

  const onGroupedDTTriggerClick = (e: any) => {
    if (e.target.className.indexOf('dt-grouped-item') === -1
      && e.target.className.indexOf('delete icon') === -1
      && e.target.className.indexOf('clear') === -1) {
      setIsDTGroupingOpen(true);
    }
  }

  const onGroupedDTItemAction = (value: string, action: 'add' | 'delete') => {
    if (action === 'add' && props.DTRecordsGroupedConfig.length === 5) {
      return;
    }
    props.handleEditDatatableConfig('groupings', value, action);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const changeListSequence = (draggedItem: any, droppedItemId: string) => {
    const updatedOptionList: string[] = [...props.DTRecordsGroupedConfig];
    const dragItemIndex = props.DTRecordsGroupedConfig.indexOf(draggedItem.data.value);
    const dropContainerIdIndex = props.DTRecordsGroupedConfig.indexOf(props.DTRecordsGroupedConfig.filter(e => e === droppedItemId)[0]);
    const element = updatedOptionList[dragItemIndex];
    updatedOptionList.splice(dragItemIndex, 1);
    updatedOptionList.splice(dropContainerIdIndex, 0, element);
    props.handleEditDatatableConfig('groupings', updatedOptionList);
  }

  const groupedDTTrigger = () => {
    return <div className={`icon menu-toggle dt-grouped-trigger-menu`}
      id='dt-grouped-trigger-menu' ref={groupedDTTriggerRef} onClick={onGroupedDTTriggerClick}>
      {props.DTRecordsGroupedConfig.map((selected, index) => {
        const details = getDTGroupFieldOptions().find(e => e.value === selected);
        if (!details) {
          return null;
        }
        return <DataTableSelectedGroupings
          key={`item-${selected}`}
          changeListSequence={changeListSequence}
          onGroupedDTItemAction={onGroupedDTItemAction}
          selectedOption={details}
          selectedOptionIndex={index}
        />
      })}
      {props.DTRecordsGroupedConfig.length > 0 ?
        <i aria-hidden="true" className="dropdown icon clear"
          onClick={() => props.handleEditDatatableConfig('groupings', [])}
        />
        :
        <Icon name='dropdown' size='small' />
      }
    </div>
  }

  return <Form.Group className='datatable-config-main'>
    <div className='datatable-config-container'>
      <div className="ui small header">Data Table Configurations</div>
      <div className='field datatable-config-field'>
        <label>Unique Records</label>
        <div className='datatable-config-enum'>
          {props.datatableConfig.uniqueConstraints.map((config: IDatatableConfigUniqueConstraint, index: number) => {
            return <div key={uuid()}>
              <Dropdown
                popperContainer={DropdownPopperOption}
                clearable
                options={getDTFieldOptions()}
                multiple
                search
                value={config.fields}
                onChange={(e, target: any) => {
                  props.handleEditDatatableConfig(config.name, target.value);
                  props.hasPageChangeRef.current.hasChange = true;
                }}
                selectOnBlur={false}
                selection />
              {props.datatableConfig.uniqueConstraints.length > 0 && !((props.datatableConfig.uniqueConstraints.length - 1) === 0) &&
                < i className={`ui icon trash`} onClick={() => props.deleteConstraint(index)} />
              }
              {(props.datatableConfig.uniqueConstraints.length - 1) === index &&
                < i className={`plus circular icon new-config ${index === 0 ? 'no-delete' : ''}`}
                  onClick={() => props.handleAddDatatableConfig()} />
              }
            </div>
          })}
        </div>
      </div>
    </div>
    {props.datatableId !== 'add-data-table' &&
      <div className='datatable-config-container'>
        <div className="ui small header">Data Table Groupings</div>
        <div className='field datatable-config-field'>
          <label>Grouped by: <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(Max of 5 fields)</span> </label>
          <Popup
            on='click'
            basic
            position='bottom left'
            id='dt-group-popup'
            eventsEnabled={false}
            open={isDTGroupingOpen}
            openOnTriggerClick
            onClose={(e: any) => {
              if (e.target.className.indexOf('dt-grouped-item') === -1
                && e.target.className.indexOf('delete icon') === -1
                && e.target.className.indexOf('clear') === -1) {
                setIsDTGroupingOpen(false);
              }
            }}
            trigger={groupedDTTrigger()}>
            <div className='datatable-config-enum'>
              <Dropdown.Menu>
                {getDTGroupFieldOptions().length === props.DTRecordsGroupedConfig.length ?
                  <Dropdown.Item >
                    No results found.
                  </Dropdown.Item>
                  :
                  getDTGroupFieldOptions().map(options => {
                    if (props.DTRecordsGroupedConfig.includes(options.value)) {
                      return null;
                    }
                    return <Dropdown.Item key={options.key} onClick={() => {
                      onGroupedDTItemAction(options.value, 'add');
                    }}>
                      {options.text}
                    </Dropdown.Item>
                  })
                }
              </Dropdown.Menu>
            </div>
          </Popup>
        </div>
      </div>
    }
  </Form.Group>
}

export default React.memo(DataTableConfig);
import { plainToClass } from 'class-transformer';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Dropdown, Popup } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { FieldElement } from '../../../../../../../../../../../../../common/field/component/field-element';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IField, IFieldAccessType } from '../../../../../../../../../../users/interface/field';
import { IAutomationFieldInput } from '../../../../../interface/automation';
import { IAutomationComponent } from '../../../../../interface/component-list';
import { useSetEditFormSelectedFields } from './hooks/use-set-edit-form-selected-field';

interface ISelectedFieldProps {
  fieldConfig: IField;
  dynamicRef: any;
  company: string;
  selectedBP: string;
  storeDynamicField(): void;
  openModal(isVisible: boolean, fieldId: string): void;
  isVisible: { [id: string]: boolean };
  operation: { [id: string]: string };
  manageOperator(value: string, id: string): void;
  selectedComponent: IAutomationComponent;
  manageDateFieldToday?(value: boolean, fieldId: string): void;
  dateFieldObject: { [id: string]: boolean };
  handleTableRowState?(fieldId: string, value: { minRows: number; maxRows: number; config: any }): void;
  tableFocusRef?: any;
  tableRowRef?: any;
}

const SelectedField: React.FC<ISelectedFieldProps> = (props) => {

  const field = plainToClass(FieldElement, props.fieldConfig);
  const Element = field.getFieldElement();

  const [isPopupOpen, setisPopupOpen] = useState(false);
  const isDateValueToday = props.dateFieldObject[field.id || ''] || false;

  const {
    formFieldList,
    automationFieldInput
  } = useSelector((state: IRootState) => state.automation);

  const {
    operation,
    sourceReference,
    setSourceReference,
    fieldId,
    setFieldId
  } = useSetEditFormSelectedFields({
    dynamicRef: props.dynamicRef,
    field,
    operation: props.operation
  });

  const getSoureceReferenceLabel = () => {
    const label = getReferenceFieldOption().find((e: IDropdownOption) => e.value === sourceReference);
    if (label) {
      return label.text;
    }
    return '';
  }

  const getReferenceFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [{
      key: uuid(),
      value: 'current-form',
      text: 'Current Form'
    }];
    const fieldList = automationFieldInput.filter(e => e.keyPath.startsWith('fields') && !(e.referenceType && e.referencedKeyPath && e.referencedName));
    fieldList.forEach((field: IAutomationFieldInput) => {
      const fieldData = formFieldList.find(e => `fields.${e.id}` === field.keyPath);
      if (fieldData && fieldData.configName === IConfigName.Referenced) {
        options.push({
          key: uuid(),
          value: field.keyPath,
          text: field.name
        })
      }
    });
    return options;
  }

  const manageElementValue = () => {
    delete props.dynamicRef.current[field.id].referencedKeyPath;
    delete props.dynamicRef.current[field.id].keyPath;
    setFieldId('')
    setSourceReference('')
    props.storeDynamicField()
  }

  const setOperator = (value: string, id: string) => {
    if (value === 'value') {
      setSourceReference('')
      setFieldId('')
    }
    props.manageOperator(value, id);
  }

  const getFormFieldOption = (): IDropdownOption[] => {
    const dateConfigs = [IConfigName.Date, IConfigName.Timestamp, IConfigName.Time, IConfigName.Range];
    const dateMeta = ['document.createdAt', 'document.updatedAt'];

    let fieldList: IAutomationFieldInput[] = automationFieldInput.filter(e =>
      e.keyPath.startsWith('fields') && e.keyPath !== `fields.${props.fieldConfig.id}`);
    let metaList: IAutomationFieldInput[] = [];

    if (sourceReference !== 'current-form') {
      fieldList = automationFieldInput.filter(e => e.referencedKeyPath === sourceReference);
    } else {
      const metadataFields = automationFieldInput.filter(e => !e.keyPath.startsWith('fields') && !e.referenceType);

      if (props.fieldConfig.configName && dateConfigs.includes(props.fieldConfig.configName)) {
        metaList = metadataFields.filter(meta => dateMeta.includes(meta.keyPath));
      } else {
        metaList = metadataFields.filter(meta => !dateMeta.includes(meta.keyPath));
      }

      fieldList = fieldList.filter(field => {
        const fieldData = formFieldList.find(e => `fields.${e.id}` === field.keyPath);
        return fieldData && fieldData.configName !== IConfigName.Referenced
          && fieldData.configName !== IConfigName.Table;
      });
    }

    return fieldList.concat(metaList)
      .filter(e => e.name !== 'Seq')
      .map(field => {
        return {
          key: uuid(),
          value: field.keyPath,
          text: field.name
        }
      });
  }

  const manageSourceReference = (value: string, id: string) => {
    setSourceReference(value)
    setFieldId('');
    if (!props.dynamicRef.current[id]) {
      props.dynamicRef.current[id] = {};
    }
    props.dynamicRef.current[id].referencedKeyPath = value;
    props.dynamicRef.current[id].keyPath = '';
  }

  const manageFieldId = (value: string, id: string) => {
    setFieldId(value);
    props.dynamicRef.current[id].keyPath = value;
  }

  const accessType = field.configName === IConfigName.Date && isDateValueToday ? IFieldAccessType.Readonly : field.accessType;
  const placeHolder = field.configName === IConfigName.Date && isDateValueToday ? 'Today' : '';

  if (field.configName === IConfigName.Date && isDateValueToday && props.dynamicRef.current[field.id || '']
    && props.dynamicRef.current[field.id || ''].value) {
    props.dynamicRef.current[field.id || ''].value = null;
  }

  return (
    <div key={uuid()} className={`automation-field-container with-button select-field-container`}>
      <div className={`field automation-field full ${field.inputConfig.config.format}`}>
        {operation === 'value'
          ? <>
            <Element hasIconInside={false}
              {...field}
              accessType={accessType}
              forwardedRef={props.dynamicRef}
              defaultValueElement={isDateValueToday || undefined}
              isAddRecord={true}
              fieldEnumOptionUrl={`${props.company}/documents/fields?formId=${props.selectedBP}`}
              throwValueOutside={manageElementValue}
              required={field.accessType === IFieldAccessType.Required}
              placeHolder={placeHolder}
              havePlaceholder={isDateValueToday || false}
              handleTableRowState={props.handleTableRowState}
              tableFocusRef={props.tableFocusRef}
              tableRowRef={props.tableRowRef}
            />
            {field.configName === IConfigName.Date &&
              <div className="ui fitted checkbox chkbx-today" onClick={(e) => {
                if (props.manageDateFieldToday) {
                  props.manageDateFieldToday(!isDateValueToday, field.id || '');
                }
              }}>
                <input className="hidden" type="checkbox" onChange={() => { }} checked={isDateValueToday} />
                <label>Today</label>
              </div>
            }
          </>
          : <>
            <label>{field.label} {field.accessType === IFieldAccessType.Required ? <span className={`required`}>*</span> : ''}</label>
            <Dropdown
              text={``}
              icon='dropdown'
              floating
              labeled
              button
              className='icon'
              popperContainer={DropdownPopperOption}
              closeOnChange={false}
            >
              <Dropdown.Menu className={`${props.isVisible[field.id] === true ? 'visible' : ''}`}>
                <Dropdown.Menu scrolling className={`option-list`}>
                  <div className={`option-title`}>Select Source Form</div>
                  <Dropdown
                    popperContainer={DropdownPopperOption}
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={sourceReference}
                    options={getReferenceFieldOption()}
                    onChange={(event, target: any) => { manageSourceReference(target.value, field.id) }}
                  />
                  <div className={`option-title`}>Field to copy value from</div>
                  <Dropdown
                    popperContainer={DropdownPopperOption}
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={fieldId}
                    options={getFormFieldOption()}
                    onChange={(event, target: any) => { manageFieldId(target.value, field.id) }}
                  />
                  <span className={`close-button`} onClick={(e) => {
                    props.openModal(props.isVisible[field.id], field.id);
                    e.stopPropagation();
                  }}>Close</span>
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ position: 'relative' }}>
              <label className={`dropdown-text-cover`}
                onClick={() => props.openModal(props.isVisible[field.id], field.id)}>
                Copy <label>{field.label}</label> from <label>{getSoureceReferenceLabel()}</label>
              </label>
            </div>
          </>
        }
      </div>
      <div className='button-container'>
        <Popup
          basic
          on='click'
          position='bottom left'
          id='copy-from-popup'
          style={{ filter: 'none', zIndex: '10000' }}
          trigger={
            <Button
              className={`btn-action-collapse ${isPopupOpen ? 'open' : ''}`}
              onClick={() => setisPopupOpen(!isPopupOpen)}
              icon>
              <i className="ellipsis horizontal icon"></i>
            </Button>
          }
          openOnTriggerClick={false}
          open={isPopupOpen}
        >
          <Container className='copy-from-popup-popup-container' >
            <div>OPERATION</div>
            <ul>
              <li onClick={() => operation === 'value' ? null : setOperator('value', field.id)}>
                {operation === 'value'
                  ? <span>✓</span>
                  : <span>&nbsp;&nbsp;&nbsp;</span>
                }
                &nbsp;&nbsp;Set value</li>
              <li onClick={() => operation === 'copy' ? null : setOperator('copy', field.id)}>
                {operation === 'copy'
                  ? <span>✓</span>
                  : <span>&nbsp;&nbsp;&nbsp;</span>
                }
                &nbsp;&nbsp;Copy from
              </li>
            </ul>
          </Container>
        </Popup>
      </div>
    </div>
  );
}

export default SelectedField;
import React, { useRef } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd';
import SVGWrapper from '../../../../../../../../../common/icons/svg/svg-wrapper';
import { DragObjectDisplayData, IDisplayDataConfigKeypaths, IDisplayDataFields } from '../interface/display-data';

interface IFieldProps {
  field: IDisplayDataFields;
  index: number;
  fieldType: 'header' | 'content';
  dropField(selectedItem: DragObjectDisplayData, type: 'header' | 'content'): void;
  sortField(draggedItem: DragObjectDisplayData, index: number, type: 'header' | 'content'): void;
  removeField(index: number, fieldType: string): void;
  updateField(index: number, tempField: IDisplayDataFields, fieldType: "header" | "content"): void;
  displayDataConfigKeypaths: IDisplayDataConfigKeypaths[];
}

const Fields: React.FC<IFieldProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const fields = props.field.config.map(config => {
    const configKeyPaths = props.displayDataConfigKeypaths.find(e => e.keyPath === config);
    if (configKeyPaths) {
      return configKeyPaths.name;
    }
    return 'Deleted field';
  });

  const [, dragSortFieldRef] = useDrag({
    item: { type: props.fieldType, data: props.field, index: props.index, fieldType: props.fieldType },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  const [, dropSortFieldRef] = useDrop({
    accept: props.fieldType,
    drop: (selectedItem: DragObjectDisplayData) => {
      props.sortField(selectedItem, props.index, props.fieldType);
    }
  });

  dragSortFieldRef(dropSortFieldRef(divRef));

  return <div className='content-field-container' ref={divRef}>
    <div className={`content-field`}>
      <Image src={icons.blue.dragIcon} className='drag-icon' />
      <label title={fields.join(', ')}>{fields.join(', ')}</label>
      <Image className='close' src={icons.blue.close} onClick={() => props.removeField(props.index, props.fieldType)} />
    </div>
    <div className='field-label' onClick={() => {
      const tempField = { ...props.field };
      tempField.showLabel = !(tempField.showLabel);
      props.updateField(props.index, tempField, props.fieldType);
    }}>
      {!props.field.showLabel &&
        <svg className='slash' width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="14.3536" y1="0.353553" x2="0.353553" y2="14.3536" stroke="#8A9296" />
        </svg>
      }
      <SVGWrapper color='#8A9296' iconName='eye' width='17' height='17' />
    </div>
  </div>
}

const LabelFields: React.FC<IFieldProps> = (props) => {
  return (
    <div className='content-field-main'>
      <Fields {...props} />
    </div>
  )
}

export default LabelFields;
import React from 'react';
import ForgotPassword from '../../../auth/reset-password';

class ForgotPasswordCallback {

  name = 'forgotPassword';

  render: React.FC = () => {

    return <>
      <ForgotPassword />
    </>
  }
}

export default ForgotPasswordCallback;
import Axios from 'axios';
import { IField } from '../../../../../../component/admin-module/module/users/interface/field';
import { IReloadFieldDetailInput } from '../../../../../../component/document-module/module/form/interface/input/field-trigger-reload';
import { IReloadFieldResponse } from '../../../../../../component/document-module/module/form/interface/response/field-trigger-reload';
import { IAxiosResponse } from '../../../../../api-request';
import { triggerFieldReloadAction } from '../../../../../utils/trigger-field-reload';

interface IUtils {
  fieldList: IField[],
  companyId: string;
  statusId: string;
  formId: string;
  documentId: string;
  dispatchTriggerFieldReload: (data: IReloadFieldResponse) => void;
  dynamicRefs: any;
  fieldReload: string[];
}

export class FieldTableReload {
  static setReloadData = (utils: IUtils) => {
    const dataTrigger = triggerFieldReloadAction({
      ...utils,
      returnDataOnly: true,
      reloadField: utils.fieldReload
    }) as IReloadFieldDetailInput;
    FieldTableReload.triggerReload(dataTrigger, utils);
  }
  static triggerReload = (args: IReloadFieldDetailInput, utils: IUtils) => {
    const company = args.companyId;
    const formId = args.formId;
    const statusId = args.statusId;
    const documentQuery = args.documentId !== 'new' ? `&documentId=${args.documentId}` : '';
    let fieldId = '';
    if (args.fieldId && args.fieldId.length > 0) {
      args.fieldId.forEach((id: string) => {
        fieldId += `&ids[]=${id}`;
      });
    }
    const url = `${company}/documents/fields?formId=${formId}${documentQuery}&statusId=${statusId}${fieldId}`;
    const data = { fields: args.fields, inputs: args.inputs };
    return new Promise(async (resolve, reject) => {
      return await Axios.post(url, data).then((response: IAxiosResponse) => {
        utils.dispatchTriggerFieldReload({
          formField: response.data.data,
          valueFieldReload: args.fields
        });
      });
    })
  }
}
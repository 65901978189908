import { SAVE_DRAFT_BP_RELATIONSHIP, SAVE_DRAFT_BP_RELATIONSHIP_RETURN, SAVE_DRAFT_BP_RELATIONSHIP_ERROR, PUBLISH_BP_RELATIONSHIP, PUBLISH_BP_RELATIONSHIP_RETURN, PUBLISH_BP_RELATIONSHIP_ERROR, CLEAR_SAVE_BP_RELATIONSHIP_ERROR, CLEAR_SAVE_BP_RELATIONSHIP_SUCCESS, GET_BPR_LIST, GET_BPR_LIST_RETURN, GET_BPR_LIST_ERROR, GET_BPR_FORM_FIELDS_RETURN, GET_BPR_FORM_FIELDS_ERROR, GET_BPR_FORM_FIELDS, GET_BPR_FORM_FIELDS_SANITIZE_RETURN, CLEAR_SAVE_BPR_SUCCESS, CLEAR_SAVE_BPR_ERROR } from './constant';
import { message } from './message';
import { IReference } from './interface/form';

export interface IConfigurationBPRelationshipReducer {
  saveBPRelationshipErrorMessage: string;
  saveBPRelationshipMessage: string;
  BPRelationshipList: any;
  saveBPRelationshipLoading: boolean;
  BPRelationshipSaveType: string;
  formListLoading: boolean;
  formListStatus: string;
  referenceList: IReference[],
  bprFormFields: any,
  sanitizingRelatedForm: boolean;
}

const initialState: IConfigurationBPRelationshipReducer = {
  saveBPRelationshipErrorMessage: '',
  saveBPRelationshipMessage: '',
  BPRelationshipList: [],
  saveBPRelationshipLoading: false,
  BPRelationshipSaveType: '',
  formListLoading: false,
  formListStatus: '',
  bprFormFields: [],
  sanitizingRelatedForm: false,
  referenceList: []
};

const configurationBPRelationshipReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  switch (action.type) {
    case GET_BPR_LIST:
      newState.formListLoading = true;
      newState.formListStatus = '';
      return newState;

    case GET_BPR_LIST_RETURN:
      newState.formListLoading = false;
      newState.referenceList = action.referenceList;
      newState.BPRelationshipList = action.BPRelationshipList;
      newState.formListStatus = 'success';
      return newState;

    case GET_BPR_LIST_ERROR:
      newState.formListLoading = false;
      newState.formListStatus = 'failed';
      return newState;

    case SAVE_DRAFT_BP_RELATIONSHIP:
      newState.saveBPRelationshipLoading = true;
      newState.saveBPRelationshipMessage = '';
      newState.saveBPRelationshipErrorMessage = '';
      newState.BPRelationshipSaveType = '';
      return newState;

    case SAVE_DRAFT_BP_RELATIONSHIP_RETURN:
      if (action.BPRelationshipSaveType === 'draft') {
        newState.saveBPRelationshipLoading = false;
        newState.saveBPRelationshipMessage = message.saveDraftBPRelationshipMessage;
      }
      newState.BPRelationshipSaveType = action.BPRelationshipSaveType;
      newState.referenceList = action.referenceList;
      newState.BPRelationshipList = action.BPRelationshipList;
      newState.sanitizingRelatedForm = false;
      return newState;

    case SAVE_DRAFT_BP_RELATIONSHIP_ERROR:
      newState.saveBPRelationshipLoading = false;
      newState.saveBPRelationshipErrorMessage = action.saveBPRelationshipErrorMessage;
      return newState;

    case PUBLISH_BP_RELATIONSHIP:
      newState.saveBPRelationshipMessage = '';
      newState.saveBPRelationshipErrorMessage = '';
      newState.BPRelationshipSaveType = '';
      return newState;

    case PUBLISH_BP_RELATIONSHIP_RETURN:
      newState.saveBPRelationshipLoading = false;
      newState.saveBPRelationshipMessage = message.publishBPRelationshipMessage;
      return newState;

    case PUBLISH_BP_RELATIONSHIP_ERROR:
      newState.saveBPRelationshipLoading = false;
      newState.saveBPRelationshipErrorMessage = action.saveBPRelationshipErrorMessage;
      return newState;

    case CLEAR_SAVE_BP_RELATIONSHIP_SUCCESS:
      newState.saveBPRelationshipMessage = '';
      return newState;

    case CLEAR_SAVE_BP_RELATIONSHIP_ERROR:
      newState.saveBPRelationshipErrorMessage = '';
      return newState;

    case GET_BPR_FORM_FIELDS:
      newState.sanitizingRelatedForm = true;
      return newState;

    case GET_BPR_FORM_FIELDS_RETURN:
      newState.bprFormFields = action.data;
      return newState;

    case GET_BPR_FORM_FIELDS_ERROR:
      return newState;

    case GET_BPR_FORM_FIELDS_SANITIZE_RETURN:
      const newBPRelationshipList = newState.BPRelationshipList.map((e: any) => {
        if (e.id === action.relatedForm.id) {
          return {
            ...e,
            showRelatedForm: true
          }
        }
        return e
      })
      newState.sanitizingRelatedForm = false;
      newState.BPRelationshipList = newBPRelationshipList;
      return newState;

    case CLEAR_SAVE_BPR_SUCCESS:
      newState.saveBPRelationshipMessage = '';
      return newState;

    case CLEAR_SAVE_BPR_ERROR:
      newState.saveBPRelationshipErrorMessage = '';
      return newState;
    default:
      return state;
  }
}

export default configurationBPRelationshipReducer;
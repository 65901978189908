import { IFilterStyle } from './interface/filter';
import { icons } from '../../../../common/icons';

export const GET_STATUS_SUMMARY = 'app/documentModule/workspace/GET_STATUS_SUMMARY';
export const GET_STATUS_SUMMARY_RETURN = 'app/documentModule/workspace/GET_STATUS_SUMMARY_RETURN';
export const GET_STATUS_SUMMARY_ERROR = 'app/documentModule/workspace/GET_STATUS_SUMMARY_ERROR';
export const GET_BUSINESS_PROCESS_LOGO = 'app/documentModule/workspace/GET_BUSINESS_PROCESS_LOGO';
export const GET_BUSINESS_PROCESS_LOGO_RETURN = 'app/documentModule/workspace/GET_BUSINESS_PROCESS_LOGO_RETURN';
export const GET_BUSINESS_PROCESS_LOGO_ERROR = 'app/documentModule/workspace/GET_BUSINESS_PROCESS_LOGO_ERROR';
export const GET_BP_STATUS_LIST = 'app/documentModule/workspace/GET_BP_STATUS_LIST';
export const GET_BP_STATUS_LIST_RETURN = 'app/documentModule/workspace/GET_BP_STATUS_LIST_RETURN';
export const GET_BP_STATUS_LIST_ERROR = 'app/documentModule/workspace/GET_BP_STATUS_LIST_ERROR';
export const SET_SELECTED_WORKFLOW_FILTER = 'app/documentModule/workspace/SET_SELECTED_WORKFLOW_FILTER';

export type workspaceFilterType = 'personal' | 'group' | 'favorite' | 'all';

export const filterToOwnerType: { [field: string]: any } = {
  'personal': 'owned',
  'group': 'co-owned',
  'favorite': 'follower'
}

export const gaugeBPFilterStyle: IFilterStyle = {
  userStyle: {
    width: '13px',
    height: '18px'
  },
  usersStyle: {
    width: '18px',
    height: '18px'
  },
  starStyle: {
    width: '18px',
    height: '18px'
  },
  allStyle: {
    fontSize: '12px'
  }
}

export const colorIndicator = {
  green: '#66B283',
  amber: '#DDA749',
  red: '#E34B22',
}

export const slaFilterStyle: IFilterStyle = {
  userStyle: {
    width: '13px',
    height: '18px',
    marginRight: '20px'
  },
  usersStyle: {
    width: '18px',
    height: '18px',
    marginRight: '20px'
  },
  starStyle: {
    width: '18px',
    height: '18px',
    marginRight: '20px'
  },
  allStyle: {
    fontSize: '12px',
    color: '#000',
    fontWeight: 'bolder',
    marginRight: '15px'
  }
}

export const workspaceFilterArray = [
  {
    id: 'personal',
    label: 'Owned',
    iconDefault: icons.black.user,
    iconSelected: icons.orange.user,
    selected: false,
    className: ''
  },
  {
    id: 'group',
    label: 'Co-Owned',
    iconDefault: icons.black.users,
    iconSelected: icons.orange.users,
    selected: false,
    className: ''
  },
  {
    id: 'favorite',
    label: 'Followed',
    iconDefault: icons.black.star,
    iconSelected: icons.orange.star,
    selected: false,
    className: ''
  },
  {
    id: 'all',
    label: 'ALL',
    selected: false,
    colorDefault: '#000',
    colorSelected: '#DDA749',
    className: ''
  }
]

export const allowedTypeForRealtimeRefreshDashboard = (type: string): boolean => {
  const acceptedType = ['doc-action', 'doc-follow', 'doc-sla-missed'];
  return acceptedType.indexOf(type) > -1;
}

export const filterType = ['personal', 'group', 'favorite'] as workspaceFilterType[];
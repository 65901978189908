import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';

enum AllowedChar {
  LowerCase = 'lowercase',
  UpperCase = 'uppercase',
  Digits = 'digits',
  Whitespace = 'whitespace',
  Special = 'special',
  Custom = 'custom',
}

export class StringDeltaFields implements DeltaFieldType {

  name = 'string';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta, config } = FieldConfigChange.targetDelta(targetDelta);
    const { newDelta, oldDelta } = this.setDelta(delta, targetInstance);

    deltaArray = FieldConfigChange.format(newDelta, oldDelta, targetInstance);

    if (config) {
      const { newDelta, oldDelta } = this.setConfigDelta(config, targetInstance.config);
      const { newConfig, newInstance } = this.formatConfigDelta(newDelta, oldDelta);
      deltaArray = deltaArray.concat(FieldConfigChange.format(newConfig, newInstance, targetInstance));
    }

    return deltaArray;
  }

  setConfigDelta = (delta: Delta, oldValue: Delta) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (Array.isArray(delta[key])) {
        if (key === 'itemConfig') {
          if (JSON.stringify(delta[key][0]) !== JSON.stringify(oldValue)) {
            Object.keys(delta[key][0]).forEach(childKey => {
              if (delta[key][0][childKey] !== oldValue[childKey]) {
                newDelta[childKey] = [oldValue[childKey], delta[key][0][childKey]];
                oldDelta[childKey] = oldValue[childKey];
              }
            });
          }
        }
        if (delta[key].length < 3 && key !== 'itemConfig' && key !== 'itemType') {
          newDelta[key] = delta[key];
          oldDelta[key] = oldValue[key];
        }
      }
    });

    return { newDelta, oldDelta };
  }

  setDelta = (delta: Delta, oldValue: Delta) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'defaultValue') {
        const data = this.setDefaultValue(delta[key]);
        if (data) {
          newDelta[key] = data;
          oldDelta[key] = oldValue[key];
        }
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = oldValue[key];
      }
    });

    return { newDelta, oldDelta };
  }

  setDefaultValue = (delta: Delta) => {
    let newDelta = {} as Delta;
    if (delta) {
      if (Array.isArray(delta)) {
        newDelta = delta.map(val => {
          if (Array.isArray(val)) {
            return val.toString() || null;
          }
          return val;
        });
      } else {
        newDelta = delta;
      }
    }

    if (Array.isArray(delta)) {
      const isAllNull = newDelta.every((e: string) => !e);
      return isAllNull ? null : newDelta;
    }

    return newDelta;
  }

  formatConfigDelta = (config: Delta, targetInstance: any) => {
    const newConfig = {} as Delta;
    const newInstance = {} as any;

    Object.keys(config).forEach(key => {

      newConfig[key] = config[key];
      newInstance[key] = targetInstance[key] || '';

      if (key === 'allowedChars') {
        newConfig[key] = config[key];
        if (config[key] instanceof Array) {
          newConfig[key] = this.removeAllowedCharCase(config[key]);
        }
        if (targetInstance[key] === undefined) {
          newInstance[key] = [''];
        } else {
          newInstance[key] = this.removeAllowedCharCase(targetInstance[key]);
        }
      }

      if (key === 'customAllowedChars') {
        if (targetInstance[key] === undefined) {
          newInstance[key] = '';
          newConfig[key] = ['', config[key][0]];
        } else {
          if (targetInstance[key] !== config[key][0]) {
            newInstance[key] = targetInstance[key];
            newConfig[key] = [targetInstance[key], config[key][0]];
          } else {
            newInstance[key] = targetInstance[key];
            newConfig[key] = [config[key][0], ''];
          }
        }
      }
    });

    return { newConfig, newInstance };
  }

  removeAllowedCharCase = (allowedChars: any) => {
    let newAllowedChar = [] as any[];

    allowedChars.forEach((char: any) => {
      if (char instanceof Array) {
        newAllowedChar = [this.removeAllowedCharCase(char)]
      } else {
        if (!(char === AllowedChar.UpperCase || char === AllowedChar.LowerCase)) {
          newAllowedChar.push(char);
        }
      }
    });

    return newAllowedChar
  }
}
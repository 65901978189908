import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import useDeviceDetect from '../../../../../general/device-detect';
import PopperContainer from '../../../../../general/popper-component';
import { icons } from '../../../../../icons';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { focusSelectedElement, getFocusedElement, setFocusedElement, setTableFocusedElement, removeFocusedElement } from '../../../../../utils/focus-element';
import { elementStatus } from '../../../utils/element-status';
import { AcceptedProps } from '../../interface/accepted-props';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../service/field-properties.service';
import { DateTimeProps } from '../../../service/type/date-time';
import { TValidationType } from '../../../service/field-properties.handler';
import { removePopperPosition } from './utils/remove-popper-position';
import { returnPopperPosition } from './utils/return-popper-position';
import { ComputeDate } from '../../enum-type/utils/compute-date';

const DateTimeType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();
  const name = props.getFieldId();
  const dateFormat = typeof props.tableColumnRowIndex === 'number' ? 'dd MMM yyyy h:mm a' : 'dd MMMM yyyy h:mm a';

  const [refresher, setRefresher]: any = useState(1);
  const [minDate, setMinDate] = useState<any>(moment().subtract(100, 'years'));
  const [maxDate, setMaxDate] = useState<any>(moment().add(100, 'years'));

  const [minTime, setMinTime] = useState<any>(undefined);
  const [maxTime, setMaxTime] = useState<any>(undefined);

  const elementProperties = new FieldProperties(name, props.forwardedRef, new DateTimeProps());

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'date-time' })
  }

  let calendarRef = useRef<any>();

  let onTypeTimeout = setTimeout(() => { }, 1000);
  const elementId = props.tableColumnRowIndex ?
    `date-time-picker-${name}-${props.tableColumnRowIndex}`
    : `date-time-picker-${name}`;

  if (props.isClearValue && elementProperties.current.value) {
    elementProperties.setCurrent({ value: '' })
  }

  const setMinMaxDate = () => {
    if (props.inputConfig && props.inputConfig.config && props.inputConfig.config.min) {
      const min = ComputeDate.compute({
        config: props.inputConfig.config.min,
        dynamicRef: props.fromRange ? props.parentRef : props.forwardedRef,
        dateFormat: "YYYY-MM-DD HH:mm:ssZ",
        fromRange: props.fromRange,
        type: 'date-time'
      });
      setMinDate(min);
      if (min) {
        setMinTime(new Date(min));
        if (!maxTime) {
          setMaxTime(new Date(new Date(minDate).setHours(23, 59, 59)));
        }
      }
    }
    if (props.inputConfig && props.inputConfig.config && props.inputConfig.config.max) {
      const max = ComputeDate.compute({
        config: props.inputConfig.config.max,
        dynamicRef: props.fromRange ? props.parentRef : props.forwardedRef,
        dateFormat: "YYYY-MM-DD HH:mm:ssZ",
        fromRange: props.fromRange,
        type: 'date-time'
      });
      setMaxDate(max);
      if (max) {
        setMaxTime(new Date(max));
        if (!minTime) {
          setMinTime(new Date(new Date(minDate).setHours(0, 0, 0)));
        }
      }
    }
  }

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === 'required' ? 1 : 0,
      isRequired: props.accessType === 'required'
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur)
    setRefresher(uuid());
  }

  const onCalendarClose = () => {
    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
  }

  const handleDateTime = (date: any) => {
    const selected = date ? moment(date).format("YYYY-MM-DD HH:mm:ssZ") : '';
    elementProperties.setCurrent({
      ...elementProperties.getCurrent(),
      value: selected ? moment(selected).utc().format() : ''
    });
    if (props.triggerFilter) {
      props.triggerFilter('')
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, elementProperties.current.value)
      }
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(elementId);
    }
  }

  const handleClickOutside = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(false);
    }
  }

  const getValue = () => {
    let selectedValue = null;
    selectedValue = elementProperties.current.value
      ? new Date(moment(elementProperties.current.value).format("YYYY-MM-DD HH:mm:ssZ"))
      : null;
    return selectedValue;
  }

  const focusInput = (id: string) => {
    if (mobile) {
      setFocusedElement(id);
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
    returnPopperPosition(id);
    if (props.fromRange) {
      setMinMaxDate();
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ value: defaultValueString })
    }
  }

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();;
    }
    if (props.accessType === 'required') {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const maxTimeValue = () => {
    const maxTimeSameWithValue = moment(getValue() || new Date()).format('MM-DD-YY') === moment(maxTime).format('MM-DD-YY');

    if (props.fromRange) {
      if (maxTimeSameWithValue) {
        return maxTime;
      } else {
        return new Date(new Date().setHours(23, 59, 59));
      }
    }

    return undefined;
  }

  const minTimeValue = () => {
    const minTimeSameWithValue = moment(getValue() || new Date()).format('MM-DD-YY') === moment(minTime).format('MM-DD-YY');

    if (props.fromRange) {
      if (minTimeSameWithValue) {
        return minTime;
      } else {
        return new Date(new Date().setHours(0, 0, 0));
      }
    }

    return undefined;
  }
  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <>
          <div style={{ flexDirection: 'column' }} className='flex-wrapper'>
            <ReactDatePicker
              id={elementId}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              maxTime={maxTimeValue()}
              minTime={minTimeValue()}
              popperContainer={PopperContainer}
              showYearDropdown={true}
              showMonthDropdown={true}
              showTimeSelect
              selected={getValue()}
              timeIntervals={1}
              disabled={props.accessType === IFieldAccessType.Readonly}
              placeholderText={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
              dropdownMode="select"
              dateFormat={dateFormat}
              onCalendarClose={() => {
                if (focusedElement && focusedElement === elementId) {
                  removeFocusedElement();
                }
                validateInput();
                onCalendarClose();
              }}
              className={`field-input single ${elementStatus({
                forwardedRef: props.forwardedRef,
                name: name,
                multiline: props.multiline || false,
                required: props.accessType === IFieldAccessType.Required,
                fieldValueObject: elementProperties
              })}`}
              onChange={(date) => handleDateTime(date)}
              onInputClick={() => {
                focusInput(elementId);
              }}
              ref={(curRef: any) => {
                if (curRef) {
                  if (focusedElement && focusedElement === elementId) {
                    focusSelectedElement(focusedElement);
                  }
                }
                calendarRef.current = curRef;
              }}
              onClickOutside={handleClickOutside}
              popperClassName={elementId}
              onKeyDown={handleKeydown}
              onSelect={(date) => setRefresher(refresher + 1)}
              autoFocus={props.isFocus}
            />
            {props.hasIconInside &&
              <div className='flex-container with-icon single clock-icon'>
                <Image width='20px' src={icons.black.dateTime} />
              </div>
            }
            {elementProperties.hasError() && props.showErrorMessage &&
              elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
              <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }} />
            }
          </div>
        </>
      </>
    }
  </>
}

export default DateTimeType;
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IDataTable } from '../../reducer';
import { IDatatableConfig, IDatatableConfigUniqueConstraint } from '../../interface/data-table-config';

interface IProps {
  companyId: string;
  dataTableId: string;
  forUpdateDataTableDetails: IDataTable;
}

export const useSetDatatableConfig = (props: IProps) => {
  const initialConfig: IDatatableConfigUniqueConstraint = { name: uuid(), fields: [] };

  const [datatableConfig, setDatatableConfig] = useState({ uniqueConstraints: [] } as IDatatableConfig);

  useEffect(() => {
    if (props.dataTableId === 'add-data-table') {
      let newDataConfig = { ...datatableConfig, uniqueConstraints: [...datatableConfig.uniqueConstraints] };
      newDataConfig.uniqueConstraints.push(initialConfig);
      setDatatableConfig(newDataConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataTableId]);

  useEffect(() => {
    if (props.dataTableId) {
      if (props.forUpdateDataTableDetails && props.forUpdateDataTableDetails.config) {
        let newDataConfig = { ...props.forUpdateDataTableDetails.config } as IDatatableConfig;
        if (!newDataConfig || !newDataConfig.uniqueConstraints || newDataConfig.uniqueConstraints.length === 0) {
          newDataConfig.uniqueConstraints.push(initialConfig);
        }
        setDatatableConfig(newDataConfig);
      } else {
        setDatatableConfig({ uniqueConstraints: [initialConfig] })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataTableId, JSON.stringify(props.forUpdateDataTableDetails)]);

  return { datatableConfig, setDatatableConfig, ...props };
}
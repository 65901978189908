import { useEffect } from 'react';

interface IProps {
  dispatch: any;
  clearExportBackupItem: any;
}

export const useClearBackupItem = (props: IProps) => {
  const { dispatch, clearExportBackupItem } = { ...props }
  useEffect(() => {
    return () => {
      dispatch(clearExportBackupItem())
    }
  }, [dispatch, clearExportBackupItem])
}
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { FieldConstruct } from '../../../../../../../../../common/field/field-construct';
import { IForm } from '../../../../form/interface/form';
import {
  IPermissionFieldCondition,
  IPermissionFieldFilterConfig,
  IStatusFieldPermission,
} from '../../../interface/field-permission';
import {
  IFormPermissionSection,
  ISection,
  IStatusAction,
  ITablePermission,
} from '../../../interface/section';

interface IUtils {
  selectedStatus: IFormPermissionSection;
  selectedFieldSection: ISection;
  selectedTableSection?: ITablePermission | undefined;
  isTablePermission: boolean;
  isSectionPermission: boolean;
  fieldData: ICustomFieldDetail;
  selectedStatusAction: IStatusAction;
  fieldList: ICustomFieldDetail[];
  currentBP: IForm;
  defaultvalueRef: React.MutableRefObject<{
    [id: string]: {
      value: string;
    };
  }>;
}

const initialConfig: IPermissionFieldCondition = {
  id: '',
  fieldId: '',
  fromBp: '',
  operator: '',
  compareType: 'value',
  compareValue: '',
  compareField: {
    fieldId: '',
  },
};

export class StructureSettingsCondition {
  setConditionConfig = ({
    selectedStatus,
    selectedFieldSection,
    selectedTableSection,
    isTablePermission,
    isSectionPermission,
    fieldData,
    currentBP,
    selectedStatusAction,
    defaultvalueRef,
    fieldList,
  }: IUtils) => {
    let hasAccessLevelPerStatus = false;
    let permissionCondition = [] as IPermissionFieldCondition[];

    if (Object.keys(selectedStatus).length > 0) {
      const { sections, table, actions } = { ...selectedStatus };

      if (isTablePermission) {
        permissionCondition = this.setTableFieldCondition(
          table || [],
          selectedTableSection || ({} as ITablePermission),
          fieldData,
          currentBP
        );
      } else {
        sections.forEach((sectionList: ISection) => {
          if (selectedFieldSection.id === sectionList.id) {
            hasAccessLevelPerStatus =
              typeof sectionList.accessLevelPerStatus === 'object';
            if (isSectionPermission && hasAccessLevelPerStatus) {
              const accessLevelPerStatus =
                sectionList.accessLevelPerStatus as IPermissionFieldFilterConfig;
              permissionCondition = accessLevelPerStatus.condition;
            } else {
              const { permissions } = { ...sectionList };
              permissionCondition = this.setFieldCondition(
                permissions,
                fieldData,
                currentBP,
                hasAccessLevelPerStatus
              );
            }
          }
        });
      }
      if (Object.keys(selectedStatusAction).length > 0) {
        permissionCondition = this.setActionCondition(
          selectedStatusAction,
          actions,
          currentBP
        );
      }
      permissionCondition.forEach((condition: IPermissionFieldCondition) => {
        const fieldId = condition.fieldId.split('.');
        const isFieldCompareETField = fieldId.length === 2;

        let selectedFieldCompare = fieldList.filter(
          (field) => field.id === condition.fieldId
        )[0];
        if (
          isTablePermission &&
          selectedTableSection &&
          isFieldCompareETField
        ) {
          const tableFields = selectedTableSection.fields.map((field) => {
            return {
              ...field,
              id: `${selectedTableSection.id}.${field.id}`,
            } as ICustomFieldDetail;
          });
          selectedFieldCompare = tableFields.filter(
            (field) => field.id === condition.fieldId
          )[0];
        }
        if (selectedFieldCompare) {
          if (selectedFieldCompare.multiline) {
            condition.compareValue = condition.compareValue.toString();
          }
          selectedFieldCompare = {
            ...selectedFieldCompare,
            id: condition.id,
            inputConfig: {
              type: selectedFieldCompare.type,
            },
          } as any;
          const formDocument = {
            fields: { [condition.id]: condition.compareValue },
          };
          FieldConstruct.setFieldToRefs(
            [selectedFieldCompare],
            formDocument,
            defaultvalueRef
          );
        }
      });
    }
    return permissionCondition;
  };

  setTableFieldCondition = (
    tablePermission: ITablePermission[],
    selectedTableSection: ITablePermission,
    fieldData: ICustomFieldDetail,
    currentBP: IForm,
    hasAccessLevelPerStatus?: boolean
  ) => {
    let permissionCondition = [] as IPermissionFieldCondition[];
    tablePermission.forEach((table) => {
      if (
        table &&
        selectedTableSection &&
        table.id === selectedTableSection.id
      ) {
        const { permissions } = { ...table };
        permissionCondition = this.setFieldCondition(
          permissions,
          fieldData,
          currentBP,
          hasAccessLevelPerStatus
        ) as IPermissionFieldCondition[];
      }
    });
    return permissionCondition;
  };

  setFieldCondition = (
    permissions: IStatusFieldPermission[],
    fieldData: ICustomFieldDetail,
    currentBP: IForm,
    hasAccessLevelPerStatus?: boolean
  ): IPermissionFieldCondition[] => {
    let permissionCondition = [] as IPermissionFieldCondition[];
    if (permissions.length > 0) {
      permissions.forEach((permission: IStatusFieldPermission) => {
        if (fieldData && permission.fieldId === fieldData.id) {
          const { config } = { ...permission };
          if (config && Object.keys(config).length) {
            permissionCondition = config.condition;
          } else if (
            (config === undefined ||
              (config && Object.keys(config).length === 0)) &&
            !hasAccessLevelPerStatus
          ) {
            permissionCondition = [
              {
                ...initialConfig,
                fromBp: currentBP.id || '',
                id: uuid(),
              },
            ];
          }
        } else if (
          Object.keys(fieldData).length === 0 &&
          !hasAccessLevelPerStatus
        ) {
          permissionCondition = [
            {
              ...initialConfig,
              fromBp: currentBP.id || '',
              id: uuid(),
            },
          ];
        }
      });
    }
    return permissionCondition;
  };

  setActionCondition = (
    selectedStatusAction: IStatusAction,
    actions: IStatusAction[],
    currentBP: IForm
  ) => {
    let permissionCondition = [] as IPermissionFieldCondition[];
    actions.forEach((actionList: IStatusAction) => {
      if (selectedStatusAction && selectedStatusAction.id === actionList.id) {
        const {
          permissions: { config },
        } = { ...actionList };
        if (config && Object.keys(config).length > 0) {
          permissionCondition = config.condition;
        } else {
          permissionCondition = [
            {
              ...initialConfig,
              fromBp: currentBP.id || '',
              id: uuid(),
            },
          ];
        }
      }
    });
    return permissionCondition;
  };
}

import { BoolValidator } from './type/bool'
import { DateValidator } from './type/date'
import { EnumValidator } from './type/enum'
import { NumberValidator } from './type/number'
import { StringValidator } from './type/string'
import { TimeValidator } from './type/time'
import { FieldElement } from '../component/field-element'
import { IValidationError, ValidationType } from './type/validation-type'
import { ArrayValidator } from './type/array'
import { FileValidator } from './type/file'
import { IField, IFieldAccessType } from '../../../component/admin-module/module/users/interface/field'
import { TableValidator } from './type/table'
import { SpacerValidator } from './type/spacer'
import { DateTimeValidator } from './type/date-time';
import { GeolocationValidator } from './type/geolocation'
import { FieldConstruct } from '../field-construct';
import { RangeValidator } from './type/range';

const validationTypeList = [
  new BoolValidator(),
  new DateValidator(),
  new EnumValidator(),
  new NumberValidator(),
  new StringValidator(),
  new TimeValidator(),
  new ArrayValidator(),
  new FileValidator(),
  new TableValidator(),
  new SpacerValidator(),
  new DateTimeValidator(),
  new GeolocationValidator(),
  new RangeValidator()
]

type TErrorFieldValue = {
  [fieldId: string]: any
}

class ValidateField {

  registry: { [name: string]: ValidationType } = {};

  constructor(types?: ValidationType[]) {

    if (!types) {
      types = validationTypeList;
    }
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  singleField = (field: FieldElement, value: any, actionType?: string): IValidationError[] => {
    let validationClass = this.registry[field.inputConfig.type];
    return typeof validationClass !== 'undefined' ? validationClass.validate(field, value, actionType) : [];
  }

  multipleField = (fieldieldList: FieldElement[], value: { [field: string]: string }, actionType?: string): TErrorFieldValue => {
    let returnValue: TErrorFieldValue = {};
    fieldieldList.forEach((field: FieldElement) => {
      const schema = { ...field.validationSchema, isRequired: field.accessType === IFieldAccessType.Required }
      returnValue[field.id] = this.singleField({ ...field, validationSchema: schema }, value[field.id], actionType);
    })
    return returnValue;
  }

  noErrorMessages = (errorMessage: TErrorFieldValue): boolean => {
    return Object.keys(errorMessage).every(key => {
      return errorMessage[key].length === 0
        && ((errorMessage[key].length > 0 && errorMessage[key].every(({ error }: any) => error && error.length === 0))
          || (errorMessage[key].length > 0 && errorMessage[key].every(({ message }: any) => message && !(message.includes('provide')))))
    });
  }

  fieldsToValidate = (formField: IField[]) => {
    return formField.filter(field => {
      const fieldType = field.inputConfig && field.inputConfig.type;
      const isRequired = field.accessType === IFieldAccessType.Required;
      const isEditable = field.accessType === IFieldAccessType.ReadWrite;

      if (fieldType === 'table') {
        const tableColumn = field.inputConfig && field.inputConfig.config && field.inputConfig.config.columns;
        if (tableColumn && (isRequired || isEditable)) {
          return tableColumn.filter(column => {
            const isColRequired = column.accessType === IFieldAccessType.Required;
            const isColEditable = column.accessType === IFieldAccessType.ReadWrite
            return isColRequired || isColEditable;
          }).length > 0
        }
      }
      return (isRequired || isEditable)
    });
  }

  requiredFields = (formField: IField[]) => {
    return formField.filter(field => {
      const isRequired = field.accessType === IFieldAccessType.Required;
      return isRequired;
    });
  }

  validateForm = (formField: IField[], fieldRefs: any, actionType?: string): [boolean, any, TErrorFieldValue] => {
    let isValid = true;
    let errorMessage: TErrorFieldValue = {}
    if (formField.length > 0) {
      let allField = FieldElement.getDataElement(formField);

      let validateField = new ValidateField();
      errorMessage = validateField.multipleField(allField, FieldConstruct.getFieldDataFromRefs(formField, { ...fieldRefs }), actionType);

      allField.forEach((field: FieldElement) => {
        if (fieldRefs.current[field.id]) {
          if (field.inputConfig.type === 'array') {
            let tempData = fieldRefs.current[field.id];
            if (tempData instanceof Array && tempData.length > 0) {
              for (let i: number = 0; i < tempData.length; i++) {
                const hasErrorMessage = errorMessage[field.id] && errorMessage[field.id][i] && errorMessage[field.id][i].error;
                isValid = hasErrorMessage && errorMessage[field.id][i].error.length > 0 ? false : isValid;
                fieldRefs.current[field.id][i].error = hasErrorMessage ? errorMessage[field.id][i].error : [];
              }
            }
          } else {
            isValid = errorMessage[field.id] && errorMessage[field.id].length > 0 ? false : isValid;
            fieldRefs.current[field.id].error = errorMessage[field.id] ? errorMessage[field.id] : [];
          }
        }
      })
    }
    return [isValid, fieldRefs, errorMessage,];
  }
}

export default ValidateField;
import {
  GET_FORM_FIELD_LIST,
  GET_FORM_FIELD_LIST_RETURN,
  GET_FORM_DETAIL,
  GET_FORM_DETAIL_RETURN,
  GET_COLUMN_VIEW_LIST_RETURN,
  GET_COLUMN_VIEW_LIST,
  SAVE_DRAFT_COLUMN_VIEW,
  SAVE_DRAFT_COLUMN_VIEW_ERROR,
  PUBLISH_COLUMN_VIEW_ERROR,
  PUBLISH_COLUMN_VIEW,
  SAVE_DRAFT_COLUMN_VIEW_RETURN,
  PUBLISH_COLUMN_VIEW_RETURN,
  CLEAR_SAVE_COLUMN_VIEW_ERROR,
  CLEAR_SAVE_COLUMN_VIEW_SUCCESS
} from './constant';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { message } from './message';
import { IApiColumnView } from './interface/custom-view';
import { IForm } from '../../../form/interface/form';

export interface IConfigurationDocumentListReducer {
  type?: string;
  getFieldListListLoading: boolean;
  getColumnViewListLoading: boolean;
  getFormDetailLoading: boolean;
  columnViewList: IApiColumnView[];
  saveColumnViewErrorMessage: string;
  saveColumnViewMessage: string;
  fieldList: ICustomFieldDetail[];
  saveColumnViewLoading: boolean;
  columnViewSaveType: string;
  formDetail: IForm;
}

const initialState: IConfigurationDocumentListReducer = {
  columnViewList: [],
  getFieldListListLoading: false,
  getColumnViewListLoading: false,
  getFormDetailLoading: false,
  saveColumnViewErrorMessage: '',
  saveColumnViewMessage: '',
  fieldList: [],
  saveColumnViewLoading: false,
  columnViewSaveType: '',
  formDetail: {} as IForm,
};

const configurationCustomFilterReducer = (state = initialState, action: IConfigurationDocumentListReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_FIELD_LIST:
      newState.getFieldListListLoading = true;
      return newState;

    case GET_FORM_FIELD_LIST_RETURN:
      let tempFields = [...state.fieldList];
      tempFields = action.fieldList.filter(field => field.type !== 'spacer');
      newState.getFieldListListLoading = false;
      newState.fieldList = tempFields;
      return newState;

    case GET_FORM_DETAIL:
      newState.getFormDetailLoading = true;
      return newState;

    case GET_FORM_DETAIL_RETURN:
      newState.getFormDetailLoading = false;
      newState.formDetail = action.formDetail;
      return newState;

    case GET_COLUMN_VIEW_LIST:
      newState.getColumnViewListLoading = true;
      return newState;

    case GET_COLUMN_VIEW_LIST_RETURN:
      newState.getColumnViewListLoading = false;
      newState.columnViewList = action.columnViewList;
      return newState;

    case SAVE_DRAFT_COLUMN_VIEW:
      newState.saveColumnViewErrorMessage = '';
      newState.saveColumnViewMessage = '';
      newState.saveColumnViewLoading = true;
      return newState;

    case SAVE_DRAFT_COLUMN_VIEW_RETURN:
      if (action.columnViewSaveType === 'draft') {
        newState.saveColumnViewMessage = message.saveDraftColumnView;
        newState.saveColumnViewLoading = false;
      }
      newState.columnViewList = action.columnViewList;
      newState.columnViewSaveType = action.columnViewSaveType;
      return newState;

    case SAVE_DRAFT_COLUMN_VIEW_ERROR:
      newState.saveColumnViewLoading = false;
      newState.saveColumnViewErrorMessage = action.saveColumnViewErrorMessage;
      return newState;

    case PUBLISH_COLUMN_VIEW:
      newState.columnViewSaveType = '';
      return newState;

    case PUBLISH_COLUMN_VIEW_RETURN:
      newState.saveColumnViewLoading = false;
      newState.saveColumnViewMessage = message.publishDraftColumnView;
      return newState;

    case PUBLISH_COLUMN_VIEW_ERROR:
      newState.saveColumnViewLoading = false;
      newState.saveColumnViewErrorMessage = action.saveColumnViewErrorMessage;
      return newState;

    case CLEAR_SAVE_COLUMN_VIEW_SUCCESS:
      newState.saveColumnViewMessage = '';
      return newState;

    case CLEAR_SAVE_COLUMN_VIEW_ERROR:
      newState.saveColumnViewErrorMessage = '';
      return newState;

    default:
      return state;
  }
}

export default configurationCustomFilterReducer;
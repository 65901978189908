import React from 'react';
import { useSelector } from 'react-redux';
import { ICustomFieldDetail } from '../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import ToolTip from '../../../../../../../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { IRootState } from '../../../../../../../../../../../reducers';
import { IFormStatusWithAction } from '../../../../../../workflow/interface/form-status';
import { triggerIconStyle } from '../../../constant';
import { IRender } from './component-registry';

const containerStyle = {
  borderRadius: '50%',
  height: '25px',
  width: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  zIndex: 1
}

class AutomationOutlineTrigger {

  name = 'trigger';

  render: React.FC<IRender> = (props) => {
    const hasObject = Object.keys(props.component.config).length > 0;
    const title = props.component.config && props.component.config.trigger && props.component.config.trigger.title;
    const name = props.component.config && props.component.config.trigger && props.component.config.trigger.name;
    const iconName = props.component.config && props.component.config.trigger && props.component.config.trigger.iconName;
    const fromStatusId = (props.component.config && props.component.config.fromStatusId) || [];
    const toStatusId = (props.component.config && props.component.config.toStatusId) || [];
    const sourceFields = (props.component.config && props.component.config.sourceFields) || [];

    const iconHeight = triggerIconStyle[iconName || 'default'].height;
    const iconWidth = triggerIconStyle[iconName || 'default'].width;

    const {
      formStatusList
    } = useSelector((state: IRootState) => state.workflow);
    const {
      formFieldList
    } = useSelector((state: IRootState) => state.automation);

    const getStatusLabel = (statusIds: string[]): string => {
      const statuslabel = formStatusList
        .filter((e: IFormStatusWithAction) => statusIds.indexOf(e.id) > -1)
        .map((e: IFormStatusWithAction) => { return e.name })
      return statuslabel.join(',');
    }

    const getFieldLabel = (fieldIds: string[]): string => {
      const fieldlabel = formFieldList
        .filter((e: ICustomFieldDetail) => e.id && fieldIds.indexOf(e.id) > -1)
        .map((e: ICustomFieldDetail) => { return e.label })
      return fieldlabel.join(',');
    }

    const renderStatusUpdate = () => {
      const currentFromStatus = getStatusLabel(fromStatusId);
      const currentToStatus = getStatusLabel(toStatusId);

      const defaultToStatus = currentFromStatus
        ? <>
          <div className={`title`}>To</div>
          <div className={`content`}>All</div>
        </>
        : <></>;

      return <div className='description-container'>
        {
          currentFromStatus
            ? <>
              <div className={`title`}>From</div>
              <div className={`content`}>{`${currentFromStatus}`}</div>
            </>
            : <></>
        }
        {
          currentToStatus
            ? <>
              <div className={`title`}>To</div>
              <div className={`content`}>{`${currentToStatus}`}</div>
            </>
            : defaultToStatus
        }
      </div>
    }

    const renderFieldUpdate = () => {
      const currrentFields = getFieldLabel(sourceFields);

      return <div className='description-container'>
        {
          currrentFields
            ? <>
              <div className={`content`}>{`${currrentFields}`}</div>
            </>
            : <></>
        }
      </div>
    }

    return (
      <li className={`outline-widget`} onClick={() => props.setSelectedComponent(props.component)}>
        <div style={iconName ? { ...containerStyle, border: '1px solid #3a85a8' } : containerStyle}>
          <SVGWrapper width={iconWidth} height={iconHeight} iconName={iconName ? `${iconName}` : 'trigger'} color={`#3A85A8`} />
        </div>
        <div className={`content`}>
          {hasObject &&
            <ToolTip position='right center'
              popupMessage='Delete'
              popupTriggerComponent={<i className="trash alternate outline icon" onClick={(e) => {
                props.deleteCreatedComponent('trigger', props.currentIndex)
                e.stopPropagation();
              }}></i>} />
          }
          <span className={`title`}>{title ? `Trigger: ${title}` : `New Trigger`}</span>
          {title
            ? <>
              {name === 'status-update' && renderStatusUpdate()}
              {name === 'field-update' && renderFieldUpdate()}
            </>
            : <span className={`description`}>{`Select event or schedule`}</span>
          }
        </div>
      </li>
    );
  }
}

export default AutomationOutlineTrigger;
import { IField } from '../../component/admin-module/module/users/interface/field';
import { IConfigName } from '../field/type/interface/field-type-object';

export const getFieldId = (fieldList: IField[], field: string): string => {
  let key = '';
  let filteredField = fieldList.filter((e: IField) => {
    return e.label === field
  });
  if (filteredField.length > 0) {
    key = filteredField[0].id || '';
  }
  return key;
}

export const getFieldLabel = (fieldList: IField[], id: string): string => {
  let label = '';
  let filteredField = fieldList.filter((e: IField) => e.id === id);
  if (filteredField.length > 0) {
    label = filteredField[0].label || '';
  }
  return label;
}

export const getFieldDetail = (fieldList: IField[], id: string): object => {
  let config = {};
  let filteredField = fieldList.filter((e: IField) => e.id === id);
  if (filteredField.length > 0) {
    config = filteredField[0];
  }
  return config;
}

export const getFieldIdByConfigName = (fieldList: IField[], configName: IConfigName): string => {
  let id = '';
  let filteredField = fieldList.filter((e: IField) => e.configName === configName);
  if (filteredField.length > 0) {
    id = filteredField[0].id || '';
  }
  return id;
}
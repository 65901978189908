export const fieldPermissionList = [
  {
    id: 'hidden',
    iconName: 'eye',
    label: 'HIDDEN'
  },
  {
    id: 'readonly',
    iconName: 'eye',
    label: 'READ-ONLY'
  },
  {
    id: 'editable',
    iconName: 'edit',
    label: 'EDITABLE'
  },
  {
    id: 'required',
    iconName: 'requiredAccess',
    label: 'REQUIRED'
  },
]
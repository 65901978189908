import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IRootState } from '../../../../../../../reducers';
import { IPortalPageLayout } from '../interface/page-layout';
import FieldType from './field-item';

interface ISideViewComponentProps {
  bpImageList: { [bpId: string]: { src: any, seq: number } };
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): any;
}

const SideViewComponent: React.FC<ISideViewComponentProps> = (props) => {

  const { formFieldList, pageLayout } = useSelector((state: IRootState) => state.portalFormView);
  const [selectedTab, setSelectedTab] = useState<'field' | 'reference'>('field');

  const getFieldList = (tab: 'field' | 'reference'): ICustomFieldDetail[] => {
    const field: ICustomFieldDetail[] = [];
    const referenceField: ICustomFieldDetail[] = [];
    formFieldList && formFieldList.forEach((e: ICustomFieldDetail) => {
      if (e.configName !== 'Referenced') {
        field.push(e);
      } else {
        referenceField.push(e);
      }
    });
    return tab === 'field' ? field : referenceField;
  }

  const isInPageLayout = (field: ICustomFieldDetail): boolean => {
    let isInPageLayout = false;
    pageLayout?.forEach((e: IPortalPageLayout) => {
      e.layout.forEach((f: string[]) => {
        if (field.id && f.indexOf(field.id) > -1) {
          isInPageLayout = true;
        }
      })
    })
    return isInPageLayout;
  }

  return (
    <div className={`side-view-container`}>
      <div className='add-section' onClick={() => { }} ><span>abc</span>&nbsp;Add Section</div>
      <div className='field-type-tab'>
        <label className={`field ${selectedTab === 'field' ? 'selected' : ''}`} onClick={() => setSelectedTab('field')}>Fields</label>
        <label className={`references ${selectedTab === 'reference' ? 'selected' : ''}`} onClick={() => setSelectedTab('reference')}>References</label>
      </div>
      {
        getFieldList(selectedTab).map((e: ICustomFieldDetail) => {
          if (isInPageLayout(e)) {
            return null;
          }
          const documentId = e.config?.data?.formId;
          return <FieldType
            key={uuid()}
            field={e}
            bpImage={documentId ? props.bpImageList[documentId] : undefined}
            viewIcon={props.viewIcon}
          />
        })
      }
    </div>
  );
}

export default SideViewComponent;
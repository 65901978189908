import React, { useEffect } from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Container } from 'semantic-ui-react-bpm';
import { useDispatch, useSelector } from 'react-redux';
import AllowedUserListActionControl from '../../common/utils/user-list-action-control';
import { IRootState } from '../../reducers';
import { HeaderMenuButton } from './utils/header-menu-button';
import { adminSubModules } from './constant'
import { AdminModuleRegistry } from './module/module-registry';
import { IHasPageChangeRef } from '../main/interface/has-page-change';
import { ConfigService } from '../../common/config';
import AdminChatBoxContainer from './history';
import { setOpenAdminChatboxAction } from '../main/action';
import { UserProfileStaticKey } from '../auth/interface/user-profile';
import { ErrorModule } from '../main';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
}

interface IAdminModule {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const AdminModule: React.FC<IAdminModule> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match: IRouteMatch = useRouteMatch();

  const data = useSelector((state: IRootState) => state.main);
  const userLoggedIn = useSelector((state: IRootState) => state.auth.userLoggedIn);
  const userCompanyId = useSelector((state: IRootState) => state.auth.company);
  const userLoggedInProfiles = userLoggedIn.profiles ? userLoggedIn.profiles : {};

  const validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);

  const setIsOpenChatbox = (value: boolean, formId?: string) => {
    dispatch(setOpenAdminChatboxAction(value));
  }

  useEffect(() => {
    if (userLoggedInProfiles && Object.keys(userLoggedInProfiles).length > 0) {
      if (match.url === '/admin' || !match.params.submodule) {
        if (validateUserProfile.allowedAccessUsersModule() && validateUserProfile.allowedViewGroupList()) {
          history.push('/admin/users');
        } else if (validateUserProfile.allowedBusinessProcessModule()) {
          history.push('/admin/business-process/form');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!validateUserProfile.allowedAccessUsersModule()
    || !validateUserProfile.allowedViewGroupList() ||
    !validateUserProfile.allowedBusinessProcessModule()
  ) {
    return <ErrorModule detailText='Only Admin users can access this page.' redirectToEndUser={true} redirectToLoginPage={true} />
  }

  return (
    <React.Fragment >
      <Container className={`admin-main-container ${ConfigService.loadConfig().general.sandbox ? `admin-main-container-test` : `admin-main-container-prod`}`} fluid>
        <HeaderMenuButton.Render
          type={data.subRoute}
          modules={adminSubModules}
          history={history}
          main={data}
          isCurrentUserNotBA={!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.BusinessAnalytic)}
          allowedAccesUser={validateUserProfile.allowedAccessUsersModule()}
          allowedAccessGroups={validateUserProfile.allowedViewGroupList()}
          hasPageChangeRef={props.hasPageChangeRef}
          setIsOpenChatbox={setIsOpenChatbox}
          isOpenChatbox={data.isOpenAdminChatbox}
          companyId={userCompanyId}
        />
        <Route path={`${match.path}`}>
          <AdminModuleRegistry.Render
            type={match.params.submodule || ''}
            allowedAccesUser={validateUserProfile.allowedAccessUsersModule()}
            allowedAccesGroups={validateUserProfile.allowedViewGroupList()}
            hasPageChangeRef={props.hasPageChangeRef}
            allowedAccessAdminSettings={validateUserProfile.hasSomeProfiles(UserProfileStaticKey.Admin)}
            allowedAccessAuditLogs={validateUserProfile.allowedAccessAuditLogs()}
            allowedBusinessProcess={validateUserProfile.allowedBusinessProcessModule()}
          />
        </Route>
      </Container>
      <AdminChatBoxContainer
        setIsOpenChatbox={setIsOpenChatbox}
        isOpenChatbox={data.isOpenAdminChatbox}
        module={data.currentRoute}
      />
    </React.Fragment>
  );
}

export default AdminModule;

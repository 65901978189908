import { Session } from './interface/session';
import { UserProfile } from './interface/user-profile';
import { IUser } from '../admin-module/module/users/interface/user';
import { PermissionExtra } from '../../common/utils/access-permissions';

export class AuthorisedUser {
  session!: Session;
  belongCompanyId!: string;
  user!: IUser;
  profiles: { [companyId: string]: UserProfile[] } = {};
  tz!: string;

  profileIds(companyId: string): string[] {
    return this.profiles[companyId]?.map((p) => p.id) || [];
  }

  canAccessPermission(companyId: string, permission: string): boolean {
    const profilesForCompany = this.profiles[companyId] || [];
    return profilesForCompany.some((p) => p.permissions.includes(permission));
  }

  canAccessExtraPermissionInSomeCompany(permission: string): boolean {
    for (const profilesForCompany of Object.values(this.profiles)) {
      if (
        profilesForCompany.some((p) => p.permissionsExtra?.includes(permission))
      )
        return true;
    }
    return false;
  }

  get isEveryCompanyReader(): boolean {
    return this.canAccessExtraPermissionInSomeCompany(
      PermissionExtra.Company.Get
    );
  }

  get isEveryCompanyCreator(): boolean {
    return this.canAccessExtraPermissionInSomeCompany(
      PermissionExtra.Company.Create
    );
  }

  get isEveryCompanyEditor(): boolean {
    return this.canAccessExtraPermissionInSomeCompany(
      PermissionExtra.Company.Update
    );
  }

  get isEveryCompanyDeleter(): boolean {
    return this.canAccessExtraPermissionInSomeCompany(
      PermissionExtra.Company.Delete
    );
  }

  get isEveryCompanyUserManager(): boolean {
    return this.canAccessExtraPermissionInSomeCompany(
      PermissionExtra.Company.ManageUsers
    );
  }
}

import React from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { ITaskAssigned, ITimeTriggerField } from '../interface/status-task-list';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import { useRect } from '../../../../../../../../../common/utils/useRect';
import FieldValueColumn from './field-value-column';
import { IReference } from '../../bp-relationship/interface/form';
import { IForm } from '../../../../form/interface/form';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ITaskAssignedList {
  onCountChange(action: string, type: string, statusId: string, value: number, isExcelValue: boolean): void;
  onCountInputChange(event: any, statusId: string, type: string, isExcelValue: boolean): void;
  onTimeUnitChange(target: any, statusId: string, type: string): void;
  onTimeTriggerChange(value: string, statusId: string, type: string): void;
  onTimeTriggerFieldChange(triggerField: any, statusId: string, slaType: "task" | "process"): void;
  taskAssigned: ITaskAssigned;
  show: boolean;
  includeDraft: boolean;
  buttonColor: string;
  referenceList: IReference[];
  currentFormId: string;
  formList: IForm[];
  fieldList: ICustomFieldDetail[];
  referencedBPFields: ICustomFieldDetail[];
}

const dynamicRefs: any = {};

const TaskAssigned: React.FC<ITaskAssignedList> = (props) => {
  const { taskAssigned } = { ...props };
  const statusElREct = useRect(dynamicRefs[taskAssigned.statusId]
    && dynamicRefs[taskAssigned.statusId].current ?
    dynamicRefs[taskAssigned.statusId]
    : '')

  const excelEl: HTMLElement | null = document.getElementById(`excel-${taskAssigned.statusId}`);
  const slaEl: HTMLElement | null = document.getElementById(`sla-${taskAssigned.statusId}`);
  const unitEl: HTMLElement | null = document.getElementById(`unit-${taskAssigned.statusId}`);

  if (excelEl && slaEl && unitEl) {
    excelEl.style.height = `${statusElREct?.height}px`
    slaEl.style.height = `${statusElREct?.height}px`
    unitEl.style.height = `${statusElREct?.height}px`
  }

  const getOrCreateRef = (id: string) => {
    if (!dynamicRefs.hasOwnProperty(id)) {
      dynamicRefs[id] = React.createRef();
    }
    return dynamicRefs[id];
  }

  return <>
    <td className={`sla-column `}>
      <div ref={getOrCreateRef(taskAssigned.statusId)} id={`status-${taskAssigned.statusId}`}
        className={`ui label status-label ${(taskAssigned.initial && !props.includeDraft) || taskAssigned.final ? 'disabled' : ''} ${props.buttonColor}`}>
        {taskAssigned.statusName.toUpperCase()}
      </div>
    </td>
    <td className={`sla-column enum`}>
      <Dropdown
        className={`time-trigger ${(taskAssigned.initial && !props.includeDraft) || taskAssigned.final ? 'disabled' : ''}`}
        id='process-unit'
        popperContainer={DropdownPopperOption}
        selectOnBlur={false}
        clearable
        search
        placeholder='Select'
        value={taskAssigned.timeTrigger}
        options={[
          { key: 1, text: 'Task Assigned', value: 'task-assigned' },
          { key: 2, text: 'Deadline', value: 'deadline' },
        ]}
        selection
        onChange={(e, target: any) => props.onTimeTriggerChange(target.value, taskAssigned.statusId, 'task')}
      />
    </td>
    <td className={`sla-column fix `}>
      <div id={`excel-${taskAssigned.statusId}`} className={`ui icon input
          ${(taskAssigned.initial && !props.includeDraft) || taskAssigned.final ? 'disabled' : ''}`}>
        <input type='number' value={taskAssigned.warningInterval} onChange={(e) =>
          props.onCountInputChange(e, taskAssigned.statusId, 'task', true)
        } />
        <i aria-hidden="true" className="caret down icon" onClick={() => {
          if (taskAssigned.warningInterval) {
            props.onCountChange('decrement', 'task', taskAssigned.statusId, taskAssigned.warningInterval, true)
          }
        }} />
        <i aria-hidden="true" className="caret up icon" onClick={() => {
          props.onCountChange('increment', 'task', taskAssigned.statusId, taskAssigned.warningInterval, true)
        }} />
      </div>
    </td>
    <td className={`sla-column fix  `}>
      <div id={`sla-${taskAssigned.statusId}`} className={`ui icon input
          ${(taskAssigned.initial && !props.includeDraft) || taskAssigned.final || props.taskAssigned.timeTrigger === 'deadline' ? 'disabled' : ''}`}>
        <input type='number' value={taskAssigned.count} onChange={(e) =>
          props.onCountInputChange(e, taskAssigned.statusId, 'task', false)
        } />
        <i aria-hidden="true" className="caret down icon" onClick={() => {
          if (taskAssigned.count) {
            props.onCountChange('decrement', 'task', taskAssigned.statusId, taskAssigned.count, false)
          }
        }} />
        <i aria-hidden="true" className="caret up icon" onClick={() => {
          props.onCountChange('increment', 'task', taskAssigned.statusId, taskAssigned.count, false)
        }} />
      </div>
    </td>
    <td className={`sla-column fix  `}>
      <Dropdown
        id={`unit-${taskAssigned.statusId}`}
        popperContainer={DropdownPopperOption}
        className={`${(taskAssigned.initial && !props.includeDraft) || taskAssigned.final ? 'disabled' : ''}`}
        selectOnBlur={false}
        clearable
        search
        options={[
          { key: 1, text: 'Days', value: 'd' },
          { key: 2, text: 'Hours', value: 'h' },
        ]}
        selection
        value={taskAssigned.unit}
        onChange={(e, target: any) => { props.onTimeUnitChange(target, taskAssigned.statusId, 'task') }}
      />
    </td>
    <FieldValueColumn
      showColumn={props.taskAssigned.timeTrigger === 'deadline'}
      disabled={(taskAssigned.initial && !props.includeDraft) || taskAssigned.final}
      triggerFieldData={taskAssigned.timeTriggerField || {} as ITimeTriggerField}
      onTimeTriggerFieldChange={props.onTimeTriggerFieldChange}
      statusId={taskAssigned.statusId}
      slaType='task'
      referenceList={props.referenceList}
      currentFormId={props.currentFormId}
      fieldList={props.fieldList}
      formList={props.formList}
      referencedBPFields={props.referencedBPFields}
    />
  </>
}

export default TaskAssigned;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeLogoutMessageModal } from '../action/logout';
import './automatic-logout.scss';
import { IRootState } from '../../../reducers';
import { AlertModal } from '../../../common/general/alert-modal';

const LogoutPopupMessage: React.FC<any> = (props) => {
  const data = useSelector((state: IRootState) => state.auth);
  let [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(closeLogoutMessageModal())
    setOpenModal(false);
  }

  useEffect(() => {
    if (data.logoutMessageModal) {
      setOpenModal(true);
    }
  }, [data.logoutMessageModal])

  return (
    <AlertModal
      close={() => closeModal()}
      message={'You have been logged out of the application for security reasons after a period of inactivity.'}
      open={openModal}
      type='info'
    />
  )
}

export default React.memo(LogoutPopupMessage);
import React from 'react';
import { AcceptedProps } from './interface/accepted-props';
import TimeType from './type/time/time';
import TimeArrayType from './type/time/time-array';

const Time: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <TimeArrayType {...props} />;
  } else {
    return <TimeType {...props} />
  }
}

export default Time;
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { getRequest, IAxiosResponse } from '../../common/api-request';
import { SAMPLE_ACTION } from './constant';
import { updateTriggerSample } from './action';

export function* getTestData() {
  // console.log('passhere');
  const req: IAxiosResponse = yield call(getRequest, 'https://jsonplaceholder.typicode.com/todos/1', { method: 'GET' });
  // console.log('req: ', req.data);
  yield put(updateTriggerSample(req.data));
}

// Individual exports for testing
export function* testComponentSagas() {
  yield takeLatest(SAMPLE_ACTION, getTestData)
}

// All sagas to be loaded
export default testComponentSagas

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { IRootState } from '../../../../../../reducers';
import ConfigurationModule from './module';
import { configurationSubModules } from './constant';
import { ConfigurationSubMenuButton } from './sub-menu';
import changeRoute from '../../../../../../common/utils/change-menu';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../common/icons';
import ToolTip from '../../../../../../common/general/tooltip';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
  thirdLevelRoute: string;
  formId: string;
}

interface IConfigurationRouteProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const ConfigurationRoute: React.FC<IConfigurationRouteProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match: IRouteMatch = useRouteMatch();
  const data = useSelector((state: IRootState) => state.main);

  const [collapse, setCollapse] = useState(false);

  return (<>
    <ToolTip
      popupMessage='Close'
      popupTriggerComponent={
        <Image id='business-process-close-icon' src={icons.blue.closeCircle} onClick={() => {
          changeRoute('/admin/business-process/form', data.hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
        }} />
      }
      position='right center'
    />
    <div id='configuration-module' className={collapse ? 'collapse' : 'expand'}>
      <Route path={`${match.path}/:formId/:fourthLevelRoute`} >
        <ConfigurationSubMenuButton.Render
          type={data.thirdLevelRoute}
          modules={configurationSubModules}
          history={history}
          main={data}
          hasPageChangeRef={props.hasPageChangeRef}
          collapse={collapse}
          setCollapse={setCollapse}
        />
        <ConfigurationModule hasPageChangeRef={props.hasPageChangeRef} />
      </Route>
    </div>
  </>
  );
}

export default ConfigurationRoute;

import { IFieldStyle } from '../../../component/admin-module/module/users/interface/field';
import { IConfigName } from '../../field/type/interface/field-type-object';
import { ICustomFieldMobileConfig } from './cutom-field-detail-mobile';
import { IFormula } from './formula';

export interface IOnCustomFieldSubmitObject {
  data: ICustomFieldDetail;
  company: string;
}

export interface ICustomFieldDetail {
  updateField: { itemType: string; itemConfig: IConfig };

  id?: string;

  type?: string;

  label?: string;

  hint?: string;

  /**
   * it can be object or string
   */
  defaultValue?: any;

  config?: IConfig;

  required?: boolean;

  permission?: any;

  custom?: boolean;

  configName?: IConfigName;

  deleted?: boolean;

  new?: boolean;

  multiline?: boolean;

  searchable?: boolean;

  flags?: string[];

  maxItems?: number | null;

  agg?: string;

  isTableField?: boolean;

  /* for datatable */
  dataType?: 'static' | 'database' | 'document' | 'form';

  mobileConfig?: ICustomFieldMobileConfig | null;

  sectionId?: string;

  confidential?: boolean;

  style?: TableColumnStyle;
}
export class IConfigAutopopulated {
  sourceField?: string;
}
export class IConfigSequence extends IConfigAutopopulated {
  companyId!: string;
  seqId!: string;
}

export class IConfigEnum extends IConfigSequence {
  dataType!: string;
  data!: any;
  dataFilter!: Object;
  displayType!: string;
  labelFormat!: string;
  labelType!: string;
  multiselect!: boolean;
  dataFilters!: IDataFitersConfig[];
  dataFiltersJoint!: string;
  separator!: string;
  imageKeyPath?: string;
  groupingKeyPath?: string;
  autopopulate?: boolean;
  uniquePerGroup?: boolean;
}

export class IConfigNumber extends IConfigEnum {
  min!: number;
  max!: number;
  alignment!: 'left' | 'center' | 'right';
  thousandSeparator!: boolean;
  decimals!: number;
  qrInput?: boolean;
  percentage!: boolean;
}

export class IConfigString extends IConfigNumber {
  format!: string;
  minLength?: number;
  maxLength?: number;
  minLines?: number;
  maxLines?: number;
  alignment!: 'left' | 'center' | 'right';
  displayType!: string;
  style?: IFieldStyle;
}

export class IConfigArray extends IConfigString {
  itemType!: string;
  itemConfig!: IConfig;
  maxItems?: number;
  qrInput?: boolean;
}

export class IConfigTable extends IConfigArray {
  columns!: ICustomFieldDetail[];
  minRows!: number | null;
  maxRows!: number | null;
  tableType!: 'vertical' | 'horizontal';
  prefillColumnIds!: string[];
  uniqueColumnIds!: string[];
  automatic!: boolean;
}

export class IConfigFx extends IConfigTable {
  alignment!: 'left' | 'center' | 'right';
  thousandSeparator!: boolean;
  decimals!: number;
  formula!: IFormula[];
}

export class IConfigFile extends IConfigFx {
  enableCameraInput?: number;
  systemGenerated?: boolean;
  generationContext?: IFileGenerationContext;
  fileType?: FileFieldFileType;
  displayEnabled?: boolean;
  display?: FileFieldDisplayConfig;
}

export class IConfigRange extends IConfigFile {
  itemConfigFrom!: IConfig;
  itemConfigTo!: IConfig;
}

export class IConfigDate extends IConfigRange {
  noWeekends!: boolean;
}

export class IConfigPhone extends IConfigDate {
  phoneCountryCode!: string;
}

export class IConfig extends IConfigPhone {}

export interface IDataFitersConfig {
  keyPath: string;
  operator: string;
  value: any;
  style: any;
}

export interface IReferencedFieldCompareField {
  fieldId: string;
  fromBp: string;
  fieldRef?: string;
}

export interface IReferencedFieldCondition {
  id: string;
  fieldId: string;
  fromBp: string;
  operator: string;
  compareType: string;
  compareValue: string;
  compareField: IReferencedFieldCompareField;
  tableId?: string;
}

export interface IReferencedFieldFilterConfig {
  joinCondition: 'and' | 'or';
  condition: IReferencedFieldCondition[];
}

export interface IFileGenerationContext {
  apiId?: string;
  templateId: string;
  isStored: boolean;
}

export enum FileFieldFileType {
  Image = 'image',
  Document = 'document',
  Signature = 'signature',
  Any = 'any',
}

export enum FileFieldFitMode {
  Fill = 'fill',
  Container = 'contain',
  Cover = 'cover',
  FitWidth = 'fitWidth',
  FitHeight = 'fitHeight',
  None = 'none',
  ScaleDown = 'scaleDown',
}

export interface FileFieldDisplayConfig {
  width?: FileFieldDisplayDimension;
  height?: FileFieldDisplayDimension;
  aspectRatio?: string;
  fitMode?: FileFieldFitMode;
}

export interface FileFieldDisplayDimension {
  value: number;
  unit: string;
}

export enum TableColumnAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}
export interface TableColumnStyle {
  width: number;
  alignment?: TableColumnAlignment;
}

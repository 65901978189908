import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import './automatic-logout.scss';
import { triggerAutomaticLogoutAction } from '../action/logout';
import { IRootState } from '../../../reducers';

let modalTimerTimeout = setTimeout(() => { }, 1000);

const IdleTimerModal: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state: IRootState) => state.auth);

  let [timeout] = useState(900000); // 15 mins

  const onAction = () => {
    clearTimeout(modalTimerTimeout);
  }

  const openModal = () => {
    dispatch(triggerAutomaticLogoutAction({ automaticLogoutModal: true }));
    modalTimerTimeout = setTimeout(() => {
      props.handleLogout();
    }, 300000); // 5 mins
  }

  const onIdle = () => {
    if (!data.automaticLogoutModal) {
      modalTimerTimeout = setTimeout(() => {
        openModal()
      }, 600000); // 10 mins
    }
  };

  return (
    <>
      {data.token && (
        <>
          <IdleTimer
            onAction={onAction}
            element={document}
            onIdle={onIdle}
            debounce={250}
            timeout={timeout}
          />
        </>
      )}
    </>
  );
};

export default React.memo(IdleTimerModal);

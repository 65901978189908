import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Dropdown, Popup, Icon, SemanticICONS } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { FieldElement } from '../../../../../../../../../../../../../common/field/component/field-element';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IField, IFieldAccessType } from '../../../../../../../../../../users/interface/field';
import { IAutomationFieldInput } from '../../../../../interface/automation';

interface ISelectedFieldSectionProps {
  dynamicRef: any;
  company: string;
  selectedBP: string;
  storeDynamicField(): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  copyFromObject: { [id: string]: any; };
  setCopyFromObject(object: { [id: string]: any }): void;
  handleTableRowState?(fieldId: string, value: { minRows: number; maxRows: number; config: any }): void;
  tableFocusRef: any;
  tableRowRef: any;
  headerName: string;
  fieldList: IField[];
  setSectionExpanded(value: boolean, id: string): void;
  sectionExpanded: { [id: string]: any };
  id: string;
}

interface ISelectedFieldProps extends ISelectedFieldSectionProps {
  fieldConfig: IField;
}

const SelectedFieldSection: React.FC<ISelectedFieldSectionProps> = (props) => {

  const icon: SemanticICONS = props.sectionExpanded && props.sectionExpanded[props.id] ? 'chevron up' : 'chevron down';
  const expanded = props.sectionExpanded && props.sectionExpanded[props.id];

  return <div className={`automation-field-section`}>
    <div className='automation-field-header'>
      <label>{props.headerName}</label>
      <div className='header-right'>
        <span className='count'>{props.fieldList.length}</span>
        <Icon name={icon} onClick={() => {
          props.setSectionExpanded(!expanded, props.id);
        }
        } />
      </div>
    </div>
    <div className={`automation-field-content ${!expanded ? 'hidden' : ''}`}>
      {props.fieldList.map((field: IField) => {
        return <SelectedField {...props}
          key={uuid()}
          fieldConfig={field}
        />
      })}
    </div>
  </div>

}

export default SelectedFieldSection;

const SelectedField: React.FC<ISelectedFieldProps> = (props) => {
  const { automationFieldInput } = useSelector((state: IRootState) => state.automation);

  const [operation, setOperation] = useState<'copy' | 'value'>('value');
  const [refresher, setRefresher] = useState(0);

  const getFromFieldOption = (): IDropdownOption[] => {
    const dateConfigs = [IConfigName.Date, IConfigName.Timestamp, IConfigName.Time, IConfigName.Range];
    const dateMeta = ['document.createdAt', 'document.updatedAt'];
    const option: IDropdownOption[] = []
    const fieldList = automationFieldInput.filter(e => e.keyPath.startsWith('fields') && !(e.referenceType && e.referencedKeyPath && e.referencedName));
    let metaList = automationFieldInput.filter(e => !e.keyPath.startsWith('fields'));
    if (props.fieldConfig.configName && dateConfigs.includes(props.fieldConfig.configName)) {
      metaList = metaList.filter(meta => dateMeta.includes(meta.keyPath));
    } else {
      metaList = metaList.filter(meta => !dateMeta.includes(meta.keyPath));
    }
    metaList.forEach(meta => {
      if (props.fieldConfig.configName && meta.name !== 'Seq') {
        option.push({
          key: uuid(),
          value: meta.keyPath,
          text: meta.name,
        });
      }
    });
    fieldList.forEach((field: IAutomationFieldInput) => {
      option.push({
        key: uuid(),
        value: field.keyPath,
        text: field.name
      });
    })
    return option;
  }

  const getFieldValue = (dynamicRef: any, id: string) => {
    if (props.copyFromObject && props.copyFromObject[field.id] &&
      (props.copyFromObject[field.id].hasOwnProperty('keyPath'))) {
      const value = props.copyFromObject[field.id];
      return value.keyPath;
    }
    return '';
  }

  const setValue = (value: string, id: string) => {
    const tempCopyObject = { ...props.copyFromObject };
    const objectValue = { keyPath: value };
    props.dynamicRef.current[id].keyPath = value;
    tempCopyObject[id] = objectValue;
    props.setCopyFromObject(tempCopyObject);
    props.storeDynamicField();
    setRefresher(refresher + 1);
  }

  const field = FieldElement.getDataElement(props.fieldConfig);
  const Element = field.getFieldElement();

  const manageOperation = (value: 'copy' | 'value', fieldId: string) => {
    setOperation(value)
    props.dynamicRef.current[fieldId].value = '';
    props.dynamicRef.current[fieldId].keyPath = '';
    const tempCopyObject = { ...props.copyFromObject };
    if (tempCopyObject[fieldId]) {
      tempCopyObject[fieldId] = '';
      props.setCopyFromObject(tempCopyObject);
    }
  }

  useEffect(() => {
    if (props.copyFromObject && props.copyFromObject[field.id] &&
      (props.copyFromObject[field.id].hasOwnProperty('keyPath'))) {
      setOperation('copy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.copyFromObject]);

  return (
    <div key={uuid()} className={`automation-field-container with-button select-field-container`}>
      <div className={`field automation-field full`}>
        {operation === 'value'
          ? <Element hasIconInside={false}
            {...field}
            forwardedRef={props.dynamicRef}
            defaultValueElement={false}
            isAddRecord={true}
            fieldEnumOptionUrl={`${props.company}/documents/fields?formId=${props.selectedBP}`}
            throwValueOutside={props.storeDynamicField}
            required={field.accessType === IFieldAccessType.Required}
            handleTableRowState={props.handleTableRowState}
            tableFocusRef={props.tableFocusRef}
            tableRowRef={props.tableRowRef}
          />
          : <>
            <label>{field.label} {field.accessType === IFieldAccessType.Required ? <span className={`required`}>*</span> : ''}</label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              placeholder='Search/ Select Fields'
              clearable
              search
              selectOnBlur={false}
              value={getFieldValue(props.dynamicRef, field.id)}
              options={getFromFieldOption()}
              onChange={(event, target: any) => { setValue(target.value, field.id) }}
            />
          </>
        }
      </div>
      <div className='button-container'>
        <Popup basic on='click' position='bottom left' id='copy-from-popup' style={{ filter: 'none', zIndex: '10000' }}
          trigger={
            <Button
              className={`btn-action-collapse`}
              icon>
              <i className="ellipsis horizontal icon"></i>
            </Button>
          }
        >
          <Container className='copy-from-popup-popup-container' >
            <div>OPERATION</div>
            <ul>
              <li onClick={() => manageOperation('value', field.id)}>
                {operation === 'value'
                  ? <span>✓</span>
                  : <span>&nbsp;&nbsp;&nbsp;</span>
                }
                &nbsp;&nbsp;Set value</li>
              <li onClick={() => manageOperation('copy', field.id)}>
                {operation === 'copy'
                  ? <span>✓</span>
                  : <span>&nbsp;&nbsp;&nbsp;</span>
                }
                &nbsp;&nbsp;Copy from
              </li>
            </ul>
          </Container>
        </Popup>
      </div>
    </div>
  );
}

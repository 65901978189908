import { useEffect } from 'react';
import { IFieldAccessType } from '../../../../../../../component/admin-module/module/users/interface/field';

interface InitialLoad {
  mountedRef: any;
  validateSelectedValue(): void;
  checkIfAllowedToCreate(): void;
  accessType: IFieldAccessType;
}

export const useInitialLoad = ({
  mountedRef,
  validateSelectedValue,
  checkIfAllowedToCreate,
  accessType
}: InitialLoad) => {
  useEffect(() => {
    if (accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    checkIfAllowedToCreate();
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
import React from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import SingleDate from './single';

const DateType: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <>Data Array</>
  }
  return <SingleDate {...props} />
}

export default DateType;
import { timeAgo } from '../../../../common/utils/get-time-ago';
import { IComment } from '../../../document-module/module/form/interface/comment';
import { ISenderInfo } from '../../../document-module/module/form/interface/sender-info';
import { businessProcessSubModules } from '../../constant';
import { IFormStatusWithAction } from '../../module/business-process/module/workflow/interface/form-status';
import { IDataTable } from '../../module/data-tables/reducer';
import { IUser } from '../../module/users/interface/user';
import { AdminHistoryCategory, AdminHistoryType, IAdminHistoryLogs } from '../interface/history';
import DeltaModule, { IDeltaFields } from './delta/module';
import { DatatableFieldsDeltaModule } from './delta/module/type/datatable/fields';
import { DatatablePermissionsDeltaModule } from './delta/module/type/datatable/permissions';

export interface IAdminHistoryUtils {
  fields?: any[];
  userList?: IUser[];
  history?: IAdminHistoryLogs[];
  selectedCategoryFilter?: string[];
  statusList?: IFormStatusWithAction[];
  dataTableList?: IDataTable[];
  tz?: string;
}

export class ManageAdminHistory {
  static uppercase = (text: string) => {
    return text.charAt(0).toLocaleUpperCase() + text.slice(1);
  }
  static lowercase = (text: string) => {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
  }
  static sanitizeData = (historyLogs: IAdminHistoryLogs[], utils: IAdminHistoryUtils): IComment[] => {
    const categoryFilter = utils.selectedCategoryFilter || [];
    const bpModuleCategories = businessProcessSubModules.map(e => e.categoryLogs);
    const isFilterCatUnderBPCategory = bpModuleCategories
      .filter(category => category.findIndex(e => categoryFilter.includes(e)) > -1)
      .every(e => e.length > 0);

    let adminHistoryLogs = historyLogs.map(logs => {
      let bpFieldsLayout = ManageAdminHistory.setBPFieldsLayout(logs);
      let { type, text, sender, context, category } = { ...logs };
      let deltaFields = null;

      text = `${sender.data.firstName || ''} ${sender.data.lastName || ''} ${ManageAdminHistory.lowercase(text)}`;

      if (categoryFilter && categoryFilter.indexOf(AdminHistoryCategory.FormBuilder) > -1) {
        category = bpFieldsLayout ? AdminHistoryCategory.FormBuilder : category;
      }

      if (category === AdminHistoryCategory.DataTableFields || category === AdminHistoryCategory.DataTablePermissions) {
        text = ManageAdminHistory.dataTableLogText(logs, { ...utils, history: historyLogs });
      }

      if (type === AdminHistoryType.Update && context && context.targetDelta && context.targetInstance
        && categoryFilter && categoryFilter.indexOf(category) > -1) {

        const adminDeltaFields = new DeltaModule();
        const delta = adminDeltaFields.formatContext({ ...logs, category }, { ...utils, history: historyLogs }) as IDeltaFields;

        if (!delta.showUpdateTextOnly) {
          deltaFields = delta.delta;
        }

        text = delta.text;
      }

      return {
        id: logs.id,
        type: 'history',
        text: text,
        sender: sender,
        createdAt: logs.createdAt,
        deltaFields,
        isAdminHistory: true,
        displayGeneratedText: ManageAdminHistory.displayGeneratedText(logs.category),
        historyType: type,
        historyCategory: category
      }
    }) as any;

    if (isFilterCatUnderBPCategory) {
      if (categoryFilter.indexOf(AdminHistoryCategory.FormBuilder) > -1) {
        const itemsToDiscard = ManageAdminHistory.formBuilderHistoryLogs([...adminHistoryLogs], utils);
        adminHistoryLogs = adminHistoryLogs.filter((e: IComment) => !itemsToDiscard.find(item => item.id === e.id));
      }
    }

    return adminHistoryLogs;
  }
  static senders = (historyLogs: IAdminHistoryLogs[]): ISenderInfo[] => {
    const senderList = [] as ISenderInfo[];
    historyLogs.forEach(logs => {
      const exists = senderList.find(sender => sender.sender.data.id === logs.sender.data.id);
      if (!exists) {
        senderList.push({ sender: logs.sender as any, senderUserInfo: logs.sender.data })
      }
    })
    return senderList;
  }
  static displayGeneratedText = (category: AdminHistoryCategory) => {
    if (category === AdminHistoryCategory.Users) {
      return false;
    }
    return true;
  }
  static setBPFieldsLayout = (historyLogs: IAdminHistoryLogs) => {
    let layout = null;
    if (historyLogs.category === AdminHistoryCategory.BusinessProcess
      && historyLogs.context.targetDelta
      && Object.keys(historyLogs.context.targetDelta).includes('fieldsLayout')) {
      layout = historyLogs;
    }
    return layout;
  }
  static formBuilderHistoryLogs = (adminHistoryLogs: IComment[], utils: IAdminHistoryUtils) => {
    const tempAdminHistoryLogs = adminHistoryLogs
      .filter((logs: IComment) => {
        return logs.historyCategory === AdminHistoryCategory.FormBuilder
          && (logs.text.includes('updated fields inside Section') ||
            logs.text.includes('updated header fields inside Section'))
      })
      .map(logs => {
        return {
          id: logs.id,
          text: logs.text,
          createdAt: timeAgo(logs.createdAt, utils.tz)
        }
      });
    return tempAdminHistoryLogs.filter((e, i) => i % 2 === 1);
  }
  static dataTableLogText = (historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) => {
    const dataTableField = new DatatableFieldsDeltaModule();
    const datatablePermission = new DatatablePermissionsDeltaModule();

    let dataTableList = dataTableField.deletedDTInstance(utils) as IDataTable[];

    if (utils.dataTableList) {
      dataTableList = dataTableList.concat(utils.dataTableList);
    }

    if (historyLogs.category === AdminHistoryCategory.DataTableFields) {
      return dataTableField.formatText(historyLogs, dataTableList, historyLogs.type);
    }

    return datatablePermission.format(historyLogs, utils).text;
  }
}
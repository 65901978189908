import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '..';
import { getFieldId } from '../../../../../../../common/utils/get-field';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { AdminHistoryCategory, AdminHistoryType, IAdminHistoryLogs } from '../../../../interface/history';
import { IAdminHistoryUtils } from '../../../manage-history';
import { FormFieldChanges } from '../../utils/form-field-changes';

export class UsersDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.Users;

  format(historyLogs: IAdminHistoryLogs, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { targetDelta, targetInstance } = { ...historyLogs.context };

    if (targetDelta && targetInstance) {
      Object.keys(targetDelta).forEach(key => {
        let deltaFields = {} as ICommentDeltaFields;
        const type = typeof { ...targetInstance }[key];

        if (key !== 'updatedAt' && key !== 'fields') {
          deltaFields.id = key;
          deltaFields.type = type === 'object' ? 'enum' : type;
          deltaFields.diff = targetDelta[key];

          if (key === 'profiles') {
            deltaFields.label = 'User Type';
            deltaFields.oldValue = targetInstance.profiles;
            deltaFields.diff = this.userProfileDelta(targetDelta[key]);
            deltaFields.oldValue = targetInstance[key] && targetInstance[key].map((e: any) => {
              return {
                id: e.id,
                label: e.name
              }
            });
          }
          if (key === 'managerUserId') {
            deltaFields.label = 'Manager';
            deltaFields.diff = this.userManagerDetails(deltaFields.diff, utils);
          }
          if (key === 'active') {
            deltaFields.label = 'Status'
          }
          deltaFields.text = historyLogs.text.match(/\((.*)\)/)?.pop();
          deltaFields.alwaysShowLabel = true;
        }
        if (Object.keys(deltaFields).length) {
          deltaArray.push(deltaFields);
        }
      });

      if (targetDelta.fields) {
        const fields = utils.fields && utils.fields.concat(this.deletedFieldInstance(utils));
        deltaArray = deltaArray.concat(FormFieldChanges.format(targetDelta, { fields }, targetInstance))
      }
    }

    return {
      delta: deltaArray,
      text: historyLogs.text
    };
  }

  userManagerDetails(managerDelta: string[], utils: IAdminHistoryUtils) {
    const userList = utils.history && utils.history
      .filter(e => e.type === AdminHistoryType.Delete && e.category === AdminHistoryCategory.Users)
      .map(e => e.context.targetInstance)
      .concat(utils.userList || []);

    return managerDelta.map(manager => {
      const userDetails = userList && userList.find(user => user.id === manager);
      if (userDetails) {
        const email = userDetails && userDetails.fields && userDetails.fields[getFieldId(utils.fields || [], 'Email Address')];
        if (email) {
          return email;
        }
      }
      return manager;
    });
  }

  deletedFieldInstance = (utils: IAdminHistoryUtils) => {
    return utils.history && utils.history
      .filter(e => e.type === AdminHistoryType.Delete
        && e.category === AdminHistoryCategory.UsersFields)
      .map(e => e.context.targetInstance)
  }

  userProfileDelta = (profileDelta: Delta) => {
    Object.keys(profileDelta).forEach(key => {
      if (profileDelta[key] instanceof Array) {
        profileDelta[key] = profileDelta[key].map((e: any) => {
          if (typeof e === 'object') {
            return {
              id: e.id,
              label: e.name
            }
          }
          return e;
        });
      }
    });
    return profileDelta;
  }
}
import React from 'react';

interface IPermissionTab {
  setActiveTab: Function;
  activeTab: 'fields' | 'dynamic-actions';
}

const PermissionTab: React.FC<IPermissionTab> = (props) => {
  return <div className='permission-tab-main'>
    <span className='permission-line' />
    <div className='permission-tab-list'>
      <div className={`permission-tab ${props.activeTab === 'fields' ? 'selected' : ''}`} onClick={() => props.setActiveTab('fields')}>
        <span className='tab-label'>Fields</span>
        {props.activeTab === 'fields' && < span className='tab-underline' />}
      </div>
      <div className={`permission-tab ${props.activeTab === 'dynamic-actions' ? 'selected' : ''}`} onClick={() => props.setActiveTab('dynamic-actions')}>
        <span className='tab-label'>Dynamic Actions</span>
        {props.activeTab === 'dynamic-actions' && <span className='tab-underline' />}
      </div>
    </div>
  </div>
}

export default PermissionTab;
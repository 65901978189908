import { ISLAs, IWorkTimeInterval, ISlaTaskAssigned, ISlaProcessLaunch, IInterval, IDeadlineSla } from '../../component/admin-module/module/business-process/module/configuration/module/slas/interface/sla';
import { IStatusTaskList, ITaskAssigned, IWorkingHours, ITimeTriggerField } from '../../component/admin-module/module/business-process/module/configuration/module/slas/interface/status-task-list';
import { IDaysCheckbox } from '../../component/admin-module/module/business-process/module/configuration/module/slas/interface/days-checkbox';
import moment from 'moment';
import { ISlasAndStatuses } from '../../component/admin-module/module/business-process/module/configuration/module/slas/interface/slaAndStatuses';
import { checkIfUUID } from './check-uuid-value';

const prepareDeadlineSla = (timeTriggerField: ITimeTriggerField, currentFormId: string): IDeadlineSla => {
  let deadlineSla = {} as IDeadlineSla;
  if (timeTriggerField.triggerType === 'value') {
    if (timeTriggerField.valueConfig) {
      let utcFormatDate = moment(timeTriggerField.valueConfig.value).utc().format();
      deadlineSla = {
        value: utcFormatDate,
        keyPath: undefined,
        refFieldId: undefined
      }
    }
  } else {
    if (timeTriggerField.fieldConfig) {
      let refFieldId: string | undefined = timeTriggerField.fieldConfig.fromBP;
      let fieldId = timeTriggerField.fieldConfig.fieldId;
      let reference = timeTriggerField.fieldConfig.reference;
      if (refFieldId === currentFormId) {
        refFieldId = undefined;
      }
      if (fieldId.indexOf('fields.') === -1) {
        fieldId = `fields.${fieldId}`;
      }
      if (reference && Object.keys(reference).length > 0) {
        deadlineSla = {
          value: undefined,
          keyPath: reference.keyPath,
          refFieldId: reference.refFieldId
        }
      } else {
        deadlineSla = {
          value: undefined,
          keyPath: fieldId,
          refFieldId: refFieldId
        }
      }
    }
  }
  return deadlineSla;
}

const splitFieldIdValue = (fieldId: string) => {
  if (fieldId.indexOf('fields.') > -1) {
    fieldId = fieldId.split('fields.').filter(key => key !== '' && checkIfUUID(key))[0];
  }
  return fieldId;
}

export const prepareDeadlineField = (deadline: IDeadlineSla, formId: string) => {
  let timeTriggerField = {} as ITimeTriggerField;
  if (deadline && deadline.value) {
    timeTriggerField.triggerType = 'value';
    timeTriggerField.valueConfig = {
      fieldType: 'date',
      value: deadline.value,
    }
  } else {
    timeTriggerField.triggerType = 'field';
    timeTriggerField.fieldConfig = {
      fieldId: splitFieldIdValue(deadline.keyPath || ''),
      fromBP: deadline.refFieldId || formId,
      reference: deadline.refFieldId ? {
        keyPath: deadline.keyPath,
        refFieldId: deadline.refFieldId
      } : {} as any
    }
  }
  return timeTriggerField;
}

export class SanitizeSlas {
  static sanitizeIncomingSla = (slaAndStatuses: ISlasAndStatuses, sla: ISlasAndStatuses): ISlasAndStatuses => {
    return {
      includeDraft: slaAndStatuses.includeDraft,
      taskAssigned: slaAndStatuses.taskAssigned.map(taskAssigned => {
        const slaTaskAssigned = sla && sla.taskAssigned && sla.taskAssigned.find(e => e.statusId === taskAssigned.statusId);
        if (!!slaTaskAssigned) {
          taskAssigned.deadline = slaTaskAssigned.deadline;
        }
        return taskAssigned;
      }),
      processLaunch: sla && sla.processLaunch && sla.processLaunch,
      workTimeIntervals: slaAndStatuses.workTimeIntervals
    } as ISlasAndStatuses;
  }

  static outgoingSlas = (statusTaskList: IStatusTaskList, currentFormId: string) => {
    console.log("Log ~ statusTaskList", statusTaskList);
    let default24Hour = moment('00:00:00', 'HH:mm:ss').format("HH:mm:ssZ");
    let workTimeIntervalList = [] as IWorkTimeInterval[];
    let taskAssignedList = [] as ISlaTaskAssigned[];

    statusTaskList.daysToCount.forEach((days: IDaysCheckbox) => {
      if (days.isChecked === true) {
        workTimeIntervalList.push({
          dayOfWeek: days.value,
          startTime: statusTaskList.workingHours ? statusTaskList.workingHours.startTime : default24Hour,
          endTime: statusTaskList.workingHours ? statusTaskList.workingHours.endTime : default24Hour
        });
      }
    });

    statusTaskList.taskAssigned.forEach((taskAssigned: ITaskAssigned) => {
      if (taskAssigned.count > 0 || (!!taskAssigned.timeTriggerField)) {
        taskAssignedList.push({
          statusId: taskAssigned.statusId,
          warningInterval: {
            unit: taskAssigned.unit,
            value: isNaN(taskAssigned.warningInterval) ? 0 : taskAssigned.warningInterval
          },
          interval: taskAssigned.timeTrigger !== 'deadline' ? {
            value: isNaN(taskAssigned.count) ? 0 : taskAssigned.count,
            unit: taskAssigned.unit
          } : undefined,
          deadline: taskAssigned.timeTrigger === 'deadline' ?
            prepareDeadlineSla(taskAssigned.timeTriggerField || {} as ITimeTriggerField, currentFormId) : undefined
        });
      }
    });

    let processLaunchData = {
      interval: statusTaskList.processLaunch.timeTrigger !== 'deadline' ? {
        unit: statusTaskList.processLaunch.unit,
        value: statusTaskList.processLaunch.count
      } as IInterval : undefined,
      warningInterval: {
        unit: statusTaskList.processLaunch.unit,
        value: statusTaskList.processLaunch.warningInterval
      },
      deadline: statusTaskList.processLaunch.timeTrigger === 'deadline' ?
        prepareDeadlineSla(statusTaskList.processLaunch.timeTriggerField || {} as ITimeTriggerField, currentFormId) : undefined
    } as ISlaProcessLaunch;

    return {
      taskAssigned: statusTaskList.isTaskAssigned ? taskAssignedList : undefined,
      processLaunch: statusTaskList.isProcessLaunch ? processLaunchData : undefined,
      workTimeIntervals: workTimeIntervalList,
      includeDraft: statusTaskList.includeDraft
    } as ISLAs;
  }

  static incomingWorkTimeInterval = (workTimeIntervals: IWorkTimeInterval[], daysOfWeek: IDaysCheckbox[]) => {
    let daysOfWeekList = Object.assign([], daysOfWeek);
    let workingHoursData = {} as IWorkingHours;
    let is24HourChecked = false;

    if (workTimeIntervals) {
      let default24Hour = moment('00:00:00', 'HH:mm:ss').format("HH:mm:ssZ");
      let daysOfWeekListNew: any = [...daysOfWeekList];
      let workingHoursNew = {} as IWorkingHours;
      is24HourChecked = workTimeIntervals.every((workTime: IWorkTimeInterval) =>
        workTime.startTime === default24Hour && workTime.endTime === default24Hour
      );
      workTimeIntervals.forEach((workTime: IWorkTimeInterval) => {
        let data = {}
        let index = daysOfWeekList.findIndex((weekDays: IDaysCheckbox) => weekDays.value === workTime.dayOfWeek);
        daysOfWeekList.forEach((days: IDaysCheckbox) => {
          if (days.value === workTime.dayOfWeek) {
            data = {
              id: days.id,
              value: days.value,
              label: days.label,
              isChecked: true
            }
          }
        });
        daysOfWeekListNew[index] = data;
        daysOfWeekList = daysOfWeekListNew
        workingHoursNew.startTime = workTime.startTime;
        workingHoursNew.endTime = workTime.endTime;
        workingHoursData = workingHoursNew;
      });
    } else {
      let daysOfWeekListNew: any = [...daysOfWeekList];
      let workingHoursNew = {} as IWorkingHours;
      daysOfWeekList = daysOfWeekListNew
      workingHoursNew.startTime = '';
      workingHoursNew.endTime = '';
      workingHoursData = workingHoursNew;
      is24HourChecked = true;
    }
    return {
      workTimeInterval: daysOfWeekList,
      workingHours: workingHoursData,
      is24HourChecked: is24HourChecked
    };
  }
}
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { ISubMenuList } from './interface/sub-menu-list';
import { ISubMenuListProp } from './interface/sub-menu-list-props';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import changeRoute from '../../../../../../../common/utils/change-menu';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
  formId: string;
}

export class SubMenuList implements ISubMenuList {

  name = 'automation';

  Render: React.FC<ISubMenuListProp> = (props) => {
    const { hasPageChange } = useSelector((state: IRootState) => state.main);
    const match: IRouteMatch = useRouteMatch();
    const dispatch = useDispatch();

    return <>
      {
        props.modules.map(({ id, name }, key) => {
          return (
            <React.Fragment key={key}>
              <Button onClick={() => {
                changeRoute(`/admin/${match.params.submodule}/${this.name}/${match.params.formId}/${id}`,
                  hasPageChange || props.hasPageChangeRef.current.hasChange,
                  { history: props.history, dispatch })
              }} className={`ui button admin-item-menu ${id.toLowerCase() === props.main.fourthLevelRoute.toLowerCase() ? 'automation-active-subroute' : ''}`}>{name}</Button>
            </React.Fragment>
          )
        })
      }

    </>
  }
}

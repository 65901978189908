import {
  IConfig,
  ICustomFieldDetail,
  IReferencedFieldFilterConfig,
  IReferencedFieldCondition,
  IDataFitersConfig,
} from '../interface/custom-field-detail';
import { plainToClass } from 'class-transformer';
import {
  EnumExclusiveMode,
  EnumSelectAllMode,
  IFieldConfig,
  IOptionValue,
  ISortEnumConfig,
} from '../../../component/admin-module/module/users/interface/field';
import { IForm } from '../../../component/admin-module/module/business-process/module/form/interface/form';
import {
  systemField,
  IDFilterField,
  URNFilterField,
} from '../filter-configuration/constant';
import { IDataTable } from '../../../component/admin-module/module/data-tables/reducer';
import { IConfigName } from '../../field/type/interface/field-type-object';
import {
  formDtoCustomField,
  userDtoCustomField,
} from '../custom-field-type/enum-field-config/constants';
interface IGenerateInputObject {
  fieldData: ICustomFieldDetail;
  configData: IFieldConfig;
  enumDataType: string;
  companyId: string;
  selectedSource: string;
  selectedDisplayField: string[];
  separator: string;
  filterConfig: IReferencedFieldFilterConfig;
  formDetail: IForm | IDataTable;
  optionValue: IOptionValue[];
  isMultiselect: boolean;
  displayFieldList: ICustomFieldDetail[];
  isCreateRecord: boolean;
  isStaticSource: boolean;
  QRInput: boolean;
  groupingKeyPath: string;
  imageKeyPath: string;
  userFieldList: ICustomFieldDetail[];
  autopopulate: boolean;
  sort: ISortEnumConfig;
  exclusiveMode: EnumExclusiveMode;
  selectAllMode: EnumSelectAllMode;
  displayFieldFrom: {
    showReferenceInSource?: boolean | undefined;
    showReferenceInDestination?: boolean | undefined;
  };
  uniquePerGroup?: boolean;
  additionalInfo?: string;
}

export enum EnumSourceType {
  Application = 'application',
  AssigneeUser = 'assigneeUser',
  Database = 'database',
  Document = 'document',
  Form = 'form',
  Plugin = 'plugin',
  Field = 'field',
  Action = 'action',
  EtField = 'etField',
  DataTable = 'datatable',
  Static = 'static',
  Status = 'status',
  User = 'user',
  UserWithSameFormAccess = 'userWithSameFormAccess',
  UserProfile = 'user-profile',
  Referenced = 'referenced',
}

export const generateInputObject = ({
  fieldData,
  configData,
  enumDataType,
  companyId,
  selectedSource,
  selectedDisplayField,
  separator,
  filterConfig,
  formDetail,
  optionValue,
  isMultiselect,
  isCreateRecord,
  isStaticSource,
  QRInput,
  imageKeyPath,
  groupingKeyPath,
  autopopulate,
  sort,
  exclusiveMode,
  selectAllMode,
  displayFieldFrom,
  displayFieldList,
  userFieldList,
  uniquePerGroup,
  additionalInfo,
}: IGenerateInputObject): IConfig => {
  const getFieldList = (source: EnumSourceType) => {
    switch (source) {
      case EnumSourceType.Form:
        return formDtoCustomField;
      case EnumSourceType.User:
        return userFieldList;
      default:
        return displayFieldList;
    }
  };

  const referencesSourceType = Object.values(EnumSourceType);
  const dataType = enumDataType as EnumSourceType;
  const isUseReference = referencesSourceType.includes(dataType);

  let config: any = {};

  if (fieldData.configName === IConfigName.Referenced) {
    const isDocumentForm =
      fieldData &&
      fieldData.type === 'enum' &&
      isUseReference &&
      selectedSource;
    if (isDocumentForm) {
      let data = { companyId: companyId } as any;
      let [type, sourceId] = selectedSource.split('|||||');
      if (type === 'database') {
        data.databaseId = sourceId;
      }
      if (type === 'document') {
        data.formId = sourceId;
      }
      let labelFormat = '';
      let labelType = '';
      selectedDisplayField.forEach((fieldId: string) => {
        if (labelFormat !== '') {
          labelFormat += separator ? separator || ' ' : ' ';
        }
        if (labelType !== '') {
          labelType += ',';
        }
        labelFormat += `{{${fieldId}}}`;
        labelType += getLabelType(getFieldList(dataType), fieldId);
      });

      config = {
        dataType: enumDataType,
        data: data,
        labelFormat: labelFormat,
        labelType: labelType,
        separator: separator ? separator : ' ',
        showCreateBpOrDt: isCreateRecord,
        staticSource: isStaticSource,
        qrInput: QRInput,
        imageKeyPath: imageKeyPath !== '' ? imageKeyPath : undefined,
        groupingKeyPath: groupingKeyPath !== '' ? groupingKeyPath : undefined,
        autopopulate: autopopulate ? autopopulate : undefined,
        uniquePerGroup: uniquePerGroup ? uniquePerGroup : undefined,
        sublabelKeyPath: additionalInfo !== '' ? additionalInfo : undefined,
      };

      if (filterConfig.condition) {
        config = {
          dataFiltersJoint:
            filterConfig.condition.length > 0
              ? filterConfig.joinCondition
              : undefined,
          ...config,
          dataFilters:
            filterConfig.condition.length > 0
              ? filterConfig.condition.map(
                  (condition: IReferencedFieldCondition) => {
                    const dataSource =
                      formDetail !== undefined &&
                      formDetail?.id === condition.fromBp
                        ? 'current'
                        : 'reference';
                    let keyPath = '';
                    let fieldValue: any = '';
                    const tempSystemField = [
                      ...systemField,
                      { ...IDFilterField },
                      { ...URNFilterField },
                      ...userDtoCustomField,
                      ...formDtoCustomField,
                    ];
                    if (enumDataType === 'user' && condition.fieldId === 'id') {
                      keyPath = condition.fieldId;
                      fieldValue = {
                        keyPath: condition.compareField.fieldRef
                          ? condition.compareField.fieldRef
                          : condition.compareField.fieldId,
                      };
                    } else if (condition.compareField.fieldRef) {
                      const isSystemField = tempSystemField.filter(
                        (field: ICustomFieldDetail) =>
                          field.id === condition.fieldId
                      );
                      keyPath =
                        isSystemField.length > 0
                          ? condition.fieldId
                          : `fields.${condition.fieldId}`;

                      fieldValue = {
                        keyPath: condition.tableId
                          ? `fields.${condition.tableId}.${condition.compareField.fieldId}`
                          : `fields.${condition.compareField.fieldId}`,
                        referencedKeyPath: `fields.${condition.compareField.fieldRef}`,
                      };
                      const isReferenceSystemField = tempSystemField.filter(
                        (field: ICustomFieldDetail) =>
                          field.id === condition.compareField.fieldId
                      );

                      if (isReferenceSystemField.length > 0) {
                        fieldValue = {
                          keyPath: condition.compareField.fieldId,
                          referencedKeyPath: condition.compareField.fieldRef,
                        };
                      }
                    } else {
                      if (dataSource === 'current') {
                        const isSystemField = tempSystemField.filter(
                          (field: ICustomFieldDetail) =>
                            field.id === condition.compareField.fieldId
                        );
                        keyPath =
                          isSystemField.length > 0
                            ? condition.compareField.fieldId
                            : `fields.${condition.compareField.fieldId}`;
                        const isCurrentSystemField = tempSystemField.filter(
                          (field: ICustomFieldDetail) =>
                            field.id === condition.fieldId
                        );
                        fieldValue = {
                          keyPath:
                            isCurrentSystemField.length > 0
                              ? condition.fieldId
                              : `fields.${condition.fieldId}`,
                        };
                        if (condition.tableId) {
                          fieldValue = {
                            keyPath: `fields.${condition.tableId}.${condition.fieldId}`,
                          };
                        }
                      } else {
                        const isSystemField = tempSystemField.filter(
                          (field: ICustomFieldDetail) =>
                            field.id === condition.fieldId
                        );
                        keyPath =
                          isSystemField.length > 0
                            ? condition.fieldId
                            : `fields.${condition.fieldId}`;
                        if (condition.compareType === 'value') {
                          fieldValue = condition.compareValue;
                        } else {
                          const isReferenceSystemField = tempSystemField.filter(
                            (field: ICustomFieldDetail) =>
                              field.id === condition.compareField.fieldId
                          );
                          fieldValue = {
                            keyPath:
                              isReferenceSystemField.length > 0
                                ? condition.compareField.fieldId
                                : `fields.${condition.compareField.fieldId}`,
                          };
                        }
                      }
                    }

                    return {
                      keyPath: keyPath,
                      operator: condition.operator,
                      value: fieldValue,
                      style: { dataSource: dataSource },
                    } as IDataFitersConfig;
                  }
                )
              : undefined,
        };
      }
    }
    config = {
      ...config,
      dataFiltersJoint:
        config.dataFilters && config.dataFilters.length > 0
          ? config.dataFiltersJoint
          : undefined,
      exclusiveMode:
        exclusiveMode && exclusiveMode !== EnumExclusiveMode.None
          ? exclusiveMode
          : undefined,
      selectAllMode:
        selectAllMode && selectAllMode !== EnumSelectAllMode.None
          ? selectAllMode
          : undefined,
      ...displayFieldFrom,
    };
  }

  let dropdownConfig = {
    data: optionValue,
    multiselect: isMultiselect,
    qrInput: QRInput,
    sort: [sort],
    ...config,
  };

  if (configData && configData.format === '') {
    configData.format = 'none';
  }

  return plainToClass(IConfig, {
    ...configData,
    ...dropdownConfig,
  });
};

const getLabelType = (
  displayFieldList: ICustomFieldDetail[],
  fieldId: string
): string => {
  const tempDisplayField = displayFieldList.filter(
    (field: ICustomFieldDetail) => field.id === fieldId.replace('fields.', '')
  );
  if (tempDisplayField.length > 0) {
    return tempDisplayField[0].type || '';
  }
  return 'no-type';
};

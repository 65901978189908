import { useEffect } from 'react';
import { getRandomColor } from '../../../../../../common/utils/get-random-color';
import { ISenderInfo } from '../../interface/sender-info';

interface IProps {
  senderList: ISenderInfo[];
  setUserColor: (value: React.SetStateAction<{
    [id: string]: string;
  }>) => void;
}

export const useSetUserColor = ({ senderList, setUserColor }: IProps): void => {
  useEffect(() => {
    let tempUserColor: { [id: string]: string } = {};
    senderList.forEach((user) => {
      tempUserColor[user.senderUserInfo && user.senderUserInfo.id] = getRandomColor();
    });
    setUserColor(tempUserColor);
    return () => {
      setUserColor({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(senderList)]);
};
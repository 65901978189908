import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';

export const GET_COMPANIES_LIST = 'app/bpmManagerModule/companies/GET_COMPANIES_LIST';
export const GET_COMPANIES_LIST_RETURN = 'app/bpmManagerModule/companies/GET_COMPANIES_LIST_RETURN';
export const GET_COMPANIES_LIST_ERROR = 'app/bpmManagerModule/companies/GET_COMPANIES_LIST_ERROR';
export const UPLOAD_COMPANY_LOGO = 'app/bpmManagerModule/companies/UPLOAD_COMPANY_LOGO';
export const UPLOAD_COMPANY_LOGO_RETURN = 'app/bpmManagerModule/companies/UPLOAD_COMPANY_LOGO_RETURN';
export const UPLOAD_COMPANY_LOGO_ERROR = 'app/bpmManagerModule/companies/UPLOAD_COMPANY_LOGO_ERROR';
export const CREATE_COMPANY = 'app/bpmManagerModule/companies/CREATE_COMPANY';
export const CREATE_COMPANY_RETURN = 'app/bpmManagerModule/companies/CREATE_COMPANY_RETURN';
export const CREATE_COMPANY_ERROR = 'app/bpmManagerModule/companies/CREATE_COMPANY_ERROR';
export const GET_COMPANY_LOGO = 'app/bpmManagerModule/companies/GET_COMPANY_LOGO';
export const GET_COMPANY_LOGO_RETURN = 'app/bpmManagerModule/companies/GET_COMPANY_LOGO_RETURN';
export const GET_COMPANY_LOGO_ERROR = 'app/bpmManagerModule/companies/GET_COMPANY_LOGO_ERROR';
export const CLEAR_COMPANY_RESPONSE_MESSAGE = 'app/bpmManagerModule/companies/CLEAR_COMPANY_RESPONSE_MESSAGE';
export const GET_COMPANY_DETAILS = 'app/bpmManagerModule/companies/GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_RETURN = 'app/bpmManagerModule/companies/GET_COMPANY_DETAILS_RETURN';
export const GET_COMPANY_DETAILS_ERROR = 'app/bpmManagerModule/companies/GET_COMPANY_DETAILS_ERROR';
export const DELETE_COMPANY = 'app/bpmManagerModule/companies/DELETE_COMPANY';
export const DELETE_COMPANY_CANCEL = 'app/bpmManagerModule/companies/DELETE_COMPANY_CANCEL';
export const DELETE_COMPANY_CONFIRM = 'app/bpmManagerModule/companies/DELETE_COMPANY_CONFIRM';
export const DELETE_COMPANY_CONFIRM_RETURN = 'app/bpmManagerModule/companies/DELETE_COMPANY_CONFIRM_RETURN';
export const DELETE_COMPANY_CONFIRM_ERROR = 'app/bpmManagerModule/companies/DELETE_COMPANY_CONFIRM_ERROR';
export const UPDATE_COMPANY = 'app/bpmManagerModule/companies/UPDATE_COMPANY';
export const UPDATE_COMPANY_RETURN = 'app/bpmManagerModule/companies/UPDATE_COMPANY_RETURN';
export const UPDATE_COMPANY_ERROR = 'app/bpmManagerModule/companies/UPDATE_COMPANY_ERROR';
export const CLEAR_CREATE_COMPANY_SUCCESS = 'app/bpmManagerModule/companies/CLEAR_CREATE_COMPANY_SUCCESS';
export const CLEAR_UPDATE_COMPANY_SUCCESS = 'app/bpmManagerModule/companies/CLEAR_UPDATE_COMPANY_SUCCESS';
export const CLEAR_DELETE_COMPANY_SUCCESS = 'app/bpmManagerModule/companies/CLEAR_DELETE_COMPANY_SUCCESS';
export const CLEAR_CREATE_COMPANY_ERROR = 'app/bpmManagerModule/companies/CLEAR_CREATE_COMPANY_ERROR';
export const CLEAR_UPDATE_COMPANY_ERROR = 'app/bpmManagerModule/companies/CLEAR_UPDATE_COMPANY_ERROR';
export const CLEAR_DELETE_COMPANY_ERROR = 'app/bpmManagerModule/companies/CLEAR_DELETE_COMPANY_ERROR';
export const CLEAR_COMPANY_LOGO_ERROR = 'app/bpmManagerModule/companies/CLEAR_COMPANY_LOGO_ERROR';
export const CLEAR_COMPANY_ACTION_MESSAGE = 'app/bpmManagerModule/companies/CLEAR_COMPANY_ACTION_MESSAGE';

export const companiesLimit = 20;

export const companyListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_COMPANY_SUCCESS,
    type: 'success',
    reducerContainer: 'companiesReducer',
    reducerItem: 'createCompanyMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_UPDATE_COMPANY_SUCCESS,
    type: 'success',
    reducerContainer: 'companiesReducer',
    reducerItem: 'updateCompanyMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_DELETE_COMPANY_SUCCESS,
    type: 'success',
    reducerContainer: 'companiesReducer',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_DELETE_COMPANY_ERROR,
    type: 'negative',
    reducerContainer: 'companiesReducer',
    reducerItem: 'confirmDeletionErrorMessage',
    hasCloseIcon: true
  },
]

export const companyFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_COMPANY_ERROR,
    type: 'negative',
    reducerContainer: 'companiesReducer',
    reducerItem: 'updateCompanyError',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_CREATE_COMPANY_ERROR,
    type: 'negative',
    reducerContainer: 'companiesReducer',
    reducerItem: 'createCompanyErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_COMPANY_LOGO_ERROR,
    type: 'negative',
    reducerContainer: 'companiesReducer',
    reducerItem: 'companyLogoError',
    hasCloseIcon: true
  }
]

export const companiesFormTabList = [
  {
    id: 'react',
    label: 'REACT'
  },
  {
    id: 'flutterApp',
    label: 'FLUTTER APP'
  },
  {
    id: 'flutterWeb',
    label: 'FLUTTER WEB'
  },
]
import { useEffect } from 'react';

interface IProps {
  dispatch: any;
  clearImportExportMessage: any;
  importExportSuccessMessage: string;
  importExportErrorMessage: string;
}

export const useClearImportExportMessage = (props: IProps) => {
  const { dispatch, clearImportExportMessage, importExportSuccessMessage, importExportErrorMessage } = { ...props }
  useEffect(() => {
    setTimeout(() => { dispatch(clearImportExportMessage()); }, 5000)
  }, [dispatch, clearImportExportMessage, importExportSuccessMessage, importExportErrorMessage])
}
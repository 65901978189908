import React from 'react';
import { v4 as uuid } from 'uuid';
import ToolTip from '../../../../../../../../../../../common/general/tooltip';
import SVGWrapper from '../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { conditionIconStyle, newComponentIcon } from '../../../constant';
import { IAutomationChildComponent, IAutomationComponent } from '../../../interface/component-list';
import { IOptionWidget } from '../../../interface/option-widget';
import { IRender } from './component-registry';

class AutomationOutlineCondition {

  name = 'condition';

  render: React.FC<IRender> = (props) => {
    const title = props.component.config && props.component.config.condition && props.component.config.condition.title;
    const name = props.component.config && props.component.config.condition && props.component.config.condition.name;

    const iconName = props.component.config && props.component.config.condition && props.component.config.condition.iconName;
    const iconHeight = conditionIconStyle[iconName || 'default'].height;
    const iconWidth = conditionIconStyle[iconName || 'default'].width;

    const newAction = (elseIndex?: number) => {
      const selectedComponent = props.component;
      if (!selectedComponent) return;

      const action = newComponentIcon.find((option: IOptionWidget) => option.type === 'action');
      const currentId = uuid();

      if (action) {
        const actionDetail = {
          id: currentId,
          type: action.type,
          name: action.title,
          config: {},
        };
        let selectedActionObject = undefined;
        if (elseIndex !== undefined) {
          if (props.component?.config?.elseConditionList && props.component?.config?.elseConditionList[elseIndex]) {
            const tempAction = props.component?.config?.elseConditionList[elseIndex].actionList ? [...props.component?.config?.elseConditionList[elseIndex].actionList] : [];
            tempAction.push(actionDetail);
            selectedComponent.config.elseConditionList[elseIndex].actionList = tempAction;
            selectedActionObject = {
              type: 'else',
              elseRowIndex: elseIndex,
              rowIndex: selectedComponent.config.elseConditionList[elseIndex].actionList.length - 1,
              component: actionDetail
            };
          }
        } else {
          const tempAction = props.component?.config?.actionList ? [...props.component?.config?.actionList] : [];
          tempAction.push(actionDetail);
          selectedComponent.config.actionList = tempAction;
          selectedActionObject = {
            type: 'if',
            rowIndex: selectedComponent.config.actionList.length - 1,
            component: actionDetail
          };
        }
        props.updateCurrentAutomation(selectedComponent, false);
        props.setSelectedComponent(props.component, selectedActionObject as IAutomationChildComponent)
      }
    }

    const newElse = () => {
      const selectedComponent = props.component;
      if (!selectedComponent) { return; }

      const elseCondition = {
        conditionList: [],
        actionList: []
      }
      const tempelseCondition = props.component?.config?.elseConditionList ? [...props.component?.config?.elseConditionList] : [];
      tempelseCondition.push(elseCondition);
      selectedComponent.config.elseConditionList = tempelseCondition;
      props.updateCurrentAutomation(selectedComponent, false);
      props.setSelectedComponent(props.component, {
        type: 'else',
        elseRowIndex: tempelseCondition.length - 1
      } as IAutomationChildComponent);
    }

    const saveWhenComponentHasChange = () => {
      const selectedComponent: any = { ...props.selectedChildComponent };
      if (selectedComponent && selectedComponent.component && props.hasComponentChange.current === true) {
        const btnSaveCurrComponent = document.getElementById(selectedComponent.component.id);
        if (btnSaveCurrComponent) {
          btnSaveCurrComponent.click();
        }
      }
    }

    const renderIfElseBlock = () => {
      const conditionList = props.component?.config?.conditionList || [];
      const elseConditionList = props.component?.config?.elseConditionList || [];
      const actionList = props.component?.config?.actionList || [];
      const elseCondtionWithEmptyCondition = elseConditionList.filter((e: { conditionList?: IAutomationComponent[], actionList?: IAutomationComponent[] }) => !e.conditionList || e.conditionList.length === 0);

      return <>
        <ul className={`rule-outline-child`}>
          <li>
            <div><i className="circle icon"></i></div>
            <div>
              <div className={`if-else-title`} onClick={saveWhenComponentHasChange}>if</div>
              {conditionList.map((condition: IAutomationComponent, itemIndex: number) => {
                const title = condition.config?.condition?.title || condition.name;
                return <div key={uuid()}
                  onClick={(e) => {
                    props.setSelectedComponent(props.component, {
                      type: 'if',
                      rowIndex: itemIndex,
                      component: condition
                    } as IAutomationChildComponent)
                    e.stopPropagation();
                  }}
                  className={`if-else-condition child-title`}>
                  <label title={title}>{title}</label>
                  <ToolTip position='right center'
                    popupMessage='Delete'
                    popupTriggerComponent={<i className="trash alternate outline icon"
                      onClick={(e) => {
                        props.deleteCreatedComponent('condition', props.currentIndex, {
                          parent: 'if',
                          type: 'condition',
                          childIndex: itemIndex,
                        })
                        e.stopPropagation();
                      }}></i>} />
                </div>
              })
              }
              <ul className={`rule-outline-child`}>
                {actionList.map((action: IAutomationComponent, itemIndex: number) => {
                  const title = action.config?.action?.title || action.name;
                  return <li key={uuid()} onClick={(e) => {
                    props.setSelectedComponent(props.component, {
                      type: 'if',
                      rowIndex: itemIndex,
                      component: action
                    } as IAutomationChildComponent)
                    e.stopPropagation();
                  }}>
                    <div><i className="circle icon"></i></div>
                    <div className={`child-title`}>
                      <label title={title}>{title}</label>
                      <ToolTip position='right center'
                        popupMessage='Delete'
                        popupTriggerComponent={<i className="trash alternate outline icon"
                          onClick={(e) => {
                            props.deleteCreatedComponent('condition', props.currentIndex, {
                              parent: 'if',
                              type: 'action',
                              childIndex: itemIndex,
                            })
                            e.stopPropagation();
                          }}></i>} />
                    </div>
                  </li>;
                })
                }
                {conditionList && conditionList.length > 0 &&
                  conditionList.every((c: IAutomationComponent) => c.config && Object.keys(c.config).length > 0) &&
                  <li className={`add-action`} onClick={(e) => {
                    newAction();
                    e.stopPropagation();
                  }}>
                    <div><i className="circle icon"></i></div>
                    <div onClick={saveWhenComponentHasChange}>Add Action</div>
                  </li>
                }
              </ul>
            </div>
          </li>
          {elseConditionList.map((e: { conditionList?: IAutomationComponent[], actionList?: IAutomationComponent[] }, elseIndex: number) => {
            return <li key={uuid()} onClick={(e) => {
              props.setSelectedComponent(props.component, {
                type: 'else',
                elseRowIndex: elseIndex
              } as IAutomationChildComponent)
              e.stopPropagation();
            }}>
              <div><i className="circle icon"></i></div>
              <div>
                <div className={`if-else-title`}>
                  <label>{`else ${e.conditionList && e.conditionList.length > 0 ? 'if' : ''}`}</label>
                  <ToolTip position='right center'
                    popupMessage='Delete'
                    popupTriggerComponent={<i className="trash alternate outline icon"
                      onClick={(e) => {
                        props.deleteCreatedComponent('condition', props.currentIndex, {
                          parent: 'else',
                          type: 'action',
                          parentIndex: elseIndex
                        })
                        e.stopPropagation();
                      }}></i>} />
                </div>
                {e.conditionList && e.conditionList.map((condition: IAutomationComponent, itemIndex: number) => {
                  const title = condition.config?.condition?.title || condition.name;
                  return <div key={uuid()}
                    onClick={(e) => {
                      props.setSelectedComponent(props.component, {
                        type: 'else',
                        elseRowIndex: elseIndex,
                        rowIndex: itemIndex,
                        component: condition
                      } as IAutomationChildComponent)
                      e.stopPropagation();
                    }}
                    className={`if-else-condition child-title`}>
                    <label title={title}>{title}</label>
                    <ToolTip position='right center'
                      popupMessage='Delete'
                      popupTriggerComponent={<i className="trash alternate outline icon"
                        onClick={(e) => {
                          props.deleteCreatedComponent('condition', props.currentIndex, {
                            parent: 'else',
                            type: 'condition',
                            parentIndex: elseIndex,
                            childIndex: itemIndex,
                          })
                          e.stopPropagation();
                        }}></i>} />
                  </div>
                })
                }
                <ul className={`rule-outline-child`}>
                  {e.actionList && e.actionList.map((action: IAutomationComponent, itemIndex: number) => {
                    const title = action.config?.action?.title || action.name;
                    return <li key={uuid()} onClick={(e) => {
                      props.setSelectedComponent(props.component, {
                        type: 'else',
                        elseRowIndex: elseIndex,
                        rowIndex: itemIndex,
                        component: action
                      } as IAutomationChildComponent)
                      e.stopPropagation();
                    }}>
                      <div><i className="circle icon"></i></div>
                      <div className={`child-title`}>
                        <label title={title}>{title}</label>
                        <ToolTip position='right center'
                          popupMessage='Delete'
                          popupTriggerComponent={<i className="trash alternate outline icon"
                            onClick={(e) => {
                              props.deleteCreatedComponent('condition', props.currentIndex, {
                                parent: 'else',
                                type: 'action',
                                parentIndex: elseIndex,
                                childIndex: itemIndex,
                              })
                              e.stopPropagation();
                            }}></i>} />
                      </div>
                    </li>
                  })
                  }
                  {e.conditionList && e.conditionList.length > 0 &&
                    e.conditionList.every((c: IAutomationComponent) => c.config && Object.keys(c.config).length > 0) &&
                    <li className={`add-action`} onClick={(e) => {
                      newAction(elseIndex);
                      e.stopPropagation();
                    }}>
                      <div><i className="circle icon"></i></div>
                      <div onClick={saveWhenComponentHasChange}>Add Action</div>
                    </li>
                  }
                </ul>
              </div>
            </li>
          })
          }
          {(elseConditionList.length === 0 || elseCondtionWithEmptyCondition.length === 0)
            ? <li className={`add-else`} onClick={(e) => {
              newElse();
              e.stopPropagation();
            }}>
              <div><i className="circle icon"></i></div>
              <div onClick={saveWhenComponentHasChange}>Add else</div>
            </li>
            : <li className={`add-else`} >
              <div><i className="circle icon"></i></div>
              <div>
              </div>
            </li>
          }
        </ul>
      </>
    }

    return (
      <li className={`outline-widget`} onClick={
        (e) => {
          props.setSelectedComponent(props.component);
          e.stopPropagation();
        }
      }>
        <div className='has-border'>
          <SVGWrapper width={iconWidth || 25} height={iconHeight || 25} iconName={iconName || 'fieldIssue'} color={`#3A85A8`} />
        </div>
        <div className={`content`}>
          <ToolTip position='right center'
            popupMessage='Delete'
            popupTriggerComponent={<i className="trash alternate outline icon" onClick={(e) => {
              props.deleteCreatedComponent('condition', props.currentIndex)
              e.stopPropagation();
            }}></i>} />
          <span className={`title`}>{title ? `Condition: ${title}` : `New Condition`}</span>
          {title
            ? <>
              {name === 'if-else-block' && renderIfElseBlock()}
            </>
            : <span className={`description`}>Select a condition to narrow the scope of your rule</span>
          }
        </div>
      </li >
    );
  }
}

export default AutomationOutlineCondition;
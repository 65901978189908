import { IApiCustomFilterField } from '../../configuration/module/custom-filter/interface/custom-filter-field';
import { saveDraftCustomFilterAction, publishCustomFilterAction } from '../../configuration/module/custom-filter/action';

interface IUpdateConfigUtils {
  deletedExistingField: string[];
  customFilterListAPI: IApiCustomFilterField[];
  formId: string;
  companyId: string;
  dispatch: any;
}

export class CheckFieldFilter {

  static updateConfig = (utils: IUpdateConfigUtils) => {
    const updatedReportsFilterConf = CheckFieldFilter.removeFieldToFilterConfig(
      utils.deletedExistingField,
      utils.customFilterListAPI
    );
    if (updatedReportsFilterConf) {
      utils.dispatch(saveDraftCustomFilterAction({
        company: utils.companyId,
        formId: utils.formId,
        saveType: 'save',
        customFilterList: updatedReportsFilterConf
      }));
      utils.dispatch(publishCustomFilterAction({
        company: utils.companyId,
        formId: utils.formId,
      }));
    }
  }

  static removeFieldToFilterConfig = (deletedFields: string[], customFilterListAPI: IApiCustomFilterField[]) => {
    const fieldsUsedInFilter = CheckFieldFilter.hasDeletedFieldUsedInFilter(deletedFields, customFilterListAPI);

    if (fieldsUsedInFilter.length === 0) {
      return null;
    }

    return CheckFieldFilter.updateReportsFilterConfig(deletedFields, customFilterListAPI)
      .filter(filter => filter.keyPaths.length > 0 && !filter.deleted)
      .map(filter => {
        delete filter.deleted;
        return filter;
      });
  }

  static transformKeypaths = (keyPaths: string[]) => {
    return keyPaths.map(key => {
      if (key.indexOf('fields.') > -1) {
        key = key.split('fields.')[1];
      }
      return key;
    })
  }

  static hasDeletedFieldUsedInFilter = (deletedFields: string[], customFilterListAPI: IApiCustomFilterField[]) => {
    return customFilterListAPI.filter(filter => {
      if (filter && filter.keyPaths) {
        const newKeyPaths = CheckFieldFilter
          .transformKeypaths(filter.keyPaths)
          .filter(ids => deletedFields.indexOf(ids) > -1);
        return newKeyPaths.length > 0;
      }
      return false;
    });
  }

  static updateReportsFilterConfig = (deletedFields: string[], customFilterListAPI: IApiCustomFilterField[]) => {
    const deletedFieldsKeypath = deletedFields.map(id => `fields.${id}`);
    return customFilterListAPI.map(filter => {
      if (filter && filter.keyPaths) {
        const newKeyPaths = filter.keyPaths.filter(ids => deletedFieldsKeypath.indexOf(ids) === -1);
        return {
          ...filter,
          keyPaths: newKeyPaths,
          deleted: newKeyPaths.length === 0
        };
      }
      return filter;
    });
  }

}
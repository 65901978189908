import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import {
  postRequest, setToken,
} from '../../../common/api-request';
import { submitLoginReturnAction } from '../action/login';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_RETURN,
  RESET_PASSWORD_RETURN_ERROR
} from './constant';

export function* resetPassword(args: any) {
  try {
    const req: AxiosResponse = yield call(postRequest, '/forgotPassword/reset', args.payload);
    yield put({ type: RESET_PASSWORD_RETURN, payload: { status: true, error: '' } });
    localStorage.setItem('token', req.data.data.Token);
    setToken(req.data.data.Token);
    yield put(submitLoginReturnAction({ token: req.data.data.Token, status: 'success' }));
  } catch (e) {
    yield put({ type: RESET_PASSWORD_RETURN_ERROR, payload: { status: false, error: e } });
  }
}

export function* resetPasswordSagas() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

// All sagas to be loaded
export default resetPasswordSagas;

import { useEffect } from 'react';
import { IDatatableRecord } from '../../../../../../common/custom-field-config/interface/datatable-record';
import { IDataTable } from '../../reducer';

interface IProps {
  setShowGridTable: (value: boolean) => void;
  forUpdateDataTableDetails: IDataTable;
  dataTableRecordsList: IDatatableRecord[];
  showGridTable: boolean;
}

export const useShowGridTable = (props: IProps): void => {
  const { setShowGridTable, dataTableRecordsList, forUpdateDataTableDetails, showGridTable } = { ...props };

  useEffect(() => {
    if (Object.keys(forUpdateDataTableDetails).length > 0 && dataTableRecordsList && !showGridTable) {
      const timeOut = setTimeout(() => setShowGridTable(true), 200);
      return () => clearTimeout(timeOut)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forUpdateDataTableDetails, dataTableRecordsList, showGridTable])

}
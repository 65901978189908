import React from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import SingleFile from './single';
import MultipleFile from './multiple';

const FileType: React.FC<AcceptedProps> = (props) => {

  if (props.multiline) {
    return <MultipleFile {...props} />
  }

  return <SingleFile {...props} />
}

export default FileType;
import {
  GET_COMPANIES_LIST,
  GET_COMPANIES_LIST_ERROR,
  GET_COMPANIES_LIST_RETURN,
  UPLOAD_COMPANY_LOGO,
  UPLOAD_COMPANY_LOGO_RETURN,
  UPLOAD_COMPANY_LOGO_ERROR,
  CREATE_COMPANY,
  GET_COMPANY_LOGO,
  GET_COMPANY_LOGO_RETURN,
  GET_COMPANY_LOGO_ERROR,
  CLEAR_COMPANY_RESPONSE_MESSAGE,
  CREATE_COMPANY_RETURN,
  CREATE_COMPANY_ERROR,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_RETURN,
  GET_COMPANY_DETAILS_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_CONFIRM,
  DELETE_COMPANY_CONFIRM_RETURN,
  DELETE_COMPANY_CONFIRM_ERROR,
  DELETE_COMPANY_CANCEL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_RETURN,
  CLEAR_COMPANY_ACTION_MESSAGE
} from './constant'
import { ICompaniesListResponse } from './interface/response/companies-list'
import { ICompanyLogoUploadDetailInput, ICompanyLogoGetDetailInput } from './interface/input/company-logo'
import { ICompanyLogoUploadResponse, ICompanyLogoGetResponse } from './interface/response/company-logo'
import { ICompanyDetailCreateInput } from './interface/input/company-create'
import { ICompanyCreateResponse } from './interface/response/company-create'
import { ICompanyDetailsGetDetailsInput } from './interface/input/company-details'
import { ICompanyDetailsResponse } from './interface/response/company-details'
import { ICompanyDeleteInput } from './interface/input/company-delete'
import { ICompanyConfirmDeleteDetailsInput } from './interface/input/company-confirm-delete'
import { ICompanyConfirmDeleteResponse } from './interface/response/company-confirm-delete'
import { ICompanyUpdateDetailsInput } from './interface/input/company-update'
import { ICompanyUpdateResponse } from './interface/response/company-update'
import { ICompanyListDetailsInput } from './interface/input/company-list'

export const getCompaniesListAction = (payload: ICompanyListDetailsInput) => {
  return { type: GET_COMPANIES_LIST, ...payload }
}

export const getCompaniesListReturnAction = (payload: ICompaniesListResponse) => {
  return { type: GET_COMPANIES_LIST_RETURN, ...payload }
}

export const getCompaniesListErrorAction = (message: string) => {
  return { type: GET_COMPANIES_LIST_ERROR, message }
}

export const uploadCompanyLogoAction = (payload: ICompanyLogoUploadDetailInput) => {
  return { type: UPLOAD_COMPANY_LOGO, ...payload }
}

export const uploadCompanyLogoReturnAction = (payload: ICompanyLogoUploadResponse) => {
  return { type: UPLOAD_COMPANY_LOGO_RETURN, ...payload }
}

export const uploadCompanyLogoErrorAction = (message: string) => {
  return { type: UPLOAD_COMPANY_LOGO_ERROR, message }
}

export const createCompanyAction = (payload: ICompanyDetailCreateInput) => {
  return { type: CREATE_COMPANY, ...payload }
}

export const createCompanyReturnAction = (payload: ICompanyCreateResponse) => {
  return { type: CREATE_COMPANY_RETURN, ...payload }
}

export const createCompanyErrorAction = (message: string) => {
  return { type: CREATE_COMPANY_ERROR, message }
}

export const geCompanyLogoAction = (payload: ICompanyLogoGetDetailInput) => {
  return { type: GET_COMPANY_LOGO, ...payload }
}

export const geCompanyLogoReturnAction = (payload: ICompanyLogoGetResponse) => {
  return { type: GET_COMPANY_LOGO_RETURN, ...payload }
}

export const geCompanyLogoErrorAction = (message: string) => {
  return { type: GET_COMPANY_LOGO_ERROR, message }
}

export const clearCompanyResponseMessage = () => {
  return { type: CLEAR_COMPANY_RESPONSE_MESSAGE }
}

export const getCompanyDetailsAction = (payload: ICompanyDetailsGetDetailsInput) => {
  return { type: GET_COMPANY_DETAILS, ...payload }
}

export const getCompanyDetailsReturnAction = (payload: ICompanyDetailsResponse) => {
  return { type: GET_COMPANY_DETAILS_RETURN, ...payload }
}

export const getCompanyDetailsErrorAction = (message: string) => {
  return { type: GET_COMPANY_DETAILS_ERROR, message }
}

export const deleteCompanyAction = (payload: ICompanyDeleteInput) => {
  return { type: DELETE_COMPANY, ...payload }
}

export const deleteCompanyCancel = () => {
  return { type: DELETE_COMPANY_CANCEL }
}

export const confirmDeleteCompanyAction = (payload: ICompanyConfirmDeleteDetailsInput) => {
  return { type: DELETE_COMPANY_CONFIRM, ...payload }
}

export const confirmDeleteCompanyReturnAction = (payload: ICompanyConfirmDeleteResponse) => {
  return { type: DELETE_COMPANY_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteCompanyErrorAction = (message: string) => {
  return { type: DELETE_COMPANY_CONFIRM_ERROR, message }
}

export const updateCompanyAction = (payload: ICompanyUpdateDetailsInput) => {
  return { type: UPDATE_COMPANY, ...payload }
}

export const updateCompanyReturnAction = (payload: ICompanyUpdateResponse) => {
  return { type: UPDATE_COMPANY_RETURN, ...payload }
}

export const updateCompanyErrorAction = (message: string) => {
  return { type: UPDATE_COMPANY_ERROR, message }
}

export const clearCompanyActionMessage = () => {
  return { type: CLEAR_COMPANY_ACTION_MESSAGE }
}
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IFormStatus } from '../interface/form-status';
import { useSetStatusToStateAndRef } from './hooks/use-set-status-to-state-and-ref';

interface IStatusModal {
  selectedStatus: IFormStatus;
  deleteStatus(statusId: string, statusName: string): void;
  updateStatus(status: IFormStatus): void;
}

interface IStatusFlagConfig { final: boolean; finalPositive?: boolean };

const StatusModal: React.FC<IStatusModal> = (props) => {
  const initialError = {
    name: false
  }
  const initialStatusFlagConfig = { final: false, finalPositive: undefined }
  const statusNameRef = useRef(document.createElement('input'));

  const [error, setError] = useState(initialError);
  const [statusFlagConfig, setStatusFlagConfig] = useState<IStatusFlagConfig>(initialStatusFlagConfig);

  const {
    selectedStatusId,
  } = useSetStatusToStateAndRef({
    statusNameRef,
    selectedStatus: props.selectedStatus
  });

  const updateStatus = (config?: { final: boolean; finalPositive: boolean | undefined }) => {
    let newErrorState = { ...error };
    if (!statusNameRef.current.value) {
      return deleteStatus();
    }

    let updatedSelectedStatus = { ...props.selectedStatus };
    updatedSelectedStatus.name = statusNameRef.current.value.toUpperCase();
    updatedSelectedStatus.final = statusFlagConfig.final;
    updatedSelectedStatus.finalPositive = statusFlagConfig.finalPositive;
    props.updateStatus(updatedSelectedStatus);
    newErrorState.name = false;
    setError(newErrorState);
  }

  const deleteStatus = () => {
    let newErrorState = { ...error };
    newErrorState.name = false;
    setError(newErrorState);
    props.deleteStatus(selectedStatusId, props.selectedStatus.name)
  }

  useEffect(() => {
    if (props.selectedStatus) {
      const tempStatusFlagConfig = {
        final: props.selectedStatus.final || false,
        finalPositive: props.selectedStatus.finalPositive || undefined,
      };
      setStatusFlagConfig(tempStatusFlagConfig);
    }
  }, [props.selectedStatus]);

  return <>
    <div className={`${selectedStatusId ? 'hidden-container' : ''}`} onClick={() => updateStatus()}></div>
    <div id='status-modal' className={`status-modal-container ${selectedStatusId ? '' : 'hidden'}`}>
      <input type='text' placeholder='Type Status name...' className={`${error.name ? 'error' : ''}`} ref={statusNameRef}
        onKeyDown={(event) => { if (event.key === 'Enter') { updateStatus() } }} />
      <div className={`${props.selectedStatus.initial !== true ? '' : 'hidden'}`}>
        <div
          className={`ui checkbox`}
          onClick={() => {
            const checked = statusFlagConfig.final && statusFlagConfig.finalPositive;
            const status = {
              final: !checked ? true : false,
              finalPositive: !checked ? true : undefined,
            }
            setStatusFlagConfig(status);
          }}>
          <input className="hidden" type="checkbox" onChange={() => { }}
            checked={statusFlagConfig.final && statusFlagConfig.finalPositive} />
          <label> (+) End status</label>
        </div>
        <div
          className={`ui checkbox`}
          onClick={() => {
            const checked = statusFlagConfig.final && !statusFlagConfig.finalPositive;
            const status = {
              final: !checked ? true : false,
              finalPositive: !checked ? false : undefined,
            }
            setStatusFlagConfig(status);
          }}>
          <input className="hidden" type="checkbox" onChange={() => { }}
            checked={statusFlagConfig.final && !statusFlagConfig.finalPositive} />
          <label> (-) End status</label>
        </div>
        <Button onClick={() => deleteStatus()}>
          <i className='trash icon alternate outline'
            title='Delete'
          ></i>
          Delete Status
        </Button>
      </div>
    </div>
  </>
}

export default StatusModal;
import { FieldElement } from '../../component/field-element';
import { errorMessages } from '../error';
import { FieldValidator } from './field-validator';
import Ajv from 'ajv';

export class DateTimeValidator extends FieldValidator {

  name = 'date-time';

  validate(field: FieldElement, value: string, actionType?: string) {
    const { multiline } = { ...field } as any;

    if (((value === '' || value === 'undefined' || value === 'Invalid date')
      && (field.accessType !== 'required' || (field.accessType === 'required' && actionType === 'save')))
      || field.accessType === 'readonly'
      || field.accessType === 'hidden' || field.accessType !== 'required') {
      return [];
    }

    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: multiline ? [value] : value });
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}
import React from 'react';
import { useDrag, DragSourceMonitor, useDrop, DragObjectWithType } from 'react-dnd';

interface IDataTableSelectedGroupings {
  selectedOption: any;
  changeListSequence(item: DragObjectWithType, value: any): void;
  selectedOptionIndex: number;
  onGroupedDTItemAction: (value: string, action: 'add' | 'delete') => void;
}

const DataTableSelectedGroupings: React.FC<IDataTableSelectedGroupings> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'selectedOption',
    drop: selectedItem => {
      props.changeListSequence(selectedItem, props.selectedOption.value)
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'selectedOption', data: props.selectedOption },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });
  return <>
    <div
      key={`list-${props.selectedOption.key}`}
      className="selectedOption"
      ref={dropRef}
      style={{
        opacity: draggedObject.opacity,
      }}
    >
      <div ref={dragRef}>
        <span className="ui label dt-grouped-item">
          {props.selectedOption.text}
          <i aria-hidden="true" className="delete icon" onClick={() => {
            props.onGroupedDTItemAction(props.selectedOption.value, 'delete');
          }} />
        </span>
      </div>
    </div>
  </>
}

export default DataTableSelectedGroupings;
import React from 'react';
import DropzoneComponent from '../../../../../../../common/general/dropzone';
import { IImportedFiles } from '../interface/file-modal';

interface Props {
  setFiles: any;
  files: any;
  setFilesToImport: any;
  filesToImport: any;
  importedFiles: IImportedFiles[];
}

const FileImport: React.FC<Props> = (props) => {
  const onSelect = (value: string, isSelected: boolean) => {
    const tempSelection = [...props.filesToImport];
    if (!isSelected) {
      tempSelection.push(value);
    } else {
      tempSelection.splice(tempSelection.indexOf(value), 1)
    }
    props.setFilesToImport(tempSelection)
  }

  const { importedFiles = [] } = { ...props };
  const bp = importedFiles.filter((e: IImportedFiles) => { return e.type === 'form' });
  const dt = importedFiles.filter((e: IImportedFiles) => { return e.type === 'datatable' });

  return (
    <>
      <div className="file-to-import-container">
        <div className="file-to-import-label"><span className="file-to-import-label">Load File</span></div>
        <DropzoneComponent setFiles={(file: any) => props.setFiles(file)} files={props.files} accept=".zip" />
        <div className="file-to-import">
          <div className="file-to-import-label">
            <span>BP and DT to import</span>
            <span>Skip</span>
          </div>
          <div className="file-to-import-list">
            {bp.length > 0 &&
              <>
                <div className="file-to-import-label"><span>BP</span></div>
                {
                  bp.map((e: IImportedFiles, idx: number) => {
                    return (
                      <div key={idx}>
                        <div className="checkbox-container">
                          <label>{e.name}</label>
                          <div className="ui checkbox" onClick={() => onSelect(e.id || '', props.filesToImport.includes(e.id || ''))}>
                            <input type="checkbox" className="hidden"
                              checked={props.filesToImport.includes(e.id || '')}
                            />
                            <label></label>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </>
            }
            {dt.length > 0 &&
              <>
                <div className="file-to-import-label"><span>DT</span></div>
                {
                  dt.map((e: IImportedFiles, idx: number) => {
                    return (
                      <div key={idx}>
                        <div className="checkbox-container">
                          <label>{e.name}</label>
                          <div className="ui checkbox" onClick={() => onSelect(e.id || '', props.filesToImport.includes(e.id || ''))}>
                            <input type="checkbox" className="hidden"
                              checked={props.filesToImport.includes(e.id || '')}
                            />
                            <label></label>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default FileImport;

import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFormula } from '../../../../../../../common/custom-field-config/interface/formula';
import { IConfigName } from '../../../../../../../common/field/type/interface/field-type-object';
import { SanitizeField } from '../../../../../../../common/utils/sanitize-field';
import { ITableWidgetConfig } from '../field-container/section-mobile';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../interface/section';

export class SanitizeSectionList {
  static setFormulaIdxToSplice = (
    currentFieldList: ICustomFieldDetail[],
    formulaList: IFormula[]) => {
    const indexToSplice = [] as number[];

    formulaList.forEach((data, index) => {
      if (data.type === 'field' && typeof data.value === 'string') {
        let [mainId, subId]: any = data.value.split('.');
        const exist = currentFieldList.find(e => e.id === mainId);
        if (!exist) {
          indexToSplice.push(index);
          const prevItem = formulaList.find((e, i) => i === (index - 1) && e.type === 'operator');
          const nextItem = formulaList.find((e, i) => i === (index + 1) && e.type === 'operator');

          if (!prevItem && nextItem) {
            indexToSplice.push(formulaList.indexOf(nextItem));
          } else if (prevItem && !nextItem) {
            indexToSplice.push(formulaList.indexOf(prevItem));
          } else if (prevItem && nextItem) {
            indexToSplice.push(formulaList.indexOf(nextItem));
          } else if (prevItem && nextItem && prevItem.value === '(' && nextItem.value === ')') {
            indexToSplice.push(formulaList.indexOf(nextItem));
            indexToSplice.push(formulaList.indexOf(prevItem));
          }
        } else {
          if (exist.configName === IConfigName.Table) {
            const { config } = exist;
            if (config && config.columns) {
              const columnExist = config.columns.find(e => e.id === subId);
              if (!columnExist) {
                indexToSplice.push(index);
                const prevItem = formulaList.find((e, i) => i === (index - 1) && e.type === 'operator');
                const nextItem = formulaList.find((e, i) => i === (index + 1) && e.type === 'operator');

                if (!prevItem && nextItem) {
                  indexToSplice.push(formulaList.indexOf(nextItem));
                } else if (prevItem && !nextItem) {
                  indexToSplice.push(formulaList.indexOf(prevItem));
                } else if (prevItem && nextItem) {
                  indexToSplice.push(formulaList.indexOf(nextItem));
                } else if (prevItem && nextItem && prevItem.value === '(' && nextItem.value === ')') {
                  indexToSplice.push(formulaList.indexOf(nextItem));
                  indexToSplice.push(formulaList.indexOf(prevItem));
                }
              }
            }
          }
        }
      }
    });
    return indexToSplice;
  }

  static getFieldListFromSections = (sectionList: IFormBuilderSection[], includeSectionId?: boolean) => {
    const fieldList = [] as ICustomFieldDetail[];
    sectionList.forEach((section: IFormBuilderSection) => {
      section.fields.forEach((fields: TFormBuilderSectionRow) => {
        if (fields) {
          fields.forEach((field: ICustomFieldDetail) => {
            const exist = fieldList.find(e => e.id === field.id);
            if (!exist && !field.deleted) {
              if (includeSectionId) {
                fieldList.push({ ...field, sectionId: section.sectionId });
              } else {
                fieldList.push(field);
              }
            }
          })
        }
      })
    });
    return fieldList;
  }

  static structureOutgoing = (sectionList: IFormBuilderSection[], tableWidgetConfig: {
    [id: string]: ITableWidgetConfig;
  }) => {
    const currentFieldList = SanitizeSectionList.getFieldListFromSections(sectionList);
    return sectionList.map((section: IFormBuilderSection) => {
      section.fields = section.fields.map((fields: TFormBuilderSectionRow) => {
        if (fields) {
          fields = fields.map((field: ICustomFieldDetail) => {
            if (field.configName === IConfigName.FX && field.config) {
              const { formula } = { ...field.config };
              if (formula && formula.length > 0) {
                let tempFormula = [...formula];
                const IdxToSplice = SanitizeSectionList.setFormulaIdxToSplice(currentFieldList, formula);
                if (IdxToSplice.length) {
                  tempFormula = tempFormula.filter((data, idx) => IdxToSplice.indexOf(idx) === -1);
                }
                if (tempFormula.length === 0) {
                  tempFormula = [{
                    label: 'Value',
                    type: 'value',
                    value: 0
                  }]
                }
                field.config.formula = tempFormula;
              }
            }
            if (field.configName === IConfigName.Table && field.config) {
              const mobileConfig = tableWidgetConfig[field.id || ''];
              if (mobileConfig) {
                field.mobileConfig = SanitizeSectionList.getMobileConfig(mobileConfig);
              }
            }
            return field;
          })
        }
        return fields;
      })
      return section;
    });
  }

  static getMobileConfig = (mobileConfig: ITableWidgetConfig) => {
    const config = SanitizeField.structureWidgetConfigToMobileConfig(
      mobileConfig.tableMobileView,
      mobileConfig.extraFields,
      mobileConfig.enabled || false,
    );
    const { items, enabled } = config.widget;
    if (!items.length && !enabled) {
      return null;
    }
    return config;
  }
}
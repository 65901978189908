import React from 'react';
import { IRender } from '.';

class FileFieldValue {

  name = 'file';

  Render: React.FC<IRender> = (props) => {
    if (props.forwardedRef.current[props.valueId]) props.forwardedRef.current[props.valueId].value = 'Empty';
    return (
      <div>
        Empty
      </div>
    );
  }
}

export default FileFieldValue;

import React from 'react';
import { IRender } from '.';
import { IField } from '../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../field/component/field-element';

class DateTimeFieldValue {

  name = 'date-time';

  Render: React.FC<IRender> = (props) => {
    const tempField: IField = {
      id: props.valueId,
      inputConfig: {
        type: 'date-time',
        config: {},
      },
      label: props.field.label,
    };
    const field = FieldElement.getDataElement(tempField);
    const Element = field.getFieldElement();
    return (
      <div>
        <Element
          {...field}
          forwardedRef={props.forwardedRef}
          defaultValueElement={true}
          throwValueOutside={props.throwValueOutside}
        />
      </div>
    );
  }
}

export default DateTimeFieldValue;

import { call, put, takeLatest } from 'redux-saga/effects';
import { IFormFieldGetInput } from './interface/input/form-field-get';
import { GET_FORM_FIELD_LIST, SAVE_DRAFT_CUSTOM_FILTER, PUBLISH_CUSTOM_FILTER, GET_CUSTOM_FILTER_LIST } from './constant';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { getFormFieldListReturnAction, saveDraftCustomFilterReturnAction, publishCustomFilterReturnAction, publishCustomFilterErrorAction, getCustomFilterListReturnAction, saveDraftCustomFilterErrorAction } from './action';
import { getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../../../common/api-request';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ICustomFilterSaveInput, ICustomFilterPublishInput } from './interface/input/custom-filter-save';
import { SanitizeCustomFilter } from '../../../../../../../../common/utils/sanitize-custom-filter';
import { ICustomFilterGetInput } from './interface/input/custom-filter-get';

export function* getFormFieldList(args: IFormFieldGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    yield put(getFormFieldListReturnAction({
      fieldList: req.data.data as ICustomFieldDetail[],
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getCustomFilterList(args: ICustomFilterGetInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/filters?draft=true`, {});
    yield put(getCustomFilterListReturnAction({
      customFilterList: SanitizeCustomFilter.structureIncoming(req.data.data),
      customFilterListAPI: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* saveDraftCustomFilter(args: ICustomFilterSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    const data = args.sanitize ? SanitizeCustomFilter.structureOutgoing(args.customFilterList) : args.customFilterList
    const req: IAxiosResponse = yield call(putRequest, `${company}/forms/${formId}/filters`, data);
    yield put(saveDraftCustomFilterReturnAction({
      customFilterList: SanitizeCustomFilter.structureIncoming(req.data.data),
      customFilterSaveType: args.saveType
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(saveDraftCustomFilterErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* publishCustomFilter(args: ICustomFilterPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishCustomFilterReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishCustomFilterErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* formBuilderSagas() {
  yield takeLatest(GET_FORM_FIELD_LIST, getFormFieldList);
  yield takeLatest(SAVE_DRAFT_CUSTOM_FILTER, saveDraftCustomFilter);
  yield takeLatest(PUBLISH_CUSTOM_FILTER, publishCustomFilter);
  yield takeLatest(GET_CUSTOM_FILTER_LIST, getCustomFilterList);
}

// All sagas to be loaded
export default formBuilderSagas;
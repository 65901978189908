import { IFormBuilderSection, TFormBuilderSectionRow } from '../../component/admin-module/module/business-process/module/form-builder/interface/section';
import { ICustomFieldDetail } from '../custom-field-config/interface/custom-field-detail';
import { IFieldsLayout, IFieldLayoutObject } from '../../component/admin-module/module/business-process/module/form/interface/form';

export class SanitizeFormBuilder {
  static outgoingSectionFieldLayout = (data: IFormBuilderSection[]): IFieldsLayout[] => {
    const outgoingFieldLayoutData: IFieldsLayout[] = [];

    data.forEach((section: IFormBuilderSection) => {
      const bodyFields: IFieldLayoutObject[] = [];
      section.fields.forEach((fields: TFormBuilderSectionRow) => {
        const removeDeletedField = fields ? fields.filter((field: ICustomFieldDetail) => field.deleted !== true) : [];
        if (removeDeletedField.length > 0) {
          const fieldList: string[] = [];
          removeDeletedField.forEach((field: ICustomFieldDetail) => {
            fieldList.push(field.id || '');
          })
          bodyFields.push({ fields: fieldList });
        }
      })

      if (section.style === 'grouped' || bodyFields.length > 0) {
        outgoingFieldLayoutData.push({
          id: section.sectionId || '',
          title: section.title,
          collapsable: section.collapsable,
          description: section.additionalInfo,
          header: { fields: section.titleFieldToShow },
          body: bodyFields,
          style: section.style,
          defaultView: section.defaultView,
          pinned: section.pinned
        })
      }
    });
    return outgoingFieldLayoutData;
  }
  static outgoingCustomField = (data: IFormBuilderSection[]): ICustomFieldDetail[] => {
    const fieldList: ICustomFieldDetail[] = [];
    data.forEach((section: IFormBuilderSection) => {
      section.fields.forEach((fields: TFormBuilderSectionRow) => {
        fields.forEach((field: ICustomFieldDetail) => {
          if (field.deleted !== true) {
            fieldList.push(field);
          }
        })
      })
    })
    return fieldList;
  }
}
import React, { useState, useEffect } from 'react';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { FieldProperties } from '../../../service/field-properties.service';
import { BooleanProps } from '../../../service/type/boolean';
import { AcceptedProps } from '../../interface/accepted-props';

const SwitchType: React.FC<AcceptedProps> = (props) => {
  const name = props.getFieldId();

  const [refresher, setRefresher] = useState(0)
  const elementProperties = new FieldProperties(name, props.forwardedRef, new BooleanProps());

  if (!elementProperties.isSet()) {
    elementProperties.setCurrent({ value: props.defaultValue && !props.defaultValueElement ? true : false })
  }

  const handleOnClick = () => {
    elementProperties.setCurrent({ value: !elementProperties.current.value });
    setRefresher(refresher + 1)
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, elementProperties.current.value)
      }
    }
  }

  const setDefaultValue = () => {
    if (elementProperties.isSet()) {
      return;
    }
    let defaultValue = props.forwardedRef.current[name] && props.defaultValue ?
      props.defaultValue : false;
    if (defaultValue && !props.defaultValueElement) {
      elementProperties.setCurrent({ value: defaultValue ? true : false })
    }
  }

  useEffect(() => {
    if (props.isAddRecord) {
      setDefaultValue();;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <div className="ui fitted toggle checkbox" onClick={props.accessType === IFieldAccessType.Readonly ? () => { } : handleOnClick}>
          <input className="hidden" type="checkbox" onChange={() => { }}
            checked={elementProperties.current.value}
            disabled={props.accessType === IFieldAccessType.Readonly}
          />
          <label></label>
        </div>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
      </>
    }
  </>
}

export default SwitchType;
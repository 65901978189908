import React from 'react';
import SectionTitle from './section-title';
import SectionBody from './section-body';
import DragDrop from './section-drag-drop';
import DragSection from './drag-section'
import { IFormBuilderSection } from '../../interface/section';
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd';
import { DragFieldObject, DragSectionObject } from '../../section-field-manager/interface/section-update-config';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ISection {
  section: IFormBuilderSection;
  changeSectionSequence(dragItem: DragSectionObject, dropContainerId: string): void;
  addFieldToSection(dragItem: DragFieldObject, sectionId: string): void;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  openSettings(selectedSection: IFormBuilderSection): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const Section: React.FC<ISection> = (props) => {
  const [isCollapse, setIsCollapse] = React.useState(true);

  const onToggleCollapse = () => setIsCollapse(!isCollapse);

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'section',
    drop: (item: any) => {
      props.changeSectionSequence(item, props.section.sectionId || '')
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'section', data: props.section },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  return <>
    <div ref={dropRef}
      style={{
        opacity: draggedObject.opacity,
      }}
      className="section"
    >
      <div ref={dragRef}>
        <DragSection section={props.section} dragRef={dragRef} />

        <SectionTitle
          isOpen={isCollapse}
          toggleCollapse={onToggleCollapse}
          openSettings={props.openSettings}
          section={props.section}
        />
        {isCollapse &&
          <>
            <SectionBody
              section={props.section}
              addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
              editFieldConfig={props.editFieldConfig}
              openDeleteConfirmationModal={props.openDeleteConfirmationModal}
              unlinkField={props.unlinkField}
              unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
              sortFieldSection={props.sortFieldSection}
              moveField={props.moveField}
              bpImage={props.bpImage}
            />
            <DragDrop section={props.section} addFieldToSection={props.addFieldToSection} />
          </>
        }
      </div>
    </div>

  </>
}

export default Section;

import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { ISubMenuList } from './interface/sub-menu-list';
import { ISubMenuListProp } from './interface/sub-menu-list-props';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import changeRoute from '../../../../../../../common/utils/change-menu';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  submodule: string;
  formId: string;
}

export class SubMenuList implements ISubMenuList {

  name = 'configuration';

  Render: React.FC<ISubMenuListProp> = (props) => {
    const { hasPageChange } = useSelector((state: IRootState) => state.main);
    const match: IRouteMatch = useRouteMatch();
    const dispatch = useDispatch();

    const arrow = props.collapse ? 'angle right icon' : 'angle left icon';

    const showMenuOnHover = (hover: boolean) => {
      const routeContainer = document.getElementById('configuration-route-container');
      const route = document.getElementById('configuration-route');
      const routeMain = document.getElementById('configuration-route-main');

      if (routeContainer && route && routeMain) {
        if (hover) {
          routeContainer.style.setProperty('width', '210px');
          routeMain.style.setProperty('padding', '15px');
          route.style.setProperty('display', 'flex');
          route.style.setProperty('flex-direction', 'column');
        } else {
          routeContainer.style.setProperty('width', '30px');
          route.style.removeProperty('display');
          route.style.removeProperty('flex-direction');
          routeMain.style.setProperty('padding', '0px');
        }
      }
    }

    const handleLinkClick = (e: any, url: string) => {
      changeRoute(url, hasPageChange || props.hasPageChangeRef.current.hasChange, { history: props.history, dispatch }, e);
    }

    return <>
      <div id='configuration-route-container' className={props.collapse ? 'collapse' : 'expand'}>
        <div className='configuration-icon-container'>
          <div className='configuration-icon'
            onClick={() => {
              if (props.setCollapse) {
                props.setCollapse(!props.collapse)
              }
            }}>
            <i className={arrow} />
          </div>
        </div>
        <div id='configuration-route-main' className={`configuration-route-main`}
          onMouseLeave={() => {
            if (props.collapse) {
              showMenuOnHover(false);
            }
          }}
          onMouseOver={() => {
            if (props.collapse) {
              showMenuOnHover(true);
            }
          }}
        >
          <div id='configuration-route' className={`configuration-route`}>
            <div className='ui left icon input'>
              <input type="text" placeholder="Search" />
              <i aria-hidden="true" className="search icon" />
            </div>
            {
              props.modules.map(({ id, name }, key) => {
                return (
                  <React.Fragment key={key}>
                    <Link to={`/admin/${match.params.submodule}/${this.name}/${match.params.formId}/${id}`}
                      onClick={(event) => handleLinkClick(event, `/admin/${match.params.submodule}/${this.name}/${match.params.formId}/${id}`)}
                      onContextMenu={(event) => handleLinkClick(event, `/admin/${match.params.submodule}/${this.name}/${match.params.formId}/${id}`)}>
                      <Button className={`configuration-sub-menu ${id.toLowerCase() === props.main.fourthLevelRoute.toLowerCase() ? 'configuration-active-subroute' : ''}`}>
                        {name}
                      </Button>
                    </Link>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  }
}

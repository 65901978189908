import { IModule } from '../../../admin-module/utils/header-menu-button/interface/module';

export const bpPortalSubModules: IModule[] = [
  {
    id: 'stakeholders-view',
    name: 'Stakeholders View',
    route_label: 'stakeholders-view'
  },
  {
    id: 'form-view',
    name: 'Form View',
    route_label: 'form-view'
  },
  {
    id: 'permissions',
    name: 'Permissions',
    route_label: 'permissions'
  },
  {
    id: 'list-view',
    name: 'List View',
    route_label: 'list-view'
  }
]
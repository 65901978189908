import React from 'react';
import { Icon, Image } from 'semantic-ui-react-bpm';
import { IFormBuilderSection } from '../../interface/section';
import ToolTip from '../../../../../../../../common/general/tooltip';
import { icons } from '../../../../../../../../common/icons';
import { useSetExpandCollapseIcon } from '../hooks/use-set-expand-collapse-icon';

interface ISectionTitle {
  isOpen: boolean;
  toggleCollapse(): void;
  openSettings(selectedSection: IFormBuilderSection): void;
  section: IFormBuilderSection
}
const SectionTitle: React.FC<ISectionTitle> = (props) => {
  const { icon } = useSetExpandCollapseIcon({ isOpen: props.isOpen });

  return <div className="section-title" >
    {`${props.section.title ? props.section.title : 'Untitled Section'}`}
    <ToolTip position='bottom right'
      popupMessage='Section Settings'
      popupTriggerComponent={
        <Image src={icons.blue.cog}
          style={{
            width: '15px',
            cursor: 'pointer',
            margin: '2px 5px',
            display: 'inline-block'
          }} className='settings-icon'
          onClick={() => { props.openSettings(props.section) }}
          name='cog' size='small' />
      }
    />
    <Icon link className='collapse-icon'
      onClick={() => { props.toggleCollapse() }}
      name={icon} size='small' />
  </div>
}

export default SectionTitle;

import { IBackupItem } from './interface/export-backup';
import {
  GET_BUSINESS_PROCESS_LIST,
  GET_BUSINESS_PROCESS_LIST_RETURN,
  GET_BUSINESS_PROCESS_LIST_ERROR,
  CREATE_BUSINESS_PROCESS,
  CREATE_BUSINESS_PROCESS_RETURN,
  CREATE_BUSINESS_PROCESS_ERROR,
  GET_BUSINESS_PROCESS,
  GET_BUSINESS_PROCESS_RETURN,
  GET_BUSINESS_PROCESS_ERROR,
  UPDATE_BUSINESS_PROCESS,
  UPDATE_BUSINESS_PROCESS_RETURN,
  UPDATE_BUSINESS_PROCESS_ERROR,
  CLEAR_BUSINESS_PROCESS_MESSAGE,
  DELETE_BUSINESS_PROCESS,
  DELETE_BUSINESS_PROCESS_CONFIRM,
  DELETE_BUSINESS_PROCESS_CANCEL,
  DELETE_BUSINESS_PROCESS_CONFIRM_RETURN,
  DELETE_BUSINESS_PROCESS_CONFIRM_ERROR,
  CLEAR_DELETE_BUSINESS_MESSAGE,
  UPLOAD_LOGO,
  UPLOAD_LOGO_RETURN,
  UPLOAD_LOGO_ERROR,
  GET_FORM_LOGO,
  GET_FORM_LOGO_RETURN,
  GET_FORM_LOGO_ERROR,
  RESET_FORM_LOGO,
  IMPORT_EXPORT_BP_DT_ERROR,
  IMPORT_EXPORT_BP_DT_SUCCESS,
  CLEAR_IMPORT_EXPORT_BP_DT_MESSAGE,
  SET_EXPORT_BACKUP_ITEM,
  GET_BACKUP_REFS_RETURN,
  GET_BACKUP_REFS,
  GET_BACKUP_REFS_ERROR,
  CLEAR_EXPORT_BACKUP_ITEM,
  SET_SELECT_ALL_BP_DT,
  DEACTIVATE_BUSINESS_PROCESS,
  DEACTIVATE_BUSINESS_PROCESS_RETURN
} from './constant';
import { IFormGetResponse, IFormGetDataResponse } from './interface/response/form-get';
import { IFormGetListDetailInput, IFormDataDetailInput } from './interface/input/form-get';
import { IFormCreateDetailInput } from './interface/input/form-create';
import { IFormCreateResponse } from './interface/response/form-create';
import { IFormUpdateDetailInput, IFormDeactivateDetailInput } from './interface/input/form-update';
import { IFormUpdateResponse, IFormDeactivateResponse } from './interface/response/form-update';
import { IFormDeleteInput } from './interface/input/form-delete';
import { IFormConfirmDeleteDetailInput } from './interface/input/form-confirm-delete';
import { ILogoUploadDetailInput } from './interface/input/logo-upload';
import { ILogoUploadResponse } from './interface/response/logo-upload';
import { IFormLogoGetDetailInput } from './interface/input/form-logo-get';
import { IFormLogoGetResponse } from './interface/response/form-logo-get';
import { IBackupRefsGetReponse } from './interface/response/backup-refs';
import { IBackupRefsDetailInput } from './interface/input/backup-refs';

export const getBusinessProcessListAction = (payload: IFormGetListDetailInput) => {
  return { type: GET_BUSINESS_PROCESS_LIST, ...payload }
}

export const getBusinessProcessListReturnAction = (payload: IFormGetResponse) => {
  return { type: GET_BUSINESS_PROCESS_LIST_RETURN, ...payload }
}

export const getBusinessProcessListErrorAction = () => {
  return { type: GET_BUSINESS_PROCESS_LIST_ERROR }
}

export const createBusinessProcessAction = (payload: IFormCreateDetailInput) => {
  return { type: CREATE_BUSINESS_PROCESS, ...payload }
}

export const createBusinessProcessReturnAction = (payload: IFormCreateResponse) => {
  return { type: CREATE_BUSINESS_PROCESS_RETURN, ...payload }
}

export const createBusinessProcessErrorAction = (message: string) => {
  return { type: CREATE_BUSINESS_PROCESS_ERROR, message }
}

export const getBusinessProcessAction = (payload: IFormDataDetailInput) => {
  return { type: GET_BUSINESS_PROCESS, ...payload }
}

export const getBusinessProcessReturnAction = (payload: IFormGetDataResponse) => {
  return { type: GET_BUSINESS_PROCESS_RETURN, ...payload }
}

export const getBusinessProcessErrorAction = (message?: string) => {
  return { type: GET_BUSINESS_PROCESS_ERROR, message }
}

export const updateBusinessProcessAction = (payload: IFormUpdateDetailInput) => {
  return { type: UPDATE_BUSINESS_PROCESS, ...payload }
}

export const updateBusinessProcessReturnAction = (payload: IFormUpdateResponse) => {
  return { type: UPDATE_BUSINESS_PROCESS_RETURN, ...payload }
}

export const updateBusinessProcessErrorAction = (message: string) => {
  return { type: UPDATE_BUSINESS_PROCESS_ERROR, message }
}

export const clearBusinessProcessMessageAction = (messageName: string) => {
  return { type: CLEAR_BUSINESS_PROCESS_MESSAGE, messageName }
}

export const deleteBusinessProcessAction = (payload: IFormDeleteInput) => {
  return { type: DELETE_BUSINESS_PROCESS, ...payload }
}

export const confirmDeleteBusinessProcessAction = (payload: IFormConfirmDeleteDetailInput) => {
  return { type: DELETE_BUSINESS_PROCESS_CONFIRM, ...payload }
}

export const cancelDeleteBusinessProcessAction = () => {
  return { type: DELETE_BUSINESS_PROCESS_CANCEL }
}

export const confirmDeleteBusinessProcessReturnAction = (payload: any) => {
  return { type: DELETE_BUSINESS_PROCESS_CONFIRM_RETURN, ...payload }
}

export const confirmDeleteBusinessProcessErrorAction = (message: string) => {
  return { type: DELETE_BUSINESS_PROCESS_CONFIRM_ERROR, message }
}

export const clearDeleteBusinessProcessMessage = () => {
  return { type: CLEAR_DELETE_BUSINESS_MESSAGE }
}

export const uploadLogoAction = (payload: ILogoUploadDetailInput) => {
  return { type: UPLOAD_LOGO, ...payload }
}

export const uploadLogoReturnAction = (payload: ILogoUploadResponse) => {
  return { type: UPLOAD_LOGO_RETURN, ...payload }
}

export const uploadLogoErrorAction = (message: string) => {
  return { type: UPLOAD_LOGO_ERROR, message }
}

export const getFormLogoAction = (payload: IFormLogoGetDetailInput) => {
  return { type: GET_FORM_LOGO, ...payload }
}

export const getFormLogoReturnAction = (payload: IFormLogoGetResponse) => {
  return { type: GET_FORM_LOGO_RETURN, ...payload }
}

export const getFormLogoErrorAction = (message: string) => {
  return { type: GET_FORM_LOGO_ERROR, message }
}

export const resetFormLogoAction = () => {
  return { type: RESET_FORM_LOGO }
}

export const importExportBPDTSuccessAction = (message: string) => {
  return { type: IMPORT_EXPORT_BP_DT_SUCCESS, message }
}

export const importExportBPDTErrorAction = (message: string) => {
  return { type: IMPORT_EXPORT_BP_DT_ERROR, message }
}

export const clearImportExportMessage = () => {
  return { type: CLEAR_IMPORT_EXPORT_BP_DT_MESSAGE }
}

export const setExportBackupItem = (payload: IBackupItem[]) => {
  return { type: SET_EXPORT_BACKUP_ITEM, selectedBackupItem: payload }
}

export const getBackupRefsAction = (payload: IBackupRefsDetailInput) => {
  return { type: GET_BACKUP_REFS, ...payload }
}

export const getBackupRefsReturnAction = (payload: IBackupRefsGetReponse) => {
  return { type: GET_BACKUP_REFS_RETURN, ...payload }
}

export const getBackupRefsErrorAction = (message: string) => {
  return { type: GET_BACKUP_REFS_ERROR, message }
}

export const clearExportBackupItem = () => {
  return { type: CLEAR_EXPORT_BACKUP_ITEM }
}

export const setSelectAllBPDT = (payload: any) => {
  return { type: SET_SELECT_ALL_BP_DT, selectAll: { ...payload } }
}

export const deactivateBusinessProcessAction = (payload: IFormDeactivateDetailInput) => {
  return { type: DEACTIVATE_BUSINESS_PROCESS, ...payload }
}

export const deactivateBusinessProcessReturnAction = (payload: IFormDeactivateResponse) => {
  return { type: DEACTIVATE_BUSINESS_PROCESS_RETURN, ...payload }
}
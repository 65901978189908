import { IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import ValidateField from '../../../validation';
import { IValidationError } from '../../../validation/type/validation-type';
import { FieldElement } from '../../field-element';
import { TValidationType, PropType } from '../field-properties.handler';

interface IDateProps {
  value: string;
  error?: IValidationError[];
  fieldType?: string;
}
export class DateProps extends PropType<IDateProps, IDateProps> {

  processValueFromRef(props: IDateProps): IDateProps {
    props.value = props.value || '';
    return props;
  }

  processValueToRef(newProps: IDateProps): IDateProps {
    newProps.value = newProps.value || '';
    return newProps;
  }

  processValidation(data: IDateProps | null, field: FieldElement, validationType: TValidationType): IValidationError[] {
    const value = data?.value || '';
    const validateField = new ValidateField();
    const validate = validateField.singleField(field, value || '', validationType);
    return field.accessType === IFieldAccessType.Required || !!value ? validate : [];
  }
}
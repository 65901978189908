import React from 'react';
import { v4 as uuid } from 'uuid';
import { FieldElement } from '../component/field-element';
import { IFieldData } from './interface/field-data';
import { IInputConfig } from '../../../component/admin-module/module/users/interface/field';
import { addThousandSeparator } from '../../utils/add-thousand-separator';
import { IConfigName } from '../type/interface/field-type-object';

interface IIntegerField {
  data: IFieldData;
  detail: FieldElement;
  overflowed?: boolean;
}

const IntegerCustomField: React.FC<IIntegerField> = (props) => {
  if (!props.data) {
    return <div></div>
  }
  let value = props.data && props.data.fields && typeof props.data.fields[props.detail.id] !== 'undefined' ? props.data.fields[props.detail.id] : '';
  if (!Array.isArray(value)) {
    value = addThousandSeparator(value, props.detail.inputConfig as IInputConfig);
  }

  let textAlign = props.detail.inputConfig.config.alignment || 'left';
  if (props.detail.configName === IConfigName.Seq) {
    textAlign = 'center';
  }

  if (value && typeof value === 'object') {
    return value.length > 0 && value.map((values: number, index: number) => {
      return (
        <div
          style={{ textAlign: textAlign }}
          key={`array-${props.detail.id}-${props.data.id}-${uuid()}`}>
          {values}
          {(value.length > 1 && index < value.length - 1) ? ',' : ''}
        </div>
      )
    })
  }

  // if (props.overflowed) {
  //   const component = <div style={{ textAlign: textAlign }}
  //     key={`column-${props.detail.id}-${props.data.id}-${uuid()}`}>
  //     {value}
  //   </div>
  //   return <Popup position='top center' trigger={component}>{component}</Popup>
  // }

  return (
    <div
      style={{ textAlign: textAlign }}
      key={`column-${props.detail.id}-${props.data.id}-${uuid()}`}>
      <span id={`value-${props.detail.id}-${props.data.id}`}>{value}</span>
    </div>
  )
}

export default IntegerCustomField;
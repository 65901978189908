import React from 'react';
import ActionConfiguration from './actions';
import DefaultFrontendAutomation from './default';

const ConfigList: any[] = [
  new ActionConfiguration()
]

interface IRender {
  type: string;
}

interface IFrontendAutomationRegistry {
  name: string;
  render: React.FC<any>
}

export class FrontendAutomationModuleRegistry {

  registry: { [name: string]: IFrontendAutomationRegistry } = {};

  constructor() {
    const types = ConfigList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : DefaultFrontendAutomation;
  }

  static Render: React.FC<IRender> = (props) => {
    const module = new FrontendAutomationModuleRegistry();
    const Render = module.getModule(props.type);
    return <Render {...props} />
  }

}
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';

interface IDetailActionButton {
  saveAutomation(): void;
  automationId: string;
}

export const DetailActionButton = ({ saveAutomation, automationId }: IDetailActionButton) => {
  return <div className={`automation-action-container`}>
    <div>
      <Button
        id={automationId}
        color='green'
        className='btn-action'
        onClick={() => saveAutomation()} >
        {'Save current component'}
      </Button>
    </div>
  </div>
}
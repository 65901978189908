import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './automatic-logout.scss';
import { triggerAutomaticLogoutAction, triggerLogoutAction } from '../action/logout';
import IdleTimer from './idle-timer';
import { IRootState } from '../../../reducers';
import { ConfirmationMessage } from '../../../common/general/confirmation-modal';

const LogoutPopup: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: IRootState) => state.auth);

  const handleModalOnClose = () => {
    dispatch(triggerAutomaticLogoutAction({ automaticLogoutModal: false }));
  };

  const handleLogout = () => {
    dispatch(triggerAutomaticLogoutAction({ automaticLogoutModal: false }));
    dispatch(triggerLogoutAction({ logoutMessageModal: true }));
  };

  return (
    <>
      <IdleTimer handleLogout={handleLogout} />
      <ConfirmationMessage
        errorMessage={''}
        confirmButtonName={'Logout'}
        cancelButtonName={'Continue'}
        confirmButtonColor={'red'}
        cancelButtonColor={'green'}
        close={() => handleModalOnClose()}
        open={data.automaticLogoutModal}
        confirm={() => handleLogout()}
        cancel={() => handleModalOnClose()}
        headerText="Confirmation"
        detailText={`You will be logged out shortly for security purposes. If you
        wish to remain logged in. please continue below.`}
        loading={data.forgotPasswordLoading}
        type='warning'
      />
    </>
  );
};

export default React.memo(LogoutPopup);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import ButtonContainer from '../../../../../admin-module/module/business-process/button-container';
import { currentRouteAction, setThirdLevelRouteAction } from '../../../../../main/action';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import ListMainView from './main-view';
import ListSideView from './side-view';

interface IListViewProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

class ListViewModule {

  name = 'list-view';

  render: React.FC<IListViewProps> = (props) => {

    const dispatch = useDispatch();
    const match = useRouteMatch();

    useEffect(() => {
      dispatch(setThirdLevelRouteAction({
        mainRoute: 'portal',
        subRoute: 'business-processes',
        thirdLevelRoute: this.name
      }))
      dispatch(currentRouteAction(this.name))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Route path={`${match.path}`}>
        <div className='list-view-main-container bp-portal-submenu'>
          <div className='list-view-container'>
            <ListSideView />
            <ListMainView />
          </div>
          <ButtonContainer
            save={() => { }}
            cancel={() => { }}
            publish={() => { }}
          />
        </div>
      </Route>
    );
  }
}

export default ListViewModule;
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import {
  GET_FORM_BUILDER_DETAIL,
  GET_FORM_BUILDER_DETAIL_RETURN,
  GET_FORM_BUILDER_DETAIL_ERROR,
  SAVE_FORM_BUILDER_CONFIGURATION_RETURN,
  SAVE_FORM_BUILDER_CONFIGURATION,
  SAVE_FORM_BUILDER_CONFIGURATION_ERROR,
  PUBLISH_FORM_BUILDER_CONFIGURATION,
  PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN,
  PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR,
  GET_PUBLISHED_FORM_LIST_RETURN,
  CLEAR_FORM_BUILDER_DETAIL_ERROR,
  CLEAR_SAVE_FORM_BUILDER_ERROR,
  CLEAR_SAVE_FORM_BUILDER_SUCCESS,
  SET_SAVE_FORM_BUILDER_MESSAGE
} from './constant';
import { IForm } from '../form/interface/form';
import { message } from '../../message';
import { IDataTable } from '../../../data-tables/reducer';
import { IPdfTemplate } from './interface/template';

export interface IFormBuilderReducer {
  type?: string;
  formBuilderField: ICustomFieldDetail[];
  formDetail: IForm;
  formBuilderDetailLoading: boolean;
  formBuilderDetailErrorMessage: string;
  saveFormBuilderLoading: boolean;
  saveFormBuilderMessage: string;
  saveFormBuilderErrorMessage: string;
  saveFormBuilderType: string;
  publishedForm: IForm[];
  datatable: IDataTable[];
  pdfTemplates: IPdfTemplate[];
  userCustomField: ICustomFieldDetail[];
}

const initialState: IFormBuilderReducer = {
  formBuilderField: [],
  formDetail: {} as IForm,
  formBuilderDetailLoading: false,
  formBuilderDetailErrorMessage: '',
  saveFormBuilderLoading: false,
  saveFormBuilderMessage: '',
  saveFormBuilderErrorMessage: '',
  saveFormBuilderType: '',
  publishedForm: [],
  datatable: [],
  pdfTemplates: [],
  userCustomField: []
};

const formBuilderReducer = (state = initialState, action: IFormBuilderReducer) => {
  let newState = { ...state };
  // let tempFormList = [];

  switch (action.type) {
    case GET_FORM_BUILDER_DETAIL:
      newState.saveFormBuilderType = '';
      newState.formBuilderDetailErrorMessage = ''
      newState.saveFormBuilderMessage = '';
      newState.saveFormBuilderErrorMessage = '';
      newState.formBuilderField = [];
      newState.userCustomField = [];
      newState.formBuilderDetailLoading = true;
      return newState;

    case GET_FORM_BUILDER_DETAIL_RETURN:
      newState.formBuilderField = action.formBuilderField;
      newState.userCustomField = action.userCustomField;
      newState.formDetail = action.formDetail;
      newState.formBuilderDetailLoading = false;
      return newState;

    case GET_FORM_BUILDER_DETAIL_ERROR:
      newState.formBuilderDetailErrorMessage = action.formBuilderDetailErrorMessage
      newState.formBuilderDetailLoading = false;
      return newState;

    case SAVE_FORM_BUILDER_CONFIGURATION:
      newState.saveFormBuilderLoading = true;
      newState.saveFormBuilderType = ''
      newState.saveFormBuilderErrorMessage = '';
      newState.saveFormBuilderMessage = '';
      return newState;

    case SAVE_FORM_BUILDER_CONFIGURATION_RETURN:
      if (action.saveFormBuilderType === 'draft') {
        newState.saveFormBuilderLoading = false;
        newState.saveFormBuilderMessage = message.saveFormBuilderSuccess;
      }
      newState.formBuilderField = action.formBuilderField;
      newState.formDetail = action.formDetail;
      newState.saveFormBuilderType = action.saveFormBuilderType;
      return newState;

    case SAVE_FORM_BUILDER_CONFIGURATION_ERROR:
      newState.saveFormBuilderLoading = false;
      newState.saveFormBuilderErrorMessage = action.saveFormBuilderErrorMessage;
      newState.saveFormBuilderType = '';
      return newState;

    case PUBLISH_FORM_BUILDER_CONFIGURATION:
      newState.saveFormBuilderType = '';
      return newState;

    case PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN:
      newState.saveFormBuilderLoading = false;
      newState.saveFormBuilderMessage = message.publishFormBuilderSuccess;
      return newState;

    case PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR:
      newState.saveFormBuilderLoading = false;
      newState.saveFormBuilderErrorMessage = action.saveFormBuilderErrorMessage;
      return newState;

    case GET_PUBLISHED_FORM_LIST_RETURN:
      newState.publishedForm = action.publishedForm;
      newState.datatable = action.datatable;
      return newState;

    case CLEAR_SAVE_FORM_BUILDER_SUCCESS:
      newState.saveFormBuilderMessage = '';
      return newState;

    case CLEAR_SAVE_FORM_BUILDER_ERROR:
      newState.saveFormBuilderErrorMessage = '';
      return newState;

    case CLEAR_FORM_BUILDER_DETAIL_ERROR:
      newState.formBuilderDetailErrorMessage = '';
      return newState;

    case SET_SAVE_FORM_BUILDER_MESSAGE:
      newState.saveFormBuilderMessage = action.saveFormBuilderMessage;
      return newState;

    default:
      return state;
  }
}

export default formBuilderReducer

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import useDeviceDetect from '../../../../../general/device-detect';
import PopperContainer from '../../../../../general/popper-component';
import { icons } from '../../../../../icons';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { getFocusedElement, setFocusedElement, setTableFocusedElement, removeFocusedElement } from '../../../../../utils/focus-element';
import { elementStatus } from '../../../utils/element-status';
import { removeInput } from '../../../utils/remove-input';
import { AcceptedProps } from '../../interface/accepted-props';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { TValidationType } from '../../../service/field-properties.handler';
import { returnPopperPosition } from './utils/return-popper-position';
import { removePopperPosition } from './utils/remove-popper-position';
import { DateTimeProps } from '../../../service/type/date-time';

const DateTimeArrayType: React.FC<AcceptedProps> = (props) => {
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();
  const name = props.getFieldId();
  const dateFormat = props.tableColumnRowIndex !== undefined ? 'dd MMM yyyy h:mm a' : 'dd MMMM yyyy h:mm a';

  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new DateTimeProps());

  const [refresher, setRefresher]: any = useState(1);
  const [multilineCount, setMultilineCount] = useState(1);

  let calendarRef = useRef<any>({});

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === 'required' ? 1 : 0,
      isRequired: props.accessType === 'required'
    }
    for (let row: number = 0; row < multilineCount; row++) {
      elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    }
    setRefresher(uuid());
  }

  const onCalendarClose = (id: string, row: number) => {

    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    validateInput();

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);

    setRefresher(refresher + 1);
  }

  const handleDateTime = (date: any, row: number) => {
    const selected = date ? moment(date).format("YYYY-MM-DD HH:mm:ssZ") : '';
    elementProperties.setCurrent({
      ...elementProperties.getCurrent(row),
      value: selected ? moment(selected).utc().format() : ''
    }, row);
    if (calendarRef.current && calendarRef.current[row]) {
      calendarRef.current[row].setOpen(false);
    }
    if (props.triggerFilter) {
      props.triggerFilter('')
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
  }

  const getValue = (row: number) => {
    return elementProperties.getCurrent(row).value
      ? new Date(moment(elementProperties.getCurrent(row).value).format("YYYY-MM-DD HH:mm:ssZ"))
      : null;
  }

  const focusInput = (id: string) => {
    setFocusedElement(id);
    if (mobile) {
      setRefresher(uuid());
      if (typeof props.tableColumnRowIndex === 'number') {
        setTableFocusedElement({
          index: props.tableColumnRowIndex,
          focusedElement: id,
          columnDisplayed: props.tableColumnDisplayed as number[]
        });
      }
    }
  }

  const addNewInput = () => {
    const newLen = multilineCount + 1;
    setMultilineCount(newLen);
    const defaultValue: any = props.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      elementProperties.setCurrent({ value: defaultValue[0] || '' }, multilineCount);
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const renderAddRow = (row: number, count: number) => {
    return row === (count - 1) && props.multiline
      && <i
        className={`plus circular icon multiline-icon ${row === 0 ? '' : 'more'}`}
        onClick={() => addNewInput()}
      />
  }

  const renderDeleteRow = (row: number, count: number) => {
    return (row > 0 || (row === 0 && row !== (count - 1)))
      && <i className="minus circular icon multiline-minus-icon"
        onClick={() => removeInput({
          forwardedRef: props.forwardedRef,
          name: name,
          multilineCount: multilineCount,
          setMultilineCount: setMultilineCount
        }, row)}
      />
  }

  const handleClickOutside = (row: number) => {
    if (calendarRef.current && calendarRef.current[row]) {
      calendarRef.current[row].setOpen(false);
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(id);
    }
  }

  const manageMultipleField = (count: number) => {
    let field: any = [];
    for (let row: number = 0; row < count; row++) {
      const id = `date-time-picker-${name}-${row}`;
      field.push(
        <React.Fragment key={uuid()}>
          {row > 0 && <span className='multiline-gap' />}
          <div className='multi-line-container'>
            <div className='flex-container'>
              <ReactDatePicker
                id={id}
                popperContainer={PopperContainer}
                showYearDropdown={true}
                showMonthDropdown={true}
                showTimeSelect
                selected={getValue(row)}
                timeIntervals={1}
                placeholderText={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
                dropdownMode="select"
                dateFormat={dateFormat}
                readOnly={props.accessType === IFieldAccessType.Readonly}
                onCalendarClose={() => onCalendarClose(id, row)}
                className={`field-input single ${elementStatus({
                  forwardedRef: props.forwardedRef,
                  name: name,
                  multiline: props.multiline || false,
                  required: props.accessType === IFieldAccessType.Required,
                  fieldValueObject: elementProperties
                })}`}
                onChange={(date) => handleDateTime(date, row)}
                onInputClick={() => { focusInput(id) }}
                onFocus={() => returnPopperPosition(id)}
                ref={(curRef: any) => {
                  if (curRef) {
                    if (focusedElement) {
                      if (id === focusedElement) {
                        curRef.input.focus();
                      }
                    }
                    if (calendarRef.current) {
                      calendarRef.current[row] = curRef;
                    }
                  }
                }}
                popperClassName={id}
                shouldCloseOnSelect
                onClickOutside={() => handleClickOutside(row)}
                onKeyDown={(e) => handleKeydown(e, id)}
              />
              {props.hasIconInside &&
                <div className='flex-container with-icon multi-line clock-icon'>
                  <Image width='20px' src={icons.black.dateTime} />
                </div>
              }
              {elementProperties.hasError() && props.showErrorMessage &&
                elementProperties.getError(row)[0].message.indexOf('Please provide value for') === -1 &&
                <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError(row)[0].message }} />
              }
            </div>
            <span>
              {renderDeleteRow(row, count)}
              {renderAddRow(row, count)}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return field;
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }
    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueArray: string[] = defaultValue as string[];
      for (let row: number = 0; row < multilineCount; row++) {
        elementProperties.setCurrent({ value: defaultValueArray[row] || '' }, row)
      }
    }
  }

  useEffect(() => {
    if (props.accessType === 'required') {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multilineCount])

  useEffect(() => {
    if (props.multiline) {
      const len = elementProperties.current.length;
      setMultilineCount(len === 0 ? 1 : len);
    }
    if (props.isAddRecord) {
      setDefaultValue();;
    }
    if (props.accessType === 'required') {
      validateInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>{props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        {manageMultipleField(multilineCount)}
      </>
    }
  </>
}

export default DateTimeArrayType;
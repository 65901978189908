import { IFormBuilderSection } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';

class MoveField {

  name = 'MoveField';

  updateSection = (sectionList: IFormBuilderSection[], field: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    let newSectionList = [...sectionList];
    if (sectionUpdateConfig.sectionId !== sectionUpdateConfig.dragFieldSectionId) {
      let sectionIndexForRemoval = -1;
      newSectionList = newSectionList.map((section: IFormBuilderSection, index: number) => {
        if (section.sectionId === sectionUpdateConfig.dragFieldSectionId
          && sectionUpdateConfig.dragFieldIndex !== undefined
          && sectionUpdateConfig.dragItemRowIndex !== undefined
          && sectionUpdateConfig.dropAreaRowIndex !== undefined
          && sectionUpdateConfig.dropAreaFieldIndex !== undefined
        ) {
          section.fields[sectionUpdateConfig.dragItemRowIndex].splice(sectionUpdateConfig.dragFieldIndex, 1);
          if (section.fields[sectionUpdateConfig.dragItemRowIndex].length === 0) {
            section.fields.splice(sectionUpdateConfig.dragItemRowIndex, 1);

            if (section.fields.length === 0 && section.style === 'plain') {
              sectionIndexForRemoval = index;
            }
          }
        }
        if (section.sectionId === sectionUpdateConfig.sectionId
          && sectionUpdateConfig.dragFieldIndex !== undefined
          && sectionUpdateConfig.dragItemRowIndex !== undefined
          && sectionUpdateConfig.dropAreaRowIndex !== undefined
          && sectionUpdateConfig.dropAreaFieldIndex !== undefined
        ) {
          section.fields[sectionUpdateConfig.dropAreaRowIndex].splice(sectionUpdateConfig.dropAreaFieldIndex, 0, field);
        }
        return section;
      });
      if (sectionIndexForRemoval > -1) {
        newSectionList.splice(sectionIndexForRemoval, 1);
      }

      return newSectionList;
    }

    return newSectionList.map((section: IFormBuilderSection) => {
      if (section.sectionId === sectionUpdateConfig.sectionId
        && sectionUpdateConfig.dragFieldIndex !== undefined
        && sectionUpdateConfig.dragItemRowIndex !== undefined
        && sectionUpdateConfig.dropAreaRowIndex !== undefined
        && sectionUpdateConfig.dropAreaFieldIndex !== undefined
      ) {
        section.fields[sectionUpdateConfig.dragItemRowIndex].splice(sectionUpdateConfig.dragFieldIndex, 1);
        section.fields[sectionUpdateConfig.dropAreaRowIndex].splice(sectionUpdateConfig.dropAreaFieldIndex, 0, field);
        if (section.fields[sectionUpdateConfig.dragItemRowIndex].length === 0) {
          section.fields.splice(sectionUpdateConfig.dragItemRowIndex, 1)
        }
      }
      return section;
    });
  }
}

export default MoveField;
import React from 'react';
import Dropdown from './enum-type/dropdown';
import Checklist from './enum-type/checklist';
import { AcceptedProps } from './interface/accepted-props';

const EnumElement: React.FC<AcceptedProps> = (props) => {
  const { config } = props.inputConfig;
  const { displayType } = config;

  if (displayType === 'dropdown') return <Dropdown {...props} />;
  if (displayType === 'checklist') return <Checklist {...props} />;
  return <div>Dropdown type not exist</div>
}

export default EnumElement;
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Timestamp,
  required: false,
  type: 'date-time',
};

interface IDefaultConfigObject {
}

const defaultConfig: IDefaultConfigObject = {

};

const value = {
  title: 'Timestamp',
  icon: {
    black: require('../../icons/black/time-stamp.png'),
    white: require('../../icons/white/time-stamp.png'),
    blue: require('../../icons/blue/time-stamp.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
import {
  CLEAR_UPDATE_COMPANY_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS,
  GET_COMPANY_SETTINGS_RETURN,
  RESET_MESSAGE_RESPONSE,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_ERROR,
  UPDATE_COMPANY_SETTINGS_RETURN
} from './constant';
import { ICompanySettings } from './interface/company-settings';
import { message } from './message';

export interface ICompanySettingsReducer {
  companySettings: ICompanySettings;
  companySettingsLoading: boolean;
  updateCompanySettingsMessage: string;
  updateCompanySettingsMessageError: string;
}

const initialState: ICompanySettingsReducer = {
  companySettings: { enableEmailNotification: false },
  companySettingsLoading: false,
  updateCompanySettingsMessage: '',
  updateCompanySettingsMessageError: ''
}

const companySettingsReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_COMPANY_SETTINGS:
      newState.companySettingsLoading = true;
      return newState;

    case GET_COMPANY_SETTINGS_RETURN:
      newState.companySettings = action.data;
      newState.companySettingsLoading = false;
      return newState;

    case UPDATE_COMPANY_SETTINGS:
      newState.updateCompanySettingsMessage = '';
      newState.companySettingsLoading = true;
      return newState;

    case UPDATE_COMPANY_SETTINGS_RETURN:
      newState.companySettings = action.data;
      newState.updateCompanySettingsMessage = message.updateCompanySettingsSuccess;
      newState.companySettingsLoading = false;
      return newState;

    case UPDATE_COMPANY_SETTINGS_ERROR:
      newState.updateCompanySettingsMessage = action.message;
      newState.companySettingsLoading = false;
      return newState;

    case CLEAR_UPDATE_COMPANY_SETTINGS_SUCCESS:
      newState.updateCompanySettingsMessage = '';
      return newState;

    case RESET_MESSAGE_RESPONSE:
      newState.updateCompanySettingsMessage = '';

      return newState;
    default:
      return state;
  }
}

export default companySettingsReducer;
import React from 'react';
import { ICustomFieldDetail } from '../../interface/custom-field-detail';
import StringFieldValue from './string';
import NumberFieldValue from './number';
import FileFieldValue from './file';
import BoolFieldValue from './bool';
import DateFieldValue from './date';
import TimeFieldValue from './time';
import EnumFieldValue from './enum';
import DateTimeFieldValue from './date-time';
import FxFieldValue from './fx';
import RangeFieldValue from './range';

const fieldType = [
  new BoolFieldValue(),
  new DateFieldValue(),
  new FileFieldValue(),
  new NumberFieldValue(),
  new StringFieldValue(),
  new TimeFieldValue(),
  new EnumFieldValue(),
  new DateTimeFieldValue(),
  new FxFieldValue(),
  new RangeFieldValue()
]

export interface IRender {
  valueId: string;
  field: ICustomFieldDetail;
  forwardedRef: any;
  dispatch: any;
  referenceBp: { id: string, name: string };
  companyId: string;
  currentFormField: ICustomFieldDetail;
  throwValueOutside?(): void;
  configType?: string;
  parentFormFieldId?: string;
  currentBpId?: string;
  fromPermission?: boolean;
  currentFieldList?: ICustomFieldDetail[];
}

interface IFieldValue {
  name: string;
  Render: React.FC<IRender>;
}

export class FieldValue {

  registry: { [name: string]: IFieldValue } = {};

  constructor() {
    const types = fieldType;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  default: React.FC = () => {
    return <span>{``}</span>;
  }

  getElement(props: IRender): React.FC<IRender> {
    return (this.registry[props.field.type || ''])
      ? this.registry[props.field.type || ''].Render
      : this.default;
  }

  static Render: React.FC<IRender> = (props) => {
    const adminModule = new FieldValue();
    const Render = adminModule.getElement(props);
    return <Render {...props} />
  }

}
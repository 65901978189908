import {
  CREATE_DATA_TABLE,
  CREATE_DATA_TABLE_RETURN,
  CREATE_DATA_TABLE_ERROR,
  GET_DATA_TABLE_LIST,
  GET_DATA_TABLE_LIST_RETURN,
  GET_DATA_TABLE_LIST_ERROR,
  RESET_DATA_TABLE_MESSAGE,
  DELETE_DATATABLE,
  DELETE_DATATABLE_CANCEL,
  DELETE_DATATABLE_CONFIRM,
  DELETE_DATATABLE_CONFIRM_RETURN,
  DELETE_DATATABLE_CONFIRM_ERROR,
  CLEAR_DELETE_DATATABLE_MESSAGE,
  GET_DATA_TABLE_FIELDS,
  GET_DATA_TABLE_FIELDS_RETURN,
  GET_DATA_TABLE_FIELDS_ERROR,
  GET_DATA_TABLE_DETAILS,
  GET_DATA_TABLE_DETAILS_RETURN,
  GET_DATA_TABLE_DETAILS_ERROR,
  CREATE_DATA_TABLE_RECORDS,
  CREATE_DATA_TABLE_RECORDS_RETURN,
  CREATE_DATA_TABLE_RECORDS_ERROR,
  GET_DATA_TABLE_RECORDS,
  GET_DATA_TABLE_RECORDS_RETURN,
  GET_DATA_TABLE_RECORDS_ERROR,
  UPDATE_DATA_TABLE,
  UPDATE_DATA_TABLE_RETURN,
  UPDATE_DATA_TABLE_ERROR,
  DELETE_DATA_TABLE_FIELDS,
  DELETE_DATA_TABLE_FIELDS_RETURN,
  DELETE_DATA_TABLE_FIELDS_ERROR,
  UPDATE_DATA_TABLE_RECORD_ERROR,
  UPDATE_DATA_TABLE_RECORD_RETURN,
  UPDATE_DATA_TABLE_RECORD,
  DELETE_DATATABLE_RECORD,
  DELETE_DATATABLE_RECORD_CONFIRM,
  DELETE_DATATABLE_RECORD_CONFIRM_RETURN,
  DELETE_DATATABLE_RECORD_CONFIRM_ERROR,
  DELETE_DATATABLE_RECORD_CANCEL,
  CLEAR_DELETE_DATATABLE_RECORD_MESSAGE,
  GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN,
  CHECK_DATATABLE_RECORD_RETURN,
  CHECK_DATATABLE_RECORD,
  CLEAR_CREATE_DATA_TABLE_SUCCESS,
  CLEAR_UPDATE_DATA_TABLE_SUCCESS,
  CLEAR_DELETE_DATA_TABLE_SUCCESS,
  CLEAR_DELETE_DATA_TABLE_ERROR,
  CLEAR_CREATE_DATA_TABLE_ERROR,
  CLEAR_UPDATE_DATA_TABLE_ERROR,
  CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS,
  CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS,
  CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS,
  CLEAR_CREATE_DATA_TABLE_RECORD_ERROR,
  CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR,
  CLEAR_DELETE_DATA_TABLE_RECORD_ERROR,
  TRIGGER_FIELD_TO_UPDATE_RETURN,
  SET_RECORD_EDITABLE_RETURN,
  RESET_RECORD_EDITABLE,
  FILTER_DATATABLE_RECORD_RETURN,
  GET_FILTER_FIELD_DATATABLE_RECORD_RETURN,
  FILTER_DATATABLE_RECORD,
  SORT_DATATABLE_RECORD_FIELD_LIST,
  BATCH_UPDATE_DATATABLE_RECORD_RETURN,
  BATCH_UPDATE_DATATABLE_RECORD,
  BATCH_UPDATE_DATATABLE_RECORD_ERROR,
  RESET_DATATABLE_FIELDS,
  BATCH_UPLOAD_DATATABLE_RECORD_RETURN,
  SET_ENUM_ITEMS_FROM_RECORDS,
  RESET_DATATABLE_BATCH_CHANGE,
  REFRESH_DATATABLE_RECORDS,
  SET_RECORDS_GROUPED_CONFIG_RETURN,
  DELETE_RECORDS_GROUPED_CONFIG,
  GET_RECORDS_GROUPED_CONFIG_RETURN,
  GET_RECORDS_GROUPED_CONFIG,
  GET_RECORDS_GROUPED_KEYPATHS_RETURN,
  GET_RECORDS_GROUPED_KEYPATHS,
} from './constant';

import dataTableMessage from './message';
import { IField } from '../users/interface/field';
import { IOption } from '../../../../common/interface/option';
import { IDatatableRecord } from '../../../../common/custom-field-config/interface/datatable-record';
import { IDatatableConfig, IDatatableRecordsGroupedConfig, IDTRecordGroupedKeypath } from './interface/data-table-config';
import { ICustomFieldDetail } from '../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../common/field/type/interface/field-type-object';
import { getEnumItemsFromRecords } from './records/utils/manage-grid';

export interface IDatatableReducer {
  dataTableList: IDataTable[];
  dataTableListError: string;
  dataTableLoading: boolean;
  createDataTableMessage: string;
  createDataTableErrorMessage: string;
  updateDataTableMessage: string;
  updateDataTableErrorMessage: string;
  updateDataTableLoading: boolean;
  forDeleteDatatableId: string;
  forDeleteDatatableName: string;
  forDeleteDataTableRecordId: string;
  isOpenDeleteConfirmationModal: boolean;
  confirmDeletetionLoading: boolean;
  confirmDeletionSuccessMessage: string;
  confirmDeletionErrorMessage: string;
  dataTableRecordsList: IDatatableRecord[];
  dataTableRecordListError: string;
  dataTableRecordLoading: boolean;
  createDataTableRecordMessage: string;
  createDataTableRecordErrorMessage: string;
  updateDataTableRecordMessage: string;
  updateDataTableRecordErrorMessage: string;
  forUpdateDataTableFields: IField[];
  forUpdateDataTablePermission: { view: string[], modify: string[] };
  forUpdateDataTablePermissionOption: IOption[];
  forUpdateDataTableFieldsLoading: boolean;
  forUpdateDataTableFieldsMessage: string;
  forUpdateDataTableFieldsError: boolean;
  forUpdateDataTableDetails: IDataTable;
  forUpdateDataTableDetailsLoading: boolean;
  forUpdateDataTableDetailsMessage: string;
  forUpdateDataTableDetailsError: boolean;
  isDataTableRecordEditable: boolean;
  dataTableRecordEditableId: string;
  deleteDataTableFieldsMessage: string;
  hasDatatableRecords: boolean;
  datatableRecordFilterFieldList: IField[];
  datatableRecordTotalCount: number;
  datatableSearchLoading: boolean;
  sortFieldAscending: { [id: string]: boolean };
  fieldConfigValue?: { [id: string]: any };
  valueFieldReload?: { [id: string]: any };
  sort?: string;
  datatableRecordGridMessage: string;
  datatableTotalCount: number;
  enumItemsFromRecords?: { [fieldId: string]: any[] }
  unChangedDatatableRecords: {
    list: IDatatableRecord[];
    count: number
  };
  batchDeletePerformed: boolean;
  recordsToDelete: string[];
  datatableRecordsGroupedConfig: IDatatableRecordsGroupedConfig | null;
  groupedRecordsKeypath: IDTRecordGroupedKeypath[],
}

export interface IDataTable {
  id: string;
  name: string;
  fields: ICustomFieldDetail[];
  permissionRules: any;
  config: IDatatableConfig;
  updatedAt?: string;
  allowedAccess?: 'edit' | 'view';
  totalRecord?: number;
}

const initialState: IDatatableReducer = {
  dataTableList: [],
  dataTableListError: '',
  dataTableLoading: false,
  createDataTableMessage: '',
  createDataTableErrorMessage: '',
  updateDataTableMessage: '',
  updateDataTableErrorMessage: '',
  updateDataTableLoading: false,
  forDeleteDatatableId: '',
  forDeleteDatatableName: '',
  forDeleteDataTableRecordId: '',
  isOpenDeleteConfirmationModal: false,
  confirmDeletetionLoading: false,
  confirmDeletionSuccessMessage: '',
  confirmDeletionErrorMessage: '',
  dataTableRecordsList: [],
  dataTableRecordListError: '',
  dataTableRecordLoading: false,
  createDataTableRecordMessage: '',
  createDataTableRecordErrorMessage: '',
  updateDataTableRecordMessage: '',
  updateDataTableRecordErrorMessage: '',
  forUpdateDataTableFields: [] as IField[],
  forUpdateDataTablePermission: { view: [], modify: [] },
  forUpdateDataTablePermissionOption: [] as IOption[],
  forUpdateDataTableFieldsLoading: false,
  forUpdateDataTableFieldsMessage: '',
  forUpdateDataTableFieldsError: false,
  forUpdateDataTableDetails: {} as IDataTable,
  forUpdateDataTableDetailsLoading: false,
  forUpdateDataTableDetailsMessage: '',
  forUpdateDataTableDetailsError: false,
  isDataTableRecordEditable: false,
  dataTableRecordEditableId: '',
  deleteDataTableFieldsMessage: '',
  hasDatatableRecords: true,
  datatableRecordFilterFieldList: [],
  datatableRecordTotalCount: 0,
  datatableSearchLoading: false,
  sortFieldAscending: {},
  datatableRecordGridMessage: '',
  datatableTotalCount: 0,
  unChangedDatatableRecords: {
    list: [],
    count: 0
  },
  batchDeletePerformed: false,
  recordsToDelete: [],
  datatableRecordsGroupedConfig: {} as IDatatableRecordsGroupedConfig,
  groupedRecordsKeypath: [],
};

const dataTableReducer = (state = { ...initialState }, action: any) => {
  let newState = { ...state };
  let tempDataTableList = [];
  let tempDataTableRecordList = [] as IDatatableRecord[];
  let tempDatatableDetail = {} as IDataTable;

  switch (action.type) {
    case GET_DATA_TABLE_LIST:
      newState.dataTableLoading = true;
      return newState;

    case GET_DATA_TABLE_LIST_RETURN:
      newState.dataTableList = action.data;
      newState.dataTableLoading = false;
      return newState;

    case GET_DATA_TABLE_LIST_ERROR:
      newState.dataTableListError = dataTableMessage.dataTableError;
      return newState;

    case CREATE_DATA_TABLE:
      newState.dataTableLoading = true;
      newState.createDataTableMessage = '';
      return newState;

    case CREATE_DATA_TABLE_RETURN:
      tempDataTableList = [...state.dataTableList];
      tempDataTableList.push(action.data);
      newState.dataTableList = tempDataTableList;
      newState.dataTableLoading = false;
      newState.createDataTableMessage = dataTableMessage.createDataTableSuccess;
      return newState;

    case CREATE_DATA_TABLE_ERROR:
      const createDatatableErrorMessage = action.message.indexOf('Existing records will violate constraint') === -1 ? action.message : dataTableMessage.withDuplicateRecordDataError;
      newState.createDataTableErrorMessage = createDatatableErrorMessage;
      newState.dataTableLoading = false;
      return newState;

    case GET_DATA_TABLE_FIELDS:
      newState = { ...state };
      newState.forUpdateDataTableFieldsLoading = true;
      newState.forUpdateDataTableFields = [];
      return newState;

    case GET_DATA_TABLE_FIELDS_RETURN:
      newState = { ...state };
      newState.forUpdateDataTableFieldsLoading = false;
      newState.forUpdateDataTableFieldsMessage = '';
      newState.forUpdateDataTableFieldsError = false;
      newState.forUpdateDataTableFields = action.data;
      return newState;

    case GET_DATA_TABLE_FIELDS_ERROR:
      newState = { ...state };
      newState.forUpdateDataTableFieldsLoading = false;
      newState.forUpdateDataTableFieldsMessage = '';
      newState.forUpdateDataTableFieldsError = true;
      return newState;

    case GET_DATA_TABLE_DETAILS:
      newState = { ...state };
      newState.forUpdateDataTableDetailsLoading = true;
      newState.forUpdateDataTableDetails = {} as IDataTable;
      newState.forUpdateDataTablePermission = initialState.forUpdateDataTablePermission;
      return newState;

    case GET_DATA_TABLE_DETAILS_RETURN:
      newState = { ...state };
      newState.forUpdateDataTableDetailsLoading = false;
      newState.forUpdateDataTableDetailsMessage = '';
      newState.forUpdateDataTableDetailsError = false;
      tempDatatableDetail = action.data as IDataTable;
      newState.forUpdateDataTablePermission = tempDatatableDetail.permissionRules;
      newState.forUpdateDataTableDetails = tempDatatableDetail;
      return newState;

    case GET_DATA_TABLE_DETAILS_ERROR:
      newState = { ...state };
      newState.forUpdateDataTableDetailsLoading = false;
      newState.forUpdateDataTableDetailsMessage = '';
      newState.forUpdateDataTableDetailsError = true;
      return newState;

    case CREATE_DATA_TABLE_RECORDS:
      newState = { ...state };
      newState.dataTableRecordLoading = true;
      newState.createDataTableRecordMessage = '';
      return newState;

    case CREATE_DATA_TABLE_RECORDS_RETURN:
      newState = { ...state };
      tempDataTableRecordList = [...state.dataTableRecordsList];
      if (state.recordsToDelete.length > 0) {
        tempDataTableRecordList = tempDataTableRecordList.filter(e => !state.recordsToDelete.includes(e.id));
      }
      if (!action.fromState) {
        tempDataTableRecordList.push(action.data);
      } else {
        tempDataTableRecordList.unshift(action.data);
      }
      newState.dataTableRecordsList = tempDataTableRecordList;
      if (!action.fromState) {
        newState.createDataTableRecordMessage = dataTableMessage.createDataTableRecordSuccess;
        newState.dataTableRecordLoading = false;
      }
      newState.datatableRecordTotalCount = state.datatableRecordTotalCount += 1;
      return newState;

    case CREATE_DATA_TABLE_RECORDS_ERROR:
      newState = { ...state };
      const createDatatableRecordErrorMessage = action.message.indexOf('Existing records will violate constraint') === -1 ? action.message : dataTableMessage.withDuplicateRecordDataError;
      newState.createDataTableRecordErrorMessage = createDatatableRecordErrorMessage;
      newState.dataTableRecordLoading = false;
      return newState;

    case GET_DATA_TABLE_RECORDS:
      newState = { ...state };
      newState.dataTableRecordLoading = true;
      newState.datatableRecordGridMessage = 'Loading records...';
      return newState;

    case GET_DATA_TABLE_RECORDS_RETURN:
      newState = { ...state };
      newState.dataTableRecordsList = action.data;
      newState.datatableRecordTotalCount = action.datatableRecordTotalCount || 0;
      newState.dataTableRecordLoading = false;
      if (action.datatableRecordTotalCount === 0) {
        newState.datatableRecordGridMessage = 'No records found.';
      } else {
        newState.datatableRecordGridMessage = '';
      }
      newState.unChangedDatatableRecords.list = [...action.data];
      newState.unChangedDatatableRecords.count = action.datatableRecordTotalCount || 0;
      return newState;

    case GET_DATA_TABLE_RECORDS_ERROR:
      newState = { ...state };
      newState.dataTableRecordListError = dataTableMessage.dataTableRecordError;
      return newState;

    case UPDATE_DATA_TABLE:
      newState.updateDataTableMessage = '';
      newState.updateDataTableLoading = true;
      return newState;

    case UPDATE_DATA_TABLE_RETURN:
      let updateDataTableList = [...state.dataTableList];
      const hasRecordAlready = updateDataTableList.find(dataTable => action.data.id === dataTable.id);
      if (hasRecordAlready) {
        newState.dataTableList = updateDataTableList.map(dataTable => {
          if (action.data.id === dataTable.id) {
            return action.data;
          }
          return dataTable
        });
      } else {
        updateDataTableList.push(action.data);
        newState.dataTableList = updateDataTableList;
      }
      newState.updateDataTableMessage = dataTableMessage.updateDataTableSuccess;
      newState.updateDataTableLoading = false;
      return newState;

    case UPDATE_DATA_TABLE_ERROR:
      const updateDatatableErrorMessage = action.message.indexOf('Existing records will violate constraint') === -1 ? action.message : dataTableMessage.withDuplicateRecordDataError;
      newState.updateDataTableErrorMessage = updateDatatableErrorMessage;
      newState.updateDataTableLoading = false;
      return newState;

    case RESET_DATA_TABLE_MESSAGE:
      newState.createDataTableMessage = '';
      newState.updateDataTableMessage = '';
      newState.createDataTableRecordMessage = '';
      newState.updateDataTableRecordMessage = '';
      newState.forUpdateDataTableDetails = {} as IDataTable;
      newState.forUpdateDataTablePermission = initialState.forUpdateDataTablePermission;
      return newState;

    case DELETE_DATATABLE:
      newState.forDeleteDatatableId = action.datatableId;
      newState.forDeleteDatatableName = action.datatableName;
      newState.isOpenDeleteConfirmationModal = true;
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_DATATABLE_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_DATATABLE_CONFIRM_RETURN:
      tempDataTableList = [...state.dataTableList];
      newState.dataTableList = tempDataTableList.filter((e: IDataTable) => e.id !== action.datatableId)
      newState.confirmDeletetionLoading = false;
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletionSuccessMessage = dataTableMessage.deleteDatatableSuccess
      return newState;

    case DELETE_DATATABLE_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_DATATABLE_CANCEL:
      newState.forDeleteDatatableId = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case CLEAR_DELETE_DATATABLE_MESSAGE:
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case SET_RECORD_EDITABLE_RETURN:
      newState.forUpdateDataTableFields = action.formField;
      newState.isDataTableRecordEditable = action.isEditable;
      newState.dataTableRecordEditableId = action.editableId;
      return newState;

    case RESET_RECORD_EDITABLE:
      newState.isDataTableRecordEditable = false;
      newState.dataTableRecordEditableId = '';
      return newState;

    case DELETE_DATA_TABLE_FIELDS:
      newState.deleteDataTableFieldsMessage = '';
      return newState;

    case DELETE_DATA_TABLE_FIELDS_RETURN:
      newState.deleteDataTableFieldsMessage = dataTableMessage.deleteDataTableFieldsSuccess;
      return newState;

    case DELETE_DATA_TABLE_FIELDS_ERROR:
      newState.deleteDataTableFieldsMessage = action.message;
      return newState;

    case UPDATE_DATA_TABLE_RECORD:
      newState.updateDataTableRecordMessage = '';
      newState.dataTableRecordLoading = true;
      return newState;

    case UPDATE_DATA_TABLE_RECORD_RETURN:
      let updateDataTableRecordList = [...state.dataTableRecordsList];
      if (state.recordsToDelete.length > 0) {
        updateDataTableRecordList = updateDataTableRecordList.filter(e => !state.recordsToDelete.includes(e.id));
      }
      newState.dataTableRecordsList = updateDataTableRecordList.map(dataTable => {
        if (action.data.id === dataTable.id) {
          return action.data;
        }
        return dataTable
      });
      if (!action.fromState) {
        newState.updateDataTableRecordMessage = dataTableMessage.updateDataTableRecordSuccess;
        newState.dataTableRecordLoading = false;
      }
      return newState;

    case UPDATE_DATA_TABLE_RECORD_ERROR:
      const updateDatatableRecordErrorMessage = action.message.indexOf('Existing records will violate constraint') === -1 ? action.message : dataTableMessage.withDuplicateRecordDataError;
      newState.updateDataTableRecordErrorMessage = updateDatatableRecordErrorMessage;
      newState.dataTableRecordLoading = false;
      return newState;

    case DELETE_DATATABLE_RECORD:
      if (!action.recordsToDelete) {
        newState.forDeleteDataTableRecordId = action.recordId;
        newState.forDeleteDatatableId = action.dataTableId;
        newState.isOpenDeleteConfirmationModal = true;
        newState.confirmDeletetionLoading = false;
        newState.confirmDeletionSuccessMessage = '';
        newState.confirmDeletionErrorMessage = '';
      } else {
        newState.recordsToDelete = action.recordsToDelete;
      }
      return newState;

    case DELETE_DATATABLE_RECORD_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_DATATABLE_RECORD_CONFIRM_RETURN:
      tempDataTableRecordList = [...state.dataTableRecordsList];
      newState.dataTableRecordsList = tempDataTableRecordList.filter((e: IDatatableRecord) => e.id !== action.recordId)
      newState.datatableRecordTotalCount = state.datatableRecordTotalCount -= 1;
      if (!action.fromState) {
        newState.confirmDeletetionLoading = false;
        newState.isOpenDeleteConfirmationModal = false;
        newState.confirmDeletionSuccessMessage = dataTableMessage.deleteDataTableRecordsSuccess
      }
      newState.batchDeletePerformed = action.batchDeletePerformed;
      return newState;

    case DELETE_DATATABLE_RECORD_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_DATATABLE_RECORD_CANCEL:
      newState.forDeleteDataTableRecordId = '';
      newState.forDeleteDatatableId = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case CLEAR_DELETE_DATATABLE_RECORD_MESSAGE:
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case GET_DATATABLE_DETAIL_PERMISSION_OPTION_RETURN:
      newState.forUpdateDataTablePermissionOption = action.data;
      return newState;

    case CHECK_DATATABLE_RECORD:
      newState.hasDatatableRecords = true;
      return newState;

    case CHECK_DATATABLE_RECORD_RETURN:
      newState.hasDatatableRecords = action.data.length !== 0;
      return newState;

    case CLEAR_CREATE_DATA_TABLE_SUCCESS:
      newState.createDataTableMessage = '';
      return newState;

    case CLEAR_UPDATE_DATA_TABLE_SUCCESS:
      newState.updateDataTableMessage = '';
      return newState;

    case CLEAR_DELETE_DATA_TABLE_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case CLEAR_DELETE_DATA_TABLE_ERROR:
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case CLEAR_CREATE_DATA_TABLE_ERROR:
      newState.createDataTableErrorMessage = '';
      return newState;

    case CLEAR_UPDATE_DATA_TABLE_ERROR:
      newState.updateDataTableErrorMessage = '';
      return newState;

    case CLEAR_CREATE_DATA_TABLE_RECORD_SUCCESS:
      newState.createDataTableRecordMessage = '';
      return newState;

    case CLEAR_UPDATE_DATA_TABLE_RECORD_SUCCESS:
      newState.updateDataTableRecordMessage = '';
      return newState;

    case CLEAR_DELETE_DATA_TABLE_RECORD_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case CLEAR_CREATE_DATA_TABLE_RECORD_ERROR:
      newState.createDataTableRecordErrorMessage = '';
      return newState;

    case CLEAR_UPDATE_DATA_TABLE_RECORD_ERROR:
      newState.updateDataTableRecordErrorMessage = '';
      return newState;

    case CLEAR_DELETE_DATA_TABLE_RECORD_ERROR:
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case TRIGGER_FIELD_TO_UPDATE_RETURN:
      let tempField = [...newState.forUpdateDataTableFields];
      let tempFieldConfigValue = { ...newState.fieldConfigValue };
      tempField = tempField.map((field: IField) => {
        const updateField = action.formField.filter((upField: IField) => upField.id === field.id);
        if (updateField.length > 0) {
          return updateField[0];
        }
        return field;
      })
      tempField.forEach((field: IField) => {
        if (field.configName === IConfigName.Autopopulated || field.configName === IConfigName.FX) {
          const updateField = action.formField.find((upField: IField) => upField.id === field.id);
          if (updateField) {
            const { config: { value } }: any = { ...updateField.inputConfig };
            tempFieldConfigValue[updateField.id || ''] = value;
          }
        }
      });
      newState.forUpdateDataTableFields = tempField;
      newState.fieldConfigValue = tempFieldConfigValue;
      newState.valueFieldReload = action.valueFieldReload;
      newState.dataTableRecordEditableId = action.docId;
      return newState;

    case FILTER_DATATABLE_RECORD:
      newState.dataTableRecordsList = [];
      newState.datatableSearchLoading = true;
      newState.datatableRecordGridMessage = 'Loading records...';
      return newState;

    case FILTER_DATATABLE_RECORD_RETURN:
      newState.dataTableRecordsList = action.data;
      newState.datatableSearchLoading = false;
      newState.sort = action.sort;
      if (action.data.length === 0) {
        newState.datatableRecordGridMessage = 'No records found.';
      } else {
        newState.datatableRecordGridMessage = '';
      }
      newState.datatableRecordTotalCount = action.datatableRecordTotalCount || 0;
      return newState;

    case GET_FILTER_FIELD_DATATABLE_RECORD_RETURN:
      newState.datatableRecordFilterFieldList = action.datatableRecordFilterFieldList;
      return newState;

    case SORT_DATATABLE_RECORD_FIELD_LIST:
      newState.sortFieldAscending[action.fieldId] = action.ascendingValue === false ? false : true;
      return newState;

    case BATCH_UPDATE_DATATABLE_RECORD:
      newState.dataTableRecordLoading = true;
      newState.createDataTableRecordMessage = '';
      return newState;

    case BATCH_UPDATE_DATATABLE_RECORD_RETURN:
      newState.dataTableRecordLoading = false;
      newState.dataTableRecordsList = action.dataTableRecordsList
      newState.createDataTableRecordMessage = dataTableMessage.batchUpdateDataTableRecordSuccess;
      newState.datatableRecordTotalCount = action.datatableRecordTotalCount || 0;
      return newState;

    case BATCH_UPLOAD_DATATABLE_RECORD_RETURN:
      newState.dataTableRecordLoading = false;
      newState.createDataTableRecordMessage = dataTableMessage.batchUpdateDataTableRecordSuccess;
      return newState;

    case BATCH_UPDATE_DATATABLE_RECORD_ERROR:
      newState.dataTableRecordLoading = false;
      const batchUploadDatatableRecordErrorMessage = action.createDataTableRecordErrorMessage.indexOf('Existing records will violate constraint') === -1
        ? action.createDataTableRecordErrorMessage
        : dataTableMessage.withDuplicateRecordDataError;
      newState.createDataTableRecordErrorMessage = batchUploadDatatableRecordErrorMessage;
      return newState;

    case RESET_DATATABLE_FIELDS:
      newState.forUpdateDataTableFields = [];
      newState.forUpdateDataTableDetails = {} as IDataTable;
      newState.dataTableRecordsList = [];
      return newState;

    case SET_ENUM_ITEMS_FROM_RECORDS:
      if (state.dataTableRecordsList.length > 0
        && state.forUpdateDataTableFields.length > 0) {
        const items = getEnumItemsFromRecords(state.dataTableRecordsList, state.forUpdateDataTableFields);
        newState.enumItemsFromRecords = items;
      }
      return newState;

    case RESET_DATATABLE_BATCH_CHANGE:
      newState.dataTableRecordsList = state.unChangedDatatableRecords.list;
      newState.datatableRecordTotalCount = state.unChangedDatatableRecords.count;
      return newState;

    case REFRESH_DATATABLE_RECORDS:
      localStorage.removeItem('gridActiveCell');
      tempDataTableRecordList = [...state.dataTableRecordsList];
      newState.dataTableRecordsList = tempDataTableRecordList;
      return newState;

    case SET_RECORDS_GROUPED_CONFIG_RETURN:
      newState.datatableRecordsGroupedConfig = action.data;
      return newState;

    case DELETE_RECORDS_GROUPED_CONFIG:
      newState.datatableRecordsGroupedConfig = null
      return newState

    case GET_RECORDS_GROUPED_CONFIG:
      newState.datatableRecordsGroupedConfig = null;
      return newState;

    case GET_RECORDS_GROUPED_CONFIG_RETURN:
      newState.datatableRecordsGroupedConfig = action.data;
      return newState;

    case GET_RECORDS_GROUPED_KEYPATHS:
      newState.groupedRecordsKeypath = [];
      return newState;

    case GET_RECORDS_GROUPED_KEYPATHS_RETURN:
      newState.groupedRecordsKeypath = action.groupedRecordsKeypath;
      return newState;

    default:
      return state;
  }
}

export default dataTableReducer;
import { IFieldAccessType, IOptionValue } from '../../../../../component/admin-module/module/users/interface/field';
import ValidateField from '../../../validation';
import { IValidationError } from '../../../validation/type/validation-type';
import { FieldElement } from '../../field-element';
import { TValidationType, PropType } from '../field-properties.handler';

interface IMultipleSelectionEnumProps {
  value: string[];
  error?: IValidationError[];
  filteredOption?: IOptionValue[];
  optionsFilterSearch?: string;
  selectedOptionFilter?: IOptionValue[];
  fieldType?: 'multiple-enum';
}

export class EnumMultiselectProps extends PropType<IMultipleSelectionEnumProps, IMultipleSelectionEnumProps> {

  processValueFromRef(props: IMultipleSelectionEnumProps): IMultipleSelectionEnumProps {
    props.value = typeof props.value === 'object' && props.value ? props.value : [];
    return props;
  }


  processValueToRef(newValue: IMultipleSelectionEnumProps): IMultipleSelectionEnumProps {
    newValue.value = newValue.value || [];
    return newValue;
  }

  processValidation(props: IMultipleSelectionEnumProps, field: FieldElement, validationType: TValidationType): IValidationError[] {
    const value = typeof props.value === 'object' && props.value ? props.value : [];
    const validateField = new ValidateField();
    const validate = validateField.singleField(field, value || '', validationType);
    return field.accessType === IFieldAccessType.Required || !!value ? validate : [];
  }
}
import { AggregateFunction } from '../fx.agg-func';

export class MaxAggregateFunction implements AggregateFunction {

  name = 'max';

  aggregate(values: number[]): number {
    return values.reduce((max, curr) => {
      return Math.max(max, curr) || max;
    }, 0);
  }

}
import { useEffect } from 'react';
import { FieldConstruct } from '../../../../../../../../../../../../common/field/field-construct';
import { IField } from '../../../../../../../../../users/interface/field';
import { IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
  defaultvalueRef: React.MutableRefObject<any>;
  getCurrentFieldElementConfig(fieldId: string): IField | undefined;
}

export const useSetIssueFieldsRefs = (props: IProps) => {
  const { selectedComponent, defaultvalueRef,
    getCurrentFieldElementConfig } = { ...props };

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      const formField = selectedComponent.config.sourceField || '';
      const value = formField === 'statusId' ? selectedComponent.config.value.toString() : (selectedComponent.config.value || '');
      if (defaultvalueRef.current.currentValue) {
        const currentSelectedField = getCurrentFieldElementConfig(formField);
        const tempCurrentField: any = currentSelectedField ? { ...currentSelectedField } : { id: '' };
        tempCurrentField.id = 'currentValue';
        const formDocument = { fields: { currentValue: value } };
        FieldConstruct.setFieldToRefs([tempCurrentField], formDocument, defaultvalueRef)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent]);

  return { ...props };
}
import React from "react";
import { SVGICONS } from './svg-icons';

interface SvgStyles {
  iconName: string;
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?: any;
  className?: string;
}


const SVGWrapper: React.FC<SvgStyles> = (props) => {
  const { iconName, color, height, width, onClick } = props;
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={SVGICONS[iconName].viewBox}>
      <g transform={SVGICONS[iconName]?.transform}>
        <path d={SVGICONS[iconName].path} fill={color} onClick={onClick} />
      </g>
    </svg>
  );
};


export default SVGWrapper;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { useDrop, DragSourceMonitor, useDrag } from 'react-dnd';
import DropboxRange from './drop-box-range';

interface IDropbox {
  withItem: any;
  itemIndex: number;
  insertDropField(selectedItem: any, itemIndex: number, withItem: any): void;
  deleteFilter(index: number, type: string): void;
  editConfiguration(index: number): void;
  dragIconOutside?: boolean;
}

const Dropbox: React.FC<IDropbox> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'customFilterField',
    drop: selectedItem => {
      console.log(selectedItem, props.itemIndex, props.withItem);
      props.insertDropField(selectedItem, props.itemIndex, props.withItem)
    }
  })

  // eslint-disable-next-line
  const [draggedObject, dragRef] = useDrag({
    item: { type: 'customFilterField', new: false, itemType: props.withItem && props.withItem !== 'contained' ? props.withItem.itemType : 'field', item: props.withItem, itemIndex: props.itemIndex },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  if (props.withItem === 'contained') {
    return <></>;
  }

  if (typeof props.withItem === 'object' && props.withItem.itemType === 'field-range') {
    return <div ref={dragRef} className={`dropbox-range`}>
      {props.dragIconOutside &&
        <Image src={icons.blue.dragIcon}
          className='img-drag'
          style={{ width: '10px', float: 'left', marginTop: '2px' }}
        />
      }
      <DropboxRange
        itemIndex={props.itemIndex}
        withItem={props.withItem}
        insertDropField={props.insertDropField}
        deleteFilter={props.deleteFilter}
        editConfiguration={props.editConfiguration}
        dragIconOutside={props.dragIconOutside}
      />
    </div>;
  }

  return (<>
    <div ref={props.withItem ? dragRef : undefined} style={{ opacity: draggedObject.opacity }}>
      {props.dragIconOutside && props.withItem &&
        <Image src={icons.blue.dragIcon}
          className='img-drag'
          style={{ width: '10px', float: 'left', marginTop: '2px' }}
        />
      }
      <div ref={dropRef} className={`dropbox ${props.withItem ? 'with-item' : ''}`}>
        {props.withItem &&
          <div className={`field`} >
            {!props.dragIconOutside &&
              <Image src={icons.blue.dragIcon}
                style={{ width: '10px', float: 'left', marginTop: '2px' }}
              />
            }
            <Image src={props.withItem.icon}
              style={{ width: '15px', marginRight: '10px', marginTop: '-3px' }}
            />
            {props.withItem.name || props.withItem.label}
            <Image src={icons.blue.delete}
              className='img-delete'
              style={{ width: '12px', float: 'right', marginTop: '2px', cursor: 'pointer' }}
              onClick={() => props.deleteFilter(props.itemIndex, 'field')}
            />
            <Image src={icons.blue.edit}
              className='img-edit'
              onClick={() => props.editConfiguration(props.itemIndex)}
              style={{ width: '15px', float: 'right', marginTop: '2px', marginRight: '3px', cursor: 'pointer' }}
            />
          </div>
        }
      </div >
    </div>
  </>
  );
}

export default Dropbox;

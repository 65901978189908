import React, { useRef, useState, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Dropdown, Image } from 'semantic-ui-react-bpm';
import { errorIndicator } from '../../constant';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig } from '../../interface/custom-field-detail';
import { IRootState } from '../../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { plainToClass } from 'class-transformer';
import { ICustomFieldProps } from '../..';
import { IOption } from '../../../interface/option';
import { getFieldListAction } from '../../action';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { icons } from '../../../icons';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { ConfirmationMessage } from '../../../general/confirmation-modal';

const selectedSourceField = (fieldId: string, params: {
  setSourceField(fieldId: string): void,
  setDisplayFieldOption(value: IOption[]): void,
  dispatch(action: any): void,
  companyId: string,
  currentFieldList?: ICustomFieldDetail[],
  tableId?: string;
  userFieldList?: ICustomFieldDetail[],
}) => {
  let selectedField = params.currentFieldList ? params.currentFieldList.filter((field: ICustomFieldDetail) => field.id === fieldId) : [];
  params.setSourceField(fieldId);
  let data = {
    company: params.companyId,
    databaseId: '',
    formId: '',
    dataType: 'document',
  }
  if (params.tableId && fieldId.split('.').length === 2) {
    const tempTableField = params.currentFieldList ? params.currentFieldList.filter((field: ICustomFieldDetail) => field.id === params.tableId) : [];
    if (tempTableField.length && tempTableField[0].config) {
      selectedField = tempTableField[0].config.columns.filter((field: ICustomFieldDetail) => fieldId && field.id === fieldId.split('.')[1])
    }
  }
  if (selectedField.length > 0) {
    const dataType = selectedField[0].config && selectedField[0].config.dataType;
    if (dataType === 'user' && params.userFieldList && params.userFieldList.length > 0) {
      const displayOption = params.userFieldList.map((field: ICustomFieldDetail, index: number) => {
        return { key: index, value: field.id || '', text: field.label || '' }
      });
      params.setDisplayFieldOption(displayOption);
    } else {
      const isDocument = selectedField[0].config && selectedField[0].config.data.formId;
      if (isDocument) {
        data.formId = selectedField[0].config && selectedField[0].config.data.formId;
      } else {
        const databaseId = selectedField[0].config && selectedField[0].config.data.databaseId;
        data.databaseId = databaseId
        data.dataType = 'database';
      }
      params.setDisplayFieldOption([]);
      params.dispatch(getFieldListAction(data));
    }
  }
}

export default class AutopopulatedFieldConfig extends DefaultFieldConfig {

  name = 'autopopulated';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const dispatch = useDispatch();

    const {
      displayFieldList
    } = useSelector((state: IRootState) => state.customField);

    const companyId = props.companyId;
    const fieldNameRef = useRef(document.createElement('input'));
    const infoRef = useRef(document.createElement('input'));
    const [error, setError] = useState(errorIndicator);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<ICustomFieldDetailConfig>({});
    const [sourceField, setSourceField] = useState<string>('');
    const [displayFieldOption, setDisplayFieldOption] = useState<IOption[]>([]);
    const [displayField, setDisplayField] = useState<string>('');
    const [sourceFieldOption, setSourceOption] = useState<IOption[]>([])
    const [autoPopulateOnce, setAutopopulateOnce] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);
    const [openAutopopulateOnceConfirm, setOpenAutopopulateOnceConfirm] = useState<boolean>(false);
    const [confidential, setConfidential] = useState<boolean>(false);

    const getSelectedField = () => {
      let selectedField: ICustomFieldDetail[] = [];
      if (props.currentFieldList) {
        if (props.tableId) {
          const tableDetails = props.currentFieldList.find((field: ICustomFieldDetail) => field.id === props.tableId);
          if (tableDetails) {
            const { columns }: any = { ...tableDetails.config };
            selectedField = columns.filter((field: ICustomFieldDetail) => field.id === props.defaultData.id);
          }
        } else {
          selectedField = props.currentFieldList.filter((field: ICustomFieldDetail) => field.id === props.defaultData.id);
        }
      }
      return selectedField;
    }

    const updateConfig = () => {
      if (validateRequiredFormField()) {
        const config = plainToClass(IConfig, {
          ...configData,
          sourceField: sourceField,
          labelFormat: `{{fields.${displayField}}}`,
          labelType: getLabelType(displayField),
          autoPopulateOnce: autoPopulateOnce,
          editable: editable
        })

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: false,
            label: fieldNameRef.current.value,
            hint: infoRef.current.value,
            config: config,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '')
      }
    }

    const getLabelType = (fieldId: string): string => {
      let labelType = '';
      let selectedField = getSelectedField();
      if (selectedField.length > 0) {
        const dataType = selectedField[0].config && selectedField[0].config.dataType;
        if (dataType === 'user' && props.userFieldList && props.userFieldList.length > 0) {
          const userField = props.userFieldList.find((field: ICustomFieldDetail) => field.id === fieldId);
          if (userField) {
            labelType = userField.type || '';
          }
        } else {
          const tempDisplayField = displayFieldList.filter((field: ICustomFieldDetail) => field.id === fieldId);
          if (tempDisplayField.length > 0) {
            labelType = tempDisplayField[0].type || '';
          }
        }
      }
      return labelType;
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      currentError.fieldname = fieldNameRef.current.value ? false : true;
      currentError.sourceField = sourceField ? false : true;
      currentError.displayField = displayField ? false : true;

      setError(currentError)
      return !currentError.fieldname && !currentError.sourceField && !currentError.displayField
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;

        setConfigData(config);
        setFieldData(data);
        setEditable(config.editable || false);
        setAutopopulateOnce(config.autoPopulateOnce || false);
        fieldNameRef.current.value = data.label || '';
        infoRef.current.value = data.hint || '';
        setSourceField(config.sourceField || '');
        setDisplayField(config.labelFormat ? config.labelFormat.replace(/fields./g, '').replace(/{/g, '').replace(/}/g, '') : '')
        selectedSourceField(config.sourceField as string, {
          setSourceField,
          setDisplayFieldOption,
          dispatch,
          companyId,
          currentFieldList: props.currentFieldList,
          tableId: props.tableId,
          userFieldList: props.userFieldList
        });
        setConfidential(data.confidential || false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    useEffect(() => {
      let selectedField = getSelectedField();
      if (selectedField.length > 0) {
        const dataType = selectedField[0].config && selectedField[0].config.dataType;
        if (displayFieldList.length > 0 && dataType !== 'user') {
          const displayOption = displayFieldList.map((field: ICustomFieldDetail, index: number) => {
            return { key: index, value: field.id || '', text: field.label || '' }
          });
          setDisplayFieldOption(displayOption);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayFieldList, props.currentFieldList, props.defaultData])

    useEffect(() => {
      if (props.currentFieldList) {
        const tempFilteredField = props.currentFieldList
          .filter((field: ICustomFieldDetail) => field.configName === IConfigName.Referenced || (field.type === 'table' && field.id === props.tableId));

        const tempSourceOption: IOption[] = [];
        let ctr = 0;
        tempFilteredField.forEach((field: ICustomFieldDetail, index: number) => {
          if (field.type === 'table' && field.config) {
            field.config.columns.forEach((tblField: ICustomFieldDetail) => {
              if (tblField.configName === IConfigName.Referenced) {
                tempSourceOption.push({
                  key: ctr++,
                  text: tblField.label || 'Untitled',
                  value: `${`${props.tableId ? `${props.tableId}.` : ''}${tblField.id}`}`
                })
              }
            })
          } else {
            tempSourceOption.push({
              key: ctr++,
              text: field.label || 'Untitled',
              value: field.id || ''
            })
          }
        })
        setSourceOption(tempSourceOption);
      }
    }, [props.currentFieldList, props.tableId, props.userFieldList])


    const triggerConfirmationMessage = () => {
      setOpenAutopopulateOnceConfirm(true);
    }

    const confirmSetStaticSource = () => {
      setOpenAutopopulateOnceConfirm(false);
      setAutopopulateOnce(!autoPopulateOnce);
    }

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='autopopulate-field-modal'
        centered={false}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`Autopopulated Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form'>
            <div className='field-info-properties-container'>
              <div className='field-info-container'>
                <Form.Group widths='equal'>
                  <div className={`required sixteen wide field ${error.fieldname ? 'error' : ''}`}>
                    <label className='label'>Field Name</label>
                    <div className="ui input">
                      <input onChange={() => {
                        error.fieldname = (fieldNameRef.current.value ? false : true)
                      }} ref={fieldNameRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group widths='equal'>
                  <div className=" sixteen wide field">
                    <label className='label'>Field Info</label>
                    <div className="ui input">
                      <input onChange={() => {
                        error.fieldname = (infoRef.current.value ? false : true)
                      }} ref={infoRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group widths='equal' className={`required`}>
                  <div className={`required field ${error.sourceField ? 'error' : ''}`}>
                    <label>Select source field in the form </label>
                    <Dropdown fluid multiple={false} selection clearable selectOnBlur={false}
                      popperContainer={DropdownPopperOption}
                      value={sourceField} options={sourceFieldOption} search
                      onChange={(event, target) => {
                        setDisplayField('');
                        setDisplayFieldOption([]);
                        selectedSourceField(target.value as string, {
                          setSourceField,
                          setDisplayFieldOption,
                          dispatch,
                          companyId,
                          currentFieldList: props.currentFieldList,
                          tableId: props.tableId,
                          userFieldList: props.userFieldList
                        })
                      }}
                    />
                  </div>
                </Form.Group>
                <div className={`display-field-from `}>
                  <Form.Group widths='equal' className={`required`}>
                    <div className={`field required ${error.displayField ? 'error' : ''}`}>
                      <label>Select field in the original field source </label>
                      <Dropdown
                        popperContainer={DropdownPopperOption}
                        fluid
                        selection
                        clearable
                        search
                        value={displayField}
                        selectOnBlur={false}
                        options={displayFieldOption}
                        onChange={(event, target) => { setDisplayField(target.value as string) }}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className='custom-field-checkbox'>
                  <div className="ui fitted checkbox" onClick={triggerConfirmationMessage}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={autoPopulateOnce} value="" />
                    <label>Static Source</label>
                  </div>
                </div>
                <div className='custom-field-checkbox'>
                  <div className="ui fitted checkbox" onClick={() => setEditable(!editable)}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={editable} value="" />
                    <label>Editable</label>
                  </div>
                </div>
                <div className='custom-field-checkbox'>
                  <div className="ui fitted checkbox" onClick={() => setConfidential(!confidential)}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} value="" />
                    <label>Confidential</label>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <div className='label-bottom'>
            <label><strong> * </strong> Fields are required</label>
          </div>
        </Modal.Content>
        <Modal.Actions className='borderless'>
          {!props.isViewOnly && <React.Fragment  >
            <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
            {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
              <Button color='red' className='btn-action create-btn'
                onClick={() => {
                  props.cancel(props.fieldId)
                  props.openDeleteModal(props.fieldId, fieldData.label || '');
                }}>Delete</Button>
            }
            {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
              <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
            }
          </React.Fragment>}
        </Modal.Actions>
      </Modal>
      <ConfirmationMessage
        confirmButtonName={'Yes'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => setOpenAutopopulateOnceConfirm(false)}
        open={openAutopopulateOnceConfirm}
        confirm={confirmSetStaticSource}
        cancel={() => setOpenAutopopulateOnceConfirm(false)}
        headerText="Confirmation"
        detailText={`Changing the static config to “Value change” will change all values in past and present forms . Do you want to proceed?`}
        loading={false}
        type='warning'
      />
    </>
  }
}
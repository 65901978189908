import React from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, Segment } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import PortalSHProfileFormDetails from './details';

interface IPortalSHProfileForm {

}

const PortalSHProfileForm: React.FC<IPortalSHProfileForm> = (props) => {
  const history = useHistory();

  const cancelForm = () => {
    history.push('portal/stakeholder')
  }

  return <Modal
    dimmer='blurring'
    size='large'
    closeOnEscape={false}
    closeOnDimmerClick={false}
    open={true}
    onClose={() => { }}
    className='portal-sh-profile-form'>
    <Modal.Header className='borderless'>
      <div className='ui borderless bg-primary'>
        <span>Stakeholder Definition</span>
      </div>
    </Modal.Header>
    <Modal.Content className='borderless'>
      <div className='content-header'>
        <span className='name'>SUPPLIERS</span>
        <span className='action'>
          <SVGWrapper
            iconName='edit'
            width='14'
            height='14'
            color='#8A9296'
          />
        </span>
      </div>
      <PortalSHProfileFormDetails />
      <PortalSHProfileFormDetails />
      <PortalSHProfileFormDetails />
      <PortalSHProfileFormDetails />
      <div className='new-el-btn'>
        <div className='icon'>
          <SVGWrapper
            iconName='plus'
            width='10'
            height='10'
            color='#fff'
          />
        </div>
        <div className='label'>
          Add New Element
        </div>
      </div>
    </Modal.Content>
    <Modal.Actions className='borderless'>
      <Segment className='borderless'>
        <Button className='btn-default btn-action cancel-btn' onClick={cancelForm}>Cancel</Button>
        <Button className='btn-success btn-action create-btn' onClick={() => { }}>Save</Button>
      </Segment>
    </Modal.Actions>
  </Modal>
}

export default PortalSHProfileForm;
import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IDropdownOption } from '../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import DropdownPopperOption from '../../../../../../../../../../../../common/general/dropdown-popper-option';
import SVGWrapper from '../../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { IRootState } from '../../../../../../../../../../../../reducers';
import { ISlaStatusesTaskAssigned } from '../../../../../../../configuration/module/slas/interface/slaAndStatuses';
import { DetailActionButton } from '../../action-button';
import AutomationDetailHeader from '../../widget/detail-header-widget';
import { useSetInitialSlaUpdateStateValue } from '../hooks/use-set-initial-sla-update-state-values';
import { ITriggerTypeRegistryRender } from './registry';

class TriggerSLAUpdate {

  name = 'sla-miss';

  render: React.FC<ITriggerTypeRegistryRender> = (props) => {
    const {
      slaStatusList
    } = useSelector((state: IRootState) => state.automation)
    const {
      selectedSLAStatus,
      setSelectedSLAStatus,
      taskAssigned,
      setTaskAssigned,
      processLaunch,
      setProcessLaunch
    } = useSetInitialSlaUpdateStateValue({ selectedComponent: props.selectedComponent });

    const getSLAOption = (): IDropdownOption[] => {
      return [
        { key: uuid(), value: 'warn', text: 'Excel → SLA' },
        { key: uuid(), value: 'late', text: 'SLA → LATE' },
      ]
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.eventType = selectedSLAStatus;
        tempComponent.config.forProcessLaunch = processLaunch;
        tempComponent.config.forStatus = taskAssigned;
        props.updateCurrentAutomation(tempComponent, true);
      }
    }

    const manageSLAOption = (value: string) => {
      setSelectedSLAStatus(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageProcessLaunch = (value: boolean) => {
      setProcessLaunch(value);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const manageTaskAssigned = (value: string) => {
      const tempTaskAssigned = [...taskAssigned];
      const valueIndex = tempTaskAssigned.indexOf(value);
      if (valueIndex > -1) {
        tempTaskAssigned.splice(valueIndex, 1);
      } else {
        tempTaskAssigned.push(value);
      }
      setTaskAssigned(tempTaskAssigned);
      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const selectAllStatus = () => {
      if (slaStatusList && slaStatusList.hasOwnProperty('taskAssigned')) {
        let tempTaskAssigned: string[] = [];
        if (taskAssigned.length === 0) {
          tempTaskAssigned = slaStatusList.taskAssigned.map((e: ISlaStatusesTaskAssigned) => {
            return e.statusId;
          })
        }
        setTaskAssigned(tempTaskAssigned);
      }

      if (props.hasComponentChange.current === false) props.parentRefresher();
      props.hasComponentChange.current = true;
    }

    const isSelectedAllStatus = (): boolean => {
      return slaStatusList !== undefined && slaStatusList.hasOwnProperty('taskAssigned')
        && slaStatusList.taskAssigned.length === taskAssigned.length;
    }

    return (<>
      <div className={`automation-form-detail-trigger`}>
        <AutomationDetailHeader
          title={`SLA Update`}
          description={`This rule will trigger when the sla status changes.`}
          iconHeight={16}
          iconWidth={16}
          iconName='iconEdit'
        />
        <div className={`sla-update-container automation-field-container`}>
          <div className={`automation-field`}>
            <label>SLA</label>
            <Dropdown
              popperContainer={DropdownPopperOption}
              fluid
              selection
              clearable
              search
              selectOnBlur={false}
              value={selectedSLAStatus}
              options={getSLAOption()}
              onChange={(event, target: any) => { manageSLAOption(target.value) }}
            />
          </div>
          {slaStatusList && slaStatusList.hasOwnProperty('taskAssigned') &&
            <>
              <div className="ui radio checkbox" onClick={() => selectAllStatus()}>
                <input type="radio"
                  className="hidden"
                  name="taskAssigned"
                  onChange={() => { }}
                  value='and'
                  checked={isSelectedAllStatus()}
                />
                <label>STATUS</label>
              </div>
              <hr />
              {
                slaStatusList.taskAssigned.map((e: ISlaStatusesTaskAssigned) => {
                  return <div key={e.statusId} className={`status-container`}>
                    <div className="ui checkbox" onClick={() => manageTaskAssigned(e.statusId)}>
                      <input className="hidden" type="checkbox"
                        checked={taskAssigned.indexOf(e.statusId) > -1}
                        onChange={() => { }} />
                      <label>{e.name.toLowerCase()}</label>
                    </div>
                    <SVGWrapper
                      width={20}
                      height={20}
                      iconName={e.deadline ? 'clock' : 'flag'}
                      color={`#3A85A8`}
                    />
                  </div>
                })}
            </>
          }
          {slaStatusList && slaStatusList.hasOwnProperty('processLaunch') &&
            <>
              <div className="ui radio checkbox" onClick={() => { manageProcessLaunch(!processLaunch); }}>
                <input type="radio"
                  className="hidden"
                  name="processLaunch"
                  onChange={() => { }}
                  value='and'
                  checked={processLaunch}
                  disabled={!slaStatusList || (slaStatusList && !slaStatusList.hasOwnProperty('processLaunch'))}
                />
                <label>PROCESS LAUNCH</label>
              </div>
              <hr />
            </>
          }

        </div>
      </div >
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}

export default TriggerSLAUpdate;
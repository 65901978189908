import {
  GET_STATUS_SUMMARY,
  GET_STATUS_SUMMARY_RETURN,
  GET_STATUS_SUMMARY_ERROR,
  GET_BUSINESS_PROCESS_LOGO,
  GET_BUSINESS_PROCESS_LOGO_RETURN,
  GET_BUSINESS_PROCESS_LOGO_ERROR,
  GET_BP_STATUS_LIST,
  GET_BP_STATUS_LIST_RETURN,
  GET_BP_STATUS_LIST_ERROR,
  SET_SELECTED_WORKFLOW_FILTER,
  workspaceFilterType
} from './constant'
import { IStatusSummaryResponse } from './interface/response/summary';
import { IStatusSummaryDetailsInput } from './interface/input/summary';
import { IFormLogoGetDetailInput } from '../../../admin-module/module/business-process/module/form/interface/input/form-logo-get';
import { IFormLogoGetResponse } from '../../../admin-module/module/business-process/module/form/interface/response/form-logo-get';
import { IBPStatusListDetailInput } from './interface/input/bp-status';
import { IBPStatusListResponse } from './interface/response/bp-status';

export const getStatusSummaryAction = (payload: IStatusSummaryDetailsInput) => {
  return { type: GET_STATUS_SUMMARY, ...payload }
}

export const getStatusSummaryReturnAction = (payload: IStatusSummaryResponse) => {
  return { type: GET_STATUS_SUMMARY_RETURN, ...payload }
}

export const getStatusSummaryErrorAction = (message: string) => {
  return { type: GET_STATUS_SUMMARY_ERROR, message }
}

export const getBPLogoAction = (payload: IFormLogoGetDetailInput) => {
  return { type: GET_BUSINESS_PROCESS_LOGO, ...payload }
}

export const getBPLogoReturnAction = (payload: IFormLogoGetResponse) => {
  return { type: GET_BUSINESS_PROCESS_LOGO_RETURN, ...payload }
}

export const getBPLogoErrorAction = (message: string) => {
  return { type: GET_BUSINESS_PROCESS_LOGO_ERROR, message }
}

export const getBPStatusListAction = (payload: IBPStatusListDetailInput) => {
  return { type: GET_BP_STATUS_LIST, ...payload }
}

export const getBPStatusListReturnAction = (payload: IBPStatusListResponse) => {
  return { type: GET_BP_STATUS_LIST_RETURN, ...payload }
}

export const getBPStatusListErrorAction = (message: string) => {
  return { type: GET_BP_STATUS_LIST_ERROR, message }
}

export const setSelectedWorkFlowFilterAction = (filter: workspaceFilterType[]) => {
  return { type: SET_SELECTED_WORKFLOW_FILTER, filter }
}
import React from 'react';

type IStatusTab = 'inactive' | 'active' | 'all';

interface IBusinesProcessFilterTab {
  setStatusTab(value: 'inactive' | 'active' | 'all'): void;
  statusTab: IStatusTab;
}

const TableStatusTab: React.FC<IBusinesProcessFilterTab> = (props) => {

  const statusListTab = [
    { key: 1, text: 'All', value: 'all' },
    { key: 2, text: 'Active', value: 'active' },
    { key: 3, text: 'Inactive', value: 'inactive' },
  ]

  return (
    <div className='table-satus-tab'>
      {statusListTab.map(status => {
        return <span key={status.key}
          className={`tab ${props.statusTab === status.value ? 'active' : ''}`}
          onClick={() => props.setStatusTab(status.value as IStatusTab)}>
          {status.text}
        </span>
      })}
    </div>
  );
}

export default TableStatusTab;
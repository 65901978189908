import React from 'react';
import { actionWidget } from '../../../constant';
import { IOptionWidget } from '../../../interface/option-widget';
import { IAutomationDetailRender } from '../component-registry';
import AutomationDetailContent from '../widget/detail-content-widget';
import AutomationDetailHeader from '../widget/detail-header-widget';
import { ActionTypeRegistry } from './actionType/registry';

class AutomationDetailNewAction {

  name = 'action';

  render: React.FC<IAutomationDetailRender> = (props) => {

    const selectAction = (e: IOptionWidget) => {
      const tempSelectedComponent = { ...props.selectedComponent };
      const tempConfig = { ...tempSelectedComponent.config };
      tempConfig.action = e;
      tempSelectedComponent.config = tempConfig;
      if (props.updateAutomationOnparent) {
        props.updateAutomationOnparent(tempSelectedComponent, props.childIndex);
      } else {
        props.updateCurrentAutomation(tempSelectedComponent, false);
      }
      props.setSelectedComponent(
        tempSelectedComponent,
        props.selectedChildComponent ? { ...props.selectedChildComponent, component: tempSelectedComponent } : undefined
        , props.isHasParent
      );
    }

    const noSelectedAction = () => {
      return <div className='automation-form-detail-new-action'>
        <AutomationDetailHeader
          title={`New Action`}
          description={`Actions change documents fields or status.`}
          iconHeight={30}
          iconWidth={30}
          iconName='newAction'
        />
        <AutomationDetailContent
          recommended={actionWidget.filter((e: IOptionWidget) => e.recommended === true)}
          allComponent={actionWidget}
          selected={props.selectedComponent.config && props.selectedComponent.config.action}
          setSelected={selectAction}
          contentScreen='Actions'
        />
      </div>
    }

    const withSelectedAction = (selectedAction: IOptionWidget) => {
      return <ActionTypeRegistry.Render
        type={selectedAction.name}
        currentAutomationRef={props.currentAutomationRef}
        selectedComponent={props.selectedComponent}
        updateCurrentAutomation={props.updateCurrentAutomation}
        refsObject={props.refsObject}
        isHasParent={props.isHasParent}
        hasComponentChange={props.hasComponentChange}
        parentRefresher={props.parentRefresher}
      />
    }

    return (<>
      {!(props.selectedComponent.config && props.selectedComponent.config.action)
        ? noSelectedAction()
        : withSelectedAction(props.selectedComponent.config && props.selectedComponent.config.action)
      }
    </>)
  }
}

export default AutomationDetailNewAction;
import { IFormBuilderSection } from '../interface/section';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from './interface/section-update-config';
import FieldTypeToDropBox from './update-type/field-type-to-dropbox';
import FieldTypeToSectionField from './update-type/field-type-to-section-field';
import UpdateField from './update-type/update-field';
import UnlinkField from './update-type/unlink-field';
import UnlinkFieldOnPlainField from './update-type/unlink-field-on-plain-field';
import DeleteField from './update-type/delete-field';
import SortSectionField from './update-type/sort-section-field';
import MoveField from './update-type/move-field';

const methodList = [
  new FieldTypeToDropBox(),
  new FieldTypeToSectionField(),
  new UpdateField(),
  new UnlinkField(),
  new UnlinkFieldOnPlainField(),
  new DeleteField(),
  new SortSectionField(),
  new MoveField()
] as ISectionFieldMethod[];

interface ISectionFieldMethod {
  name: 'FieldTypeToDropbox'
  | 'FieldTypeToSectionField'
  | 'UpdateField'
  | 'UnlinkField'
  | 'UnlinkFieldOnPlainField'
  | 'DeleteField'
  | 'SortFieldSection'
  | 'MoveField';
  updateSection(sectionList: any, fieldData: any, sectionUpdateConfig: any): any;
}

class SectionFieldManagerRegistry {
  registry: { [name: string]: ISectionFieldMethod } = {};

  constructor() {
    for (let method of methodList) {
      this.registry[method.name] = method;
    }
  }

  getMethod(name: string) {
    return (this.registry[name])
      ? this.registry[name].updateSection
      : undefined;
  }
}

export class SectionFieldManager {
  static updateSectionField = (sectionList: IFormBuilderSection[], fieldData: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    const dropTypeRegistry = new SectionFieldManagerRegistry();
    let method = dropTypeRegistry.getMethod(sectionUpdateConfig.updateType);
    if (method && method instanceof Function) {
      return method(sectionList, fieldData, sectionUpdateConfig)
    }
    return sectionList;
  }
}
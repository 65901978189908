import React from 'react';
import { IAutomation } from '../../../../interface/automation';
import { IAutomationChildComponent, IAutomationComponent } from '../../../../interface/component-list';
import IfElseBlock from './if-else-block';
import FieldCondition from './issue-fields';

const ComponentList: IConditionTypeRegistry[] = [
  new IfElseBlock(),
  new FieldCondition(),

]

export interface IConditionType {
  type: string;
}


export interface IConditionTypeRegistryRender {
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  selectedComponent: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  updateAutomationOnparent?(component: IAutomationComponent, number?: number): void;
  childIndex?: number;
  selectedChildComponent?: IAutomationChildComponent;
  refsObject: React.MutableRefObject<any>;
  type: string;
  setSelectedComponent(component: IAutomationComponent, selectedActionObject?: IAutomationChildComponent, childOnly?: boolean): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
  isHasParent: boolean;
}

interface IConditionTypeRegistry {
  name: string;
  render: React.FC<IConditionTypeRegistryRender>
}

export class ConditionTypeRegistry {

  registry: { [name: string]: IConditionTypeRegistry } = {};

  constructor() {
    const types = ComponentList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC<IConditionTypeRegistryRender> = () => {
    return null;
  }

  getModule(name: string): React.FC<IConditionTypeRegistryRender> {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IConditionTypeRegistryRender> = (props) => {
    const component = new ConditionTypeRegistry();
    const Render = component.getModule(props.type);
    return <Render {...props} />
  }

}
export const verifyIfStringIsJSON = (value: string) => {
  try {
    const pareseObject = JSON.parse(value);
    if (typeof pareseObject !== 'object') {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import { IField } from '../interface/field';
import ToolTip from '../../../../../common/general/tooltip';
import { sortNonCustomField } from '../../../../../common/utils/sort-non-custom-field';

interface IHeaderProps {
  staticField: IField[];
  dynamicField: IField[];
  userLoggedIn: any;
  companyId: string;
  openCustomField(): void;
  allowedCreateCustomField: boolean;
}

const tableHeaderAction: React.FC<IHeaderProps> = (props) => {

  return <tr className='header-name'>
    <th className='disabled th-bottom-user-info'>#</th>
    {
      props.staticField && sortNonCustomField(props.staticField).map((detail: IField) => {
        return <th key={`header-cell-2-${detail.id}`} className='disabled th-bottom-user-info'>{detail.label}</th>
      })
    }
    <th className='disabled th-bottom-user-info'>User Type</th>
    <th className='disabled th-bottom-user-info th-bottom-user-info-status'>Status</th>
    {
      props.dynamicField && props.dynamicField.map((detail: IField) => {
        return <th key={`header-cell-2-${detail.id}`} className={`th-bottom-user-info-other ${detail.accessType}`}>
          <span>{detail.label}</span>
          {detail.hint && <i className={`icon info-icon ${detail.id}`} title={detail.hint} />}
        </th>
      })
    }
    <th>
      {props.allowedCreateCustomField &&
        <ToolTip popupMessage={'Add'} popupTriggerComponent={
          <i id='button-action-plus' aria-hidden="true" className="plus icon table-action-plus" onClick={() => { props.openCustomField() }} />
        } />
      }
    </th>
  </tr>
}

export default tableHeaderAction;
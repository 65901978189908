import { ISlaStatusesDetailInput } from '../../../configuration/module/slas/interface/input/slaStatuses';
import { ISlaStatusesResponse } from '../../../configuration/module/slas/interface/response/slaStatuses';
import {
  CLEAR_SELECTED_AUTOMATION,
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_ERROR,
  CREATE_AUTOMATION_RETURN,
  DELETE_AUTOMATION,
  DELETE_AUTOMATION_RETURN,
  GET_AUTOMATION_CONTENT,
  GET_AUTOMATION_CONTENT_RETURN,
  GET_AUTOMATION_LIST,
  GET_AUTOMATION_LIST_RETURN,
  GET_BP_SELECTED_FORM_FIELD,
  GET_BP_SELECTED_FORM_FIELD_RETURN,
  GET_DATATABLE_INPUT_FIELD,
  GET_DATATABLE_INPUT_FIELD_RETURN,
  GET_FORM_FIELD,
  GET_FORM_FIELD_RETURN,
  GET_FORM_STATUS,
  GET_FORM_STATUS_RETURN,
  GET_INPUT_FIELD,
  GET_INPUT_FIELD_RETURN,
  GET_PUBLISH_FORM,
  GET_PUBLISH_FORM_RETURN,
  GET_REFERENCES,
  GET_REFERENCES_RETURN,
  GET_SLA_STATUS,
  GET_SLA_STATUS_RETURN,
  PUBLISH_AUTOMATION,
  PUBLISH_AUTOMATION_ERROR,
  PUBLISH_AUTOMATION_RETURN,
  UPDATE_AUTOMATION,
  UPDATE_AUTOMATION_ERROR,
  UPDATE_AUTOMATION_RETURN,
  GET_AUTOMATION_FIELD_INPUT,
  GET_AUTOMATION_FIELD_INPUT_RETURN,
  GET_AUTOMATION_PLACEHOLDERS,
  GET_AUTOMATION_PLACEHOLDERS_RETURN,
  GET_PORTALS,
  GET_PORTALS_RETURN,
} from './constant';
import { IAutomationCreateDetailInput } from './interface/input/automation-create';
import { IAutomationDeleteDetailInput } from './interface/input/automation-delete';
import { IAutomationGetContentDetailInput } from './interface/input/automation-get-content';
import { IAutomationListGetDetailInput } from './interface/input/automation-list-get';
import { IAutomationPublishDetailInput } from './interface/input/automation-publish';
import { IAutomationUpdateDetailInput } from './interface/input/automation-update';
import { IFieldInputGetDetailInput } from './interface/input/field-input-get';
import { IFormFieldGetDetailInput } from './interface/input/form-field-get';
import { IFormGetDetailInput } from './interface/input/form-get';
import { IFormtatusGetDetailInput } from './interface/input/form-status-get';
import { IReferenceGetDetailInput } from './interface/input/reference-get';
import { IAutomationCreateResponse } from './interface/response/automation-create';
import { IAutomationDeleteResponse } from './interface/response/automation-delete';
import { IAutomationGetContentResponse } from './interface/response/automation-get-content';
import { IAutomationListResponse } from './interface/response/automation-list-get';
import { IAutomationUpdateResponse } from './interface/response/automation-update';
import { IFieldInputGetResponse } from './interface/response/field-input-get';
import { IFormFieldResponse } from './interface/response/form-field-get';
import { IFormGetResponse } from './interface/response/form-get';
import { IFormStatusGetResponse } from './interface/response/form-status-get';
import { IReferenceGerResponse } from './interface/response/reference-get';
import { IAutomationFieldInputGetResponse } from './interface/response/field-input-get';
import { IPlaceholderGetResponse } from './interface/response/placeholder-get';
import { IPortal } from './interface/portals';

export const createAutomationAction = (
  payload: IAutomationCreateDetailInput
) => {
  return {
    type: CREATE_AUTOMATION,
    ...payload,
  };
};

export const createAutomationReturnAction = (
  payload: IAutomationCreateResponse
) => {
  return {
    type: CREATE_AUTOMATION_RETURN,
    ...payload,
  };
};

export const createAutomationErrorAction = (error: string) => {
  return {
    type: CREATE_AUTOMATION_ERROR,
    saveAutomationErrorMessage: error,
  };
};

export const getAutomationContentAction = (
  payload: IAutomationGetContentDetailInput
) => {
  return {
    type: GET_AUTOMATION_CONTENT,
    ...payload,
  };
};

export const getAutomationContentReturnAction = (
  payload: IAutomationGetContentResponse
) => {
  return {
    type: GET_AUTOMATION_CONTENT_RETURN,
    ...payload,
  };
};

export const updateAutomationAction = (
  payload: IAutomationUpdateDetailInput
) => {
  return {
    type: UPDATE_AUTOMATION,
    ...payload,
  };
};

export const updateAutomationErrorAction = (error: string) => {
  return {
    type: UPDATE_AUTOMATION_ERROR,
    updateAutomationErrorMessage: error,
  };
};

export const updateAutomationReturnAction = (
  payload: IAutomationUpdateResponse
) => {
  return {
    type: UPDATE_AUTOMATION_RETURN,
    ...payload,
  };
};

export const deleteAutomationAction = (
  payload: IAutomationDeleteDetailInput
) => {
  return {
    type: DELETE_AUTOMATION,
    ...payload,
  };
};

export const deleteAutomationReturnAction = (
  payload: IAutomationDeleteResponse
) => {
  return {
    type: DELETE_AUTOMATION_RETURN,
    ...payload,
  };
};

export const getFormFieldAction = (payload: IFormFieldGetDetailInput) => {
  return {
    type: GET_FORM_FIELD,
    ...payload,
  };
};

export const getFormFieldReturnAction = (payload: IFormFieldResponse) => {
  return {
    type: GET_FORM_FIELD_RETURN,
    ...payload,
  };
};

export const getPublishFormAction = (payload: IFormGetDetailInput) => {
  return {
    type: GET_PUBLISH_FORM,
    ...payload,
  };
};

export const getPublishFormReturnAction = (payload: IFormGetResponse) => {
  return {
    type: GET_PUBLISH_FORM_RETURN,
    ...payload,
  };
};

export const getFormStatusAction = (payload: IFormtatusGetDetailInput) => {
  return {
    type: GET_FORM_STATUS,
    ...payload,
  };
};

export const getFormStatusReturnAction = (payload: IFormStatusGetResponse) => {
  return {
    type: GET_FORM_STATUS_RETURN,
    ...payload,
  };
};

export const getSelectedBPFormFieldAction = (
  payload: IFormFieldGetDetailInput
) => {
  return {
    type: GET_BP_SELECTED_FORM_FIELD,
    ...payload,
  };
};

export const getSelectedBPFormFieldReturnAction = (
  payload: IFormFieldResponse
) => {
  return {
    type: GET_BP_SELECTED_FORM_FIELD_RETURN,
    ...payload,
  };
};

export const getInputFieldAction = (payload: IFieldInputGetDetailInput) => {
  return {
    type: GET_INPUT_FIELD,
    ...payload,
  };
};

export const getInputFieldReturnAction = (payload: IFieldInputGetResponse) => {
  return {
    type: GET_INPUT_FIELD_RETURN,
    ...payload,
  };
};

export const getReferenceListAction = (payload: IReferenceGetDetailInput) => {
  return {
    type: GET_REFERENCES,
    ...payload,
  };
};

export const getReferenceListReturnAction = (
  payload: IReferenceGerResponse
) => {
  return {
    type: GET_REFERENCES_RETURN,
    ...payload,
  };
};

export const getDatatableInputFieldAction = (
  payload: IFieldInputGetDetailInput
) => {
  return {
    type: GET_DATATABLE_INPUT_FIELD,
    ...payload,
  };
};

export const getDatatableInputFieldReturnAction = (
  payload: IFieldInputGetResponse
) => {
  return {
    type: GET_DATATABLE_INPUT_FIELD_RETURN,
    ...payload,
  };
};

export const getAutomationlistAction = (
  payload: IAutomationListGetDetailInput
) => {
  return {
    type: GET_AUTOMATION_LIST,
    ...payload,
  };
};

export const getAutomationlistReturnAction = (
  payload: IAutomationListResponse
) => {
  return {
    type: GET_AUTOMATION_LIST_RETURN,
    ...payload,
  };
};

export const publishAutomationAction = (
  payload: IAutomationPublishDetailInput
) => {
  return {
    type: PUBLISH_AUTOMATION,
    ...payload,
  };
};

export const publishAutomationReturnAction = () => {
  return {
    type: PUBLISH_AUTOMATION_RETURN,
  };
};

export const publishAutomationErrorAction = (error: string) => {
  return {
    type: PUBLISH_AUTOMATION_ERROR,
    publishAutomationErrorMessage: error,
  };
};

export const clearSelectedAutomation = () => {
  return {
    type: CLEAR_SELECTED_AUTOMATION,
  };
};

export const getSlaStatusAction = (payload: ISlaStatusesDetailInput) => {
  return { type: GET_SLA_STATUS, ...payload };
};

export const getSlaStatusReturnAction = (payload: ISlaStatusesResponse) => {
  return { type: GET_SLA_STATUS_RETURN, ...payload };
};

export const getAutomationInputFieldAction = (payload: IFormFieldGetDetailInput) => {
  return { type: GET_AUTOMATION_FIELD_INPUT, ...payload };
};

export const getAutomationInputFieldReturnAction = (payload: IAutomationFieldInputGetResponse) => {
  return { type: GET_AUTOMATION_FIELD_INPUT_RETURN, ...payload };
};

export const getAutomationPlaceholdersAction = (payload: IFormFieldGetDetailInput) => {
  return { type: GET_AUTOMATION_PLACEHOLDERS, ...payload };
}

export const getAutomationPlaceholdersReturnAction = (payload: IPlaceholderGetResponse) => {
  return { type: GET_AUTOMATION_PLACEHOLDERS_RETURN, ...payload };
}

export const getPortalsAction = (payload: { companyId: string }) => {
  return { type: GET_PORTALS, ...payload };

}

export const getPortalsReturnAction = (payload: { portals: IPortal[] }) => {
  return { type: GET_PORTALS_RETURN, ...payload };

}
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IField } from '../../../../../users/interface/field';
import message from '../../../../message';
import { IReference } from '../../../configuration/module/bp-relationship/interface/form';
import { ISlasAndStatuses } from '../../../configuration/module/slas/interface/slaAndStatuses';
import { IForm } from '../../../form/interface/form';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import {
  CLEAR_SELECTED_AUTOMATION,
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_ERROR,
  CREATE_AUTOMATION_RETURN,
  DELETE_AUTOMATION,
  DELETE_AUTOMATION_RETURN,
  GET_AUTOMATION_CONTENT,
  GET_AUTOMATION_CONTENT_RETURN,
  GET_AUTOMATION_LIST,
  GET_AUTOMATION_LIST_RETURN,
  GET_BP_SELECTED_FORM_FIELD,
  GET_BP_SELECTED_FORM_FIELD_RETURN,
  GET_DATATABLE_INPUT_FIELD_RETURN,
  GET_FORM_FIELD_RETURN,
  GET_FORM_STATUS_RETURN,
  GET_INPUT_FIELD_RETURN,
  GET_PUBLISH_FORM,
  GET_PUBLISH_FORM_RETURN,
  GET_REFERENCES,
  GET_REFERENCES_RETURN,
  GET_SLA_STATUS_RETURN,
  PUBLISH_AUTOMATION_ERROR,
  PUBLISH_AUTOMATION_RETURN,
  RESET_PUBLISH_AUTOMATION_MESSAGE,
  UPDATE_AUTOMATION,
  UPDATE_AUTOMATION_ERROR,
  UPDATE_AUTOMATION_RETURN,
  GET_AUTOMATION_FIELD_INPUT_RETURN,
  GET_AUTOMATION_PLACEHOLDERS_RETURN,
  GET_PORTALS_RETURN,
} from './constant';
import { IAutomation, IAutomationFieldInput, IAutomationPlaceholder } from './interface/automation';
import { IPortal } from './interface/portals';

export interface IAutomationInputFieldList {
  [name: string]: IField[];
}
export interface IAutomationPublishStatusList {
  [name: string]: IFormStatusWithAction[];
}

export interface IAutomationReducer {
  type?: string;
  draft?: boolean;
  inputFieldId?: string;
  inputField?: IField[];
  formId?: string;
  formStatusList?: IFormStatusWithAction[];
  automation?: IAutomation;
  automationId?: string;
  automationList: IAutomation[];
  createAutomationLoading: boolean;
  selectedAutomation?: IAutomation;
  getAutomationContentLoading: boolean;
  updateAutomationLoading: boolean;
  deleteAutomationLoading: boolean;
  formFieldList: ICustomFieldDetail[];
  publishedFormList: IForm[];
  publishedFormStatusList: IAutomationPublishStatusList;
  draftFormStatusList: IAutomationPublishStatusList;
  selectedBPFormField: ICustomFieldDetail[];
  inputFieldList: IAutomationInputFieldList;
  referenceList: IReference[];
  datatableInputField: IAutomationInputFieldList;
  publishAutomationSuccessMessage: string;
  publishAutomationErrorMessage: string;
  saveAutomationSuccessMessage: string;
  saveAutomationErrorMessage: string;
  updateAutomationSuccessMessage: string;
  updateAutomationErrorMessage: string;
  triggerCloseAutomationModal: boolean;
  slaStatusList?: ISlasAndStatuses;
  automationFieldInput: IAutomationFieldInput[];
  automationPlaceholders: IAutomationPlaceholder[];
  portals: IPortal[];
}

const initialState: IAutomationReducer = {
  automationList: [],
  createAutomationLoading: false,
  selectedAutomation: undefined,
  getAutomationContentLoading: false,
  updateAutomationLoading: false,
  deleteAutomationLoading: false,
  formFieldList: [],
  publishedFormList: [],
  publishedFormStatusList: {},
  draftFormStatusList: {},
  selectedBPFormField: [],
  inputFieldList: {},
  referenceList: [],
  datatableInputField: {},
  publishAutomationSuccessMessage: '',
  publishAutomationErrorMessage: '',
  saveAutomationSuccessMessage: '',
  saveAutomationErrorMessage: '',
  updateAutomationSuccessMessage: '',
  updateAutomationErrorMessage: '',
  triggerCloseAutomationModal: false,
  slaStatusList: undefined,
  automationFieldInput: [],
  automationPlaceholders: [],
  portals: [],
};

const automationReducer = (state = initialState, action: IAutomationReducer) => {
  let newState = { ...state };
  let tempAutomationList = [...newState.automationList];

  switch (action.type) {
    case CREATE_AUTOMATION:
      newState.createAutomationLoading = true;
      return newState;

    case CREATE_AUTOMATION_RETURN:
      if (action.automation) {
        tempAutomationList.push(action.automation)
        newState.automationList = tempAutomationList;
        newState.saveAutomationSuccessMessage = message.saveAutomationSuccess;
      }
      newState.createAutomationLoading = false;
      newState.triggerCloseAutomationModal = true;
      return newState;

    case CREATE_AUTOMATION_ERROR:
      newState.saveAutomationErrorMessage = action.saveAutomationErrorMessage;
      newState.createAutomationLoading = false;
      return newState;

    case UPDATE_AUTOMATION:
      newState.updateAutomationLoading = true;
      return newState;

    case UPDATE_AUTOMATION_RETURN:
      if (action.automation) {
        const filteredAutomation = tempAutomationList.filter((e: IAutomation) => action.automation && action.automation.id === e.id);
        if (filteredAutomation.length > 0) {
          const filteredAutomationIndex = tempAutomationList.indexOf(filteredAutomation[0]);
          tempAutomationList.splice(filteredAutomationIndex, 1, { ...action.automation })
          newState.automationList = tempAutomationList;
          newState.updateAutomationSuccessMessage = message.updateAutomationSuccess;
          newState.triggerCloseAutomationModal = true;
        }
      }
      newState.updateAutomationLoading = false;
      return newState;

    case UPDATE_AUTOMATION_ERROR:
      newState.updateAutomationErrorMessage = action.updateAutomationErrorMessage;
      newState.updateAutomationLoading = false;
      return newState;

    case DELETE_AUTOMATION:
      newState.deleteAutomationLoading = true;
      return newState;

    case DELETE_AUTOMATION_RETURN:
      if (action.automationId) {
        const filteredAutomation = tempAutomationList.filter((e: IAutomation) => action.automationId === e.id);
        if (filteredAutomation.length > 0) {
          const filteredAutomationIndex = tempAutomationList.indexOf(filteredAutomation[0]);
          tempAutomationList.splice(filteredAutomationIndex, 1)
          newState.automationList = tempAutomationList;
        }
      }
      newState.deleteAutomationLoading = false;
      return newState;

    case GET_AUTOMATION_CONTENT:
      newState.triggerCloseAutomationModal = false;
      newState.getAutomationContentLoading = true;
      return newState;

    case GET_AUTOMATION_CONTENT_RETURN:
      newState.selectedAutomation = action.automation;
      newState.getAutomationContentLoading = false;
      return newState;

    case GET_FORM_FIELD_RETURN:
      newState.formFieldList = action.formFieldList;
      return newState;

    case GET_PUBLISH_FORM:
      newState.publishedFormList = [];
      return newState;

    case GET_PUBLISH_FORM_RETURN:
      newState.publishedFormList = action.publishedFormList;
      return newState;

    case GET_FORM_STATUS_RETURN:
      if (action.draft) {
        newState.draftFormStatusList[action.formId || ''] = action.formStatusList || [];
      } else {
        newState.publishedFormStatusList[action.formId || ''] = action.formStatusList || [];
      }
      return newState;

    case GET_BP_SELECTED_FORM_FIELD:
      newState.selectedBPFormField = [];
      return newState;

    case GET_BP_SELECTED_FORM_FIELD_RETURN:
      newState.selectedBPFormField = action.formFieldList || [];
      return newState;

    case GET_INPUT_FIELD_RETURN:
      newState.inputFieldList[action.inputFieldId || ''] = action.inputField || [];
      return newState;

    case GET_REFERENCES:
      newState.referenceList = [];
      return newState;

    case GET_REFERENCES_RETURN:
      newState.referenceList = action.referenceList || [];
      return newState;

    case GET_DATATABLE_INPUT_FIELD_RETURN:
      newState.datatableInputField[action.inputFieldId || ''] = action.inputField || [];
      return newState;

    case GET_AUTOMATION_LIST:
      newState.automationList = [];
      return newState;

    case GET_AUTOMATION_LIST_RETURN:
      newState.automationList = action.automationList || [];
      return newState;

    case PUBLISH_AUTOMATION_RETURN:
      newState.publishAutomationSuccessMessage = message.publishFormBuilderSuccess;
      return newState;

    case PUBLISH_AUTOMATION_ERROR:
      newState.publishAutomationErrorMessage = action.publishAutomationErrorMessage;
      return newState;

    case RESET_PUBLISH_AUTOMATION_MESSAGE:
      newState.publishAutomationSuccessMessage = '';
      newState.publishAutomationErrorMessage = '';
      newState.saveAutomationErrorMessage = '';
      newState.saveAutomationSuccessMessage = '';
      newState.updateAutomationErrorMessage = '';
      newState.updateAutomationSuccessMessage = '';
      newState.triggerCloseAutomationModal = false;
      return newState;

    case CLEAR_SELECTED_AUTOMATION:
      newState.triggerCloseAutomationModal = false;
      newState.selectedAutomation = undefined;
      return newState;

    case GET_SLA_STATUS_RETURN:
      newState.slaStatusList = action.slaStatusList;
      return newState;

    case GET_AUTOMATION_FIELD_INPUT_RETURN:
      newState.automationFieldInput = action.automationFieldInput;
      return newState;

    case GET_AUTOMATION_PLACEHOLDERS_RETURN:
      newState.automationPlaceholders = action.automationPlaceholders;
      return newState;

    case GET_PORTALS_RETURN:
      newState.portals = action.portals;
      return newState;

    default:
      return newState;
  }
}

export default automationReducer
import {
  GET_FORM_FIELDS,
  GET_FORM_FIELDS_RETURN,
  GET_FORM_FIELDS_ERROR,
  GET_DUPLICATE_CHECKER,
  GET_DUPLICATE_CHECKER_ERROR,
  GET_DUPLICATE_CHECKER_RETURN,
  PUBLISH_DUPLICATE_CHECKER,
  PUBLISH_DUPLICATE_CHECKER_RETURN,
  PUBLISH_DUPLICATE_CHECKER_ERROR,
  SAVE_DUPLICATE_CHECKER,
  SAVE_DUPLICATE_CHECKER_RETURN,
  SAVE_DUPLICATE_CHECKER_ERROR,
  CLEAR_SAVE_DUPLICATE_CHECKER_ERROR,
  CLEAR_SAVE_DUPLICATE_CHECKER_SUCCESS
} from './constant';
import { IOption } from '../../../../../../../../common/interface/option';
import { IDuplicateChecker } from './interface/duplicate-checker';
import { message } from './message';

export interface IDuplicateCheckerReducer {
  formFieldsOptionList: IOption[];
  formFieldsLoading: boolean;
  formFieldsError: string;
  saveDuplicateCheckerLoading: boolean;
  saveDuplicateCheckerError: string;
  saveDuplicateCheckerMessage: string;
  duplicateCheckerSaveType: string;
  duplicateChecker: IDuplicateChecker;
  duplicateCheckerLoading: boolean;
  duplicateCheckerError: string;
}

const initialState: IDuplicateCheckerReducer = {
  formFieldsOptionList: [] as IOption[],
  formFieldsLoading: false,
  formFieldsError: '',
  saveDuplicateCheckerError: '',
  saveDuplicateCheckerLoading: false,
  saveDuplicateCheckerMessage: '',
  duplicateCheckerSaveType: '',
  duplicateChecker: {} as IDuplicateChecker,
  duplicateCheckerLoading: false,
  duplicateCheckerError: ''
}

const duplicateCheckerReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_FIELDS:
      newState.formFieldsLoading = true;
      newState.formFieldsError = '';
      newState.formFieldsOptionList = [];
      return newState;

    case GET_FORM_FIELDS_RETURN:
      newState.formFieldsLoading = false;
      newState.formFieldsError = '';
      newState.formFieldsOptionList = action.data;
      return newState;

    case GET_FORM_FIELDS_ERROR:
      newState.formFieldsLoading = false;
      newState.formFieldsError = action.message;
      return newState;

    case GET_DUPLICATE_CHECKER:
      newState.duplicateCheckerLoading = true;
      newState.duplicateCheckerError = '';
      return newState;

    case GET_DUPLICATE_CHECKER_RETURN:
      newState.duplicateCheckerLoading = false;
      newState.duplicateChecker = action.duplicateChecker;
      return newState;

    case GET_DUPLICATE_CHECKER_ERROR:
      newState.duplicateCheckerError = action.message;
      newState.duplicateCheckerLoading = false;
      return newState;

    case SAVE_DUPLICATE_CHECKER:
      newState.saveDuplicateCheckerError = '';
      newState.saveDuplicateCheckerLoading = true;
      newState.saveDuplicateCheckerMessage = '';
      return newState;

    case SAVE_DUPLICATE_CHECKER_RETURN:
      if (action.duplicateCheckerSaveType === 'draft') {
        newState.saveDuplicateCheckerLoading = false;
        newState.saveDuplicateCheckerMessage = message.saveDuplicateChecker;
      }
      newState.duplicateCheckerSaveType = action.duplicateCheckerSaveType;
      newState.duplicateChecker = action.duplicateChecker;
      return newState;

    case SAVE_DUPLICATE_CHECKER_ERROR:
      newState.saveDuplicateCheckerError = action.message;
      newState.saveDuplicateCheckerLoading = false;
      return newState;

    case PUBLISH_DUPLICATE_CHECKER:
      newState.duplicateCheckerSaveType = '';
      newState.saveDuplicateCheckerLoading = true;
      return newState;

    case PUBLISH_DUPLICATE_CHECKER_RETURN:
      newState.saveDuplicateCheckerLoading = false;
      newState.saveDuplicateCheckerMessage = message.publishDuplicateChecker;
      return newState;

    case PUBLISH_DUPLICATE_CHECKER_ERROR:
      newState.saveDuplicateCheckerError = action.message;
      newState.saveDuplicateCheckerLoading = false;
      return newState;

    case CLEAR_SAVE_DUPLICATE_CHECKER_SUCCESS:
      newState.saveDuplicateCheckerMessage = '';
      return newState;

    case CLEAR_SAVE_DUPLICATE_CHECKER_ERROR:
      newState.saveDuplicateCheckerError = '';
      return newState;

    default:
      return state;
  }
}

export default duplicateCheckerReducer;
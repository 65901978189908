import { ICustomFieldDetail, IConfig } from '../interface/custom-field-detail';
import DateType from '../../field/type/date';
import StringType from '../../field/type/text-string';
import DropdownType from '../../field/type/dropdown';
import BoolType from '../../field/type/switch';

export const GET_REFERENCED_FIELD_LIST =
  'app/admin-module/business-process/formBuilder/filter-configuration/GET_REFERENCED_FIELD_LIST';
export const GET_REFERENCED_FIELD_LIST_RETURN =
  'app/admin-module/business-process/formBuilder/filter-configuration/GET_REFERENCED_FIELD_LIST_RETURN';
export const GET_SELECTED_BP_STATUS_LIST =
  'app/admin-module/business-process/formBuilder/filter-configuration/GGET_SELECTED_BP_STATUS_LIST';
export const GET_SELECTED_BP_STATUS_LIST_RETURN =
  'app/admin-module/business-process/formBuilder/filter-configuration/GET_SELECTED_BP_STATUS_LIST_RETURN';
export const GET_FROM_REF_FIELD_LIST =
  'app/admin-module/business-process/formBuilder/filter-configuration/GET_FROM_REF_FIELD_LIST';
export const GET_FROM_REF_FIELD_LIST_RETURN =
  'app/admin-module/business-process/formBuilder/filter-configuration/GET_FROM_REF_FIELD_LIST_RETURN';

export const systemField: ICustomFieldDetail[] = [
  {
    ...DropdownType.defaultData,
    type: 'enum',
    id: 'statusId',
    label: 'Status',
    config: { ...DropdownType.defaultConfig, multiselect: false } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...DateType.defaultData,
    type: 'date',
    id: 'createdAt',
    label: 'Doc. Creation Date',
    config: undefined,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...DateType.defaultData,
    type: 'date',
    id: 'updatedAt',
    label: 'Last Update Date',
    config: undefined,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...StringType.defaultData,
    type: 'string',
    id: 'creator',
    label: 'Doc. Creator',
    config: { ...StringType.defaultConfig } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...StringType.defaultData,
    type: 'string',
    id: 'updatedBy',
    label: 'Last Updated By',
    config: { ...StringType.defaultConfig } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...StringType.defaultData,
    type: 'string',
    id: 'owner',
    label: 'Owner',
    config: { ...StringType.defaultConfig } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...StringType.defaultData,
    type: 'string',
    id: 'updater',
    label: 'Last Updated By',
    config: { ...StringType.defaultConfig } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
  {
    ...BoolType.defaultData,
    type: 'bool',
    id: 'deleted',
    label: 'Deleted',
    config: { ...BoolType.defaultConfig } as IConfig,
    updateField: { itemType: '', itemConfig: {} as IConfig },
  },
];

export const IDFilterField: ICustomFieldDetail = {
  ...StringType.defaultData,
  type: 'id',
  id: 'id',
  label: 'ID',
  config: { ...StringType.defaultConfig } as IConfig,
  updateField: { itemType: '', itemConfig: {} as IConfig },
};
export const URNFilterField: ICustomFieldDetail = {
  ...StringType.defaultData,
  type: 'string',
  id: 'urn',
  label: 'URN',
  config: { ...StringType.defaultConfig } as IConfig,
  updateField: { itemType: '', itemConfig: {} as IConfig },
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router';
import { Container } from 'semantic-ui-react-bpm';
import { currentRouteAction, setSubRouteAction } from '../../../main/action';
import PortalSHProfileFilter from './filter';
import PortalSHProfileForm from './form';
import PortalSHProfile from './list';


class Stakeholders {

  name = 'stakeholders';

  render: React.FC = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        setSubRouteAction({
          mainRoute: 'PORTAL',
          subRoute: this.name
        })
      );
      dispatch(currentRouteAction(this.name));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
      <Route path={`${match.path}`}>
        <Container className='portal-module-container' fluid>
          <div className='portal-sh-profile-container'>
            <PortalSHProfileFilter />
            <PortalSHProfile />
          </div>
        </Container>
      </Route>
      <Route exact path={`${match.path}/:stakeholderId`}>
        <PortalSHProfileForm />
      </Route>
    </>
  }

}

export default Stakeholders;
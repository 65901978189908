import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import LineArrayType from '../type/string/line-array';
import LineType from '../type/string/line';

const Line: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <LineArrayType {...props} />;
  } else {
    return <LineType {...props} />
  }
}

export default Line;
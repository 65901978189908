export const isEllipsisActive = (element: any) => {
  return element.scrollHeight > element.offsetHeight ||
    element.scrollWidth > element.offsetWidth;
}

export const setLabelRef = (element: HTMLLabelElement | null, fieldId: string, hasInfo?: boolean) => {
  const hintEl = document.getElementById(`info-${fieldId}`);
  if (!element) return;
  if (element.parentElement && element.parentElement.parentElement) {
    if (element.scrollHeight > 32 && element.parentElement.childElementCount > 1
      && !(element.parentElement.parentElement.className.includes('field-type-array'))) {
      if (!element.parentElement.parentElement.className.includes('Multiline')) {
        element.parentElement.parentElement.style.setProperty('grid-row-gap', 'unset', 'important');
      }
    }
  }
  if (isEllipsisActive(element)) {
    element.title = element.innerText;
  } else {
    element.style.setProperty('height', 'max-content', 'important');
    if (hintEl) {
      hintEl.style.setProperty('position', 'relative');
      hintEl.style.setProperty('top', '5px');
    }
  } 
}
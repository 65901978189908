import React from 'react';
import { IAutomationChildComponent, IAutomationComponent } from '../../../interface/component-list';
import AutomationOutlineAction from './action';
import AutomationOutlineCondition from './condition';
import AutomationOutlineTrigger from './trigger';

const ComponentList = [
  new AutomationOutlineTrigger(),
  new AutomationOutlineAction(),
  new AutomationOutlineCondition(),
]

export interface IRender {
  type: string;
  setSelectedComponent(component: IAutomationComponent, selectedActionObject?: IAutomationChildComponent): void;
  component: IAutomationComponent;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  deleteCreatedComponent(type: string, parentIndex: number, childProperty?: any): void;
  currentIndex: number;
  hasComponentChange: React.MutableRefObject<boolean>;
  setAlertMessage(value: string): void;
  selectedComponent: IAutomationComponent | undefined;
  selectedChildComponent?: IAutomationChildComponent;
}

interface IAutomationOutlineComponentRegistry {
  name: string;
  render: React.FC<any>
}

export class AtomationOutlineComponentRegistry {

  registry: { [name: string]: IAutomationOutlineComponentRegistry } = {};

  constructor() {
    const types = ComponentList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return null;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const component = new AtomationOutlineComponentRegistry();
    const Render = component.getModule(props.type);
    return <Render {...props} />
  }

}
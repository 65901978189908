import { useEffect, useState } from 'react';
import { IFormAction } from '../../interface/form-action';

interface IProps {
  selectedAction: IFormAction;
  actionNameRef: React.MutableRefObject<HTMLInputElement>;
}

export const useSetActionToStateAndRef = (props: IProps) => {
  const { actionNameRef, selectedAction } = { ...props };

  const [assignmentType, setAssignmentType] = useState('');
  const [selectedActionId, setSelectedId] = useState('');
  const [returnAction, setReturnAction] = useState(false);

  useEffect(() => {
    actionNameRef.current.value = props.selectedAction.name;
    setSelectedId(props.selectedAction.id);
    const assignmentTypeValue = props.selectedAction.config && props.selectedAction.config.assignmentType && props.selectedAction.config.direction
      ? `${props.selectedAction.config.direction}-${props.selectedAction.config.assignmentType}`
      : undefined;
    setAssignmentType(assignmentTypeValue || '');
    setReturnAction((props.selectedAction.config && props.selectedAction.config.return) || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction]);

  return {
    assignmentType, setAssignmentType, selectedActionId, setSelectedId,
    returnAction, setReturnAction, ...props
  };
}
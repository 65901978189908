import React from 'react';
import { useDrop } from 'react-dnd';
import { IFormBuilderSection } from '../../interface/section';
import { DragFieldObject } from '../../section-field-manager/interface/section-update-config';

interface ISectionBody {
  section: IFormBuilderSection;
  addFieldToSection(dragItem: DragFieldObject, sectionId?: string): void;
}

const DragDrop: React.FC<ISectionBody> = (props) => {

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'field',
    drop: (item: DragFieldObject) => {
      props.addFieldToSection(item, props.section.sectionId)
    }
  })
  return <div ref={dropRef} className="drag-and-drop" >
    Drag and drop fields here
    </div>
}

export default DragDrop;

import { useEffect } from 'react';
import { IDisplayData } from '../interface/display-data';
import { IDisplayDataConfigAssignments } from '../interface/display-data-assignments';

interface IProps {
  setDisplayDataList: (value: IDisplayData[]) => void;
  displayData: IDisplayData[];
  displayDataAssignments: IDisplayDataConfigAssignments[];
  setDisplayDataApplied: (value: IDisplayDataConfigAssignments[]) => void;
  displayDataList: IDisplayData[];
}

export const useSetDisplayDataState = (props: IProps): void => {
  const { displayData, setDisplayDataList, displayDataAssignments, setDisplayDataApplied, displayDataList } = { ...props };

  useEffect(() => {
    const displayDataList = [...displayData];
    setDisplayDataList(displayDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData]);

  useEffect(() => {
    let displayDataAssignmentList = [...displayDataAssignments];
    setDisplayDataApplied(displayDataAssignmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayDataAssignments, displayDataList]);
}
import React from 'react';
import TableListDetail from './table-list-detail';
import { IUser } from '../interface/user';
import { IField } from '../interface/field';

interface IList {
  user: IUser[];
  staticField: IField[];
  dynamicField: IField[];
  openDeleteuserConfirmation?: any;
  openUpdateUserFormModal?: any;
  userLoggedIn: any;
  companyId: string;
}

const tableList: React.FC<IList> = (props) => {
  return <tbody>{
    props.user &&
    props.user.map((user: any) => {
      return <TableListDetail
        openUpdateUserFormModal={props.openUpdateUserFormModal}
        key={`detail-row-${user.id}`}
        user={user}
        staticField={props.staticField}
        dynamicField={props.dynamicField}
        openDeleteuserConfirmation={props.openDeleteuserConfirmation}
        userLoggedIn={props.userLoggedIn}
        companyId={props.companyId}
      />
    })
  }
  </tbody>
}

export default tableList;
import React, { useRef } from 'react';
import SwitchCustomField from './switch-field';
import DateCustomField from './date-field';
import TimeCustomField from './time-field';
import StringCustomField from './string-field';
import IntegerCustomField from './integer-field';
import DropdownCustomField from './dropdown-field';
import { IFieldData } from './interface/field-data';
import { FieldElement } from '../component/field-element';
import ArrayField from './array-field';
import TableField from './table-field';
import FileField from './file-field';
import DateTime from './date-time-field';
import RangeCustomField from './range-field';
import { useRect } from '../../utils/useRect';

interface IDynamicFieldRender {
  data: IFieldData;
  detail: FieldElement;
  overflowed?: boolean;
  multiline?: boolean;
  useChip?: boolean;
}

export class DynamicField {

  static Render: React.FC<IDynamicFieldRender> = (props) => {
    const containerRef = useRef<any>();
    const containerRect = useRect(containerRef);

    const type = props.detail?.inputConfig?.type;

    let element = <div></div>;

    switch (type) {
      case 'enum':
        element = <DropdownCustomField {...props} containerRect={containerRect} />
        break;
      case 'number':
        element = <IntegerCustomField {...props} />
        break;
      case 'fx':
        element = <IntegerCustomField {...props} />
        break;
      case 'string':
        element = <StringCustomField {...props} />
        break;
      case 'autopopulated':
        element = <StringCustomField {...props} />
        break;
      case 'time':
        element = <TimeCustomField {...props} />
        break;
      case 'date':
        element = <DateCustomField {...props} />
        break;
      case 'bool':
        element = <SwitchCustomField {...props} />
        break;
      case 'array':
        element = <ArrayField {...props} />
        break;
      case 'table':
        element = <TableField {...props} />
        break;
      case 'file':
        element = <FileField {...props} />
        break;
      case 'date-time':
        element = <DateTime {...props} />
        break;
      case 'range':
        element = <RangeCustomField {...props} />
        break;
      default:
        return element;
    }

    if (props.data && props.detail && !props.data.fields[props.detail.id]) {
      element = <div></div>;
    }

    return <div className={`field-${type}-container`} ref={containerRef}>{element}</div>
  }
}
export default DynamicField;
import React from 'react';
import SectionBody from './section-body';
import DragSection from './drag-section'
import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { DragFieldObject, DragSectionObject } from '../../section-field-manager/interface/section-update-config';

interface ISection {
  section: IFormBuilderSection;
  changeSectionSequence(dragItem: DragSectionObject, dropContainerId: string): void;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const PlainSection: React.FC<ISection> = (props) => {

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'section',
    drop: (item: any) => {
      props.changeSectionSequence(item, props.section.sectionId || '')
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'section', data: props.section },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  let fieldWithoutDeletedField: ICustomFieldDetail[] = [];
  props.section.fields.forEach((fieldContainer: TFormBuilderSectionRow) => {
    fieldContainer.forEach((field: ICustomFieldDetail) => {

      if (!field.deleted) {
        fieldWithoutDeletedField.push(field);
      }
    });
  })


  return <>
    {fieldWithoutDeletedField.length > 0 &&
      <div ref={dropRef}
        style={{
          opacity: draggedObject.opacity,
        }}
        className="section plain"
      >
        <div ref={dragRef}>
          <DragSection section={props.section} dragRef={dragRef} />
          <>
            <SectionBody
              section={props.section}
              addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
              editFieldConfig={props.editFieldConfig}
              openDeleteConfirmationModal={props.openDeleteConfirmationModal}
              unlinkField={props.unlinkField}
              unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
              sortFieldSection={props.sortFieldSection}
              moveField={props.moveField}
              bpImage={props.bpImage}
            />
          </>
        </div>
      </div>
    }

  </>
}

export default PlainSection;

import React from 'react';
import Field from '../field-selection/field';
import { IColumnView, IDragColumnView } from '../interface/custom-view';
import { useDrop } from 'react-dnd';
import { v4 as uuid } from 'uuid';

interface ISelectedFieldContainerProps {
  customViewList: IColumnView[];
  addCustomViewList(selectedItem: IDragColumnView): void;
  arrangeSequence(selectedItem: IDragColumnView, field: IColumnView): void;
  onClick?(field: IColumnView): void;
}

const SelectedFieldContainer: React.FC<ISelectedFieldContainerProps> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'column-field',
    drop: (selectedItem: IDragColumnView) => {
      if (selectedItem.data.new === true) {
        props.addCustomViewList(selectedItem);
      }
    }
  })
  return (
    <div ref={dropRef} className='selected-field-container'>
      {
        props.customViewList.map((field: IColumnView) => {
          return <Field arrangeSequence={props.arrangeSequence} onClick={props.onClick} allowDrop={true} key={`selected-field-key-${uuid()}`} field={field} />
        })
      }
    </div>
  );
}

export default SelectedFieldContainer;
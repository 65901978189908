import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../common/icons/svg/svg-wrapper';
import { IHasPageChangeRef } from '../../../../../main/interface/has-page-change';
import { tempStakeholdersData } from './constant';

interface IStakeholdersView {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const StakeholdersView: React.FC<IStakeholdersView> = (props) => {

  const accessTypeComponent = (accessType: string[]) => {
    const noAccess = accessType.includes('none');
    const hasView = accessType.includes('view');
    const hasEdit = accessType.includes('edit');
    const noAccessColor = noAccess ? '#fff' : '#8A9296';
    const viewAccessColor = hasView ? '#fff' : '#8A9296';
    const editAccessColor = hasEdit ? '#fff' : '#8A9296';

    return <div className='access-type-wrapper'>
      <div className={`svg-wrapper ${noAccess ? 'active' : 'inactive'}`}>
        <SVGWrapper iconName='noAccess' color={noAccessColor} width='15' />
      </div>
      <div className={`svg-wrapper ${hasView ? 'active' : 'inactive'}`}>
        <SVGWrapper iconName='eye' color={viewAccessColor} width='18' />
      </div>
      <div className={`svg-wrapper ${hasEdit ? 'active' : 'inactive'}`}>
        <SVGWrapper iconName='edit' color={editAccessColor} width='15' />
      </div>
    </div>
  }

  return <div className='stakeholders-view-table-container'>
    <table className='stakeholders-view-table'>
      <thead className='sv-header-wrap'>
        <tr>
          <th className='sv-header'>Status</th>
          <th className='sv-header'>SH Rights</th>
          <th className='sv-header'>Status Public Name</th>
          <th className='sv-header'>Actions</th>
        </tr>
      </thead>
      <tbody className='sv-body'>
        {tempStakeholdersData.map(data => {
          let statusColor = 'blue';

          if (data.final && data.actionsList.length === 0) {
            statusColor = 'red';
          }

          if (data.final && data.actionsList.length > 0) {
            statusColor = 'orange'
          }

          return <tr key={data.id} className='sv-body-wrap'>
            <td className='sv-body-td'>
              <div className={`status-wrapper ${statusColor}`}>
                {data.status}
              </div>
            </td>
            <td className='sv-body-td'>
              {accessTypeComponent(data.accessType)}
            </td>
            <td className='sv-body-td'>
              {data.statusName}
            </td>
            <td className='sv-body-td dropdown'>
              {data.actionsList.length > 0 &&
                <div className={`six wide field`}>
                  <Dropdown
                    className='action-dropdown'
                    search
                    clearable
                    selectOnBlur={false}
                    placeholder='Select Status'
                    selection
                    multiple
                    value={data.selected}
                    options={data.actionsList}
                    onChange={(e, target: DropdownProps) => { }}
                  />
                </div>
              }
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}

export default StakeholdersView;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { IFormBuilderSection } from '../../interface/section';
import { icons } from '../../../../../../../../common/icons';
import ToolTip from '../../../../../../../../common/general/tooltip';

interface IDragSection {
  section: IFormBuilderSection;
  dragRef: any;
}

const DragSection: React.FC<IDragSection> = (props) => {
  return <div className="drag-section" >
    <ToolTip position='left center'
      popupMessage='Re-order row'
      popupTriggerComponent={
        <Image src={icons.blue.dragIcon}></Image>
      }
    />
  </div>
}

export default DragSection;

import React from 'react';
import Section from './section';

interface ISectionContainer {

}

const SectionContainer: React.FC<ISectionContainer> = (props) => {
  return <div className='section-container'>
    <Section />
  </div>
}

export default SectionContainer;
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const BusinessProcess: React.FC = () => {
  const history = useHistory()

  useEffect(() => {

    history.push('/admin/business-process/form');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>This will redirect to /form</>
  );
}

export default BusinessProcess;
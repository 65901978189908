import React from 'react';
import { IFormPermissionSection } from '../../interface/section';
import StatusList from './list';

interface IStatusContainer {
  formStatusSectionList: IFormPermissionSection[];
  handleSelectedStatus(formStatusPermission: IFormPermissionSection, statusIndex: number): void;
  selectedStatus: IFormPermissionSection;
}

const StatusContainer: React.FC<IStatusContainer> = (props) => {
  return <div className='status-container'>
    {props.formStatusSectionList.map((status: IFormPermissionSection, index: number) => {
      return <StatusList
        key={`statuspermission-${status.statusId}`}
        status={status}
        statusIdex={index}
        handleSelectedStatus={props.handleSelectedStatus}
        selectedStatus={props.selectedStatus}
      />
    })}
  </div>
}

export default StatusContainer;
import React from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import SingleEnum from './single';
import MultipleEnumType from './multiple';

const EnumType: React.FC<AcceptedProps> = (props) => {
  const multipleSelect = props.inputConfig.config.multiselect;

  if (multipleSelect) {
    return <MultipleEnumType {...props} />
  }
  return <SingleEnum {...props} />
}

export default EnumType;
import React from 'react';
import { icons } from '../../../../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';
import { IColumnView, IDragColumnView } from '../interface/custom-view';

interface IFieldItemProps {
  field: IColumnView;
  allowDrop?: boolean;
  arrangeSequence?(selectedItem: IDragColumnView, field: IColumnView): void;
  onClick?(field: IColumnView): void;
}

const Field: React.FC<IFieldItemProps> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'column-field',
    drop: (selectedItem: IDragColumnView) => {
      if (props.arrangeSequence && selectedItem.data.new !== true) {
        props.arrangeSequence(selectedItem, props.field);
      }
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'column-field', data: props.field },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  return (
    <div ref={dragRef} onClick={() => props.onClick ? props.onClick(props.field) : {}} style={draggedObject} className={`field-item ${props.field.color || ''}`}>
      <div ref={props.allowDrop === true ? dropRef : undefined}>
        <Image style={props.field.iconStyle} src={props.field.icon} className={`icon`} />
        <label title={`${props.field.label}`}>{props.field.label}</label>
        <Image src={props.field.color === 'status' ? icons.blue.dragIconLight : icons.blue.dragIcon} className={'drag-icon'} />
      </div>
    </div >
  );
}

export default Field;
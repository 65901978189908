import React, { Suspense, lazy, useState } from 'react';
import { Segment, Loader } from 'semantic-ui-react-bpm';
import { useDispatch, useSelector } from 'react-redux';
import { AppTableList } from './app-table';
import { AppFilter } from './filter';
import AllowedUserListActionControl from '../../../../common/utils/user-list-action-control';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { cancelDeleteAppAction, confirmDeleteAppAction, deleteAppAction, getAppListAction } from './action';
import { ConfirmationMessage } from '../../../../common/general/confirmation-modal';
import ActionMessage from '../../../../common/general/action-message';
import { AppActionMessage, appLimit } from './constant';
import DownloadPopup from '../../../../common/general/download-popup';
import { useSetSubRoute } from '../../hooks/use-set-sub-route';
import { useGetAppData } from './hooks/use-get-app-data';
import { IAdminRender } from '../module-registry';

const AppFormModal = lazy(() => import('./form'));

class AppModule {
  name = 'apps'

  render: React.FC<IAdminRender> = (props) => {
    let filterTimeout = setTimeout(() => { }, 1000);

    const dispatch = useDispatch();
    const history = useHistory();
    let match = useRouteMatch();

    const {
      appList,
      createAndAddNew,
      confirmDeletionErrorMessage,
      isOpenDeleteConfirmationModal,
      confirmDeletetionLoading,
      forDeleteAppId,
      forDeleteAppName,
    } = useSelector((state: any) => state.apps);
    const { company } = useSelector((state: any) => state.auth);
    const { isOpenAdminChatbox } = useSelector((state: any) => state.main);

    const userLoggedIn = useSelector((state: any) => state.auth.userLoggedIn);
    
    const validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);

    const [currentOffset, setCurrentOffset] = useState(0);

    const closeDeleteModal = () => {
      dispatch(cancelDeleteAppAction());
    }

    const confirmDelete = () => {
      dispatch(confirmDeleteAppAction({ appId: forDeleteAppId, company }))
    }

    const openDeleteModal = (appId: string, appName: string) => {
      history.push(`/admin/apps`);
      dispatch(deleteAppAction({ appId: appId, appName: appName }));
    }

    const triggerFilter = (limit?: number, offset?: number, search?: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        dispatch(getAppListAction({
          company: company,
          limit: limit || appLimit,
          offset: offset || 0,
          name: search
        }));
      }, 1000);
      setCurrentOffset(offset || 0);
    }

    useGetAppData({ company, dispatch });

    useSetSubRoute({
      dispatch,
      mainRoute: 'admin',
      subRoute: 'apps',
      currentRoute: this.name
    });

    return (
      <Suspense fallback={<Loader active inline />}>
        {!createAndAddNew &&
          <ActionMessage messages={AppActionMessage} />
        }
        <Segment className='borderless app-main-container'>
          {validateUserProfile.allowedViewAppList() &&
            <>
              <AppFilter.Render
                isOpenChatbox={isOpenAdminChatbox}
                allowedAccessAuditLogs={props.allowedAccessAuditLogs ? props.allowedAccessAuditLogs : false}
              />
              {false && (
                <DownloadPopup />
              )}
              <AppTableList.Render
                appList={appList}
                openDeleteModal={openDeleteModal}
                triggerFilter={triggerFilter}
                currentOffset={currentOffset}
              />
            </>
          }
        </Segment>
        <Route exact path={`${match.url}/:appId`}>
          <AppFormModal
            openDeleteModal={openDeleteModal}
          />
        </Route>
        <ConfirmationMessage
          errorMessage={confirmDeletionErrorMessage}
          confirmButtonName={'Confirm'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={closeDeleteModal}
          open={isOpenDeleteConfirmationModal}
          confirm={confirmDelete}
          cancel={closeDeleteModal}
          headerText="Confirmation"
          detailText={`The app '${forDeleteAppName}' will be deleted. Do you still want to proceed?`}
          loading={confirmDeletetionLoading}
          type='warning'
        />
      </Suspense>
    );
  }
}


export default AppModule;

import { IFormStatus } from '../../../workflow/interface/form-status';
import { CLEAR_SAVE_API_CONFIG_ERROR, CLEAR_SAVE_API_CONFIG_SUCCESS, GET_API_CONFIG, GET_API_CONFIG_RETURN, GET_STATUS_LIST_RETURN, PUBLISH_API_CONFIG, PUBLISH_API_CONFIG_RETURN, SAVE_API_CONFIG_ERROR, SAVE_DRAFT_API_CONFIG, SAVE_DRAFT_API_CONFIG_RETURN, UPDATE_LOCAL_API_LIST } from './constant';
import { IApiConfiguration } from './interface/api-configuration';
import { message } from './message';

export interface IConfigurationAPIReducer {
  type?: string;
  apiConfigurationList: IApiConfiguration[];
  statusList: IFormStatus[];
  apiConfigSaveType: string;
  saveDraftApiConfigLoading: boolean;
  saveApiConfigMessage: string;
  saveApiConfigErrorMessage: string;
}

const initialState: IConfigurationAPIReducer = {
  apiConfigurationList: [],
  statusList: [],
  apiConfigSaveType: '',
  saveDraftApiConfigLoading: false,
  saveApiConfigMessage: '',
  saveApiConfigErrorMessage: '',
};

const configurationAPIsReducer = (state = initialState, action: IConfigurationAPIReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_STATUS_LIST_RETURN:
      newState.statusList = action.statusList;
      return newState;

    case GET_API_CONFIG:
      newState.apiConfigurationList = [];
      return newState;

    case GET_API_CONFIG_RETURN:
      newState.apiConfigurationList = action.apiConfigurationList;
      return newState;

    case UPDATE_LOCAL_API_LIST:
      newState.apiConfigurationList = action.apiConfigurationList;
      return newState;

    case SAVE_DRAFT_API_CONFIG:
      newState.saveDraftApiConfigLoading = true;
      newState.saveApiConfigMessage = '';
      newState.saveApiConfigErrorMessage = '';
      newState.apiConfigSaveType = '';
      return newState;

    case SAVE_DRAFT_API_CONFIG_RETURN:
      if (action.apiConfigSaveType === 'draft') {
        newState.saveDraftApiConfigLoading = false;
        newState.saveApiConfigMessage = message.saveApisSuccess;
      }
      newState.apiConfigSaveType = action.apiConfigSaveType;
      return newState;

    case SAVE_API_CONFIG_ERROR:
      newState.saveDraftApiConfigLoading = false;
      newState.saveApiConfigErrorMessage = action.saveApiConfigErrorMessage;
      return newState;

    case PUBLISH_API_CONFIG:
      newState.saveApiConfigMessage = '';
      newState.saveApiConfigErrorMessage = '';
      newState.apiConfigSaveType = '';
      return newState;

    case PUBLISH_API_CONFIG_RETURN:
      newState.saveDraftApiConfigLoading = false;
      newState.saveApiConfigMessage = message.publishApisSuccess;
      return newState;

    case CLEAR_SAVE_API_CONFIG_SUCCESS:
      newState.saveApiConfigMessage = '';
      return newState;

    case CLEAR_SAVE_API_CONFIG_ERROR:
      newState.saveApiConfigErrorMessage = '';
      return newState;


    default:
      return newState;
  }
}

export default configurationAPIsReducer;
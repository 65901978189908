import { useEffect } from 'react';
import { getStatusListAction } from '../../slas/action';
import {
  getDisplayDataConfigAction
} from '../actions';

interface IProps {
  dispatch: any;
  company: string;
  formId: string
}

export const useGetDisplayData = (props: IProps): void => {
  const { dispatch, company, formId } = { ...props };

  useEffect(() => {
    if (formId && company) {
      dispatch(getDisplayDataConfigAction({ company: company, formId: formId }));
      dispatch(getStatusListAction({ companyId: company, formId: formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, company]);
}
import { useEffect, useState } from 'react';
import { IAutomationChildComponent, IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
  selectedChildComponent?: IAutomationChildComponent | undefined;
}

export const useSetIfElseBlockType = (props: IProps) => {
  const { selectedComponent, selectedChildComponent } = { ...props };

  const [type, setType] = useState('');

  useEffect(() => {
    if (selectedComponent && !selectedChildComponent?.component) {
      setType('condition');
    }
    if (selectedComponent && selectedChildComponent?.component) {
      const component = selectedChildComponent.component;
      setType(component?.type || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent, selectedChildComponent])

  return { ...props, type, setType }
}
import { getFileExtension } from '../../../../../../../../../../common/utils/get-file-extension';
import { fileIcons, fileOfficeBlackIcons } from '../../../../../../constant';

export const imageForFile = (file: any, isBlack: boolean) => {
  const supportedImgs = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'text', 'pdf', 'jpg', 'gif', 'png', 'jpeg', 'tif', 'tiff'];
  const ext = getFileExtension(file.name).toLowerCase();
  let fileImg = fileIcons['unknown'];
  if (supportedImgs.indexOf(ext) > -1) {
    fileImg = fileIcons[ext];
    if (file.type === 'office' && isBlack) {
      fileImg = fileOfficeBlackIcons[`${ext}Black`]
    }
  } else {
    fileImg = fileIcons['unknown'];
  }
  return fileImg;
}
export const icons = {
  black: {
    dollar: require('./black/$-black.png'),
    number: require('./black/123-black.png'),
    alignJustify: require('./black/align-justify.png'),
    calendar: require('./black/calendar.png'),
    check: require('./black/check.png'),
    checklist: require('./black/checklist.png'),
    circleCheck: require('./black/circle-check.png'),
    clock: require('./black/clock.png'),
    delete: require('./black/delete.png'),
    dropdown: require('./black/drop-down-list.png'),
    email: require('./black/email-black.png'),
    eye: require('./black/eye.png'),
    funnel: require('./black/filter-filled-tool-symbol.png'),
    font: require('./black/font.png'),
    refresh: require('./black/free-curved-arrow-icon-2261-thumb copy.png'),
    fx: require('./black/fx-sign.png'),
    group: require('./black/group.png'),
    header: require('./black/header.png'),
    edit: require('./black/icon-edit.png'),
    interact: require('./black/interact .png'),
    key: require('./black/key.png'),
    link: require('./black/link.png'),
    lock: require('./black/lock.png'),
    magnifier: require('./black/magnifier.png'),
    user: require('./black/man-user.png'),
    users: require('./black/multiple-users-silhouette.png'),
    noAccess: require('./black/no-access.png'),
    noView: require('./black/no-view.png'),
    off: require('./black/off.png'),
    open: require('./black/open.png'),
    paperclip: require('./black/paperclip-vector-6.png'),
    grid: require('./black/smart-grid-black.png'),
    cog: require('./black/to_configure_36980.png'),
    unlink: require('./black/unlink.png'),
    unlock: require('./black/unlock.png'),
    upload: require('./black/upload.png'),
    world: require('./black/world.png'),
    xls: require('./black/xls.png'),
    flag: require('./black/flag-signal.png'),
    star: require('./black/star.png'),
    gridFilter: require('./black/grid.png'),
    list: require('./black/list-square-bullet.png'),
    expand: require('./black/arrow-expand.png'),
    close: require('./black/close.png'),
    menu: require('./black/menu.png'),
    clear: require('./black/clear.png'),
    defaultOwners: require('./black/team-2.png'),
    copy: require('./black/sheet.png'),
    mail: require('./black/mail.png'),
    brokenLink: require('./black/broken-link.png'),
    workspaceMenu: require('./black/workspace-menu.png'),
    todoMenu: require('./black/todo-menu.png'),
    reportMenu: require('./black/report-menu.png'),
    calendarMenu: require('./black/calendar-menu.png'),
    admin: require('./black/admin.png'),
    bell: require('./black/bell.png'),
    endUser: require('./black/user.png'),
    filterOutline: require('./black/filter-outline.png'),
    expandColumn: require('./black/expand-table.png'),
    collapseColumn: require('./black/collapse-table.png'),
    datatable: require('./black/datatable.png'),
    closePlain: require('./black/close-plain.png'),
    autopopulated: require('./black/autopopulated.png'),
    editProfile: require('./black/edit-profile.png'),
    mobile: require('./black/mobile-phone.png'),
    avatarIcon: require('./black/avatar-icon.png'),
    cloudUpload: require('./black/cloud-upload.png'),
    penOutline: require('./black/pen-outline.png'),
    checkSheet: require('./black/check-sheet.png'),
    lightning: require('./black/lightning.png'),
    coOwnerUser: require('./black/co-owner-user.png'),
    chat: require('./black/chat.png'),
    clearFilters: require('./black/clear-filters.png'),
    bulkUpload: require('./black/bulk-upload.png'),
    sadFace: require('./black/sad.png'),
    smileFace: require('./black/smile.png'),
    neutralFace: require('./black/neutral.png'),
    gap: require('./black/gap.png'),
    collapse: require('./black/collapse.png'),
    switchCamera: require('./black/switchcamera.png'),
    chatIcon: require('./black/chat-icon.png'),
    filter: require('./black/filter.png'),
    filterActive: require('./black/filter-active.png'),
    shield: require('./black/shield.png'),
    dataFormats: require('./black/data-formats.png'),
    dateTime: require('./black/time-stamp.png'),
    dragIcon: require('./black/drag-icon.png'),
    range: require('./black/date-time-range.png'),
    phone: require('./black/mobile.png'),
    download: require('./black/download.png')
  },
  blue: {
    dollar: require('./blue/$-blue.png'),
    number: require('./blue/123-blue.png'),
    alignJustify: require('./blue/align-justify.png'),
    calendar: require('./blue/calendar.png'),
    check: require('./blue/check.png'),
    checklist: require('./blue/checklist.png'),
    circleCheck: require('./blue/circle-check.png'),
    clock: require('./blue/clock.png'),
    delete: require('./blue/delete.png'),
    dropdown: require('./blue/drop-down-list.png'),
    email: require('./blue/email-blue.png'),
    eye: require('./blue/eye.png'),
    funnel: require('./blue/filter-filled-tool-symbol.png'),
    font: require('./blue/font.png'),
    refresh: require('./blue/free-curved-arrow-icon-2261-thumb copy.png'),
    fx: require('./blue/fx-sign.png'),
    group: require('./blue/group.png'),
    header: require('./blue/header.png'),
    edit: require('./blue/icon-edit.png'),
    interact: require('./blue/interact.png'),
    key: require('./blue/key.png'),
    link: require('./blue/link.png'),
    lock: require('./blue/lock.png'),
    magnifier: require('./blue/magnifier.png'),
    user: require('./blue/man-user.png'),
    users: require('./blue/multiple-users-silhouette.png'),
    noAccess: require('./blue/no-access.png'),
    noView: require('./blue/no-view.png'),
    off: require('./blue/off.png'),
    open: require('./blue/open.png'),
    paperclip: require('./blue/paperclip-vector-6.png'),
    grid: require('./blue/smart-grid-blue.png'),
    cog: require('./blue/to_configure_36980.png'),
    unlink: require('./blue/unlink.png'),
    unlock: require('./blue/unlock.png'),
    upload: require('./blue/upload.png'),
    world: require('./blue/world.png'),
    xls: require('./blue/xls.png'),
    dragIcon: require('./blue/drag-icon.png'),
    dragIconLight: require('./blue/drag-icon-light.png'),
    flag: require('./blue/flag-signal.png'),
    workspaceMenu: require('./blue/workspace-menu.png'),
    todoMenu: require('./blue/todo-menu.png'),
    reportMenu: require('./blue/report-menu.png'),
    calendarMenu: require('./blue/calendar-menu.png'),
    admin: require('./blue/admin.png'),
    endUser: require('./blue/user.png'),
    menu: require('./blue/menu.png'),
    clear: require('./blue/clear.png'),
    bell: require('./blue/bell.png'),
    close: require('./blue/close.png'),
    matrix: require('./blue/matrix.png'),
    rowHeader: require('./blue/row header.png'),
    columnHeader: require('./blue/column header.png'),
    closePlain: require('./blue/close-plain.png'),
    autopopulated: require('./blue/autopopulated.png'),
    chat: require('./blue/chat.png'),
    companyUsers: require('./blue/company-users.png'),
    companyEdit: require('./blue/company-edit.png'),
    companyDelete: require('./blue/company-delete.png'),
    datatable: require('./blue/datatable.png'),
    closeCircle: require('./blue/close-circle.png'),
    cogOutline: require('./blue/cog-outline.png'),
    gap: require('./blue/gap.png'),
    dateTime: require('./blue/time-stamp.png'),
    range: require('./blue/date-time-range.png'),
    phone: require('./blue/mobile.png'),
  },
  white: {
    dollar: require('./white/$-white.png'),
    number: require('./white/123-white.png'),
    alignJustify: require('./white/align-justify.png'),
    calendar: require('./white/calendar.png'),
    check: require('./white/check.png'),
    checklist: require('./white/checklist.png'),
    circleCheck: require('./white/circle-check.png'),
    clock: require('./white/clock.png'),
    delete: require('./white/delete.png'),
    dropdown: require('./white/drop-down-list.png'),
    email: require('./white/email-white.png'),
    eye: require('./white/eye.png'),
    funnel: require('./white/filter-filled-tool-symbol.png'),
    font: require('./white/font.png'),
    refresh: require('./white/free-curved-arrow-icon-2261-thumb copy.png'),
    fx: require('./white/fx-sign.png'),
    group: require('./white/group.png'),
    header: require('./white/header.png'),
    edit: require('./white/icon-edit.png'),
    interact: require('./white/interact.png'),
    key: require('./white/key.png'),
    link: require('./white/link.png'),
    lock: require('./white/lock.png'),
    magnifier: require('./white/magnifier.png'),
    user: require('./white/man-user.png'),
    users: require('./white/multiple-users-silhouette.png'),
    noAccess: require('./white/no-access.png'),
    noView: require('./white/no-view.png'),
    off: require('./white/off.png'),
    open: require('./white/open.png'),
    paperclip: require('./white/paperclip-vector-6.png'),
    grid: require('./white/smart-grid-white.png'),
    cog: require('./white/to_configure_36980.png'),
    unlink: require('./white/unlink.png'),
    unlock: require('./white/unlock.png'),
    upload: require('./white/upload.png'),
    world: require('./white/world.png'),
    xls: require('./white/xls.png'),
    flag: require('./white/flag-signal.png'),
    star: require('./white/star.png'),
    menu: require('./white/menu.png'),
    clear: require('./white/clear.png'),
    clearFilters: require('./white/clear-filters.png'),
    closePlain: require('./white/close-plain.png'),
    autopopulated: require('./white/autopopulated.png'),
    chat: require('./white/chat.png'),
    dragIcon: require('./white/drag-icon.png'),
    iconCollapse: require('./white/collapse.png'),
    iconNotCollapse: require('./white/not-collapse.png'),
    clip: require('./white/clip.png'),
    chatBubble: require('./white/chat-bubble.png'),
    bot: require('./white/bot.png'),
    gap: require('./white/gap.png'),
    switchCamera: require('./white/switchcamera-white.png'),
    dateTime: require('./white/time-stamp.png'),
    range: require('./white/date-time-range.png'),
    phone: require('./white/mobile.png'),
  },
  gray: {
    edit: require('./gray/icon-edit.png'),
    noAccess: require('./gray/no-access_gray.png'),
    open: require('./gray/open.png'),
    upload: require('./gray/upload.png'),
    view: require('./gray/view.png'),
    delete: require('./gray/delete.png'),
    close: require('./gray/close.png'),
    star: require('./gray/star_gray.png'),
    notificationFlag: require('./gray/waving-flag-gray.png')
  },
  orange: {
    user: require('./orange/man-user.png'),
    users: require('./orange/multiple-users-silhouette.png'),
    star: require('./orange/star.png'),
    coOwnerUser: require('./orange/co-owner-user.png'),
    checkSheet: require('./orange/check-sheet.png'),
    lightning: require('./orange/lightning.png'),
  },
  other: {
    leftArrow: require('./other/left-arrow.png'),
    rightArrow: require('./other/right-arrow.png'),
    colorGroup: require('./other/color-group.png'),
    colorGroupSelected: require('./other/color-group.png'),
    arrowStatusEnd: require('./other/arrow-status-end.png'),
    arrowStatus: require('./other/arrow-status.png'),
    eyeGreen: require('./other/eye-green.png'),
    eyeOrange: require('./other/eye-orange.png'),
    eyeRed: require('./other/eye-red.png'),
    attachment: require('./other/attachment.png'),
    selectedAttachment: require('./other/attachment-selected.png'),
    bot: require('./other/bot.png'),
    selectedBot: require('./other/bot-selected.png'),
    comments: require('./other/comments.png'),
    selectedComments: require('./other/comments-selected.png'),
    chatSubmit: require('./other/chat-submit.png'),
    circleOk: require('./other/circle-check-ok.png'),
    circleCancel: require('./other/circle-check-cancel.png'),
    taskCompletedSelected: require('./other/completed-selected.png'),
    taskCompleted: require('./other/completed.png'),
    taskPendingSelected: require('./other/pending-selected.png'),
    taskPending: require('./other/pending.png'),
    undo: require('./other/undo.png'),
    remove: require('./other/remove.png'),
    jpg: require('./other/icon-jpg.png'),
    png: require('./other/icon-png.png'),
    tiff: require('./other/icon-tiff.png'),
    gif: require('./other/icon-gif.png'),
    unknown: require('./other/icon-unknown.png'),
    pptBlack: require('./other/icon-ppt-black.png'),
    wordBlack: require('./other/icon-word-black.png'),
    excelBlack: require('./other/icon-excel-black.png'),
    ppt: require('./other/icon-ppt.png'),
    word: require('./other/icon-word.png'),
    excel: require('./other/icon-excel.png'),
    txt: require('./other/icon-txt.png'),
    pdf: require('./other/icon-pdf.png'),
    attachment_excel: require('./other/attachement_excel.png'),
    attachment_png: require('./other/attachement_png.png'),
    attachment_word: require('./other/attachement_word.png'),
    relatedDocument: require('./other/icon-bpr-user.png'),
    backwardReturn: require('./other/return.png'),
    mobbizLogo: require('./other/mobbiz-logo.png')
  }
}
import moment from 'moment';
import React, { CSSProperties, Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button, Icon, Popup } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IField, IFieldAccessType, IInputConfig, IOptionValue } from '../../../../../../component/admin-module/module/users/interface/field';
import { setDocumentActionMessage } from '../../../../../../component/document-module/actions';
import { fieldTriggerUpdateResponseAction, saveDocumentErrorAction } from '../../../../../../component/document-module/module/form/action';
import { EnumFieldValueFilter } from '../../../../../../component/document-module/module/form/automation/components/action/filter-enum/enum-items-data-source';
import { IReloadFieldResponse } from '../../../../../../component/document-module/module/form/interface/response/field-trigger-reload';
import { IRootState } from '../../../../../../reducers';
import useDeviceDetect from '../../../../../general/device-detect';
import DownloadPopup from '../../../../../general/download-popup';
import TableFileUpload from '../../../../../general/table-file-upload';
import { addThousandSeparator } from '../../../../../utils/add-thousand-separator';
import { AggregateService } from '../../../../../utils/agg/aggregate-service';
import { focusSelectedElement, getTableColumnDisplayed, getTableFocusedElement } from '../../../../../utils/focus-element';
import { FieldConstruct } from '../../../../field-construct';
import { IConfigName } from '../../../../type/interface/field-type-object';
import { FieldElement } from '../../../field-element';
import { triggerFilterOptions } from '../../enum-type/utils';
import { AcceptedProps } from '../../interface/accepted-props';
import { FieldTableReload } from '../../table-type/utils/field-reload-table';
import { setTableConfigValues } from '../../table-type/utils/set-config-values';
import { updateTableConfig } from '../../table-type/utils/update-table-config';
import { removeCursorPosition } from '../string/utils/cursor-position';

const setColumnsDisplayedCount = (width: number, defaultCount: number) => {
  if (width <= 480) {
    return 1;
  } else if (width > 480 && width <= 768) {
    return 2;
  } else if (width > 768 && width <= 1024) {
    return 3;
  } else if (width > 1024 && width <= 1280) {
    return 4;
  } else if (width > 1280 && width <= 1366) {
    return 5;
  } else {
    return defaultCount;
  }
}

interface IItems {
  id?: string;
  label?: string;
}

interface EnumFieldsOptions {
  [key: string]: IOptionValue[]
}

const VerticalTableTypeComponent: React.FC<AcceptedProps> = (props) => {
  const tableContainerRef = useRef(document.createElement('div'));
  const { width } = useDeviceDetect();
  const { documentView } = useSelector((rootState: IRootState) => rootState.main.companyColor);
  const { userCompanyDetails, company } = useSelector((state: IRootState) => state.auth);
  const { formStatus } = useSelector((state: IRootState) => state.documentForm);
  const name = props.getFieldId();
  const focusedElement = getTableFocusedElement() as string;
  const columnDisplayed = getTableColumnDisplayed() as string;
  const match: { params: { documentId: string, formId: string } } = useRouteMatch();

  const [refresher, setRefresher] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState([] as number[]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [showDataFileUpload, setShowDataFileUpload] = useState(false);
  const [activeField, setActiveField] = useState('');
  const dispatch = useDispatch();

  if (!props.forwardedRef.current[name]) {
    props.forwardedRef.current[name] = [];
  }

  if (props.tableFieldEnumOptionsRef &&
    props.tableFieldEnumOptionsRef.current &&
    !props.tableFieldEnumOptionsRef.current[name]) {
    props.tableFieldEnumOptionsRef.current[name] = [];
  }

  const { config } = { ...props.inputConfig };
  const minRows = config.minRows || 0;
  const maxRows = config.maxRows || 0;

  let columnsToDisplay = props.inputConfig.config.columns;

  const columnLength = props.inputConfig.config.columns.length;
  const columnsDisplayedCount = setColumnsDisplayedCount(width, columnLength);
  const isEven = columnLength % 2 === 0;

  const minRowState = props.tableRowRef && props.tableRowRef.current[name] ? props.tableRowRef.current[name].minRows : 0;
  const maxRowState = props.tableRowRef && props.tableRowRef.current[name] ? props.tableRowRef.current[name].maxRows : 0;
  const originalConfig = props.tableRowRef && props.tableRowRef.current[name] ? props.tableRowRef.current[name].config : config;
  const activeRow = props.tableFocusRef && props.tableFocusRef.current !== undefined ? props.tableFocusRef.current[name] : undefined;

  if (columnsDisplayedCount === 1) {
    columnsToDisplay = props.inputConfig.config.columns
      .filter(e => e.accessType !== IFieldAccessType.Hidden)
      .filter((e, i) => selectedIndex.includes(i));
  }

  const allColumnFieldsHidden = columnsToDisplay.every(col => col.accessType === 'hidden');
  columnsToDisplay = columnsToDisplay.filter(e => e.inputConfig && e.inputConfig.type !== 'file');

  const onTableScroll = (e: any) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    const scrollPercent = Math.round((Math.floor(scrollLeft)) / (scrollWidth - clientWidth) * 100);
    const arrowRight = document.getElementById(`arrow-right-${name}`);
    const arrowLeft = document.getElementById(`arrow-left-${name}`);
    if (arrowLeft && arrowRight) {
      if (scrollPercent === 100) {
        arrowRight.style.setProperty('display', 'none');
        arrowLeft.style.removeProperty('display');
      } else if (scrollPercent === 0) {
        arrowLeft.style.setProperty('display', 'none');
        arrowRight.style.removeProperty('display');
      } else {
        arrowRight.style.removeProperty('display');
        arrowLeft.style.removeProperty('display');
      }
    }
  }

  const setRef = (element: HTMLDivElement | null) => {
    if (!element) return;
    const { clientWidth, scrollWidth } = element;
    setIsScrollable(scrollWidth > clientWidth);
    return tableContainerRef.current = element;
  }

  const showPrevButton = () => {
    if (columnsDisplayedCount === 1) {
      if (selectedIndex.length > 1) {
        return selectedIndex.every(e => e > (columnsDisplayedCount - 1));
      }
      return selectedIndex.every(e => e > 0);
    } else {
      return false;
    }
  }

  const showNextButton = () => {
    if (columnsDisplayedCount === 1) {
      if (selectedIndex.length !== columnLength) {
        if (selectedIndex.length > 1) {
          if (isEven) {
            return selectedIndex.every(e => e !== (columnLength - 1))
          }
          return selectedIndex.every(e => e <= (columnLength - 1))
        }
        return selectedIndex.every(e => e < (columnLength - 1))
      } else {
        return false;
      }
    } else {
      return isScrollable;
    }
  }

  const onClickArrowLeft = () => {
    if (columnsDisplayedCount === 1) {
      const newSelected = selectedIndex.map((e, i) => {
        if (e > -1) {
          return e -= columnsDisplayedCount;
        }
        return e;
      });
      setSelectedIndex(newSelected);
    } else {
      scrollToList(-480);
    }
  }

  const onClickArrowRight = () => {
    if (columnsDisplayedCount === 1) {
      const newSelected = selectedIndex.map((e, i) => {
        if (e <= (props.inputConfig.config.columns.length - 1)) {
          return e += columnsDisplayedCount;
        }
        return e + 0;
      })
      setSelectedIndex(newSelected);
    } else {
      scrollToList(480);
    }
  }

  const scrollToList = (change: number) => {
    const duration = 700;
    let start = tableContainerRef.current.scrollLeft,
      currentTime = 0,
      increment = 20;

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }

    const animateScroll = () => {
      currentTime += increment;
      const scrollLeft = easeInOutQuad(currentTime, start, change, duration);
      tableContainerRef.current.scrollLeft = scrollLeft;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }
    animateScroll();
  }

  const setNonTableFxFieldReload = (reloadField: string[], fieldId?: string) => {
    if (reloadField && fieldId && props.fieldCollection) {
      const nonTableFxReload = localStorage.getItem('nonTableFxReload');
      const nonTableFXField: IField[] = nonTableFxReload ? JSON.parse(nonTableFxReload) : [];
      reloadField.forEach((id: string) => {
        const [mainId] = id.split('.');
        if (mainId !== name) {
          let fieldDetail: IField | undefined = props.fieldCollection &&
            props.fieldCollection.find((field: IField) => field.id === mainId);
          if (fieldDetail
            && fieldDetail.configName === IConfigName.FX
            && fieldDetail.requiresFieldData
            && fieldDetail.requiresFieldData.indexOf(fieldId) > -1) {
            const alreadyExist = nonTableFXField.find(e => e.id === mainId);
            if (!alreadyExist) {
              nonTableFXField.push(fieldDetail);
            }
          }
        } else {
          let tableDetail: IField | undefined = props.fieldCollection &&
            props.fieldCollection.find((field: IField) => field.id === mainId);
          if (tableDetail) {
            const alreadyExist = nonTableFXField.find(e => e.id === mainId);
            if (!alreadyExist) {
              nonTableFXField.push(tableDetail);
            }
          }
        }
      });
      localStorage.setItem('nonTableFxReload', JSON.stringify(nonTableFXField));
    }
  }

  const generateRow = () => {
    const rowElement = [];

    for (let i = 0; i < minRowState; i++) {
      const id = `table-${name}-${i}`;

      if (!props.forwardedRef.current[name][i]) {
        props.forwardedRef.current[name][i] = {};
      }

      rowElement.push(
        <tr key={id} id={`table-row-${name}-${i}`} className={`${activeRow === i ? 'active' : 'inactive'} table-row-${i}`}
          onClick={(e) => {
            setActiveRow(i);
            e.stopPropagation();
          }}>
          {columnsToDisplay.map((columnField: IField) => {
            const rowColumnField = columnField.configPerRow && columnField.configPerRow[i]
            if (rowColumnField) {
              columnField = { ...columnField, ...rowColumnField };
            }

            const reloadOnChange = props.reloadOnChange ? { reloadOnChange: props.reloadOnChange } : {};
            const field = FieldElement.getDataElement({ ...columnField, accessType: columnField.accessType, ...reloadOnChange, tableId: props.id } as any);
            const showHiddenDiv = field.configName === IConfigName.TextString || field.inputConfig.type === 'enum';

            const Element = field.getFieldElement();
            const fieldId = field.getFieldId();

            if (!props.forwardedRef.current[name][i].current) {
              props.forwardedRef.current[name][i].current = {};
            }
            if (!props.forwardedRef.current[name][i].current[fieldId]) {
              props.forwardedRef.current[name][i].current[fieldId] = {};
            }
            if (!props.forwardedRef.current[name][i].current[fieldId].value && field.inputConfig.type !== 'number') {
              props.forwardedRef.current[name][i].current[fieldId].value = null;
            }

            Object.values({ ...props.validationSchema }).forEach((value: any) => {
              if (Array.isArray(value)) {
                value.forEach(val => {
                  if (val && val.properties && val.properties.hasOwnProperty(field.id)) {
                    let schema = val.properties[field.id];
                    if (schema.hasOwnProperty('items')) {
                      field.validationSchema = {
                        ...schema.items,
                        isRequired: field.accessType === IFieldAccessType.Required
                      };
                    }
                  }
                })
              } else {
                if (value && value.hasOwnProperty(field.id)) {
                  let schema = value[field.id];
                  if (schema.hasOwnProperty('items')) {
                    field.validationSchema = {
                      ...schema.items,
                      isRequired: field.accessType === IFieldAccessType.Required
                    };
                  }
                }
              }
            });

            let accessType = field.accessType;

            if (props.forwardedRef.current[name]
              && props.forwardedRef.current[name].error
              && props.forwardedRef.current[name].error.length > 0
              && props.showErrorMessage
              && field.accessType === IFieldAccessType.Required) {
              accessType = IFieldAccessType.Required;
            }

            if (field.configName === IConfigName.FX || field.configName === IConfigName.Autopopulated) {
              accessType = IFieldAccessType.Readonly;
            }

            // use for frontend automation per cell access type, it overrides all access level above
            if (columnField.accessTypePerRow && columnField.accessTypePerRow[i]) {
              accessType = columnField.accessTypePerRow[i];
            }

            return <td id={`${id}-${field.id}`} key={`${id}-${field.id}`} onClick={(e) => {
              if (props.forwardedRef.current[name]
                && props.forwardedRef.current[name].error
                && props.forwardedRef.current[name].error.length > 0) {
                props.forwardedRef.current[name].error = [];
              }
              setActiveField(field.id);
              setActiveRow(i);
              e.stopPropagation();
            }}
              className={`${showHiddenDiv ? 'with-input-click' : ''} table-row-${i} ${columnField.accessType}`}>
              {width <= 480 &&
                <Popup
                  id='table-mobile-action'
                  style={{ padding: '5px 10px' }}
                  size='tiny'
                  on='click'
                  basic
                  position='bottom right'
                  trigger={<Icon name='ellipsis vertical' />}>
                  <div className='content'>
                    <span className='action' onClick={() => deleteRow(i)}>Delete Row</span>
                    <span className='separator' />
                    <span className='action' onClick={() => { }}>Download data</span>
                  </div>
                </Popup>
              }
              {showHiddenDiv && (activeRow !== i) &&
                <div className='input-click' onClick={(e) => setActiveField(field.id)} />
              }
              <Element
                {...field}
                accessType={accessType}
                hasPageChangeRef={props.hasPageChangeRef}
                forwardedRef={props.forwardedRef.current[name][i]}
                defaultValueElement={false}
                fieldCollection={props.fieldCollection}
                tableId={name}
                tableColumnRowIndex={i}
                tableColumnDisplayed={selectedIndex}
                showErrorMessage={props.showErrorMessage}
                throwValueOutside={props.throwValueOutside}
                hint=''
                sendTriggerToParentContainer={triggerAutomationFieldUpdate}
                isAddRecord={props.isAddRecord}
                isFocus={activeField === field.id && activeRow === i}
                triggerFieldReload={(reloadField: string[], fieldId?: string) => {
                  triggerTableFieldReload(reloadField, 'insideTable');
                  setNonTableFxFieldReload(reloadField, fieldId);
                }}
                fieldEnumOptionUrl={props.fieldEnumOptionUrl}
                setActiveField={setActiveField}
                tableFieldEnumOptionsRef={props.tableFieldEnumOptionsRef}
              />
            </td>
          })
          }
          {width > 480 &&
            <td>
              <i className={`icon alternate trash outline`}
                onClick={() => deleteRow(i)} />
            </td>
          }
        </tr >
      );
    }
    return rowElement;
  }

  const deleteRow = (index: number) => {
    if (originalConfig.minRows < minRowState) {
      setMinRowState(minRowState - 1);
    }
    props.forwardedRef.current[name].splice(index, 1);
    setRefresher(refresher + 1);
  }

  const getColumnValues = (fieldId: string): any[] => {
    let values: any[] = [];
    if (props.forwardedRef.current[name] && props.forwardedRef.current[name].length > 0) {

      props.forwardedRef.current[name].forEach((field: any) => {
        values.push(field.current
          && field.current[fieldId]
          && field.current[fieldId].value
          && field.current[fieldId].value !== ''
          && field.current[fieldId].value !== null
          ? parseFloat(field.current[fieldId].value.toString().replace(/,/g, ''))
          : null)
      })
    }

    return values;
  }

  const triggerTableConfigValues = (tableField: IField | undefined, action?: string) => {
    if (tableField && tableField.inputConfig) {
      setTableConfigValues(tableField.inputConfig, {
        forwardedRef: props.forwardedRef,
        name,
        action
      });
      setRefresher(refresher + 1);
    }
  }

  const dispatchTriggerFieldReload = ({ formField, valueFieldReload }: IReloadFieldResponse, type: 'insideTable' | 'outsideTable') => {
    const tableField = formField.find(e => e.id === name);
    const nonTableField = formField.filter(e => e.id !== name);
    if (type === 'insideTable') {
      triggerTableConfigValues(tableField, 'fieldReload');
    } else {
      const nonTableFxReload = localStorage.getItem('nonTableFxReload');
      const nonTableFXField: IField[] = nonTableFxReload ? JSON.parse(nonTableFxReload) : [];
      const fxFieldReload = nonTableFXField.filter(e => e.id !== name);

      triggerTableConfigValues(tableField, 'fieldReload');
      if (nonTableField.length > 0) {
        dispatch(fieldTriggerUpdateResponseAction({
          formField: nonTableField,
          valueFieldReload,
          triggeredOnChange: fxFieldReload.length > 0,
          fxFieldReload: fxFieldReload
        }));
      }
    }
  }

  const triggerTableFieldReload = (reloadField: string[], type: 'insideTable' | 'outsideTable') => {
    if (props.triggerFieldReload && reloadField.length > 0) {
      FieldTableReload.setReloadData({
        companyId: company,
        documentId: match.params.documentId,
        fieldList: props.fieldCollection || [],
        formId: match.params.formId,
        statusId: formStatus.id,
        dispatchTriggerFieldReload: (reloadFieldResponse: IReloadFieldResponse) => {
          dispatchTriggerFieldReload(reloadFieldResponse, type);
        },
        dynamicRefs: props.forwardedRef,
        fieldReload: reloadField
      });
    }
  }

  const handleClickEvent = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    const tableBody = document.getElementById(`body-${name}`);
    const fieldsToReload = localStorage.getItem('fieldToReload');
    let newFieldToReload: string[] = fieldsToReload ? JSON.parse(fieldsToReload) : [];
    if (tableBody && activeRow !== undefined) {
      const clickedOutside = (
        tableBody && !tableBody.contains(event.target as Node) &&
        target && target.className && target.className.includes && !(target.className.includes('item')
          || target.className.includes('text') || target.className.includes('react-datepicker'))
      );
      if (clickedOutside) {
        triggerTableFieldReload(newFieldToReload, 'outsideTable');
        localStorage.removeItem('fieldToReload');
        removeCursorPosition();
        setActiveRow(undefined);
      }
    }
  }

  const setFieldReloadToLocal = (reloadField: string[]) => {
    const fieldsToReload = localStorage.getItem('fieldToReload');
    let newFieldToReload: string[] = fieldsToReload ? JSON.parse(fieldsToReload) : [];
    const alreadyExist = newFieldToReload.some(e => reloadField.includes(e));
    if (!alreadyExist) {
      localStorage.setItem('fieldToReload', JSON.stringify([...newFieldToReload, ...reloadField]));
    }
  }

  const triggerAutomationFieldUpdate = () => {
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);

      if (field) {
        const tableField = updateTableConfig({
          field,
          forwardedRef: props.forwardedRef,
          name: name,
          tableFieldEnumOptionsRef: props.tableFieldEnumOptionsRef,
          maxRowState: maxRowState,
          minRowState: minRowState
        });
        const value = FieldConstruct.getFieldDataFromRefs([field], props.forwardedRef);
        props.automationService.didUpdateFieldValue(tableField, value[props.id] ? value[props.id] : undefined);
      }
    }
  }

  const setMinRowState = (value: number) => {
    if (props.handleTableRowState) {
      props.handleTableRowState(name, { maxRows, minRows: value, config: originalConfig });
    }
  }

  const setActiveRow = (value: number | undefined) => {
    props.tableFocusRef.current[name] = value;
    setRefresher(refresher + 1);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEvent);
    document.addEventListener('touchstart', handleClickEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickEvent);
      document.removeEventListener('touchstart', handleClickEvent);
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    const fieldsToReload = [] as string[];
    if (columnsToDisplay.length > 0 && activeRow !== undefined) {
      columnsToDisplay.forEach(fields => {
        if (fields && fields.reloadOnChange && fields.reloadOnChange.length > 0) {
          fields.reloadOnChange.forEach(ids => {
            const exists = fieldsToReload.find(e => e === ids);
            if (!exists) {
              fieldsToReload.push(ids);
            }
          })
        }
      });
    }
    setFieldReloadToLocal(fieldsToReload as string[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsToDisplay, activeRow])

  useEffect(() => {
    const element = JSON.parse(focusedElement);
    if (element && width <= 480) {
      focusSelectedElement(element.focusedElement);
      setActiveRow(element.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedElement]);

  useEffect(() => {
    const column = JSON.parse(columnDisplayed);
    let count = [] as number[];
    for (let i = 0; i < columnsDisplayedCount; i++) {
      count.push(i);
    }
    if (column) {
      count = column;
    }
    setSelectedIndex(count);
  }, [width, columnsDisplayedCount, columnDisplayed])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.inputConfig.config.value)]);

  const sortTable = (n: any) => {
    let rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    let table = document.getElementById("vertical-table-field-type") as HTMLTableElement;
    if (table) {
      if (table) {
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
          // Start by saying: no switching is done:
          switching = false;
          rows = table.rows;
          /* Loop through all table rows (except the
          first, which contains table headers): */
          for (i = 1; i < (rows.length - 1); i++) {
            // Start by saying there should be no switching:
            shouldSwitch = false;
            /* Get the two elements you want to compare,
            one from current row and one from the next: */

            // x = rows[i].getElementsByTagName("TD")[n];
            x = rows[i].getElementsByTagName("TD")[n].getElementsByClassName('field-input ui input').length > 0
              ? rows[i].getElementsByTagName("TD")[n].getElementsByTagName('input')[0].value
              : rows[i].getElementsByTagName("TD")[n].getElementsByClassName('text')[0]?.innerHTML
            y = rows[i + 1].getElementsByTagName("TD")[n].getElementsByClassName('field-input ui input').length > 0
              ? rows[i + 1].getElementsByTagName("TD")[n].getElementsByTagName('input')[0].value
              : rows[i + 1].getElementsByTagName("TD")[n].getElementsByClassName('text')[0]?.innerHTML

            /* Check if the two rows should switch place,
            based on the direction, asc or desc: */
            if (dir === "asc") {
              if (x?.toLowerCase() > y?.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            } else if (dir === "desc") {
              if (x?.toLowerCase() < y?.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            /* If a switch has been marked, make the switch
            and mark that a switch has been done: */
            rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            // Each time a switch is done, increase this count by 1:
            switchcount++;
          } else {
            /* If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again. */
            if (switchcount === 0 && dir === "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }
    }
  }

  const dataFileUpload = () => {
    setShowDataFileUpload(!showDataFileUpload);
  }

  const isValidToUpload = (tableData: any) => {
    if (maxRows > 0 && minRows > 0 && tableData.length > maxRows && tableData.length > minRows) {
      return [`Upload Error: Maximum rows of (${maxRows}) only but (${tableData.length}) rows found.`];
    }
    return columnsToDisplay.map(column => {
      let error = null as string | null;

      if (column.inputConfig
        && column.inputConfig.type === 'enum'
        && column.inputConfig.config.dataSource
        && column.inputConfig.config.dataSource.dataFilters) {
        const requiresFieldData = [] as IField[];

        column.inputConfig.config.dataSource.dataFilters.forEach(filter => {
          let valueFieldKeypath = undefined as IField | undefined;
          let fieldKeypath = undefined as IField | undefined;

          const keyPath = filter.keyPath.startsWith('fields') ? filter.keyPath.split('fields.')[1] : filter.keyPath;
          fieldKeypath = props.fieldCollection && props.fieldCollection.find(e => e.id === keyPath);

          if (typeof filter.value === 'object' && filter.value.hasOwnProperty('keyPath')) {
            const value = filter.value as EnumFieldValueFilter;
            const valueKeyPath = value.keyPath.startsWith('fields') ? value.keyPath.split('fields.')[1] : filter.keyPath;
            valueFieldKeypath = props.fieldCollection && props.fieldCollection.find(e => e.id === valueKeyPath);
          }

          if (fieldKeypath && props.forwardedRef.current[fieldKeypath.id || '']
            && !props.forwardedRef.current[fieldKeypath.id || ''].value) {
            const exists = requiresFieldData.find(e => fieldKeypath && e.id === fieldKeypath.id);
            if (!exists) {
              requiresFieldData.push(fieldKeypath);
            }
          }
          if (valueFieldKeypath && props.forwardedRef.current[valueFieldKeypath.id || '']
            && !props.forwardedRef.current[valueFieldKeypath.id || ''].value) {
            const exists = requiresFieldData.find(e => valueFieldKeypath && e.id === valueFieldKeypath.id);
            if (!exists) {
              requiresFieldData.push(valueFieldKeypath);
            }
          }

        });

        if (requiresFieldData.length > 0) {
          let errorMessage = `Upload Error: Table Field '${column.label}' requires value of field/s: `;
          requiresFieldData.forEach((field, index) => {
            if (requiresFieldData.length > 2) {
              if (index < (requiresFieldData.length - 1)) {
                errorMessage += `'${field.label}', `
              } else {
                errorMessage += `and '${field.label}'`
              }
            } else {
              if (index < (requiresFieldData.length - 1)) {
                errorMessage += `'${field.label}' and `
              } else {
                errorMessage += `'${field.label}'`
              }
            }
          });
          errorMessage += '.';
          error = errorMessage;
        }
      }

      return error;
    });
  }

  const uploadDataToTable = async (tableData: any) => {
    console.log('Uploaded data: ', tableData);
    // Replace existing table value with uploaded csv. 
    let enumFieldsOptions = {} as EnumFieldsOptions;
    let fieldData = {} as { [fieldId: string]: any };

    const error = isValidToUpload(tableData).filter(e => e !== null);

    if (error.length > 0) {
      dispatch(saveDocumentErrorAction(error.join(' | ')));
      return;
    }

    for (const column of columnsToDisplay) {
      if (column.label && column.id) {
        const reloadOnChange = props.reloadOnChange ? { reloadOnChange: props.reloadOnChange } : {};
        const field = FieldElement.getDataElement({ ...column, accessType: column.accessType, ...reloadOnChange, tableId: props.id } as any);
        const fieldId = field.getFieldId();

        let options = [] as IOptionValue[];

        if (field.inputConfig.type === 'enum') {
          if (field.inputConfig.config.dataSource) {
            const dataSource = { ...field.inputConfig.config.dataSource };
            if (!enumFieldsOptions.hasOwnProperty(fieldId)) {
              options = await triggerFilterOptions({
                companyId: company,
                forwardedRef: props.forwardedRef,
                id: field.id,
                filter: '',
                type: field.inputConfig.type,
                url: props.fieldEnumOptionUrl || '',
                selectedValues: [],
                requiresFieldData: [],
                currentFieldList: props.fieldCollection || [],
                hasAllOption: false,
                enumItemsSource: dataSource,
                fieldEnumOptionUrlAdditionalFieldBody: props.fieldEnumOptionUrlAdditionalFieldBody,
              });
              enumFieldsOptions = { ...enumFieldsOptions, [fieldId]: options };
              options = enumFieldsOptions[fieldId];
            } else {
              options = enumFieldsOptions[fieldId];
            }
          } else {
            options = field.inputConfig.config.items;
          }
        }

        const columnValue = Array.from(new Array(tableData.length)).map((data, rowIndex) => {
          let fieldValue = tableData[rowIndex][column.label as string] || '';
          if (field.inputConfig.type === 'enum') {
            const enumValue: IItems | any = options.filter((items: IItems) => { return items.label === fieldValue });
            fieldValue = enumValue;
            if (enumValue && enumValue.length > 0 && !field.inputConfig.config.multiselect) {
              fieldValue = enumValue[0];
            }
          }
          if (field.inputConfig.type === 'date-time') {
            const dateTimeValue = new Date(fieldValue);
            fieldValue = fieldValue ? moment(dateTimeValue).format() : fieldValue;
          }
          return fieldValue
        });
        fieldData[column.id] = columnValue;
      }
    }

    if (props.fieldCollection) {
      const data = props.fieldCollection.find(e => e.id === name);
      if (data) {
        const config: any = { ...data.inputConfig?.config };
        const tempData = {
          ...data,
          inputConfig: {
            ...data.inputConfig,
            config: {
              ...config,
              columns: config.columns.map((col: IField) => {
                if (col.inputConfig && col.inputConfig.type === 'enum'
                  && enumFieldsOptions[col.id as string] && enumFieldsOptions[col.id as string].length > 0) {
                  col.inputConfig.config.items = enumFieldsOptions[col.id as string];
                }
                return col;
              })
            }
          }
        } as IField;
        FieldConstruct.setFieldToRefs([tempData], { fields: { [name]: fieldData } }, props.forwardedRef);
        let rows = tableData.length;
        if (rows < minRows) {
          rows = rows + (minRows - rows);
        }
        setMinRowState(rows);
        setRefresher(refresher + 1);
      }
    }

    dispatch(setDocumentActionMessage(`File has been uploaded successfully`));
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  return <>
    <div className={`vertical-table-container ${width <= 480 ? 'mobile' : ''} ${allColumnFieldsHidden ? 'hidden' : ''} `}>
      <label className={`vertical-table-title ${props.label === 'Untitled' ? 'hidden' : ''} ${width <= 480 ? 'full-width' : ''} `} style={{
        background: `${documentView.embeddedTableHeader} `,
        color: `${documentView.embeddedTableHeaderText} `
      }}>{props.label}</label>
      <div className={`vertical-table-navigation flex ${(!isScrollable && width > 480) ? 'hidden' : ''} ${width <= 480 ? 'mobile' : ''} `}>
        <Icon style={!showPrevButton() ? { display: 'none' } : {}} id={`arrow-left-${name} `}
          name='chevron circle left' onClick={onClickArrowLeft} />
        <Icon style={!showNextButton() ? { display: 'none' } : {}} id={`arrow-right-${name} `}
          name='chevron circle right' onClick={onClickArrowRight} />
      </div>
      {columnsToDisplay.length > 0 ?
        <div className={`vertical-table-content ${isScrollable ? 'hiddenScroll' : ''} `} ref={setRef} onScroll={onTableScroll}>
          <table className={`vertical-table-field-type`} id="vertical-table-field-type">
            <thead>
              <tr>
                {
                  columnsToDisplay.map((e: IField, i: number) => {
                    return <th className={e.accessType} style={columnsToDisplay.length < columnsDisplayedCount ? {
                      width: `calc(100% /${columnsToDisplay.length})`
                    } : {}
                    } onClick={() => sortTable(i)} key={uuid()} >
                      {e.label}
                      {
                        e.accessType === IFieldAccessType.Required &&
                        <span className='required-indicator'>*</span>
                      }
                      {
                        e.hint &&
                        <i id={`info-${name}`} className={`icon info-icon ${name}`} title={e.hint} />
                      }
                    </th>
                  })
                }
                {
                  width > 480 &&
                  <th>
                    <DownloadPopup
                      downloadLink={`/${userCompanyDetails.id}/documents/${match.params.documentId}/data/${name}?export=xlsx`}
                      method='get'
                      downloadName={props.label}
                      hasFileUpload={true}
                      dataFileUpload={() => dataFileUpload()}
                    />
                  </th>
                }
              </tr>
            </thead>
            <tbody id={`body-${name}`}>
              {generateRow()}
              {maxRowState > minRowState &&
                <tr>
                  {
                    columnsToDisplay.map((e: IField) => {
                      return <td className={e.accessType} key={uuid()}>
                        {width <= 480 &&
                          <Button className='btn-add-row' onClick={() => {
                            setMinRowState(minRowState + 1);
                            setActiveRow(minRowState)
                          }}>
                            Add a new row
                          </Button>
                        }
                      </td>
                    })
                  }
                  {width > 480 &&
                    <td>
                      <i className={`plus circular icon`} onClick={() => {
                        setMinRowState(minRowState + 1);
                        setActiveRow(minRowState)
                      }} />
                    </td>
                  }
                </tr>
              }
              <tr>
                {
                  columnsToDisplay.map((e: IField) => {
                    let style: CSSProperties = { fontWeight: 700, textAlign: 'center', border: 'none' };
                    if (e.inputConfig && e.inputConfig.config) {
                      if (e.inputConfig.config.alignment === 'left') {
                        style = { ...style, textAlign: 'left' }
                      }
                      if (e.inputConfig.config.alignment === 'right') {
                        style = { ...style, textAlign: 'right' }
                      }
                    }
                    return <td className={e.accessType} key={uuid()} style={style}>
                      {e.agg
                        ? addThousandSeparator(AggregateService.aggregate(getColumnValues(e.id || ''), e.agg).toString(), e.inputConfig || {} as IInputConfig)
                        : ''}
                    </td>
                  })
                }
                {width > 768 && <td style={{ border: 'none' }} />}
              </tr>
            </tbody>
          </table>
          {
            props.forwardedRef.current[name] && props.forwardedRef.current[name].error &&
            props.forwardedRef.current[name].error.length > 0 && props.showErrorMessage &&
            <Fragment>
              <small style={{ display: 'flex' }} />
              {props.forwardedRef.current[name].error.map((e: any, idx: number) => {
                return (
                  <small className='field error' key={idx}
                    dangerouslySetInnerHTML={{ __html: e.message }}
                  />
                )
              })}
            </Fragment>
          }
        </div>
        :
        <label className={`vertical-table-title ${props.label === 'Untitled' ? 'hidden' : ''} ${width <= 480 ? 'full-width' : ''}`} >
          No Fields Configured
        </label>
      }
    </div>
    <TableFileUpload open={showDataFileUpload} close={() => dataFileUpload()} uploadDataToTable={(file: any) => uploadDataToTable(file)} tableFields={columnsToDisplay} tableLabel={props.label} />
  </>
}

export default React.memo(VerticalTableTypeComponent, (prevProps: AcceptedProps, nextProps: AcceptedProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});
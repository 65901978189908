import React from 'react';
import { Icon } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../../../common/icons/svg/svg-wrapper';
import { fieldPermissionList } from '../../constant';

interface ISectionHeader {

}

interface IPermissionComponent {
}

const PermissionComponent: React.FC<IPermissionComponent> = (props) => {
  return <div id='permission-component' className='header'>
    {fieldPermissionList.map(permission => {
      let iconColor = '#8A9296';

      if (permission.id === 'editable') {
        iconColor = '#fff'
      }
      return <div key={permission.id} className={`permission-icon ${permission.id === 'editable' ? 'selected' : ''}`}>
        {permission.id === 'hidden' &&
          <svg className='slash' width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="14.3536" y1="0.353553" x2="0.353553" y2="14.3536" stroke={iconColor} />
          </svg>
        }
        <SVGWrapper iconName={permission.iconName} color={iconColor} width='16' height='16' />
      </div>
    })}

    <div className='permission-label'>
      EDITABLE
    </div>
  </div>
}

const SectionHeader: React.FC<ISectionHeader> = (props) => {
  return <div className='section-header'>
    <div className='header-info'>
      <span className='title'>HEADER</span>
      <span className='slider'>
        <div className="ui fitted checkbox">
          <input type="checkbox"
            className="hidden"
            onChange={() => { }}
            checked={false}
          />
          <label>Set Access Level Per Section</label>
        </div>
        {false && <div className='header-permission'>
          <div className="ui fitted checkbox">
            <input type="checkbox"
              className="hidden"
              onChange={() => { }}
              checked={false}
            />
            <label></label>
          </div>
          <PermissionComponent />
        </div>}
        <div className='settings'>
          <SVGWrapper iconName='cogIcon' color='#61a7b3' width='22' height='22' />
        </div>
      </span>
    </div>
    <div className='header-icon'>
      <Icon link className='collapse-icon' name={'chevron up'} size='small' />
    </div>
  </div>
}

export default SectionHeader;
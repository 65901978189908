// import { fromJS } from 'immutable'
import {
  GET_STATUS_SUMMARY,
  GET_STATUS_SUMMARY_RETURN,
  GET_STATUS_SUMMARY_ERROR,
  GET_BUSINESS_PROCESS_LOGO,
  GET_BUSINESS_PROCESS_LOGO_ERROR,
  GET_BUSINESS_PROCESS_LOGO_RETURN,
  GET_BP_STATUS_LIST,
  GET_BP_STATUS_LIST_ERROR,
  GET_BP_STATUS_LIST_RETURN,
  SET_SELECTED_WORKFLOW_FILTER,
  workspaceFilterType
} from './constant';
import { IStatusSummary } from './interface/workspace';
import { IFormStatus } from '../../../admin-module/module/business-process/module/workflow/interface/form-status';

export interface IWorkspaceReducer {
  statusSummaryLoading: boolean;
  statusSummary: IStatusSummary[];
  statusList: IFormStatus[];
  statusListError: string;
  statusSummaryError: string;
  businessProcessLogo: string;
  businessProcessLogoError: string;
  filter: workspaceFilterType[];
}

const initialState: IWorkspaceReducer = {
  statusSummaryLoading: false,
  statusSummary: [] as IStatusSummary[],
  statusList: [] as IFormStatus[],
  statusListError: '',
  statusSummaryError: '',
  businessProcessLogo: '',
  businessProcessLogoError: '',
  filter: []
}

const workspaceReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_STATUS_SUMMARY:
      newState.statusSummaryLoading = true;
      newState.statusSummary = [];
      newState.statusList = [];
      newState.statusSummaryError = '';
      return newState;

    case GET_STATUS_SUMMARY_RETURN:
      newState.statusSummaryLoading = false;
      newState.statusSummaryError = '';
      newState.statusSummary = action.statusSummary.filter((e: any) => e.name.toLowerCase() !== 'draft');
      return newState;

    case GET_STATUS_SUMMARY_ERROR:
      newState.statusSummaryError = action.message;
      newState.statusSummaryLoading = false;
      return newState;

    case GET_BUSINESS_PROCESS_LOGO:
      newState.businessProcessLogo = '';
      newState.businessProcessLogoError = ''
      return newState;

    case GET_BUSINESS_PROCESS_LOGO_RETURN:
      newState.businessProcessLogo = action.file;
      newState.businessProcessLogoError = ''
      return newState;

    case GET_BUSINESS_PROCESS_LOGO_ERROR:
      newState.businessProcessLogo = '';
      newState.businessProcessLogoError = action.message;
      return newState;

    case GET_BP_STATUS_LIST:
      newState.statusList = [];
      return newState;

    case GET_BP_STATUS_LIST_RETURN:
      newState.statusList = action.statusList;
      return newState;

    case GET_BP_STATUS_LIST_ERROR:
      newState.statusListError = action.message
      return newState;

    case SET_SELECTED_WORKFLOW_FILTER:
      newState.filter = action.filter;
      return newState;

    default:
      return state;
  }
}

export default workspaceReducer;
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';
import { RESET_DELETE_DOCUMENT_ERROR_MESSAGE, RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE } from '../form/constant';

export const GET_DRAFT_LIST = 'app/document/draft/GET_DRAFT_LIST';
export const GET_DRAFT_LIST_RETURN = 'app/document/draft/GET_DRAFT_LIST_RETURN';

export const draftListLimit = 50;

export const draftMessageList: IMessageProps[] = [
  {
    dispatchType: RESET_DELETE_DOCUMENT_ERROR_MESSAGE,
    type: 'negative',
    reducerContainer: 'documentForm',
    reducerItem: 'deleteDocumentErrorMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: RESET_DELETE_DOCUMENT_SUCCESS_MESSAGE,
    type: 'success',
    reducerContainer: 'documentForm',
    reducerItem: 'deleteDocumentSuccessMessage',
    hasCloseIcon: true
  },
]
export const companyColorPaletteList = [
  '#9D2913',
  '#F36527',
  '#FDEF3D',
  '#8EC740',
  '#448CCC',
  '#A186BF',
  '#F39ABF',
  '#EF4A5C',
  '#4CC7C3',
  '#A7EC6C',
  '#BC8DBF',
  '#014A7E',
  '#652D62',
  '#E9AF2F',
  '#ACACAC',
  '#FF847C',
  '#83AF9B',
  '#C02942',
  '#542437',
  '#FA6900',
  '#69D2E7',
  '#594F4F',
  '#556270',
  '#FC9D9A',
  '#C8C8A9',
  '#036564',
  '#D95B43',
  '#555152',
  '#D95B43',
  '#6A4A3C',
]

export const getRandomColor = () => {
  return companyColorPaletteList[Math.floor(Math.random() * companyColorPaletteList.length)]
}
import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { TValidationType } from '../../../service/field-properties.handler';
import { FieldProperties } from '../../../service/field-properties.service';
import { GeoLocationProps } from '../../../service/type/geolocation';
import { elementStatus } from '../../../utils/element-status';
import { AcceptedProps } from '../../interface/accepted-props';

type longLatType = 'lon' | 'lat';

const GeolocationType: React.FC<AcceptedProps> = (props) => {
  const name = props.id;
  const [refresher, setRefresher] = useState('');
  const elementProperties = new FieldProperties(name, props.forwardedRef, new GeoLocationProps());

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'geolocation' })
  }

  const validateInput = (schema: any) => {
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(uuid());
    if (props.triggerFilter) props.triggerFilter('');
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
  }

  const onBlur = (type: longLatType, value: any) => {
    if (typeof value !== 'number') {
      value = parseFloat(value || '0');
    }
    elementProperties.setCurrent({ value: { ...elementProperties.current.value, [type]: value } });
    validateInput({
      ...props.validationSchema,
      isRequired: props.accessType === 'required'
    });
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const getValue = (type: longLatType): number => {
    return elementProperties.current.value[type];
  }

  useEffect(() => {
    if (props.isAddRecord && !elementProperties.hasValue()) {
      elementProperties.setCurrent({ value: { ...props.defaultValue as any } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAddRecord, props.defaultValue])

  return (<>
    <span className={`field-info ${refresher}`}>
      <label
        ref={(element) => setLabelRef(element, name)}
        className={`field-label ${elementStatus({
          forwardedRef: props.forwardedRef,
          name: name,
          multiline: props.multiline || false,
          required: props.accessType === 'required',
          fieldValueObject: elementProperties
        })}`}>
        {props.label}
      </label>
      {
        props.hint &&
        <i id={`info-${name}`} className={`icon info-icon ${name}`} title={props.hint}></i>
      }
    </span>
    <div className={`field-input ui input single geolocation-input-container ${elementStatus({
      forwardedRef: props.forwardedRef,
      name: name,
      multiline: props.multiline || false,
      required: props.accessType === IFieldAccessType.Required,
      fieldValueObject: elementProperties
    })}`}>
      <input type='number' ref={(curRef) => {
        if (curRef && getValue('lat')) {
          curRef.value = getValue('lat').toString();
        }
      }} onBlur={(e) => onBlur('lat', e.target.value)} placeholder={`Latitude`} />
      <input ref={(curRef) => {
        if (curRef && getValue('lon')) {
          curRef.value = getValue('lon').toString();
        }
      }}
        type='number' onBlur={(e) => onBlur('lon', e.target.value)} placeholder={`Longitude`} />
    </div>
    {elementProperties.hasError() && props.showErrorMessage &&
      elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
      <Fragment>
        <small style={{ display: 'flex' }} />
        <small id={`error-${name}`} className='field error'
          dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
        />
      </Fragment>
    }
  </>)
}

export default GeolocationType;
import { ICustomFieldDetail } from '../interface/custom-field-detail';
import { GET_REFERENCED_FIELD_LIST_RETURN, GET_SELECTED_BP_STATUS_LIST_RETURN, GET_FROM_REF_FIELD_LIST_RETURN } from './constant';
import { IFormStatus } from '../../../component/admin-module/module/business-process/module/workflow/interface/form-status';

export interface IFilterConfigurationReducer {
  type?: string;
  referenceFieldList: ICustomFieldDetail[];
  selectedBpStatusList: IFormStatus[];
  fromRefFieldList: ICustomFieldDetail[];
  refSourceFieldList: { [sourceId: string]: ICustomFieldDetail[] };
  sourceId: string;
}

const initialState: IFilterConfigurationReducer = {
  referenceFieldList: [],
  selectedBpStatusList: [],
  fromRefFieldList: [],
  refSourceFieldList: {},
  sourceId: ''
};

const fielterConfigurationReducer = (state = initialState, action: IFilterConfigurationReducer) => {
  let newState = { ...state };

  switch (action.type) {

    case GET_REFERENCED_FIELD_LIST_RETURN:
      newState.referenceFieldList = action.referenceFieldList;
      return newState;

    case GET_SELECTED_BP_STATUS_LIST_RETURN:
      newState.selectedBpStatusList = action.selectedBpStatusList;
      return newState;

    case GET_FROM_REF_FIELD_LIST_RETURN:
      let tempRefSourceFieldList = { ...newState.refSourceFieldList };
      if (!tempRefSourceFieldList[action.sourceId]) {
        tempRefSourceFieldList[action.sourceId] = action.fromRefFieldList;
      }
      newState.refSourceFieldList = tempRefSourceFieldList;
      return newState;

    default:
      return newState;
  }
}

export default fielterConfigurationReducer

import React, { useState, useEffect, CSSProperties, useRef, Fragment } from 'react';
import { AcceptedProps } from '../../interface/accepted-props';
import { IField, IFieldAccessType, IOptionValue } from '../../../../../../component/admin-module/module/users/interface/field';
import { getOption, triggerFilterOptions } from '../../enum-type/utils';
import { v4 as uuid } from 'uuid';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../reducers';
import { IForm } from '../../../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../../../../component/admin-module/module/data-tables/reducer';
import { setFocusedElement, getFocusedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import MultiselectDropdown from '../../../../../general/multiselect-dropdown';
import { FieldProperties } from '../../../service/field-properties.service';
import { EnumMultiselectProps } from '../../../service/type/enum-multiselect';
import { useInitialLoad } from './hook/use-initial-load';
import { triggerAutomationUpdateField } from './util/trigger-field-update-on-option-change';
import { TValidationType } from '../../../service/field-properties.handler';
import { isWithShortcut } from './util/is-with-shortcut';
import { useIsAddRecord } from './hook/use-is-add-record';
import { setConstraintInitialValue } from './util/set-constraint-initial-value';
import { IConfigName } from '../../../../type/interface/field-type-object';
import DropdownComponent from '../../../../../general/dropdown';
import { isInsideTable } from './util/is-inside-table';
import { formatEnumValidationSchema } from './util/format-validation-schema';
import { setTableEnumOptions } from '../../table-type/utils/set-table-enum-options';

interface IDropdownOption {
  key: string;
  text: string;
  value: string;
  deleted: boolean | undefined;
}

let searchFilterTimeout = setTimeout(() => { }, 1000);

const DropdownMultiSelect: React.FC<AcceptedProps> = (props) => {
  const { userBusinessProcess, userDataTable } = useSelector((state: IRootState) => state.endUser);
  const focusedElement = getFocusedElement();

  const { company } = useSelector((state: IRootState) => state.auth);
  const name = props.getFieldId();
  const mountedRef = useRef(true)
  const [refresher, setRefresher] = useState(0);
  const [triggerSearchFilter, setTriggerSearchFilter] = useState(0);
  const [hasAccessShorctut, setHasAccessShortCut] = useState(false);
  const [popup, setPopup] = useState(false);
  const [forceReadOnlyElement, setForceReadOnlyElement] = useState(false);

  const elementProperties = new FieldProperties(name, props.forwardedRef, new EnumMultiselectProps());
  const elementId = props.tableColumnRowIndex ? `dropdown-${name}-${props.tableColumnRowIndex}` : `dropdown-${name}`;

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'multiple-enum' })
  }

  const resetSearchOptionData = () => {
    elementProperties.setCurrent({ ...elementProperties.current, optionsFilterSearch: '' })
    clearTimeout(searchFilterTimeout);
  }

  const validateSelectedValue = () => {
    let schema = formatEnumValidationSchema(props);
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(refresher + 1);
  }

  const setFullDetailOfSelectedValue = (value: string[]) => {
    let tempSelectedValue: IOptionValue[] = [];
    let options = getOption(props);
    if (elementProperties.current.filteredOption) {
      options = elementProperties.current.filteredOption;
    }

    options.forEach((e: IOptionValue) => {
      if (value.indexOf(e.id) > -1) {
        tempSelectedValue.push(e);
      }
    });

    elementProperties.setCurrent({ ...elementProperties.current, selectedOptionFilter: tempSelectedValue });
  }

  const handleOnChange = (e: any, { value }: { value: string[] }, triggerFilter?: boolean) => {
    resetSearchOptionData();
    elementProperties.setCurrent({ ...elementProperties.current, value });
    setRefresher(refresher + 1); // to refresh state
    setFullDetailOfSelectedValue(getValue())
    if (props.accessType === IFieldAccessType.Required) {
      validateSelectedValue();
    }
    if (props.triggerFilter && !triggerFilter) {
      props.triggerFilter('')
    }
    if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
      props.triggerFieldReload(props.reloadOnChange);
    }
    if (props.triggerRelatedApplication && props.configName === IConfigName.Referenced) {
      const config: any = props.inputConfig.config;
      const dataType = Object.keys(config.source).includes('formId') ? 'document' : 'database';
      props.triggerRelatedApplication({
        id: elementProperties.current.value,
        source: config.source[Object.keys(config.source).toString()],
        dataType: dataType,
        multiple: config.multiselect,
        fieldId: name
      });
    }
    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }

    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    setTimeout(() => {
      if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
      if (props.automationService && props.fieldCollection) {
        const field = props.fieldCollection.find((e: IField) => e.id === props.id);
        if (field) {
          props.automationService.didUpdateFieldValue(field, value)
        }
      }
    }, 500);
  }

  const getValue = () => {
    return elementProperties.current.value || []
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue = props.defaultValue ? props.defaultValue as string[] : [];
    if (!props.defaultValueElement && defaultValue) {
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValue });
      setRefresher(refresher + 1);
    }
  }

  const removeDeletedItemInOptions = (option: IDropdownOption[], value: any): IDropdownOption[] => {
    return option.filter((e: IDropdownOption) => {
      let isValueAlreadySelected = false;
      if (value instanceof Array) {
        isValueAlreadySelected = (value.indexOf(e.value) > -1);
      } else {
        isValueAlreadySelected = e.value === value;
      }

      if (e.deleted && !isValueAlreadySelected) {
        return false;
      }
      return true;
    })
  }

  const elementStatus = (value: any) => {
    return elementProperties.hasValue() && !elementProperties.hasError() ? 'valid' : elementProperties.hasError() ? 'error' : '';
  }

  const fieldStyle = (): CSSProperties => {
    let style = {} as CSSProperties;
    if (props.hasInlineStyle) {
      const headerElement = document.getElementById(`header-${name}`);
      if (headerElement) {
        const { width } = headerElement.getBoundingClientRect();
        style = { maxWidth: `${width}px` }
      } else {
        style = { maxWidth: `${100}px` }
      }
    }
    return style;
  }

  const onSearchChange = (e: any) => {
    if (props.fieldEnumOptionUrl) {
      elementProperties.setCurrent({ ...elementProperties.current, optionsFilterSearch: e.target.value })
      clearTimeout(searchFilterTimeout);
      searchFilterTimeout = setTimeout(() => {
        setTriggerSearchFilter(triggerSearchFilter + 1)
      }, 1000);
    }
  }

  const ignoreFetch = () => {
    let ignoreFetch = false;
    let options = getOption(props);
    if ((props.flags && props.flags.indexOf('constraint') > -1
      && options.length > 0 && !!props.inputConfig.config.value) ||
      (props.configName === 'Referenced' && options.length > 0)) {
      ignoreFetch = true;
    }
    return ignoreFetch;
  }

  const triggerSearchOption = async () => {
    if (ignoreFetch()) return;
    if (props.fieldEnumOptionUrl && elementProperties.isSet()) {
      const retOptions = await triggerFilterOptions({
        companyId: company,
        id: name,
        originalId: props.originalId,
        forwardedRef: props.forwardedRef,
        filter: elementProperties.current.optionsFilterSearch || '',
        url: props.fieldEnumOptionUrl,
        selectedValues: elementProperties.current.selectedOptionFilter || [],
        requiresFieldData: props.requiresFieldData,
        currentFieldList: props.fieldCollection || [],
        hasAllOption: (props.inputConfig.config.items.filter((e: IOptionValue) => e.id === 'all')).length > 0,
        fieldEnumOptionUrlAdditionalFieldBody: props.fieldEnumOptionUrlAdditionalFieldBody,
        type: props.inputConfig.type,
        tableId: props.tableId,
        localFilter: props.inputConfig.config.filterEnumOptions,
        enumItemsSource: props.inputConfig.config.dataSource,
        sort: props.inputConfig.config.sort,
      });
      if (mountedRef.current) {
        elementProperties.setCurrent({ ...elementProperties.current, filteredOption: retOptions })
        triggerAutomationUpdateField({
          value,
          removeDeletedItemInOptions,
          getOption,
          automationService: props.automationService,
          fieldCollection: props.fieldCollection,
          currentProps: props,
          elementProperties
        })
        if (props.tableFieldEnumOptionsRef && props.tableId) {
          setTableEnumOptions(props.tableFieldEnumOptionsRef, retOptions, props.tableId, name);
        }
        setRefresher(refresher + 1);
      }
    }
  }

  const checkIfAllowedToCreate = async () => {
    let hasAccess = false;
    if (props.inputConfig.config.source && props.inputConfig.config.source.formId) {
      const myForm = userBusinessProcess.find((form: IForm) => props.inputConfig.config.source && form.id === props.inputConfig.config.source.formId)
      if (myForm) {
        hasAccess = true;
      }
    }
    if (props.inputConfig.config.source && props.inputConfig.config.source.databaseId) {
      const mydatatable = userDataTable.find((dt: IDataTable) =>
        props.inputConfig.config.source && dt.id === props.inputConfig.config.source.databaseId
        && dt.allowedAccess === 'edit'
      )
      if (mydatatable) {
        hasAccess = true;
      }
    }
    setHasAccessShortCut(hasAccess)
  }

  const focusInput = (id: string) => {
    setFocusedElement(id);
    if (typeof props.tableColumnRowIndex === 'number') {
      setTableFocusedElement({
        index: props.tableColumnRowIndex,
        focusedElement: id,
        columnDisplayed: props.tableColumnDisplayed as number[]
      });
    }
  }

  const enumOptionValue = (e: IOptionValue): IDropdownOption => {
    return { key: uuid(), text: e.label, value: e.id, deleted: e.deleted || undefined };
  }

  const enumOptions = () => {
    const filteredOption = elementProperties.current.filteredOption;
    const componentOption = getOption(props).map(e => enumOptionValue(e));
    if (filteredOption && filteredOption.length) {
      for (const e of filteredOption) {
        const option = enumOptionValue(e);
        const existing = componentOption.find(e => e.value === option.value);
        if (!existing) {
          componentOption.push(option);
        }
      }
    }
    return removeDeletedItemInOptions(componentOption, value);
  }

  const renderError = () => {
    return <>
      {
        elementProperties.hasError() && props.showErrorMessage &&
        elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
        <Fragment>
          <small style={{ display: 'flex' }} />
          <small className='field error'
            dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
          />
        </Fragment>
      }
    </>
  }

  const renderDefaultDropdown = () => {
    return !(props.useSpecialEnum && props.inputConfig?.config?.multiselect) &&
      <DropdownComponent
        id={elementId}
        className={`field-input ${!props.triggerFilter ? elementStatus(value) : ''}`}
        fluid
        multiple={true}
        onOpen={() => setPopup(true)}
        selection
        search
        clearable={props.triggerFilter !== undefined ? false : true}
        disabled={props.accessType === IFieldAccessType.Readonly || forceReadOnlyElement}
        value={value}
        options={options}
        onChange={(e, { value }: any) => handleOnChange(undefined, { value })}
        onSearchChange={onSearchChange}
        selectOnBlur={false}
        placeholder={props.havePlaceholder ? props.placeHolder || props.label : ''}
        searchInput={{ autoFocus: props.isFocus || focusedElement === elementId }}
        style={fieldStyle()}
        icon={props.customDropdownIcon ? props.customDropdownIcon : undefined}
        onClose={() => { onSearchChange({ target: { value: '' } }); removeFocusedElement(); }}
        onFocus={() => { focusInput(elementId); setPopup(false); props.setActiveField && props.setActiveField(elementId); }}
      />
  }

  const renderSpecialDropdown = () => {
    let defaultText = 'All';
    if (!props.isDatatable) {
      defaultText = props.placeHolder ? props.placeHolder : '';
    }
    return props.useSpecialEnum && props.inputConfig?.config?.multiselect &&
      <MultiselectDropdown
        defaultText={defaultText}
        value={value && value instanceof Array ? value.filter((e: string) => e !== 'all') : undefined}
        option={options.filter(e => e.value !== 'all').map((e: IDropdownOption) => { return { label: e.text, value: e.value } })}
        onChange={(values: string[], triggerFilter?: boolean) => handleOnChange(undefined, { value: values }, triggerFilter)}
        onSearchChange={onSearchChange}
        customDropdownIcon={props.customDropdownIcon}
        isClearable={props.isClearable}
        name={name}
        isExpand={value && value instanceof Array && value.length > 0}
      />
  }

  const renderShorcutButton = () => {
    return hasAccessShorctut && props.inputConfig.config.showCreateBpOrDt && props.createDataForReference &&
      <div className={`shortcut-btn-container`} onClick={() => props.createDataForReference && props.createDataForReference({ ...props.inputConfig.config.source, fieldId: name, rowIndex: props.tableColumnRowIndex })}>
        <i className={'plus icon'} />
      </div>

  }

  useIsAddRecord({
    setConstraintInitialValue,
    getOption,
    setDefaultValue,
    props,
    setForceReadOnlyElement,
    setRefresher,
    elementProperties,
    refresher
  })

  useEffect(() => {
    if (popup || (props.useSpecialEnum && props.inputConfig?.config?.multiselect)) {
      triggerSearchOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearchFilter, popup])

  useInitialLoad({
    accessType: props.accessType,
    mountedRef,
    validateSelectedValue,
    checkIfAllowedToCreate,
  })

  useEffect(() => {
    if (props.isClearValue && elementProperties.isSet()) {
      elementProperties.setCurrent({ ...elementProperties.current, value: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearValue])

  let value = getValue();
  setFullDetailOfSelectedValue(value);

  const options = enumOptions();

  if (options.length === 0) {
    value = [];
  }

  return <>
    {props.accessType !== 'hidden' &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div className={`field-dropdown-container ${isInsideTable(props) ? 'inside-table' : ''} ${isWithShortcut(props, hasAccessShorctut) ? 'with-shortcut' : ''}`}
          onClick={() => focusInput(elementId)}>
          {renderSpecialDropdown()}
          {renderDefaultDropdown()}
          {renderShorcutButton()}
        </div>
        {renderError()}
      </>
    }
  </>
}

export default React.memo(DropdownMultiSelect);
import React from 'react';
import spliceArrayToChunk from '../../../../../common/utils/splice-array-to-chunk';
import { FieldElement } from '../../../../../common/field/component/field-element';
import { IField, IInputConfig } from '../interface/field';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';

interface ICustomField {
  dynamicRefs: any;
  customFieldList: IField[];
  triggerFilter(): void;
  company: string;
}

const DynamicFilterField: React.FC<ICustomField> = (props) => {
  return <>
    {
      props.customFieldList && spliceArrayToChunk(props.customFieldList, 2).map((spliceConfig: IField[], i: number) => {
        return <React.Fragment key={`form-group-${i}`}>
          {
            spliceConfig.map((fieldConfig: IField, j: number) => {
              let customFilterField: IField = {
                ...fieldConfig,
                inputConfig: {
                  ...fieldConfig.inputConfig,
                  config: {
                    ...fieldConfig.inputConfig?.config,
                    multiselect: false
                  }
                } as IInputConfig
              }

              const field = FieldElement.getDataElement(customFilterField);
              const Element = field.getFieldElement();
              return <div key={`element-${fieldConfig.id}-${j}`} className={`six wide field ${field.accessType}`} >
                <Element {...field} hint='' forwardedRef={props.dynamicRefs}
                  havePlaceholder={fieldConfig.configName === IConfigName.Dropdown}
                  defaultValueElement={false}
                  triggerFilter={props.triggerFilter}
                  fieldCollection={props.customFieldList}
                  fieldEnumOptionUrl={`${props.company}/users/fields`}
                  isUser={true}
                />
              </div>;
            })
          }
          {spliceConfig.length === 1 &&
            <div key={`element-no-field-${i}`} className="six wide field"></div>
          }
        </React.Fragment>
      })
    }
  </>
}

export default DynamicFilterField;
import { AggregateFunction } from '../fx.agg-func';

export class AvgAggregateFunction implements AggregateFunction {

  name = 'avg';

  aggregate(values: number[]): number {
    let sum = 0;
    let count = 0;
    values.forEach((curr) => {
      if (curr !== undefined && curr !== null) {
        count += 1;
        sum += curr;
      }
    });
    if (count > 0) {
      return sum / count;
    } else {
      return 0;
    }
  }

}
import React from 'react';
import { PagerComponent } from '@syncfusion/ej2-react-grids';

interface IPagerProps {
  totalCount: number;
  currentLimit: number;
  changePage(activePage: number, offset: number): void;
  currentOffset: number;
}

const GridPagination: React.FC<IPagerProps> = (props) => {

  const triggerOnChange = (event: any) => {
    const offset = (event.currentPage - 1) * props.currentLimit;
    props.changePage(props.currentLimit, offset);
  }

  return <PagerComponent
    pageSize={props.currentLimit}
    totalRecordsCount={props.totalCount}
    pageCount={5}
    immediateRender
    click={triggerOnChange}
  />
}

export default GridPagination;

import React from 'react';
import { Table } from 'semantic-ui-react-bpm';
import PortalUserTableHeader from './header';
import PortalUserTableList from './list';

interface IPortalUserList {

}

const PortalUserList: React.FC<IPortalUserList> = (props) => {
  return <div className='portal-user-list'>
    <table className='portal-user-table table-container'>
      <Table.Header>
        <PortalUserTableHeader />
      </Table.Header>
      <PortalUserTableList />
    </table>
  </div>
}

export default PortalUserList;
import { put, takeLatest } from 'redux-saga/effects';
import { setStatusCodeAction } from '../../../../../error/action';
import { IPortalFieldListGetInput } from './interface/input/field-list-get';
import { IPortalListViewGetInput } from './interface/input/list-view-get';
import {
  GET_PORTAL_LIST_VIEW,
  GET_PORTAL_FIELD_LIST
} from './constant';

export function* getPortalListView(args: IPortalListViewGetInput) {
  try {

  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getPortalFieldList(args: IPortalFieldListGetInput) {
  try {

  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* portalListViewSagas() {
  yield takeLatest(GET_PORTAL_LIST_VIEW, getPortalListView);
  yield takeLatest(GET_PORTAL_FIELD_LIST, getPortalFieldList);
}

// All sagas to be loaded
export default portalListViewSagas;
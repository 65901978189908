import React from 'react';
import PortalUserTableDetail from './detail';

interface IListProps {

}

const PortalUserTableList: React.FC<IListProps> = (props) => {
  return <tbody>
    <PortalUserTableDetail />
    <PortalUserTableDetail />
    <PortalUserTableDetail />
    <PortalUserTableDetail />
  </tbody>
}

export default PortalUserTableList;
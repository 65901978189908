import { ValidationType } from './validation-type';
import { FieldElement } from '../../component/field-element';

export class SpacerValidator implements ValidationType {

  name = 'spacer';

  validate(field: FieldElement, value: string) {
    return [];
  }

}


import { useEffect, useState } from 'react';
import { ITaskView, ITaskViewConfig } from '../interface/task-view';
import { IFormStatusWithAction } from '../../../../workflow/interface/form-status';
import { ICustomFilterContainer } from '../../custom-filter/interface/custom-filter-field';
import { systemFieldLabel } from '../../custom-filter/constant';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IProps {
  formStatusList: IFormStatusWithAction[];
  taskView: ITaskView;
  defaultTaskConfig: ITaskViewConfig;
  formFieldList: ICustomFieldDetail[];
  taskViewRef: React.MutableRefObject<ITaskView>;
}

export const useSetTaskViewState = (props: IProps) => {
  const { taskView, formStatusList, defaultTaskConfig, formFieldList, taskViewRef } = { ...props };

  const [taskViewConfig, setTaskViewConfig] = useState<ITaskViewConfig>({ ...defaultTaskConfig });
  const [selectedStatus, setSelectedStatus] = useState('');

  const setTaskViewFields = (taskViewData: ITaskView) => {
    let tempTaskViewData = {} as ITaskView;
    formStatusList.length > 0 && formStatusList.forEach(status => {
      let data: any = {} as ICustomFilterContainer;
      if (taskView[status.id]) {
        const filterFields = taskView[status.id].filterField;
        for (let i = 0; i < 4; i++) {
          const key = i.toString();
          data[key] = filterFields[key];
          if (typeof filterFields[key] === 'object') {
            if (data[key].fields.length > 0) {
              const tempData = data[key].fields.map((fieldValue: string) => {
                let [type, fieldName, label] = fieldValue.split('|||||');
                let existingField = formFieldList.filter((field: ICustomFieldDetail) => field.id === fieldName);
                if (type === 'system') {
                  label = systemFieldLabel[fieldName];
                }
                if (type === 'field' && existingField.length > 0) {
                  type = existingField[0].type || 'no Type';
                  label = existingField[0].label || 'No Label';
                }
                return `${type}|||||${fieldName}|||||${label}`;
              })
              data[key] = { ...data[key], fields: tempData };
            }
          }
        }
        tempTaskViewData[status.id] = { ...taskViewData[status.id], filterField: data };
      }
    });
    return tempTaskViewData;
  }

  useEffect(() => {
    const tempTaskView = { ...setTaskViewFields(taskView) };
    taskViewRef.current = { ...tempTaskView };
    if (tempTaskView && formStatusList.length > 0) {
      setSelectedStatus(formStatusList[0].id);
      setTaskViewConfig(taskViewRef.current[formStatusList[0].id] || { ...defaultTaskConfig })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskView, formStatusList, formFieldList])

  return { ...props, taskViewConfig, setTaskViewConfig, selectedStatus, setSelectedStatus };
}
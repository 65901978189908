import React, { useState } from 'react';
import { Image } from 'semantic-ui-react-bpm';
import useDeviceDetect from '../../../../../../common/general/device-detect';
import { icons } from '../../../../../../common/icons';
import { ISenderInfo } from '../../interface/sender-info';
import ChatFilterMoreUsers from './more-users';
import ChatFilterType from './type';
import ChatFilterUsers from './users';

export interface IChatFilterProps {
  closeChatbox(): void;
  selectedFilter: string[];
  filterComment(value: any): void;
  userList: ISenderInfo[];
  selectedUser: string[];
  selectUser(value: string): void;
  clearFilter(): void;
  userColor: { [id: string]: string };
  hideFilterType?: boolean;
}

const ChatFilter: React.FC<IChatFilterProps> = (props) => {
  const { width } = useDeviceDetect();

  const [errorImage, setErrorImage] = useState<string[]>([])
  const [showMoreUser, setShowMoreuser] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const moreThanFiveUserList = props.userList.filter((user, index) => index >= 5);
  const hasSelectedInMore = moreThanFiveUserList.filter(user => user && user.sender &&
    user.sender.data && props.selectedUser.indexOf(user.sender.data.id) > -1).length > 0;

  const addToErrorImage = (userId: string) => {
    const tempErrorImage = [...errorImage];
    tempErrorImage.push(userId);
    setErrorImage(tempErrorImage)
  }

  const handleOnClickMoreUser = () => {
    setShowMoreuser(!showMoreUser)
  }

  const handleOnBlurMoreUser = () => {
    if (!onMouseOver) {
      setShowMoreuser(false)
    }
  }

  const handleOnHoverMoreUser = () => {
    setOnMouseOver(true);
  }

  const handleOnMouseOutMoreUser = () => {
    setOnMouseOver(false);
    document.getElementById('more-user')?.focus()
  }

  const clearFilter = () => {
    if (props.selectedFilter.length === 0 && props.selectedUser.length === 0) return;
    props.clearFilter();
  }

  return <>
    <div className={`chat-filter`}>
      {width <= 480 &&
        <Image onClick={() => props.closeChatbox()}
          className='close' src={icons.black.closePlain} />
      }
      {!props.hideFilterType &&
        <ChatFilterType
          selectedFilter={props.selectedFilter}
          filterComment={props.filterComment}
        />
      }
      <div className={`filter-by-user`}>
        {moreThanFiveUserList.length > 0 &&
          <span tabIndex={0} id='more-user' className={`more-user ${hasSelectedInMore || showMoreUser ? 'selected' : ''}`}
            onBlur={handleOnBlurMoreUser} onClick={handleOnClickMoreUser}>
            +{moreThanFiveUserList.length}
          </span>
        }
        <ChatFilterUsers {...props}
          addToErrorImage={addToErrorImage}
          errorImage={errorImage}
        />
        {showMoreUser &&
          <ChatFilterMoreUsers {...props}
            addToErrorImage={addToErrorImage}
            errorImage={errorImage}
            handleOnHoverMoreUser={handleOnHoverMoreUser}
            handleOnMouseOutMoreUser={handleOnMouseOutMoreUser}
            moreThanFiveUserList={moreThanFiveUserList}
          />
        }
      </div>
      {(props.selectedFilter.length > 0 || props.selectedUser.length > 0) &&
        <div className={`clear-filter ${props.selectedFilter.length === 0 && props.selectedUser.length === 0 ? 'disabled' : ''}`}
          onClick={clearFilter}>
          <label>CLEAR</label>
        </div>
      }
    </div>
  </>;
}

export default ChatFilter;
import React from 'react';
import PortalSHProfileDetail from './detail';

interface ISHProfile {

}

const PortalSHProfile: React.FC<ISHProfile> = (props) => {
  return <div className='portal-sh-profile-main'>
    <div className='portal-sh-profile-list'>
      <PortalSHProfileDetail headerName={'customer'} />
      <PortalSHProfileDetail headerName={'suppliers'} />
      <PortalSHProfileDetail headerName={'products'} />
      <PortalSHProfileDetail headerName={'clients'} />
    </div>
  </div>
}

export default PortalSHProfile;
import React from 'react';
import { IComment } from '../../../../interface/comment';
import SytemMessagesDeltaFields from './delta-fields';

interface ISytemMessageComment {
  comment: IComment;
}

const SytemMessageComment: React.FC<ISytemMessageComment> = ({ comment }) => {
  const { deltaFields } = { ...comment };

  if (!!deltaFields && deltaFields.length > 0) {
    return <SytemMessagesDeltaFields comment={comment} />
  }

  return <p>{comment.text}</p>
}

export default SytemMessageComment;

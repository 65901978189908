import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { FieldConstruct } from '../../../../../../../../../../../../../common/field/field-construct';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IField } from '../../../../../../../../../../users/interface/field';
import AutomationDetailHeader from '../../../widget/detail-header-widget';
import SelectedFieldSection from './fields';
import { IActionTypeRegistryRender } from '../registry';
import { useSetEditFormRefsAndState } from './hooks/use-set-edit-form-refs-and-state';
import { DetailActionButton } from '../../../action-button';
import { setRowRefState } from '../../../../../../../../../../../../document-module/module/form/utils/manage-form-data';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { FormEnumLabel } from '../../../../../../../../../../../../document-module/module/form/utils/manage-enum-id-label';

class EditFormComponent {

  name = 'edit-form';

  render: React.FC<IActionTypeRegistryRender> = (props) => {
    let onComponentLeave = setTimeout(() => { }, 1000);

    const match: { params: { formId: string } } = useRouteMatch();

    const { inputFieldList } = useSelector((state: IRootState) => state.automation);
    const { company } = useSelector((state: IRootState) => state.auth);

    const [refresher, setRefresher] = useState(0);
    const [searchField, setSearchField] = useState('');

    const dynamicRef = useRef<any>({});
    const tableRowRef: any = useRef({});

    const {
      selectedField,
      operation,
      setOperation,
      getSelectedFieldList,
      setSelectedField,
      setDateFieldObject,
      dateFieldObject
    } = useSetEditFormRefsAndState({
      dynamicRef,
      inputFieldList,
      selectedComponent: props.selectedComponent,
      match
    });

    const getFieldDataFromRefs = () => {
      let fields = FieldConstruct.getFieldDataFromRefs(getSelectedFieldList(selectedField) || [], dynamicRef);
      const hasEnumSelected = getSelectedFieldList(selectedField).filter(e => e.configName === IConfigName.Dropdown);
      if (hasEnumSelected.length > 0) {
        fields = FormEnumLabel.getEnumIdLabel(fields, dynamicRef, getSelectedFieldList(selectedField) || []);
      }
      selectedField.forEach(fieldId => {
        if (dateFieldObject[fieldId]) {
          fields[fieldId] = 'today';
        }
      });
      return Object.entries(fields).reduce((field: any, [key, value]: any) => {
        field[key] = value;
        if (value && typeof value === 'object' && (value.hasOwnProperty('keyPath') || value.hasOwnProperty('referencedKeyPath'))) {
          if (value.referencedKeyPath === 'current-form') {
            field[key] = { keyPath: value.keyPath }
          }
        }
        return field;
      }, {});
    }

    const saveClick = () => {
      const tempComponent = { ...props.selectedComponent };
      if (tempComponent) {
        tempComponent.config = tempComponent.config ? { ...tempComponent.config } : {};
        tempComponent.config.selectedField = selectedField;
        tempComponent.config.fields = getFieldDataFromRefs();
        props.updateCurrentAutomation(tempComponent, false);
      }
    }

    const refreshComponentToChangeButton = () => {
      if (props.hasComponentChange.current === false) {
        setRefresher(refresher + 1)
        props.parentRefresher();
      }
    }

    const storeDynamicField = () => {
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const openModal = (value: boolean, fieldId: string) => {
      if (value) {
        storeDynamicField();
      }
      const tempContainer: { [id: string]: boolean } = props.refsObject.current.isVisible ? props.refsObject.current.isVisible : {};
      getSelectedFieldList(selectedField).forEach((field: IField) => {
        tempContainer[field.id || ''] = field.id === fieldId ? !value : false;
      });
      props.refsObject.current.isVisible = tempContainer;
      setRefresher(refresher + 1)
    }

    const manageOperator = (value: string, fieldId: string) => {
      const tempContainer: { [id: string]: string } = {};
      getSelectedFieldList(selectedField).forEach((field: IField) => {
        const tempFieldId = field.id || ''
        const tempOperation = tempFieldId === fieldId ? value || 'value' : operation[tempFieldId] || 'value';
        if (tempOperation === 'value') {
          dynamicRef.current[fieldId] = null;
        }
        tempContainer[tempFieldId] = tempOperation;
      });
      setOperation(tempContainer);
      refreshComponentToChangeButton();
      props.hasComponentChange.current = true;
    }

    const manageSelectedField = (value: string) => {
      refreshComponentToChangeButton();
      const tempSelectedField = [...selectedField]
      const currentIndex = tempSelectedField.indexOf(value);
      if (currentIndex > -1) {
        tempSelectedField.splice(currentIndex, 1);
        delete dynamicRef.current[value];
      } else {
        tempSelectedField.push(value);
      }
      setSelectedField(tempSelectedField)
      props.hasComponentChange.current = true;
    }

    const openFieldDropdown = () => {
      props.refsObject.current.editFormSelectFieldOpen = true;
      openModal(false, 'none');
      setRefresher(refresher + 1);
    }

    const closeFieldDropdown = () => {
      props.refsObject.current.editFormSelectFieldOpen = false;
      setRefresher(refresher + 1);
      setSearchField('');
    }

    const onFieldSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, fieldId: string) => {
      manageSelectedField(fieldId);
      e.stopPropagation();
      if (searchField) {
        closeFieldDropdown();
      }
    }

    const manageDateFieldToday = (value: boolean, fieldId: string) => {
      let tempDateFieldObj = { ...dateFieldObject };
      tempDateFieldObj[fieldId] = value;
      setDateFieldObject(tempDateFieldObj);
      storeDynamicField();
    }

    const saveOnMouseLeave = () => {
      if (typeof onComponentLeave === 'function') { clearTimeout(onComponentLeave); };
      onComponentLeave = setTimeout(() => {
        if (props.isHasParent && props.hasComponentChange.current === true) saveClick();
      }, 500);
    }

    useEffect(() => {
      if (inputFieldList[match.params.formId] &&
        inputFieldList[match.params.formId].length > 0) {
        setRowRefState(tableRowRef, inputFieldList[match.params.formId]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFieldList, match.params.formId])

    return (<>
      <div className={`automation-form-detail-action edit-form-action`} onMouseLeave={saveOnMouseLeave}>
        <AutomationDetailHeader
          title={`Edit Issue`}
          description={`Set values for fields on the issue. Simply add the fields you want to edit.`}
          iconHeight={20}
          iconWidth={25}
          iconName='pen'
        />
        <div className={`automation-field-container`}>
          <div className={`automation-field `}>
            <Dropdown
              text='Choose field to set'
              icon='dropdown'
              floating
              labeled
              button
              className='icon'
              popperContainer={DropdownPopperOption}
              onClick={openFieldDropdown}
              open={props.refsObject?.current?.editFormSelectFieldOpen}
              search
              onSearchChange={(e: any) => setSearchField(e.target.value)}
              onClose={closeFieldDropdown}
            >
              <Dropdown.Menu className={`${props.refsObject.current.editFormSelectFieldOpen === true ? 'visible' : ''}`}>
                <div className={`option-title`}>Common Field</div>
                <Dropdown.Menu scrolling className={`option-list`}>
                  {
                    inputFieldList[match.params.formId] && inputFieldList[match.params.formId].map((field: IField, index: number) => {
                      if (field && field.label && field.label.toUpperCase().indexOf(searchField.toUpperCase()) === -1) {
                        return undefined;
                      }
                      return <div key={uuid()} className="ui fitted checkbox" onClick={(e) => onFieldSelect(e, field.id || '')}>
                        <input className="hidden" type="checkbox" onChange={() => { }} checked={selectedField.indexOf(field.id || '') > -1} />
                        <label>{field.label}</label>
                      </div>
                    })
                  }
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <SelectedFieldSection
            dynamicRef={dynamicRef}
            company={company}
            storeDynamicField={storeDynamicField}
            openModal={openModal}
            operation={operation}
            manageOperator={manageOperator}
            selectedComponent={props.selectedComponent}
            manageDateFieldToday={manageDateFieldToday}
            dateFieldObject={dateFieldObject}
            fieldList={getSelectedFieldList(selectedField)}
            formId={match.params.formId}
            refsObject={props.refsObject}
          />
        </div>
      </div>
      {props.hasComponentChange.current === true &&
        <DetailActionButton automationId={props.selectedComponent.id} saveAutomation={() => saveClick()} />
      }
    </>
    );
  }
}

export default EditFormComponent;
import { Delta } from 'jsondiffpatch';
import { ICommentDeltaFields } from '../../../../../document-module/module/form/interface/comment';
import { NumberDeltaFields } from './type/number';
import { StringDeltaFields } from './type/string';
import { DateDeltaFields } from './type/date';
import { TimeDeltaFields } from './type/time';
import { BoolDeltaFields } from './type/bool';
import { DropdownDeltaFields } from './type/dropdown';
import { FileDeltaFields } from './type/file';
import { DateTimeDeltaFields } from './type/date-time';
import { ArrayDeltaFields } from './type/array';
import { FXDeltaFields } from './type/fx';
import { AutopopulatedDeltaFields } from './type/autopopulated';
import { TableDeltaFields } from './type/table';
import { IAdminHistoryUtils } from '../../manage-history';
import { GeolocationDeltaFields } from './type/geolocation';
export interface DeltaFieldType {
  name: string;
  format(targetDelta: Delta, targetInstance: any, utils?: IAdminHistoryUtils): ICommentDeltaFields[];
}

const DeltaFieldsTypeList = [
  new StringDeltaFields(),
  new NumberDeltaFields(),
  new DateDeltaFields(),
  new TimeDeltaFields(),
  new BoolDeltaFields(),
  new DropdownDeltaFields(),
  new FileDeltaFields(),
  new DateTimeDeltaFields(),
  new ArrayDeltaFields(),
  new FXDeltaFields(),
  new AutopopulatedDeltaFields(),
  new TableDeltaFields(),
  new GeolocationDeltaFields()
]

class DeltaFields {

  registry: { [name: string]: DeltaFieldType } = {};

  constructor(types?: DeltaFieldType[]) {

    if (!types) {
      types = DeltaFieldsTypeList;
    }

    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  formatContext(targetDelta: Delta, targetInstance: any, utils?: IAdminHistoryUtils) {
    let type = targetInstance.type;
    let validationClass = this.registry[type];
    return typeof validationClass !== 'undefined' ?
      validationClass.format(targetDelta, targetInstance, utils)
      : [];
  }

}

export default DeltaFields;
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react-bpm';
import { IAutomation } from '../interface/automation';
import { Image } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ConfirmationMessage } from '../../../../../../../../../common/general/confirmation-modal';
import { deleteAutomationAction, publishAutomationAction, updateAutomationAction } from '../action';
import changeRoute from '../../../../../../../../../common/utils/change-menu';
import ButtonContainer from '../../../../../button-container';
import ActionMessage from '../../../../../../../../../common/general/action-message';
import { AutomationActionMessage } from '../constant';
import { useGetAutomationData } from '../hooks/use-get-automation-data';
import ToolTip from '../../../../../../../../../common/general/tooltip';
import { IRootState } from '../../../../../../../../../reducers';
import { icons } from '../../../../../../../../../common/icons';
import LoaderComponent from '../../../../../../../../../common/general/loader';

const AutomationList: React.FC<any> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match: { url: string, params: { formId: string, fourthLevelRoute: string } } = useRouteMatch();
  const searchRef = useRef(document.createElement('input'));
  const [refresher, setRefresher] = useState(0);
  const [forDeletedAutomation, setFordeletedAutomation] = useState<IAutomation | undefined>(undefined);

  const { company } = useSelector((state: IRootState) => state.auth);
  const { automationList, deleteAutomationLoading } = useSelector((state: IRootState) => state.automation);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const searchClick = () => {
    setRefresher(refresher + 1);
  }

  const confirmDeleteAutomation = () => {
    if (forDeletedAutomation) {
      dispatch(deleteAutomationAction({
        automationId: forDeletedAutomation.id,
        company: company,
        formId: match.params.formId
      }));
      setFordeletedAutomation(undefined);
    }
  }

  const enableAutomation = (automation: IAutomation) => {
    const tempAutomation = { ...automation };
    tempAutomation.enabled = tempAutomation.enabled ? false : true;
    dispatch(updateAutomationAction({
      automation: tempAutomation,
      company: company,
      formId: match.params.formId
    }));
  }

  const publishAutomation = () => {
    dispatch(publishAutomationAction({
      company: company,
      formId: match.params.formId
    }))
  }

  useGetAutomationData({
    dispatch,
    company,
    formId: match.params.formId
  });

  return (<>
    <ActionMessage messages={AutomationActionMessage} /> {/* message when publish */}
    <LoaderComponent loading={deleteAutomationLoading} />
    <div className={`automation-main-container`}>
      <div className='business-rule-list-container table-wrapper'>
        <Segment className='borderless filter-wrapper'>
          <div className={`search-filter`}>
            <input placeholder='Search' ref={searchRef} />
            <button onClick={searchClick}  ><i className={`ui icon search`}></i></button>
          </div>
          <Button onClick={() => {
            console.log(`${match.url}/add-record`)
            history.push(`${match.url}/add-record`);
          }} floated='right' className='add-button' >Add Rule</Button>
        </Segment>
        <table className='table-container business-rule-table'>
          <thead>
            <tr>
              <th className='th-group'>Name</th>
              <th className='th-group'>Description</th>
              <th className='th-group'>Owner</th>
              <th className='th-group'>Enabled</th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            {automationList.map((automation: IAutomation, index: number) => {
              if (
                automation.name.indexOf(searchRef.current.value || '') > -1
                || automation.description.indexOf(searchRef.current.value || '') > -1
              ) {
                const avatar = automation?.creator?.avatar || icons.black.user;
                const fullName = `${automation?.creator?.firstName || ''} ${automation?.creator?.lastName || ''}`
                return <tr key={uuid()}>
                  <td><Link to={`/admin/business-process/automation/${match.params.formId}/${match.params.fourthLevelRoute}/${automation.id}`}>
                    {automation.name}
                  </Link></td>
                  <td className={`description-column`}><p>
                    {`${automation.description.substr(0, 40)}`}
                    {automation.description.length > 40 ? <br /> : ''}
                    {`${automation.description.substr(40, 40)}`}
                    {automation.description.length > 80 ? '...' : ''}
                  </p></td>
                  <td className={`owner-icon`}><Image src={avatar} /> {fullName}</td>
                  <td>
                    <div className="ui read-only toggle checkbox checked table-checkbox-toggle" onClick={() => enableAutomation(automation)}>
                      <input style={{ cursor: 'pointer' }} type="checkbox" checked={automation.enabled} onChange={() => { }} />
                      <label></label>
                    </div>
                  </td>
                  <td>
                    <ToolTip position='right center'
                      popupMessage={'Delete'}
                      popupTriggerComponent={
                        <Image src={icons.blue.delete}
                          style={{
                            width: '12px',
                            cursor: 'pointer',
                            margin: '0px 5px',
                            display: 'inline-block'
                          }} onClick={() => setFordeletedAutomation(automation)} name='trash alternate outline' />
                      }
                    />
                  </td>
                </tr>
              } else {
                return null;
              }
            })
            }
          </tbody>
        </table>
        <ConfirmationMessage
          confirmButtonName={'Yes'}
          confirmButtonColor={'green'}
          cancelButtonColor={'grey'}
          close={() => setFordeletedAutomation(undefined)}
          open={forDeletedAutomation !== undefined}
          confirm={confirmDeleteAutomation}
          cancel={() => setFordeletedAutomation(undefined)}
          headerText="Confirmation"
          detailText={`A deleted rule cannot be recovered. Are you sure you want to continue?`}
          loading={false}
          type='warning'
        />
      </div>
      <div>
        <ButtonContainer
          cancel={() => {
            changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
          }}
          publish={() => publishAutomation()}
        />
      </div>
    </div>
  </>
  );
}

export default AutomationList;
import React from 'react';
import DecathlonCallback from './type/decathlon';
import ForgotPasswordCallback from './type/forgot-password';
import Routes from '../../routes';

const CallbackList: any[] = [
  new DecathlonCallback(),
  new ForgotPasswordCallback()
]

interface IRender {
  type: string;
}

export class CallbackModuleRegistry {

  registry: { [name: string]: any } = {};

  constructor() {
    const types = CallbackList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <Routes />
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IRender> = (props) => {
    const module = new CallbackModuleRegistry();
    const Render = module.getModule(props.type);
    return <Render {...props} />
  }

}
import React, { useEffect, useState } from 'react';
import { IFieldAccessType } from '../../../../../../../../../../../users/interface/field';
import { IAutomationComponent } from '../../../../../../interface/component-list';
import { IAutomationInputFieldList } from '../../../../../../reducer';

interface IProps {
  selectedComponent: IAutomationComponent;
  getInputField(formId: string, statusId?: string): void;
  getFormStatus(formId: string): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  inputFieldList: IAutomationInputFieldList;
}

export const useSetInitialData = (props: IProps) => {
  const { selectedComponent, inputFieldList } = { ...props };

  const [selectedFields, setSelectedField] = useState<string[]>([]);
  const [landingStatus, setlandingStatus] = useState('');
  const [selectedBP, setSelectedBP] = useState('');
  const [copyFromObject, setCopyFromObject] = useState<{ [id: string]: any }>({});
  const [initial, setInitial] = useState(true);

  const manageLandingStatus = (currentBp: string, value: string, excludeApiCall: boolean = true, hasChange: boolean = false, clearDependent: boolean = true) => {
    setlandingStatus(value);
    if (hasChange === true) props.hasComponentChange.current = true;
    if (!excludeApiCall && currentBp !== '' && value !== '') {
      props.getInputField(currentBp, value);
    }
    if (clearDependent) {
      setSelectedField([]);
    }
  }

  const manageSelectBP = (value: string, clearDependent: boolean = true, hasChange: boolean = false) => {
    setSelectedBP(value);
    if (hasChange === true) props.hasComponentChange.current = true;
    if (clearDependent) {
      setlandingStatus('');
      setSelectedField([]);
    }
    if (value !== '') {
      props.getFormStatus(value);
    }
  }

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      const formId = selectedComponent.config.formId || '';
      const fields = selectedComponent.config.fields || {};
      const selectedFields = Object.keys(fields) || [];
      if (inputFieldList[formId] && initial) {
        const selectedInputFields = inputFieldList[formId]
          .filter(e => e.accessType !== IFieldAccessType.Required)
          .filter(e => e.id && selectedFields.includes(e.id));
        setSelectedField(selectedInputFields.map(e => e.id || ''));
        setInitial(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFieldList, selectedComponent, initial])

  useEffect(() => {
    let tempCopyFrom = {} as { [id: string]: any };
    if (selectedComponent && selectedComponent.config) {
      const fields = selectedComponent.config.fields || {};
      const landingStatus = selectedComponent.config.landingStatusId || '';
      const selectedBP = selectedComponent.config.formId || '';
      Object.keys(fields).forEach((id: string) => {
        if (fields[id] && (fields[id].hasOwnProperty('keyPath')) && !tempCopyFrom[id]) {
          tempCopyFrom[id] = fields[id]
        }
      });
      setCopyFromObject(tempCopyFrom);
      manageSelectBP(selectedBP, false);
      manageLandingStatus(selectedBP, landingStatus, false, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedFields,
    setSelectedField,
    landingStatus,
    setlandingStatus,
    selectedBP,
    setSelectedBP,
    manageLandingStatus,
    manageSelectBP,
    copyFromObject,
    setCopyFromObject,
    ...props
  }
}
export const AUTH_LOGIN = 'app/auth/AUTH_LOGIN';
export const AUTH_LOGIN_RETURN = 'app/auth/AUTH_LOGIN_RETURN';
export const AUTH_LOGIN_ERROR = 'app/auth/AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT = 'app/auth/AUTH_LOGOUT';
export const AUTH_LOGOUT_RETURN = 'app/auth/AUTH_LOGOUT_RETURN';
export const AUTH_LOGOUT_MESSAGE_RETURN = 'app/auth/AUTH_LOGOUT_MESSAGE_RETURN'
export const AUTH_MODAL = 'app/auth/AUTH_MODAL';
export const FORGOT_PASSWORD = 'app/forgotPassword/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_MODAL = 'app/forgotPassword/FORGOT_PASSWORD_MODAL';
export const FORGOT_PASSWORD_RETURN = 'app/forgotPassword/FORGOT_PASSWORD_RETURN';
export const FORGOT_PASSWORD_RETURN_ERROR = 'app/forgotPassword/FORGOT_PASSWORD_RETURN_ERROR';
export const SCREEN_INACTIVITY_MODAL = 'app/auth/automatic-logout/SCREEN_INACTIVITY_MODAL';
export const AUTH_LOGOUT_MESSAGE_RESET = 'app/auth/automaticLogoutMessage/AUTH_LOGOUT_MESSAGE_RESET';
export const UPDATE_USER_DETAIL = 'app/auth/auth/UPDATE_USER_DETAIL';
import React from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { IFormPermissionSection } from '../../interface/section';
import DropdownPopperOption from '../../../../../../../../common/general/dropdown-popper-option';

interface IStatusRefer {
  formPermissionSection: IFormPermissionSection[];
  selectedStatusIndex: number;
  selectedStatus: IFormPermissionSection;
  handleReferToStatus(forUpdateSection: IFormPermissionSection, statusId: string): void;
  showStatusOption: boolean;
}

const StatusRefer: React.FC<IStatusRefer> = (props) => {
  const linkStatus: IFormPermissionSection[] = [];

  props.formPermissionSection.forEach((status: IFormPermissionSection, index: number) => {
    let isAllowedOption = true;
    const hasReferStatusId = status.referToStatusId !== ''

    if (hasReferStatusId) {
      isAllowedOption = false;
    }
    if (index >= props.selectedStatusIndex) {
      isAllowedOption = false;
    }
    if (isAllowedOption) {
      linkStatus.push(status)
    }
  });

  const selectedValue = props.selectedStatus ? props.selectedStatus.referToStatusId : '';
  const optionList = linkStatus.map((status: IFormPermissionSection, index: number) => {
    return {
      key: index,
      text: status.statusName,
      value: status.statusId
    }
  });

  return (<>
    {props.showStatusOption &&
      <div className='status-filter-container'>
        <label>Refer to Status: </label>
        <Dropdown
          popperContainer={DropdownPopperOption}
          selectOnBlur={false}
          clearable
          options={optionList}
          placeholder='Status'
          selection
          value={selectedValue}
          onChange={(e, target: any) => {
            props.handleReferToStatus(props.selectedStatus, target.value)
          }}
        />
      </div>
    }
  </>)
}

export default StatusRefer;
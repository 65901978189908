import { useEffect, useRef, useState } from 'react';
import { postRequest } from '../../../../../common/api-request';


export const useManageUrlToken = () => {
  const [processState, setProcessState] = useState<'process' | 'failed' | 'success'>('process');

  const unmountRef = useRef(false);

  const validateToken = async (company: string, code: string) => {
    try {
      const response: { data: { data: { token: string } } } = await postRequest(`${company}/login/validate`, {
        service: 'decathlon',
        input: {
          code
        }
      });
      if (!unmountRef.current) {
        setProcessState('success');
        localStorage.setItem('token', response.data.data.token);
      }
      document.location.href = "/";
    } catch (e) {
      if (!unmountRef.current) {
        setProcessState('failed');
      }
    }
  }

  useEffect(() => {
    const params = new URL(window.location.href).searchParams
    const code = params.get('code')
    const companyId = params.get('state')
    if (code && companyId) {
      try {
        validateToken(companyId, code);
        return;
      } catch (e) {
        console.log(e)
        setProcessState('failed')
      }
    } else {
      console.error(`Invalid searchParams: ${params.toString()}`)
    }
  
    setProcessState('failed');

    return () => {
      unmountRef.current = true;
    }
  }, [])

  return processState;
}
import React from 'react';
import Field from '../fields';
import { Image } from 'semantic-ui-react-bpm';
import { ISection, ITablePermission } from '../../../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../../interface/field-permission';
import { icons } from '../../../../../../../../../../common/icons';
import { IConfigName } from '../../../../../../../../../../common/field/type/interface/field-type-object';

interface IFields {
  fieldPermission: IStatusFieldPermission;
  fields: ICustomFieldDetail;
  index: number;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  section: ISection | ITablePermission;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  tablePermission?: ITablePermission[];
  sectionType: 'section' | 'table';
}

const SectionFields: React.FC<IFields> = (props) => {
  const { config } = { ...props.fieldPermission };

  const isTableAggregate = () => {
    return props.tablePermission && props.tablePermission.length > 0 &&
      props.tablePermission[0] && props.tablePermission[0].consolidateTable !== undefined;
  }

  return <div className='field-container'>
    <div className="field-list" >
      {props.fields && props.fields.configName !== IConfigName.Seq &&
        <Field
          key={`field-list-field-${props.fields.id}-${props.index}`}
          field={props.fields}
          permission={props.fieldPermission}
          section={props.section}
          handlePermissionChange={props.handlePermissionChange}
          tablePermission={props.tablePermission}
          openFieldPermissionSettings={props.openFieldPermissionSettings}
          sectionType={props.sectionType}
        />
      }
    </div>
    {(config !== undefined || isTableAggregate()) && props.fields.id === props.fieldPermission.fieldId ?
      <Image onClick={() => props.openFieldPermissionSettings(props.fields, props.section, props.sectionType)} className='settings-icon' src={icons.blue.cog} />
      :
      <Image onClick={() => props.openFieldPermissionSettings(props.fields, props.section, props.sectionType)} className='settings-icon' src={icons.blue.cogOutline} />
    }
  </div>
}

export default SectionFields;
import React, { useState, useRef, useEffect } from 'react';
import { ICustomFieldProps, IDefaultRef } from '../..';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Dropdown } from 'semantic-ui-react-bpm';
import { rangeFieldType } from '../../../field/type';
import { IFieldTypeObj } from '../../../field/type/interface/field-type-object';
import { IConfig, ICustomFieldDetail, IOnCustomFieldSubmitObject } from '../../interface/custom-field-detail';
import RangeField from './properties';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { IFieldConfig } from '../../../../component/admin-module/module/users/interface/field';
import { plainToClass } from 'class-transformer';
import { errorIndicator } from '../../constant';

export default class RangeConfig extends DefaultFieldConfig {

  name = 'range';

  Render: React.FC<ICustomFieldProps> = (props) => {

    const [error, setError] = useState(errorIndicator);
    const [itemType, setItemType] = useState('');
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [confidential, setConfidential] = useState(false);

    const defaultvalueRefData = useRef<IDefaultRef>({});
    const helpRef = useRef(document.createElement('textarea'));
    const fieldNameRef = useRef(document.createElement('input'));

    const companyId = props.companyId;

    const fieldTypeOption = rangeFieldType.map((field: IFieldTypeObj, index: number) => {
      return { key: index + 1, text: field.title, value: field.defaultData.type }
    });

    const handleItemTypeChange = (value: string) => {
      setItemType(value);
      setConfigData({
        itemConfigFrom: {},
        itemConfigTo: {},
        itemType: value
      } as any);
      resetRef(defaultvalueRefData);
    }

    const resetRef = (defaultValueRef: React.MutableRefObject<IDefaultRef>) => {
      if (defaultValueRef.current['defaultField']) {
        Object.keys(defaultValueRef.current['defaultField']).forEach(key => {
          if (defaultValueRef.current['defaultField'][key]) {
            defaultValueRef.current['defaultField'][key].current[`${key}.defaultField`].value = '';
          }
        });
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };
      if (props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
        currentError.itemType = itemType ? false : true;
      }
      setError(currentError)
      return !currentError.fieldname
    }

    const inputObject = () => {
      return {
        ...configData,
      }
    }

    const defaultValueRange = (defaultValueRef: any, rangeType: 'From' | 'To') => {
      let defaultValue: any = null;

      if (defaultValueRef.current[`${rangeType}.defaultField`]) {
        defaultValue = defaultValueRef.current[`${rangeType}.defaultField`].value;
        defaultValue = defaultValue || null;
      }

      return defaultValue;
    }

    const defaultValue = () => {
      const defaultValueFrom = defaultValueRange(defaultvalueRefData.current['defaultField']['From'], 'From');
      const defaultValueTo = defaultValueRange(defaultvalueRefData.current['defaultField']['To'], 'To');

      if (defaultValueFrom && defaultValueTo) {
        return {
          from: defaultValueFrom,
          to: defaultValueTo
        }
      }
      return null;
    }

    const updateConfig = () => {
      if (validateRequiredFormField()) {
        const config = plainToClass(IConfig, { ...inputObject() });

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            label: fieldNameRef.current.value,
            hint: helpRef.current.value,
            defaultValue: defaultValue(),
            config: config,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '')
      }
    }

    const setDefaultValueToRefs = (defaultValue: any | null, rangeType: 'From' | 'To') => {
      if (!defaultvalueRefData.current['defaultField']) {
        defaultvalueRefData.current['defaultField'] = {};
      }
      if (!defaultvalueRefData.current['defaultField'][rangeType]) {
        defaultvalueRefData.current['defaultField'][rangeType] = {};
      }
      if (!defaultvalueRefData.current['defaultField'][rangeType].current) {
        defaultvalueRefData.current['defaultField'][rangeType].current = {}
      }
      if (!defaultvalueRefData.current['defaultField'][rangeType].current[`${rangeType}.defaultField`]) {
        defaultvalueRefData.current['defaultField'][rangeType].current[`${rangeType}.defaultField`] = {};
      }
      defaultvalueRefData.current['defaultField'][rangeType].current[`${rangeType}.defaultField`].value = defaultValue || '';
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config);
        setFieldData(data);
        setItemType(config.itemType || '');
        fieldNameRef.current.value = data.label || '';
        helpRef.current.value = data.hint || '';
        setDefaultValueToRefs(data.defaultValue ? data.defaultValue['from'] : null, 'From');
        setDefaultValueToRefs(data.defaultValue ? data.defaultValue['to'] : null, 'To');
        setConfidential(data.confidential || false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='range-field-modal'
        className={`range-field-modal`}
        centered={false}
      >
        {(props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`${props.defaultData.configName || 'Custom'} Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Segment className='borderless' style={{ marginTop: `${props.formType === 'FormBuilder' ? '-20px' : '2rem'}` }}>
            <Form size='big' id='custom-field-form'>
              {(props.formType === 'User' || props.formType === 'FormBuilder') &&
                <Form.Group widths='equal'>
                  <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                    <label>Field Name</label>
                    <div className="ui input">
                      <input onChange={() => { error.fieldname = (fieldNameRef.current.value ? false : true) }}
                        ref={fieldNameRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
              }
              <Form.Group widths='equal'>
                <div className="field">
                  <label>Field Info</label>
                  <textarea rows={3} ref={helpRef}></textarea>
                </div>
              </Form.Group>
              <Form.Group widths='equal' className={`required`}>
                <div className={`field ${error.itemType ? 'error' : ''}`}>
                  <label>Select Field Type:  </label>
                  <Dropdown
                    className={'field-type'}
                    fluid
                    selection
                    clearable
                    search
                    value={itemType}
                    selectOnBlur={false}
                    options={fieldTypeOption}
                    onChange={(event, target: any) => handleItemTypeChange(target.value)}
                  />
                </div>
              </Form.Group>
              {itemType &&
                <Form.Group widths='equal' className='range-field-container'>
                  <label className='field'>Default Value:  </label>
                  <div className='range-field-config'>
                    <RangeField
                      defaultvalueRef={defaultvalueRefData}
                      configData={configData}
                      fieldData={fieldData}
                      inputObject={inputObject}
                    />
                  </div>
                </Form.Group>
              }
              {props.formType === 'FormBuilder' &&
                <div id='custom-field-checkbox' style={{ margin: '15px 0px' }}>
                  <div className="ui fitted checkbox" onClick={() => setConfidential(!confidential)}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} value="" />
                    <label>Confidential</label>
                  </div>
                </div>
              }
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions className={`borderless`} id='delete-custom-field-modal-footer'>
          <React.Fragment>
            <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
            <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
          </React.Fragment>
        </Modal.Actions>
      </Modal>
    </>
  }
}

import Axios from 'axios';
import { UploadedFile } from './upload';

export interface GeneratePdfRequest {
  companyId: string;
  documentId: string;
  fieldId: string;
  progress?: (percent: number) => void;
  abortHandler?: (abort: () => void) => void;
}

export async function generatePdf(request: GeneratePdfRequest): Promise<UploadedFile> {

  const url = `/${request.companyId}/documentToPdf/${request.documentId}/field/${request.fieldId}`;

  const prepared = await Axios.post(url, undefined);

  return {
    id: prepared.data.data.id,
    name: prepared.data.data.name,
    type: prepared.data.data.type,
    size: prepared.data.data.size,
    url: prepared.data.data.url,
  };
}
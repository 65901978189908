import ConfigService from '../../common/config';
import { icons } from '../../common/icons';
import { ThemeColorType } from '../bpm-manager-module/module/companies/interface/theme-color-state-data';

export const MAIN_ROUTE = 'app/home/MAIN_ROUTE';
export const SUB_ROUTE = 'app/home/SUB_ROUTE';
export const THIRD_LEVEL_ROUTE = 'app/home/THIRD_LEVEL_ROUTE';
export const FOURTH_LEVEL_ROUTE = 'app/home/FOURTH_LEVEL_ROUTE';
export const RESET_FOURTH_LEVEL_ROUTE = 'app/home/RESET_FOURTH_LEVEL_ROUTE';
export const CHANGE_PAGE_NEXT_ROUTE = 'app/home/CHANGE_PAGE_NEXT_ROUTE';
export const CURRENT_ROUTE = 'app/home/CURRENT_ROUTE';
export const SET_DOWNLOAD_REQUESTS = 'app/home/SET_DOWNLOAD_REQUESTS';
export const REMOVE_DOWNLOAD_REQUESTS = 'app/home/REMOVE_DOWNLOAD_REQUESTS';
export const CLEAR_DOWNLOAD_REQUESTS = 'app/home/CLEAR_DOWNLOAD_REQUESTS';
export const SET_CURRENT_LOCATION = 'app/home/SET_CURRENT_LOCATION';
export const SET_OPEN_ADMIN_CHATBOX = 'app/home/SET_OPEN_ADMIN_CHATBOX';

export const menuList = [
  {
    id: 'admin',
    name: 'ADMIN',
    route_label: 'ADMIN MODULE',
    icon: icons.black.admin,
    style: {
      width: '32px',
      marginLeft: '20px',
    }
  },
  {
    id: 'portal',
    name: 'PORTAL',
    route_label: 'PORTAL MODULE',
    icon: '',
    style: {
      display: 'none'
    }
  },
  // {
  //   id: 'bpm-manager',
  //   name: 'BPM MANAGER',
  //   route_label: 'BPM MANAGER MODULE',
  //   icon: '',
  //   style: {
  //     display: 'none'
  //   }
  // },
]

export const endUserMenuList = [
  {
    id: "workspaces",
    name: "Dashboard",
    route_label: "workspace",
    icon: icons.black.workspaceMenu
  },
  {
    id: "tasks",
    name: "Tasks",
    route_label: "workspace",
    icon: icons.black.todoMenu
  },
  {
    id: "calendars",
    name: "Calendar",
    route_label: "calendar",
    icon: icons.black.calendarMenu
  },
  {
    id: "reports",
    name: "Reports",
    route_label: "reports",
    icon: icons.black.reportMenu
  },
  {
    id: "datatables",
    name: "Data Tables",
    route_label: "datatables",
    icon: icons.black.datatable
  }
]

export const bpmManagerMenuList = [
  {
    id: '',
    name: 'HOME',
    route_label: '',
    icon: '',
    style: {
      display: 'none'
    }
  },
  {
    id: 'admin',
    name: 'ADMIN',
    route_label: 'ADMIN MODULE'
  },
  {
    id: 'partner-area',
    name: 'PARTNER AREA',
    route_label: 'PARTNER AREA MODULE'
  },
  {
    id: 'documents',
    name: 'END USER',
    route_label: 'END USER MODULE'
  }
]


export const documentMenuList = [
  {
    id: 'admin',
    name: 'ADMIN',
    route_label: 'ADMIN MODULE',
    icon: icons.black.admin,
    rootRoute: '/',
    style: {
      width: '32px',
    }
  }
]

export const mainCompanyName = ConfigService.loadConfig().general.mainCompanyName;

export const companyDefaultColorStructure = [
  {
    text: 'Upper Menu',
    id: 'upperMenuIcon',
    subObject: [
      { type: ThemeColorType.color, text: 'Icons (a): Workspace, Tasks, etc.', id: 'iconA' },
      { type: ThemeColorType.color, text: 'Icons (b): Notifications, Admin, Account', id: 'iconB' },
      { type: ThemeColorType.color, text: 'New Button Text', id: 'newButtonText' },
      { type: ThemeColorType.color, text: 'Tasks Custom Text', id: 'taskCustomText' },
      { type: ThemeColorType.color, text: 'Reports Custom Text', id: 'reportsCustomText' },
    ]
  },
  {
    text: 'Lateral Menu',
    id: 'lateralMenu',
    subObject: [
      { type: ThemeColorType.color, text: 'Background Color', id: 'backgroundColor' },
      { type: ThemeColorType.color, text: 'Triangle Color', id: 'triangle' },
      { type: ThemeColorType.color, text: 'Highlight', id: 'highlight' },
      { type: ThemeColorType.color, text: 'Highlight Text Color', id: 'highlightTextColor' },
      { type: ThemeColorType.color, text: 'Text Color', id: 'textColor' },
    ]
  },
  {
    type: ThemeColorType.color,
    text: 'Chat Icon Color',
    id: 'chatIcon',
  },
  {
    text: 'Comment And History',
    id: 'comment',
    subObject: [
      {
        type: ThemeColorType.color,
        text: 'Filter Highlight Color',
        id: 'filterHighlight'
      },
      {
        type: ThemeColorType.color,
        text: 'Send Icon',
        id: 'sendIcon'
      }
    ]
  },
  {
    text: 'Document View',
    id: 'documentView',
    subObject: [
      {
        type: ThemeColorType.color,
        text: 'Embedded table Header',
        id: 'embeddedTableHeader'
      },
      {
        type: ThemeColorType.color,
        text: 'Embedded table header text',
        id: 'embeddedTableHeaderText'
      },
      {
        type: ThemeColorType.color,
        text: 'Section Header Text Color',
        id: 'sectionHeaderTextColor'
      },
      {
        type: ThemeColorType.color,
        text: 'Section Header Line Color',
        id: 'sectionHeaderLineColor'
      },
      {
        type: ThemeColorType.color,
        text: 'Expand/Collapse Color',
        id: 'expandCollapseColor'
      }
    ]
  },
  {
    text: 'Calendar',
    id: 'calendar',
    subObject: [
      {
        type: ThemeColorType.color,
        text: 'Current Calendar Date Color',
        id: 'currentCalendarDateColor'
      },
    ]
  },
  {
    text: 'Tasks',
    id: 'tasks',
    subObject: [
      {
        type: ThemeColorType.text,
        text: 'Pending Tasks Text',
        id: 'pendingTaskText'
      },
      {
        type: ThemeColorType.text,
        text: 'Completed Tasks Text',
        id: 'completedTaskText'
      },
      {
        type: ThemeColorType.text,
        text: 'Task Menu Button Text',
        id: 'taskMenuButtonText'
      },
      {
        type: ThemeColorType.file,
        text: 'Task Menu Button Icon',
        id: 'taskMenuButtonIcon'
      }
    ]
  },
  {
    text: 'Reports',
    id: 'reports',
    subObject: [
      {
        type: ThemeColorType.text,
        text: 'Reports Header Text',
        id: 'reportsHeaderText'
      },
      {
        type: ThemeColorType.text,
        text: 'Reports Menu Button Text',
        id: 'reportsMenuButtonText'
      },
      {
        type: ThemeColorType.file,
        text: 'Reports Menu Button Icon',
        id: 'reportsMenuButtonIcon'
      }
    ]
  },
]

export const reactThemeColor = {
  color: '#f1ee3b',
  upperMenuIcon: {
    iconA: '#debb1b',
    iconB: '#debb1b',
    newButtonText: '#000000'
  },
  lateralMenu: {
    backgroundColor: '#000000',
    triangle: '#DEBB1B',
    highlight: '#DEBB1B',
    highlightTextColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  chatIcon: '#000000',
  comment: {
    filterHighlight: '#000000',
    sendIcon: '#000000',
  },
  documentView: {
    embeddedTableHeader: '#DEBB1B',
    embeddedTableHeaderText: '#000000',
    sectionHeaderTextColor: '#000000',
    sectionHeaderLineColor: '#000000',
    expandCollapseColor: '#000000'
  },
  calendar: {
    currentCalendarDateColor: '#ED673D'
  }
}

export const flutterWebThemeColor = {
  color: '#f1ee3b',
  upperMenuIcon: {
    iconA: '#debb1b',
    iconB: '#debb1b',
    newButtonText: '#000000',
  },
  comment: {
    sendIcon: '#000000',
  },
  documentView: {
    embeddedTableHeader: '#DEBB1B',
    embeddedTableHeaderText: '#000000',
    sectionHeaderTextColor: '#000000',
    sectionHeaderLineColor: '#000000',
    expandCollapseColor: '#000000'
  },
}

export const flutterAppThemeColor = {
  color: '#f1ee3b',
  upperMenuIcon: {
    iconA: '#debb1b',
    iconB: '#debb1b',
    newButtonText: '#000000',
  },
  comment: {
    sendIcon: '#000000',
  },
  documentView: {
    embeddedTableHeader: '#DEBB1B',
    embeddedTableHeaderText: '#000000',
    sectionHeaderTextColor: '#000000',
    sectionHeaderLineColor: '#000000',
    expandCollapseColor: '#000000'
  },
  tasks: {
    pendingTaskText: 'Pending Tasks',
    completedTaskText: 'Completed Tasks',
    taskMenuButtonText: 'Tasks',
    taskMenuButtonIcon: null
  },
  reports: {
    reportsHeaderText: 'Documents',
    reportsMenuButtonText: 'Documents',
    reportsMenuButtonIcon: null
  }
}

export const defaultCompanyThemeColorSettings = {
  color: reactThemeColor.color,
  react: Object.assign({}, { ...reactThemeColor }),
  flutterApp: Object.assign({}, { ...flutterAppThemeColor }),
  flutterWeb: Object.assign({}, { ...flutterWebThemeColor })
};
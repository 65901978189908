import { useEffect } from 'react';
import { FieldConstruct } from '../../../../../../../../../../../../../../common/field/field-construct';
import { IAutomationComponent } from '../../../../../../interface/component-list';
import { IAutomationInputFieldList } from '../../../../../../reducer';

interface IProps {
  selectedComponent: IAutomationComponent;
  inputFieldList: IAutomationInputFieldList;
  dynamicRef: any;
}

export const useSetComponentToRefs = (props: IProps) => {
  const {
    inputFieldList,
    selectedComponent,
    dynamicRef
  } = { ...props };

  useEffect(() => {
    if (selectedComponent && selectedComponent.config) {
      const formId = selectedComponent.config.formId || '';
      const fields = selectedComponent.config.fields || {};
      FieldConstruct.setFieldToRefs(inputFieldList[formId] || [], { fields }, dynamicRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFieldList])

  return { ...props }
}
import { Delta } from 'jsondiffpatch';
import DeltaFields, { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryUtils } from '../../../manage-history';

export class ArrayDeltaFields implements DeltaFieldType {

  name = 'array';

  format(targetDelta: Delta, targetInstance: any, utils: IAdminHistoryUtils) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { newDelta, oldDelta } = this.setDelta(targetDelta, targetInstance);

    const fieldsDelta = new DeltaFields();
    deltaArray = fieldsDelta.formatContext(newDelta, oldDelta, utils);

    return deltaArray;
  }

  setDelta = (delta: Delta, instance: any) => {
    let newDelta = { ...delta } as Delta;
    let oldDelta = { ...instance } as Delta;

    const itemType = instance.config.itemType;

    if (newDelta['config'] && newDelta['config']['itemConfig']) {
      newDelta['config'] = delta['config']['itemConfig'];
    }
    if (instance['config'] && instance['config']['itemConfig']) {
      oldDelta['config'] = instance['config']['itemConfig'];
    }

    if (newDelta['defaultValue']) {
      const defaultValue = this.setDefaultValue(delta['defaultValue']);
      newDelta['defaultValue'] = defaultValue.newDelta['defaultValue'];
      oldDelta['defaultValue'] = defaultValue.oldDelta['defaultValue'];
    }

    return { newDelta, oldDelta: { ...oldDelta, type: itemType } };
  }

  setDefaultValue = (delta: Delta) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (Array.isArray(delta[key])) {
        if (key.indexOf('_') > -1) {
          oldDelta['defaultValue'] = delta[key][0];
        } else {
          newDelta['defaultValue'] = [delta[key][0]];
        }
      }
    });
    if (!oldDelta['defaultValue']) {
      oldDelta['defaultValue'] = '';
    }
    return { newDelta, oldDelta };
  }
}
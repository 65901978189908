import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMessageProps } from './interface/message-props';

const Message: React.FC<IMessageProps> = (props) => {

  const dispatch = useDispatch();
  const data = useSelector((state: any) => state[props.reducerContainer]);
  const timeoutContainer = useRef(setTimeout(() => { }, 5000));

  const closeMessage = () => {
    clearTimeout(timeoutContainer.current);
    dispatch({
      type: props.dispatchType
    })
  }

  useEffect(() => {
    if (data[props.reducerItem]) {
      timeoutContainer.current = setTimeout(() => {
        closeMessage();
      }, 5000);
    } else {
      clearTimeout(timeoutContainer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[props.reducerItem]])

  return <>
    <div className={`response-message-widget ui ${props.type} message ${data[props.reducerItem] ? '' : 'transition hidden'}`}>
      {props.hasCloseIcon &&
        <i className="close icon" onClick={() => closeMessage()}></i>
      }
      < div className="header response-message">{data[props.reducerItem]}</div>
    </div>
  </>
};

export default React.memo(Message);
import React from 'react';
import { ICommentDeltaFields } from '../../../../../../interface/comment';
import moment from 'moment-timezone';

const isValidNumber = (value: string) => {
  let regExStr = '^-*\\d*$';
  const regex = RegExp(regExStr, 'i');
  return regex.test(value);
}

const checkValueType = (refValue: any) => {
  const parsedDate = new Date(refValue);

  var dateFormats = ["YYYY-MM-DD LT", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD"];
  var timeFormats = ["h:mm:ss A", "HH:mm:ss", "HH:mm"];
  let dateToday = moment().format("YYYY-MM-DD");
  let time = new Date(dateToday + ' ' + refValue);
  let regUUId = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;

  return {
    isNumber: isValidNumber(refValue),
    isBoolean: refValue === 'true' || refValue === 'false' ? true : false,
    isDate: typeof refValue === 'string' && parsedDate.toString() !== 'Invalid Date'
      && moment(refValue, dateFormats, true).isValid(),
    isUUID: regUUId.test(refValue),
    isTime: typeof refValue === 'string' && moment(time, timeFormats, true).isValid(),
    isDateTime: typeof refValue === 'string' && parsedDate.toString() !== 'Invalid Date'
      && moment(refValue, moment.defaultFormatUtc, true).isValid()
  }
}

const parseDateTime = (value: string) => {
  let formattedTime: string = '';

  if (value) {
    let newDate = new Date(moment(value).format("YYYY-MM-DD HH:mm:ssZ"));
    formattedTime = moment(newDate).format('DD MMMM YYYY h:mm A');
  }

  return formattedTime;
}

export const renderFieldValue = (fieldValues: any, delta: ICommentDeltaFields) => {
  if (typeof fieldValues === 'object' && Array.isArray(fieldValues)) {
    const isNull = fieldValues.every((e: string) => e === null || e === '');
    if (isNull) {
      return 'No Value';
    } else {
      if (delta.type === 'array') {
        return fieldValues.map((value: string, index: number) => {
          const valueType = checkValueType(value);
          if (valueType.isDateTime) {
            value = parseDateTime(value);
          }
          return <React.Fragment key={index}>
            {value}
            {(fieldValues.length > 1 && index < fieldValues.length - 1) ? ',' : ''}
            <br />
          </React.Fragment>
        });
      } else {
        return fieldValues.join(', ').toString();
      }
    }
  } else {
    const valueType = checkValueType(fieldValues);
    if (valueType.isDateTime) {
      fieldValues = parseDateTime(fieldValues);
    }
    return fieldValues;
  }
}
import { useEffect } from 'react';
import { getPublishedFormAction } from '../../business-process/module/form-builder/action';
import {
  filterUsersAction,
  getUserFieldListAction,
  getUserFieldPermissionAction,
  getUserProfileAction,
  getDataTableListAction
} from '../action';

interface IProps {
  dispatch: any;
  companyId: string;
}

export const useGetUserData = (props: IProps): void => {
  const { dispatch, companyId } = { ...props };

  useEffect(() => {
    if (companyId) {
      dispatch(filterUsersAction({ company: companyId }));
      dispatch(getUserFieldListAction({ company: companyId }));
      dispatch(getUserFieldPermissionAction({ company: companyId }));
      dispatch(getUserProfileAction({ company: companyId }));
      dispatch(getDataTableListAction({ company: companyId }));
      dispatch(getPublishedFormAction({ company: companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);
}
import {
  GET_BUSINESS_PROCESS_LIST,
  GET_BUSINESS_PROCESS_LIST_ERROR,
  GET_BUSINESS_PROCESS_LIST_RETURN,
  CREATE_BUSINESS_PROCESS,
  CREATE_BUSINESS_PROCESS_RETURN,
  CREATE_BUSINESS_PROCESS_ERROR,
  GET_BUSINESS_PROCESS_ERROR,
  GET_BUSINESS_PROCESS,
  GET_BUSINESS_PROCESS_RETURN,
  UPDATE_BUSINESS_PROCESS,
  UPDATE_BUSINESS_PROCESS_RETURN,
  UPDATE_BUSINESS_PROCESS_ERROR,
  CLEAR_BUSINESS_PROCESS_MESSAGE,
  DELETE_BUSINESS_PROCESS,
  DELETE_BUSINESS_PROCESS_CONFIRM,
  DELETE_BUSINESS_PROCESS_CONFIRM_ERROR,
  DELETE_BUSINESS_PROCESS_CONFIRM_RETURN,
  DELETE_BUSINESS_PROCESS_CANCEL,
  CLEAR_DELETE_BUSINESS_MESSAGE,
  UPLOAD_LOGO,
  UPLOAD_LOGO_RETURN,
  UPLOAD_LOGO_ERROR,
  GET_FORM_LOGO,
  GET_FORM_LOGO_RETURN,
  GET_FORM_LOGO_ERROR,
  RESET_FORM_LOGO,
  CLEAR_DELETE_BUSINESS_PROCESS_SUCCESS,
  CLEAR_UPDATE_BUSINESS_PROCESS_SUCCESS,
  CLEAR_CREATE_BUSINESS_PROCESS_ERROR,
  CLEAR_DELETE_BUSINESS_PROCESS_ERROR,
  CLEAR_UPDATE_BUSINESS_PROCESS_ERROR,
  IMPORT_EXPORT_BP_DT_ERROR,
  IMPORT_EXPORT_BP_DT_SUCCESS,
  CLEAR_IMPORT_EXPORT_BP_DT_MESSAGE,
  SET_EXPORT_BACKUP_ITEM,
  GET_BACKUP_REFS,
  GET_BACKUP_REFS_RETURN,
  GET_BACKUP_REFS_ERROR,
  CLEAR_EXPORT_BACKUP_ITEM,
  SET_SELECT_ALL_BP_DT,
  DEACTIVATE_BUSINESS_PROCESS,
  DEACTIVATE_BUSINESS_PROCESS_RETURN
} from './constant';
import message from '../../message';
import { IForm } from './interface/form'
import { IBackupItem, IBackupDependency } from './interface/export-backup';

interface ISelectAll {
  form: boolean;
  database: boolean;
}
export interface IBusinessProcessReducer {
  formList: IForm[];
  formLoading: boolean;
  formListStatus: string;
  createFormLoading: boolean;
  createFormSuccessMessage: string;
  createFormErrorMessage: string;
  selectedBusinessProcessId: string;
  getFormDetailLoading: boolean;
  getFormDetailStatus: string;
  getFormDetailData: IForm;
  updateFormLoading: boolean;
  updateFormSuccessMessage: string;
  updateFormErrorMessage: string;
  forDeleteFormId: string;
  forDeleteFormName: string;
  isOpenDeleteConfirmationModal: boolean;
  confirmDeletetionLoading: boolean;
  confirmDeletionSuccessMessage: string;
  confirmDeletionErrorMessage: string;
  businessProcessLogoId: string;
  uploadLoading: boolean;
  uploadErrorMessage: string;
  businessProcessLogo: string;
  importExportSuccessMessage: string;
  importExportErrorMessage: string;
  importExportLoading: boolean;
  selectedBackupItem: IBackupItem[];
  backupRefs: IBackupDependency[];
  backupRefsLoading: boolean;
  backupRefsStatus: string;
  selectAllBP: boolean;
  selectAllDT: boolean;
  selectAll: ISelectAll;
  deactivateLoading: boolean;
  deactivateSuccess: boolean;
}

type IBPMessage = { [field: string]: string };

const initialState = {
  formList: [] as IForm[],
  formListLoading: false,
  formListStatus: '',
  createFormLoading: false,
  createFormSuccessMessage: '',
  createFormErrorMessage: '',
  selectedBusinessProcessId: '',
  getFormDetailLoading: false,
  getFormDetailStatus: '',
  getFormDetailData: {} as IForm,
  updateFormLoading: false,
  updateFormSuccessMessage: '',
  updateFormErrorMessage: '',
  forDeleteFormId: '',
  forDeleteFormName: '',
  isOpenDeleteConfirmationModal: false,
  confirmDeletetionLoading: false,
  confirmDeletionSuccessMessage: '',
  confirmDeletionErrorMessage: '',
  businessProcessLogoId: '',
  uploadLoading: false,
  uploadErrorMessage: '',
  businessProcessLogo: '',
  importBPDTSuccessMessage: '',
  importBPDTErrorMessage: '',
  exportBPDTSuccessMessage: '',
  exportBPDTErrorMessage: '',
  importExportSuccessMessage: '',
  importExportErrorMessage: '',
  importExportLoading: false,
  selectedBackupItem: [] as IBackupItem[],
  backupRefs: [] as IBackupDependency[],
  backupRefsLoading: false,
  backupRefsStatus: '',
  selectAll: {
    form: false,
    database: false
  },
  selectAllBP: false,
  selectAllDT: false,
  deactivateLoading: false,
  deactivateSuccess: false
};

const businessProcessReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  let tempFormList = [];

  switch (action.type) {
    case GET_BUSINESS_PROCESS_LIST:
      newState.formListLoading = true;
      newState.formListStatus = '';
      return newState;

    case GET_BUSINESS_PROCESS_LIST_RETURN:
      newState.formListLoading = false;
      newState.formList = action.data;
      newState.formListStatus = 'success';
      return newState;

    case GET_BUSINESS_PROCESS_LIST_ERROR:
      newState.formListLoading = false;
      newState.formListStatus = 'failed';
      return newState;

    case CREATE_BUSINESS_PROCESS:
      newState.selectedBusinessProcessId = '';
      newState.createFormLoading = true;
      newState.createFormSuccessMessage = '';
      newState.createFormErrorMessage = '';
      return newState;

    case CREATE_BUSINESS_PROCESS_RETURN:
      newState.createFormLoading = false;
      tempFormList = [...state.formList];
      tempFormList.push(action.data);
      newState.selectedBusinessProcessId = action.data.id;
      newState.formList = tempFormList;
      newState.createFormSuccessMessage = message.createFormSuccess;
      return newState;

    case CREATE_BUSINESS_PROCESS_ERROR:
      newState.createFormLoading = false;
      newState.createFormErrorMessage = action.message;
      return newState;

    case GET_BUSINESS_PROCESS:
      newState.selectedBusinessProcessId = '';
      newState.getFormDetailLoading = true;
      newState.getFormDetailData = {} as IForm;
      newState.getFormDetailStatus = '';
      return newState;

    case GET_BUSINESS_PROCESS_RETURN:
      newState.getFormDetailLoading = false;
      newState.getFormDetailData = action.data;
      newState.businessProcessLogoId = action.data.icon;
      newState.getFormDetailStatus = 'success';
      return newState;

    case GET_BUSINESS_PROCESS_ERROR:
      newState.getFormDetailLoading = false;
      newState.getFormDetailStatus = 'error';
      console.log(action.message)
      newState.updateFormErrorMessage = action.message;
      return newState;

    case UPDATE_BUSINESS_PROCESS:
      newState.updateFormLoading = true;
      newState.updateFormSuccessMessage = '';
      newState.updateFormErrorMessage = '';
      return newState;

    case UPDATE_BUSINESS_PROCESS_RETURN:
      newState.updateFormLoading = false;
      newState.formList = state.formList.map((e: IForm) => {
        if (action.data.id === e.id) {
          return action.data;
        }
        return e;
      });
      newState.selectedBusinessProcessId = action.data.id;
      newState.updateFormSuccessMessage = message.updateFormSuccess;
      return newState;

    case UPDATE_BUSINESS_PROCESS_ERROR:
      newState.updateFormLoading = false;
      newState.updateFormErrorMessage = action.message;
      return newState;

    case CLEAR_BUSINESS_PROCESS_MESSAGE:
      let newMessage: IBPMessage = {};
      newMessage[action.messageName] = '';
      newState = { ...newState, ...newMessage };
      return newState;

    case DELETE_BUSINESS_PROCESS:
      newState.forDeleteFormId = action.formId;
      newState.forDeleteFormName = action.formName;
      newState.isOpenDeleteConfirmationModal = true;
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_BUSINESS_PROCESS_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_BUSINESS_PROCESS_CONFIRM_RETURN:
      tempFormList = [...state.formList];
      newState.formList = tempFormList.filter((e: IForm) => e.id !== action.formId)
      newState.confirmDeletetionLoading = false;
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletionSuccessMessage = message.deleteFormSuccess;
      return newState;

    case DELETE_BUSINESS_PROCESS_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_BUSINESS_PROCESS_CANCEL:
      newState.forDeleteFormId = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case CLEAR_DELETE_BUSINESS_MESSAGE:
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case UPLOAD_LOGO:
      newState.businessProcessLogoId = '';
      newState.uploadLoading = true;
      return newState;

    case UPLOAD_LOGO_RETURN:
      newState.uploadLoading = false;
      newState.businessProcessLogoId = action.file[0].filename;
      return newState;

    case UPLOAD_LOGO_ERROR:
      newState.uploadErrorMessage = action.message;
      return newState;

    case GET_FORM_LOGO:
      newState.businessProcessLogo = '';
      return newState;

    case GET_FORM_LOGO_RETURN:
      newState.businessProcessLogo = action.file;
      return newState;

    case GET_FORM_LOGO_ERROR:
      newState.uploadErrorMessage = action.message;
      return newState;

    case RESET_FORM_LOGO:
      newState.businessProcessLogo = '';
      newState.businessProcessLogoId = '';
      return newState;

    case CLEAR_UPDATE_BUSINESS_PROCESS_SUCCESS:
      newState.updateFormSuccessMessage = '';
      return newState;

    case CLEAR_DELETE_BUSINESS_PROCESS_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case CLEAR_DELETE_BUSINESS_PROCESS_ERROR:
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case CLEAR_CREATE_BUSINESS_PROCESS_ERROR:
      newState.createFormErrorMessage = '';
      return newState;

    case CLEAR_UPDATE_BUSINESS_PROCESS_ERROR:
      newState.updateFormErrorMessage = '';
      return newState;

    case IMPORT_EXPORT_BP_DT_ERROR:
      newState.importExportLoading = false;
      newState.importExportErrorMessage = action.message;
      return newState;

    case IMPORT_EXPORT_BP_DT_SUCCESS:
      newState.importExportLoading = false;
      newState.importExportSuccessMessage = action.message;
      return newState;

    case CLEAR_IMPORT_EXPORT_BP_DT_MESSAGE:
      newState.importExportSuccessMessage = '';
      newState.importExportErrorMessage = '';
      return newState;

    case SET_EXPORT_BACKUP_ITEM:
      newState.selectedBackupItem = action.selectedBackupItem;
      return newState;

    case GET_BACKUP_REFS:
      newState.backupRefsLoading = true;
      newState.backupRefsStatus = '';
      return newState;

    case GET_BACKUP_REFS_RETURN:
      newState.backupRefsLoading = false;
      newState.backupRefs = action.data;
      newState.backupRefsStatus = 'success';
      return newState;

    case GET_BACKUP_REFS_ERROR:
      newState.backupRefsLoading = false;
      newState.backupRefsStatus = 'failed';
      return newState;

    case CLEAR_EXPORT_BACKUP_ITEM:
      newState.backupRefsLoading = false;
      newState.backupRefsStatus = '';
      newState.selectedBackupItem = [];
      newState.backupRefs = [];
      return newState;

    case SET_SELECT_ALL_BP_DT:
      newState.selectAll = { ...newState.selectAll, ...action.selectAll };
      return newState;

    case DEACTIVATE_BUSINESS_PROCESS:
      newState.deactivateLoading = true;
      return newState;

    case DEACTIVATE_BUSINESS_PROCESS_RETURN:
      newState.formList = state.formList.map((e: IForm) => {
        if (action.formUpdated === e.id) {
          return {
            ...e,
            enabled: action.deactivateSuccess ? action.enabled : e.enabled
          }
        }
        return e;
      });
      if (action.deactivateSuccess) {
        newState.updateFormSuccessMessage = `Business Process successfully ${!action.enabled ? 'deactivated' : 'activated'}`;
      } else {
        newState.updateFormErrorMessage = `Error ${!action.enabled ? 'deactivating' : 'activating'} Business Process`;
      }
      newState.deactivateLoading = false;
      return newState;

    default:
      return state;
  }
}

export default businessProcessReducer

import React from 'react';
import { icons } from '../../../../../../icons';
import { Image } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../icons/svg/svg-wrapper';

export const renderIcon = (hasIconInside: boolean, isPercentage: boolean) => {
  return <>{hasIconInside &&
    <div className={`flex-container with-icon ${isPercentage ? 'percent' : 'number'}`}>
      {!isPercentage ?
        <Image width='15px' src={icons.black.number} />
        :
        <SVGWrapper iconName='percentage' width='13' height='15' color='#000' />
      }
    </div>
  }
  </>
}
import {
  GET_DOCUMENT_FORM_FIELDS,
  GET_DOCUMENT_FORM_FIELDS_RETURN,
  GET_DOCUMENT_FORM_FIELDS_ERROR,
  RESET_FORM_DOCUMENT_LIST,
  FILTER_DOCUMENTS,
  FILTER_DOCUMENTS_RETURN,
  FILTER_DOCUMENTS_ERROR,
  GET_FORM_CUSTOM_FILTER,
  GET_FORM_CUSTOM_FILTER_ERROR,
  GET_FORM_CUSTOM_FILTER_RETURN,
  REFRESH_DOCUMENTS_TABLE,
  SET_DOCUMENT_FILTER,
  CHECK_CURRENT_USER_EDITING,
  SET_CURRENT_USER_EDITING,
  CANCEL_CURRENT_USER_EDITING,
  GET_STATUS_FILTER_FIELD,
  GET_STATUS_FILTER_FIELD_RETURN,
} from './constant'
import { IDocumentFormFieldsDetailInput } from './interface/input/form-fields'
import { IDocumentFormFieldsResponse } from './interface/response/form-fields'
import { IFilterByFormDetailInput } from './interface/input/filter-documents'
import { IFilterByFormResponse } from './interface/response/filter-documents'
import { IFormCustomFilterDetailInput } from './interface/input/form-custom-filter'
import { IFormCustomFilterResponse } from './interface/response/form-custom-filter';
import { IUserLockingCheckDetailInput } from './interface/input/user-locking-check'
import { IuserLockingCheckResponse } from './interface/response/user-locking-check'
import { IField } from '../../../admin-module/module/users/interface/field'

export const getDocumentFormFieldsAction = (payload: IDocumentFormFieldsDetailInput) => {
  return { type: GET_DOCUMENT_FORM_FIELDS, ...payload }
}

export const getDocumentFormFieldsReturnAction = (payload: IDocumentFormFieldsResponse) => {
  return { type: GET_DOCUMENT_FORM_FIELDS_RETURN, ...payload }
}

export const getDocumentFormFieldsErrorAction = (message: string) => {
  return { type: GET_DOCUMENT_FORM_FIELDS_ERROR, message }
}

export const resetFormDocumentListAction = () => {
  return { type: RESET_FORM_DOCUMENT_LIST }
}

export const filterDocumentsAction = (payload: IFilterByFormDetailInput) => {
  return { type: FILTER_DOCUMENTS, ...payload }
}

export const filterDocumentsReturnAction = (payload: IFilterByFormResponse) => {
  return { type: FILTER_DOCUMENTS_RETURN, ...payload }
}

export const filterDocumentsErrorAction = (message: string) => {
  return { type: FILTER_DOCUMENTS_ERROR, message }
}

export const getFormCustomFilterAction = (payload: IFormCustomFilterDetailInput) => {
  return { type: GET_FORM_CUSTOM_FILTER, ...payload }
}

export const getFormCustomFilterReturnAction = (payload: IFormCustomFilterResponse) => {
  return { type: GET_FORM_CUSTOM_FILTER_RETURN, ...payload }
}

export const getFormCustomFilterErrorAction = (message: string) => {
  return { type: GET_FORM_CUSTOM_FILTER_ERROR, message }
}

export const refreshDocumentsTableAction = (refreshTable: boolean) => {
  return { type: REFRESH_DOCUMENTS_TABLE, refreshTable }
}

export const setDocumentFilter = (payload: any) => {
  return { type: SET_DOCUMENT_FILTER, ...payload }
}

export const checkCurrentuserEditingTheDocumentAction = (payload: IUserLockingCheckDetailInput) => {
  return { type: CHECK_CURRENT_USER_EDITING, ...payload }
}

export const setCurrentUserEdintingTheDocumentAction = (payload: IuserLockingCheckResponse) => {
  return { type: SET_CURRENT_USER_EDITING, ...payload }
}

export const cancelCurrentUserEditingAction = () => {
  return { type: CANCEL_CURRENT_USER_EDITING, }
}

export const getStatusFilterFieldAction = (payload: { company: string, fields: any }) => {
  return { type: GET_STATUS_FILTER_FIELD, ...payload }
}

export const getStatusFilterFieldReturnAction = (payload: { statusFieldFilter: IField[] }) => {
  return { type: GET_STATUS_FILTER_FIELD_RETURN, ...payload }
}
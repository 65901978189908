import { useEffect, useState } from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ITimeTriggerField } from '../interface/status-task-list';

interface IProps {
  triggerFieldData: ITimeTriggerField;
  currentFormRefFields: ICustomFieldDetail[];
}

export const useSetFormAndFieldValueState = (props: IProps) => {
  const { currentFormRefFields, triggerFieldData } = { ...props };

  const [fieldValue, setFieldValue] = useState('');
  const [formValue, setFormValue] = useState('');

  useEffect(() => {
    if (triggerFieldData) {
      const reference = triggerFieldData && triggerFieldData.fieldConfig && triggerFieldData.fieldConfig.reference;
      if (reference && Object.keys(reference).length > 0) {
        const refField = currentFormRefFields.length > 0 && currentFormRefFields.find(field => field.id === reference.refFieldId);
        if (refField && Object.keys(refField).length > 0) {
          const { config } = { ...refField };
          const { data, dataType } = { ...config };
          const sourceId = dataType === 'database' ? data.databaseId : data.formId;
          setFormValue(sourceId);
          setFieldValue(reference.keyPath);
        }
      } else {
        const formId = triggerFieldData && triggerFieldData.fieldConfig && triggerFieldData.fieldConfig.fromBP;
        const fieldId = triggerFieldData && triggerFieldData.fieldConfig && triggerFieldData.fieldConfig.fieldId;
        setFormValue(formId || '');
        setFieldValue(fieldId || '');
      }
    }
    // eslint-disable-next-line
  }, [triggerFieldData, currentFormRefFields]);

  return { ...props, fieldValue, setFieldValue, formValue, setFormValue };
}
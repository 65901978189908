import { IFormRole } from '../../component/admin-module/module/business-process/module/roles/interface/form-role'
import { IFormAction } from '../../component/admin-module/module/business-process/module/workflow/interface/form-action';
import { IUser } from '../../component/admin-module/module/users/interface/user';
import { IGroup } from '../../component/admin-module/module/groups/interface/group';
import { IConstraint, IStatusConstraint } from '../../component/admin-module/module/business-process/module/roles/interface/constraint';

interface IApiRole {
  id: string;
  name?: string;
  groupIds: string[];
  userIds: string[];
  actionIds: string[];
  constraintsPerStatus: IStatusConstraint[];
}

interface IApiIncomingRole {
  id: string;
  name: string;
  automatic: boolean;
  groups: IGroup[];
  users: IUser[];
  actions: IFormAction[];
  constraintsPerStatus: IStatusConstraint[];
}

export class SanitizeRole {
  static outgoingFormRole = (roleList: IFormRole[]): IApiRole[] => {
    let formRole = [] as IApiRole[];
    roleList.forEach((role: IFormRole) => {
      if (role.deleted !== true) {
        const groupIds: string[] = [];
        const userIds: string[] = [];
        const actionIds: string[] = [];
        if (role.type === 'group') {
          groupIds.push(role.linkedId)
        }
        if (role.type === 'user') {
          userIds.push(role.linkedId)
        }
        role.actions.forEach((action: IFormAction) => {
          actionIds.push(action.id)
        })

        formRole.push({
          id: role.id,
          constraintsPerStatus: role.constraintsPerStatus || [],
          // name: undefined,
          groupIds: groupIds,
          userIds: userIds,
          actionIds: actionIds
        })
      }
    })

    return formRole;
  }

  static incomingFormRole = (roleList: IApiIncomingRole[]): IFormRole[] => {
    let formRole = [] as IFormRole[];
    roleList.forEach((role: IApiIncomingRole) => {
      let type = '';
      let linkedId = '';
      let active = undefined;

      if (role.groups.length > 0) {
        type = 'group';
        linkedId = role.groups[0].id;
      }

      if (role.users.length > 0) {
        type = 'user';
        linkedId = role.users[0].id || '';
        active = role.users[0].active;
      }

      formRole.push({
        id: role.id,
        name: role.name,
        linkedId,
        type: type,
        actions: role.actions,
        constraintsPerStatus: role.constraintsPerStatus,
        active: active,
        disabled: role.automatic,
      })
    })
    return formRole;
  }

  static updateRoleListConstraint = (roleList: IFormRole[], constraintList: IConstraint[]) => {
    const tempRoleList = [...roleList];
    return tempRoleList
      .filter((e) => !e.disabled)
      .map((role: IFormRole) => {
        const tempRole = { ...role };
        if (tempRole.constraintsPerStatus && tempRole.constraintsPerStatus.length > 0) {
          tempRole.constraintsPerStatus = tempRole.constraintsPerStatus.map((constraintStatus: IStatusConstraint) => {
            const tempStatusConstraint = { ...constraintStatus };
            tempStatusConstraint.constraints = tempStatusConstraint.constraints.map((constraint: IConstraint) => {
              let tempConstraint = { ...constraint };
              const checkConstraintForUpdate = constraintList.filter((constraintTemplate: IConstraint) =>
                constraintTemplate.style
                && constraint.style
                && constraintTemplate.style.constraintId === constraint.style.constraintId
              );
              if (checkConstraintForUpdate.length > 0) {
                tempConstraint = checkConstraintForUpdate[0];
              }
              return tempConstraint;
            })
            return tempStatusConstraint;
          })
        }
        return tempRole;
      })
  }
}
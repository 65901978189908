import React from 'react';
import { icons } from '../../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';
import { getFilters } from '../../../../../../main/partials/header/hexToCssFilter';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IFieldProps {
  field: ICustomFieldDetail;
  viewIcon(field: ICustomFieldDetail, bpImage?: { src: any, seq: number }, type?: string): void;
  bpImage?: { src: any, seq: number };
}

const Field: React.FC<IFieldProps> = (props) => {

  const filters = { filter: getFilters('#797979').filter }
  return (
    <div className={`field`}>
      {props.viewIcon(props.field, props.bpImage, 'gray')}
      <span>{props.field.label}</span>
      <Image className={`delete-btn`} style={{ ...filters, width: '15px', }} src={icons.black.delete} />
      <Image className={`unlink-btn`} style={{ ...filters, width: '15px', }} src={icons.black.unlink} />
    </div>
  );
}

export default Field;
import { call, put, takeLatest } from 'redux-saga/effects';
import { postRequest, getRequest, deleteRequest, putRequest, IAxiosResponse } from '../../../../common/api-request';
import {
  CREATE_DATA_TABLE,
  GET_DATA_TABLE_LIST,
  DELETE_DATATABLE_CONFIRM,
  GET_DATA_TABLE_FIELDS,
  GET_DATA_TABLE_DETAILS,
  CREATE_DATA_TABLE_RECORDS,
  GET_DATA_TABLE_RECORDS,
  UPDATE_DATA_TABLE,
  DELETE_DATA_TABLE_FIELDS,
  UPDATE_DATA_TABLE_RECORD,
  DELETE_DATATABLE_RECORD_CONFIRM,
  GET_DATATABLE_DETAIL_PERMISSION_OPTION,
  CHECK_DATATABLE_RECORD,
  TRIGGER_FIELD_TO_UPDATE,
  SET_RECORD_EDITABLE,
  FILTER_DATATABLE_RECORD,
  GET_FILTER_FIELD_DATATABLE_RECORD,
  BATCH_UPDATE_DATATABLE_RECORD,
  SET_RECORDS_GROUPED_CONFIG,
  GET_RECORDS_GROUPED_CONFIG,
  GET_RECORDS_GROUPED_KEYPATHS,
  DELETE_RECORDS_GROUPED_CONFIG
} from './constant';
import {
  createDataTableErrorAction,
  createDataTableReturnAction,
  getDataTableListReturnAction,
  confirmDeleteDatatableErrorAction,
  confirmDeleteDatatableReturnAction,
  getDataTableFieldsErrorAction,
  getDataTableFieldsReturnAction,
  getDataTableDetailsReturnAction,
  createDataTableRecordsReturnAction,
  getDataTableRecordsErrorAction,
  getDataTableRecordsReturnAction,
  updateDataTableErrorAction,
  updateDataTableReturnAction,
  deleteDataTableFieldsErrorAction,
  deleteDataTableFieldsReturnAction,
  createDataTableRecordsErrorAction,
  updateDataTableRecordErrorAction,
  updateDataTableRecordReturnAction,
  confirmDeleteDataTableRecordErrorAction,
  confirmDeleteDataTableRecordReturnAction,
  getDatatableDetailPermissionOptionReturnAction,
  checkHasDatatableRecordReturnAction,
  fieldTriggerUpdateResponseAction,
  setDataTableRecordEditableReturn,
  filterDatatableRecordReturnAction,
  getfilterFieldDatatableRecordReturnAction,
  batchUpdateDatatableRecordReturnAction,
  batchUpdateDatatableRecordErrorAction,
  getRecordsGroupedConfigReturnAction,
  getRecordsGroupedKeypathReturnAction
} from './action';
import { setStatusCodeAction } from '../../../error/action';
import { IDatatableConfirmDeleteInput } from './interface/input/datatable-confirm-delete';
import { IDataTableRecordCreateInput, IDataTableRecordBatchInput } from './interface/input/data-table-record-create';
import { IDataTableCreateInput, IDTDelteRecordsGroupedInput, IDTSetRecordsGroupedInput } from './interface/input/data-table-create';
import { IDataTableRecordsInput, IFilterDataTableRecordsInput, IGetFilterFilterDataTableRecordsInput } from './interface/input/data-table-records';
import { IDataTableUpdateInput } from './interface/input/data-table-update';
import { IDataTableFieldDeleteInput } from './interface/input/data-table-field-delete';
import { IDataTableRecordUpdateInput } from './interface/input/data-table-record-update';
import { IDataTableRecordConfirmDeleteInput } from './interface/input/data-table-record-confirm-delete';
import { SanitizeField } from '../../../../common/utils/sanitize-field';
import { ICustomFieldDetail } from '../../../../common/custom-field-config/interface/custom-field-detail';
import { SanitizeDatatablePermission } from '../../../../common/utils/sanitize-datatable-permission';
import { IUserListInput } from '../users/interface/input/user-list';
import { IReloadFieldInput } from '../../../document-module/module/form/interface/input/field-trigger-reload';
import { IDatatableConfigUniqueConstraint } from './interface/data-table-config';
import { IDataTableListInput } from './interface/input/data-table-list';
import { datatableRecordLimit } from '../../../document-module/module/datatable/constant';
import { IDTRecordsGroupedInput } from './interface/input/data-table-detail';

export function* getDataTableList(args: IDataTableListInput) {
  try {
    let company = args.company;
    let name = args.name ? `&name=${args.name}` : '';
    const req: IAxiosResponse = yield call(getRequest, `${company}/databases?load=fields${name}`, {});
    yield put(getDataTableListReturnAction({ ...req.data, error: '' }))
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getDataTableListReturnAction(error))
  }
}

export function* createDataTable(args: IDataTableCreateInput) {
  try {
    let company = args.company;
    let data = { ...args.data };
    data.fields = SanitizeField.structureOutgoingFields(args.data.fields as ICustomFieldDetail[]);
    data.permissionRules = SanitizeDatatablePermission.convertOutgoingPermission(args.data.permissionRules);
    data.config.uniqueConstraints = args.data.config.uniqueConstraints.filter((e: IDatatableConfigUniqueConstraint) => e.fields && e.fields.length > 0);
    let req: IAxiosResponse = yield call(postRequest, `${company}/databases/batchCreate`, data);
    yield put(createDataTableReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(error);
    yield put(setStatusCodeAction(error))
    yield put(
      createDataTableErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }

}

export function* deleteDatatable(args: IDatatableConfirmDeleteInput) {
  try {
    let company = args.company;
    let datatableId = args.dataTableId;
    yield call(deleteRequest, `${company}/databases/${datatableId}`, {});
    yield put(confirmDeleteDatatableReturnAction({ datatableId }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(e))
    yield put(confirmDeleteDatatableErrorAction(JSON.stringify(error.response.data.message || e)));
  }
}

export function* getDataTableFields(args: any) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId
    let req: IAxiosResponse = yield call(postRequest, `${company}/databases/${databaseId}/records/fields`, {});
    yield put(getDataTableFieldsReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    if (error && error.response && error.response.data) {
      yield put(getDataTableFieldsErrorAction({ message: JSON.stringify(error.response.data.message) }));
    }
  }
}

export function* getDataTableDetails(args: any) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let req: IAxiosResponse = yield call(getRequest, `${company}/databases/${databaseId}?load=permissions&load=fields&load=total&load=access`, {});
    yield put(getDataTableDetailsReturnAction({
      data:
      {
        ...req.data.data,
        permissionRules: SanitizeDatatablePermission.convertIncomingPermission(req.data.data.permissionRules),
        fields: SanitizeField.structureIncomingField(req.data.data.fields)
      }
    }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(
      getDataTableFieldsErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* createDataTableRecord(args: IDataTableRecordCreateInput) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let data = args.data;
    let req: IAxiosResponse = yield call(postRequest, `${company}/databases/${databaseId}/records`, data);
    yield put(createDataTableRecordsReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(
      createDataTableRecordsErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* getDataTableRecords(args: IDataTableRecordsInput) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let limit = args.limit || 0;
    let offset = args.offset || 0;
    let req: IAxiosResponse = yield call(getRequest, `${company}/databases/${databaseId}/records?limit=${limit}&offset=${offset}`, {});
    yield put(getDataTableRecordsReturnAction({
      data: req.data.data,
      datatableRecordTotalCount: req.data && req.data.pagination ? req.data.pagination.total : 0,
    }));
  } catch (e) {
    const error = e as any;
    console.log(error);
    yield put(setStatusCodeAction(error))
    yield put(
      getDataTableRecordsErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* checkDataTableRecords(args: IDataTableRecordsInput) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let req: IAxiosResponse = yield call(getRequest, `${company}/databases/${databaseId}/records?limit=1`, {});
    yield put(checkHasDatatableRecordReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
  }
}

export function* updateDataTable(args: IDataTableUpdateInput) {
  try {
    let company = args.company;

    let data = { ...args.data };
    let databaseId = args.dataTableId;
    data.fields = SanitizeField.structureOutgoingFields(args.data.fields as ICustomFieldDetail[]);
    data.permissionRules = SanitizeDatatablePermission.convertOutgoingPermission(args.data.permissionRules);
    data.config.uniqueConstraints = args.data.config.uniqueConstraints.filter((e: IDatatableConfigUniqueConstraint) => e.fields && e.fields.length > 0);
    let req: IAxiosResponse = yield call(postRequest, `${company}/databases/${databaseId}/batchUpdate`, data);
    yield put(updateDataTableReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(
      updateDataTableErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* deleteDataTableFields(args: IDataTableFieldDeleteInput) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let fieldId = args.fieldId;
    yield call(deleteRequest, `${company}/databases/${databaseId}/fields/${fieldId}`, {});
    yield put(deleteDataTableFieldsReturnAction());
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(deleteDataTableFieldsErrorAction(JSON.stringify(error.response.data.message || e)));
  }
}

export function* updateDataTableRecord(args: IDataTableRecordUpdateInput) {
  try {
    let company = args.company;
    let data = args.data;
    let databaseId = args.dataTableId;
    let recordId = args.recordId
    let req: IAxiosResponse = yield call(putRequest, `${company}/databases/${databaseId}/records/${recordId}`, data);
    yield put(updateDataTableRecordReturnAction({ data: { ...req.data.data } }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(
      updateDataTableRecordErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* deleteDataTableRecord(args: IDataTableRecordConfirmDeleteInput) {
  try {
    let company = args.company;
    let databaseId = args.dataTableId;
    let recordId = args.recordId
    yield call(deleteRequest, `${company}/databases/${databaseId}/records/${recordId}`, {});
    yield put(confirmDeleteDataTableRecordReturnAction({ recordId }));
  } catch (e) {
    const error = e as any;
    console.log(error.response);
    yield put(setStatusCodeAction(error))
    yield put(
      confirmDeleteDataTableRecordErrorAction(JSON.stringify(error.response.data.message || e))
    );
  }
}

export function* getActiveUserList(args: IUserListInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/users?company=${company}&limit=0&active=true`, {});
    const groupReq: IAxiosResponse = yield call(getRequest, `${company}/groups?limit=0&active=true`, {});
    const fieldListReq: IAxiosResponse = yield call(getRequest, `${company}/users/fields?company=${company}`, {});
    const options = SanitizeDatatablePermission.convertUserPermissionOption(req.data.data, groupReq.data.data, fieldListReq.data.data);
    yield put(getDatatableDetailPermissionOptionReturnAction({ data: options }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    const error = e as any;
    console.log(error.response || e);
    // nothing to do
  }
}

export function* triggerFieldToUpdate(args: IReloadFieldInput) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    const documentId = !args.documentId.includes('new') ? args.documentId : '';
    const documentQuery = documentId ? `&recordId=${documentId}` : '';
    let fieldId = '';
    if (args.fieldId && args.fieldId.length > 0) {
      args.fieldId.forEach((id: string, idx: number) => {
        if (idx === 0) {
          fieldId += `ids=${id}`;
        } else {
          fieldId += `&ids=${id}`;
        }
      });
    }
    const fieldResponse: IAxiosResponse = yield call(postRequest,
      `${company}/databases/${formId}/records/fields?${fieldId}${documentQuery}`,
      { fields: args.fields, inputs: args.inputs }
    );
    yield put(fieldTriggerUpdateResponseAction({
      formField: fieldResponse.data.data,
      valueFieldReload: args.fields,
      gridEditMode: args.gridEditMode,
      docId: args.documentId
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* editRecord(args: any) {
  try {
    const company = args.companyId;
    const formId = args.formId;
    const documentQuery = args.recordId ? `&recordId=${args.recordId}` : '';
    const fieldResponse: IAxiosResponse = yield call(postRequest,
      `${company}/databases/${formId}/records/fields?${documentQuery}`,
      { fields: args.fields, inputs: args.inputs }
    );

    yield put(setDataTableRecordEditableReturn({
      formField: fieldResponse.data.data,
      isEditable: args.isEditable,
      editableId: args.editableId
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* filterDatatableRecord(args: IFilterDataTableRecordsInput) {
  try {
    const company = args.company;
    const dataTableId = args.dataTableId;
    const limit = args.limit;
    const offset = args.offset;
    const sort = args.sort ? `&sort=${args.sort}` : '';
    const globalSearch = args.globalSearch ? `&globalSearch=${args.globalSearch}` : '';
    const req: IAxiosResponse = yield call(postRequest,
      `${company}/databases/${dataTableId}/records/search?limit=${limit}&offset=${offset}${sort}${globalSearch}`,
      { filters: args.filters }
    );
    yield put(filterDatatableRecordReturnAction({
      data: req.data.data,
      sort: args.sort,
      datatableRecordTotalCount: req.data && req.data.pagination ? req.data.pagination.total : 0,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* getFilterFieldDatatableRecord(args: IGetFilterFilterDataTableRecordsInput) {
  try {
    const company = args.company;
    const dataTableId = args.dataTableId;
    const req: IAxiosResponse = yield call(postRequest, `${company}/databases/${dataTableId}/records/filterFields${args.ids ? `?ids=${args.ids}` : ''}`, {});

    yield put(getfilterFieldDatatableRecordReturnAction({
      datatableRecordFilterFieldList: req.data.data,
    }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}

export function* saveDatatableByBatch(args: IDataTableRecordBatchInput) {
  try {
    const companyId = args.company;
    const datatableId = args.datatableId;
    const data = args.data;
    const offset = args.offset ? `&offset=${args.offset}` : '';
    yield call(postRequest, `${companyId}/databases/${datatableId}/records/batch`, data);
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/databases/${datatableId}/records?limit=${datatableRecordLimit}${offset}`, {});
    const returnedData = args.localData ? args.localData.map((e) => {
      const f = { ...e };
      f.saveAlready = true;
      return f;
    }) : req.data.data;
    yield put(batchUpdateDatatableRecordReturnAction({
      dataTableRecordsList: returnedData,
      datatableRecordTotalCount: req.data && req.data.pagination ? req.data.pagination.total : 0,
    }));
  } catch (e) {
    const error = e as any;
    console.log('e: ', e);
    yield put(setStatusCodeAction(error))
    yield put(
      batchUpdateDatatableRecordErrorAction(JSON.stringify(error.response.data.message))
    );
  }
}

export function* setRecordsGroupedConfig(args: IDTSetRecordsGroupedInput) {
  try {
    let company = args.company;
    yield call(postRequest, `${company}/recordsGrouped/config`, args.data);
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e));
    yield put(
      createDataTableErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* deleteRecordsGroupedConfig(args: IDTDelteRecordsGroupedInput) {
  try {
    let company = args.company;
    yield call(deleteRequest, `${company}/recordsGrouped/config/${args.id}`, {});
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e));
    yield put(
      createDataTableErrorAction({ message: JSON.stringify(error.response.data.message) })
    );
  }
}

export function* getRecordsGroupedConfig(args: IDTRecordsGroupedInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/recordsGrouped/config?entityType=${args.entityType}&entityConfigId=${args.entityConfigId}`, {});
    yield put(getRecordsGroupedConfigReturnAction({
      data: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* getRecordsGroupedKeypaths(args: IDTRecordsGroupedInput) {
  try {
    let company = args.company;
    const keyPaths: IAxiosResponse = yield call(getRequest, `${company}/recordsGrouped/keyPaths?entityType=${args.entityType}&entityConfigId=${args.entityConfigId}`, {});
    yield put(getRecordsGroupedKeypathReturnAction({
      groupedRecordsKeypath: keyPaths.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* dataTableSagas() {
  yield takeLatest(GET_DATA_TABLE_LIST, getDataTableList);
  yield takeLatest(CREATE_DATA_TABLE, createDataTable);
  yield takeLatest(DELETE_DATATABLE_CONFIRM, deleteDatatable);
  yield takeLatest(GET_DATA_TABLE_FIELDS, getDataTableFields);
  yield takeLatest(GET_DATA_TABLE_DETAILS, getDataTableDetails);
  yield takeLatest(CREATE_DATA_TABLE_RECORDS, createDataTableRecord);
  yield takeLatest(GET_DATA_TABLE_RECORDS, getDataTableRecords);
  yield takeLatest(CHECK_DATATABLE_RECORD, checkDataTableRecords);
  yield takeLatest(UPDATE_DATA_TABLE, updateDataTable);
  yield takeLatest(UPDATE_DATA_TABLE_RECORD, updateDataTableRecord);
  yield takeLatest(DELETE_DATATABLE_RECORD_CONFIRM, deleteDataTableRecord);
  yield takeLatest(DELETE_DATA_TABLE_FIELDS, deleteDataTableFields);
  yield takeLatest(GET_DATATABLE_DETAIL_PERMISSION_OPTION, getActiveUserList);
  yield takeLatest(TRIGGER_FIELD_TO_UPDATE, triggerFieldToUpdate);
  yield takeLatest(SET_RECORD_EDITABLE, editRecord);
  yield takeLatest(FILTER_DATATABLE_RECORD, filterDatatableRecord);
  yield takeLatest(GET_FILTER_FIELD_DATATABLE_RECORD, getFilterFieldDatatableRecord);
  yield takeLatest(BATCH_UPDATE_DATATABLE_RECORD, saveDatatableByBatch);
  yield takeLatest(GET_RECORDS_GROUPED_CONFIG, getRecordsGroupedConfig);
  yield takeLatest(SET_RECORDS_GROUPED_CONFIG, setRecordsGroupedConfig);
  yield takeLatest(DELETE_RECORDS_GROUPED_CONFIG, deleteRecordsGroupedConfig);
  yield takeLatest(GET_RECORDS_GROUPED_KEYPATHS, getRecordsGroupedKeypaths);
}

// All sagas to be loaded
export default dataTableSagas;
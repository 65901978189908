import { v4 as uuid } from 'uuid';
import { IOptionValue } from '../../../../../../../component/admin-module/module/users/interface/field';
import { FieldProperties } from '../../../../service/field-properties.service';
import { EnumProps } from '../../../../service/type/enum';
import { EnumMultiselectProps } from '../../../../service/type/enum-multiselect';
import { IDropdownOption } from '../../../enum-type/checklist';
import { AcceptedProps } from '../../../interface/accepted-props';

export interface ISetConstraintInitialValueProps {
  getOption(props: AcceptedProps): IOptionValue[];
  currentProps: AcceptedProps;
  setForceReadOnlyElement(value: boolean): void;
  elementProperties: FieldProperties<EnumProps> | FieldProperties<EnumMultiselectProps>;
  setRefresher(value: number): void;
  refresher: number;
}

export const setConstraintInitialValue = ({
  getOption,
  currentProps,
  setForceReadOnlyElement,
  setRefresher,
  elementProperties,
  refresher
}: ISetConstraintInitialValueProps) => {
  let componentOption = getOption(currentProps)
    .map((e: IOptionValue): IDropdownOption => {
      return { key: uuid(), text: e.label, value: e.id, deleted: e.deleted || undefined }
    });
  if (componentOption.length === 1) {
    setForceReadOnlyElement(true);
    if (currentProps?.inputConfig?.config?.multiselect) {
      elementProperties.current.value = [componentOption[0].value]
    } else {
      elementProperties.current.value = componentOption[0].value;
    }
    setRefresher(refresher + 1);
  }
}

import React from 'react';
import Field from './field';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { numberToStringName } from '../../constant';
import { useDrop, DragSourceMonitor, useDrag } from 'react-dnd';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../common/icons';
import ToolTip from '../../../../../../../../common/general/tooltip';
import { IConfigName } from '../../../../../../../../common/field/type/interface/field-type-object';
import { DragFieldObject } from '../../section-field-manager/interface/section-update-config';

interface ISectionBody {
  section: IFormBuilderSection;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

interface IFieldContainerBody {
  fields: TFormBuilderSectionRow;
  index: number;
  section: IFormBuilderSection;
  addNewFieldInSectionFieldList(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  editFieldConfig(field: ICustomFieldDetail, sectionId: string): void;
  openDeleteConfirmationModal(field: ICustomFieldDetail, sectionId: string): void;
  unlinkField(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  unlinkFieldToPlainSection(sectionId: string, field: ICustomFieldDetail, rowIndex: number, currentFieldIndex: number): void;
  sortFieldSection(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number): void;
  moveField(dragItem: DragFieldObject, sectionId: string, dropAreaRowIndex: number, dropAreaFieldIndex: number): void;
  bpImage: { [bpId: string]: { src: any, seq: number } };
}

const FieldContainer: React.FC<IFieldContainerBody> = (props) => {
  const fieldCountInText = numberToStringName[(props.fields.filter((e: ICustomFieldDetail) => e.deleted !== true && e.configName !== IConfigName.Seq)).length.toString()];

  // eslint-disable-next-line
  const [dropFieldProps, dropFieldRef] = useDrop({
    accept: 'field',
    drop: (item: any) => {
      props.addNewFieldInSectionFieldList(item, props.section.sectionId || '', props.index)
    }
  })

  // eslint-disable-next-line
  const [dropSortFieldProps, dropSortFieldRef] = useDrop({
    accept: 'sortField',
    drop: (item: DragFieldObject) => {
      props.sortFieldSection(item, props.section.sectionId || '', props.index)
    }
  })

  // eslint-disable-next-line
  const [draggedObject, dragRef] = useDrag({
    item: {
      type: 'sortField',
      fieldRowIndex: props.index,
      fieldSectionId: props.section.sectionId,
      rowItem: props.fields
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  })

  return <>
    <div ref={props.section.style !== 'plain' ? dragRef : null}>

      {props.section.style !== 'plain' &&
        <ToolTip position='left center'
          popupMessage='Re-order row'
          popupTriggerComponent={
            <Image style={{
              float: 'left',
              width: '10px',
              marginTop: '9px',
              marginRight: '5px',
              cursor: 'grab'
            }} src={icons.blue.dragIcon}></Image>
          }
        />
      }
      <div ref={dropSortFieldRef}>
        <div ref={dropFieldRef} className={`field-group ${fieldCountInText}`}>
          {
            props.fields.map((field: ICustomFieldDetail, index: number) => {
              if (field.deleted !== true && field.configName !== IConfigName.Seq) {
                return <Field
                  field={field}
                  key={`field-container-field-${index}`}
                  editFieldConfig={props.editFieldConfig}
                  section={props.section}
                  openDeleteConfirmationModal={props.openDeleteConfirmationModal}
                  showUnlink={(props.fields.filter((e: ICustomFieldDetail) => e.deleted !== true && e.configName !== IConfigName.Seq)).length > 1}
                  rowIndex={props.index}
                  currentFieldIndex={index}
                  unlinkField={props.unlinkField}
                  unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
                  moveField={props.moveField}
                  bpImage={props.bpImage}
                />;
              }
              return undefined;
            })
          }
        </div>
      </div>
    </div>
  </>
}

const SectionBody: React.FC<ISectionBody> = (props) => {
  return <div className={props.section.style !== 'plain' ? `section-body` : ''} >
    {
      props.section.fields.map((fields: TFormBuilderSectionRow, index: number) => {
        const hasField = fields.filter((field: ICustomFieldDetail) => field.deleted !== true && field.configName !== IConfigName.Seq);
        return hasField.length > 0
          ? <FieldContainer
            key={`section-body-field-container-${index}`}
            fields={fields}
            index={index}
            section={props.section}
            addNewFieldInSectionFieldList={props.addNewFieldInSectionFieldList}
            editFieldConfig={props.editFieldConfig}
            openDeleteConfirmationModal={props.openDeleteConfirmationModal}
            unlinkField={props.unlinkField}
            unlinkFieldToPlainSection={props.unlinkFieldToPlainSection}
            sortFieldSection={props.sortFieldSection}
            moveField={props.moveField}
            bpImage={props.bpImage}
          />
          : undefined
      })
    }
  </div>
}

export default SectionBody;

import { IComment, ICommentDeltaFields } from '../../../../../../interface/comment';
import { ISenderDetail, ISenderType } from '../../../../../../interface/sender-info';

export const setCommentText = (newDeltaFields: ICommentDeltaFields[], comment: IComment) => {
  const { sender } = { ...comment };
  const senderUserInfo = { ...(sender && sender.data) } as ISenderDetail;
  const deltaFieldsInfo = newDeltaFields.length === 1 ? newDeltaFields[0].label : newDeltaFields.length;

  let commentSender = senderUserInfo && (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');
  let commentField = newDeltaFields.length === 1 ? 'field' : 'fields';

  if (comment.isAdminHistory && !comment.displayGeneratedText) {
    comment.text = comment.text.match(/\((.*)\)/)?.pop() || comment.text;
  }

  if (sender && sender.type === ISenderType.Automation) {
    commentSender = `Automation "${senderUserInfo.name || ''}"`;
  }

  if (comment.displayGeneratedText) {
    return comment.text;
  }

  return `${commentSender} has updated ${deltaFieldsInfo} ${commentField} for ${comment.isAdminHistory ? `User (${comment.text})` : 'this form'}.`
}
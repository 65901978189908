import React from 'react';
import { IFormStatus } from '../../workflow/interface/form-status';

interface ITableHeaderDetail {
  buttonColor: string;
  isShown?: boolean;
  icon?: NodeRequire;
  labelText: string;
  style?: any;
  status: IFormStatus;
}

const TableHeaderDetail: React.FC<ITableHeaderDetail> = (props) => {
  return <th className={props.buttonColor}>
    <div className='header-container'>
      <div className='header-label'>
        <span>{props.labelText}</span>
      </div>
    </div>
  </th>
}

export default TableHeaderDetail;
import React from 'react';
import { IConstraint } from '../../interface/constraint';
import { v4 as uuid } from 'uuid';
import { operatorSymbol } from '../../constant';

interface IConstraintListProps {
  constraintList: IConstraint[];
  setIsAddNew(value: boolean): void;
  setModifyConstraint(value: IConstraint | undefined): void;
}

const ConstraintList: React.FC<IConstraintListProps> = (props) => {
  return <>
    <div className={`constraint-list-container`} >
      <div className={`custom-constraint`} >
        {
          props.constraintList.map((constraint: IConstraint, index: number) => {
            return <div key={uuid()}
              className='created-constraint'
              onClick={() => props.setModifyConstraint(constraint)}
              style={constraint.style ? { backgroundColor: constraint.style.constraintColor } : {}} >
              {constraint.style ? constraint.style.constraintName : ''} {`${operatorSymbol[constraint.operator] || ''}`}
            </div>
          })
        }
      </div>
      {props.constraintList.length <= 8 &&
        <div className='add-new-button' onClick={() => props.setIsAddNew(true)}>Add New</div>
      }
    </div>
  </>
}

export default ConstraintList;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest } from '../../../../../../common/api-request';
import { setStatusCodeAction } from '../../../../../error/action';
import { GET_FORM_STATUS_LIST, SAVE_FORM_STATUS, GET_FORM_ACTION_LIST, PUBLISH_WORKFLOW } from './constant';
import { getFormStatusListReturnAction, getFormStatusListErrorAction, getFormActionListReturnAction, getFormActionListErrorAction, saveFormStatusReturnAction, publishWorkflowReturnAction, publishWorkflowErrorAction, saveFormStatusErrorAction } from './action';
import { IFormStatusListInput } from './interface/input/form-status-list';
import { IFormStatusSaveInput, IFormWorkflowPublishInput } from './interface/input/form-status-save';
import { SanitizeWorkflow } from '../../../../../../common/utils/sanitize-form-status';

export function* getFormStatusList(args: IFormStatusListInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses?draft=true&load=actions&load=inUseDoc`, {});
    yield put(getFormStatusListReturnAction({
      formStatusList: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getFormStatusListErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getFormActionList(args: IFormStatusListInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/actions?formId=${formId}&draft=true`, {});
    yield put(getFormActionListReturnAction({
      formActionList: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getFormActionListErrorAction());
  }
}

export function* saveFormStatus(args: IFormStatusSaveInput) {
  try {
    const companyId = args.companyId;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(postRequest, `${companyId}/forms/${formId}/statuses/batch?load=actions&draft=true`, SanitizeWorkflow.outgoingFormStatus(args.statusList, args.actionList));
    yield put(saveFormStatusReturnAction({ ...SanitizeWorkflow.incomingFormStatus(req.data.data), saveWorkflowType: args.saveWorkflowType }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(saveFormStatusErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* publishWorkflow(args: IFormWorkflowPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishWorkflowReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(publishWorkflowErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* workflowSagas() {
  yield takeLatest(GET_FORM_STATUS_LIST, getFormStatusList);
  yield takeLatest(GET_FORM_ACTION_LIST, getFormActionList);
  yield takeLatest(SAVE_FORM_STATUS, saveFormStatus);
  yield takeLatest(PUBLISH_WORKFLOW, publishWorkflow);
}

// All sagas to be loaded
export default workflowSagas;
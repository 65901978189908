import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getRequest,
  IAxiosResponse,
  postRequest,
  putRequest,
} from '../../../../../../../../common/api-request';
import { setStatusCodeAction } from '../../../../../../../error/action';
import {
  GET_PDF_TEMPLATE_LIST,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG,
  PUBLISH_PDF_TEMPLATE_CONFIG,
} from './constant';
import { IPdfTemplateGetInput } from './interface/input/template-get';
import {
  getPdfTemplateListReturnAction,
  saveDraftPDFTemplateConfigReturnAction,
  saveDraftPDFTemplateConfigErrorAction,
  publishPDFTemplateConfigReturnAction,
} from './action';
import {
  IPdfTemplatePublishInput,
  IPdfTemplateSaveInput,
} from './interface/input/template-save';

export function* getPDFTemplateConfig(args: IPdfTemplateGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const templates: IAxiosResponse = yield call(
      getRequest,
      `${companyId}/documentToPdf/config/${formId}?draft=true`,
      {}
    );
    console.log('templates', templates.data.data.templates);
    yield put(
      getPdfTemplateListReturnAction({
        pdfTemplateConfigList: templates.data.data.templates || [],
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
  }
}

export function* savePDFTemplateConfig(args: IPdfTemplateSaveInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    yield call(putRequest, `${companyId}/documentToPdf/config/${formId}`, {
      templates: args.pdfTemplateConfigList,
    });
    yield put(
      saveDraftPDFTemplateConfigReturnAction({
        pdfTemplateConfigSaveType: args.saveType,
      })
    );
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      saveDraftPDFTemplateConfigErrorAction(
        JSON.stringify(e.response.data.message)
      )
    );
  }
}

export function* publishPdfTemplateConfig(args: IPdfTemplatePublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(
      postRequest,
      `${company}/forms/${formId}/publish?force=false`,
      null
    );
    yield put(publishPDFTemplateConfigReturnAction());
  } catch (e) {
    yield put(setStatusCodeAction(e));
    yield put(
      saveDraftPDFTemplateConfigErrorAction(
        JSON.stringify(e.response.data.message)
      )
    );
  }
}

export function* pdfTemplateSagas() {
  yield takeLatest(GET_PDF_TEMPLATE_LIST, getPDFTemplateConfig);
  yield takeLatest(SAVE_DRAFT_PDF_TEMPLATE_CONFIG, savePDFTemplateConfig);
  yield takeLatest(PUBLISH_PDF_TEMPLATE_CONFIG, publishPdfTemplateConfig);
}

// All sagas to be loaded
export default pdfTemplateSagas;

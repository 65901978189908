import React, { useEffect } from 'react';
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Inject,
  CommandModel,
  EditSettingsModel,
  Edit,
  Sort,
  Search,
  Grid,
  Toolbar,
  Page
} from '@syncfusion/ej2-react-grids';
import classes from '../css/apps-grid.module.scss';
import { useDispatch } from 'react-redux';
import { clearAppMessageAction } from '../action';
import { useHistory } from "react-router-dom";
import { appLimit } from '../constant';

interface TableRowData {
  [key: string]: any
}
interface IAppsGridList {
  headers: string[]
  rows: TableRowData[]
  className: string
  deleteApp(appListId: string, appListLabel: string): void
  triggerFilter(limit?: number | undefined, offset?: number | undefined, search?: string | undefined): void;
  currentOffset: number;
}

const AppsGrid: React.FC<IAppsGridList> = (props) => {
  let gridInstance: Grid;

  const dispatch = useDispatch();
  const history = useHistory();
  const editOptions: EditSettingsModel = { allowDeleting: true, showDeleteConfirmDialog: true, };
  const commands: CommandModel[] = [
    { type: 'Edit', buttonOption: { iconCss: `e-icons e-edit ${classes.apps_icons}`, cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: `e-icons e-delete ${classes.apps_icons}`, cssClass: 'e-flat' } }
  ];
  const clearSearch = {
    title: 'Clear Search',
    cssClass: 'e-clear-btn',
    tooltipText: 'Clear Search',
    prefixIcon: 'e-input-group-icon e-close',
  }
  const toolbarOptions = ['Search', clearSearch]

  const commandClick = (args: any): void => {
    if (args.commandColumn.type === "Delete") {
      const row: TableRowData = args.rowData!
      props.deleteApp(row.key, row["App Name"])
    }
    else if (args.commandColumn.type === "Edit") {
      dispatch(clearAppMessageAction());
      const row: TableRowData = args.rowData!
      history.push(`/admin/apps/${row.key}`);
    }
  }

  const gridDataBound = () => {
    const toolbarLeftNodes = document.getElementsByClassName('e-toolbar-left')[0].childNodes;
    const items = [].slice.call(toolbarLeftNodes).filter((item: any) => { return item.getAttribute('title') === 'Clear Search' });
    items.forEach((element: any) => {
      element.remove();
    });
    for (var i = 0; i < items.length; i++) {
      document.getElementsByClassName('e-toolbar-right')[0].appendChild(items[i]);
    }
  }

  const gridActionBegin = (event: any) => {
    if (event.requestType === 'searching') {
      const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
      props.triggerFilter(appLimit, 0, '');
      if (clear) {
        clear.className = clear.className.replace('e-focus', '');
      }
      event.cancel = true;
    }
  }

  const gridToolbarClick = (event: any) => {
    const input: any = document.getElementById(gridInstance.element.id + '_searchbar');
    if (event && event.item) {
      if (event.item.title === 'Clear Search') {
        if (input && input.value) {
          input.value = '';
          props.triggerFilter(appLimit, 0, '');
        }
      }
      if (event.item.type === 'Input') {
        const clear = document.getElementsByClassName('e-toolbar-right')[0].getElementsByClassName('e-close')[0];
        if (input) {
          const searchWrap = input.parentElement;
          if (searchWrap) {
            clear.className = clear.className + ' e-focus';
          }
          input.onblur = (() => {
            clear.className = clear.className.replace('e-focus', '');
          });
        }
      }
    }
  }

  useEffect(() => {
    if (gridInstance) {
      const search = gridInstance.element.querySelector('#' + gridInstance.element.getAttribute('id') + '_searchbar');
      if (search) {
        search.addEventListener('keyup', function (e: any) {
          const timer = setTimeout(() => {
            props.triggerFilter(appLimit, 0, e.target.value);
          }, 1000);
          return () => { clearTimeout(timer) }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <GridComponent
      dataSource={props.rows}
      editSettings={editOptions}
      commandClick={commandClick}
      className={classes.apps_grid}
      rowHeight={31}
      toolbar={toolbarOptions}
      allowSorting={true}
      allowPaging={true}
      dataBound={gridDataBound}
      toolbarClick={gridToolbarClick}
      actionBegin={gridActionBegin}
      ref={grid => { if (grid) gridInstance = grid }}
    >
      <Inject services={[CommandColumn, Edit, Sort, Search, Toolbar, Page]} />
      <ColumnsDirective >
        <ColumnDirective isPrimaryKey field='key' visible={false} />
        <ColumnDirective field='App ID' textAlign='Center' width='150' />
        <ColumnDirective field='App Name' textAlign='Center' width='400' />
        <ColumnDirective field='Business Process' textAlign='Center' />
        <ColumnDirective headerText='' width='120' commands={commands} textAlign='Right' />
      </ColumnsDirective>
    </GridComponent>
  )
}

export default AppsGrid;


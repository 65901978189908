import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_DOCUMENT_FORM_FIELDS,
  FILTER_DOCUMENTS,
  GET_FORM_CUSTOM_FILTER,
  reportListRecordLimit,
  CHECK_CURRENT_USER_EDITING,
  GET_STATUS_FILTER_FIELD,
} from './constant';
import { IDocumentFormFieldsInput } from './interface/input/form-fields';
import { setStatusCodeAction } from '../../../error/action';
import {
  getDocumentFormFieldsErrorAction,
  getDocumentFormFieldsReturnAction,
  filterDocumentsErrorAction,
  filterDocumentsReturnAction,
  getFormCustomFilterReturnAction,
  getFormCustomFilterErrorAction,
  setCurrentUserEdintingTheDocumentAction,
  getStatusFilterFieldReturnAction,
} from './action';
import { postRequest, getRequest, IAxiosResponse } from '../../../../common/api-request';
import { IFilterSortByFormInput } from './interface/input/filter-documents';
import { IFormCustomFilterInput } from './interface/input/form-custom-filter';
import { IFormDocument } from './interface/form-document';
import { IApiColumnView } from '../../../admin-module/module/business-process/module/configuration/module/document-list/interface/custom-view';
import { IApiCustomFilterField } from '../../../admin-module/module/business-process/module/configuration/module/custom-filter/interface/custom-filter-field';
import { IUserLockingCheckInput } from './interface/input/user-locking-check';

export function* getDocumentFormFields(args: IDocumentFormFieldsInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    const publishedForms: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/fields?draft=false`, {});
    const column: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/listColumns?order=view&draft=false`, {});

    yield put(getDocumentFormFieldsReturnAction({
      columnViewList: column.data.data,
      publishedForms: publishedForms.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getDocumentFormFieldsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* filterDocuments(args: IFilterSortByFormInput) {
  try {
    let offset = '';
    if (args.offset !== undefined) {
      offset = `&offset=${args.offset}`;
    }
    let loadCreator = '&load=creator';
    if (args.documentColumnList && args.documentColumnList.length > 0) {
      if (args.documentColumnList.filter((e: IApiColumnView) => e.keyPath === 'creator').length === 0) {
        loadCreator = '';
      }
    }
    let sort = `&sort=${args.sort ? args.sort : `createdAt|desc`}`;

    const load = `?load=form${loadCreator}&load=updatedBy&load=statusName&load=slaStatus`;
    const company = args.company;
    const filterData = args.filterData;

    const filter: { data: { data: IFormDocument[], pagination: { total: number } } } = yield call(postRequest, `${company}/documents/search${load}${sort}&limit=${reportListRecordLimit}${offset}&component=reports`, filterData);

    yield put(filterDocumentsReturnAction({ filteredDocuments: filter.data.data, documentRecordTotalCount: filter.data.pagination.total }))
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(filterDocumentsErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getFormCustomFilter(args: IFormCustomFilterInput) {
  try {
    let company = args.company;
    let formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/filters`, {});
    let filterFields = { data: { data: [] } }
    if (req.data.data && req.data.data.length > 0) {
      let ids = '';
      req.data.data.forEach((e: IApiCustomFilterField) => { ids += `&ids=filters.${e.id}` });
      filterFields = yield call(postRequest, `${company}/documents/filterFields?true${ids}`, {
        fields: { form: [`${formId}`] }
      });
    }
    yield put(getFormCustomFilterReturnAction({
      customFilterList: req.data.data,
      formDocumentsFilterFields: filterFields.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getFormCustomFilterErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* checkCurrentUserEditing(args: IUserLockingCheckInput) {
  try {
    let company = args.company;
    let documentId = args.documentId;
    const req: IAxiosResponse = yield call(getRequest, `${company}/documents/${documentId}/currentUsers`, {});
    yield put(setCurrentUserEdintingTheDocumentAction({ currentUserEditingDocument: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* getStatusFilterField(args: { type: string, company: string, fields: any }) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(postRequest, `${company}/documents/filterFields?ids=status`, args.fields);
    yield put(getStatusFilterFieldReturnAction({
      statusFieldFilter: req.data.data,
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getFormCustomFilterErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* reportSagas() {
  yield takeLatest(GET_DOCUMENT_FORM_FIELDS, getDocumentFormFields);
  yield takeLatest(FILTER_DOCUMENTS, filterDocuments);
  yield takeLatest(GET_FORM_CUSTOM_FILTER, getFormCustomFilter);
  yield takeLatest(CHECK_CURRENT_USER_EDITING, checkCurrentUserEditing);
  yield takeLatest(GET_STATUS_FILTER_FIELD, getStatusFilterField);
}

// All sagas to be loaded
export default reportSagas;
import React from 'react';
import Dropbox from '../../../../../../../admin-module/module/business-process/module/configuration/module/custom-filter/configuration/drop-box';

interface IListFilterConfig {

}

const ListFilterConfig: React.FC<IListFilterConfig> = (props) => {
  return <div className='list-filter-main-container'>
    <h5>Filters</h5>
    <div className='list-filter-container'>
      <div className={`dropbox-container`}>
        <Dropbox
          itemIndex={0}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={1}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={2}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={3}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
      </div>
      <div className={`dropbox-container`}>
        <Dropbox
          itemIndex={4}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={5}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={6}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
        <Dropbox
          itemIndex={7}
          withItem={undefined}
          insertDropField={() => { }}
          deleteFilter={() => { }}
          editConfiguration={() => { }}
        />
      </div>
    </div>
  </div>
}

export default ListFilterConfig;
import React from 'react';
import { ITableMobileView } from '../../../interface/table-view';

interface IContentUpperHeader {
  tableMobileView: ITableMobileView;
  updateTableMobileView: (property: string, value: any) => void;
  index: string;
}

const ContentUpperHeader: React.FC<IContentUpperHeader> = (props) => {
  return <input
    placeholder='Type column header'
    value={props.tableMobileView.columnHeader || ''}
    onChange={({ target: { value } }) => {
      props.updateTableMobileView(props.index, { ...props.tableMobileView, columnHeader: value })
    }}
  />
}

export default ContentUpperHeader;

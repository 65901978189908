import { useEffect, useState } from 'react';
import { IFormRelated } from '../interface/bp-relationship-field';
import { IReference } from '../interface/form';

interface IProps {
  BPRelationshipList: any;
  referenceList: IReference[];
}

export const useSetBPRelationsState = (props: IProps) => {
  const { BPRelationshipList, referenceList } = { ...props };

  const [selectedRelation, setSelectedRelation] = useState<IFormRelated[]>([]);
  const [relatedOptions, setRelatedOptions] = useState<any>([]);

  const setOptions = (reference: IReference[]) => {
    return reference.map((e: IReference, i: number) => {
      return { id: e.id, text: e.name, value: `${e.id}|||||${e.name}|||||${e.dataType}`, }
    })
  };

  useEffect(() => {
    const bpRelationship: IFormRelated[] = [...BPRelationshipList];
    const relatedOptions = referenceList.filter((ref, index) => {
      return bpRelationship.filter(e => e.relationId === ref.id).length === 0 &&
        index === referenceList.findIndex(elem => elem.id === ref.id && elem.id)
    });
    setRelatedOptions(setOptions([...relatedOptions]));
    setSelectedRelation(BPRelationshipList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(BPRelationshipList), JSON.stringify(referenceList)])

  return {
    ...props, selectedRelation, setSelectedRelation,
    relatedOptions, setRelatedOptions, setOptions
  };
}
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react-bpm';
import { SemanticICONS } from 'semantic-ui-react-bpm/dist/commonjs/generic';
import { v4 as uuid } from 'uuid';
import { IField } from '../../../../../../../../../../users/interface/field';
import { IAutomationComponent } from '../../../../../interface/component-list';
import SelectedField from '../edit-form/selected-field';

interface ICreateUserFieldProps {
  dynamicRef: any;
  company: string;
  storeDynamicField(): void;
  openModal(isVisible: boolean, fieldId: string): void;
  operation: { [id: string]: string };
  manageOperator(value: string, id: string): void;
  selectedComponent: IAutomationComponent;
  manageDateFieldToday?(value: boolean, fieldId: string): void;
  dateFieldObject: { [id: string]: boolean };
  fieldList: IField[];
  formId: string;
  refsObject: React.MutableRefObject<any>;
  headerName: string;
  additionalFieldToShow?: JSX.Element[];
}

const SelectedMandatoryField: React.FC<ICreateUserFieldProps> = (props) => {

  const [expand, setExpand] = useState(false);

  const icon: SemanticICONS = expand ? 'chevron up' : 'chevron down';

  return <div className={`automation-field-section`}>
    <div className='automation-field-header'>
      <label>{props.headerName}</label>
      <div className='header-right'>
        <span className='count'>{props.fieldList.length + (props.additionalFieldToShow?.length || 0)}</span>
        <Icon name={icon} onClick={() => setExpand(!expand)} />
      </div>
    </div>
    <div className={`automation-field-content ${!expand ? 'hidden' : ''}`}>
      {props.additionalFieldToShow ? props.additionalFieldToShow : null}
      {props.fieldList.map((field: IField) => {
        return <SelectedField
          key={uuid()}
          fieldConfig={field}
          selectedBP={props.formId}
          dynamicRef={props.dynamicRef}
          storeDynamicField={props.storeDynamicField}
          company={props.company}
          openModal={props.openModal}
          isVisible={props.refsObject.current.isVisible || {}}
          operation={props.operation}
          manageOperator={props.manageOperator}
          selectedComponent={props.selectedComponent}
          manageDateFieldToday={props.manageDateFieldToday}
          dateFieldObject={props.dateFieldObject}
        />
      })}
    </div>
  </div>
}

export default SelectedMandatoryField;
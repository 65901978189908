import React, { useState, useEffect } from 'react';
import CustomFilterConfiguration from './configuration';
import CustomFilterField from './field';
import { customFilterActionMessage } from './constant';
import { ICustomFilterField, ICustomFilterContainer } from './interface/custom-filter-field';
import { AlertModal } from '../../../../../../../../common/general/alert-modal';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../../../../../../reducers';
import { saveDraftCustomFilterAction, publishCustomFilterAction } from './action';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ButtonContainer from '../../../../button-container';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';
import ActionMessage from '../../../../../../../../common/general/action-message';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { useSetCustomFilterFieldList } from './hooks/use-set-custom-filter-field-list';
import { useGetCustomFilterData } from './hooks/use-get-custom-filter-data';

export interface IDragItem {
  type: string;
  itemType: string;
  item: ICustomFilterField;
  new: boolean;
  itemIndex: number | undefined;
}

interface ICustomFilterProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const CustomFilter: React.FC<ICustomFilterProps> = (props) => {
  const match: { params: { formId: string } } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    fieldList,
    customFilterList,
    customFilterSaveType,
    saveCustomFilterLoading,
    getCustomFieldListLoading,
  } = useSelector((state: IRootState) => state.customFilter);
  const {
    company
  } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange } = useSelector((state: IRootState) => state.main);

  const [rangeFieldErrorMessage, setRangeFieldErrorMessage] = useState('');
  const [selectedFieldFilter, setSelectedFieldFilter] = useState(null as number | null);
  const { customFilterFieldList, setCustomFilterFieldList } = useSetCustomFilterFieldList({
    customFilterList,
    fieldList
  });

  const isAllowedFieldInsert = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropAreaItem: any, dropIndex: number): boolean => {
    let isAllowedFieldInsert = true;
    if (dragItem.new && dropAreaItem !== undefined) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't insert field if there is already existing field.`);
    }

    if (dragItem.itemType === 'field-range') {
      if ((dropIndex === 3 || dropIndex === 7)) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
      if (newCustomFilterFieldList[(dropIndex + 1).toString()] !== undefined && dragItem.new) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
    }

    const isMoveSingleItemDoesNotFullfillRangeSlot = newCustomFilterFieldList[dropIndex.toString()]
      && newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range'
      && dragItem.itemType !== 'field-range'
      && dragItem.itemIndex !== undefined
      && (newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] || dragItem.itemIndex === 7 || dragItem.itemIndex === 3)
      && !dragItem.new;

    if (isMoveSingleItemDoesNotFullfillRangeSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }
    const isMoveRangeDoesNotFullfillDropAreaSlot = (!newCustomFilterFieldList[dropIndex.toString()]
      || (newCustomFilterFieldList[dropIndex.toString()]
        && newCustomFilterFieldList[dropIndex.toString()].itemType !== 'field-range'))
      && dragItem.itemType === 'field-range'
      && newCustomFilterFieldList[(dropIndex + 1).toString()]
      && dragItem.itemIndex !== (dropIndex + 1)
      && !dragItem.new;

    if (isMoveRangeDoesNotFullfillDropAreaSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }

    return isAllowedFieldInsert;
  }

  const cleanDragItemSlot = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropIndex: number): { [x: string]: any } => {
    if (!dragItem.new && dragItem.itemIndex !== undefined) { // move drop area ite to drag item position
      if (newCustomFilterFieldList[dropIndex.toString()]) { // with exiting field in drop area
        if (newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range') { // for drop area field-range
          if (dragItem.itemType === 'field-range') { // for drag item field-range and drop area with field-range
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          } else {
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
            newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = 'contained';
            newCustomFilterFieldList[(dropIndex + 1).toString()] = undefined;
          }
        } else {
          if (dragItem.itemType === 'field-range') { // for drag item field-range but drop area with single field
            if (dragItem.itemIndex === (dropIndex + 1)) { // drag field range and position is of field is on left side
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = newCustomFilterFieldList[dropIndex.toString()];
            } else {
              newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
            }
          } else { // drag item field range and no item on drop area
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          }
        }
      } else {
        if (dragItem.itemType === 'field-range') { // drag item field range and no item on drop area
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
          newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
        } else {
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
        }
      }
    }

    props.hasPageChangeRef.current.hasChange = true;
    return newCustomFilterFieldList;
  }

  const insertDropField = (dragItem: IDragItem, dropIndex: number, dropAreaItem: any) => {
    let newCustomFilterFieldList = { ...customFilterFieldList };
    if (isAllowedFieldInsert(dragItem, newCustomFilterFieldList, dropAreaItem, dropIndex)) {
      newCustomFilterFieldList = cleanDragItemSlot(dragItem, newCustomFilterFieldList, dropIndex);

      if (dragItem.itemType === 'field-range') {
        newCustomFilterFieldList[(dropIndex + 1).toString()] = 'contained';
      }

      newCustomFilterFieldList[dropIndex.toString()] = dragItem.item;
      setCustomFilterFieldList(newCustomFilterFieldList)
      props.hasPageChangeRef.current.hasChange = true;
    }
  }

  const cancelConfiguration = () => {
    setSelectedFieldFilter(null);
  }

  const editConfiguration = (item: number) => {
    setSelectedFieldFilter(item);
  }

  const saveConfiguration = (filterIndex: number, selectedFilter: ICustomFilterField) => {
    customFilterFieldList[filterIndex.toString()] = selectedFilter;
    setSelectedFieldFilter(null);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const deleteFilter = (index: number, itemType: string) => {
    const newCustomFilterFieldList = { ...customFilterFieldList };
    if (itemType === 'field-range') {
      newCustomFilterFieldList[(index + 1).toString()] = undefined;
    }
    newCustomFilterFieldList[index.toString()] = undefined;
    setCustomFilterFieldList(newCustomFilterFieldList)
    props.hasPageChangeRef.current.hasChange = true;
  }

  const closeSettings = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  const saveDraft = (saveType: string) => {
    dispatch(saveDraftCustomFilterAction({
      company: company,
      formId: match.params.formId,
      saveType: saveType,
      customFilterList: customFilterFieldList,
      sanitize: true
    }));
    props.hasPageChangeRef.current.hasChange = false;
    removeReportsFilter();
  }

  const removeReportsFilter = () => {
    const item = localStorage.getItem('reports-filter');
    if (item) {
      try {
        const filterObject = JSON.parse(item);
        if (JSON.stringify(filterObject.fields)) {
          delete filterObject.fields;
          localStorage.setItem('reports-filter', JSON.stringify({ ...filterObject }));
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useGetCustomFilterData({
    dispatch,
    company,
    formId: match.params.formId
  });

  useEffect(() => {
    if (customFilterSaveType === 'publish') {
      dispatch(publishCustomFilterAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilterSaveType])

  return (<>
    <ActionMessage messages={customFilterActionMessage} />
    <div className={`custom-filter-container`}>
      {
        (saveCustomFilterLoading || getCustomFieldListLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <div className='custom-filter-main'>
        <CustomFilterConfiguration
          customFilterFieldList={customFilterFieldList}
          insertDropField={insertDropField}
          deleteFilter={deleteFilter}
          selectedFieldFilter={selectedFieldFilter}
          cancel={cancelConfiguration}
          editConfiguration={editConfiguration}
          saveConfiguration={saveConfiguration}
          fieldList={fieldList}
        />
        <CustomFilterField
          fieldList={fieldList}
          customFilterFieldList={customFilterFieldList}
        />
      </div>
      <ButtonContainer
        cancel={() => closeSettings()}
        save={() => saveDraft('draft')}
        publish={() => saveDraft('publish')}
      />
      <AlertModal
        open={rangeFieldErrorMessage !== ''}
        close={() => setRangeFieldErrorMessage('')}
        message={rangeFieldErrorMessage}
        type='error'
      />
    </div>
  </>);
}

export default CustomFilter;

import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import VerticalTableType from './vertical';

const tableTypeList = [
  new VerticalTableType(),
]

export interface ITableType {
  name: string;
  Render: React.FC<AcceptedProps>
}
export default class TableTypeComponent {

  registry: { [name: string]: ITableType } = {};

  constructor() {
    const types = tableTypeList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  getComponent(name: string): ITableType {
    return (this.registry[name])
      ? this.registry[name]
      : new VerticalTableType();
  }
}
import React from 'react';
import "react-sweet-progress/lib/style.css";
import { AcceptedProps } from './../interface/accepted-props';
import MultipleFileType from '../type/file/file-array';
import FileType from '../type/file/file';

const FileComponent: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <MultipleFileType {...props} />
  } else {
    return <FileType {...props} />
  }
}

export default FileComponent;
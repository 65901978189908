import { IPdfTemplateConfig } from './interface/pdf';
import {
  GET_PDF_TEMPLATE_LIST_RETURN,
  UPDATE_LOCAL_PDF_TEMPLATE_LIST,
  PUBLISH_PDF_TEMPLATE_CONFIG_RETURN,
  PUBLISH_PDF_TEMPLATE_CONFIG,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR,
  SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN,
  CLEAR_PDF_TEMPLATE_CONFIG_SUCCESS,
  CLEAR_PDF_TEMPLATE_CONFIG_ERROR
} from './constant';
import { message } from './message';

export interface IConfigurationPDFTemplateReducer {
  type?: string;
  pdfTemplateConfigList: IPdfTemplateConfig[];
  pdfTemplateConfigSaveType: string;
  saveDraftPdfTemplateConfigLoading: boolean;
  savePDFTempConfigMessage: string;
  savePDFTempErrorMessage: string;
  hasActionTriggered: boolean;
}

const initialState: IConfigurationPDFTemplateReducer = {
  pdfTemplateConfigList: [],
  saveDraftPdfTemplateConfigLoading: false,
  pdfTemplateConfigSaveType: '',
  savePDFTempConfigMessage: '',
  savePDFTempErrorMessage: '',
  hasActionTriggered: false
};

const configurationPDFTemplateReducer = (state = initialState, action: IConfigurationPDFTemplateReducer) => {
  let newState = { ...state };
  switch (action.type) {

    case GET_PDF_TEMPLATE_LIST_RETURN:
      newState.pdfTemplateConfigList = action.pdfTemplateConfigList;
      return newState;

    case UPDATE_LOCAL_PDF_TEMPLATE_LIST:
      newState.pdfTemplateConfigList = action.pdfTemplateConfigList;
      newState.hasActionTriggered = true;
      return newState;

    case SAVE_DRAFT_PDF_TEMPLATE_CONFIG:
      newState.saveDraftPdfTemplateConfigLoading = true;
      newState.savePDFTempConfigMessage = '';
      newState.savePDFTempErrorMessage = '';
      newState.pdfTemplateConfigSaveType = '';
      return newState;

    case SAVE_DRAFT_PDF_TEMPLATE_CONFIG_RETURN:
      newState.hasActionTriggered = false;
      if (action.pdfTemplateConfigSaveType === 'draft') {
        newState.saveDraftPdfTemplateConfigLoading = false;
        newState.savePDFTempConfigMessage = message.savePdfTemplateSuccess;
      }
      newState.pdfTemplateConfigSaveType = action.pdfTemplateConfigSaveType;
      return newState;

    case SAVE_DRAFT_PDF_TEMPLATE_CONFIG_ERROR:
      newState.savePDFTempErrorMessage = action.savePDFTempErrorMessage;
      newState.saveDraftPdfTemplateConfigLoading = false;
      return newState;

    case PUBLISH_PDF_TEMPLATE_CONFIG:
      newState.saveDraftPdfTemplateConfigLoading = true;
      newState.savePDFTempConfigMessage = '';
      newState.savePDFTempErrorMessage = '';
      newState.pdfTemplateConfigSaveType = '';
      return newState;

    case PUBLISH_PDF_TEMPLATE_CONFIG_RETURN:
      newState.saveDraftPdfTemplateConfigLoading = false;
      newState.savePDFTempConfigMessage = message.publishPdfTemplateSuccess;
      return newState;

    case CLEAR_PDF_TEMPLATE_CONFIG_SUCCESS:
      newState.savePDFTempConfigMessage = '';
      return newState;

    case CLEAR_PDF_TEMPLATE_CONFIG_ERROR:
      newState.savePDFTempErrorMessage = '';
      return newState;

    default:
      return newState;
  }
}

export default configurationPDFTemplateReducer;
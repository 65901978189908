import React from 'react';
import UserModule from './users';
// import GroupModule from './groups';
import BusinessProcessRouteModule from './business-process/business-process-route';
import AppModule from './apps';
import DataTableModule from './data-tables';
import { IHasPageChangeRef } from '../../main/interface/has-page-change';
// import AdminSettings from './settings/settings-route';

const ModuleList = [
  new UserModule(),
  // new GroupModule(),
  new BusinessProcessRouteModule(),
  new AppModule(),
  new DataTableModule(),
  // new AdminSettings()
]

interface IAdminModuleRegistry {
  name: string;
  render: React.FC<any>
}

export interface IAdminRender {
  type: string;
  allowedAccesUser?: boolean;
  allowedAccesGroups?: boolean;
  allowedAccessAdminSettings?: boolean;
  allowedAccessAuditLogs?: boolean;
  allowedBusinessProcess?: boolean;
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

export class AdminModuleRegistry {

  registry: { [name: string]: IAdminModuleRegistry } = {};

  constructor() {
    const types = ModuleList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div></div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;;
  }

  static Render: React.FC<IAdminRender> = (props) => {
    if (!props.allowedAccesUser && (props.type === 'users')) {
      return null;
    }
    if (!props.allowedAccesGroups && (props.type === 'groups')) {
      return null;
    }
    if (!props.allowedBusinessProcess && (props.type === 'business-process')) {
      return null;
    }
    const adminModule = new AdminModuleRegistry();
    const Render = adminModule.getModule(props.type);
    return <Render {...props} />
  }

}
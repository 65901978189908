import {
  GET_FORM_STATUSES,
  GET_FORM_STATUSES_RETURN,
  GET_FORM_STATUSES_ERROR,
  GET_FORM_DETAIL,
  GET_FORM_DETAIL_RETURN,
  GET_FORM_DETAIL_ERROR,
  GET_FORM_STATUS,
  GET_FORM_STATUS_RETURN,
  GET_FORM_STATUS_ERROR,
  SAVE_PERMISSION,
  SAVE_PERMISSION_RETURN,
  SAVE_PERMISSION_ERROR,
  PUBLISH_PERMISSION,
  PUBLISH_PERMISSION_RETURN,
  PUBLISH_PERMISSION_ERROR,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_RETURN,
  GET_PERMISSION_LIST_ERROR
} from './constant';
import { IFormStatusListDetailInput } from '../workflow/interface/input/form-status-list';
import { IFormStatusListResponse } from '../workflow/interface/response/form-status-list';
import { IFormDetailsInput, IFormPermissionListDetailInput } from './interface/input/form-details';
import { IFormDetailReturnResponse, IGetPermissionListReturnResponse } from './interface/response/form-detail';
import { IStatusFieldPermissionDetailInput } from './interface/input/field-permission';
import { IFormPermissionSaveDetailInput, IFormPermissionPublishDetailInput } from './interface/input/form-permission-save';
import { IFormPermissionSaveResponse } from './interface/response/form-role-save';

export const getFormStatusesAction = (payload: IFormStatusListDetailInput) => {
  return { type: GET_FORM_STATUSES, ...payload };
}

export const getFormStatusesReturnAction = (payload: IFormStatusListResponse) => {
  return { type: GET_FORM_STATUSES_RETURN, ...payload };
}

export const getFormStatusesErrorAction = (message: string) => {
  return { type: GET_FORM_STATUSES_ERROR, message };
}

export const getFormStatusAction = (payload: IStatusFieldPermissionDetailInput) => {
  return { type: GET_FORM_STATUS, ...payload };
}

export const getFormStatusReturnAction = (payload: IFormStatusListResponse) => {
  return { type: GET_FORM_STATUS_RETURN, ...payload };
}

export const getFormStatusErrorAction = (message: string) => {
  return { type: GET_FORM_STATUS_ERROR, message };
}

export const getFormDetailAction = (payload: IFormDetailsInput) => {
  return { type: GET_FORM_DETAIL, ...payload }
}

export const getFormDetailReturnAction = (payload: IFormDetailReturnResponse) => {
  return { type: GET_FORM_DETAIL_RETURN, ...payload }
}

export const getFormDetailErrorAction = (message: string) => {
  return { type: GET_FORM_DETAIL_ERROR, message }
}

export const saveFormPermissionAction = (payload: IFormPermissionSaveDetailInput) => {
  return { type: SAVE_PERMISSION, ...payload };
}

export const saveFormPermissionReturnAction = (payload: IFormPermissionSaveResponse) => {
  return { type: SAVE_PERMISSION_RETURN, ...payload };
}

export const saveFormPermissionErrorAction = (message: string) => {
  return { type: SAVE_PERMISSION_ERROR, formSaveErrorMessage: message };
}

export const publishPermissionAction = (payload: IFormPermissionPublishDetailInput) => {
  return { type: PUBLISH_PERMISSION, ...payload };
}

export const publishPermissionReturnAction = () => {
  return { type: PUBLISH_PERMISSION_RETURN };
}

export const publishPermissionErrorAction = (message: string) => {
  return { type: PUBLISH_PERMISSION_ERROR, formSaveErrorMessage: message };
}

export const getPermissionListAction = (payload: IFormPermissionListDetailInput) => {
  return { type: GET_PERMISSION_LIST, ...payload };
}

export const getPermissionListReturnAction = (payload: IGetPermissionListReturnResponse) => {
  return { type: GET_PERMISSION_LIST_RETURN, ...payload };
}

export const getPermissionListErrorAction = (message: string) => {
  return { type: GET_PERMISSION_LIST_ERROR, getPermissionListErrorMessage: message };
}
import React, { useState, Suspense, lazy } from 'react';
import { fieldType } from '../../../../../common/field/type';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationMessage } from '../../../../../common/general/confirmation-modal';
import {
  resetMessageResponseAction,
  deleteUserFieldAction,
  resetUserFieldAction
} from '../action';
import { IRootState } from '../../../../../reducers';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import FieldTypeComponent from './field-type';
import { useSetCustomFieldOpen } from './hooks/use-set-custom-field-open';
import { useSetDeleteModalOpen } from './hooks/use-set-delete-modal-open';

const UserCustomField = lazy(() => import('./custom-field'));
interface IFieldTypeProps {
  open: boolean;
  close: any;
  editCustomField?: boolean;
  openCustomFieldModal(id: string, userName: string): void;
  companyId: string;
}

const FieldType: React.FC<IFieldTypeProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const companyId = props.companyId;

  const { deleteUserFieldMessage, datatableList } = useSelector((state: IRootState) => state.user);

  const [customFieldIdForDeletion, setCustomFieldIdForDeletion] = useState('');
  const [customFieldNamerNameForDeletion, setCustomFieldNameForDeletion] = useState('');

  const {
    isOpenDeleteModal,
    setIsOpenDeleteModal
  } = useSetDeleteModalOpen({
    companyId,
    dispatch,
    deleteUserFieldMessage
  });
  const {
    isCustomFieldFormOpen,
    setIsCustomFieldOpen,
    currentFieldConfig,
    setCurrentFormConfig,
    currentFieldData,
    setCurrentFormData
  } = useSetCustomFieldOpen({
    dispatch,
    editCustomField: props.editCustomField
  });

  const openDeleteModal = (id: string, fieldName: string) => {
    setIsOpenDeleteModal(true);
    setCustomFieldIdForDeletion(id);
    setCustomFieldNameForDeletion(fieldName);
  }

  const confirmDelete = () => {
    dispatch(deleteUserFieldAction({ fieldId: customFieldIdForDeletion, company: companyId }));
    history.push('/admin/users');
    closeCustomField();
  }

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
    dispatch(resetMessageResponseAction({}));
  }

  const openCustomFieldForm = (field: any) => {
    dispatch(resetMessageResponseAction({}));
    setIsCustomFieldOpen(true)
    setCurrentFormConfig(field.defaultConfig)
    setCurrentFormData({ ...field.defaultData, new: true, })
  }

  const closeCustomField = () => {
    dispatch(resetUserFieldAction())
    setCurrentFormConfig({});
    setIsCustomFieldOpen(false)
    props.close()
  }

  return <>
    <FieldTypeComponent
      openCustomFieldForm={openCustomFieldForm}
      fieldType={fieldType}
      open={props.open}
    />
    <Suspense fallback={<></>}>
      {isCustomFieldFormOpen &&
        <UserCustomField
          isCustomFieldFormOpen={isCustomFieldFormOpen}
          closeCustomField={closeCustomField}
          currentFieldConfig={currentFieldConfig}
          currentFieldData={currentFieldData}
          openDeleteModal={openDeleteModal}
          companyId={companyId}
          datatable={datatableList}
        />
      }

      <Route exact path={`${match.url}/fields/:fieldId`}>
        <UserCustomField
          isCustomFieldFormOpen={isCustomFieldFormOpen}
          closeCustomField={closeCustomField}
          currentFieldConfig={currentFieldConfig}
          currentFieldData={currentFieldData}
          openDeleteModal={openDeleteModal}
          companyId={companyId}
          datatable={datatableList}
        />
      </Route>
      <ConfirmationMessage
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={closeDeleteModal}
        open={isOpenDeleteModal}
        confirm={confirmDelete}
        cancel={closeDeleteModal}
        headerText="Confirmation"
        detailText={`The custom field '${customFieldNamerNameForDeletion}' and all its records will be deleted. Do you still want to proceed?`}
        type='warning'
      />
    </Suspense>
  </>
}

export default FieldType;
import Axios from 'axios';
import { IFormRelatedField, IRelatedField } from '../../component/admin-module/module/business-process/module/configuration/module/bp-relationship/interface/bp-relationship-field';
import { IFormLabels, IApiFormLabels, IAppliedLabels, IAppliedLabelsPayload, IApiAppliedLabels } from '../../component/admin-module/module/business-process/module/configuration/module/labels/interface/label';
import { IForm } from '../../component/admin-module/module/business-process/module/form/interface/form';
import { IFormDocument } from '../../component/document-module/module/report/interface/form-document';
import { ICustomFieldDetail } from '../custom-field-config/interface/custom-field-detail';
import { checkIfUUID } from './check-uuid-value';
import { SanitizeBPR } from './sanitize-bpr';

export interface IFormAppliedLabels {
  formId: string;
  appliedLabels: IApiAppliedLabels[];
}

export const getLabelsSelectedFields = async (appliedLabels: { formId: string, ids: string }, company: string) => {
  if (company && appliedLabels) {
    const formId = appliedLabels.formId;
    const ids = appliedLabels.ids;
    try {
      const request = await Axios.get(`${company}/documents/fields?formId=${formId}${ids}`, {});
      return request.data.data;
    } catch (_e) {
      return _e;
    }
  }
}

export const structureAppliedLabelFieldIds = (formAppliedLabelList: IFormAppliedLabels[]) => {
  let appliedLabelIds = [] as { formId: string, ids: string[] }[];
  if (formAppliedLabelList.length > 0) {
    formAppliedLabelList.forEach(formAppliedLabels => {
      const { appliedLabels, formId } = { ...formAppliedLabels };
      if (appliedLabels && appliedLabels.length > 0) {
        let fieldIds = [] as string[];
        appliedLabels.forEach(labelData => {
          const { label } = { ...labelData };
          const { config } = { ...label };
          if (config) {
            const { description, header } = { ...config };
            if (header) {
              const id = header.keyPath.replace('fields.', '');
              const exists = fieldIds.includes(id);
              if (!exists) {
                fieldIds.push(id);
              }
            }
            if (description.length > 0) {
              description.forEach(field => {
                const id = field.keyPath.replace('fields.', '');
                const exists = fieldIds.includes(id);
                if (!exists) {
                  fieldIds.push(id);
                }
              });
            }
          }
        });
        appliedLabelIds.push({
          formId: formId,
          ids: fieldIds
        });
      }
    });
  }
  return appliedLabelIds.map(data => {
    const ids = data.ids.filter(id => checkIfUUID(id)).map(e => { return `&ids=${e}` }).join('').toString();
    return {
      formId: data.formId,
      ids: ids
    }
  });
}

export class SanitizeLabels {
  static structureOutgoing = (formLabels: IFormLabels, saveType: string): IApiFormLabels => {
    let headerField: IRelatedField = formLabels.headerField.map((e: IFormRelatedField) => {
      return {
        keyPath: SanitizeBPR.isBPRSystemField(e.id) ? e.id : `fields.${e.id}`,
        showLabel: e.showLabel ? true : false
      }
    })[0];
    let descFields: IRelatedField[] = formLabels.fields.map((e: IFormRelatedField) => {
      return { keyPath: SanitizeBPR.isBPRSystemField(e.id) ? e.id : `fields.${e.id}`, showLabel: e.showLabel ? true : false }
    });
    return {
      id: (saveType === 'update' || saveType === 'delete') ? formLabels.id : undefined,
      name: formLabels.name,
      config: {
        header: headerField,
        description: descFields
      },
      new: formLabels.new,
      deleted: formLabels.deleted,
      edited: formLabels.edited
    };
  }

  static splitFieldKeypathValue = (data: IRelatedField): string => {
    if (data.keyPath.indexOf('fields.') > -1) {
      const keyPath = data.keyPath.split('fields.').filter(key => key !== '' && checkIfUUID(key));
      return keyPath[0];
    }
    return data.keyPath;
  }

  static structureIncomingFormLabel = (formLabel: IApiFormLabels, formFields: ICustomFieldDetail[], formId: string, saveType?: string): IFormLabels => {
    const { header, description } = { ...formLabel.config };
    const headerFieldData = header ? formFields.find(e => e.id === SanitizeLabels.splitFieldKeypathValue(header)) : undefined;
    const headerField = {
      ...headerFieldData,
      name: (headerFieldData && headerFieldData.label) || '',
      showLabel: header ? header.showLabel : undefined
    } as IFormRelatedField;

    const descFields = description.map(fields => {
      const descFieldData = fields ? formFields.find(e => e.id === SanitizeLabels.splitFieldKeypathValue(fields)) : undefined;
      return {
        ...descFieldData,
        name: (descFieldData && descFieldData.label) || '',
        showLabel: fields ? fields.showLabel : undefined
      } as IFormRelatedField;
    });

    return {
      id: formLabel.id,
      name: formLabel.name,
      formId: formId,
      fields: descFields.filter(e => e.type !== 'table'),
      headerField: Object.keys(headerField).length > 0 && headerField.type !== 'table' ? new Array(headerField) : [],
      new: false,
      deleted: saveType === 'delete'
    } as IFormLabels;
  }

  static structureIncoming = (formLabels: IApiFormLabels[], formFields: ICustomFieldDetail[], formId: string, saveType?: string): IFormLabels[] => {
    if (formLabels && formLabels.length > 0) {
      return formLabels.map((labels: IApiFormLabels) => SanitizeLabels.structureIncomingFormLabel(labels, formFields, formId, saveType));
    }
    return [];
  }

  static structureOutgoingAppliedLabel = (formAppliedLabels: IAppliedLabels, saveType: string): IAppliedLabelsPayload => {
    let allowAllComponents = false;
    let allowAllStatuses = formAppliedLabels.status.every(e => e === 'all');
    return {
      id: (saveType === 'update' || saveType === 'delete') ? formAppliedLabels.id : '',
      labelId: formAppliedLabels.label.id,
      config: {
        allowAllComponents: allowAllComponents,
        allowAllStatuses: allowAllStatuses,
        components: formAppliedLabels.component,
        statusIds: formAppliedLabels.status
      },
      enabled: formAppliedLabels.enabled,
      new: formAppliedLabels.new,
      edited: formAppliedLabels.edited,
      deleted: formAppliedLabels.deleted
    }
  }

  static structureIncomingAppliedLabels = (appliedFormLabels: IApiAppliedLabels[], formFields: ICustomFieldDetail[], formId: string): IAppliedLabels[] => {
    if (appliedFormLabels && appliedFormLabels.length > 0) {
      return appliedFormLabels.map(appliedLabel => {
        return {
          id: appliedLabel.id,
          component: appliedLabel.config.components,
          label: SanitizeLabels.structureIncomingFormLabel(appliedLabel.label, formFields, formId),
          status: appliedLabel.config.statusIds,
          enabled: appliedLabel.enabled,
          creatorId: appliedLabel.creatorId,
          new: false
        };
      });
    }
    return [];
  }
  static getBPAppliedLabel = async (form: IForm, company: string, screen: string) => {
    if (form) {
      try {
        const request = await Axios.get(`${company}/forms/${form.id}/appliedLabels?draft=false`, {});
        const reportsLabel = request.data.data.filter((label: IApiAppliedLabels) => {
          const { config, enabled } = { ...label };
          return config.components.includes(screen) && enabled;
        })
        return {
          formId: form.id,
          appliedLabels: reportsLabel
        }
      } catch (_e) {
        return _e;
      }
    }
  }
  static setDocumentAppliedLabels = (tasks: IFormDocument, formAppliedLabels: IFormAppliedLabels[]): IApiFormLabels => {
    let documentAppliedLabels = {} as IApiFormLabels;
    const tempFormLabels = formAppliedLabels.find(labels => labels.formId === (tasks.form && tasks.form.id));
    if (tempFormLabels) {
      const { appliedLabels } = { ...tempFormLabels };
      if (appliedLabels.length > 0) {
        const isAllStatus = appliedLabels.filter(label => label.config.allowAllStatuses);
        if (isAllStatus.length > 0) {
          const { label } = { ...isAllStatus[0] };
          documentAppliedLabels = label;
        } else {
          const documentStatus = tasks.statusId;
          const labelPerStatus = appliedLabels.filter(label => label.config.statusIds.includes(documentStatus || ''));
          if (labelPerStatus.length > 0) {
            const { label } = { ...labelPerStatus[0] };
            documentAppliedLabels = label;
          }
        }
      }
    }
    return documentAppliedLabels;
  }
}
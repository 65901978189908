import React, { useState, useEffect } from 'react';
import Dropzone, { useDropzone } from "react-dropzone";
import { Dimmer, Dropdown, Icon, Loader } from 'semantic-ui-react-bpm';
import { icons } from '../icons';
import prettyBytes from 'pretty-bytes';
import Axios, { Method } from 'axios';
import ConfigService from '../config';
import ActionMessage from './action-message';
import { useDispatch, useSelector } from 'react-redux';
import { setDownloadRequestsAction } from '../../component/main/action';
import { useRouteMatch } from 'react-router-dom';
import { datatableRecordLimit } from '../../component/document-module/module/datatable/constant';
import { IRootState } from '../../reducers';
import { getDataTableRecordsAction } from '../../component/admin-module/module/data-tables/action';
import SVGwrapper from '../icons/svg/svg-wrapper';
import { saveDocumentErrorAction } from '../../component/document-module/module/form/action';
import { documentFormActionMessage } from '../../component/document-module/module/form/constant';
import useDeviceDetect from './device-detect';
import DropdownPopperOption from './dropdown-popper-option';
import { IOption } from './download-popup';

export interface ITemplate {
  templateName: string;
  templateURL: string;
  templateURLUpdate?: {
    url: string;
    data: any,
    headers?: any
  };
}

interface IFileUploadModal {
  open: boolean;
  close(): void;
  cancel(): void;
  template: ITemplate;
  onUpload(file: any, data: any): void;
  urlGet?: string;
  urlMethod?: Method;
  statusComponentProps?: {
    options: IOption[],
    value: string;
    setSelectedStatus(value: string): void;
  };
  page?: string;
}

const FileUploadModal: React.FC<IFileUploadModal> = (props) => {
  const axiosURL = ConfigService.loadConfig().general.apiBaseUrl + props.template.templateURL;
  const defaultError = { status: false };

  const dispatch = useDispatch();
  const { width } = useDeviceDetect();
  const { acceptedFiles, getInputProps } = useDropzone();
  const match: { params: { datatableId: string } } = useRouteMatch();

  const { company } = useSelector((state: IRootState) => state.auth);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({} as any | null);
  const [error, setError] = useState(defaultError);

  const setErrorMessage = () => {
    let errorMessage = `Unable to upload: `;
    if (error && Object.keys(error).length) {
      Object.keys(error).forEach((key, idx) => {
        if (Object.keys(error).length > 2) {
          if (idx < (Object.keys(error).length - 1)) {
            errorMessage += `'${key}', `
          } else {
            errorMessage += `and '${key}'`
          }
        } else {
          if (idx < (Object.keys(error).length - 1)) {
            errorMessage += `'${key}' and `
          } else {
            errorMessage += `'${key}'`
          }
        }
      });
    }
    errorMessage += ` ${Object.keys(error).length > 1 ? 'fields' : 'field'} requires to have a value.`;
    return errorMessage;
  }

  const isValidUpload = () => {
    let currentError = { ...error };
    if (props.statusComponentProps && !props.statusComponentProps.value) {
      currentError.status = true;
    }
    setError(currentError);
    return !currentError.status;
  }

  const onUpload = async (file: any) => {
    if (!isValidUpload()) {
      props.onUpload(false, setErrorMessage());
      return;
    }

    setLoading(true);
    const uploadURL = axiosURL.indexOf('&getTemplate=true') > -1 ? axiosURL.split('&getTemplate=true')[0] : axiosURL;
    try {
      const upload = await Axios.post(uploadURL, file, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/octet-stream'
        }
      });

      const result = props.urlGet ? await Axios({
        method: props.urlMethod,
        url: props.urlGet,
        data: props.template.templateURLUpdate ? props.template.templateURLUpdate.data : undefined
      }) : { data: {} };

      const offset = Math.ceil((result.data.pagination.total - datatableRecordLimit) / datatableRecordLimit) * datatableRecordLimit;
      if (props.page === 'end-user-datatables') {
        dispatch(getDataTableRecordsAction({ company: company, dataTableId: match.params.datatableId, limit: datatableRecordLimit, offset: offset }));
      }

      props.onUpload(upload.data.success, { ...result.data, pagination: { ...result.data.pagination, offset } });
      setLoading(false);
      props.close();
      removeUploadFile(file);
    } catch (e) {
      const error = { ...e as { response: { data: { message: string } }, message: string } };
      const errorMessage = (error && error.response && error.response.data && error.response.data.message)
        || (error && error.message);
      if (errorMessage) {
        setLoading(false);
        saveDocumentErrorAction(errorMessage);
        props.onUpload(false, errorMessage);
        props.close();
        removeUploadFile(file);
      }

    }
  }

  const downloadTemplate = () => {
    const templateURL = props.template.templateURLUpdate ? props.template.templateURLUpdate.url : props.template.templateURL;
    const axiosURL = ConfigService.loadConfig().general.apiBaseUrl + templateURL;

    dispatch(setDownloadRequestsAction({
      method: 'post',
      url: axiosURL,
      data: props.template.templateURLUpdate ? props.template.templateURLUpdate.data : {},
      downloadName: props.template.templateName,
      headers: props.template.templateURLUpdate && props.template.templateURLUpdate.headers
        ? props.template.templateURLUpdate.headers : {
          'Content-Disposition': "attachment; filename=template.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    }));
  }

  const iconForFile = (file: any) => {
    if (file.type.startsWith('image')) {
      return URL.createObjectURL(file);
    } else {
      return icons.black.paperclip;
    }
  }

  const removeUploadFile = (file: any) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
    setFile({});
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line
  }, [acceptedFiles[0]])

  return <React.Fragment>
    <ActionMessage messages={documentFormActionMessage} />
    {props.open &&
      <div id='file-upload-modal-dimmer' className={`ui page modals dimmer transition visible active ${props.page}`}
        style={props.statusComponentProps ? { zIndex: 20 } : { zIndex: 200 }}>
        <div id="file-upload-modal" className={`ui modal transition visible active ${width <= 480 ? 'mobile' : ''}`}>
          {
            (loading) &&
            <Dimmer active inverted>
              <Loader active content='Loading' />
            </Dimmer>
          }
          <div className="borderless header">
            <span className="header-label">Upload File</span>
          </div>
          <div className="content">
            <Dropzone
              multiple={false}
              accept=".jpg,.png,.pdf,.doc,.docx,.xlsx,.pptx,.ppt,.xls"
              onDrop={acceptedFiles => {
                if (acceptedFiles.length > 0) {
                  setFile(acceptedFiles[0])
                }
              }}
              noClick
            >
              {({ getRootProps }) => (
                <section className='dropzone-main-container'>
                  <div className='dropzone-container' {...getRootProps()}>
                    {Object.keys(file).length > 0 ?
                      <span className='file-selected'>
                        <img className='file-icon' src={iconForFile(file)} alt="" />
                        <label className='file-name'>{file.name}</label>
                        <p className='file-size'> ({prettyBytes(file.size)})</p>
                        <i className={`icon close`} onClick={() =>
                          removeUploadFile(file)
                        } />
                      </span>
                      :
                      <div className='file-icon-upload'> <SVGwrapper iconName='uploadCloud' width='75' height='75' color='#000' /> </div>
                    }
                    <span className='file-upload-label'>Drag and drop your files anywhere or</span>
                    <input id='file-upload' {...getInputProps()} />
                    <label htmlFor='file-upload'>
                      <div className='file-upload-btn'>Upload a file</div>
                    </label>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="actions borderless">
            <div onClick={() => downloadTemplate()} className='btn-download-template'>Download Template</div>
            {props.statusComponentProps &&
              <div className={`form-status-field`}>
                <Dropdown
                  selectOnBlur={false}
                  clearable
                  search
                  options={props.statusComponentProps.options}
                  value={props.statusComponentProps.value}
                  placeholder='Status'
                  onChange={(e, target: any) => {
                    if (props.statusComponentProps) {
                      props.statusComponentProps.setSelectedStatus(target.value);
                    }
                  }}
                  selection
                  icon={<Icon name='angle down' />}
                  popperContainer={DropdownPopperOption}
                  className={`${error.status ? 'error' : ''}`}
                />
              </div>}
            <div onClick={() => {
              removeUploadFile(file);
              props.close()
            }} className='btn-cancel'>Cancel</div>
            {/* <div className={`btn-validate ${Object.keys(file).length > 0 ? '' : 'disabled'}`}>Validate</div>    */}
            <div className={`btn-upload ${Object.keys(file).length > 0 ? '' : 'disabled'}`} onClick={() => {
              if (Object.keys(file).length > 0) onUpload(file)
            }}
            >Upload</div>
          </div>
        </div>
      </div>
    }
  </React.Fragment>
}

export default FileUploadModal;
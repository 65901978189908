import { useEffect } from 'react';
import { UploadedFile } from '../../../../../../../common/api-request/upload';
import { defineEditorHeight } from '../utils/define-editor-height';

interface IProps {
  uploadedFileList: UploadedFile[];
  removeUploadedFile(file: UploadedFile): void;
  mobile: boolean;
  textRef: any;
  actionType: "create" | "edit";
  setAttachmentElement: () => void;
}

export const useSetEditorHeightWhenUploadedFile = ({
  uploadedFileList,
  removeUploadedFile,
  mobile,
  textRef,
  actionType,
  setAttachmentElement
}: IProps): void => {
  useEffect(() => {
    const editorRef = { ...textRef.current };
    if (editorRef && uploadedFileList.length > 0) {
      const editor = editorRef.editor;
      if (editor && editor.selection) {
        const contentRect: DOMRect = editor.selection.getNode().getBoundingClientRect();
        const lineCount = Math.round(contentRect.height / 19);
        defineEditorHeight({
          actionType: actionType,
          hasUploadedFile: uploadedFileList.length > 0,
          lineCount,
          contentHeight: contentRect.height,
          mobile: mobile
        })
      }
    }
    setAttachmentElement();
    // eslint-disable-next-line
  }, [uploadedFileList, mobile]);
};
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';

export const GET_USER_LIST = 'app/users/GET_USER_LIST';
export const GET_USER_LIST_RETURN = 'app/users/GET_USER_LIST_RETURN';
export const GET_USER_LIST_ERROR = 'app/users/GET_USER_LIST_ERROR';
export const GET_USER_FIELD_LIST = 'app/users/GET_USER_FIELD_LIST';
export const GET_USER_FIELD_LIST_RETURN = 'app/users/GET_USER_FIELD_LIST_RETURN';
export const GET_USER_FIELD_LIST_ERROR = 'app/users/GET_USER_FIELD_LIST_ERROR';
export const DELETE_USER = 'app/user/DELETE_USER';
export const DELETE_USER_RETURN = 'app/user/DELETE_USER_RETURN';
export const DELETE_USER_ERROR = 'app/user/DELETE_USER_ERROR';
export const GET_USER_FIELD_PERMISSION = 'app/user/GET_USER_FIELD_PERMISSION';
export const GET_USER_FIELD_PERMISSION_RETURN = 'app/user/GET_USER_FIELD_PERMISSION_RETURN';
export const GET_USER_PROFILE = 'app/user/GET_USER_PROFILE';
export const GET_USER_PROFILE_RETURN = 'app/user/GET_USER_PROFILE_RETURN';
export const CREATE_USER = 'app/user/CREATE_USER';
export const CREATE_USER_RETURN = 'app/user/CREATE_USER_RETURN';
export const CREATE_USER_ERROR = 'app/user/CREATE_USER_ERROR';
export const GET_USER_DETAIL = 'app/user/GET_USER_DETAIL';
export const GET_USER_DETAIL_ERROR = 'app/user/GET_USER_DETAIL_ERROR';
export const GET_USER_DETAIL_RETURN = 'app/user/GET_USER_DETAIL_RETURN';
export const UPDATE_USER = 'app/user/UPDATE_USER';
export const UPDATE_USER_RETURN = 'app/user/UPDATE_USER_RETURN';
export const UPDATE_USER_ERROR = 'app/user/UPDATE_USER_ERROR';
export const UPDATE_USER_STATUS = 'app/user/UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_RETURN = 'app/user/UPDATE_USER_STATUS_RETURN';
export const UPDATE_USER_STATUS_ERROR = 'app/user/UPDATE_USER_STATUS_ERROR';
export const GET_USER_FIELD_DETAIL = 'app/user/GET_USER_FIELD_DETAIL';
export const GET_USER_FIELD_DETAIL_RETURN = 'app/user/GET_USER_FIELD_DETAIL_RETURN';
export const GET_USER_FIELD_DETAIL_ERROR = 'app/user/GET_USER_FIELD_DETAIL_ERROR';
export const GET_ACTIVE_USER_LIST = 'app/user/GET_ACTIVE_USER_LIST';
export const GET_ACTIVE_USER_LIST_RETURN = 'app/user/GET_ACTIVE_USER_LIST_RETURN';
export const RESET_MESSAGE_RESPONSE = 'app/user/RESET_MESSAGE_RESPONSE';
export const RESET_USER_FIELD_STATE = 'app/user/RESET_USER_FIELD_STATE';
export const RESET_USER_DETAIL = 'app/user/RESET_USER_DETAIL';
export const UPDATE_USER_FIELD = 'app/user/UPDATE_USER_FIELD';
export const UPDATE_USER_FIELD_RETURN = 'app/user/UPDATE_USER_FIELD_RETURN';
export const UPDATE_USER_FIELD_ERROR = 'app/user/UPDATE_USER_FIELD_ERROR';
export const CREATE_USER_FIELD = 'app/user/CREATE_USER_FIELD';
export const CREATE_USER_FIELD_RETURN = 'app/user/CREATE_USER_FIELD_RETURN';
export const CREATE_USER_FIELD_ERROR = 'app/user/CREATE_USER_FIELD_ERROR';
export const RESET_USER_FIELD_MESSAGE_RESPONSE = 'app/user/RESET_USER_FIELD_MESSAGE_RESPONSE';
export const DELETE_USER_FIELD = 'app/user/DELETE_USER_FIELD';
export const DELETE_USER_FIELD_RETURN = 'app/user/DELETE_USER_FIELD_RETURN';
export const DELETE_USER_FIELD_ERROR = 'app/user/DELETE_USER_FIELD_ERROR';
export const FILTER_USERS = 'app/user/FILTER_USERS';
export const FILTER_USERS_RETURN = 'app/user/FILTER_USERS_RETURN';
export const FILTER_USERS_ERROR = 'app/user/FILTER_USERS_ERROR';
export const CLEAR_UPDATE_USER_SUCCESS = 'app/user/CLEAR_UPDATE_USER_SUCCESS';
export const CLEAR_CREATE_USER_SUCCESS = 'app/user/CLEAR_CREATE_USER_SUCCESS';
export const CLEAR_DELETE_USER_SUCCESS = 'app/user/CLEAR_DELETE_USER_SUCCESS';
export const CLEAR_CREATE_CUSTOM_FIELD_SUCCESS = 'app/user/CLEAR_CREATE_CUSTOM_FIELD_SUCCESS';
export const CLEAR_UPDATE_USER_STATUS_SUCCESS = 'app/user/CLEAR_UPDATE_USER_STATUS_SUCCESS';
export const CLEAR_UPDATE_USER_STATUS_ERROR = 'app/user/CLEAR_UPDATE_USER_STATUS_ERROR';
export const CLEAR_UPDATE_USER_FIELD_SUCCESS = 'app/user/CLEAR_UPDATE_USER_FIELD_SUCCESS';
export const CLEAR_DELETE_USER_FIELD_SUCCESS = 'app/user/CLEAR_DELETE_USER_FIELD_SUCCESS';
export const CLEAR_RESET_USER_PASSWORD_SUCCESS = 'app/user/CLEAR_RESET_USER_PASSWORD_SUCCESS';
export const CLEAR_UPDATE_USER_ERROR = 'app/user/CLEAR_UPDATE_USER_ERROR';
export const CLEAR_CREATE_USER_ERROR = 'app/user/CLEAR_CREATE_USER_ERROR';
export const CLEAR_CREATE_USER_FIELD_ERROR = 'app/custom-field/CLEAR_CREATE_USER_FIELD_ERROR';
export const CLEAR_UPDATE_USER_FIELD_ERROR = 'app/custom-field/CLEAR_UPDATE_USER_FIELD_ERROR';
export const CLEAR_RESET_USER_PASSWORD_ERROR = 'app/user/CLEAR_RESET_USER_PASSWORD_ERROR';
export const RESET_USER_PASSWORD = 'app/user/RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_RETURN = 'app/user/RESET_USER_PASSWORD_RETURN';
export const RESET_USER_PASSWORD_ERROR = 'app/user/RESET_USER_PASSWORD_ERROR';
export const UPLOAD_USERLIST_SUCCESS_MESSAGE = 'app/user/UPLOAD_USERLIST_SUCCESS_MESSAGE';
export const UPLOAD_USERLIST_ERROR_MESSAGE = 'app/user/UPLOAD_USERLIST_ERROR_MESSAGE';
export const CLEAR_UPLOAD_USERLIST_MESSAGE = 'app/user/CLEAR_UPLOAD_USERLIST_MESSAGE';
export const GET_DATATABLE_LIST = 'app/user/GET_DATATABLE_LIST';
export const GET_DATATABLE_LIST_RETURN = 'app/user/GET_DATATABLE_LIST_RETURN';

export const staticFieldLabel = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  phone: 'Phone'
}

export const userListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_USER_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'updateUserMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_USER_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'deleteUserMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_CREATE_CUSTOM_FIELD_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'createUserFieldMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_USER_STATUS_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'forupdateUserStatusMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_USER_FIELD_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'updateUserFieldMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_USER_FIELD_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'deleteUserFieldMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_USER_STATUS_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'forupdateUserStatusMessageError',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_RESET_USER_PASSWORD_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'resetUserPassMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_UPLOAD_USERLIST_MESSAGE,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'uploadUserListMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_UPLOAD_USERLIST_MESSAGE,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'uploadUserListErrorMessage',
    hasCloseIcon: true
  }
]

export const userListActionMessageAddNew: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_USER_SUCCESS,
    type: 'success',
    reducerContainer: 'user',
    reducerItem: 'createUserMessage',
    hasCloseIcon: true,
  },
]

export const userFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_USER_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'updateUserMessageError',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_CREATE_USER_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'createUserMessageError',
    hasCloseIcon: true,
  },
]

export const resetPassFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_RESET_USER_PASSWORD_ERROR,
    type: 'negative',
    reducerContainer: 'user',
    reducerItem: 'resetUserPassError',
    hasCloseIcon: true
  }
]

export const userListRecordLimit = 50;
import { ISenderDetail } from '../../interface/sender-info';

export const commentSenderUserInfoData = (senderUserInfo: ISenderDetail, userColor: { [id: string]: string }) => {
  return {
    firstName: senderUserInfo?.firstName ? senderUserInfo.firstName : '',
    lastName: senderUserInfo?.lastName ? senderUserInfo.lastName : '',
    firstLetter: senderUserInfo?.firstName ? senderUserInfo.firstName.substr(0, 1).toUpperCase() : '',
    lastLetter: senderUserInfo?.lastName ? senderUserInfo.lastName.substr(0, 1).toUpperCase() : '',
    userNameBg: { backgroundColor: userColor[senderUserInfo?.id] || 'noId' || '#F3F3F3' }
  }
}
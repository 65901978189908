import { UploadedFile } from '../../../../../../../../../../common/api-request/upload';
import { getFileExtension } from '../../../../../../../../../../common/utils/get-file-extension';

export const getFileType = (file: UploadedFile) => {
  const supportedImage = ['jpg', 'gif', 'png', 'jpeg', 'ico', 'webp'];
  const archives = ['rar', 'zip', '7z', 'tar'];
  const office = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
  const ext = getFileExtension(file.name).toLowerCase();

  return {
    isImage: file.type && file.type.startsWith('image'),
    isSupportedImage: supportedImage.includes(ext),
    isVideo: file.type && file.type.startsWith('video'),
    isAudio: file.type && file.type.startsWith('audio'),
    isText: (file.type && file.type.startsWith('text')),
    isArchive: archives.includes(ext),
    isOffice: office.includes(ext),
    isPDF: (ext === 'pdf'),
    isDoc: !file.type,
  };
}
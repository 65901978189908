import { IComment } from '../../document-module/module/form/interface/comment';
import { ISenderInfo } from '../../document-module/module/form/interface/sender-info';
import {
  FILTER_ADMIN_HISTORY,
  FILTER_ADMIN_HISTORY_ERROR,
  FILTER_ADMIN_HISTORY_RETURN, GET_ADMIN_HISTORY_LOGS,
  GET_ADMIN_HISTORY_LOGS_ERROR,
  GET_ADMIN_HISTORY_LOGS_RETURN
} from './constant';
import { IHistoryGetDetailInput } from './interface/input/history-get';

export interface IAdminHistoryReducer {
  adminHistoryLoading: boolean;
  adminHistoryError: string;
  adminHistoryLogs: IComment[];
  adminHistorySenders: ISenderInfo[];
  adminHistoryArgs: IHistoryGetDetailInput;
}

const initialState: IAdminHistoryReducer = {
  adminHistoryLoading: false,
  adminHistoryError: '',
  adminHistoryLogs: [],
  adminHistorySenders: [],
  adminHistoryArgs: {} as IHistoryGetDetailInput
};

const adminHistoryReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_ADMIN_HISTORY_LOGS:
      newState.adminHistoryLoading = true;
      return newState;

    case GET_ADMIN_HISTORY_LOGS_RETURN:
      newState.adminHistoryLoading = false;
      newState.adminHistoryLogs = action.adminHistoryLogs;
      newState.adminHistorySenders = action.adminHistorySenders;
      newState.adminHistoryArgs = action.adminHistoryArgs;
      return newState;

    case GET_ADMIN_HISTORY_LOGS_ERROR:
      newState.adminHistoryLoading = false;
      newState.adminHistoryError = action.message;
      return newState;

    case FILTER_ADMIN_HISTORY:
      newState.adminHistoryLoading = true;
      return newState;

    case FILTER_ADMIN_HISTORY_RETURN:
      newState.adminHistoryLoading = false;
      newState.adminHistoryLogs = action.adminHistoryLogs;
      return newState;

    case FILTER_ADMIN_HISTORY_ERROR:
      newState.adminHistoryLoading = false;
      newState.adminHistoryError = action.message;
      return newState;

    default:
      return state;
  }
};

export default adminHistoryReducer;

import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { Loader, Segment } from 'semantic-ui-react-bpm';
import ActionMessage from '../../../../common/general/action-message';
import { ConfirmationMessage } from '../../../../common/general/confirmation-modal';
import { currentRouteAction, setSubRouteAction } from '../../../main/action';
import { IAdminRender } from '../module-registry';
import {
  cancelDeleteDatatableAction,
  cancelDeleteDataTableRecordAction,
  clearDeleteDatatableMessage,
  confirmDeleteDatatableAction,
  confirmDeleteDataTableRecordAction,
  deleteDatatableAction,
  deleteDataTableRecordAction,
  getDataTableListAction,
  resetDataTableMessageAction,
  resetDataTableRecordEditable
} from './action';
import { dataTableLimit, dataTableListActionMessage, dataTableRecordListActionMessage } from './constant';
import DataTableForm from './data-table-form';
import { DataTableRecords } from './records';
import { DataTablesTableList } from './table';
import { DataTableFilter } from './filter';

class DataTableModule {

  name = 'data-tables'

  render: React.FC<IAdminRender> = (props) => {
    let filterTimeout = setTimeout(() => { }, 1000);
    let match = useRouteMatch();

    const location = useLocation();
    const dispatch = useDispatch();

    const dataTable = useSelector((state: any) => state.dataTable);
    const {
      dataTableList,
      isOpenDeleteConfirmationModal,
      confirmDeletetionLoading,
      forDeleteDatatableId,
      forDeleteDataTableRecordId,
      configmDeletionErrorMessage,
      forDeleteDatatableName,
    } = useSelector((state: any) => state.dataTable);
    const { company } = useSelector((state: any) => state.auth);
    const { isOpenAdminChatbox } = useSelector((state: any) => state.main);

    const [currentOffset, setCurrentOffset] = useState(0);

    const openDeleteConfirmationModal = (datatableId: string, datatableName: string) => {
      dispatch(deleteDatatableAction({ datatableId: datatableId, datatableName: datatableName }));
    }

    const cancelDeleteDatatable = () => {
      dispatch(cancelDeleteDatatableAction());
    }

    const confirmDeleteDatatable = () => {
      dispatch(confirmDeleteDatatableAction({ dataTableId: forDeleteDatatableId, company }));
    }

    const openDeleteRecordConfirmationModal = (recordId: string, dataTableId: string) => {
      dispatch(deleteDataTableRecordAction({ recordId, dataTableId }));
    }

    const confirmDeleteDataTableRecord = () => {
      dispatch(confirmDeleteDataTableRecordAction({
        recordId: forDeleteDataTableRecordId,
        company,
        dataTableId: forDeleteDatatableId
      }));
    }

    const cancelDeleteDataTableRecord = () => {
      dispatch(cancelDeleteDataTableRecordAction());
    }

    const triggerFilter = (limit?: number, offset?: number, search?: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        dispatch(getDataTableListAction({
          company: company,
          limit: limit || dataTableLimit,
          offset: offset || 0,
          name: search
        }));
      }, 1000);
      setCurrentOffset(offset || 0);
    }

    useEffect(() => {
      if (company) {
        dispatch(getDataTableListAction({ company: company, limit: dataTableLimit, offset: 0 }));
      }
    }, [dispatch, company])

    const openDataTableRecords = (id: string) => {
      dispatch(resetDataTableMessageAction({}));
      dispatch(resetDataTableRecordEditable());
    }

    useEffect(() => {
      if (location.pathname === '/admin/data-tables') {
        dispatch(setSubRouteAction({
          mainRoute: 'admin',
          subRoute: 'data-tables'
        }))
        dispatch(currentRouteAction(this.name))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
      <Route exact path={`${match.url}`}>
        <ActionMessage messages={dataTableListActionMessage} />
        <Segment className='borderless data-table-list-container'>
          <Suspense fallback={<Loader active inline />}>
            <DataTableFilter.Render
              isOpenChatbox={isOpenAdminChatbox}
              allowedAccessAuditLogs={props.allowedAccessAuditLogs ? props.allowedAccessAuditLogs : false}
            />
            <DataTablesTableList.Render
              dataTable={dataTableList}
              openDeleteConfirmationModal={openDeleteConfirmationModal}
              openDataTableRecords={openDataTableRecords}
              triggerFilter={triggerFilter}
              company={company}
              currentOffset={currentOffset}
            />
            <ConfirmationMessage
              clearErrorMessage={() => dispatch(clearDeleteDatatableMessage())}
              errorMessage={configmDeletionErrorMessage}
              confirmButtonName={'Confirm'}
              confirmButtonColor={'green'}
              cancelButtonColor={'grey'}
              close={cancelDeleteDatatable}
              open={isOpenDeleteConfirmationModal}
              confirm={confirmDeleteDatatable}
              cancel={cancelDeleteDatatable}
              headerText="Confirmation"
              detailText={`The data table '${forDeleteDatatableName}' and all its records will be deleted. Do you still want to proceed?`}
              loading={confirmDeletetionLoading}
              type='warning'
            />
          </Suspense>
        </Segment>
      </Route>
      <Route exact path={`${match.url}/:dataTableId`}>
        <DataTableForm dataTable={dataTableList} hasPageChangeRef={props.hasPageChangeRef} />
      </Route>
      <Route exact path={`${match.url}/records/:dataTableId`}>
        <ActionMessage messages={dataTableRecordListActionMessage} />
        <Segment className='borderless data-tables-record-container'>
          <DataTableRecords.Render
            dataRecordsList={dataTable.dataTableList}
            openDeleteRecordConfirmationModal={openDeleteRecordConfirmationModal}
            hasPageChangeRef={props.hasPageChangeRef}
          />
          <ConfirmationMessage
            clearErrorMessage={() => dispatch(clearDeleteDatatableMessage())}
            errorMessage={configmDeletionErrorMessage}
            confirmButtonName={'Confirm'}
            confirmButtonColor={'green'}
            cancelButtonColor={'grey'}
            close={cancelDeleteDataTableRecord}
            open={isOpenDeleteConfirmationModal}
            confirm={confirmDeleteDataTableRecord}
            cancel={cancelDeleteDataTableRecord}
            headerText="Confirmation"
            detailText={`This will delete this data table record. Are you sure you want to continue?`}
            loading={confirmDeletetionLoading}
            type='warning'
          />
        </Segment>
      </Route>
    </>
    );
  }
}


export default DataTableModule;

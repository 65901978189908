import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import { getFormFieldListAction } from '../custom-filter/action';
import GanttChartFilter from './filter';

interface IGanttChartContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const GanttChartContainer: React.FC<IGanttChartContainerProps> = (props) => {

  const dispatch = useDispatch();
  const match: { params: { formId: string } } = useRouteMatch();
  const { company } = useSelector((state: IRootState) => state.auth);
  const { fieldList } = useSelector((state: IRootState) => state.customFilter);

  useEffect(() => {
    if (company && match.params.formId) {
      dispatch(getFormFieldListAction({ company: company, formId: match.params.formId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, match.params.formId])

  return <div className='gantt-chart-container'>
    <GanttChartFilter fieldList={fieldList} />
  </div>
}

export default GanttChartContainer;
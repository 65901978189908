class NumberOperatorOption {

  name = 'number';

  option = [
    { key: 0, value: 'is-empty', text: 'Is empty' },
    { key: 1, value: 'is-not-empty', text: 'Is not empty' },
    { key: 2, value: 'more', text: '>' },
    { key: 3, value: 'more-or-equal', text: '>=' },
    { key: 4, value: 'equal', text: '=' },
    { key: 5, value: 'less', text: '<' },
    { key: 6, value: 'less-or-equal', text: '<=' },
    { key: 7, value: 'not-equal', text: '<>' },
    { key: 8, value: 'is-between', text: 'Is Between' },
    { key: 9, value: 'is-not-between', text: 'Is Not Between' },
    { key: 10, value: 'starts-with', text: 'Starts With' },
    { key: 11, value: 'ends-with', text: 'Ends With' },
  ]

}

export default NumberOperatorOption;
import { IApiDisplayData, IDisplayData, IDisplayDataConfig, IDisplayDataFields } from '../../component/admin-module/module/business-process/module/configuration/module/display-data/interface/display-data';
import { IDisplayDataConfigAssignments } from '../../component/admin-module/module/business-process/module/configuration/module/display-data/interface/display-data-assignments';

const slots: { [name: string]: string[] } = {
  header: ['h1', 'h2'],
  body: ['b1', 'b2', 'b3', 'b4'],
  meta: ['meta_1', 'meta_2', 'meta_3']
}

export class SanitizeDisplayData {

  static configsApiMeta = (configs: IDisplayDataConfig[]): IDisplayDataFields => {
    const showLabel = configs.filter(e => e.showLabel === true).length > 0;
    const tempConfig = ['', '', ''];
    configs.forEach(conf => {
      const slotIndex = conf.slot.split('_')[1];
      tempConfig[parseInt(slotIndex) - 1] = conf.keyPath;
    });
    return {
      showLabel,
      config: tempConfig
    };
  }

  static groupByKey(displayData: IDisplayDataConfig[]) {
    return displayData.reduce((data: any, { slot, keyPath }: any) => {
      const newSlot = slot.split('_')[0];
      if (!data[newSlot]) data[newSlot] = [];
      data[newSlot].push(keyPath);
      return data;
    }, {});
  }


  static configsApiToDisplayData = (configs: IDisplayDataConfig[]) => {
    const showLabel = configs.filter(e => e.showLabel === true).length > 0;
    const data = SanitizeDisplayData.groupByKey(configs);
    const fields = [] as IDisplayDataFields[];
    Object.keys(data).forEach(name => {
      fields.push({
        showLabel,
        config: data[name]
      })
    });
    return fields;
  }

  static configsToApiDisplayData = (configs: IDisplayDataFields[], name: 'header' | 'body' | 'meta') => {
    const dataConfig = [] as IDisplayDataConfig[];
    configs.forEach((field, index) => {
      const slot = slots[name][index];
      field.config.forEach((config, idx) => {
        dataConfig.push({
          slot: name === 'meta' ? slots[name][idx] : `${slot}_${idx + 1}`,
          keyPath: config,
          showLabel: field.showLabel && idx === 0 ? true : false
        } as IDisplayDataConfig)
      });
    });
    return dataConfig;
  }

  static structureOutgoing = (displayDataList: IDisplayData[]): IApiDisplayData[] => {
    return displayDataList.map(displayData => {
      const headerConfigs = SanitizeDisplayData.configsToApiDisplayData(displayData.header, 'header');
      const bodyConfigs = SanitizeDisplayData.configsToApiDisplayData(displayData.content, 'body');
      const thumbnail = displayData.thumbnail ? [displayData.thumbnail] : [];
      const bpIcon = displayData.bpIcon ? [displayData.bpIcon] : [];
      const metaConfigs = displayData.meta ? displayData.meta : [];

      return {
        id: displayData.id,
        name: displayData.name,
        config: [
          ...headerConfigs,
          ...bodyConfigs,
          ...metaConfigs,
          ...thumbnail,
          ...bpIcon
        ]
      } as IApiDisplayData;
    })
  }

  static structureIncoming = (displayDataList: IApiDisplayData[]): IDisplayData[] => {
    return displayDataList.map(displayData => {
      const header = displayData.config.filter(e => e.slot.startsWith('h'));
      const content = displayData.config.filter(e => e.slot.startsWith('b') && !e.slot.startsWith('bp'));
      const meta = displayData.config.filter(e => e.slot.startsWith('meta'));
      const bpIcon = displayData.config.filter(e => e.slot === 'bp_icon');
      const thumbnail = displayData.config.filter(e => e.slot === 'thumb');

      return {
        id: displayData.id,
        name: displayData.name,
        header: SanitizeDisplayData.configsApiToDisplayData(header),
        content: SanitizeDisplayData.configsApiToDisplayData(content),
        meta: meta.length > 0 ? meta : undefined,
        bpIcon: bpIcon.length > 0 ? bpIcon[0] : undefined,
        thumbnail: thumbnail.length > 0 ? thumbnail[0] : undefined,
      } as IDisplayData;
    })
  }

  static structureOutgoingDisplayDataAssignments = (displayDataAssignments: IDisplayDataConfigAssignments[]) => {
    return displayDataAssignments.map(assignment => {
      const statusId = assignment.statusId === 'all' || !assignment.statusId ? null : assignment.statusId;
      const component = assignment.component === 'all' || !assignment.component ? null : assignment.component;

      return {
        formId: assignment.formId,
        configId: assignment.configId,
        component: component,
        statusId: statusId
      };
    })
  }

  static structureIncomingDisplayDataAssignments = (displayDataAssignments: IDisplayDataConfigAssignments[], displayDataList: IDisplayData[]) => {
    return displayDataAssignments.map(data => {
      const content = displayDataList.find(e => e.id === data.configId);
      const statusId = !data.statusId ? 'all' : data.statusId;
      const component = !data.component ? 'all' : data.component;

      return {
        ...data,
        statusId: statusId,
        component: component,
        content: content ? content : undefined
      }
    });
  }
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Dropdown, Popup } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { FieldElement } from '../../../../../../../../../../../../../common/field/component/field-element';
import { IDropdownOption } from '../../../../../../../../../../../../../common/field/component/object/enum-type/checklist';
import { IConfigName } from '../../../../../../../../../../../../../common/field/type/interface/field-type-object';
import DropdownPopperOption from '../../../../../../../../../../../../../common/general/dropdown-popper-option';
import { IRootState } from '../../../../../../../../../../../../../reducers';
import { IField } from '../../../../../../../../../../users/interface/field';
import { IReference } from '../../../../../../../../configuration/module/bp-relationship/interface/form';
import { fileInputField } from '../../../../../constant';
import { IAutomationComponent } from '../../../../../interface/component-list';

interface ISendEmailFileProps {
  storeDynamicField(): void;
  index: number;
  label: string;
  isVisible: { [id: string]: any };
  openModal(isVisible: boolean, emailType: string, index: number): void;
  selectedBP: string;
  fileInputRef: any;
  fileInputRefValue: any;
  fileList: string[];
  addNewEmailFile: () => void;
  manageOperator(value: string, index: number, emailType: string): void;
  operation: any;
  selectedComponent: IAutomationComponent;
  getOption(component: IAutomationComponent, emailType: string): void;
  updateEmailFile: (data: any, index: number) => void;
  data: any;
  removeEmailFile: (index: number) => void;
  firstItemIdx: number;
}

const SendEmailFile: React.FC<ISendEmailFileProps> = (props) => {
  const id = 'attachments';
  const attachmentId = `attachments-${props.index + 1}`;

  const {
    formFieldList,
    inputFieldList,
    referenceList,
    datatableInputField
  } = useSelector((state: IRootState) => state.automation);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const data = props.fileInputRef.current ? props.fileInputRef.current[props.index] : '';
  const operation = props.operation[id] ? props.operation[id][props.index] : 'manual';

  let fieldId = '';
  let sourceReference = '';

  if (data && typeof data === 'object' && (data.hasOwnProperty('referencedKeyPath') || data.hasOwnProperty('keyPath'))) {
    fieldId = data.keyPath;
    sourceReference = data.referencedKeyPath;
  }

  const field = FieldElement.getDataElement({ ...fileInputField, id: attachmentId });
  const Element = field.getFieldElement();

  const setOperator = (value: 'form' | 'manual') => {
    setIsPopupOpen(false);
    props.manageOperator(value, props.index, id);
    props.updateEmailFile('', props.index);
  }

  const manageFieldId = (value: string) => {
    props.updateEmailFile({ referencedKeyPath: sourceReference, keyPath: value }, props.index);
  }

  const manageSourceReference = (value: string) => {
    const key = id;
    if (value !== props.selectedBP) {
      if (!props.selectedComponent.config[key]) {
        props.selectedComponent.config[key] = [];
      }
      const data = props.selectedComponent.config[key];
      if (data instanceof Array) {
        data[props.index] = { referencedKeyPath: value };
      }
      props.selectedComponent.config[key] = data;
    }
    props.updateEmailFile({ referencedKeyPath: value, keyPath: '' }, props.index);
    props.getOption(props.selectedComponent, key);
  }

  const getSoureceReferenceLabel = () => {
    const label = getReferenceFieldOption().find((e: IDropdownOption) => e.value === sourceReference);
    if (label) {
      return label.text;
    }
    return '';
  }

  const getFieldLabel = () => {
    const label = getFormFieldOption().find((e: IDropdownOption) => e.value === fieldId);
    if (label) {
      return label.text;
    }
    return 'File Field';
  }

  const getReferenceFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [{
      key: uuid(),
      value: 'current-form',
      text: 'Current Form'
    }];
    formFieldList.forEach((field: ICustomFieldDetail) => {
      if (field.configName === IConfigName.Referenced) {
        options.push({
          key: uuid(),
          value: field.id || '',
          text: field.label || ''
        })
      }
    })
    return options;
  }

  const getFormFieldOption = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    let sourceId = props.selectedBP;
    let fieldList: any[] = [];
    if (sourceReference !== 'current-form') {
      const fieldConfig = formFieldList.find((e: ICustomFieldDetail) => e.id === sourceReference);
      if (fieldConfig) {
        sourceId = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
      }
      const selectedRef = referenceList.find((ref: IReference) => ref.id === sourceId);
      if (selectedRef) {
        fieldList = inputFieldList[sourceId];
        if (selectedRef.dataType === 'database') {
          fieldList = datatableInputField[sourceId];
        }
      }
    } else {
      fieldList = inputFieldList[sourceId];
    }
    fieldList && fieldList.forEach((e: IField) => {
      if (e.inputConfig && (
        e.inputConfig.type === 'file' ||
        (e.inputConfig.config && e.inputConfig.config.items && e.inputConfig.config.items.type === 'file')
      )) {
        options.push({
          key: uuid(),
          value: e.id || '',
          text: e.label || ''
        })
      }
    })
    return options;
  }

  const manageElementValue = () => {
    if (props.fileInputRefValue
      && props.fileInputRefValue.current
      && props.fileInputRefValue.current[attachmentId].hasOwnProperty('value')) {
      const data = props.fileInputRefValue.current[attachmentId].value;
      if (data && typeof data === 'object' && (data.hasOwnProperty('id'))) {
        props.updateEmailFile(data.id, props.index);
      } else {
        props.updateEmailFile(null, props.index);
      }
    }
  }

  return <div className={`automation-field-container attach-email with-action-btn ${props.index !== props.firstItemIdx ? 'no-padding-top' : ''}`}>
    <div className={`field automation-field `}>
      {props.index === props.firstItemIdx && <label>{props.label}:</label>}
      <div className='with-left-icon attach-email'>
        {operation === 'form' ?
          <div className='select-field-container'>
            <Dropdown
              text={``}
              icon='dropdown'
              floating
              labeled
              button
              className='icon'
              closeOnChange={false}
              popperContainer={DropdownPopperOption}
            >
              <Dropdown.Menu className={`${props.isVisible[id] && props.isVisible[id][props.index] === true ? 'visible' : ''}`}>
                <Dropdown.Menu scrolling className={`option-list`}>
                  <div className={`option-title`}>Select Source Form</div>
                  <Dropdown
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={sourceReference}
                    options={getReferenceFieldOption()}
                    onChange={(event, target: any) => { manageSourceReference(target.value) }}
                    popperContainer={DropdownPopperOption}
                  />
                  <div className={`option-title`}>Field to copy value form</div>
                  <Dropdown
                    fluid
                    selection
                    clearable
                    search
                    selectOnBlur={false}
                    value={fieldId}
                    options={getFormFieldOption()}
                    onChange={(event, target: any) => { manageFieldId(target.value) }}
                    popperContainer={DropdownPopperOption}
                  />
                  <span className={`close-button`} onClick={(e) => {
                    props.openModal(props.isVisible[id], id, props.index);
                    e.stopPropagation();
                  }}>Close</span>
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ position: 'relative' }}>
              <label className={`dropdown-text-cover`}
                onClick={() => props.openModal(
                  props.isVisible[id] ? props.isVisible[id][props.index] : false, id, props.index
                )}>
                <label>{getFieldLabel()}</label> from <label>{getSoureceReferenceLabel()}</label>
              </label>
            </div>
          </div>
          :
          <Element
            {...field}
            hasIconInside={false}
            forwardedRef={props.fileInputRefValue}
            defaultValueElement={false}
            isAddRecord={true}
            throwValueOutside={manageElementValue}
          />
        }
      </div>
    </div>
    <div className='button-container'>
      <Popup
        basic
        on='click'
        position='bottom left'
        id='copy-from-popup'
        style={{ filter: 'none', zIndex: '10000' }}
        trigger={
          <Button
            className={`btn-action-collapse `}
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            icon>
            <i className="ellipsis horizontal icon"></i>
          </Button>
        }
        onClose={() => setIsPopupOpen(!isPopupOpen)}
        open={isPopupOpen}>
        <Container className='copy-from-popup-popup-container email' >
          <div>OPERATION</div>
          <ul>
            <li onClick={() => setOperator('manual')}>
              {operation === 'manual' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Manual Input</li>
            <li onClick={() => setOperator('form')}>
              {operation === 'form' ? <span>✓</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              &nbsp;&nbsp;Choose from list</li>
          </ul>
        </Container>
      </Popup>
    </div>
    {props.fileList.length > 0 && !((props.fileList.length - 1) === 0) &&
      <div className='button-container delete'>
        <i className={`icon alternate trash outline`} color={'#3b86a8'} onClick={() => props.removeEmailFile(props.index)} />
      </div>
    }
    {props.index === props.fileList.length - 1 ?
      <div className='button-container add'>
        <i className={`plus circular icon new-email`} onClick={() => props.addNewEmailFile()} />
      </div>
      :
      <div />
    }
  </div>
}

export default SendEmailFile;
import { FieldsData } from "../../../../../../interface/input/filter";
import { postRequest } from "../../../../../../../../common/api-request";
import { IOptionValue, ISortEnumConfig } from "../../../../../../../admin-module/module/users/interface/field";
import { FilterOperator } from "../../../utils/condition-matcher/condition-matcher-operator";

export class EnumItemsResult {

  value!: IOptionValue[]
  config!: EnumItemsDataSource

  currentProcess?: Promise<void>

  async waitIfNeeded(): Promise<void> {
    if (this.currentProcess) {
      await this.currentProcess
    }
  }

  isValidCacheForConfig(config: EnumItemsDataSource): boolean {
    const cacheIsNotEmpty = this.value && this.value.length > 0;
    return this.config.search === config.search && cacheIsNotEmpty;
  }

  static withConfig(value: IOptionValue[], config: EnumItemsDataSource): EnumItemsResult {
    const result = new EnumItemsResult()
    result.value = value
    result.config = config
    return result
  }

  static withConfigAndPromise(value: Promise<IOptionValue[]>, config: EnumItemsDataSource): EnumItemsResult {
    const result = new EnumItemsResult()
    result.currentProcess = value.then(v => {
      result.value = v
      delete result.currentProcess
    })
    result.config = config
    return result
  }
}

export class EnumFieldAnotherFieldRef {
  // Specifies if it's field inside current document or referenced. If not specified, 'local' would be used
  // If 'local' specified - EnumFieldAnotherFieldRef.keyPath - gets value from currently opened Form and compares
  // to value from Source table (i.e. DataTable or BP used as source for enum)
  link?: 'local' | 'refer'

  // Specifies field, which value would be used for filtering
  fieldId?: string

  // Key path to travel deeper inside field value (used for complex objects)
  keyPath?: string

  // Key path for value in referenced form, if keyPath is reference field
  referencedKeyPath?: string
}

export class EnumFieldValueFilter {

  keyPath!: string

  operator!: FilterOperator

  value!: string | number | boolean | string[] | EnumFieldAnotherFieldRef

  style?: Object
}

export interface EnumItemSource {
  type: string
  config: Object
}


export class EnumItemsDataSource {

  source?: EnumItemSource

  search?: string

  dataFilters?: EnumFieldValueFilter[]

  dataFiltersJoint?: 'or' | 'and'

  labelFormat!: string

  resolvedLocalFields?: FieldsData | undefined | null

  cachedResult?: EnumItemsResult

  static _enumDataFilter = (filters: EnumFieldValueFilter[]): EnumFieldValueFilter[] => {
    const dataFilters = filters || [];
    return dataFilters;
  }

  static from(source: EnumItemsDataSource, resolvedData: FieldsData | undefined | null): EnumItemsDataSource {
    const result = new EnumItemsDataSource()
    result.source = source.source
    result.dataFilters = source.dataFilters
    result.dataFiltersJoint = source.dataFiltersJoint
    result.labelFormat = source.labelFormat
    result.resolvedLocalFields = resolvedData
    return result
  }


  static async fetch(companyId: string, source: EnumItemsDataSource, page: { offset: number, limit: number } = { offset: 0, limit: 200 },
    sort?: ISortEnumConfig): Promise<IOptionValue[]> {
    return (await postRequest(`${companyId}/data/enumOptions/get?limit=${page.limit}&offset=${page.offset}`, {
      source: source.source,
      dataFilters: source.dataFilters,
      search: source.search,
      fields: source.resolvedLocalFields,
      dataFiltersJoint: source.dataFiltersJoint,
      labelFormat: source.labelFormat,
      sort: sort ? sort : undefined
    }))?.data?.data;
  }
}
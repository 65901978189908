import React from 'react';
import { AcceptedProps } from '../interface/accepted-props';
import UrlType from '../type/string/url';
import UrlArrayType from '../type/string/url-array';
const Url: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <UrlArrayType {...props} />;
  } else {
    return <UrlType {...props} />
  }
}

export default Url;
import React, { useState } from 'react';
import { IUserField } from '../../component/admin-module/module/users/interface/user-field';
import { IFieldConfig, IFieldStyle } from '../../component/admin-module/module/users/interface/field';
import { Icon } from 'semantic-ui-react-bpm';
import ToolTip from '../general/tooltip';
import { IConfigName } from '../field/type/interface/field-type-object';
import PhonePrefixField from './phone-prefix-field';

interface IProperties {
  minLengthRef: React.MutableRefObject<HTMLInputElement>;
  maxLengthRef: React.MutableRefObject<HTMLInputElement>;
  fieldData: IUserField;
  configData: IFieldConfig;
  alignment: string;
  decimalRef: React.MutableRefObject<HTMLInputElement>;
  commaSeparated: boolean;
  setCommaSeparated(value: boolean): void;
  setRefreshState(value: number): void;
  refreshState: number;
  setAlignment(value: string): void;
  activeIndex: number;
  handleClick(event: any, props: { index: number }): void;
  error: any;
  setAdvanceConfigType?(value: string): void;
  isAdvanceConfigType?: boolean;
  stringCase?: string;
  setStringCase?(value: string): void;
  QRInput?: boolean;
  setQRInput?(value: boolean): void;
  percentage?: boolean;
  setPercentage?(value: boolean): void;
  setConfidential?(value: boolean): void;
  confidential?: boolean;
  style?: IFieldStyle;
  setFieldStyle?(value: IFieldStyle): void;
  setPhoneCountryCode?(value: string): void;
  phoneCountryCode?: string;
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const Properties: React.FC<IProperties> = (props) => {

  const [tab, setTab] = useState<'properties' | 'style'>('properties');

  const hasProperties = props.fieldData &&
    (
      props.fieldData.type === 'number'
      || props.fieldData.type === 'fx'
      || (props.fieldData.type === 'string'
        && (props.configData.format === 'none'
          || props.configData.format === ''
          || props.configData.format === 'regexp'
          || props.configData.format === 'allowedChars'
          || props.configData.format === 'phone'
        )
      )
    );

  const hasStyles = props.fieldData &&
    props.fieldData.configName === IConfigName.TextStringMultiline;

  const preventNegativeInput = (e: any) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e: any) => {
    const clipboardData = e.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const setFieldStyle = (style: IFieldStyle) => {
    if (props.setFieldStyle) {
      props.setFieldStyle({ ...props.style, ...style });
    }
  }

  return (
    <div
      id='properties-container'
      className={`${hasProperties ? 'ui vertical accordion ui menu' : 'hidden'}`}>
      <div className="item" >
        <div className={`${props.activeIndex === 1 ? 'active' : ''} title flex-container`}>
          <div className='tab-selection'>
            <label className={`${tab === 'properties' ? 'active' : ''}`} onClick={() => setTab('properties')}>Properties</label>
            {hasStyles && <label className={`${tab === 'style' ? 'active' : ''}`} onClick={() => setTab('style')}>Style</label>}
          </div>
          {props.fieldData.configName === IConfigName.TextString &&
            <ToolTip position='right center'
              popupMessage='Advanced configuration'
              popupTriggerComponent={
                <Icon onClick={(e: any) => { props.setAdvanceConfigType && props.setAdvanceConfigType(props.isAdvanceConfigType ? 'none' : 'allowedChars') }} name={`cog`} />
              } />
          }
          {props.activeIndex === 1 ?
            <Icon onClick={(e: any) => props.handleClick(e, { index: props.activeIndex })} name={`chevron up`} size='big' />
            :
            <Icon onClick={(e: any) => props.handleClick(e, { index: props.activeIndex })} name={`chevron down`} size='big' />
          }
        </div>
        {tab === 'style' &&
          <Style {...props}
            style={props.style}
            setFieldStyle={setFieldStyle}
            preventNegativeInput={preventNegativeInput}
            preventPasteNegative={preventPasteNegative}
          />
        }
        <div className={`${props.activeIndex === 1 ? 'active' : ''} ${tab !== 'properties' ? 'hidden' : ''} content`}>
          <div id="collapse-custom-field" className={`grouped equal width fields`}>
            <div id="collapse-form-group" className={`inline fields ${props.fieldData.type === 'fx' || props.configData.format === 'phone' ? 'hidden' : ''}`}>
              <label>Min {props.fieldData && props.fieldData.type === 'string' ? 'Length' : 'Value'}</label>
              <div className={`ui input ${props.error.minLength ? 'error' : ''}`}>
                <input min='0' type="number" onPaste={preventPasteNegative} onKeyPress={preventNegativeInput} ref={props.minLengthRef} onChange={() => { props.setRefreshState(props.refreshState + 1) }} />
              </div>
              <label>Max {props.fieldData && props.fieldData.type === 'string' ? 'Length' : 'Value'}</label>
              <div className={`ui input ${props.error.maxLength ? 'error' : ''}`}>
                <input min='0' type="number" onPaste={preventPasteNegative} onKeyPress={preventNegativeInput} ref={props.maxLengthRef}
                  onChange={() => { props.setRefreshState(props.refreshState + 1) }} />
              </div>
            </div>
            {props.fieldData.configName === IConfigName.Phone &&
              <PhonePrefixField
                setPhoneCountryCode={props.setPhoneCountryCode}
                phoneCountryCode={props.phoneCountryCode}
              />
            }
            <div id="collapse-button-group">
              <div className="ui icon buttons">
                <label>Alignment</label>
                <button className={`ui button ${props.alignment === 'left' ? 'active' : ''}`} onClick={() => { props.setAlignment('left') }}>
                  <i aria-hidden="true" className="align left icon"></i>
                </button>
                <button className={`ui button ${props.alignment === 'center' ? 'active' : ''}`} onClick={() => { props.setAlignment('center') }}>
                  <i aria-hidden="true" className="align center icon"></i>
                </button>
                <button className={`ui button ${props.alignment === 'right' ? 'active' : ''}`} onClick={() => { props.setAlignment('right') }}>
                  <i aria-hidden="true" className="align right icon"></i>
                </button>
              </div>
              {props.fieldData && Object.keys(props.fieldData).length > 0 &&
                (props.fieldData.type === 'string' && props.configData.format !== 'phone') &&
                <div className={`ui icon buttons format`}>
                  <label>Format</label>
                  <button className={`ui button ${props.stringCase === 'lowercase' ? 'active' : ''}`} onClick={() => { props.setStringCase && props.setStringCase('lowercase') }}>
                    abc
                  </button>
                  <button className={`ui button ${props.stringCase === 'case-sensitive' ? 'active' : ''}`} onClick={() => { props.setStringCase && props.setStringCase('case-sensitive') }}>
                    Abc
                  </button>
                  <button className={`ui button ${props.stringCase === 'uppercase' ? 'active' : ''}`} onClick={() => { props.setStringCase && props.setStringCase('uppercase') }}>
                    ABC
                  </button>
                </div>
              }
              <div id={`${props.fieldData && Object.keys(props.fieldData).length > 0 && props.fieldData.type === 'string' ? 'hide-element' : ''}`} className='inline field'>
                <label>Max Decimals</label>
                <div className={`ui input ${props.percentage ? 'disabled' : ''}`}>
                  <input type="number" ref={props.decimalRef} onChange={() => { props.setRefreshState(props.refreshState + 1) }} />
                </div>
              </div>
            </div>
            <div id="collapse-checkbox-toggle"
              className={`${props.fieldData && props.fieldData.type === 'string' ? 'hidden' : ''}`}
              onClick={() => { if (!props.percentage) props.setCommaSeparated(!props.commaSeparated) }}
            >
              <label>Comma Separated? </label>
              <div className={`ui toggle checkbox ${props.percentage ? 'disabled' : ''}`}>
                <input type="checkbox" onChange={() => { }} checked={!!props.commaSeparated} />
                <label>Yes</label>
              </div>
            </div>
            <div id="collapse-checkbox-toggle"
              className={`${props.fieldData && props.fieldData.type === 'string' ? 'hidden' : ''}`}
              onClick={() => {
                if (props.setPercentage) {
                  props.setPercentage(!props.percentage);
                  if (props.commaSeparated) {
                    props.setCommaSeparated(false);
                  }
                }
              }}
            >
              {(props.fieldData && props.fieldData.type !== 'fx') &&
                <>
                  <label>Percentage? </label>
                  <div className="ui toggle checkbox">
                    <input type="checkbox" onChange={() => { }} checked={!!props.percentage} />
                    <label>Yes</label>
                  </div>
                </>
              }
            </div>
            <div id="collapse-checkbox-toggle"
              className={`${props.fieldData && props.fieldData.type === 'string' ? '' : 'hidden'} ${props.fieldData.type}`}
              onClick={() => { if (props.setQRInput) props.setQRInput(!props.QRInput) }}
            >
              <label>QR Input</label>
              <div className="ui toggle checkbox">
                <input type="checkbox" onChange={() => { }} checked={!!props.QRInput} />
                <label>Yes</label>
              </div>
            </div>
            {props.setConfidential && <div id="collapse-checkbox-toggle"
              className={`${props.fieldData.type}`}
              onClick={() => props.setConfidential && props.setConfidential(!props.confidential)}>
              <label>Confidential</label>
              <div className={`ui toggle checkbox`} >
                <input type="checkbox" onChange={() => { }} checked={!!props.confidential} />
                <label>Yes</label>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Properties;

interface IStyle extends IProperties {
  preventPasteNegative(e: any): void;
  preventNegativeInput(e: any): void;
  style?: IFieldStyle;
  setFieldStyle(value: IFieldStyle): void;
}

const Style: React.FC<IStyle> = (props) => {
  return <div className={`${props.activeIndex === 1 ? 'active' : ''} content`}>
    <div id="collapse-form-group" className={`inline fields 
              ${props.fieldData.configName !== IConfigName.TextStringMultiline ? 'hidden' : ''}`}>
      <label>Min Lines</label>
      <div className={`ui input`}>
        <input
          min='0'
          type="number"
          value={props.style?.minLines || ''}
          onPaste={props.preventPasteNegative}
          onKeyPress={props.preventNegativeInput}
          onChange={({ target }) => {
            props.setFieldStyle({ minLines: parseInt(target.value) });
          }} />
      </div>
      <label>Max Lines</label>
      <div className={`ui input`}>
        <input
          min='0'
          type="number"
          value={props.style?.maxLines || ''}
          onPaste={props.preventPasteNegative}
          onKeyPress={props.preventNegativeInput}
          onChange={({ target }) => {
            props.setFieldStyle({ maxLines: parseInt(target.value) });
          }} />
      </div>
    </div>
  </div>
}
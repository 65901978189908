import React from 'react';
import { v4 as uuid } from 'uuid';
import { IAutomation } from '../../interface/automation';
import { IAutomationChildComponent, IAutomationComponent } from '../../interface/component-list';
import { IOptionWidget } from '../../interface/option-widget';
import AddComponent from './add-component';
import { AtomationDetailComponentRegistry } from './component-registry';

interface IAutomationDetailProps {
  selectedComponent: IAutomationComponent | undefined;
  addNewComponent(value: IOptionWidget): void;
  updateAutomationDetails(value: string, field: string): void;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  updateCurrentAutomation(component: IAutomationComponent, saveToApi: boolean): void;
  // this is only to refresh component as ref dont refresh component even there is changes on it
  businessRuleId: string;
  selectedChildComponent?: IAutomationChildComponent;
  refsObject: React.MutableRefObject<any>;
  setSelectedComponent(component: IAutomationComponent, selectedActionObject?: IAutomationChildComponent, childOnly?: boolean): void;
  hasComponentChange: React.MutableRefObject<boolean>;
  parentRefresher(): void;
}

const AutomationDetail: React.FC<IAutomationDetailProps> = (props) => {
  return (
    <div className='automation-form-detail'>
      {props.selectedComponent &&
        <AtomationDetailComponentRegistry.Render
          key={uuid()}
          type={props.selectedComponent.type}
          updateAutomationDetails={props.updateAutomationDetails}
          currentAutomationRef={props.currentAutomationRef}
          selectedComponent={props.selectedComponent}
          updateCurrentAutomation={props.updateCurrentAutomation}
          businessRuleId={props.businessRuleId}
          selectedChildComponent={props.selectedChildComponent}
          refsObject={props.refsObject}
          isHasParent={false}
          setSelectedComponent={props.setSelectedComponent}
          hasComponentChange={props.hasComponentChange}
          parentRefresher={props.parentRefresher}
        />
      }
      {!props.selectedComponent &&
        <AddComponent addNewComponent={props.addNewComponent} />
      }
    </div>
  );
}

export default React.memo(AutomationDetail, (prev: IAutomationDetailProps, next: IAutomationDetailProps) => {
  return next.hasComponentChange.current === true
});
import React, { useState } from 'react';
import ChatWidgetMessage from '../widget/messages';
import { IComment } from '../../interface/comment';
import { ConfirmationMessage } from '../../../../../../common/general/confirmation-modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCommentAction, deleteCommentAttachmentAction } from '../../action';
import { useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../reducers';
import { UploadedFile } from '../../../../../../common/api-request/upload';

interface IChatContent {
  commentList: IComment[];
  saveComment(text: string, commentId?: string, commentAttachments?: UploadedFile[]): void;
  removeUploadedFile(file: UploadedFile): void;
  userColor: { [id: string]: string };
  uploadedFileList: UploadedFile[];
  removeFileAfterSave(): void;
  documentId: string;
  company: string;
  setSelectedId(value: string): void;
  selectedId: string;
  markCommentAsUnread(comment: IComment): void;
  dismissUnreadNavigator(comments: IComment[], type: 'read' | 'unread'): void;
  lastReadMessage: IComment;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  commentsUnread: IComment[];
  module: string;
  sort: boolean;
}

const ChatContent: React.FC<IChatContent> = (props) => {
  const dispatch = useDispatch();
  const match: {
    path: string;
    params: {
      formId: string;
      documentId: string
    };
  } = useRouteMatch();
  const { company } = useSelector((state: IRootState) => state.auth);

  const [deleteDetails, setDeleteDetails] = useState<any>(null as {} | null);

  const markCommentsAsRead = () => {
    props.dismissUnreadNavigator(props.commentsUnread, 'read');
  }

  const deleteCommentAttachment = (attachmentId: string, commentId: string) => {
    setDeleteDetails({
      deleteMessage: 'Are you sure you want to delete this file permanently?',
      idForDeletion: attachmentId,
      commentId: commentId,
      type: 'attachment',
    });
  };

  const deleteComment = (id: string) => {
    setDeleteDetails({
      deleteMessage:
        'Are you sure you want to delete this comment? This cannot be undone.',
      idForDeletion: id,
      type: 'comment',
    });
  };

  const confirmDelete = () => {
    if (deleteDetails === null) return;
    if (deleteDetails.type === 'comment') {
      dispatch(
        deleteCommentAction({
          company: company,
          documentId: match.params.documentId,
          commentId: deleteDetails && deleteDetails.idForDeletion,
        })
      );
    } else {
      dispatch(
        deleteCommentAttachmentAction({
          company: company,
          documentId: match.params.documentId,
          commentId: deleteDetails && deleteDetails.commentId,
          attachmentId: deleteDetails && deleteDetails.idForDeletion,
        })
      );
    }
    setDeleteDetails(null);
  };

  const onScroll = (e: any) => {
    const navigatorWrap = document.getElementById('chat-navigator-wrap');
    const unreadNavigator = document.getElementById('chat-navigator');
    const lastReadEl = document.getElementById(props.lastReadMessage.id);

    if (lastReadEl) {
      const rect = lastReadEl.getBoundingClientRect();
      const containerRect = props.containerRef.current.getBoundingClientRect();
      const top = rect.top;
      const bottom = rect.bottom;
      const isVisible = (top >= containerRect.top && bottom <= containerRect.bottom);

      if (navigatorWrap) {
        if (isVisible) {
          navigatorWrap.style.setProperty('opacity', '0');
        } else {
          navigatorWrap.style.removeProperty('opacity');
        }
      }
      if (unreadNavigator) {
        if (top >= containerRect.top) {
          unreadNavigator.className = 'arrow down icon';
        } else {
          unreadNavigator.className = 'arrow up icon';
        }
      }
    }
  }

  const scrollToElement = () => {
    const lastReadEl = document.getElementById(props.lastReadMessage.id);
    if (lastReadEl) {
      lastReadEl.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: 'smooth'
      });
    }
  }

  return (
    <div id="chat-content" ref={props.containerRef}
      className={`chat-content ${props.uploadedFileList.length > 0 ? 'withUpload' : ''}`}
      onScroll={onScroll}>
      {props.commentsUnread.length > 0 &&
        <div id='chat-navigator-wrap' className='chat-unread-nagivator' >
          <span onClick={scrollToElement} title='Jump' className='label'>
            <i id='chat-navigator' aria-hidden="true" className="arrow down icon" />
            {props.commentsUnread.length} new messages
          </span>
          <span title='Mark as read' className='close' onClick={markCommentsAsRead} />
        </div>
      }
      <ChatWidgetMessage
        documentId={props.documentId}
        removeFileAfterSave={props.removeFileAfterSave}
        removeUploadedFile={props.removeUploadedFile}
        uploadedFileList={props.uploadedFileList}
        userColor={props.userColor}
        deleteComment={deleteComment}
        saveComment={props.saveComment}
        commentList={props.commentList}
        company={props.company}
        deleteCommentAttachment={deleteCommentAttachment}
        markCommentAsUnread={props.markCommentAsUnread}
        lastReadMessage={props.lastReadMessage}
        selectedId={props.selectedId}
        setSelectedId={props.setSelectedId}
        module={props.module}
        sort={props.sort}
      />
      <ConfirmationMessage
        confirmButtonName={'Yes'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => setDeleteDetails(null)}
        open={!!deleteDetails}
        confirm={confirmDelete}
        cancel={() => setDeleteDetails(null)}
        headerText="Confirmation"
        detailText={deleteDetails && deleteDetails.deleteMessage}
        loading={false}
        type="warning"
      />
    </div>
  );
};

export default ChatContent;

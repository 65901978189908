const dataTableMessage = {
  dataTableError: 'Error retrieving data table list.',
  dataTableRecordError: 'Error retrieving data table records.',
  createDataTableSuccess: 'Data table successfully created.',
  deleteDatatableSuccess: 'Data table successfully deleted.',
  createDataTableRecordSuccess: 'Data table record successfully created.',
  batchUpdateDataTableRecordSuccess: 'Data table record list successfully updated.',
  updateDataTableRecordSuccess: 'Data table record successfully updated.',
  updateDataTableSuccess: 'Data table successfully updated.',
  deleteDataTableFieldsSuccess: 'Data table field successfully deleted.',
  deleteDataTableRecordsSuccess: 'Data table record successfully deleted.',
  withDuplicateRecordDataError: 'Duplicate records found, please delete existing duplicate records first.',
  validationError: 'Record cannot be submitted. Please fill-up mandatory fields and/or fix errors found below then re-submit the record.',
  savingWithNoChangesMessage: 'You must update at least 1 data table field to be able to save.',
};

export default dataTableMessage;
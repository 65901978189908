import { IFormBuilderPublishDetailInput } from '../../../form-builder/interface/input/form-builder-save';
import { IFormFieldGetDetailInput } from '../../../roles/interface/input/form-field-get';
import { IFormFieldResponse } from '../../../roles/interface/response/form-field-get';
import { IFormStatusListDetailInput } from '../../../workflow/interface/input/form-status-list';
import { IFormStatusListResponse } from '../../../workflow/interface/response/form-status-list';
import { GET_FORM_FIELD, GET_FORM_FIELD_RETURN, GET_FORM_STATUS, GET_FORM_STATUS_RETURN, GET_TASK_VIEW, GET_TASK_VIEW_RETURN, PUBLISH_TASK_VIEW, PUBLISH_TASK_VIEW_ERROR, PUBLISH_TASK_VIEW_RETURN, SAVE_TASK_VIEW, SAVE_TASK_VIEW_ERROR, SAVE_TASK_VIEW_RETURN } from './constant';
import { ITaskViewGetDetailInput } from './interface/input/task-view-get';
import { ITaskViewSaveDetailInput } from './interface/input/task-view-save';
import { ITaskViewGetResponse } from './interface/response/task-view-get';
import { ITaskViewSaveResponse } from './interface/response/task-view-save';

export const getFormStatusListAction = (payload: IFormStatusListDetailInput) => {
  return { type: GET_FORM_STATUS, ...payload };
}

export const getFormStatusListReturnAction = (payload: IFormStatusListResponse) => {
  return { type: GET_FORM_STATUS_RETURN, ...payload };
}

export const getTaskViewAction = (payload: ITaskViewGetDetailInput) => {
  return { type: GET_TASK_VIEW, ...payload };
}

export const getTaskViewReturnAction = (payload: ITaskViewGetResponse) => {
  return { type: GET_TASK_VIEW_RETURN, ...payload };
}

export const getFormFieldAction = (payload: IFormFieldGetDetailInput) => {
  return {
    type: GET_FORM_FIELD,
    ...payload
  }
}

export const getFormFieldReturnAction = (payload: IFormFieldResponse) => {
  return {
    type: GET_FORM_FIELD_RETURN,
    ...payload
  }
}

export const saveTaskViewAction = (payload: ITaskViewSaveDetailInput) => {
  return {
    type: SAVE_TASK_VIEW,
    ...payload
  }
}

export const saveTaskViewReturnAction = (payload: ITaskViewSaveResponse) => {
  return {
    type: SAVE_TASK_VIEW_RETURN,
    ...payload
  }
}

export const saveTaskViewErrorAction = (message: string) => {
  return { type: SAVE_TASK_VIEW_ERROR, saveTaskViewErrorMessage: message }
}

export const publishTaskViewAction = (payload: IFormBuilderPublishDetailInput) => {
  return {
    type: PUBLISH_TASK_VIEW,
    ...payload
  }
}

export const publishTaskViewReturnAction = () => {
  return { type: PUBLISH_TASK_VIEW_RETURN }
}

export const publishTaskViewErrorAction = (message: string) => {
  return { type: PUBLISH_TASK_VIEW_ERROR, saveTaskViewErrorMessage: message }
}

import React from 'react';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { IConfig } from '../../../field-element';
import { AcceptedProps } from '../../interface/accepted-props';
import RangeFieldType from './range-field-type';

const RangeType: React.FC<AcceptedProps> = (props) => {
  const name = props.id;

  const itemConfigFrom = props.inputConfig.config.from;
  const itemConfigTo = props.inputConfig.config.to;

  if (!props.forwardedRef.current[name]) {
    props.forwardedRef.current[name] = {};
  }

  return (<>
    <span className={`field-info`}>
      <label
        ref={(element) => setLabelRef(element, name)}
        className={`field-label`}>
        {props.label}
      </label>
      {
        props.hint &&
        <i id={`info-${name}`} className={`icon info-icon ${name}`} title={props.hint}></i>
      }
    </span>
    <div className='field-range'>
      <RangeFieldType {...props}
        placeHolder={`From`}
        fieldConfig={itemConfigFrom || {} as IConfig}
        name={name}
      />
      <span className='range-separator'>&#8212;</span>
      <RangeFieldType {...props}
        placeHolder={`To`}
        fieldConfig={itemConfigTo || {} as IConfig}
        name={name}
      />
    </div>
  </>)
}

export default RangeType;
import React from 'react';
import ClearConfig from './clear';
import PermissionConfig from './permission';
import ValueConfig from './value';

const DefaultTypeList: any[] = [
  new ClearConfig(),
  new PermissionConfig(),
  new ValueConfig(),
]

interface IRender {
  type: string;
}

interface IDefaultTypeRegistry {
  name: string;
  render: React.FC<any>
}

export class DefaultTypeModuleRegistry {

  registry: { [name: string]: IDefaultTypeRegistry } = {};

  constructor() {
    const types = DefaultTypeList;
    for (let type of types) {
      this.registry[type.name] = type;
    }
  }

  notFoundModule: React.FC = () => {
    return <div>Config not exist.</div>;
  }

  getModule(name: string): React.FC {
    return (this.registry[name])
      ? this.registry[name].render
      : this.notFoundModule;
  }

  static Render: React.FC<IRender> = (props) => {
    const module = new DefaultTypeModuleRegistry();
    const Render = module.getModule(props.type);
    return <Render {...props} />
  }

}
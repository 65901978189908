import { IutilProps } from '../..';
import { IAutomationComponent } from '../../../../interface/component-list';
import { CreateDocument } from './create-document';
import { EditForm } from './edit-form';
import { SendEmailUtils } from './send-email';
import { CreateUserUtils } from './create-user';
import { SendSMSUtils } from './send-sms';

export class ActionType {
  static browse = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    switch (selectedComponent?.config?.action?.name) {
      case 'create-document':
        CreateDocument.getOptions(selectedComponent, utils);
        break;
      case 'edit-form':
        EditForm.getOptions(selectedComponent, utils);
        break;
      case 'send-email':
        SendEmailUtils.getOptions(selectedComponent, utils, 'all');
        break;
      case 'create-user':
        CreateUserUtils.getPortals(selectedComponent, utils);
        break;
      case 'send-sms':
        SendSMSUtils.getOptions(selectedComponent, utils);
        break;
    }
  }


}
import { GET_APPLICATION_LIST, GET_APPLICATION_LIST_RETURN, GET_FORM_FIELD, GET_FORM_FIELD_RETURN } from './constant'
import { IApplicationGetDetailInput } from './interface/input/application-get'
import { IApplicationGetResponse } from './interface/response/application-get'
import { IFormFieldListGetDetailInput } from './interface/input/form-field-get'
import { IFormFieldGetResponse } from './interface/response/form-field-get'

export const getApplicationListAction = (payload: IApplicationGetDetailInput) => {
  return {
    type: GET_APPLICATION_LIST,
    ...payload
  }
}

export const getApplicationListReturnAction = (payload: IApplicationGetResponse) => {
  return {
    type: GET_APPLICATION_LIST_RETURN,
    ...payload
  }
}

export const getFormFieldAction = (payload: IFormFieldListGetDetailInput) => {
  return {
    type: GET_FORM_FIELD,
    ...payload
  }
}

export const getFormFieldReturnAction = (payload: IFormFieldGetResponse) => {
  return {
    type: GET_FORM_FIELD_RETURN,
    ...payload
  }
}
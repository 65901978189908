import React from 'react';
import { IRender } from '.';
import { IField } from '../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../field/component/field-element';

class RangeFieldValue {

  name = 'range';

  Render: React.FC<IRender> = (props) => {
    const tempField: IField = {
      id: props.valueId,
      inputConfig: {
        type: 'range',
        config: {
          from: {
            config: props.field.config ? props.field.config.itemConfigFrom : {},
            type: props.field.config ? props.field.config.itemType : 'string'
          } as any,
          to: {
            config: props.field.config ? props.field.config?.itemConfigTo : {},
            type: props.field.config ? props.field.config?.itemType : {}
          } as any,
        },
      },
      label: props.field.label,
    };
    const field = FieldElement.getDataElement(tempField);
    const Element = field.getFieldElement();
    return (
      <div>
        <Element
          {...field}
          forwardedRef={props.forwardedRef}
          defaultValueElement={true}
          throwValueOutside={props.throwValueOutside}
        />
      </div>
    );
  }
}

export default RangeFieldValue;

import React, { CSSProperties, useState } from 'react';
import { Container, Popup } from 'semantic-ui-react-bpm';
import { IDisplayData, IDisplayDataConfigKeypaths } from '../../interface/display-data';
import { renderFields, renderMetaFields } from './render-fields';

interface ICreatedDisplayData {
  displayData: IDisplayData;
  displayDataConfigKeypath: IDisplayDataConfigKeypaths[];
  deleteConfig(selected: IDisplayData): void;
  openDisplayDataForm(data: IDisplayData, actionType: 'edit' | 'create'): void;
}

const actionStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '15px auto',
  gridColumnGap: '10px',
  alignItems: 'center'
}

const contentStyle: CSSProperties = {
  padding: '7px 15px',
  fontSize: '13px'
}

const CreatedDisplayDataList: React.FC<ICreatedDisplayData> = (props) => {

  const [actionPopup, setActionPopup] = useState(false);

  const openForm = () => {
    setActionPopup(false);
    props.openDisplayDataForm(props.displayData, 'edit');
  }

  const deleteData = () => {
    setActionPopup(false);
    props.deleteConfig(props.displayData);
  }

  return <div className={`display-data-selected`}>
    <div className={`display-data-header`}>
      <span>{props.displayData.name}</span>
      <Popup
        size='mini'
        basic
        on='click'
        open={actionPopup}
        position='bottom right'
        id='dtr-bulk-actions-popup'
        onClose={() => setActionPopup(false)}
        trigger={
          <i aria-hidden="true" id={`selected-relation-icon-${props.displayData.id}`}
            className={`ellipsis vertical icon`} onClick={() => setActionPopup(!actionPopup)} />
        }
      >
        <Container className='bulk-actions-popup-container' style={{ minWidth: 'unset', width: '100px' }}>
          <div onClick={openForm} style={contentStyle} className='bulk-actions-content'>
            <div style={actionStyle}>
              <span>Edit</span>
            </div>
          </div>
          <div className='bulk-actions-separator' />
          <div onClick={deleteData} style={{ ...contentStyle }} className='bulk-actions-content'>
            <div style={actionStyle}>
              <span>Delete</span>
            </div>
          </div>
        </Container>
      </Popup>
    </div>
    <div className='display-data-content'>
      <div className='header-container'>
        {props.displayData.header.map((header, idx) => {
          return <label className='header-field' key={`header-field-${idx}`}>
            {renderFields(header, props.displayDataConfigKeypath)}
          </label>
        })}
      </div>
      <div className='body-container'>
        {props.displayData.content.map((body, idx) => {
          return <label className='body-field' key={`body-field-${idx}`}>
            {renderFields(body, props.displayDataConfigKeypath)}
          </label>
        })}
      </div>
      <div className='meta-container'>
        {props.displayData.meta &&
          <label className='meta-field' >
            {renderMetaFields(props.displayData.meta, props.displayDataConfigKeypath)}
          </label>
        }
      </div>
    </div>

  </div>
}

export default CreatedDisplayDataList;
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../common/icons';
import { IStatusAction } from '../interface/section';
import ActionsSlider from './slider';

interface IActionList {
  action: IStatusAction;
  handleActionPermissionChange: Function;
  openStatusActionPermissionSettings: Function;
}

const ActionList: React.FC<IActionList> = (props) => {
  const { config } = { ...props.action.permissions };
  return <div className='actions-container'>
    <ActionsSlider
      action={props.action}
      handleActionPermissionChange={props.handleActionPermissionChange}
    />
    {config && Object.keys(config).length > 0 ?
      <Image onClick={() => props.openStatusActionPermissionSettings(props.action)}
        className='settings-icon' src={icons.blue.cog} />
      :
      <Image
        onClick={() => props.openStatusActionPermissionSettings(props.action)}
        className='settings-icon' src={icons.blue.cogOutline}
      />
    }
  </div>
}

export default ActionList;
// Does not work with currently installed ts and other libs
// function CrudPermission<prefix extends string>(
//   name: prefix
// ): {
//   Get: `${prefix}.get`;
//   Create: `${prefix}.create`;
//   Update: `${prefix}.update`;
//   Delete: `${prefix}.delete`;
// } {
function CrudPermission(name: string): {
  Get: string;
  Create: string;
  Update: string;
  Delete: string;
} {
  return {
    Get: `${name}.get`,
    Create: `${name}.create`,
    Update: `${name}.update`,
    Delete: `${name}.delete`,
  };
}

export const Permission = {
  User: CrudPermission('user'),
  UserField: CrudPermission('userField'),
  Group: CrudPermission('group'),
  Form: CrudPermission('form'),
  Document: {
    ...CrudPermission('document'),
    PerformAction: 'document.performAction',
    ExtendedUpload: 'document.extendedUpload',
  },
  CompanySettings: CrudPermission('companySettings'),
};

export const PermissionExtra = {
  Company: { ...CrudPermission('company'), ManageUsers: 'company.manageUsers' },
};

function permissionsExtractStrings(data: object): string[] {
  return Object.values(data).reduce((arr, nestedData) => {
    if (nestedData && nestedData instanceof Object) {
      arr.push(...permissionsExtractStrings(nestedData));
      return arr;
    }

    arr.push(nestedData);
    return arr;
  }, []);
}

function permissionsExtractStringsSet(data: object): Set<string> {
  const arr = permissionsExtractStrings(data);
  const set = new Set(arr);
  if (arr.length !== set.size) {
    throw new Error(`Permissions must be unique. ${arr.join(', ')}`);
  }

  return set;
}

export const PermissionSet: Set<string> =
  permissionsExtractStringsSet(Permission);
export const PermissionExtraSet: Set<string> =
  permissionsExtractStringsSet(PermissionExtra);

import React from 'react';
import { icons } from '../../../../../../icons';
import { Image } from 'semantic-ui-react-bpm';

export const renderIcon = (type: 'single' | 'multi-line', hasIconInside: boolean) => {
  return <>{hasIconInside &&
    <div className={`flex-container with-icon ${type} calendar-icon`} >
      <Image width='15px' src={icons.black.calendar} />
    </div>
  }
  </>
}
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.Switch,
  required: false,
  type: 'bool',
};

const value = {
  title: 'Switch',
  icon: {
    black: require('../../icons/black/off.png'),
    white: require('../../icons/white/off.png'),
    blue: require('../../icons/blue/off.png'),
  },
  style: {
    width: '20px',
    height: '12px',
    top: '3px'
  },
  defaultData,
  defaultConfig: {}
}

export default value;
import React, { useRef, useState, useEffect } from 'react';
import {
  Modal,
  Segment,
  Button,
  Form,
  Dimmer,
  Loader,
  Dropdown,
  Image,
} from 'semantic-ui-react-bpm';
import { useSelector } from 'react-redux';
import DefaultField from '../../default-value';
import {
  IFieldConfig,
  IFieldStyle,
} from '../../../../component/admin-module/module/users/interface/field';
import { IRootState } from '../../../../reducers';
import Properties from '../../properties';
import {
  IConfig,
  ICustomFieldDetail,
  IOnCustomFieldSubmitObject,
} from '../../interface/custom-field-detail';
import { IUserFieldPermission } from '../../../../component/admin-module/module/users/interface/user-field-permission';
import {
  fieldVisibility,
  UserFieldErrorMessage,
  errorIndicator,
} from '../../constant';
import ActionMessage from '../../../general/action-message';
import { ICustomFieldType } from '..';
import { ICustomFieldProps, IDefaultRef } from '../..';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { icons } from '../../../icons';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { plainToClass } from 'class-transformer';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import parsePhoneNumberFromString, {
  CountryCode, 
  parse
} from 'libphonenumber-js';

class DefaultFieldConfig implements ICustomFieldType {
  name = 'default';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const [refreshState, setRefreshState] = useState(1);
    const [activeIndex, setActiveIndex] = useState(1);
    const [mandatory, setMandatory] = useState(false);
    const [searchable, setSearchable] = useState(false);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>(
      {} as ICustomFieldDetail
    );
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [alignment, setAlignment] = useState('left');
    const [commaSeparated, setCommaSeparated] = useState(false);
    const [isMultiline, setIsMultiline] = useState(false);
    const [fieldPermissionColor, setFieldPermissionColor] = useState('');
    const [stringCase, setStringCase] = useState('case-sensitive');
    const [QRInput, setQRInput] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [fieldStyle, setFieldStyle] = useState<IFieldStyle>({});
    const [phoneCountryCode, setPhoneCountryCode] = useState('');

    const companyId = props.companyId;
    const { userFieldPermissionList } = useSelector(
      (state: IRootState) => state.user
    );

    const fieldNameRef = useRef(document.createElement('input'));
    const defaultvalueRef = useRef<IDefaultRef>({});
    const helpRef = useRef(document.createElement('textarea'));
    const minLengthRef = useRef(document.createElement('input'));
    const maxLengthRef = useRef(document.createElement('input'));
    const decimalRef = useRef(document.createElement('input'));

    const [error, setError] = useState(errorIndicator);

    const handleClick = (e: any, titleProps: any) => {
      const { index } = titleProps;
      setActiveIndex(!index ? 1 : 0);
    };

    const inputObject = () => {
      let integer = {};
      let string = {};
      let phone = {};
      let qrInputStrings = {};

      if (fieldData && configData) {
        const type = fieldData.type;
        const format = configData.format;
        if (type === 'number') {
          integer = {
            alignment: alignment,
            max: maxLengthRef.current
              ? parseFloat(maxLengthRef.current.value || '')
              : undefined,
            min: minLengthRef.current
              ? parseFloat(minLengthRef.current.value || '')
              : undefined,
            thousandSeparator: commaSeparated,
            decimals: decimalRef.current
              ? parseFloat(decimalRef.current.value || '')
              : undefined,
            percent: percentage ? percentage : undefined,
          };
        }
        if (type === 'string' && format && format === 'none') {
          string = {
            alignment: alignment,
            maxLength:
              maxLengthRef.current && maxLengthRef.current.value
                ? parseFloat(maxLengthRef.current.value || '0')
                : undefined,
            minLength:
              minLengthRef.current && minLengthRef.current.value
                ? parseFloat(minLengthRef.current.value || '0')
                : undefined,
            case: stringCase,
            style: fieldStyle,
          };
        }
        if (type === 'string' && format && format === 'phone') {
          phone = {
            alignment: alignment,
            phoneCountryCode: phoneCountryCode,
          };
        }
        if (type === 'string') {
          qrInputStrings = { qrInput: QRInput };
        }
      }

      return plainToClass(IConfig, {
        ...configData,
        ...integer,
        ...string,
        ...phone,
        ...qrInputStrings,
      });
    };

    const onSubmit = () => {
      const permission = setFieldPermission(fieldPermissionColor || 'red');

      if (validateRequiredFormField()) {
        let defaultValue: any = null;
        if (defaultvalueRef.current['defaultField']) {
          const value = defaultvalueRef.current['defaultField'].value;
          defaultValue = value !== '' ? value : null;
          
          if (fieldData.type === 'number') {
            defaultValue = !!defaultValue
              ? parseFloat(defaultValue.replace(/,/g, ''))
              : null;
          } else if (fieldData.type === 'bool') {
            // retain value
          } else if (defaultValue !== null && configData.format === 'phone') {
            const parsed = parsePhoneNumberFromString(
              defaultValue,
              phoneCountryCode as CountryCode
            );
            if (parsed !== null) {
              defaultValue = parsed!.number;
            }
          } else {
            defaultValue = defaultValue || null;
          }
        }

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: mandatory,
            label: fieldNameRef.current.value,
            defaultValue: defaultValue,
            hint: helpRef.current.value,
            config: inputObject(),
            multiline: isMultiline,
            confidential: confidential,
            permission,
          },
        };
        if (props.formType === 'User') {
          data.data.searchable = searchable;
        } 
        props.submit(data, props.fieldId || '');
      }
    };

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      if (props.formType === 'User' || props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
      }

      setError(currentError);
      return (
        !currentError.fieldname &&
        !currentError.sourceBp &&
        !currentError.maxLength &&
        !currentError.minLength &&
        !currentError.displayField
      );
    };

    const showMultiline = (): boolean => {
      return (
        props.formType === 'FormBuilder' &&
        fieldData.configName !== IConfigName.Checklist &&
        fieldData.configName !== IConfigName.Switch &&
        fieldData.configName !== IConfigName.FileUpload &&
        fieldData.configName !== IConfigName.Signature &&
        !props.forceNoMultiline
      );
    };

    const showQRInput = (): boolean => {
      return (
        props.formType === 'FormBuilder' &&
        (fieldData.configName === IConfigName.Email ||
          fieldData.configName === IConfigName.URL)
      );
    };

    const showDefaultValue = (): boolean => {
      return (
        fieldData.configName !== IConfigName.Signature &&
        fieldData.configName !== IConfigName.FileUpload &&
        !!fieldData.configName
      );
    };

    const showSearchable = (): boolean => {
      return (
        fieldData.configName === IConfigName.TextString ||
        fieldData.configName === IConfigName.Email ||
        fieldData.configName === IConfigName.URL ||
        fieldData.configName === IConfigName.Number
      );
    };

    const setFieldPermission = (permissionColor: string) => {
      const colorPermission = userFieldPermissionList.filter(
        (permission: IUserFieldPermission) =>
          permission.name === permissionColor
      );
      const permission =
        colorPermission.length > 0 ? colorPermission[0].id : undefined;
      return permission;
    };

    const setRefInputValues = (
      config: ICustomFieldDetailConfig,
      data: ICustomFieldDetail
    ) => {
      fieldNameRef.current.value = data.label || '';
      helpRef.current.value = data.hint || '';

      let defaultValue = data.defaultValue; 

      if(config.phoneCountryCode && defaultValue) {
        const parsed = parse(defaultValue, config.phoneCountryCode as CountryCode); 
        defaultValue = parsed.phone; 
      } 
      
      if (!defaultvalueRef.current['defaultField']) {
        defaultvalueRef.current['defaultField'] = {};
      }

      defaultvalueRef.current['defaultField'].value = defaultValue;

      maxLengthRef.current.value =
        config.max?.toString() || config.maxLength?.toString() || '';
      minLengthRef.current.value =
        config.min?.toString() || config.minLength?.toString() || '';
      decimalRef.current.value = config.decimals?.toString() || '';
    };

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        const colorPermission = userFieldPermissionList.filter(
          (permission: IUserFieldPermission) =>
            permission.id === data.permission
        );
        const permissionColor =
          colorPermission.length > 0 ? colorPermission[0].name : undefined;

        setRefInputValues(config, data);
        setFieldData(data);
        setConfigData(config);
        setFieldPermissionColor(permissionColor || 'red');
        setCommaSeparated(config.thousandSeparator || false);
        setSearchable(data.searchable ? true : false);
        setAlignment(config.alignment || 'left');
        setMandatory(data.required ? true : false);
        setIsMultiline(data.multiline ? true : false);
        setStringCase(config.case || 'case-sensitive');
        setQRInput(config.qrInput ? true : false);
        setPercentage(config.percent ? true : false);
        setConfidential(data.confidential || false);
        setFieldStyle(config.style || {});
        setPhoneCountryCode(config.phoneCountryCode || 'PH');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData]);

    return (
      <>
        <Modal
          dimmer="blurring"
          size="large"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={props.fieldId ? true : props.open}
          onClose={() => props.close()}
          id="custom-field-modal"
          centered={false}
          closeIcon={
            props.isViewOnly ? (
              <Image id="field-close-img" src={icons.other.circleCancel} />
            ) : null
          }
        >
          {props.loading && (
            <Dimmer active inverted>
              <Loader active content="Loading" />
            </Dimmer>
          )}
          <Modal.Header className="borderless" id="custom-field-modal-header">
            <Segment className={`borderless bg-primary`}>
              <h4>{`${fieldData.configName || 'Custom'} Field Setup`}</h4>
            </Segment>
          </Modal.Header>
          <Modal.Content>
            <div id="custom-field-message">
              <ActionMessage messages={UserFieldErrorMessage} />
            </div>
            <Segment
              className="borderless"
              style={{
                marginTop: `${
                  props.formType === 'FormBuilder' ? '-20px' : '2rem'
                }`,
              }}
            >
              <Form size="big" id="custom-field-form">
                <div className="header-field">
                  {props.formType === 'User' && (
                    <span className="field-visibility">
                      Visibility&nbsp;&nbsp;&nbsp;
                      <Dropdown
                        popperContainer={DropdownPopperOption}
                        selection
                        value={fieldPermissionColor || 'red'}
                        options={fieldVisibility}
                        selectOnBlur={false}
                        onChange={(event, target: any) => {
                          setFieldPermissionColor(target.value);
                        }}
                      />
                    </span>
                  )}
                  {props.formType !== 'FormBuilder' && (
                    <div
                      className="five wide field mandatory-field"
                      onClick={() => {
                        setMandatory(!mandatory);
                      }}
                    >
                      <span>Mandatory</span>
                      <div className="ui fitted toggle checkbox">
                        <input
                          className="hidden"
                          onChange={() => {}}
                          type="checkbox"
                          checked={mandatory}
                          value=""
                        />
                        <label></label>
                      </div>
                    </div>
                  )}
                  {props.formType === 'User' && showSearchable() && (
                    <div
                      className="five wide field searchable-field"
                      onClick={() => {
                        setSearchable(!searchable);
                      }}
                    >
                      <span>Searchable</span>
                      <div className="ui fitted toggle checkbox">
                        <input
                          className="hidden"
                          onChange={() => {}}
                          type="checkbox"
                          checked={searchable}
                          value=""
                        />
                        <label></label>
                      </div>
                    </div>
                  )}
                </div>
                {(props.formType === 'User' ||
                  props.formType === 'FormBuilder') && (
                  <Form.Group widths="equal">
                    <div
                      className={`required six wide field ${
                        error.fieldname ? 'error' : ''
                      }`}
                    >
                      <label>Field Name</label>
                      <div className="ui input">
                        <input
                          onChange={() => {
                            error.fieldname = fieldNameRef.current.value
                              ? false
                              : true;
                          }}
                          ref={fieldNameRef}
                          type="text"
                        />
                      </div>
                    </div>
                  </Form.Group>
                )}
                <Form.Group widths="equal">
                  <div className="field">
                    <label>Field Info</label>
                    <textarea rows={3} ref={helpRef}></textarea>
                  </div>
                </Form.Group>
                {showDefaultValue() && (
                  <Form.Group widths="equal">
                    <DefaultField
                      defaultvalueRef={defaultvalueRef}
                      configData={configData}
                      fieldData={fieldData}
                      inputObject={inputObject}
                      minLengthRef={minLengthRef}
                      maxLengthRef={maxLengthRef}
                      refreshValue={refreshState}
                      showDefaultValue={showDefaultValue()}
                    />
                  </Form.Group>
                )}
                <Properties
                  fieldData={fieldData}
                  configData={configData}
                  handleClick={handleClick}
                  minLengthRef={minLengthRef}
                  maxLengthRef={maxLengthRef}
                  alignment={alignment}
                  decimalRef={decimalRef}
                  commaSeparated={commaSeparated}
                  setCommaSeparated={setCommaSeparated}
                  setRefreshState={setRefreshState}
                  refreshState={refreshState}
                  setAlignment={setAlignment}
                  activeIndex={activeIndex}
                  error={error}
                  stringCase={stringCase}
                  setStringCase={setStringCase}
                  QRInput={QRInput}
                  setQRInput={setQRInput}
                  percentage={percentage}
                  setPercentage={setPercentage}
                  setFieldStyle={setFieldStyle}
                  style={fieldStyle}
                  setPhoneCountryCode={setPhoneCountryCode}
                  phoneCountryCode={phoneCountryCode}
                />
                {showMultiline() && (
                  <Form.Group widths="equal">
                    <div>
                      <div id="custom-field-checkbox">
                        <div
                          className="ui fitted checkbox"
                          onClick={() => setIsMultiline(!isMultiline)}
                        >
                          <input
                            className="hidden"
                            type="checkbox"
                            onChange={() => {}}
                            checked={isMultiline}
                            value=""
                          />
                          <label>Multi-line</label>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                )}
                {showQRInput() && (
                  <Form.Group widths="equal">
                    <div>
                      <div id="custom-field-checkbox">
                        <div
                          className="ui fitted checkbox"
                          onClick={() => setQRInput(!QRInput)}
                        >
                          <input
                            className="hidden"
                            type="checkbox"
                            onChange={() => {}}
                            checked={QRInput}
                            value=""
                          />
                          <label>QR Input</label>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                )}
                <Form.Group widths="equal">
                  <div>
                    <div id="custom-field-checkbox">
                      <div
                        className="ui fitted checkbox"
                        onClick={() => setConfidential(!confidential)}
                      >
                        <input
                          className="hidden"
                          type="checkbox"
                          onChange={() => {}}
                          checked={confidential}
                          value=""
                        />
                        <label>Confidential</label>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions
            className={`borderless`}
            id="delete-custom-field-modal-footer"
          >
            {!props.isViewOnly && (
              <React.Fragment>
                <Button
                  color="grey"
                  className="btn-action"
                  onClick={() => props.cancel(props.fieldId)}
                >
                  {'Cancel'}
                </Button>
                {(!fieldData.flags ||
                  (fieldData.flags &&
                    fieldData.flags.indexOf('constraint') === -1)) &&
                  props.fieldId &&
                  props.formType === 'User' && (
                    <Button
                      color="red"
                      className="btn-action create-btn"
                      onClick={() => {
                        props.cancel(props.fieldId, true);
                        props.openDeleteModal(
                          props.fieldId,
                          fieldData.label || ''
                        );
                      }}
                    >
                      Delete
                    </Button>
                  )}
                {((fieldData.flags &&
                  fieldData.flags.indexOf('constraint') === -1) ||
                  !fieldData.flags) && (
                  <Button
                    color="green"
                    className="btn-action"
                    onClick={() => onSubmit()}
                  >
                    {props.fieldId ? 'Update' : 'Create'}
                  </Button>
                )}
              </React.Fragment>
            )}
          </Modal.Actions>
        </Modal>
      </>
    );
  };
}
export default DefaultFieldConfig;

import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import FieldConfigSlider from '../slider/field';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { formBuilderFieldType } from '../../../../../../../../../../common/field/type';
import { IConfigName, IFieldTypeObj } from '../../../../../../../../../../common/field/type/interface/field-type-object';
import { IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../../interface/field-permission';
import { ISection, ITablePermission } from '../../../../interface/section';
import TableField from './table';

interface IField {
  field: ICustomFieldDetail;
  permission: IStatusFieldPermission;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  section: ISection | ITablePermission;
  tablePermission?: ITablePermission[];
  sectionType: 'section' | 'table';
}

const Field: React.FC<IField> = (props) => {
  let fieldConfigName = props.field.configName;

  if (fieldConfigName === IConfigName.Referenced) {
    fieldConfigName = IConfigName.Dropdown;
  }

  const formFieldObj = formBuilderFieldType.filter((field: IFieldTypeObj) => field.defaultData.configName === fieldConfigName);

  let icon = undefined;
  let fieldType = '';
  if (formFieldObj && formFieldObj.length > 0) {
    icon = formFieldObj[0].icon.blue;
    fieldType = formFieldObj[0].defaultData.type;
  }

  if (fieldType === 'table') {
    return <TableField {...props} />
  }

  return (
    <div className={`status-field`}>
      <div className='field-icon'><Image src={icon} /></div>
      <div className='field-label'><span>{props.field.label}</span></div>
      <div
        id="slider-config-container"
        key={`permission-${props.permission.fieldId}`}>
        <FieldConfigSlider
          permission={props.permission}
          section={props.section}
          handlePermissionChange={props.handlePermissionChange}
          sectionType={props.sectionType}
        />
      </div>
    </div>
  )
}
export default Field;
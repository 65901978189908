import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Image } from 'semantic-ui-react-bpm';
import { FieldValue } from '../../../../../../../../../common/custom-field-config/filter-configuration/field-value';
import { IConfig, ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';
import { icons } from '../../../../../../../../../common/icons';
import { checkIfUUID } from '../../../../../../../../../common/utils/check-uuid-value';
import { hideValueAndFieldOption } from '../../../../../../../../../common/utils/field-operator';
import { FormEnumLabel } from '../../../../../../../../document-module/module/form/utils/manage-enum-id-label';
import { IPermissionFieldCondition } from '../../../interface/field-permission';
import { getRangeFieldValue } from './get-range-field-value';

interface IConditionFieldFilter {
  getCurrentBpField: Function;
  handleConfig: Function;
  getOperatorOption: Function;
  handleCompareField: Function;
  getSelectedField: Function;
  getReferenceBpDetail: Function;
  removeCondition: Function;
  condition: IPermissionFieldCondition;
  rowIndex: number;
  company: string;
  fieldData: ICustomFieldDetail;
  defaultvalueRef: any;
  configData: IConfig;
  isTablePermission: boolean;
  parentFormFieldId: string;
  currentFieldList: ICustomFieldDetail[];
}

const ConditionFieldFilter: React.FC<IConditionFieldFilter> = (props) => {
  const dispatch = useDispatch();
  const { getCurrentBpField, handleConfig, getOperatorOption, condition, rowIndex,
    getSelectedField, company, handleCompareField, fieldData, configData, getReferenceBpDetail,
    defaultvalueRef, removeCondition, isTablePermission, parentFormFieldId } = { ...props };

  const hideValueOption = hideValueAndFieldOption['value'].split('|').includes(condition.operator);
  const hideFieldOption = hideValueAndFieldOption['field'].split('|').includes(condition.operator);

  const handleThrowValueOutside = (valueId: string) => {
    let tempCurrentField: any = getSelectedField(condition);
    tempCurrentField = {
      ...tempCurrentField,
      id: valueId,
      inputConfig: {
        config: tempCurrentField.config,
        type: tempCurrentField.type
      }
    }
    if (tempCurrentField.type === 'range') {
      const tempValue = getRangeFieldValue(defaultvalueRef.current[valueId], valueId);
      handleConfig('compareValue', tempValue, rowIndex);
    } else {
      let tempValue = defaultvalueRef.current
        && defaultvalueRef.current[valueId]
        && defaultvalueRef.current[valueId].value
        ? defaultvalueRef.current[valueId].value
        : '';
      if (defaultvalueRef.current
        && defaultvalueRef.current[valueId]
        && typeof defaultvalueRef.current[valueId] === 'string') {
        tempValue = defaultvalueRef.current[valueId];
      }
      const fieldData = { [valueId]: tempValue };
      const fields = FormEnumLabel.getEnumIdLabel(fieldData, defaultvalueRef, [tempCurrentField]);
      tempValue = fields[valueId];
      handleConfig('compareValue', tempValue, rowIndex);
    }
  }

  const triggerOptionGet = () => {
    let trigger = false;
    const field = getSelectedField(condition);
    if (checkIfUUID(field.id as string)) {
      if (field.configName === IConfigName.Referenced) {
        trigger = true;
      }
      if (field.configName === IConfigName.Autopopulated
        && field.config && field.config.labelType === 'enum') {
        trigger = true;
      }
    }
    return trigger;
  }

  return <>
    <div className={`condition-field`} >
      <div className={`condition-config`}>
        <div className={`source-field-container`}>
          <Dropdown
            fluid
            selection
            clearable
            search
            selectOnBlur={false}
            placeholder='Select field'
            value={condition.fieldId}
            options={getCurrentBpField()}
            id='permission-config-source-enum'
            onChange={(event, target) => handleConfig('fieldId', target.value as string, rowIndex)}
          />
        </div>
        <Dropdown
          fluid
          selection
          clearable
          search
          placeholder='Operator'
          selectOnBlur={false}
          value={condition.operator}
          options={getOperatorOption(condition)}
          onChange={(event, target) => handleConfig('operator', target.value as string, rowIndex)}
        />
        <div className='selection-field-value-config'>
          <div className='selection-field-value'>
            {!hideValueOption && <label onClick={() => handleConfig('compareType', 'value', rowIndex)}
              className={`value-element ${condition.compareType === 'value' ? 'selected' : ''}`}>
              Value</label>}
            {!hideFieldOption && <label onClick={() => handleConfig('compareType', 'field', rowIndex)}
              className={`field-element ${condition.compareType === 'field' ? 'selected' : ''}`}>
              Field</label>}
            {!hideValueOption && condition.compareType === 'value' &&
              <div className={`condition-value`}>
                <FieldValue.Render
                  field={getSelectedField(condition)}
                  forwardedRef={defaultvalueRef}
                  dispatch={dispatch}
                  referenceBp={getReferenceBpDetail()}
                  companyId={company}
                  currentFormField={{
                    ...fieldData,
                    config: {
                      dataType: configData.dataType || '',
                    } as IConfig
                  }}
                  valueId={condition.id}
                  configType={isTablePermission && getSelectedField(condition).isTableField ? 'tableConfig' : undefined}
                  parentFormFieldId={isTablePermission && getSelectedField(condition).isTableField ? parentFormFieldId : undefined}
                  throwValueOutside={() => handleThrowValueOutside(condition.id)}
                  fromPermission={triggerOptionGet()}
                  currentFieldList={props.currentFieldList}
                />
              </div>
            }
            {!hideFieldOption && condition.compareType === 'field' &&
              <Dropdown
                fluid
                selection
                search
                clearable
                selectOnBlur={false}
                placeholder='Select field'
                options={getCurrentBpField(condition)}
                value={condition.compareField.fieldId}
                onChange={(event, target) => handleCompareField('fieldId', target.value as string, rowIndex)}
              />
            }
          </div>
        </div>
        <div className='configure-condition'>
          <Image onClick={() => removeCondition(rowIndex)} className='icon-config' src={icons.blue.delete} />
        </div>
      </div>
    </div>
  </>
}

export default ConditionFieldFilter;
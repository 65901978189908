import { AdminHistoryCategory } from './history/interface/history';

export const adminSubModules = [
  {
    id: 'users',
    name: 'Users',
    route_label: 'USERS'
  },
  // {
  //   id: 'groups',
  //   name: 'Groups',
  //   route_label: 'GROUPS'
  // },
  {
    id: 'business-process',
    name: 'Business Process',
    route_label: 'BUSINESS PROCESS'
  },
  {
    id: 'apps',
    name: 'Apps',
    route_label: 'APPS',
    categoryLogs: ['Apps']
  },
  {
    id: 'data-tables',
    name: 'Data Tables',
    route_label: 'DATA TABLES',
  }
];

export const businessProcessSubModules = [
  {
    id: 'form-builder',
    name: 'Form Builder',
    route_label: 'FORM BUILDER',
    categoryLogs: [
      AdminHistoryCategory.FormBuilder,
      AdminHistoryCategory.BusinessProcess
    ]
  },
  {
    id: 'workflow',
    name: 'Workflow',
    route_label: 'WORKFLOW',
    categoryLogs: [AdminHistoryCategory.Workflow]
  },
  {
    id: 'roles',
    name: 'Roles',
    route_label: 'ROLES',
    categoryLogs: [AdminHistoryCategory.Roles]
  },
  {
    id: 'permissions',
    name: 'Permissions',
    route_label: 'PERMISSIONS',
    categoryLogs: [
      AdminHistoryCategory.PermissionsFieldPermissions,
      AdminHistoryCategory.PermissionsDynamicActions
    ]
  },
  {
    id: 'configuration',
    name: 'Configuration',
    route_label: 'CONFIGURATION',
    categoryLogs: []
  },
  {
    id: 'automation',
    name: 'Automation',
    route_label: 'AUTOMATION',
    categoryLogs: [AdminHistoryCategory.ConfigurationsAutomation]
  },
];
export enum ISenderType {
  User = 'user',
  Scheduler = 'scheduler',
  Automation = 'automation'
}

export interface ISenderDetail {
  active: boolean;
  avatar: string;
  email: string;
  firstName?: string;
  id: string;
  lastName?: string;
  name?: string;
}

export interface ISenderInfo {
  senderUserInfo: ISenderDetail;
  sender: ISender;
}

export interface ISender {
  type: ISenderType,
  data: ISenderDetail;
}
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_FIELD_LIST } from './constant';
import { getRequest, IAxiosResponse } from '../api-request';
import { getFieldListReturnAction } from './action';
import { IFieldGetInput } from './interface/input/field-get';

export function* getFieldList(args: IFieldGetInput) {
  try {
    const company = args.company;
    let req: IAxiosResponse = { data: { data: [] } };
    if (args.dataType === 'database') {
      const databaseId = args.databaseId;
      if (databaseId) req = yield call(getRequest, `${company}/databases/${databaseId}/fields`, {});
    }
    if (args.dataType === 'document') {
      const formId = args.formId;
      if (formId) req = yield call(getRequest, `${company}/forms/${formId}/fields?draft=true`, {});
    }
    yield put(getFieldListReturnAction({ displayFieldList: req.data.data }))
  } catch (e) {
    console.log(e.response || e);
  }
}

export function* sagas() {
  yield takeLatest(GET_FIELD_LIST, getFieldList);
}

// All sagas to be loaded
export default sagas;

import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { ManageAdminHistory } from '../../../manage-history';
import { FieldConfigChange } from '../../utils/field-config-change';

export class GeolocationDeltaFields implements DeltaFieldType {

  name = 'geolocation';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta } = FieldConfigChange.targetDelta(targetDelta);
    const { newDelta, oldDelta } = this.setDelta(delta, targetInstance);

    deltaArray = FieldConfigChange.format(newDelta, oldDelta, targetInstance);

    return deltaArray;
  }

  setDelta = (delta: Delta, oldValue: any) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'defaultValue') {
        Object.keys(delta[key]).forEach(childKey => {
          const newKey = ManageAdminHistory.uppercase(childKey);
          newDelta[`DefaultValue: ${newKey}`] = delta[key][childKey];
          oldDelta[`DefaultValue: ${newKey}`] = oldValue[key][childKey];
        });
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = oldValue[key];
      }
    });

    return { newDelta, oldDelta };
  }
}
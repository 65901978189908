import React from 'react';
import { icons } from '../../../../../../../../../common/icons';
import { Image } from 'semantic-ui-react-bpm';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';
import { IColumnView, IDragColumnView } from '../../document-list/interface/custom-view';

interface IFieldItemProps {
  field: IColumnView;
  allowDrop?: boolean;
  arrangeSequence?(dragItem: IDragColumnView, field: IColumnView): void;
  onClick?(field: IColumnView): void;
  deleteColumn(field: IColumnView): void;
}

const Field: React.FC<IFieldItemProps> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'column-field',
    drop: (selectedItem: IDragColumnView) => {
      if (props.arrangeSequence && selectedItem.data.new !== true) {
        props.arrangeSequence(selectedItem, props.field);
      }
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'column-field', data: props.field },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  return (
    <div ref={dragRef} style={draggedObject} className='field-item'>
      < div ref={dropRef} className={`field`} >
        <Image src={icons.blue.dragIcon}
          style={{ width: '10px', float: 'left', marginTop: '5px' }}
        />
        <Image style={props.field.iconStyle} src={props.field.icon} className={`icon`}
        />
        <label title={`${props.field.label}`}>{props.field.label}</label>
        <Image src={icons.blue.delete}
          style={{ width: '12px', float: 'right', marginTop: '2px', cursor: 'pointer' }}
          onClick={() => props.deleteColumn(props.field)}
        />
        <Image src={icons.blue.edit}
          onClick={() => props.onClick ? props.onClick(props.field) : {}}
          style={{ width: '15px', float: 'right', marginTop: '2px', marginRight: '3px', cursor: 'pointer' }}
        />
      </div>
    </div>
  );
}

export default Field;
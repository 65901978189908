import Axios from 'axios';
import ajv from 'ajv';

export interface UploadedFile {
  id: string;
  name: string;
  type: string;
  size: number;
  url: string;
}

export function UploadedFileIsValid(file: UploadedFile): boolean {
  const validator = ajv();
  return validator.validate({
    type: 'object',
    required: ['id', 'name', 'type', 'size', 'url'],
    properties: {
      id: {
        type: 'string'
      },
      name: {
        type: 'string'
      },
      type: {
        type: 'string'
      },
      size: {
        type: 'number'
      },
      url: {
        type: 'string'
      }
    }
  }, file) as boolean;
}

export interface UploadFileRequest {
  /**
   * If not specified, it would try to upload to 'main' bucket, if user has permissions for that
   * */
  companyId?: string;

  file: File;

  progress?: (percent: number) => void;

  abortHandler?: (abort: () => void) => void;
}

export async function uploadFile(request: UploadFileRequest): Promise<UploadedFile> {
  /**
   * Getting signed URL for file uploading and prepare backend
   */
  const url = request.companyId ? `/${request.companyId}/uploads/prepare` : `/uploads/prepare`;

  const prepared = await Axios.post(url, undefined, {
    params: {
      name: request.file.name,
      type: request.file.type,
      size: request.file.size
    },
  });

  /**
   * Upload to S3 directly
   */
  const uploadPromise = new Promise((resolve, reject) => {

    const xhr = new XMLHttpRequest();
    xhr.open(prepared.data.data.method, prepared.data.data.request);
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (request.progress) {
        request.progress(event.loaded / event.total);
      }
    }

    if (request.abortHandler) {
      request.abortHandler(() => {
        xhr.abort();
      });
    }
    const handleError = () => {
      let errorMessage = '';
      // Parsing a message from AWS
      if (xhr.responseText && xhr.getResponseHeader('Content-Type') === 'application/xml') {
        const parser = new DOMParser();
        const parsedAwsBody = parser.parseFromString(xhr.responseText, "text/xml");
        const msg = parsedAwsBody.getElementsByTagName('Message');
        if (msg.length > 0) {
          errorMessage = `Message: "${msg[0].firstChild?.nodeValue}"`
        }
      }
      reject(new Error(`Upload failed with status ${xhr.status}, ${xhr.statusText}. ${errorMessage}`));
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response)
        }
        else {
          console.log(`Status is not 200: `, xhr.statusText, ` `, xhr.status)
          handleError();
        }
      }
    };
    xhr.onerror = (e: ProgressEvent) => {
      handleError();
    }
    xhr.send(request.file);
  });

  await uploadPromise;


  if (request.progress) {
    request.progress(1.0);
  }

  return {
    id: prepared.data.data.fileId,
    name: request.file.name,
    type: request.file.type,
    size: request.file.size,
    url: prepared.data.data.url,
  };
}
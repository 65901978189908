import React, { useEffect, useState } from 'react';
import { Icon, Image } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { formBuilderFieldType } from '../../../../../../../../../../common/field/type';
import { IFieldTypeObj } from '../../../../../../../../../../common/field/type/interface/field-type-object';
import { IPermissionFieldFilterConfig, IStatusFieldPermission } from '../../../../interface/field-permission';
import { ISection, ITablePermission } from '../../../../interface/section';
import SectionBody from '../section/body';
import FieldConfigSlider from '../slider/field';

interface ITableField {
  field: ICustomFieldDetail;
  permission: IStatusFieldPermission;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  section: ISection | ITablePermission;
  tablePermission?: ITablePermission[];
}

const TableField: React.FC<ITableField> = (props) => {
  const [expandTable, setExpandTable] = useState(false);

  const formFieldObj = formBuilderFieldType.filter((field: IFieldTypeObj) => field.defaultData.configName === props.field.configName);
  let icon = undefined;
  let tablePermission = {} as ITablePermission;

  if (props.tablePermission && props.tablePermission.length > 0 && props.tablePermission[0]) {
    tablePermission = props.tablePermission[0];
  }

  if (formFieldObj && formFieldObj.length > 0) {
    icon = formFieldObj[0].icon.blue;
  }

  const accessLevelPerTable = (typeof tablePermission.accessLevelPerTable !== 'undefined'
    && tablePermission.accessLevelPerTable !== '');

  useEffect(() => {
    setExpandTable(!accessLevelPerTable);
  }, [accessLevelPerTable])

  return <div className={`table-field-container ${tablePermission && tablePermission.fields &&
    tablePermission.fields.length === 0 ? 'b-dashed' : ''}`}>
    <div className={`status-field table`}>
      <div className='field-icon'><Image src={icon} /></div>
      <div className={`field-label table ${accessLevelPerTable ? 'width' : ''}`}><span>{props.field.label}</span></div>

      <div id="slider-config-container" className={`table-level ${!accessLevelPerTable ? 'no-width' : ''}`}
        key={`permission-${props.permission.fieldId}`}>
        <div className="ui fitted checkbox" onClick={() => {
          let accessLevel = 'editable';
          if (accessLevelPerTable) {
            accessLevel = ''
          }
          props.handlePermissionChange(props.section, props.permission.fieldId, accessLevel, undefined, 'section');
          setExpandTable(accessLevelPerTable);
        }}>
          <input className="hidden" type="checkbox" onChange={() => { }} checked={accessLevelPerTable} />
          <label>{!accessLevelPerTable ? 'Set Access Level Per Table' : ''}</label>
        </div>
        {accessLevelPerTable &&
          <FieldConfigSlider
            permission={props.permission}
            section={props.section}
            handlePermissionChange={props.handlePermissionChange}
            sectionType='section'
          />}
      </div>
      {tablePermission && tablePermission.fields && tablePermission.fields.length > 0 &&
        <Icon name='dropdown' className={expandTable ? 'expand' : ''} onClick={() => setExpandTable(!expandTable)} />
      }
    </div>
    {expandTable &&
      <SectionBody
        sectionType='table'
        section={tablePermission}
        handlePermissionChange={props.handlePermissionChange}
        openFieldPermissionSettings={props.openFieldPermissionSettings}
      />
    }
  </div>
}

export default TableField;
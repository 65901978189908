import React, { useState, useEffect } from 'react';
import { Segment, Header, Image, Portal } from 'semantic-ui-react-bpm';
import { icons } from '../icons';
import Axios, { AxiosPromise, Method } from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../reducers';
import { clearDownloadRequestsAction } from '../../component/main/action';

interface IDownloadWidget {
  open?: boolean;
  pathname?: string;
  close?(): void;
}

export interface DownloadFileRequest {
  url: string;
  method?: Method;
  data?: any;
  match?: { params: { submodule: string } }
  progress?: (percent: number) => void;
  downloadName?: string;
  headers?: any;
}

const RoundProgressBar = (props: {
  size: number,
  value: number,
  max: number,
  strokeWidth: number,
}) => {
  const size = props.size;
  const radius = (props.size - props.strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - dashArray * props.value / props.max;

  return (
    <svg width={props.size} height={props.size} viewBox={viewBox}>
      <defs>
        <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop offset="15%" stopColor="#e24640" />
          <stop offset="50%" stopColor="#fed26a" />
          <stop offset="95%" stopColor="#589db1" />
        </linearGradient>
      </defs>
      <circle
        fill={'none'}
        stroke={'#b7b7b7'}
        cx={props.size / 2}
        cy={props.size / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`} />
      <circle
        fill={'none'}
        stroke="url(#gradient)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        cx={props.size / 2}
        cy={props.size / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`}
        transform={`rotate(-90 ${props.size / 2} ${props.size / 2})`}
      />
    </svg>
  );
}

const DownloadWidget: React.FC<IDownloadWidget> = (props) => {
  const dispatch = useDispatch();
  const { downloadRequests, currentRoute } = useSelector((state: IRootState) => state.main);
  const [collapse, setCollapse] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0 as number);
  const [downloadRequestData, setDownloadRequestData] = useState([] as DownloadFileRequest[]);

  const downloadFiles = async (fileRequests: AxiosPromise<any>[], fileName: string[], progressCallback: Function) => {
    let progress = 0;
    progressCallback(0);
    fileRequests.forEach(req => req.then(() => {
      progress++;
      progressCallback((progress * 100) / fileRequests.length);
    }));
    return await Promise.all(fileRequests).then((responses) => {
      if (fileRequests.length === responses.length) {
        responses.forEach((response, index) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName[index]}-data.xlsx`);
            document.body.appendChild(link);
            link.click();
          }, 1000);
        });
      }
    })
  }

  const onDownload = async (downloadRequests: DownloadFileRequest[]) => {
    let fileRequests: AxiosPromise<any>[] = [];
    let fileName: string[] = [];
    for (let requests of downloadRequests) {
      const name = (requests.downloadName && requests.downloadName.toLowerCase().replace(/ /g, '-')) || currentRoute;
      fileName.push(name);
      fileRequests.push(
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(Axios({
              method: requests.method,
              url: `${requests.url}`,
              data: requests.data,
              headers: requests.headers,
              responseType: 'arraybuffer'
            }));
          }, 1000);
        })
      )
    }

    downloadFiles(fileRequests, fileName, (progress: any) => {
      setDownloadProgress(progress);
    })
  }

  useEffect(() => {
    onDownload(downloadRequestData);
    // eslint-disable-next-line
  }, [downloadRequestData])

  useEffect(() => {
    setDownloadRequestData(downloadRequests);
    // eslint-disable-next-line
  }, [downloadRequests.length])

  useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        dispatch(clearDownloadRequestsAction());
        setDownloadProgress(0);
        setDownloadRequestData([]);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [downloadProgress])

  return <Portal
    open={downloadRequests.length > 0}
  >
    <Segment style={{
      top: `${collapse ? 'calc(100vh - 50px - 34px )' : 'calc(100vh - 165px)'}`
    }} id='download-widget'>
      <Header>
        Download Progress
        <div className='header-icon-group'>
          <Image onClick={() => setCollapse(!collapse)} className='icon-collapse' src={collapse ? icons.white.iconCollapse : icons.white.iconNotCollapse} />
          <Image onClick={() => props.close && props.close()} className='icon-close' src={icons.white.closePlain} />
        </div>
      </Header>
      {!collapse && (
        <div className='content'>
          <Image className='file-icon' src={icons.black.reportMenu} />
          {downloadProgress === 100 ?
            <span>{`${downloadRequestData.length} ${downloadRequestData.length === 1 ? 'file' : 'files'} downloaded`}</span>
            :
            <span>{`Downloading ${downloadRequestData.length} ${downloadRequestData.length === 1 ? 'file' : 'files'}`}</span>
          }
          <span className='progress-label'>{downloadProgress}%</span>
          <div className='progress-container'>
            <RoundProgressBar
              value={downloadProgress}
              max={100}
              size={40}
              strokeWidth={7}
            />
          </div>
        </div>
      )}
    </Segment>
  </Portal>
}

export default DownloadWidget;
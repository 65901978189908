import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse } from '../../../../common/api-request';
import {
  getStatusSummaryReturnAction,
  getStatusSummaryErrorAction,
  getBPLogoErrorAction,
  getBPLogoReturnAction,
  getBPStatusListReturnAction,
  getBPStatusListErrorAction
} from './action';
import { setStatusCodeAction } from '../../../error/action';
import { GET_STATUS_SUMMARY, GET_BUSINESS_PROCESS_LOGO, GET_BP_STATUS_LIST } from './constant';
import { IStatusSummaryInput } from './interface/input/summary';
import { IFormLogoGetInput } from '../../../admin-module/module/business-process/module/form/interface/input/form-logo-get';
import { IBPStatusListInput } from './interface/input/bp-status';

export function* getStatusSummary(args: IStatusSummaryInput) {
  try {
    let company = args.company;
    let appId = args.appId;
    let formId = args.formId;
    let request: IAxiosResponse = { data: { data: undefined } };

    if (((!formId && appId) || (formId && appId)) && company) {
      request = yield call(getRequest, `${company}/summary/forms`, {});
    }
    if (formId && !appId && company) {
      if (formId.indexOf(',') > -1) {
        request = yield call(getRequest, `${company}/summary/forms`, {});
      } else {
        request = yield call(getRequest, `${company}/summary/forms/${formId}`, {});
      }
    }
    if (!appId && !formId && company) {
      request = yield call(getRequest, `${company}/summary/apps`, {});
    }


    yield put(getStatusSummaryReturnAction({ statusSummary: request.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
    yield put(getStatusSummaryErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getBusinessProcessLogo(args: IFormLogoGetInput) {
  try {
    const fileId = args.fileId;
    const req: IAxiosResponse = yield call(getRequest, `/uploads/${fileId}`, { responseType: 'blob' });
    yield put(getBPLogoReturnAction({ file: URL.createObjectURL(req.data) }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getBPLogoErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* getBPStatusList(args: IBPStatusListInput) {
  try {
    const formId = args.formId;
    const company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/forms/${formId}/statuses?draft=false`, {})
    yield put(getBPStatusListReturnAction({ statusList: req.data.data }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(getBPStatusListErrorAction(JSON.stringify(e.response.data.message)));
  }
}

export function* workspaceSagas() {
  yield takeLatest(GET_STATUS_SUMMARY, getStatusSummary);
  yield takeLatest(GET_BUSINESS_PROCESS_LOGO, getBusinessProcessLogo);
  yield takeLatest(GET_BP_STATUS_LIST, getBPStatusList);
}

// All sagas to be loaded
export default workspaceSagas;
import { useEffect } from 'react';
import message from '../../message';

interface IProps {
  history: any;
  createDataTableMessage: string;
  updateDataTableMessage: string;
}

export const useRedirectFormToList = (props: IProps): void => {
  const { createDataTableMessage, updateDataTableMessage, history } = { ...props };

  useEffect(() => {
    if (createDataTableMessage === message.createDataTableSuccess) {
      history.push('/admin/data-tables');
    }
    if (updateDataTableMessage === message.updateDataTableSuccess) {
      history.push('/admin/data-tables');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDataTableMessage, updateDataTableMessage]);
}
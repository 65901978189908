import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface IRouteMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: IRouteMatchPrams;
}

interface IRouteMatchPrams {
  formId: string;
}

const AutomationRedirect: React.FC = () => {
  const history = useHistory();
  const match: IRouteMatch = useRouteMatch();

  useEffect(() => {
    history.push(`/admin/business-process/automation/${match.params.formId}/automation-rules`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>This will redirect to /general-setup</>
  );
}

export default AutomationRedirect;
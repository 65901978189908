import { IOption } from '../../interface/option';
import { IForm } from '../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../component/admin-module/module/data-tables/reducer';
import {
  dropdownOptionStyle,
  dropdownLabelStyle,
  bpListTitleOption,
  dtListTitleOption,
  systemListTitleOption
} from '../constant';
import { icons } from '../../icons';

interface IGenerateSourceOption {
  publishedForm: IForm[];
  datatable: IDataTable[];
  datatableDetail?: IDataTable;
  formDetail: IForm | IDataTable;
  formType: 'User' | 'Datatable' | 'FormBuilder' | 'EmbeddedTable',
  dataType?: string;
  includeNameOnValue?: boolean;
}

export const generateSourceOption = (data: IGenerateSourceOption): IOption[] => {
  let addDTOptions = {
    image: { src: icons.blue.datatable, style: { maxWidth: '22px' } },
    style: dropdownOptionStyle,
  };

  let options = [] as IOption[];
  let ctr = 0;

  options.push(systemListTitleOption);

  if (data.formType === 'EmbeddedTable' || data.formType === 'FormBuilder' || (data.formType === 'Datatable' && data.dataType === 'user')) {
    options.push({
      value: data.includeNameOnValue ? 'user|||||none|||||Users' : 'user',
      key: ctr++,
      text: 'Users',
      className: 'source-item',
      ...addDTOptions
    });
  }

  if (data.formType === 'EmbeddedTable' || data.formType === 'FormBuilder' || data.formType === 'User' ||
    (data.formType === 'Datatable' && data.dataType === 'form')) {
    options.push({
      value: data.includeNameOnValue ? 'form|||||none|||||Business Processes' : 'form',
      key: ctr++,
      text: 'Business Processes',
      className: 'source-item',
      ...addDTOptions
    });
  }

  if (data.datatable && data.datatable.length > 0) {
    options.push(dtListTitleOption);
    data.datatable.forEach((datatable: IDataTable) => {
      if (data.datatableDetail === undefined || (data.datatableDetail && data.datatableDetail.id !== datatable.id)) {
        options.push({
          value: `database|||||${datatable.id || ''}${data.includeNameOnValue ? `|||||${datatable.name}` : ''}`,
          key: ctr++,
          text: datatable.name,
          className: 'source-item',
          ...addDTOptions
        });
      }
    })
  }
  if (data.publishedForm && data.publishedForm.length > 0) {
    options.push(bpListTitleOption);
    data.publishedForm.forEach((form: IForm) => {
      if (data.formDetail === undefined || (data.formDetail && data.formDetail.id !== form.id)) {
        let dataType = 'document';
        if (!form.hasOwnProperty('abbr')) {
          dataType = 'database';
        }
        let addFormOptions = {
          image: form.icon ? { src: form.icon, style: { maxWidth: '22px' }, } : undefined,
          label: !form.icon ? { content: form.seq, style: dropdownLabelStyle } : undefined,
          style: dropdownOptionStyle
        };
        options.push({
          value: `${dataType}|||||${form.id || ''}${data.includeNameOnValue ? `|||||${form.name}` : ''}`,
          key: ctr++,
          text: form.name,
          className: 'source-item',
          ...addFormOptions
        });
      }
    })
  }
  return options;
}
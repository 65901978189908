export const regularFieldOperator: { [fieldType: string]: string } = {
  bool: 'equal|not-equal',
  file: 'is-empty|is-not-empty',
  date: 'is-empty|is-not-empty|equal|not-equal|less|more|more-or-equal|less-or-equal',
  time: 'is-empty|is-not-empty|equal|not-equal|less|more',
  enum: 'is-empty|is-not-empty|equal|not-equal|any-in|starts-with|ends-with|contains|does-not-contain',
  'multiple-enum':
    'is-empty|is-not-empty|equal|not-equal|includes|excludes|any-in|starts-with|ends-with|contains|does-not-contain',
  number:
    'is-empty|is-not-empty|more|more-or-equal|equal|less|less-or-equal|not-equal',
  string:
    'is-empty|is-not-empty|contains|does-not-contain|starts-with|ends-with|equal|not-equal',
  id: 'any-in|excludes',
  'date-time': 'is-empty|is-not-empty|equal|not-equal|less|more',
  fx: 'is-empty|is-not-empty|more|more-or-equal|equal|less|less-or-equal|not-equal',
  range:
    'is-empty|is-not-empty|more|more-or-equal|equal|less|less-or-equal|not-equal|includes|excludes|intersect|is-included|is-excluded',
};

export const currentBPOperator: { [fieldType: string]: string } = {
  bool: 'equal|not-equal',
  string:
    'is-empty|is-not-empty|contains|does-not-contain|starts-with|ends-with|equal|not-equal',
  date: 'equal|not-equal|less|less-or-equal|more|more-or-equal',
  time: 'equal|not-equal|less|more',
  enum: 'equal|not-equal',
  'multiple-enum': 'equal|not-equal|includes|excludes',
  number: 'more|more-or-equal|equal|less|less-or-equal|not-equal',
  id: 'any-in|excludes',
  fx: 'more|more-or-equal|equal|less|less-or-equal|not-equal',
  range:
    'is-empty|is-not-empty|more|more-or-equal|equal|less|less-or-equal|not-equal|includes|excludes|intersect|is-included|is-excluded',
  file: 'is-empty|is-not-empty',
};

export const hideValueAndFieldOption = {
  value: 'is-empty|is-not-empty|any-in',
  field: 'is-empty|is-not-empty',
};

export const statusFieldOperator = 'equal|not-equal|includes|excludes';

export const allowedFieldCompare: { [fieldType: string]: string } = {
  Autopopulated: '',
  Checklist: '',
  Timestamp: '',
  Date: '',
  Dropdown: '',
  Email: '',
  FileUpload: '',
  FX: '',
  Geolocation: '',
  Number: 'Number|FX|Text String',
  'Multiple Select': '',
  Referenced: '',
  Signature: '',
  Switch: '',
  'Text String Multiline': '',
  'Text String': '',
  Time: '',
  Title: '',
  URL: '',
  None: '',
  Range: '',
  Phone: '',
};

import React from 'react';
import { Segment, Container, Image, List } from 'semantic-ui-react-bpm';
import { useLocation } from 'react-router-dom';
import SandboxComponent from '../sandbox';

const FooterComponent: React.FC = () => {
  const location = useLocation();

  return <>
    {location.pathname.startsWith('/attachment') === false &&
      location.pathname.startsWith('/documents') === false &&
      <Segment vertical>
        <Container textAlign='center'>
          <Image src={require('../../mobbiz.png')} centered size='mini' />
          <List className='hidden' horizontal divided link size='small'>
            <List.Item as='a' href='#'>Site Map</List.Item>
            <List.Item as='a' href='#'>Contact Us</List.Item>
            <List.Item as='a' href='#'>Terms and Conditions</List.Item>
            <List.Item as='a' href='#'>Privacy Policy</List.Item>
          </List>

        </Container>
      </Segment>
    }
    <SandboxComponent />
  </>
}


export default FooterComponent;
import React from 'react';
import { Icon, Popup, Dropdown } from 'semantic-ui-react-bpm';
import { EnumExclusiveMode, EnumSelectAllMode } from '../../../../component/admin-module/module/users/interface/field';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { IEnumFieldConfig } from '../../interface/enum-field-config';
import { selectAllModeList, exclusiveModeList } from './constants';

interface IEnumConfigPopup extends IEnumFieldConfig {
  showAutopopulate?: boolean;
}

const EnumConfigPopup: React.FC<IEnumConfigPopup> = (props) => {
  const isUseReference = props.enumDataType === 'document' || props.enumDataType === 'database' ||
    props.enumDataType === 'referenced';

  const hasConfiguredProperty = () => {
    return (props.formType === 'User' && isUseReference)
      || props.isCreateRecord
      || props.isStaticSource
      || props.isMultiselect
      || props.QRInput
      || props.autopopulate
      || props.displayFieldFrom.showReferenceInSource
      || props.displayFieldFrom.showReferenceInDestination
      || props.confidential
  }

  return <div className={`enum-config`}>
    <label>Configuration</label>
    <Popup
      on='click'
      id='enum-config-popup'
      basic
      position='bottom left'
      trigger={
        <Icon name={`cog`} className={hasConfiguredProperty() ? 'active' : 'inactive'} />
      }
    >
      <div className='enum-config-item'>
        <span className='title'>Multiple Selection</span>
        <div className="ui fitted checkbox" onClick={() => {
          props.setIsMultiselect(!props.isMultiselect);
          props.setSelectAllMode(EnumSelectAllMode.None);
          props.setExclusiveMode(EnumExclusiveMode.None);
        }}>
          <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.isMultiselect} />
          <label></label>
        </div>
      </div>
      {props.isMultiselect && props.configName === IConfigName.Referenced &&
        <div className={`enum-config-item multiple-select`}>
          {props.enumGroupField && props.setUniqueGrouping &&
            <div className='dropdown-wrap'>
              <span className='title'>Unique grouping</span>
              <Dropdown
                inline
                options={[
                  {
                    key: 1,
                    text: 'Yes',
                    value: true,
                  },
                  {
                    key: 2,
                    text: 'No',
                    value: false,
                  },
                ]}
                value={props.uniqueGrouping}
                onChange={(event, target: any) => {
                  const value = target.value;
                  props.setUniqueGrouping!(target.value);
                  if (value) {
                    props.setSelectAllMode(EnumSelectAllMode.None);
                  }
                }}
              />
            </div>
          }
          <div className='dropdown-wrap'>
            <span className='title'>Select All Mode</span>
            <Dropdown
              inline
              options={selectAllModeList}
              value={props.selectAllMode}
              onChange={(event, target: any) => {
                const value = target.value;
                props.setSelectAllMode(target.value);
                if (value !== EnumSelectAllMode.None) {
                  props.setUniqueGrouping!(false);
                }
              }}
            />
          </div>
          <div className='dropdown-wrap'>
            <span className='title'>Exclusive Mode</span>
            <Dropdown
              inline
              options={exclusiveModeList}
              value={props.exclusiveMode}
              onChange={(event, target: any) => props.setExclusiveMode(target.value)}
            />
          </div>
        </div>
      }
      {props.configName === IConfigName.Referenced &&
        <div className='enum-config-item'>
          <span className='title'>Create record in source</span>
          <div className="ui fitted checkbox" onClick={() => {
            props.setIsCreateRecord(props.isCreateRecord ? false : true);
          }}>
            <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.isCreateRecord} />
            <label></label>
          </div>
        </div>
      }
      {props.configName === IConfigName.Referenced && !props.fieldData.isTableField &&
        <div className={`enum-config-item sub-checkbox`}>
          <span className='title'>Display Related Form</span>
          <div className='enum-config-item checkbox-wrap'>
            <span className='title'>In Current BP</span>
            <div className="ui fitted checkbox" onClick={() => {
              const showReferenceInSource = props.displayFieldFrom.showReferenceInSource;
              props.triggerDisplayFieldFrom('showReferenceInSource', !showReferenceInSource);
            }}>
              <input className="hidden" type="checkbox" onChange={() => { }}
                checked={!!props.displayFieldFrom.showReferenceInSource} />
              <label></label>
            </div>
          </div>
          <div className='enum-config-item checkbox-wrap'>
            <span className='title'>In Source BP</span>
            <div className="ui fitted checkbox" onClick={() => {
              const showReferenceInDestination = props.displayFieldFrom.showReferenceInDestination;
              props.triggerDisplayFieldFrom('showReferenceInDestination', !showReferenceInDestination);
            }}>
              <input className="hidden" type="checkbox" onChange={() => { }}
                checked={!!props.displayFieldFrom.showReferenceInDestination} />
              <label></label>
            </div>
          </div>
        </div>
      }
      {props.configName === IConfigName.Referenced &&
        <div className='enum-config-item'>
          <span className='title'>Static Source</span>
          <div className="ui fitted checkbox" onClick={() => {
            props.triggerStaticSource(props.isStaticSource ? false : true)
          }}>
            <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.isStaticSource} />
            <label></label>
          </div>
        </div>
      }
      <div className='enum-config-item'>
        <span className='title'>QR Input</span>
        <div className="ui fitted checkbox" onClick={() => props.setQRInput(!props.QRInput)}>
          <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.QRInput} />
          <label></label>
        </div>
      </div>
      {props.showAutopopulate && props.setAutopopulate &&
        <div className='enum-config-item'>
          <span className='title'>Autopopulate</span>
          <div className="ui fitted checkbox" onClick={() => {
            props.setAutopopulate(!props.autopopulate)
          }}>
            <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.autopopulate} />
            <label></label>
          </div>
        </div>
      }
      {(props.formType === 'User') &&
        <div className='enum-config-item'>
          <span className='title'>Use Reference</span>
          <div className={`ui fitted checkbox`} onClick={() => {
            props.setEnumDataType(isUseReference ? 'static' : 'referenced')
          }}>
            <input className="hidden" type="checkbox" onChange={() => { }} checked={isUseReference} />
            <label></label>
          </div>
        </div>
      }
      <div className='enum-config-item'>
        <span className='title'>Confidential</span>
        <div className="ui fitted checkbox" onClick={() => props.setConfidential(!props.confidential)}>
          <input className="hidden" type="checkbox" onChange={() => { }} checked={!!props.confidential} />
          <label></label>
        </div>
      </div>
    </Popup>
  </div>
}

export default EnumConfigPopup;
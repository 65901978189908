import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react-bpm';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import { backupMake, backupDownload, backupRestore } from '../../../../../../../common/api-request/backup';
import { clearExportBackupItem, clearImportExportMessage, importExportBPDTErrorAction, importExportBPDTSuccessAction } from '../action';
import ResponseMessage from '../../../../../../../common/general/response-message';
import LoaderComponent from '../../../../../../../common/general/loader';
import { useClearBackupItem } from './hooks/use-clear-backup-item';
import { useClearImportExportMessage } from './hooks/use-clear-export-message';
import ImportFile from './import-backup';
import ExportFile from './export-backup';

interface IMatch {
  params: { fileAction: string, formId: string };
}

interface IImportedFiles {
  id: string;
  name: string;
  type: string;
}

const FileModal: React.FC = () => {
  const match: IMatch = useRouteMatch();
  const history = useHistory();
  const { company } = useSelector((state: IRootState) => state.auth);
  const { importExportSuccessMessage, importExportErrorMessage, backupRefs, backupRefsLoading, selectedBackupItem } = useSelector((state: IRootState) => state.businessProcess);

  const [filesToImport, setFilesToImport] = useState<string[]>([]);
  const [importedFiles, setImportedFiles] = useState<IImportedFiles[]>([]);

  const [files, setFiles] = useState({} as any | null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isExport = (): boolean => {
    return match.params.fileAction === 'export';
  }

  const exportFile = async () => {
    try {
      let query = '';

      // Extract selected item
      for (const item of selectedBackupItem) {
        query += query.includes(`&${item.type}Ids=${item.id}`) ? '' : `&${item.type}Ids=${item.id}`
      }

      // Extract selected refrences
      for (const ref of backupRefs) {
        if (ref.requires) {
          for (const req of ref.requires) {
            // Remove duplicate
            query += query.includes(`&${req.type}Ids=${req.id}`) ? '' : `&${req.type}Ids=${req.id}`
          }
        } else {
          // Remove duplicate
          query += query.includes(`&${ref.type}Ids=${ref.id}`) ? '' : `&${ref.type}Ids=${ref.id}`
        }
      }

      setLoading(true);
      const response = await backupMake(company, query.substring(1));
      backupDownload(response.data);
      dispatch(importExportBPDTSuccessAction('Successfully exported.'))
      setLoading(false);
    } catch (error) {
      dispatch(importExportBPDTErrorAction(error.message));
    }
  }

  const importFile = async () => {
    try {
      let query: string = '';
      for (const value of filesToImport) {
        query += `&skipIds=${value}`
      }
      setLoading(true);
      await backupRestore(company, query.substring(1), files);
      dispatch(importExportBPDTSuccessAction('Successfully imported.'))
      setFiles({});
      setImportedFiles([]);
      setLoading(false);
      history.push('/admin/business-process/form')
    } catch (error) {
      dispatch(importExportBPDTErrorAction(error.message));
      setLoading(false);
    }
  }

  const processFile = async (file: any) => {
    const JSZip = require("jszip");
    let zipFiles = [];
    if (Object.keys(file).length > 0) {
      const jzip = new JSZip();
      const zip = await jzip.loadAsync(file);
      if (await zip.file('meta.json')) zipFiles = JSON.parse(await zip.file('meta.json').async("string"));
    }
    setImportedFiles(zipFiles);
    setFiles(file);
  }

  useClearImportExportMessage({
    dispatch,
    clearImportExportMessage,
    importExportSuccessMessage,
    importExportErrorMessage
  })

  useClearBackupItem({
    dispatch,
    clearExportBackupItem
  })

  const responseMessage = importExportSuccessMessage || importExportErrorMessage;

  return (
    <Modal
      dimmer='blurring'
      size='small'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={true}
      className='business-process-modal'
    >
      <LoaderComponent loading={loading || backupRefsLoading} />
      <Modal.Header className='borderless' >
        <div
          className='ui borderless bg-primary'
          style={{ height: '42px' }} >
          {`${(isExport() ? 'Export' : 'Import')} Business Process`}
        </div>
      </Modal.Header>
      {(responseMessage) &&
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <ResponseMessage
            message={responseMessage}
            open={true}
            close={() => { }}
            error={!!importExportErrorMessage}
          />
        </div>
      }
      <Modal.Content>
        {
          isExport() &&
          <ExportFile />
        }
        {
          !isExport() &&
          <ImportFile
            setFiles={(file: any) => processFile(file)}
            files={files}
            filesToImport={filesToImport}
            setFilesToImport={(file: any) => setFilesToImport(file)}
            importedFiles={importedFiles}
          />
        }
      </Modal.Content>
      <Modal.Actions className='borderless' >
        <Button className='btn-default btn-action cancel-btn' onClick={() => history.push('/admin/business-process/form')}>Cancel</Button>
        {isExport() && (selectedBackupItem.length > 0) && <Button className='btn-success btn-action' onClick={() => exportFile()}>Export</Button>}
        {!isExport() && Object.keys(files).length > 0 && <Button className='btn-success btn-action' onClick={() => importFile()}>Import</Button>}
      </Modal.Actions>
    </Modal >
  );
}

export default FileModal;

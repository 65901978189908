import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from '../../../../../../../../../reducers';
import { IHasPageChangeRef } from '../../../../../../../../main/interface/has-page-change';
import PDFTemplateForms from './form';
import { useGetPDFTemplateData } from './hooks/use-get-pdf-template-data';
import PDFTemplateList from './list';

interface IPdfTemplateContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const PDFTemplateContainer: React.FC<IPdfTemplateContainerProps> = (props) => {
  const match: any = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { company } = useSelector((state: IRootState) => state.auth);
  const { hasActionTriggered } = useSelector((state: IRootState) => state.configurationPDFTemplate);

  useGetPDFTemplateData({
    dispatch,
    company,
    formId: match.params.formId,
    hasActionTriggered
  });

  return <div className={`pdf-template-container`}>
    <Route path={`${match.path}`} exact>
      <button className="ui right floated button add-button"
        onClick={() => {
          history.push(`/admin/${match.params.submodule}/${match.params.thirdLevelRoute}/${match.params.formId}/${match.params.fourthLevelRoute}/new-template`);
        }}
      >Add Template</button>
      <PDFTemplateList hasPageChangeRef={props.hasPageChangeRef} />
    </Route>
    <Route path={`${match.path}/:pdfTemplateId`}>
      <PDFTemplateForms hasPageChangeRef={props.hasPageChangeRef} />
    </Route>
  </div>
}

export default PDFTemplateContainer;
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react-bpm';
import { IComment } from '../../../../../interface/comment';
import { sanitizeDeltaFields } from '../utils/system-delta-fields';
import SytemMessagesDeltaFieldChanges from './field-changes';
import { setCommentText } from './utils/set-comment-text';

interface ISytemMessagesDeltaFields {
  comment: IComment;
}

const SytemMessagesDeltaFields: React.FC<ISytemMessagesDeltaFields> = ({ comment }) => {
  const { deltaFields, action } = { ...comment };

  const [previewFields, setPreviewFields] = useState(false);

  const dropdownIcon = previewFields ? 'triangle down' : 'triangle right';
  const newDeltaFields = sanitizeDeltaFields(deltaFields);

  if (newDeltaFields.length === 0 && (action && action.newRecord)) {
    return <p>{comment.text}</p>
  }

  if (newDeltaFields.length === 0 && !(action && action.newRecord)) {
    return null;
  }

  return <>
    <p>{setCommentText(newDeltaFields, comment)}</p>
    <div onClick={() => { setPreviewFields(!previewFields) }} className={`icon-dropdown ${newDeltaFields.length === 1 ? 'hidden' : ''}`}>
      <span className='fields-preview-name'>
        {newDeltaFields.length === 1 ? newDeltaFields.length + ' field' : newDeltaFields.length + ' fields'}
      </span>
      <Icon className='icon-expand' name={dropdownIcon} />
    </div>
    {newDeltaFields.length === 1 ?
      <SytemMessagesDeltaFieldChanges
        isCollapse={false}
        newDeltaFields={newDeltaFields}
      />
      :
      <SytemMessagesDeltaFieldChanges
        isCollapse={!previewFields}
        newDeltaFields={newDeltaFields}
      />
    }
  </>
}

export default SytemMessagesDeltaFields;

import React from 'react';
import Filter from './filter';
import FieldContainer from './field-container';
import { IColumnView } from '../interface/custom-view';
import { IForm } from '../../../../form/interface/form';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface IFieldSelectionProps {
  customViewList: IColumnView[];
  removeSelectedColumnView(dragItem: { data: IColumnView }): void;
  formDetail: IForm;
  fieldList: ICustomFieldDetail[];
  filterSearchInput(searchValue: string): void;
  searchText: string;
  filterByType(value: string): void;
  filterBySection(value: string): void;
  fieldTypeFilter: string;
  fieldSectionFilter: string;
}
const FieldSelection: React.FC<IFieldSelectionProps> = (props) => {

  return (
    <div className='field-selection'>
      <label>Select Field to Add</label>
      <Filter
        filterSearchInput={props.filterSearchInput}
        filterByType={props.filterByType}
        filterBySection={props.filterBySection}
        fieldTypeFilter={props.fieldTypeFilter}
        fieldSectionFilter={props.fieldSectionFilter}
        formDetail={props.formDetail}
      />
      <FieldContainer
        customViewList={props.customViewList}
        removeSelectedColumnView={props.removeSelectedColumnView}
        formDetail={props.formDetail}
        fieldList={props.fieldList}
        searchText={props.searchText}
        fieldTypeFilter={props.fieldTypeFilter}
        fieldSectionFilter={props.fieldSectionFilter}
      />
    </div>
  );
}

export default FieldSelection;
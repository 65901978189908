import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Segment } from 'semantic-ui-react-bpm';
import { v4 as uuid } from 'uuid';
import { IForm } from '../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../component/admin-module/module/data-tables/reducer';
import { IFieldConfig } from '../../../component/admin-module/module/users/interface/field';
import { IRootState } from '../../../reducers';
import { IOption } from '../../interface/option';
import { userDtoCustomField } from '../custom-field-type/enum-field-config/constants';
import { ICustomFieldDetail, IReferencedFieldCondition, IReferencedFieldFilterConfig } from '../interface/custom-field-detail';
import FilterConfigDetail from './config';
import { IDFilterField } from './constant';
import { FilterConfigurationUtils } from './utils/filter-configuration-utils';
import { getFromRefFieldListAction } from './action';

interface IFilterConfigurationProps {
  fieldData: ICustomFieldDetail;
  configData: IFieldConfig;
  open: boolean;
  closeFilterModal(): void;
  currentBp: IForm | IDataTable;
  publishedForm: IForm[],
  datatable: IDataTable[],
  updateFieldFilterConfig(filter: IReferencedFieldFilterConfig): void;
  filterConfig: IReferencedFieldFilterConfig;
  fieldId: string;
  fieldList: ICustomFieldDetail[];
  tableId?: string;
  tableFieldList?: ICustomFieldDetail[];
  userFieldList?: ICustomFieldDetail[];
}

const FilterConfiguration: React.FC<IFilterConfigurationProps> = (props) => {
  let dispatch = useDispatch();
  const errorIndicator = { compareTypeField: false }

  const customField = useSelector((state: IRootState) => state.customField);

  const { company } = useSelector((state: IRootState) => state.auth);
  const { formBuilderField } = useSelector((state: IRootState) => state.formBuilder);
  const { selectedBpStatusList } = useSelector((state: IRootState) => state.filterConfiguration);

  const referenceFieldList = [...customField.displayFieldList]

  const [error, setError] = useState(errorIndicator);
  const [joinCondition, setJoinCondition] = useState('and');
  const [conditions, setCondition] = useState([] as IReferencedFieldCondition[]);

  const defaultvalueRef = useRef<{ [id: string]: { value: string } }>({});

  const userFieldList = [...props.userFieldList || [], ...userDtoCustomField];

  const filterConfigUtils = new FilterConfigurationUtils();
  const referencedBPDetail = filterConfigUtils.getReferenceBpDetail(props.configData, props.fieldData, props.publishedForm, props.datatable);
  const filterMetaDataFields = filterConfigUtils.setFilterMetaDataFields(props.configData);
  const defaultField = filterConfigUtils.setFilterDefaultFields(filterMetaDataFields, selectedBpStatusList);
  const initialConfig: IReferencedFieldCondition = filterConfigUtils.setFilterInitialConfig(referencedBPDetail, props.currentBp, props.tableId);
  const currentBpFieldOptions = filterConfigUtils.getCurrentBpFieldOptions(defaultField, props.fieldList, props.fieldId);
  const referencedBPFieldOptions = filterConfigUtils.getReferencedBpFieldOptions(defaultField, referenceFieldList, props.configData, userFieldList);
  const tableFieldOptions = filterConfigUtils.setTableFieldOptions(props.fieldId, props.tableId, props.tableFieldList);
  const currBPRefFields = filterConfigUtils.getReferencedFieldType(props.fieldList, props.fieldId);
  const currBPRefFieldsOptions = filterConfigUtils.setFieldsToOption(currBPRefFields);
  const tableDetails = filterConfigUtils.getFieldDetails(props.fieldList, props.tableId);

  defaultField.push(IDFilterField);

  const addNewCondition = () => {
    const newCondition = [...conditions];
    let newObject = { ...initialConfig, id: uuid() };
    newCondition.push(newObject);
    setCondition(newCondition);
  }

  const removeCondition = (conditionIndex: number | null) => {
    if (conditionIndex !== null) {
      const tempCondition = [...conditions];
      tempCondition.splice(conditionIndex, 1);
      setCondition(tempCondition);
    }
  }

  const handleConfig = (name: 'fieldId' | 'fromBp' | 'operator' | 'compareType' | 'tableId', value: string, index: number | null) => {
    if (index !== null) {
      const tempCondition = [...conditions];
      tempCondition[index][name] = value;
      if (name === 'fromBp') {
        tempCondition[index].compareType = props.currentBp.id === value ? 'field' : 'value';
        tempCondition[index].operator = '';
        tempCondition[index].fieldId = '';
        tempCondition[index].compareField = { ...initialConfig.compareField };
        if (value) {
          if (props.currentBp.id === value) {
            tempCondition[index].compareField.fromBp = referencedBPDetail.id || '';
          } else {
            tempCondition[index].compareField.fromBp = props.currentBp.id || '';
          }
        }
        defaultvalueRef.current[tempCondition[index].id] = { value: '' };
      }
      if (name === 'fieldId') {
        tempCondition[index].operator = '';
        tempCondition[index].compareType = value === 'id' ? 'field' : 'value';
        defaultvalueRef.current[tempCondition[index].id] = { value: '' };
        if (value === 'id') {
          tempCondition[index].operator = 'any-in';
          tempCondition[index].compareField.fieldId = '';
          tempCondition[index].compareField.fieldRef = '';
        }
      }
      if (name === 'tableId') {
        tempCondition[index].compareType = 'field';
      }
      setCondition(tempCondition);
    }
  }

  const handleCompareField = (name: 'fieldId' | 'fromBp' | 'fieldRef', value: string, index: number | null) => {
    if (index !== null) {
      const tempCondition = [...conditions];
      tempCondition[index].compareField[name] = value;
      if (name === 'fromBp') {
        tempCondition[index].compareField.fieldId = '';
      }
      if (name !== 'fieldRef') {
        tempCondition[index].compareField.fieldRef = '';
      }
      setCondition(tempCondition);
    }
  }

  const getOperatorOption = (condition: IReferencedFieldCondition): IOption[] => {
    return filterConfigUtils.getOperatorOption(
      condition,
      referencedBPDetail,
      props.currentBp,
      props.fieldList,
      referenceFieldList,
      defaultField,
      props.tableFieldList,
      userFieldList,
      props.configData,
    )
  }

  const getSelectedField = (condition: IReferencedFieldCondition): ICustomFieldDetail => {
    return filterConfigUtils.getSelectedField(
      condition,
      formBuilderField,
      referencedBPDetail,
      props.currentBp,
      referenceFieldList,
      defaultField,
      props.tableFieldList,
      userFieldList,
      props.configData,
    )
  }

  const getSelectedCompareField = (fieldId: string): ICustomFieldDetail => {
    return filterConfigUtils.getSelectedCompareField(
      fieldId,
      formBuilderField,
      referenceFieldList,
      defaultField,
      props.tableFieldList
    )
  }

  const getRefFieldSourceFormDetails = (selectedCompareField: ICustomFieldDetail): { id: string; name: string; } | null => {
    if (!selectedCompareField) {
      return null;
    }
    return filterConfigUtils.getReferenceBpDetail(
      selectedCompareField.config as any,
      selectedCompareField,
      props.publishedForm,
      props.datatable
    )
  }

  const updateFilterConfig = () => {
    let forUpdateCondition = [...conditions];
    let currentError = { ...error };

    const newCondition = forUpdateCondition.map((condition: IReferencedFieldCondition) => {
      if (condition.compareType === 'value') {
        let tempValue: any = defaultvalueRef.current && defaultvalueRef.current[condition.id] &&
          defaultvalueRef.current[condition.id].value ? defaultvalueRef.current[condition.id].value : '';
        if (defaultvalueRef.current && defaultvalueRef.current[condition.id] &&
          typeof defaultvalueRef.current[condition.id] === 'string') {
          tempValue = defaultvalueRef.current[condition.id];
        }
        condition.compareValue = tempValue;
      }
      if (props.currentBp.id === condition.fromBp && condition.compareType === 'field' && !condition.compareField.fieldId) {
        currentError.compareTypeField = true;
      } else {
        currentError.compareTypeField = false;
      }
      return condition;
    })
    let filter: IReferencedFieldFilterConfig = {
      condition: newCondition,
      joinCondition: joinCondition === 'and' ? 'and' : 'or'
    }
    setError(currentError);

    if (currentError.compareTypeField) {
      return
    } else {
      props.updateFieldFilterConfig(filter);
      setCondition([]);
      setJoinCondition('and');
    }
  }

  const getField = (fieldId: string) => {
    const tables = formBuilderField.filter(e => e.type === 'table').map(e => e.config?.columns);
    const tableFields = tables.length ? (tables.reduce((flatten, array) => [...flatten || [], ...array || []]) || []) : [];
    const fieldList = [
      ...defaultField,
      ...referenceFieldList,
      ...formBuilderField,
      ...tableFields,
    ];
    const field = fieldList.find(e => e.id === fieldId);
    const { config } = { ...field };

    return config;
  }

  const getCompareFieldRefLabelFields = (fieldId: string) => {
    const config = getField(fieldId);

    if (config && config.data) {
      dispatch(getFromRefFieldListAction({
        company: config.data.companyId,
        dataType: config.dataType,
        databaseId: config.data.databaseId,
        formId: config.data.formId,
      }));
    }

  }

  useEffect(() => {
    if (props.open) {
      if (Object.keys(props.filterConfig).length > 0) {
        setJoinCondition(props.filterConfig.joinCondition);
        setCondition(props.filterConfig.condition);
        props.filterConfig.condition.forEach((condition: IReferencedFieldCondition) => {
          defaultvalueRef.current[condition.id] = { value: condition.compareValue };
          if (condition && condition.compareField.fieldId) {
            getCompareFieldRefLabelFields(condition.compareField.fieldId);
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.filterConfig), props.open]);

  return <Modal
    dimmer='blurring'
    size='large'
    closeOnEscape={false}
    closeOnDimmerClick={false}
    open={props.open}
    className='form-filter-configuration-modal'
  >
    <Modal.Header className='borderless' >
      <Segment className={`borderless bg-primary`}>
        {props.fieldData.label || 'Untitled'}&nbsp;-&nbsp;Source:&nbsp; {referencedBPDetail.name || 'Untitled Source'}
      </Segment>
    </Modal.Header>
    <Modal.Content>
      <div className={`condition-join-method`}>
        <label>Conditions: </label>
        <div className={`condition-container`}>
          <label onClick={() => setJoinCondition('and')} className={`${joinCondition === 'and' ? 'selected' : ''}`}>AND</label>
          <label onClick={() => setJoinCondition('or')} className={`${joinCondition === 'or' ? 'selected' : ''}`}>OR</label>
        </div>
      </div>
      {
        conditions.map((condition: IReferencedFieldCondition, index: number) => {
          return <FilterConfigDetail
            key={uuid()}
            rowIndex={index}
            condition={condition}
            currentBp={props.currentBp}
            removeCondition={removeCondition}
            handleConfig={handleConfig}
            getOperatorOption={getOperatorOption}
            getSelectedField={getSelectedField}
            defaultvalueRef={defaultvalueRef}
            company={company}
            fieldData={props.fieldData}
            configData={props.configData}
            handleCompareField={handleCompareField}
            error={error}
            getSelectedCompareField={getSelectedCompareField}
            tableId={props.tableId}
            referencedBPDetail={referencedBPDetail}
            currentBpFieldOptions={currentBpFieldOptions}
            referencedBPFieldOptions={referencedBPFieldOptions}
            tableFieldOptions={tableFieldOptions}
            defaultField={defaultField}
            currBPRefFieldsOptions={currBPRefFieldsOptions}
            getRefFieldSourceFormDetails={getRefFieldSourceFormDetails}
            tableDetails={tableDetails}
            userFieldList={props.userFieldList}
            referenceFieldList={referenceFieldList}
          />
        })
      }
      <i className={`plus circular icon add-new-condtion`} onClick={() => addNewCondition()} />
    </Modal.Content>
    <Modal.Actions className='borderless' id='forgot-password-modal-footer'>
      <React.Fragment  >
        <Button color='green' className='btn-action' onClick={() => updateFilterConfig()}>{'Update'}</Button>
        <Button color='grey' className='btn-action' onClick={() => props.closeFilterModal()}>{'Cancel'}</Button>
      </React.Fragment>
    </Modal.Actions>
  </Modal >
}

export default FilterConfiguration;

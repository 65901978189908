import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../common/field/type/interface/field-type-object';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../interface/section';

interface IUpdateConfigUtils {
  forDeleteField: ICustomFieldDetail;
  sectionList: IFormBuilderSection[]
}

export class CheckFieldFxUsage {

  static isUsedInFxField = (utils: IUpdateConfigUtils): null | ICustomFieldDetail => {
    let fieldUsed: null | ICustomFieldDetail = null;
    utils.sectionList.forEach((section: IFormBuilderSection) => {
      section.fields.forEach((fieldList: TFormBuilderSectionRow) => {
        fieldList.forEach((field: ICustomFieldDetail) => {
          if (field.configName === IConfigName.FX && field.config) {
            const fieldInFormula = field.config.formula.filter(formula => formula.type === 'field' && formula.value === utils.forDeleteField.id);
            if (fieldInFormula.length > 0) {
              fieldUsed = field;
            }
          }
        });
      });
    });
    return fieldUsed;
  }

}
import { IOption } from '../../../../common/interface/option';
import {
  GET_BUSINESS_PROCESS_OPTION,
  GET_BUSINESS_PROCESS_OPTION_RETURN,
  GET_BUSINESS_PROCESS_OPTION_ERROR,
  CREATE_APP,
  CREATE_APP_RETURN,
  CREATE_APP_ERROR,
  GET_APP_LIST,
  GET_APP_LIST_RETURN,
  GET_APP_LIST_ERROR,
  CLEAR_APP_MESSAGE,
  GET_APP_DETAILS,
  GET_APP_DETAILS_RETURN,
  GET_APP_DETAILS_ERROR,
  DELETE_APP,
  DELETE_APP_CONFIRM,
  DELETE_APP_CONFIRM_RETURN,
  DELETE_APP_CONFIRM_ERROR,
  DELETE_APP_CANCEL,
  UPDATE_APP,
  UPDATE_APP_RETURN,
  UPDATE_APP_ERROR,
  CLEAR_CREATE_APP_SUCCESS,
  CLEAR_DELETE_APP_SUCCESS,
  CLEAR_UPDATE_APP_SUCCESS,
  CLEAR_CREATE_APP_ERROR,
  CLEAR_UPDATE_APP_ERROR
} from './constant';
import appMessage from './message';
import { IForm } from '../business-process/module/form/interface/form';

export interface IApp {
  id: string;
  name: string;
  seq: number;
  description: string;
  forms: IForm[];
  color?: string;
}

export interface IAppReducer {
  businessProcessOption: IOption;
  businessProcessOptionError: boolean;
  createAndAddNew: boolean;
  createAppLoading: boolean;
  createAppMessage: string;
  createAppMessageError: string;
  appList: IApp[];
  appLoading: boolean;
  appListError: string;
  getAppDetailsData: IApp[],
  getAppDetailsLoading: boolean;
  getAppDetailsError: boolean;
  getAppSelectedForm: IOption;
  updateAppMessage: string;
  updateAppMessageError: string;
  updateAppLoading: boolean;
  forDeleteAppId: string;
  forDeleteAppName: string;
  isOpenDeleteConfirmationModal: boolean;
  confirmDeletetionLoading: boolean;
  confirmDeletionSuccessMessage: string;
  confirmDeletionErrorMessage: string;
}

const applicationList: IApp[] = [];

const InitialState: IAppReducer = {
  businessProcessOption: {} as IOption,
  businessProcessOptionError: false,
  createAndAddNew: false,
  createAppLoading: false,
  createAppMessage: '',
  createAppMessageError: '',
  appList: applicationList,
  appLoading: false,
  appListError: '',
  updateAppLoading: false,
  updateAppMessage: '',
  updateAppMessageError: '',
  getAppDetailsData: applicationList,
  getAppDetailsLoading: false,
  getAppDetailsError: false,
  forDeleteAppId: '',
  forDeleteAppName: '',
  isOpenDeleteConfirmationModal: false,
  confirmDeletetionLoading: false,
  confirmDeletionSuccessMessage: '',
  confirmDeletionErrorMessage: '',
  getAppSelectedForm: {} as IOption
}

const appsReducer = (state = InitialState, action: any) => {
  let newState = { ...state };
  let tempAppList: any = [];

  switch (action.type) {
    case GET_BUSINESS_PROCESS_OPTION:
      newState.businessProcessOptionError = false;
      return newState;

    case GET_BUSINESS_PROCESS_OPTION_RETURN:
      newState.businessProcessOption = action.data;
      return newState;

    case GET_BUSINESS_PROCESS_OPTION_ERROR:
      newState.businessProcessOptionError = true;
      return newState;

    case GET_APP_LIST:
      newState.appLoading = true;
      return newState;

    case GET_APP_LIST_RETURN:
      newState.appList = action.data;
      newState.appLoading = false;
      return newState;

    case GET_APP_LIST_ERROR:
      newState.appListError = appMessage.appListError;
      return newState;

    case CREATE_APP:
      newState.createAndAddNew = action.createAndAddNew || false;
      newState.createAppLoading = true;
      return newState;

    case CREATE_APP_RETURN:
      tempAppList = Object.keys(state.appList).map((keys: any) => { return state.appList[keys] });
      tempAppList.push(action.data)
      newState.appList = tempAppList;
      newState.createAppLoading = false;
      newState.createAppMessage = appMessage.createAppSuccess;
      return newState;

    case CREATE_APP_ERROR:
      newState.createAppMessageError = action.message;
      newState.createAppLoading = false;
      return newState;

    case GET_APP_DETAILS:
      newState.getAppDetailsLoading = true;
      newState.getAppDetailsError = false;
      return newState;

    case GET_APP_DETAILS_RETURN:
      newState.getAppDetailsLoading = false;
      newState.getAppDetailsError = false;
      newState.getAppDetailsData = action.data;
      newState.getAppSelectedForm = action.data.forms.map((e: IForm, i: number) => {
        return { key: i, text: e.name, value: e.id }
      });
      return newState;

    case GET_APP_DETAILS_ERROR:
      newState.getAppDetailsError = true;
      newState.getAppDetailsLoading = false;
      return newState;

    case DELETE_APP:
      newState.forDeleteAppId = action.appId;
      newState.forDeleteAppName = action.appName;
      newState.isOpenDeleteConfirmationModal = true;
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_APP_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_APP_CONFIRM_RETURN:
      tempAppList = Object.keys(state.appList).map((keys: any) => { return state.appList[keys] });
      newState.appList = tempAppList.filter((e: IApp) => e.id !== action.appId)
      newState.confirmDeletetionLoading = false;
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletionSuccessMessage = appMessage.deleteAppSuccess;
      return newState;

    case DELETE_APP_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_APP_CANCEL:
      newState.forDeleteAppId = '';
      newState.forDeleteAppName = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case UPDATE_APP:
      newState.updateAppLoading = true;
      newState.updateAppMessage = '';
      return newState;

    case UPDATE_APP_RETURN:
      tempAppList = Object.keys(state.appList).map((keys: any) => { return state.appList[keys] });
      newState.appList = tempAppList.map((app: IApp) => {
        if (action.data.id === app.id) {
          return action.data;
        }
        return app
      });
      newState.updateAppLoading = false;
      newState.updateAppMessage = appMessage.updateAppSuccess;
      return newState;

    case UPDATE_APP_ERROR:
      newState.updateAppLoading = false;
      newState.updateAppMessageError = action.message;
      return newState;

    case CLEAR_APP_MESSAGE:
      newState.appListError = '';
      newState.createAppMessage = '';
      newState.updateAppMessage = '';
      newState.confirmDeletionSuccessMessage = '';
      newState.createAppMessageError = '';
      newState.updateAppMessageError = '';
      return newState;

    case CLEAR_CREATE_APP_SUCCESS:
      newState.createAppMessage = '';
      return newState;

    case CLEAR_DELETE_APP_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case CLEAR_UPDATE_APP_SUCCESS:
      newState.updateAppMessage = '';
      return newState;

    case CLEAR_CREATE_APP_ERROR:
      newState.createAppMessageError = '';
      return newState;

    case CLEAR_UPDATE_APP_ERROR:
      newState.updateAppMessageError = '';
      return newState;

    default:
      return state;
  }

}

export default appsReducer;
import { AggregateFunction } from '../fx.agg-func';

export class SumAggregateFunction implements AggregateFunction {

  name = 'sum';

  aggregate(values: number[]): number {
    return values.reduce((sum, curr) => {
      sum += curr;
      return sum;
    }, 0);
  }

}
import { IReferenceFieldGetDetailInput } from './interface/input/reference-field-get';
import {
  GET_REFERENCED_FIELD_LIST,
  GET_REFERENCED_FIELD_LIST_RETURN,
  GET_SELECTED_BP_STATUS_LIST,
  GET_SELECTED_BP_STATUS_LIST_RETURN,
  GET_FROM_REF_FIELD_LIST_RETURN,
  GET_FROM_REF_FIELD_LIST
} from './constant';
import { IReferenceFieldGetResponse } from './interface/response/reference-field-get';
import { ISelectedBpStatusGetDetailInput } from './interface/input/selected-bp-status-get';
import { ISelectedBpStatusGetResponse } from './interface/response/selected-bp-status-get';
import { ICustomFieldDetail } from '../interface/custom-field-detail';

export const getReferencedFieldLstAction = (payload: IReferenceFieldGetDetailInput) => {
  return {
    type: GET_REFERENCED_FIELD_LIST,
    ...payload
  }
}

export const getReferencedFieldLstReturnAction = (payload: IReferenceFieldGetResponse) => {
  return {
    type: GET_REFERENCED_FIELD_LIST_RETURN,
    ...payload
  }
}

export const getSelectedBpStatusListAction = (payload: ISelectedBpStatusGetDetailInput) => {
  return {
    type: GET_SELECTED_BP_STATUS_LIST,
    ...payload
  }
}

export const getSelectedBpStatusListReturnAction = (payload: ISelectedBpStatusGetResponse) => {
  return {
    type: GET_SELECTED_BP_STATUS_LIST_RETURN,
    ...payload
  }
}

export const getFromRefFieldListAction = (payload: IReferenceFieldGetDetailInput) => {
  return {
    type: GET_FROM_REF_FIELD_LIST,
    ...payload
  }
}

export const getFromRefFieldListReturnAction = (payload: {
  fromRefFieldList: ICustomFieldDetail[],
  sourceId: string;
}) => {
  return {
    type: GET_FROM_REF_FIELD_LIST_RETURN,
    ...payload
  }
}
import React from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './service-worker';
import store from './store';
import 'semantic-ui-css/semantic.min.css';
import TokenChecker from './component/auth/token-checker';
import LogoutPopup from './component/auth/automatic-logout';
import LogoutMessage from './component/auth/automatic-logout/automatic-logout-message';
import './component/css/css-import.ts'

import UnauthorizedRequestHanlder from './component/error/unauthorized-error-handler';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend'
import DownloadWidget from './common/general/download-widget';
import { CallbackComponent } from './component/callback-manager';
import ConfigService from './common/config';

const ManageRoute: React.FC = () => {
  const location = useLocation();
  return <>
    {location.pathname === '/'
      ? <Route path="/" component={CallbackComponent} />
      : <Route path='/:callback' component={CallbackComponent} />
    }
  </>
}

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={Backend}>
      <TokenChecker />
      <Router>
        <DownloadWidget />
        {ConfigService.loadConfig().general.inactivityLogout &&
          <>
            <LogoutMessage />
            <LogoutPopup />
          </>
        }
        <UnauthorizedRequestHanlder />
        <ManageRoute />
      </Router>
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { GET_COMPANIES_LIST_RETURN } from '../bpm-manager-module/module/companies/constant';
import { CHECK_AUTH_TOKEN_RETURN } from '../auth/token-checker/constant';
import { ICompany } from '../bpm-manager-module/module/companies/interface/response/company';
/*
 *
 * test reducer
 *
 */

// import { fromJS } from 'immutable'
import {
  MAIN_ROUTE,
  SUB_ROUTE,
  THIRD_LEVEL_ROUTE,
  FOURTH_LEVEL_ROUTE,
  RESET_FOURTH_LEVEL_ROUTE,
  CHANGE_PAGE_NEXT_ROUTE,
  CURRENT_ROUTE,
  SET_DOWNLOAD_REQUESTS,
  REMOVE_DOWNLOAD_REQUESTS,
  CLEAR_DOWNLOAD_REQUESTS,
  reactThemeColor,
  SET_CURRENT_LOCATION,
  SET_OPEN_ADMIN_CHATBOX,
  defaultCompanyThemeColorSettings
} from './constant';

export interface IMainReducer {
  data?: any;
  userCompany?: ICompany;
  type?: string;
  mainRoute: string;
  subRoute: string;
  thirdLevelRoute: string;
  fourthLevelRoute: string;
  hasPageChange: boolean;
  changePageNextRoute: string;
  currentRoute: string;
  downloadRequests: [] | any;
  downloadIndex: 0,
  mainCompanyDetail: ICompany;
  companyColor: typeof reactThemeColor;
  currentLocation: string;
  previousLocation: string;
  isOpenAdminChatbox: boolean;
  openNewTab: boolean;
}

export const initialState: IMainReducer = {
  mainRoute: '',
  subRoute: '',
  thirdLevelRoute: '',
  fourthLevelRoute: '',
  hasPageChange: false,
  changePageNextRoute: '',
  currentRoute: '',
  downloadRequests: [],
  downloadIndex: 0,
  mainCompanyDetail: {},
  companyColor: reactThemeColor as typeof reactThemeColor,
  currentLocation: '',
  previousLocation: '',
  isOpenAdminChatbox: false,
  openNewTab: false,
};

const homeReducer = (state = initialState, action: IMainReducer) => {
  let newState = { ...state };
  let tempRequests = [...state.downloadRequests];

  switch (action.type) {
    case MAIN_ROUTE:
      newState.mainRoute = action.mainRoute;
      newState.subRoute = '';
      newState.thirdLevelRoute = '';
      return newState;

    case SUB_ROUTE:
      newState.mainRoute = action.mainRoute;
      newState.subRoute = action.subRoute;
      newState.thirdLevelRoute = '';
      return newState;

    case THIRD_LEVEL_ROUTE:
      newState.mainRoute = action.mainRoute;
      newState.subRoute = action.subRoute;
      newState.thirdLevelRoute = action.thirdLevelRoute;
      return newState;

    case FOURTH_LEVEL_ROUTE:
      newState.mainRoute = action.mainRoute;
      newState.subRoute = action.subRoute;
      newState.thirdLevelRoute = action.thirdLevelRoute;
      newState.fourthLevelRoute = action.fourthLevelRoute;
      return newState;

    case RESET_FOURTH_LEVEL_ROUTE:
      newState.fourthLevelRoute = '';
      return newState;

    case CHANGE_PAGE_NEXT_ROUTE:
      newState.changePageNextRoute = action.changePageNextRoute;
      newState.openNewTab = action.openNewTab;
      return newState;

    case CURRENT_ROUTE:
      newState.currentRoute = action.currentRoute;
      return newState;

    case SET_DOWNLOAD_REQUESTS:
      tempRequests.push(action.downloadRequests);
      newState.downloadRequests = tempRequests;
      return newState;

    case REMOVE_DOWNLOAD_REQUESTS:
      if (action.downloadIndex > -1) {
        tempRequests.splice(action.downloadIndex, 1);
      }
      newState.downloadRequests = newState.downloadRequests.filter((e: any, i: number) => i !== action.downloadIndex);
      return newState;

    case CLEAR_DOWNLOAD_REQUESTS:
      newState.downloadRequests = [];
      return newState;

    case GET_COMPANIES_LIST_RETURN:
      const mainCompany = action.data ? action.data.find((company: ICompany) => company.seq === 1) : [];
      if (mainCompany) {
        newState.mainCompanyDetail = mainCompany;
      }
      return newState;

    case CHECK_AUTH_TOKEN_RETURN:
      let companyThemeColor = { ...defaultCompanyThemeColorSettings['react'] } as typeof reactThemeColor;
      if (action.userCompany && action.userCompany.style) {
        if (action.userCompany.style.hasOwnProperty('react')) {
          const { react }: any = { ...action.userCompany.style };
          companyThemeColor = { ...companyThemeColor, ...react } as typeof reactThemeColor;
        } else {
          companyThemeColor = { ...companyThemeColor, ...action.userCompany.style } as typeof reactThemeColor
        }
      }
      newState.companyColor = companyThemeColor;
      return newState;

    case SET_CURRENT_LOCATION:
      const tempPrevLocation = newState.currentLocation;
      newState.previousLocation = tempPrevLocation;
      if (!(
        action.currentLocation === '/documents/user-business-process' ||
        action.currentLocation.indexOf('/documents/forms') > -1)
      ) {
        newState.currentLocation = action.currentLocation;
      }
      return newState;

    case SET_OPEN_ADMIN_CHATBOX:
      newState.isOpenAdminChatbox = action.isOpenAdminChatbox;
      return newState;

    default:
      return state;
  }
}

export default homeReducer

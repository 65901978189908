import { GET_GROUP_LIST, GET_GROUP_LIST_RETURN, GET_GROUP_LIST_ERROR, CREATE_GROUP, CREATE_GROUP_RETURN, CREATE_GROUP_ERROR, GET_USER_OPTION_LIST, GET_USER_OPTION_LIST_RETURN, GET_USER_OPTION_LIST_ERROR, CLEAR_GROUP_MESSAGE, DELETE_GROUP_CANCEL, DELETE_GROUP, DELETE_GROUP_CONFIRM, DELETE_GROUP_CONFIRM_RETURN, DELETE_GROUP_CONFIRM_ERROR, GET_GROUP_DETAIL, GET_GROUP_DETAIL_RETURN, GET_GROUP_DETAIL_ERROR, UPDATE_GROUP, UPDATE_GROUP_RETURN, UPDATE_GROUP_ERROR, CLEAR_CREATE_GROUP_SUCCESS, CLEAR_UPDATE_GROUP_SUCCESS, CLEAR_DELETE_GROUP_SUCCESS, CLEAR_CREATE_GROUP_ERROR, CLEAR_UPDATE_GROUP_ERROR, FILTER_GROUPS, FILTER_GROUPS_RETURN, FILTER_GROUPS_ERROR } from './constant';
import groupMessage from './message';
import { Group, IUserGroup, IGroup } from './interface/group';
import { IOption } from './interface/response/user-option-list';
import { IField, IOptionValue } from '../users/interface/field';

const groups: Group[] = [];

export interface IGroupReducer {
  groupList: Group[];
  groupListError: string;
  groupListLoading: boolean;
  createAndAddNew: boolean;
  createGroupMessage: string;
  createGroupMessageError: string;
  createGroupLoading: boolean;
  userOptionList: IOption;
  userOptionListError: boolean;
  forDeleteGroupId: string;
  forDeleteGroupName: string;
  isOpenDeleteConfirmationModal: boolean;
  confirmDeletetionLoading: boolean;
  confirmDeletionSuccessMessage: string;
  confirmDeletionErrorMessage: string;
  forUpdateGroupDetails: Group;
  forUpdateGroupDetailsLoading: boolean;
  forUpdateGroupDetailsMessage: string;
  forUpdateGroupDetailsError: boolean;
  forUpdateUserOptionList: IOption,
  forUpdateGroupOptionList: IOption,
  forUpdateGroupList: IOption,
  updateGroupMessage: string,
  updateGroupMessageError: string;
  updateGroupLoading: boolean;
  filterGroupsLoading: boolean;
  filterGroupsError: string;
  userFilterOption: IOptionValue[];
  userFields: IField[];
}

const initialState: IGroupReducer = {
  groupList: groups,
  groupListError: '',
  groupListLoading: false,
  createAndAddNew: false,
  createGroupMessage: '',
  createGroupMessageError: '',
  createGroupLoading: false,
  userOptionList: {} as IOption,
  userOptionListError: true,
  forDeleteGroupId: '',
  forDeleteGroupName: '',
  isOpenDeleteConfirmationModal: false,
  confirmDeletetionLoading: false,
  confirmDeletionSuccessMessage: '',
  confirmDeletionErrorMessage: '',
  forUpdateGroupDetails: {} as Group,
  forUpdateGroupDetailsLoading: false,
  forUpdateGroupDetailsMessage: '',
  forUpdateGroupDetailsError: false,
  forUpdateUserOptionList: {} as IOption,
  forUpdateGroupOptionList: {} as IOption,
  forUpdateGroupList: {} as IOption,
  updateGroupLoading: false,
  updateGroupMessage: '',
  updateGroupMessageError: '',
  filterGroupsLoading: false,
  filterGroupsError: '',
  userFilterOption: [],
  userFields: []
}

const groupReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  let tempGroupList: any = [];

  switch (action.type) {
    case GET_GROUP_LIST:
      newState.groupListLoading = true;
      return newState;

    case GET_GROUP_LIST_RETURN:
      newState.groupList = action.data;
      newState.userFilterOption = action.userFilterOption;
      newState.groupListLoading = false;
      return newState;

    case GET_GROUP_LIST_ERROR:
      newState.groupListError = groupMessage.groupError;
      return newState;

    case CREATE_GROUP:
      newState.createAndAddNew = action.createAndAddNew || false;
      newState.createGroupMessage = '';
      newState.createGroupLoading = true;
      return newState;

    case CREATE_GROUP_RETURN:
      tempGroupList = Object.keys(state.groupList).map((keys: any) => { return state.groupList[keys] });
      tempGroupList.push(action.data);
      newState.groupList = tempGroupList;
      newState.createGroupMessage = groupMessage.createGroupSuccess;
      newState.createGroupLoading = false;
      return newState;

    case CREATE_GROUP_ERROR:
      newState.createGroupMessageError = action.message;
      newState.createGroupLoading = false;
      return newState;

    case GET_USER_OPTION_LIST:
      newState.userOptionListError = false;
      return newState;

    case GET_USER_OPTION_LIST_RETURN:
      newState.userOptionList = action.data;
      newState.userFields = action.userFields;
      return newState;

    case GET_USER_OPTION_LIST_ERROR:
      newState.userOptionListError = true;
      return newState;

    case DELETE_GROUP:
      newState.forDeleteGroupId = action.groupId;
      newState.forDeleteGroupName = action.groupName;
      newState.isOpenDeleteConfirmationModal = true;
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionSuccessMessage = '';
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_GROUP_CONFIRM:
      newState.confirmDeletetionLoading = true;
      newState.confirmDeletionErrorMessage = '';
      return newState;

    case DELETE_GROUP_CONFIRM_RETURN:
      tempGroupList = Object.keys(state.groupList).map((keys: any) => { return state.groupList[keys] });
      newState.groupList = tempGroupList.filter((e: Group) => e.id !== action.groupId)
      newState.confirmDeletetionLoading = false;
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletionSuccessMessage = groupMessage.deleteGroupSuccess;
      return newState;

    case DELETE_GROUP_CONFIRM_ERROR:
      newState.confirmDeletetionLoading = false;
      newState.confirmDeletionErrorMessage = action.message;
      return newState;

    case DELETE_GROUP_CANCEL:
      newState.forDeleteGroupId = '';
      newState.forDeleteGroupName = '';
      newState.isOpenDeleteConfirmationModal = false;
      newState.confirmDeletetionLoading = false;
      return newState;

    case GET_GROUP_DETAIL:
      newState.forUpdateGroupDetails = {};
      newState.forUpdateGroupDetailsLoading = true;
      newState.forUpdateGroupDetailsMessage = '';
      newState.forUpdateGroupDetailsError = false;
      return newState;

    case GET_GROUP_DETAIL_RETURN:
      tempGroupList = Object.keys(state.groupList).map((keys: any) => { return state.groupList[keys] });
      let filteredData = tempGroupList.filter((e: Group, i: number) => e.name !== action.data.name);
      newState.forUpdateGroupList = filteredData.map((e: Group, i: number) => {
        return { key: i, text: e.name, value: e.id, type: 'group' }
      });
      newState.forUpdateGroupDetails = action.data;
      newState.forUpdateUserOptionList = action.data.users.map((e: IUserGroup, i: number) => {
        const firstName = e.firstName || '';
        const lastName = e.lastName || '';
        return { key: i, text: `${firstName} ${lastName}`, value: e.id, type: 'user', active: e.active }
      });
      newState.forUpdateGroupOptionList = action.data.groups.map((e: IGroup, i: number) => {
        return { key: i, text: e.name, value: e.id, type: 'group' }
      });
      newState.forUpdateGroupDetailsError = false;
      newState.forUpdateGroupDetailsMessage = '';
      newState.forUpdateGroupDetailsLoading = false;
      return newState;

    case GET_GROUP_DETAIL_ERROR:
      newState.forUpdateGroupDetailsError = true;
      newState.forUpdateGroupDetailsMessage = '';
      newState.forUpdateGroupDetailsLoading = false;
      return newState;

    case UPDATE_GROUP:
      newState.updateGroupMessage = '';
      newState.updateGroupLoading = true;
      return newState;

    case UPDATE_GROUP_RETURN:
      tempGroupList = Object.keys(state.groupList).map((keys: any) => { return state.groupList[keys] });
      newState.groupList = tempGroupList.map((group: Group) => {
        if (action.data.id === group.id) {
          return action.data;
        }
        return group
      })
      newState.updateGroupLoading = false;
      newState.updateGroupMessage = groupMessage.updateGroupSuccess;
      return newState;

    case UPDATE_GROUP_ERROR:
      newState.updateGroupLoading = false;
      newState.updateGroupMessageError = action.message;
      return newState;

    case CLEAR_GROUP_MESSAGE:
      newState.createGroupMessage = '';
      newState.updateGroupMessage = '';
      return newState;

    case CLEAR_CREATE_GROUP_SUCCESS:
      newState.createGroupMessage = '';
      return newState;

    case CLEAR_CREATE_GROUP_ERROR:
      newState.createGroupMessageError = '';
      return newState;

    case CLEAR_UPDATE_GROUP_SUCCESS:
      newState.updateGroupMessage = '';
      return newState;

    case CLEAR_UPDATE_GROUP_ERROR:
      newState.updateGroupMessageError = '';
      return newState;

    case CLEAR_DELETE_GROUP_SUCCESS:
      newState.confirmDeletionSuccessMessage = '';
      return newState;

    case FILTER_GROUPS:
      newState.filterGroupsLoading = true;
      return newState;

    case FILTER_GROUPS_RETURN:
      newState.filterGroupsLoading = false;
      newState.groupList = action.data;
      return newState;

    case FILTER_GROUPS_ERROR:
      newState.filterGroupsLoading = false;
      newState.filterGroupsError = action.message;
      return newState;

    default:
      return state;
  }
}

export default groupReducer;
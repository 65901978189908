import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, IAxiosResponse, postRequest, putRequest } from '../../../../../../../../common/api-request';
import { setStatusCodeAction } from '../../../../../../../error/action';
import { getApiConfigReturnAction, getStatusListReturnAction, publishApiConfigReturnAction, saveApiConfigErrorAction, saveDraftApiConfigReturnAction } from './action';
import { externalApiPlugin, GET_API_CONFIG, GET_STATUS_LIST, PUBLISH_API_CONFIG, SAVE_DRAFT_API_CONFIG } from './constant';
import { IApiPublishInput, IApiSaveInput } from './interface/input/api-save';
import { IStatusListGetInput } from './interface/input/status-get';

export function* getApiConfig(args: IStatusListGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/plugins/${externalApiPlugin}?draft=true`, {});
    yield put(getApiConfigReturnAction({
      apiConfigurationList: req.data?.data?.config?.apis || []
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* getStatusList(args: IStatusListGetInput) {
  try {
    const companyId = args.company;
    const formId = args.formId;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/forms/${formId}/statuses`, {});
    yield put(getStatusListReturnAction({
      statusList: req.data.data
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
  }
}

export function* saveApiConfig(args: IApiSaveInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(putRequest, `${company}/forms/${formId}/plugins/${externalApiPlugin}`, { apis: args.apiConfigurationList });
    yield put(saveDraftApiConfigReturnAction({
      apiConfigSaveType: args.saveType
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(saveApiConfigErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* publishApiConfig(args: IApiPublishInput) {
  try {
    const company = args.company;
    const formId = args.formId;
    yield call(postRequest, `${company}/forms/${formId}/publish?force=false`, null);
    yield put(publishApiConfigReturnAction())
  } catch (e) {
    yield put(setStatusCodeAction(e))
    yield put(saveApiConfigErrorAction(JSON.stringify(e.response.data.message)))
  }
}

export function* apisSagas() {
  yield takeLatest(GET_STATUS_LIST, getStatusList);
  yield takeLatest(GET_API_CONFIG, getApiConfig);
  yield takeLatest(SAVE_DRAFT_API_CONFIG, saveApiConfig);
  yield takeLatest(PUBLISH_API_CONFIG, publishApiConfig);
}

// All sagas to be loaded
export default apisSagas;
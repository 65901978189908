import {
  GET_COMPANY_SETTINGS,
  GET_COMPANY_SETTINGS_ERROR,
  GET_COMPANY_SETTINGS_RETURN,
  RESET_MESSAGE_RESPONSE,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_ERROR,
  UPDATE_COMPANY_SETTINGS_RETURN
} from './constant';
import { ICompanySettingsDetailInput } from './interface/input/company-settings';
import { ICompanySettingsUpdateDetailInput } from './interface/input/company-settings-update';
import { ICompanySettingsResponse } from './interface/response/company-settings';
import { ICompanySettingsUpdateResponse, IUpdateCompanySettingsError } from './interface/response/company-settings-update';

export const getCompanySettingAction = (payload: ICompanySettingsDetailInput) => {
  return { type: GET_COMPANY_SETTINGS, ...payload };
}

export const getCompanySettingsReturnAction = (payload: ICompanySettingsResponse) => {
  return { type: GET_COMPANY_SETTINGS_RETURN, ...payload };
}

export const getCompanySettingsReturnErrorAction = () => {
  return { type: GET_COMPANY_SETTINGS_ERROR }
}

export const updateCompanySettingsAction = (payload: ICompanySettingsUpdateDetailInput) => {
  return { type: UPDATE_COMPANY_SETTINGS, ...payload }
}

export const updateCompanySettingsReturnAction = (payload: ICompanySettingsUpdateResponse) => {
  return { type: UPDATE_COMPANY_SETTINGS_RETURN, ...payload }
}

export const updateCompanySettingsErrorAction = (payload: IUpdateCompanySettingsError) => {
  return { type: UPDATE_COMPANY_SETTINGS_ERROR, ...payload }
}

export const resetMessageResponseAction = (payload: {}) => {
  return { type: RESET_MESSAGE_RESPONSE, ...payload }
}
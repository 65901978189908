import React from 'react'
import { ArrayFieldProperties } from '../../../../service/array-field-properties.service'
import { FieldProperties } from '../../../../service/field-properties.service'
import { TimeProps } from '../../../../service/type/time'

interface IProps {
  elementProperties: ArrayFieldProperties<TimeProps> | FieldProperties<TimeProps>;
  row?: number;
  showErrorMessage?: boolean;
}

export const renderError = ({
  elementProperties,
  row,
  showErrorMessage
}: IProps) => {
  if (elementProperties instanceof ArrayFieldProperties) {
    return <>
      {row !== undefined && elementProperties.hasError(row) && showErrorMessage &&
        elementProperties.getError(row)[0].message.indexOf('Please provide value for') === -1 &&
        <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError(row)[0].message }} />
      }
    </>
  } else if (elementProperties instanceof FieldProperties) {
    return <>
      {elementProperties.hasError() && showErrorMessage &&
        elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
        <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }} />
      }
    </>
  }

}
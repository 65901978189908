export const focusSelectedElement = (id: string | null) => {
  const element = document.getElementById(id || '');
  if (element) {
    if (id && id.includes('dropdown')) {
      element.getElementsByTagName('input')[0].focus();
    } else {
      element.focus();
    }
  }
}

export const setTableFocusedElement = (element: { index: number, focusedElement: string, columnDisplayed: number[] }) => {
  localStorage.setItem('table-focused-element', JSON.stringify(element));
  localStorage.setItem('column-displayed', JSON.stringify(element.columnDisplayed));
}

export const setFocusedElement = (id: string) => {
  localStorage.setItem('focused-element', id);
}
export const getTableFocusedElement = () => {
  return localStorage.getItem('table-focused-element');
}

export const getFocusedElement = () => {
  return localStorage.getItem('focused-element');
}

export const getTableColumnDisplayed = () => {
  return localStorage.getItem('column-displayed');
}

export const removeFocusedElement = () => {
  localStorage.removeItem('focused-element');
  localStorage.removeItem('table-focused-element');
}

export const removeColumnDisplayed = () => {
  return localStorage.removeItem('column-displayed');
}
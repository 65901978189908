import { Delta } from 'jsondiffpatch';
import { DeltaModuleType } from '../..';
import { ICommentDeltaFields } from '../../../../../../../document-module/module/form/interface/comment';
import { ISenderDetail } from '../../../../../../../document-module/module/form/interface/sender-info';
import { IAction } from '../../../../../../../document-module/module/report/interface/form-document';
import { AdminHistoryCategory, IAdminHistoryLogs } from '../../../../../interface/history';
import { FieldConfigChange } from '../../../utils/field-config-change';
import * as jsondiffpatch from 'jsondiffpatch';
import { IOptionValue } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail-config';

export class BPWorkflowDeltaModule implements DeltaModuleType {

  name = AdminHistoryCategory.Workflow;

  format(historyLogs: IAdminHistoryLogs) {
    let deltaArray = [] as ICommentDeltaFields[];
    let deltaText = historyLogs.text;

    const { targetDelta, targetInstance } = { ...historyLogs.context };
    const senderUserInfo = { ...historyLogs.sender.data } as ISenderDetail;
    const commentSender = (senderUserInfo.firstName || '') + ' ' + (senderUserInfo.lastName || '');

    if (targetDelta) {
      const { actionDeltaArray, deltaArr } = this.setDelta(targetDelta, targetInstance);
      if (actionDeltaArray.length > 0) {
        const tempActionDelta = actionDeltaArray.map(delta => {
          if (delta.id === 'actions') {
            const enumValue = [[...delta.oldValue], jsondiffpatch.patch([...delta.oldValue], delta.diff)];
            delta.diff = this.setPatchedActionItems(enumValue);
            delta.oldValue = this.setPatchedActionItems(delta.oldValue);
            delta.type = 'array'
          }
          return delta;
        });
        deltaText = `${commentSender} has updated actions under Status (name: ${targetInstance.name})`;
        deltaArray = deltaArray.concat(tempActionDelta);
      }
      if (deltaArr.length > 0) {
        deltaText = `${commentSender} has updated Status (name: ${targetInstance.name})`;
        deltaArray = deltaArray.concat(deltaArr);
      }
    }

    return { delta: deltaArray, text: deltaText };
  }

  setDelta = (delta: Delta, instance: any) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    let actionDeltaArray = [] as ICommentDeltaFields[];

    Object.keys(delta).forEach(key => {
      if (key === 'actions') {
        const deltaArr = this.setActionsDelta(delta[key], instance[key], instance);
        actionDeltaArray = actionDeltaArray.concat(deltaArr);
      } else {
        if (key === 'final') {
          newDelta['End Status'] = delta[key];
          oldDelta['End Status'] = instance[key];
        } else {
          newDelta[key] = delta[key];
          oldDelta[key] = instance[key];
        }
      }
    });

    const deltaArr = FieldConfigChange.format(newDelta, oldDelta, oldDelta);

    return { actionDeltaArray, deltaArr };
  }

  setActionsDelta = (delta: Delta, instance: any, instanceObject: any) => {
    let actionDeltaArray = [] as ICommentDeltaFields[];

    const deltaKeys = Object.keys(delta).filter(e => delta[e] instanceof Array);
    const stringDeltaKeys = Object.keys(delta).filter((key, index) => key !== '_t').join('').toString();

    if (stringDeltaKeys.indexOf('_') > -1) {
      let newDelta = {} as Delta;
      let oldDelta = {} as Delta;

      deltaKeys.forEach(key => {
        if (key.indexOf('_') > -1) {
          const newKey = key.split('_')[1];
          oldDelta[newKey] = delta[key][0];
        } else {
          newDelta[key] = delta[key][0];
        }
      });
      if (Object.keys(newDelta).length > 0) {
        Object.keys(newDelta).forEach(key => {
          const deltaChange = FieldConfigChange.targetDelta(newDelta[key] || {});
          const deltaInstance = FieldConfigChange.targetDelta(oldDelta[key] || {});
          if (oldDelta[key] && Object.keys(oldDelta[key]).length > 0) {
            actionDeltaArray.push({
              id: oldDelta[key].id,
              label: `Action: ${oldDelta[key].name}`,
              type: 'tableUpdate',
              diff: this.actionDelta(deltaChange, deltaInstance),
              oldValue: null
            });
          }
        });
      } else {
        const actions = instance
          .filter((e: IAction) => e.type === 'dynamic' || e.type === 'save')
          .map((e: IAction) => this.setActionItems(e));
        const data = {} as Delta;
        Object.keys(oldDelta).forEach(key => {
          if (oldDelta[key]) {
            const index = actions.findIndex((e: any) => oldDelta[key].id === e.id);
            data[`_${index}`] = [this.setActionItems(oldDelta[key]), 0, 0];
            data['_t'] = 'a';
          }
        });
        if (Object.keys(data).length > 0) {
          const deltaArray = FieldConfigChange.format({ actions: data }, { actions: actions }, instance);
          actionDeltaArray = actionDeltaArray.concat(deltaArray);
        }
      }
    } else {
      const actions = instance
        .filter((e: IAction) => e.type === 'dynamic' || e.type === 'save')
        .map((e: IAction) => this.setActionItems(e));
      let ctr = actions.length;
      const data = {} as Delta;
      Object.keys(delta).forEach(key => {
        if (delta[key] && Array.isArray(delta[key])) {
          ctr++;
          data[ctr] = delta[key].map((e: IAction) => this.setActionItems(e));
        } else {
          data[key] = delta[key];
        }
      });
      if (Object.keys(data).length > 0) {
        const deltaArray = FieldConfigChange.format({ actions: data }, { actions: actions }, instance);
        actionDeltaArray = actionDeltaArray.concat(deltaArray);
      }
    }
    return actionDeltaArray;
  }

  actionDelta = (delta: Delta, oldDelta: Delta) => {
    let newData = {} as Delta;
    let oldData = {} as Delta;

    let actionDeltaArray = [] as ICommentDeltaFields[];

    Object.keys(delta).forEach(key => {
      if (delta[key]) {
        if (JSON.stringify(delta[key]) !== JSON.stringify(oldDelta[key])) {
          if (typeof delta[key] === 'object' && !Array.isArray(delta[key])) {
            let newValue = {} as Delta;
            let oldValue = {} as Delta;
            Object.keys(delta[key]).forEach(childKey => {
              if (JSON.stringify(delta[key][childKey]) !== JSON.stringify(oldDelta[key][childKey])) {
                newValue[childKey] = [oldDelta[key][childKey] || null, delta[key][childKey]];
                oldValue[childKey] = oldDelta[key][childKey] || '';
                if (childKey === 'return') {
                  oldValue[childKey] = oldDelta[key][childKey] || false;
                }
                if (childKey === 'assignmentType') {
                  newValue[childKey] = [
                    this.setAssignmentType(oldDelta[key][childKey]) || null,
                    this.setAssignmentType(delta[key][childKey])
                  ];
                  oldValue[childKey] = this.setAssignmentType(oldDelta[key][childKey]) || '';
                }
              }
            });
            newData[key] = newValue;
            oldData[key] = oldValue || '';
          }
        }
      }
    });

    if (newData) {
      const instance: any = {
        ...oldDelta, config: Object.keys(oldDelta.config).length > 0 ?
          { ...oldDelta.config } : { ...oldData.config }
      };
      if (newData.config) {
        const deltaArray = FieldConfigChange.format(newData.config, oldData.config, instance);
        actionDeltaArray = actionDeltaArray.concat(deltaArray);
      }
      if (newData.delta) {
        const deltaArray = FieldConfigChange.format(newData.delta, oldData.delta, instance);
        actionDeltaArray = actionDeltaArray.concat(deltaArray);
      }
    }

    return actionDeltaArray;
  }

  setActionItems(action: IAction) {
    if (action.type === 'save' && !action.name) {
      action.name = 'save';
    }
    return {
      id: action.id,
      label: action.name ? action.name.toUpperCase() : null
    }
  }

  setPatchedActionItems = (enumValue: IOptionValue[]) => {
    return enumValue.map((item) => {
      if (Array.isArray(item)) {
        return item.length ? item.map((e: any) => { return e.label }) : null
      } else {
        return item ? item.label : null;
      }
    });
  }

  setAssignmentType = (assignmentType: string) => {
    if (assignmentType === 'none') {
      assignmentType = 'group'
    };
    return assignmentType;
  }
}

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react-bpm';
import SVGWrapper from '../../../../../../../../common/icons/svg/svg-wrapper';
import { IOption } from '../../../../../../../../common/interface/option';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import { IAppliedLabels, IFormLabels } from './interface/label';
import LabelFieldList from './labels-fields';
import { v4 as uuid } from 'uuid';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';


interface ILabelsPanes {
  statusList: IFormStatusWithAction[];
  labelData: IFormLabels[];
  addNewAppliedLabel(appLabel?: IAppliedLabels): void;
  editAppliedLabel(value: any): void;
  deleteSelectedAppliedLabel(id: string): void;
  appliedLabelList: IAppliedLabels[];
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const AppliedLabels: React.FC<ILabelsPanes> = (props) => {
  const [showAddButton, setShowAddButton] = useState(true);

  const statusOptions: IOption[] = props.statusList.map((status, index) => {
    return {
      key: index,
      text: status.name,
      value: status.id
    }
  });
  statusOptions.unshift({ key: -1, text: 'ALL', value: 'all' });

  const labelOptions = props.labelData.map((status, index) => {
    return {
      key: index,
      text: status.name,
      value: status.id
    }
  });

  const addNewAppliedLabel = () => {
    const initialAppliedLabel: IAppliedLabels = {
      id: uuid(),
      status: ['all'],
      label: {} as IFormLabels,
      component: [],
      enabled: false,
      new: true,
      creatorId: ''
    };
    props.addNewAppliedLabel(initialAppliedLabel);
    setShowAddButton(false);
    props.hasPageChangeRef.current.hasChange = true;
  }

  return (
    <div className='labels-container'>
      <div className='labels-search-container'>
        <div className={`search-filter`}>
          <div className='ui input'>
            <input placeholder='Search' />
          </div>
          <button className='ui icon button search-button'><i className={`ui icon search`}></i></button>
        </div>
      </div>
      <div className='applied-label-table-wrapper'>
        <table className='applied-label-table'>
          <thead>
            <tr>
              <th className='th-group h-component'>Component</th>
              <th className='th-group h-status'>Status</th>
              <th className='th-group h-label'>Label</th>
              <th className='th-group h-contents'>Label Contents</th>
              <th className='th-group'>Created by</th>
              <th className='th-group'>Enabled</th>
              <th className='th-group'></th>
            </tr>
          </thead>
          <tbody>
            {props.appliedLabelList.map(appliedLabel => {
              if (appliedLabel.deleted) {
                return null;
              }
              return <tr key={appliedLabel.id}>
                <LabelFieldList
                  statusOptions={statusOptions}
                  labelOptions={labelOptions}
                  labelData={props.labelData}
                  appliedLabel={appliedLabel}
                  editAppliedLabel={props.editAppliedLabel}
                  setShowAddButton={setShowAddButton}
                  deleteSelectedAppliedLabel={props.deleteSelectedAppliedLabel}
                />
              </tr>
            })}
            {showAddButton &&
              <tr>
                <td>
                  <Button onClick={addNewAppliedLabel} className='label-add-row'>
                    <SVGWrapper iconName='plus' height='15' color='#fff' />
                  </Button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default AppliedLabels;
import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatatablesListReturnAction } from './action';
import { getRequest, IAxiosResponse } from '../../../../common/api-request';
import { setStatusCodeAction } from '../../../error/action';
import { IDatatableListGetInput } from './interface/input/datatable-list-get';
import { GET_DATATABLE_LIST } from './constant';


export function* getDatatableList(args: IDatatableListGetInput) {
  try {
    const companyId = args.companyId;
    const name = args.name;
    const req: IAxiosResponse = yield call(getRequest, `${companyId}/databases?name=${name}&load=total&load=access${args.sort ? `&sort=${args.sort}` : ''}`, {});
    yield put(getDatatablesListReturnAction({ datatableList: req.data.data }));
  } catch (e) {
    console.log('e: ', e);
    yield put(setStatusCodeAction(e))
  }
}


export function* Sagas() {
  yield takeLatest(GET_DATATABLE_LIST, getDatatableList);
}

// All sagas to be loaded
export default Sagas;
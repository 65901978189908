import {
  GET_FORM_STATUSES,
  GET_FORM_STATUSES_RETURN,
  GET_FORM_STATUSES_ERROR,
  GET_FORM_DETAIL,
  GET_FORM_DETAIL_RETURN,
  GET_FORM_DETAIL_ERROR,
  GET_FORM_STATUS,
  GET_FORM_STATUS_RETURN,
  GET_FORM_STATUS_ERROR,
  SAVE_PERMISSION,
  SAVE_PERMISSION_RETURN,
  SAVE_PERMISSION_ERROR,
  PUBLISH_PERMISSION,
  PUBLISH_PERMISSION_RETURN,
  PUBLISH_PERMISSION_ERROR,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_RETURN,
  GET_PERMISSION_LIST_ERROR,
  CLEAR_SAVE_PERMISSION_ERROR,
  CLEAR_SAVE_PERMISSION_SUCCESS
} from './constant';
import { IFormStatus, IFormStatusWithAction } from '../workflow/interface/form-status';
import { ICustomFieldDetail } from '../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IForm } from '../form/interface/form';
import { IFormFieldPermission } from './interface/field-permission';
import { message } from './message';
import { IApiPermissionObject } from './interface/api-permission';

export interface IFormPermissionReducer {
  formStatuses: IFormStatusWithAction[];
  formStatusesLoading: boolean;
  formDetailLoading: boolean;
  formDetailErrorMessage: string;
  formDetailField: ICustomFieldDetail[];
  formDetail: IForm;
  formFieldPermissionList: IFormFieldPermission[];
  getFormFieldPermissionLoading: boolean;
  getFormFieldPermissionError: '';
  formStatus: IFormStatus;
  formStatusLoading: boolean;
  formRoleSaveLoading: boolean;
  formSaveErrorMessage: string;
  formSaveMessage: string;
  saveRoleType: string;
  permissionList: IApiPermissionObject[];
  getPermissionListLoading: boolean;
  getPermissionListErrorMessage: string;
}

const initialState: IFormPermissionReducer = {
  formStatuses: [],
  formStatusesLoading: false,
  formDetailLoading: false,
  formDetailErrorMessage: '',
  formDetailField: [],
  formDetail: {} as IForm,
  formFieldPermissionList: [],
  getFormFieldPermissionLoading: false,
  getFormFieldPermissionError: '',
  formStatus: {} as IFormStatus,
  formStatusLoading: false,
  formRoleSaveLoading: false,
  formSaveErrorMessage: '',
  formSaveMessage: '',
  saveRoleType: '',
  permissionList: [],
  getPermissionListLoading: false,
  getPermissionListErrorMessage: '',
};

const permissionReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_FORM_STATUSES:
      newState.formStatusesLoading = true;
      return newState;

    case GET_FORM_STATUSES_RETURN:
      newState.formStatuses = action.formStatusList;
      newState.formStatusesLoading = false;
      return newState;

    case GET_FORM_STATUSES_ERROR:
      newState.formStatusesLoading = false;
      return newState;

    case GET_FORM_STATUS:
      newState.formStatusLoading = true;
      return newState;

    case GET_FORM_STATUS_RETURN:
      newState.formStatus = action.data;
      newState.formStatusLoading = false;
      return newState;

    case GET_FORM_STATUS_ERROR:
      newState.formStatusLoading = false;
      return newState;

    case GET_FORM_DETAIL:
      newState.formDetailLoading = true;
      newState.formDetailErrorMessage = '';
      newState.formDetailField = [];
      return newState;

    case GET_FORM_DETAIL_RETURN:
      let tempFields = [...state.formDetailField];
      tempFields = action.fieldData.filter((field: ICustomFieldDetail) => field.type !== 'spacer');
      newState.formDetail = action.formData;
      newState.formDetailField = tempFields;
      newState.formDetailLoading = false;
      return newState;

    case GET_FORM_DETAIL_ERROR:
      newState.formDetailLoading = false;
      newState.formDetailErrorMessage = action.message;
      return newState;

    case SAVE_PERMISSION:
      newState.formRoleSaveLoading = true;
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      newState.saveRoleType = '';
      return newState;

    case SAVE_PERMISSION_RETURN:
      if (action.saveRoleType === 'draft') {
        newState.formRoleSaveLoading = false;
        newState.formSaveMessage = message.saveFormPermissionSuccessMessage;
      }
      newState.permissionList = action.permissionList;
      newState.saveRoleType = action.saveRoleType;
      return newState;

    case SAVE_PERMISSION_ERROR:
      newState.formRoleSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_PERMISSION:
      newState.saveRoleType = '';
      return newState;

    case PUBLISH_PERMISSION_RETURN:
      newState.formRoleSaveLoading = false;
      newState.formSaveMessage = message.publishFormPermissionSuccessMessage;
      return newState;

    case PUBLISH_PERMISSION_ERROR:
      newState.formRoleSaveLoading = false;
      newState.formSaveErrorMessage = action.formSaveErrorMessage;
      return newState;

    case GET_PERMISSION_LIST:
      newState.getPermissionListLoading = true;
      newState.getPermissionListErrorMessage = '';
      newState.formSaveErrorMessage = '';
      newState.formSaveMessage = '';
      return newState;

    case GET_PERMISSION_LIST_RETURN:
      newState.getPermissionListLoading = false;
      newState.getPermissionListErrorMessage = '';
      newState.permissionList = action.permissionList;
      return newState;

    case GET_PERMISSION_LIST_ERROR:
      newState.getPermissionListLoading = false;
      newState.getPermissionListErrorMessage = action.getRoleListErrorMessage;
      return newState;

    case CLEAR_SAVE_PERMISSION_SUCCESS:
      newState.formSaveMessage = '';
      return newState;

    case CLEAR_SAVE_PERMISSION_ERROR:
      newState.formSaveErrorMessage = '';
      return newState;

    default:
      return state;
  }
}

export default permissionReducer

import React from 'react';
import SectionBody from './body';
import SectionHeader from './header';

interface ISection {

}

const Section: React.FC<ISection> = (props) => {
  return <div className='section'>
    <SectionHeader />
    <SectionBody />
  </div>
}

export default Section;
import React, { useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import message from '../auth/message';
import { Route, useHistory } from 'react-router-dom';
import FooterComponent from './partials/footer/index';
import HeaderComponent from './partials/header/index';
import Home from '../home-module';
import AdminModule from '../admin-module';
// import BpmManager from '../bpm-manager-module';
import { IRootState } from '../../reducers';
import FileViewerComponent from '../../common/general/file-viewer';
import { HasPageChangePopup } from './has-page-change-popup';
import { IHasPageChangeRef } from './interface/has-page-change';
import PortalModule from '../portal-module/portal-route';
import { ConfirmationMessage } from '../../common/general/confirmation-modal';

import {
  setToken, clearLocalStorage
} from '../../common/api-request';
import ConfigService from '../../common/config';

const Main: React.FC = () => {
  const history = useHistory();
  const hasPageChangeRef = useRef<IHasPageChangeRef>({ hasChange: false });

  const data = useSelector((state: IRootState) => state.auth);

  useEffect(() => {
    if (data.logoutStatus === message.logoutSuccess && data.token === '' && data.logoutMessageModal === false) {
      history.push('/auth');
    }
  }, [data.logoutMessageModal, data.logoutStatus, data.token, history])

  return (
    <>
      <Fragment>
        <HeaderComponent hasPageChangeRef={hasPageChangeRef} />
        <div className='main-container' >
          <Route exact path='/' render={(props) => <Home {...props} />} />

          <Route exact path='/admin' render={(props) => <AdminModule {...props} hasPageChangeRef={hasPageChangeRef} />} />
          <Route path='/admin/:submodule' render={(props) => <AdminModule {...props} hasPageChangeRef={hasPageChangeRef} />} />

          {/* <Route exact path='/bpm-manager' render={(props) => <BpmManager {...props} hasPageChangeRef={hasPageChangeRef} />} />
          <Route path='/bpm-manager/:submodule' render={(props) => <BpmManager {...props} hasPageChangeRef={hasPageChangeRef} />} /> */}

          <Route exact path='/documents' render={(props) =>
            <ErrorModule detailText='This feature is disabled. Please use new Web version.' redirectToEndUser={true} redirectToLoginPage={true} />
          } />

          <Route path='/documents/:submodule' render={(props) =>
            <ErrorModule detailText='This feature is disabled. Please use new Web version.' redirectToEndUser={true} redirectToLoginPage={true} />}
          />

          <Route exact path={'/attachment/:fileId'} render={(props) => <FileViewerComponent {...props} />} />

          <Route exact path='/portal' render={(props) => <PortalModule {...props} hasPageChangeRef={hasPageChangeRef} />} />
          <Route path='/portal/:submodule' render={(props) => <PortalModule {...props} hasPageChangeRef={hasPageChangeRef} />} />

          <FooterComponent />

        </div>
        <HasPageChangePopup hasPageChangeRef={hasPageChangeRef} />
      </Fragment >
    </>
  );
}

export default Main;

export const ErrorModule: React.FC<{ detailText: string; externalLink?: string; redirectToLoginPage?: boolean; redirectToEndUser?: boolean; }> = ({ detailText, externalLink, redirectToLoginPage, redirectToEndUser }) => {
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (externalLink) {
        window.location.replace(externalLink);
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, [externalLink]);

  const redirectToEndUserAction = () => {
    const url = ConfigService.loadConfig().general.apiBaseUrl;
    const flutterInternalUrl = 'https://internal.cloud.mobbizapps.com/';
    const flutterProdUrl = 'https://cloud.mobbizapps.com/';
    const envUrl = ['uat', 'qa', 'pre-production'];
    const isInternalUrl = envUrl.filter((e) => url.includes(e)).length > 0;
    window.open(isInternalUrl ? flutterInternalUrl : flutterProdUrl, '_blank');
  };

  const redirectToLoginPageAction = () => {
    setToken('')
    clearLocalStorage();
    history.push('/auth');
  };

  return <ConfirmationMessage
    confirmButtonName={'Confirm'}
    confirmButtonColor={'green'}
    cancelButtonColor={'grey'}
    close={() => { }}
    open={true}
    confirm={() => window.location.reload()}
    cancel={() => { }}
    headerText="Unable to access page!"
    detailText={detailText}
    loading={false}
    type='error'
    showHeaderText={true}
    noCancelButton={true}
    redirectToEndUser={redirectToEndUser}
    redirectToEndUserAction={() => redirectToEndUserAction()}
    redirectToLoginPage={redirectToLoginPage}
    redirectToLoginPageAction={() => redirectToLoginPageAction()}

  />
}
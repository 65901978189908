import { v4 as uuid } from 'uuid';
import { IConfigName } from '../../../../../../../../common/field/type/interface/field-type-object';
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';
import { IOption } from '../../../../../../../../common/interface/option';
import { IField, IFieldAccessType } from '../../../../../users/interface/field';
import { IOptionWidget } from './interface/option-widget';

export const GET_AUTOMATION_LIST =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_LIST';
export const GET_AUTOMATION_LIST_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_LIST_RETURN';
export const CREATE_AUTOMATION =
  'app/app/admin-module/business-process/configuration/automation/CREATE_AUTOMATION';
export const CREATE_AUTOMATION_RETURN =
  'app/app/admin-module/business-process/configuration/automation/CREATE_AUTOMATION_RETURN';
export const CREATE_AUTOMATION_ERROR =
  'app/app/admin-module/business-process/configuration/automation/CREATE_AUTOMATION_ERROR';
export const GET_AUTOMATION_CONTENT_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_CONTENT_RETURN';
export const GET_AUTOMATION_CONTENT =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_CONTENT';
export const UPDATE_AUTOMATION =
  'app/app/admin-module/business-process/configuration/automation/UPDATE_AUTOMATION';
export const UPDATE_AUTOMATION_RETURN =
  'app/app/admin-module/business-process/configuration/automation/UPDATE_AUTOMATION_RETURN';
export const UPDATE_AUTOMATION_ERROR =
  'app/app/admin-module/business-process/configuration/automation/UPDATE_AUTOMATION_ERROR';
export const DELETE_AUTOMATION =
  'app/app/admin-module/business-process/configuration/automation/DELETE_AUTOMATION';
export const DELETE_AUTOMATION_RETURN =
  'app/app/admin-module/business-process/configuration/automation/DELETE_AUTOMATION_RETURN';
export const GET_FORM_FIELD =
  'app/app/admin-module/business-process/configuration/automation/GET_FORM_FIELD';
export const GET_FORM_FIELD_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_FORM_FIELD_RETURN';
export const GET_PUBLISH_FORM =
  'app/app/admin-module/business-process/configuration/automation/GET_PUBLISH_FORM';
export const GET_PUBLISH_FORM_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_PUBLISH_FORM_RETURN';
export const GET_FORM_STATUS =
  'app/app/admin-module/business-process/configuration/automation/GET_FORM_STATUS';
export const GET_FORM_STATUS_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_FORM_STATUS_RETURN';
export const GET_BP_SELECTED_FORM_FIELD =
  'app/app/admin-module/business-process/configuration/automation/GET_BP_SELECTED_FORM_FIELD';
export const GET_BP_SELECTED_FORM_FIELD_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_BP_SELECTED_FORM_FIELD_RETURN';
export const GET_INPUT_FIELD =
  'app/app/admin-module/business-process/configuration/automation/GET_INPUT_FIELD';
export const GET_INPUT_FIELD_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_INPUT_FIELD_RETURN';
export const GET_REFERENCES =
  'app/app/admin-module/business-process/configuration/automation/GET_REFERENCES';
export const GET_REFERENCES_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_REFERENCES_RETURN';
export const GET_DATATABLE_INPUT_FIELD =
  'app/app/admin-module/business-process/configuration/automation/GET_DATATABLE_INPUT_FIELD';
export const GET_DATATABLE_INPUT_FIELD_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_DATATABLE_INPUT_FIELD_RETURN';
export const PUBLISH_AUTOMATION =
  'app/app/admin-module/business-process/configuration/automation/PUBLISH_AUTOMATION';
export const PUBLISH_AUTOMATION_RETURN =
  'app/app/admin-module/business-process/configuration/automation/PUBLISH_AUTOMATION_RETURN';
export const PUBLISH_AUTOMATION_ERROR =
  'app/app/admin-module/business-process/configuration/automation/PUBLISH_AUTOMATION_ERROR';
export const RESET_PUBLISH_AUTOMATION_MESSAGE =
  'app/app/admin-module/business-process/configuration/automation/RESET_PUBLISH_AUTOMATION_MESSAGE';
export const CLEAR_SELECTED_AUTOMATION =
  'app/app/admin-module/business-process/configuration/automation/CLEAR_SELECTED_AUTOMATION';
export const GET_DATATABLE_LIST =
  'app/app/admin-module/business-process/configuration/automation/GET_DATATABLE_LIST';
export const GET_DATATABLE_LIST_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_DATATABLE_LIST_RETURN';
export const GET_SLA_STATUS =
  'app/app/admin-module/business-process/configuration/automation/GET_SLA_STATUS';
export const GET_SLA_STATUS_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_SLA_STATUS_RETURN';
export const GET_AUTOMATION_FIELD_INPUT =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_FIELD_INPUT';
export const GET_AUTOMATION_FIELD_INPUT_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_FIELD_INPUT_RETURN';
export const GET_AUTOMATION_PLACEHOLDERS =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_PLACEHOLDERS';
export const GET_AUTOMATION_PLACEHOLDERS_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_AUTOMATION_PLACEHOLDERS_RETURN';

export const GET_PORTALS =
  'app/app/admin-module/business-process/configuration/automation/GET_PORTALS';
export const GET_PORTALS_RETURN =
  'app/app/admin-module/business-process/configuration/automation/GET_PORTALS_RETURN';

export const triggerOptionList: IOptionWidget[] = [
  {
    type: 'trigger',
    name: 'field-update',
    iconName: 'iconEdit',
    title: 'Field Value Change',
    description: `Rule is run when an issue's field value changes.`,
    iconWidth: 15,
    iconHeight: 15,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      padding: '4px',
    },
  },
  {
    type: 'trigger',
    name: 'status-update',
    iconName: 'flag',
    title: 'Status Update',
    description: `Rule is run when a Dynamic Action is executed.`,
    iconWidth: 18,
    iconHeight: 19,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'trigger',
    name: 'sla-miss',
    iconName: 'flag',
    title: 'SLA Update',
    description: `Rule is run when a Dynamic Action is executed.`,
    iconWidth: 18,
    iconHeight: 19,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'trigger',
    name: 'scheduled',
    iconName: 'scheduler',
    title: 'Scheduler',
    description: `Rule is run when a Dynamic Action is executed.`,
    iconWidth: 18,
    iconHeight: 19,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
];

export const newComponentIcon: IOptionWidget[] = [
  {
    type: 'condition',
    name: 'newCondition',
    iconName: 'fieldIssue',
    title: 'New condition',
    description: `Actions will only execute if all conditions preceding them pass.`,
    iconWidth: 25,
    iconHeight: 25,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'newAction',
    iconName: 'newAction',
    title: 'New action',
    description: `Actions perform changes to a system.`,
    iconWidth: 30,
    iconHeight: 30,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
];

export const actionWidget: IOptionWidget[] = [
  {
    type: 'action',
    name: 'update-status',
    iconName: 'flag',
    title: 'Update Status',
    description: `Rules is run when a dynamic action is executed.`,
    iconWidth: 18,
    iconHeight: 19,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'create-document',
    iconName: 'newDoc',
    title: 'Create Document',
    description: `Create a new Document in:`,
    iconWidth: 16,
    iconHeight: 16,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'edit-form',
    iconName: 'pen',
    title: 'Edit Form',
    description: `Set values for fields on the issue. Simply add fields you want to edit`,
    iconWidth: 16,
    iconHeight: 16,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'compute-field',
    iconName: 'computeField',
    title: 'Compute Fields',
    description:
      'Set values for fields on the issue. Simply add the fields you want to edit.',
    iconWidth: 16,
    iconHeight: 16,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'send-email',
    iconName: 'mail',
    title: 'Send Email',
    description: 'Rules is run when a dynamic action is executed.',
    iconWidth: 16,
    iconHeight: 16,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'create-user',
    iconName: 'createUser',
    title: 'Create User',
    description: 'Rules is run when a dynamic action is executed.',
    iconWidth: 16,
    iconHeight: 16,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'send-sms',
    iconName: 'sendSMS',
    title: 'Send SMS',
    description: 'Rules is run when a dynamic action is executed.',
    iconWidth: 18,
    iconHeight: 18,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'action',
    name: 'generate-pdf',
    iconName: 'generatePdf',
    title: 'Auto-generate PDF',
    description:
      'Generates PDF automatically when a dynamic action is executed',
    iconWidth: 18,
    iconHeight: 18,
    containerStyle: {
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
];

export const conditionWidget: IOptionWidget[] = [
  // {
  //   type: 'condition',
  //   name: 'advanceCompareCondition',
  //   iconName: 'compare',
  //   title: 'Advanced compare condition',
  //   description: `Compares two values`,
  //   iconWidth: 25,
  //   iconHeight: 25,
  //   containerStyle: {
  //     borderRadius: '50%',
  //     height: '26px',
  //     width: '26px',
  //     border: '1px solid',
  //   }
  // },
  {
    type: 'condition',
    name: 'field-condition',
    iconName: 'fieldIssue',
    title: 'Issue fields condition',
    description: `Check whether an issue's field meets a certain criteria.`,
    iconWidth: 24,
    iconHeight: 24,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    type: 'condition',
    name: 'if-else-block',
    iconName: 'flowchart',
    title: 'If / else block',
    description: `Perform different actions using if, if - else and else to control the flow.`,
    iconWidth: 20,
    iconHeight: 16,
    containerStyle: {
      border: '1px solid',
      borderRadius: '50%',
      height: '26px',
      width: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  // {
  //   type: 'condition',
  //   name: 'issueAttachment',
  //   iconName: 'paperclip',
  //   title: 'Issue attachments',
  //   description: `Checks if issue attachments exist or don't exist.`,
  //   iconWidth: 18,
  //   iconHeight: 18,
  //   containerStyle: {
  //     border: '1px solid',
  //     borderRadius: '50%',
  //     height: '26px',
  //     width: '26px',
  //     padding: '3px',
  //   }
  // },
  // {
  //   type: 'condition',
  //   name: 'userCondition',
  //   iconName: 'userCondition',
  //   title: 'UserCondition',
  //   description: `User matches criteria: Reporter in group 'customers'.`,
  //   iconWidth: 25,
  //   iconHeight: 25,
  //   containerStyle: {
  //     border: '1px solid',
  //     borderRadius: '50%',
  //     height: '26px',
  //     width: '26px',
  //   }
  // },
];

export const triggerFieldUpdateOperator = [
  {
    key: uuid(),
    value: 'create-form',
    text: 'Create Form',
  },
  {
    key: uuid(),
    value: 'edit-form',
    text: 'Edit Form',
  },
  {
    key: uuid(),
    value: 'transition-status',
    text: 'Status Transition',
  },
  {
    key: uuid(),
    value: 'assign',
    text: 'Assign',
  },
];

export const AutomationActionMessage: IMessageProps[] = [
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'negative',
    reducerContainer: 'automation',
    reducerItem: 'publishAutomationErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'success',
    reducerContainer: 'automation',
    reducerItem: 'publishAutomationSuccessMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'negative',
    reducerContainer: 'automation',
    reducerItem: 'saveAutomationErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'negative',
    reducerContainer: 'automation',
    reducerItem: 'updateAutomationErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'success',
    reducerContainer: 'automation',
    reducerItem: 'saveAutomationSuccessMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: RESET_PUBLISH_AUTOMATION_MESSAGE,
    type: 'success',
    reducerContainer: 'automation',
    reducerItem: 'updateAutomationSuccessMessage',
    hasCloseIcon: true,
  },
];

export const actionIconStyle: {
  [iconName: string]: { height: number; width: number };
} = {
  flag: {
    height: 18,
    width: 15,
  },
  pen: {
    height: 15,
    width: 15,
  },
  newDoc: {
    height: 20,
    width: 16,
  },
  computeField: {
    height: 17,
    width: 14,
  },
  mail: {
    height: 20,
    width: 16,
  },
  createUser: {
    height: 20,
    width: 16,
  },
  sendSMS: {
    height: 18,
    width: 18,
  },
  generatePdf: {
    height: 19,
    width: 19,
  },
  default: {
    height: 25,
    width: 25,
  },
};

export const conditionIconStyle: {
  [iconName: string]: { height: number; width: number };
} = {
  flowchart: {
    height: 25,
    width: 15,
  },
  fieldIssue: {
    height: 25,
    width: 25,
  },
  default: {
    height: 25,
    width: 25,
  },
};

export const triggerIconStyle: {
  [iconName: string]: { height: number; width: number };
} = {
  flag: {
    height: 18,
    width: 15,
  },
  iconEdit: {
    height: 13,
    width: 13,
  },
  default: {
    height: 25,
    width: 25,
  },
  scheduler: {
    height: 18,
    width: 15,
  },
};

export const operatorOption: IOption[] = [
  {
    key: 0,
    text: '+',
    value: '+',
  },
  {
    key: 1,
    text: '-',
    value: '-',
  },
  {
    key: 2,
    text: '/',
    value: '/',
  },
  {
    key: 3,
    text: '*',
    value: '*',
  },
];

export const statusInputField: IField[] = [
  {
    id: 'statusId',
    label: 'Status',
    inputConfig: {
      type: 'enum',
      config: {
        multiselect: false,
        items: [],
      },
    },
    accessType: IFieldAccessType.ReadWrite,
    validationSchema: {
      anyOf: [
        {
          type: 'array',
          items: {
            anyOf: [
              {
                type: 'string',
                minLength: 1,
              },
              {
                type: 'object',
                properties: {
                  id: {
                    type: 'string',
                    format: 'uuid',
                  },
                },
              },
            ],
          },
        },
        {
          type: 'null',
        },
      ],
    },
  },
];

export const fileInputField: IField = {
  id: uuid(),
  label: 'File Upload',
  hint: '',
  inputConfig: {
    type: 'file',
    config: {
      enableCameraInput: false,
    },
  },
  configName: IConfigName.FileUpload,
  accessType: IFieldAccessType.ReadWrite,
  defaultValue: null,
  validationSchema: {
    anyOf: [
      {
        type: 'object',
        required: ['id', 'name', 'type', 'size'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          size: {
            type: 'number',
          },
          url: {
            type: 'string',
            format: 'uri',
          },
        },
      },
      {
        type: 'null',
      },
    ],
  },
};

import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateUserStatusAction } from '../action';
// import { IRootState } from '../../../../../reducers';
import AllowedUserListActionControl from '../../../../../common/utils/user-list-action-control';

interface IUserStatus {
  userStatus: boolean;
  userId: string;
  validateUserProfile: AllowedUserListActionControl;
  companyId: string;
}

const UserStatus: React.FC<IUserStatus> = (props) => {
  // const dispatch = useDispatch();
  // const userLoggedIn = useSelector((state: IRootState) => state.auth.userLoggedIn);
  let [isUserActive] = useState(props.userStatus);
  // const { companyId, userId } = props

  // const setUserStatus = (active: boolean) => {
  //   setIsUserActive(active === true ? isUserActive = false : isUserActive = true)
  //   let dataToUpdate = {
  //     userId,
  //     company: companyId,
  //     data: {
  //       active: active === true ? false : true
  //     }
  //   }
  //   dispatch(updateUserStatusAction(dataToUpdate));
  // }

  return (<>
    <div className="ui fitted toggle checkbox checked table-checkbox-toggle">
      <input style={{ cursor: 'not-allowed' }} type="checkbox" onChange={() => {
        // if (props.validateUserProfile.allowedUserUpdate(companyId) && userLoggedIn.user.id !== props.userId) {
        //   setUserStatus(props.userStatus)
        // }
      }} checked={isUserActive} />
      <label></label>
    </div>
  </>);
}

export default UserStatus;
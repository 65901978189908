import React, { useState } from 'react';
import { IFieldPermissionSettings } from '../..';
import AutoHeightTextarea from '../../../../../../../../../common/utils/auto-height-textarea';
import { IPermissionFieldFilterConfig } from '../../../interface/field-permission';
import { IRulesPermission } from '../../../interface/rules';
import BoolSelection from '../utils/bool-selection';
import ConditionConfigSelection from '../utils/config-selection';
import ActionPermissionConfig from './permission';

export type TConfigOption = 'permission' | 'value' | 'clear';
export type TConfigType = 'onTrue' | 'onFalse';
export type TRulesPermission = keyof IRulesPermission;

interface IFrontendAutomationConfig extends IFieldPermissionSettings {
  permissionConfigRef: React.MutableRefObject<IPermissionFieldFilterConfig | undefined>;
}

const ActionAutomationConfig: React.FC<IFrontendAutomationConfig> = (props) => {

  const [boolSelected, setBoolSelected] = useState(true);
  const [configSelected, setConfigSelected] = useState<TRulesPermission>('setPermission');
  const [refresher, setRefresher] = useState(0);

  const updateConfig = (configType: TConfigType, name: TRulesPermission, value: any) => {
    let tempConfig: any = configType === 'onTrue' ? { ...onTrueConfig } : { ...onFalseConfig };
    tempConfig[name] = value;
    setConfig(tempConfig, configType);
  }

  const setConfig = (config: IRulesPermission, configType: TConfigType) => {
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.conditionRule[configType] = config
      setRefresher(refresher + 1);
    }
  }

  const disabledMessageChange = (value: string) => {
    if (props.permissionConfigRef.current) {
      props.permissionConfigRef.current.conditionRule['disabledMessage'] = value;
      setRefresher(refresher + 1);
    }
  }

  const onTrueConfig = props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule.onTrue : {};
  const onFalseConfig = props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule.onFalse : {};
  const disabledMessage = props.permissionConfigRef.current ? props.permissionConfigRef.current.conditionRule.disabledMessage : '';

  const defaultTypeModuleProps = {
    ...props,
    boolSelected,
    updateConfig,
    rules: boolSelected ? (onTrueConfig as IRulesPermission) : (onFalseConfig as IRulesPermission)
  }

  return <>
    <BoolSelection
      value={boolSelected}
      setBoolSelected={setBoolSelected}
    />
    <ConditionConfigSelection
      setConfigSelected={setConfigSelected}
      configOption={configSelected}
      configSettingsType={'action'}
      fieldData={props.fieldData}
      boolSelected={boolSelected}
    />
    {configSelected === 'setPermission' &&
      <ActionPermissionConfig
        {...defaultTypeModuleProps}
        updateConfig={updateConfig}
      />
    }
    {configSelected === 'setDisabledMessage' &&
      <div className='disabled-message-container'>
        <AutoHeightTextarea
          textCallback={disabledMessageChange}
          defaultValue={disabledMessage}
          properties={{
            className: 'field-input',
            placeholder: 'Set message when action is non-clickable..'
          }}
        />
      </div>
    }
  </>
}

export default ActionAutomationConfig;
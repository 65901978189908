import React from 'react';
import { ICommentAttachment } from '../../../../../interface/comment';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { getFilters } from '../../../../../../../../main/partials/header/hexToCssFilter';

export const renderAttachmentNoPreview = (attachment: ICommentAttachment) => {

  return (
    <div className={`attachment-header no-preview ${attachment.type}`}>
      <div className='icon-no-preview'>
        <Image className={`file-icon no-preview`}
          src={icons.black.download} style={{ filter: getFilters('#7a797a').filter, }} />
      </div>
      <div className="file-info"
        onClick={() => {
          const aLink = document.createElement('a');
          aLink.href = attachment.url;
          const event = new MouseEvent('click');
          aLink.dispatchEvent(event);
          aLink.remove();
        }}>{attachment.name}</div>
    </div>
  );
};
import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../../../common/icons';
import { useDrop } from 'react-dnd';

interface IDropboxRange {
  withItem: any;
  itemIndex: number;
  insertDropField(selectedItem: any, itemIndex: number, withItem: any): void;
  deleteFilter(index: number, type: string): void;
  editConfiguration(index: number): void;
  dragIconOutside?: boolean;
}

const DropboxRange: React.FC<IDropboxRange> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'customFilterField',
    drop: selectedItem => {
      props.insertDropField(selectedItem, props.itemIndex, props.withItem)
    }
  })

  return (
    <div ref={dropRef} className={`dropbox`}>
      <div className={`field`} >
        {!props.dragIconOutside &&
          <Image src={icons.blue.dragIcon}
            style={{ width: '10px', float: 'left', marginTop: '2px' }}
          />
        }
        <Image src={props.withItem.icon}
          style={{ width: '15px', marginRight: '5px', marginTop: '-3px' }}
        />
        <span style={{ marginRight: '5px' }}>→</span>
        <Image src={props.withItem.icon}
          style={{ width: '15px', marginRight: '10px', marginTop: '-3px' }}
        />
        {props.withItem.name || props.withItem.label}
        <Image src={icons.blue.delete}
          style={{ width: '12px', float: 'right', marginTop: '2px', cursor: 'pointer' }}
          onClick={() => props.deleteFilter(props.itemIndex, 'field-range')}
        />
        <Image src={icons.blue.edit}
          onClick={() => props.editConfiguration(props.itemIndex)}
          style={{ width: '15px', float: 'right', marginTop: '2px', marginRight: '3px', cursor: 'pointer' }}
        />
      </div>
    </div>
  );
}

export default DropboxRange;

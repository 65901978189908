import React, { useState, useRef, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Dropdown } from 'semantic-ui-react-bpm';
import { ICustomFieldProps } from '../..';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig } from '../../interface/custom-field-detail';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { IFieldConfig } from '../../../../component/admin-module/module/users/interface/field';
import DefaultField from '../../default-value';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../reducers';
import { errorIndicator, fieldVisibility } from '../../constant';
import DropdownPopperOption from '../../../general/dropdown-popper-option';
import { IUserFieldPermission } from '../../../../component/admin-module/module/users/interface/user-field-permission';
import { plainToClass } from 'class-transformer';
import { IConfigName } from '../../../field/type/interface/field-type-object';

export default class GeolocationConfig extends DefaultFieldConfig {

  name = 'geolocation';

  Render: React.FC<ICustomFieldProps> = (props) => {
    if (props.defaultData.configName !== IConfigName.Geolocation) {
      const OtherElement = new DefaultFieldConfig();
      return <OtherElement.Render {...props} />
    }
    const [mandatory, setMandatory] = useState(false);
    const [searchable, setSearchable] = useState(false);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [isMultiline, setIsMultiline] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [fieldPermissionColor, setFieldPermissionColor] = useState('');

    const companyId = props.companyId;

    const user = useSelector((state: IRootState) => state.user);
    const fieldNameRef = useRef(document.createElement('input'));
    const defaultvalueRef = useRef({ 'defaultField': { value: { lat: undefined, long: undefined } } });
    const helpRef = useRef(document.createElement('textarea'));
    const minLengthRef = useRef(document.createElement('input'));
    const maxLengthRef = useRef(document.createElement('input'));

    const [error, setError] = useState(errorIndicator)


    const inputObject = () => {
      return {
        ...configData,
      }
    }

    const updateConfig = () => {
      const permission = setFieldPermission(fieldPermissionColor || 'red');
      if (validateRequiredFormField()) {

        const config = plainToClass(IConfig, {
          ...inputObject(),
        })

        let defaultValue: any = null;
        if (defaultvalueRef.current['defaultField']) {
          defaultValue = defaultvalueRef.current['defaultField'].value;
          defaultValue = defaultValue || null;
        }

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: mandatory,
            label: fieldNameRef.current.value,
            hint: helpRef.current.value,
            config: config,
            multiline: isMultiline,
            permission,
            defaultValue: defaultValue,
            confidential: confidential,
          }
        }

        props.submit(data, props.fieldId || '')
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };
      let isError = false;
      currentError.fieldname = false;
      if (props.formType === 'FormBuilder' && !fieldNameRef.current.value) {
        currentError.fieldname = true;
        isError = true;
      }
      const defaultValue: any = defaultvalueRef.current['defaultField'];
      if (defaultValue && defaultValue?.error && defaultValue?.error.length > 0) {
        isError = true;
      }

      setError(currentError)
      return !isError
    }

    const setFieldPermission = (permissionColor: string) => {
      const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.name === permissionColor);
      const permission = colorPermission.length > 0 ? colorPermission[0].id : undefined;
      return permission;
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config)
        setFieldData(data)
        fieldNameRef.current.value = data.label || '';
        helpRef.current.value = data.hint || '';

        const colorPermission = user.userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.id === data.permission);
        const permissionColor = colorPermission.length > 0 ? colorPermission[0].name : undefined;
        setFieldPermissionColor(permissionColor || 'red');
        setSearchable(data.searchable ? true : false);
        setMandatory(data.required ? true : false);
        setIsMultiline(data.multiline ? true : false);
        setConfidential(data.confidential || false);
        defaultvalueRef.current = { defaultField: { value: { lat: 0, lon: 0, ...data.defaultValue } } };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='geolocation-field-modal'
        className={`geolocation-field-modal`}
        centered={false}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`${props.defaultData.configName || 'Custom'} Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form'  >
            <div className={``}>
              <div className='string-normal-config'>
                <Segment className='borderless' style={{
                  marginTop: `${props.formType === 'FormBuilder'}`
                }}>
                  <div className='header-field'>
                    {props.formType === 'User' &&
                      <span className='field-visibility'>
                        Visibility&nbsp;&nbsp;&nbsp;
                        <Dropdown
                          popperContainer={DropdownPopperOption}
                          selection
                          value={fieldPermissionColor || 'red'}
                          options={fieldVisibility}
                          selectOnBlur={false}
                          onChange={(event, target: any) => { setFieldPermissionColor(target.value) }}
                        />
                      </span>
                    }
                    {(props.formType !== 'FormBuilder') &&
                      <div className="five wide field mandatory-field" onClick={() => { setMandatory(!mandatory) }}>
                        <span>Mandatory</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={mandatory} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                    {props.formType === 'User' &&
                      <div className="five wide field searchable-field" onClick={() => { setSearchable(!searchable) }}>
                        <span>Searchable</span>
                        <div className="ui fitted toggle checkbox">
                          <input className="hidden" onChange={() => { }} type="checkbox" checked={searchable} value="" />
                          <label></label>
                        </div>
                      </div>
                    }
                  </div>
                  {(props.formType === 'User' || props.formType === 'FormBuilder') &&
                    <Form.Group widths='equal'>
                      <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                        <label>Field Name</label>
                        <div className="ui input">
                          <input onChange={() => {
                            error.fieldname = (fieldNameRef.current.value ? false : true)
                          }
                          } ref={fieldNameRef} type="text" />
                        </div>
                      </div>
                    </Form.Group>
                  }
                  <Form.Group widths='equal'>
                    <div className="field">
                      <label>Field Info</label>
                      <textarea rows={3} ref={helpRef}></textarea>
                    </div>
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <DefaultField
                      defaultvalueRef={defaultvalueRef}
                      configData={configData}
                      fieldData={fieldData}
                      inputObject={inputObject}
                      minLengthRef={minLengthRef}
                      maxLengthRef={maxLengthRef}
                      refreshValue={0}
                      showDefaultValue={true}
                    />
                  </Form.Group>
                </Segment>
              </div>
            </div>
            {props.formType === 'FormBuilder' &&
              <div id='custom-field-checkbox'>
                <div className="ui fitted checkbox" onClick={() => setConfidential(!confidential)}>
                  <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} value="" />
                  <label>Confidential</label>
                </div>
              </div>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions className='borderless'>
          <div className={`date-action-container`}>
            <div>
              <div className='label-bottom'>
                <label><strong> * </strong> Fields are required</label>
              </div>
            </div>
            <div>
              <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
              {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
                <Button color='red' className='btn-action create-btn'
                  onClick={() => {
                    props.cancel(props.fieldId)
                    props.openDeleteModal(props.fieldId, fieldData.label || '');
                  }}>Delete</Button>
              }
              {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
                <Button color='green' className='btn-action' onClick={() => { updateConfig() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
              }
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  }
}
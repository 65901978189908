import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { checkAuthTokenAction } from './action'
import {
  setToken
} from '../../../common/api-request';

/**
 * This will only run once during initialization of the app.
 * This only do check if there is existing token in localstorage
 * and check if token is valid
 * 
 * Note: This is added to avoid infinite loop checking token from 
 * local storage
 */
const TokenChecker = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      setToken(token || '');
      dispatch(checkAuthTokenAction(token));
    }
  }, [dispatch]);

  return (
    <></>
  )
}

export default TokenChecker
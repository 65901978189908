import React from 'react';
import { Image } from 'semantic-ui-react-bpm';
import { icons } from '../../../../../../../common/icons';
import { getFilters } from '../../../../../../main/partials/header/hexToCssFilter';

interface IListSideViewFields {

}

const ListSideViewFields: React.FC<IListSideViewFields> = (props) => {
  return <div className={`list-sv-field-item`}>
    <div className='sv-field'>
      <Image style={{ filter: getFilters('#61A7B3').filter }} src={icons.black.calendar} className={`icon`} />
      <label className='label'>{'Order Date'}</label>
      <Image style={{ filter: getFilters('#8A9296').filter }} src={icons.black.dragIcon} className={'drag-icon'} />
    </div>
  </div >
}

export default ListSideViewFields;
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';
import { IApiCustomFilterField } from '../custom-filter/interface/custom-filter-field';
import { v4 as uuid } from 'uuid';

export const GET_FORM_STATUS = 'app/admin-module/business-process/configuration/task-view/GET_FORM_STATUS';
export const GET_FORM_STATUS_RETURN = 'app/admin-module/business-process/configuration/task-view/GET_FORM_STATUS_RETURN';
export const GET_FORM_FIELD = 'app/admin-module/business-process/configuration/task-view/GET_FORM_FIELD';
export const GET_FORM_FIELD_RETURN = 'app/admin-module/business-process/configuration/task-view/GET_FORM_FIELD_RETURN';
export const SAVE_TASK_VIEW = 'app/admin-module/business-process/configuration/task-view/SAVE_TASK_VIEW';
export const SAVE_TASK_VIEW_RETURN = 'app/admin-module/business-process/configuration/task-view/SAVE_TASK_VIEW_RETURN';
export const SAVE_TASK_VIEW_ERROR = 'app/admin-module/business-process/configuration/task-view/SAVE_TASK_VIEW_ERROR';
export const PUBLISH_TASK_VIEW = 'app/admin-module/business-process/configuration/task-view/PUBLISH_TASK_VIEW';
export const PUBLISH_TASK_VIEW_RETURN = 'app/admin-module/business-process/configuration/task-view/PUBLISH_TASK_VIEW_RETURN';
export const PUBLISH_TASK_VIEW_ERROR = 'app/admin-module/business-process/configuration/task-view/PUBLISH_TASK_VIEW_ERROR';
export const GET_TASK_VIEW = 'app/admin-module/business-process/configuration/task-view/GET_TASK_VIEW';
export const GET_TASK_VIEW_RETURN = 'app/admin-module/business-process/configuration/task-view/GET_TASK_VIEW_RETURN';
export const CLEAR_TASK_VIEW_MESSAGE = 'app/admin-module/business-process/configuration/task-view/CLEAR_TASK_VIEW_MESSAGE';

export const taskViewActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_TASK_VIEW_MESSAGE,
    type: 'success',
    reducerContainer: 'taskView',
    reducerItem: 'saveTaskViewSuccessMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_TASK_VIEW_MESSAGE,
    type: 'negative',
    reducerContainer: 'taskView',
    reducerItem: 'saveTaskViewErrorMessage',
    hasCloseIcon: true
  },

]

export const emptyFilterField: IApiCustomFilterField = {
  id: uuid(),
  isArrayInput: false,
  isRangeInput: false,
  keyPaths: [],
  name: 'Empty',
  placement: "-1",
  type: 'string'
}
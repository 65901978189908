import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IFieldLayoutObject, IFieldsLayout, IForm } from '../../form/interface/form';
import { IFormBuilderSection, TFormBuilderSectionRow } from '../interface/section';


interface IProps {
  formDetail: IForm;
  formBuilderField: ICustomFieldDetail[];
  formId: string;
}

export const useSetSectionList = (props: IProps) => {
  const { formDetail, formBuilderField, formId } = { ...props };

  const [sectionList, setSectionList] = useState([] as IFormBuilderSection[]);

  useEffect(() => {
    if (formId && formDetail.fieldsLayout && formBuilderField) {
      let fieldNotExistInSection = [...formBuilderField];

      const newSectionList = formDetail.fieldsLayout.map((fieldLayout: IFieldsLayout): IFormBuilderSection => {
        const sectionField = fieldLayout.body.map((body: IFieldLayoutObject): ICustomFieldDetail[] => {
          return body.fields
            .filter((fieldId: string) => {
              const field = formBuilderField.filter((customField: ICustomFieldDetail) => customField.id === fieldId);
              return field.length > 0;
            })
            .map((fieldId: string) => {
              fieldNotExistInSection = fieldNotExistInSection.filter((field: ICustomFieldDetail) => field.id !== fieldId);
              const field = formBuilderField.filter((customField: ICustomFieldDetail) => customField.id === fieldId);
              return field[0] || {};
            })
        })
        return {
          sectionId: fieldLayout.id,
          title: fieldLayout.title,
          titleHidden: fieldLayout.header.fields.length > 0,
          titleFieldToShow: fieldLayout.header.fields,
          collapsable: fieldLayout.collapsable,
          fields: sectionField,
          additionalInfo: fieldLayout.description,
          style: fieldLayout.style || 'grouped',
          defaultView: fieldLayout.defaultView,
          pinned: fieldLayout.pinned
        }
      });

      if (fieldNotExistInSection.length > 0) {
        let sectionFields: TFormBuilderSectionRow[] = [];
        let ctr = 0;
        let arrIndex = 0;
        fieldNotExistInSection.forEach((field: ICustomFieldDetail) => {
          ctr++;
          if (ctr === 1) {
            sectionFields[arrIndex] = [];
          }
          sectionFields[arrIndex].push(field);
          if (ctr === 4) {
            ctr = 0;
            arrIndex += 1;
          }
        })
        newSectionList.push({
          sectionId: uuid(),
          title: '',
          titleHidden: fieldNotExistInSection.length > 0,
          titleFieldToShow: [],
          collapsable: false,
          fields: sectionFields,
          additionalInfo: '',
          style: 'plain'
        })
      }

      setSectionList(newSectionList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDetail, formBuilderField, formId]);

  return { sectionList, setSectionList, ...props };
}
import React from 'react';
import Dropbox from './drop-box';
import ConfigurationModal from './configuration-modal';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';

interface ICustomFilterConfiguration {
  customFilterFieldList: { [position: string]: any };
  insertDropField(selectedItem: any, itemIndex: number, withItem: any): void;
  deleteFilter(index: number, type: string): void;
  selectedFieldFilter: number | null;
  cancel(): void;
  editConfiguration(index: number): void;
  saveConfiguration(selectedFieldFilter: number | null, newConfiguration: any): void;
  fieldList: ICustomFieldDetail[];
}

const CustomFilterConfiguration: React.FC<ICustomFilterConfiguration> = (props) => {

  return (<>
    <div className={`custom-filter-config`}>
      <div className={`dropbox-container`}>
        <Dropbox
          itemIndex={0}
          withItem={props.customFilterFieldList['0']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={1}
          withItem={props.customFilterFieldList['1']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={2}
          withItem={props.customFilterFieldList['2']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={3}
          withItem={props.customFilterFieldList['3']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
      </div>
      <div className={`dropbox-container`}>
        <Dropbox
          itemIndex={4}
          withItem={props.customFilterFieldList['4']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={5}
          withItem={props.customFilterFieldList['5']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={6}
          withItem={props.customFilterFieldList['6']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
        <Dropbox
          itemIndex={7}
          withItem={props.customFilterFieldList['7']}
          insertDropField={props.insertDropField}
          deleteFilter={props.deleteFilter}
          editConfiguration={props.editConfiguration}
        />
      </div>
    </div>
    <ConfigurationModal
      selectedFieldFilter={props.selectedFieldFilter}
      cancel={props.cancel}
      customFilterFieldList={props.customFilterFieldList}
      saveConfiguration={props.saveConfiguration}
      fieldList={props.fieldList}
    />
  </>);
}

export default CustomFilterConfiguration;

import { combineReducers } from 'redux';
import sample, { ITestComponentReducer } from './component/sample/reducer'
import auth, { IAuthReducer } from './component/auth/reducer'
import resetPassword, { IResetPassword } from './component/auth/reset-password/reducer'
import errorStatusHandler, { IErrorStatusHandlerReducer } from './component/error/reducer'
import main, { IMainReducer } from './component/main/reducer'
import user, { IUserReducer } from './component/admin-module/module/users/reducer'
import customField, { ICustomFieldReducer } from './common/custom-field-config/reducer'

import { AUTH_LOGOUT_RETURN } from './component/auth/constant';
import dataTable, { IDatatableReducer } from './component/admin-module/module/data-tables/reducer';
import businessProcess, { IBusinessProcessReducer } from './component/admin-module/module/business-process/module/form/reducer';
import formBuilder, { IFormBuilderReducer } from './component/admin-module/module/business-process/module/form-builder/reducer';
import workflow, { IFormWorkflowReducer } from './component/admin-module/module/business-process/module/workflow/reducer';
import groups, { IGroupReducer } from './component/admin-module/module/groups/reducer';
import apps, { IAppReducer } from './component/admin-module/module/apps/reducer';
import role, { IFormRoleReducer } from './component/admin-module/module/business-process/module/roles/reducer';
import permission, { IFormPermissionReducer } from './component/admin-module/module/business-process/module/permission/reducer';
import configuration, { IConfigurationReducer } from './component/admin-module/module/business-process/module/configuration/reducer';
import customFilter, { IConfigurationCustomFilterReducer } from './component/admin-module/module/business-process/module/configuration/module/custom-filter/reducer';
import slas, { ISlasReducer } from './component/admin-module/module/business-process/module/configuration/module/slas/reducer';
import documentList, { IConfigurationDocumentListReducer } from './component/admin-module/module/business-process/module/configuration/module/document-list/reducer';
import endUser, { IEndUserReducer } from './component/document-module/reducer';
import documentForm, { IDocumentFormReducer } from './component/document-module/module/form/reducer';
import duplicateChecker, { IDuplicateCheckerReducer } from './component/admin-module/module/business-process/module/configuration/module/duplicate-checker/reducer';
import reportReducer, { IReportReducer } from './component/document-module/module/report/reducer';
import lateralMenuReducer, { ILateralMenuReducer } from './component/document-module/side-menu/reducer';
import companiesReducer, { ICompaniesReducer } from './component/bpm-manager-module/module/companies/reducer';
import filterConfiguration, { IFilterConfigurationReducer } from './common/custom-field-config/filter-configuration/reducer';
import workspaceReducer, { IWorkspaceReducer } from './component/document-module/module/workspace/reducer';
import endUserDatatableReducer, { IEndUserDatatableReducer } from './component/document-module/module/datatable/reducer';
import calendarReducer, { ICalendarReducer } from './component/document-module/module/calendar/reducer';
import userAccountReducer, { IUserAccountReducer } from './component/document-module/module/user-account/reducer';
import tasksReducer, { ITasksReducer } from './component/document-module/module/task/reducer';
import configurationAPIs, { IConfigurationAPIReducer } from './component/admin-module/module/business-process/module/configuration/module/apis/reducer';
import notification, { INotificationReducer } from './component/document-module/module/notification/reducer';
import draft, { IDraftReducer } from './component/document-module/module/draft/reducer';
import bpRelationship, { IConfigurationBPRelationshipReducer } from './component/admin-module/module/business-process/module/configuration/module/bp-relationship/reducer';
import languageRegionReducer, { ILanguageRegionReducer } from './component/admin-module/module/settings/module/language-and-region/reducer';
import authSettings, { IAuthSettingsReducer } from './component/admin-module/module/settings/module/authentication/reducer';
import configurationLabels, { IConfigurationLabelsReducer } from './component/admin-module/module/business-process/module/configuration/module/labels/reducer';
import taskView, { ITaskViewReducer } from './component/admin-module/module/business-process/module/configuration/module/task-view/reducer';
import configurationPDFTemplate, { IConfigurationPDFTemplateReducer } from './component/admin-module/module/business-process/module/configuration/module/pdf-template/reducer';
import automation, { IAutomationReducer } from './component/admin-module/module/business-process/module/automation/module/automation-rules/reducer';
import automationLogs, { IAutomationLogsReducer } from './component/admin-module/module/business-process/module/automation/module/automation-logs/reducer';
import adminHistoryLogs, { IAdminHistoryReducer } from './component/admin-module/history/reducer';
import portalListView, { IPortalListViewReducer } from './component/portal-module/module/business-process/module/list-view/reducer';
import portalFormView, { IPortalFormViewReducer } from './component/portal-module/module/business-process/module/form-view/reducer';
import companySettings, { ICompanySettingsReducer } from './component/admin-module/module/settings/reducer';
import userSettings, { IUserSettingsReducer } from './component/document-module/module/setting/reducer';
import configurationDisplayData, { IConfigurationDisplayDataReducer } from './component/admin-module/module/business-process/module/configuration/module/display-data/reducer';

export interface IRootState {
  sample: ITestComponentReducer;
  auth: IAuthReducer;
  resetPassword: IResetPassword;
  errorStatusHandler: IErrorStatusHandlerReducer;
  main: IMainReducer;
  user: IUserReducer;
  customField: ICustomFieldReducer;
  dataTable: IDatatableReducer;
  businessProcess: IBusinessProcessReducer;
  formBuilder: IFormBuilderReducer;
  workflow: IFormWorkflowReducer;
  groups: IGroupReducer;
  apps: IAppReducer;
  role: IFormRoleReducer;
  permission: IFormPermissionReducer;
  configuration: IConfigurationReducer;
  customFilter: IConfigurationCustomFilterReducer
  slas: ISlasReducer;
  documentList: IConfigurationDocumentListReducer;
  endUser: IEndUserReducer;
  documentForm: IDocumentFormReducer;
  duplicateChecker: IDuplicateCheckerReducer;
  reportReducer: IReportReducer;
  lateralMenuReducer: ILateralMenuReducer;
  companiesReducer: ICompaniesReducer;
  filterConfiguration: IFilterConfigurationReducer;
  workspaceReducer: IWorkspaceReducer;
  endUserDatatableReducer: IEndUserDatatableReducer;
  calendarReducer: ICalendarReducer;
  userAccountReducer: IUserAccountReducer;
  tasksReducer: ITasksReducer;
  configurationAPIs: IConfigurationAPIReducer;
  notification: INotificationReducer;
  automation: IAutomationReducer;
  draft: IDraftReducer;
  bpRelationship: IConfigurationBPRelationshipReducer;
  languageRegionReducer: ILanguageRegionReducer
  authSettings: IAuthSettingsReducer;
  configurationLabels: IConfigurationLabelsReducer;
  taskView: ITaskViewReducer;
  configurationPDFTemplate: IConfigurationPDFTemplateReducer;
  automationLogs: IAutomationLogsReducer;
  adminHistoryLogs: IAdminHistoryReducer;
  portalListView: IPortalListViewReducer;
  portalFormView: IPortalFormViewReducer;
  companySettings: ICompanySettingsReducer;
  userSettings: IUserSettingsReducer;
  configurationDisplayData: IConfigurationDisplayDataReducer
}

const appReducer = combineReducers({
  sample,
  auth,
  resetPassword,
  errorStatusHandler,
  main,
  user,
  customField,
  dataTable,
  businessProcess,
  formBuilder,
  workflow,
  groups,
  apps,
  role,
  permission,
  configuration,
  customFilter,
  slas,
  documentList,
  endUser,
  documentForm,
  duplicateChecker,
  reportReducer,
  lateralMenuReducer,
  companiesReducer,
  filterConfiguration,
  workspaceReducer,
  endUserDatatableReducer,
  calendarReducer,
  userAccountReducer,
  tasksReducer,
  configurationAPIs,
  notification,
  automation,
  draft,
  bpRelationship,
  languageRegionReducer,
  authSettings,
  configurationLabels,
  taskView,
  configurationPDFTemplate,
  automationLogs,
  adminHistoryLogs,
  portalListView,
  portalFormView,
  companySettings,
  userSettings,
  configurationDisplayData
});

/**
 * This will reset redux state to initial once user logs out
 */
const rootReducer = (state: any, action: any) => {
  if (action.type === AUTH_LOGOUT_RETURN && action.success) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
import { IAppliedLabels, IFormLabels } from '../interface/label';
import Axios from 'axios';
import { SanitizeLabels } from '../../../../../../../../../common/utils/sanitize-labels';

interface IUtils {
  company: string;
  formId: string;
}

export class FormLabelConfig {

  static saveAppliedLabels = async (appliedLabelsData: IAppliedLabels, { company, formId }: IUtils) => {
    const saveType = appliedLabelsData.new ? 'create' : 'update';
    const data = SanitizeLabels.structureOutgoingAppliedLabel(appliedLabelsData, saveType);

    if (company && formId) {
      try {
        if (data.new) {
          await Axios.post(`${company}/forms/${formId}/appliedLabels`, data);
        }
        if (data.deleted) {
          await Axios.delete(`${company}/forms/${formId}/appliedLabels/${data.id}`, {});
        }
        if (!data.new && data.edited) {
          await Axios.put(`${company}/forms/${formId}/appliedLabels/${data.id}`, data);
        }
        return
      } catch (_e) {
        return _e;
      }
    }
  }

  static saveCreatedLabels = async (formLabelsData: IFormLabels, { company, formId }: IUtils) => {
    const saveType = formLabelsData.new ? 'create' : 'update';
    const data = SanitizeLabels.structureOutgoing(formLabelsData, saveType);

    if (company && formId) {
      try {
        if (data.new) {
          await Axios.post(`${company}/forms/${formId}/labels`, data);
        }
        if (data.deleted) {
          await Axios.delete(`${company}/forms/${formId}/labels/${data.id}`, {});
        }
        if (!data.new && data.edited) {
          await Axios.put(`${company}/forms/${formId}/labels/${data.id}`, data);
        }
        return
      } catch (_e) {
        return _e;
      }
    }
  }
}
import React, { useState, useEffect } from 'react';
import { IFieldPermissionSettings } from '../..';
import { Dropdown, Image, Button } from 'semantic-ui-react-bpm';
import { IOption } from '../../../../../../../../../common/interface/option';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';
import { IRootState } from '../../../../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { getFromRefFieldListAction } from '../../../../../../../../../common/custom-field-config/filter-configuration/action';
import { ConsolidateFunction, IConsolidateTableColumn, IConsolidateTableConfig } from '../../../interface/field-permission';
import { icons } from '../../../../../../../../../common/icons';
import { TConsolidateTableColumn } from '.';
import { saveFormPermissionErrorAction } from '../../../action';

interface IAggregateConfigProps extends IFieldPermissionSettings {
  consolidateTableRef: React.MutableRefObject<IConsolidateTableConfig | undefined>
}

const AggregateConfig: React.FC<IAggregateConfigProps> = (props) => {
  const dispatch = useDispatch();

  const { company } = useSelector((state: IRootState) => state.auth);
  const { refSourceFieldList } = useSelector((state: IRootState) => state.filterConfiguration);

  const [refresher, setRefresher] = useState(0);
  const [selectedConf, setSelectedConf] = useState<'table' | 'fields'>('table');

  const fieldRefSelected = props.consolidateTableRef.current ? props.consolidateTableRef.current.referenceKeyPath : '';
  const fieldSelected = props.consolidateTableRef.current ? props.consolidateTableRef.current.sourceTableId : '';
  const consolidateColumns = props.consolidateTableRef.current ? props.consolidateTableRef.current.columns : [];

  const updateAggregate = (key: TConsolidateTableColumn, value: any, idx: number) => {
    const tempConsolidateCol = [...consolidateColumns];
    tempConsolidateCol[idx][key] = value;
    if (key === 'action') {
      tempConsolidateCol[idx]['fromColumnOrFieldId'] = '';
      tempConsolidateCol[idx]['toColumnId'] = '';
    }
    if (key === 'fromColumnOrFieldId') {
      tempConsolidateCol[idx]['toColumnId'] = '';
    }
    if (props.consolidateTableRef.current) {
      props.consolidateTableRef.current.columns = tempConsolidateCol;
    }
    setRefresher(refresher + 1);
  }

  const deleteAggregate = (idx: number) => {
    let tempConsolidateCol = [...consolidateColumns];
    tempConsolidateCol.splice(idx, 1);
    if (props.consolidateTableRef.current) {
      props.consolidateTableRef.current.columns = tempConsolidateCol;
    }
    setRefresher(refresher + 1);
  }

  const addNewAggregate = () => {
    const tempConsolidateCol = [...consolidateColumns];
    tempConsolidateCol.push({} as IConsolidateTableColumn);
    if (props.consolidateTableRef.current) {
      props.consolidateTableRef.current.columns = tempConsolidateCol;
    }
    setRefresher(refresher + 1);
  }

  const getReferenceFieldOption = () => {
    let options: IOption[] = [];
    props.fieldList.forEach(field => {
      if (field.configName === IConfigName.Referenced && field.id !== props.fieldData.id &&
        field.config && field.config.multiselect) {
        options.push({
          key: `${field.id}`,
          value: `${field.id}`,
          text: field.label || '',
          type: 'referenced'
        });
      }
    });
    return options;
  }

  const getSelectedReferencedField = (fieldId: string, type?: string): ICustomFieldDetail | undefined => {
    let tempFieldList = [...props.fieldList];
    let selectedField = tempFieldList.find((field: ICustomFieldDetail) => field.id === fieldId);
    if (type === 'tableReferenced') {
      const [tableId, columnId] = fieldId.split('.');
      const table = props.fieldList.find((field: ICustomFieldDetail) => field.id === tableId);
      if (table) {
        const { columns } = { ...table.config };
        if (columns) {
          selectedField = columns.find((field: ICustomFieldDetail) => field.id === columnId);
        }
      }
    }
    return selectedField;
  }

  const getFieldFromSourceRef = () => {
    let fieldList = [] as ICustomFieldDetail[];
    const refSelected = getReferenceFieldOption().find(e => e.value === fieldRefSelected);
    if (refSelected) {
      let selectedField = getSelectedReferencedField(fieldRefSelected, refSelected.type);
      if (selectedField) {
        const { config } = { ...selectedField };
        if (config && config.data && selectedField.configName === IConfigName.Referenced) {
          if (config.dataType === 'document') {
            const sourceId = config.data.formId;
            if (refSourceFieldList[sourceId]) {
              fieldList = refSourceFieldList[sourceId];
            }
          }
        }
      }
    }
    return fieldList;
  }

  const isConfigValid = () => {
    const data = { ...props.consolidateTableRef.current } as IConsolidateTableConfig;
    if (data) {
      const error = data.columns && data.columns.map((columns: IConsolidateTableColumn) => {
        return columns.action === undefined || columns.fromColumnOrFieldId === '' || columns.toColumnId === '';
      });
      return data.referenceKeyPath !== '' && data.columns.length > 0 && error && error.every(e => e === false);
    }
    return true;
  }

  const handleConfigChange = () => {
    if (!isConfigValid()) {
      dispatch(saveFormPermissionErrorAction('Configuration is incomplete. Referenced Field (BP), Action, Input and Output Fields cannot be empty.'));
      return;
    }
    const data: IConsolidateTableConfig = {
      referenceKeyPath: fieldRefSelected,
      sourceTableId: fieldSelected ? fieldSelected : undefined,
      columns: consolidateColumns
    };
    props.updatePermissionConfig(null, 'aggregate', props.type, data);
    props.cancel();
  }

  const deleteAggConfig = () => {
    props.updatePermissionConfig(null, 'aggregate', props.type, null);
    props.cancel();
  }

  const getInputFieldOption = (column: IConsolidateTableColumn, idx: number) => {
    const selectedFromFields = consolidateColumns.filter((e, i) => i !== idx).map(e => e.fromColumnOrFieldId);
    const fieldList = getFieldFromSourceRef();
    const options: IOption[] = [];
    if (!column.action) {
      return [];
    }
    if (column.action !== ConsolidateFunction.Group) {
      let fields = [...fieldList];
      const selectedTable = fieldList.find(e => e.id === fieldSelected);
      if (selectedTable) {
        const { columns } = { ...selectedTable.config };
        if (columns) {
          fields = columns.filter(e => !selectedFromFields.includes(e.id as string));
        }
      }
      fields
        .filter(e => !selectedFromFields.includes(e.id as string))
        .forEach(field => {
          const validType = field.type === 'number' || field.type === 'fx' ||
            (field.config && (field.config.labelType === 'number' || field.config.labelType === 'fx'));
          if (validType) {
            options.push({
              key: field.id || '',
              text: field.label || '',
              value: field.id || '',
            });
          }
        });
    } else {
      let referencedFields = fieldList.filter(e => e.configName === IConfigName.Referenced && e.config && !e.config.multiselect);
      const selectedTable = fieldList.find(e => e.id === fieldSelected);
      if (selectedTable) {
        const { columns } = { ...selectedTable.config };
        if (columns) {
          referencedFields = columns.filter(e => e.configName === IConfigName.Referenced && e.config && !e.config.multiselect);
        }
      }
      if (referencedFields.length > 0) {
        referencedFields
          .filter(e => !selectedFromFields.includes(e.id as string))
          .forEach(field => {
            options.push({
              key: field.id || '',
              text: field.label || '',
              value: field.id || '',
            });
          })
      }
    }

    return options;
  }

  const getOutputFieldOption = (column: IConsolidateTableColumn, idx: number) => {
    if (!column.fromColumnOrFieldId) {
      return [];
    }
    const selectedToFields = consolidateColumns.filter((e, i) => i !== idx).map(e => e.toColumnId);
    const refFieldList = getFieldFromSourceRef();
    const options: IOption[] = [];
    const { columns } = { ...props.fieldData.config };
    if (columns) {
      const fieldList = columns;
      const colList = fieldList.filter(e => !selectedToFields.includes(e.id as string));
      colList.forEach(col => {
        if (column.action === ConsolidateFunction.Group) {
          let refFieldFrom = refFieldList.find(e => e.id === column.fromColumnOrFieldId);
          const selectedTable = refFieldList.find(e => e.id === fieldSelected);
          if (selectedTable) {
            const { columns } = { ...selectedTable.config };
            if (columns) {
              refFieldFrom = columns.find(e => e.id === column.fromColumnOrFieldId);
            }
          }
          if (refFieldFrom) {
            const { data } = { ...refFieldFrom.config };
            if (data && col.configName === IConfigName.Referenced && col.config
              && JSON.stringify(col.config.data) === JSON.stringify(data) && !col.config.multiselect) {
              const exists = options.find(e => e.value === col.id);
              if (!exists) {
                options.push({
                  key: col.id || '',
                  text: col.label || '',
                  value: col.id || '',
                });
              }
            }
          }
        } else {
          if (col.type === 'number') {
            const exists = options.find(e => e.value === col.id);
            if (!exists) {
              options.push({
                key: col.id || '',
                text: col.label || '',
                value: col.id || '',
              });
            }
          }
        }
      });
    }
    return options;
  }

  const getFormFieldOption = (sourceTable: 'currentBP' | 'referencedBP', display?: 'tableNumberFields', valueToFilter?: any): IOption[] => {
    const options: IOption[] = [];
    const fieldList = getFieldFromSourceRef();
    fieldList.forEach(field => {
      if (field.type === 'table') {
        options.push({
          key: field.id || '',
          text: field.label || '',
          value: field.id || '',
        });
      }
    });
    return options;
  }

  const getRefSourceField = (fieldId: string, type?: string) => {
    let selectedField = getSelectedReferencedField(fieldId, type);
    if (selectedField && selectedField.configName === IConfigName.Referenced) {
      const { config } = { ...selectedField };
      if (config && config.data) {
        let noSourceFields = config.dataType === 'database' ? (!refSourceFieldList[config.data.databaseId])
          : (!refSourceFieldList[config.data.formId]);
        if (noSourceFields) {
          dispatch(getFromRefFieldListAction({
            company: company,
            dataType: config.dataType,
            databaseId: config.data.databaseId,
            formId: config.data.formId
          }));
        }
      }
    }
  }

  const getAggregateActionOption = (): IOption[] => {
    let ctr = 0;
    const options: IOption[] = [];
    (Object.keys(ConsolidateFunction) as Array<keyof typeof ConsolidateFunction>).forEach(func => {
      let text: string = func;
      if (func === 'Group') {
        text = 'Group By';
      } else {
        text = `Aggregate - ${func}`;
      }
      options.push({
        key: ctr++,
        text: text,
        value: ConsolidateFunction[func]
      })
    });
    return options;
  }

  const handleOnSelectConf = (conf: 'table' | 'fields') => {
    setSelectedConf(conf);
    if (props.consolidateTableRef.current) {
      props.consolidateTableRef.current.referenceKeyPath = '';
      props.consolidateTableRef.current.columns = [];
      props.consolidateTableRef.current.sourceTableId = undefined;
    }
    setRefresher(refresher + 1);
  }

  const showDeleteBtn = () => {
    return props.selectedStatus && props.selectedStatus.table && props.selectedStatus.table.length > 0;
  }

  useEffect(() => {
    if (props.consolidateTableRef.current) {
      const { sourceTableId, referenceKeyPath } = { ...props.consolidateTableRef.current };
      getRefSourceField(referenceKeyPath);
      if (referenceKeyPath) {
        setSelectedConf(sourceTableId ? 'table' : 'fields');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.consolidateTableRef.current]);

  return <div className='permission-settings-main aggregate-config'>
    <div className={`bool-selection`}>
      <span className={selectedConf === 'table' ? 'selected' : ''} onClick={() => handleOnSelectConf('table')}>Embedded Table</span>
      <span className={selectedConf === 'fields' ? 'selected' : ''} onClick={() => handleOnSelectConf('fields')}>Fields</span>
    </div>
    <div className='aggregate-source-select'>
      <Dropdown
        fluid
        selection
        search
        clearable
        selectOnBlur={false}
        placeholder='Select Referenced Field (BP)'
        options={getReferenceFieldOption()}
        value={fieldRefSelected}
        onChange={(event, target: any) => {
          if (target.value) {
            const { type } = target.options.find((e: IOption) => e.value === target.value);
            if (type) {
              getRefSourceField(target.value, type);
            }
          }
          if (props.consolidateTableRef.current) {
            props.consolidateTableRef.current.referenceKeyPath = target.value;
            props.consolidateTableRef.current.columns = [];
            props.consolidateTableRef.current.sourceTableId = undefined;
          }
          setRefresher(refresher + 1);
        }}
      />
      {selectedConf === 'table' &&
        <Dropdown
          fluid
          selection
          search
          clearable
          selectOnBlur={false}
          placeholder='Select ET from source BP'
          options={getFormFieldOption('referencedBP')}
          value={fieldSelected}
          onChange={(event, target: any) => {
            if (props.consolidateTableRef.current) {
              props.consolidateTableRef.current.sourceTableId = target.value;
              props.consolidateTableRef.current.columns = [];
            }
            setRefresher(refresher + 1);
          }}
          className='referenced-dropdown'
        />
      }
    </div>
    <div className='permission-settings-container'>
      <div className={`aggregate-action-list header ${!fieldRefSelected ? 'hidden' : ''}`}>
        <div className='aggregate-action-header'>Action</div>
        <div className='aggregate-action-header'>
          Input {selectedConf === 'fields' ? 'Fields' : 'Embedded Table'}
        </div>
        <div className='aggregate-action-header'>
          Output Embedded Table
        </div>
        <div className='aggregate-action-header'></div>
      </div>
      <div className={`permission-settings aggregate ${!fieldRefSelected ? 'hidden' : ''}`}>
        {consolidateColumns.map((column, idx) => {
          return <div className='aggregate-action-list' key={idx}>
            <div className='aggregate-action-item'>
              <Dropdown
                upward={false}
                fluid
                selection
                search
                clearable
                selectOnBlur={false}
                placeholder='Aggregate action'
                options={getAggregateActionOption()}
                value={column.action}
                onChange={(event, target: any) => {
                  updateAggregate('action', target.value, idx);
                }}
                className='referenced-dropdown'
              />
            </div>
            <div className='aggregate-action-item'>
              <Dropdown
                upward={false}
                fluid
                selection
                search
                clearable
                selectOnBlur={false}
                value={column.fromColumnOrFieldId}
                placeholder='Select fields'
                options={getInputFieldOption(column, idx)}
                onChange={(event, target: any) => {
                  updateAggregate('fromColumnOrFieldId', target.value, idx);
                }}
                className='referenced-dropdown'
              />
            </div>
            <div className='aggregate-action-item'>
              <Dropdown
                upward={false}
                fluid
                selection
                search
                clearable
                selectOnBlur={false}
                placeholder='Select fields'
                value={column.toColumnId}
                options={getOutputFieldOption(column, idx)}
                onChange={(event, target: any) => {
                  updateAggregate('toColumnId', target.value, idx);
                }}
                className='referenced-dropdown'
              />
            </div>
            <div className='aggregate-del'>
              <Image className='icon-config' src={icons.blue.delete} onClick={() => deleteAggregate(idx)} />
            </div>
          </div>
        })}
        <div className='add-new-aggregate'>
          <i className={`plus circular icon new-aggregate`} onClick={addNewAggregate} />
        </div>
      </div>
      <div className="button-container">
        <Button className='btn-success' onClick={handleConfigChange}>Save</Button>
        {showDeleteBtn() && <Button color='red' className='btn-action' onClick={deleteAggConfig}>Delete</Button>}
        <Button className='btn-default' onClick={() => {
          if (props.consolidateTableRef) {
            props.consolidateTableRef.current = undefined;
          }
          props.cancel();
        }}>Cancel</Button>
      </div>
    </div>
  </div>
}

export default AggregateConfig
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props';

export const GET_GROUP_LIST = 'app/group/GET_GROUP_LIST';
export const GET_GROUP_LIST_RETURN = 'app/group/GET_GROUP_LIST_RETURN';
export const GET_GROUP_LIST_ERROR = 'app/group/GET_GROUP_LIST_ERROR';
export const CREATE_GROUP = 'app/group/CREATE_GROUP';
export const CREATE_GROUP_RETURN = 'app/group/CREATE_GROUP_RETURN';
export const CREATE_GROUP_ERROR = 'app/group/CREATE_GROUP_ERROR';
export const GET_USER_OPTION_LIST = 'app/group/GET_USER_OPTION_LIST';
export const GET_USER_OPTION_LIST_RETURN = 'app/group/GET_USER_OPTION_LIST_RETURN';
export const GET_USER_OPTION_LIST_ERROR = 'app/group/GET_USER_OPTION_LIST_ERROR';
export const CLEAR_GROUP_MESSAGE = 'app/group/CLEAR_GROUP_MESSAGE';
export const DELETE_GROUP = 'app/group/DELETE_GROUP';
export const DELETE_GROUP_CANCEL = 'app/group/DELETE_GROUP_CANCEL';
export const DELETE_GROUP_CONFIRM = 'app/group/DELETE_GROUP_CONFIRM';
export const DELETE_GROUP_CONFIRM_RETURN = 'app/group/DELETE_GROUP_CONFIRM_RETURN';
export const DELETE_GROUP_CONFIRM_ERROR = 'app/group/DELETE_GROUP_CONFIRM_ERROR';
export const GET_GROUP_DETAIL = 'app/group/GET_GROUP_DETAIL';
export const GET_GROUP_DETAIL_RETURN = 'app/group/GET_GROUP_DETAIL_RETURN';
export const GET_GROUP_DETAIL_ERROR = 'app/group/GET_GROUP_DETAIL_ERROR';
export const UPDATE_GROUP = 'app/group/UPDATE_GROUP';
export const UPDATE_GROUP_RETURN = 'app/group/UPDATE_GROUP_RETURN';
export const UPDATE_GROUP_ERROR = 'app/group/UPDATE_GROUP_ERROR';
export const CLEAR_CREATE_GROUP_SUCCESS = 'app/group/CLEAR_CREATE_GROUP_SUCCESS';
export const CLEAR_CREATE_GROUP_ERROR = 'app/group/CLEAR_CREATE_GROUP_ERROR';
export const CLEAR_UPDATE_GROUP_SUCCESS = 'app/group/CLEAR_UPDATE_GROUP_SUCCESS';
export const CLEAR_UPDATE_GROUP_ERROR = 'app/group/CLEAR_UPDATE_GROUP_ERROR';
export const CLEAR_DELETE_GROUP_SUCCESS = 'app/group/CLEAR_DELETE_GROUP_SUCCESS';
export const FILTER_GROUPS = 'app/group/FILTER_GROUPS';
export const FILTER_GROUPS_RETURN = 'app/group/FILTER_GROUPS_RETURN';
export const FILTER_GROUPS_ERROR = 'app/group/FILTER_GROUPS_ERROR';
export const groupActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_GROUP_SUCCESS,
    type: 'success',
    reducerContainer: 'groups',
    reducerItem: 'createGroupMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_GROUP_SUCCESS,
    type: 'success',
    reducerContainer: 'groups',
    reducerItem: 'updateGroupMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_GROUP_SUCCESS,
    type: 'success',
    reducerContainer: 'groups',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true,
  },
]

export const groupActionMessageAddNew: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_GROUP_SUCCESS,
    type: 'success',
    reducerContainer: 'groups',
    reducerItem: 'createGroupMessage',
    hasCloseIcon: true,
  },
]

export const formGroupActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_GROUP_ERROR,
    type: 'negative',
    reducerContainer: 'groups',
    reducerItem: 'createGroupMessageError',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_GROUP_ERROR,
    type: 'negative',
    reducerContainer: 'groups',
    reducerItem: 'updateGroupMessageError',
    hasCloseIcon: true,
  },
]
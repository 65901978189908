import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import { v4 as uuid } from 'uuid';
import { FileProps } from '../../service/type/file';
import { TValidationType } from '../../service/field-properties.handler';
import { useDropzone } from 'react-dropzone';
import { UploadedFile } from '../../../../api-request/upload';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../reducers';
import SVGWrapper from '../../../../icons/svg/svg-wrapper';
import { FileCamera } from '../../../../general/file-camera';
import FileUploadProgress from './utils/progress';
import { Popup } from 'semantic-ui-react-bpm';
import { IField, IFieldAccessType } from '../../../../../component/admin-module/module/users/interface/field';
import { ArrayFieldProperties } from '../../service/array-field-properties.service';

const MultipleFile: React.FC<AcceptedProps> = (props) => {

  const { company } = useSelector((state: IRootState) => state.auth);

  const name = props.getFieldId();
  const limit = props.inputConfig && props.inputConfig.maxItems ? props.inputConfig.maxItems : 1;
  const elementProperties = new ArrayFieldProperties(name, props.forwardedRef, new FileProps());

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const validateIfRequired = () => {
    elementProperties.validate({ ...props }, TValidationType.onBlur);
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    if (files && (acceptedFiles.length + files.length) > limit) {
      return;
    }
    const tempFiles = [...files, ...acceptedFiles];
    setFiles([...files, ...acceptedFiles]);
    tempFiles.forEach((f, i) => {
      elementProperties.setCurrent({ value: f || null }, i);
    })
    if (acceptedFiles.length) {
      setPopupOpen(true);
    }
    // eslint-disable-next-line
  }, [limit, files])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: true,
    disabled: files.length >= limit,
    maxFiles: limit,
  });

  const setFileUploaded = (uploaded: UploadedFile, index: number, initial?: boolean) => {
    elementProperties.setCurrent({ value: uploaded || null }, index);
    let tempFiles = [...files];
    tempFiles[index] = uploaded;
    setFiles(tempFiles);
    if (props.throwValueOutside && !initial) {
      props.throwValueOutside()
    }
    manageAutomationTrigger(uploaded);
  }

  const dataURLtoFile = (dataurl: any, filename: string): any => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleTakePhoto = async (photoUri: string) => {
    var file = dataURLtoFile(photoUri, `${uuid()}.png`);
    setIsCameraOpen(false);
    setFiles([...files, ...[file]]);
    if (file) {
      setPopupOpen(true);
    }
  }

  const manageAutomationTrigger = (value: any) => {
    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();

    // this part will work only on standalone field meaning it is not under table or array field
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, value && value.length > 0 ? value[0] : undefined)
      }
    }
  }

  const isSystemGenerated = (): boolean => {
    return props.inputConfig && props.inputConfig.config &&
      props.inputConfig.config.systemGenerated === true;
  }

  const removeSelectedFile = useCallback((index: number) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    const uploadedFiles = elementProperties.current.filter((_value: any, e: number) => { return e !== index });
    elementProperties.clearElement();
    uploadedFiles.forEach((file, index: number) => {
      elementProperties.setCurrent({ ...file }, index);
    });
    if (tempFiles.length === 0) {
      setPopupOpen(false);
    }
    setFiles(tempFiles);

    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }
    manageAutomationTrigger(null)
    // eslint-disable-next-line
  }, [files]);

  const openFile = useCallback((index: number) => {
    props.openDocumentAttachment(name, index, isSystemGenerated())
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (elementProperties.current !== null) {
      const currentFiles = elementProperties.current.map(e => e.value);
      setFiles(currentFiles as UploadedFile[]);
      if (props.accessType === IFieldAccessType.Required) {
        validateIfRequired()
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.accessType === IFieldAccessType.Required) {
      validateIfRequired();
    }
    // eslint-disable-next-line
  }, [files])

  return <div className='file-upload-wrap' style={{ textAlign: 'right' }}>
    <section className={`e-file-select-wrap dropzone-area`}>
      <div {...getRootProps({ className: `dropzone-wrap dropzone-container ${files.length >= limit ? 'disabled' : ''}` })}>
        <input id='file-multi-upload' {...getInputProps({ onClick: open, disabled: files.length >= limit })} />
        <label htmlFor='file-upload'>
          <span className={`e-css e-btn btn-upload`} tabIndex={0} title="Browse...">
            Browse...
          </span>
        </label>
        <span className="e-file-drop">Or drop files here</span>
        <div className='e-camera-btn' onClick={() => setIsCameraOpen(true)}
          title='capture image'>
          <SVGWrapper
            iconName='camera'
            color='#0000008a'
            width={28}
            height={28}
          />
        </div>
      </div>
      <Popup
        id='e-upload-file-list-wrap'
        on='click'
        position='bottom center'
        basic
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        className={!files.length ? 'empty' : ''}
        trigger={
          <div className="e-badge e-badge-info file-cnt" title='Click to show files'
            onClick={() => setPopupOpen(!popupOpen)}>
            {`Files: ${files.length}/${limit}`}
          </div>
        }
        eventsEnabled
        hideOnScroll
      >
        {files.length > 0 ?
          <Fragment>
            {files.map((file, index) => {
              return <FileUploadProgress
                key={index}
                data={file}
                removeFile={removeSelectedFile}
                setUploadedFile={setFileUploaded}
                company={company}
                index={index}
                openFile={openFile}
              />
            })}
          </Fragment>
          :
          <div id='e-upload-file-list' className='empty'>
            No Files Uploaded
          </div>
        }
      </Popup>
    </section>
    <FileCamera
      open={isCameraOpen}
      handleTakePhoto={handleTakePhoto}
      close={() => setIsCameraOpen(false)}
    />
  </div>
}

export default MultipleFile;
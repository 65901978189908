import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { AlertModal } from '../../../../../../../../../common/general/alert-modal';
import Dropbox from '../../custom-filter/configuration/drop-box';
import { IDragItem } from '../../custom-filter/custom-filter';
import { ICustomFilterContainer } from '../../custom-filter/interface/custom-filter-field';
import { setGanttChartFilterOptions } from '../utils/set-gantt-chart-filter-option';
import { customFilterSingleField, customFilterRangeField } from '../../custom-filter/constant';

interface IGanttChartFilter {
  fieldList: ICustomFieldDetail[];
}

const defaultFilterList: ICustomFilterContainer = {
  '0': undefined,
  '1': undefined,
  '2': undefined,
  '3': undefined
};

const GanttChartFilter: React.FC<IGanttChartFilter> = (props) => {

  const [customFilterFieldList, setCustomFilterFieldList] = useState(defaultFilterList);
  const [rangeFieldErrorMessage, setRangeFieldErrorMessage] = useState('');

  const isAllowedFieldInsert = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropAreaItem: any, dropIndex: number): boolean => {
    let isAllowedFieldInsert = true;
    if (dragItem.new && dropAreaItem !== undefined) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't insert field if there is already existing field.`);
    }

    if (dragItem.itemType === 'field-range') {
      if ((dropIndex === 3 || dropIndex === 7)) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
      if (newCustomFilterFieldList[(dropIndex + 1).toString()] !== undefined && dragItem.new) {
        isAllowedFieldInsert = false;
        setRangeFieldErrorMessage(`Can't insert range field, it requires empty slot next to it.`);
      }
    }

    const isMoveSingleItemDoesNotFullfillRangeSlot = newCustomFilterFieldList[dropIndex.toString()]
      && newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range'
      && dragItem.itemType !== 'field-range'
      && dragItem.itemIndex !== undefined
      && (newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] || dragItem.itemIndex === 7 || dragItem.itemIndex === 3)
      && !dragItem.new;

    if (isMoveSingleItemDoesNotFullfillRangeSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }
    const isMoveRangeDoesNotFullfillDropAreaSlot = (!newCustomFilterFieldList[dropIndex.toString()]
      || (newCustomFilterFieldList[dropIndex.toString()]
        && newCustomFilterFieldList[dropIndex.toString()].itemType !== 'field-range'))
      && dragItem.itemType === 'field-range'
      && newCustomFilterFieldList[(dropIndex + 1).toString()]
      && dragItem.itemIndex !== (dropIndex + 1)
      && !dragItem.new;

    if (isMoveRangeDoesNotFullfillDropAreaSlot) {
      isAllowedFieldInsert = false;
      setRangeFieldErrorMessage(`Can't move range field, it requires empty slot next to it.`);
    }

    return isAllowedFieldInsert;
  }

  const cleanDragItemSlot = (dragItem: IDragItem, newCustomFilterFieldList: ICustomFilterContainer, dropIndex: number): { [x: string]: any } => {
    if (!dragItem.new && dragItem.itemIndex !== undefined) { // move drop area ite to drag item position
      if (newCustomFilterFieldList[dropIndex.toString()]) { // with exiting field in drop area
        if (newCustomFilterFieldList[dropIndex.toString()].itemType === 'field-range') { // for drop area field-range
          if (dragItem.itemType === 'field-range') { // for drag item field-range and drop area with field-range
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          } else {
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
            newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = 'contained';
            newCustomFilterFieldList[(dropIndex + 1).toString()] = undefined;
          }
        } else {
          if (dragItem.itemType === 'field-range') { // for drag item field-range but drop area with single field
            if (dragItem.itemIndex === (dropIndex + 1)) { // drag field range and position is of field is on left side
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = newCustomFilterFieldList[dropIndex.toString()];
            } else {
              newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
              newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
            }
          } else { // drag item field range and no item on drop area
            newCustomFilterFieldList[dragItem.itemIndex.toString()] = newCustomFilterFieldList[dropIndex.toString()];
          }
        }
      } else {
        if (dragItem.itemType === 'field-range') { // drag item field range and no item on drop area
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
          newCustomFilterFieldList[(dragItem.itemIndex + 1).toString()] = undefined;
        } else {
          newCustomFilterFieldList[dragItem.itemIndex.toString()] = undefined;
        }
      }
    }

    return newCustomFilterFieldList;
  }

  const insertDropField = (dragItem: IDragItem, dropIndex: number, dropAreaItem: any) => {
    let newCustomFilterFieldList = { ...customFilterFieldList };
    if (isAllowedFieldInsert(dragItem, newCustomFilterFieldList, dropAreaItem, dropIndex)) {
      newCustomFilterFieldList = cleanDragItemSlot(dragItem, newCustomFilterFieldList, dropIndex);

      if (dragItem.itemType === 'field-range') {
        newCustomFilterFieldList[(dropIndex + 1).toString()] = 'contained';
      }

      newCustomFilterFieldList[dropIndex.toString()] = dragItem.item;
      setCustomFilterFieldList(newCustomFilterFieldList)
    }
  }

  const deleteFilter = (index: number, itemType: string) => {
    const newCustomFilterFieldList = { ...customFilterFieldList };
    if (itemType === 'field-range') {
      newCustomFilterFieldList[(index + 1).toString()] = undefined;
    }
    newCustomFilterFieldList[index.toString()] = undefined;
    setCustomFilterFieldList(newCustomFilterFieldList);
  }

  const onFilterSelect = (value: string) => {
    const filterList = [...customFilterSingleField, ...customFilterRangeField];
    const selected = filterList.find(e => e.type === value);
    const emptyIndexes = Object.keys(customFilterFieldList)
      .filter(key => customFilterFieldList[key] === undefined)
      .map(key => key)
    if (selected) {
      const dragItem: any = {
        item: selected,
        itemType: selected.itemType,
        new: true,
        type: "customFilterField"
      }
      insertDropField(dragItem, parseInt(emptyIndexes[0]), customFilterFieldList[emptyIndexes[0]]);
    }
  }

  return <div className='gantt-chart-filter'>
    <div className='filter-select'>
      <div className='title'>
        <span>Filters</span>
      </div>
      <Dropdown
        className='filter-option'
        selection
        selectOnBlur={false}
        onChange={(e, target: any) => onFilterSelect(target.value)}
        options={setGanttChartFilterOptions(props.fieldList)}
        placeholder='Select Filter'
      />
    </div>
    <span className='border' />
    <div className='dropbox-container'>
      <Dropbox
        itemIndex={0}
        withItem={customFilterFieldList['0']}
        insertDropField={insertDropField}
        deleteFilter={deleteFilter}
        editConfiguration={() => { }}
        dragIconOutside
      />
      <Dropbox
        itemIndex={1}
        withItem={customFilterFieldList['1']}
        insertDropField={insertDropField}
        deleteFilter={deleteFilter}
        editConfiguration={() => { }}
        dragIconOutside
      />
      <Dropbox
        itemIndex={2}
        withItem={customFilterFieldList['2']}
        insertDropField={insertDropField}
        deleteFilter={deleteFilter}
        editConfiguration={() => { }}
        dragIconOutside
      />
      <Dropbox
        itemIndex={3}
        withItem={customFilterFieldList['3']}
        insertDropField={insertDropField}
        deleteFilter={deleteFilter}
        editConfiguration={() => { }}
        dragIconOutside
      />
    </div>
    <AlertModal
      open={rangeFieldErrorMessage !== ''}
      close={() => setRangeFieldErrorMessage('')}
      message={rangeFieldErrorMessage}
      type='error'
    />
  </div>
}

export default GanttChartFilter;
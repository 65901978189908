import { IDocumentsGetDetailInput } from './interface/input/get-documents';
import { GET_DOCUMENTS, GET_DOCUMENTS_ERROR, GET_DOCUMENTS_RETURN } from './constant';
import { IDocumentGetResponse } from './interface/return/get-documents';

export const getDocumentsAction = (payload: IDocumentsGetDetailInput) => {
  return { type: GET_DOCUMENTS, ...payload }
}

export const getDocumentsReturnAction = (payload: IDocumentGetResponse) => {
  return { type: GET_DOCUMENTS_RETURN, ...payload }
}

export const getDocumentsErrorAction = (message: string) => {
  return { type: GET_DOCUMENTS_ERROR, message }
}
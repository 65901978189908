import { ICustomFilterContainer, IApiCustomFilterField, ICustomFilterField } from '../../component/admin-module/module/business-process/module/configuration/module/custom-filter/interface/custom-filter-field';
import { v4 as uuid } from 'uuid';
import { defaultFilterList, customFilterSingleField, customFilterRangeField } from '../../component/admin-module/module/business-process/module/configuration/module/custom-filter/constant';

export class SanitizeCustomFilter {
  static structureOutgoing = (customFilterList: ICustomFilterContainer): IApiCustomFilterField[] => {
    const customFieldContainer: IApiCustomFilterField[] = [];
    for (var i = 0; i < 8; i++) {
      if (customFilterList[i.toString()] && customFilterList[i.toString()] !== 'contained') {
        const data: ICustomFilterField = customFilterList[i.toString()];
        let isRange = false;
        let tempType = data.type;

        if (data.type === 'timeRange' || data.type === 'dateRange' || data.type === 'numberRange') {
          isRange = true;
          tempType = data.type.replace('Range', '');
        }
        if (data.type === 'searchBox' || data.type === 'fileupload') {
          tempType = 'string';
        }
        if (data.type === 'dropdown') {
          tempType = 'enum';
        }
        if (data.type === 'switch') {
          tempType = 'bool';
        }
        const fields: string[] = SanitizeCustomFilter.convertOutgoingField(data.fields)
        customFieldContainer.push({
          id: data.id ? data.id : uuid(),
          placement: i.toString(),
          name: data.name,
          type: tempType,
          keyPaths: fields,
          isArrayInput: data.isMultiSelection,
          isRangeInput: isRange
        })
      }
    }

    return customFieldContainer;
  }

  static structureIncoming = (customFilterList: IApiCustomFilterField[]): ICustomFilterContainer => {
    let currentCustomFilter: ICustomFilterContainer = { ...defaultFilterList }
    customFilterList.forEach((filter: IApiCustomFilterField) => {
      let type = filter.type;

      if (filter.type === 'string') {
        type = 'searchBox'
      }
      if (filter.type === 'enum') {
        type = 'dropdown'
      }
      if (filter.type === 'bool') {
        type = 'switch'
      }
      let defaultData = customFilterSingleField.filter((customFilter: ICustomFilterField) => customFilter.type === type);
      if (filter.isRangeInput) {
        type = `${filter.type}Range`;
        defaultData = customFilterRangeField.filter((customFilter: ICustomFilterField) => customFilter.type === type);
        const nextPos = parseInt(filter.placement) + 1;
        currentCustomFilter[nextPos.toString()] = 'contained';
      }
      const currentObject = defaultData.length === 0 ? {} as ICustomFilterField : defaultData[0];
      const currentFilterObject: ICustomFilterField = Object.assign({}, { ...currentObject });
      currentFilterObject.fields = filter.keyPaths.map((fieldName: string) => {
        let fieldType = 'system';
        if (fieldName.indexOf('fields.') > -1) {
          fieldType = 'field';
          fieldName = fieldName.split('.')[1];
        }
        return `${fieldType}|||||${fieldName}|||||no label`;
      });
      currentFilterObject.name = filter.name;
      currentFilterObject.isMultiSelection = filter.isArrayInput;
      currentCustomFilter[filter.placement] = currentFilterObject;
      currentFilterObject.id = filter.id;
    });
    return currentCustomFilter;
  }

  static convertOutgoingField = (fields: string[]): string[] => {
    return fields.map((field: string) => {
      const [type, fieldName] = field.split('|||||');
      let tempFieldName = fieldName;
      if (type !== 'system') {
        tempFieldName = `fields.${fieldName}`
      }
      return tempFieldName;
    });
  }

}
import { IFormBuilderSection } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';

class UnlinkField {

  name = 'UnlinkField';

  updateSection = (sectionList: IFormBuilderSection[], forUpdateField: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    let newSectionList = [...sectionList];
    return newSectionList.map((section: IFormBuilderSection) => {
      if (section.sectionId === sectionUpdateConfig.sectionId) {
        if (sectionUpdateConfig.rowIndex !== undefined && sectionUpdateConfig.currentFieldIndex !== undefined) {
          section.fields[sectionUpdateConfig.rowIndex].splice(sectionUpdateConfig.currentFieldIndex, 1);
          section.fields.splice(sectionUpdateConfig.rowIndex + 1, 0, [forUpdateField]);
        }
      }
      return section;
    });
  }
}

export default UnlinkField;
export const setDateValidationSchema = (config: any, id: string, rangeType: string) => {
  if (rangeType === 'From') {
    config.max = {
      type: 'basicExpression',
      value: {
        left: {
          fieldId: `To.${id}`,
          keyPath: `fields.To.${id}`
        },
        operator: 'add',
        right: {
          amount: 0,
          unit: 'days'
        }
      }
    }
  } else {
    config.min = {
      type: 'basicExpression',
      value: {
        left: {
          fieldId: `From.${id}`,
          keyPath: `fields.From.${id}`
        },
        operator: 'subtract',
        right: {
          amount: 0,
          unit: 'days'
        }
      }
    }
  }
  return config;
}
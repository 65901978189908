import React from 'react';
import { ICustomFieldDetail } from '../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IPermissionFieldFilterConfig } from '../../../../interface/field-permission';
import { ISection, ITablePermission } from '../../../../interface/section';
import SectionBody from './body';

interface IPlainSectionContainer {
  section: ISection;
  handlePermissionChange(forUpdateSection: ISection | ITablePermission, fieldId: string, accessLevel: string, permissionConfig?: IPermissionFieldFilterConfig, updateType?: string): void;
  openFieldPermissionSettings(field: ICustomFieldDetail, section: ISection | ITablePermission, updateType: string): void;
  tablePermission?: ITablePermission[];
}

const PermissionSectionPlain: React.FC<IPlainSectionContainer> = (props) => {
  return <div className='section-list-container-plain'>
    <SectionBody
      section={props.section}
      handlePermissionChange={props.handlePermissionChange}
      openFieldPermissionSettings={props.openFieldPermissionSettings}
      tablePermission={props.tablePermission}
      sectionType='section'
    />
  </div>

}

export default PermissionSectionPlain;
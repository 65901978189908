class MultipleEnumOperatorOption {

  name = 'multiple-enum';

  option = [
    { key: 0, value: 'is-empty', text: 'Is empty' },
    { key: 1, value: 'is-not-empty', text: 'Is not empty' },
    { key: 2, value: 'equal', text: 'Equal' },
    { key: 3, value: 'not-equal', text: 'Not Equal' },
    { key: 4, value: 'includes', text: 'Includes' },
    { key: 5, value: 'excludes', text: 'Excludes' },
    { key: 6, value: 'any-in', text: 'Any In' }
  ]

}

export default MultipleEnumOperatorOption;
import React, { Fragment } from 'react';
import { ArrayFieldProperties } from '../../../../service/array-field-properties.service';
import { FieldProperties } from '../../../../service/field-properties.service';
import { NumberProps } from '../../../../service/type/number';

interface IProps {
  elementProperties: ArrayFieldProperties<NumberProps> | FieldProperties<NumberProps>;
  showErrorMessage?: boolean;
  row?: number;
}

export const renderError = ({
  elementProperties,
  showErrorMessage,
  row
}: IProps) => {
  if (elementProperties instanceof ArrayFieldProperties && row !== undefined) {
    return <>{elementProperties.hasError(row) && showErrorMessage &&
      elementProperties.getError(row)[0].message.indexOf('Please provide value for') === -1 &&
      <small className='field error' dangerouslySetInnerHTML={{ __html: elementProperties.getError(row)[0].message }} />
    }</>;
  }
  if (elementProperties instanceof FieldProperties) {
    return <>
      {
        elementProperties.hasError() && showErrorMessage &&
        elementProperties.getError()[0].message.indexOf('Please provide value for') === -1 &&
        <Fragment>
          <small style={{ display: 'flex' }} />
          <small className='field error'
            dangerouslySetInnerHTML={{ __html: elementProperties.getError()[0].message }}
          />
        </Fragment>
      }
    </>
  }
}
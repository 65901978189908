import React, { useState, useEffect } from 'react';
import { UploadedFile, uploadFile } from '../../../../../api-request/upload';
import prettyBytes from 'pretty-bytes';

const SweetProgress = require('react-sweet-progress');
const mime = require('mime-types');

interface IFileUploadProgress {
  data: IUploadedFile;
  removeFile(number: number): void;
  company: string;
  setUploadedFile(file: UploadedFile, number: number): void;
  index: number;
  openFile: any;
}

interface IUploadedFile extends UploadedFile {
  new?: boolean;
}

const FileUploadProgress: React.FC<IFileUploadProgress> = (props) => {

  const [uploadProgress, setUploadProgress] = useState(null as number | null);
  const [file, setFile] = useState(null as IUploadedFile | null);
  const [uploadMessage, setUploadMessage] = useState<string | null | Error>(null);

  const startUpload = async (files: any) => {
    if (!files) { return };

    try {
      setUploadMessage(null);
      const uploadedFile = await uploadFile({
        companyId: props.company,
        file: files,
        progress: (progress: number) => {
          setUploadProgress(progress);
        }
      });
      setUploadedFile(uploadedFile, files);
    } catch (e) {
      const error = e as any;
      setUploadMessage(error);
    }
  }

  const setUploadedFile = (file: IUploadedFile, prevFile: any) => {
    setUploadMessage('File uploaded successfully');
    setFile({ ...file, new: true });
    setUploadProgress(null);
  }

  useEffect(() => {
    if (props.data.new && !uploadProgress) {
      setUploadMessage('File uploaded successfully');
    }
    if (props.data && !props.data.id) {
      const timer = setTimeout(() => {
        startUpload(props.data);
      }, 500);
      return () => { clearTimeout(timer) }
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    if (file !== null) props.setUploadedFile(file, props.index,);
    // eslint-disable-next-line
  }, [file])

  return <div id='e-upload-file-list'>
    <span className="e-file-container">
      <span className="e-file-name" title={props.data.name}
        onClick={() => props.openFile(props.index)}>
        {props.data.name}
      </span>
      <span className="e-file-type">
        ({mime.extension(props.data.type)})
      </span>
      <span className="e-file-size">
        {prettyBytes(props.data.size)}
      </span>
      {!uploadMessage && uploadProgress !== null ?
        <SweetProgress.Progress
          percent={Math.round(uploadProgress * 10000) / 100}
          theme={{
            success: {
              symbol: Math.round(uploadProgress * 10000) / 100 + '%',
              color: '#86d94c'
            },
            active: {
              symbol: Math.round(uploadProgress * 10000) / 100 + '%',
              color: '#86d94c'
            },
            default: {
              symbol: Math.round(uploadProgress * 10000) / 100 + '%',
              color: '#86d94c'
            }
          }}
        />
        :
        <span className={`e-file-status ${uploadMessage instanceof Error ? 'e-upload-error' : 'e-upload-success'}`}>
          {uploadMessage}
        </span>
      }
    </span>
    <span {...{ index: props.index }} className="e-icons e-file-delete" tabIndex={0} title="Delete file"
      onClick={() => props.removeFile(props.index)} />
  </div>
}

export default FileUploadProgress;
export interface ILanguageRegionReducer {}

const initialState: ILanguageRegionReducer = {};

const languageRegionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default languageRegionReducer;

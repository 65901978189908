import {
  GET_USER_BUSINESS_PROCESS,
  GET_USER_BUSINESS_PROCESS_RETURN,
  GET_USER_BUSINESS_PROCESS_ERROR,
  GET_END_USER_DETAIL,
  GET_END_USER_DETAIL_RETURN,
  GET_END_USER_DETAIL_ERROR,
  SET_SELECTED_BP,
  SET_SELECTED_APP,
  SET_CURRENT_USER_IN_DOCUMENT,
  SET_DOCUMENT_ACTION_MESSAGE,
  CLEAR_DOCUMENT_ACTION_MESSAGE
} from './constant';
import { IUser } from '../admin-module/module/users/interface/user';
import { IForm } from '../admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../admin-module/module/data-tables/reducer';
import { ISenderDetail } from './module/form/interface/sender-info';
import { IFormDocument } from './module/report/interface/form-document';

// import { fromJS } from 'immutable'
export interface IEndUserReducer {
  endUserDetail: IUser;
  userBusinessProcess: IForm[];
  userBusinessProcessLoading: boolean;
  userBusinessProcessError: string;
  selectedApp: string[];
  selectedBp: string[];
  userDataTable: IDataTable[];
  currentUserEditingDocument: ISenderDetail[];
  currentuserDocumentDetail: IFormDocument;
  documentActionMessage: string;
  hasEndUserDetailsAndBP: boolean;
}

const initialState: IEndUserReducer = {
  userBusinessProcess: [] as IForm[],
  userBusinessProcessLoading: false,
  userBusinessProcessError: '',
  endUserDetail: {} as IUser,
  selectedApp: [],
  selectedBp: [],
  userDataTable: [],
  currentUserEditingDocument: [],
  currentuserDocumentDetail: {} as IFormDocument,
  documentActionMessage: '',
  hasEndUserDetailsAndBP: false
}

const endUserReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  const selectedApp = localStorage.getItem('selectedApp');
  const selectedBp = localStorage.getItem('selectedBp');
  newState.selectedApp = selectedApp ? JSON.parse(selectedApp) : [];
  newState.selectedBp = selectedBp ? JSON.parse(selectedBp) : [];

  switch (action.type) {
    case GET_USER_BUSINESS_PROCESS:
      newState.userBusinessProcessLoading = true;
      newState.userBusinessProcess = [];
      newState.hasEndUserDetailsAndBP = false;
      return newState;

    case GET_USER_BUSINESS_PROCESS_RETURN:
      newState.userBusinessProcessLoading = false;
      newState.userBusinessProcess = action.businessProcess;
      newState.userDataTable = action.dataTable;
      newState.hasEndUserDetailsAndBP = true;
      return newState;

    case GET_USER_BUSINESS_PROCESS_ERROR:
      newState.userBusinessProcessLoading = false;
      newState.userBusinessProcessError = action.message;
      return newState;

    case GET_END_USER_DETAIL:
      newState.userBusinessProcessLoading = true;
      newState.endUserDetail = {};
      return newState;

    case GET_END_USER_DETAIL_RETURN:
      newState.userBusinessProcessLoading = false;
      newState.endUserDetail = action.data;
      return newState;

    case GET_END_USER_DETAIL_ERROR:
      newState.userBusinessProcessLoading = false;
      newState.userBusinessProcessError = action.message;
      return newState;

    case SET_SELECTED_BP:
      localStorage.setItem('selectedBp', JSON.stringify(action.selectedBp));
      newState.selectedBp = action.selectedBp;
      return newState;

    case SET_SELECTED_APP:
      localStorage.setItem('selectedApp', JSON.stringify(action.selectedApp));
      newState.selectedApp = action.selectedApp;
      return newState;

    case SET_CURRENT_USER_IN_DOCUMENT:
      newState.currentUserEditingDocument = action.currentUserEditingDocument;
      newState.currentuserDocumentDetail = action.currentuserDocumentDetail;
      return newState;

    case SET_DOCUMENT_ACTION_MESSAGE:
      newState.documentActionMessage = action.documentActionMessage;
      return newState;

    case CLEAR_DOCUMENT_ACTION_MESSAGE:
      newState.documentActionMessage = '';
      return newState;

    default:
      return state;
  }
}

export default endUserReducer;
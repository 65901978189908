import React, { CSSProperties, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react-bpm';
import { formBuilderFieldType } from '../../../../../../../../../common/field/type';
import { IFieldTypeObj } from '../../../../../../../../../common/field/type/interface/field-type-object';
import { IForm, IFieldsLayout } from '../../../../form/interface/form';
import { clearTimeout } from 'timers';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';
import { fieldIconBaseOnConfigName } from '../constant';

interface IFilterProps {
  filterSearchInput(searchValue: string): void;
  filterByType(value: string): void;
  filterBySection(value: string): void;
  fieldTypeFilter: string;
  fieldSectionFilter: string;
  formDetail: IForm;
}

const Filter: React.FC<IFilterProps> = (props) => {
  const searchRef = useRef(document.createElement('input'));
  let onTypeTimeout = setTimeout(() => { }, 1000);

  const getTypeOption = () => {
    return formBuilderFieldType.map((field: IFieldTypeObj, index: number) => {
      let iconStyle: CSSProperties = field.style;
      if (field.defaultData) {
        if (fieldIconBaseOnConfigName[field.defaultData.configName]) {
          iconStyle = {
            ...fieldIconBaseOnConfigName[field.defaultData.configName].dropdownStyle
          };
        }
      }
      return {
        key: index,
        value: field.defaultData.configName,
        text: field.title,
        image: { src: field.icon.blue, style: iconStyle }
      }
    })
  }

  const getSectionOption = () => {
    return props.formDetail.fieldsLayout
      ? props.formDetail.fieldsLayout.map((layout: IFieldsLayout, index: number) => {
        return {
          key: index,
          text: layout.title || 'Untitled Section',
          value: layout.id
        }
      })
      : [];
  }

  const onChangeSearch = () => {
    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      props.filterSearchInput(searchRef.current.value)
    }, 1000);
  }

  return (
    <div className='filter-container'>
      <div className={`field`}>
        <div className="ui input">
          <input ref={searchRef}
            type="text" placeholder='Search' onChange={onChangeSearch} />
        </div>
        <Dropdown
          popperContainer={DropdownPopperOption}
          value={props.fieldTypeFilter}
          className={`field-type-selection`}
          clearable
          options={getTypeOption()}
          placeholder='Field Type'
          selection
          search
          selectOnBlur={false}
          onChange={(element, target) => {
            props.filterByType(target.value as string);
          }}
        />
        <Dropdown
          popperContainer={DropdownPopperOption}
          value={props.fieldSectionFilter}
          clearable options={getSectionOption()}
          placeholder='Section'
          selection
          search
          selectOnBlur={false}
          onChange={(element, target) => {
            props.filterBySection(target.value as string);
          }}
        />
      </div>
    </div>
  );
}

export default Filter;
import { v4 as uuid } from 'uuid';

export const tempStakeholdersData = [
  {
    id: uuid(),
    status: 'Order Draft',
    accessType: ['edit'],
    statusName: 'Order Draft',
    selected: ['pay'],
    initial: false,
    final: false,
    actionsList: [
      {
        key: 0,
        text: 'Pay',
        value: 'pay'
      },
      {
        key: 1,
        text: 'Payment',
        value: 'payment'
      }
    ]
  },
  {
    id: uuid(),
    status: 'For Preparation',
    accessType: ['edit'],
    statusName: 'Being Process',
    initial: false,
    final: true,
    selected: ['payment'],
    actionsList: [
      {
        key: 0,
        text: 'Pay',
        value: 'pay'
      },
      {
        key: 1,
        text: 'Payment',
        value: 'payment'
      }
    ]
  },
  {
    id: uuid(),
    status: 'Cancel-Non Stock',
    accessType: ['edit', 'view'],
    statusName: 'Cancel-Out of Stock',
    initial: false,
    final: true,
    selected: [],
    actionsList: []
  },
  {
    id: uuid(),
    status: 'Completed',
    accessType: ['edit', 'view'],
    statusName: 'Completed',
    initial: false,
    final: true,
    selected: [],
    actionsList: []
  },
]
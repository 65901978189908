import { getRequest, postRequest, deleteRequest, putRequest, IAxiosResponse } from '../../../../common/api-request';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setStatusCodeAction } from '../../../error/action';
import {
  getCompaniesListErrorAction,
  getCompaniesListReturnAction,
  uploadCompanyLogoReturnAction,
  uploadCompanyLogoErrorAction,
  createCompanyReturnAction,
  createCompanyErrorAction,
  geCompanyLogoReturnAction,
  geCompanyLogoErrorAction,
  getCompanyDetailsReturnAction,
  getCompanyDetailsErrorAction,
  confirmDeleteCompanyErrorAction,
  confirmDeleteCompanyReturnAction,
  updateCompanyReturnAction,
  updateCompanyErrorAction
} from './action';
import { GET_COMPANIES_LIST, UPLOAD_COMPANY_LOGO, CREATE_COMPANY, GET_COMPANY_LOGO, GET_COMPANY_DETAILS, DELETE_COMPANY_CONFIRM, UPDATE_COMPANY } from './constant';
import { ICompanyLogoUploadInput, ICompanyLogoGetInput } from './interface/input/company-logo';
import { ICompanyCreateInput } from './interface/input/company-create';
import { ICompanyDetailsGetInput } from './interface/input/company-details';
import { ICompanyConfirmDeleteInput } from './interface/input/company-confirm-delete';
import { ICompanyUpdateInput } from './interface/input/company-update';
import { AuthorisedUser } from '../../../auth/authorize-user';
import { updateUserDetailAction } from '../../../auth/action/login';
import { ICompanyListInput } from './interface/input/company-list';

export function* getCompaniesList(args: ICompanyListInput) {
  try {
    let limit = 0;
    let offset = 0;
    const req: IAxiosResponse = yield call(getRequest, `companies?offset=${offset}&limit=${limit}`, {});
    yield put(getCompaniesListReturnAction({ data: req.data.data, companiesTotalCount: req.data.data.length }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getCompaniesListErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* uploadCompanyLogo(args: ICompanyLogoUploadInput) {
  try {
    const req: IAxiosResponse = yield call(postRequest, `/uploads`, args.data);
    yield put(uploadCompanyLogoReturnAction({ file: req.data.data }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(uploadCompanyLogoErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* createCompany(args: ICompanyCreateInput) {
  try {
    const req: IAxiosResponse = yield call(postRequest, `companies`, args.data);
    if (req.data.success) {
      const userDetail: IAxiosResponse = yield call(getRequest, '/me', {});
      yield put(updateUserDetailAction({
        ...userDetail.data.data,
        user: {
          ...userDetail.data.data,
          deleted: userDetail.data.data.user.deleted
        },
      } as AuthorisedUser));
    }
    yield put(createCompanyReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    console.log(`error`, error);
    yield put(setStatusCodeAction(e))
    if (error && error.response) {
      yield put(createCompanyErrorAction(JSON.stringify(error.response.data.message)));
    }
  }
}

export function* getCompanyLogo(args: ICompanyLogoGetInput) {
  try {
    const fileId = args.fileId;
    const req: IAxiosResponse = yield call(getRequest, `/uploads/${fileId}`, { responseType: 'blob' });
    yield put(geCompanyLogoReturnAction({ file: URL.createObjectURL(req.data) }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(geCompanyLogoErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* getCompanyDetails(args: ICompanyDetailsGetInput) {
  try {
    const company = args.companyId;
    const req: IAxiosResponse = yield call(getRequest, `companies/${company}`, {});
    yield put(getCompanyDetailsReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(getCompanyDetailsErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* deleteCompany(args: ICompanyConfirmDeleteInput) {
  try {
    const company = args.companyId;
    yield call(deleteRequest, `companies/${company}`, {});
    yield put(confirmDeleteCompanyReturnAction({ companyId: company }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(confirmDeleteCompanyErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* updateCompany(args: ICompanyUpdateInput) {
  try {
    const company = args.companyId;
    const req: IAxiosResponse = yield call(putRequest, `companies/${company}`, args.data);
    yield put(updateCompanyReturnAction({ data: req.data.data }));
  } catch (e) {
    const error = e as any;
    yield put(setStatusCodeAction(e))
    yield put(updateCompanyErrorAction(JSON.stringify(error.response.data.message)));
  }
}

export function* companiesSaga() {
  yield takeLatest(GET_COMPANIES_LIST, getCompaniesList);
  yield takeLatest(UPLOAD_COMPANY_LOGO, uploadCompanyLogo);
  yield takeLatest(CREATE_COMPANY, createCompany);
  yield takeLatest(GET_COMPANY_LOGO, getCompanyLogo);
  yield takeLatest(GET_COMPANY_DETAILS, getCompanyDetails);
  yield takeLatest(DELETE_COMPANY_CONFIRM, deleteCompany);
  yield takeLatest(UPDATE_COMPANY, updateCompany);
}

// All sagas to be loaded
export default companiesSaga;
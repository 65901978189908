import { Delta } from 'jsondiffpatch';
import { ICommentDeltaFields } from '../../../../../document-module/module/form/interface/comment';
import { IAdminHistoryUtils } from '../../manage-history';

export class FormFieldChanges {
  static format = (targetDelta: Delta, utils: IAdminHistoryUtils, targetInstance: any) => {
    const deltaArray = [] as ICommentDeltaFields[];

    Object.keys(targetDelta.fields).forEach(key => {
      let deltaFields = {} as ICommentDeltaFields;
      const oldValue = targetInstance && targetInstance.fields && targetInstance.fields[key];

      const fieldConfig = utils.fields && utils.fields.find(e => e.id === key);
      if (fieldConfig) {
        deltaFields.id = fieldConfig.id || '';
        deltaFields.label = fieldConfig.label || '';
        deltaFields.diff = targetDelta.fields[key];
        if (targetDelta.fields[key] instanceof Array && targetDelta.fields[key].length === 1) {
          deltaFields.diff = [null, ...targetDelta.fields[key]]
        }
        if (fieldConfig.inputConfig) {
          deltaFields.type = fieldConfig.inputConfig.type;
        }
        deltaFields.oldValue = oldValue;
        deltaFields.alwaysShowLabel = true;
      }
      if (Object.keys(deltaFields).length) {
        deltaArray.push(deltaFields);
      }
    });
    return deltaArray;
  }
}
import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import BusinessProcessList from '.';
import { IAdminRender } from '../module-registry';
import BusinessProcessModule from './module';

interface IMatch {
  path: string;
  params: {
    submodule: string;
    thirdLevelRoute?: string;
  }
}

class BusinessProcessRouteModule {

  name = 'business-process';

  render: React.FC<IAdminRender> = (props) => {
    const match: IMatch = useRouteMatch();

    return (
      <>
        <Route exact path={`${match.path}`}>
          <BusinessProcessList />
        </Route>
        <Route path={`${match.path}/:thirdLevelRoute`}>
          <BusinessProcessModule {...props} />
        </Route>
      </>
    );
  }
}


export default BusinessProcessRouteModule;

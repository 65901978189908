import React, { CSSProperties } from 'react';
import SVGWrapper from '../../../../../../../../../../../common/icons/svg/svg-wrapper';
import { IOptionWidget } from '../../../interface/option-widget';

interface IAutomationDetailTriggerOptionProps {
  name: string;
  title: string;
  description: string;
  iconName: string;
  iconHeight: number;
  iconWidth: number;
  containerStyle: CSSProperties;
  selected: boolean;
  setSelected(option?: IOptionWidget): void;
}

const AutomationDetailOptionWidget: React.FC<IAutomationDetailTriggerOptionProps> = (props) => {

  let selectedStyle = {};
  if (props.selected) {
    selectedStyle = {
      color: '#3A85A8',
      borderColor: '#3A85A8',
    }
  }
  return (
    <div className='option-widget' onClick={() => props.setSelected()}>
      <div style={{ ...props.containerStyle, ...selectedStyle }}>
        <SVGWrapper
          width={props.iconHeight}
          height={props.iconHeight}
          iconName={props.iconName}
          color={props.selected ? `#3A85A8` : '#000000'}
        />
      </div>
      <div className={`content ${props.selected ? 'selected' : ''}`}>
        <span className='title'>{props.title}</span>
        <span className='description'>{props.description}</span>
      </div>
    </div>
  );
}

export default AutomationDetailOptionWidget;
import { IMessageProps } from '../../../../common/general/action-message/interface/message-props'

export const GET_BUSINESS_PROCESS_OPTION = 'app/apps/GET_BUSINESS_PROCESS_OPTION';
export const GET_BUSINESS_PROCESS_OPTION_RETURN = 'app/apps/GET_BUSINESS_PROCESS_OPTION_RETURN';
export const GET_BUSINESS_PROCESS_OPTION_ERROR = 'app/apps/GET_BUSINESS_PROCESS_OPTION_ERROR';
export const CREATE_APP = 'app/apps/CREATE_APP';
export const CREATE_APP_RETURN = 'app/apps/CREATE_APP_RETURN';
export const CREATE_APP_ERROR = 'app/apps/CREATE_APP_ERROR';
export const GET_APP_LIST = 'app/apps/GET_APP_LIST';
export const GET_APP_LIST_RETURN = 'app/apps/GET_APP_LIST_RETURN';
export const GET_APP_LIST_ERROR = 'app/apps/GET_APP_LIST_ERROR';
export const CLEAR_APP_MESSAGE = 'app/apps/CLEAR_APP_MESSAGE';
export const GET_APP_DETAILS = 'app/apps/GET_APP_DETAILS';
export const GET_APP_DETAILS_RETURN = 'app/apps/GET_APP_DETAILS_RETURN';
export const GET_APP_DETAILS_ERROR = 'app/apps/GET_APP_DETAILS_ERROR';
export const DELETE_APP = 'app/apps/DELETE_APP'
export const DELETE_APP_CANCEL = 'app/apps/DELETE_APP_CANCEL'
export const DELETE_APP_CONFIRM = 'app/apps/DELETE_APP_CONFIRM'
export const DELETE_APP_CONFIRM_RETURN = 'app/apps/DELETE_APP_CONFIRM_RETURN'
export const DELETE_APP_CONFIRM_ERROR = 'app/apps/DELETE_APP_CONFIRM_ERROR'
export const UPDATE_APP = 'app/apps/UPDATE_APP';
export const UPDATE_APP_RETURN = 'app/apps/UPDATE_APP_RETURN';
export const UPDATE_APP_ERROR = 'app/apps/UPDATE_APP_ERROR';

export const CLEAR_CREATE_APP_SUCCESS = 'app/apps/CLEAR_CREATE_APP_SUCCESS';
export const CLEAR_DELETE_APP_SUCCESS = 'app/apps/CLEAR_DELETE_APP_SUCCESS';
export const CLEAR_UPDATE_APP_SUCCESS = 'app/apps/CLEAR_UPDATE_APP_SUCCESS';
export const CLEAR_CREATE_APP_ERROR = 'app/apps/CLEAR_CREATE_APP_ERROR';
export const CLEAR_UPDATE_APP_ERROR = 'app/apps/CLEAR_UPDATE_APP_ERROR';

export const appLimit = 20;

export const AppActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_APP_SUCCESS,
    type: 'success',
    reducerContainer: 'apps',
    reducerItem: 'createAppMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_APP_SUCCESS,
    type: 'success',
    reducerContainer: 'apps',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_APP_SUCCESS,
    type: 'success',
    reducerContainer: 'apps',
    reducerItem: 'updateAppMessage',
    hasCloseIcon: true,
  },
]

export const FormAppActionMessageCreateAddNew: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_APP_SUCCESS,
    type: 'success',
    reducerContainer: 'apps',
    reducerItem: 'createAppMessage',
    hasCloseIcon: true,
  }
]

export const FormAppActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_APP_ERROR,
    type: 'negative',
    reducerContainer: 'apps',
    reducerItem: 'createAppMessageError',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_APP_ERROR,
    type: 'negative',
    reducerContainer: 'apps',
    reducerItem: 'updateAppMessageError',
    hasCloseIcon: true,
  }
]
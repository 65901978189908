import { IFormFieldGetDetailInput } from './interface/input/form-field-get'
import { IFormFieldGetResponse, IFormDetailGetResponse } from './interface/response/form-field-get'
import { GET_COLUMN_VIEW_LIST, GET_COLUMN_VIEW_LIST_RETURN, GET_FORM_FIELD_LIST, GET_FORM_FIELD_LIST_RETURN, GET_FORM_DETAIL, GET_FORM_DETAIL_RETURN, SAVE_DRAFT_COLUMN_VIEW, SAVE_DRAFT_COLUMN_VIEW_RETURN, SAVE_DRAFT_COLUMN_VIEW_ERROR, PUBLISH_COLUMN_VIEW, PUBLISH_COLUMN_VIEW_RETURN, PUBLISH_COLUMN_VIEW_ERROR } from './constant'
import { IFormBuilderDetailInput } from '../../../form-builder/interface/input/form-builder'
import { IColumnViewDetailSaveInput, IColumnViewPublishDetailInput } from './interface/input/column-view-save'
import { IColumnViewSaveResponse } from './interface/response/column-view-save'

export const getColumnViewListAction = (payload: any) => {
  return {
    type: GET_COLUMN_VIEW_LIST,
    ...payload
  }
}

export const getColumnViewListReturnAction = (payload: any) => {
  return {
    type: GET_COLUMN_VIEW_LIST_RETURN,
    ...payload
  }
}

export const getFormFieldListAction = (payload: IFormFieldGetDetailInput) => {
  return {
    type: GET_FORM_FIELD_LIST,
    ...payload
  }
}

export const getFormFieldListReturnAction = (payload: IFormFieldGetResponse) => {
  return {
    type: GET_FORM_FIELD_LIST_RETURN,
    ...payload
  }
}

export const getFormDetailAction = (payload: IFormBuilderDetailInput) => {
  return {
    type: GET_FORM_DETAIL,
    ...payload
  }
}

export const getFormDetailReturnAction = (payload: IFormDetailGetResponse) => {
  return {
    type: GET_FORM_DETAIL_RETURN,
    ...payload
  }
}

export const saveDraftColumnViewAction = (payload: IColumnViewDetailSaveInput) => {
  return {
    type: SAVE_DRAFT_COLUMN_VIEW,
    ...payload
  }
}

export const saveDraftColumnViewReturnAction = (payload: IColumnViewSaveResponse) => {
  return {
    type: SAVE_DRAFT_COLUMN_VIEW_RETURN,
    ...payload
  }
}

export const saveDraftColumnViewErrorAction = (message: string) => {
  return {
    type: SAVE_DRAFT_COLUMN_VIEW_ERROR,
    saveColumnViewErrorMessage: message
  }
}

export const publishColumnViewAction = (payload: IColumnViewPublishDetailInput) => {
  return {
    type: PUBLISH_COLUMN_VIEW,
    ...payload
  }
}

export const publishColumnViewReturnAction = () => {
  return {
    type: PUBLISH_COLUMN_VIEW_RETURN
  }
}

export const publishColumnViewErrorAction = (message: string) => {
  return {
    type: PUBLISH_COLUMN_VIEW_ERROR,
    saveColumnViewErrorMessage: message
  }
}
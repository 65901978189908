import { IFieldAccessType } from '../../../../../../../component/admin-module/module/users/interface/field';
import { FieldElement } from '../../../../field-element';

export const formatEnumValidationSchema = (field: FieldElement) => {
  const multiple = field.inputConfig && field.inputConfig.config && field.inputConfig.config.multiselect;
  let schema = {
    ...field.validationSchema,
    isRequired: field.accessType === IFieldAccessType.Required
  } as any;
  if (schema.hasOwnProperty('anyOf') && multiple) {
    if (Array.isArray(schema.anyOf)) {
      schema.anyOf.forEach((e: any) => {
        if (field.inputConfig.config.multiselect) {
          if (e.type === 'array') {
            schema = { ...e, isRequired: field.accessType === IFieldAccessType.Required };
          }
        } else {
          if (e.hasOwnProperty('anyOf')) {
            schema = { ...e, isRequired: field.accessType === IFieldAccessType.Required };
          }
        }
      });
    }
  }
  return schema;
}
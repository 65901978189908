import React, { useState } from 'react';
import FilterTab from './filter-tab';
import { fieldIconBaseOnConfigName, systemFieldList } from '../../document-list/constant';
import { IColumnView, IDragColumnView } from '../../document-list/interface/custom-view';
import Field from '../../document-list/field-selection/field';
import { v4 as uuid } from 'uuid';
import { ICustomFieldDetail } from '../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { useDrop } from 'react-dnd';
import { IConfigName } from '../../../../../../../../../common/field/type/interface/field-type-object';

interface ISideViewProps {
  currentSideView: 'column' | 'filter';
  setCurrentSideView(type: 'column' | 'filter'): void;
  fieldList: ICustomFieldDetail[];
  customViewList: IColumnView[];
  removeSelectedField(column: IColumnView): void;
}

const SideView: React.FC<ISideViewProps> = (props) => {

  const { currentSideView, setCurrentSideView } = { ...props };
  const [searchtext, setSearchText] = useState('')

  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'column-field',
    drop: (selectedItem: IDragColumnView) => {
      if (selectedItem.data.new === false) {
        props.removeSelectedField(selectedItem.data);
      }
    }
  })


  const getSystemFieldList = (): IColumnView[] => {
    const systemField: IColumnView[] = [];

    systemFieldList.forEach((e: IColumnView) => {
      if (props.customViewList.find((f: IColumnView) => f.fieldName === e.fieldName)) {
        systemField.push(e);
      }
    })

    return systemField;
  }


  return (
    <div className='side-view'>
      <div className='tab-container'>
        <span className={`column-tab-btn ${currentSideView === 'column' ? 'active' : ''}`} onClick={() => setCurrentSideView('column')}>Column</span>
        <span className={`filter-tab-btn ${currentSideView === 'filter' ? 'active' : ''}`} onClick={() => setCurrentSideView('filter')}>Filter</span>
      </div>
      {
        currentSideView === 'column'
        && <div ref={dropRef} className={`column-tab`}>
          <div className="ui search">
            <div className="ui icon input">
              <i className="search icon"></i>
              <input className="prompt" onChange={(e: any) => {
                console.log('e.target.value: ', e.target.value);
                setSearchText(e.target.value)
              }} type="text" placeholder="Search" />
            </div>
            <div className="results"></div>
          </div>
          <hr />
          {
            systemFieldList.map((field: IColumnView) => {
              if (props.customViewList.find((e: IColumnView) => e.fieldName === field.fieldName)) {
                return null;
              }

              if (searchtext === '' || (field.label && field.label.toLowerCase().indexOf(searchtext.toLowerCase()) > -1)) {
                return <Field key={`field-container-system-field-key-${uuid()}`} field={field} />;
              }

              return null;

            })
          }
          {
            props.fieldList.map((field: ICustomFieldDetail) => {
              if (
                props.customViewList.find((e: IColumnView) => e.fieldName === field.id)
              ) {
                return null;
              }

              if (fieldIconBaseOnConfigName[field.configName || IConfigName.TextString]) {
                const columnField: IColumnView = {
                  type: 'field',
                  label: field.label || '',
                  fieldName: field.id || '',
                  icon: fieldIconBaseOnConfigName[field.configName || IConfigName.TextString].icon,
                  iconStyle: fieldIconBaseOnConfigName[field.configName || IConfigName.TextString].iconStyle,
                  color: '',
                  new: true,
                };
                if (searchtext === '' || (field.label && field.label.toLowerCase().indexOf(searchtext.toLowerCase()) > -1)) {
                  return <Field key={`field-container-cutom-field-key-${uuid()}`} field={columnField} />;
                }
              }
              return null;
            })
          }
        </div>
      }

      {
        currentSideView === 'filter'
        && <FilterTab
          fieldList={props.fieldList}
          systemField={getSystemFieldList()}
        />
      }
    </div>
  )
}

export default SideView;
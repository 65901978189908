import { FieldElement } from '../../component/field-element';
import { errorMessages } from '../error';
import { FieldValidator } from './field-validator';
import Ajv from 'ajv';
import { IFieldAccessType } from '../../../../component/admin-module/module/users/interface/field';

export class TimeValidator extends FieldValidator {
  name = 'time';

  validate(field: FieldElement, value: string, actionType?: string): Ajv.ErrorObject[] {
    const { multiline } = { ...field } as any;

    if (((value === '' || value === 'undefined' || value === 'Invalid date')
      && (field.accessType !== 'required' || (field.accessType === IFieldAccessType.Required && actionType === 'save')))
      || field.accessType === IFieldAccessType.Readonly
      || field.accessType === IFieldAccessType.Hidden || field.accessType !== 'required') {
      return [];
    }

    const ajv = new Ajv();
    const validationSchema = {
      properties: {
        [field.id]: { ...field.validationSchema }
      },
    };
    const validate = ajv.compile(validationSchema);
    validate({ [field.id]: multiline ? [value] : value });
    if (validate.errors) {
      validate.errors = validate.errors.map(error => {
        return errorMessages(error, { ...field }, value);
      });
    }
    return validate.errors || [];
  }
}
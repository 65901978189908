import { IFieldTypeObj } from '../../../../../../common/field/type/interface/field-type-object';
import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';
import { ITableMobileViewContainer } from './interface/table-view';
import { ITableMobileView } from './interface/table-view';
import referenceForm from '../../../../../../common/field/type/reference-form';

export const GET_FORM_BUILDER_DETAIL = 'app/admin-module/business-process/formBuilder/GET_FORM_BUILDER_DETAIL';
export const GET_FORM_BUILDER_DETAIL_RETURN = 'app/admin-module/business-process/formBuilder/GET_FORM_BUILDER_DETAIL_RETURN';
export const GET_FORM_BUILDER_DETAIL_ERROR = 'app/admin-module/business-process/formBuilder/GET_FORM_BUILDER_DETAIL_ERROR';
export const SAVE_FORM_BUILDER_CONFIGURATION = 'app/admin-module/business-process/formBuilder/SAVE_FORM_BUILDER_CONFIGURATION';
export const SAVE_FORM_BUILDER_CONFIGURATION_RETURN = 'app/admin-module/business-process/formBuilder/SAVE_FORM_BUILDER_CONFIGURATION_RETURN';
export const SAVE_FORM_BUILDER_CONFIGURATION_ERROR = 'app/admin-module/business-process/formBuilder/SAVE_FORM_BUILDER_CONFIGURATION_ERROR';
export const DELETE_FORM_BUILDER_FIELD = 'app/admin-module/business-process/formBuilder/DELETE_FORM_BUILDER_FIELD';
export const PUBLISH_FORM_BUILDER_CONFIGURATION = 'app/admin-module/business-process/formBuilder/PUBLISH_FORM_BUILDER_CONFIG';
export const PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN = 'app/admin-module/business-process/formBuilder/PUBLISH_FORM_BUILDER_CONFIGURATION_RETURN';
export const PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR = 'app/admin-module/business-process/formBuilder/PUBLISH_FORM_BUILDER_CONFIGURATION_ERROR';
export const GET_PUBLISHED_FORM_LIST = 'app/admin-module/business-process/formBuilder/GET_PUBLISHED_FORM_LIST';
export const GET_PUBLISHED_FORM_LIST_RETURN = 'app/admin-module/business-process/formBuilder/GET_PUBLISHED_FORM_LIST_RETURN';
export const CLEAR_SAVE_FORM_BUILDER_SUCCESS = 'app/admin-module/business-process/formBuilder/CLEAR_SAVE_FORM_BUILDER_SUCCESS';
export const CLEAR_PUBLISH_FORM_BUILDER_SUCCESS = 'app/admin-module/business-process/formBuilder/CLEAR_PUBLISH_FORM_BUILDER_SUCCESS';
export const CLEAR_SAVE_FORM_BUILDER_ERROR = 'app/admin-module/business-process/formBuilder/CLEAR_SAVE_FORM_BUILDER_ERROR';
export const CLEAR_PUBLISH_FORM_BUILDER_ERROR = 'app/admin-module/business-process/formBuilder/CLEAR_PUBLISH_FORM_BUILDER_ERROR';
export const CLEAR_FORM_BUILDER_DETAIL_ERROR = 'app/admin-module/business-process/formBuilder/CLEAR_FORM_BUILDER_DETAIL_ERROR';
export const SET_SAVE_FORM_BUILDER_MESSAGE = 'app/admin-module/business-process/formBuilder/SET_SAVE_FORM_BUILDER_MESSAGE';

export const numberToStringName: { [number: string]: string } = {
  '1': 'one',
  '2': 'two',
  '3': 'three',
  '4': 'four',
  '5': 'five',
  '6': 'six'
}

export const maxFieldInARow = 4;

export const formBuilderActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_FORM_BUILDER_SUCCESS,
    type: 'success',
    reducerContainer: 'formBuilder',
    reducerItem: 'saveFormBuilderMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_SAVE_FORM_BUILDER_ERROR,
    type: 'negative',
    reducerContainer: 'formBuilder',
    reducerItem: 'saveFormBuilderErrorMessage',
    hasCloseIcon: true,
  }
]

export const formBuilderDetailMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_FORM_BUILDER_DETAIL_ERROR,
    type: 'negative',
    reducerContainer: 'formBuilder',
    reducerItem: 'formBuilderDetailErrorMessage',
    hasCloseIcon: true,
  }
]

const initialTableMobileView: ITableMobileView = {
  columnHeader: '',
  lowerField: '',
  upperField: ''
}

export const tableMobileViewList: ITableMobileViewContainer = {
  '0': initialTableMobileView,
  '1': initialTableMobileView,
  '2': initialTableMobileView,
};

export const systemReferencedFields: IFieldTypeObj[] = [
  {
    ...referenceForm,
    defaultConfig: {
      ...referenceForm.defaultConfig,
      dataType: 'user',
    },
    defaultData: {
      ...referenceForm.defaultData,
      label: 'Users',
    },
    title: 'Users'
  } as IFieldTypeObj,
  {
    ...referenceForm,
    defaultConfig: {
      ...referenceForm.defaultConfig,
      dataType: 'form',
    },
    defaultData: {
      ...referenceForm.defaultData,
      label: 'Business Processes',
    },
    title: 'Business Processes'
  } as IFieldTypeObj,
];
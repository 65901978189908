import { useEffect } from 'react';
import {
  getBusinessProcessOptionAction,
  getAppListAction
} from '../action';

interface IProps {
  company: string;
  dispatch: any;
}

export const useGetAppData = (props: IProps): void => {
  const { dispatch, company } = { ...props };

  useEffect(() => {
    if (company) {
      dispatch(getBusinessProcessOptionAction({ company: company }));
      dispatch(getAppListAction({ company: company }))
    }
  }, [dispatch, company]);
}
import { useEffect } from 'react';
export const useRemoveRoleActionModalEl = (): void => {
  useEffect(() => {
    return () => {
      const roleActionModal = document.getElementById('role-action-modal');
      const hiddenContainer = document.getElementById('role-action-modal-hiddencontainer');
      if (roleActionModal) {
        roleActionModal.parentNode?.removeChild(roleActionModal);
      }
      if (hiddenContainer) {
        hiddenContainer.parentNode?.removeChild(hiddenContainer);
      }
    }
  }, [])
}
import React, { useState, CSSProperties } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import { AcceptedProps } from '../../interface/accepted-props';
import { elementStatus } from '../../../utils/element-status';
import { v4 as uuid } from 'uuid';
import PopperContainer from '../../../../../general/popper-component';
import { setLabelRef } from '../../../../../utils/check-text-overflow';
import { setFocusedElement, getFocusedElement, focusSelectedElement, removeFocusedElement, setTableFocusedElement } from '../../../../../utils/focus-element';
import useDeviceDetect from '../../../../../general/device-detect';
import { ComputeDate } from '../../enum-type/utils/compute-date';
import { IField, IFieldAccessType } from '../../../../../../component/admin-module/module/users/interface/field';
import { DateProps } from '../../../service/type/date';
import { FieldProperties } from '../../../service/field-properties.service';
import { TValidationType } from '../../../service/field-properties.handler';
import { useInitialLoad } from './hook/use-initial-load';
import { renderIcon } from './utils/render-icon';
import { renderError } from './utils/render-error';
import { removePopperPosition } from '../date-time/utils/remove-popper-position';
import { returnPopperPosition } from '../date-time/utils/return-popper-position';
import { setMinMaxDateState } from './utils/set-min-max-date-state';

const DateType: React.FC<AcceptedProps> = (props) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { width } = useDeviceDetect();
  const mobile = width <= 1366;
  const focusedElement = getFocusedElement();
  const elementId = uuid();

  const name = props.getFieldId();
  const elementProperties = new FieldProperties(name, props.forwardedRef, new DateProps());

  if (!elementProperties.isSet() && props.triggerFilter) {
    elementProperties.setCurrent({ ...elementProperties.current, fieldType: 'date' })
  }

  const [refresher, setRefresher]: any = useState(1);
  const [minDate, setMinDate] = useState<any>(moment().subtract(100, 'years'));
  const [maxDate, setMaxDate] = useState<any>(moment().add(100, 'years'));
  const [excludeDates, setExcludeDates] = useState<Date[] | undefined>([] as Date[] | undefined);

  let onTypeTimeout = setTimeout(() => { }, 1000);

  const currentId = props.tableColumnRowIndex ?
    `${elementId}-date-picker-${name}-${props.tableColumnRowIndex}`
    : `${elementId}-date-picker-${name}`;

  const validateInput = () => {
    const schema = {
      ...props.validationSchema,
      minLength: props.accessType === IFieldAccessType.Required ? 1 : 0,
      isRequired: props.accessType === IFieldAccessType.Required
    }
    elementProperties.validate({ ...props, validationSchema: { ...schema } }, TValidationType.onBlur);
    setRefresher(uuid());
  }

  const setMinMaxDate = () => {
    setMinMaxDateState({
      inputConfig: { ...props.inputConfig },
      forwardedRef: props.forwardedRef,
      fromRange: props.fromRange,
      parentRef: props.parentRef,
      maxDate: maxDate,
      minDate: minDate,
      setExcludeDates,
      setMaxDate,
      setMinDate,
    });
  }

  const onCalendarClose = (id: string) => {
    if (focusedElement && focusedElement === id) {
      removeFocusedElement();
    }
    validateInput();

    if (typeof onTypeTimeout === 'function') {
      clearTimeout(onTypeTimeout);
    }
    onTypeTimeout = setTimeout(() => {
      if (props.reloadOnChange && props.reloadOnChange.length > 0 && props.triggerFieldReload) {
        props.triggerFieldReload(props.reloadOnChange);
      }
    }, 1000);
    setRefresher(refresher + 1);
  }

  const handleDate = (date: any) => {
    const value = date ? moment(date).format("YYYY-MM-DD") : '';
    elementProperties.setCurrent({ ...elementProperties.current, value });
    if (props.triggerFilter) { props.triggerFilter('') }

    if (props.hasPageChangeRef) {
      props.hasPageChangeRef.current.hasChange = true;
    }
    if (props.throwValueOutside) {
      props.throwValueOutside()
    }

    if (props.sendTriggerToParentContainer) props.sendTriggerToParentContainer();
    if (props.automationService && props.fieldCollection) {
      const field = props.fieldCollection.find((e: IField) => e.id === props.id);
      if (field) {
        props.automationService.didUpdateFieldValue(field, elementProperties.current.value)
      }
    }
  }

  const getValue = () => {
    const data = elementProperties.getCurrent().value;
    return data ? new Date(moment(data).tz(tz).format("MMMM/DD/YYYY")) : null;
  }

  const focusInput = (id: string) => {
    setFocusedElement(id);
    if (mobile) {
      setRefresher(uuid());
    }
    if (props.tableColumnRowIndex !== undefined) {
      setTableFocusedElement({
        index: props.tableColumnRowIndex,
        focusedElement: id,
        columnDisplayed: props.tableColumnDisplayed as number[]
      });
    }
    setMinMaxDate();
  }

  const setDefaultValue = () => {
    if (elementProperties.hasValue()) {
      return;
    }

    let defaultValue: any = props.defaultValue;
    if (props.defaultValue && !props.defaultValueElement) {
      const defaultValueString: string = defaultValue ? defaultValue as string : '';
      elementProperties.setCurrent({ ...elementProperties.current, value: defaultValueString });
    }
  }

  const fieldStyle = (): CSSProperties => {
    let style = {} as CSSProperties;
    if (props.hasInlineStyle) {
      const headerElement = document.getElementById(`header-${name}`);
      if (headerElement) {
        let { width } = headerElement.getBoundingClientRect();
        width = width < 72 ? 72 : width;
        style = { maxWidth: `${width}px` }
      } else {
        style = { maxWidth: `${72}px` }
      }
    }
    return style;
  }

  const setRef = (curRef: any, id: string) => {
    if (curRef && focusedElement && focusedElement === id) {
      focusSelectedElement(focusedElement);
    }
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab' || e.which === 9 || e.keyCode === 9) {
      removePopperPosition(currentId);
    }
  }

  const handleYearAndMonthChange = (date: Date) => {
    if (props.inputConfig.config.noWeekends) {
      const startDateOfMonth = moment(date).startOf('month').startOf('week');
      const endDateOfMonth = moment(date).endOf('month').endOf('week');
      const weekends = ComputeDate.getWeekends(startDateOfMonth.format('MM/DD/YYYY'), endDateOfMonth.format('MM/DD/YYYY'));
      setExcludeDates(weekends);
    }
  }

  useInitialLoad({ setDefaultValue, validateInput, accessType: props.accessType, isAddRecord: props.isAddRecord || false })

  return (<>
    {props.accessType !== IFieldAccessType.Hidden &&
      <>
        <span className='field-info'>
          <label
            ref={(element) => setLabelRef(element, name)}
            className={`field-label ${elementProperties.hasError() ? 'error' : ''}`}>
            {props.label}
          </label>
          {
            props.hint &&
            <i id={`info-${name}`} className="icon info-icon" title={props.hint}></i>
          }
        </span>
        <div style={{ ...fieldStyle(), flexDirection: 'column' }} className='flex-wrapper'>
          <>
            <DatePicker
              id={`${currentId}`}
              popperContainer={PopperContainer}
              onCalendarClose={() => onCalendarClose(currentId)}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              dropdownMode="select"
              showYearDropdown={true}
              showMonthDropdown={true}
              selected={getValue()}
              onChange={(date) => handleDate(date)}
              readOnly={props.accessType === IFieldAccessType.Readonly}
              autoFocus={props.isFocus}
              className={`field-input single ${elementStatus({
                forwardedRef: props.forwardedRef,
                name: name,
                multiline: props.multiline || false,
                required: props.accessType === IFieldAccessType.Required,
                fieldValueObject: elementProperties
              })}`}
              placeholderText={props.havePlaceholder && props.placeHolder ? props.placeHolder : ''}
              onInputClick={() => focusInput(currentId)}
              onFocus={() => returnPopperPosition(currentId)}
              ref={(curRef: any) => setRef(curRef, currentId)}
              onKeyDown={handleKeydown}
              popperClassName={currentId}
              excludeDates={excludeDates}
              onMonthChange={(date: Date) => handleYearAndMonthChange(date)}
              onYearChange={(date: Date) => handleYearAndMonthChange(date)}
            />
          </>
          {renderIcon('single', props.hasIconInside || false)}
          {renderError({ elementProperties, showErrorMessage: props.showErrorMessage })}
        </div>

      </>
    }
  </>)
}

export default DateType;
import { IutilProps } from '../..';
import { getFormStatusAction, getInputFieldAction } from '../../../../action';
import { IAutomationComponent } from '../../../../interface/component-list';

export class CreateDocument {
  static getOptions = (selectedComponent: IAutomationComponent, utils: IutilProps) => {
    const formId = selectedComponent?.config?.formId;
    if (formId) {
      if (!utils.publishedFormStatusList[formId]) {
        utils.dispatch(getFormStatusAction({
          company: utils.company,
          formId: formId,
          draft: false
        }));
      }
      if (!utils.inputFieldList[formId]) {
        utils.dispatch(getInputFieldAction({
          companyId: utils.company,
          formId: formId,
          statusId: ''
        }))
      }
    }
  }
}

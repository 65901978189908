import { ArrayFieldProperties } from '../../../service/array-field-properties.service';
import { TValidationType } from '../../../service/field-properties.handler';
import { FieldProperties } from '../../../service/field-properties.service';
import { NumberProps } from '../../../service/type/number';

export interface IValidateInput {
  name: string;
  validationSchemaProps: any;
  multiline: boolean;
  multilineCount?: number;
  forwardedRef: any;
  inputConfig: any;
  labelProps: string;
  requiredProps: boolean;
  setRefresher?(value: number): void;
  reloadOnChange?: string[];
  accessType?: string;
  valueFieldClass?: any;
  refresher: number;
}

export const validateInput = (data: IValidateInput) => {
  const {
    setRefresher,
    name,
    validationSchemaProps,
    multiline,
    multilineCount,
    inputConfig,
    labelProps,
    accessType,
    valueFieldClass,
    refresher
  } = { ...data };

  if (multiline && multilineCount) {
    let tempValue: ArrayFieldProperties<NumberProps> = valueFieldClass;
    for (let i: number = 0; i < multilineCount; i++) {
      tempValue.validate({
        id: name,
        label: labelProps,
        inputConfig: inputConfig,
        validationSchema: { ...validationSchemaProps },
        multiline: multiline,
        accessType: accessType
      } as any, TValidationType.onBlur)
    }
  } else {
    let tempValue: FieldProperties<NumberProps> = valueFieldClass;
    tempValue.validate({
      id: name,
      label: labelProps,
      inputConfig: inputConfig,
      validationSchema: { ...validationSchemaProps },
      multiline: multiline,
      accessType: accessType
    } as any, TValidationType.onBlur)
  }
  if (setRefresher) setRefresher(refresher + 1);
}
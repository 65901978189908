import { useEffect, useState } from 'react';

interface IProps {
  isOpen: boolean;
}

export const useSetExpandCollapseIcon = (props: IProps) => {
  const [icon, setIcon]: any = useState('');

  useEffect(() => {
    if (props.isOpen) {
      setIcon('chevron up');
    } else {
      setIcon('chevron down');
    }
  }, [props.isOpen]);

  return { icon, setIcon, ...props };
}
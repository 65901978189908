import { IFormBuilderSection, TFormBuilderSectionRow } from '../../interface/section';
import { ICustomFieldDetail } from '../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { ISectionUpdateConfig } from '../interface/section-update-config';
import { maxFieldInARow } from '../../constant';

class FieldTypeToSectionField {

  name = 'FieldTypeToSectionField';

  updateSection = (sectionList: IFormBuilderSection[], field: ICustomFieldDetail, sectionUpdateConfig: ISectionUpdateConfig): IFormBuilderSection[] => {
    const newSectionList = [...sectionList];
    return newSectionList.map((section: IFormBuilderSection) => {
      section.fields = section.fields.map((fields: TFormBuilderSectionRow, index: number) => {
        if (section.fields.length && sectionUpdateConfig.sectionId === section.sectionId && sectionUpdateConfig.dropAreaRowIndex === index) {
          let allowedToAddFieldInARow = maxFieldInARow > fields.length;
          if (allowedToAddFieldInARow) {
            fields.push(field);
          }
        }
        return fields;
      })

      return section;
    })
  }
}

export default FieldTypeToSectionField;
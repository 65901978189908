// import { fromJS } from 'immutable'

import { IFormDocument } from '../report/interface/form-document';
import { GET_DOCUMENTS, GET_DOCUMENTS_RETURN, GET_DOCUMENTS_ERROR, CLEAR_DOCUMENTS_ERROR } from './constant';

export interface ICalendarReducer {
  documentsList: IFormDocument[];
  documentsListLoading: boolean;
  documentsListError: string;
}

const initialState: ICalendarReducer = {
  documentsList: [] as IFormDocument[],
  documentsListError: '',
  documentsListLoading: false
}

const calendarReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_DOCUMENTS:
      newState.documentsListLoading = true;
      newState.documentsListError = '';
      newState.documentsList = [];
      return newState;

    case GET_DOCUMENTS_RETURN:
      newState.documentsListLoading = false;
      newState.documentsList = action.documentsList.filter((e: any) => e.statusName.toLowerCase() !== 'draft');
      return newState;

    case GET_DOCUMENTS_ERROR:
      newState.documentsListLoading = false;
      newState.documentsListError = action.message;
      return newState;

    case CLEAR_DOCUMENTS_ERROR:
      newState.documentsListError = '';
      return newState;

    default:
      return state;
  }
}

export default calendarReducer;
import React from 'react';
import { AcceptedProps } from './interface/accepted-props';
import TableTypeComponent from './table-type';

const TableElement: React.FC<AcceptedProps> = (props) => {
  const TableField = new TableTypeComponent();
  const tableType = props.inputConfig.config.tableType as string;
  const Component = TableField.getComponent(tableType)
  return <Component.Render {...props} />
}

export default TableElement;
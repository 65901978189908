import { IForm } from '../../../../admin-module/module/business-process/module/form/interface/form';
import { IField, IFieldAccessType } from '../../../../admin-module/module/users/interface/field';
import { IFieldLayoutObject, IFieldsLayout } from '../../../../admin-module/module/business-process/module/form/interface/form';
import { IFormSection, TFormSectionRow } from '../interface/section';
import { v4 as uuid } from 'uuid';
import { IDocumentAction, IDocumentDetail, IDocumentDetailAction } from '../interface/document-action';
import ValidateField from '../../../../../common/field/validation';
import { FieldConstruct } from '../../../../../common/field/field-construct';
import { FormEnumLabel } from './manage-enum-id-label';
import { IGetIndividualUserDetailInput } from '../interface/input/individual-user-get';
import { IDocumentSaveDetailInput } from '../interface/input/document-save';
import { UploadedFile } from '../../../../../common/api-request/upload';
import ConfigService from '../../../../../common/config';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';
import { IFormDocument } from '../../report/interface/form-document';
import { IConstraint } from '../../../../admin-module/module/business-process/module/roles/interface/constraint';

interface IFormAdditionalPopupUtils {
  dispatch: any;
  hasPageChangeRef: any;
  formFieldWithFieldPermission: IField[];
  dynamicRefs: any;
  documentAction: IDocumentAction[];
  formField: IField[];
  formSaveCount: number;
  company: string;
  formId: string;
  currentDocumentId: string;
  setSelectedDocumentAction: (value: React.SetStateAction<IDocumentAction>) => void;
  setFormSaveCount: (value: React.SetStateAction<number>) => void;
  saveWithoutChangeOnNewDocumentAction(): void;
  getIndividualUsersListAction(payload: IGetIndividualUserDetailInput): void;
  isDocumentNew?: boolean;
  documentId?: string;
  message?: { [key: string]: string };
  valueFieldReload?: IField[];
  saveDocumentErrorAction?(message: string): void;
  setFormFieldWithFieldPermission?: (value: React.SetStateAction<IField[]>) => void;
  closeDuplicateCheckerModal?: (loading?: boolean | undefined) => void;
  closeDuplicateChecker?: boolean;
  setSelectedAssignee: (value: React.SetStateAction<{}>) => void;
  saveDocument: (action: IDocumentAction, input: { [lable: string]: any; }) => void;
  setDocumentInput?: React.Dispatch<React.SetStateAction<{
    [label: string]: any;
  }>>
}

interface IFormSaveUtils {
  formFieldWithFieldPermission: IField[];
  dynamicRefs: any;
  formField: IField[];
  currentDocumentId: string;
  formId: string;
  company: string;
  dispatch: any;
  documentId: string;
  formSaveCount: number;
  setFormSaveCount: (value: React.SetStateAction<number>) => void;
  saveDocumentAction(payload: IDocumentSaveDetailInput): void;
  setSelectedDocumentAction: (value: React.SetStateAction<IDocumentAction>) => void;
  valueFieldReload?: IField[];
  setSubmittedData?: (value: React.SetStateAction<IDocumentDetail>) => void
  setFormFieldWithFieldPermission?: (value: React.SetStateAction<IField[]>) => void;
}

export const formatFormSectionList = (formField: IField[], formDetail: IForm, constraintTemplateList: IConstraint[]) => {
  formField = formField.filter(e => e.configName !== IConfigName.Seq);
  let newSectionList: IFormSection[] = [];
  if (formField.length && formDetail && formDetail.fieldsLayout) {
    let fieldNotExistInSection = [...setFieldConstraint(formField, constraintTemplateList)];
    newSectionList = formDetail.fieldsLayout.map((fieldLayout: IFieldsLayout): IFormSection => {
      const sectionField = fieldLayout.body.map((body: IFieldLayoutObject): IField[] => {
        return body.fields
          .filter((fieldId: string) => {
            const field = formField.filter((customField: IField) => customField.id === fieldId);
            return field.length > 0;
          })
          .map((fieldId: string) => {
            fieldNotExistInSection = fieldNotExistInSection.filter((field: IField) => field.id !== fieldId);
            const field = formField.filter((customField: IField) => customField.id === fieldId);
            return field[0] || {};
          })
      });
      return {
        sectionId: fieldLayout.id,
        title: fieldLayout.title,
        titleHidden: fieldLayout.header.fields.length > 0,
        titleFieldToShow: fieldLayout.header.fields,
        collapsable: fieldLayout.collapsable,
        fields: sectionField,
        additionalInfo: fieldLayout.description,
        style: fieldLayout.style || 'grouped'
      }
    });
    if (fieldNotExistInSection.length > 0) {
      let sectionFields: TFormSectionRow[] = [];
      let ctr = 0;
      let arrIndex = 0;
      fieldNotExistInSection.forEach((field: IField) => {
        ctr++;
        if (ctr === 1) {
          sectionFields[arrIndex] = [];
        }
        sectionFields[arrIndex].push(field);
        if (ctr === 4) {
          ctr = 0;
          arrIndex += 1;
        }
      })
      newSectionList.push({
        sectionId: uuid(),
        title: '',
        titleHidden: fieldNotExistInSection.length > 0,
        titleFieldToShow: [],
        collapsable: false,
        fields: sectionFields,
        additionalInfo: '',
        style: 'plain',
      })
    }
  }
  return newSectionList;
}

export const triggerOpenFormAdditionalPopup = (utils: IFormAdditionalPopupUtils, action: IDocumentAction, input?: { [label: string]: any }) => {
  if ((action.type === 'save' && !utils.hasPageChangeRef.current.hasChange) ||
    (utils.isDocumentNew && action.type === 'save' && !utils.hasPageChangeRef.current.hasChange)) {
    utils.dispatch(utils.saveWithoutChangeOnNewDocumentAction());
    return undefined;
  }
  if (utils.setDocumentInput && input) {
    utils.setDocumentInput(input);
  }
  const validateField = new ValidateField();

  const fieldsToValidate = validateField.fieldsToValidate(utils.formFieldWithFieldPermission);
  // eslint-disable-next-line
  const [isValid, fieldRefs, errorMessage] = validateField.validateForm(fieldsToValidate, { ...utils.dynamicRefs }, action.type);

  if ((!isValid && action.type !== 'save') || (!isValid && action.type === 'save' && !validateField.noErrorMessages(errorMessage))) {
    if (utils.saveDocumentErrorAction && utils.message) {
      utils.dispatch(utils.saveDocumentErrorAction(utils.message.formError));
    }
    utils.setFormSaveCount(utils.formSaveCount + 1);
    return undefined;
  }

  if (action.requiresInput) {
    const saveAction = utils.documentAction.find((action: IDocumentAction) => action.type === 'save');
    const fieldData = FieldConstruct.getFieldDataFromRefs(utils.formFieldWithFieldPermission, { ...utils.dynamicRefs }, undefined, action.type);
    const fields = FormEnumLabel.getEnumIdLabel(fieldData, { ...utils.dynamicRefs }, utils.formField);
    let dataForSubmit: IDocumentDetailAction = {
      input: input ? input : {},
      document: { id: utils.currentDocumentId, fields }
    };

    utils.dispatch(utils.getIndividualUsersListAction({
      actionId: action.id,
      companyId: utils.company,
      data: dataForSubmit,
      formId: utils.formId,
      saveActionId: saveAction ? saveAction.id : '',
      documentId: utils.isDocumentNew || !utils.documentId ? undefined : utils.documentId,
    }));
    utils.setSelectedDocumentAction(action);

    if (utils.setFormFieldWithFieldPermission && utils.valueFieldReload && utils.valueFieldReload instanceof Array) {
      const tempFormField = FieldConstruct.setValueToTableFieldReload([...utils.formFieldWithFieldPermission], fields, utils.valueFieldReload);
      utils.setFormFieldWithFieldPermission(tempFormField);
    }

    if (utils.closeDuplicateChecker && utils.closeDuplicateCheckerModal) {
      utils.closeDuplicateCheckerModal(true);
    }
    return;
  }

  utils.setSelectedAssignee({});
  utils.saveDocument(action, {});
}

export const triggerSaveDocument = (action: IDocumentAction, input: { [lable: string]: any }, utils: IFormSaveUtils) => {
  utils.setSelectedDocumentAction(action);
  const fieldData = FieldConstruct.getFieldDataFromRefs(utils.formFieldWithFieldPermission, { ...utils.dynamicRefs }, undefined, action.type);
  const fields = FormEnumLabel.getEnumIdLabel(fieldData, { ...utils.dynamicRefs }, utils.formField);
  let dataForSubmit: IDocumentDetailAction = {
    input: input,
    document: action.requiresInput
      ? { id: utils.currentDocumentId, fields: {} }
      : { id: utils.currentDocumentId, fields }
  };
  utils.dispatch(utils.saveDocumentAction({
    company: utils.company,
    formId: utils.formId,
    documentId: utils.documentId,
    actionId: action.id,
    data: dataForSubmit,
    actionType: action.type
  }));
  if (utils.setSubmittedData) {
    utils.setSubmittedData(dataForSubmit.document);
  }
  utils.setFormSaveCount(utils.formSaveCount + 1);

  if (utils.valueFieldReload && utils.valueFieldReload.length > 0 && utils.setFormFieldWithFieldPermission) {
    const tempFormField = FieldConstruct.setValueToTableFieldReload([...utils.formFieldWithFieldPermission], fields, utils.valueFieldReload);
    utils.setFormFieldWithFieldPermission(tempFormField);
  }
}

export const triggerOpenDocumentAttachment = (documentsAttachmentData: UploadedFile, isSystemGenerated: boolean) => {
  let url = `${ConfigService.loadConfig().general.siteUrl}/attachment/${documentsAttachmentData.id}`;
  if (isSystemGenerated) {
    window.open(documentsAttachmentData.url, '_blank');
  } else {
    sessionStorage.setItem('selectedAttachment', JSON.stringify(documentsAttachmentData));
    window.open(url, '_blank');
  }
}

export const setRowRefState = (tableRowRef: any, formField: IField[], data?: IFormDocument) => {
  if (formField.length > 0) {
    formField.forEach(field => {
      const name = field.id as string;
      const { config, type }: any = { ...field.inputConfig };
      if (type && type === 'table' && config && config.columns) {
        let maxRows = config.maxRows || 0;
        let minRows = config.minRows || 0;

        if (data && Object.keys(data).length > 0 && minRows !== maxRows) {
          const fieldData = data.fields ? data.fields[name] : {};
          let tableRows = tableFieldDataRowsCount(fieldData);
          if (tableRows < minRows) {
            tableRows = tableRows + (minRows - tableRows);
          }
          minRows = tableRows;
        }
        tableRowRef.current[name] = { minRows, maxRows, config };
      }
    });
  }
  return tableRowRef;
}

export const tableFieldDataRowsCount = (data: any): number => {
  if (!data) {
    return 0
  }
  const indexesWithValue = Object.keys(data).map(key => {
    const indexOfLastItemWithValue = data[key].reduce((acc: any, value: any, index: number) => (value ? index : acc), -1);
    return indexOfLastItemWithValue + 1;
  });
  return Math.max(...indexesWithValue);
}

export const manageFieldReload = (fieldState: IField[], updatedFormField: IField[], autopopulateFieldReload?: IField[], documentDetail?: IFormDocument) => {
  let tempField = [...fieldState];
  tempField = tempField.map((field: IField) => {
    let updateField = updatedFormField.find((upField: IField) => upField.id === field.id);
    if (updateField) {
      if (updateField.configName === IConfigName.FX) {
        const accessType = (updateField.accessType === IFieldAccessType.ReadWrite || updateField.accessType === IFieldAccessType.Readonly)
          ? IFieldAccessType.Readonly : updateField.accessType;
        updateField.accessType = accessType;
        if (documentDetail && updateField.inputConfig && updateField.inputConfig.config) {
          const data = { ...documentDetail.fields };
          const configValue = updateField.inputConfig && updateField.inputConfig.config && updateField.inputConfig.config.value;
          if (!configValue && data && data[field.id || '']) {
            updateField.inputConfig.config.value = data[field.id || ''];
          }
        }
      }
      return updateField;
    }
    return field;
  });
  if (autopopulateFieldReload) {
    tempField = tempField.map(field => {
      const autopopulateField = autopopulateFieldReload.find(e => e.id === field.id);
      if (autopopulateField) {
        field.accessType = autopopulateField.accessType;
      }
      return field;
    });
  }
  return {
    tempField,
    autopopulateFieldReload: autopopulateFieldReload ?
      autopopulateFieldReload.map(field => {
        const data = updatedFormField.find(e => e.id === field.id) as IField;
        if (data) { return data; }
        return field;
      })
      :
      autopopulateFieldReload
  };
}

const setFieldConstraint = (formField: IField[], constraintTemplateList: IConstraint[]) => {
  let tempFormField = [...formField];
  if (constraintTemplateList.length > 0) {
    tempFormField = tempFormField.map(field => {
      const { config } = { ...field.inputConfig };
      const fieldId = field.id as string;
      const isConstraint = constraintTemplateList.length > 0 ?
        constraintTemplateList.filter(e => e.documentKeyPath === `fields.${fieldId}`).length > 0 : false;
      if (!field.flags && isConstraint) {
        field.flags = ['constraint'];
        if (config && config.items.length === 1 && config.value) {
          field.accessType = IFieldAccessType.Readonly;
        }
      }
      if (isConstraint && field.flags && config && config.items.length === 1 && config.value) {
        field.accessType = IFieldAccessType.Readonly;
      }
      return field;
    });
  }
  return tempFormField;
}
export enum UserProfileStaticKey {
  BusinessAnalytic = 'ba',
  UsersManager = 'users_manager',
  Admin = 'admin',
  EndUser = 'end_user',
  BPMManager = 'bpm_manager',
}

export interface UserProfile {
  id: string;
  name: string;
  permissions: string[];
  permissionsExtra: string[];
  staticKey: UserProfileStaticKey;
}

import { IutilProps } from '../..';
import { getReferencedFieldLstAction } from '../../../../../../../../../../../../common/custom-field-config/filter-configuration/action';
import { ICustomFieldDetail } from '../../../../../../../../../../../../common/custom-field-config/interface/custom-field-detail';
import { IConfigName } from '../../../../../../../../../../../../common/field/type/interface/field-type-object';
import { IReference } from '../../../../../../../configuration/module/bp-relationship/interface/form';
import { IFormStatusWithAction } from '../../../../../../../workflow/interface/form-status';
import { getDatatableInputFieldAction, getInputFieldAction } from '../../../../action';
import { IAutomationComponent } from '../../../../interface/component-list';

export class FieldCondition {
  static getOptions = (selectedComponent: IAutomationComponent, utils: IutilProps) => {

    const sourceRefField = selectedComponent?.config?.sourceRefField;
    const sourceField = selectedComponent?.config?.sourceField;

    if (sourceField !== '') {
      const fieldConfig = utils.formFieldList.find((e: ICustomFieldDetail) => e.id === sourceField);
      if (fieldConfig && fieldConfig.configName === IConfigName.Autopopulated) {
        const config = fieldConfig.config as any;
        const sourceField = utils.formFieldList.find((e: ICustomFieldDetail) => e.id === config.sourceField);
        const formId = sourceField?.config?.data.formId;
        utils.dispatch(getReferencedFieldLstAction({
          formId: formId,
          dataType: 'document',
          company: utils.company,
          databaseId: ''
        }));
      }
    }

    if (sourceRefField !== '') {
      let bpReference = '';
      const fieldConfig = utils.formFieldList.find((e: ICustomFieldDetail) => e.id === sourceRefField);
      if (fieldConfig) {
        bpReference = fieldConfig.config?.data?.databaseId || fieldConfig.config?.data?.formId;
      }
      if (!bpReference) {
        return;
      }

      const selectedRef = utils.referenceList.find((ref: IReference) => ref.id === bpReference);
      if (selectedRef) {
        if (selectedRef.dataType === 'document' && !utils.inputFieldList[selectedRef.id]) {
          utils.dispatch(getInputFieldAction({
            companyId: utils.company,
            formId: selectedRef.id,
            statusId: ''
          }))
        }
        if (selectedRef.dataType === 'database' && !utils.datatableInputField[selectedRef.id]) {
          utils.dispatch(getDatatableInputFieldAction({
            companyId: utils.company,
            formId: selectedRef.id,
            statusId: ''
          }))
        }
      }
    }
  }
  static setStatusFieldItems = (statusField: any[], formStatusList: IFormStatusWithAction[]) => {
    return statusField.map((e: any) => {
      if (e.config) {
        e.config.data = formStatusList.map((e: IFormStatusWithAction) => { return { id: e.id, label: e.name } });
        e.config.multiselect = false;
      } else {
        if (e.inputConfig && e.inputConfig.config) {
          e.inputConfig.config.items = formStatusList.map((e: IFormStatusWithAction) => { return { id: e.id, label: e.name } });
        }
      }
      return e;
    });
  }
}
import React from 'react';
import { Button } from 'semantic-ui-react-bpm';
import { IButtonListProp } from '../../admin-module/utils/header-menu-button/interface/button-list-props';
import { IButtonList } from '../../admin-module/utils/header-menu-button/interface/button-list';

export class PortalButtonList implements IButtonList {

  name = 'default';

  Render: React.FC<IButtonListProp> = (props) => {

    return <>
      <div id='route-container' >
        {
          props.modules.map(({ id, name }, key) => {
            return (
              <React.Fragment key={key}>
                <Button onClick={() => {
                  props.history.push(`/portal/${id}`)
                }} className={`portal-item-menu ${id.toLowerCase() === props.main.subRoute.toLowerCase() ? 'active-portal-subroute' : ''}`}>{name}</Button>
              </React.Fragment>
            )
          })
        }
      </div>
    </>
  }
}

import { IApiColumnView } from '../../configuration/module/document-list/interface/custom-view';
import { saveDraftColumnViewAction, publishColumnViewAction } from '../../configuration/module/document-list/action';

interface IUpdateConfigUtils {
  formId: string;
  companyId: string;
  dispatch: any;
  deletedExistingField: string[];
  columnViewList: IApiColumnView[];
}

export class CheckFieldColumn {

  static updateConfig = (utils: IUpdateConfigUtils) => {
    const updatedReportsColumnConf = CheckFieldColumn.removeFieldFromColumnConfig(
      utils.deletedExistingField,
      utils.columnViewList
    );
    if (updatedReportsColumnConf) {
      utils.dispatch(saveDraftColumnViewAction({
        company: utils.companyId,
        formId: utils.formId,
        customViewList: updatedReportsColumnConf as any,
        saveType: 'save'
      }));
      utils.dispatch(publishColumnViewAction({
        company: utils.companyId,
        formId: utils.formId,
      }));
    }
  }

  static removeFieldFromColumnConfig = (deletedFields: string[], columnViewList: IApiColumnView[]) => {
    const fieldsUsedInColumn = CheckFieldColumn.hasDeletedFieldUsedInColumn(deletedFields, columnViewList);
    if (fieldsUsedInColumn.length === 0) {
      return null;
    }
    return CheckFieldColumn.updateColumnConfig(deletedFields, columnViewList);
  }

  static hasDeletedFieldUsedInColumn = (deletedFields: string[], columnViewList: IApiColumnView[]) => {
    return columnViewList.filter(column => {
      if (column && column.keyPath) {
        const deleted = deletedFields.find(id => `fields.${id}` === column.keyPath);
        if (deleted) {
          return true;
        }
      }
      return false;
    })
  }

  static updateColumnConfig = (deletedFields: string[], columnViewList: IApiColumnView[]) => {
    return columnViewList.filter(column => {
      return deletedFields.filter(id => `fields.${id}` === column.keyPath).length === 0;
    });
  }
}
import React, { useEffect, useState } from 'react';
import { Icon, Image } from 'semantic-ui-react-bpm';
import { IField } from '../interface/field';
import { icons } from '../../../../../common/icons';
import ToolTip from '../../../../../common/general/tooltip';
// import DownloadPopup from '../../../../../common/general/download-popup';
import { IFilterData } from '../user';
import { IOption } from '../../../../../common/interface/option';
import { IConfigName } from '../../../../../common/field/type/interface/field-type-object';

interface IHeaderProps {
  staticField: IField[];
  dynamicField: IField[];
  openUpdateCustomFieldModal: any;
  filterData: IFilterData;
  companyId: string;
  setIsOpenFileUploadModal(value: boolean): void;
  hasFileUpload: boolean;
  allowedCreateCustomField: boolean;
  userTypeOption: IOption[];
}

const TableHeaderAction: React.FC<IHeaderProps> = (props) => {
  const [, setFilterData] = useState({} as IFilterData);

  useEffect(() => {
    let newFilterData = { ...props.filterData };
    if (props.userTypeOption) {
      if ((!props.filterData || !props.filterData.filters)) {
        newFilterData.filters = {
          profile: props.userTypeOption.map(e => e.value)
        }
      }
      if (props.filterData && props.filterData.filters && !(props.filterData.hasOwnProperty('profile'))) {
        newFilterData.filters = {
          ...props.filterData.filters,
          profile: props.userTypeOption.map(e => e.value)
        }
      }
    }
    setFilterData(newFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData, props.userTypeOption])

  return <tr className='header-action'>
    <th className='disabled th-top-user-info'>
      <ToolTip position='bottom left' popupMessage={'Hidden Field'} popupTriggerComponent={<Image style={{ maxWidth: 'unset !important' }} width='16px' src={icons.other.eyeRed} />} />
    </th>
    {
      props.staticField && props.staticField.map((detail: IField) => {
        const permissionToolTipMessage = detail && detail.permission && detail.permission.name === 'green' ? 'Public Visibility'
          : detail && detail.permission && detail.permission.name === 'orange' ? 'User Profile Visibility' : 'Hidden Field';

        const permissionIcon = detail && detail.permission && detail.permission.name === 'green' ?
          <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeGreen} /> : detail && detail.permission && detail.permission.name === 'orange' ?
            <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeOrange} /> :
            <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeRed} />

        return <th key={`header-cell-1-${detail.id}`} className='disabled th-top-user-info' >
          {detail.permission &&
            <>
              <ToolTip position='bottom center' popupMessage={permissionToolTipMessage}
                popupTriggerComponent={permissionIcon} />
              {detail.searchable &&
                <ToolTip position='bottom center' popupMessage={'Searchable field'}
                  popupTriggerComponent={<Image src={icons.black.magnifier} width='14px' />} />
              }
            </>
          }
        </th>
      })
    }
    <th className='disabled th-top-user-info' >
    </th>
    <th className='disabled th-top-user-info' >
    </th>
    {
      props.dynamicField && props.dynamicField.map((detail: any) => {
        const permissionToolTipMessage = detail.permission.name === 'green' ? 'Public Visibility'
          : detail.permission.name === 'orange' ? 'User Profile Visibility' : 'Hidden Field';

        const permissionIcon = detail.permission.name === 'green' ?
          <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeGreen} /> : detail.permission.name === 'orange' ?
            <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeOrange} /> :
            <Image style={{ top: '-1px' }} width='16px' src={icons.other.eyeRed} />

        return <th key={`header-cell-2-${detail.id}`} className='th-top-user-info-other' >
          {detail.permission &&
            <>
              <ToolTip position='bottom center' popupMessage={permissionToolTipMessage}
                popupTriggerComponent={permissionIcon} />
              {(detail.configName === IConfigName.Email
                || detail.configName === IConfigName.TextString
                || detail.configName === IConfigName.URL
                || detail.configName === IConfigName.Referenced) && detail.searchable &&
                <ToolTip position='bottom center' popupMessage={'Searchable field'} popupTriggerComponent={<Image src={icons.black.magnifier} width='14px' />} />
              }
              {detail.configName === IConfigName.Dropdown && detail.searchable &&
                <ToolTip position='bottom center' popupMessage={'Filter Enabled field'}
                  popupTriggerComponent={<Image width='14px' src={icons.black.filterOutline} />}
                />
              }
            </>
          }
          {props.allowedCreateCustomField &&
            <ToolTip position='bottom center'
              popupMessage={'Custom Field Settings'}
              popupTriggerComponent={
                <Icon name='ellipsis vertical' link onClick={() => props.openUpdateCustomFieldModal(detail.id)
                } />} />
          }
        </th>
      })
    }
    <th className='download'>
      {/* <DownloadPopup
        downloadLink={`/${props.companyId}/users/search?${props.filterData && props.filterData.globalSearch ? `globalSearch=${props.filterData.globalSearch}&` : ''}load=profiles&limit=0&export=xlsx`}
        filter={{ filters: filterData && filterData.filters ? filterData.filters : {} }}
        method='post'
        hasFileUpload={props.hasFileUpload}
        dataFileUpload={() => props.setIsOpenFileUploadModal(true)}
      /> */}
    </th>
  </tr>
}

export default TableHeaderAction;
import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../reducers';
import { useRouteMatch } from 'react-router-dom';
import Axios from 'axios';
import { SendToSqsPluginConfig } from './interface/sqs-plugin-config';
import ButtonContainer from '../../../../button-container';
import { AlertModal } from '../../../../../../../../common/general/alert-modal';
import { Button, Form, Icon } from 'semantic-ui-react-bpm';
import { IOption } from '../../../../../../../../common/interface/option';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const SqsPlugin: React.FC = () => {
  const { company } = useSelector((state: IRootState) => state.auth);
  const match: { params: { formId: string } } = useRouteMatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [config, setConfig] = useState({} as SendToSqsPluginConfig);
  const [statuses, setStatuses] = useState([] as IOption[]);

  const [triggerStatus, setTriggerStatus] = useState('');
  const [switchToStatus, setSwitchToStatus] = useState('');

  const [exampleUrn, setExampleUrn] = useState('');
  const [exampleText, setExampleText] = useState('');
  const [code, setCode] = useState<any>(undefined);

  const [sqsFormExpand, setSqsFormExpand] = useState(false);

  const editor = useRef(undefined as any | undefined);

  const getCurrentConfig = async () => {
    const formId = match.params.formId;
    if (!formId || !company) {
      return;
    }
    const settings = await Axios.get(`${company}/forms/${formId}/plugins/com.bpm.send-to-sqs?draft=true`);
    const conf = settings.data?.data?.config;
    if (conf) {
      setConfig(conf);
      setCode(conf.template);
      editor.current?.setValue(conf.template || "");
      setTriggerStatus(conf.triggerStatusId || '')
      setSwitchToStatus(conf.successStatusId || '')

      console.log(`Editor text ste`, editor);
    }


    const statuses = await Axios.get(`${company}/forms/${formId}/statuses?draft=false`);
    const options: IOption[] = statuses.data.data.map((s: any, index: number) => {
      return {
        key: index,
        text: s.name,
        value: s.id
      }
    })
    console.log(`options:`, conf);

    setStatuses(options);
  }

  const writeUiToConfig = () => {
    config.template = code || '';
    config.triggerStatusId = triggerStatus.length > 0 ? triggerStatus : undefined;
    config.successStatusId = switchToStatus.length > 0 ? switchToStatus : undefined;

  }

  const saveCurrentConfig = async () => {
    const formId = match.params.formId;
    if (!formId || !company) {
      return;
    }
    writeUiToConfig()

    try {
      await Axios.put(`${company}/forms/${formId}/plugins/com.bpm.send-to-sqs`, config);
      setSuccessMessage('Saved');
    } catch (e) {
      const error = e as any;
      setErrorMessage(error.response?.data?.message || 'Unknown Error');
      setSuccessMessage('');
    }
  }

  const publishForm = async () => {
    const formId = match.params.formId;
    try {
      await Axios.post(`${company}/forms/${formId}/publish`);
      setSuccessMessage('Published');
    } catch (e) {
      const error = e as any;
      setErrorMessage(error.response?.data?.message || 'Unknown Error');
      setSuccessMessage('');
    }
  }

  const tryRenderConfig = async () => {
    const formId = match.params.formId;
    writeUiToConfig()
    try {
      const result = await Axios.post(`${company}/forms/${formId}/plugins/com.bpm.send-to-sqs/try`, {
        config: config,
        documentUrn: exampleUrn,
        input: {
          type: 'render'
        }
      });
      setExampleText(result.data.data);
    } catch (e) {
      const error = e as any;
      setExampleText(error.response?.data?.message || 'Unknown Error');
    }
  }

  const trySendConfig = async () => {
    const formId = match.params.formId;
    writeUiToConfig()
    try {
      const result = await Axios.post(`${company}/forms/${formId}/plugins/com.bpm.send-to-sqs/try`, {
        config: config,
        documentUrn: exampleUrn,
        input: {
          type: 'send'
        }
      });
      setExampleText(result.data.data);
    } catch (e) {
      const error = e as any;
      setExampleText(error.response?.data?.message || 'Unknown Error');
    }
  }

  const handleEndpointChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const endpoint: any = { ...config?.endpoint };
    endpoint[key] = e.target.value;
    setConfig({ ...config, endpoint: { ...endpoint } });
  }

  const openInNewTab = () => {
    const winURL = URL.createObjectURL(
      new Blob([exampleText], { type: 'text/html' })
    );
    window.open(winURL, '_blank');
  }

  useEffect(() => {
    getCurrentConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<div className={`sqs-main-container`}>
    <div className='sqs-container'>
      <div className='sqs-form-container'>
        <div className='sqs-header' onClick={() => setSqsFormExpand(!sqsFormExpand)}>
          <label>SQS Endpoint</label>
          <i className={`angle ${sqsFormExpand ? 'up' : 'down'} icon`} />
        </div>
        <Form size='small' className={`form-container ${!sqsFormExpand ? 'hidden' : ''}`}>
          <Form.Group widths='equal'>
            <div className={`six wide field`}>
              <label>Access Key</label>
              <div className={`ui input`}>
                <input value={config?.endpoint?.accessKey || ''} onChange={(e) => handleEndpointChange(e, 'accessKey')} />
              </div>
            </div>
            <div className={`six wide field`}>
              <label>Secret Key</label>
              <div className={`ui input`}>
                <input value={config?.endpoint?.secretKey || ''} onChange={(e) => handleEndpointChange(e, 'secretKey')} />
              </div>
            </div>
            <div className={`six wide field`}>
              <label>Queue URL</label>
              <div className={`ui input`}>
                <input value={config?.endpoint?.queueUrl || ''} onChange={(e) => handleEndpointChange(e, 'queueUrl')} />
              </div>
            </div>
          </Form.Group>
          <Form.Group widths='equal'>
            <div className={`six wide field`}>
              <label>Region</label>
              <div className={`ui input`}>
                <input value={config?.endpoint?.region || ''} onChange={(e) => handleEndpointChange(e, 'region')} />
              </div>
            </div>
            <Form.Select
              clearable
              search
              label='Trigger on status:'
              placeholder='Trigger on status'
              selectOnBlur={false}
              value={triggerStatus}
              onChange={(e, target: any) => setTriggerStatus(target.value as string)}
              options={statuses}
              width='6'
              selection
            />
            <Form.Select
              clearable
              search
              label='Set status after submitting:'
              placeholder='Set status'
              selectOnBlur={false}
              value={switchToStatus}
              onChange={(e, target: any) => setSwitchToStatus(target.value as string)}
              options={statuses}
              width='6'
              selection
            />
          </Form.Group>
        </Form>
      </div>
      <div className='sqs-form-container'>
        <div className='sqs-header'>
          <label>Document Mapping Template</label>
        </div>
      </div>
      <div className='template-trigger'>
        <label>Try how template renders document. Set example document URN in field and press "Render"</label>
        <div className='fields'>
          <div className={`ui input`}>
            <input placeholder="URN" value={exampleUrn} onChange={(e) => setExampleUrn(e.target.value)}></input>
          </div>
          <Button onClick={() => tryRenderConfig()}>Render Template</Button>
          <Button onClick={() => trySendConfig()}>Send to Queue</Button>
        </div>
      </div>
      <div className='doc-mapping-container' style={styles.templateContainer}>

        <AceEditor
          mode="javascript"
          theme="tomorrow"
          fontSize={16}
          showGutter={true}
          highlightActiveLine={true}
          onChange={(value) => {
            setCode(value);
          }}
          placeholder='Paste or type your data here...'
          width='100%'
          height='calc(100vh - 460px)'
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 4,
            printMargin: 0,
          }} />
        <div>
          {exampleText !== '' &&
            <div className='icon-group'>
              <Icon name='expand arrows alternate' onClick={openInNewTab} title="Open in New Window" />
            </div>
          }
          <iframe
            style={{ width: '100%', height: '100%', borderStyle: 'groove' }}
            title='iFrameMD'
            src="data:text/html;charset=utf-8,"
            srcDoc={exampleText}
            placeholder='HTML Output'
          />
        </div>
      </div>
    </div>
    <ButtonContainer
      cancel={() => { }}
      save={() => saveCurrentConfig()}
      publish={() => publishForm()}
    />
    <AlertModal
      open={errorMessage !== ''}
      close={() => setErrorMessage('')}
      message={errorMessage}
      type='error'
    />
    <>{
      successMessage && <p>{successMessage}</p>
    }</>
  </div >);
}


const styles: { [key: string]: CSSProperties } = {
  endpointConfig: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px'
  },
  templateEditor: {
    width: '100%',
  },
  templateContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: 'calc(100vh - 460px)',
    gridColumnGap: '5px',
    minHeight: '460px'
  },
  templateExampleDiv: {
    overflow: 'scroll',
    flexGrow: 1,
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  }
}

export default SqsPlugin;

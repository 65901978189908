import React from 'react';
import { IFieldData } from './interface/field-data';
import { FieldElement } from '../component/field-element';
import { IField, IInputConfig } from '../../../component/admin-module/module/users/interface/field';
import { v4 as uuid } from 'uuid';
import DynamicField from '.';
import { IConfigName } from '../type/interface/field-type-object';

interface IArrayField {
  data: IFieldData;
  detail: any;
}

const ArrayField: React.FC<IArrayField> = (props) => {
  const items: any = props.detail.inputConfig.config.items;
  let tempField: IField = {
    id: props.detail.id,
    inputConfig: { ...items } as IInputConfig,
    label: props.detail.label,
    validationSchema: { ...props.detail.validationSchema },
    defaultValue: props.detail.defaultValue,
    accessType: props.detail.accessType,
    configName: props.detail.configName || IConfigName.None
  };
  const field = FieldElement.getDataElement(tempField);
  return <DynamicField.Render
    key={`column-${field.id}-${props.data.id}-${uuid()}`}
    data={props.data as IFieldData}
    detail={field}
    multiline={true}
  />
}
export default ArrayField;
import { GET_DRAFT_LIST, GET_DRAFT_LIST_RETURN } from './constant';
import { IDraftGetDetailInput } from './interface/input/draft-get';
import { IDraftGetResponse } from './interface/response/draft-get';

export const getDraftListAction = (payload: IDraftGetDetailInput) => {
  return { type: GET_DRAFT_LIST, ...payload }
}

export const getDraftListReturnAction = (payload: IDraftGetResponse) => {
  return { type: GET_DRAFT_LIST_RETURN, ...payload }
}
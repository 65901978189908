import React from 'react';
import { AcceptedProps } from '../../object/interface/accepted-props';
import SingleNumber from './single';

const NumberType: React.FC<AcceptedProps> = (props) => {
  if (props.multiline) {
    return <>Number Array</>
  }
  return <SingleNumber {...props} />
}

export default NumberType;
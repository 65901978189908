import moment from 'moment';
import { verifyIfStringIsJSON } from './verify-json-string';

interface IRange {
  from: any,
  to: any
}

const splitUUIDValue = (object: any) => {
  object = object.split('.')[1];
  return object;
}

const convertDateToUTC = (date: Date, type?: 'startOf' | 'endOf' | 'dateOnly') => {
  const isDateValid = moment.utc(date).isValid();
  date = new Date(date);
  let utcFormatDate = moment(date).utc().format();
  if (type === 'startOf') utcFormatDate = moment(date).utc().startOf('day').format();
  if (type === 'endOf') utcFormatDate = moment(date).utc().endOf('day').format();
  if (type === 'dateOnly') utcFormatDate = moment(date).format("YYYY-MM-DD");
  return { valid: isDateValid, utcFormatDate: utcFormatDate }
}

const convertTimeToUTC = (timeValue: Date) => {
  let dateToday = moment().format("YYYY-MM-DD");
  let time = new Date(dateToday + ' ' + timeValue);
  const isValid = moment(time).isValid();
  return { valid: isValid, formatTime: timeValue }
}

const isValidNumber = (value: string) => {
  let regExStr = '^-*\\d*$';
  const regex = RegExp(regExStr, 'i');
  return regex.test(value);
}

const checkRefValueType = (refValue: any) => {
  const parsedDate = new Date(refValue);

  var dateFormats = ["YYYY-MM-DD LT", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD"];
  var timeFormats = ["h:mm:ss A", "HH:mm:ss", "HH:mm"];
  let dateToday = moment().format("YYYY-MM-DD");
  let time = new Date(dateToday + ' ' + refValue);
  let regUUId = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;

  return {
    isNumber: isValidNumber(refValue),
    isBoolean: refValue === 'true' || refValue === 'false' ? true : false,
    isDate: typeof refValue === 'string' && parsedDate.toString() !== 'Invalid Date'
      && moment(refValue, dateFormats, true).isValid(),
    isUUID: regUUId.test(refValue),
    isTime: typeof refValue === 'string' && moment(time, timeFormats, true).isValid(),
    isDateTime: typeof refValue === 'string' && parsedDate.toString() !== 'Invalid Date'
      && moment(refValue, moment.defaultFormatUtc, true).isValid()
  }
}

const setFieldRangeValue = (rangeObject: IRange, rangeType: 'from' | 'to', fieldType: string, value: any) => {
  if (checkRefValueType(value).isDate && fieldType === 'date') {
    rangeObject[rangeType] = convertDateToUTC(value).valid ?
      convertDateToUTC(value, 'dateOnly').utcFormatDate : undefined;
  }
  if (checkRefValueType(value).isTime && fieldType === 'time') {
    rangeObject[rangeType] = convertTimeToUTC(value).valid ?
      convertTimeToUTC(value).formatTime : undefined;
  }
  if (checkRefValueType(value).isNumber && fieldType === 'number') {
    rangeObject[rangeType] = parseInt(value);
  }
  return rangeObject;
}

const getFieldRangeValue = (refObject: any, dynamicRefs: any) => {
  let rangeObject = {} as IRange;
  Object.keys(dynamicRefs.current).forEach((key) => {
    if (splitUUIDValue(refObject) === splitUUIDValue(key)) {
      if (dynamicRefs.current[key] && dynamicRefs.current[key].value) {
        const rangeKey = key.indexOf('From.') > -1 ? 'from' : 'to';
        const fieldType = dynamicRefs.current[key].fieldType;
        rangeObject = setFieldRangeValue(rangeObject, rangeKey, fieldType, dynamicRefs.current[key].value);
      }
    }
  });
  return rangeObject;
}

const getSingleFieldValue = (refValue: any, type?: string) => {
  let value: any = refValue;
  if (checkRefValueType(refValue).isBoolean && type === 'bool') {
    if (refValue === 'true') {
      value = true;
    }
    if (refValue === 'false') {
      value = false;
    }
  }
  if ((typeof refValue === 'string' && checkRefValueType(refValue).isUUID) || type === 'enum') {
    value = new Array(refValue);
  }
  if (checkRefValueType(refValue).isDate && type === 'date') {
    let convertDate = moment(refValue);
    value = convertDate.isValid() ? convertDate.format("YYYY-MM-DD") : undefined;
  }
  if (checkRefValueType(refValue).isNumber && type === 'number') {
    value = parseInt(refValue);
  }
  if (checkRefValueType(refValue).isTime && type === 'time') {
    let utcTime = convertTimeToUTC(refValue);
    value = utcTime.valid ? utcTime.formatTime : undefined;
  }
  if (type === 'string') {
    value = value + '';
  }

  return value
}

export const formatFilterValues = (dynamicRefs: any, type: 'User' | 'Documents' | 'Groups') => {
  let filterData: any = {}
  let globalSearchValue: any = '';

  Object.keys(dynamicRefs.current).forEach((key) => {
    if (dynamicRefs.current[key] && dynamicRefs.current[key].value) {
      let dynamicRefsValue: any = undefined;
      let filterIDs: any = '' as string;
      let keyPrefix = type === 'User' || type === 'Groups' ? 'fields.' : '';

      if (key === 'search') {
        globalSearchValue = dynamicRefs.current[key].value ? dynamicRefs.current[key].value : undefined;
        return;
      }

      filterIDs = checkRefValueType(key).isUUID ? `${keyPrefix}${key}` : key;
      dynamicRefsValue = getSingleFieldValue(dynamicRefs.current[key].value, dynamicRefs.current[key].fieldType);

      if (type === 'Documents') {
        if (key.indexOf('From.') > -1 || key.indexOf('Until.') > -1) {
          dynamicRefsValue = getFieldRangeValue(key, dynamicRefs);
          let objectID = splitUUIDValue(key);

          if (checkRefValueType(objectID).isUUID) {
            filterIDs = `filters.${splitUUIDValue(key)}`;
          } else {
            filterIDs = splitUUIDValue(key);
          }
        }
      }

      if (verifyIfStringIsJSON(dynamicRefsValue)) {
        dynamicRefsValue = JSON.parse(dynamicRefsValue);
      }

      if (dynamicRefsValue instanceof Array && dynamicRefsValue.length === 0) return;
      if (dynamicRefsValue instanceof Object && Object.keys(dynamicRefsValue).length === 0) return;
      if (typeof dynamicRefsValue === 'undefined') return;

      filterData[filterIDs] = dynamicRefsValue;
    }
  });

  return {
    filters: filterData,
    globalSearch: globalSearchValue
  }
}

import prettyBytes from 'pretty-bytes';
import React, { useEffect, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import useDeviceDetect from './device-detect';
import { icons } from '../icons';
import SVGwrapper from '../icons/svg/svg-wrapper';
import { readString } from 'react-papaparse'
import { IField } from '../../component/admin-module/module/users/interface/field';
interface ITableFileUpload {
  open?: boolean;
  close: Function;
  uploadDataToTable: Function;
  tableFields: IField[],
  tableLabel: string;
}

const acceptedFileFormat = '.csv';

const TableFileUpload: React.FC<ITableFileUpload> = (props) => {
  const { width } = useDeviceDetect();
  const [file, setFile] = useState({} as any | null);
  const { acceptedFiles, getInputProps } = useDropzone();

  const iconForFile = (file: any) => {
    if (file.type.startsWith('image')) {
      return URL.createObjectURL(file);
    } else {
      return icons.black.paperclip;
    }
  }

  const removeUploadFile = (file: any) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFile({});
  }

  const toggleModal = () => {
    setFile({});
    props.close();
  }

  const csvJSON = (csv: string): any => {
    const parseResult = readString(csv).data as any;
    const headers = parseResult[0] as any;
    let result: {}[] = [];
    parseResult.slice(1).forEach((value: any) => {
      let tempValue = {}
      if (value.length === headers.length) {
        value.forEach((e: string, idx: number) => {
          tempValue = { ...tempValue, [headers[idx]]: e }
        });
        result.push(tempValue);
      }
    })
    return result;
  }

  const onUpload = () => {
    if (Object.keys(file).length > 0) {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          props.uploadDataToTable(csvJSON(reader.result));
          toggleModal();
        }
      }
      reader.readAsText(file);
    }
  }

  const download = (fileName: string, urlData: string) => {
    const aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
    aLink.remove();
  }

  const downloadTemplate = () => {
    const tableHeaders = props.tableFields.map((e) => e.label).join(',')
    download(`${props.tableLabel}-template.csv`, 'data:text/csv;charset=UTF-8,' + encodeURIComponent(tableHeaders));
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line
  }, [acceptedFiles[0]])

  return <>
    {props.open &&
      <div id='file-upload-modal-dimmer' className="ui page modals dimmer transition visible active">
        <div id="file-upload-modal" className={`ui modal transition visible active ${width <= 480 ? 'mobile' : ''}`}>
          <div className="borderless header">
            <span className="header-label">Upload File</span>
          </div>
          <div className="content">
            <Dropzone
              multiple={false}
              accept={acceptedFileFormat}
              onDrop={acceptedFiles => {
                if (acceptedFiles.length > 0) {
                  setFile(acceptedFiles[0])
                }
              }}
              noClick
            >
              {({ getRootProps }) => (
                <section className='dropzone-main-container'>
                  <div className='dropzone-container' {...getRootProps()}>
                    {Object.keys(file).length > 0 ?
                      <span className='file-selected'>
                        <img className='file-icon' src={iconForFile(file)} alt="" />
                        <label className='file-name'>{file.name}</label>
                        <p className='file-size'> ({prettyBytes(file.size)})</p>
                        <i className={`icon close`} onClick={() =>
                          removeUploadFile(file)
                        } />
                      </span>
                      :
                      <div className='file-icon-upload'> <SVGwrapper iconName='uploadCloud' width='75' height='75' color='#000' /> </div>
                    }
                    <span className='file-upload-label'>Drag and drop your files anywhere or</span>
                    <input id='file-upload' {...getInputProps()} accept={acceptedFileFormat} />
                    <label htmlFor='file-upload'>
                      <div className='file-upload-btn'>Upload a file</div>
                    </label>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="actions borderless">
            <div onClick={() => downloadTemplate()} className='btn-download-template'>Download Template</div>
            <div onClick={() => toggleModal()} className='btn-cancel'>Cancel</div>
            <div className={`btn-upload ${Object.keys(file).length > 0 ? '' : 'disabled'}`} onClick={() => onUpload()}
            >Upload</div>
          </div>
        </div>
      </div>
    }
  </>
}

export default TableFileUpload;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Container, Icon, Image, Menu } from 'semantic-ui-react-bpm';
// import { icons } from '../../../../common/icons';
import changeRoute from '../../../../common/utils/change-menu';
import AllowedUserListActionControl from '../../../../common/utils/user-list-action-control';
import { IRootState } from '../../../../reducers';
import { getBusinessProcessAction } from '../../../admin-module/module/business-process/module/form/action';
// import { settingsMenuList } from '../../../admin-module/module/settings/constant';
import { AUTH_LOGOUT } from '../../../auth/constant';
import { UserProfileStaticKey } from '../../../auth/interface/user-profile';
import { menuList } from '../../constant';
import { IHasPageChangeRef } from '../../interface/has-page-change';
// import { getFilters } from './hexToCssFilter';
import UserAvatar from './user-avatar';

const FormNameBreadcrumb = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: IRootState) => state.auth);
  const { getFormDetailData } = useSelector((state: IRootState) => state.businessProcess);
  const match: { params: { adminModule: string, formType: string, formId: string } } = useRouteMatch();

  useEffect(() => {
    if (company && match.params.formType && match.params.formId !== 'add' && match.params.formId !== 'file') {
      const formId = match.params.formId;
      dispatch(getBusinessProcessAction({ formId: formId, company: company }))
    }
    // eslint-disable-next-line
  }, [match.params.formType, match.params.formId])

  return (
    <>
      {(match.params.formType && match.params.formId) &&
        <Link className={`menu-route-label`} to={`/admin/business-process/form-builder/${match.params.formId}`}>
          {getFormDetailData.name ?
            <>
              {` > `}
              <span className={`label current-route`}>
                {getFormDetailData.name.toUpperCase()}
                {!getFormDetailData.enabled &&
                  <span className='warning'>
                    (BP Inactive)
                  </span>}
              </span>
            </>
            : ''
          }
        </Link>
      }
    </>
  )
}

const DTNameBreadcrumb = () => {
  const { forUpdateDataTableDetails } = useSelector((state: IRootState) => state.dataTable);
  const match: { params: { dataTableId: string } } = useRouteMatch();

  return (
    <>
      {match.params.dataTableId && forUpdateDataTableDetails &&
        ` > ${(forUpdateDataTableDetails.name || 'new data table').toUpperCase().replace(/-/g, ' ')}`
      }
    </>
  )
}

interface IAdminHeaerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const AdminHeader: React.FC<IAdminHeaerProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match: { path: string } = useRouteMatch();
  const { main } = useSelector((state: IRootState) => state);
  const { userCompanyDetails, userLoggedIn } = useSelector((state: IRootState) => state.auth);
  const { hasPageChange, currentRoute } = useSelector((state: IRootState) => state.main);

  let validateUserProfile = AllowedUserListActionControl.withUser(userLoggedIn);

  const handleLogout = () => {
    localStorage.removeItem('selectedAttachment');
    dispatch({ type: AUTH_LOGOUT, payload: {} });
  }

  const popupTriggerComponent = userLoggedIn.user && userLoggedIn.user.avatar ?
    <Image style={{ cursor: 'pointer' }} src={userLoggedIn.user.avatar} avatar /> :
    <Icon style={{ cursor: 'pointer' }} name='user circle' size='big' color='orange' />;

  const handleLinkClick = (e: any, url: string) => {
    changeRoute(url, hasPageChange || props.hasPageChangeRef.current.hasChange, { history, dispatch }, e);
  }

  return (
    <Container fluid>
      <Menu
        fixed='top'
        borderless
        className="main-menu"
      >
        <Menu.Menu position='left' className="menu-item-left">
          <Menu.Item>
            <Image className="menu-logo" size='mini' src={require('../../mobbiz.png')} />
          </Menu.Item>
          <Menu.Item >
            {location.pathname === '' ? '' :
              <Link className={`menu-route-label`} to={`/${main.mainRoute}`}>
                <span className={`label`}>{`${main.mainRoute.toUpperCase()}`}</span>
              </Link>
            }
            {main.subRoute ?
              <Link className={`menu-route-label ${main.subRoute === currentRoute ? 'current-route' : ''}`} to={`/${main.mainRoute}/${main.subRoute}`}>
                {` > `}
                <span className={`label ${main.subRoute === currentRoute ? 'current-route' : ''}`}>{main.subRoute.toUpperCase().replace(/-/g, ' ')}</span>
              </Link>
              : ''
            }
            <Route path={`${match.path}/business-process/:formType/:formId`}>
              <FormNameBreadcrumb />
            </Route>
            <Route path={`${match.path}/data-tables/:dataTableId`}>
              <DTNameBreadcrumb />
            </Route>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position='right' className="menu-item-right">
          {
            menuList.map(({ id, name, route_label, icon, style }, key) => {

              if (!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.EndUser) && id === 'documents') {
                return null;
              }
              if (name === 'BPM MANAGER' &&
                (!validateUserProfile.hasSomeProfiles(UserProfileStaticKey.BPMManager)
                  || (userCompanyDetails && userCompanyDetails.seq !== 1))) {
                return null;
              }
              if (id === 'admin' && match.path === '/admin') {
                return null;
              }
              if (id === 'portal') {
                return null;
              }

              return (
                <React.Fragment key={key}>
                  <Link to={`/${id}`} className={`item ${route_label === main.mainRoute ? 'active disabled' : ''}`}
                    onClick={(event) => handleLinkClick(event, `/${id}`)}
                    onContextMenu={(event) => handleLinkClick(event, `/${id}`)}>
                    {name}
                  </Link>
                </React.Fragment>
              )
            })
          }
          {/* {validateUserProfile.hasSomeProfiles(UserProfileStaticKey.Admin) &&
            <Link to={`/admin/settings/${settingsMenuList[0].id}`} className='item settings'
              onClick={(event) => handleLinkClick(event, `/admin/settings/authentication`)}
              onContextMenu={(event) => handleLinkClick(event, `/admin/settings/authentication`)}>
              <Image className='icon-settings' src={icons.black.cog} style={{
                width: '20px',
                height: '20px',
                filter: getFilters('#7F7F7F').filter
              }} />
            </Link>
          } */}
          <UserAvatar handleLogout={handleLogout}
            popupTriggerComponent={popupTriggerComponent}
          />
        </Menu.Menu>
      </Menu>
    </Container>
  )
}

export default AdminHeader;
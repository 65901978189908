import { IFormula } from '../../custom-field-config/interface/formula';
import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.FX,
  required: false,
  type: 'fx',
};

interface IDefaultConfigObject {
  alignment?: string;
  thousandSeparator?: boolean;
  decimals?: number;
  formula: IFormula[];
}
const defaultConfig: IDefaultConfigObject = {
  alignment: 'right',
  thousandSeparator: false,
  formula: []
};

const value = {
  title: 'Fx',
  icon: {
    black: require('../../icons/black/fx-sign.png'),
    white: require('../../icons/white/fx-sign.png'),
    blue: require('../../icons/blue/fx-sign.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  postRequest,
  getRequest,
  setToken,
  IAxiosResponse,
  clearLocalStorage,
} from '../../common/api-request';
import { AUTH_LOGIN, AUTH_LOGOUT, FORGOT_PASSWORD } from './constant';
import { CHECK_AUTH_TOKEN } from './token-checker/constant';
import {
  triggerLogoutReturnAction,
  triggerLogoutMessageReturn,
} from './action/logout';
import {
  checkAuthTokenActionReturn,
  submitLoginReturnAction,
  submitLoginRErrorAction,
} from './action/login';
import { setStatusCodeAction } from '../error/action';
import { ILogin } from './interface/input/login';
import { ILogout } from './interface/input/logout';
import { ICheckAuthToken } from './interface/input/check-auth-token';
import { AuthorisedUser } from './authorize-user';
import { IForgotPassword } from './interface/input/forgot-password';
import {
  submitForgotPasswordReturnAction,
  submitForgotPasswordErrorAction,
} from './action/forgot-password';
import { ICompany } from '../bpm-manager-module/module/companies/interface/response/company';
import { sortNonCustomField } from '../../common/utils/sort-non-custom-field';
import authMessage from './message';

export function* login(args: ILogin) {
  try {
    localStorage.removeItem('selectedApp');
    localStorage.removeItem('selectedBp');
    const req: IAxiosResponse = yield call(postRequest, '/login', args);
    localStorage.setItem('token', req.data.data.Token);
    setToken(req.data.data.Token);
    const userData = JSON.parse(req.config.data);
    const base64User = Buffer.from(userData.username).toString('base64');
    // @ts-ignore
    yield call(window.gtag, 'config', 'G-S5QJYM3Z3N', { user_id: base64User });
    // @ts-ignore
    yield call(window.gtag, 'config', 'G-0CB1Q94MME', { user_id: base64User });
    // @ts-ignore
    yield call(window.gtag, 'event', 'login', {
      method: 'BPM credential',
      company: userData.username.split('@')[1] || 'N/A',
    });
    yield put(
      submitLoginReturnAction({
        token: req.data.data.Token,
        status: 'success',
      })
    );
  } catch (e) {
    const error = e as any;
    console.log(e);
    yield put(
      submitLoginRErrorAction({ status: 'failed', error: error.message })
    );
  }
}

export function* logout(args: ILogout) {
  try {
    const req: IAxiosResponse = yield call(getRequest, '/logout', {});
    if (req.data.success && req.data.message === authMessage.logoutSuccess) {
      setToken('');
      clearLocalStorage();
    }
    if (args.logoutMessageModal) {
      yield put(triggerLogoutMessageReturn({ logoutMessageModal: true }));
    } else {
      yield put(triggerLogoutReturnAction({ logoutMessageModal: false }));
    }
  } catch (e) {
    console.log(e);
    yield put(setStatusCodeAction(e));
    yield put(triggerLogoutReturnAction({ logoutMessageModal: false }));
  }
}

export function* checkTokenIsValid(args: ICheckAuthToken) {
  try {
    const token = localStorage.getItem('token') || '';
    const req: IAxiosResponse = yield call(getRequest, '/me', {});
    const userCompany: IAxiosResponse = yield call(
      getRequest,
      `/companies/${req.data.data.belongCompanyId}`,
      {}
    );
    const userField: IAxiosResponse = yield call(
      postRequest,
      `${req.data.data.belongCompanyId}/users/fields?preloadItemsCount=0`,
      {}
    );
    console.log(userField.data);
    const userDetail: IAxiosResponse = yield call(
      getRequest,
      `${req.data.data.belongCompanyId}/users/${req.data.data.user.id}?load=profiles&load=groups`,
      {}
    );
    const userStaticField = userField.data.data.filter(
      (e: any) => e.custom === false
    );

    yield put(
      checkAuthTokenActionReturn({
        token,
        userDetail: {
          ...req.data.data,
          user: {
            ...userDetail.data.data,
            deleted: req.data.data.user.deleted,
          },
        } as AuthorisedUser,
        userCompany: userCompany.data.data as ICompany,
        userField: sortNonCustomField(userStaticField),
      })
    );
  } catch (e) {
    console.log(e);
    yield put(setStatusCodeAction(e));
    yield put(
      checkAuthTokenActionReturn({
        token: '',
        userDetail: {} as AuthorisedUser,
      })
    );
  }
}

export function* forgotPassword(args: IForgotPassword) {
  try {
    yield call(postRequest, '/forgotPassword/sendEmail', args);
    yield put(submitForgotPasswordReturnAction({ status: true, error: '' }));
  } catch (e) {
    const error = e as any;
    console.log(e);
    yield put(submitForgotPasswordErrorAction({ status: false, error }));
  }
}

export function* authSagas() {
  yield takeLatest(AUTH_LOGIN, login);
  yield takeLatest(AUTH_LOGOUT, logout);
  yield takeLatest(CHECK_AUTH_TOKEN, checkTokenIsValid);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}

// All sagas to be loaded
export default authSagas;

import { useEffect, useState } from 'react';
import { IAutomationComponent } from '../../../../interface/component-list';

interface IProps {
  selectedComponent: IAutomationComponent;
}

export const useSetInitialStatusUpdateStateValue = (props: IProps) => {
  const { selectedComponent } = { ...props };

  const [fromStatus, setFromStatus] = useState<string[]>([]);
  const [toStatus, setToStatus] = useState<string[]>([]);

  useEffect(() => {
    if (selectedComponent) {
      const fromStatusIds = selectedComponent.config.fromStatusId || [];
      const toStatusIds = selectedComponent.config.toStatusId || [];
      setFromStatus(fromStatusIds)
      setToStatus(toStatusIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...props, fromStatus, setFromStatus,
    toStatus, setToStatus
  };
}
import { IMessageProps } from '../../../../../../common/general/action-message/interface/message-props';

export const CLEAR_BUSINESS_PROCESS_MESSAGE = 'app/admin-module/CLEAR_BUSINESS_PROCESS';
export const GET_BUSINESS_PROCESS_LIST = 'app/admin-module/GET_BUSINESS_PROCESS_LIST';
export const GET_BUSINESS_PROCESS_LIST_RETURN = 'app/admin-module/GET_BUSINESS_PROCESS_LIST_RETURN';
export const GET_BUSINESS_PROCESS_LIST_ERROR = 'app/admin-module/GET_BUSINESS_PROCESS_LIST_ERROR';
export const CREATE_BUSINESS_PROCESS = 'app/admin-module/CREATE_BUSINESS_PROCESS';
export const CREATE_BUSINESS_PROCESS_RETURN = 'app/admin-module/CREATE_BUSINESS_PROCESS_RETURN';
export const CREATE_BUSINESS_PROCESS_ERROR = 'app/admin-module/CREATE_BUSINESS_PROCESS_ERROR';
export const GET_BUSINESS_PROCESS = 'app/admin-module/GET_BUSINESS_PROCESS';
export const GET_BUSINESS_PROCESS_RETURN = 'app/admin-module/GET_BUSINESS_PROCESS_RETURN';
export const GET_BUSINESS_PROCESS_ERROR = 'app/admin-module/GET_BUSINESS_PROCESS_ERROR';
export const UPDATE_BUSINESS_PROCESS = 'app/admin-module/UPDATE_BUSINESS_PROCESS';
export const UPDATE_BUSINESS_PROCESS_RETURN = 'app/admin-module/UPDATE_BUSINESS_PROCESS_RETURN';
export const UPDATE_BUSINESS_PROCESS_ERROR = 'app/admin-module/UPDATE_BUSINESS_PROCESS_ERROR';
export const DELETE_BUSINESS_PROCESS = 'app/admin-module/DELETE_BUSINESS_PROCESS';
export const DELETE_BUSINESS_PROCESS_CONFIRM = 'app/admin-module/DELETE_BUSINESS_PROCESS_CONFIRM';
export const DELETE_BUSINESS_PROCESS_CONFIRM_RETURN = 'app/admin-module/DELETE_BUSINESS_PROCESS_CONFIRM_RETURN';
export const DELETE_BUSINESS_PROCESS_CONFIRM_ERROR = 'app/admin-module/DELETE_BUSINESS_PROCESS_CONFIRM_ERROR';
export const DELETE_BUSINESS_PROCESS_CANCEL = 'app/admin-module/DELETE_BUSINESS_PROCESS_CANCEL';
export const CLEAR_DELETE_BUSINESS_MESSAGE = 'app/admin-module/CLEAR_DELETE_BUSINESS_MESSAGE';
export const UPLOAD_LOGO = 'app/admin-module/UPLOAD_LOGO';
export const UPLOAD_LOGO_RETURN = 'app/admin-module/UPLOAD_LOGO_RETURN';
export const UPLOAD_LOGO_ERROR = 'app/admin-module/UPLOAD_LOGO_ERROR';
export const GET_FORM_LOGO = 'app/admin-module/GET_FORM_LOGO';
export const GET_FORM_LOGO_RETURN = 'app/admin-module/GET_FORM_LOGO_RETURN';
export const GET_FORM_LOGO_ERROR = 'app/admin-module/GET_FORM_LOGO_ERROR';
export const RESET_FORM_LOGO = 'app/admin-module/RESET_FORM_LOGO';
export const CLEAR_UPDATE_BUSINESS_PROCESS_SUCCESS = 'app/admin-module/CLEAR_UPDATE_BUSINESS_PROCESS_SUCCESS';
export const CLEAR_DELETE_BUSINESS_PROCESS_SUCCESS = 'app/admin-module/CLEAR_DELETE_BUSINESS_PROCESS_SUCCESS';
export const CLEAR_CREATE_BUSINESS_PROCESS_ERROR = 'app/admin-module/CLEAR_CREATE_BUSINESS_PROCESS_ERROR';
export const CLEAR_UPDATE_BUSINESS_PROCESS_ERROR = 'app/admin-module/CLEAR_UPDATE_BUSINESS_PROCESS_ERROR';
export const CLEAR_DELETE_BUSINESS_PROCESS_ERROR = 'app/admin-module/CLEAR_DELETE_BUSINESS_PROCESS_ERROR';
export const IMPORT_EXPORT_BP_DT_SUCCESS = 'app/admin-module/IMPORT_EXPORT_BP_DT_SUCCESS';
export const IMPORT_EXPORT_BP_DT_ERROR = 'app/admin-module/IMPORT_EXPORT_BP_DT_ERROR';
export const CLEAR_IMPORT_EXPORT_BP_DT_MESSAGE = 'app/admin-module/CLEAR_IMPORT_EXPORT_ACTION';
export const SET_EXPORT_BACKUP_ITEM = 'app/admin-module/SET_EXPORT_BACKUP_ITEM';
export const GET_BACKUP_REFS = 'app/admin-module/GET_BACKUP_REFS';
export const GET_BACKUP_REFS_RETURN = 'app/admin-module/GET_BACKUP_REFS_RETURN';
export const GET_BACKUP_REFS_ERROR = 'app/admin-module/GET_BACKUP_REFS_ERROR';
export const CLEAR_EXPORT_BACKUP_ITEM = 'app/admin-module/CLEAR_EXPORT_BACKUP_ITEM';
export const SET_SELECT_ALL_BP_DT = 'app/admin-module/SET_SELECT_ALL_BP_DT';
export const DEACTIVATE_BUSINESS_PROCESS = 'app/admin-module/DEACTIVATE_BUSINESS_PROCESS';
export const DEACTIVATE_BUSINESS_PROCESS_RETURN = 'app/admin-module/DEACTIVATE_BUSINESS_PROCESS_RETURN';

export const businessProcessListActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_UPDATE_BUSINESS_PROCESS_SUCCESS,
    type: 'success',
    reducerContainer: 'businessProcess',
    reducerItem: 'updateFormSuccessMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_BUSINESS_PROCESS_SUCCESS,
    type: 'success',
    reducerContainer: 'businessProcess',
    reducerItem: 'confirmDeletionSuccessMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_DELETE_BUSINESS_PROCESS_ERROR,
    type: 'negative',
    reducerContainer: 'businessProcess',
    reducerItem: 'confirmDeletionErrorMessage',
    hasCloseIcon: true,
  }
]

export const businessProcessListFormActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_CREATE_BUSINESS_PROCESS_ERROR,
    type: 'negative',
    reducerContainer: 'businessProcess',
    reducerItem: 'createFormErrorMessage',
    hasCloseIcon: true,
  },
  {
    dispatchType: CLEAR_UPDATE_BUSINESS_PROCESS_ERROR,
    type: 'negative',
    reducerContainer: 'businessProcess',
    reducerItem: 'updateFormErrorMessage',
    hasCloseIcon: true,
  }
]

export const progressStyles: { [key: string]: any } = {
  progess: {
    maxWidth: "200px",
  },
  progressTheme: {
    success: {
      color: '#86d94c'
    },
    active: {
      color: '#86d94c'
    },
    default: {
      color: '#86d94c'
    }
  }
}
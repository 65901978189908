import { useEffect, useState } from 'react';
import { IFormAction } from '../../../workflow/interface/form-action';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';
import { IFormRole } from '../../interface/form-role';

interface IProps {
  status: IFormStatusWithAction | undefined;
  role: IFormRole | undefined;
}

export const useSetUserRoleActions = (props: IProps) => {

  const [noAccess, setNoAccess] = useState(false);
  const [view, setView] = useState(false);
  const [save, setSave] = useState(false);
  const [assign, setAssign] = useState(false);
  const [upload, setUpload] = useState(false);
  const [selectedActions, setSelectedActions] = useState([] as IFormAction[]);

  useEffect(() => {
    let hasView = false;
    let hasSave = false;
    let hasAssign = false;
    let hasAttach = false;
    let selectedActionsList = [] as IFormAction[];
    if (props.role) {
      const actions = props.role.actions.filter(e => props.status && e.statusId === props.status.id);
      const actionTypes = actions.map(e => e.type);

      hasSave = actionTypes.includes('save') || actionTypes.includes('dynamic');
      hasView = !hasSave ? actionTypes.includes('view') : false;
      hasAttach = actionTypes.includes('upload');

      selectedActionsList = actions.filter(e => e.type === 'dynamic');

      const showSave = props.status && props.status.initial === true;
      if (showSave) {
        const saveAction = actions.filter((e: IFormAction) => e.type === 'save').map(e => {
          return { ...e, name: 'SAVE' }
        });
        selectedActionsList = selectedActionsList.concat(saveAction);
      }
    }
    setNoAccess(!hasView && !hasSave && !hasAssign && !hasAttach && selectedActionsList.length === 0)
    setView(hasView)
    setSave(hasSave)
    setAssign(hasAssign)
    setUpload(hasAttach)
    setSelectedActions(selectedActionsList)
  }, [props.role, props.status]);

  return {
    noAccess, setNoAccess, view, setView, save, setSave, assign, setAssign,
    upload, setUpload, selectedActions, setSelectedActions, ...props
  }
}
import { useEffect } from 'react';
import { IAutomation } from '../../interface/automation';
import { IAutomationComponent } from '../../interface/component-list';

interface IProps {
  businessRulesId: string;
  selectedAutomation: IAutomation | undefined;
  getAutomationContentLoading: boolean;
  currentAutomationRef: React.MutableRefObject<IAutomation | undefined>;
  setSelectedComponent: (value: React.SetStateAction<IAutomationComponent | undefined>) => void;
  setComponentHasSet: (value: boolean) => void;
  componentHasSet: boolean;
}

export const useSetSelectedAutomationComponentState = (props: IProps) => {
  const {
    businessRulesId,
    getAutomationContentLoading,
    selectedAutomation,
    currentAutomationRef,
    setSelectedComponent,
    componentHasSet,
    setComponentHasSet
  } = { ...props };

  useEffect(() => {
    if (businessRulesId !== 'add-record' && !getAutomationContentLoading && selectedAutomation && !componentHasSet) {
      currentAutomationRef.current = { ...selectedAutomation };
      if (currentAutomationRef.current && currentAutomationRef.current.component
        && currentAutomationRef.current.component.length > 0) {
        setSelectedComponent(currentAutomationRef.current.component[0]);
        setComponentHasSet(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedAutomation), businessRulesId, getAutomationContentLoading]);

  return { ...props };

}
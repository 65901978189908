import React, { useEffect, useState } from 'react';
import { IOptionWidget } from '../../../interface/option-widget';
import { IAutomationDetailRender } from '../component-registry';
import { useSetNameDescValue } from '../hooks/use-set-name-desc-value';
import MainTrigger from './main-trigger';

const errorIndicator = {
  ruleName: false,
}

class AutomationDetailNewTrigger {

  name = 'trigger';

  render: React.FC<IAutomationDetailRender> = (props) => {
    const { ruleNameRef, descriptionRef } = useSetNameDescValue({ currentAutomationRef: props.currentAutomationRef });
    const [selectedTrigger, setSelectedTrigger] = useState<IOptionWidget | undefined>(undefined);
    const [error, setError] = useState(errorIndicator);
    const [refresher, setRefresher] = useState(0);

    const saveClick = () => {
      props.updateAutomationDetails(ruleNameRef.current.value, 'name');
      props.updateAutomationDetails(descriptionRef.current.value, 'description');
      setRefresher(refresher + 1);
    }

    const selectTrigger = (e: IOptionWidget) => {
      saveClick();
      if ((ruleNameRef.current.value)) {
        const tempSelectedComponent = { ...props.selectedComponent };
        const tempConfig = { ...tempSelectedComponent.config };
        tempConfig.trigger = e;
        tempSelectedComponent.config = tempConfig;
        props.updateCurrentAutomation(tempSelectedComponent, false);
        props.setSelectedComponent(tempSelectedComponent);
      }
    }

    const resetTriggerIfEmpty = () => {
      const tempError = { ...error };
      tempError.ruleName = false;
      if (ruleNameRef.current.value === '') {
        setSelectedTrigger(undefined);
        tempError.ruleName = true;
      }
      setError(tempError)
    }

    useEffect(() => {
      const tempSelectedTrigger = props.selectedComponent.config && props.selectedComponent.config.trigger;
      setSelectedTrigger(tempSelectedTrigger);
    }, [props.selectedComponent])

    return (
      <MainTrigger
        error={error}
        ruleNameRef={ruleNameRef}
        descriptionRef={descriptionRef}
        currentAutomationRef={props.currentAutomationRef}
        resetTriggerIfEmpty={resetTriggerIfEmpty}
        selectTrigger={selectTrigger}
        selectedTrigger={selectedTrigger}
        selectedComponent={props.selectedComponent}
        updateCurrentAutomation={props.updateCurrentAutomation}
        saveClick={saveClick}
        hasComponentChange={props.hasComponentChange}
        parentRefresher={props.parentRefresher}
        refresher={refresher}
        setRefresher={setRefresher}
      />
    );
  }
}

export default AutomationDetailNewTrigger;
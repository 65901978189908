import { ISenderDetail } from '../form/interface/sender-info';
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_RETURN,
  GET_NOTIFICATION_SHOWMORE_RETURN,
  GET_UNREAD_NOTIFICATION_COUNT_RETURN,
  LOCAL_UPDATE_NOTIFICATION_LIST,
  UPDATE_UNREAD_COUNT,
  NOTIFICATION_CURRENT_USER_IN_DOCUMENT,
  CLEAR_NOTIFICATION
} from './constant';
import { INotification } from './interface/notification';

export interface INotificationReducer {
  type?: string;
  notificationUnreadCount: number;
  notificationList: INotification[];
  notificationListLoading: boolean;
  notificationTotalCount: number;
  currentUserInDocument: { [docId: string]: ISenderDetail[] };
}

const initialState: INotificationReducer = {
  notificationUnreadCount: 0,
  notificationList: [],
  notificationListLoading: false,
  notificationTotalCount: 0,
  currentUserInDocument: {},
}

const notificationReducer = (state = initialState, action: INotificationReducer) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_UNREAD_NOTIFICATION_COUNT_RETURN:
      newState.notificationUnreadCount = action.notificationUnreadCount;
      return newState;

    case GET_NOTIFICATION:
      newState.notificationListLoading = true;
      return newState;

    case GET_NOTIFICATION_RETURN:
      newState.notificationList = action.notificationList;
      newState.notificationListLoading = false;
      newState.notificationTotalCount = action.notificationTotalCount;
      return newState;

    case GET_NOTIFICATION_SHOWMORE_RETURN:
      newState.notificationList = state.notificationList.concat(action.notificationList);
      newState.notificationListLoading = false;
      return newState;

    case LOCAL_UPDATE_NOTIFICATION_LIST:
      newState.notificationList = action.notificationList;
      return newState;

    case UPDATE_UNREAD_COUNT:
      const currentCount = parseFloat(newState.notificationUnreadCount.toString());
      console.log('newState.notificationUnreadCount: ', currentCount);
      newState.notificationUnreadCount = currentCount + 1;
      console.log('newState.notificationUnreadCount + 1: ', currentCount + 1);
      return newState;

    case NOTIFICATION_CURRENT_USER_IN_DOCUMENT:
      newState.currentUserInDocument = action.currentUserInDocument;
      return newState;

    case CLEAR_NOTIFICATION:
      newState.notificationList = [];
      newState.notificationListLoading = false;
      return newState;

    default:
      return state;
  }
}

export default notificationReducer;
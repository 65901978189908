import React from 'react';
import { v4 as uuid } from 'uuid';
import { IChatFilterProps } from '.';
import { IComment } from '../../interface/comment';
import CommentWidgetAvatar from '../widget/utils/avatar';
import { ISenderDetail, ISenderInfo } from '../../interface/sender-info';
import { commentSenderUserInfoData } from '../utils/sender-user-info';

interface IChatFilterMoreUsersProps extends IChatFilterProps {
  addToErrorImage: (userId: string) => void;
  errorImage: string[];
  moreThanFiveUserList: ISenderInfo[];
  handleOnHoverMoreUser: () => void;
  handleOnMouseOutMoreUser: () => void
}

const ChatFilterMoreUsers: React.FC<IChatFilterMoreUsersProps> = (props) => {
  return <div className='more-user-container'
    onMouseOver={props.handleOnHoverMoreUser}
    onMouseOut={props.handleOnMouseOutMoreUser}
  >
    <ul>
      {props.moreThanFiveUserList.map((user: ISenderInfo) => {
        const senderUserInfo = { ...user.sender.data } as ISenderDetail;
        const { firstName, lastName } = commentSenderUserInfoData(senderUserInfo, props.userColor);

        return <li key={uuid()}>
          <div className="ui fitted checkbox" onClick={() => props.selectUser(senderUserInfo.id)}>
            <input type="checkbox"
              className="hidden"
              onChange={() => { }}
              checked={props.selectedUser.indexOf(senderUserInfo.id) > -1}
            />
            <label></label>
          </div>
          <CommentWidgetAvatar
            addToErrorImage={props.addToErrorImage}
            comment={{ sender: user.sender } as IComment}
            avatarClassName={`with-icon`}
            errorImage={props.errorImage}
            userColor={props.userColor}
            userIconClassName='no-class'
          />
          <div title={`${firstName} ${lastName}`}>{firstName} {lastName}</div>
        </li>
      })}
    </ul>
  </div>
}

export default ChatFilterMoreUsers;
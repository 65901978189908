import React from 'react';
import { Segment, Button, Input, Dropdown, Image } from 'semantic-ui-react-bpm';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearGroupMessageAction } from '../action';
import DropdownPopperOption from '../../../../../common/general/dropdown-popper-option';
import { Group, IGroup } from '../interface/group';
import { IOptionValue } from '../../users/interface/field';
import { icons } from '../../../../../common/icons';
import { IUserInfo } from '../../../../document-module/module/report/interface/form-document';
import SVGWrapper from '../../../../../common/icons/svg/svg-wrapper';
import { setOpenAdminChatboxAction } from '../../../../main/action';
import { getAdminHistoryLogsAction } from '../../../history/action';
import { IRootState } from '../../../../../reducers';

interface IGroupFilter {
  dynamicRefs: any;
  filterGroups(): void;
  groups: Group[];
  userFilterOption: IOptionValue[];
  showClearFilter: boolean;
  clearFilters(): void;
  isOpenChatbox: boolean;
  allowedAccessAuditLogs: boolean;
}

export const getUserFilterOptions = (groups: IGroup[]): IOptionValue[] => {
  const userFilterOption: any[] = []
  if (groups.length > 0) {
    for (const group of groups) {
      const { users }: any = { ...group }
      if (users && users.length) {
        users.forEach((user: IUserInfo, index: number) => {
          const options = userFilterOption.filter(e => e.value === user.id);
          if (options.length === 0) {
            userFilterOption.push({
              key: user.id,
              text: user.firstName + ' ' + user.lastName,
              value: user.id || ''
            })
          }
        });
      }
    }
  }
  return userFilterOption;
}

export class GroupFilter {

  static Render: React.FC<IGroupFilter> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const companyId = useSelector((state: IRootState) => state.auth.company);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const filterUsersValue = props.dynamicRefs && props.dynamicRefs.current['users'] ? props.dynamicRefs.current['users'].value : undefined;
    const searchValue = props.dynamicRefs && props.dynamicRefs.current['search'] ? props.dynamicRefs.current['search'].value : undefined;
    const nameValue = props.dynamicRefs && props.dynamicRefs.current['name'] ? props.dynamicRefs.current['name'].value : undefined;
    const seqValue = props.dynamicRefs && props.dynamicRefs.current['seq'] ? props.dynamicRefs.current['seq'].value : undefined;

    const openGroupFormModal = () => {
      history.push('/admin/groups/add-group');
      dispatch(clearGroupMessageAction());
    }

    const setIsOpenChatbox = () => {
      dispatch(setOpenAdminChatboxAction(true));
      dispatch(getAdminHistoryLogsAction({
        company: companyId,
        categories: ['Groups'],
        tz
      }));
    }

    const triggerSearchFilter = (value: string) => {
      let isNum = /^\d+$/.test(value);
      if (isNum) {
        props.dynamicRefs.current['seq'].value = value;
      } else {
        props.dynamicRefs.current['name'].value = value;
      }
    }

    return <Segment className='borderless filter-wrapper'>
      <div className='group-filter-container'>
        <div className='search-filter'>
          <div className="ui input">
            <input
              type='text'
              defaultValue={searchValue || ''}
              placeholder='Group ID/Name'
              onChange={(e) => {
                props.dynamicRefs.current['search'].value = e.target.value
                triggerSearchFilter(e.target.value);
                props.filterGroups();
              }}
              ref={(curRef: any) => {
                if (curRef) {
                  return props.dynamicRefs.current['search'] = curRef
                }
              }}
            />
            <input type='hidden'
              defaultValue={nameValue || ''}
              ref={(curRef: any) => {
                if (curRef) {
                  curRef.fieldType = 'string';
                  return props.dynamicRefs.current['name'] = curRef
                }
              }}
            />
            <input type='hidden'
              defaultValue={seqValue || ''}
              ref={(curRef: any) => {
                if (curRef) {
                  curRef.fieldType = 'number';
                  return props.dynamicRefs.current['seq'] = curRef
                }
              }}
            />
          </div>
        </div>
        <Dropdown
          search
          selectOnBlur={false}
          clearable={false}
          options={props.userFilterOption}
          placeholder='User Filter'
          selection
          value={filterUsersValue}
          popperContainer={DropdownPopperOption}
          onChange={(e, target: any) => {
            props.dynamicRefs.current['users'].value = target.value;
            props.filterGroups();
          }}
        />
        <input type='hidden'
          defaultValue={filterUsersValue || ''}
          ref={(curRef: any) => {
            if (curRef) {
              if (props.dynamicRefs) {
                curRef.fieldType = 'enum';
                return props.dynamicRefs.current['users'] = curRef
              }
            }
          }} />
      </div>
      {props.showClearFilter &&
        <Button className='filter-groups' primary onClick={() => props.clearFilters()}>
          <Image src={icons.white.clearFilters} width='30px' />
        </Button>
      }
      {props.allowedAccessAuditLogs &&
        <Button className={`filter-users chat-icon ${!props.isOpenChatbox ? 'inactive' : ''}`} primary
          onClick={setIsOpenChatbox}>
          <SVGWrapper iconName='adminChatIcon' width='20' color={`${!props.isOpenChatbox ? '#3a85a8' : '#fff'}`} />
        </Button>
      }
      <Button type='button' className={`add-button ${props.allowedAccessAuditLogs ? 'hasAuditLogAccess' : ''}`} primary
        size={"large"} floated={"right"} onClick={() => { openGroupFormModal() }}>Add Group</Button>
      <div style={{ display: 'none' }} className='search-group-container'>
        <Input placeholder='Group ID/Name' />
        <Button className="search-group" primary icon={{ name: 'search' }}></Button>
        <Button className="filter-group" primary icon={{ name: 'filter' }}></Button>
      </div>
    </Segment>
  }

}
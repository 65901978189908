import { Delta } from 'jsondiffpatch';
import { fieldVisibilityIndicator } from '../../../../../../common/custom-field-config/constant';
import { ICommentDeltaFields } from '../../../../../document-module/module/form/interface/comment';
import { keysOfEnumType } from '../../../constant';

export class FieldConfigChange {
  static uppercase = (text: string) => {
    return text.charAt(0).toLocaleUpperCase() + text.slice(1);
  }
  static format = (delta: Delta, oldValue: any, targetInstance: any) => {
    let deltaArray = [] as ICommentDeltaFields[];
    delta && Object.keys(delta).length && Object.keys(delta).forEach(key => {
      let deltaFields = {} as ICommentDeltaFields;

      const type = oldValue[key] instanceof Array ? 'array' :
        typeof oldValue[key] !== 'undefined' ? typeof oldValue[key] : typeof delta[key];

      deltaFields.id = key;
      deltaFields.type = type;
      deltaFields.diff = delta[key];
      deltaFields.label = FieldConfigChange.uppercase(key);
      deltaFields.oldValue = oldValue[key] || '';
      deltaFields.alwaysShowLabel = true;

      if (delta[key] instanceof Array && delta[key].length === 1) {
        deltaFields.diff = [null, ...delta[key]];
      }
      if (key === 'permission') {
        deltaFields = FieldConfigChange.permission(delta['permission']);
      }
      if (key === 'defaultValue') {
        deltaFields.type = targetInstance.type;
      }
      if (key === 'labelFormat' || key === 'source') {
        deltaFields.type = 'link';
      }
      if (keysOfEnumType.includes(key)) {
        deltaFields.type = 'enum';
      }
      if (Object.keys(deltaFields).length && deltaFields.type !== 'undefined') {
        deltaArray.push(deltaFields);
      }
    });
    return deltaArray;
  }
  static permission = (delta: Delta) => {
    let deltaFields = {} as ICommentDeltaFields;
    if (delta.name && delta.name instanceof Array) {
      deltaFields.id = 'permission';
      deltaFields.label = 'Visibility';
      deltaFields.diff = delta.name.map(e => fieldVisibilityIndicator[e]);
      deltaFields.type = 'string';
      deltaFields.oldValue = deltaFields.diff[0]
      deltaFields.alwaysShowLabel = true;
    }
    return deltaFields;
  }
  static targetDelta = (targetDelta: Delta) => {
    let config = {} as Delta;
    let delta = {} as Delta;
    Object.keys(targetDelta).forEach(key => {
      if (key === 'config') {
        config = targetDelta[key];
      } else {
        delta[key] = targetDelta[key];
      }
    });
    return { delta, config };
  }
}
import React, { useState, useEffect } from 'react';
import { Modal, Dimmer, Loader, Image, Icon } from 'semantic-ui-react-bpm';
import { useSelector, useDispatch } from 'react-redux';
import Message from './response-message';
import { resetMessageResponseAction } from '../../component/admin-module/module/users/action';
import { message as userMessage } from '../../component/admin-module/module/users/message';
import { IRootState } from '../../reducers';
import { icons } from '../icons';
import PopupBackground from './popup-message-bg.png';
import useDeviceDetect from './device-detect';

interface IConfirmationModal {
  close(): void;
  headerText: string;
  detailText: string;
  confirm(): void;
  cancel(): void;
  open: boolean;
  confirmButtonName?: string;
  cancelButtonName?: string;
  confirmButtonColor?: "grey" | "red" | "orange" | "yellow" | "olive" | "green" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "black" | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined;
  cancelButtonColor?: "grey" | "red" | "orange" | "yellow" | "olive" | "green" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "black" | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined;
  loading?: boolean;
  errorMessage?: string;
  clearErrorMessage?(): void;
  type?: 'warning' | 'error' | 'info' | 'network-error'
  detailImage?: any;
  showHeaderText?: boolean;
  noCancelButton?: boolean;
  reloadButton?: boolean;
  customActionBtn?: JSX.Element;
  redirectToEndUser?: boolean;
  redirectToLoginPage?: boolean;
  redirectToEndUserAction?(): void;
  redirectToLoginPageAction?(): void;

}

export const ConfirmationMessage: React.FC<IConfirmationModal> = (props) => {
  const dispatch = useDispatch();
  const { width } = useDeviceDetect();
  const { deleteUserMessage } = useSelector((state: IRootState) => state.user);
  const { deleteCustomFieldMessage } = useSelector((state: IRootState) => state.customField);

  const [messageResponse, setMessageResponse] = useState('');
  const [openMessageResponse, setOpenMessageResponse] = useState(false);
  const [isError, setIsError] = useState(false);

  let headerIcon = icons.black.smileFace;

  if (props.type === 'error') {
    headerIcon = icons.black.sadFace;
  } else if (props.type === 'warning') {
    headerIcon = icons.black.neutralFace;
  }

  const closeMessageResponse = () => {
    dispatch(resetMessageResponseAction({}));
    setOpenMessageResponse(false);
  }

  useEffect(() => {
    if (deleteUserMessage !== '' && deleteUserMessage !== userMessage.deleteUserSuccess) {
      setMessageResponse(deleteUserMessage);
      setOpenMessageResponse(true);
      setIsError(true);
    }

    if (deleteCustomFieldMessage !== '' && deleteCustomFieldMessage !== userMessage.deleteUserFieldSuccess) {
      setMessageResponse(deleteCustomFieldMessage);
      setOpenMessageResponse(true);
      setIsError(true);
    }

    return () => {
      setMessageResponse('');
      setOpenMessageResponse(false);
    }
  }, [deleteCustomFieldMessage, deleteUserMessage]);

  const capitalize = (title: string) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  return <Modal
    id='confirmation-popup'
    dimmer='blurring'
    size='tiny'
    closeIcon={!props.noCancelButton
      ? <Image className='close-icon' onClick={() => props.close()} src={icons.black.close} />
      : <div className='no-close-icon' />
    }
    closeOnEscape={false}
    closeOnDimmerClick={false}
    open={props.open}
    onClose={() => props.close()}
    className={`${props.type} ${width <= 768 ? 'mobile' : ''}`}
    style={{
      backgroundImage: `url(${PopupBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}
  >
    {
      (props.loading || false) &&
      <Dimmer active inverted>
        <Loader active content='Loading' />
      </Dimmer>
    }
    <Modal.Header className='borderless' id='forgot-password-modal-header' >
      <h1 className='label flex-container'>
        {capitalize(props.type || '')}
        <Image className='sad-icon' src={headerIcon} />
      </h1>
    </Modal.Header>
    {props.errorMessage &&
      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Message
          message={props.errorMessage}
          open={true}
          close={() => { if (props.clearErrorMessage) props.clearErrorMessage(); }}
          error={true}
        />
      </div>
    }
    {props.showHeaderText &&
      <h3
        className='sub-header'>
        {props.headerText}
      </h3>
    }
    <Modal.Content>
      <div id='delete-error-message'>
        <Message message={messageResponse} open={openMessageResponse} close={closeMessageResponse} error={isError} />
      </div>
      <div className={`confirmation-message`}>
        {props.detailImage}
        <p className='mb-0'>{props.detailText}</p>
        {props.redirectToEndUser &&
          <div style={{ marginTop: '10px', textDecoration: 'underline', cursor: 'pointer', color: '#4F8BC8' }} onClick={() => { if (props.redirectToEndUserAction) props.redirectToEndUserAction() }}>Redirect to end user</div>
        }
        {props.redirectToLoginPage &&
          <div style={{ marginTop: '10px', textDecoration: 'underline', cursor: 'pointer', color: '#4F8BC8' }} onClick={() => { if (props.redirectToLoginPageAction) props.redirectToLoginPageAction() }}>Login as another user</div>
        }
      </div>
    </Modal.Content>
    <Modal.Actions className={`borderless ${props.reloadButton ? 'reload' : ''}`} id='forgot-password-modal-footer'>
      <React.Fragment>
        {!props.noCancelButton && <div className='btn-cancel' onClick={() => props.cancel()}>{props.cancelButtonName || 'Cancel'}</div>}
        {props.type && props.type === 'warning' && (
          <div className='btn-confirm' onClick={() => props.confirm()}>{props.confirmButtonName || 'Confirm'}</div>
        )}
        {props.reloadButton && <div className='reload-btn' onClick={() => props.confirm()} >
          <span>Reload</span>
          <Icon name='redo' size='large' />
        </div>
        }
        {props.customActionBtn}
      </React.Fragment>
    </Modal.Actions>
  </Modal >
}
import { IMessageProps } from '../../../../../../../../common/general/action-message/interface/message-props';

export const SAVE_DRAFT_BP_RELATIONSHIP = 'app/app/admin-module/business-process/configuration/bp-relationship/SAVE_DRAFT_BP_RELATIONSHIP';
export const SAVE_DRAFT_BP_RELATIONSHIP_RETURN = 'app/app/admin-module/business-process/configuration/bp-relationship/SAVE_DRAFT_BP_RELATIONSHIP_RETURN';
export const SAVE_DRAFT_BP_RELATIONSHIP_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/SAVE_DRAFT_BP_RELATIONSHIP_ERROR';
export const PUBLISH_BP_RELATIONSHIP = 'app/app/admin-module/business-process/configuration/bp-relationship/PUBLISH_BP_RELATIONSHIP';
export const PUBLISH_BP_RELATIONSHIP_RETURN = 'app/app/admin-module/business-process/configuration/bp-relationship/PUBLISH_BP_RELATIONSHIP_RETURN';
export const PUBLISH_BP_RELATIONSHIP_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/PUBLISH_BP_RELATIONSHIP_ERROR';
export const CLEAR_SAVE_BP_RELATIONSHIP_SUCCESS = 'app/app/admin-module/business-process/configuration/bp-relationship/CLEAR_SAVE_BP_RELATIONSHIP_SUCCESS';
export const CLEAR_SAVE_BP_RELATIONSHIP_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/CLEAR_SAVE_BP_RELATIONSHIP_ERROR';
export const GET_BPR_LIST = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_LIST';
export const GET_BPR_LIST_RETURN = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_LIST_RETURN';
export const GET_BPR_LIST_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_LIST_ERROR';
export const GET_BPR_FORM_FIELDS = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_FORM_FIELDS';
export const GET_BPR_FORM_FIELDS_RETURN = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_FORM_FIELDS_RETURN';
export const GET_BPR_FORM_FIELDS_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_FORM_FIELDS_ERROR';
export const GET_BPR_FORM_FIELDS_SANITIZE_RETURN = 'app/app/admin-module/business-process/configuration/bp-relationship/GET_BPR_FORM_FIELDS_SANITIZE_RETURN';
export const CLEAR_SAVE_BPR_SUCCESS = 'app/app/admin-module/business-process/configuration/bp-relationship/CLEAR_SAVE_BPR_SUCCESS';
export const CLEAR_SAVE_BPR_ERROR = 'app/app/admin-module/business-process/configuration/bp-relationship/CLEAR_SAVE_BPR_ERROR';

export const bpRelationshipActionMessage: IMessageProps[] = [
  {
    dispatchType: CLEAR_SAVE_BPR_SUCCESS,
    type: 'success',
    reducerContainer: 'bpRelationship',
    reducerItem: 'saveBPRelationshipMessage',
    hasCloseIcon: true
  },
  {
    dispatchType: CLEAR_SAVE_BPR_ERROR,
    type: 'negative',
    reducerContainer: 'bpRelationship',
    reducerItem: 'saveBPRelationshipErrorMessage',
    hasCloseIcon: true
  }
]

export const bprSystemFields = [
  {
    value: 'creator|||||Creator|||||system',
    text: 'Creator',
    key: -1
  },
  {
    value: 'updatedAt|||||Updated At|||||system',
    text: 'Updated At',
    key: -2

  },
  {
    value: 'createdAt|||||Created At|||||system',
    text: 'Created At',
    key: -3
  },
];

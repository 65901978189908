import { Delta } from 'jsondiffpatch';
import { DeltaFieldType } from '..';
import { ICommentDeltaFields } from '../../../../../../document-module/module/form/interface/comment';
import { FieldConfigChange } from '../../utils/field-config-change';

export class TimeDeltaFields implements DeltaFieldType {

  name = 'time';

  format(targetDelta: Delta, targetInstance: any) {
    let deltaArray = [] as ICommentDeltaFields[];

    const { delta } = FieldConfigChange.targetDelta(targetDelta);

    if (Object.keys(delta).length > 0) {
      const { newDelta, oldDelta } = this.setDelta(delta, targetInstance)
      deltaArray = FieldConfigChange.format(newDelta, oldDelta, targetInstance);
    }

    return deltaArray;
  }

  setDelta = (delta: Delta, instance: any) => {
    let newDelta = {} as Delta;
    let oldDelta = {} as Delta;

    Object.keys(delta).forEach(key => {
      if (key === 'defaultValue') {
        const data = this.setDefaultValue(delta[key]);
        if (data) {
          newDelta[key] = data;
          oldDelta[key] = instance[key];
        }
      } else {
        newDelta[key] = delta[key];
        oldDelta[key] = instance[key];
      }
    })
    return { newDelta, oldDelta };
  }

  setDefaultValue = (delta: Delta) => {
    let newDelta = {} as Delta;
    if (delta) {
      if (Array.isArray(delta)) {
        newDelta = delta.map(val => {
          if (Array.isArray(val)) {
            return val.toString() || null;
          }
          return val;
        });
      } else {
        newDelta = delta;
      }
    }

    if (Array.isArray(delta)) {
      const isAllNull = newDelta.every((e: string) => !e);
      return isAllNull ? null : newDelta;
    }

    return newDelta;
  }
}
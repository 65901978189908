class TimeOperatorOption {

  name = 'time';

  // option = [
  //   { key: 0, value: 'is-empty', text: 'Is Empty' },
  //   { key: 1, value: 'is-not-empty', text: 'Is Not Empty' },
  //   { key: 2, value: 'less', text: 'Is Before' },
  //   { key: 3, value: 'more', text: 'Is After' },
  //   { key: 4, value: 'is-between', text: 'Is Between' },
  //   { key: 5, value: 'is-not-between', text: 'Is Not Between' },
  //   { key: 6, value: 'equal', text: 'Equal' },
  //   { key: 7, value: 'not-equal', text: 'Not Equal' },
  // ]

  option = [
    { key: 0, value: 'is-empty', text: 'Is Empty' },
    { key: 1, value: 'is-not-empty', text: 'Is Not Empty' },
    { key: 2, value: 'less', text: 'Is Before' },
    { key: 3, value: 'more', text: 'Is After' },
    { key: 4, value: 'equal', text: 'Equal' },
    { key: 5, value: 'not-equal', text: 'Not Equal' },
  ]
}

export default TimeOperatorOption;
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../reducers';
import { IComment } from '../../../interface/comment';
import { setCommentBody } from './manage-comment';

interface IChatWidgetMessageBody {
  body: IComment;
  attachment?: boolean;
}

const CommentWidgetBody: React.FC<IChatWidgetMessageBody> = ({ body, attachment }) => {

  const { user } = useSelector((state: IRootState) => state.auth.userLoggedIn);

  let bodyHTML = setCommentBody(body, user);

  if (!attachment) {
    bodyHTML = bodyHTML.slice(0, -4) +
      (body.createdAt !== body.updatedAt
        ? `&nbsp;<span style="color: #777676;font-size:10px">(edited)</span>`
        : '') +
      '</p>';

  }

  return <div className={`message-content`} dangerouslySetInnerHTML={{ __html: bodyHTML }} />
};

export default CommentWidgetBody;

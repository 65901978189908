import { IConfigName } from './interface/field-type-object';

interface IDefaultDataObject {
  configName: IConfigName;
  required: boolean;
  type: string;
}

const defaultData: IDefaultDataObject = {
  configName: IConfigName.URL,
  required: false,
  type: 'string',
};

interface IDefaultConfigObject {
  displayType: string;
  format: string;
}

const defaultConfig: IDefaultConfigObject = {
  displayType: 'line',
  format: 'url',
};

const value = {
  title: 'URL',
  icon: {
    black: require('../../icons/black/link.png'),
    white: require('../../icons/white/link.png'),
    blue: require('../../icons/blue/link.png'),
  },
  style: {
    width: '20px',
    height: '20px'
  },
  defaultData,
  defaultConfig
}

export default value;
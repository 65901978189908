import { IField } from '../../../../../admin-module/module/users/interface/field';
import {
  GET_PORTAL_LIST_VIEW,
  GET_PORTAL_LIST_VIEW_RETURN,
  GET_PORTAL_FIELD_LIST,
  GET_PORTAL_FIELD_LIST_RETURN
} from './constant';

export interface IPortalListViewReducer {
  type?: string;
  portalListView: any;
  portalFieldList: IField[];
}

const initialState: IPortalListViewReducer = {
  portalFieldList: [],
  portalListView: []
}

const listViewReducer = (state = initialState, action: IPortalListViewReducer) => {
  let newState = { ...state };
  switch (action.type) {

    case GET_PORTAL_LIST_VIEW:
      newState.portalListView = [];
      return newState;

    case GET_PORTAL_LIST_VIEW_RETURN:
      newState.portalListView = action.portalListView;
      return newState;

    case GET_PORTAL_FIELD_LIST:
      newState.portalFieldList = [];
      return newState;

    case GET_PORTAL_FIELD_LIST_RETURN:
      newState.portalFieldList = action.portalFieldList;
      return newState;

    default:
      return state;
  }
}

export default listViewReducer;
import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteRequest, getRequest, IAxiosResponse, postRequest } from '../../../../common/api-request';
import { setStatusCodeAction } from '../../../error/action';
import { IFilter } from '../../interface/input/filter';
import { getNotificationReturnAction, getNotificationShowMoreReturnAction, getUnreadNotificationReturnAction } from './action';
import { GET_NOTIFICATION, GET_UNREAD_NOTIFICATION_COUNT, MARK_ALL_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD, DELETE_NOTIFICATION } from './constant';
import { INotificationDelInput } from './interface/input/notification-del';
import { INotificationGetInput } from './interface/input/notification-get';
import { INotificationUpdateUnreadInput } from './interface/input/notification-update-unread';
import { INotification } from './interface/notification';

export function* getUnreadNotification(args: INotificationGetInput) {
  try {
    let company = args.company;
    const req: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
    yield put(getUnreadNotificationReturnAction({
      notificationUnreadCount: req.data.data.count
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* getNotification(args: INotificationGetInput) {
  try {
    let company = args.company;
    let offset = '';
    let limit = '';
    let filter = {} as IFilter;

    if (args.offset) {
      offset = `&offset=${args.offset}`;
    }
    if (args.limit) {
      limit = `&limit=${args.limit}`;
    }
    if (args.filter) {
      filter = args.filter;
    }
    const req: { data: { data: INotification[], pagination: { total: number } } } = yield call(postRequest, `${company}/notifications/search?${offset}${limit}`, filter);
    if (args.showMore && args.offset && args.offset > 0) {
      yield put(getNotificationShowMoreReturnAction({
        notificationList: req.data.data,
        notificationTotalCount: req.data.pagination.total
      }));
    } else {
      yield put(getNotificationReturnAction({
        notificationList: req.data.data,
        notificationTotalCount: req.data.pagination.total
      }));
    }
    const unreadCount: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
    yield put(getUnreadNotificationReturnAction({
      notificationUnreadCount: unreadCount.data.data.count
    }));
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* markNotificationAsRead(args: INotificationUpdateUnreadInput) {
  try {
    let company = args.company;
    if (args.notificationId) {
      const markAsRead: IAxiosResponse = yield call(postRequest, `${company}/notifications/read`, {
        ids: [args.notificationId]
      });
      if (markAsRead) {
        const req: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
        yield put(getUnreadNotificationReturnAction({
          notificationUnreadCount: req.data.data.count
        }));
      }
    }
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* markNotificationAsUnread(args: INotificationUpdateUnreadInput) {
  try {
    let company = args.company;
    if (args.notificationId) {
      const markAsUnRead: IAxiosResponse = yield call(postRequest, `${company}/notifications/unread`, {
        ids: [args.notificationId]
      });
      if (markAsUnRead) {
        const req: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
        yield put(getUnreadNotificationReturnAction({
          notificationUnreadCount: req.data.data.count
        }));
      }
    }
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* markAllNotificationAsRead(args: INotificationGetInput) {
  try {
    let company = args.company;
    const markAllAsRead: IAxiosResponse = yield call(postRequest, `${company}/notifications/read/all`, {});
    if (markAllAsRead) {
      const req: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
      yield put(getUnreadNotificationReturnAction({
        notificationUnreadCount: req.data.data.count
      }));
    }
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* deleteNotification(args: INotificationDelInput) {
  try {
    const company = args.company;
    let success = false;
    if (args.deleteType === 'individual') {
      const req: IAxiosResponse = yield call(deleteRequest, `${company}/notifications`, { data: { ids: args.ids } })
      success = req.data.success;
    }
    if (success) {
      const req: IAxiosResponse = yield call(getRequest, `${company}/notifications/unreadCount`, {});
      yield put(getUnreadNotificationReturnAction({
        notificationUnreadCount: req.data.data.count
      }));
    }
  } catch (e) {
    yield put(setStatusCodeAction(e))
    console.log(e.response);
  }
}

export function* notificationSagas() {
  yield takeLatest(GET_UNREAD_NOTIFICATION_COUNT, getUnreadNotification);
  yield takeLatest(GET_NOTIFICATION, getNotification);
  yield takeLatest(MARK_ALL_NOTIFICATION_AS_READ, markAllNotificationAsRead);
  yield takeLatest(MARK_NOTIFICATION_AS_READ, markNotificationAsRead);
  yield takeLatest(MARK_NOTIFICATION_AS_UNREAD, markNotificationAsUnread);
  yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
}

// All sagas to be loaded
export default notificationSagas;
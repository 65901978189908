import React, { ReactElement } from 'react';
import { Image, Popup } from 'semantic-ui-react-bpm';
import { icons } from '../../../../common/icons';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../reducers';
import { getFieldId } from '../../../../common/utils/get-field';
import { useRouteMatch, useHistory } from 'react-router-dom';

interface IUserAvatar {
  handleLogout(): void;
  popupTriggerComponent: ReactElement
}

const UserAvatar: React.FC<IUserAvatar> = (props) => {
  const history = useHistory();
  const { userLoggedIn: { user }, userField } = useSelector((state: IRootState) => state.auth);
  const match: { path: string } = useRouteMatch();
  const { mainRoute } = useSelector((state: IRootState) => state.main);

  const getFieldValue = (fieldLabel: string) => {
    let fieldValue = '';
    if (user && user.fields) {
      fieldValue = user.fields[getFieldId(userField, fieldLabel)];
    }
    return fieldValue;
  }

  const onNavigationClick = (page: string) => {
    if (mainRoute === 'END USER MODULE') {
      if (match.path === '/documents') {
        history.push(`${match.path}/${page}`)
      } else {
        history.push(`/documents/${page}`)
      }
    }
  }

  return <div className='item'>
    <Popup id='user-avatar-modal' on='click' basic
      trigger={props.popupTriggerComponent}
    >
      <div id='user-info-main'>
        <div className='user-info-wrapper'>
          {user && user.avatar ?
            <Image src={user.avatar} avatar />
            :
            <Image src={icons.blue.user} avatar />
          }
          <div className='user-info'>
            <span className='name'>{getFieldValue('First Name')}&nbsp;{getFieldValue('Last Name')}</span><br />
            <span className='email'>{getFieldValue('Email Address')}</span>
          </div>
        </div>
      </div>
      <div className='horizontal-line' />
      <div className='user-navigation'>
        <label onClick={() => onNavigationClick('setting')}>Settings</label>
        <label onClick={() => onNavigationClick('user-account')}>User Account</label>
        <label onClick={() => props.handleLogout()}><strong>Logout</strong></label>
      </div>
    </Popup>
  </div>
}

export default UserAvatar;
import moment from 'moment';
import { ICommentDeltaFields } from '../../../../../interface/comment';
import * as jsondiffpatch from 'jsondiffpatch';
import { UploadedFile } from '../../../../../../../../../common/api-request/upload';

export const setSytemMessagesDeltaFields = (delta: ICommentDeltaFields) => {
  if (!delta.type) {
    delta = parseDeltaObject(delta);
  }

  let deltaValue = delta.diff;

  if (Array.isArray(delta.diff)) {
    deltaValue = delta.diff.map((e: any) => {
      if (typeof e === 'string' && e === '<No data>') {
        e = null;
      }
      return e;
    });
  }

  if (delta.type === 'enum') {
    if (!delta.oldValue || typeof delta.oldValue === 'undefined' || Object.keys(delta.oldValue).length === 0) delta.oldValue = [];
    if (Array.isArray(delta.diff)) {
      deltaValue = delta.diff.map((item) => {
        if (Array.isArray(item)) {
          return item.map((e: any) => { return e ? e.label : '' })
        } else {
          return item ? item.label : null;
        }
      });
    } else {
      if (delta.diff.hasOwnProperty('label')) {
        deltaValue = delta.diff ? delta.diff.label : null;
      } else if (delta.diff.hasOwnProperty('id')) {
        deltaValue = delta.diff ? delta.diff.id : null;
      } else {
        const enumValue = [[...delta.oldValue], jsondiffpatch.patch([...delta.oldValue], delta.diff)];
        deltaValue = enumValue.map(item => { return item.map((e: any) => { return e.label }) });
      }
    }
  }
  if (delta.type === 'time') {
    if (Array.isArray(delta.diff)) {
      deltaValue = delta.diff.map(value => {
        let dateToday = moment().format("YYYY-MM-DD");
        let time = new Date(dateToday + ' ' + value);
        const isValid = moment(time).isValid();
        return isValid ? moment(time).format('HH:mm:ss') : null;
      });
    }
  }
  if (delta.type === 'date-time') {
    if (Array.isArray(delta.diff)) {
      const objectLength = Object.keys(delta.diff).length;
      if (objectLength === 1) {
        if (!delta.oldValue) {
          delta.diff = [null, delta.diff];
        }
      }
      deltaValue = delta.diff.map((value: any) => {
        if (!value) {
          return null;
        }
        let date = new Date(moment(value).format("YYYY-MM-DD HH:mm:ssZ"));
        const isValid = moment(date).isValid();
        return isValid ? moment(date).format("DD MMM YYYY h:mm a") : null;
      });
    }
  }
  if (delta.type === 'array') {
    if (!delta.oldValue || typeof delta.oldValue === 'undefined') delta.oldValue = [];
    deltaValue = [[...delta.oldValue], jsondiffpatch.patch([...delta.oldValue], delta.diff)];
    if (deltaValue && deltaValue[0] && deltaValue[1]) {
      const fileType = deltaValue.every((value: any) => {
        return value.every((e: any) => e && e.hasOwnProperty('id'))
      });
      if (fileType) {
        let fileIds = deltaValue[0].map((value: UploadedFile) => value.id);
        let hasEqualFiles = deltaValue[1].filter((value: UploadedFile) => value && fileIds.includes(value.id));
        if (hasEqualFiles.length > 0) {
          let equalFileIds = hasEqualFiles.map((value: UploadedFile) => value.id);
          deltaValue = deltaValue.map((value: any) => {
            return value.filter((e: any) => e && !equalFileIds.includes(e.id))
          });
        }
      }
    }
  }
  if (delta.type === 'file') {
    if (typeof delta.oldValue === 'undefined') {
      deltaValue = delta.diff
    } else {
      deltaValue = [{ ...delta.oldValue }, { ...jsondiffpatch.patch({ ...delta.oldValue }, delta.diff) }];
    }
    if (deltaValue && deltaValue[0] && deltaValue[1]) {
      if (deltaValue[0].id !== 'generated' || deltaValue[1].id !== 'generated') {
        if (deltaValue[0].id === deltaValue[1].id) {
          deltaValue = undefined;
        }
      }
    } else {
      if (deltaValue[0] && deltaValue[0].id === 'generated' && !deltaValue[1] && !delta.oldValue) {
        deltaValue = undefined;
      }
    }
  }
  if (delta.type === 'bool' || delta.type === 'boolean') {
    deltaValue = delta.diff.map((e: any) => {
      return e !== null ? e.toString() : 'false';
    });
  }
  if (delta.type === 'string') {
    const deltaDiff = delta.diff.map((e: string | null) => !e ? '' : e);
    if (!delta.oldValue || typeof delta.oldValue === 'undefined') delta.oldValue = '';
    deltaValue = [delta.oldValue, jsondiffpatch.patch(delta.oldValue, deltaDiff)];
  }
  if (delta.type === 'range') {
    deltaValue = parseRangeAndGeolocationDelta('range', delta);
  }
  if (delta.type === 'geolocation') {
    deltaValue = parseRangeAndGeolocationDelta('geolocation', delta);
  }

  return deltaValue;
}

const parseDeltaObject = (delta: ICommentDeltaFields) => {
  let deltaArray: any = [null, null];

  if (delta.diff instanceof Array) {
    const hasEnumProps = delta.diff.some(e => e && e.hasOwnProperty('id') && e.hasOwnProperty('label'));
    if (hasEnumProps) {
      delta.type = 'enum';
    }
  }

  if (!(delta.diff instanceof Array)) {
    if (delta.diff.hasOwnProperty('id') && delta.diff.hasOwnProperty('label')) {
      if (delta.oldValue) {
        const newValue: any = {};
        deltaArray[0] = delta.oldValue;
        Object.keys(delta.diff).forEach(keys => {
          const values = delta.diff[keys];
          if (values instanceof Array) {
            newValue[keys] = values[1];
          }
        });
        deltaArray[1] = newValue;
      }
      delta.type = 'enum';
    }
    delta.diff = deltaArray;
  }

  return delta;
}

const parseRangeAndGeolocationDelta = (type: 'range' | 'geolocation', delta: ICommentDeltaFields) => {
  let deltaValue = delta.diff;

  const firstProp = type === 'range' ? 'from' : 'lat';
  const secondProp = type === 'range' ? 'to' : 'lon';

  if (!delta.oldValue || typeof delta.oldValue === 'undefined' || Object.keys(delta.oldValue).length === 0) delta.oldValue = [];

  const isArray = delta.diff instanceof Array;
  if (!isArray) {
    const objectLength = Object.keys(delta.diff).length;
    if (objectLength === 1) {
      if (delta.diff.hasOwnProperty(firstProp)) {
        if (delta.oldValue && delta.oldValue.hasOwnProperty(secondProp)) {
          delta.diff = { ...delta.diff, [secondProp]: [delta.oldValue[secondProp], delta.oldValue[secondProp]] };
        }
      } else {
        if (delta.oldValue && delta.oldValue.hasOwnProperty(firstProp)) {
          delta.diff = { ...delta.diff, [firstProp]: [delta.oldValue[firstProp], delta.oldValue[firstProp]] };
        }
      }
    }
    deltaValue = [
      { [firstProp]: delta.diff[firstProp][0], [secondProp]: delta.diff[secondProp][0] },
      { [firstProp]: delta.diff[firstProp][1], [secondProp]: delta.diff[secondProp][1] }
    ]
  } else {
    if (delta.diff.length === 1) {
      if (delta.oldValue instanceof Array && !delta.oldValue.length) {
        deltaValue = [null, ...delta.diff];
      } else {
        deltaValue = [...delta.diff, null];
      }
    }
  }
  return deltaValue;
}

export const isFileArrayType = ({ delta, fieldValues }: any) => {
  return delta.type === 'array' &&
    (
      (fieldValues[0] && fieldValues[0].hasOwnProperty('url')) ||
      (Array.isArray(fieldValues[0]) && fieldValues[0].length > 0 && fieldValues[0].every((value: any) => !!(value && value.url))) ||
      (Array.isArray(fieldValues[1]) && fieldValues[1].length > 0 && fieldValues[1].every((value: any) => !!(value && value.url)))
    )
}

export const setEditedFieldRef = (element: HTMLDivElement | null) => {
  if (!element) return;
  let childrenWidth = 0;
  if (element.children.length > 0) {
    for (let i = 0; i < element.children.length; i++) {
      const { clientWidth } = element.children[i];
      childrenWidth += clientWidth;
    }
  }
  if (childrenWidth > 410) {
    element.style.setProperty('display', 'block', 'important');
  } else {
    if (element.children.length > 0) {
      element.children[1].className = 'edited-field-preview inline';
    }
  }
}

export const sanitizeDeltaFields = (deltaFields: ICommentDeltaFields[]) => {
  return deltaFields.filter(delta => {
    if (Array.isArray(delta.diff) && delta.diff.length === 2 && !delta.oldValue) {
      delta.oldValue = delta.diff[0];
    }
    const fieldValues: any[] = delta.diff && (delta.diff.length > 0 || Object.keys(delta.diff).length > 0)
      ? setSytemMessagesDeltaFields(delta) : [];

    if (delta.type === 'geolocation') {
      return fieldValues.every(value => {
        return value && (value
          && typeof value === 'object' && delta.type === 'geolocation'
          && Object.values(value).every(val => typeof val === 'number' && val > 0))
      })
    }

    return fieldValues && !(fieldValues.every(value => {
      if (Array.isArray(value)) {
        return value.length === 0 || (value.length > 0 && value.every(val => val === null))
      } else {
        return !value || (value && typeof value === 'object' && Object.keys(value).length === 0)
      }
    }) || fieldValues.every(val => val === null));
  });
}
// import { fromJS } from 'immutable'

import {
  GET_DOCUMENT_FORM_FIELDS,
  GET_DOCUMENT_FORM_FIELDS_RETURN,
  GET_DOCUMENT_FORM_FIELDS_ERROR,
  RESET_FORM_DOCUMENT_LIST,
  FILTER_DOCUMENTS,
  FILTER_DOCUMENTS_RETURN,
  FILTER_DOCUMENTS_ERROR,
  GET_FORM_CUSTOM_FILTER,
  GET_FORM_CUSTOM_FILTER_RETURN,
  GET_FORM_CUSTOM_FILTER_ERROR,
  CLEAR_FILTER_DOCUMENTS_ERROR,
  CLEAR_FORM_DOCUMENTS_FIELD_ERROR,
  REFRESH_DOCUMENTS_TABLE,
  SET_DOCUMENT_FILTER,
  CHECK_CURRENT_USER_EDITING,
  SET_CURRENT_USER_EDITING,
  CANCEL_CURRENT_USER_EDITING,
  GET_STATUS_FILTER_FIELD_RETURN,
} from './constant';
import { IField } from '../../../admin-module/module/users/interface/field';
import { IFormDocument, IFormApp } from './interface/form-document';
import { IFormStatus } from '../../../admin-module/module/business-process/module/workflow/interface/form-status';
import { IForm } from '../../../admin-module/module/business-process/module/form/interface/form';
import { IApiCustomFilterField } from '../../../admin-module/module/business-process/module/configuration/module/custom-filter/interface/custom-filter-field';
import { IApiColumnView } from '../../../admin-module/module/business-process/module/configuration/module/document-list/interface/custom-view';
import { IFilter } from '../../interface/input/filter';
import { ISenderDetail } from '../form/interface/sender-info';
import { ICustomFieldDetail } from '../../../../common/custom-field-config/interface/custom-field-detail';

export interface IReportReducer {
  formDocumentsFieldLoading: boolean;
  formDocumentsFieldError: string;
  formDocumentsList: IFormDocument[];
  formDocumentsStatusList: IFormStatus[];
  filterDocumentsLoading: boolean;
  filterDocumentsError: string;
  formApplications: IFormApp;
  formsList: IForm[];
  formCustomFilterLoading: boolean,
  formCustomFilterError: string,
  formCustomFilter: IApiCustomFilterField[];
  formDocumentsFilterFields: IField[];
  formColumnViewList: IApiColumnView[];
  refreshTableLoading: boolean;
  saveDocumentMessage: string;
  documentRecordTotalCount: number;
  documentFilter: IFilter,
  deleteDocumentSuccessMessage: string;
  triggerUserLockModal: boolean;
  currentUserEditingDocument: ISenderDetail[];
  statusFieldFilter: IField[];
  publishedForms: ICustomFieldDetail[];
}

const initialState: IReportReducer = {
  formDocumentsFieldError: '',
  formDocumentsFieldLoading: false,
  formDocumentsList: [] as IFormDocument[],
  formDocumentsStatusList: [] as IFormStatus[],
  filterDocumentsLoading: false,
  filterDocumentsError: '',
  formApplications: {} as IFormApp,
  formsList: [] as IForm[],
  formCustomFilter: [] as IApiCustomFilterField[],
  formCustomFilterError: '',
  formCustomFilterLoading: false,
  formDocumentsFilterFields: [] as IField[],
  formColumnViewList: [] as IApiColumnView[],
  refreshTableLoading: false,
  saveDocumentMessage: '',
  documentRecordTotalCount: 0,
  documentFilter: {},
  deleteDocumentSuccessMessage: '',
  triggerUserLockModal: false,
  currentUserEditingDocument: [],
  statusFieldFilter: [],
  publishedForms: []
}

const reportReducer = (state = initialState, action: any) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_DOCUMENT_FORM_FIELDS:
      newState.formDocumentsFieldError = '';
      newState.formDocumentsFieldLoading = true;
      return newState;

    case GET_DOCUMENT_FORM_FIELDS_RETURN:
      newState.formDocumentsFieldError = '';
      newState.formDocumentsFieldLoading = false;
      newState.formColumnViewList = action.columnViewList;
      newState.publishedForms = action.publishedForms;
      return newState;

    case GET_DOCUMENT_FORM_FIELDS_ERROR:
      newState.formDocumentsFieldError = action.message;
      newState.formDocumentsFieldLoading = false;
      return newState;

    case RESET_FORM_DOCUMENT_LIST:
      return newState;

    case FILTER_DOCUMENTS:
      newState.filterDocumentsLoading = true;
      newState.filterDocumentsError = '';
      return newState;

    case FILTER_DOCUMENTS_RETURN:
      newState.filterDocumentsLoading = false;
      newState.formDocumentsList = action.filteredDocuments;
      newState.documentRecordTotalCount = action.documentRecordTotalCount || 0;
      return newState;

    case FILTER_DOCUMENTS_ERROR:
      newState.filterDocumentsLoading = false;
      newState.filterDocumentsError = action.message;
      return newState;

    case GET_FORM_CUSTOM_FILTER:
      newState.formCustomFilterLoading = true;
      newState.formCustomFilterError = '';
      return newState;

    case GET_FORM_CUSTOM_FILTER_RETURN:
      newState.formCustomFilterLoading = false;
      newState.formCustomFilterError = '';
      newState.formCustomFilter = action.customFilterList;
      newState.formDocumentsFilterFields = action.formDocumentsFilterFields;
      return newState;

    case GET_FORM_CUSTOM_FILTER_ERROR:
      newState.formCustomFilterLoading = false;
      newState.formCustomFilterError = action.message;
      return newState;

    case CLEAR_FILTER_DOCUMENTS_ERROR:
      newState.filterDocumentsError = '';
      return newState;

    case CLEAR_FORM_DOCUMENTS_FIELD_ERROR:
      newState.formDocumentsFieldError = '';
      return newState;

    case REFRESH_DOCUMENTS_TABLE:
      newState.refreshTableLoading = action.refreshTable;
      return newState;

    case SET_DOCUMENT_FILTER:
      newState.documentFilter = action.documentFilter;
      return newState;

    case CHECK_CURRENT_USER_EDITING:
      newState.triggerUserLockModal = false;
      return newState;

    case SET_CURRENT_USER_EDITING:
      newState.triggerUserLockModal = true;
      newState.currentUserEditingDocument = action.currentUserEditingDocument;
      return newState;

    case CANCEL_CURRENT_USER_EDITING:
      newState.triggerUserLockModal = false;
      newState.currentUserEditingDocument = [];
      return newState;

    case GET_STATUS_FILTER_FIELD_RETURN:
      newState.statusFieldFilter = action.statusFieldFilter;
      return newState;

    default:
      return state;
  }
}

export default reportReducer;